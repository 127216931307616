import type {FC} from 'react';
import React from 'react';

import Modal from '../Modal/Modal';

import styles from './index.module.css';

interface Props {
  onClose: () => void;
  onDecline?: () => void;
}

export const TermsModal: FC<Props> = ({onClose, onDecline}) => {
  return (
    <Modal classNameContent={styles.modalContentWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.titleContainer}>
          <div>
            <p className={styles.title}>Terms and Conditions</p>
            <p className={styles.subtitle}>
              brought to you in partnership with YourCoach
            </p>
          </div>
          <img
            src={'https://static.hcod.yourcoach.health/logo_vertical.svg'}
            alt="logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.desc}>
          <p>
            These YourCoach Terms of Service ("Terms") set forth the terms and
            conditions upon which YourCoach Health, Inc., d/b/a YourCoach Health
            ("YourCoach", "we", "our") offers you, our third party registered
            end-users ("End-User(s)"), access to YourCoach’s proprietary
            coaching platform (the "Platform") through the YourCoach application
            (the "App") and the YourCoach website (the "Site"). By clicking "I
            Agree" or by registering with, accessing or otherwise using the
            Platform, you agree to be bound by these Terms. If you do not agree
            to these Terms, do not access or use the Platform. End-Users are
            either health and/or wellness coaches who register with the Platform
            (each, a "Coach") or individuals seeking health, wellness and/or
            lifestyle information who register with the Platform and subscribe
            for services (each, a "Client").
          </p>
          <p>1. LICENSE; RESERVATION OF RIGHTS</p>
          <p>
            1.1 Limited License to Use the Platform. Subject to End-User’s
            compliance with these Terms, YourCoach hereby grants to End-User a
            limited, personal, non-exclusive, non-transferable, license to use
            the App and/or the Site, as applicable, to access Platform solely
            for the End-User’s own personal purposes. Except for the licenses
            and rights expressly granted under these Terms, no licenses or
            rights are granted by YourCoach to End-User hereunder, and all such
            other licenses and rights are reserved to YourCoach.
          </p>
          <p>
            1.2 End-User Registration. End-User shall register a YourCoach
            account for itself. End-User represents and warrants (i) it will
            keep its account credentials and passwords confidential, and shall
            be responsible for any use of the App, the Site or the Platform via
            End-User’s account, and (ii) it has the right to provide the
            End-User Content (as defined below) via the Platform and the App
            and/or the Site, as applicable. End-Users may not register a
            YourCoach account if they are under eighteen (18) years old without
            providing verifiable parental consent.
          </p>
          <p>
            1.3 Other License Restrictions​. End-User shall not (i) copy or
            modify the Platform, App or Site for any purpose; (ii) reverse
            engineer, decompile, modify, translate, disassemble or discover the
            source code for all or any portion of the Platform, the App or the
            Site; or (iii) distribute, disclose, market, rent, lease or
            otherwise transfer the Platform, the App or the Site to any other
            person or entity. You may not use, reproduce or distribute the text,
            graphics, downloads, tools or any other content on the Platform for
            any purpose other than in connection with accessing or using the
            functionality provided via the Platform in accordance with these
            Terms.
          </p>
          <p>
            1.4 Specific Tools​. YourCoach offers certain functionality via the
            App and the Site. Your use of such functionality is also subject to
            any additional terms specific to such functionality (e.g.
            videoconferencing or payment processing applications).
          </p>
          <p>
            1.5 Intellectual Property Rights​. We retain all intellectual
            property rights in and to the Platform, the App and the Site, and
            all related documentation interest (including all copyrights,
            patents, service marks, trademarks and other intellectual property
            rights), including but not limited to any and all updates,
            enhancements, customizations, revisions, modifications, future
            releases and any other changes thereto, and all related information,
            material and documentation. Except for rights specifically provided
            in this Agreement, End-User hereby assigns to YourCoach all other
            intellectual property rights it may now or hereafter possess in the
            Platform, the App, the Site, and related documentation, and all
            derivative works and improvements thereof, and agrees to execute all
            documents, and take all actions, that may be necessary to confirm
            such rights. End-User also agrees to retain all proprietary marks,
            legends and patent and copyright notices that appear on the
            Platform, the App, and the Site, and any related documentation
            delivered to End-User by YourCoach and all whole or partial copies
            thereof.
          </p>
          <p>
            1.6 End-User Content​. “End-User Content” means data, images or
            other content uploaded to the Platform through the App, the Site, or
            otherwise used in connection with the End-User’s use of the
            Platform. End-User (or its licensors) shall own and continue to own
            all right, title and interest in and to the End-User Content.
            End-User hereby grants to YourCoach a royalty-free worldwide license
            to use and modify the End-User Content in connection with the
            provision of the App, the Site and the Platform, and related
            services. Use by YourCoach of End-User Content shall be governed by
            these Terms and the terms of the YourCoach "Privacy Policy". The
            Platform may, from time to time, offer interactive features that
            allow End-Users to submit End-User Content. YourCoach does not and
            cannot review all such End-User Content, and is not responsible for
            such End-User Content. You acknowledge that by providing the ability
            to view and distribute your End-User Content, YourCoach is merely
            acting as a passive conduit for such distribution and is not
            undertaking any obligation or liability related thereto. However,
            YourCoach reserves the right to block or remove communications or
            materials that it determines to be unacceptable to YourCoach in its
            sole discretion. Harassment in any manner or form on the Platform,
            including via e-mail, chat, or by use of obscene or abusive
            language, is strictly forbidden. Impersonation of others, including
            a YourCoach employee or representative, as well as other members or
            visitors on the Platform, is prohibited. You may not upload to,
            distribute, or otherwise publish through the Platform any content
            which is libelous, defamatory, obscene, threatening, invasive of
            privacy or publicity rights, abusive, illegal, or otherwise
            objectionable which may constitute or encourage a criminal offense,
            violate the rights of any party or which may otherwise give rise to
            liability or violate any law.
          </p>
          <p>
            1.7 Additional Restrictions​. YourCoach, the Platform, the App and
            the Site do not provide medical advice. Requesting or providing
            medical advice, diagnoses or treatment (or purporting to do so) via
            the Platform, the App or the Site is strictly prohibited. If a
            Client is seeking medical attention for physical or mental ailments,
            Client should seek help from a licensed medical professional as soon
            as possible, and shall not utilize the Platform for any such help.
          </p>
          <p>2. Warranty disclaimer and limitations of liability</p>
          <p>
            2.1 Warranty Disclaimer​. YOURCOACH DISCLAIMS ANY AND ALL WARRANTIES
            RELATING TO THE PLATFORM, THE APP, THE SITE, OR ANY OTHER MATTER
            COVERED BY THESE TERMS, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            YOURCOACH DOES NOT WARRANT THAT THE PLATFORM, APP OR SITE WILL
            OPERATE WITHOUT INTERRUPTION OR DELAY AND/OR BE ERROR FREE, OR THAT
            ANY FAILURES OF THE PLATFORM, APP OR SITE TO CONFORM TO THE
            DOCUMENTATION CAN OR WILL BE CORRECTED. YOURCOACH MAKES NO
            WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY OF (i) ANY END-USER
            CONTENT PROVIDED TO OR PROCESSED BY THE PLATFORM, THE APP OR THE
            SITE OR PROVIDED BY COACHES OR CLIENTS TO EACH OTHER, (ii) THE
            CREDENTIALS OR QUALIFICATIONS OF ANY COACH, OR (iii) THE INFORMATION
            PROVIDED BY ANY CLIENT. NEITHER THE PLATFORM, THE APP OR THE SITE,
            NOR ANY CONTENT SET FORTH THEREIN, CONSTITUTES MEDICAL ADVICE.
          </p>
          <p>
            2.2 Limitations of Liability​. In NO EVENT SHALL YOURCOACH BE LIABLE
            FOR LOST PROFITS OR BUSINESS, LOSS OF GOODWILL, LOSS OF DATA,
            INTERRUPTION OF BUSINESS, OR FOR ANY EXEMPLARY, PUNITIVE, SPECIAL,
            INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, REGARDLESS OF WHETHER
            SUCH DAMAGES ARISE UNDER ANY THEORY OF TORT, CONTRACT, STRICT
            LIABILITY OR OTHERWISE AND REGARDLESS OF WHETHER SUCH PARTY IS
            ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. The aggregate
            liability of YourCoach for any and all claims arising under or in
            connection with these Terms, the Platform, the App, the Site, or
            their subject matter shall not exceed $500. [Some jurisdictions may
            not permit the limitation of liability as described above, so some
            or all of the described limitations may not apply to you.
          </p>
          <p>3. Indemnification & unauthorized use</p>
          <p>
            3.1 Indemnification​. End-User hereby agrees to indemnify and hold
            harmless YourCoach from any and all damages, costs and expenses
            (including, without limitation, reasonable attorneys’ fees) suffered
            or incurred by YourCoach in connection with any claims that
            YourCoach is required to pay to third parties to the extent such
            damages, settlement amounts, costs and expenses are attributable to
            End-User’s provision of the End-User Content, including any actual
            or alleged violations of third party intellectual property by such
            End-User Content, or End-User’s violation of these Terms.
          </p>
          <p>
            3.2 Notification of Unauthorized Use​. End-User shall promptly
            notify YourCoach in writing upon its discovery of any unauthorized
            use or infringement of the Platform, the App, the Site, or the
            related documentation, or YourCoach’s intellectual property rights
            with respect thereto. YourCoach shall have the sole and exclusive
            right to bring an infringement action or proceeding against any
            infringing third party, and, in the event that YourCoach brings such
            an action or proceeding, End-User shall cooperate and provide full
            information and assistance to YourCoach and its counsel in
            connection with any such action or proceeding.
          </p>
          <p>4. Term and termination</p>
          <p>
            4.1 Term​. End-User’s access to the App, the Site and the Platform
            shall begin on the date End-User registers for an account and
            accepts these Terms, and shall continue until terminated earlier
            under the provisions of this Article 4.
          </p>
          <p>
            4.2 Termination by YourCoach​. YourCoach may terminate End-User’s
            use of the Platform, the App and/or the Site at any time. In the
            event YourCoach terminates your use of the Platform other than due
            to your breach of these Terms or any payment or other obligations in
            connection with your use of the Platform, the App and/or the Site:
            (i) if you are a Client, any pre-paid but unused fees which have
            been paid by you to YourCoach will be refunded to you, and (ii) if
            you are a Coach, any fees due to you but unpaid as of the date of
            termination will be paid.
          </p>
          <p>
            4.3 Termination by End-Users​. If you do not agree to be bound by
            any updated Terms, you must immediately discontinue use of the
            Platform, the App and the Site.
          </p>
          <p>
            4.4 Effect of Termination​. Upon the expiration or sooner
            termination of these Terms, all license rights of End-User under
            these Terms shall automatically and immediately cease and End-User
            shall promptly cease all uses of the Platform and the Site, and
            shall uninstall the App. Sections 1.2, 1.3, 1.4, 1.5, 2.2, 2.3, 3,
            4.3, and 5-8 shall survive the expiration or sooner termination of
            these Terms.
          </p>
          <p>5. Third party links</p>
          <p>
            YourCoach may link to sites operated by third parties. However, even
            if the third party is affiliated with YourCoach, YourCoach has no
            control over these linked sites, all of which may have separate
            privacy and data collection practices, independent of YourCoach.
            These linked sites are only for your convenience and therefore you
            access them at your own risk. Without limiting the foregoing,
            YourCoach specifically disclaims any responsibility if such sites:
            infringe any third party’s intellectual property rights; are
            inaccurate, incomplete or misleading; are not merchantable or fit
            for a particular purpose; do not provide adequate security; contain
            viruses or other items of a destructive nature; or are libelous or
            defamatory. ​YourCoach does not endorse the content, or any products
            or services available, on such sites. Nonetheless, YourCoach seeks
            to protect the integrity of its Site and the links placed upon it
            and therefore requests any feedback on not only its own Site, but
            for sites it links to as well (including if a specific link does not
            work).
          </p>
          <p>6. Copyright complaints</p>
          <p>
            YourCoach respects the intellectual property of others. The Digital
            Millennium Copyright Act (DMCA) provides recourse for copyright
            owners who believe that material appearing on the Internet infringes
            their rights under U.S. copyright law. If you believe in good faith
            that materials hosted by YourCoach infringe your copyright rights,
            you (or your agent) may send us a notice requesting that the
            material be removed, or access to it blocked. The notice must
            include the following information: (i) a physical or electronic
            signature of a person authorized to act on behalf of the owner of an
            exclusive right that is allegedly infringed; (ii) identification of
            the copyrighted work claimed to have been infringed, or, if multiple
            copyrighted works at a single website are covered by a single
            notification, a representative list of such works at that site;
            (iii) identification of the material that is claimed to be
            infringing or to be the subject of infringing activity and that is
            to be removed or access to which is to be disabled, and information
            reasonably sufficient to permit us to locate the material; (iv)
            information reasonably sufficient to permit us to contact you, such
            as an address, telephone number and email address; (v) a statement
            that you have a good faith belief that use of the material in the
            manner complained of is not authorized by you, your agent, or the
            law; and (vi) a statement that the information in the notification
            is accurate, and under penalty of perjury, that you are authorized
            to act on behalf of the owner of an exclusive right that is
            allegedly infringed. All notices described above should be sent to
            YourCoach’s Designated Agent (by email, fax, or mail) at:
            privacy@yourcoach.health or ​64 Bay 38th Street, #3A, Brooklyn, NY
            11214​ .
          </p>
          <p>7. Client – Coach agreements</p>
          <p>
            Coaches and Clients agree (i) YourCoach does not (a) provide
            consultations or advice, (b) guarantee it will match Client requests
            for Coaches, or (c) guarantee any match provided will result in an
            agreement between a Client and a Coach; and (ii) YourCoach is not a
            party to, and shall not be responsible for the performance of, any
            agreement between Coaches and Clients which may occur on the
            Platform or otherwise result from an introduction made through the
            Platform.
          </p>
          <p>8. General</p>
          <p>
            1.1 Modification of Terms; Changes to Functionality​. YourCoach may,
            at any time and without notice to you, modify these Terms by
            revising them and posting them on the App and/or the Site, as
            applicable. Notwithstanding any changes or updates, no such changes
            or updates will be retroactive. Your continued use of the Platform,
            the App and/or the Site after such changes or updates constitutes
            your acceptance of any such revisions as of the date of such
            revisions. Additionally, YourCoach may discontinue or change all or
            part of the Platform, the App, the Site and/or any related services
            with or without notice to you.
          </p>
          <p>
            1.2 Export Compliance​. The Platform, the App and the Site may be
            subject to U.S. and other national export controls and economic
            sanctions. Individuals or entities owned or controlled, registered
            in, or related to Cuba, Iran, Sudan, Syria, or North Korea are not
            permitted to use the App or the Site or access the Platform without
            prior written permission from YourCoach once granted by the
            appropriate jurisdiction. The rights and obligations of End-User
            shall be subject to such United States laws and regulations as shall
            from time to time govern the license and delivery of technology
            abroad by persons subject to the jurisdiction of the United States,
            including the Export Administration Act of 1979, as amended, any
            successor legislation to the Export Administration Act of 1979, and
            the Export Administration regulations issued by the Department of
            Commerce, International Trade Administration, Office of Export
            Administration. End-User each shall certify that it shall not,
            directly or indirectly, export, re-export or transship the Platform
            in such manner as to violate such laws and regulations in effect
            from time to time. End-User shall indemnify and hold harmless
            YourCoach from and against any and all losses, claims and expenses
            incurred by YourCoach as a result of the breach of End-User’s
            obligations under this Section.
          </p>
          <p>
            1.3 Independent Contractors​. In making and performing these Terms,
            the parties are acting and shall act as independent contractors.
            Neither party is, nor will be deemed to be, an agent, legal
            representative, joint venturer or partner of the other party for any
            purpose.
          </p>
          <p>
            1.4 Force Majeure​. In the event that YourCoach is unable to perform
            its obligations under these Terms because of acts of God, strikes,
            equipment or transmission failure, unavailability or poor
            performance of the Internet, or other causes reasonably beyond its
            control, YourCoach shall not be liable to the End-User for any
            damages resulting from such failure to perform or otherwise from
            such causes.
          </p>
          <p>
            1.5 Governing Law​. These Terms and its subject matter shall be
            governed in accordance with the laws of the State of New York,
            without reference to its conflict of laws principles. You agree that
            the federal or state courts sitting in New York, New York shall be
            the exclusive courts of jurisdiction and venue for any litigation,
            special proceeding or other proceeding as between the parties that
            may be brought, or arise out of, or in connection with, or by reason
            of these Terms. The United Nations Convention on Contracts for the
            International Sale of Goods is expressly disclaimed.
          </p>
          <p>
            1.6 Assignment​. End-User may not assign any of its rights or
            privileges, or delegate any of its duties or obligations hereunder
            to any third party without the prior written consent of YourCoach.
            Any purported assignment in contravention of this Section shall be
            null and void. Subject to the foregoing, these Terms shall be
            binding upon and inure to the benefit of the parties hereto and
            their respective permitted successors and assigns.
          </p>
          <p>
            1.7 Notices​. All notices and other communications hereunder shall
            be in writing and shall be deemed effective when delivered by hand,
            reputable overnight delivery service, or certified mail (return
            receipt requested), postage prepaid to: (a) in the case of
            YourCoach, to mborukhovich@yourcoach.health, to the attention of
            Marina Borukhovich; and (b) in the case of End-User, to the email
            address set forth in its account information.
          </p>
          <p>
            1.8 Entire Agreement; Amendment​. These Terms constitute the entire
            agreement between the parties concerning the subject matter hereof
            and supersede all prior understandings and agreements between the
            parties, whether written or oral, regarding the subject matter
            hereof. You agree carefully to review these Terms, and the other
            YourCoach policies and agreements applicable to you, before using
            the Platform, the App, the Site, or services and functionality
            offered therein. You agree that if any term of these Terms of Use is
            held by a court of competent jurisdiction to be invalid, unlawful or
            incapable of being enforced, then all conditions and provisions of
            this Agreement that can be given effect without such invalid,
            unlawful or unenforceable provision, shall nevertheless remain in
            full force and you agree to be bound by the remaining, enforceable
            terms, provided that the original intentions of you and YourCoach
            under these Terms shall be maintained.
          </p>
          <p>
            1.9 Waivers​. A waiver by either party of a breach or violation of
            any provision of these Terms will not constitute or be construed as
            a waiver of any subsequent breach or violation of that provision or
            as a waiver of any breach or violation of any other provision of
            these Terms.
          </p>
        </div>
        <div className={styles.buttonsWrapper}>
          <button className={styles.button} onClick={onClose}>
            Agree
          </button>
          <button className={styles.button} onClick={onDecline}>
            Decline
          </button>
        </div>
      </div>
    </Modal>
  );
};
