import React from 'react';

import dayjs from 'dayjs';

import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import styles from '@src/modules/SeeProgram/Squad/styles.module.scss';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface IUserInfoModal {
  userInfo: Array<any> | undefined;
  showUserInfoModal: boolean;
  handleShow: () => void;
}

const UserInfoModal = ({
  userInfo,
  showUserInfoModal,
  handleShow,
}: IUserInfoModal) => {
  return (
    <ModalAnimateWin
      showModal={showUserInfoModal}
      closeModalHandler={handleShow}
      isBody
      header={`${userInfo?.private_profile?.first_name}`}
      classNameHeader="schedule-header"
      type="user-info-modal"
      classNameBody={styles.customScroll}
    >
      <div className={styles.UserInfoContainer}>
        <div className={styles.InfoAbout}>About</div>
        <div className={styles.InfoRowContainer}>
          <div className={styles.MarginRight}>
            <p className={styles.HeaderInfo}>First name</p>
            <p className={styles.Info}>
              {userInfo?.private_profile?.first_name || userInfo?.name || ''}
            </p>
          </div>
          <div>
            <p className={styles.HeaderInfo}>Partner ID</p>
            <p className={styles.Info}>{userInfo?.partner_id}</p>
          </div>
        </div>
        <div className={styles.InfoRowContainer}>
          <div className={styles.MarginRight}>
            <p className={styles.HeaderInfo}>Gender</p>
            <p className={styles.Info}>
              {userInfo?.private_profile?.gender || userInfo?.gender || ''}
            </p>
          </div>
          <div>
            <p className={styles.HeaderInfo}>External ID</p>
            <p className={styles.Info}>{userInfo?.external_id}</p>
          </div>
        </div>
        <div className={styles.InfoRowContainer}>
          <div>
            <p className={styles.HeaderInfo}>Timezone</p>
            <p className={styles.Info}>
              {dayjs().tz(userInfo?.timezone).format('z')}
            </p>
          </div>
        </div>
        <div className={styles.divider} />
        {userInfo?.private_profile?.additional_data.length
          ? userInfo?.private_profile?.additional_data.map(elem => (
              <>
                <div className={styles.Question}>
                  {elem.question.replace(/["']/g, '')}
                </div>
                <div className={styles.Answer}>
                  {elem.answer.replace(/["']/g, '')}
                </div>
              </>
            ))
          : null}
      </div>
    </ModalAnimateWin>
  );
};

export default UserInfoModal;
