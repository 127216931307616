import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

interface Props {
  children: ReactNode;
  firstColClassName?: string;
  secondColClassName?: string;
  thirdColClassName?: string;
  mergeTowLastColumn?: boolean;
}

const ThreeColumns: FC<Props> = ({
  children,
  firstColClassName = '',
  secondColClassName = '',
  thirdColClassName = '',
  mergeTowLastColumn = false,
}) => {
  return (
    <div className="ThreeColumnsLayouts percent100H">
      <div className={firstColClassName} />
      <div
        className={`secondCol percent100H ${
          mergeTowLastColumn ? 'mergeTowLastColumn' : ''
        } ${secondColClassName}`}
      >
        {children}
      </div>
      {mergeTowLastColumn ? null : <div className={thirdColClassName} />}
    </div>
  );
};

export default memo(ThreeColumns);
