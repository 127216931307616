import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {CustomSelect} from '../../../components/CustomForm';
import type {IOption} from '../../../components/CustomForm/CustomSelect/CustomSelect';
import type {ModalRef} from '../../../components/ModalNew';
import type {CourseT} from '../../courses/SelectCourse';
import SelectCourseModal from '../../courses/SelectCourse/Modal';

interface Props {
  options?: IOption[];
  defaultId?: string;
  className?: string;
  seeError?: boolean;
  onSelectCourse: (courses: CourseT[]) => void;
  onCloseSelect?: () => void;
  disabled?: boolean;
  course?: CourseT;
}

const SelectCourseContainer: FC<Props> = ({
  options = [],
  defaultId,
  className = '',
  seeError = false,
  disabled = false,
  onSelectCourse,
  onCloseSelect,
  course,
}) => {
  const selectCourseModalRef = useRef<ModalRef>(null);

  const handleCloseModalCourseList = () => {
    if (onCloseSelect) {
      onCloseSelect();
    }
  };

  const _onCourseButtonPress = () => {
    selectCourseModalRef.current?.show();
  };

  const handleOnSelectCourse = (courses: CourseT[]) => {
    onSelectCourse(courses);

    selectCourseModalRef.current?.hide();
  };

  return (
    <Observer>
      {() => (
        <>
          <CustomSelect
            options={options}
            defaultId={defaultId}
            onSelect={() => {}}
            className={className}
            seeError={seeError}
            disabled={disabled}
            onClick={_onCourseButtonPress}
          />
          <SelectCourseModal
            onSelect={handleOnSelectCourse}
            initialSelection={course ? [course] : []}
            ref={selectCourseModalRef}
            onRequestClose={handleCloseModalCourseList}
            title="Select program"
          />
        </>
      )}
    </Observer>
  );
};

export default memo(SelectCourseContainer);
