import type {FC} from 'react';
import React, {memo} from 'react';

import SecondaryFolderIcon from '@yourcoach/shared/assets/icons/folder-secondary.svg';

import {IconCheck} from '@src/components/icons';
import type {ILink} from '@src/models/library';

interface Props {
  data: ILink;
  className?: string;
  onClick: () => void;
  isSelect: boolean;
}

const UserFolder: FC<Props> = ({data, isSelect, onClick}) => {
  const {name} = data;

  return (
    <div className="UserFolder containerUserFolder" onClick={onClick}>
      <div className="UserFolderContainer">
        <div className={`checkItem ${isSelect ? 'active' : ''}`}>
          {isSelect && (
            <div className="checkedContainer">
              <IconCheck />
            </div>
          )}
        </div>
        <div className="iconFolderContainer">
          <SecondaryFolderIcon />
        </div>
        <div className="folderName">
          <span>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(UserFolder);
