import React from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';
import styled from 'styled-components';

import DangerIcon from '@yourcoach/shared/assets/icons/primary/Danger.svg';
import VideoIcon from '@yourcoach/shared/assets/icons/primary/Video.svg';
import {alert} from '@yourcoach/shared/modules/alert';
import type {
  ConferenceModel,
  ConferenceStrategy,
  TaskStrategy,
} from '@yourcoach/shared/stores/single-event';
import {Button} from '@yourcoach/shared/uikit/Button';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {IconButton} from '@src/v2/components/IconButton';
import {PathBuilderService} from '@src/v2/services/PathBuilderService';

import {I18N_SINGLE_EVENT} from '../../utils';

const StyledButton = styled(Button)`
  box-shadow: 0 4px 20px rgba(66, 56, 181, 0.1);
  min-width: 90px;
`;

export const ControlsContainer: React.FC<{
  store: ConferenceStrategy | TaskStrategy;
}> = observer(({store}) => {
  const history = useHistory();
  const onEditHandler = React.useCallback(() => {
    store.isEditOpenModal.toggle();
  }, [store]);

  const onDelete = React.useCallback(async () => {
    await store.delete(history.goBack);
  }, [store, history]);

  return store.initialLoad.isReady ? (
    <View style={{gap: 12, alignItems: 'center'}}>
      {store.event.isConference && (
        <ConferenceControlsContainer
          event={store.event as ConferenceModel}
          onEditHandler={onEditHandler}
          startButtonLabel={(store as ConferenceStrategy).startButtonLabel}
        />
      )}
      {store.event.isEditable && store.isAllClientTasksAreNOTDone && (
        <StyledButton onClick={onEditHandler} scheme={'option'}>
          <Text color={'text3'}>
            {t([I18N_SINGLE_EVENT, 'button', 'edit'])}
          </Text>
        </StyledButton>
      )}
      {store.event.isDeletable && store.isAllClientTasksAreNOTDone && (
        <StyledButton onClick={onDelete} scheme={'option'}>
          <Text color={'text3'}>{store.deleteButtonLabel}</Text>
        </StyledButton>
      )}
    </View>
  ) : null;
});

const ConferenceControlsContainer: React.FC<{
  event: ConferenceModel;
  startButtonLabel: string;
  onEditHandler: () => any;
}> = observer(({event, startButtonLabel, onEditHandler}) => {
  const history = useHistory();
  const onStartHandler = React.useCallback(async () => {
    const confirm = await alert.confirm({
      title: t(['Conference', 'start_conference_question']),
    });

    if (confirm) {
      history.push(PathBuilderService.toConference(event.id));
    }
  }, [history, event]);

  const onFixConflictClickHandler = React.useCallback(() => {
    onEditHandler();
  }, [onEditHandler]);

  return (
    <React.Fragment>
      {event.isConflicting ? (
        <IconButton
          icon={<DangerIcon />}
          scheme={'error'}
          onClick={onFixConflictClickHandler}
        >
          <Text color={'inherit'}>
            {t([I18N_SINGLE_EVENT, 'button', 'fix_conflict'])}
          </Text>
        </IconButton>
      ) : (
        <IconButton
          icon={<VideoIcon />}
          scheme={'secondary'}
          onClick={onStartHandler}
          disabled={!event.isStartEnabled}
        >
          <Text color={'inherit'}>{startButtonLabel}</Text>
        </IconButton>
      )}
    </React.Fragment>
  );
});
