import type {FC} from 'react';
import React, {memo} from 'react';
import {useLocation} from 'react-router-dom';

import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {MainContainerWithMenuColor} from '@src/layouts';
import ThreeColumns from '@src/layouts/ThreeColumns/ThreeColumns';
import SeeProgramState from '@src/modules/SeeProgram/context/SeeProgramState';
import Squad from '@src/modules/SeeProgram/Squad/Squad';

interface Props {}

const CoursePage: FC<Props> = () => {
  const currentSquadId = useURLQueryParam('sqid');
  const location = useLocation();

  const tab =
    // @ts-ignore
    location.state && location.state.tab
      ? // @ts-ignore
        (location.state.tab as string)
      : null;

  return (
    <MainContainerWithMenuColor isWithFrom isWithNewCrumbs isWithCrumbs>
      <div className="SquadPage percent100H">
        <ThreeColumns thirdColClassName="greyColumn" mergeTowLastColumn>
          <SeeProgramState>
            <Squad currentSquadId={currentSquadId} tab={tab} />
          </SeeProgramState>
        </ThreeColumns>
      </div>
    </MainContainerWithMenuColor>
  );
};

export default memo(CoursePage);
