import {useCallback} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import authStore from '@yourcoach/shared/api/auth/store';
import type {Access, Session} from '@yourcoach/shared/api/auth/types';
import type {User} from '@yourcoach/shared/api/user';
import {currentUserStore} from '@yourcoach/shared/api/user';

export type AuthAction = 'login' | 'register';

interface UserInfoApi {
  partner_id: number | null;
  demographics: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    state: string;
    zipcode: string;
    date_of_birth: string | null;
    gender: string;
  };
  coach_reason: {}[];
  additional_data: {key: string[]}[];
}

export type UserInfo = {
  partnerId: UserInfoApi['partner_id'];
  demographics: {
    firstName: UserInfoApi['demographics']['first_name'];
    lastName: UserInfoApi['demographics']['last_name'];
    email: UserInfoApi['demographics']['email'];
    phone: UserInfoApi['demographics']['phone'];
    state: UserInfoApi['demographics']['state'];
    zipcode: UserInfoApi['demographics']['zipcode'];
    dateOfBirth: UserInfoApi['demographics']['date_of_birth'];
    gender: UserInfoApi['demographics']['gender'];
  };
  coachReason: UserInfoApi['coach_reason'];
  additionalData: UserInfoApi['additional_data'];
};

export interface AuthParams {
  client_id?: string;
  client_secret?: string;
  action: AuthAction;
  device: string;
  token?: string;
  push_token: string | null;
  external_id?: string;
  app_id?: string;
  user_info?: UserInfoApi;
}

interface LoginMethodParams {
  clientId?: string;
  clientSecret?: string;
  action: AuthAction;
  agent: string;
  token?: string;
  appId?: string;
  pushToken?: string | null;
  errorCallback?: (err: any) => void;
}

interface RegisterMethodParams {
  clientId: string;
  clientSecret: string;
  action: AuthAction;
  agent: string;
  token: string;
  pushToken?: string | null;
  appId?: string;
  userInfo: UserInfo;
  errorCallback?: (err: any) => void;
}

export const useAuth = ({
  setTermsVisible,
}: {
  setTermsVisible: (isVisible: boolean) => void;
}) => {
  const makeRequest = useCallback(async (params: AuthParams) => {
    try {
      const {
        access,
        user,
        session,
      }: {
        session: Session;
        access: Access;
        user: User;
      } = await apiRequest<AuthParams>({
        // @ts-ignore
        method: 'user.auth.external_auth',
        params,
        options: {withAuth: false},
      });

      authStore._onReceiveAccessToken(access.token);
      authStore._saveSession(session);

      // TODO: fix the TS error (required changes in shared)
      // @ts-ignore
      currentUserStore.setUser(user);
    } catch (error) {
      window.widget.api.onAuthError?.(error);

      throw error;
    }
  }, []);

  const login = useCallback(
    async ({
      clientId,
      clientSecret,
      agent,
      action,
      appId,
      token,
    }: LoginMethodParams) => {
      const params: AuthParams = {
        client_id: clientId,
        client_secret: clientSecret,
        action,
        device: agent,
        app_id: appId,
        token,
        push_token: null,
      };

      if (action === 'register') {
        setTermsVisible(true);
      } else {
        await makeRequest(params);
      }
    },
    [makeRequest, setTermsVisible],
  );

  const register = useCallback(
    async ({
      clientId,
      clientSecret,
      action,
      agent,
      token,
      appId,
      userInfo,
    }: RegisterMethodParams) => {
      const params: AuthParams = {
        client_id: clientId,
        client_secret: clientSecret,
        action,
        device: agent,
        app_id: appId,
        token,
        push_token: null,
      };

      params.user_info = {
        partner_id: userInfo.partnerId,
        demographics: {
          first_name: userInfo.demographics.firstName,
          last_name: userInfo.demographics.lastName,
          email: userInfo.demographics.email,
          phone: userInfo.demographics.phone,
          state: userInfo.demographics.state,
          zipcode: userInfo.demographics.zipcode,
          date_of_birth: userInfo.demographics.dateOfBirth,
          gender: userInfo.demographics.gender,
        },
        coach_reason: userInfo.coachReason,
        additional_data: userInfo.additionalData,
      };

      await makeRequest(params);
    },
    [makeRequest],
  );

  return {login, register};
};
