import {useContext, useMemo} from 'react';

import {action, computed, observable} from 'mobx';

import {labelCoursesLbl} from '@src/common/i18n/i18nLibrary';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import type {ProgramT, TListDataItem, TScheduleMaterial} from '@src/models/app';
import type {TSystemFolder} from '@src/models/library';
import {materialIsFolder} from '@src/models/materials';

export interface IProgramLocalStore {
  program: ProgramT | null;
  materials: TScheduleMaterial[];
  userIsCoach: boolean;
  listData: any[];
  setProgram: (newProgram: ProgramT) => void;
  setMaterial: (newMaterial: TScheduleMaterial[]) => void;
}

export const useProgramLocalStore = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const programId = useURLQueryParam('pid');

  const listProgramLocalStore = useMemo(
    () =>
      observable<IProgramLocalStore>(
        {
          materials: [],
          program: null,
          setProgram(newProgram: ProgramT) {
            this.program = newProgram;
          },
          setMaterial(newMaterial: TScheduleMaterial[]) {
            this.materials = newMaterial;
          },
          get userIsCoach() {
            const user = currentUserStore.user;

            return (
              this.program && user && this.program.coach_ids.includes(user._id)
            );
          },
          get listData() {
            let data: TListDataItem[] = [];
            let folders: TListDataItem[] = [];
            let files: TScheduleMaterial[] = [];

            this.materials.forEach(material => {
              if (materialIsFolder(material)) {
                const folderM = {
                  _id: `courses&pid=${programId}&mid=${
                    (material as TScheduleMaterial).uuid
                  }`,
                  type: 'folder',
                  isSystem: true,
                  name: (material as TScheduleMaterial).title,
                  day: (material as TScheduleMaterial).day,
                };

                folders.push(folderM as TListDataItem);
              } else {
                files.push(material);
              }
            });

            const sortFn = (a: TScheduleMaterial, b: TScheduleMaterial) =>
              a.day - b.day;

            data = folders.concat(files).sort(sortFn);

            const systemFolders: TSystemFolder[] = [
              {
                _id: `courses&pid=${programId}`,
                isSystem: true,
                type: 'folder',
                isStarred: true,
                name: labelCoursesLbl(),
              },
            ];

            return (systemFolders as TListDataItem[]).concat(data);
          },
        },
        {
          program: observable,
          materials: observable,
          userIsCoach: computed,
          listData: computed,
          setProgram: action,
          setMaterial: action,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programId],
  );

  return listProgramLocalStore;
};
