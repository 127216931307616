import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {
  className?: string;
}

const OverlayImage: FC<Props> = ({className = ''}) => {
  return <div className={`OverlayImage ${className}`} />;
};

export default memo(OverlayImage);
