import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from 'i18n-js';

dayjs.extend(LocalizedFormat);

const DEFAULT_LOCALE = 'en-US';

const locales = {
  'en-US': 'en-US',
};

const dayjsLocales = {
  'en-US': 'en-ca',
};

export let userLocale =
  typeof navigator !== 'undefined'
    ? navigator.language ||
      // @ts-ignore
      navigator.browserLanguage ||
      // @ts-ignore
      navigator.userLanguage
    : '';

if (userLocale.includes('_')) {
  userLocale = userLocale.replace('_', '-');
}

i18n.defaultLocale = DEFAULT_LOCALE;
i18n.fallbacks = true;

i18n.locale = userLocale;

i18n.missingTranslation = scope =>
  // @ts-ignore
  `NT: ${i18n.currentLocale()}.${i18n.getFullScope(scope)}`;

export const init = async () => {
  try {
    const defaultLocaleDict = require(`../../src/locales/${locales[DEFAULT_LOCALE]}`);

    const defaultSharedLocaleDict = require(`@yourcoach/shared/locales/${locales[DEFAULT_LOCALE]}`);

    i18n.translations = {
      [DEFAULT_LOCALE]: {
        shared: defaultSharedLocaleDict,
        number: defaultSharedLocaleDict.number || {},
        ...defaultLocaleDict,
      },
    };

    if (userLocale !== DEFAULT_LOCALE && locales[userLocale]) {
      try {
        const dict = require(`../../src/locales/${locales[userLocale]}`);

        const sharedDict = require(`@yourcoach/shared/locales/${locales[userLocale]}`);

        i18n.translations = {
          [userLocale]: {
            shared: sharedDict,
            number: sharedDict.number || {},
            ...dict,
          },
        };
      } catch (error) {
        // fallback to default locale
      }
    }

    const defaultDayjsLocaleDict = require(`dayjs/locale/${dayjsLocales[DEFAULT_LOCALE]}`);

    if (userLocale !== DEFAULT_LOCALE && dayjsLocales[userLocale]) {
      try {
        const dayjsDict = require(`dayjs/locale/${dayjsLocales[userLocale]}`);

        dayjs.locale(userLocale, {
          ...defaultDayjsLocaleDict,
          ...dayjsDict,
        });
      } catch {
        // fallback to default locale
      }
    } else {
      dayjs.locale(userLocale, {
        ...defaultDayjsLocaleDict,
      });
    }

    return true;
  } catch (e) {
    // TODO: Log
    // fallback to en

    return true;
  }
};

const dayjsLocalesCommon = {
  'en-US': require('dayjs/locale/en-ca'),
};

dayjs.locale(userLocale, {
  ...dayjsLocalesCommon['en-US'],
  ...dayjsLocalesCommon[userLocale],
});

export const getCurrentDateLocale = () => dayjs.Ls[userLocale];

export const t: (scope: i18n.Scope, options?: i18n.TranslateOptions) => string =
  i18n.t.bind(i18n);

export default i18n;
