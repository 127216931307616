import React, {useCallback, useEffect, useRef} from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import {sessionNoteStore} from '@yourcoach/shared/api/sessionNote';
import {ClientSessionNotesStore} from '@yourcoach/shared/stores/sessionNotes/ClientSessionNotes';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import * as S from './styles';
import type {Props} from './types';

export const CRUSessionNoteModal: React.FC = NiceModal.create<Props>(
  ({userId, courseId, user, course, program, note}) => {
    const modal = useModal();

    const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible(false);

    const colorRef = useRef(note?.color);

    const clientSessionNotesStore = useRef(
      new ClientSessionNotesStore({
        clientId: userId,
        courseId,
        user,
        course,
        program,
      }),
    ).current;

    const fields = useRef({
      body: createHtmlInputField<HTMLTextAreaElement>('body', {
        defaultValue: note?.body || '',
        validationRule: {
          type: 'string',
          max: 5000,
          messages: {
            required: t('shared.session_notes.validation.body_required'),
            stringMax: t('shared.session_notes.validation.body_max_length', {
              maxLength: 5000,
            }),
          },
        },
      }),
    }).current;

    useEffect(() => {
      return () => {
        clientSessionNotesStore.dispose();
      };
    }, [clientSessionNotesStore]);

    const onColorChange = useCallback((color: string) => {
      colorRef.current = color;
    }, []);

    const onSaveButtonClick = useCallback(async () => {
      try {
        showOverlay();

        const data = {
          course_id: courseId,
          body: fields.body.value.trim(),
          color: colorRef.current,
        };

        if (note) {
          await sessionNoteStore.update(note, data);
        } else {
          await sessionNoteStore.create({
            client_id: userId,
            ...data,
          });
        }

        hideOverlay();

        modal.hide();
      } catch (error) {
        hideOverlay();

        getCustomConfirmAlert({
          title: t('shared.message.error'),
          message: error.message,
          buttons: [
            {
              label: t('shared.button.ok'),
            },
          ],
        });
      }
    }, [
      courseId,
      fields.body.value,
      hideOverlay,
      modal,
      note,
      showOverlay,
      userId,
    ]);

    return (
      <S.Modal
        isOpen={modal.visible}
        onRequestClose={modal.hide}
        onAfterClose={modal.remove}
        title={
          note
            ? t('shared.session_notes.edit_note_button')
            : t('shared.session_notes.add_note_button')
        }
        bodyClassName="body"
      >
        <Observer>
          {() => (
            <S.InfoRow>
              <S.UserInfo>
                <S.UserAvatar
                  src={
                    getFileSrc(clientSessionNotesStore.user?.avatar, 250).url ||
                    ''
                  }
                  resizeMode="cover"
                  placeholder={
                    <AvatarPlaceholder
                      text={clientSessionNotesStore.user?.name}
                    />
                  }
                />
                <S.UserName>{clientSessionNotesStore.user?.name}</S.UserName>
              </S.UserInfo>
              <S.ProgramContainer>
                <S.ProgramTitle>
                  {clientSessionNotesStore.program?.title}
                </S.ProgramTitle>
                <S.CourseDates>
                  {clientSessionNotesStore.course !== undefined
                    ? getCourseDurationString(clientSessionNotesStore.course) ||
                      t(['label', 'course_no_start_date', 'coach'])
                    : ''}
                </S.CourseDates>
              </S.ProgramContainer>
            </S.InfoRow>
          )}
        </Observer>
        <S.Content>
          <Observer>
            {() => (
              <>
                <S.Textarea
                  value={fields.body.value}
                  onChange={fields.body.onChange}
                />
                <S.ColorPicker
                  initialColor={note?.color}
                  onChange={onColorChange}
                />
                {fields.body.error ? (
                  <S.Error>{fields.body.error}</S.Error>
                ) : null}
              </>
            )}
          </Observer>
        </S.Content>
        <S.Footer>
          <Observer>
            {() => (
              <S.SaveButton
                disabled={!!fields.body.error}
                onClick={onSaveButtonClick}
              >
                {t('shared.button.save')}
              </S.SaveButton>
            )}
          </Observer>
        </S.Footer>
        {overlayIsVisible ? (
          <div className="overlay">
            <Loader />
          </div>
        ) : null}
      </S.Modal>
    );
  },
);
