import {useCallback, useState} from 'react';

import {useSelection} from '../useSelection';

const useListEditor = <T,>(
  initialItems: T[],
  fixedItems: T[],
  nullItem: T,
  matchFn: (item1: T, item2: T) => boolean,
  onUpdate: (item: T, newValue: any) => T,
) => {
  // Populate items: empty element to add new value,
  // custom items (not matched to fixed ones) and fixed items
  const [items, setItems] = useState(
    [{...nullItem}]
      .concat(
        ...initialItems.filter(t => !fixedItems.find(t1 => matchFn(t, t1))),
      )
      .concat(...fixedItems),
  );

  // Populate selection:
  // - use raw index in `items` for custom items
  // - use index from `fixedItems` for matched items
  const {selection, onItemClick, onInsert, onRemove} = useSelection(
    initialItems
      .map((item, index) => {
        const indexInInit = fixedItems.findIndex(t => matchFn(t, item));

        return indexInInit >= 0
          ? indexInInit + items.length - fixedItems.length
          : 1 + index;
      })
      .filter(i => i > 0),
    true,
    0,
    undefined,
    (index: number, _willSelect: boolean) =>
      // Block select of placeholder
      index > 0,
  );

  // Update item when inputs(s) changed
  const makeOnItemChange = useCallback(
    index => {
      return valueOrEvent => {
        items[index] = onUpdate(
          items[index],
          valueOrEvent.currentTarget
            ? valueOrEvent.currentTarget.value
            : valueOrEvent,
        );

        setItems([...items]);
      };
    },
    [items, onUpdate],
  );

  // When user has finished item editing...
  const makeOnBlur = useCallback(
    index => {
      return event => {
        if (event.currentTarget.value === '' && index !== 0) {
          // ...remove cleared item...
          setItems(items.filter((_, i) => i !== index));
          onRemove(index);
        } else if (event.currentTarget.value !== '' && index === 0) {
          // ...or insert new empty one
          setItems([{...nullItem}].concat(...items));
          onInsert(0, true);
        }
      };
    },
    [items, onRemove, nullItem, onInsert],
  );

  const makeOnKeyPress = useCallback(
    index => {
      return event => {
        if (event.key === 'Enter') {
          makeOnBlur(index)(event);
        }
      };
    },
    [makeOnBlur],
  );

  return {
    selection,
    items,
    makeOnItemChange,
    makeOnBlur,
    makeOnKeyPress,
    onItemClick,
  };
};

export {useListEditor};
