import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Img as ImageE} from 'react-image';

import type {PostAttachment as PostAttachmentT} from '@yourcoach/shared/api/channel/post';
import {
  isAudioAttachment,
  isFileAttachment,
  isImageAttachment,
} from '@yourcoach/shared/api/channel/post';
import type {Material} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';

import DocFileIcon from '../../../../assets/doc-file.svg';
import AvatarLoader from '../../../../components/AvatarLoader/AvatarLoader';
import {IconClose} from '../../../../components/icons';
import type {Expanded as ExpandedMaterial} from '../../../../models/materials';
import {
  getMaterialFile,
  getMaterialIcon,
  materialIsFolder,
} from '../../../../models/materials';
import styles from '../../styles/styles.module.css';

interface Props {
  attachments: PostAttachmentT[];
  deleteAttachment: (attachment: PostAttachmentT) => void;
}

const AttachmentsForm: FC<Props> = ({attachments, deleteAttachment}) => {
  return (
    <div className={`AttachmentsForm ${styles.AttachmentsForm}`}>
      {attachments.map(attachment => {
        return (
          <div key={attachment._id}>
            <AttachmentsElement
              attachment={attachment}
              deleteAttachment={deleteAttachment}
            />
          </div>
        );
      })}
    </div>
  );
};

export default memo(AttachmentsForm);

interface PropsAttachmentsElement {
  attachment: PostAttachmentT;
  deleteAttachment: (attachment: PostAttachmentT) => void;
}

const AttachmentsElement: FC<PropsAttachmentsElement> = ({
  attachment,
  deleteAttachment,
}) => {
  const {preview, Icon, linkUrl} = useMemo(() => {
    let attachmentType = (attachment._id || '').split(':')[0];

    if (!attachmentType && isFileAttachment(attachment)) {
      attachmentType = 'file';
    }

    const result: {
      title: string;
      description: string;
      Icon: JSX.Element;
      preview: string | null | undefined;
      buttonText: string | undefined;
      isAudio: boolean;
      linkUrl: string | undefined;
    } = {
      title: (attachment as Material).title,
      description: (attachment as Material).description,
      Icon: <DocFileIcon />,
      preview: '',
      buttonText: '',
      isAudio: !!isAudioAttachment(attachment),
      linkUrl: undefined,
    };

    if (materialIsFolder(attachment as Material)) {
      const IconMaterial = getMaterialIcon(
        attachment as Material & ExpandedMaterial,
      );

      result.Icon = <IconMaterial />;
    } else {
      let _attachment = attachment;

      if (attachmentType === 'material') {
        _attachment = getMaterialFile(
          attachment as Material & ExpandedMaterial,
        )!;

        if (_attachment) {
          [attachmentType] = _attachment._id.split(':');
        }
      }

      if (attachmentType === 'file') {
        const IconFle = getFileIcon(_attachment as IFile);

        result.Icon = <IconFle />;
        result.title = getFileName(_attachment as IFile);

        // @ts-ignore
        const {url, urlrepr} = getFileSrc(_attachment);

        if (isImageAttachment(_attachment)) {
          result.preview = url;
          result.linkUrl = url || undefined;
        } else if ((_attachment as IFile).type === 'url') {
          result.preview = getFileSrc(_attachment as IFile, 'image').url;
          result.linkUrl = urlrepr;
        }
      }
    }

    return result;
  }, [attachment]);

  const deleteAttachmentCb = useCallback(() => {
    deleteAttachment && deleteAttachment(attachment);
  }, [attachment, deleteAttachment]);

  return (
    <div className={`AttachmentsElement ${styles.AttachmentsElement}`}>
      <div className={styles.closeButtonContainer}>
        <button
          onClick={deleteAttachmentCb}
          className={`closeBut ${styles.closeBut}`}
        >
          <IconClose />
        </button>
      </div>
      <div className={styles.imageContainer}>
        {preview ? (
          <div className={styles.image}>
            <a target="_blank" rel="noopener noreferrer" href={linkUrl}>
              <ImageE src={linkUrl || ''} loader={<AvatarLoader />} />
            </a>
          </div>
        ) : (
          <div className={styles.ico}>{Icon}</div>
        )}
      </div>
    </div>
  );
};
