import BugsnagPluginReact from '@bugsnag/plugin-react';

import Bugsnag from '@bugsnag/js';
import mixpanelBrowser from 'mixpanel-browser';

import {apiRequest} from '@yourcoach/shared/api';
import type {ILogger} from '@yourcoach/shared/utils/logger';

import pkg from '../../package.json';
import {
  API_VERSION,
  BUGSNAG_KEY,
  IS_ANALYTICS_ENABLED,
  IS_WIDGET_PACKAGE,
  MIXPANEL_TOKEN,
} from '../config';

const mixpanel = IS_ANALYTICS_ENABLED
  ? (() => {
      mixpanelBrowser.init(MIXPANEL_TOKEN);

      return mixpanelBrowser;
    })()
  : null;

export class Logger implements ILogger {
  constructor() {
    this.init();
  }

  init: Required<ILogger>['init'] = () => {
    if (!IS_WIDGET_PACKAGE) {
      return;
    }

    Bugsnag.start({
      appVersion: pkg.version,
      apiKey: BUGSNAG_KEY,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: ['stage', 'prod'],
      releaseStage: process.env.APP_ENV,
      metadata: {
        api_version: {
          version: API_VERSION,
        },
      },
      redactedKeys: [
        'access_token',
        'session_token',
        'client_id',
        'client_secret',
        'external_id',
        'token',
        'phone',
        'email',
      ],
      onError: event => {
        if (
          event.originalError.name === 'AbortError' ||
          apiRequest.isCanceledError(event.originalError) ||
          event.originalError.message === 'The network connection was lost.'
        ) {
          return false;
        }
      },
    });
  };

  setUser: Required<ILogger>['setUser'] = user => {
    if (user) {
      mixpanel?.identify(user._id);
    } else {
      mixpanel?.reset();
    }

    if (IS_WIDGET_PACKAGE) {
      Bugsnag.setUser(
        user ? user._id : undefined,
        undefined,
        user ? user.name : undefined,
      );
    }
  };

  setUserProperty: Required<ILogger>['setUserProperty'] = (prop, value) => {
    mixpanel?.people.set(prop, value);
  };

  event: Required<ILogger>['event'] = (name, params) => {
    mixpanel?.track(name, params);
  };

  error: Required<ILogger>['error'] = error => {
    if (IS_WIDGET_PACKAGE) {
      Bugsnag.notify(error);
    }
  };

  logApiRequest: Required<ILogger>['logApiRequest'] = payload => {
    if (IS_WIDGET_PACKAGE) {
      Bugsnag.leaveBreadcrumb(
        'RPC request',
        {
          method: Array.isArray(payload)
            ? `Batch: ${payload.map(item => item.method).join(' ,')}`
            : payload.method,
        },
        'log',
      );
    }
  };
}
