import React, {useCallback, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import NoteIcon from '@yourcoach/shared/assets/icons/primary/Edit Square.svg';
import PlusIcon from '@yourcoach/shared/assets/icons/primary/Plus.svg';
import {SessionNotesStore} from '@yourcoach/shared/stores/sessionNotes/SessionNotes';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import {t} from '@src/i18n';

import {AddSessionNoteModal} from './AddSessionNoteModal';
import {SessionNotesListItem} from './SessionNotesListItem';
import {SessionNotesModal} from './SessionNotesModal';
import * as S from './styles';

const I18N_SCOPE = 'SessionNotesTab';

const MyNotes: React.FC = () => {
  const sessionNotesStore = useRef(
    new SessionNotesStore({limit: 50, showExistingOnly: true}),
  ).current;

  useEffect(() => {
    sessionNotesStore.fetch();

    return () => {
      sessionNotesStore.dispose();
    };
  }, [sessionNotesStore]);

  const onNoteButtonClick: GetComponentProps<
    typeof SessionNotesListItem
  >['onNoteButtonClick'] = useCallback(membership => {
    NiceModal.show(SessionNotesModal, {
      userId: membership.user_id,
      courseId: membership.course_id,
      user: membership.user,
      program: membership.program,
      course: membership.course,
    });
  }, []);

  const onAddNoteButtonClick = useCallback(() => {
    NiceModal.show(AddSessionNoteModal);
  }, []);

  const onFetchMoreInViewChange = useCallback(
    isInView => {
      if (isInView) {
        sessionNotesStore.fetchMore();
      }
    },
    [sessionNotesStore],
  );

  return (
    <S.Container>
      <Observer>
        {() => (!sessionNotesStore.computedStore.isLoaded ? <Loader /> : null)}
      </Observer>
      <Observer>
        {() =>
          !sessionNotesStore.listStore.isEmpty &&
          !sessionNotesStore.isSearching ? (
            <S.AddNoteButton onClick={onAddNoteButtonClick}>
              {t(['shared.session_notes.add_note_button'])}
              <PlusIcon />
            </S.AddNoteButton>
          ) : null
        }
      </Observer>
      <Observer>
        {() => (
          <S.List>
            {sessionNotesStore.computedStore.items.map(item => (
              <SessionNotesListItem
                item={item}
                key={item._id}
                onNoteButtonClick={onNoteButtonClick}
              />
            ))}
            {/* @ts-ignore */}
            <InView as="div" threshold={0} onChange={onFetchMoreInViewChange}>
              <Observer>
                {() =>
                  sessionNotesStore.computedStore.hasMore ? (
                    <Loader size={50} />
                  ) : null
                }
              </Observer>
            </InView>
          </S.List>
        )}
      </Observer>
      <Observer>
        {() =>
          sessionNotesStore.listStore.isEmpty ? (
            <S.NoResultsContainer>
              <NoResultsHeader
                text={t([I18N_SCOPE, 'no_results_label'])}
                icon={NoteIcon}
              />
              <S.AddNoteButton onClick={onAddNoteButtonClick}>
                {t(['shared.session_notes.add_note_button'])}
                <PlusIcon />
              </S.AddNoteButton>
            </S.NoResultsContainer>
          ) : null
        }
      </Observer>
    </S.Container>
  );
};

export default React.memo(MyNotes);
