import React, {memo} from 'react';

import SpinnerIcon from '@src/assets/img/spinner.svg';

import styles from './SpinnerOverlay.module.css';

const SpinnerOverlay = ({isActive, children}) => {
  return (
    <div className={styles.SpinnerOverlay}>
      {children}
      {isActive ? (
        <div className={styles.spinnerContainer}>
          <SpinnerIcon />
        </div>
      ) : null}
    </div>
  );
};

export default memo(SpinnerOverlay);
