import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';
import {reaction} from 'mobx';

import type {
  Edition,
  ProgramScheduleItemType,
} from '@yourcoach/shared/api/program';
import {ProgramScheduleItemTypeEnum} from '@yourcoach/shared/api/program';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import CheckboxIcon from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import CalendarIcon from '@yourcoach/shared/assets/icons/primary/Calendar.svg';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import NoResultsHeader from '@src/components/NoResultsHeader';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import type {ProgramT} from '.';
import styles from './Schedule.module.css';
import ScheduleEventModal from './ScheduleEventModal';
import ScheduleListItem from './ScheduleListItem';
import type {TabProps, TabRef} from './useTabs';

const I18N_SCOPE = 'shared.CRUProgramSchedule';

const SCHEDULE_EVENT_CONTEXT_MENU_ID = 'schedule_event_context_menu';

type ScheduledConference = Edition['conferences'][0];

type ScheduledTask = Edition['tasks'][0];

type Section = {title: string; data: ScheduleItem[]};

export type ScheduleItem = (ScheduledConference | ScheduledTask) & {
  type: ProgramScheduleItemType;
};

const CRUProgramSchedule = React.forwardRef<TabRef, TabProps>(
  ({program, isIndividual}, ref) => {
    const {
      stores: {editionStore},
    } = useContext(AppContext);

    useImperativeHandle(ref, () => ({
      getData,
    }));

    const [conferences, setConferences] = useState<ScheduledConference[]>([]);
    const [tasks, setTasks] = useState<ScheduledTask[]>([]);

    const isScheduleItemInDurationRange = useCallback(
      (item: {day: number}) => {
        if (program) {
          const lastEdition = program.editions[program.editions.length - 1];

          if (lastEdition) {
            const maxDay = lastEdition.duration - 1;

            if (item.day > maxDay) {
              return false;
            }
          }
        }

        return true;
      },
      [program],
    );

    const flatSchedule = useMemo(
      () => [
        ...(isIndividual ? [] : conferences || [])
          .filter(isScheduleItemInDurationRange)
          .map(item => ({
            ...item,
            type: ProgramScheduleItemTypeEnum.CONFERENCE,
          })),
        ...(tasks || []).filter(isScheduleItemInDurationRange).map(item => ({
          ...item,
          type: ProgramScheduleItemTypeEnum.TASK,
        })),
      ],
      [conferences, isIndividual, isScheduleItemInDurationRange, tasks],
    );

    const shouldRenderStub = useMemo(
      () => !flatSchedule.length,
      [flatSchedule],
    );

    const sections = useMemo(() => {
      const result: Section[] = [];
      const groups: {[key: number]: ScheduleItem[]} = {};

      const groupByDays = (item: ScheduleItem) => {
        const groupId = item.day;

        groups[groupId] = groups[groupId] || [];
        groups[groupId].push(item);
      };

      flatSchedule.forEach(groupByDays);

      Object.keys(groups)
        // @ts-ignore
        .sort((a, b) => a - b)
        .forEach(key => {
          const day = parseInt(key, 10);

          result.push({
            title:
              day === 0
                ? t([I18N_SCOPE, 'first_day_section_header'])
                : t([I18N_SCOPE, 'day_section_header'], {day: day + 1}),
            data: groups[key].sort(
              (a: ScheduleItem, b: ScheduleItem) => a.time - b.time,
            ),
          });
        });

      return result;
    }, [flatSchedule]);

    const [
      scheduleEventModalIsOpen,
      showScheduleEventModal,
      hideScheduleEventModal,
    ] = useIsVisible();

    const scheduleEventToEdit = useRef<ScheduledTask>();
    const scheduleEventType = useRef<'task' | 'conference'>();

    const getData = useCallback(() => {
      let lastEdition: ProgramT['editions'][0] | null =
        program && program.editions.length
          ? program.editions[program.editions.length - 1]
          : null;

      lastEdition = {
        ...lastEdition,
        conferences: isIndividual
          ? []
          : conferences.slice().filter(isScheduleItemInDurationRange),
        tasks: tasks.slice().filter(isScheduleItemInDurationRange),
      } as ProgramT['editions'][0];

      let editions: ProgramT['editions'];

      if (program && program.editions.length) {
        program.editions.splice(program.editions.length - 1, 1, lastEdition);

        editions = program.editions;
      } else {
        editions = [lastEdition];
      }

      return {
        editions,
      };
    }, [
      conferences,
      isIndividual,
      isScheduleItemInDurationRange,
      program,
      tasks,
    ]);

    useEffect(() => {
      if (program) {
        const lastEdition = program.editions[program.editions.length - 1];

        if (lastEdition) {
          setConferences(lastEdition.conferences || conferences);
          setTasks(lastEdition.tasks || tasks);
        }
      }

      const dispose = reaction(
        () => editionStore.updating,
        updating => {
          if (
            program &&
            updating.success &&
            updating.entity &&
            updating.entity._id === program._id
          ) {
            const lastEdition = program.editions[program.editions.length - 1];

            if (lastEdition) {
              setConferences(lastEdition.conferences || conferences);
              setTasks(lastEdition.tasks || tasks);
            }
          }
        },
      );

      return () => {
        dispose();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddTaskButtonClick = useCallback(() => {
      scheduleEventType.current = 'task';

      showScheduleEventModal();
    }, [showScheduleEventModal]);

    const onAddConferenceButtonClick = useCallback(() => {
      scheduleEventType.current = 'conference';

      showScheduleEventModal();
    }, [showScheduleEventModal]);

    const onClearButtonClick = useCallback(async () => {
      getCustomConfirmAlert({
        title: 'Are you sure?',
        buttons: [
          {
            label: 'No',
            onClick: () => {},
          },
          {
            label: 'Yes',
            type: 'confirm',
            onClick: () => {
              setTasks([]);
              setConferences([]);
            },
          },
        ],
      });
    }, []);

    const onEventsScheduled = useCallback(
      (events: ScheduleItem[], type: 'task' | 'conference') => {
        // edit
        if (scheduleEventToEdit.current) {
          const [event] = events;

          if (type === 'conference') {
            setConferences(prev => {
              const index = prev.findIndex(
                conference => conference.uuid === event.uuid,
              );

              if (index >= 0) {
                prev.splice(index, 1, event as ScheduledConference);
              }

              return [...prev];
            });
          } else if (type === 'task') {
            setTasks(prev => {
              const index = prev.findIndex(task => task.uuid === event.uuid);

              if (index >= 0) {
                prev.splice(index, 1, event);
              }

              return [...prev];
            });
          }
        } else {
          if (type === 'task') {
            setTasks(prev => [...prev, ...events]);
          } else if (type === 'conference') {
            setConferences(prev => [
              ...prev,
              ...(events as ScheduledConference[]),
            ]);
          }
        }

        hideScheduleEventModal();
      },
      [hideScheduleEventModal],
    );

    const onUpdateButtonClick = useCallback(
      ({props}: ItemParams<{item: ScheduleItem}>) => {
        if (props) {
          const {item} = props;

          contextMenu.hideAll();

          scheduleEventToEdit.current = item;

          // @ts-ignore
          scheduleEventType.current = item.type.id;

          showScheduleEventModal();
        }
      },
      [showScheduleEventModal],
    );

    const onDeleteButtonClick = useCallback(
      ({props}: ItemParams<{item: ScheduleItem}>) => {
        if (props) {
          const {item} = props;

          if (item.type.id === ProgramScheduleItemTypeEnum.CONFERENCE.id) {
            setConferences(prev => {
              const index = prev.findIndex(
                conference => conference.uuid === item.uuid,
              );

              if (index >= 0) {
                prev.splice(index, 1);
              }

              return [...prev];
            });
          } else if (item.type.id === ProgramScheduleItemTypeEnum.TASK.id) {
            setTasks(prev => {
              const index = prev.findIndex(task => task.uuid === item.uuid);

              if (index >= 0) {
                prev.splice(index, 1);
              }

              return [...prev];
            });
          }
        }
      },
      [],
    );

    const onScheduleEventMoreButtonClick = useCallback(
      (item: ScheduleItem, event: React.MouseEvent<Element, MouseEvent>) => {
        contextMenu.show({
          id: SCHEDULE_EVENT_CONTEXT_MENU_ID,
          event,
          props: {
            item,
          },
        });
      },
      [],
    );

    const ContextMenu = useCallback(
      () => (
        <Menu
          id={SCHEDULE_EVENT_CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          <Item onClick={onUpdateButtonClick} className="contextMenuItem">
            {t([I18N_SCOPE, 'more_update_button'])}
          </Item>
          <Item
            onClick={onDeleteButtonClick}
            className={classNames('contextMenuItem', 'danger')}
          >
            {t([I18N_SCOPE, 'more_delete_button'])}
          </Item>
        </Menu>
      ),
      [onDeleteButtonClick, onUpdateButtonClick],
    );

    const onScheduleItemClick = useCallback(
      (item: ScheduleItem) => {
        contextMenu.hideAll();

        scheduleEventToEdit.current = item;

        // @ts-ignore
        scheduleEventType.current = item.type.id;

        showScheduleEventModal();
      },
      [showScheduleEventModal],
    );

    const onScheduleEventModalClose = useCallback(() => {
      scheduleEventToEdit.current = undefined;
      hideScheduleEventModal();
    }, [hideScheduleEventModal]);

    return (
      <>
        <div className={styles.addButtonsContainer}>
          <Button onClick={onAddTaskButtonClick}>
            <CheckboxIcon />
            {t([I18N_SCOPE, 'add_task_button'])}
          </Button>
          {!isIndividual ? (
            <Button onClick={onAddConferenceButtonClick}>
              <CameraIcon />
              {t([I18N_SCOPE, 'add_conference_button'])}
            </Button>
          ) : null}
          {!shouldRenderStub ? (
            <Button onClick={onClearButtonClick} className={styles.clearButton}>
              {t([I18N_SCOPE, 'clear_button'])}
            </Button>
          ) : null}
        </div>
        {shouldRenderStub ? (
          <div className={styles.noResultsContainer}>
            <NoResultsHeader
              text={
                isIndividual
                  ? t([I18N_SCOPE, 'no_results_label_without_conferences'])
                  : t([I18N_SCOPE, 'no_results_label'])
              }
              icon={CalendarIcon}
            />
          </div>
        ) : (
          <div className={styles.list}>
            {sections.map(section => (
              <div key={section.title}>
                <div className={styles.sectionHeaderContainer}>
                  <h4 className={styles.sectionHeader}>{section.title}</h4>
                  <span className={styles.line} />
                </div>
                {section.data.map(item => (
                  <ScheduleListItem
                    key={item.uuid}
                    item={item}
                    onClick={onScheduleItemClick}
                    onMoreButtonClick={onScheduleEventMoreButtonClick}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
        <ContextMenu />
        {program && scheduleEventType.current ? (
          <ScheduleEventModal
            isOpen={scheduleEventModalIsOpen}
            onAfterClose={onScheduleEventModalClose}
            program={program}
            event={scheduleEventToEdit.current}
            type={scheduleEventType.current}
            onSuccess={onEventsScheduled}
          />
        ) : null}
      </>
    );
  },
);

export default React.memo(CRUProgramSchedule, isEqual);
