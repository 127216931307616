import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';

import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import type {Follower} from '@src/models/questionnaire';

import QSendUsersContext from '../context/QSendUsersContext';
import type {ILocalStoreQSendUsers} from '../context/QSendUsersLocalStore';

import styles from './../styles.module.css';
import ByEmail from './ByEmail/ByEmail';
import ByName from './ByName/ByName';
import BySquad from './BySquad/BySquad';

interface Props {}

const BodyQSendUsers: FC<Props> = () => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);
  const getBodyEl = () => {
    if (storeQSendUsers.currentItemMenu === 'byName') {
      return <ByName />;
    } else if (storeQSendUsers.currentItemMenu === 'byEmail') {
      return <ByEmail />;
    } else if (storeQSendUsers.currentItemMenu === 'bySquad') {
      return <BySquad />;
    } else {
      return <ByName />;
    }
  };

  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const userIsConcierge = useMemo(
    () =>
      !!(
        currentUserStore.user &&
        currentUserStore.user.roles.includes('concierge')
      ),
    [currentUserStore.user],
  );

  useEffect(() => {
    let followersStore: CollectionStore<Follower> = createCollectionStore({
      method: 'coach.followers.list',
      params: {
        sort: [['created', -1]],
        expand: {
          follower: [
            ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
          ],
        },
      },
    });

    if (userIsConcierge) {
      followersStore = createCollectionStore({
        method: 'client.users.search',
        params: {
          query: [
            ['concierge_ids', 'in', [currentUserStore.user!._id]],
            ['_id', '!=', currentUserStore.user!._id],
            ['deleted', '==', null],
          ],
          expand: {
            user: ['avatar_id'],
          },
        },
      });
    }

    storeQSendUsers.setFollowersStore(followersStore);
    storeQSendUsers.refreshFollowersStore(true);

    return () => {
      if (storeQSendUsers.followersStore) {
        storeQSendUsers.followersStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div className={`BodyQSendUsers ${styles.BodyQSendUsers}`}>
          {storeQSendUsers.loading ? <Loader /> : getBodyEl()}
        </div>
      )}
    </Observer>
  );
};

export default memo(BodyQSendUsers);
