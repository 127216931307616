import type {FC} from 'react';
import React, {memo} from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

const LeftPaneLayout: FC<Props> = ({className = '', children}) => {
  return (
    <div className={classNames(styles.leftPaneLayout, className)}>
      <div className={styles.leftPane}>
        {React.Children.map(children, (c, index) => (index === 0 ? c : null))}
      </div>
      <div className={styles.rightPane}>
        {React.Children.map(children, (c, index) => (index > 0 ? c : null))}
      </div>
    </div>
  );
};

export default memo(LeftPaneLayout);
