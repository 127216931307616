import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Helmet} from 'react-helmet';
import {Img as Image} from 'react-image';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import {
  action,
  autorun,
  computed,
  observable,
  reaction,
  runInAction,
  toJS,
} from 'mobx';

import type {ApiRpcRequestParams} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {sortCoursesFn} from '@yourcoach/shared/api/course';
import type {Follower} from '@yourcoach/shared/api/follower';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {Membership} from '@yourcoach/shared/api/membership';
import {
  createPaymentPlan,
  FULL_PORTION,
  parsePaymentPlan,
  ProgramTypeEnum,
} from '@yourcoach/shared/api/program';
import getProposedMembership from '@yourcoach/shared/api/program/utils/getProposedMembership';
import SendIcon from '@yourcoach/shared/assets/icons/primary/Send.svg';
import delay from '@yourcoach/shared/utils/delay';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelClientCoursesTab,
  labelCoursesTab,
  labelDescriptionTab,
  labelInvitesTab,
  labelJoinNow,
  labelRequestsTab,
} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconCalendar, IconUser, IconUserGroup} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import type {ModalRef} from '@src/components/ModalNew';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import {syncIleStorage} from '@src/hooks/useIsLimitedEdition';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import {expand as programExpand} from '@src/models/program';
import JoinProgramModal from '@src/modules/programs/JoinProgramModal';
import SendMessageModal from '@src/modules/users/SendMessageModal';

import {emitter} from '../../../widget/src/utils';

import CoachRightMenu from './CoachRightMenu/CoachRightMenu';
import SeeProgramContext from './context/SeeProgramContext';
import type {
  ClientMembership,
  ISeeProgramLocalStore,
  ProgramT,
} from './context/useSeeProgramLocalStore';
import DescriptionTab from './DescriptionTab/DescriptionTab';
import InvitesTab from './InvitesTab/InvitesTab';
import SquadsTab from './SquadsTab/SquadsTab';
import SquadsTabClient from './SquadsTabClient/SquadsTabClient';
import RequestsTab from './ProgramRequestsTab';
import styles from './styles.module.scss';

type TItemMenu = 'courses' | 'requests' | 'invites' | 'description' | 'client';

const I18N_SCOPE = 'Program';

interface ILocalStore {
  isOpenModuleJoinProgram: boolean;
  setIsOpenModuleJoinProgram(isOpenModuleJoinProgram: boolean): void;
  currentItemMenu: TItemMenu;
  setCurrentItemMenu(type: TItemMenu): void;
  itemMenu: {type: TItemMenu; name: string}[];
}

interface Props {
  programId: string | null;
  coachId: string;
  tab?: string | null;
}

const SeeProgram: FC<Props> = ({programId, tab, coachId}) => {
  const {
    stores: {programStore, membershipStore, currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);

  const [joinModalIsOpen, showJoinProgramModal, hideJoinProgramModal] =
    useIsVisible();

  const programExpandWithCourses = JSON.parse(JSON.stringify(programExpand));
  const editionsExpandIndex = programExpandWithCourses.program.findIndex(
    item => Array.isArray(item) && item[0] === 'edition_ids',
  );

  programExpandWithCourses.program[editionsExpandIndex][2].edition.push(
    'course_ids',
  );

  // @ts-ignore
  const localStore: ILocalStore = useRef(
    observable(
      {
        isOpenModuleJoinProgram: false,
        setIsOpenModuleJoinProgram(isOpenModuleJoinProgram: boolean) {
          this.isOpenModuleJoinProgram = isOpenModuleJoinProgram;
        },
        currentItemMenu: 'courses',
        setCurrentItemMenu(type: TItemMenu) {
          this.currentItemMenu = type;
        },
        get itemMenu() {
          type Tab = {type: TItemMenu; name: string};

          let tabs: Tab[] = [
            {type: 'description', name: labelDescriptionTab()},
          ];

          if (seeProgramLocalStore!.userIsProgramCoach) {
            tabs = (
              [
                {
                  type: 'courses',
                  name: t([
                    I18N_SCOPE,
                    'courses_tab',
                    this.isIndividual ? 'individual' : 'group',
                  ]),
                },
                !seeProgramLocalStore!.isOfferedProgram
                  ? {type: 'requests', name: labelRequestsTab()}
                  : null,
                !seeProgramLocalStore!.isOfferedProgram
                  ? {type: 'invites', name: labelInvitesTab()}
                  : null,
                {type: 'description', name: labelDescriptionTab()},
              ] as Tab[]
            ).filter(Boolean);
          } else if (
            seeProgramLocalStore!.clientMembership ||
            seeProgramLocalStore!.clientHasArchivedMembership
          ) {
            tabs.unshift({type: 'client', name: labelClientCoursesTab()});
          }

          return tabs;
        },
        get isIndividual() {
          return (
            seeProgramLocalStore!.lastEdition &&
            seeProgramLocalStore!.lastEdition.group_size === 1
          );
        },
      },
      {
        isOpenModuleJoinProgram: observable,
        isIndividual: computed,
        setIsOpenModuleJoinProgram: action,
        currentItemMenu: observable,
        itemMenu: computed,
        setCurrentItemMenu: action,
      },
    ),
  ).current;

  const itemMenu: {type: TItemMenu; name: string}[] = [
    {type: 'courses', name: labelCoursesTab()},
    {type: 'requests', name: labelRequestsTab()},
    {type: 'invites', name: labelInvitesTab()},
    {type: 'description', name: labelDescriptionTab()},
    {type: 'client', name: labelClientCoursesTab()},
  ];

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    const disposeUserIsProgramCoach = reaction(
      () => seeProgramLocalStore!.userIsProgramCoach,
      userIsProgramCoach => {
        if (userIsProgramCoach) {
          localStore.currentItemMenu = 'courses';
        }
      },
    );
    const disposeClientMembership = reaction(
      () => seeProgramLocalStore!.clientMembership,
      clientMembership => {
        if (clientMembership && !seeProgramLocalStore!.userIsProgramCoach) {
          localStore.currentItemMenu = 'client';
        }
      },
    );
    const disposeClientHasArchivedMembership = reaction(
      () => seeProgramLocalStore!.clientHasArchivedMembership,
      clientHasArchivedMembership => {
        if (
          clientHasArchivedMembership &&
          !seeProgramLocalStore!.userIsProgramCoach
        ) {
          localStore.currentItemMenu = 'client';
        }
      },
    );

    const disposeAutorun = autorun(() => {
      if (
        currentUserStore.user &&
        !currentUserStore.user.roles.includes('coach')
      ) {
        syncIleStorage(
          seeProgramLocalStore!.programId || undefined,
          seeProgramLocalStore!.clientHasMatched,
        );
      }
    });

    return () => {
      disposeAutorun();
      disposeClientMembership();
      disposeClientHasArchivedMembership();
      disposeUserIsProgramCoach();
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programId && coachId !== '') {
      seeProgramLocalStore!.setProgramId(programId);

      if (!seeProgramLocalStore!.userIsProgramCoach) {
        localStore.currentItemMenu = 'description';
      }

      fetchProgram();
      getRequestCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, coachId]);

  useEffect(() => {
    if (tab) {
      localStore.setCurrentItemMenu(tab as TItemMenu);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const fetchProgram = async () => {
    try {
      let program: ProgramT | null = null;
      let params: ApiRpcRequestParams = {};

      params._id = programId;
      params.expand = programExpandWithCourses;

      try {
        program = (await programStore.client.fetch(params)) as ProgramT;
      } catch (clientFetchError) {
        program = (await programStore.coach.fetch(params)) as ProgramT;
      }

      runInAction(() => {
        seeProgramLocalStore?.getProgram(program!);

        _fetchClientMembership();

        if (
          seeProgramLocalStore!.showAvailableCourses &&
          seeProgramLocalStore!.lastEdition
        ) {
          const courses: Course[] = [];

          program!.editions.forEach(edition => {
            if (edition) {
              courses.push(...edition.courses);
            }
          });

          seeProgramLocalStore!.availableCourses = courses
            .filter(course =>
              (['ongoing', 'planned'] as Course['status'][]).includes(
                course.status,
              ),
            )
            .sort(sortCoursesFn);
        }
      });
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const getRequestCount = async () => {
    if (seeProgramLocalStore?.isCoach) {
      try {
        const result = await apiRequest({
          batch: [
            {
              method: 'coach.memberships.count',
              params: {
                query: [
                  ['program_id', '==', seeProgramLocalStore!.programId],
                  ['status', 'in', ['requested'] as Membership['status'][]],
                ],
              },
            },
            {
              method: 'coach.invites.count',
              params: {
                query: [['program_id', '==', seeProgramLocalStore!.programId]],
              },
            },
          ],
        });

        seeProgramLocalStore!.setRequestsCount(result[0]._count as number);
        seeProgramLocalStore!.setInvitesCount(result[1]._count as number);
      } catch (error) {
        setError(error);
      }
    }
  };

  const _handleWsMessage = msg => {
    if (
      msg.event.type.includes('program') ||
      msg.event.type.includes('membership') ||
      msg.event.type.includes('edition')
    ) {
      fetchProgram();
    }
  };

  const _fetchClientMembership = async () => {
    if (!seeProgramLocalStore!.userIsProgramCoach) {
      const followersResult = (await apiRequest({
        method: 'client.followers.list',
        params: {
          query: [['coach_id', '==', seeProgramLocalStore!.program!.user_id]],
        },
      })) as {_items: Follower[]};

      const [follower] = followersResult._items;

      runInAction(() => {
        seeProgramLocalStore!.clientHasMatched =
          follower && follower.is_matched && follower.status !== 'invited';
      });

      if (seeProgramLocalStore!.clientHasMatched) {
        return;
      }
    }

    if (!seeProgramLocalStore!.clientMembership) {
      const proposedMembership = await getProposedMembership(
        seeProgramLocalStore!.program!,
      );

      if (proposedMembership) {
        runInAction(() => {
          seeProgramLocalStore!.clientIsInvited = true;
        });

        return;
      }

      const programInvitesResult = await apiRequest({
        method: 'client.invites.count',
        params: {
          query: [
            ['program_id', '==', seeProgramLocalStore!.program!._id],
            ['user_id', '==', currentUserStore.user!._id],
          ],
        },
      });

      runInAction(() => {
        seeProgramLocalStore!.clientIsInvited = !!programInvitesResult._count;
      });

      return;
    }

    try {
      const membership = (await membershipStore!.client.fetch({
        _id: seeProgramLocalStore!.clientMembership._id,
        expand: {
          membership: ['course_id'],
        },
      })) as ClientMembership;

      runInAction(() => {
        seeProgramLocalStore!.clientMembershipWithCourse = membership;
      });
    } catch (error) {
      await getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);

      // TODO: log error

      await delay(2000);

      _fetchClientMembership();
    }
  };

  const handleOnClickMenuItem = (type: TItemMenu) => {
    return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      localStore.setCurrentItemMenu(type);
    };
  };

  const onJoinButtonClick = () => {
    showJoinProgramModal();
  };

  const onJoinSuccess = (membership: Membership & {course?: Course | null}) => {
    hideJoinProgramModal();

    fetchProgram();

    if (
      membership.course &&
      membership.course.channel_ids.length &&
      (membership.status === 'accepted' || membership.status === 'active')
    ) {
      history.push({
        pathname: '/chats',
        search: `?cid=${membership.course.channel_ids[0]}`,
      });
    } else if (!membership.course) {
      getCustomConfirmAlert({
        message: t([I18N_SCOPE, 'request_submitted_message'], {
          programTitle: seeProgramLocalStore!.program!.title,
        }),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
            type: 'confirm',
          },
        ],
      });
    }
  };

  const onCourseCreated = useCallback(
    op => {
      if (op?.success) {
        const program = seeProgramLocalStore!.program!;
        const edition = program.editions[program.editions.length - 1]!;

        edition.course_ids.push(op.entity._id);
        edition.courses.push(op.entity);
      }
    },
    [seeProgramLocalStore],
  );

  const onCourseDeleted = useCallback(
    op => {
      if (op?.success) {
        const program = seeProgramLocalStore!.program!;
        const edition = program.editions[program.editions.length - 1]!;

        edition.course_ids = edition.course_ids.filter(
          id => id !== op.entity._id,
        );
        edition.courses = edition.courses.filter(c => c._id !== op.entity._id);
      }
    },
    [seeProgramLocalStore],
  );

  const {
    stores: {courseStore},
  } = useContext(AppContext);

  const [programStatus, setProgramStatus] = useState('');

  useEffect(() => {
    const disposers = [
      reaction(() => courseStore.creating, onCourseCreated),
      reaction(() => courseStore.deleting, onCourseDeleted),
      autorun(() => {
        let status = '';

        if (seeProgramLocalStore!.program) {
          if (seeProgramLocalStore!.userIsProgramCoach) {
            status = t([
              I18N_SCOPE,
              'status',
              seeProgramLocalStore!.program.status,
            ]);

            if (seeProgramLocalStore!.program.is_closed) {
              status = t([I18N_SCOPE, 'status', 'closed']);
            }

            if (seeProgramLocalStore!.program.offer_id) {
              status = t([I18N_SCOPE, 'status', 'restricted']);
            }
          } else if (seeProgramLocalStore!.program.is_closed) {
            status = t([I18N_SCOPE, 'status', 'closed']);
          }

          setProgramStatus(status);
        }
      }),
    ];

    return () => {
      disposers.forEach(disposer => disposer());
    };
  }, [courseStore, onCourseCreated, onCourseDeleted, seeProgramLocalStore]);

  const sendMessageModal = useRef<ModalRef | null>(null);

  const onSendMessageButtonClick = useCallback(() => {
    if (sendMessageModal.current) {
      sendMessageModal.current.show();
    }
  }, []);

  return (
    <Observer>
      {() => (
        <div className={`SeeProgram percent100H ${styles.SeeProgram}`}>
          {!seeProgramLocalStore?.program ? (
            <Loader />
          ) : (
            <>
              <div className={styles.greyInMobile}>
                <Helmet title={seeProgramLocalStore.program.title} />
                <div className={styles.ImageProgramContainer}>
                  <div className={styles.ImageProgramAbsContainer}>
                    <Image
                      src={
                        getFileSrc(seeProgramLocalStore.program.avatar, 500)
                          .url || ''
                      }
                      loader={<Loader height="55%" width="55%" />}
                      unloader={
                        <AvatarLoader
                          title={seeProgramLocalStore.program.title}
                          isBGGradient
                          directionGradient="180deg"
                        />
                      }
                    />
                    <OverlayImage className={styles.OverlayImage} />
                    {programStatus ? (
                      <div
                        className={classNames(
                          styles.status,
                          styles[programStatus],
                        )}
                      >
                        {programStatus}
                      </div>
                    ) : null}
                  </div>
                </div>
                {seeProgramLocalStore.userIsProgramCoach ? (
                  <CoachRightMenu />
                ) : seeProgramLocalStore.clientIsAbleToJoin ? (
                  <div
                    className={styles.joinNowMainBut}
                    onClick={onJoinButtonClick}
                  >
                    {labelJoinNow()}
                  </div>
                ) : seeProgramLocalStore.program &&
                  seeProgramLocalStore.program.is_closed &&
                  !seeProgramLocalStore.clientMembership ? (
                  <div>
                    <Button
                      className={styles.joinNowMainBut}
                      onClick={onSendMessageButtonClick}
                    >
                      {t([I18N_SCOPE, 'message_coach_button'])}
                      <SendIcon />
                    </Button>
                    <SendMessageModal
                      ref={sendMessageModal}
                      title={t([I18N_SCOPE, 'message_coach_button'])}
                      userId={seeProgramLocalStore!.program.user_id}
                    />
                  </div>
                ) : null}
              </div>
              <div className={styles.greyColumn}>
                <div className={styles.headerProgram}>
                  <div className={styles.headerProgramLeft}>
                    <h1>{seeProgramLocalStore.program.title}</h1>
                    {seeProgramLocalStore.programPrice ? (
                      <div className={styles.paymentPlan}>
                        {seeProgramLocalStore.programPrice}
                      </div>
                    ) : null}
                    <div className={styles.paymentPlanOptionContainer}>
                      <div className={styles.paymentPlanOption}>
                        <div className={styles.paymentPlanIcon}>
                          <IconCalendar
                            className={styles.calendarIcon}
                            viewBox="-2 -2 24 24"
                          />
                        </div>
                        <span>
                          {seeProgramLocalStore.duration
                            ? seeProgramLocalStore.duration!.toString()
                            : ''}
                        </span>
                      </div>
                      <div className={styles.paymentPlanOption}>
                        <div className={styles.programTypeIcon}>
                          {seeProgramLocalStore.programType! &&
                          seeProgramLocalStore.programType!.id ===
                            ProgramTypeEnum.INDIVIDUAL.id ? (
                            <IconUser viewBox="0 0 21 21" />
                          ) : (
                            <IconUserGroup
                              className={styles.groupIcon}
                              viewBox="0 1 32 32"
                            />
                          )}
                        </div>
                        <span>
                          {seeProgramLocalStore.programType
                            ? seeProgramLocalStore.programType!.toString()
                            : ''}
                        </span>
                      </div>
                      {seeProgramLocalStore.coaches.map(coach => {
                        let coachSignData = coach._id
                          ? seeProgramLocalStore.program!.coaches[coach._id]
                          : null;

                        let priceForCoach;

                        if (
                          coachSignData &&
                          !seeProgramLocalStore.cocoachesAreSigned &&
                          seeProgramLocalStore.lastEdition &&
                          seeProgramLocalStore.lastEdition.payment_plan &&
                          !seeProgramLocalStore.isMainCoach
                        ) {
                          const coachPrice = Math.round(
                            seeProgramLocalStore.paymentPlan!.total *
                              (coachSignData.portion / FULL_PORTION),
                          );

                          const coachPaymentPlan = parsePaymentPlan(
                            createPaymentPlan({
                              price: coachPrice,
                              duration:
                                seeProgramLocalStore.lastEdition.duration,
                              paymentType:
                                seeProgramLocalStore.paymentPlan!.paymentType,
                              currency:
                                seeProgramLocalStore.paymentPlan!
                                  .parsedCurrency,
                            }),
                          );

                          priceForCoach =
                            seeProgramLocalStore.lastEdition.payment_plan
                              .processing === 'internal'
                              ? coachPaymentPlan.extractedCommission.toString()
                              : coachPaymentPlan.toString();
                        }

                        if (
                          coach._id &&
                          seeProgramLocalStore.lastEdition &&
                          seeProgramLocalStore.cocoachesAreSigned &&
                          !seeProgramLocalStore.lastEditionIsSigned
                        ) {
                          // @ts-ignore
                          coachSignData =
                            seeProgramLocalStore.lastEdition.coaches[coach._id];
                        }

                        let status:
                          | 'approved'
                          | 'rejected'
                          | 'pending'
                          | undefined = coachSignData
                          ? coachSignData.signed
                            ? 'approved'
                            : coachSignData.resigned
                            ? 'rejected'
                            : 'pending'
                          : undefined;

                        return (
                          <div
                            className={styles.paymentPlanOption}
                            key={coach._id}
                          >
                            <div className={styles.coachAvatarContainer}>
                              <OtherUserProfileImg
                                avatar={coach.avatar}
                                title={coach.name}
                              />
                            </div>
                            <span>{coach.name}</span>
                            {(!seeProgramLocalStore.cocoachesAreSigned ||
                              !seeProgramLocalStore.lastEditionIsSigned) &&
                            ((seeProgramLocalStore.lastEditionIsSigned &&
                              coach._id !==
                                seeProgramLocalStore.program!.user_id) ||
                              !seeProgramLocalStore.lastEditionIsSigned) &&
                            status ? (
                              <>
                                <p
                                  className={classNames(
                                    styles.coachStatus,
                                    status && styles[status],
                                  )}
                                >
                                  {t([I18N_SCOPE, `cocoach_${status}_label`])}
                                </p>
                                {priceForCoach ? (
                                  <p
                                    className={classNames(
                                      styles.coachPrice,
                                      status && styles[status],
                                    )}
                                  >
                                    {priceForCoach}
                                  </p>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div />
                </div>
                <div>
                  <div className={styles.menu}>
                    {localStore.itemMenu.map(({type, name}) => (
                      <a
                        className={`customTopMenu ${styles.menuItem} ${
                          localStore.currentItemMenu === type
                            ? `selected ${styles.selected}`
                            : ''
                        }`}
                        key={type}
                        onClick={handleOnClickMenuItem(type)}
                      >
                        <div className={styles.menuItemContent}>
                          {name}
                          {type === 'requests' &&
                          seeProgramLocalStore!.requestsCount > 0 ? (
                            <div className={styles.counter}>
                              {seeProgramLocalStore!.requestsCount}
                            </div>
                          ) : null}
                          {type === 'invites' &&
                          seeProgramLocalStore!.invitesCount > 0 ? (
                            <div className={styles.counter}>
                              {seeProgramLocalStore!.invitesCount}
                            </div>
                          ) : null}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
                <hr className="first" />
                <div>
                  {localStore.currentItemMenu === itemMenu[0].type && (
                    <SquadsTab />
                  )}
                  {localStore.currentItemMenu === itemMenu[1].type && (
                    <RequestsTab />
                  )}
                  {localStore.currentItemMenu === itemMenu[2].type && (
                    <InvitesTab />
                  )}
                  {localStore.currentItemMenu === itemMenu[3].type && (
                    <DescriptionTab />
                  )}
                  {localStore.currentItemMenu === itemMenu[4].type && (
                    <SquadsTabClient />
                  )}
                </div>
              </div>
              <JoinProgramModal
                isOpen={joinModalIsOpen}
                onAfterClose={hideJoinProgramModal}
                // @ts-ignore
                program={toJS(seeProgramLocalStore.program)}
                onSuccess={onJoinSuccess}
              />
            </>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(SeeProgram);
