import type {FC} from 'react';
import React, {memo, useCallback, useContext, useRef, useState} from 'react';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

interface Props {
  steps: ((props: any) => JSX.Element)[];
}

const OnboardingSteps: FC<Props> = ({steps}) => {
  const dataByIndex = useRef({}).current;
  const dataByName = useRef({}).current;
  const [index, setIndex] = useState(0);
  const [isRequest, setIsRequest] = useState(false);
  const history = useRef<number[]>([]).current;
  const currentFieldName = useRef('');

  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const StepFn = props => steps[index](props);

  const onBack = useCallback(() => {
    setIndex(history[history.length - 1]);
    history.pop();
  }, [history]);

  const onContinue = useCallback(
    (fieldName?: string) => {
      const value = dataByIndex[index];

      fieldName = fieldName || currentFieldName.current;

      if (value && fieldName[0] !== '_') {
        var params = value;

        if (typeof value === 'string') {
          params = {
            [fieldName]: value,
          };
        } else {
          if (value._id) {
            // for avatar
            params = {
              [fieldName]: value._id,
            };
          }
        }

        setIsRequest(true);

        currentUserStore
          ?.update(params)
          .then(() => {
            history.push(index);
            setIndex(index + 1);
          })
          .catch(e => {
            getCustomConfirmAlert({
              message: e.message,
              buttons: [
                {
                  label: t('shared.button.ok'),
                  onClick: () => {},
                },
              ],
            });
          })
          .finally(() => {
            setIsRequest(false);
          });
      } else {
        history.push(index);
        setIndex(index + 1);
      }
    },
    [currentUserStore, dataByIndex, history, index],
  );

  const onSkip = useCallback(() => {
    history.push(index);
    setIndex(index + 2);
  }, [history, index]);

  const onChange = useCallback(
    (fieldName, value, clearNext) => {
      currentFieldName.current = fieldName;
      dataByIndex[index] = value;

      if (clearNext && dataByIndex[index + 1]) {
        Object.keys(dataByIndex[index + 1]).forEach(k => {
          delete dataByName[k];
        });
        delete dataByIndex[index + 1];
      }

      dataByName[fieldName] = value;
    },
    [dataByIndex, dataByName, index],
  );

  return (
    <StepFn
      data={dataByName}
      progress={Math.round((index * 8) / steps.length)}
      total={8}
      isRequest={isRequest}
      onChange={onChange}
      onBack={index > 0 ? onBack : undefined}
      onContinue={index < steps.length - 1 ? onContinue : undefined}
      onSkip={onSkip}
      initialValue={dataByIndex[index]}
    />
  );
};

export default memo(OnboardingSteps);
