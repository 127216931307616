import React from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';
import styled from 'styled-components';

import DangerIcon from '@yourcoach/shared/assets/icons/primary/Danger.svg';
import VideoIcon from '@yourcoach/shared/assets/icons/primary/Video.svg';
import {alert} from '@yourcoach/shared/modules/alert';
import type {
  ConferenceModel,
  ConferenceStrategy,
  TaskStrategy,
} from '@yourcoach/shared/stores/single-event';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {IconButton} from '@src/v2/components/IconButton';
import {PathBuilderService} from '@src/v2/services/PathBuilderService';

import {I18N_SINGLE_EVENT} from '../../utils';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 38px;
  right: 48px;
  width: 200px;
  background: #f24c00;

  &:hover {
    background: #ef6e32;
  }

  @media (min-width: 320px) and (max-width: 950px) {
    position: static;
    width: 100%;
  }
`;

export const ControlsContainer: React.FC<{
  store: ConferenceStrategy | TaskStrategy;
}> = observer(({store}) => {
  const onEditHandler = React.useCallback(() => {
    store.isEditOpenModal.toggle();
  }, [store]);

  return store.initialLoad.isReady ? (
    <View>
      {store.event.isConference && (
        <ConferenceControlsContainer
          event={store.event as ConferenceModel}
          onEditHandler={onEditHandler}
          startButtonLabel={(store as ConferenceStrategy).startButtonLabel}
        />
      )}
    </View>
  ) : null;
});

const ConferenceControlsContainer: React.FC<{
  event: ConferenceModel;
  startButtonLabel: string;
  onEditHandler: () => any;
}> = observer(({event, startButtonLabel, onEditHandler}) => {
  const history = useHistory();
  const onStartHandler = React.useCallback(async () => {
    const confirm = await alert.confirm({
      title: t(['Conference', 'start_conference_question']),
    });

    if (confirm) {
      history.push(PathBuilderService.toConference(event.id));
    }
  }, [history, event]);

  const onFixConflictClickHandler = React.useCallback(() => {
    onEditHandler();
  }, [onEditHandler]);

  return (
    <React.Fragment>
      {event.isConflicting ? (
        <IconButton
          icon={<DangerIcon />}
          scheme={'error'}
          onClick={onFixConflictClickHandler}
        >
          <Text color={'inherit'}>
            {t([I18N_SINGLE_EVENT, 'button', 'fix_conflict'])}
          </Text>
        </IconButton>
      ) : (
        event.isShowStart && (
          <StyledIconButton
            icon={<VideoIcon />}
            scheme={'secondary'}
            onClick={onStartHandler}
            disabled={!event.isStartEnabled}
          >
            <Text color={'inherit'}>{startButtonLabel}</Text>
          </StyledIconButton>
        )
      )}
    </React.Fragment>
  );
});
