import styled, {css} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

import List from '@src/components/List';

import {ListItem} from './Item';

export const Container = styled.div<{active?: boolean}>`
  display: none;
  margin-top: 10px;
  height: 100%;

  ${p =>
    p.active &&
    css`
      display: initial;
    `}
`;

const SListItem = styled(ListItem)`
  border-top: 1px solid ${getColor('border2')};
`;

const SList = styled(List)`
  height: 100%;

  ${SListItem}:first-child {
    border-top: 0;
  }
`;

export {SList as List, SListItem as ListItem};

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
