import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';

import classNames from 'classnames';
import {toJS} from 'mobx';

import {
  FULL_PORTION,
  parseDuration,
  parsePaymentPlan,
} from '@yourcoach/shared/api/program';
import type {CoCoachFields} from '@yourcoach/shared/api/program/utils/co-coaching';
import {
  distributePortions,
  fillPortionsToFull,
} from '@yourcoach/shared/api/program/utils/co-coaching';
import CloneProgramIcon from '@yourcoach/shared/assets/icons/clone-program.svg';
import UserGroupPlusIcon from '@yourcoach/shared/assets/icons/user-group-plus.svg';
import {getPriceString} from '@yourcoach/shared/utils/money';

import Button from '@src/components/Button';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import SelectCoachModal from '@src/modules/Coaches/SelectCoachModal';

import type {ProgramT} from '.';
import styles from './CocoachingManager.module.css';
import CocoachingManagerListItem from './CocoachingManagerListItem';

export const I18N_SCOPE = 'shared.CocoachingManager';

const COACH_CONTEXT_MENU_ID = 'coach_context_menu';

type Props = {
  program: ProgramT;
  cloneProgram: () => void;
  userIsOwner: boolean;
  shouldCloneProgram: boolean;
  className?: string;
};

export type Coach = ProgramT['expanded_coaches'][0] & CoCoachFields;

export type Ref = {
  getData: () => {
    coaches: ProgramT['coaches'];
    expandedCoaches: ProgramT['expanded_coaches'];
    isntProviderCoaches: ProgramT['expanded_coaches'];
  };
  removeIsntProviderCoaches: () => void;
};

const CocoachingManager = React.forwardRef<Ref, Props>(
  (
    {program, userIsOwner, shouldCloneProgram, cloneProgram, className},
    ref,
  ) => {
    const {
      stores: {currentUserStore},
    } = useContext(AppContext);

    const [coaches, setCoaches] = useState<Coach[]>([]);

    const [addCoachModalIsOpen, showAddCoachModal, hideAddCoachModal] =
      useIsVisible();

    const lastEdition = useMemo(
      () => program.editions[program.editions.length - 1],
      [program.editions],
    );

    const parsedDuration = useMemo(
      () => parseDuration(lastEdition!.duration),
      [lastEdition],
    );

    const parsedPaymentPlan = useMemo(
      () => parsePaymentPlan(lastEdition!.payment_plan),
      [lastEdition],
    );

    const isEqualPortions = useMemo(() => {
      if (!parsedPaymentPlan.total) {
        return true;
      }

      return coaches.every(
        coach => coach.portion === FULL_PORTION / coaches.length,
      );
    }, [coaches, parsedPaymentPlan.total]);

    const ownerId = useMemo(
      () => program.user_id || currentUserStore.user!._id,
      [currentUserStore.user, program.user_id],
    );

    const isReady = useRef(false);

    useEffect(() => {
      const defaultCoach = toJS(currentUserStore.user!);

      setCoaches(() => {
        setTimeout(() => {
          isReady.current = true;
        }, 5 * 16);

        return (
          JSON.parse(
            JSON.stringify(program.expanded_coaches || [defaultCoach]),
          ) as ProgramT['expanded_coaches']
        )
          .map(expandedCoach => {
            const coach = {...expandedCoach} as Coach;

            const {total, parsedCurrency} = parsedPaymentPlan;

            const {portion} = (program.coaches || {
              [defaultCoach._id]: {
                portion: FULL_PORTION,
              },
            })[coach._id!] || {
              portion: 0,
            };

            let price = Math.round(total * (portion / FULL_PORTION));

            coach.is_owner = coach._id === ownerId;
            coach.portion = portion;
            coach.price = getPriceString({
              price,
              intZeroPrice: true,
              currency: parsedCurrency,
            });

            return coach;
          })
          .sort((a, b) => {
            return a.is_owner === b.is_owner ? 0 : a.is_owner ? -1 : 1;
          });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetPortions = useCallback(() => {
      const {total, parsedCurrency} = parsedPaymentPlan;

      const portion = FULL_PORTION / coaches.length;
      const price = getPriceString({
        price: Math.round(total * (portion / FULL_PORTION)),
        intZeroPrice: true,
        currency: parsedCurrency,
      });

      setCoaches(prev =>
        prev.map(coach => ({
          ...coach,
          portion,
          price,
        })),
      );
    }, [coaches.length, parsedPaymentPlan]);

    useEffect(() => {
      if (isReady.current && coaches.length && !shouldCloneProgram) {
        resetPortions();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coaches.length]);

    const onAddCoachButtonClick = useCallback(() => {
      showAddCoachModal();
    }, [showAddCoachModal]);

    const onAddCoaches = useCallback(
      (addedCoaches: Coach[]) => {
        hideAddCoachModal();
        setCoaches(prev => {
          const newCoaches = [...prev];

          addedCoaches.forEach(addedCoach => {
            if (!newCoaches.find(coach => coach._id === addedCoach._id)) {
              newCoaches.push(addedCoach);
            }
          });

          return newCoaches;
        });
      },
      [hideAddCoachModal],
    );

    const deleteCoach = useCallback((coach: Coach) => {
      setCoaches(prev => {
        const index = prev.findIndex(item => item._id === coach._id);

        if (index >= 0) {
          prev.splice(index, 1);
        }

        return [...prev];
      });
    }, []);

    const onDeleteCoachButtonClick = useCallback(
      ({props}: ItemParams<{coach: Coach}>) => {
        if (props) {
          const {coach} = props;

          deleteCoach(coach);
        }
      },
      [deleteCoach],
    );

    const onCoachMoreButtonClick = useCallback(
      (coach: Coach, event: React.MouseEvent<Element, MouseEvent>) => {
        contextMenu.show({
          id: COACH_CONTEXT_MENU_ID,
          event,
          props: {
            coach,
          },
        });
      },
      [],
    );

    const ContextMenu = useCallback(
      () => (
        <Menu
          id={COACH_CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          <Item
            onClick={onDeleteCoachButtonClick}
            className={classNames('contextMenuItem', 'danger')}
          >
            {t([I18N_SCOPE, 'more_delete_button'])}
          </Item>
        </Menu>
      ),
      [onDeleteCoachButtonClick],
    );

    const getData = useCallback(() => {
      const coachesData: ProgramT['coaches'] = {};

      const expandedCoaches = coaches.map(coach => {
        const copy = {...coach};

        // cleanup
        // @ts-ignore
        delete copy.is_owner;
        // @ts-ignore
        delete copy.portion;
        // @ts-ignore
        delete copy.price;

        return copy;
      });

      const isntProviderCoaches: Coach[] = [];

      const portions = fillPortionsToFull(
        coaches.map(coach => ({...coach, portion: Math.round(coach.portion)})),
      ).map(coach => coach.portion);

      coaches.forEach((coach, i) => {
        if (coach._id) {
          coachesData[coach._id] = {
            ...(program.coaches || {})[coach._id],
            role: 'Coach',
            portion: portions[i],
          };

          if (
            parsedPaymentPlan.total &&
            parsedPaymentPlan.processing === 'internal' &&
            !coach.is_provider
          ) {
            isntProviderCoaches.push(coach);
          }
        }
      });

      return {
        coaches: coachesData,
        expandedCoaches,
        isntProviderCoaches,
      };
    }, [
      coaches,
      parsedPaymentPlan.processing,
      parsedPaymentPlan.total,
      program,
    ]);

    const removeIsntProviderCoaches = useCallback(() => {
      const {isntProviderCoaches} = getData();

      isntProviderCoaches.forEach(coach => {
        deleteCoach(coach);
      });

      if (isntProviderCoaches.length) {
        resetPortions();
      }
    }, [deleteCoach, getData, resetPortions]);

    useImperativeHandle(ref, () => ({
      getData,
      removeIsntProviderCoaches,
    }));

    const onCoachPriceChange = useCallback(
      (text: string, coach: Coach) => {
        const {total, parsedCurrency} = parsedPaymentPlan;

        const rawPrice = parseFloat(
          text
            .replace(/[^\d., d]/g, '')
            .replace(parsedCurrency.sign, '')
            .replace(',', '.'),
        );

        const price = ((rawPrice || 0) * 1000) / 10;

        let priceText = text.replace(parsedCurrency.sign, '');

        if (priceText === '.' || priceText === ',') {
          priceText = `${parsedCurrency.sign}0.`;
        } else if (priceText === '0' || priceText === '') {
          priceText = `${parsedCurrency.sign}0`;
        } else if (priceText.includes('.') || priceText.includes(',')) {
          let point = '.';

          if (priceText.includes(',')) {
            point = ',';
          }

          const tmp = priceText.split(point);

          priceText = `${parsedCurrency.sign}${tmp[0]}${point}${tmp[1].slice(
            0,
            2,
          )}`;
        } else {
          priceText = `${parsedCurrency.sign}${rawPrice}`;
        }

        // current coach portion
        const portion =
          price <= total ? (price / total) * FULL_PORTION : FULL_PORTION;

        setCoaches(prev =>
          distributePortions<Coach>({
            portion,
            coach,
            coaches: prev,
            price: priceText,
            parsedPaymentPlan,
          }),
        );
      },
      [parsedPaymentPlan],
    );

    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.titleContainer}>
          <div className="flex1">
            <h3 className={styles.title}>
              {t([I18N_SCOPE, 'cocoaching_label'])}
            </h3>
            {!shouldCloneProgram && userIsOwner ? (
              <p className={styles.message}>
                {t([I18N_SCOPE, 'description_label'])}
              </p>
            ) : null}
          </div>
          <div className={styles.titleButtonsContainer}>
            {!isEqualPortions && userIsOwner && !shouldCloneProgram ? (
              <Button onClick={resetPortions} className={styles.resetButton}>
                {t([I18N_SCOPE, 'reset_button'])}
              </Button>
            ) : null}
            {!shouldCloneProgram && userIsOwner ? (
              <>
                <Button
                  onClick={onAddCoachButtonClick}
                  className={styles.addButton}
                >
                  <UserGroupPlusIcon />
                  {t([I18N_SCOPE, 'add_coach_button'])}
                </Button>
                <SelectCoachModal
                  title={t([I18N_SCOPE, 'add_coach_modal_title'])}
                  isOpen={addCoachModalIsOpen}
                  onAfterClose={hideAddCoachModal}
                  canAcceptPayments={
                    !!(
                      parsedPaymentPlan.total &&
                      parsedPaymentPlan.processing === 'internal'
                    )
                  }
                  // @ts-ignore
                  onSelect={onAddCoaches}
                />
              </>
            ) : null}
          </div>
        </div>
        <div className={styles.list}>
          {coaches.map(coach => {
            const {total, processing} = parsedPaymentPlan;

            let ableToDelete = userIsOwner && !shouldCloneProgram;

            if (
              ownerId === coach._id ||
              currentUserStore.user!._id === coach._id
            ) {
              ableToDelete = false;
            }

            let role: 'you' | 'owner' | 'cocoach';
            let status: 'approved' | 'rejected' | 'pending' | undefined;

            if (coach.is_owner) {
              role = userIsOwner ? 'you' : 'owner';
            } else if (currentUserStore.user!._id === coach._id) {
              role = 'you';
            } else {
              role = 'cocoach';
            }

            if (
              coach._id &&
              !coach.is_owner &&
              program.coaches &&
              program.coaches[coach._id] &&
              program.status === 'draft'
            ) {
              if (program.coaches[coach._id].signed) {
                status = 'approved';
              } else if (program.coaches[coach._id].resigned) {
                status = 'rejected';
              } else {
                status = 'pending';
              }
            }

            const cantAcceptPayments = !!(
              total &&
              processing === 'internal' &&
              !coach.is_provider
            );

            return (
              <CocoachingManagerListItem
                key={coach._id}
                coach={coach}
                role={role}
                status={status}
                parsedDuration={parsedDuration}
                parsedPaymentPlan={parsedPaymentPlan}
                cantAcceptPayments={cantAcceptPayments}
                priceIsEditable={
                  userIsOwner && !shouldCloneProgram && !cantAcceptPayments
                }
                onMoreButtonClick={
                  ableToDelete ? onCoachMoreButtonClick : undefined
                }
                showMoreButton={userIsOwner && !shouldCloneProgram}
                onPriceChange={
                  total && coaches.length > 1 ? onCoachPriceChange : undefined
                }
              />
            );
          })}
        </div>
        {shouldCloneProgram && userIsOwner ? (
          <div className={styles.cloneContainer}>
            <div className={styles.message}>
              {t([I18N_SCOPE, 'edit_label'])}
            </div>
            <Button onClick={cloneProgram} className={styles.cloneButton}>
              <CloneProgramIcon />
              {t([I18N_SCOPE, 'clone_program_button'])}
            </Button>
          </div>
        ) : null}
        <ContextMenu />
      </div>
    );
  },
);

export default CocoachingManager;
