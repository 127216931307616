import type {FC} from 'react';
import React, {memo, useMemo} from 'react';
import {DayPicker} from 'react-dates';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable} from 'mobx';
import moment from 'moment';

import {labelSelectStartDate} from '@src/common/i18n/i18nPrograms';
import {CustomButton} from '@src/components/CustomForm';
import {IconNext, IconPrev} from '@src/components/icons';

import styles from './../../styles.module.scss';

interface ILocalStore {
  selectDate: Date;
  setSelectDate(selectDate: Date): void;
  isButDisable: boolean;
  setIsButDisable(isButDisable: boolean): void;
}

interface Props {
  date: Date;
  minDate: Date;
  onDateSelect: (date: Date) => void;
}

const RangeDate: FC<Props> = ({date, minDate: minDateOut, onDateSelect}) => {
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          selectDate: date
            ? dayjs(date).toDate()
            : dayjs().add(1, 'day').toDate(),
          setSelectDate(selectDate: Date) {
            this.selectDate = selectDate;
          },
          isButDisable: false,
          setIsButDisable(isButDisable: boolean) {
            this.isButDisable = isButDisable;
          },
        },
        {
          setSelectDate: action,
          selectDate: observable,
          isButDisable: observable,
          setIsButDisable: action,
        },
      ),
    [date],
  );

  const handleSelectDate = (selectDate: Date) => {
    if (isDif(selectDate)) {
      localStore.setSelectDate(selectDate);
    }
  };

  const handleGetDate = async () => {
    onDateSelect(localStore.selectDate);
  };

  const isDif = selectDate => {
    const minDate = minDateOut
      ? dayjs(minDateOut).add(1, 'day').toDate()
      : dayjs().add(1, 'day').toDate();

    const dif = dayjs(selectDate).diff(minDate, 'day') >= 0;

    return dif;
  };

  return (
    <Observer>
      {() => (
        <div className="modalCalendar">
          <h4 className={styles.h3}>{labelSelectStartDate()}:</h4>
          <DayPicker
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            noBorder
            navPrev={<IconPrev height="14" />}
            navNext={<IconNext height="14" />}
            renderWeekHeaderElement={dayName => {
              return <>{dayName}</>;
            }}
            onDayClick={params => {
              const retDate = params.toDate() as Date;
              const m = moment(retDate, 'ddd MMM D YYYY HH:mm:ss ZZ');

              m.set({
                h: dayjs().hour(),
                m: dayjs().minute(),
                s: dayjs().second(),
              });
              handleSelectDate(m.toDate());
            }}
            firstDayOfWeek={1}
            initialVisibleMonth={() => moment().add(1, 'days')}
            renderDayContents={day => {
              const m = moment(day, 'ddd MMM D YYYY HH:mm:ss ZZ');

              m.set({
                h: dayjs().hour(),
                m: dayjs().minute(),
                s: dayjs().second(),
              });

              return (
                <Observer>
                  {() => {
                    const dif = isDif(m.toDate());

                    const selectDay = moment(
                      dayjs(localStore.selectDate).toDate(),
                    );
                    const isSelectDay =
                      selectDay.format('YYYY-MM-DD') ===
                      day.format('YYYY-MM-DD');

                    const dayWeek = day.day() % 7;
                    const isWeekend = dayWeek === 6 || dayWeek === 0;

                    return (
                      <div
                        className={`particularDay ${
                          isSelectDay ? 'today' : 'otherDay'
                        } ${isWeekend ? 'weekend' : ''} particularDay ${
                          dif ? '' : 'blocked'
                        }`}
                      >
                        {day.format('D')}
                      </div>
                    );
                  }}
                </Observer>
              );
            }}
          />
          <CustomButton
            disabled={localStore.isButDisable}
            type="button"
            classButton={`QuestionnaireContinueBut allWidth ${styles.greenBack}`}
            onClick={handleGetDate}
          >
            <span>Select</span>
          </CustomButton>
        </div>
      )}
    </Observer>
  );
};

export default memo(RangeDate);
