import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {apiRequest, isEntityId} from '@yourcoach/shared/api';
import type {
  CoachProgramsReadParams,
  PublicProgramsReadParams,
} from '@yourcoach/shared/api/types/methods';

import {setPathBreadcrumbs} from '@src/common/localStorageApp';
import AppContext from '@src/context/App';
import ThreeColumns from '@src/layouts/ThreeColumns/ThreeColumns';
import {expand as programExpand} from '@src/models/program';
import SeeProgramState from '@src/modules/SeeProgram/context/SeeProgramState';
import type {ProgramT} from '@src/modules/SeeProgram/context/useSeeProgramLocalStore';
import SeeProgram from '@src/modules/SeeProgram/SeeProgram';
import {slugify} from '@src/routes/utils';

interface Props {}

const programExpandWithCourses = JSON.parse(JSON.stringify(programExpand));
const publicProgramExpandWithCourses = JSON.parse(
  JSON.stringify(programExpand),
);

const editionsExpandIndex = programExpandWithCourses.program.findIndex(
  item => Array.isArray(item) && item[0] === 'edition_ids',
);

programExpandWithCourses.program[editionsExpandIndex][2].edition.push(
  'course_ids',
);

publicProgramExpandWithCourses.program[editionsExpandIndex][2].edition = [
  'course_ids',
];

const ProgramPage: FC<Props> = () => {
  const {
    stores: {programStore, currentUserStore},
  } = useContext(AppContext);
  const {
    slugPractice,
    slugProgram,
  }: {slugPractice: string; slugProgram: string} = useParams();
  const location = useLocation();
  const [programId, setProgramId] = useState<string | null>(null);
  const [coachId, setCoachId] = useState<string>('');

  const tab =
    // @ts-ignore
    location.state && location.state.tab
      ? // @ts-ignore
        (location.state.tab as string)
      : null;

  useEffect(() => {
    const fetch = async () => {
      setProgramId(null);

      const {pathname} = location;

      let localCoachId = currentUserStore.user?._id;
      let labelPractice = slugify(currentUserStore.user?.coach_title || '');

      try {
        // let params: ApiRpcRequestParams = {};
        let publicReadParams: PublicProgramsReadParams =
          {} as unknown as PublicProgramsReadParams; // хуйня. заменить на билдер параметров

        if (isEntityId(slugProgram, 'program')) {
          publicReadParams._id = slugProgram;
        } else {
          publicReadParams.slug = slugProgram;
        }

        if (publicReadParams.slug || !currentUserStore.user) {
          if (isEntityId(slugPractice, 'user')) {
            publicReadParams.coach_id = slugPractice;

            localCoachId = slugPractice;
            labelPractice = slugPractice;
          } else {
            const result = await apiRequest({
              method: 'public.profile.read',
              params: {slug: slugPractice},
              options: {withAuth: false},
            });

            publicReadParams.coach_id = result.user._id;

            localCoachId = result.user._id;

            labelPractice = slugify(result.user.coach_title);
          }
        }

        setCoachId(localCoachId!);

        const pathnamePractice: string = `/coaches/${slugPractice}`;

        await setPathBreadcrumbs({
          label: labelPractice,
          pathname: pathnamePractice,
          search: '',
        });

        let program: ProgramT | null = null;

        if (currentUserStore.user) {
          publicReadParams.expand = programExpandWithCourses;

          try {
            program = (await programStore.client.fetch(
              publicReadParams,
            )) as ProgramT;
          } catch (clientFetchError) {
            const {_id, slug, expand} = publicReadParams;
            const coachReadParams: CoachProgramsReadParams = {
              _id,
              slug,
              expand,
            };

            program = (await programStore.coach.fetch(
              coachReadParams,
            )) as ProgramT;
          }
        } else {
          publicReadParams.expand = publicProgramExpandWithCourses;

          program = (await programStore.public.fetch(publicReadParams, {
            withAuth: false,
          })) as ProgramT;
        }

        setProgramId(program._id);
        await setPathBreadcrumbs({label: program.title, pathname, search: ''});
      } catch (error) {}
    };

    fetch();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, slugPractice, slugProgram]);

  return (
    <div
      className="ProgramPage"
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        flexGrow: 1,
        flex: 1,
        display: 'flex',
      }}
    >
      <ThreeColumns thirdColClassName="greyColumn">
        <SeeProgramState>
          {programId ? (
            <SeeProgram programId={programId} tab={tab} coachId={coachId} />
          ) : null}
        </SeeProgramState>
      </ThreeColumns>
    </div>
  );
};

export default memo(ProgramPage);
