import type {Entity} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {User as IUser, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

export type User = IUser & {
  avatar?: IFile | null;
};

export type Follower = Entity & {
  client_id: string;
  client: User | (Partial<User> & Required<Pick<User, 'name'>>);
};

export interface IUserFollower {
  follower: Follower;
  isAdded: boolean;
}

export const expand = {
  questionnaire: ['avatar_id'],
};

export interface Expanded {
  avatar?: IFile | null;
}

export const userQuestionnaireExpand = {
  user_questionnaire: [
    'avatar_id',
    [
      'coach_ids->expanded_coaches',
      {coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

export type ExpandedCoach = (
  | User
  | (Partial<User> & Required<Pick<User, 'coach_title'>>)
) &
  UserExpanded;

export interface UserQuestionnaireExpanded {
  avatar?: IFile | null;
  expanded_coaches: ExpandedCoach[];
}
