import type {FC} from 'react';
import React, {useCallback, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import type {IFile} from '@yourcoach/shared/api/media/file';
import type {User} from '@yourcoach/shared/api/user';

import {
  labelChatsTitle,
  labelChatsTypeTogDirectMess,
  labelChatsTypeTogSquads,
} from '@src/common/i18n/i18nChannel';
import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {expand as channelExpand} from '@src/models/channels';

import ChatsContext from '../context/ChatsContext';
import type {
  ChannelT,
  IChatsLocalStore,
  toggleTypeList,
} from '../context/useChatsLocalStore';
import {idTypeList} from '../context/useChatsLocalStore';

import styles from './../styles.module.scss';
import ChatsList from './ChatsList/ChatsList';
import ChatsListContainerMenu from './ChatsListContainerMenu/ChatsListContainerMenu';

interface IHistoryGet {
  pathname: string;
  search?: string;
  state?: {
    channel?: ChannelT;
    isDM?: boolean;
    postId?: string;
    withUser?: User & {
      avatar?: IFile | null;
    };
    tab?: string | null;
  };
}

interface Props {
  expandedMode?: boolean;
}

const ChatsListContainer: FC<Props> = ({expandedMode}) => {
  const {
    stores: {currentUserStore, channelStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  const handleOnClickTypeTogList = (typeId: toggleTypeList) => {
    return () => {
      chatsLocalStore?.setSelectTypeList(typeId);
    };
  };

  const location: IHistoryGet = useLocation();

  const _checkChannelWithUserExists = useCallback(async () => {
    const withUser = location.state!.withUser;

    try {
      if (withUser!._id) {
        const channel = (await channelStore.create({
          user_ids: [...withUser!._id, user!._id],
          expand: channelExpand,
        })) as ChannelT;

        chatsLocalStore!.setSelectChannelIdUrl(channel._id);
        chatsLocalStore?.setSelectTypeList(idTypeList[1]);
        chatsLocalStore!.setSelectChannel(channel);
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  }, [channelStore, chatsLocalStore, location.state, user]);

  useEffect(() => {
    if (location.state && location.state.isDM) {
      chatsLocalStore?.setSelectTypeList('dms');
    }

    if (location.search) {
      const arr = location.search.split('=');
      const canId = arr[1];

      chatsLocalStore!.setSelectChannelIdUrl(canId);
    }

    if (location.state && location.state.withUser) {
      chatsLocalStore!.setWithUser({...location.state.withUser});
      _checkChannelWithUserExists();
    }

    if (location.state && location.state.postId) {
      chatsLocalStore!.setPostId(location.state.postId);
    }

    if (location.state && location.state.tab) {
      chatsLocalStore!.setInMainTabSelect(location.state.tab);
    }
  }, [location, chatsLocalStore, _checkChannelWithUserExists]);

  return (
    <div
      className={`ChatsListContainer percent100H ${styles.ChatsListContainer}`}
    >
      <h1>{labelChatsTitle()}</h1>
      {!expandedMode && <ChatsListContainerMenu />}
      <div
        className={
          !expandedMode
            ? styles.toggleTypeListContainer
            : styles.toggleTypeListVerticalContainer
        }
      >
        {chatsLocalStore && chatsLocalStore.tabBarBadge && (
          <div
            className={`${styles.toggleTypeListItem} ${
              chatsLocalStore?.selectTypeList === idTypeList[0]
                ? styles.activeListItem
                : null
            }`}
            onClick={handleOnClickTypeTogList(idTypeList[0])}
          >
            <span>{labelChatsTypeTogSquads()}</span>
            {chatsLocalStore!.tabBarBadge.updatesCountActiveChannels <=
            0 ? null : (
              <span className={styles.listItemNumMess}>
                {chatsLocalStore!.tabBarBadge.updatesCountActiveChannels}
              </span>
            )}
          </div>
        )}
        {chatsLocalStore && chatsLocalStore.tabBarBadge && (
          <div
            className={`${styles.toggleTypeListItem} ${
              chatsLocalStore?.selectTypeList === idTypeList[1]
                ? styles.activeListItem
                : null
            }`}
            onClick={handleOnClickTypeTogList(idTypeList[1])}
          >
            <span>{labelChatsTypeTogDirectMess()}</span>
            {chatsLocalStore!.tabBarBadge.updatesCountArchivedChannels <=
            0 ? null : (
              <span className={styles.listItemNumMess}>
                {chatsLocalStore!.tabBarBadge.updatesCountArchivedChannels}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.chatListContainer}>
        {chatsLocalStore?.selectTypeList === idTypeList[0] &&
          (chatsLocalStore.isFetching ? (
            <Loader />
          ) : (
            <ChatsList
              channels={chatsLocalStore?.sections[0]?.data || []}
              expandedMode={expandedMode}
            />
          ))}
        {chatsLocalStore?.selectTypeList === idTypeList[1] &&
          (chatsLocalStore.isFetching ? (
            <Loader />
          ) : (
            <ChatsList
              channels={chatsLocalStore?.sections[0]?.data || []}
              expandedMode={expandedMode}
            />
          ))}
      </div>
    </div>
  );
};

export default observer(ChatsListContainer);
