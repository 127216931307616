import React, {memo, useEffect, useState} from 'react';

import {DEFAULT_NOTE_COLOR} from '@yourcoach/shared/api/sessionNote';

import * as S from './styles';
import type {Props} from './types';

const COLORS = ['note2', 'note5', 'note3', 'note4', 'note1'];

const ColorSelector: React.FC<Props> = ({initialColor, onChange, ...rest}) => {
  const [selectedColor, setSelectedColor] = useState(
    initialColor
      ? initialColor.startsWith('#')
        ? DEFAULT_NOTE_COLOR
        : initialColor
      : DEFAULT_NOTE_COLOR,
  );

  useEffect(() => {
    if (onChange) {
      onChange(selectedColor);
    }
  }, [onChange, selectedColor]);

  return (
    <S.Container {...rest}>
      {COLORS.map(color => (
        <S.Item
          key={color}
          color={color}
          active={color === selectedColor}
          onClick={() => {
            setSelectedColor(color);
          }}
        />
      ))}
    </S.Container>
  );
};

export default memo(ColorSelector);
