import type {FC} from 'react';
import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import Fade from 'react-reveal/Fade';

interface Props {
  children: any;
  isAnimateShow: boolean;
  callBackHide: () => void;
}

const AnimateShow: FC<Props> = ({isAnimateShow, callBackHide, children}) => {
  const [isAnimate, setIsAnimate] = useState(false);

  const idT = useRef(0);

  const toggleVis = useCallback(
    (isShow: boolean) => {
      if (isShow) {
        clearTimeout(idT.current);
        // @ts-ignore
        idT.current = setTimeout(() => {
          setIsAnimate(true);
        }, 300);
      } else {
        setIsAnimate(false);
        clearTimeout(idT.current);
        // @ts-ignore
        idT.current = setTimeout(() => {
          callBackHide();
        }, 300);
      }
    },
    [callBackHide],
  );

  useEffect(() => {
    toggleVis(isAnimateShow);

    return () => {
      if (idT.current) {
        clearTimeout(idT.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimateShow]);

  return (
    <Fade bottom duration={300} when={isAnimate}>
      {children}
    </Fade>
  );
};

export default memo(AnimateShow);
