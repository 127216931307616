import type {FC} from 'react';
import React, {useContext, useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {reaction} from 'mobx';

import {WS_RECEIVE_MESSAGE_EVENT} from '../../components/WS/WS';
import AppContext from '../../context/App';
import {emitter} from '../../utils';

import ChatMainContent from './ChatMainContent/ChatMainContent';
import MainChatsState from './ChatMainContent/context/MainChatsState';
import ChatsListContainer from './ChatsListContainer/ChatsListContainer';
import ChatsContext from './context/ChatsContext';
import type {IChatsLocalStore} from './context/useChatsLocalStore';
import {SUPPORTED_EVENTS} from './context/useChatsLocalStore';
import styles from './styles/styles.module.css';

interface Props {}

const Chats: FC<Props> = () => {
  const {
    stores: {courseStore, channelStore, postStore, eventStore},
  } = useContext(AppContext);
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    // chatsLocalStore!.createChannelsStores();

    const disposeCourseUpdate = reaction(
      () => courseStore.updating,
      updating => {
        if (updating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );

    const disposeCourseDel = reaction(
      () => courseStore.deleting,
      updating => {
        if (updating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeChannelCreate = reaction(
      () => channelStore.creating,
      creating => {
        if (creating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeCannelUpdate = reaction(
      () => channelStore.updating,
      updating => {
        if (updating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeCannelDelete = reaction(
      () => channelStore.deleting,
      deleting => {
        if (deleting.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostCreating = reaction(
      () => postStore.creating,
      creating => {
        if (creating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostUpdate = reaction(
      () => postStore.updating,
      updating => {
        if (updating.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostDelete = reaction(
      () => postStore.deleting,
      deleting => {
        if (deleting.success) {
          // chatsLocalStore!._onRefresh(true);
        }
      },
    );

    // chatsLocalStore!._onRefresh();

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      disposeCourseUpdate();
      disposeCourseDel();
      disposeChannelCreate();
      disposeCannelUpdate();
      disposeCannelDelete();
      disposePostCreating();
      disposePostUpdate();
      disposePostDelete();

      if (chatsLocalStore) {
        // chatsLocalStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (
      [
        'course_started',
        'membership_frozen',
        'membership_unfrozen',
        'post_created',
        'coach_coverage',
        'coach_coverage_course_stopped',
        'coach_coverage_course_started',
      ]
        .concat(SUPPORTED_EVENTS)
        .includes(msg.event.type)
    ) {
      _fetchUnreadEvents();
    }
  };

  const _fetchUnreadEvents = () => {
    eventStore.fetchUnread({
      limit: 500,
    });
  };

  return (
    <div className={`Chats ${styles.Chats}`}>
      <ChatsListContainer />
      <div className={styles.Chat_Main_Container}>
        <MainChatsState>
          <ChatMainContent />
        </MainChatsState>
      </div>
    </div>
  );
};

export default observer(Chats);
