import type {FC} from 'react';
import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';

import ChatsContext from '../../context/ChatsContext';
import type {
  ChannelT,
  IChatsLocalStore,
} from '../../context/useChatsLocalStore';
import styles from '../../styles/styles.module.css';
import ChatsListItem from '../ChatsListItem/ChatsListItem';

interface Props {}

const DirectMessage: FC<Props> = () => {
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  return (
    <div className={`DirectMessage percent100H ${styles.DirectMessage}`}>
      {chatsLocalStore!.sections[0] &&
        chatsLocalStore!.sections[0].data.map((channel: ChannelT) => {
          return (
            <ChatsListItem
              key={channel._id}
              channel={channel}
              showUpdatedDate
              showLastPost
              showCoaches
              badge={channel.updatesCount}
            />
          );
        })}
    </div>
  );
};

export default observer(DirectMessage);
