import type {FC, MutableRefObject} from 'react';
import React, {memo, useCallback, useRef, useState} from 'react';
import ReactPlayer from 'react-player';

import Button from '@src/components/Button';
import {ASSETS_URL} from '@src/config';
import {t} from '@src/i18n';

import LeftPaneLayout from '../../../layouts/LeftPaneLayout/LeftPaneLayout';
import LogoSVG from '../images/logo_large.svg';

import styles from './styles.module.css';

interface Props {
  onClick?: (event) => void;
}

const I18N_SCOPE = 'Login.welcome';

const WelcomeStep: FC<Props> = ({onClick}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<ReactPlayer>() as MutableRefObject<ReactPlayer>;

  const onPlayerReady = useCallback(() => {
    setIsPlaying(true);

    const tryStartVideo = () => {
      // Chrome policy blocks play until user interacts with page
      playerRef?.current
        ?.getInternalPlayer()
        .play()
        .catch(() => {
          setTimeout(tryStartVideo, 500);
        });
    };

    tryStartVideo();
  }, []);

  return (
    <LeftPaneLayout>
      <div className={styles.videoPlayer}>
        <ReactPlayer
          ref={playerRef}
          url={`${ASSETS_URL}/video/intro-w.mp4`}
          width="auto"
          height="100%"
          playing={isPlaying}
          muted={false}
          loop={true}
          onReady={onPlayerReady}
        />
        {isPlaying ? null : (
          <img src={`${ASSETS_URL}/video/intro-w-poster.jpg`} />
        )}
      </div>
      <div className={styles.welcomeStep}>
        <div className={styles.content}>
          <div className={styles.topRow}>{t([I18N_SCOPE, 'title'])}</div>
          <LogoSVG />
          <div className={styles.message}>{t([I18N_SCOPE, 'description'])}</div>
          <div className={styles.buttons}>
            <Button
              data-action="signIn"
              className={`${styles.middle} ${styles.inverted}`}
              onClick={onClick}
            >
              {t([I18N_SCOPE, 'sign_in_button'])}
            </Button>
            <Button
              data-action="signUp"
              className={styles.middle}
              onClick={onClick}
            >
              {t([I18N_SCOPE, 'sign_up_button'])}
            </Button>
          </div>
        </div>
      </div>
    </LeftPaneLayout>
  );
};

export default memo(WelcomeStep);
