import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import {apiRequest, datetimeObjToISOString} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Task as ITask} from '@yourcoach/shared/api/task';
import {taskIsDone} from '@yourcoach/shared/api/task';
import AttachIcon from '@yourcoach/shared/assets/icons/attach.svg';
import CalendarIcon from '@yourcoach/shared/assets/icons/calendar.svg';
import ChatIcon from '@yourcoach/shared/assets/icons/chat.svg';
import CheckIcon0 from '@yourcoach/shared/assets/icons/checkbox-0.svg';
import CheckIcon1 from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import ClockIcon from '@yourcoach/shared/assets/icons/clock.svg';
import CommentIcon from '@yourcoach/shared/assets/icons/comment.svg';
import MealIcon from '@yourcoach/shared/assets/icons/meal.svg';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import {t} from '@src/i18n';
import type {Expanded as TaskExpanded, Meal} from '@src/models/tasks';
import {expand, MEAL_SEPARATOR} from '@src/models/tasks';

const I18N_SCOPE = 'ClientTask';

import {useHistory} from 'react-router-dom';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';

import {emitter} from '../../../../widget/src/utils';
import {PathBuilderService} from '../../../v2/services/PathBuilderService';
import ShowMealPopup from '../ShowMealPopup/ShowMealPopup';
import styles from '../styles.module.css';

type Task = ITask & TaskExpanded;

interface ILocalStore {
  task: Task | null;
  selectMeal: Meal | null;
  setSelectMeal(selectMeal: Meal | null): void;
  isMailPopup: boolean;
  setIsMailPopup(isMailPopup: boolean): void;
}

interface Props {
  taskId: string;
  task?: Task;
  noProgramLabel?: boolean;
  noSquadLabel?: boolean;
}

const ClientTask: FC<Props> = ({
  taskId,
  task,
  noSquadLabel = true,
  noProgramLabel = true,
}) => {
  const history = useHistory();
  const {
    stores: {taskStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        selectMeal: null,
        setSelectMeal(selectMeal: Meal | null) {
          this.selectMeal = selectMeal;
        },
        isMailPopup: false,
        setIsMailPopup(isMailPopup: boolean) {
          this.isMailPopup = isMailPopup;
        },
        task: null,
      },
      {
        task: observable,
        selectMeal: observable,
        setSelectMeal: action,
        isMailPopup: observable,
        setIsMailPopup: action,
      },
    ),
  ).current;

  useEffect(() => {
    if (task) {
      runInAction(() => {
        localStore.task = task;
      });
    } else {
      _fetchTask();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = message => {
    if (
      localStore.task &&
      message.event.type === 'task_done' &&
      localStore.task._id === message.task._id
    ) {
      _fetchTask();
    }
  };

  const _fetchTask = async () => {
    try {
      const NewTask = (await taskStore.coach.fetch({
        _id: taskId,
        expand,
      })) as Task;

      runInAction(() => {
        localStore.task = NewTask;
      });
    } catch (error) {
      if (apiRequest.isCanceledError(error)) {
        return;
      }

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
          },
        ],
      });
    }
  };

  const _onClientInfoButtonPress = () => {
    // TODO: navigate to user profile
  };

  const _onClientChatButtonPress = () => {
    if (
      localStore.task &&
      localStore.task.course &&
      localStore.task.course.edition &&
      localStore.task.course.edition.group_size === 1
    ) {
      history.push({
        pathname: '/chats',
        search: `?cid=${localStore.task.course.channel_ids[0]}`,
      });
    } else {
      history.push({
        pathname: '/chats',
        state: {withUser: {_id: [localStore.task!.user._id]}},
      });
    }
  };

  const _onImageAttachmentPress = (url: string) => () => {
    window.open(url, '_blank');
  };

  const _onMealPress = (meal: Meal) => {
    localStore.setSelectMeal(meal);
    localStore.setIsMailPopup(true);
  };

  const handleClosePopup = () => {
    localStore.setIsMailPopup(false);
  };

  const _onProgramRowPress = () => {
    // const practiceName = localStore.task!.course!.program!.expanded_coaches[0]
    //   .slug
    //   ? localStore.task!.course!.program!.expanded_coaches[0].slug
    //   : localStore.task!.course!.program!.expanded_coaches[0]._id;
    //
    // const programName = localStore.task!.course!.program!.slug
    //   ? localStore.task!.course!.program!.slug
    //   : localStore.task!.course!.program!._id;
    //
    // history.push({
    //   pathname: `/coaches/${practiceName}/programs/${programName}`,
    // });

    const program = localStore.task!.course!.program!;
    const practiceSlug = program.expanded_coaches[0].slug;
    const practiceId = program.expanded_coaches[0]._id;
    const programSlug = program.slug;
    const programId = program._id;

    history.push(
      PathBuilderService.toProgram(
        {slug: practiceSlug, id: practiceId as string},
        {slug: programSlug, id: programId as string},
      ),
    );
  };

  const _onCourseRowPress = () => {
    // const practiceName = localStore.task!.course!.program!.expanded_coaches[0]
    //   .slug
    //   ? localStore.task!.course!.program!.expanded_coaches[0].slug
    //   : localStore.task!.course!.program!.expanded_coaches[0]._id;
    //
    // const programName = localStore.task!.course!.program!.slug
    //   ? localStore.task!.course!.program!.slug
    //   : localStore.task!.course!.program!._id;
    //
    // history.push({
    //   pathname: `/coaches/${practiceName}/programs/${programName}/program`,
    //   search: `?sqid=${localStore.task!.course_id}&pid=${
    //     localStore.task!.program_id
    //   }`,
    // });

    const program = localStore.task!.course!.program!;
    const practiceSlug = program.expanded_coaches[0].slug;
    const practiceId = program.expanded_coaches[0]._id;
    const programSlug = program.slug;
    const programId = program._id;
    const courseId = localStore.task!.course_id;

    history.push(
      PathBuilderService.toCourse(
        {slug: practiceSlug, id: practiceId as string},
        {slug: programSlug, id: programId as string},
        courseId,
        programId,
      ),
    );
  };

  const _renderHeader = () => {
    return (
      <Observer>
        {() => (
          <div className={styles.ClientTaskHeader}>
            <div className={styles.clientRow}>
              <div className={styles.clientButtonInfoContainer}>
                {localStore.task && localStore.task.user ? (
                  <div
                    onClick={_onClientInfoButtonPress}
                    className={styles.clientButtonInfo}
                  >
                    i
                  </div>
                ) : null}
              </div>
              <div className={styles.bigUserAvatar}>
                {localStore.task && localStore.task.user ? (
                  <OtherUserProfileImg
                    avatar={localStore.task.user.avatar}
                    title={localStore.task.user.name}
                    isOverlay={!localStore.task.user.avatar}
                  />
                ) : (
                  <div className={styles.loadingClientAvatar}>
                    <Loader />
                  </div>
                )}
              </div>
              <div className={styles.clientButtonChatContainer}>
                {localStore.task &&
                localStore.task.user &&
                localStore.task.user._id ? (
                  <div
                    onClick={_onClientChatButtonPress}
                    className={styles.clientButtonChat}
                  >
                    <CommentIcon fill={'#ffffff'} width={22} height={18} />
                  </div>
                ) : null}
              </div>
            </div>
            {localStore.task ? (
              <div className={styles.clientName}>
                {(localStore.task.user || {name: '...'}).name}
              </div>
            ) : null}
          </div>
        )}
      </Observer>
    );
  };

  const _renderFooter = () => {
    return (
      <Observer>
        {() => <div className={styles.footerContainerTopGradient} />}
      </Observer>
    );
  };

  return (
    <div className={`ClientTask ${styles.ClientTask}`}>
      {_renderHeader()}
      <Observer>
        {() => {
          if (!localStore.task) {
            return null;
          }

          const hasAttachments =
            localStore.task.attachments.filter(Boolean).length ||
            localStore.task.report.split(MEAL_SEPARATOR).slice(1).length;

          const isDone = taskIsDone(localStore.task);

          const CheckIcon = isDone ? CheckIcon1 : CheckIcon0;

          const program = localStore.task.course
            ? localStore.task.course.program
            : {
                avatar: null,
                title: 'Program not found',
              };

          return (
            <div className={styles.ClientTaskContainer}>
              <div className={styles.taskRowContainer}>
                <div className={styles.taskRowAvatar}>
                  <CheckIcon width={20} height={20} />
                </div>
                <div className={styles.taskNameContainer}>
                  <div className={styles.taskRowLabel}>
                    {t([I18N_SCOPE, 'task_label'])}
                  </div>
                  <div className={styles.taskRowTitle}>
                    {localStore.task.title}
                  </div>
                </div>
              </div>
              <div className={styles.taskRowContainer}>
                <div className={styles.taskRowAvatar}>
                  <ClockIcon width={20} height={20} />
                </div>
                <div className={styles.flex1}>
                  <div className={styles.taskRowLabel}>
                    {t([I18N_SCOPE, 'due_date_label'])}
                  </div>
                  <div className={styles.taskRowTitle}>
                    {formatDate(
                      datetimeObjToISOString(localStore.task!.end_date),
                      {
                        withYTT: false,
                        appendYTT: false,
                        customFormat: 'lll',
                      },
                    )}
                  </div>
                </div>
              </div>
              {!noProgramLabel ? (
                <div
                  className={styles.taskRowContainer}
                  onClick={_onProgramRowPress}
                >
                  <div className={styles.taskRowAvatar}>
                    <OtherUserProfileImg
                      avatar={program.avatar}
                      title={program.title}
                      titleFontSize="14px"
                      isBGGradient
                      getTitleIconIfNoImage
                      isOverlay={!program.avatar}
                    />
                  </div>
                  <div className={styles.flex1}>
                    <div className={styles.taskRowLabel}>
                      {t([I18N_SCOPE, 'program_label'])}
                    </div>
                    <div className={styles.taskRowTitle}>{program.title}</div>
                  </div>
                </div>
              ) : null}
              {!noSquadLabel ? (
                <div
                  className={styles.taskRowContainer}
                  onClick={_onCourseRowPress}
                >
                  <div className={styles.taskRowAvatar}>
                    <CalendarIcon width={20} height={20} />
                  </div>
                  <div className={styles.taskNameContainer}>
                    <div className={styles.taskRowLabel}>
                      {t([I18N_SCOPE, 'course_label'])}
                    </div>
                    <div className={styles.taskRowTitle}>
                      {getCourseDurationString(localStore.task!.course)}
                    </div>
                  </div>
                </div>
              ) : null}
              {localStore.task.report ? (
                <div className={styles.taskRowContainer}>
                  <div className={styles.taskRowAvatar}>
                    <ChatIcon width={20} height={20} />
                  </div>
                  <div className={styles.flex1}>
                    <div className={styles.taskRowLabel}>
                      {t([I18N_SCOPE, 'comment_label'])}
                    </div>
                    <div className={styles.taskDescription}>
                      {localStore.task!.report.split(MEAL_SEPARATOR)[0]}
                    </div>
                  </div>
                </div>
              ) : null}
              {hasAttachments ? (
                <div className={styles.taskRowContainer}>
                  <div className={styles.taskRowAvatar}>
                    <AttachIcon width={20} height={20} />
                  </div>
                  <div className={styles.flex1}>
                    <div className={styles.taskRowLabel}>
                      {t([I18N_SCOPE, 'attachments_label'])}
                    </div>
                    <div className={styles.attachmentsContainer}>
                      {localStore
                        .task!.attachments.filter(Boolean)
                        .map(attachment => (
                          <div
                            key={attachment!._id}
                            onClick={_onImageAttachmentPress(
                              attachment?.src.original.url!,
                            )}
                            className={styles.taskRowAvatar}
                          >
                            <OtherUserProfileImg
                              avatar={attachment}
                              titleFontSize="14px"
                              isOverlay={!attachment}
                              className={styles.className}
                              profileClassName={styles.profileClassName}
                            />
                          </div>
                        ))}
                      {localStore
                        .task!.report.split(MEAL_SEPARATOR)
                        .slice(1)
                        .map(mealStr => {
                          const meal = JSON.parse(mealStr);

                          return (
                            <div
                              key={mealStr}
                              onClick={() => _onMealPress(meal)}
                              className={styles.mealAttachmentContainer}
                            >
                              <div
                                className={`${styles.attachment} ${styles.mealAttachment}`}
                              >
                                <MealIcon
                                  width={24}
                                  height={24}
                                  className={styles.attachmentIcon}
                                />
                                <div className={styles.attachmentText}>
                                  {formatDate(meal.date, {
                                    useUTC: true,
                                    customFormat: 'LT',
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ) : null}
              {localStore.selectMeal ? (
                <ShowMealPopup
                  isOpen={localStore.isMailPopup}
                  handleClose={handleClosePopup}
                  meal={localStore.selectMeal}
                />
              ) : null}
            </div>
          );
        }}
      </Observer>
      {_renderFooter()}
    </div>
  );
};

export default memo(ClientTask);
