import {
  labelFieldsWithAnAsteriskAreRequired,
  labelNotCorrectEmail,
  labelTheNameMustConsistOf3To50Characters,
} from '@src/common/i18n/i18nProfile';

const obj = () => ({
  name: {
    validTest: /^.{3,255}$/,
    err: labelTheNameMustConsistOf3To50Characters({maxLength: 255}),
    required: true,
    requiredErr: labelFieldsWithAnAsteriskAreRequired(),
    name: 'userName',
  },
  email: {
    required: true,
    requiredErr: labelFieldsWithAnAsteriskAreRequired(),
    validTest: /^[\w.%+-]+@[A-Z\d-].{2,}[A-Z]{2}$/i,
    err: labelNotCorrectEmail(),
    name: 'userEmail',
  },
});

export default obj;
