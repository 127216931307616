import dayjs from 'dayjs';

import {datetimeObjectToTimestamp} from '@yourcoach/shared/api';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {Task} from '@yourcoach/shared/api/task';
import type {
  CalendarEventType,
  ClientInfo,
  MapScheme,
} from '@yourcoach/shared/modules/calendar';
import type {
  CalendarEntry,
  Conference,
  User,
} from '@yourcoach/shared/stores/calendar-data/types';
import type {
  DayStartTimestamp,
  Nullable,
  Timestamp,
} from '@yourcoach/shared/utils';
import {
  anyOf,
  getConferenceActions,
  getCourseDurationString,
  getEntityTypeById,
  getTaskActions,
  isConferenceConflicting,
  isConferenceEnded,
  isConferenceExpired,
  isConferenceStarted,
  isCoursePersonal,
  isMeCoachForEntity,
  not,
} from '@yourcoach/shared/utils';

export const mapScheme: MapScheme<CalendarEntry> = {
  id: getId,
  title: getTitle,
  sourceData: getEventSourceData,
  start: getStartDate,
  end: getEndDate,
  day: getDayStartTimeStamp,
  groupInfo: getGroupInfo,
  type: getCalendarEventType,
  eventStatus: getEventStatus,
  conditions: makeConditions,
};

function getEventStatus({event}: CalendarEntry) {
  // const eventType = getEntityTypeById(event._id);
  //
  // switch (eventType) {
  //   case 'task':
  //     return users[0].taskStatus ?? event.status;
  //   case 'conference':
  //   default:
  //     return event.status;
  // }
  return event.status;
}

function getEventSourceData({event}: CalendarEntry) {
  return event;
}

function getId({event}: CalendarEntry) {
  return event._id;
}

function getTitle({event}: CalendarEntry) {
  return event.title;
}

function getStartDate({event}: CalendarEntry) {
  return datetimeObjectToTimestamp(event.start_date);
}

function makeConditions(data: CalendarEntry) {
  const isMeCoach = isMeCoachForEntity(data.event);
  const entityTypeById = getEntityTypeById(data.event._id);

  let eventActionClickDisabled = false;
  let eventActionIsHidden = false;
  let isDeleteDisabled = false;
  let isEditDisabled = false;
  let isRescheduleDisabled = false;

  // const eventType = getCalendarEventType(data);

  switch (entityTypeById) {
    case 'conference': {
      const conference = data.event as Conference;
      const actions = getConferenceActions(conference);

      eventActionClickDisabled = anyOf([
        isConferenceExpired(conference),
        isConferenceEnded(conference),
        not(isConferenceStarted(conference)),
      ]);

      if (isConferenceConflicting(conference)) {
        eventActionClickDisabled = false;
      }

      isDeleteDisabled = false;
      isEditDisabled = not(actions.includes('edit'));
      isRescheduleDisabled = not(actions.includes('reschedule'));
      break;
    }
    case 'task': {
      const task = data.event as Task;
      const actions = getTaskActions(task);

      eventActionIsHidden = isMeCoachForEntity(task);
      isDeleteDisabled = not(actions.includes('delete'));
      isEditDisabled = not(actions.includes('edit'));
      break;
    }
  }

  return {
    isEditDisabled,
    isDeleteDisabled,
    isRescheduleDisabled,
    eventActionClickDisabled,
    eventActionIsHidden,
    isMeCoach,
    isEditHidden: true,
  };
}

function getEndDate({event}: CalendarEntry): Nullable<Timestamp> {
  return datetimeObjectToTimestamp(event.end_date);
}

function getGroupInfo({event, users}: CalendarEntry) {
  return {
    programAvatarUrl: getFileSrc(event.course?.program.avatar, 250).url ?? '',
    programName: event.course?.program.title || '',
    groupName: getCourseDurationString(event.course),
    clients: users.map(clientInfoMapper),
    isIndividual: isCoursePersonal(event.course),
  };
}

function getDayStartTimeStamp({event}: CalendarEntry): DayStartTimestamp {
  return dayjs(datetimeObjectToTimestamp(event.start_date))
    .startOf('day')
    .valueOf();
}

function clientInfoMapper(client: User): ClientInfo {
  return {
    id: client._id,
    name: client.name,
    avatarUrl: getFileSrc(client.avatar, 250).url ?? '',
  };
}

function getCalendarEventType({event}: CalendarEntry): CalendarEventType {
  const entityTypeById = getEntityTypeById(event._id);

  switch (entityTypeById) {
    case 'task': {
      return entityTypeById;
    }
    case 'conference':
    default: {
      return isConferenceConflicting(event as Conference)
        ? 'conflict'
        : 'session';
    }
  }
}
