import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Button} from '@yourcoach/shared/uikit/Button';
import {Image} from '@yourcoach/shared/uikit/Image';
import {Text} from '@yourcoach/shared/uikit/Text';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(66, 56, 181, 0.05);
  padding: 15px 20px;
  background-color: ${getColor('background1')};
  margin-bottom: 10px;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0.75;
`;

export const Avatar = styled(Image)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

export const Title = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

export const ProgramContainer = styled.div`
  flex: 0.9;
  border-left: 1px solid ${getColor('border2')};
  padding-left: 20px;
`;

export const ProgramTitle = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

export const CourseDates = styled(Text).attrs({as: 'p'})`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: ${getColor('text3')};
`;

export const NoteButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
`;

export const NoteButton = styled(Button).attrs({scheme: 'gray'})`
  height: 38px;
  border-radius: 19px;
  padding: 11px 19px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${getColor('background2')};
  color: ${getColor('text3')};

  svg {
    width: 20px;
    height: 20px;
    fill: ${getColor('text3')};
    margin-right: 10px;
  }
`;
