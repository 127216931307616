import type {IState, TActionTypes} from './PracticeState';
import {
  SET_AVATAR,
  SET_CLIENTS_HELPED,
  SET_COACH_DESCRIPTION,
  SET_COACH_LOGO,
  SET_COACH_TITLE,
  SET_NAME,
  SET_USER,
} from './types';

export default (state: IState, action: TActionTypes) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userId: action.payload || '',
      };
    case SET_CLIENTS_HELPED:
      return {
        ...state,
        clientsHelped: action.payload || 0,
      };
    case SET_COACH_TITLE:
      return {
        ...state,
        coachTitle: action.payload || '',
      };
    case SET_COACH_DESCRIPTION:
      return {
        ...state,
        coachDescription: action.payload || '',
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload || '',
      };
    case SET_AVATAR:
      return {
        ...state,
        userAvatar: action.payload || null,
      };
    case SET_COACH_LOGO:
      return {
        ...state,
        coachLogo: action.payload || null,
      };
    default:
      return state;
  }
};
