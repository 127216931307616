import type {FC, ReactNode} from 'react';
import React, {memo, useCallback} from 'react';

import styles from './styles.module.css';

interface Props {
  children?: ReactNode;
  title?: string;
  message?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CustomToast: FC<Props> = ({children, title, message, onClick}) => {
  const onClickCB = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClick ? onClick(e) : undefined;
    },
    [onClick],
  );

  return (
    <div
      className={`CustomToast ${styles.customToastNotification} ${
        onClick ? styles.isClickable : ''
      }`}
      onClick={onClickCB}
    >
      {children ? <div className={styles.toastIcon}>{children}</div> : null}
      <div className={styles.toastContainer}>
        {title ? <div className={styles.toastTitle}>{title}</div> : null}
        {message ? <div className={styles.toastMessage}>{message}</div> : null}
      </div>
    </div>
  );
};

export default memo(CustomToast);
