import type {ChangeEvent, DragEvent, FC, RefObject} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import type {IFile} from '@yourcoach/shared/api/media/file';

import {
  labelChooseFiles,
  labelOrDropFilesHere,
  labelUpload,
  labelUploadAll,
  labelUploadFiles,
} from '@src/common/i18n/i18nLibrary';
import {CustomButton} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';

import FilePreview from './FilePreview/FilePreview';
import type {ILoadFile, ILoadFilesStore} from './addFileLocalStore';
import {addFileLocalStore} from './addFileLocalStore';
import styles from './styles.module.css';

interface Props {
  multiple?: boolean;
  name?: string;
  maxSize?: number;
  label?: string;
  isOnlyImage?: boolean;
  isOnlyDock?: boolean;
  isOnlyPdf?: boolean;
  onUpload: (file: ILoadFile[]) => Promise<unknown>;
}

const AddFile: FC<Props> = ({
  multiple = true,
  name = 'example-upload',
  maxSize = 300000,
  label = labelUploadFiles(),
  isOnlyImage = false,
  isOnlyDock = false,
  isOnlyPdf = false,
  onUpload,
}) => {
  const inputEl:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null
    | undefined = useRef(null);

  let accept = '';

  const allow: string[] = [];

  if (isOnlyImage) {
    accept = 'image/*,image/jpeg';
    allow.push('gif', 'jpeg', 'jpg', 'png');
  } else if (isOnlyDock) {
    accept = '.pdf,.doc,.docx';
    allow.push('pdf', 'docx', 'doc');
  } else if (isOnlyPdf) {
    accept = '.pdf';
    allow.push('pdf');
  } else {
    accept =
      '.7z,.doc,.docx,.gif,.ico,.jpeg,.jpg,.mov,.mp3,.mpeg,.mpg,.pdf,.png,.ppt,.pptx,.rar,.txt,.wav,.xls,.xlsx,.zip,.m4a';
    allow.push(
      '7z',
      'doc',
      'docx',
      'gif',
      'ico',
      'jpeg',
      'jpg',
      'mov',
      'mp3',
      'mpeg',
      'mpg',
      'pdf',
      'png',
      'ppt',
      'pptx',
      'rar',
      'txt',
      'wav',
      'xls',
      'xlsx',
      'zip',
      'm4a',
    );
  }

  const localStore: ILoadFilesStore = addFileLocalStore;

  useEffect(() => {
    localStore.clearFileList();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragOver = (
    event: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    if ('preventDefault' in event) {
      event.stopPropagation();
      event.preventDefault();
    }

    localStore.getHoverState(event);
  };

  const handleFileSelect = (
    event: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    handleDragOver(event);

    let files;

    if (event.hasOwnProperty('dataTransfer')) {
      event = event as DragEvent<HTMLDivElement>;
      files = event.dataTransfer.files;
    } else {
      event = event as ChangeEvent<HTMLInputElement>;
      files = event.target.files;
    }

    let fileList: ILoadFile[] = Object.keys(files).map(file => files[file]);

    fileList = fileList.filter(itemFile => {
      const ext: string =
        itemFile.name.split('.').pop()?.toLocaleLowerCase() || '';

      return allow.indexOf(ext) !== -1;
    });

    if (!multiple) {
      fileList = [fileList[0]];
    }

    localStore.getFileList(fileList);
  };

  const removeFile = (retFiles: IFile[]) => {
    //retFiles.repr;
    const indexes: number[] = [];

    localStore.fileList.forEach((file: ILoadFile) => {
      const nameF = file.name.replace(/\..+$/, '');

      if (retFiles.some((retFile: IFile) => retFile.repr === nameF)) {
        indexes.push(localStore.fileList.indexOf(file));
      }
    });

    indexes.reverse().forEach(index => localStore.removeItem(index));

    localStore.getIsUploadFile(false);
  };

  const previews = () => {
    return localStore.fileList.map((file: ILoadFile, index) => {
      const removeItem1 = () => {
        localStore.removeItem(index);
      };
      const uploadFile1 = () => {
        const listArr: ILoadFile[] = [file];

        localStore.getIsUploadFile(true);

        onUpload(listArr).then((retFiles: IFile[]) => {
          removeFile(retFiles);
        });
      };

      return (
        <FilePreview
          key={index}
          data={file}
          multiple={multiple && localStore.fileList.length > 1}
          disabled={localStore.isUploadFile}
          onRemove={removeItem1}
          onUpload={uploadFile1}
        />
      );
    });
  };

  const uploadFiles = () => {
    localStore.getIsUploadFile(true);

    onUpload(localStore.fileList.slice()).then((files: IFile[]) => {
      removeFile(files);
    });
  };

  const selectFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (inputEl !== null && inputEl.current !== null) {
      inputEl.current.click();
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.isUploadFile ? (
            <Loader />
          ) : (
            <div className={`AddFile ${styles.AddFile}`}>
              <input type="hidden" name={`${name}:maxSize`} value={maxSize} />
              <div>
                <label>
                  <span className={styles.title}>{label}</span>
                  <div
                    className={styles.fileDrag}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragOver}
                    onDrop={handleFileSelect}
                  >
                    <div className={styles.inputWrapper}>
                      <input
                        type="file"
                        tabIndex={-1}
                        ref={inputEl}
                        className={styles.input}
                        name={name}
                        multiple={multiple}
                        accept={accept}
                        onChange={handleFileSelect}
                      />
                      <div className={styles.inputCover}>
                        <button
                          className={`ovalButton ${styles.button}`}
                          type="button"
                          onClick={selectFile}
                        >
                          {labelChooseFiles()}
                        </button>
                        <span className={styles.fileName}>
                          {localStore.fileNames}
                        </span>
                        <span className={styles.fileExt}>
                          {localStore.extTail}
                        </span>
                      </div>
                    </div>
                    <span className={styles.helpText}>
                      {labelOrDropFilesHere()}
                    </span>
                  </div>
                </label>
                <CustomButton
                  type="button"
                  disabled={localStore.isUploadFile}
                  classButton="standardButton"
                  classContainer={styles.customButtonContainer}
                  onClick={uploadFiles}
                >
                  {multiple ? labelUploadAll() : labelUpload()}
                </CustomButton>
              </div>
              <div className={styles.previews}>
                <Scrollbar
                  className={`scrollbar ${styles.mainSectionContainer}`}
                  noScrollX
                  wrapperProps={{className: 'wrapper'}}
                  trackYProps={{className: 'trackY'}}
                  thumbYProps={{className: 'thumbY'}}
                  trackXProps={{className: 'trackX'}}
                  thumbXProps={{className: 'thumbY'}}
                >
                  {previews()}
                </Scrollbar>
              </div>
              <div className={styles.hiddenContainer} />
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(AddFile);
