import {apiRequest} from '@yourcoach/shared/api/index';

const readMembership = async () => {
  return await apiRequest({
    method: 'coach.memberships.list',
    params: {
      limit: 1000,
      query: [['status', 'in', ['accepted', 'active']]],
      sort: [['created', -1]],
      expand: {
        membership: [
          [
            'user_id',
            {name: 'partner_test'},
            {
              user: ['avatar_id'],
            },
          ],
          'program_id',
          'course_id',
        ],
      },
    },
  });
};

export {readMembership};
