import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as PaymentMethodsProps} from './PaymentMethods';
import PaymentMethods, {I18N_SCOPE} from './PaymentMethods';

type Props = Partial<ModalProps> & PaymentMethodsProps;

const PaymentMethodsModal: React.FC<Props> = ({
  ableToDelete,
  onSelect,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])}>
    <PaymentMethods ableToDelete={ableToDelete} onSelect={onSelect} />
    {children}
  </Modal>
);

export default React.memo(PaymentMethodsModal);
