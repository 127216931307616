import {NavLink} from 'react-router-dom';

import styled from 'styled-components';

import {link} from './styles';

const BreadcrumbLink = styled(NavLink)`
  ${link};
`;

export {BreadcrumbLink};
