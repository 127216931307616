import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import type {Certificate} from '@yourcoach/shared/api/certificate';

import styles from './CertificatesList.module.css';
import CertificatesListItem from './CertificatesListItem';

interface Props {
  items: Certificate[];
  isVertical?: boolean;
  isReadOnly?: boolean;
  onEditClick: (certificate: Certificate) => void;
  onDeleteClick: (certificate: Certificate) => void;
}

const CertificatesList: FC<Props> = ({
  items = [],
  isVertical = true,
  isReadOnly = true,
  onEditClick = () => {},
  onDeleteClick = () => {},
}) => {
  return (
    <ul
      className={`CertificatesList ${styles.CertificatesList} ${
        isVertical ? '' : styles.horizontal
      }`}
    >
      <Observer>
        {() => (
          <>
            {items.map((item, index) => (
              <li key={'item-' + index} className={styles.li}>
                <CertificatesListItem
                  certificate={item}
                  isReadOnly={isReadOnly}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                />
              </li>
            ))}
          </>
        )}
      </Observer>
    </ul>
  );
};

export default memo(CertificatesList);
