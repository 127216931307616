import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';
import {autorun, runInAction} from 'mobx';

import type {Goal} from '@yourcoach/shared/api/goal';
import CupIcon from '@yourcoach/shared/assets/icons/primary/Trophy.svg';
import delay from '@yourcoach/shared/utils/delay';
import {logger} from '@yourcoach/shared/utils/logger';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import CRUGoalModal from '@src/modules/Goals/CRUGoalModal';
import GoalsListItem from '@src/modules/Goals/GoalsListItem';

import styles from './Goals.module.css';
import type {TabProps, TabRef} from './useTabs';

const GOAL_CONTEXT_MENU_ID = 'goal_context_menu';

export const I18N_SCOPE = 'shared.CRUProgramGoals';

export type GoalT =
  | Goal
  | Pick<Goal, 'title' | 'description' | 'is_client_managed' | '_id'>;

const CRUProgramGoals = React.forwardRef<TabRef, TabProps>(
  ({program, goalsStore, isActive}, ref) => {
    useImperativeHandle(ref, () => ({
      getData,
    }));

    const [goals, setGoals] = useState<GoalT[]>([]);
    const [isFetching, setIsFetching] = useState(goalsStore!.isFetching);
    const [isLoaded, setIsLoaded] = useState(goalsStore!.isLoaded);

    const fetchGoalsDelay = useRef(delay(0));
    const goalToEdit = useRef<GoalT>();

    const shouldRenderStub = useMemo(
      () => isLoaded && !goals.length,
      [goals.length, isLoaded],
    );

    const [cruGoalModalIsOpen, showCRUGoalModal, hideCRUGoalModal] =
      useIsVisible();

    const getData = useCallback(() => ({}), []);

    const fetchGoals = useCallback(async () => {
      try {
        await goalsStore!.fetch();
      } catch (error) {
        logger.error(error);

        fetchGoalsDelay.current = delay(2000);

        await fetchGoalsDelay.current;

        await fetchGoals();
      }
    }, [goalsStore]);

    useEffect(() => {
      const dispose = autorun(() => {
        setGoals(goalsStore!.items.slice());
        setIsLoaded(goalsStore!.isLoaded);
        setIsFetching(goalsStore!.isFetching);
      });

      return dispose;
    }, [goalsStore]);

    useEffect(() => {
      if (isActive && !isLoaded) {
        fetchGoals();
      }

      return () => {
        fetchGoalsDelay.current.cancel();
      };
    }, [fetchGoals, isActive, isLoaded]);

    const onAddGoalButtonClick = useCallback(() => {
      showCRUGoalModal();
    }, [showCRUGoalModal]);

    const onCRUGoalSuccess = useCallback(
      (goal: GoalT) => {
        hideCRUGoalModal();

        if (goalToEdit.current) {
          goalsStore!.updateItem(goal as Goal, goal);
        } else {
          goalsStore!.addItem(goal as Goal);
        }
      },
      [goalsStore, hideCRUGoalModal],
    );

    const onClearButtonClick = useCallback(async () => {
      getCustomConfirmAlert({
        title: 'Are you sure?',
        buttons: [
          {
            label: 'No',
            onClick: () => {},
          },
          {
            label: 'Yes',
            type: 'confirm',
            onClick: () => {
              runInAction(() => {
                goalsStore!.items = [];
              });
            },
          },
        ],
      });
    }, [goalsStore]);

    const onGoalMoreButtonClick = useCallback(
      (goal: GoalT, event: React.MouseEvent<Element, MouseEvent>) => {
        contextMenu.show({
          id: GOAL_CONTEXT_MENU_ID,
          event,
          props: {
            goal,
          },
        });
      },
      [],
    );

    const onDeleteGoalButtonClick = useCallback(
      ({props}: ItemParams<{goal: GoalT}>) => {
        if (props) {
          const {goal} = props;

          goalsStore!.removeItem(goal as Goal);
        }
      },
      [goalsStore],
    );

    const onUpdateGoalButtonClick = useCallback(
      ({props}: ItemParams<{goal: GoalT}>) => {
        if (props) {
          const {goal} = props;

          contextMenu.hideAll();

          goalToEdit.current = goal;

          showCRUGoalModal();
        }
      },
      [showCRUGoalModal],
    );

    const ContextMenu = useCallback(
      () => (
        <Menu
          id={GOAL_CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          <Item onClick={onUpdateGoalButtonClick} className="contextMenuItem">
            {t([I18N_SCOPE, 'more_update_button'])}
          </Item>
          <Item
            onClick={onDeleteGoalButtonClick}
            className={classNames('contextMenuItem', 'danger')}
          >
            {t([I18N_SCOPE, 'more_delete_button'])}
          </Item>
        </Menu>
      ),
      [onDeleteGoalButtonClick, onUpdateGoalButtonClick],
    );

    const onGoalClick = useCallback(
      (item: GoalT) => {
        contextMenu.hideAll();

        goalToEdit.current = item;

        showCRUGoalModal();
      },
      [showCRUGoalModal],
    );

    const onCRUGoalModalClose = useCallback(() => {
      goalToEdit.current = undefined;
      hideCRUGoalModal();
    }, [hideCRUGoalModal]);

    return (
      <>
        <div className={styles.addButtonsContainer}>
          <Button onClick={onAddGoalButtonClick}>
            <CupIcon />
            {t([I18N_SCOPE, 'add_goal_button'])}
          </Button>
          {!shouldRenderStub ? (
            <Button onClick={onClearButtonClick} className={styles.clearButton}>
              {t([I18N_SCOPE, 'clear_button'])}
            </Button>
          ) : null}
        </div>
        {!isLoaded && isFetching ? (
          <div>
            <Loader />
          </div>
        ) : null}
        {shouldRenderStub ? (
          <div className={styles.noResultsContainer}>
            <NoResultsHeader
              text={t([I18N_SCOPE, 'no_results_label'])}
              icon={CupIcon}
            />
          </div>
        ) : (
          <div className={styles.list}>
            {goals.map((goal, i) => (
              <GoalsListItem
                key={goal._id}
                index={i}
                goal={goal as Goal}
                onClick={onGoalClick}
                onMoreButtonClick={onGoalMoreButtonClick}
                className={styles.listItem}
              />
            ))}
          </div>
        )}
        <CRUGoalModal
          isOpen={cruGoalModalIsOpen}
          onAfterClose={onCRUGoalModalClose}
          programId={program ? program._id : undefined}
          goal={goalToEdit.current}
          onSuccess={onCRUGoalSuccess}
        />
        <ContextMenu />
      </>
    );
  },
);

export default React.memo(CRUProgramGoals, isEqual);
