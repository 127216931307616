const isActive = (path, match, location) => {
  let _isActive = false;

  if (match) {
    _isActive = true;
  } else if (location.pathname.includes(path)) {
    _isActive = true;
  }

  return _isActive;
};

export default isActive;
