import React from 'react';

import {t} from 'i18n-js';
import styled from 'styled-components';

import type {Nutrient as NutrientType} from '@yourcoach/shared/stores/single-event';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {I18N_MEAL_POPUP} from '../../utils';

import {useController} from './controller';
import {
  mealContainer,
  mealLineCell,
  mealLineCellRight,
  mealLineContainer,
} from './styles';
import type {Props} from './types';

const MealContainer = styled(View)`
  ${mealContainer}
`;

const MealLineLayout = {
  Container: styled(View)`
    ${mealLineContainer}
  `,
  CellLeft: styled(View)`
    ${mealLineCell};
  `,
  CellRight: styled(View)`
    ${mealLineCellRight}
  `,
};

const MealLine: React.FC<{label; value}> = ({label, value}) => (
  <MealLineLayout.Container>
    <MealLineLayout.CellLeft>
      <Text weight="bold">{label}</Text>
    </MealLineLayout.CellLeft>
    <MealLineLayout.CellRight>
      <Text>{value}</Text>
    </MealLineLayout.CellRight>
  </MealLineLayout.Container>
);

const Nutrient: React.FC<NutrientType> = ({name, qty, unit}) => (
  <MealLine label={t([I18N_MEAL_POPUP, 'label', name])} value={qty + unit} />
);

const Meal: React.FC<WithDomStyleProp<Props>> = ({meal, ...rest}) => {
  const {dataString, time} = useController(meal);

  return (
    <MealContainer {...rest}>
      <MealContainer>
        <MealLine
          label={t([I18N_MEAL_POPUP, 'label', 'meal'])}
          value={meal.query}
        />
        <MealLine
          label={t([I18N_MEAL_POPUP, 'label', 'data_for_analysis'])}
          value={dataString}
        />
        {meal.nutrients.map(({name, qty, unit}) => (
          <Nutrient key={name} name={name} qty={qty} unit={unit} />
        ))}
      </MealContainer>
      <View>
        {meal.date ? (
          <Text>
            {t([I18N_MEAL_POPUP, 'label', 'time'])} {time}
          </Text>
        ) : null}
      </View>
    </MealContainer>
  );
};

export {Meal};
