import type {FC} from 'react';
import React from 'react';

import {MainContainerWithMenu} from '@src/layouts';
import {Dashboard, MyPractice, MyProfile} from '@src/modules';

const DashboardPage: FC = () => {
  return (
    <MainContainerWithMenu
      allHeight
      // pageTitle={'Your Space'}
      isWithNewCrumbs
      // isWithCrumbs
    >
      <div className="DashboardPage grid2-1">
        <div className="greyArea">
          <Dashboard />
        </div>
        <div className="whiteArea mobileFirst">
          <MyProfile />
          <div className="separator" />
          <MyPractice />
        </div>
      </div>
    </MainContainerWithMenu>
  );
};

export default DashboardPage;
