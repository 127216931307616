import React from 'react';
import {observer} from 'mobx-react';

import type {Conference} from '@yourcoach/shared/api/conference';
import {useMobxStore} from '@yourcoach/shared/hooks';
import {eventStoreFactory} from '@yourcoach/shared/stores/single-event';
import {isConferenceRescheduleEnabled} from '@yourcoach/shared/utils';

import {useBookSessions} from '../../Chats/ChatsListContainer/ChatsListItem/useBookSession';
import {Card} from '../CardWidget';

import {Layout} from './components/Layout';
import {AttachmentsForm} from './containers/AttachmentsForm';
import {ControlsContainer} from './containers/Controls';
import {CourseInfoContainer} from './containers/CourseInfo';

interface Props {
  eventId: string;
  onStartConference: (arg: string) => void;
  handleCloseEventModal: () => void;
  storeCalendarFetch: () => void;
}

export const SingleEvent: React.FC<Props> = observer(
  ({eventId, onStartConference, handleCloseEventModal, storeCalendarFetch}) => {
    const store = useMobxStore(() => eventStoreFactory(eventId));

    const {bookingJSX, setIsOpenSchedulingModal, setSessionToRebook} =
      useBookSessions({
        onBookSession: () => {
          handleCloseEventModal();

          storeCalendarFetch();
        },
      });

    const onRescheduleClick = () => {
      setSessionToRebook(store.event.toJS as Conference);

      setIsOpenSchedulingModal(true);
    };

    React.useEffect(() => {
      store.fetch();
    }, [store]);

    return (
      <Layout.Container>
        <Layout.Main>
          <Card>
            <CourseInfoContainer
              courseId={store.event?.value?.course_id}
              eventId={store.event?.id}
            />
            {store.event?.isTask && store.event?.isMeClient && (
              <AttachmentsForm taskId={store.event.id} />
            )}
            <ControlsContainer
              store={store}
              onStartConference={onStartConference}
              isRescheduleButtonDisabled={
                !store.event ||
                !isConferenceRescheduleEnabled(store.event.toJS as Conference)
              }
              onRescheduleClick={onRescheduleClick}
            />
          </Card>
        </Layout.Main>
        {bookingJSX}
      </Layout.Container>
    );
  },
);
