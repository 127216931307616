import {t} from '@src/i18n';

const I18N_SCOPE = 'CourseMaterials';

export const labelCourseMatsTodayTabTitle = () =>
  t([I18N_SCOPE, 'today_tab_title']);

export const labelCourseMatsRecentTabTitle = () =>
  t([I18N_SCOPE, 'recent_tab_title']);

export const labelCourseMatsAllTabTitle = () =>
  t([I18N_SCOPE, 'all_tab_title']);

export const labelCourseMatsChooseModeTitle = () =>
  t([I18N_SCOPE, 'choose_mode_nav_title']);

export const labelCourseMatsNavTitle = () => t([I18N_SCOPE, 'nav_title']);

export const labelCourseMatsChoose = () => t([I18N_SCOPE, 'choose_btn']);

export const labelCourseMatsAddFile = () => t([I18N_SCOPE, 'AddFile']);

export const labelCourseMatsDelete = () =>
  t([I18N_SCOPE, 'more_menu', 'delete']);

export const labelCourseMatsUnlock = () =>
  t([I18N_SCOPE, 'more_menu', 'unlock']);

export const labelCourseMatsChangeUnlockDate = () =>
  t([I18N_SCOPE, 'more_menu', 'change_unlock_date']);

export const labelCourseMatsNoResults = () => t([I18N_SCOPE, 'no_results_lbl']);

export const labelCourseMatsMaterialNavTitle = () =>
  t([I18N_SCOPE, 'Material', 'nav_title']);

export const labelCourseMatsMaterialNoResults = () =>
  t([I18N_SCOPE, 'Material', 'no_results_lbl']);

export const labelCourseMatsItemLocked = () =>
  t([I18N_SCOPE, 'MaterialsListItem', 'locked_lbl']);

export const labelCourseMatsItemNotFound = () =>
  t([I18N_SCOPE, 'MaterialsListItem', 'not_found_lbl']);

export const labelCourseMatsNotStartedCourse = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'not_started_course_label', groupType]);

export const labelCourseMatsEmptyFoldersError = (obj: {
  count: number;
  names: string;
}) => t([I18N_SCOPE, 'empty_folders_error'], obj);

export const labelCourseMatsFailedLinksError = (obj: {
  count: number;
  userNames: string;
}) => t([I18N_SCOPE, 'failed_links_error'], obj);
