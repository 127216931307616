import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {
  ApiRpcRequestParams,
  CollectionStore,
  Expand,
} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import {getSubgoalColor} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';
import ArrowNextIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';
import {themes} from '@yourcoach/shared/styles/theme';
import {logger} from '@yourcoach/shared/utils/logger';
import type {Field} from '@yourcoach/shared/utils/validation';
import {createField} from '@yourcoach/shared/utils/validation';

import {getCustomConfirmAlert} from '../../../components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '../../../components/GoalsModal/GoalsModal';
import {IconCheck, IconCup} from '../../../components/icons';
import Loader from '../../../components/Loader/Loader';
import NoResults from '../../../components/NoResults/NoResults';
import OtherUserProfileImg from '../../../components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '../../../context/App';
import type {ListItemT} from '../../../modules/SeeProgram/Squad/Course/CourseGoalsTab/CourseGoalsTab';
import BoundSubgoalsListItem from '../BoundSubgoalsListItem/BoundSubgoalsListItem';
import ClientGoal from '../ClientGoal/ClientGoal';
import RateSubgoal from '../RateSubgoal/RateSubgoal';
import SubgoalsListItem from '../SubgoalsListItem/SubgoalsListItem';

import styles from './../styles.module.css';
import GoalModal from './GoalModal';

const LIMIT = 50;

export type GoalT = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: Course & {
    client: ExpandedClient;
  };
};

const goalExpand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
        ],
      },
    ],
  ],
};

const boundSubgoalExpand: Expand = {
  subgoal: [
    ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
    ['goal_id', null, goalExpand],
  ],
};

export type BoundSubgoal = Subgoal & {
  client: ExpandedClient;
  goal: GoalT;
};

type Section = {
  id: 'goal' | 'subgoal' | 'client_subgoals' | 'client';
  client?: ExpandedClient;
  clientSubgoalsCount?: number;
  clientReachedSubgoalsCount?: number;
  data: BoundSubgoal[];
};

export type ExpandedClient = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) & {
  avatar?: IFile | null;
};

interface IField extends Field {
  value: string;
  ref?: React.RefObject<HTMLInputElement>;
  onChangeText?: (text: string) => void;
}

interface IFields {
  currentValue: IField;
  comment: IField;
}

interface ILocalStore {
  goal: GoalT | null;
  boundGoal: GoalT | null;
  boundGoals: GoalT[];
  subgoal: Subgoal | null;
  currentValue: number;
  setCurrentValue(currentValue: number): void;
  goalsStore: CollectionStore<GoalT> | null;
  boundGoalsStore: CollectionStore<GoalT> | null;
  boundSubgoalsStore: CollectionStore<BoundSubgoal> | null;
  goalIndex?: number;
  isClientManagedGoal?: boolean;
  shouldShowClientSubgoals?: boolean;
  boundSubgoalsStoreParams: ApiRpcRequestParams;
  listProps: {
    sections: Section[];
  };
  isPaginating: boolean;
  fields: IFields;
  selectBoundSubgoal: BoundSubgoal | null;
  setSelectBoundSubgoal(selectBoundSubgoal: BoundSubgoal | null): void;
  _onCheckpointPress(checkpoint: Subgoal['checkpoints'][0]): void;
  _onIncreaseProgressButtonPress(): void;
  _onDecreaseProgressButtonPress(): void;
  _onCurrentValueTextChange(text: string): void;
  _fetchGoal(): Promise<void>;
  _fetchGoals(silent?: boolean | undefined): Promise<void>;
  _fetchBoundSubgoals(silent?: boolean): Promise<void>;
  _deleteSubGoal(subgoal: Subgoal): Promise<void>;
  _onEndReached(): Promise<void>;
  isOpenRateSubgoalModule: boolean;
  setIsOpenRateSubgoalModule(isOpenRateSubgoalModule: boolean): void;
  reteSubgoal: Subgoal | null;
  setReteSubgoal(reteSubgoal: Subgoal | null): void;
  selectUserId: string | null;
  setSelectUserId(selectUserId: string | null): void;
  offsetClientGoal: number;
  setOffsetClientGoal(offsetClientGoal: number): void;
}

interface Props {
  goalId: string;
  goal?: GoalT;
  subgoalId?: string;
  forCoach?: boolean;
  isOpenGoalModal: boolean;
  handleCloseModal: () => void;
  showClientsSubgoals?: boolean;
  isRenderMainGoal?: boolean;
  isRenderHeader?: boolean;
  selectUserId?: string;
  closeContainer?: () => void;
  onMorePress?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ListItemT,
  ) => void;
  onDeleteSubgoal?: (subgoal: Subgoal) => void;
  handleOpenRateGoalsModal: () => void;
  setCourseId: (arg) => void;
}

const Goal: FC<Props> = ({
  goalId,
  goal,
  subgoalId,
  forCoach,
  showClientsSubgoals,
  isRenderMainGoal = false,
  isRenderHeader = true,
  selectUserId,
  closeContainer,
  onMorePress,
  onDeleteSubgoal,
  isOpenGoalModal,
  handleCloseModal,
  handleOpenRateGoalsModal,
  setCourseId,
}) => {
  const thisRef = useRef<HTMLDivElement | null>(null);
  const onMorePressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, subgoal: Subgoal) => {
      onMorePress && onMorePress(event, subgoal as ListItemT);
    },
    [onMorePress],
  );

  const currentValueFields = createField('currentValue', {
    validationRule: {
      type: 'string',
      optional: true,
    },
  }) as IField;

  currentValueFields.ref = useRef(null);
  currentValueFields.onChangeText = (text: string) => {
    runInAction(() => {
      localStore.fields.currentValue.value = text;
      localStore.fields.currentValue.validate();
    });
  };

  const commentFields = createField('comment', {
    validationRule: {
      type: 'string',
      optional: true,
    },
  }) as IField;

  commentFields.ref = useRef(null);
  commentFields.onChangeText = (text: string) => {
    runInAction(() => {
      localStore.fields.comment.value = text;
      localStore.fields.comment.validate();
    });
  };

  const {
    stores: {goalStore},
  } = useContext(AppContext);

  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          goal: null,
          boundGoals: [],
          boundGoal: null,
          subgoal: null,
          selectUserId: null,
          offsetClientGoal: 0,
          setOffsetClientGoal(offsetClientGoal: number) {
            this.offsetClientGoal = offsetClientGoal;
          },
          setSelectUserId(selectUserId: string | null) {
            this.selectUserId = selectUserId;
          },
          currentValue: 0,
          setCurrentValue(currentValue: number) {
            this.currentValue = currentValue;
          },
          isOpenRateSubgoalModule: false,
          setIsOpenRateSubgoalModule(isOpenRateSubgoalModule: boolean) {
            this.isOpenRateSubgoalModule = isOpenRateSubgoalModule;
          },
          reteSubgoal: null,
          setReteSubgoal(reteSubgoal: Subgoal | null) {
            this.reteSubgoal = reteSubgoal;
          },
          goalsStore: null,
          boundGoalsStore: null,
          boundSubgoalsStore: null,
          isPaginating: false,
          selectBoundSubgoal: null,
          setSelectBoundSubgoal(selectBoundSubgoal: BoundSubgoal | null) {
            this.selectBoundSubgoal = selectBoundSubgoal;
          },
          get goalIndex() {
            let index: number | undefined;

            if (this.goalsStore && this.goal && this.goalsStore.isLoaded) {
              index = this.goalsStore.items.findIndex(
                item => item._id === goalId,
              );

              if (index === -1) {
                index === undefined;
              }
            }

            return index;
          },
          get isClientManagedGoal() {
            return this.goal ? this.goal.is_client_managed : false;
          },
          get shouldShowClientSubgoals() {
            return (
              forCoach &&
              showClientsSubgoals &&
              (this.isClientManagedGoal ||
                (this.goal && this.goal.counters.client_subgoals))
            );
          },
          get boundSubgoalsStoreParams() {
            const params: ApiRpcRequestParams = {};

            if (this.shouldShowClientSubgoals && this.boundGoals.length) {
              params.query = [
                ['goal_id', 'in', this.boundGoals.map(_goal => _goal._id)],
                ['is_client_managed', '==', true],
              ];
              params.sort = [['client_id', -1]];
            } else if (this.subgoal) {
              params.query = [['parent_subgoal_id', '==', this.subgoal._id]];
            }

            return params;
          },
          get listProps() {
            const sections: Section[] = [];

            if (this.goal) {
              sections.push({
                id: 'goal',
                data: [],
              });
            }

            if (this.shouldShowClientSubgoals) {
              sections.push({
                id: 'client_subgoals',
                data: [],
              });

              const clientSubgoals = this.boundSubgoalsStore.items.slice();

              const groups: {[key: string]: BoundSubgoal[]} = {};

              const groupBy = (subgoal: BoundSubgoal) => {
                const groupId = subgoal.client_id || '';

                groups[groupId] = groups[groupId] || [];

                groups[groupId].push(subgoal);
              };

              clientSubgoals.forEach(groupBy);

              Object.keys(groups).forEach(key => {
                const data: Section['data'] = groups[key];

                data.sort((a, b) => a.sort_order - b.sort_order);

                sections.push({
                  id: 'client',
                  client: {
                    ...groups[key][0].client,
                    _id: groups[key][0].client._id || key,
                  },
                  clientSubgoalsCount: data.length,
                  clientReachedSubgoalsCount: data.filter(
                    subgoal => !!subgoal.reached,
                  ).length,
                  data,
                });
              });
            } else if (this.subgoal) {
              let data: Section['data'] = [];

              if (forCoach) {
                data = this.boundSubgoalsStore.items.slice();

                if (data[0] && selectUserId === data[0].client._id) {
                  localStore.setSelectBoundSubgoal(data[0]);
                }
              } else {
                // render client part as a one list item
                // @ts-ignore
                data = [''];
              }

              sections.push({
                id: 'subgoal',
                data,
              });
            }

            return {sections};
          },
          fields: {
            currentValue: currentValueFields,
            comment: commentFields,
          },
          _onCheckpointPress(checkpoint: Subgoal['checkpoints'][0]) {
            const index = this.subgoal!.checkpoints.findIndex(
              item => item.uuid === checkpoint.uuid,
            );

            this.subgoal!.checkpoints.splice(index, 1, {
              ...checkpoint,
              reached: checkpoint.reached
                ? null
                : ISOStringToDatetimeObj(dayjs().toISOString()),
            });
          },
          _onIncreaseProgressButtonPress() {
            if (this.currentValue >= this.subgoal!.target_value) {
              return;
            }

            this.currentValue += 1;

            this.fields.currentValue.onChangeText(`${this.currentValue}`);
          },
          _onDecreaseProgressButtonPress() {
            if (this.currentValue <= this.subgoal!.initial_value) {
              return;
            }

            this.currentValue -= 1;

            this.fields.currentValue.onChangeText(`${this.currentValue}`);
          },
          _onCurrentValueTextChange(text: string) {
            let currentValueText = text.replace(/\D/g, '');

            if (!currentValueText) {
              currentValueText = '0';
            } else if (currentValueText.startsWith('0')) {
              currentValueText = currentValueText.slice(1);
            } else if (
              parseInt(currentValueText, 10) > this.subgoal!.target_value
            ) {
              currentValueText = `${this.subgoal!.target_value}`;
            } else if (
              parseInt(currentValueText, 10) <= this.subgoal!.initial_value
            ) {
              currentValueText = `${this.subgoal!.initial_value}`;
            }

            this.currentValue = currentValueText
              ? parseInt(currentValueText, 10)
              : 0;

            this.fields.currentValue.onChangeText(currentValueText);
          },
          async _fetchGoal() {
            try {
              let fetch = goalStore.client.fetch;

              if (forCoach) {
                fetch = goalStore.coach.fetch;
              }

              const goalFetch = (await fetch({
                _id: goalId,
                expand: goalExpand,
              })) as GoalT;

              runInAction(() => {
                this.goal = goalFetch;
              });
            } catch (error) {
              if (apiRequest.isCanceledError(error)) {
                return;
              }

              getCustomConfirmAlert({
                title: 'Oops! Something went wrong! We are working to fix it!',
                message: error.message,
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            }
          },
          async _fetchGoals(silent = false) {
            if (!this.goal) {
              return;
            }

            await this.goalsStore.fetch(
              {
                query: [['course_id', '==', this.goal.course_id]],
              },
              {silent},
            );
          },
          async _fetchBoundSubgoals(silent = false) {
            try {
              if (this.goal) {
                const boundGoals = await this.boundGoalsStore.fetch({
                  query: [['parent_goal_id', '==', this.goal._id]],
                });

                if (boundGoals.length) {
                  runInAction(() => {
                    this.boundGoal = boundGoals[0];
                    this.boundGoals = boundGoals;
                  });

                  await this.boundSubgoalsStore.fetch(
                    {
                      ...this.boundSubgoalsStoreParams,
                      limit: Math.max(
                        this.boundSubgoalsStore.items.length,
                        LIMIT,
                      ),
                    },
                    {silent},
                  );
                } else {
                  runInAction(() => {
                    this.boundSubgoalsStore.isLoaded = true;
                  });
                }
              }
            } catch (e) {
              // do nothing
            }
          },
          async _deleteSubGoal(subgoal: Subgoal) {
            try {
              await goalStore.sub.coach.bound.delete(subgoal);
            } catch (error) {
              getCustomConfirmAlert({
                title: 'Oops! Something went wrong! We are working to fix it!',
                message: error.message,
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            }
          },
          async _onEndReached() {
            if (this.isPaginating) {
              return;
            }

            this.isPaginating = true;

            if (this.boundSubgoalsStore.hasMore) {
              try {
                await this.boundSubgoalsStore.fetch(
                  {
                    ...this.boundSubgoalsStoreParams,
                    offset: this.boundSubgoalsStore.items.length,
                  },
                  {
                    silent: true,
                  },
                );
              } catch (error) {
                // TODO: log error
              }
            }

            this.isPaginating = false;
          },
        },
        {
          goal: observable.shallow,
          boundGoal: observable,
          subgoal: observable,
          currentValue: observable,
          setCurrentValue: action,
          goalsStore: observable,
          boundGoalsStore: observable,
          boundSubgoalsStore: observable,
          isPaginating: observable,
          goalIndex: computed,
          isClientManagedGoal: computed,
          shouldShowClientSubgoals: computed,
          boundSubgoalsStoreParams: computed,
          listProps: computed,
          fields: observable.shallow,
          _onCheckpointPress: action,
          _onIncreaseProgressButtonPress: action,
          _onDecreaseProgressButtonPress: action,
          _onCurrentValueTextChange: action,
          _fetchGoal: action,
          _fetchGoals: action,
          _fetchBoundSubgoals: action,
          _deleteSubGoal: action,
          _onEndReached: action,
          selectBoundSubgoal: observable,
          setSelectBoundSubgoal: action,
          isOpenRateSubgoalModule: observable,
          setIsOpenRateSubgoalModule: action,
          reteSubgoal: observable,
          setReteSubgoal: action,
          selectUserId: observable,
          setSelectUserId: action,
          offsetClientGoal: observable,
          setOffsetClientGoal: action,
        },
      ),
    //eslint-disable-next-line  react-hooks/exhaustive-deps
    [
      forCoach,
      goalId,
      goalStore.client.fetch,
      goalStore.coach.fetch,
      goalStore.sub.coach.bound,
      selectUserId,
      showClientsSubgoals,
    ],
  );

  useEffect(() => {
    setCourseId(goal?.course_id);
    runInAction(() => {
      localStore.goalsStore = createCollectionStore({
        method: forCoach ? 'coach.goals.unbound.list' : 'client.goals.list',
        params: {
          sort: [['sort_order', 1]],
          expand: goalExpand,
        },
      });

      localStore.boundGoalsStore = createCollectionStore({
        method: forCoach ? 'coach.goals.bound.list' : 'client.goals.list',
        params: {
          expand: goalExpand,
        },
      });

      localStore.boundSubgoalsStore = createCollectionStore({
        method: 'coach.subgoals.bound.list',
        params: {
          limit: LIMIT,
          expand: boundSubgoalExpand,
        },
      });
    });

    const disposeGoal = reaction(
      () => localStore.goal && localStore.goal._id,
      async () => {
        if (localStore.goal && localStore.goal._id) {
          await localStore._fetchGoals();

          if (localStore.shouldShowClientSubgoals) {
            await localStore._fetchBoundSubgoals();
          } else {
            const subgoalIndex = subgoalId
              ? localStore.goal.subgoal_ids.findIndex(id => id === subgoalId)
              : localStore.goal.subgoal_ids.length
              ? 0
              : localStore.goal.subgoal_ids.length - 1;

            if (subgoalIndex >= 0) {
              localStore.subgoal = localStore.goal.subgoals[subgoalIndex];

              localStore.fields.currentValue.setValue(
                `${localStore.subgoal.current_value || '0'}`,
              );
              localStore.currentValue = localStore.subgoal.current_value;

              localStore.fields.comment.setValue(
                localStore.subgoal.client_comment,
              );

              if (forCoach) {
                await localStore._fetchBoundSubgoals();
              }
            }
          }
        }
      },
    );

    const disposeGoalStoreUpdating = reaction(
      () => goalStore.updating,
      updating => {
        if (
          localStore.goal &&
          updating.success &&
          updating.entity &&
          updating.entity._id === goalId
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore.goal![key] = updating.entity![key];
          });

          localStore._fetchGoal();
        }
      },
    );

    const disposeSubCoachBoundUpdating = reaction(
      () => goalStore.sub.coach.bound.updating,
      updating => {
        if (updating.success && updating.entity) {
          const subGoalIndex = localStore.boundGoal!.subgoal_ids.findIndex(
            item => item === updating.entity!._id,
          );

          if (subGoalIndex >= 0) {
            const subGoalItem = localStore.boundGoal!.subgoals[subGoalIndex];

            localStore.boundGoal!.subgoals[subGoalIndex] = {
              ...subGoalItem,
              ...updating.entity,
            };

            localStore.boundSubgoalsStore!.updateItem(
              updating.entity as BoundSubgoal,
              updating.entity,
            );
          }
        }
      },
    );

    const disposeGoalStoreSubCoachBoundDeleting = reaction(
      () => goalStore.sub.coach.bound.deleting,
      deleting => {
        if (deleting.success && deleting.entity) {
          const subGoalIndex = localStore.boundGoal!.subgoal_ids.findIndex(
            item => item === deleting.entity!._id,
          );

          if (subGoalIndex >= 0) {
            localStore.boundGoal!.subgoal_ids.splice(subGoalIndex, 1);

            localStore.boundGoal!.subgoals.splice(subGoalIndex, 1);

            localStore.boundSubgoalsStore!.removeItem(
              deleting.entity as BoundSubgoal,
            );
          }
        }
      },
    );

    if (goal) {
      runInAction(() => {
        localStore.goal = goal;
      });
    } else {
      localStore._fetchGoal();
    }

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }

      if (localStore.boundGoalsStore) {
        localStore.boundGoalsStore.clear();
      }

      if (localStore.boundSubgoalsStore) {
        localStore.boundSubgoalsStore.clear();
      }

      disposeGoal();
      disposeGoalStoreUpdating();
      disposeSubCoachBoundUpdating();
      disposeGoalStoreSubCoachBoundDeleting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, goalId, subgoalId]);

  const _onSaveSubgoalProgressButtonPress = () => {
    _saveProgress();

    if (localStore.subgoal!.checkpoints.length > 0) {
      logger.event('confirm_goal_progress_tap', {type: 'checklist'});
    } else {
      logger.event('confirm_goal_progress_tap', {type: 'progress'});
    }
  };

  const _saveProgress = async () => {
    try {
      const data: ApiRpcRequestParams = {};

      if (localStore.subgoal!.checkpoints.length) {
        data.checkpoints = localStore.subgoal!.checkpoints.map(
          (checkpoint, i) => [i, !!checkpoint.reached],
        );
      } else {
        data.current_value = localStore.currentValue;
      }

      data.client_comment = localStore.fields.comment.value;

      const updatedSubgoal = await goalStore.sub.client.update(
        localStore.subgoal!,
        data,
      );

      const subgoalIsReached =
        (updatedSubgoal.checkpoints.length &&
          updatedSubgoal.checkpoints.every(
            checkpoint => !!checkpoint.reached,
          )) ||
        (!updatedSubgoal.checkpoints.length &&
          updatedSubgoal.current_value >= updatedSubgoal.target_value);

      if (subgoalIsReached) {
        await goalStore.sub.client.markReached(updatedSubgoal);
      }

      if (subgoalIsReached) {
        localStore.setReteSubgoal(updatedSubgoal);
        localStore.setIsOpenRateSubgoalModule(true);
      } else {
        if (closeContainer) {
          localStore.setReteSubgoal(null);
          closeContainer();
        }
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: 'Oops! Something went wrong! We are working to fix it!',
        message: error.message,
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _onBoundSubgoalPress = (
    subgoal: BoundSubgoal,
    boundElement?: DOMRect,
  ) => {
    if (boundElement) {
      const y = boundElement.top - thisRef.current!.getBoundingClientRect().top;

      localStore.setOffsetClientGoal(y);
    } else {
      localStore.setOffsetClientGoal(0);
    }

    localStore.setSelectBoundSubgoal(subgoal);
  };

  const _renderGoal = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.goal && !localStore.goalsStore) {
            return <div>Getting your data, please try again...</div>;
          }

          return (
            <div className={`_renderGoal ${styles.goalContainer}`}>
              <div className={styles.goalTitleContainer}>
                <div className={styles.goal_index}>
                  Main goal #{localStore.goalIndex! + 1}
                </div>
                <div className={styles.goal_title}>
                  {localStore.goal!.title}
                </div>
              </div>
              {localStore.goal!.description ? (
                <div className={styles.goalDescriptionContainer}>
                  {localStore.goal!.description}
                </div>
              ) : null}
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderSubgoal = () => {
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.subgoalContainer}>
              <div className={styles.subgoalHeaderContainer} />
              <SubgoalsListItem
                subgoal={localStore.subgoal!}
                isVariantHeader
                onMorePress={onMorePress ? onMorePressCb : undefined}
              />
              {forCoach &&
              localStore.boundSubgoalsStore &&
              (!localStore.boundSubgoalsStore.isLoaded ||
                localStore.boundSubgoalsStore.isFetching) ? (
                <div className={styles.progressSpinnerContainer}>
                  <Loader />
                </div>
              ) : null}
              {!forCoach ||
              (forCoach &&
                localStore.boundSubgoalsStore &&
                localStore.boundSubgoalsStore.hasItems) ? (
                <div className={styles.subgoalProgressHeaderContainer}>
                  <div className={styles.subgoalProgressHeader}>Progress</div>
                  {!forCoach ? (
                    <div
                      className={styles.subgoalProgressSubHeader}
                      style={{
                        color: getSubgoalColor(
                          localStore.subgoal!.color,
                          themes.light,
                        ),
                      }}
                    >
                      {goal?.subgoals.find(subgoal => subgoal._id === subgoalId)
                        ?.current_value
                        ? goal?.subgoals.find(
                            subgoal => subgoal._id === subgoalId,
                          )?.current_value
                        : goal?.subgoals
                            .find(subgoal => subgoal._id === subgoalId)
                            ?.checkpoints.filter(
                              checkpoint => checkpoint.reached !== null,
                            )?.length}
                      /
                      {goal?.subgoals.find(subgoal => subgoal._id === subgoalId)
                        ?.checkpoints.length
                        ? goal.subgoals.find(
                            subgoal => subgoal._id === subgoalId,
                          )?.checkpoints.length
                        : goal?.subgoals.find(
                            subgoal => subgoal._id === subgoalId,
                          )?.target_value}
                      {goal?.subgoals.find(subgoal => subgoal._id === subgoalId)
                        ?.unit
                        ? ` ${
                            goal?.subgoals.find(
                              subgoal => subgoal._id === subgoalId,
                            )?.unit
                          }`
                        : ' steps'}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {localStore.boundSubgoalsStore &&
              localStore.boundSubgoalsStore.isLoaded &&
              !localStore.boundSubgoalsStore.hasItems ? (
                <NoResults
                  text="No progress yet"
                  className={styles.NoResults}
                  classNameTextContainer={styles.textContainer}
                >
                  <div className={styles.iconNoResultContainer}>
                    <IconCup
                      className={styles.iconNoResult}
                      viewBox="4 4 30 30"
                    />
                  </div>
                </NoResults>
              ) : null}
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderClientSubgoalsSectionHeader = () => {
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.subgoalContainer}>
              {localStore.boundSubgoalsStore &&
              localStore.boundSubgoalsStore.isLoaded &&
              localStore.boundSubgoalsStore.hasItems ? (
                <div className={styles.subgoalHeaderContainer}>Clients</div>
              ) : null}
              {localStore.boundSubgoalsStore &&
              (!localStore.boundSubgoalsStore.isLoaded ||
                localStore.boundSubgoalsStore.isFetching) ? (
                <div className={styles.progressSpinnerContainer}>
                  ActivityIndicator
                </div>
              ) : null}
              {localStore.boundSubgoalsStore &&
              localStore.boundSubgoalsStore.isLoaded &&
              !localStore.boundSubgoalsStore.hasItems ? (
                <NoResults
                  text="None of your clients have created short-term goals"
                  className={styles.NoResults}
                  classNameTextContainer={styles.textContainer}
                >
                  <div className={styles.iconNoResultContainer}>
                    <IconCup
                      className={styles.iconNoResult}
                      viewBox="4 4 30 30"
                    />
                  </div>
                </NoResults>
              ) : null}
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderClientSectionHeader = (section: Section) => {
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.clientSectionHeader}>
              <div className={styles.clientContent}>
                <div className={styles.avatarContainer}>
                  <OtherUserProfileImg
                    avatar={section.client!.avatar}
                    title={section.client!.name}
                    isOverlay={!section.client!.avatar}
                  />
                </div>
                <div className={styles.clientName}>{section.client!.name}</div>
              </div>
              <div className={styles.rightContainer}>
                {section.clientReachedSubgoalsCount ===
                section.clientSubgoalsCount ? (
                  <div className={styles.checkIconContainer}>
                    <CheckIcon />
                  </div>
                ) : null}
                <div
                  className={`${styles.clientSubgoalsCount} ${
                    section.clientReachedSubgoalsCount ===
                    section.clientSubgoalsCount
                      ? styles.done
                      : ''
                  }`}
                >
                  {`${section.clientReachedSubgoalsCount} / ${section.clientSubgoalsCount}`}
                </div>
                <div
                  className={`${styles.arrowNextIconContainer} ${
                    localStore.selectUserId === section.client!._id
                      ? styles.open
                      : ''
                  }`}
                >
                  <ArrowNextIcon />
                </div>
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  const RenderSectionHeader = ({section}: {section: Section}) => {
    if (section.id === 'goal' && isRenderMainGoal) {
      return _renderGoal();
    } else if (section.id === 'subgoal') {
      return _renderSubgoal();
    } else if (section.id === 'client_subgoals') {
      return _renderClientSubgoalsSectionHeader();
    } else if (section.id === 'client') {
      return _renderClientSectionHeader(section);
    }

    return null;
  };

  const handleOnDecreaseProgressButtonPress = () => {
    localStore._onDecreaseProgressButtonPress();
    logger.event('edit_goal_progress_tap');
  };

  const handleOnIncreaseProgressButtonPress = () => {
    localStore._onIncreaseProgressButtonPress();
    logger.event('edit_goal_progress_tap');
  };

  const handleOnChangeVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      // eslint-disable-next-line radix
      let val = parseInt(e.target.value);

      if (val >= localStore.subgoal!.target_value) {
        val = localStore.subgoal!.target_value;
      }

      if (val <= localStore.subgoal!.initial_value) {
        val = localStore.subgoal!.initial_value;
      }

      if (!isNaN(val)) {
        localStore.setCurrentValue(val);
        localStore.fields.currentValue.onChangeText!(`${val}`);
      }
    }
  };

  const handleOnChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    localStore.fields.comment.onChangeText!(e.target.value);
  };

  const _renderClientPart = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.subgoal) {
            return null;
          }

          return (
            <div className={styles.clientPartContainer}>
              {localStore.subgoal.checkpoints.length ? (
                localStore.subgoal.checkpoints.map(checkpoint => {
                  const isReached = !!checkpoint.reached;

                  return (
                    <div
                      key={checkpoint.uuid}
                      onClick={() => {
                        localStore._onCheckpointPress(checkpoint);
                        logger.event('edit_goal_progress_tap');
                      }}
                      className={styles.checkpointContainer}
                    >
                      <div
                        className={`${styles.checkBox} ${
                          isReached ? styles.selected : ''
                        }`}
                        style={{
                          borderColor: getSubgoalColor(
                            localStore.subgoal!.color,
                            themes.light,
                          ),
                          background: isReached
                            ? getSubgoalColor(
                                localStore.subgoal!.color,
                                themes.light,
                              )
                            : 'initial',
                        }}
                      >
                        {isReached ? (
                          <IconCheck
                            customClass={styles.checkIcon}
                            fill={'#ffffff'}
                            viewBox={'2 0 32 32'}
                          />
                        ) : null}
                      </div>
                      <div
                        className={`${styles.checkpointTitle} ${
                          isReached ? styles.doneCheckpointTitle : ''
                        }`}
                        style={{
                          color: isReached
                            ? getSubgoalColor(
                                localStore.subgoal!.color,
                                themes.light,
                              )
                            : themes.light.color.text1,
                        }}
                      >
                        {checkpoint.title}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progressBarFill}
                      style={{
                        flex:
                          localStore.currentValue /
                          localStore.subgoal.target_value,
                        backgroundColor: getSubgoalColor(
                          localStore.subgoal!.color,
                          themes.light,
                        ),
                      }}
                    />
                  </div>
                  <div className={styles.progressBarValue}>
                    <div
                      style={{
                        color: getSubgoalColor(
                          localStore.subgoal!.color,
                          themes.light,
                        ),
                      }}
                    >{`${localStore.currentValue}`}</div>
                    {`/${localStore.subgoal.target_value} ${
                      localStore.subgoal.unit ? localStore.subgoal.unit : ''
                    }`}
                  </div>
                  <div className={styles.addProgressContainer}>
                    <div className={styles.addProgressTitle}>Add Progress</div>

                    <div className={styles.addProgressContent}>
                      <div
                        onClick={handleOnDecreaseProgressButtonPress}
                        className={styles.addProgressButton}
                      >
                        <div style={{marginTop: '-2px'}}>-</div>
                      </div>
                      <input
                        value={localStore.fields.currentValue.value}
                        onChange={handleOnChangeVal}
                      />

                      <div
                        onClick={handleOnIncreaseProgressButtonPress}
                        className={styles.addProgressButton}
                      >
                        <div>+</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.commentInputContainer}>
                <textarea
                  value={localStore.fields.comment.value}
                  onChange={handleOnChangeComment}
                  placeholder="Leave comment"
                />
              </div>
              {localStore.subgoal!.client_comment ? (
                <div className={styles.commentContainer}>
                  <div className={styles.commentHeader}>Comment</div>
                  <div className={styles.comment}>
                    {localStore.subgoal!.client_comment}
                  </div>
                </div>
              ) : null}
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderFooter = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.goal) {
            return null;
          }

          if (!forCoach && localStore.subgoal) {
            return (
              <div className={styles.saveButContainer}>
                <button
                  type="button"
                  className={`${styles.confirm_button}`}
                  onClick={_onSaveSubgoalProgressButtonPress}
                >
                  <span>Confirm</span>
                </button>
              </div>
            );
          }

          return null;
        }}
      </Observer>
    );
  };

  const handleCloseRateSubgoal = () => {
    localStore.setReteSubgoal(null);
    localStore.setIsOpenRateSubgoalModule(false);
    handleOpenRateGoalsModal();

    if (closeContainer) {
      closeContainer();
    }
  };

  const handleClickCloseRateSubgoalModal = () => {
    handleCloseRateSubgoal();
  };

  const handleOnDeleteButtonPress = (subgoal: Subgoal) => {
    if (onDeleteSubgoal) {
      onDeleteSubgoal(subgoal);
    }
  };

  const handleOnClickUser = useCallback(
    (userId: string | null = null) =>
      (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (localStore.selectUserId === userId) {
          localStore.setSelectUserId(null);
        } else {
          localStore.setSelectUserId(userId);
        }
      },
    [localStore],
  );

  return (
    <Observer>
      {() => (
        <>
          {isOpenGoalModal ? (
            <GoalModal
              type={localStore.isOpenRateSubgoalModule ? 'subgoal' : ''}
              onClose={handleCloseModal}
            >
              <div
                className={`Goal ${styles.Goal} ${styles.Goal__content}`}
                ref={thisRef}
              >
                <div className={styles.columnSelectGoal}>
                  {_renderGoal()}
                  {localStore.listProps.sections.map((section, index) => {
                    return (
                      <div
                        key={`sub_${section.id}_${index}`}
                        className={styles.section_Container}
                        onClick={handleOnClickUser(section.client?._id)}
                      >
                        {isRenderHeader ? (
                          <RenderSectionHeader section={section} />
                        ) : null}
                        {section.data.map(item => {
                          if (item) {
                            if (section.id === 'subgoal') {
                              return (
                                <BoundSubgoalsListItem
                                  key={`subgoal_${item._id}`}
                                  subgoal={item}
                                  isSelect={
                                    localStore.selectBoundSubgoal
                                      ? localStore.selectBoundSubgoal._id ===
                                        item._id
                                      : false
                                  }
                                  className={styles.boundSubgoalContainer}
                                  onPress={_onBoundSubgoalPress}
                                />
                              );
                            } else if (section.id === 'client') {
                              if (!localStore.isClientManagedGoal) {
                                return (
                                  <SubgoalsListItem
                                    key={`client_${item._id}`}
                                    subgoal={item}
                                    isRemovable={onDeleteSubgoal ? true : false}
                                    onDeleteButtonPress={
                                      handleOnDeleteButtonPress
                                    }
                                    onPress={_onBoundSubgoalPress}
                                  />
                                );
                              } else {
                                if (
                                  localStore.selectUserId ===
                                  section.client?._id
                                ) {
                                  return (
                                    <SubgoalsListItem
                                      key={`client_${item._id}`}
                                      subgoal={item}
                                      isRemovable={
                                        onDeleteSubgoal ? true : false
                                      }
                                      onDeleteButtonPress={
                                        handleOnDeleteButtonPress
                                      }
                                      onPress={_onBoundSubgoalPress}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              }
                            }

                            return null;
                          } else {
                            return (
                              <div key={'client'}>
                                <div>{_renderClientPart()}</div>
                                <div>{_renderFooter()}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
                {localStore.selectBoundSubgoal ? (
                  <div className={styles.columnSelectUser}>
                    <ClientGoal
                      goalId={localStore.selectBoundSubgoal.goal_id}
                      goal={localStore.selectBoundSubgoal.goal}
                      subgoalId={localStore.selectBoundSubgoal._id}
                      clientId={localStore.selectBoundSubgoal.client_id!}
                      offset={localStore.offsetClientGoal}
                    />
                  </div>
                ) : null}
                <ModalAnimateWin
                  showModal={localStore.isOpenRateSubgoalModule}
                  closeModalHandler={handleCloseRateSubgoal}
                  className="greyHeaderContainer orWhite littleContainer List"
                  classNameBody="whiteBody noP30"
                  header="Rate Subgoal"
                  classNameHeader="greyHeader w440"
                  classNameCloseBut="greyHeaderBut"
                >
                  <div className="whiteBodyContent">
                    <RateSubgoal
                      subgoal={localStore.reteSubgoal!}
                      closeModal={handleClickCloseRateSubgoalModal}
                    />
                  </div>
                </ModalAnimateWin>
              </div>
            </GoalModal>
          ) : null}
        </>
      )}
    </Observer>
  );
};

export default memo(Goal);
