import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Payment as IPayment} from '@yourcoach/shared/api/payment';
import type {User} from '@yourcoach/shared/api/user';
import {Currency, getPriceString} from '@yourcoach/shared/utils/money';

import {labelSharedYou} from '@src/common/i18n/i18nCommon';
import {
  labelCoursePaymentsListItemConfirmButton,
  labelCoursePaymentsListItemConfirmDescription,
  labelCoursePaymentsListItemFailedDescription,
  labelCoursePaymentsListItemMarkPaidButton,
  labelCoursePaymentsListItemMarkPaidDescription,
  labelCoursePaymentsListItemRetryButton,
} from '@src/common/i18n/i18nCourse';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';

import styles from './../../../styles.module.scss';

type PaymentT = IPayment & {
  user: User & {
    avatar: IFile | null;
  };
};

export interface Props<T extends PaymentT = PaymentT> {
  payment: T;
  isExternal?: boolean;
  onPress?: (payment: T) => void;
  onMarkPaidButtonPress?: (payment: T) => void;
  onRetryButtonPress?: (payment: T) => void;
}

const CoursePaymentsListItem: FC<Props> = ({
  payment,
  isExternal,
  onPress,
  onMarkPaidButtonPress,
  onRetryButtonPress,
}) => {
  const {
    stores: {currentUserStore, cardStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const cards = cardStore.cards;

  const onPressCb = useCallback(() => {
    onPress && onPress(payment);
  }, [payment, onPress]);

  const onMarkPaidButtonPressCb = useCallback(() => {
    onMarkPaidButtonPress && onMarkPaidButtonPress(payment);
  }, [payment, onMarkPaidButtonPress]);

  const onRetryButtonPressCb = useCallback(() => {
    onRetryButtonPress && onRetryButtonPress(payment);
  }, [payment, onRetryButtonPress]);

  const paymentMethod = useMemo(
    () => (payment.card_id ? cards[payment.card_id] || null : null),
    [cards, payment.card_id],
  );

  return (
    <Observer>
      {() => (
        <div
          className={`CoursePaymentsListItem ${styles.CoursePaymentsListItem}`}
          onClick={onPressCb}
        >
          <div className={styles.userContainer}>
            <div className={styles.userAvatar}>
              {
                <OtherUserProfileImg
                  avatar={payment.user.avatar}
                  title={payment.user.name}
                />
              }
            </div>
            <div className={styles.userName}>
              {payment.user._id !== user!._id
                ? payment.user.name
                : labelSharedYou()}
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.datePayment}>
              {dayjs(
                datetimeObjToISOString(
                  payment.paid ||
                    payment.status === 'failed' ||
                    payment.status === 'frozen'
                    ? payment.end_date
                    : payment.start_date,
                ),
              ).format('lll')}
            </div>
            <div className={styles.amountPaymentContainer}>
              <div className={styles.amountPayment}>
                {getPriceString({
                  price: payment.amount,
                  currency: Currency[payment.currency],
                })}
              </div>
              {paymentMethod ? (
                <div className={styles.cardPayment}>
                  {`${paymentMethod.card!.brand} ****${
                    paymentMethod.card!.last4
                  }`}
                </div>
              ) : null}
            </div>
          </div>
          {payment.user._id === user!._id ? (
            <div className={styles.buttonsContainer}>
              {isExternal && payment.status !== 'paid' ? (
                <div className={styles.butContainer}>
                  <div className={styles.butMessContainer}>
                    {labelCoursePaymentsListItemMarkPaidDescription()}
                  </div>
                  <div
                    className={styles.deleteBut}
                    onClick={onMarkPaidButtonPressCb}
                  >
                    {labelCoursePaymentsListItemMarkPaidButton()}
                  </div>
                </div>
              ) : null}
              {!isExternal && payment.status === 'requires_action' ? (
                <div className={styles.butContainer}>
                  <div className={styles.butMessContainer}>
                    {labelCoursePaymentsListItemConfirmDescription()}
                  </div>
                  <div
                    className={styles.startBut}
                    onClick={onRetryButtonPressCb}
                  >
                    {labelCoursePaymentsListItemConfirmButton()}
                  </div>
                </div>
              ) : null}
              {!isExternal &&
              (payment.status === 'failed' || payment.status === 'frozen') ? (
                <div className={styles.butContainer}>
                  <div className={styles.butMessContainer}>
                    {labelCoursePaymentsListItemFailedDescription()}
                  </div>
                  <div
                    className={styles.startBut}
                    onClick={onRetryButtonPressCb}
                  >
                    {labelCoursePaymentsListItemRetryButton()}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(CoursePaymentsListItem);
