import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {CustomButton} from '../../../components/CustomForm';
import type {ILoadFile} from '../addFileLocalStore';

import styles from './styles.module.css';

interface ILocalStore {
  loading: boolean;
  src: string;
  type: string;
  preview: any;
  thisData: any;
  addData(newData: any): void;
  loadData(): void;
  getType(): void;
  getPreview(): void;
}

interface Props {
  data: ILoadFile;
  disabled: boolean;
  multiple?: boolean;
  onRemove: () => void;
  onUpload: () => void;
}

const FilePreview: FC<Props> = ({
  data,
  onRemove,
  onUpload,
  multiple = true,
  disabled = false,
}) => {
  const localStore: ILocalStore = useRef(
    observable<ILocalStore>(
      {
        loading: true,
        src: '',
        type: '',
        preview: null,
        thisData: {},
        addData(newData) {
          this.thisData = newData;

          this.loadData();
        },
        loadData() {
          if (!this.thisData) {
            return;
          }

          this.loading = true;
          this.preview = null;
          this.getType();

          const reader = new FileReader();

          if (this.type === 'text') {
            reader.readAsText(this.thisData);
          } else if (this.type === 'image') {
            reader.readAsDataURL(this.thisData);
          } else {
            this.src = false;
            this.loading = false;
          }

          reader.onload = (e: ProgressEvent<FileReader>) => {
            const src = e.target!.result;

            this.src = src;
            this.loading = false;
            this.getPreview();
          };
        },
        getType() {
          if (this.thisData.type.match('text')) {
            this.type = 'text';
          } else if (this.thisData.type.match('image')) {
            this.type = 'image';
          } else {
            this.type = this.thisData.type;
          }
        },
        getPreview() {
          let retEl;

          if (!this.loading) {
            if (this.type === 'text') {
              retEl = <pre className={styles.preview}>{this.src}</pre>;
            } else if (this.type === 'image') {
              retEl = (
                <img
                  alt="preview"
                  src={this.src}
                  className={styles.imagePreview}
                />
              );
            } else {
              retEl = <pre className={styles.preview}>no preview</pre>;
            }
          } else {
            retEl = null;
          }

          this.preview = retEl;
        },
      },
      {
        loading: observable,
        thisData: observable,
        type: observable,
        src: observable,
        preview: observable,
        loadData: action,
        addData: action,
        getType: action,
        getPreview: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.addData(data);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, disabled]);

  return (
    <Observer>
      {() => (
        <div className={styles.prevLine}>
          {localStore.preview}
          <div className={`${styles.spacer} ${styles.fileName}`}>
            {data.name}
          </div>
          <button className={`ovalButton ${styles.button}`} onClick={onRemove}>
            Remove
          </button>
          {multiple ? (
            <CustomButton
              type="button"
              disabled={disabled}
              classButton={`standardButton ${styles.butUpload}`}
              onClick={onUpload}
            >
              Upload
            </CustomButton>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(FilePreview);
