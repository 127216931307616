import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo, useState} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import {
  apiRequest,
  datetimeObjToISOString,
  expandObj,
} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Goal} from '@yourcoach/shared/api/goal';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {Card} from '@yourcoach/shared/api/payment/card';
import type {
  Edition as IEdition,
  ParsedPaymentPlan,
  PaymentPlan,
} from '@yourcoach/shared/api/program';
import {parsePaymentPlan} from '@yourcoach/shared/api/program';
import delay from '@yourcoach/shared/utils/delay';
import {getPriceString} from '@yourcoach/shared/utils/money';

import {
  labelClientsTab,
  labelClientTab,
  labelCoachesTab,
  labelGoalsTab,
  labelInvitesTab,
  labelMembersTab,
  labelPaymentsTab,
  labelStatusLbl,
} from '@src/common/i18n/i18nCourse';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconCalendar, IconCup, IconPrice} from '@src/components/icons';
import MainBanner, {
  CreateGoalBanner,
  RateGoalBanner,
} from '@src/components/MainBanner/MainBanner';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import {expand as courseExpand} from '@src/models/courses';
import {ongoingCoachHistoryRequest} from '@src/modules/Chats/api';

import SeeProgramContext from '../../context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '../../context/useSeeProgramLocalStore';
import type {Course as CourseT} from '../../SquadsTab/SquadsTab';

import styles from './../styles.module.scss';
import ButtonsHeader from './ButtonsHeader/ButtonsHeader';
import CourseClientsTab from './CourseClientsTab/CourseClientsTab';
import CourseCoachesTab from './CourseCoachesTab/CourseCoachesTab';
import CourseGoalsTab from './CourseGoalsTab/CourseGoalsTab';
import CourseInvitesTab from './CourseInvitesTab/CourseInvitesTab';
import CourseMembersTab from './CourseMembersTab/CourseMembersTab';
import CoursePaymentsTab from './CoursePaymentsTab/CoursePaymentsTab';
import UserInfoModal from './UserInfoModal';

const I18N_SCOPE = 'Course';

interface Route {
  key: TTabs;
  title: string;
  subtitle?: string;
  badge?: string | number;
}

type Edition = IEdition & {
  courses: CourseT[];
};

export type TTabs =
  | 'clients'
  | 'coaches'
  | 'invites'
  | 'payments'
  | 'chats'
  | 'members'
  | 'goals'
  | '';

interface ILocalStore {
  _fetchGoals(): Promise<void>;
  course: CourseT | null;
  goals: Goal[] | null;
  courseId: string;
  currentItemMenu: TTabs;
  setCurrentItemMenu(currentItemMenu: string): void;
  firstRenderHelper: boolean;
  tabIndex: number;
  activeTabIndex: number;
  userIsCourseCoach: boolean;
  userIsMainCoach: boolean;
  userMembership:
    | Pick<
        Membership,
        | '_id'
        | 'program_id'
        | 'edition_id'
        | 'course_id'
        | 'card_id'
        | 'status'
        | 'frozen_by'
      >
    | undefined;
  userPaymentMethod: Card | null;
  courseWasStarted: boolean;
  edition: Edition;
  tabs: Route[];
  paymentPlan: (PaymentPlan & ParsedPaymentPlan) | null;
  setCourse(newCourse: CourseT): void;
  isBannerCreateGoal: boolean;
  isBannerRateGoal: boolean;
  isBannerAddGoalClick: boolean;
  setIsBannerAddGoalClick(isBannerAddGoalClick: boolean): void;
  isOfferedProgram: boolean;
  coursePrice: string | undefined;
}

interface Props {
  course: CourseT | null | undefined;
  tab?: string | null;
  userInfo?: any;
}

const Course: FC<Props> = ({course, tab, userInfo}) => {
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [coverageLabelStartDate, setCoverageLabelStartDate] = useState<
    any | string
  >('');
  const [coverageLabelEndDate, setCoverageLabelEndDate] = useState<
    any | string
  >('');
  const {
    stores: {
      currentUserStore,
      membershipStore,
      cardStore,
      courseStore,
      goalStore,
    },
  } = useContext(AppContext);
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);
  const user = currentUserStore.user;
  const cards = cardStore.cards;

  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          async _fetchGoals() {
            if (
              !localStore.userIsCourseCoach &&
              localStore.course &&
              localStore.course.status !== 'archived'
            ) {
              return;
            }

            try {
              const result = await apiRequest({
                method: localStore.userIsCourseCoach
                  ? 'coach.goals.unbound.list'
                  : 'client.goals.list',
                params: {
                  query: [['course_id', '==', localStore.course!._id]],
                },
              });

              runInAction(() => {
                localStore.goals = result._items;
              });

              return result._items;
            } catch (error) {
              setError(error);

              await delay(2000);

              localStore._fetchGoals();
            }
          },
          course: null,
          setCourse(newCourse: CourseT) {
            this.course = newCourse;

            if (localStore.course) {
              const tabIndex = this.tabs.findIndex(item => item.key === tab);

              if (tabIndex >= 0) {
                this.tabIndex = tabIndex;
                this.activeTabIndex = tabIndex;
              }

              localStore._fetchGoals();
            }
          },
          goals: null,
          courseId: '',
          isBannerAddGoalClick: false,
          setIsBannerAddGoalClick(isBannerAddGoalClick: boolean) {
            this.isBannerAddGoalClick = isBannerAddGoalClick;
          },
          currentItemMenu: 'clients',
          setCurrentItemMenu(currentItemMenu: string) {
            this.currentItemMenu = currentItemMenu;
          },
          firstRenderHelper: false,
          tabIndex: 0,
          activeTabIndex: 0,
          get userIsCourseCoach() {
            return !!(
              this.course &&
              user &&
              this.course.coach_ids.includes(user._id)
            );
          },
          get isOfferedProgram() {
            return this.course && this.course.program.offer_id;
          },
          get coursePrice() {
            if (this.course) {
              if (this.course.edition) {
                const paymentPlan = parsePaymentPlan(
                  this.course.edition.payment_plan,
                );

                if (
                  (!this.userIsCourseCoach &&
                    ((this.isOfferedProgram && paymentPlan!.total) ||
                      !this.isOfferedProgram)) ||
                  (this.userIsCourseCoach && !this.isOfferedProgram)
                ) {
                  return getPriceString({
                    price: this.paymentPlan.total,
                    currency: this.paymentPlan.parsedCurrency,
                  });
                }
              }
            }
          },
          get userIsMainCoach() {
            return !!(this.course && user && this.course.user_id === user._id);
          },
          get userMembership() {
            return (
              this.course &&
              membershipStore.membershipLookup.get(this.course._id)
            );
          },
          get userPaymentMethod() {
            if (
              this.userMembership &&
              this.userMembership.card_id &&
              cards[this.userMembership.card_id]
            ) {
              return cards[this.userMembership.card_id];
            }

            return null;
          },
          get courseWasStarted() {
            return (
              this.course &&
              (this.course.status === 'ongoing' ||
                this.course.status === 'archived')
            );
          },
          get edition() {
            return (
              (this.course as CourseT) &&
              ((this.course as CourseT).edition as Edition)
            );
          },
          get tabs() {
            const pushToTabs = (
              key: TTabs,
              title: string,
              subtitle?: string,
            ) => {
              return tabs.push({
                key,
                title,
                subtitle,
              });
            };

            let tabs: Route[] = [];

            if (!this.course) {
              return tabs;
            }

            if (this.userIsCourseCoach) {
              if (this.edition && this.edition.group_size > 1) {
                const clientsCount = _getTabSubtitle('clients');

                pushToTabs(
                  'clients',
                  labelClientsTab(),
                  `${clientsCount ? `${clientsCount}/` : ''}${
                    this.edition.group_size
                  }`,
                );

                pushToTabs('goals', labelGoalsTab());

                if (
                  !(
                    this.course.status === 'archived' ||
                    this.course.status === 'canceled'
                  )
                ) {
                  pushToTabs(
                    'invites',
                    labelInvitesTab(),
                    _getTabSubtitle('invites'),
                  );
                }
              }

              pushToTabs('clients', labelClientTab());

              pushToTabs('goals', labelGoalsTab());
            }

            if (!this.userIsCourseCoach) {
              pushToTabs(
                'coaches',
                labelCoachesTab(),
                this.course!.coach_ids.length > 1
                  ? `${this.course!.coach_ids.length}`
                  : undefined,
              );

              pushToTabs('goals', labelGoalsTab());
            }

            if (
              !this.userIsCourseCoach &&
              this.edition &&
              this.edition.group_size > 1
            ) {
              const membersCount = _getTabSubtitle('members');

              pushToTabs(
                'members',
                labelMembersTab(),
                `${membersCount ? `${membersCount}/` : ''}${
                  this.edition.group_size
                }`,
              );
            }

            if (
              this.courseWasStarted &&
              this.edition &&
              this.edition.payment_plan
            ) {
              if (
                !this.userIsCourseCoach ||
                (this.userIsCourseCoach && !this.edition.offer_id)
              ) {
                pushToTabs('payments', labelPaymentsTab());
              }
            }

            return tabs;
          },
          get paymentPlan() {
            if (this.edition) {
              return parsePaymentPlan(this.edition!.payment_plan);
            } else {
              return null;
            }
          },
          get isBannerCreateGoal() {
            if (this.userIsCourseCoach) {
              if (
                (this.course.status === 'planned' ||
                  this.course.status === 'ongoing') &&
                this.goals &&
                !this.goals.length
              ) {
                return true;
              }
            }

            return false;
          },
          get isBannerRateGoal() {
            if (
              this.goals &&
              this.goals.some(goal =>
                this.userIsCourseCoach
                  ? goal.counters.clients_need_review !== 0
                  : goal.client_attitude === null,
              )
            ) {
              const courseEndData = dayjs(
                datetimeObjToISOString(this.course.end_date),
              );
              const daysDiff = dayjs().diff(courseEndData, 'day');

              if (
                (this.userIsCourseCoach &&
                  this.course.status === 'archived' &&
                  daysDiff < 10) ||
                (!this.userIsCourseCoach && daysDiff > -2 && daysDiff < 20)
              ) {
                return true;
              }
            }

            return false;
          },
        },
        {
          goals: observable,
          course: observable,
          setCourse: action,
          firstRenderHelper: observable,
          tabIndex: observable,
          activeTabIndex: observable,
          currentItemMenu: observable,
          isBannerAddGoalClick: observable,
          setIsBannerAddGoalClick: action,
          setCurrentItemMenu: action,
          userIsCourseCoach: computed,
          userIsMainCoach: computed,
          userMembership: computed,
          userPaymentMethod: computed,
          courseWasStarted: computed,
          edition: computed,
          tabs: computed,
          paymentPlan: computed,
          isBannerCreateGoal: computed,
          isBannerRateGoal: computed,
          isOfferedProgram: computed,
          coursePrice: computed,
        },
      ),
    [cards, membershipStore.membershipLookup, tab, user],
  );

  const _getTabSubtitle = (key: Route['key']) => {
    let subtitle = key;

    return subtitle;
  };

  const disposerCourseId = reaction(
    () => localStore.course,
    () => {
      if (localStore.course) {
        const tabIndex = localStore.tabs.findIndex(
          item => item.key === 'members',
        );

        if (localStore.tabs.length > 0) {
          const _tabIndex = localStore.tabs.findIndex(item => item.key === tab);

          if (_tabIndex >= 0) {
            localStore.setCurrentItemMenu(tab!);
          } else {
            localStore.setCurrentItemMenu(localStore.currentItemMenu);
          }
        } else {
          localStore.setCurrentItemMenu('');
        }

        if (tabIndex >= 0) {
          localStore.tabIndex = tabIndex;
          localStore.activeTabIndex = tabIndex;
        }
      }
    },
  );

  useEffect(() => {
    localStore.setCourse(course!);
    localStore.courseId = course!._id;

    const disposeCourse = reaction(
      () => localStore.course && localStore.course._id,
      () => {
        if (localStore.course) {
          localStore._fetchGoals();
        }
      },
    );

    const disposerCourseStoreUpdating = reaction(
      () => courseStore.updating,
      updating => {
        if (
          localStore.course &&
          updating.success &&
          updating.entity &&
          updating.entity._id === localStore.courseId
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore.course![key] = updating.entity![key];
          });

          localStore.setCurrentItemMenu(localStore.currentItemMenu);
        }
      },
    );

    const disposeGoalCreating = reaction(
      () => goalStore.creating,
      creating => {
        if (
          localStore.course &&
          creating.success &&
          creating.entity &&
          creating.entity.course_id === localStore.courseId
        ) {
          const goals = localStore.goals || [];

          if (!goals.length) {
            const tabIndex = localStore.tabs.findIndex(
              item => item.key === 'goals',
            );

            if (tabIndex >= 0) {
              localStore.tabIndex = tabIndex;
              localStore.activeTabIndex = tabIndex;
            }
          }

          goals.push(creating.entity);

          localStore.goals = goals;
        }
      },
    );

    const disposeGoalStoreCreateBatch = reaction(
      () => goalStore.creatingBatch,
      creating => {
        if (localStore.course && creating.success) {
          const courseGoals = creating.entities.filter(
            entity => entity.course_id === localStore.courseId,
          );

          if (courseGoals.length) {
            const goals = localStore.goals || [];

            if (!goals.length) {
              const tabIndex = localStore.tabs.findIndex(
                item => item.key === 'goals',
              );

              if (tabIndex >= 0) {
                localStore.tabIndex = tabIndex;
                localStore.activeTabIndex = tabIndex;
              }
            }

            courseGoals.forEach(goal => {
              goals.push(goal);
            });

            localStore.goals = goals;
          }
        }
      },
    );

    const disposeGoalStoreDeleting = reaction(
      () => goalStore.deleting,
      deleting => {
        if (
          localStore.course &&
          deleting.success &&
          deleting.entity &&
          deleting.entity.course_id === localStore.courseId
        ) {
          const goals = localStore.goals || [];

          const goalIndex = goals.findIndex(
            item => item._id === deleting.entity!._id,
          );

          if (goalIndex >= 0) {
            goals.splice(goalIndex, 1);
          }

          localStore.goals = goals;
        }
      },
    );

    return () => {
      disposerCourseId();
      disposerCourseStoreUpdating();
      disposeCourse();
      disposeGoalCreating();
      disposeGoalStoreCreateBatch();
      disposeGoalStoreDeleting();
    };
  }, [
    course,
    courseStore,
    disposerCourseId,
    goalStore,
    localStore,
    localStore.course,
    localStore.courseId,
  ]);

  const handleOnClickMenuItem = (key: TTabs) => {
    return () => {
      localStore.setIsBannerAddGoalClick(false);
      localStore.setCurrentItemMenu(key);
    };
  };

  const fetchCourse = async () => {
    const courseId = localStore.courseId;
    const forCoach = localStore.userIsCourseCoach;

    try {
      let courseF: CourseT;

      if (forCoach) {
        courseF = (await courseStore.fetch({
          _id: courseId,
          expand: courseExpand,
        })) as CourseT;
      }

      if (!forCoach) {
        const membership = membershipStore.membershipLookup.get(courseId);

        const error = {
          code: 'membership_not_found',
          message: 'You are not a member of the group',
        };

        if (membership) {
          const expand = {membership: [['course_id', null, courseExpand]]};

          const result = await apiRequest({
            method: 'client.memberships.read',
            params: {
              _id: membership._id,
              expand,
            },
          });

          const expandedMembership = expandObj(result.membership, {
            expand,
            expanded: result._expanded,
          });

          if (!expandedMembership.course) {
            throw error;
          } else {
            courseF = expandedMembership.course;
          }
        }

        if (!membership) {
          throw error;
        }
      }

      runInAction(() => {
        localStore.course = courseF;
      });
    } catch (error) {
      if (error.canceled) {
        return;
      }

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  useEffect(() => {
    localStore.userIsCourseCoach &&
      ongoingCoachHistoryRequest(localStore.courseId).then(res => {
        setCoverageLabelStartDate(
          res?._items[res?._items?.length - 1]?.start_date,
        );
        setCoverageLabelEndDate(res?._items[res?._items?.length - 1]?.end_date);
      });
  }, [localStore.courseId, localStore.userIsCourseCoach]);

  const RenderStatus = () => {
    let status = '';

    if (localStore.course) {
      if (
        localStore.course.status === 'ongoing' ||
        localStore.course.status === 'archived' ||
        localStore.course.status === 'canceled'
      ) {
        status = localStore.course.status;
      }
    }

    return (
      <Observer>
        {() => (
          <div className={styles.statusInnerContainer}>
            {status ? (
              <div
                className={`${styles.statusContainer} ${
                  status === 'ongoing' ? styles.secondaryStatus : styles.status
                }`}
              >
                {labelStatusLbl(status as 'ongoing' | 'archived' | 'canceled')}
              </div>
            ) : null}
            {coverageLabelStartDate?.day &&
              coverageLabelEndDate?.day &&
              dayjs().isAfter(
                dayjs(datetimeObjToISOString(coverageLabelStartDate)),
              ) &&
              dayjs().isBefore(
                dayjs(datetimeObjToISOString(coverageLabelEndDate)),
              ) && <div className={`${styles.coverageStatus}`}>Coverage</div>}
          </div>
        )}
      </Observer>
    );
  };

  const _onCreateGoalBannerPress = () => {
    const goalTab: TTabs = 'goals';

    localStore.setIsBannerAddGoalClick(true);
    localStore.setCurrentItemMenu(goalTab);
  };

  return (
    <Observer>
      {() => (
        <div className={`Course ${styles.Course}`}>
          <div className={`contentContainer ${styles.pl60}`}>
            <div className={styles.headerContainer}>
              <div className={styles.headerContent}>
                <div className={styles.headerTop}>
                  <div className={styles.headerTopTitle}>
                    <h2>
                      {seeProgramLocalStore!.isIndividual
                        ? seeProgramLocalStore?.isCoach &&
                          localStore.course &&
                          localStore.course!.edition.group_size === 1 &&
                          localStore.course!.client_id
                          ? localStore.course!.client.name
                          : t([I18N_SCOPE, 'title', 'individual'])
                        : t([I18N_SCOPE, 'title', 'group'])}
                    </h2>
                    <div className={styles.courseStatus}>
                      <RenderStatus />
                    </div>
                    <div className={styles.headerDescriptionPriceContainer}>
                      {localStore.coursePrice ? (
                        <>
                          <div className={styles.headerDescriptionPriceIcon}>
                            <IconPrice />
                          </div>
                          <div className={styles.headerDescriptionPriceText}>
                            {localStore.coursePrice}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    {seeProgramLocalStore?.isCoach &&
                    localStore.course &&
                    localStore.course!.edition.group_size === 1 &&
                    localStore.course!.client_id ? (
                      <div className={styles.singleUserContainer}>
                        <div className={styles.singleUserAvatar}>
                          <OtherUserProfileImg
                            avatar={localStore.course!.client.avatar}
                            title={localStore.course!.client.name}
                          />
                        </div>
                        <div className={styles.courseClient}>
                          {localStore.course!.client.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.headerDescription}>
                    <div className={styles.descriptionProgramContainer}>
                      <div className={styles.programImage}>
                        <OtherUserProfileImg
                          avatar={seeProgramLocalStore!.program?.avatar}
                          title={seeProgramLocalStore!.program?.title}
                        />
                      </div>
                      <div className={styles.programTitleContainer}>
                        <div className={styles.label}>Program</div>
                        <div className={styles.programName}>
                          {seeProgramLocalStore!.program?.title}
                        </div>
                      </div>
                    </div>
                    <div className={styles.descriptionCourseContainer}>
                      <div className={styles.courseImage}>
                        <IconCalendar className={styles.calendarButt} />
                      </div>
                      <div className={styles.courseTitleContainer}>
                        <div className={styles.label}>
                          {t(['ClientTask', 'course_label'])}
                        </div>
                        <div className={styles.courseName}>
                          {getCourseDurationString(localStore.course)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.headerTopBut}>
                  <ButtonsHeader course={course!} fetchCourse={fetchCourse} />
                </div>
              </div>
              <div className={styles.bannerContainer}>
                {localStore.course ? (
                  <MainBanner>
                    {localStore.isBannerCreateGoal &&
                    dayjs() <=
                      dayjs(datetimeObjToISOString(course!.end_date)) ? (
                      <CreateGoalBanner onClick={_onCreateGoalBannerPress} />
                    ) : null}
                    {localStore.isBannerRateGoal ? <RateGoalBanner /> : null}
                  </MainBanner>
                ) : null}
              </div>
            </div>
          </div>
          <div className={`contentContainer ${styles.pl60}`}>
            <div>
              <div className={styles.menu}>
                {localStore.tabs.map(({key, title}) => (
                  <a
                    className={`customTopMenu ${styles.menuItem} ${
                      localStore.currentItemMenu === key
                        ? `selected ${styles.selected}`
                        : ''
                    }`}
                    key={key}
                    onClick={handleOnClickMenuItem(key)}
                  >
                    <div className={styles.menuItemContainer}>
                      {key === 'goals' ? (
                        <div className={styles.iconContainer}>
                          <div className={styles.iconContent}>
                            <IconCup viewBox="5 5 35 35" />
                          </div>
                        </div>
                      ) : null}
                      <div className={styles.textContainer}>{title}</div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <hr className={styles.first} />
          <div className={`contentContainer ${styles.pl60}`}>
            <div className={styles.tabsContainer}>
              {localStore.course &&
                localStore.currentItemMenu === 'clients' && (
                  <CourseClientsTab
                    handleShowUserModal={() => setShowUserInfoModal(true)}
                    course={localStore.course!}
                    onRefetchCourse={fetchCourse}
                  />
                )}
              {localStore.course && localStore.currentItemMenu === 'goals' && (
                <CourseGoalsTab
                  course={localStore.course!}
                  isBannerAddGoalClick={localStore.isBannerAddGoalClick}
                  onRefetchCourse={fetchCourse}
                />
              )}
              {localStore.course &&
                localStore.currentItemMenu === 'invites' && (
                  <CourseInvitesTab course={localStore.course!} />
                )}
              {localStore.course &&
                localStore.currentItemMenu === 'coaches' && (
                  <CourseCoachesTab course={localStore.course!} />
                )}
              {localStore.course &&
                localStore.currentItemMenu === 'members' && (
                  <CourseMembersTab course={localStore.course!} />
                )}
              {localStore.course &&
                localStore.currentItemMenu === 'payments' && (
                  <CoursePaymentsTab
                    course={localStore.course!}
                    isExternalPayments={
                      !!(
                        localStore.edition &&
                        localStore.edition.payment_plan &&
                        localStore.edition.payment_plan.processing ===
                          'external'
                      )
                    }
                  />
                )}
            </div>
          </div>
          <UserInfoModal
            userInfo={userInfo}
            showUserInfoModal={showUserInfoModal}
            handleShow={() => setShowUserInfoModal(false)}
          />
        </div>
      )}
    </Observer>
  );
};

export default memo(Course);
