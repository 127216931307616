import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {
  labelAddLink,
  labelEditLink,
  labelIncorrectUrl,
  labelLinkUrl,
  labelRequiredFieldError,
} from '@src/common/i18n/i18nLibrary';
import {CustomButton, CustomInput} from '@src/components/CustomForm';

import styles from './styles.module.css';

interface Props {
  onUpload: (nameLink: string, urlLink: string) => void;
  edit?: boolean;
  nameLink?: string;
  url?: string;
}

interface ILocalStore {
  inputs: IInput[];
  isDisable: boolean;
  updateVal: (name: string, val: string) => void;
  setIsValid: (index: number, val: string) => void;
}

interface IInput {
  name: string;
  type: 'input';
  userName: string;
  isRequired: boolean;
  validTest: IValidator[] | null;
  isValid: boolean;
  customClass: string;
  errorText: string;
  val: string;
}

interface IValidator {
  test: RegExp;
  errorText: string;
  isMustMatch: boolean;
}

const inputs: IInput[] = [
  {
    name: 'link',
    type: 'input',
    userName: labelLinkUrl(),
    isRequired: true,
    customClass: styles.LinkUrl,
    validTest: [
      {
        isMustMatch: true,
        test: /^https?:\/\/[\w:/?#[\]@!$&'()*+,;=\-|.~%]+$/,
        errorText: labelIncorrectUrl(),
      },
    ],
    isValid: false,
    errorText: '',
    val: '',
  },
];

const AddLink: FC<Props> = ({onUpload, edit = false, nameLink = ''}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        inputs: inputs,
        isDisable: true,
        updateVal(name: string, val: string) {
          const index = this.inputs.findIndex(
            ({name: findName}) => findName === name,
          );

          (this.inputs[index] as IInput).val = val;

          this.setIsValid(index, val);
        },
        setIsValid(index: number, val: string) {
          const input: IInput = this.inputs[index];

          let valid = true;
          let textErr = '';

          if (input.validTest) {
            input.validTest.forEach(({test, isMustMatch, errorText}) => {
              let isTestComplete;

              if (isMustMatch) {
                isTestComplete = !test.test(val);
              } else {
                isTestComplete = test.test(val);
              }

              if (isTestComplete) {
                valid = false;
                textErr = errorText;
              }
            });
          }

          if (valid && input.isRequired) {
            if (!val.length) {
              valid = false;
              textErr = labelRequiredFieldError();
            }
          }

          if (valid) {
            input.isValid = true;
            input.errorText = '';
          } else {
            input.isValid = false;
            input.errorText = textErr;
          }

          this.isDisable = !this.inputs.every(({isValid}) => isValid);
        },
      },
      {
        inputs: observable,
        isDisable: observable,
        updateVal: action,
        setIsValid: action,
      },
    ),
  ).current;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStore.updateVal(e.target.name, e.target.value);
  };

  const handleOnClick = () => {
    onUpload('', localStore.inputs[0].val);
  };

  return (
    <Observer>
      {() => (
        <div className={`AddLink ${styles.AddLink}`}>
          <h3>{edit ? `${labelEditLink()} '${nameLink}'` : labelAddLink()}</h3>
          <div className={styles.container}>
            {localStore.inputs.map(input => {
              if (input.type === 'input') {
                return (
                  <CustomInput
                    showErrorImmediately={true}
                    type="input"
                    key={input.name}
                    label={input.userName}
                    name={input.name}
                    customClassLabel="standardLabel"
                    customClassInput="standardInput border"
                    value={input.val}
                    customClassCont={input.customClass}
                    error={input.errorText}
                    onChange={handleOnChange}
                  />
                );
              }
            })}
          </div>
          <CustomButton
            type="button"
            disabled={localStore.isDisable}
            onClick={handleOnClick}
          >
            {edit ? labelEditLink() : labelAddLink()}
          </CustomButton>
        </div>
      )}
    </Observer>
  );
};

export default memo(AddLink);
