import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InView from 'react-intersection-observer';
import {Link, Redirect} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {autorun} from 'mobx';

import type {
  ApiRpcQuery,
  ApiRpcRequestParams,
  CollectionStore,
} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Follower} from '@yourcoach/shared/api/follower';
import type {Membership as IMembership} from '@yourcoach/shared/api/membership';
import type {Program} from '@yourcoach/shared/api/program';
import type {User as IUser, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';
import CoachIcon from '@yourcoach/shared/assets/icons/coach.svg';
import {logger} from '@yourcoach/shared/utils/logger';

import getQueryParams from '@src/common/getURLParams';
import {CustomSearch} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import Practice from '@src/components/Practice/Practice';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import {MainContainerWithMenu} from '@src/layouts';

import {PathBuilderService} from '../../v2/services/PathBuilderService';

import styles from './styles.module.css';

interface Section {
  title?: string;
  isSlider?: boolean;
  data: Coach[][];
}

interface FCoach extends Follower {
  coach: Coach | null;
}

type Coach = IUser & UserExpanded;

type ExpandedCoach = (
  | IUser
  | (Partial<IUser> & Required<Pick<IUser, 'name'>>)
) &
  UserExpanded;

type Membership = IMembership & {
  course?: {
    expanded_coaches: ExpandedCoach[];
  };
  program?: Program | null;
};

type SearchParams = {
  c?: string;
  t?: string;
  s?: string;
};

type Props = {};

const LIMIT = 50;

const I18N_SCOPE = 'Coaches';

const CoachesPage: React.FC<Props> = () => {
  const {
    stores: {currentUserStore, categoryStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const [sections, setSections] = useState<Section[]>([]);
  const [keyTab, setKeyTab] = useState<String>('coaches');
  const [query, setQuery] = useState<string>('');
  const [categories, setCategories] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [shouldRenderStub, setShouldRenderStub] = useState(false);
  const [userIsCoach, setUserIsCoach] = useState(
    !!(user && user.roles.includes('coach')),
  );
  const [isSearching, setIsSearching] = useState(false);

  const defaultParams = useRef<ApiRpcRequestParams>({
    limit: LIMIT,
  });

  const isMounted = useRef(false);

  const fetchTimeout = useRef<number>();

  const coachesStore = useRef<CollectionStore<Coach>>(
    createCollectionStore({
      method: 'client.users.list',
      params: {
        ...defaultParams.current,
        sort: [['coach_scores', -1]],
        query: [['deleted', '==', null]],
      },
    }),
  );

  const followingStore = useRef<CollectionStore<FCoach>>(
    createCollectionStore({
      method: 'client.followers.list',
      params: {
        ...defaultParams.current,
        query: [['client_deleted', '==', null]],
      },
    }),
  );

  const myMembershipsStore = useRef<CollectionStore<Membership>>(
    createCollectionStore({
      method: 'client.memberships.list',
      params: {
        query: (
          [
            user ? ['user_id', '==', user._id] : null,
            [
              'status',
              '!in',
              ['requested', 'proposed', 'archived'] as Membership['status'][],
            ],
          ] as ApiRpcQuery[]
        ).filter(Boolean),
        expand: {
          membership: [
            'program_id',
            [
              'course_id',
              null,
              {
                course: [
                  [
                    'coach_ids->expanded_coaches',
                    {name: 'Coach not found'},
                    userExpand,
                  ],
                ],
              },
            ],
          ],
        },
      },
    }),
  );

  const searchStore = useRef(
    createCollectionStore({
      method: 'client.users.search',
      params: defaultParams.current,
    }),
  );

  const followersSearchStore = useRef(
    createCollectionStore({
      method: 'client.followers.search',
      params: defaultParams.current,
    }),
  );

  const computedSearchStore = useMemo(
    () => (userIsCoach ? searchStore.current : followersSearchStore.current),
    [userIsCoach],
  );

  const computedCoachesStore = useMemo(
    () => (userIsCoach ? coachesStore.current : followingStore.current),
    [userIsCoach],
  );

  const store = useMemo(() => {
    return query || categories.length
      ? computedSearchStore
      : computedCoachesStore;
  }, [query, categories, computedCoachesStore, computedSearchStore]);

  const searchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (shouldSearch) {
      setIsSearching(true);

      searchTimeout.current = setTimeout(async () => {
        try {
          await fetchData(true);
        } finally {
          setIsSearching(false);
        }
      }, 500);
    } else if (!query || categories.length) {
      setIsSearching(false);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, categories]);

  useEffect(() => {
    if (!userIsCoach) {
      categories.length ? setCategories([]) : null;
      keyTab === 'mentors' ? setKeyTab('coaches') : null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, keyTab]);

  useEffect(() => {
    const searchParams: SearchParams = getQueryParams(document.location.search);

    const searchQuery: string | undefined = searchParams.s;
    const searchTab: string | undefined = searchParams.t;
    const searchCategory: string | undefined = searchParams.c;

    searchQuery ? setQuery(searchQuery) : setQuery('');
    searchTab ? setKeyTab(searchTab) : setKeyTab('coaches');

    if (searchCategory) {
      const searchCategoryArray: string[] = searchCategory?.split(',');

      setCategories(searchCategoryArray);
    } else {
      setCategories([]);
    }

    const storesClear = [
      searchStore.current.clear,
      followersSearchStore.current.clear,
      coachesStore.current.clear,
      followingStore.current.clear,
      myMembershipsStore.current.clear,
    ];

    isMounted.current = true;

    return () => {
      isMounted.current = false;
      storesClear.forEach(clear => clear());

      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const dispose = autorun(() => {
      if (query.length !== 1 || categories.length) {
        const currentSections: Section[] = new Array(30);

        let toFilterOtherCoaches: Coach[] = [];

        switch (keyTab) {
          case 'coaches':
            if (
              !(
                computedCoachesStore &&
                myMembershipsStore.current &&
                computedCoachesStore.isLoaded &&
                myMembershipsStore.current.isLoaded
              )
            ) {
              return currentSections.filter(Boolean);
            }

            if (
              myMembershipsStore.current.items.length &&
              !query &&
              !categories.length
            ) {
              const memberships = myMembershipsStore.current.items.slice();
              const coaches: {[id: string]: Coach} = {};

              memberships.forEach(membership => {
                if (membership.course) {
                  membership.course.expanded_coaches.forEach(coach => {
                    if (coach._id) {
                      coaches[coach._id] = {
                        ...coach,
                        // @ts-ignore
                        isLimitedEdition: !!membership.program?.offer_id,
                      } as Coach;
                    }
                  });
                }
              });

              const sectionData = Object.keys(coaches).map(id => coaches[id]);

              toFilterOtherCoaches = sectionData.slice();

              if (sectionData.length) {
                currentSections[0] = {
                  title: t([I18N_SCOPE, 'my_coaches_section_header']),
                  isSlider: true,
                  data: [sectionData],
                };
              }
            }
            break;
          default:
            break;
        }

        if (store && store.hasItems) {
          const data: Coach[][] = [];

          let items = store.items
            .slice()
            // @ts-ignore
            .map((item: Coach | FCoach) => ({
              ...((item as FCoach).coach_id ? (item as FCoach).coach : item),
              isLimitedEdition: (item as FCoach).coach_id
                ? (item as FCoach).is_matched
                : false,
            })) as Coach[];

          if (user && userIsCoach && !query) {
            items.unshift(user);
          }

          items = items.filter(
            coach => !toFilterOtherCoaches.find(item => item._id === coach._id),
          );

          let chunk: Coach[];

          while (items.length > 0) {
            chunk = items.splice(0, items.length);

            data.push(chunk);
          }

          if (data.length) {
            currentSections[5] = {
              title:
                currentSections.filter(Boolean).length &&
                !query &&
                !categories.length
                  ? t([I18N_SCOPE, 'other_coaches_section_header'])
                  : '',
              data,
            };
          }
        }

        setSections(currentSections.filter(Boolean));
      }

      setUserIsCoach(!!(user && user.roles.includes('coach')));

      const urlSearch = query ? `&s=${query}` : '';
      const urlCategory = categories.length ? `&c=${categories}` : '';

      if (isMounted.current) {
        userIsCoach
          ? window.history.pushState(
              null,
              '',
              `/community?t=${keyTab}${urlCategory}${urlSearch}`,
            )
          : window.history.pushState(
              null,
              '',
              `/my-coaches?t=${keyTab}${urlCategory}${urlSearch}`,
            );
      }

      setIsFetching(
        keyTab === 'coaches'
          ? !computedCoachesStore.isLoaded ||
              computedCoachesStore.isFetching ||
              !myMembershipsStore.current.isLoaded ||
              myMembershipsStore.current.isFetching
          : !computedCoachesStore.isLoaded || computedCoachesStore.isFetching,
      );

      setShouldRenderStub(
        keyTab === 'coaches'
          ? computedCoachesStore.isLoaded &&
              !computedCoachesStore.isFetching &&
              myMembershipsStore.current.isLoaded &&
              !myMembershipsStore.current.isFetching
          : computedCoachesStore.isLoaded && !computedCoachesStore.isFetching,
      );
    });

    return dispose;
  }, [
    computedCoachesStore,
    keyTab,
    query,
    store,
    user,
    categories,
    userIsCoach,
  ]);

  const storeParams = useMemo(() => {
    const params: ApiRpcRequestParams = {};

    if (userIsCoach) {
      params.query = params.query || [];

      params.query.push(['roles', 'in', ['coach']]);
      params.query.push(['is_hidden', '==', false]);
    }

    if (keyTab === 'mentors') {
      params.query = params.query || [];

      params.query.push(['roles', 'in', ['mentor']]);
    }

    params.expand = userIsCoach
      ? userExpand
      : {
          follower: [['coach_id', null, userExpand]],
        };

    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, computedCoachesStore, userIsCoach, user, keyTab, categories]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    clearTimeout(fetchTimeout.current);

    fetchTimeout.current = +setTimeout(() => {
      fetchData();
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeParams]);

  const shouldSearch = useMemo(
    () => categories.length || query.length >= 2,
    [query, categories],
  );

  const fetchData = useCallback(
    async (silent = false) => {
      if (!isMounted.current) {
        return;
      }

      try {
        if (!computedCoachesStore.isLoaded && store === computedSearchStore) {
          await computedCoachesStore.fetch({
            ...storeParams,
          });

          if (keyTab === 'coaches') {
            await myMembershipsStore.current.fetch();
          }
        }

        if (
          !query &&
          !categories.length &&
          keyTab === 'coaches' &&
          !myMembershipsStore.current.isFetching
        ) {
          await myMembershipsStore.current.fetch().catch(() => {
            // TODO: Log error
          });
        }

        if ((query && shouldSearch) || !query) {
          if (store === computedSearchStore && query) {
            storeParams.query = storeParams.query || [];

            storeParams.query.push([
              userIsCoach
                ? 'coach_title:3,coach_description:2,name'
                : 'coach_title:3,coach_description:2,client_name',
              'fulltext',
              `${query}*`,
            ]);
          }

          if (store === computedSearchStore && categories.length) {
            storeParams.query = storeParams.query || [];

            storeParams.query.push([
              'coach_categories.category_id',
              'in',
              categories,
            ]);
          }

          await store.fetch(
            {
              ...storeParams,
              limit: Math.max(store.items.length, LIMIT),
            },
            {silent},
          );
        }
      } catch (error) {
        // TODO: Log error
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      computedCoachesStore,
      keyTab,
      query,
      shouldSearch,
      store,
      storeParams,
      categories,
    ],
  );

  const onQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.currentTarget.value;

      setQuery(text);
    },
    [],
  );

  const onTabClick = useCallback((tabTitle: string) => {
    const storesClear = [
      searchStore.current.clear,
      followersSearchStore.current.clear,
      coachesStore.current.clear,
      followingStore.current.clear,
      myMembershipsStore.current.clear,
    ];

    storesClear.forEach(clear => clear());
    setKeyTab(tabTitle);
  }, []);

  const activeTabStyle = useMemo(
    () => (keyTab === 'coaches' ? `${styles.activeTab}` : `${styles.tabs}`),
    [keyTab],
  );

  const tabStyle = useMemo(
    () => (keyTab !== 'coaches' ? `${styles.activeTab}` : `${styles.tabs}`),
    [keyTab],
  );

  const onCategoryClick = (selectedCategory: string, index: number) => {
    const isDel = index !== -1;

    if (isDel) {
      setCategories(prevCategory => [
        ...prevCategory.slice(0, index),
        ...prevCategory.slice(index + 1),
      ]);
    } else {
      setCategories(prevCategory => [...prevCategory, ...selectedCategory]);
    }
  };

  const fetchMoreData = useCallback(async () => {
    if (store.hasMore) {
      try {
        await store.fetch(
          {
            ...storeParams,
            offset: store.items.length,
          },
          {
            silent: true,
          },
        );
      } catch (error) {
        logger.error(error);
      }
    }
  }, [store, storeParams]);

  const onPaginationInViewChange = useCallback(
    isInView => {
      if (isInView) {
        fetchMoreData();
      }
    },
    [fetchMoreData],
  );

  if (userIsCoach) {
    return (
      <Redirect
        to={{
          pathname: `${url}/${tabs[0].path}`,
        }}
      />
    );
  }

  return (
    <MainContainerWithMenu allHeight isWithNewCrumbs>
      <div className={`Coaches ${styles.Coaches}`}>
        <div className={styles.tabsPanel}>
          <div className={styles.headerContainer}>
            <div className={styles.tabsContainer}>
              <div
                className={activeTabStyle}
                onClick={() => onTabClick('coaches')}
              >
                {t([I18N_SCOPE, 'coaches_tab'])}
              </div>
              {userIsCoach ? (
                <div className={tabStyle} onClick={() => onTabClick('mentors')}>
                  {t([I18N_SCOPE, 'mentors_tab'])}
                </div>
              ) : null}
            </div>
            <div className={styles.menuSearchContainer}>
              <CustomSearch
                label="Search practice"
                onChange={onQueryChange}
                classContainer={`searchContainer ${styles.searchContainer}`}
                classInput={styles.searchContainerInput}
                className="search"
                classIcon="icon"
                classIconContainer="iconContainer"
                value={query}
              />
            </div>
          </div>
        </div>
        {userIsCoach ? (
          <div className={styles.categories}>
            {
              <div
                onClick={
                  categories.length ? () => setCategories([]) : undefined
                }
                className={
                  !categories.length
                    ? `${styles.category} ${styles.all} ${styles.selected}`
                    : `${styles.category} ${styles.all}`
                }
              >
                <span>{t([I18N_SCOPE, 'all_categories_label'])}</span>
              </div>
            }
            {categoryStore.categories.map(item => {
              const index = categories.findIndex(
                localCategory => localCategory === item._id,
              );

              const isSelected = index >= 0;

              return (
                <div
                  onClick={() => onCategoryClick(item._id, index)}
                  className={`${styles.category} ${
                    isSelected ? styles.selected : ''
                  }`}
                  key={item._id}
                >
                  <span>{item.title}</span>
                </div>
              );
            })}
          </div>
        ) : null}
        <div className={styles.list}>
          {isFetching || isSearching ? (
            <Loader />
          ) : !sections.length && shouldRenderStub ? (
            <div className={styles.noResultsContainer}>
              <NoResultsHeader
                icon={CoachIcon}
                text={
                  userIsCoach
                    ? t([
                        I18N_SCOPE,
                        'no_results_label',
                        'for_coach',
                        `${keyTab}_tab`,
                      ])
                    : t([I18N_SCOPE, 'no_results_label', 'for_client'])
                }
              />
            </div>
          ) : (
            sections.map((section: Section) => {
              return (
                <div key={section.title} className={styles.section}>
                  {section.title ? (
                    <div className={styles.sectionTitle}>{section.title}</div>
                  ) : null}
                  <div className={styles.sectionContent}>
                    <div className={styles.practiceContainer}>
                      {section.data[0].map((coach: Coach) => {
                        const nextLocation = PathBuilderService.toPractice(
                          {
                            slug: coach.slug,
                            id: coach._id,
                          },
                          {
                            from: window.location.pathname,
                          },
                        );

                        return (
                          <Link
                            key={coach._id}
                            to={nextLocation}
                            className={styles.practiceLink}
                          >
                            <Practice
                              title={coach.coach_title}
                              coachName={coach.name}
                              practiceImage={coach.coach_logo}
                              // @ts-ignore
                              isLimitedEdition={coach.isLimitedEdition}
                            />
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <InView as="div" threshold={0} onChange={onPaginationInViewChange}>
            <Observer>
              {() => (store.hasMore ? <Loader size={50} /> : null)}
            </Observer>
          </InView>
        </div>
      </div>
    </MainContainerWithMenu>
  );
};

export default React.memo(CoachesPage);
