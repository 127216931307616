import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Img as Image} from 'react-image';
import {Observer} from 'mobx-react';

import {getFileSrc} from '@yourcoach/shared/api/media/file';

import AppContext from '@src/context/App';
import {
  getColorByString,
  getFirstSymbols,
  lightenDarkenColor,
} from '@src/utils';

import AvatarLoader from '../AvatarLoader/AvatarLoader';

import styles from './styles.module.css';

interface Props {
  titleFontSize?: string;
}

const UserProfileImg: FC<Props> = ({titleFontSize = '14px'}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const differenceGradientColor = -0.2;
  const directionGradient = 'to bottom';
  const isBGGradient = true;

  const getBackGround = () => {
    if (
      currentUserStore &&
      currentUserStore.user &&
      currentUserStore.user.name !== ''
    ) {
      const firstColor = getColorByString(currentUserStore.user.name);

      if (isBGGradient) {
        const secondColor = lightenDarkenColor(
          firstColor,
          differenceGradientColor,
        );

        return {
          background: `linear-gradient(${directionGradient}, ${firstColor}, ${secondColor})`,
        };
      } else {
        return {
          backgroundColor: `${firstColor}`,
        };
      }
    } else {
      return undefined;
    }
  };

  const getTitle = () => {
    if (
      currentUserStore.user &&
      currentUserStore.user.name &&
      currentUserStore.user.name !== ''
    ) {
      return getFirstSymbols(currentUserStore.user.name);
    } else {
      return '';
    }
  };

  return (
    <Observer>
      {() => (
        <div
          className={`UserProfileImg ${styles.UserProfileImg}`}
          style={
            currentUserStore.user && currentUserStore.user.avatar === null
              ? getBackGround()
              : {}
          }
        >
          {currentUserStore.user && currentUserStore.user.avatar && (
            <Image
              src={getFileSrc(currentUserStore.user.avatar, 250).url || ''}
              loader={<AvatarLoader />}
              unloader={
                <div className={styles.UserProfileImg} style={getBackGround()}>
                  <div
                    className={styles.AvatarTitle}
                    style={{fontSize: titleFontSize}}
                  >
                    {getTitle()}
                  </div>
                </div>
              }
            />
          )}
          {currentUserStore.user && currentUserStore.user.avatar === null && (
            <div
              className={styles.AvatarTitle}
              style={{fontSize: titleFontSize}}
            >
              {getTitle()}
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(UserProfileImg);
