import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc, SUPPORTED_FORMATS} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';

export interface ILinkExpanded {
  resource?: IFile | null;
}

export type ILink = Link & ILinkExpanded;

export type TSystemFolder = {
  _id: string;
  name: string;
  isSystem?: boolean;
  isStarred?: boolean;
  isUploads?: boolean;
  isCourses?: boolean;
  program_id?: string;
  isProgram?: boolean;
  type?: 'folder' | 'link';
  onPress?: () => void;
};

export type TTypesFiles = {
  type?: 'folder' | 'link' | 'url';
};

export type TListDataItem = TSystemFolder | ILink | IFile;

export type TFileWithLink = IFile & {
  link: Link;
  resource: null;
};

export const linkExpand = {
  link: ['resource_id'],
};

export interface LinkExpanded {
  resource?: IFile | null;
}

export const getFilePressHandler = (file: IFile | null | undefined) => {
  if (!file) {
    return () => {};
  }

  const {
    url,

    mimetype,
  } = getFileSrc(file);

  if (SUPPORTED_FORMATS.document.mimetypes.includes(mimetype)) {
    return () => {
      if (url) {
        window.open(url);
      }
    };
  }

  if (file.categories.includes('image')) {
    return () => {
      if (url) {
        window.open(url);
      }
    };
  }

  return () => {
    if (url) {
      window.open(url);
    }
  };
};
