import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {runInAction} from 'mobx';

import {dateObjToDateString, dateStringToDateObj} from '@yourcoach/shared/api';
import type {PrivateProfile, User} from '@yourcoach/shared/api/user';
import {logger} from '@yourcoach/shared/utils/logger';
import {
  getEmailSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import {MODAL_STICKY_FOOTER_CLASS_NAME} from '@src/components/ModalNew';
import Select from '@src/components/Select';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import styles from './UserPrivateProfile.module.css';

export const I18N_SCOPE = 'UserPrivateProfile';

const DATE_FORMAT = 'YYYY-MM-DD';
const MAXIMUM_DOB = dayjs()
  .subtract(3, 'year')
  .endOf('year')
  .format(DATE_FORMAT);
const MINIMUM_DOB = dayjs()
  .subtract(100, 'year')
  .startOf('year')
  .format(DATE_FORMAT);

export type Props = {
  requiredFields?: (keyof PrivateProfile)[];
  onSuccess?: () => void;
};

const RequiredLabel = () => (
  <span className={styles.requiredFieldLabel}>{' * '}</span>
);

const UserPrivateProfile: React.FC<Props> = ({requiredFields, onSuccess}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const [dob, setDob] = useState('');
  const [dobError, setDobError] = useState('');
  const [gender, setGender] = useState<User['gender']>();
  const [genderError, setGenderError] = useState('');

  const fields = useRef({
    firstName: createHtmlInputField('firstName', {
      validationRule: {
        max: 255,
        type: 'string',
        optional: true,
        messages: {
          required: t([I18N_SCOPE, 'first_name_required_error']),
          stringMax: t([I18N_SCOPE, 'first_name_max_length_error'], {
            maxLength: 255,
          }),
        },
      },
      defaultValue: '',
    }),
    middleName: createHtmlInputField('middleName', {
      validationRule: {
        max: 255,
        type: 'string',
        optional: true,
        messages: {
          required: t([I18N_SCOPE, 'middle_name_required_error']),
          stringMax: t([I18N_SCOPE, 'middle_name_max_length_error'], {
            maxLength: 255,
          }),
        },
      },
      defaultValue: '',
    }),
    lastName: createHtmlInputField('lastName', {
      validationRule: {
        max: 255,
        type: 'string',
        optional: true,
        messages: {
          required: t([I18N_SCOPE, 'last_name_required_error']),
          stringMax: t([I18N_SCOPE, 'last_name_max_length_error'], {
            maxLength: 255,
          }),
        },
      },
      defaultValue: '',
    }),
    email: createHtmlInputField('email', {
      validationRule: {
        ...getEmailSchema(),
        optional: true,
      },
      defaultValue: '',
    }),
    phone: createHtmlInputField('phone', {
      validationRule: {
        type: 'string',
        pattern: /^\+\d*$/,
        min: 5,
        max: 16,
        optional: true,
        messages: {
          required: t([I18N_SCOPE, 'phone_required_error']),
          stringPattern: t([I18N_SCOPE, 'phone_format_error']),
          stringMin: t([I18N_SCOPE, 'phone_not_valid']),
          stringMax: t([I18N_SCOPE, 'phone_not_valid']),
        },
      },
      defaultValue: '',
    }),
    location: createHtmlInputField('location', {
      validationRule: {
        max: 255,
        type: 'string',
        optional: true,
        messages: {
          required: t([I18N_SCOPE, 'location_required_error']),
          stringMax: t([I18N_SCOPE, 'location_max_length_error'], {
            maxLength: 255,
          }),
        },
      },
      defaultValue: '',
    }),
  }).current;

  useEffect(() => {
    const {user} = currentUserStore;

    if (user && requiredFields) {
      const {
        first_name,
        middle_name,
        last_name,
        email,
        phone,
        date_of_birth,
        gender: genderFromProfile,
        location,
      } = user.private_profile;

      runInAction(() => {
        fields.firstName.value = first_name || fields.firstName.value;
        fields.firstName.validationRule!.optional =
          !requiredFields.includes('first_name');

        fields.middleName.value = middle_name || fields.middleName.value;
        fields.middleName.validationRule!.optional =
          !requiredFields.includes('middle_name');

        fields.lastName.value = last_name || fields.lastName.value;
        fields.lastName.validationRule!.optional =
          !requiredFields.includes('last_name');

        fields.email.value = email || fields.email.value;
        fields.email.validationRule!.optional =
          !requiredFields.includes('email');

        fields.phone.value = phone || fields.phone.value;
        fields.phone.validationRule!.optional =
          !requiredFields.includes('phone');

        // TODO: Add coords
        fields.location.value = location || fields.location.value;
        fields.location.validationRule!.optional =
          !requiredFields.includes('location');

        if (date_of_birth) {
          setDob(dayjs(dateObjToDateString(date_of_birth)).format(DATE_FORMAT));
        }

        setGender(genderFromProfile);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDobChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDobError('');
    setDob(e.currentTarget.value);
  }, []);

  const onClearDobButtonClick = useCallback(() => {
    setDob('');
  }, []);

  const onGenderChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setGenderError('');
      // @ts-ignore
      setGender(e.currentTarget.value);
    },
    [],
  );

  const onClearGenderButtonClick = useCallback(() => {
    setGender(null);
  }, []);

  const updatePrivateProfile = useCallback(async () => {
    try {
      showOverlay();

      await currentUserStore.update({
        private_profile: {
          first_name: fields.firstName.value.trim() || null,
          middle_name: fields.middleName.value.trim() || null,
          last_name: fields.lastName.value.trim() || null,
          email: fields.email.value.trim() || null,
          phone: fields.phone.value.trim() || null,
          gender,
          date_of_birth: dob
            ? dateStringToDateObj(dayjs(dob).format(DATE_FORMAT))
            : null,
          location: fields.location.value.trim() || null,
        },
      });

      logger.event('user_private_profile_updated');

      hideOverlay();

      onSuccess && onSuccess();
    } catch (error) {
      hideOverlay();

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  }, [
    onSuccess,
    showOverlay,
    hideOverlay,
    currentUserStore,
    dob,
    gender,
    fields.firstName.value,
    fields.middleName.value,
    fields.lastName.value,
    fields.phone.value,
    fields.email.value,
    fields.location.value,
  ]);

  const fieldIsRequired = useCallback(
    (field: keyof PrivateProfile) => {
      return (requiredFields || []).includes(field);
    },
    [requiredFields],
  );

  const onSaveButtonClick = useCallback(async () => {
    if (!gender && fieldIsRequired('gender')) {
      setGenderError(t([I18N_SCOPE, 'gender_required_error']));
    }

    if (!dob && fieldIsRequired('date_of_birth')) {
      setDobError(t([I18N_SCOPE, 'dob_required_error']));
    }

    const fieldsAreValid = validateFields(fields) && !genderError && !dobError;

    if (fieldsAreValid) {
      updatePrivateProfile();
    }
  }, [
    dob,
    dobError,
    fieldIsRequired,
    fields,
    gender,
    genderError,
    updatePrivateProfile,
  ]);

  return (
    <div className={classNames('UserPrivateProfile', styles.Component)}>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.first_name')}:`}
          {fieldIsRequired('first_name') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.firstName.ref}
              value={fields.firstName.value}
              placeholder={t([I18N_SCOPE, 'first_name_text_input_placeholder'])}
              onChange={fields.firstName.onChange}
              error={fields.firstName.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.middle_name')}:`}
          {fieldIsRequired('middle_name') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.middleName.ref}
              value={fields.middleName.value}
              placeholder={t([
                I18N_SCOPE,
                'middle_name_text_input_placeholder',
              ])}
              onChange={fields.middleName.onChange}
              error={fields.middleName.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.last_name')}:`}
          {fieldIsRequired('last_name') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.lastName.ref}
              value={fields.lastName.value}
              placeholder={t([I18N_SCOPE, 'last_name_text_input_placeholder'])}
              onChange={fields.lastName.onChange}
              error={fields.lastName.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.email')}:`}
          {fieldIsRequired('email') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.email.ref}
              value={fields.email.value}
              placeholder={t([I18N_SCOPE, 'email_text_input_placeholder'])}
              onChange={fields.email.onChange}
              error={fields.email.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.phone')}:`}
          {fieldIsRequired('phone') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.phone.ref}
              value={fields.phone.value}
              placeholder={t([I18N_SCOPE, 'phone_text_input_placeholder'])}
              onChange={fields.phone.onChange}
              error={fields.phone.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.gender')}:`}
          {fieldIsRequired('gender') ? <RequiredLabel /> : null}
          {gender ? (
            <span
              className={styles.clearButton}
              onClick={onClearGenderButtonClick}
            >
              {t([I18N_SCOPE, 'clear_button'])}
            </span>
          ) : null}
        </div>
        <div className={styles.inputWrapper}>
          <Select
            value={gender || ''}
            onChange={onGenderChange}
            className={classNames(!gender && 'empty')}
            error={genderError}
          >
            <option disabled value={''}>
              {t([I18N_SCOPE, 'gender_select_placeholder'])}
            </option>
            <option value="male">{t('shared.gender.male')}</option>
            <option value="female">{t('shared.gender.female')}</option>
            <option value="na">{t('shared.gender.na')}</option>
          </Select>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.date_of_birth')}:`}
          {fieldIsRequired('date_of_birth') ? <RequiredLabel /> : null}
          {dob ? (
            <span
              className={styles.clearButton}
              onClick={onClearDobButtonClick}
            >
              {t([I18N_SCOPE, 'clear_button'])}
            </span>
          ) : null}
        </div>
        <TextField
          type="date"
          onChange={onDobChange}
          value={dob}
          min={MINIMUM_DOB}
          max={MAXIMUM_DOB}
          error={dobError}
          className={classNames(!dob && styles.emptyDobField)}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {`${t('shared.private_profile.location')}:`}
          {fieldIsRequired('location') ? <RequiredLabel /> : null}
        </div>
        <Observer>
          {() => (
            <TextField
              ref={fields.location.ref}
              value={fields.location.value}
              placeholder={t([I18N_SCOPE, 'location_text_input_placeholder'])}
              onChange={fields.location.onChange}
              error={fields.location.error || ''}
            />
          )}
        </Observer>
      </div>
      <div
        className={classNames(styles.footer, MODAL_STICKY_FOOTER_CLASS_NAME)}
      >
        <Button onClick={onSaveButtonClick}>
          {t([I18N_SCOPE, 'save_button'])}
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(UserPrivateProfile);
