import {action, observable} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';

import {setError} from '@src/common/setError';
import type {Follower, IUserFollower} from '@src/models/questionnaire';

export interface ILocalStoreQSendUsers {
  LIMIT: number;
  countSelectUser: number;
  loading: boolean;
  setLoading: (newLoading: boolean) => void;
  currentQId: string;
  setCurrentQId: (newId: string) => void;
  currentItemMenu: TItemMenu;
  setCurrentItemMenu: (curItem: TItemMenu) => void;
  usersList: IUserFollower[] | null;
  usersEmailList: string[];
  addEmail: (newEmail: string) => void;
  dellEmail: (delEmail: string) => void;
  followersStore: CollectionStore<Follower> | null;
  setFollowersStore: (newFollowersStore: CollectionStore<Follower>) => void;
  refreshFollowersStore: (silent?: boolean) => void;
  setAddUser: (userFollower: IUserFollower) => void;
  setAddAllUser: (userFollowers: IUserFollower[]) => void;
  setDelUser: (userFollower: IUserFollower) => void;
  closeModalWindow: () => void;
  sentQ: () => void;
  sentQUser: (userId: string) => void;
  sentQEmail: () => void;
  allNull: () => void;
  setCloseWindow: (newCloseModalWindow: () => void) => void;
  clear: () => void;
}

export type TItemMenu = 'byName' | 'byEmail' | 'bySquad';

const localStoreQSendUsers = observable<ILocalStoreQSendUsers>(
  {
    LIMIT: 999,
    countSelectUser: 0,
    closeModalWindow: () => {},
    setCloseWindow(newCloseModalWindow: () => void) {
      this.closeModalWindow = newCloseModalWindow;
    },
    sentQ() {
      const dataSend: {
        questionnaire_id: string;
        emails?: string[];
        user_ids?: string[];
      } = {
        questionnaire_id: this.currentQId,
      };

      if (this.usersEmailList.length > 0) {
        dataSend.emails = this.usersEmailList.slice();
      }

      if (
        this.usersList &&
        this.usersList.some(item => item.isAdded === true)
      ) {
        dataSend.user_ids = this.usersList
          .filter(item => item.isAdded === true)
          .map(
            (userFollower: IUserFollower) => userFollower.follower.client._id,
          );
      }

      const send = async (params: ApiRpcRequestParams) => {
        try {
          const result = await apiRequest({
            method: 'coach.invites.send',
            params,
          });

          result();
        } catch (error) {
          setError(error.message);
        }
      };

      send(dataSend);

      this.closeModalWindow();
    },
    sentQUser(userId: string) {
      const dataSend: {
        questionnaire_id: string;
        emails?: string[];
        user_ids?: string[];
      } = {
        questionnaire_id: this.currentQId,
      };

      dataSend.user_ids = [`${userId}`];

      const send = async (params: ApiRpcRequestParams) => {
        try {
          const result = await apiRequest({
            method: 'coach.invites.send',
            params,
          });

          result();
        } catch (error) {
          setError(error.message);
        }
      };

      send(dataSend);
    },
    sentQEmail() {
      const dataSend: {
        questionnaire_id: string;
        emails?: string[];
        user_ids?: string[];
      } = {
        questionnaire_id: this.currentQId,
      };

      if (this.usersEmailList.length > 0) {
        dataSend.emails = this.usersEmailList.slice();
      }

      const send = async (params: ApiRpcRequestParams) => {
        try {
          const result = await apiRequest({
            method: 'coach.invites.send',
            params,
          });

          result();
        } catch (error) {
          setError(error.message);
        }
      };

      send(dataSend);

      this.usersEmailList = [];
      this.countSelectUser = 0;
    },
    allNull() {
      this.countSelectUser = 0;
      this.usersList = null;
      this.usersEmailList = [];
    },
    loading: false,
    setLoading(newLoading: boolean) {
      this.loading = newLoading;
    },
    currentQId: '',
    setCurrentQId(newId: string) {
      this.currentQId = newId;
    },
    currentItemMenu: 'byName',
    setCurrentItemMenu(curItem: TItemMenu) {
      this.currentItemMenu = curItem;
    },
    usersList: null,
    usersEmailList: [],
    addEmail(newEmail: string) {
      const isExist = this.usersEmailList.some(el => el === newEmail);

      if (!isExist) {
        this.usersEmailList.push(newEmail);
        this.countSelectUser++;
      }
    },
    dellEmail(delEmail: string) {
      const index = this.usersEmailList.findIndex(item => item === delEmail);

      if (index >= 0) {
        this.usersEmailList.splice(index, 1);
        this.countSelectUser--;
      }
    },
    clear() {
      if (this.followersStore) {
        this.followersStore.clear();
      }
    },
    followersStore: null,
    setFollowersStore(newFollowersStore: CollectionStore<Follower>) {
      this.followersStore = newFollowersStore;
    },
    async refreshFollowersStore(silent = false) {
      this.setLoading(true);

      try {
        await this.followersStore.fetch(
          {
            limit: Math.max(this.followersStore.items.length, this.LIMIT),
          },
          {silent},
        );

        this.usersList = this.followersStore!.items.slice().map(
          (follower: Follower) =>
            follower.client_id
              ? {
                  follower,
                  isAdded: false,
                }
              : {
                  follower: {
                    client: follower,
                    client_id: follower._id,
                  },
                  isAdded: false,
                },
        );
        this.setLoading(false);
      } catch (error) {
        this.setLoading(false);
        setError(error.message);
      }
    },
    setAddUser(userFollower: IUserFollower) {
      this.countSelectUser++;
      userFollower.isAdded = true;
    },
    setAddAllUser(userFollowers: IUserFollower[]) {
      userFollowers.forEach(userFollower => {
        this.countSelectUser++;
        userFollower.isAdded = true;
      });
    },
    setDelUser(userFollower: IUserFollower) {
      this.countSelectUser--;
      userFollower.isAdded = false;
    },
  },
  {
    LIMIT: observable,
    countSelectUser: observable,
    currentItemMenu: observable,
    currentQId: observable,
    usersEmailList: observable,
    usersList: observable,
    followersStore: observable,
    setCurrentQId: action,
    setCurrentItemMenu: action,
    setFollowersStore: action,
    refreshFollowersStore: action,
    setAddUser: action,
    setAddAllUser: action,
    setDelUser: action,
    addEmail: action,
    dellEmail: action,
    sentQ: action,
    sentQUser: action,
    sentQEmail: action,
    clear: action,
  },
);

export default localStoreQSendUsers;
