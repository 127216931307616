import type {FC} from 'react';
import React, {memo, useState} from 'react';

import type {ApiRpcRequestParams} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {
  QuestionnaireQuestion,
  QuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';
import IconPrev from '@yourcoach/shared/assets/icons/secondary/Arrow - Left 2.svg';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {labelBack, labelCreate, labelUpdate} from '@src/common/i18n/i18nCommon';
import {labelQuestionsCount} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
// import {CustomButton} from '@src/components/CustomForm';
import IconNext from '@src/components/icons/IconNext/IconNext';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
// import {ColorWrapper} from '@src/layouts';
import {
  LongText,
  MultipleChoice,
  MultipleChoiceOpen,
  SingleChoice,
  SingleChoiceOpen,
} from '@src/modules/Questionnaire/QDetail/TypesInputTemplates';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';
import {PATH_TYPE} from '@src/routes/utils';

import {IconButton} from '../../../../../v2/components/IconButton';
import questionnaireAppStore from '../../../context/qAppStore';
import styles from '../../styles.module.css';

interface Props {
  clickContinue: () => void;
  clickBack: () => void;
}

const Step4: FC<Props> = ({clickBack}) => {
  const [isDisable, setDisable] = useState(false);
  const QUserId = useURLQueryParam('id');
  const redirect = useAppRedirect();
  const {userTemplateQuest: userQ} = questionnaireAppStore!;
  const handleClickPublish = async () => {
    setDisable(true);

    try {
      const sections: QuestionnaireSection[] = userQ!.sections
        .slice()
        .map(section => ({
          title: section.title,
          description: section.description,
          questions: section.questions.slice().map(question => {
            const result: QuestionnaireQuestion = {
              question: question.question,
              type: question.type,
              is_optional: question.is_optional,
            };

            if (question.options && question.options.length) {
              result.options = question.options;
            }

            return result;
          }),
        }));

      const params: ApiRpcRequestParams = {
        is_public: userQ?.is_public,
        avatar_id:
          // @ts-ignore
          userQ?.avatar_id || (userQ?.avatar as IFile)?._id || undefined,
        title: userQ?.title,
        description: userQ?.description,
        sections: [...sections],
      };

      if (QUserId) {
        params._id = QUserId;
      }

      await apiRequest({
        method: `coach.questionnaires.own.${QUserId ? 'update' : 'create'}`,
        params,
      });
      setDisable(false);
      redirect(`/${PATH_TYPE.yourSpace}/${PATH_TYPE.myToolbox}`);
    } catch (error) {
      setError(error.message);
      setDisable(false);
    }
  };

  const handleClickBack = () => {
    clickBack();
  };

  return (
    <div className={`Step4 ${styles.step} ${styles.Step4}`}>
      <div className={styles.header}>
        <div className={styles.title}>{userQ?.title}</div>
        {userQ?.description !== '' && (
          <div className={styles.description}>{userQ?.description}</div>
        )}
        <div className={styles.numQuestions}>
          <div>?</div>
          <span>
            {questionnaireAppStore?.getNumQuestionsToTheUserQuestionnaire}{' '}
            {labelQuestionsCount(
              questionnaireAppStore?.getNumQuestionsToTheUserQuestionnaire,
            )}
          </span>
        </div>
      </div>
      <div className={styles.questionsBody}>
        {userQ?.sections.map(section => (
          <div key={section.id} className={styles.section}>
            <div className={styles.sectionTitle}>{section.title}</div>
            {section.description.length > 0 && (
              <div className={styles.sectionDescription}>
                {section.description}
              </div>
            )}
            <div className={styles.questionsContainer}>
              {section.questions.map((questionItem, index) => {
                switch (questionItem.type) {
                  case ALIAS_TYPES.text:
                    return (
                      <div key={index}>
                        <LongText
                          question={questionItem}
                          classAnswer={styles.textInput}
                        />
                      </div>
                    );
                  case ALIAS_TYPES.singleChoice:
                    return (
                      <div key={index}>
                        <SingleChoice question={questionItem} />
                      </div>
                    );
                  case ALIAS_TYPES.singleChoiceOpen:
                    return (
                      <div key={index}>
                        <SingleChoiceOpen question={questionItem} />
                      </div>
                    );
                  case ALIAS_TYPES.multipleChoice:
                    return (
                      <div key={index}>
                        <MultipleChoice question={questionItem} />
                      </div>
                    );
                  case ALIAS_TYPES.multipleChoiceOpen:
                    return (
                      <div key={index}>
                        <MultipleChoiceOpen question={questionItem} />
                      </div>
                    );
                }
              })}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        {/*<ColorWrapper containerClass={styles.right}>*/}
        {/*  <CustomButton*/}
        {/*    type="button"*/}
        {/*    disabled={isDisable}*/}
        {/*    classButton="QuestionnaireContinueBut"*/}
        {/*    onClick={handleClickPublish}*/}
        {/*  >*/}
        {/*    <span>*/}
        {/*      {questionnaireAppStore?.isEditMode*/}
        {/*        ? labelUpdate()*/}
        {/*        : labelCreate()}*/}
        {/*    </span>*/}
        {/*    <IconNext customClass="IconNext" />*/}
        {/*  </CustomButton>*/}
        {/*</ColorWrapper>*/}
        <View
          style={{
            width: '100%',
            padding: 40,
            // display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            iconFillColor={'icon3'}
            size={'xl'}
            scheme={'gray'}
            onClick={handleClickBack}
            icon={<IconPrev />}
          >
            <Text variant={'medium'} color={'inherit'}>
              {labelBack()}
            </Text>
          </IconButton>
          <IconButton
            onClick={handleClickPublish}
            disabled={isDisable}
            size={'xl'}
            icon={<IconNext />}
          >
            <Text variant={'medium'} color={'inherit'}>
              {questionnaireAppStore?.isEditMode
                ? labelUpdate()
                : labelCreate()}
            </Text>
          </IconButton>
        </View>
      </div>
    </div>
  );
};

export default memo(Step4);
