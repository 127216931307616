import {useContext, useRef} from 'react';

import {action, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Follower as IFollower} from '@yourcoach/shared/api/follower';
import type {Membership} from '@yourcoach/shared/api/membership';
import {membershipStore} from '@yourcoach/shared/api/membership';
import type {Program} from '@yourcoach/shared/api/program';
import type {User as IUser, UserExpanded} from '@yourcoach/shared/api/user';
import delay from '@yourcoach/shared/utils/delay';

import {
  labelInviteExistedMembershipsError,
  labelInviteSuccessMsg,
  labelInviteSuccessMsgWithExistedMemberships,
} from '@src/common/i18n/i18nInvites';
import {labelSharedMsgError} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

export type User = IUser & UserExpanded;

export type Follower = IFollower & {
  client: User | (Partial<User> & Required<Pick<User, 'name'>>);
};

export interface ToItem extends Follower {
  fromSearch?: boolean;
}

export type ToItemEmail = string;

export interface ILocalStoreInvite {
  target: Program | Course | null;
  setCurrentTarget(target: Program | Course): void;
  toItems: ToItem[];
  setToItems(toItems: ToItem[]): void;
  toItemEmail: ToItemEmail[];
  setToItemEmail(toItemEmail: ToItemEmail[]): void;
  onSend?: () => void;
  setCurrentOnSend(onSend: (() => void) | undefined): void;
  isLoaded: boolean;
  setIsLoaded(isLoaded: boolean): void;
  currentTab: TTabs;
  usersStore: CollectionStore<Follower> | null;
  setUsersStore(usersStore: CollectionStore<Follower> | null): void;
  initialUsersStore: CollectionStore<Follower> | null;
  setInitialUsersStore(
    initialUsersStore: CollectionStore<Follower> | null,
  ): void;
  firstRenderHelper: boolean;
  isEditingMessage: boolean;
  setIsEditingMessage(isEditingMessage: boolean): void;
  query: string;
  setQuery(query: string): void;
  queryEmail: string;
  setQueryEmail(queryEmail: string): void;
  toItemsWidths: number[];
  selectedItem: ToItem | null;
  setSelectedItem(selectedItem: ToItem | null): void;
  message: string;
  setMessage(message: string): void;
  error: string;
  setInvite({
    emails,
    user_ids,
    message,
  }: {
    emails: string[];
    user_ids: string[];
    message: string;
  }): Promise<void>;
  getData(): {
    query: string;
    queryEmail: string;
    items: ToItem[];
    itemEmail: ToItemEmail[];
    message: string;
    error: string;
  };
  clear(): void;
  fetchInitialUsersStore(): void;
}

export type TTabs = 'byName' | 'byEmail';

const useLocalStoreInvite = () => {
  const {
    stores: {inviteStore},
  } = useContext(AppContext);

  const store: ILocalStoreInvite | null = useRef(
    observable<ILocalStoreInvite>(
      {
        target: null,
        onSend: undefined,
        toItems: [],
        setToItems(toItems: ToItem[]) {
          this.toItems = toItems;
        },
        toItemEmail: [],
        setToItemEmail(toItemEmail: ToItemEmail[]) {
          this.toItemEmail = toItemEmail;
        },
        message: '',
        setMessage(message: string) {
          this.message = message;
        },
        error: '',
        selectedItem: null,
        setSelectedItem(selectedItem: ToItem | null) {
          this.selectedItem = selectedItem;
        },
        query: '',
        setQuery(query: string) {
          this.query = query;
        },
        queryEmail: '',
        setQueryEmail(queryEmail: string) {
          this.queryEmail = queryEmail;
        },
        isEditingMessage: false,
        setIsEditingMessage(isEditingMessage: boolean) {
          this.isEditingMessage = isEditingMessage;
        },
        toItemsWidths: [],
        firstRenderHelper: false,
        usersStore: null,
        setUsersStore(usersStore: CollectionStore<Follower> | null) {
          this.usersStore = usersStore;
        },
        initialUsersStore: null,
        setInitialUsersStore(
          initialUsersStore: CollectionStore<Follower> | null,
        ) {
          this.initialUsersStore = initialUsersStore;
        },
        setCurrentTarget(target: Program | Course) {
          this.target = target;
        },
        setCurrentOnSend(onSend: (() => void) | undefined) {
          this.onSend = onSend;
        },
        isLoaded: true,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        currentTab: 'byName' as TTabs,
        async setInvite({
          emails = [],
          user_ids = [],
          message = '',
        }: {
          emails: string[];
          user_ids: string[];
          message: string;
        }) {
          try {
            this.setIsLoaded(false);

            const targetType = this.target!._id.split(':')[0];

            let existedMemberships: Membership[] = [];

            if (targetType === 'course') {
              const memberships = await membershipStore.coach.createBatch(
                user_ids.map(userId => ({
                  user_id: userId,
                  course_id: this.target!._id,
                })),
              );

              memberships.forEach(membership => {
                if (
                  !(
                    ['proposed', 'requested'] as Membership['status'][]
                  ).includes(membership.status)
                ) {
                  existedMemberships.push(membership);
                }
              });
            }

            const shouldInviteToProgram =
              targetType === 'program' || !!emails.length;

            if (shouldInviteToProgram) {
              const programInviteRequestParams: {
                program_id: string;
                emails: string[];
                user_ids?: string[];
                message: string;
              } = {
                program_id: this.target!._id,
                emails,
                user_ids,
                message,
              };

              if (targetType === 'course') {
                programInviteRequestParams.program_id = (
                  this.target as Course
                ).program_id;

                delete programInviteRequestParams.user_ids;
              }

              await inviteStore.send(programInviteRequestParams);
            }

            this.setIsLoaded(true);

            let title = labelInviteSuccessMsg(emails.length + user_ids.length);
            let alertMessage = '';

            if (existedMemberships.length) {
              if (user_ids.length === 1) {
                title = labelSharedMsgError();
              } else {
                title = labelInviteSuccessMsgWithExistedMemberships(
                  emails.length + user_ids.length,
                );
              }

              alertMessage = labelInviteExistedMembershipsError(
                existedMemberships.length,
                existedMemberships.map(item => item.user_name).join(', '),
              );
            }

            await getCustomConfirmAlert({
              title: title,
              message: alertMessage,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });

            await delay(500);

            this.onSend && this.onSend();

            //clearTab()
          } catch (error) {
            this.setIsLoaded(true);

            getCustomConfirmAlert({
              title: t(['Common', 'ErrorOccurred']),
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });

            setError(error);
          }
        },
        getData() {
          return {
            query: this.query as string,
            queryEmail: this.queryEmail as string,
            items: this.toItems.slice() as ToItem[],
            itemEmail: this.toItemEmail.slice() as ToItemEmail[],
            message: this.message as string,
            error: this.error as string,
          };
        },
        clear() {
          this.query = '';
          this.queryEmail = '';
          this.toItems = [];
          this.toItemEmail = [];

          this.message = '';
          this.toItemsWidths = [];

          if (this.usersStore) {
            this.usersStore.clear();
            this.initialUsersStore.clear();
            this.fetchInitialUsersStore();
          }
        },
        async fetchInitialUsersStore() {
          if (this.initialUsersStore) {
            this.setIsLoaded(false);
            await this.initialUsersStore.fetch();
            this.setIsLoaded(true);
          }
        },
      },
      {
        target: observable,
        setCurrentTarget: action,
        toItems: observable.shallow,
        setToItems: action,
        toItemEmail: observable.shallow,
        setToItemEmail: action,
        toItemsWidths: observable,
        usersStore: observable,
        setUsersStore: action,
        initialUsersStore: observable,
        setInitialUsersStore: action,
        onSend: observable,
        setCurrentOnSend: action,
        queryEmail: observable,
        setQueryEmail: action,
        query: observable,
        setQuery: action,
        selectedItem: observable,
        setSelectedItem: action,
        message: observable,
        setMessage: action,
        error: observable,
        isEditingMessage: observable,
        setIsEditingMessage: action,
        isLoaded: observable,
        setIsLoaded: action,
        currentTab: observable,
        firstRenderHelper: observable,
        setInvite: action,
        getData: action,
        clear: action,
        fetchInitialUsersStore: action,
      },
    ),
  ).current;

  return store;
};

export default useLocalStoreInvite;
