import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {useHistory} from 'react-router-dom';

import {v4 as uuidv4} from 'uuid';

import {apiRequest} from '@yourcoach/shared/api';
import type {Edition} from '@yourcoach/shared/api/program';
import {createConciergeChannel} from '@yourcoach/shared/api/user/onboarding';

import Button from '@src/components/Button';
import SizeFitText from '@src/components/SizeFitText';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import styles from './styles.module.css';
import VirtualHomeStep from './VirtualHomeStep';

const I18N_SCOPE = 'Onboarding.ready';

const ReadyStep = props => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();
  const lastEditionId = useRef('');

  const userMeta = useMemo(
    () => currentUserStore.user?.metadata,
    [currentUserStore.user?.metadata],
  );

  useEffect(() => {
    const fetchEdition = async () => {
      const editionResult = (await apiRequest({
        method: 'client.onboarding.editions.read',
        params: {
          _id: props.data._program.editions[0]._id,
        },
      })) as {onboarding_edition: Edition};

      lastEditionId.current = editionResult.onboarding_edition._id;
    };

    if (props.data._program) {
      fetchEdition();
    }
  }, [props.data._program]);

  const onContinue = useCallback(() => {
    const accountType = userMeta?.onboarding?.account_type || 'client';

    const createConciergeChat = () => {
      if (accountType === 'employee' || accountType === 'client') {
        return createConciergeChannel(accountType).catch(_reason => {});
      } else {
        return Promise.resolve(null);
      }
    };

    const startOnboarding = () => {
      if (lastEditionId.current) {
        const tasks = props.data._tasks || {};

        return apiRequest({
          method: 'client.onboarding.start',
          params: {
            edition_id: lastEditionId.current,
            goals: [],
            tasks: tasks.map(task => ({
              ...task,
              day: 1,
              description: '',
              duration: 1,
              uuid: task.uuid || uuidv4().replace(/-/g, ''),
            })),
          },
        });
      } else {
        return Promise.resolve(null);
      }
    };

    const updateProfile = currentUserStore.update({
      metadata: {
        ...userMeta,
        onboarding: {
          ...userMeta?.onboarding,
          is_finished: true,
        },
      },
    });

    Promise.all([createConciergeChat(), startOnboarding(), updateProfile]).then(
      () => {
        history.replace('/');
      },
    );
  }, [currentUserStore, history, props.data, userMeta]);

  return (
    <VirtualHomeStep
      title={
        <SizeFitText
          className={styles.finalTitle}
          value={t([I18N_SCOPE, 'title'], {
            name: currentUserStore.user?.name,
          })}
          steps={{
            60: styles.medium,
            80: styles.small,
          }}
        />
      }
      description={
        <div className={styles.largeDescription}>
          {t([I18N_SCOPE, 'description'])}
        </div>
      }
      step={10}
      wideLeft
      buttons={[
        <Button
          onClick={onContinue}
          className={`${styles.wide} ${styles.noBorder} ${styles.inverted}`}
        >
          {t([I18N_SCOPE, 'skip_tour_button'])}
        </Button>,
        t([I18N_SCOPE, 'start_tour_button']),
      ]}
      {...props}
      onContinue={onContinue}
    />
  );
};

export default ReadyStep;
