import type {FC} from 'react';
import React, {memo} from 'react';

import useURLQueryParam from '@src/hooks/useURLQueryParam';

import ListProgram from './ListProgram/ListProgram';
import ListPrograms from './ListPrograms/ListPrograms';

interface Props {}

const ListFoldersPrograms: FC<Props> = () => {
  const prograId = useURLQueryParam('pid');

  return (
    <div className="ListFoldersPrograms">
      {prograId ? <ListProgram /> : <ListPrograms />}
    </div>
  );
};

export default memo(ListFoldersPrograms);
