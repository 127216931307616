import type {Expand} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';

import type {Expanded as CourseExpanded} from '../models/courses';
import {expand as courseExpand} from '../models/courses';

export const expand: Expand = {
  channel: [
    ['resource_id', null, courseExpand],
    [
      'user_ids->members',
      {name: 'User not found'},
      {user: ['avatar_id', 'coach_logo_id']},
    ],
  ],
};

export type ExpandedMember = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) &
  UserExpanded;

export interface Expanded {
  resource?: (Course & CourseExpanded) | null;
  members: ExpandedMember[];
}
