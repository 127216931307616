import {apiRequest} from '@yourcoach/shared/api/index';

const getArchivedMembershipStatus = async () => {
  return await apiRequest({
    method: 'client.memberships.list',
    params: {
      query: [['status', 'in', ['archived']]],
      sort: [['created', -1]],
      expand: {
        membership: ['course_id', 'edition_id'],
      },
    },
  });
};

const getCoachHistoryNull = async course_id => {
  return await apiRequest({
    method: 'client.coach_history.list',
    params: {
      query: [
        ['type', '==', 'coach_coverage'],
        ['status', '==', 'ongoing'],
        ['course_id', '==', course_id],
        ['new_coach_id', '==', null],
      ],
      limit: 51,
      expand: {
        coach_history: [
          'old_coach_id',
          'new_coach_id',
          'client_id',
          'course_id',
        ],
      },
      sort: [['created', -1]],
    },
  });
};

export {getArchivedMembershipStatus, getCoachHistoryNull};
