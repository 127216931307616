import type {ChangeEvent, FC, RefObject} from 'react';
import React, {memo, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

interface Props {
  id?: string;
  ref?:
    | ((instance: HTMLTextAreaElement | null) => void)
    | RefObject<HTMLTextAreaElement>
    | null
    | undefined;
  name?: string;
  hideLabel?: boolean;
  placeholder?: string;
  type: string;
  showErrorImmediately?: boolean;
  required?: boolean;
  value?: string;
  error?: string | null;
  label?: string;
  onCreate?: (
    ref:
      | ((instance: HTMLTextAreaElement | null) => void)
      | React.MutableRefObject<HTMLTextAreaElement | null>
      | null,
  ) => void;
  onBlur?: () => void;
  disabled?: boolean;
  autocomplete?: 'on' | 'off';
  customClassCont?: string;
  customClassInput?: string;
  customClassLabel?: string;
  maxLength?: number;
  customClassError?: string;
  onChange?: ((event?: ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyPress?:
    | ((event: React.KeyboardEvent<HTMLTextAreaElement>) => void)
    | undefined;
  autoFit?: boolean;
}

const CustomTextArea: FC<Props> = React.forwardRef((props: Props, ref) => {
  const {
    error = '',
    id,
    name,
    placeholder,
    required,
    label = '',
    value = '',
    onCreate = () => {},
    onKeyPress = () => {},
    disabled = false,
    hideLabel = false,
    maxLength = 500,
    customClassCont = '',
    customClassInput = '',
    customClassLabel = '',
    customClassError = '',
    autocomplete = 'on',
    showErrorImmediately = false,
    onChange,
    onClick,
    onBlur = () => {},
    autoFit = true,
  } = props;

  const [isTouched, setTouched] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const isError = error !== '' && error !== null;

  useEffect(() => {
    onCreate(ref);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length > maxLength) {
      e.currentTarget.value = e.currentTarget.value.substr(0, maxLength - 1);
    }

    if (onChange) {
      onChange(e);
    }

    setDirty(true);

    if (autoFit) {
      const newHeight = 'height: ' + (e.currentTarget.scrollHeight + 1) + 'px;';

      // @ts-ignore
      var oldStyle = e.currentTarget.attributes.style?.value || '';

      if (oldStyle.indexOf('height:') >= 0) {
        oldStyle = oldStyle.replace(/height: [^;]+;/, newHeight);
      } else {
        oldStyle = newHeight;
      }

      e.currentTarget.setAttribute('style', oldStyle);
    }
  };

  const handleFocus = () => {
    setTouched(true);

    onBlur();
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    if (onClick) {
      onClick(event);
    }
  };

  const classesName = `${isError ? 'invalid' : 'valid'} ${
    value === '' ? 'empty' : 'filled'
  }`;

  return (
    <div
      className={`customTextAreaContainer ${customClassCont}`}
      onClick={handleOnClick}
    >
      <div
        className={`customTextArea ${classesName} ${customClassInput} ${
          isTouched ? 'touched' : 'untouched'
        } ${isDirty ? 'dirty' : 'pristine'}`}
      >
        <textarea
          ref={ref}
          id={id}
          autoComplete={autocomplete}
          name={name}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          required={required}
          value={value}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={handleFocus}
        />
        <label
          className={`${customClassLabel} ${
            hideLabel && value !== '' ? 'hideLabel' : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>
      </div>

      <CSSTransition
        in={
          isError &&
          ((!showErrorImmediately && isTouched && value !== '') ||
            showErrorImmediately)
        }
        unmountOnExit
        timeout={10}
        classNames={{
          enter: 'animate__fadeInDown',
          enterActive: 'animate__fadeInDown',
          enterDone: 'animate__fadeInDown',
          exit: 'animate__fadeOutUp',
          exitActive: 'animate__fadeOutUp',
          exitDone: 'animate__fadeOutUp',
        }}
      >
        <div className={`animate__animated errorContainer ${customClassError}`}>
          <span>{error}</span>
        </div>
      </CSSTransition>
    </div>
  );
});

export default memo(CustomTextArea);
