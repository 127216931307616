import type {FC} from 'react';
import React from 'react';

import {t} from '@src/i18n';

import TrackEditor from './Wellbeing/TrackEditor';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.tracking';

interface Props {
  initialValue: any;
  onChange: (fieldName, value: Object, clearNext: boolean) => void;
  hideButtons: boolean;
  onBack: () => void;
  onContinue: () => void;
  autoNext?: boolean;
  clearNext?: boolean;
  editorRef?: React.RefObject<any>;
  children: React.ReactNode | React.ReactNode[];
}

const TrackingStep: FC<Props> = props => {
  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={6}
      fieldName="targets"
      {...props}
    >
      <TrackEditor />
    </EditorStep>
  );
};

export default TrackingStep;
