import styled from 'styled-components';

import type {SingleDatePickerProps} from '../components/SingleDatePicker';

type SizeAndPositionProps = Partial<SingleDatePickerProps>;

export const DatePickerContainer = styled('div')`
padding: 12px;
position: absolute;
width: ${({width}: SizeAndPositionProps) => width}px;
height: ${({height}: SizeAndPositionProps) => height}px;
right: ${({marginRight}: SizeAndPositionProps) => marginRight}px;
bottom: ${({marginBottom}: SizeAndPositionProps) => marginBottom}px;
background: #FFFFFF;
filter: drop-shadow(0px 4px 20px rgba(1, 1, 1, 0.05));
border-radius: 10px;

.react-datepicker {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #FFFFFF;
    border: none;
    width: 100%;

    &__navigation--previous,
    &__navigation--next {
        width: 24px;
        height: 24px;
        background: transparent;
        border-radius: 4px;
        padding-top: 24px;
    }

    &__navigation--previous {
        padding-right: 4px;
        left: 254px;
    }

    &__navigation--next {
        padding-left: 4px;
        right: 12px;
    }

    &__month-container {
        background: #FFFFFF;
        border: none;
    }

    &__header {
        background-color: #FFFFFF;
        border-bottom: none;
    }

    &__current-month {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        padding-left: 12px;
        text-align: start;
    }

    &__week {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__day-names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 8px 0 16px;
    }

    &__day-name {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
    }

    &__day {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        &--today,
        &--weekend {
            background: transparent;
            border: none;
        }

        &--today {
            color: #171717;
            border-radius: 50%;
            background: transparent;
            border: solid 1px #25D2A2;
            font-weight: 400;
        }

        &--weekend {
            color: #CAD0DC;
        }

        &--highlighted {
            background: transparent;
            border: none;
            color: #FF0000;
        }

        &--selected,
        &--in-range {
            border-radius: 50%;
            background: #25D2A2;
            border: none;
            color: #FFFFFF;
        }

        &--in-selecting-range {
            background: #C9F7EA;
            border: none;
            border-radius: 50%;
            color: #000000;
        }

        &--outside-month,
        &--disabled {
            color: #CAD0DC;
            pointer-events: none;
        }

        &:hover {
            border-radius: 50%;
            background: #25D2A2;
            border: none;
            color: #FFFFFF
        }
    }
}

.current_day {
    display: flex;
    flex-direction: column;
    align-items: center;
}
}`;
