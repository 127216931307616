import type {FC} from 'react';
import React, {memo} from 'react';

import {
  getColorByString,
  getFirstSymbols,
  lightenDarkenColor,
} from '@src/utils';

import styles from './styles.module.css';

interface Props {
  className?: string;
  isBGGradient?: boolean;
  getTitleIconIfNoImage?: boolean;
  titleClassName?: string;
  title?: string | null;
  titleFontSize?: string;
  directionGradient?: string;
  differenceGradientClor?: number;
}

const AvatarLoader: FC<Props> = ({
  className = '',
  titleClassName = '',
  isBGGradient = false,
  directionGradient = 'to left',
  getTitleIconIfNoImage = false,
  differenceGradientClor = -0.2,
  title = '',
  titleFontSize = '18px',
}) => {
  const getBackGround = () => {
    if (title && title !== '') {
      const firstColor = getColorByString(title);

      if (isBGGradient) {
        const secondColor = lightenDarkenColor(
          firstColor,
          differenceGradientClor,
        );

        return {
          background: `linear-gradient(${directionGradient}, ${firstColor}, ${secondColor})`,
        };
      } else {
        return {
          backgroundColor: `${firstColor}`,
        };
      }
    } else {
      return undefined;
    }
  };

  const getTitle = () => {
    if (getTitleIconIfNoImage && title && title !== '') {
      return getFirstSymbols(title);
    } else {
      return '';
    }
  };

  return (
    <div
      className={`AvatarLoader ${styles.AvatarLoader} ${className}`}
      style={getBackGround()}
    >
      {getTitleIconIfNoImage && title && title !== '' ? (
        <div
          className={`${styles.AvatarTitle} ${titleClassName}`}
          style={{fontSize: titleFontSize}}
        >
          {getTitle()}
        </div>
      ) : null}
    </div>
  );
};

export default memo(AvatarLoader);
