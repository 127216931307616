import React from 'react';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';

// import {TaskAndClient as TaskWithClient} from '../../stores/models/TaskModel';
import type {TaskAndClient} from '@yourcoach/shared/stores/single-event';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';

import {Subtitle} from '../../components/Subtitle';
import {TaskStatus} from '../../components/TaskStatus';
import {UserBox} from '../../components/UserBox';
import {I18N_CLIENT_TASK} from '../../utils';

import {Layout} from './components/Layout';
import {ListSkeleton} from './components/skeleton';
import type {Props} from './types';

const ClientsList: React.FC<Props> = observer(
  ({users, selectedUserId, onUserSelect, isLoading}) => {
    const handleUserClick = userId => {
      try {
        onUserSelect && onUserSelect(userId);
      } catch (error) {
        throw error;
      }
    };

    return (
      <Layout.Container>
        <Layout.Header>
          <Subtitle>
            {t([I18N_CLIENT_TASK, 'section_title', 'clients'])}
          </Subtitle>
        </Layout.Header>
        <Layout.Body>
          {isLoading ? (
            <ListSkeleton />
          ) : (
            <React.Fragment>
              {users.map(event => (
                <Clickable
                  key={event.user.id}
                  onClick={() => handleUserClick(event.user.id)}
                >
                  <UserBox
                    userName={event.user.name}
                    userAvatarUrl={event.user.avatarUrl}
                    isSelected={selectedUserId === event.user.id}
                    taskStatusSlot={
                      event.isTask && (
                        <TaskStatus
                          sizeVariant={'default'}
                          status={(event as TaskAndClient).status}
                          isOverdue={event.isExpired}
                        />
                      )
                    }
                  />
                </Clickable>
              ))}
            </React.Fragment>
          )}
        </Layout.Body>
      </Layout.Container>
    );
  },
);

export {ClientsList};
