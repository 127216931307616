import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import {autorun, reaction} from 'mobx';

import {createCollectionStore} from '@yourcoach/shared/api';
import type {Card} from '@yourcoach/shared/api/payment/card';
import CardIcon from '@yourcoach/shared/assets/icons/primary/Card.svg';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import AddCardModal from '@src/modules/payments/AddCardModal';

import styles from './PaymentMethods.module.css';
import CardsListItem from './PaymentMethods-CardsListItem';

export const I18N_SCOPE = 'PaymentMethods';

export interface Props {
  onSelect?: (paymentMethod: Card) => void;
  ableToDelete?: boolean;
}

const PaymentMethods: React.FC<Props> = ({onSelect, ableToDelete}) => {
  const {
    stores: {cardStore},
  } = useContext(AppContext);

  const [cards, setCards] = useState<Card[]>([]);
  const [shouldRenderStub, setShouldRenderStub] = useState(false);

  const [addCardModalIsOpen, showAddCardModal, hideAddCardModal] =
    useIsVisible();

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const cardsStore = useRef(
    createCollectionStore<Card>({
      method: 'user.cards.list',
      params: {
        sort: [['created', -1]],
        query: [['type', '==', 'card']],
      },
    }),
  ).current;

  useEffect(() => {
    const disposers = [
      reaction(
        () => cardStore.creating,
        creating => {
          if (creating.success && creating.entity) {
            hideAddCardModal();
            cardsStore.addItem(creating.entity, 0);
          }
        },
      ),
      reaction(
        () => cardStore.deleting,
        deleting => {
          if (deleting.success && deleting.entity) {
            hideAddCardModal();
            cardsStore.removeItem(deleting.entity);
          }
        },
      ),
      reaction(
        () => cardsStore.isLoaded && cardsStore.items.length,
        () => {
          if (cardsStore.isLoaded) {
            hideAddCardModal();
            cardStore.setCards(cardsStore.items.slice());
          }
        },
      ),
      autorun(() => {
        setCards(cardsStore.items.slice());
        setShouldRenderStub(cardsStore.isLoaded && !cardsStore.hasItems);
      }),
    ];

    fetchCards();

    return () => {
      hideAddCardModal();

      disposers.forEach(dispose => dispose());

      cardsStore.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCards = useCallback(async () => {
    try {
      showOverlay();

      await cardsStore.fetch();
    } catch (error) {
      // do nothing
    } finally {
      hideOverlay();
    }
  }, [cardsStore, hideOverlay, showOverlay]);

  const onAddCardButtonClick = useCallback(() => {
    showAddCardModal();
  }, [showAddCardModal]);

  const onDeleteCardButtonClick = (card: Card) => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCard(card),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteCard = async (card: Card) => {
    try {
      showOverlay();

      await cardStore.delete(card);

      hideOverlay();
    } catch (error) {
      hideOverlay();

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const onCardClick = useCallback(
    (card: Card) => {
      onSelect && onSelect(card);
    },
    [onSelect],
  );

  return (
    <div className={classNames('PaymentMethods', styles.Component)}>
      <div className={styles.buttonsContainer}>
        <Button onClick={onAddCardButtonClick}>
          <CardIcon />
          <span>{t([I18N_SCOPE, 'add_card_button'])}</span>
        </Button>
      </div>
      {shouldRenderStub ? (
        <NoResults
          text={t([I18N_SCOPE, 'no_results_label'])}
          className={styles.noResults}
        >
          <CardIcon />
        </NoResults>
      ) : (
        <div className={styles.list}>
          {cards.map(card => (
            <CardsListItem
              card={card}
              key={card._id}
              onClick={onSelect ? onCardClick : undefined}
              onDeleteButtonClick={
                ableToDelete ? onDeleteCardButtonClick : undefined
              }
            />
          ))}
        </div>
      )}
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
      <AddCardModal
        isOpen={addCardModalIsOpen}
        onAfterClose={hideAddCardModal}
      />
    </div>
  );
};

export default React.memo(PaymentMethods);
