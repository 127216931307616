import type {FC} from 'react';
import React, {memo} from 'react';

import MultipleChoiceType from '../MultipleChoiceType/MultipleChoiceType';
import TextType from '../TextType/TextType';

interface Props {}

const MultipleChoiceOpenType: FC<Props> = () => {
  return (
    <div className="MultipleChoiceOpenType">
      <MultipleChoiceType />
      <TextType noError />
    </div>
  );
};

export default memo(MultipleChoiceOpenType);
