import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Helmet} from 'react-helmet';

import {getFileSrc, getYcLogoFile} from '@yourcoach/shared/api/media/file';
import {Image} from '@yourcoach/shared/uikit/Image';

import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Loader from '@src/components/Loader/Loader';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';
import useIsLimitedEdition from '@src/hooks/useIsLimitedEdition';

import PracticeContext from '../context/PracticeContext';

import styles from './styles.module.css';

interface Props {}

const HeaderPractice: FC<Props> = () => {
  const practiceContext = useContext(PracticeContext);

  const {isLimitedEdition} = useIsLimitedEdition(practiceContext?.userId);

  return (
    <div className={`HeaderPractice ${styles.HeaderPractice}`}>
      {practiceContext?.userId === '' || isLimitedEdition === null ? (
        <Loader />
      ) : (
        <div className={styles.headerPracticeContainer}>
          <Helmet
            title={
              isLimitedEdition
                ? practiceContext?.name
                : practiceContext?.coachTitle
            }
          />
          <div className={styles.imgContainer}>
            <div className={styles.HeaderImg}>
              <Image
                src={
                  isLimitedEdition === null
                    ? ''
                    : isLimitedEdition
                    ? getFileSrc(getYcLogoFile(340, true)).url || ''
                    : getFileSrc(practiceContext!.coachLogo, 1000).url || ''
                }
                placeholder={<AvatarLoader />}
                blurredCover
                resizeMode="contain"
              />
              <OverlayImage className={styles.OverlayImage} />
            </div>
          </div>
          <div className={styles.textContainer}>
            {isLimitedEdition !== null && !isLimitedEdition ? (
              <h2 className={styles.title}>{practiceContext?.coachTitle}</h2>
            ) : null}
            <div className={styles.coachContainer}>
              <div className={styles.coachAvatar}>
                <Image
                  src={getFileSrc(practiceContext?.userAvatar, 250).url || ''}
                  placeholder={
                    <AvatarPlaceholder name={practiceContext?.name} />
                  }
                  resizeMode="cover"
                />
              </div>
              <div className={styles.nameContainer}>
                <div className={styles.coachName}>{practiceContext?.name}</div>
              </div>
            </div>
            <div className={styles.description}>
              {practiceContext?.coachDescription}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(HeaderPractice);
