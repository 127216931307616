import type {FC} from 'react';
import React, {useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {CustomInput} from '@src/components/CustomForm';

import UserFlowContext from '../../../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../../../context/useUserFlowLocalStore';

import styles from './../../styles.module.css';

interface Props {
  noError?: boolean;
  singleOnly?: boolean;
}

const TextType: FC<Props> = ({noError = false, singleOnly = false}) => {
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);
  const currentQ = userFlowStory!.currentStepQuestion!;
  const inputRef = useRef(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;

    userFlowStory?.setCurrentStepQuestionError('');
    userFlowStory?.setCurrentStepQuestionUserAnswer(val);

    if (singleOnly) {
      userFlowStory!.setCurrentStepQuestionAnswers([]);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`TextType ${styles.TextType}`}>
          <CustomInput
            type="input"
            ref={inputRef}
            label="Add answer"
            id="Add_answer"
            customClassLabel="QStandardLabel"
            customClassCont="QStandardContainer"
            customClassInput={`QStandardInput ${styles.QStandardInput}`}
            value={currentQ.userAnswer}
            error={noError ? '' : currentQ.textError}
            showErrorImmediately
            onChange={handleOnChange}
          />
        </div>
      )}
    </Observer>
  );
};

export default TextType;
