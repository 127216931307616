import type {Expand} from '@yourcoach/shared/api';
import type {Subgoal} from '@yourcoach/shared/api/goal';

export const expand: Expand = {
  goal: ['subgoal_ids'],
};

export type Expanded = {
  subgoals: Subgoal[];
};
