import {useEffect, useState} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';
import type {AvailableCourse} from '@yourcoach/shared/api/program/utils/getAvailableCoursesToJoin';
import getAvailableCoursesToJoin from '@yourcoach/shared/api/program/utils/getAvailableCoursesToJoin';
import {logger} from '@yourcoach/shared/utils/logger';

import useIsMounted from '@src/hooks/useIsMounted';

const useAvailableCoursesToJoin = (program: Program) => {
  const [availableCourses, setAvailableCourses] = useState<AvailableCourse[]>(
    [],
  );
  const [availableCoursesToJoin, setAvailableCoursesToJoin] = useState<
    AvailableCourse[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<unknown>();

  const isMountedRef = useIsMounted();

  useEffect(() => {
    const fetch = async () => {
      if (!isMountedRef.current) {
        return;
      }

      setIsFetching(true);

      try {
        const result = await getAvailableCoursesToJoin(program);

        if (!isMountedRef.current) {
          return;
        }

        setIsFetching(false);
        setAvailableCourses(result.availableCourses);
        setAvailableCoursesToJoin(result.availableCoursesToJoin);
      } catch (fetchError) {
        logger.error(fetchError);

        if (apiRequest.isCanceledError(fetchError)) {
          return;
        }

        if (!isMountedRef.current) {
          return;
        }

        setIsFetching(false);
        setError(fetchError);
      }
    };

    fetch();
  }, [isMountedRef, program, program._id]);

  return {availableCourses, availableCoursesToJoin, isFetching, error};
};

export default useAvailableCoursesToJoin;
