import type {FC} from 'react';
import React, {memo} from 'react';

import GoalsSelectCourseTab from './GoalsSelectCourseTab/GoalsSelectCourseTab';

const GoalsPage: FC = () => {
  return (
    <div className="percent100HCorrected overflow">
      <div className="percent100HCorrected whiteColorArea">
        <GoalsSelectCourseTab />
      </div>
    </div>
  );
};

export default memo(GoalsPage);
