import type {FC} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api/index';

import StoppedCircle from '../../../assets/StoppedCircle.svg';
import Loader from '../../../components/Loader/Loader';
import {MAX_TABLET_BREAKPOINT, MIN_MOBILE_BREAKPOINT} from '../../../const';
import {useWindowDimensions} from '../../../hooks/useWindowDimensions';
import ChatsContext from '../context/ChatsContext';
import type {IChatsLocalStore} from '../context/useChatsLocalStore';
import {idTypeList} from '../context/useChatsLocalStore';
import styles from '../styles/styles.module.css';

import ChatsList from './ChatsList/ChatsList';
import DirectMessage from './DirectMessage/DirectMessage';
import {getArchivedMembershipStatus, getCoachHistoryNull} from './api';

interface Props {}

const ChatsListContainer: FC<Props> = () => {
  const {width} = useWindowDimensions();
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);
  const [isCourseStopped, setIsCourseStopped] = useState(false);
  const [isCCOngoingAndNoCoachAssigned, setIsCCOngoingAndNoCoachAssigned] =
    useState(false);
  const [programStartDate, setProgramStartDate] = useState('');

  useEffect(() => {
    chatsLocalStore?.setSelectTypeList('channels');
  }, [chatsLocalStore]);

  useEffect(() => {
    getArchivedMembershipStatus().then(resp => {
      if (resp?._items.length) {
        setIsCourseStopped(true);
      }
    });
    chatsLocalStore?.selectChannel?.resource_id &&
      getCoachHistoryNull(chatsLocalStore?.selectChannel?.resource_id).then(
        resp => {
          if (resp?._items?.length) {
            setIsCCOngoingAndNoCoachAssigned(true);
            setProgramStartDate(
              dayjs(datetimeObjToISOString(resp?._items[0]?.end_date)).add(
                1,
                'd',
              ),
            );
          }
        },
      );
  }, [chatsLocalStore?.selectChannel?.resource_id]);

  return (
    <div
      className={
        chatsLocalStore!.isFetching ? styles.DataLoading : styles.DataLoaded
      }
    >
      {chatsLocalStore!.isFetching ? (
        <Loader />
      ) : (
        <>
          <div className={`${styles.ChatsListContainer}`}>
            <div className={styles.chatListContainer}>
              {chatsLocalStore?.selectTypeList === idTypeList[0] && (
                <ChatsList />
              )}
              {width >= MIN_MOBILE_BREAKPOINT &&
              width <= MAX_TABLET_BREAKPOINT ? null : (
                <div className={styles.Separator} />
              )}
              {chatsLocalStore?.selectTypeList === idTypeList[1] && (
                <DirectMessage />
              )}
            </div>
          </div>
          {isCourseStopped && !isCCOngoingAndNoCoachAssigned && (
            <div className={styles.StoppedCourse}>
              <StoppedCircle />
              Your program has stopped. If you'd like to continue with health
              coaching, please send your coach a message here or email
              coaching@yourcoach.health
            </div>
          )}
          {isCCOngoingAndNoCoachAssigned &&
            programStartDate &&
            dayjs().isBefore(programStartDate) && (
              <div className={styles.StoppedCourse}>
                <StoppedCircle />
                Your program is paused and will resume on{' '}
                {dayjs(programStartDate).utc().format('MMMM D, YYYY')}. If you
                change your mind, please email coaching@yourcoach.health
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default observer(ChatsListContainer);
