import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import md5 from 'js-md5';
import {reaction} from 'mobx';

import type {ApiRpcRequest} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import {authStore} from '@yourcoach/shared/api/auth';
import {currentUserStore} from '@yourcoach/shared/api/user';
import {ThemeContext} from '@yourcoach/shared/styles/ThemeProvider';
import {redactStr} from '@yourcoach/shared/utils/format';
import {logger} from '@yourcoach/shared/utils/logger';

import CalendarIcon from '../assets/ActiveCalendar.svg';
import GoalsIcon from '../assets/ActiveGoals.svg';
import ChatIcon from '../assets/ChatsActive.svg';
import LibraryIcon from '../assets/LibraryActive.svg';
import {ConfirmationModal, TermsModal} from '../components/TermsModal';
import {WidgetInnerContainer} from '../components/WidgetInnerContainer';
import WS from '../components/WS/WS';
import {API_VERSION, IS_WIDGET_PACKAGE} from '../config';
import {MAX_MOBILE_BREAKPOINT, MIN_MOBILE_BREAKPOINT} from '../const';
import type {AuthAction} from '../hooks/useAuth';
import {useAuth} from '../hooks/useAuth';
import {init as i18nInit} from '../i18n';
import Tab from '../modules/Tab';
import styles from '../styles/Widget.module.css';
import {Logger} from '../utils/logger';

import type {Props} from './types';

apiRequest.configure({
  apiEndpoint: process.env.API_ENDPOINT!,
  apiVersion: API_VERSION,
  onRequestDidEnd: (payload: ApiRpcRequest | ApiRpcRequest[]) => {
    logger.logApiRequest(payload);
  },
  onRpcRequestError: error => {
    // cleanup params
    if (error.sentParams) {
      [
        'access_token',
        'session_token',
        'client_id',
        'client_secret',
        'external_id',
        'token',
        'phone',
        'email',
      ].forEach(key => {
        if (error.sentParams[key]) {
          error.sentParams[key] = redactStr(error.sentParams[key]);
        }
      });
    }

    const hash = md5(JSON.stringify(error));

    logger.error({
      name: `RpcRequestError - ${hash}`,
      message: JSON.stringify(error, null, 2),
    });
  },
});

if (IS_WIDGET_PACKAGE) {
  logger.__setPlatformLogger(new Logger());
}

const Widget: React.VFC<Props> = ({
  viewportHeight,
  viewportWidth,
  authAction,
  clientId,
  clientSecret,
  token,
  userInfo,
  appId,
  accentColor = '#f24c00',
  isInternalTesting,
}) => {
  const [activeTab, setActiveTab] = useState('chat');
  const [i18nIsReady, setI18nIsReady] = useState(false);
  const [isTermsVisible, setTermsVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isClientMatched, setIsClientMatched] = useState(true);

  const {setAccentColor} = useContext(ThemeContext);

  const {login, register} = useAuth({setTermsVisible});

  const authActionRef = useRef<AuthAction>();

  useEffect(() => {
    logger.event('home_screen_view');
  }, []);

  useEffect(() => {
    logger.setUserProperty('subtenant_id', clientId);
  }, [clientId]);

  useEffect(() => {
    setAccentColor({
      light: accentColor,
      dark: accentColor,
    });
  }, [setAccentColor, accentColor]);

  useEffect(() => {
    // TODO: Make sync require of l10n dicts to make them included to the bundle
    i18nInit().finally(() => {
      setI18nIsReady(true);
    });
  }, []);

  useEffect(() => {
    const readUserProfile = async () => {
      const response = await apiRequest({
        method: 'user.profile.read',
      });

      // as I understand, when a client is matched with a coach, concierge_ids is empty
      setIsClientMatched(!response.user.concierge_ids.length);
    };

    if (isInternalTesting) {
      readUserProfile();
    }
  }, [isInternalTesting]);

  const hasAllNecessaryDataToLogin = useMemo(
    () => clientId && clientSecret && token,
    [clientId, clientSecret, token],
  );

  const hasAllNecessaryDataToRegister = useMemo(
    () => userInfo && clientId && clientSecret && token,
    [clientId, clientSecret, token, userInfo],
  );

  const hasUserOrUserWithoutId = useMemo(
    () => currentUserStore?.user === null || !currentUserStore?.user?._id,
    [],
  );

  useEffect(() => {
    if (isInternalTesting) {
      return;
    }

    if (
      (authAction === 'login' &&
        hasAllNecessaryDataToLogin &&
        hasUserOrUserWithoutId) ||
      (authAction === 'register' &&
        hasAllNecessaryDataToRegister &&
        hasUserOrUserWithoutId)
    ) {
      if (
        !authActionRef.current ||
        (authActionRef.current && authActionRef.current !== authAction)
      ) {
        authActionRef.current = authAction;

        login({
          clientId,
          clientSecret,
          action: authAction,
          appId,
          agent: navigator.userAgent,
          token,
        });
      }
    } else {
      authStore._renewAccessToken();
    }
  }, [
    clientId,
    clientSecret,
    token,
    userInfo,
    login,
    appId,
    hasAllNecessaryDataToLogin,
    hasUserOrUserWithoutId,
    hasAllNecessaryDataToRegister,
    authAction,
    isInternalTesting,
  ]);

  useEffect(
    () =>
      reaction(
        () => currentUserStore.user && currentUserStore.user._id,
        () => {
          logger.setUser(currentUserStore.user);
        },
      ),
    [],
  );

  const onClickHandler = (tab: string) => {
    if (!isClientMatched) {
      return;
    }

    switch (tab) {
      case 'chat':
        logger.event('home_chats_tap');
        break;
      case 'to-dos':
        logger.event('home_schedule_tap');
        break;
      case 'goals':
        logger.event('home_goals_tap');
        break;
      case 'library':
        logger.event('home_library_tap');
        break;
    }

    setActiveTab(tab);
  };

  const onAcceptTerms = () => {
    if (!isInternalTesting) {
      register({
        clientId: clientId!,
        clientSecret: clientSecret!,
        action: authAction!,
        agent: navigator.userAgent,
        token: token!,
        userInfo: userInfo!,
        appId,
      });
    }

    setTermsVisible(false);
  };

  const handleDeclineConfirmationModal = () => {
    window.widget.api.onTermsDeclined?.();
  };

  // should be solved by css!!!
  const shouldUseSmallIcon =
    (viewportWidth || 0) >= MIN_MOBILE_BREAKPOINT &&
    (viewportWidth || 0) <= MAX_MOBILE_BREAKPOINT;

  const tabArray = [
    {
      title: 'Chats',
      Icon: ChatIcon,
      isSmallIcon: shouldUseSmallIcon,
      isActive: activeTab === 'chat',
      onClick: () => onClickHandler('chat'),
      isDisabled: false,
    },
    {
      title: 'To-Dos',
      Icon: CalendarIcon,
      isSmallIcon: shouldUseSmallIcon,
      isActive: activeTab === 'to-dos',
      onClick: () => onClickHandler('to-dos'),
      isDisabled: !isClientMatched,
    },
    {
      title: 'Goals',
      Icon: GoalsIcon,
      isSmallIcon: shouldUseSmallIcon,
      isActive: activeTab === 'goals',
      onClick: () => onClickHandler('goals'),
      isDisabled: !isClientMatched,
    },
    {
      title: 'Library',
      Icon: LibraryIcon,
      isSmallIcon: shouldUseSmallIcon,
      isActive: activeTab === 'library',
      onClick: () => onClickHandler('library'),
      isDisabled: !isClientMatched,
    },
  ];

  return (
    <div id="allContent" style={{height: viewportHeight, width: viewportWidth}}>
      {i18nIsReady ? (
        <div className={styles.Widget}>
          <div className={styles.Tab_Container}>
            {tabArray.map((tab, i) => (
              <Tab
                i={i}
                key={i}
                title={tab.title}
                Icon={tab.Icon}
                isSmallIcon={tab.isSmallIcon}
                isActive={tab.isActive}
                onClickHandler={tab.onClick}
                isDisabled={tab.isDisabled}
              />
            ))}
          </div>
          {<WidgetInnerContainer activeTab={activeTab} />}
        </div>
      ) : null}

      <WS />
      {isTermsVisible && (
        <TermsModal
          onClose={onAcceptTerms}
          onDecline={() => setIsConfirmationModalVisible(true)}
        />
      )}
      {isConfirmationModalVisible && (
        <ConfirmationModal
          onClose={() => setIsConfirmationModalVisible(false)}
          onDecline={handleDeclineConfirmationModal}
        />
      )}
    </div>
  );
};

export default memo(Widget);
