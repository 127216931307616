import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Account as IAccount} from '@yourcoach/shared/api/account';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelAccounts, labelAddAccount} from '@src/common/i18n/i18nProfile';
import {setError} from '@src/common/setError';
import {useGetCode} from '@src/common/useUserMethods';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {
  IconCheck,
  IconClose,
  IconMail,
  IconSmartphone,
} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import GetAccount from './GetAccount/GetAccount';
import GetCode from './GetCode/GetCode';
import styles from './styles.module.css';

interface ILocalStore {
  accountsStore: CollectionStore<IAccount> | null;
  fetch: () => void;
  showModal: boolean;
  setModalOpen: (valModalOpen: boolean) => void;
  showModalCode: boolean;
  setModalCodeOpen: (valModalOpen: boolean) => void;
  newAccType: string;
  newAccVal: string;
  setNewAcc: (newAccType: string, newAccVal: string) => void;
}

interface Props {
  isModal?: boolean;
}

const localStore: ILocalStore = observable(
  {
    accountsStore: null,
    fetch() {
      this.accountsStore.fetch();
    },
    showModal: false,
    setModalOpen(valModalOpen: boolean) {
      this.showModal = valModalOpen;
    },
    showModalCode: false,
    setModalCodeOpen(valModalCodeOpen: boolean) {
      this.showModalCode = valModalCodeOpen;
    },
    newAccType: '',
    newAccVal: '',
    setNewAcc(newAccType: string, newAccVal: string) {
      this.newAccType = newAccType;
      this.newAccVal = newAccVal;
    },
  },
  {
    accountsStore: observable,
    showModal: observable,
    newAccType: observable,
    newAccVal: observable,
    showModalCode: observable,
    setModalOpen: action,
    setModalCodeOpen: action,
    fetch: action,
  },
);

const I18N_SCOPE = 'Profile';

const Accounts: FC<Props> = ({isModal = true}) => {
  const {
    stores: {accountStore},
  } = useContext(AppContext);
  const getCode = useGetCode();

  useEffect(() => {
    runInAction(() => {
      localStore.accountsStore = createCollectionStore({
        method: 'user.accounts.list',
      });

      localStore.fetch();
    });

    return () => {
      if (localStore.accountsStore) {
        localStore.accountsStore.clear();
      }
    };
  }, []);

  const openModal = () => {
    localStore.setModalOpen(true);
  };

  const closeModal = () => {
    localStore.setModalOpen(false);
  };

  const addAccount = ({type, val}: {type: 'email' | 'phone'; val: string}) => {
    closeModal();

    try {
      getCode(type, val);
    } catch (error) {
      setError(error.message);
    }

    localStore.setNewAcc(type, val);
    localStore.setModalCodeOpen(true);
  };

  const closeModalCode = () => {
    localStore.setModalCodeOpen(false);
  };

  const handleEndCode = () => {
    localStore.fetch();
    localStore.setModalCodeOpen(false);
  };

  const handleOnClickDeleteAcc = (account: IAccount) => {
    return async () => {
      try {
        await accountStore.delete(account);
        localStore.fetch();
      } catch (error) {
        setError(error.message);
      }
    };
  };

  const makeAccountPrimary = useCallback(
    async (account: IAccount) => {
      try {
        await accountStore.makePrimary(account);

        logger.event('account_marked_as_primary', {
          type: account.type,
        });

        localStore
          .accountsStore!.items.slice()
          .filter(acc => acc.type === account.type)
          .forEach(acc => {
            localStore.accountsStore!.updateItem(acc, {
              primary: acc._id === account._id,
            });
          });
      } catch (error) {
        logger.error(error);

        getCustomConfirmAlert({
          title: t('shared.message.error_fix'),
          message: error.message,
          buttons: [
            {
              label: t('shared.button.try_later'),
            },
            {
              label: t('shared.button.try_again'),
              onClick: () => makeAccountPrimary(account),
            },
          ],
        });
      }
    },
    [accountStore],
  );

  const onAccountClick = useCallback(
    async (account: IAccount) => {
      if (account.primary) {
        getCustomConfirmAlert({
          title: t([I18N_SCOPE, 'primary_account_message', account.type]),
          buttons: [
            {
              label: t('shared.button.ok'),
            },
          ],
        });

        return;
      }

      getCustomConfirmAlert({
        message: t([I18N_SCOPE, 'primary_account_confirm', account.type], {
          account: account.value,
        }),
        buttons: [
          {
            label: t('shared.button.no'),
          },
          {
            label: t('shared.button.yes'),
            type: 'attention',
            onClick: () => makeAccountPrimary(account),
          },
        ],
      });
    },
    [makeAccountPrimary],
  );

  return (
    <Observer>
      {() => (
        <div className={`Accounts ${styles.Accounts}`}>
          {!localStore.accountsStore?.isLoaded ? (
            <Loader />
          ) : (
            <div>
              {isModal ? (
                <h3>{labelAccounts()}</h3>
              ) : (
                <div className={styles.topMenu}>
                  <h3>{labelAccounts()}</h3>
                  {localStore.accountsStore.items.length < 10 && (
                    <button
                      className={`ovalButton ${styles.addAccButtonNoModal}`}
                      type="button"
                      onClick={openModal}
                    >
                      <div className={styles.textButContainer}>
                        <div className={styles.pluse}>+</div>
                        <div>{labelAddAccount()}</div>
                      </div>
                    </button>
                  )}
                </div>
              )}
              {localStore.accountsStore?.items.slice().map(account => {
                return (
                  <div
                    key={account._id}
                    className={styles.accountItemsContainer}
                  >
                    <div className={styles.typeAccount}>
                      {account.type === 'email' ? (
                        <div className={styles.typeAccountEmail}>
                          <IconMail />
                        </div>
                      ) : (
                        <div className={styles.typeAccountPhone}>
                          <IconSmartphone />
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.accountName}
                      onClick={() => onAccountClick(account)}
                    >
                      {account.value}
                    </div>
                    <div className={styles.space} />
                    {account.primary ? (
                      <div className={styles.check}>
                        <IconCheck />
                      </div>
                    ) : (
                      <div
                        className={styles.delAccount}
                        onClick={handleOnClickDeleteAcc(account)}
                      >
                        <IconClose />
                      </div>
                    )}
                  </div>
                );
              })}
              {localStore.accountsStore.items.length < 10 && (
                <div>
                  {isModal ? (
                    <button
                      className={`ovalButton ${styles.addAccButton}`}
                      type="button"
                      onClick={openModal}
                    >
                      {labelAddAccount()}
                    </button>
                  ) : null}
                  <ModalAnimateWin
                    showModal={localStore.showModal}
                    closeModalHandler={closeModal}
                    className="greyHeaderContainer littleContainer"
                    isBody
                    classNameBody="whiteBody"
                    header="Add account"
                    classNameHeader="greyHeader"
                    classNameCloseBut="greyHeaderBut"
                  >
                    <GetAccount addAccount={addAccount} />
                  </ModalAnimateWin>
                  <ModalAnimateWin
                    showModal={localStore.showModalCode}
                    closeModalHandler={closeModalCode}
                    className="greyHeaderContainer littleContainer"
                    isBody
                    classNameBody="whiteBody"
                    header="Add account"
                    classNameHeader="greyHeader"
                    classNameCloseBut="greyHeaderBut"
                  >
                    <GetCode
                      newAccType={localStore.newAccType}
                      newAccVal={localStore.newAccVal}
                      endCode={handleEndCode}
                    />
                  </ModalAnimateWin>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(Accounts);
