import type {ReactNode} from 'react';
import React, {memo} from 'react';

import LoginFormContext from './LoginFormContext';
import store from './store';

interface Props {
  children: ReactNode[] | ReactNode;
}

const LoginFormState = (props: Props) => {
  return (
    <LoginFormContext.Provider value={{...store}}>
      {props.children}
    </LoginFormContext.Provider>
  );
};

export default memo(LoginFormState);
