import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as CRUProgramMaterialPublicationDayProps} from './MaterialPublicationDay';
import CRUProgramMaterialPublicationDay, {
  I18N_SCOPE,
} from './MaterialPublicationDay';

type Props = Partial<ModalProps> & CRUProgramMaterialPublicationDayProps;

const CRUProgramMaterialPublicationDayModal: React.FC<Props> = ({
  program,
  material,
  onSuccess,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])}>
    <CRUProgramMaterialPublicationDay
      program={program}
      material={material}
      onSuccess={onSuccess}
    />
    {children}
  </Modal>
);

export default React.memo(CRUProgramMaterialPublicationDayModal);
