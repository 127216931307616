import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';
import {DayPickerRangeController} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';

import {IconNext, IconPrev} from '../icons';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

interface Props {
  autoFocusEndDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  initialStartDate?: any;
  initialEndDate?: any;
  daysViolatingMinNightsCanBeClicked?: boolean;
  minimumNights?: number;
  changeDate?: (startDate: Date, endDate: Date) => void;
  orientation: string;
  withPortal: boolean;
}

const DayPickerRangeControllerWrapper: FC<Props> = ({
  autoFocusEndDate = false,
  initialStartDate = null,
  initialEndDate = null,
  daysViolatingMinNightsCanBeClicked = false,
  minimumNights = 1,
  minDate = null,
  maxDate = null,
  changeDate = null,
  orientation,
  withPortal,
}) => {
  const [focusedInput, setFocusedInput] = useState(
    autoFocusEndDate ? END_DATE : START_DATE,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [startDateVal, setStartDate] = useState(initialStartDate);
  const [endDateVal, setEndDate] = useState(initialEndDate);

  const onDatesChange = ({startDate, endDate}) => {
    let doesNotMeetMinNights = false;
    let validStartDate = true;
    let validEndDate = true;

    if (minDate && maxDate) {
      const difStert1 = Math.sign(moment(startDate).diff(minDate)) >= 0;
      const difStert2 =
        Math.sign(moment(maxDate).add(1, 'day').diff(startDate)) >= 0;
      const difEnd1 = Math.sign(moment(endDate).diff(minDate)) >= 0;
      const difEnd2 =
        Math.sign(moment(maxDate).add(1, 'day').diff(endDate)) >= 0;

      validStartDate = difStert1 && difStert2;
      validEndDate = difEnd1 && difEnd2;
    }

    if (validStartDate) {
      setStartDate(startDate);
    }

    if (validEndDate) {
      if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
        const dayDiff = endDate.diff(
          startDate.clone().startOf('day').hour(12),
          'days',
        );

        doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
      }

      setErrorMessage(
        doesNotMeetMinNights
          ? 'That day does not meet the minimum nights requirement'
          : null,
      );

      setEndDate(doesNotMeetMinNights ? null : endDate);

      if (startDateVal && endDate && changeDate) {
        changeDate(moment(startDateVal).toDate(), moment(endDate).toDate());
      }
    }
  };

  useEffect(() => {
    if (startDateVal) {
      setFocusedInput(END_DATE);
    }

    return () => {};
  }, [startDateVal]);

  const renderCalendarInfo = errorMessage ? <div>{errorMessage}</div> : null;

  return (
    <div className={'customRangeDatePicker'}>
      <DayPickerRangeController
        numberOfMonths={2}
        firstDayOfWeek={1}
        noBorder
        hideKeyboardShortcutsPanel={true}
        navPrev={<IconPrev height="14" />}
        navNext={<IconNext height="14" />}
        startDate={startDateVal}
        endDate={endDateVal}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        initialVisibleMonth={() => moment()}
        renderCalendarInfo={renderCalendarInfo}
        orientation={orientation}
        withPortal={withPortal}
        renderDayContents={day => {
          let dif1 = true;
          let dif2 = true;
          let dif = true;

          if (minDate && maxDate) {
            dif1 = Math.sign(moment(day).diff(minDate)) >= 0;
            dif2 = Math.sign(moment(maxDate).add(1, 'day').diff(day)) >= 0;
            dif = dif1 && dif2;
          }

          return (
            <div className={`dayContainer ${dif ? 'selectable' : ''}`}>
              <div className={`day${dif ? '' : 'Blocked'}`}>
                {day.format('D')}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default memo(DayPickerRangeControllerWrapper);
