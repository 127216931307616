import React from 'react';

import {
  labelComplete,
  labelDescription,
  labelQuestions,
} from '@src/common/i18n/i18nQuestionnaire';

import {Step1, Step3, Step4} from './Steps';

const useGetSteps = () => {
  const sections = [
    {
      name: `1. ${labelDescription()}`,
      num: 0,
      component: (clickContinue: () => void, clickBack: () => void) => (
        <Step1 clickContinue={clickContinue} clickBack={clickBack} />
      ),
    },
    {
      name: `2. ${labelQuestions()}`,
      num: 1,
      component: (clickContinue: () => void, clickBack: () => void) => (
        <Step3 clickContinue={clickContinue} clickBack={clickBack} />
      ),
    },
    {
      name: `3. ${labelComplete()}`,
      num: 2,
      component: (clickContinue: () => void, clickBack: () => void) => (
        <Step4 clickContinue={clickContinue} clickBack={clickBack} />
      ),
    },
  ];

  return sections;
};

export default useGetSteps;
