import type {FC} from 'react';
import React, {memo, useContext, useMemo} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {Img as Image} from 'react-image';
import {useHistory} from 'react-router-dom';
import TextClamp from 'react-string-clamp';

import {apiRequest} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import HideIcon from '@yourcoach/shared/assets/icons/primary/Hide.svg';

import {
  labelDelete,
  labelEdit,
  labelErrorOccurred,
} from '@src/common/i18n/i18nCommon';
import {labelDeleteTheProgram} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {ISoCouch} from '@src/modules/Practice/context/usePracticeLocalStore';

import AvatarLoader from '../AvatarLoader/AvatarLoader';
import {getCustomConfirmAlert} from '../CustomConfirmAlert/CustomConfirmAlert';
import {IconCloseUser} from '../icons';
import IconGroupFill from '../icons/IconGroupFill/IconGroupFill';
import IconUserFill from '../icons/IconUserFill/IconUserFill';
import Loader from '../Loader/Loader';
import OtherUserProfileImg from '../OtherUserProfileImg/OtherUserProfileImg';
import OverlayImage from '../OverlayImage/OverlayImage';

import styles from './styles.module.css';

const I18N_SCOPE = 'Program';

interface Props {
  title: string;
  programId?: string;
  avatar?: IFile | null | undefined;
  status?: string;
  isGroup?: boolean;
  durationStr?: string;
  isCoach?: boolean;
  priceStr: string;
  coaches?: ISoCouch[];
  rating?: number;
  is_hidden?: boolean;
  is_closed?: boolean;
  ownerId?: string;
  offerId?: string;
  hidePrice?: boolean;
  updateListProgram?: () => void;
}

const Program: FC<Props> = ({
  avatar,
  status,
  durationStr,
  coaches,
  isGroup,
  isCoach,
  title,
  is_hidden,
  is_closed,
  programId,
  priceStr,
  ownerId,
  offerId,
  hidePrice,
  updateListProgram = () => {},
}) => {
  const menuId = programId!;
  const history = useHistory();

  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const statusString = useMemo(
    () =>
      ({
        join_now: t([I18N_SCOPE, 'join_now_label']),
        requested: t([I18N_SCOPE, 'requested_label']),
        joined: t([I18N_SCOPE, 'joined_label']),
        in_progress: t([I18N_SCOPE, 'in_progress_label']),
        active: t([I18N_SCOPE, 'active']),
        draft: t([I18N_SCOPE, 'draft']),
        draft_single: t([I18N_SCOPE, 'draft_single']),
        archived: t([I18N_SCOPE, 'archived']),
        closed: t([I18N_SCOPE, 'closed']),
        restricted: t([I18N_SCOPE, 'restricted']),
      }[status || ''] || 'unknown'),
    [status],
  );

  const handleOnClickMore = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    contextMenu.show({
      id: menuId,
      event: event,
    });
  };

  const MyMenu = () => (
    <div onClick={handleClickMenu}>
      <Menu id={menuId} animation={animation.fade} className="contextMenu">
        <Item onClick={handleEditEvent} className="contextMenuItem">
          {labelEdit()}
        </Item>
        {currentUserStore.user &&
        ownerId === currentUserStore.user!._id &&
        !offerId ? (
          <Item onClick={handleDeleteConfirm} className="contextMenuItem">
            {labelDelete()}
          </Item>
        ) : null}
      </Menu>
    </div>
  );

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEditEvent = () => {
    history.push(`/program/${programId}`);
  };

  const handleDeleteConfirm = () => {
    contextMenu.hideAll();

    getCustomConfirmAlert({
      title: labelDeleteTheProgram(),
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteEditEvent(),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteEditEvent = async () => {
    try {
      await apiRequest({
        method: 'coach.programs.delete',
        params: {
          _id: programId,
        },
      });

      updateListProgram();
    } catch (error) {
      const errorMess =
        error.message === 'Cannot delete program with clients'
          ? t([I18N_SCOPE, 'cannot_delete_program_with_active_clients'])
          : error.message;

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: errorMess,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  return (
    <div className={`Program ${styles.Program}`}>
      <div className={styles.imageContainer}>
        <Image
          src={getFileSrc(avatar, 250).url || ''}
          loader={<Loader height="55%" width="55%" />}
          unloader={
            <AvatarLoader
              title={title}
              isBGGradient
              directionGradient="180deg"
            />
          }
        />
        <OverlayImage />
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.firstLine}>
          {status ? (
            <div
              className={`${styles.status} ${
                status === 'joined' ? styles.statusJoined : ''
              }`}
            >
              {statusString}
            </div>
          ) : null}
          <div className={styles.avatarContainer}>
            <div className={styles.coachAvatarContainer}>
              {coaches && coaches?.length > 0 ? (
                <>
                  {coaches.map((coach: ISoCouch, index) => {
                    return (
                      <div key={index} className={styles.coachAvatar}>
                        <OtherUserProfileImg
                          avatar={coach.avatar}
                          title={coach.name}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                ''
              )}
              {isCoach ? (
                <>
                  <div className={styles.moreButt} onClick={handleOnClickMore}>
                    ⋮
                  </div>
                  <MyMenu />
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.hiddenCloseContainer}>
          {is_hidden ? (
            <div className={styles.iconHideContainer}>
              <HideIcon />
            </div>
          ) : null}
          {is_closed ? (
            <div className={styles.iconCloseUserContainer}>
              <IconCloseUser />
            </div>
          ) : null}
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.line3}>
            <div className={isGroup ? styles.fillGroup : styles.fillIndividual}>
              {isGroup ? <IconGroupFill /> : <IconUserFill />}
            </div>
            <div className={styles.duration}>{durationStr}</div>
          </div>
          <TextClamp text={title} className={styles.title} lines={2} />
          {!hidePrice ? <div className={styles.price}>{priceStr}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default memo(Program);
