import type {Expand} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Task} from '@yourcoach/shared/api/task';
import type {User} from '@yourcoach/shared/api/user';
import {currentUserStore} from '@yourcoach/shared/api/user';
import Checkbox0Icon from '@yourcoach/shared/assets/icons/checkbox-0.svg';
import Checkbox1Icon from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import type {Theme} from '@yourcoach/shared/styles/theme';

import type {Expanded as CourseExpanded} from './courses';
import {expand as courseExpand} from './courses';

export const expand: Expand = {
  task: [
    ['course_id', null, courseExpand],
    ['attachment_ids', null],
    ['user_id', {name: 'User not found'}, {user: ['avatar_id']}],
  ],
};

export interface Expanded {
  course?: Course & CourseExpanded;
  attachments: (IFile | null)[];
  user: Partial<User> &
    Required<Pick<User, 'name'>> & {
      avatar?: IFile | null;
    };
}

export const getTaskColor = (task: Task | undefined | null, theme: Theme) => {
  if (
    task &&
    currentUserStore.user &&
    task.coach_ids &&
    task.coach_ids.includes(currentUserStore.user._id)
  ) {
    return theme.color.primary1;
  }

  return theme.color.secondary1;
};

export const getTaskIcon = (task: Task | undefined | null) => {
  if (
    (task && task.status === 'done') ||
    (task &&
      currentUserStore.user &&
      task.coach_ids &&
      task.coach_ids.includes(currentUserStore.user._id))
  ) {
    return Checkbox1Icon;
  }

  return Checkbox0Icon;
};

export const taskIsDone = (task: Task | undefined | null) => {
  if (task && task.status === 'done') {
    return true;
  }

  return false;
};

export const WaterGlassVolume = {
  OZ: {
    amount: 8,
    unit: 'oz',
  },
  L: {
    amount: 0.2,
    unit: 'l',
  },
};

export interface MealIngredient {
  name: string;
  qty: number;
  unit: string;
}

export interface MealNutrient {
  name: string;
  qty: number;
  unit: string;
}

export interface MealData {
  query?: string;
  ingredients: MealIngredient[];
  nutrients: MealNutrient[];
}

export interface Meal extends MealData {
  date: Date | string;
}

export const MEAL_SEPARATOR = '%meal_attachment%';

export const MEAL_POPUP_I18N_SCOPE = 'MealPopup';
