import type {FC} from 'react';
import React, {memo} from 'react';
import {CSSTransition} from 'react-transition-group';

interface Props {
  value: string | null;
}

const ErrorMessage: FC<Props> = ({value = ''}) => {
  return (
    <CSSTransition
      in={!!value}
      unmountOnExit
      timeout={10}
      classNames={{
        enter: 'animate__fadeInDown',
        enterActive: 'animate__fadeInDown',
        enterDone: 'animate__fadeInDown',
        exit: 'animate__fadeOutUp',
        exitActive: 'animate__fadeOutUp',
        exitDone: 'animate__fadeOutUp',
      }}
    >
      <div className={'animate__animated errorContainer'}>
        <span>{value}</span>
      </div>
    </CSSTransition>
  );
};

export default memo(ErrorMessage);
