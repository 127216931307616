import React, {useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

import type {User} from '@yourcoach/shared/api/user';
import {currentUserStore} from '@yourcoach/shared/api/user';
import {logger} from '@yourcoach/shared/utils/logger';
import storage from '@yourcoach/shared/utils/storage';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import {t} from '@src/i18n';
import LogoOnlyLayout from '@src/layouts/LogoOnly';
import {PATH_TYPE} from '@src/routes/utils';

export const CONNECT_STRIPE_STORAGE_KEY = 'connect_stripe';

const REDIRECT_URI_REGEXP = /redirect_uri=.*?&/g;

export const connectStripe = () =>
  new Promise<boolean>(resolve => {
    const handleStripeConnect = async (e: StorageEvent) => {
      if (e.key === CONNECT_STRIPE_STORAGE_KEY) {
        const savedState = (await storage.getItem(
          CONNECT_STRIPE_STORAGE_KEY,
        )) as string;

        try {
          const {result: isStripeConnected} = JSON.parse(savedState);

          if (isStripeConnected) {
            stripeWindow?.close();

            await currentUserStore.localUpdate({
              is_provider: true,
            });

            await currentUserStore.fetch();

            window.removeEventListener('storage', handleStripeConnect);
          }

          resolve(isStripeConnected);
        } catch (error) {
          resolve(false);
        }
      }
    };

    window.addEventListener('storage', handleStripeConnect);

    const stripeWindow: Window | null = window.open(
      `/${PATH_TYPE.connectStripe}`,
      '_blank',
    );

    stripeWindow?.focus();

    const timerId = setInterval(() => {
      try {
        if (stripeWindow?.closed) {
          clearInterval(timerId);
          window.removeEventListener('storage', handleStripeConnect);
          stripeWindow?.close();
          resolve(false);
        }
      } catch (error) {
        // do nothing
      }
    }, 250);
  });

interface Props {}

const ConnectStripePage: React.FC<Props> = () => {
  const query = new URLSearchParams(useLocation().search);

  const code = query.get('code');
  const state = query.get('state');

  useEffect(() => {
    const mount = async () => {
      if (code && state) {
        try {
          const savedState = (await storage.getItem(
            CONNECT_STRIPE_STORAGE_KEY,
          )) as string;

          const {redirectUri, providerType} = JSON.parse(savedState);

          if (redirectUri) {
            const urlSend = `${decodeURIComponent(redirectUri)}${
              window.location.search
            }`;

            // in dev env you need to disable CORS in your browser
            await fetch(urlSend);

            logger.event('stripe_setup', {
              provider_type: providerType || 'unknown',
            });
          }

          storage.setItem(
            CONNECT_STRIPE_STORAGE_KEY,
            JSON.stringify({
              result: true,
            }),
          );
        } catch (error) {
          getCustomConfirmAlert({
            title: t('shared.message.error'),
            message: error.message,
            buttons: [
              {
                label: t('shared.button.ok'),
                onClick: () => {},
              },
            ],
          });
        }
      } else {
        const providerType = await askForStripeAccountType();

        const url = await currentUserStore.getProviderUrl({
          isExpress: providerType === 'express',
        });

        const match = url.match(REDIRECT_URI_REGEXP);

        let redirectUri = '';

        if (match) {
          redirectUri = match[0].replace('redirect_uri=', '').replace('&', '');
        }

        storage.setItem(
          CONNECT_STRIPE_STORAGE_KEY,
          JSON.stringify({
            result: false,
            providerType,
            redirectUri,
          }),
        );

        const newRedirectUri = `redirect_uri=${encodeURIComponent(
          `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        )}&`;

        const newUrl = url.replace(REDIRECT_URI_REGEXP, newRedirectUri);

        window.location.replace(newUrl);
      }
    };

    mount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const askForStripeAccountType = useCallback(
    () =>
      new Promise<User['provider_type']>(resolve =>
        getCustomConfirmAlert({
          title: t('message.existing_stripe_account_question'),
          buttons: [
            {
              label: t('shared.button.no'),
              onClick: () => resolve('express'),
            },
            {
              label: t('shared.button.yes'),
              onClick: () => resolve('standard'),
            },
          ],
        }),
      ),
    [],
  );

  return (
    <LogoOnlyLayout>
      <Helmet title={'Connect stripe'} />
      <div className="ConnectStripePage">
        <Loader />
      </div>
    </LogoOnlyLayout>
  );
};

export default React.memo(ConnectStripePage);
