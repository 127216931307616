//import {expand as userExpand} from '@yourcoach/shared/user/utils';
import type {ApiRpcQuery, ApiRpcRequestParams} from '@yourcoach/shared/api';

import {programExpand} from '@src/models/app';

export interface ISendRpcParams {
  method?: string | undefined;
  params?: ApiRpcRequestParams | undefined;
  withAuth?: boolean | undefined;
  withCount?: boolean | undefined;
  observableFields?: string[] | undefined;
}

const getSendMyProgramRpsParams = (
  limit: number,
  isMe: boolean,
  coachId: string,
) => {
  // const SendRpcParams: ISendRpcParams = {
  const SendRpcParams: {params: ApiRpcRequestParams} = {
    params: {
      sort: [['created', -1]],
      query: ([['coach_ids', 'in', [coachId]]] as ApiRpcQuery[]).filter(
        Boolean,
      ),
      limit: limit,
      expand: programExpand,
    },
  };

  if (!isMe) {
    SendRpcParams.params!.include_joined = true;
  }

  return SendRpcParams;
};

export default getSendMyProgramRpsParams;
