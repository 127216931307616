import type {SVGProps} from 'react';
import React from 'react';

import classNames from 'classnames';

import styles from './Tab.module.css';

interface ITab {
  i: number;
  title: string;
  Icon: React.FC<SVGProps<SVGSVGElement>>;
  isSmallIcon: boolean;
  isActive: boolean;
  onClickHandler: () => void;
  isDisabled?: boolean;
}

const REGULAR_ICON_SIZE = 24;
const SMALL_ICON_SIZE = 18;

const Tab = ({
  i,
  title,
  Icon,
  isSmallIcon,
  isActive,
  onClickHandler,
  isDisabled,
}: ITab) => {
  const iconSize = isSmallIcon ? SMALL_ICON_SIZE : REGULAR_ICON_SIZE;

  return (
    <div
      className={classNames({
        [styles.Tab]: true,
        [styles.IsActive]: isActive,
        [styles.firstTab]: i === 0,
        [styles.IsDisabled]: isDisabled,
      })}
      onClick={onClickHandler}
    >
      <Icon width={iconSize} height={iconSize} fill="#727C82" />
      <div className={styles.Title}>{title}</div>
    </div>
  );
};

export default Tab;
