import React from 'react';
import {observer} from 'mobx-react';

import styled from 'styled-components';

import TickSquareSvg from '@yourcoach/shared/assets/icons/primary/Tick Square.svg';
import CameraSvg from '@yourcoach/shared/assets/icons/secondary/Camera.svg';
import {useMobxStore} from '@yourcoach/shared/hooks';
import type {AttachmentFile} from '@yourcoach/shared/stores/single-event';
import {TaskAttachmentFormStore} from '@yourcoach/shared/stores/single-event';
import {TextInput} from '@yourcoach/shared/uikit/TextInput';
import {View} from '@yourcoach/shared/uikit/View';
import {
  ConditionalFlagManager,
  payloadActionCreator,
} from '@yourcoach/shared/utils';

import AddFileModal from '@src/modules/library/AddFileModal';
import {IconButton} from '@src/v2/components/IconButton';

import {AttachedPhotoIcon} from '../../components/AttachedPhotoIcon';
import {AttachmentsBox} from '../../components/AttachmentsBox';
import {useOnAttachmentClick} from '../../utils';

import {Removable} from './components/Removable';
import {FormSkeleton} from './components/skeleton';

const StyledIconButton = styled(IconButton)`
  width: 160px;
  position: absolute;
  top: 38px;
  right: 48px;
  background: #f24c00;

  &:hover {
    background: #ef6e32;
  }

  @media (min-width: 320px) and (max-width: 950px) {
    position: static;
    width: 100%;
  }
`;

const StyledIconPhotoButton = styled(IconButton)`
  @media (min-width: 320px) and (max-width: 950px) {
    min-width: auto;
    width: 51px;
    height: 51px;
    padding: 0;
    flex-direction: column;
    font-size: 9px;

    div {
      margin-right: 0;
    }
  }
`;

const StyledView = styled(View)`
  justifycontent: space-between;

  @media (min-width: 320px) and (max-width: 950px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const AttachmentsForm: React.FC<{
  taskId: string;
}> = observer(({taskId}) => {
  const store = useMobxStore(() => new TaskAttachmentFormStore(taskId));

  const isLoading = useMobxStore(() => {
    const manager = new ConditionalFlagManager(true);

    manager.trueWhen(() => store.initialLoad.isLoading);

    return manager;
  });

  const onAttachmentClickHandler = useOnAttachmentClick();

  const onAddPhoto = React.useCallback(
    (files: AttachmentFile[]) => {
      store.onAddPhoto(payloadActionCreator(files));
    },
    [store],
  );

  const onDoneClick = async () => {
    await store.completeTask();
  };

  return isLoading.value ? (
    <FormSkeleton />
  ) : (
    <View style={{flexDirection: 'column', gap: 20}}>
      <TextInput
        value={store.editor.task.comment}
        placeholder="Leave comment"
        onChange={store.onChangeComment}
        disabled={store.isActionsDisabled}
      />
      <AttachmentsBox>
        {store.editor.task.attachments.map(attachment => (
          <Removable
            onClick={() =>
              store.onRemovePhoto(payloadActionCreator(attachment))
            }
            disabled={store.isActionsDisabled}
            key={attachment._id}
          >
            <AttachedPhotoIcon
              alt={attachment.alt}
              onClick={() => onAttachmentClickHandler(attachment)}
              thumbSrc={attachment.imageSrc}
            />
          </Removable>
        ))}
      </AttachmentsBox>
      <StyledView>
        <View style={{gap: 12}}>
          <StyledIconPhotoButton
            iconFillColor={'icon3'}
            icon={<CameraSvg />}
            scheme={'gray'}
            onClick={store.addFileModalFlag.toggle}
            disabled={store.isActionsDisabled}
          >
            Add photo
          </StyledIconPhotoButton>
        </View>
        <StyledIconButton
          icon={<TickSquareSvg />}
          scheme={'secondary'}
          disabled={store.isDoneDisabled}
          onClick={onDoneClick}
        >
          {store.doneButtonLabel}
        </StyledIconButton>
      </StyledView>
      <AddFileModal
        isOpen={store.addFileModalFlag.isTrue}
        onRequestClose={store.addFileModalFlag.toggle}
        mode={'upload'}
        onUpload={onAddPhoto}
        fileType={'image'}
      />
    </View>
  );
});

export {AttachmentsForm};
