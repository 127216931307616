import {useLocation} from 'react-router-dom';

export default function useUserId(): string {
  const location = useLocation();

  const parts = location.pathname.split('/');
  const place = parts[parts.length - 1] as string;

  return place;
}
