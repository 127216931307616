import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {logger} from '@yourcoach/shared/utils/logger';

import {MAX_TABLET_BREAKPOINT, MIN_MOBILE_BREAKPOINT} from '../../../../const';
import {useWindowDimensions} from '../../../../hooks/useWindowDimensions';
import styles from '../../styles/styles.module.css';

interface IScheduleCallButton {
  isDisabled: boolean;
  scheduledSession?: any;
  onClick: () => void;
}

export const ScheduleCallButton = ({
  isDisabled,
  scheduledSession,
  onClick,
}: IScheduleCallButton) => {
  const {width} = useWindowDimensions();

  const handleClick = () => {
    if (isDisabled) {
      logger.event('session_join_tap', {
        type: 'not_available',
        place: 'chat',
      });

      return;
    }

    onClick();

    logger.event('session_schedule_open_tap');
  };

  return (
    <div
      onClick={handleClick}
      className={
        !isDisabled ? styles.Schedule_Call_Button : styles.DisabledButton
      }
    >
      <div>
        {!scheduledSession ? (
          'Schedule live session'
        ) : (
          <div
            className={
              width >= MIN_MOBILE_BREAKPOINT && width <= MAX_TABLET_BREAKPOINT
                ? styles.MobileScheduleButtonContainer
                : styles.DesktopScheduleButtonContainer
            }
          >
            <div>Next session:</div>
            <div>
              {dayjs(
                new Date(datetimeObjToISOString(scheduledSession.start_date)),
              ).format('MM/DD/YY - LT z')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
