import type {FC} from 'react';
import React from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const IconActivity: FC<Props> = ({
  height = '100%',
  width = '100%',
  color = '#819AA9',
}) => {
  return (
    <div className="icon">
      <svg
        width={height}
        height={width}
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6668 12H20.0002L16.5002 22.5L9.50016 1.5L6.00016 12H1.3335"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default IconActivity;
