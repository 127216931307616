import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Helmet} from 'react-helmet';
import {OTPublisher, OTSession, OTSubscriber} from 'opentok-react';
import type {
  Event,
  EventHandler,
  Session,
  SessionConnectEvent,
  SessionEventHandlers,
  SignalEvent,
  Stream as OtStream,
  StreamCreatedEvent,
  StreamDestroyedEvent,
  StreamPropertyChangedEvent,
  SubscriberEventHandlers,
  VideoEnabledChangedEvent,
} from 'opentok-react/types/opentok';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {reaction} from 'mobx';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {Conference} from '@yourcoach/shared/api/conference';
import {
  conferenceIsEnded,
  conferenceIsExpired,
  conferenceIsStarted,
  getConferenceAbleToStartDate,
} from '@yourcoach/shared/api/conference';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import CameraOffIcon from '@yourcoach/shared/assets/icons/camera-off.svg';
import MicIcon from '@yourcoach/shared/assets/icons/mic.svg';
import MicOffIcon from '@yourcoach/shared/assets/icons/mic-off.svg';
import {waitFor} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import AvatarPlaceholder from '../../components/AvatarPlaceholder';
import Button from '../../components/Button';
import {getCustomConfirmAlert} from '../../components/CustomConfirmAlert/CustomConfirmAlert';
import Image from '../../components/Image';
import Loader from '../../components/Loader/Loader';
import AppContext from '../../context/App';
import useToggle from '../../hooks/useToggle';
import ConferenceLayout from '../../layouts/ConferenceLayout';
import type {Expanded as ConferenceExpanded} from '../../modules/conferences/utils';
import {expand as conferenceExpand} from '../../modules/conferences/utils';

import styles from './styles.module.css';

const FORCE_DISCONNECT_SIGNAL_TYPE = 'forceDisconnect';

const MAX_CONNECTIONS = 2;

interface Stream extends OtStream {
  userId: string;
  avatar?: string;
  role?: 'coach' | 'co-coach' | 'client';
  isCreator?: boolean;
  isPublisher?: boolean;
}

type ConferenceT = Conference & ConferenceExpanded;

type Props = {conferenceId?: string; onCloseConference: () => void};

const isIOS = /iPhone/.test(navigator.userAgent) && !window.MSStream;

const getWindowHeight = () => window.innerHeight;

const ConferencePage: FC<Props> = ({conferenceId, onCloseConference}) => {
  const {
    stores: {conferenceStore, eventStore, currentUserStore},
  } = useContext(AppContext);

  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [status, setStatus] = useState<
    'connecting' | 'joining' | 'disconnecting' | 'connected'
  >('connecting');
  const [conference, setConference] = useState<ConferenceT>();
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [streams, setStreams] = useState<Stream[]>([]);
  const [videoIsDisabled, toggleVideoIsDisabled] = useToggle(false);
  const [isRecordingPopupVisible, setRecordingPopupVisible] = useToggle(true);
  const [isMuted, toggleIsMuted] = useToggle(false);
  const [startTime, setStartTime] = useState(() => Date.now());

  // used in memo
  const [, setCurrentUserId] = useState(
    (currentUserStore.user && currentUserStore.user._id) || '',
  );

  const isReady = useMemo(
    () => scriptIsLoaded && sessionId && token,
    [scriptIsLoaded, sessionId, token],
  );

  const otSession = useRef<Session>();

  useEffect(() => {
    logger.event('video_screen_view');
    waitFor(
      // @ts-ignore
      () => global.OT,
      () => {
        setScriptIsLoaded(true);
      },
    );
  }, []);

  useEffect(() => {
    const dispose = reaction(
      () => currentUserStore.user && currentUserStore.user._id,
      id => {
        setCurrentUserId(id || '');
      },
      {fireImmediately: true},
    );

    return dispose;
  }, [currentUserStore.user]);

  const fetchConference = useCallback(async () => {
    if (conferenceId) {
      try {
        let fetchedConference: ConferenceT;

        try {
          fetchedConference = (await conferenceStore.client.fetch({
            _id: conferenceId,
            expand: conferenceExpand,
          })) as ConferenceT;
        } catch (error) {
          fetchedConference = (await conferenceStore.coach.fetch({
            _id: conferenceId,
            expand: conferenceExpand,
          })) as ConferenceT;
        }

        if (conferenceIsExpired(fetchedConference)) {
          throw {
            message: 'The live session has expired',
          };
        } else if (conferenceIsEnded(fetchedConference)) {
          throw {
            message: 'The live session has ended',
          };
        } else if (!conferenceIsStarted(fetchedConference)) {
          if (
            dayjs() <
            dayjs(
              datetimeObjToISOString(
                getConferenceAbleToStartDate(fetchedConference),
              ),
            )
          ) {
            throw {
              message: `The live session is going to start at ${dayjs(
                datetimeObjToISOString(fetchedConference.start_date),
              ).format('lll')}`,
            };
          } else if (
            !fetchedConference.coach_ids.includes(currentUserStore.user!._id)
          ) {
            throw {
              message: 'The live session is not started yet',
            };
          }
        }

        eventStore.markReadAll({
          query: [['context_ids', 'in', [conferenceId]]],
          limit: 500,
        });

        setConference(fetchedConference);
      } catch (error) {
        logger.error(error);

        getCustomConfirmAlert({
          title: 'Oops! Something went wrong!',
          message: error.message,
          buttons: [
            {
              label: 'OK',
              onClick: () => {
                onCloseConference();
              },
            },
          ],
        });
      }
    }
  }, [
    conferenceId,
    conferenceStore.client,
    conferenceStore.coach,
    currentUserStore.user,
    eventStore,
    onCloseConference,
  ]);

  useEffect(() => {
    setStatus('connecting');
    setSessionId('');
    setToken('');

    fetchConference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceId]);

  const start = useCallback(async () => {
    try {
      const result = await conferenceStore.coach.start({
        _id: conferenceId,
      } as Conference);

      setSessionId(result.conference.session_id!);
      setToken(result.token);
      setStatus('connecting');
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: 'You are unable to start this live session at the moment',
        message: error.message,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              onCloseConference();
            },
          },
        ],
      });
    }
  }, [conferenceId, conferenceStore.coach, onCloseConference]);

  const joinAs = useCallback(
    async (who: 'coach' | 'client') => {
      try {
        const result = await conferenceStore[who].join({
          _id: conferenceId,
        } as Conference);

        return result as {conference: ConferenceT; token: string};
      } catch (error) {
        throw error;
      }
    },
    [conferenceId, conferenceStore],
  );

  const join = useCallback(async () => {
    try {
      let result: {conference: ConferenceT; token: string};

      if (!conference || token) {
        return;
      }

      if (conference.coach_ids.includes(currentUserStore.user!._id)) {
        result = await joinAs('coach');
      } else {
        result = await joinAs('client');
      }

      setSessionId(result.conference.session_id!);
      setToken(result.token);
      setStatus('connecting');
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: 'Unable to join the live session. Please try again!',
        message: error.message,
        buttons: [
          {
            label: 'Later',
            onClick: () => {
              onCloseConference();
            },
          },
          {
            label: 'Retry',
            type: 'attention',
            onClick: join,
          },
        ],
      });
    }
  }, [conference, currentUserStore.user, onCloseConference, joinAs, token]);

  const stop = useCallback(async () => {
    let prevStatus: typeof status = 'connected';

    try {
      setStatus(prev => {
        prevStatus = prev;

        return 'disconnecting';
      });

      await conferenceStore.coach.stop(conference!);

      if (prevStatus === 'connected' && otSession.current) {
        otSession.current.signal({
          type: FORCE_DISCONNECT_SIGNAL_TYPE,
          data: '',
        });
      } else {
        onCloseConference();
      }
    } catch (error) {
      logger.error(error);

      setStatus(prevStatus);

      getCustomConfirmAlert({
        title: 'This live session cannot be ended at the moment',
        message: error.message,
        buttons: [
          {
            label: 'Later',
          },
          {
            label: 'Retry',
            type: 'attention',
            onClick: stop,
          },
        ],
      });
    }
  }, [conference, conferenceStore.coach, onCloseConference]);

  useEffect(() => {
    if (conference && !sessionId) {
      if (conferenceIsStarted(conference)) {
        join();
      } else if (conference.coach_ids.includes(currentUserStore.user!._id)) {
        start();
      }
    }
  }, [conference, currentUserStore.user, join, sessionId, start]);

  const currentUserStreams = useMemo(
    () =>
      streams.filter(stream => stream.userId === currentUserStore.user!._id),
    [currentUserStore.user, streams],
  );

  useEffect(() => {
    if (currentUserStreams.length + 1 > MAX_CONNECTIONS) {
      if (otSession.current) {
        otSession.current.disconnect();
      }

      getCustomConfirmAlert({
        title: 'Oops! Something went wrong!',
        message: `You have joined the conference from ${currentUserStreams.length} devices. Please leave the conference from a previous device to join from this one.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              onCloseConference();
            },
          },
        ],
      });
    }
  }, [currentUserStore.user, currentUserStreams, onCloseConference]);

  const coachesStreams = useMemo(
    () => streams.filter(stream => stream.role && stream.role !== 'client'),
    [streams],
  );

  const clientsStreams = useMemo(
    () => streams.filter(stream => !stream.role || stream.role === 'client'),
    [streams],
  );

  const isOneToOne = useMemo(
    () =>
      conference
        ? [
            ...conference.coach_ids,
            conference.client_id,
            conference.course?.client_id,
            ...new Array(
              conference.course
                ? conference.course.counters.memberships.accepted || 0
                : 0,
            ).fill('accepted_membership'),
          ].filter(Boolean).length === 2
        : false,

    [conference],
  );

  const conferenceTitle = useMemo(() => {
    if (conference) {
      if (conference.created_by === 'client') {
        return conference.program_id ? 'Live session' : 'Free intro call';
      }

      return conference.title || '';
    }

    return '';
  }, [conference]);

  const onVideoButtonClick = useCallback(() => {
    toggleVideoIsDisabled();
    logger.event('camera_tap', {type: videoIsDisabled ? 'on' : 'off'});
  }, [toggleVideoIsDisabled, videoIsDisabled]);

  const onMuteButtonClick = useCallback(() => {
    toggleIsMuted();
    logger.event('audio_tap', {type: isMuted ? 'unmute' : 'mute'});
  }, [isMuted, toggleIsMuted]);

  const onSessionError = useCallback(
    error => {
      logger.event('video_loaded_fail', {reason: error.message});

      logger.error(error);

      getCustomConfirmAlert({
        title: 'Ooops! Something went wrong!',
        message: error.message,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              onCloseConference();
            },
          },
        ],
      });
    },
    [onCloseConference],
  );

  const onSessionConnected: EventHandler<SessionConnectEvent> = useCallback(
    e => {
      otSession.current = e.target as Session;

      logger.event('video_loaded_success');
      setStartTime(Date.now());
      setStatus('connected');
    },
    [],
  );

  const onSessionReconnected = useCallback(() => {
    setStatus('connected');
  }, []);

  const onSessionReconnecting = useCallback(() => {
    setStatus('connecting');
  }, []);

  const onSessionDisconnected = useCallback(() => {
    setStatus('connecting');
  }, []);

  const onSignal: EventHandler<SignalEvent> = useCallback(
    e => {
      if (!conference) {
        return;
      }

      const isCreator = conference.moderator_id === currentUserStore.user!._id;

      if (e.type === `signal:${FORCE_DISCONNECT_SIGNAL_TYPE}`) {
        if (!isCreator) {
          let moderatorName = 'Your Coach';

          if (conference.course && conference.course.program) {
            const coach = conference.course.program.expanded_coaches.find(
              item => item._id === conference.moderator_id,
            );

            if (coach) {
              moderatorName = coach.name;
            }
          }

          getCustomConfirmAlert({
            title: `${moderatorName} has ended the live session`,
            buttons: [
              {
                label: 'OK',
                onClick: () => {
                  onCloseConference();
                },
              },
            ],
          });
        } else {
          onCloseConference();
        }
      }
    },
    [conference, currentUserStore.user, onCloseConference],
  );

  const onStreamCreated: EventHandler<StreamCreatedEvent> = useCallback(
    e => {
      if (!conference) {
        return;
      }

      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        if (index >= 0) {
          return prev;
        }

        const {stream} = e;

        const [name, id, avatar] = stream.name.split('|');

        const isCreator = conference.moderator_id === id;

        let role: Stream['role'];

        if (conference.coach_ids.includes(id)) {
          role = 'coach';

          if (conference.course?.user_id !== id) {
            role = 'co-coach';
          }
        }

        return [
          ...prev,
          {
            ...stream,
            isCreator,
            userId: id,
            name,
            avatar,
            role,
          },
        ];
      });
    },
    [conference],
  );

  const onStreamDestroyed: EventHandler<StreamDestroyedEvent> = useCallback(
    e => {
      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        if (index >= 0) {
          prev.splice(index, 1);
        }

        return [...prev];
      });
    },
    [],
  );

  const streamPropertyChanged: EventHandler<StreamPropertyChangedEvent> =
    useCallback(e => {
      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        e.stream[e.changedProperty] = e.newValue;

        if (index >= 0) {
          prev[index][e.changedProperty] = e.newValue;

          prev[index] = {
            ...prev[index],
            [e.changedProperty]: e.newValue,
          };

          return [...prev];
        }

        return prev;
      });
    }, []);

  const otSessionEventHandlers: SessionEventHandlers = useMemo(
    () => ({
      sessionConnected: onSessionConnected,
      sessionReconnected: onSessionReconnected,
      sessionReconnecting: onSessionReconnecting,
      sessionDisconnected: onSessionDisconnected,
      signal: onSignal,
      streamCreated: onStreamCreated,
      streamDestroyed: onStreamDestroyed,
      streamPropertyChanged: streamPropertyChanged,
    }),
    [
      onSessionConnected,
      onSessionDisconnected,
      onSessionReconnected,
      onSessionReconnecting,
      onSignal,
      streamPropertyChanged,
      onStreamCreated,
      onStreamDestroyed,
    ],
  );

  const getStreamsContainerClassName = useCallback(
    (role?: 'coach') => {
      const streamsLength =
        role === 'coach' ? coachesStreams.length : clientsStreams.length;

      return classNames(
        styles.streams,
        role === 'coach' ? styles.coachesStreams : styles.clientsStreams,
        isIOS ? styles.ios : '',
        streamsLength > 2 && styles.moreThan2,
        streamsLength > 6 && styles.moreThan6,
      );
    },
    [clientsStreams.length, coachesStreams.length],
  );

  const getConferenceContainerClassName = useCallback(() => {
    return classNames(styles.conference, isIOS ? styles.ios : '');
  }, []);

  const onSubscriberVideoEnabled: EventHandler<
    VideoEnabledChangedEvent<'videoEnabled'>
  > = useCallback(
    e => {
      // @ts-ignore
      streamPropertyChanged({
        changedProperty: 'hasVideo',
        newValue: true,
        oldValue: false,
        // @ts-ignore
        stream: e.target.stream,
      });
    },
    [streamPropertyChanged],
  );

  const onSubscriberVideoDisabled: EventHandler<
    VideoEnabledChangedEvent<'videoDisabled'>
  > = useCallback(
    e => {
      // @ts-ignore
      streamPropertyChanged({
        changedProperty: 'hasVideo',
        newValue: false,
        oldValue: true,
        // @ts-ignore
        stream: e.target.stream,
      });
    },
    [streamPropertyChanged],
  );

  const onSubscriberAudioBlocked: EventHandler<Event<'audioBlocked'>> =
    useCallback(
      e => {
        // @ts-ignore
        streamPropertyChanged({
          changedProperty: 'hasAudio',
          newValue: false,
          oldValue: true,
          // @ts-ignore
          stream: e.target.stream,
        });
      },
      [streamPropertyChanged],
    );

  const onSubscriberAudioUnblocked: EventHandler<Event<'audioUnblocked'>> =
    useCallback(
      e => {
        // @ts-ignore
        streamPropertyChanged({
          changedProperty: 'hasAudio',
          newValue: true,
          oldValue: false,
          // @ts-ignore
          stream: e.target.stream,
        });
      },
      [streamPropertyChanged],
    );

  return (
    <ConferenceLayout>
      <Helmet title={'Live session'}>
        <script src="https://static.opentok.com/v2/js/opentok.min.js" defer />
      </Helmet>
      <div className={styles.container}>
        {!isReady ? (
          <Loader />
        ) : (
          <div
            className={getConferenceContainerClassName()}
            style={isIOS ? {height: `${getWindowHeight()}px`} : {}}
          >
            <div className={styles.topBar}>
              <p className={styles.title}>{conferenceTitle}</p>
            </div>
            {isRecordingPopupVisible && (
              <div className={styles.popupWrapper}>
                <p className={styles.popupTitle}>
                  This session is being recorded
                </p>
                <p className={styles.popupDescription}>
                  By staying in this session, you confirm your consent to be
                  recorded
                </p>
                <button
                  className={styles.popupButton}
                  onClick={setRecordingPopupVisible}
                >
                  Ok
                </button>
              </div>
            )}
            <div className={styles.otSessionContainer}>
              <OTSession
                apiKey={process.env.OPENTOK_KEY!}
                sessionId={sessionId}
                token={token}
                onError={onSessionError}
                eventHandlers={otSessionEventHandlers}
              >
                <div
                  className={classNames(
                    styles.streamsContainer,
                    isOneToOne && styles.oneToOne,
                  )}
                >
                  <div className={getStreamsContainerClassName('coach')}>
                    <Publisher
                      type={'coach'}
                      conference={conference}
                      isMuted={isMuted}
                      videoIsDisabled={videoIsDisabled}
                    />
                    {coachesStreams.map(stream => (
                      <Stream
                        stream={stream}
                        key={stream.streamId}
                        isOneToOne={isOneToOne}
                        videoDisabled={onSubscriberVideoDisabled}
                        videoEnabled={onSubscriberVideoEnabled}
                        audioBlocked={onSubscriberAudioBlocked}
                        audioUnblocked={onSubscriberAudioUnblocked}
                      />
                    ))}
                  </div>
                  <div className={getStreamsContainerClassName()}>
                    <Publisher
                      type={'client'}
                      conference={conference}
                      isMuted={isMuted}
                      videoIsDisabled={videoIsDisabled}
                    />
                    {clientsStreams.map(stream => (
                      <Stream
                        stream={stream}
                        key={stream.streamId}
                        isOneToOne={isOneToOne}
                        videoDisabled={onSubscriberVideoDisabled}
                        videoEnabled={onSubscriberVideoEnabled}
                        audioBlocked={onSubscriberAudioBlocked}
                        audioUnblocked={onSubscriberAudioUnblocked}
                      />
                    ))}
                  </div>
                </div>
              </OTSession>
            </div>
            <div className={styles.actionsBar}>
              <div>
                <Button
                  onClick={onMuteButtonClick}
                  className={styles.actionButton}
                >
                  {isMuted ? <MicOffIcon /> : <MicIcon />}
                  Audio
                </Button>
                <Button
                  onClick={onVideoButtonClick}
                  className={styles.actionButton}
                >
                  {videoIsDisabled ? <CameraOffIcon /> : <CameraIcon />}
                  Video
                </Button>
              </div>
              <Button
                onClick={() => {
                  try {
                    let durationMls = Date.now() - startTime;

                    logger.event('video_end_tap', {
                      duration: Math.floor(durationMls / 1000),
                    });
                  } catch (err) {
                    logger.error(err);
                  }

                  onCloseConference();
                }}
                className={styles.closeButton}
              >
                End
              </Button>
            </div>
          </div>
        )}
      </div>
    </ConferenceLayout>
  );
};

const Stream: React.FC<{
  stream: Stream;
  isOneToOne?: boolean;
  videoEnabled?: SubscriberEventHandlers['videoEnabled'];
  videoDisabled?: SubscriberEventHandlers['videoDisabled'];
  audioBlocked?: SubscriberEventHandlers['audioBlocked'];
  audioUnblocked?: SubscriberEventHandlers['audioUnblocked'];
}> = React.memo(
  ({
    stream,
    isOneToOne,
    videoEnabled,
    videoDisabled,
    audioBlocked,
    audioUnblocked,
  }) => (
    <div
      className={classNames(styles.stream, stream.hasVideo && styles.hasVideo)}
    >
      <OTSubscriber
        stream={stream}
        onError={error => {
          logger.error(error);
        }}
        eventHandlers={{
          videoEnabled,
          videoDisabled,
          audioBlocked,
          audioUnblocked,
        }}
        properties={{
          showControls: false,
          subscribeToAudio: stream.hasAudio,
          subscribeToVideo: stream.hasVideo,
        }}
      />
      {stream.hasVideo ? (
        <>
          {!isOneToOne ? (
            <p className={styles.streamBadge}>
              {`${stream.name} ${stream.role ? ` (${stream.role})` : ''}`}
            </p>
          ) : null}
        </>
      ) : (
        <>
          <div className={styles.streamAvatarContainer}>
            <Image
              src={stream.avatar || ''}
              placeholder={<AvatarPlaceholder name={stream.name} />}
              className={styles.streamAvatar}
            />
            <p className={styles.streamName}>{stream.name}</p>
          </div>
        </>
      )}
      {!stream.hasAudio ? (
        <MicOffIcon className={styles.streamNoAudioIcon} />
      ) : null}
    </div>
  ),
);

const Publisher: React.FC<{
  conference?: ConferenceT;
  type: 'coach' | 'client';
  isMuted: boolean;
  videoIsDisabled: boolean;
}> = ({conference, type, isMuted, videoIsDisabled}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  if (!conference) {
    return null;
  }

  const onPublisherError = error => {
    let title = 'Oops, something went wrong!';
    let message = error.message;

    // for web we should use error.name but for mobile error.code
    const errorCode = error.name || error.code;

    // @see https://tokbox.com/developer/sdks/js/reference/Error.html
    switch (errorCode) {
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        message =
          'Please allow your browser to use your camera & microphone to successfully connect live sessions';
        break;
      case 'OT_TIMEOUT':
      case 'ConnectionFailed':
      case 'ConnectionDropped':
        title = 'Poor network connection';
        message =
          'Video and/or audio connection can not be established due to weak Internet connection. Please check your network';
        break;
      default:
        break;
    }

    message += ` (${errorCode || 'UNKNOWN_ERROR'})`;

    getCustomConfirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'OK',
          onClick: () => {},
        },
      ],
    });

    logger.error(error);
  };

  if (
    (type === 'coach' &&
      conference.coach_ids.includes(currentUserStore.user!._id)) ||
    (type === 'client' &&
      !conference.coach_ids.includes(currentUserStore.user!._id))
  ) {
    return (
      <div
        className={classNames(
          styles.stream,
          styles.publisher,
          !videoIsDisabled && styles.hasVideo,
        )}
      >
        <OTPublisher
          properties={{
            publishVideo: !videoIsDisabled,
            publishAudio: !isMuted,
            name: `${currentUserStore.user!.name}|${
              currentUserStore.user!._id
            }|${getFileSrc(currentUserStore.user!.avatar).url || ''}`,
          }}
          onError={onPublisherError}
        />
        {videoIsDisabled ? (
          <>
            <div className={styles.streamAvatarContainer}>
              <Image
                src={getFileSrc(currentUserStore.user!.avatar).url || ''}
                placeholder={
                  <AvatarPlaceholder name={currentUserStore.user!.name} />
                }
                className={styles.streamAvatar}
              />
              <p className={styles.streamName}>{currentUserStore.user!.name}</p>
            </div>
          </>
        ) : null}
        {isMuted ? <MicOffIcon className={styles.streamNoAudioIcon} /> : null}
      </div>
    );
  }

  return null;
};

export default memo(ConferencePage);
