import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import dayjs from 'dayjs';
import {t} from 'i18n-js';

import type {ApiRpcQuery, ApiRpcRequestParams} from '@yourcoach/shared/api';
import {
  apiRequest,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Task} from '@yourcoach/shared/api/task';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {expand as taskExpand} from '@src/models/tasks';
import {expand as conferenceExpand} from '@src/modules/conferences/utils';

const useTasksTab = (course: Course) => {
  const [selectedDate, setSelectedDate] = useState<Date>(dayjs().toDate());
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const [userTasks, setUserTasks] = useState<Task[]>([]);
  const [tasksLoaded, setTasksLoaded] = useState<boolean>(false);

  const [isSelectedDateChanged, setIsSelectedDateChanged] =
    useState<boolean>(false);
  const [selectedCourseId, setSelectedCourseId] = useState(prevState => ({
    ...prevState,
    currentCourseId: course?._id,
  }));

  const history = useHistory();

  const handleOnStartConferenceButtonPress = (conferenceId: string) => {
    getCustomConfirmAlert({
      title: t(['Conference', 'start_conference_question']),
      buttons: [
        {
          label: 'No',
          onClick: () => {},
        },
        {
          label: 'Yes',
          onClick: () => {
            history.push({
              pathname: `/conferences/${conferenceId}`,
            });
          },
          type: 'confirm',
        },
      ],
    });
  };

  const fetchItemsList = useCallback(
    async (date: Date) => {
      try {
        const params: ApiRpcRequestParams = {
          query: [
            [
              'start_date',
              '>=',
              ISOStringToDatetimeObj(dayjs(date).startOf('day').toISOString()),
            ],
          ] as ApiRpcQuery[],
        };

        setTasksLoaded(false);

        const result = await apiRequest({
          batch: [
            {
              method: 'client.tasks.list',
              params: {
                ...params,
                expand: taskExpand,
              },
            },
            {
              method: 'client.conferences.list',
              params: {
                ...params,
                expand: conferenceExpand,
              },
            },
            {
              method: 'coach.tasks.coach.list',
              params: {
                ...params,
                expand: taskExpand,
              },
            },
            {
              method: 'coach.conferences.list',
              params: {
                ...params,
                expand: conferenceExpand,
              },
            },
          ],
        });

        setUserTasks(
          [
            ...result[0]._items,
            ...result[1]._items,
            ...result[2]._items,
            ...result[3]._items,
          ]
            .filter(
              item =>
                item.course_id === course._id &&
                dayjs(datetimeObjToISOString(item?.start_date)).format(
                  'YYYY.MM.DD',
                ) === dayjs(date).format('YYYY.MM.DD'),
            )
            .sort(
              (a, b) =>
                Number(
                  dayjs(datetimeObjToISOString(a?.start_date)).format('D'),
                ) -
                Number(
                  dayjs(datetimeObjToISOString(b?.start_date)).format('D'),
                ),
            ),
        );

        setTasksLoaded(true);
      } catch (error) {
        return error.message;
      }
    },
    [course._id],
  );

  useEffect(() => {
    !tasksLoaded && fetchItemsList(selectedDate);

    if (isSelectedDateChanged || selectedCourseId !== course?._id) {
      fetchItemsList(selectedDate);
      setIsSelectedDateChanged(false);
    }

    setSelectedCourseId(course?._id);

    return () => {};
  }, [
    course,
    fetchItemsList,
    isSelectedDateChanged,
    selectedCourseId,
    selectedCourseId.currentCourseId,
    selectedDate,
    tasksLoaded,
  ]);

  return {
    isShowCalendar,
    selectedDate,
    tasksLoaded,
    userTasks,
    handleOnStartConferenceButtonPress,
    setIsSelectedDateChanged,
    setIsShowCalendar,
    setSelectedDate,
  };
};

export default useTasksTab;
