import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {reaction} from 'mobx';

import GuideIcon from '@yourcoach/shared/assets/icons/guide.svg';
import PencilIcon from '@yourcoach/shared/assets/icons/pencil.svg';
import ToolIcon from '@yourcoach/shared/assets/icons/tool.svg';

import {
  labelJoinedProgramsTitle,
  labelLibraryTitle,
  labelMySquadsTitle,
  labelSessionNotesTitle,
  labelToolboxTitle,
} from '@src/common/i18n/i18nCommon';
import isActive from '@src/common/isActivePath';
import {IconFolder, IconProgram} from '@src/components/icons';
import AppContext from '@src/context/App';
import {
  MyJoinedPrograms,
  MyLibrary,
  MyNotes,
  MySquads,
  MyToolbox,
} from '@src/modules';
import {PATH_TYPE} from '@src/routes/utils';

interface Tab {
  key: 'practice' | 'library' | 'toolbox' | 'joinedCourses' | 'sessionNotes';
  title: string;
  path: string;
  icon: () => React.ReactElement;
  component: FC;
}

const NavTabs: FC = () => {
  const {url} = useRouteMatch();
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    const dispose = reaction(
      () =>
        currentUserStore.user && currentUserStore.user.roles.includes('coach'),
      userIsCoach => {
        const _tabs: Tab[] = [
          {
            key: 'library',
            title: labelLibraryTitle(),
            path: PATH_TYPE.myLibrary,
            component: MyLibrary,
            icon: () => (
              <div className="icoContainer myLibrary">
                <IconFolder viewBox="7 5 18 22" />
              </div>
            ),
          },
        ];

        if (userIsCoach) {
          _tabs.unshift({
            key: 'practice',
            title: labelMySquadsTitle(),
            path: PATH_TYPE.practice,
            component: MySquads,
            icon: () => (
              <div className="icoContainer mySquads">
                <GuideIcon />
              </div>
            ),
          });

          _tabs.push({
            key: 'toolbox',
            title: labelToolboxTitle(),
            path: PATH_TYPE.myToolbox,
            component: MyToolbox,
            icon: () => (
              <div className="icoContainer myToolbox">
                <ToolIcon />
              </div>
            ),
          });

          _tabs.push({
            key: 'sessionNotes',
            title: labelSessionNotesTitle(),
            path: PATH_TYPE.mySessionNotes,
            component: MyNotes,
            icon: () => (
              <div className="icoContainer mySessionNotes">
                <PencilIcon />
              </div>
            ),
          });

          if (currentUserStore.user!.counters.following.joined > 0) {
            _tabs.push({
              key: 'joinedCourses',
              title: labelJoinedProgramsTitle(),
              path: PATH_TYPE.myJoinedPrograms,
              component: MyJoinedPrograms,
              icon: () => (
                <div className="icoContainer myJoinedPrograms">
                  <IconProgram viewBox="5 3 18 18" />
                </div>
              ),
            });
          }
        } else {
          // TODO: Add routines tab
          // if (
          //   currentUserStore.user &&
          //   currentUserStore.user.metadata.onboarding &&
          //   (currentUserStore.user.metadata.onboarding.account_type ===
          //     'client' ||
          //     currentUserStore.user.metadata.onboarding.account_type ===
          //       'employee')
          // ) {
          //   _tabs.unshift({
          //     key: 'routine',
          //     title: t([I18N_SCOPE, 'routine_tab']),
          //   });
          // }

          _tabs.unshift({
            key: 'joinedCourses',
            title: labelJoinedProgramsTitle(),
            path: PATH_TYPE.myJoinedPrograms,
            component: MyJoinedPrograms,
            icon: () => (
              <div className="icoContainer myJoinedPrograms">
                <IconProgram viewBox="5 3 18 18" />
              </div>
            ),
          });
        }

        setTabs(_tabs);
      },
      {fireImmediately: true},
    );

    return dispose;
  }, [currentUserStore.user]);

  return (
    <Observer>
      {() => (
        <>
          <div className="NavTabs">
            {tabs.map(({key, title, path, icon}) => {
              return (
                <div key={key} className="NavTabsItem">
                  <NavLink
                    to={`${url}/${path}`}
                    isActive={isActive.bind(this, `${url}/${path}`)}
                    activeClassName="selected"
                  >
                    <div className="linkContainer">
                      {icon()}
                      <div className="nameContainer">{title}</div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
          <Switch>
            {tabs.map(({key, path, component}) => {
              const Component = component;

              return (
                <Route key={key} path={`${url}/${path}`}>
                  <Component />
                </Route>
              );
            })}
            {tabs.length ? (
              <Route path="*">
                <Redirect
                  to={{
                    pathname: `${url}/${tabs[0].path}`,
                  }}
                />
              </Route>
            ) : null}
          </Switch>
        </>
      )}
    </Observer>
  );
};

export default memo(NavTabs);
