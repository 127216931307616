import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {DatePickerContainer} from '../styles/stylesDatePicker';

export interface SingleDatePickerProps {
  excludeDates?: Date[];
  filterDate?: (date: Date) => boolean;
  height: string;
  highlightDates?: Date[];
  marginBottom: string;
  marginRight: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: any;
  onMonthChange?: ((date: Date) => void | undefined) | undefined;
  paddingBottom?: string;
  renderDayContents?: any;
  selectedDate: Date | null;
  showMonthYearDropdown?: boolean;
  width: string;
}

const SingleDatePicker = ({
  excludeDates,
  height,
  highlightDates,
  marginBottom,
  marginRight,
  minDate,
  onChange,
  selectedDate,
  width,
  ...props
}: SingleDatePickerProps) => (
  <DatePickerContainer
    height={height}
    marginBottom={marginBottom}
    marginRight={marginRight}
    width={width}
  >
    <DatePicker
      calendarStartDay={0}
      disabledKeyboardNavigation
      excludeDates={excludeDates}
      highlightDates={highlightDates}
      inline
      minDate={minDate}
      onChange={onChange}
      selected={selectedDate}
      shouldCloseOnSelect
      {...props}
    />
  </DatePickerContainer>
);

export default SingleDatePicker;
