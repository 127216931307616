import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';

import DocumentIcon from '@src/assets/img/DocumentIcon.svg';
import {labelAddFile} from '@src/common/i18n/i18nLibrary';
import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import AppContext from '@src/context/App';
import {AddFile} from '@src/modules';

import LibraryContext from '../../context/LibraryContext';
import type {ILibraryLocalStore} from '../../context/useLibraryLocalStore';
import {linkFiles} from '../../libraryFunctions';
import styles from '../styles.module.scss';

interface Props {
  className?: string;
}

interface ILocalStore {
  showModal: boolean;
  toggleVisModal(isShow: boolean): void;
}

const AddFileButt: FC<Props> = () => {
  const {
    stores: {fileStore},
  } = useContext(AppContext);
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const localStore = useRef(
    observable<ILocalStore>(
      {
        showModal: false,
        toggleVisModal(isShow: boolean) {
          this.showModal = isShow;
        },
      },
      {
        showModal: observable,
        toggleVisModal: action,
      },
    ),
  ).current;

  const handleOnClick = () => {
    localStore.toggleVisModal(true);
  };

  const closeModal = () => {
    localStore.toggleVisModal(false);
  };

  const handleOnUpLoad = filesArr => {
    return new Promise(async (resolve: (files: IFile[]) => void) => {
      const files: IFile[] = await Promise.all(
        filesArr
          .map(file => {
            return () =>
              fileStore.upload(file, {
                name: file.name,
              });
          })
          .map(fn => fn()),
      );

      linkFiles(files, libraryStory!.folderID, () => {});

      closeModal();

      libraryStory!.updateFolderLinks(files.length);

      resolve(files);
    });
  };

  return (
    <Observer>
      {() => (
        <>
          <CustomButton
            type="button"
            classButton={`${styles.buttonItem}`}
            onClick={handleOnClick}
          >
            <div className={styles.buttonLabelContainer}>
              {labelAddFile()}
              <DocumentIcon className={styles.addItemButtonIcon} />
            </div>
          </CustomButton>
          <ModalAnimateWin
            showModal={localStore.showModal}
            closeModalHandler={closeModal}
          >
            <AddFile onUpload={handleOnUpLoad} />
          </ModalAnimateWin>
        </>
      )}
    </Observer>
  );
};

export default memo(AddFileButt);
