import React, {useCallback, useState} from 'react';

import classNames from 'classnames';

import type {Edition} from '@yourcoach/shared/api/program';
import type {PrivateProfile} from '@yourcoach/shared/api/user';

import Button from '@src/components/Button';
import {MODAL_STICKY_FOOTER_CLASS_NAME} from '@src/components/ModalNew';
import Switch from '@src/components/Switch';
import {t} from '@src/i18n';

import styles from './PrivateDataRequest.module.css';

export const I18N_SCOPE = 'shared.CRUProgramPrivateDataRequest';

type PDRequest = Edition['private_data_request'];

const AVAILABLE_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'email',
  'phone',
  'gender',
  'date_of_birth',
  'location',
] as PDRequest;

export type Props = {
  privateDataRequest: PDRequest;
  onSet: (privateDataRequest: PDRequest) => void;
};

const PrivateDataRequest: React.FC<Props> = ({privateDataRequest, onSet}) => {
  const [pdRequest, setPdRequest] = useState(privateDataRequest.slice());

  const onSetButtonPress = useCallback(() => {
    onSet(pdRequest.slice());
  }, [onSet, pdRequest]);

  const onRequestChange = useCallback(
    (key: keyof PrivateProfile, keyIndex: number) => {
      const index = pdRequest.findIndex(item => item === key);

      if (index >= 0) {
        pdRequest.splice(index, 1);

        setPdRequest([...pdRequest]);
      } else {
        const tmp = AVAILABLE_FIELDS.map(item => {
          if (!pdRequest.includes(item)) {
            return;
          }

          return item;
        });

        tmp[keyIndex] = key;

        setPdRequest(tmp.filter(Boolean) as PDRequest);
      }
    },
    [pdRequest],
  );

  return (
    <div
      className={classNames('CRUProgramPrivateDataRequest', styles.Component)}
    >
      <div className={styles.container}>
        {AVAILABLE_FIELDS.map((key, i) => {
          const isSelected = pdRequest.includes(key);

          return (
            <div className={styles.row} key={key}>
              <div className={styles.title}>
                {t(`shared.private_profile.${key}`)}
              </div>
              <Switch
                isOn={isSelected}
                onChange={() => onRequestChange(key, i)}
              />
            </div>
          );
        })}
      </div>
      <div
        className={classNames(styles.footer, MODAL_STICKY_FOOTER_CLASS_NAME)}
      >
        <Button onClick={onSetButtonPress}>
          {t([I18N_SCOPE, 'set_button'])}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PrivateDataRequest);
