import {useContext, useRef} from 'react';

import dayjs from 'dayjs';
import {action, computed, observable, runInAction} from 'mobx';

import type {
  ApiRpcQuery,
  ApiRpcRequestParams,
  CancellablePromise,
  RpcRequest,
} from '@yourcoach/shared/api';
import {
  apiRequest,
  datetimeObjToISOString,
  expandObj,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';

import AppContext from '@src/context/App';
import {expand as taskExpand} from '@src/models/tasks';
import {expand as conferenceExpand} from '@src/modules/conferences/utils';
import type {CourseT} from '@src/modules/courses/SelectCourse';

import type {Conference, Task} from '../ToDos/context/useToDosLocalStore';
import {
  DATE_FORMAT,
  MAX_DATE,
  MIN_DATE,
} from '../ToDos/context/useToDosLocalStore';

export interface ICalendarEventStore {
  greenEvents: Array<number>;
  purpleEvents: Array<number>;
  getEvents(): void;
  setGreenEvents(greenEvents: Array<number>): void;
  setPurpleEvents(purpleEvents: Array<number>): void;
  isFocused: boolean;
  setIsFocused(isFocused: boolean): void;
  currentMonth: string;
  setCurrentMonth(newDate: Date): void;
  isLoaded: boolean;
  setIsLoaded(isLoaded: boolean): void;
  isFetching: boolean;
  setIsFetching(isFetching: boolean): void;
  userIsCoach: boolean | null;
  _fetchRequest: CancellablePromise<any> | null;
  getFetchRequest(): CancellablePromise<any>;
  _fetchEvents(): void;
  course: CourseT | null;
  setCourse(newCourse: CourseT): void;
  _getCourseEndDate(): void;
}

const useEventCalendar = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const store: ICalendarEventStore = useRef(
    observable(
      {
        greenEvents: [],
        purpleEvents: [],
        get userIsCoach() {
          return user && user.roles.includes('coach');
        },
        isFocused: false,
        setIsFocused(isFocused: boolean) {
          this.isFocused = isFocused;
        },
        setGreenEvents(greenEvents: Array<number>) {
          this.greenEvents = greenEvents;
        },
        setPurpleEvents(purpleEvents: Array<number>) {
          this.purpleEvents = purpleEvents;
        },
        getEvents() {
          this.greenEvents = [];
          this.purpleEvents = [];

          this._fetchEvents();
        },
        currentMonth: dayjs().startOf('month').format(DATE_FORMAT),
        setCurrentMonth(newDate: Date) {
          this.currentMonth = dayjs(newDate)
            .startOf('month')
            .format(DATE_FORMAT);
        },
        isLoaded: false,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        _getCourseEndDate() {
          if (!this.course) {
            return dayjs();
          }

          return this.course.status === 'ongoing'
            ? dayjs.max(
                dayjs(),
                dayjs(datetimeObjToISOString(this.course.end_date)).subtract(
                  1,
                  'day',
                ),
              )
            : dayjs(datetimeObjToISOString(this.course.end_date)).subtract(
                1,
                'day',
              );
        },
        isFetching: false,
        setIsFetching(isFetching: boolean) {
          this.isFetching = isFetching;
        },
        _fetchRequest: null,
        getFetchRequest() {
          const maxDate = this.course
            ? this._getCourseEndDate().endOf('day')
            : MAX_DATE;
          const minDate = this.course
            ? dayjs(datetimeObjToISOString(this.course.start_date)).startOf(
                'day',
              )
            : MIN_DATE;

          const startDate = dayjs.max(
            minDate,
            dayjs(this.currentMonth).startOf('month'),
          );
          const endDate = dayjs.min(
            maxDate,
            dayjs(this.currentMonth).endOf('month'),
          );

          const query: ApiRpcQuery[] = [
            [
              'start_date',
              '>=',
              ISOStringToDatetimeObj(dayjs(startDate).toISOString()),
            ],
            [
              'start_date',
              '<=',
              ISOStringToDatetimeObj(dayjs(endDate).toISOString()),
            ],
          ];

          if (this.course && this.course._id) {
            query.unshift(['course_id', '==', this.course._id]);
          }

          const params: ApiRpcRequestParams = {
            query,
            limit: 1000,
          };

          return apiRequest({
            batch: (
              [
                {
                  method: 'client.tasks.list',
                  params: {
                    ...params,
                    expand: taskExpand,
                  },
                },
                {
                  method: 'client.conferences.list',
                  params: {
                    ...params,
                    expand: conferenceExpand,
                  },
                },
                this.userIsCoach
                  ? {
                      method: 'coach.tasks.coach.list',
                      params: {
                        ...params,
                        expand: taskExpand,
                      },
                    }
                  : null,
                this.userIsCoach
                  ? {
                      method: 'coach.conferences.list',
                      params: {
                        ...params,
                        expand: conferenceExpand,
                      },
                    }
                  : null,
              ] as RpcRequest[]
            ).filter(Boolean),
          });
        },
        async _fetchEvents() {
          if (
            this.isFetching &&
            this._fetchRequest &&
            this._fetchRequest.cancel
          ) {
            this._fetchRequest.cancel();
          }

          try {
            this.setIsFetching(true);

            runInAction(() => {
              this._fetchRequest = this.getfetchRequest;
            });

            const [
              tasksResult,
              conferencesResult,
              coachTasksResults,
              coachConferencesResult,
            ] = await this._fetchRequest();

            const tasks: Task[] = [];
            const conferences: Conference[] = [];

            const clientTasks: Task[] = [];
            const clientTasksLength = tasksResult._items.length;

            const processTasks = async () => {
              for (let taskI = 0; taskI < clientTasksLength; taskI += 1) {
                clientTasks[taskI] = expandObj(tasksResult._items[taskI], {
                  expand: taskExpand,
                  expanded: tasksResult._expanded,
                });
              }

              tasks.push(...clientTasks);
            };

            const clientConferences: Conference[] = [];
            const clientConferencesLength = conferencesResult._items.length;

            const processConferences = async () => {
              for (
                let conferencesI = 0;
                conferencesI < clientConferencesLength;
                conferencesI += 1
              ) {
                clientConferences[conferencesI] = expandObj(
                  conferencesResult._items[conferencesI],
                  {
                    expand: conferenceExpand,
                    expanded: conferencesResult._expanded,
                  },
                );
              }

              conferences.push(...clientConferences);
            };

            let coachTasks: Task[] = [];
            let coachConferences: Conference[] = [];

            const processCoachTasks = async () => {
              if (this.userIsCoach) {
                const coachTaskGroups = {};

                const groupByUuid = (task: Task) => {
                  const groupId = `${dayjs(
                    datetimeObjToISOString(task.start_date),
                  ).format(DATE_FORMAT)}-${task.uuid}`;

                  coachTaskGroups[groupId] = coachTaskGroups[groupId] || [];
                  coachTaskGroups[groupId].push({
                    ...task,
                    is_coach_task: true,
                  });
                };

                coachTasksResults._items.forEach(groupByUuid);

                Object.keys(coachTaskGroups).forEach(key => {
                  coachTasks.push(coachTaskGroups[key][0]);
                });

                const coachTasksLength = coachTasks.length;

                for (
                  let coachTaskI = 0;
                  coachTaskI < coachTasksLength;
                  coachTaskI += 1
                ) {
                  coachTasks[coachTaskI] = expandObj(coachTasks[coachTaskI], {
                    expand: taskExpand,
                    expanded: coachTasksResults._expanded,
                  });
                }

                tasks.push(...coachTasks);
              }
            };

            const processCoachConferences = async () => {
              if (this.userIsCoach) {
                const coachConferencesLength =
                  coachConferencesResult._items.length;

                for (
                  let conferencesI = 0;
                  conferencesI < coachConferencesLength;
                  conferencesI += 1
                ) {
                  coachConferences[conferencesI] = expandObj(
                    coachConferencesResult._items[conferencesI],
                    {
                      expand: conferenceExpand,
                      expanded: coachConferencesResult._expanded,
                    },
                  );
                }

                conferences.push(...coachConferences);
              }
            };

            await Promise.all(
              [
                processTasks,
                processConferences,
                processCoachTasks,
                processCoachConferences,
              ].map(fn => fn()),
            );

            runInAction(() => {
              tasks.map(task =>
                dayjs(datetimeObjToISOString(task.start_date)).format('D'),
              );

              const resTasksDay: number[] = [];

              for (let task of tasks) {
                const dayNum = +dayjs(
                  datetimeObjToISOString(task.start_date),
                ).format('D');

                if (!resTasksDay.includes(dayNum)) {
                  resTasksDay.push(dayNum);
                }
              }

              const resConferencesDay: number[] = [];

              for (let conference of conferences) {
                const dayNum = +dayjs(
                  datetimeObjToISOString(conference.start_date),
                ).format('D');

                if (!resConferencesDay.includes(dayNum)) {
                  resConferencesDay.push(dayNum);
                }
              }

              this.setGreenEvents(resTasksDay);
              this.setPupleEvents(resConferencesDay);

              this.isLoaded = true;
              this.isFetching = false;
            });
          } catch (error) {
            this.setIsLoaded(true);
            this.setIsFetching(false);

            if (!error.canceled) {
              // TODO: Log error
            }
          }
        },
        course: null,
        setCourse(newCourse: CourseT) {
          this.course = newCourse;
        },
      },
      {
        greenEvents: observable,
        purpleEvents: observable,
        getEvents: action,
        setGreenEvents: action,
        setPurpleEvents: action,
        isFocused: observable,
        setIsFocused: action,
        currentMonth: observable,
        setCurrentMonth: action,
        isLoaded: observable,
        setIsLoaded: action,
        isFetching: observable,
        setIsFetching: action,
        userIsCoach: computed,
        _fetchRequest: observable,
        getFetchRequest: action,
        _fetchEvents: action,
        course: observable,
        setCourse: action,
        _getCourseEndDate: action,
      },
    ),
  ).current;

  return store;
};

export default useEventCalendar;
