import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import type {
  UserQuestionnaire,
  UserQuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';
import type {User as IUser} from '@yourcoach/shared/api/user';

import {
  labelResults,
  labelSection,
  labelSent,
} from '@src/common/i18n/i18nQuestionnaire';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import {
  LongText,
  MultipleChoice,
  SingleChoice,
} from '../../TypesInputTemplates';

import styles from './../styles.module.css';

interface Props {
  currentAnswer: UserQuestionnaire | null;
  currentUser: IUser | null;
}

const Result: FC<Props> = ({currentAnswer, currentUser}) => {
  return (
    <Observer>
      {() => (
        <div className={`Result ${styles.Result}`}>
          <div className={styles.header}>
            <h2>{labelResults()}</h2>
            <div className={styles.headerUserRow}>
              <div className={styles.userContainer}>
                <div className={styles.userAvatar}>
                  <OtherUserProfileImg
                    // @ts-ignore
                    avatar={currentUser!.avatar}
                    title={currentUser!.name}
                  />
                </div>
                <div className={styles.userName}>{currentUser!.name}</div>
              </div>
              <div className={styles.sent}>{labelSent()}</div>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.scrollContainer}>
              {currentAnswer &&
                currentAnswer.sections
                  .slice()
                  .map(
                    (section: UserQuestionnaireSection, numSection: number) => (
                      <div key={numSection} className={styles.sectionContainer}>
                        <div className={styles.sectionNum}>
                          {labelSection()} {numSection + 1}
                        </div>
                        <div className={styles.sectionName}>
                          {section.title}
                        </div>
                        {styles.sectionDescription !== '' && (
                          <div className={styles.sectionDescription}>
                            {section.description}
                          </div>
                        )}
                        <br />
                        <div className={styles.questionsContainer}>
                          {section.questions
                            .slice()
                            .map((question, numQuestion) => {
                              switch (question.type) {
                                case ALIAS_TYPES.text:
                                  return (
                                    <div key={numQuestion}>
                                      <LongText
                                        question={question}
                                        answer={question.answer}
                                      />
                                    </div>
                                  );
                                case ALIAS_TYPES.singleChoice:
                                  return (
                                    <div key={numQuestion}>
                                      <SingleChoice
                                        question={question}
                                        answer={question.answer}
                                      />
                                    </div>
                                  );
                                case ALIAS_TYPES.singleChoiceOpen:
                                  return (
                                    <div key={numQuestion}>
                                      <SingleChoice
                                        question={question}
                                        answer={question.answer}
                                      />
                                    </div>
                                  );
                                case ALIAS_TYPES.multipleChoice:
                                  return (
                                    <div key={numQuestion}>
                                      <MultipleChoice
                                        question={question}
                                        answers={question.answer}
                                      />
                                    </div>
                                  );
                                case ALIAS_TYPES.multipleChoiceOpen:
                                  return (
                                    <div key={numQuestion}>
                                      <MultipleChoice
                                        question={question}
                                        answers={question.answer}
                                      />
                                    </div>
                                  );
                              }
                            })}
                        </div>
                      </div>
                    ),
                  )}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Result);
