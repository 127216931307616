import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

import type {Link} from '@yourcoach/shared/api/media/link';
import PrimaryFolderIcon from '@yourcoach/shared/assets/icons/folder-primary.svg';
import SecondaryFolderIcon from '@yourcoach/shared/assets/icons/folder-secondary.svg';

import {IconCheck, StarIcon} from '@src/components/icons';

import styles from './../styles.module.scss';

type Folder = Link;

interface Props<T extends Folder = Folder> {
  folder: T;
  isSystem?: boolean;
  isStarred?: boolean;
  isPartiallySelected?: boolean;
  secondRow?: string;
  className?: string;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onPress?: (vent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onLongPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCheckboxPress?: (folder: T) => void;
  isMedium?: boolean;
}

const FolderCard: FC<Props> = ({
  folder,
  isSystem,
  isStarred,
  secondRow,
  className = '',
  isSelectionMode,
  isSelected,
  isPartiallySelected,
  onPress,
  onLongPress,
  onCheckboxPress,
  isMedium = false,
}) => {
  const onPressCb = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onPress && onPress(e);
    },
    [onPress],
  );

  const onLongPressCb = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onLongPress && onLongPress(e);
    },
    [onLongPress],
  );

  const onCheckboxPressCb = useCallback(() => {
    onCheckboxPress && onCheckboxPress(folder);
  }, [onCheckboxPress, folder]);

  return (
    <Observer>
      {() => (
        <div
          className={`FolderCard ${styles.FolderCard} ${
            isSystem ? styles.systemFolder : ''
          } ${className}`}
          onClick={onPressCb}
          onContextMenu={onLongPressCb}
        >
          <div
            className={`${styles.mainContainer} ${
              isMedium ? styles.mediumContainer : ''
            }`}
          >
            <div className={styles.iconContainer}>
              {isSystem ? <PrimaryFolderIcon /> : <SecondaryFolderIcon />}
              {isStarred ? (
                <StarIcon
                  customClass={styles.iconFolder}
                  width="20"
                  height="20"
                />
              ) : null}
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.name}>{folder.name}</div>
              {secondRow ? (
                <div className={styles.secondRow}>{secondRow}</div>
              ) : null}
            </div>
          </div>
          {isSelectionMode && !isSystem ? (
            <div
              onClick={onCheckboxPressCb}
              className={`${styles.checkboxContainer} ${
                isSelected
                  ? isPartiallySelected
                    ? styles.partialSelect
                    : styles.selected
                  : ''
              }`}
            >
              {isSelected && !isPartiallySelected ? <IconCheck /> : null}
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(FolderCard);
