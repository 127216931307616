import {v4 as uuidv4} from 'uuid';

import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';
import {linkStore} from '@yourcoach/shared/api/media/link';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import {editionStore, programStore} from '@yourcoach/shared/api/program';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

import {
  labelUpdateProgramSchedulePopupDescription,
  labelUpdateProgramSchedulePopupHeader,
} from '../../../src/common/i18n/i18nProgramSchedulePopup';
import {setError} from '../common/setError';
import {getCustomConfirmAlert} from '../components/CustomConfirmAlert/CustomConfirmAlert';

export const expand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      null,
      {
        edition: ['contract_id', 'materials.file_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

export type ExpandedCoach = Partial<User> &
  Required<Pick<User, 'coach_title'>> &
  UserExpanded;

export interface Expanded {
  avatar?: IFile | null;
  background?: IFile | null;
  editions: (
    | (Edition & {
        contract?: IFile | null;
        materials: ScheduleMaterial[];
      })
    | undefined
  )[];
  expanded_coaches: ExpandedCoach[];
}

export type ScheduleMaterial = Edition['materials'][0] & {
  files: (IFile | null)[];
};

export const linksToMaterials = (
  links: (Link & {resource: IFile})[],
  currentUserStore,
) => {
  const promises: Promise<
    {emptyFolder: boolean} | ScheduleMaterial | (Error & {isError: boolean})
  >[] = [];

  links.forEach(link => {
    if (link.type === 'folder') {
      const unfoldPromise = linkStore
        .unfold(link, {
          query: [['type', '==', 'link']],
          expand: {
            link: ['resource_id'],
          },
        })
        .then(
          // @ts-ignore
          (result: {
            _items: (Link & {resource: IFile})[];
            _expanded: {[key: string]: IFile};
          }) => {
            if (!result._items.length) {
              return Promise.resolve({
                emptyFolder: true,
              });
            }

            return Promise.resolve({
              uuid: uuidv4().replace(/-/g, ''),
              title:
                result._items.length === 1 ? result._items[0].name : link.name,
              file_ids: result._items.map(item => item.resource_id),
              files: result._items.map(item =>
                item.resource_id ? result._expanded[item.resource_id] : null,
              ),
              day: 0,
              time: 0,
              description: '',
              coach_id: currentUserStore.user ? currentUserStore.user._id : '',
            });
          },
        )
        .catch((e: Error) => ({
          ...e,
          isError: true,
        }));

      promises.push(unfoldPromise);
    } else {
      const promise = Promise.resolve({
        uuid: uuidv4().replace(/-/g, ''),
        title: link.name,
        file_ids: link.resource_id ? [link.resource_id] : [],
        files: [link.resource],
        day: 0,
        time: 0,
        description: '',
        coach_id: currentUserStore.user ? currentUserStore.user._id : '',
      });

      promises.push(promise);
    }
  });

  return promises;
};

export const updateTasksSchedule = async ({
  tasks,
  programId,
}: {
  tasks: Edition['tasks'];
  programId: string;
}) => {
  try {
    const program = (await programStore.coach.fetch({
      _id: programId,
      expand: {
        program: ['edition_ids'],
      },
    })) as Program & {editions: Edition[]};

    const lastEdition = program.editions[program.editions.length - 1];

    const editionTasks = lastEdition.tasks.slice();

    tasks.forEach(task => {
      const conferenceIndex = editionTasks.findIndex(
        item => item.uuid === task.uuid,
      );

      if (conferenceIndex >= 0) {
        if (task.title) {
          editionTasks.splice(conferenceIndex, 1, task);
        } else {
          editionTasks.splice(conferenceIndex, 1);
        }
      } else if (task.title) {
        editionTasks.push(task);
      }
    });

    await editionStore.update(lastEdition, {
      revision: lastEdition.revision,
      tasks: editionTasks,
      expand: {edition: ['contract_id', 'materials.file_ids']},
    });
  } catch (error) {
    getCustomConfirmAlert({
      title: 'An error has occurred!',
      message: error.message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });

    setError(error);
  }
};

export const isTaskInSchedule = async ({
  tasks,
  programId,
}: {
  tasks: Edition['tasks'];
  programId: string;
}) => {
  let retVal = false;

  try {
    const program = (await programStore.coach.fetch({
      _id: programId,
      expand: {
        program: ['edition_ids'],
      },
    })) as Program & {editions: Edition[]};

    const lastEdition = program.editions[program.editions.length - 1];

    const editionTasks = lastEdition.tasks.slice();

    tasks.forEach(task => {
      const index = editionTasks.findIndex(item => item.uuid === task.uuid);

      if (index >= 0) {
        retVal = true;
      } else {
        retVal = false;
      }
    });

    return retVal;
  } catch (error) {
    getCustomConfirmAlert({
      title: 'An error has occurred!',
      message: error.message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });

    setError(error);
  }
};

export const updateConferencesSchedule = async ({
  conferences,
  programId,
}: {
  conferences: Edition['conferences'];
  programId: string;
}) => {
  try {
    const program = (await programStore.coach.fetch({
      _id: programId,
      expand: {
        program: ['edition_ids'],
      },
    })) as Program & {editions: Edition[]};

    const lastEdition = program.editions[program.editions.length - 1];

    const editionConferences = lastEdition.conferences.slice();

    conferences.forEach(conference => {
      const conferenceIndex = editionConferences.findIndex(
        item => item.uuid === conference.uuid,
      );

      if (conferenceIndex >= 0) {
        if (conference.title) {
          editionConferences.splice(conferenceIndex, 1, conference);
        } else {
          editionConferences.splice(conferenceIndex, 1);
        }
      } else if (conference.title) {
        editionConferences.push(conference);
      }
    });

    await editionStore.update(lastEdition, {
      revision: lastEdition.revision,
      conferences: editionConferences,
      expand: {edition: ['contract_id', 'materials.file_ids']},
    });
  } catch (error) {
    getCustomConfirmAlert({
      title: 'An error has occurred!',
      message: error.message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });

    setError(error);
  }
};

export const shouldUpdateProgramSchedule = ({
  programTitle,
  actionType,
  targetType,
  isIndividual = false,
}: {
  programTitle: string;
  actionType: 'create' | 'update' | 'delete';
  targetType: 'conference' | 'task' | 'material';
  isIndividual?: boolean;
}) =>
  new Promise(resolve => {
    getCustomConfirmAlert({
      title: labelUpdateProgramSchedulePopupHeader(targetType, actionType),
      message: labelUpdateProgramSchedulePopupDescription(
        targetType,
        actionType,
        programTitle,
        isIndividual ? 'individual' : 'group',
      ),
      buttons: [
        {
          label: 'Yes',
          onClick: () => resolve(true),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => resolve(false),
        },
      ],
    });
  });

export const updateMaterialsSchedule = async ({
  materials,
  programId,
}: {
  materials: Edition['materials'];
  programId: string;
}) => {
  try {
    const program = (await programStore.coach.fetch({
      _id: programId,
      expand: {
        program: ['edition_ids'],
      },
    })) as Program & {editions: Edition[]};

    const lastEdition = program.editions[program.editions.length - 1];

    const editionMaterials = lastEdition.materials.slice();

    materials.forEach(material => {
      const materialIndex = editionMaterials.findIndex(
        item => item.uuid === material.uuid,
      );

      if (materialIndex >= 0) {
        if (material.title) {
          editionMaterials.splice(materialIndex, 1, material);
        } else {
          editionMaterials.splice(materialIndex, 1);
        }
      } else if (material.title) {
        editionMaterials.push(material);
      }
    });

    await editionStore.update(lastEdition, {
      revision: lastEdition.revision,
      materials: editionMaterials,
      expand: {edition: ['contract_id', 'materials.file_ids']},
    });
  } catch (error) {
    getCustomConfirmAlert({
      title: 'An error has occurred!',
      message: error.message,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });

    setError(error);
  }
};
