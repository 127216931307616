import storage from '@yourcoach/shared/utils/storage';

export type TNameLocalStorage = 'loginRegisterForm' | 'Breadcrumbs';

export type TTypeAction = 'login' | 'register';

export interface ILoginRegisterFormObjLcStr {
  phone?: string;
  email?: string;
  typeActivation?: TTypeAction;
}

export interface IBreadcrumbsLocalStore {
  label: string;
  pathname: string;
  search: string;
}

export const setLRFLocalStorage = (obj: ILoginRegisterFormObjLcStr): void => {
  setLocalStorage('loginRegisterForm', obj);
};

export const getLRLocalStorage =
  async (): Promise<ILoginRegisterFormObjLcStr> => {
    return await getLocalStorage('loginRegisterForm');
  };

export const clearLRLocalStorage = (): void => {
  clearLocalStorage('loginRegisterForm');
};

//-------------------Breadcrumbs------------------------------
export const setPathBreadcrumbs = async (
  obj: IBreadcrumbsLocalStore,
): Promise<void> => {
  let breadcrumbs: IBreadcrumbsLocalStore[] =
    (await getPathBreadcrumbs()) || [];

  if (breadcrumbs && breadcrumbs.some(breadC => breadC.label === obj.label)) {
    breadcrumbs = breadcrumbs.map(breadC => {
      if (breadC.label === obj.label) {
        return obj;
      } else {
        return breadC;
      }
    });
  } else {
    breadcrumbs.push(obj);
  }

  setLocalStorage('Breadcrumbs', breadcrumbs);
};

export const getPathBreadcrumbs = async (): Promise<
  IBreadcrumbsLocalStore[]
> => {
  const objStr = await storage.getItem('Breadcrumbs');
  const retObj = JSON.parse(objStr || '[]') as IBreadcrumbsLocalStore[];

  return retObj;
};

export const clearPathBreadcrumbs = (): void => {
  clearLocalStorage('Breadcrumbs');
};
//--------------------------------------------------------

const setLocalStorage = (name: TNameLocalStorage, obj: {} = {}) => {
  storage.setItem(name, JSON.stringify(obj));
};

const getLocalStorage = async (name: TNameLocalStorage) => {
  const objStr = (await storage.getItem(name)) as string;
  const retObj = JSON.parse(objStr) as ILoginRegisterFormObjLcStr;

  return retObj;
};

const clearLocalStorage = (name: TNameLocalStorage) => {
  storage.removeItem(name);
};
