import {action, observable} from 'mobx';

export interface IRegLogFormStore {
  attemptSignUp: boolean;
  attemptLogIn: boolean;
  codeAuth: string | null;
  wasEnteredEmail: string;
  wasEnteredPhone: string;
  setAttemptSignUp(val: boolean): void;
  setAttemptLogIn(val: boolean): void;
  setCodeAuth(val: string | null): void;
  setWasEnteredPhone(val: string | null): void;
  setWasEnteredEmail(val: string | null): void;
}

const useRegLogFormStore = () => {
  const store: IRegLogFormStore = observable(
    {
      attemptSignUp: false,
      attemptLogIn: false,
      codeAuth: null,
      wasEnteredEmail: '',
      wasEnteredPhone: '',

      setAttemptSignUp(val: boolean) {
        this.attemptSignUp = val;
      },

      setAttemptLogIn(val: boolean) {
        this.attemptLogIn = val;
      },

      setCodeAuth(val: string) {
        this.codeAuth = val;
      },
      setWasEnteredEmail(val: string) {
        this.wasEnteredEmail = val;
      },
      setWasEnteredPhone(val: string) {
        this.wasEnteredPhone = val;
      },
    },
    {
      attemptSignUp: observable,
      attemptLogIn: observable,
      codeAuth: observable,
      wasEnteredEmail: observable,
      wasEnteredPhone: observable,
      setAttemptSignUp: action,
      setAttemptLogIn: action,
      setCodeAuth: action,
      setWasEnteredEmail: action,
      setWasEnteredPhone: action,
    },
  );

  return store;
};

export default useRegLogFormStore;
