import type {FC} from 'react';
import React, {memo} from 'react';

import styles from './styles.module.css';

interface Props {
  isOpen: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const BurgerButton: FC<Props> = ({isOpen, onClick}) => {
  return (
    <div
      className={`BurgerBut ${styles.CustomBurgerBut} ${
        isOpen ? styles.open : ''
      }`}
      onClick={onClick}
    >
      <div className={styles.burgerLines} />
    </div>
  );
};

export default memo(BurgerButton);
