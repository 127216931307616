import React from 'react';

import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';

import {Layout as L} from '../Layout';

export const InfoItem: React.FC<{label: string; icon: any}> = props => {
  return (
    <L.Info.Item.Container>
      <L.Info.Item.Icon>
        <IconBox variant={'round'} bg={'gray10'} fill={'icon3'} size={'icon.s'}>
          {props.icon}
        </IconBox>
      </L.Info.Item.Icon>
      <L.Info.Item.Content>
        <Text color={'text3'} variant={'small'}>
          {props.label}
        </Text>
        {props.children}
      </L.Info.Item.Content>
    </L.Info.Item.Container>
  );
};
