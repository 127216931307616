import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {Observer} from 'mobx-react-lite';

import {autorun} from 'mobx';

import {
  createField,
  getUserNameSchema,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Checkbox from '@src/components/Checkbox';
import TextField from '@src/components/TextField';
import {DEFAULT_USER_NAME} from '@src/config';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import EditorStep from './EditorStep';
import styles from './styles.module.css';

interface Data {
  name: string;
  private_profile: {first_name: string};
}

interface Props {
  value?: Data;
  onChange?: (arg0: Data) => void;
}

interface Editor {
  validate: () => boolean;
}

const I18N_SCOPE = 'Onboarding.name';

const NameEditor = React.forwardRef<Editor, Props>(({onChange}, ref) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const dropDefault = (nick: string, defaults: string[]) =>
    defaults.includes(nick) ? '' : nick;

  const fields = useRef({
    name: createHtmlInputField('name', {
      defaultValue: currentUserStore.user?.private_profile?.first_name || '',
      validationRule: getUserNameSchema(),
    }),
    nick: createHtmlInputField('nick', {
      defaultValue: dropDefault(currentUserStore.user?.name || '', [
        DEFAULT_USER_NAME,
        currentUserStore.user?.private_profile?.first_name || '',
      ]),
      validationRule: {
        ...getUserNameSchema(),
        messages: {
          required: t([I18N_SCOPE, 'nick_required_error']),
          stringMax: t('shared.validation.name_max_length', {
            maxLength: 255,
          }),
        },
      },
    }),
    useName: createField<boolean>('useName', {
      defaultValue:
        currentUserStore.user?.name ===
        currentUserStore.user?.private_profile.first_name,
    }),
  }).current;

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        if (!fields.name.validate()) {
          return false;
        }

        if (fields.useName.value) {
          fields.nick.value = fields.name.value;

          return true;
        }

        return fields.nick.validate();
      },
    }),
    [fields],
  );

  useEffect(() => {
    autorun(() => {
      if (onChange) {
        onChange({
          name: fields.nick.value || fields.name.value,
          private_profile: {
            ...currentUserStore.user?.private_profile,
            first_name: fields.name.value,
          },
        });
      }
    });
  }, [
    onChange,
    currentUserStore.user?.private_profile,
    fields.nick.value,
    fields.name.value,
  ]);

  const onUseNameClick = useCallback(() => {
    fields.useName.value = !fields.useName.value;
  }, [fields.useName]);

  return (
    <>
      <Observer>
        {() => (
          <TextField
            placeholder={t([I18N_SCOPE, 'placeholder_name'])}
            value={fields.name.value}
            onChange={fields.name.onChange}
            error={fields.name.error}
            autoFocus
          />
        )}
      </Observer>
      <div className={styles.nameDescription}>
        {t([I18N_SCOPE, 'privacy_text'])}
        <br />
        <br />
        {t([I18N_SCOPE, 'enter_nick_text'])}
      </div>
      <Observer>
        {() => (
          <TextField
            placeholder={t([I18N_SCOPE, 'placeholder_nick'])}
            value={fields.nick.value}
            onChange={fields.nick.onChange}
            error={fields.nick.error}
          />
        )}
      </Observer>
      <br />
      <Observer>
        {() => (
          <Checkbox
            label={t([I18N_SCOPE, 'use_first_name_label'])}
            checked={!!fields.useName.value}
            onClick={onUseNameClick}
          />
        )}
      </Observer>
    </>
  );
});

const NameStep = props => {
  const editorRef = React.createRef<Editor>();

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      step={0}
      fieldName="name"
      editorRef={editorRef}
      {...props}
    >
      <NameEditor ref={editorRef} />
    </EditorStep>
  );
};

export default NameStep;
