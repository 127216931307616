/**
 * Styles for the Meal component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

//import {getColor, makeColorScheme, switchProp} from '@yourcoach/shared/styles/utils';
// import type {StyledProps} from './types';

export const mealContainer = css`
  flex-direction: column;
`;

export const mealLineContainer = css`
  align-items: center;
  gap: 10px;
`;

export const mealLineCell = css`
  width: 50%;
  justify-content: flex-end;
`;

export const mealLineCellRight = css`
  ${mealLineCell};
  justify-content: flex-start;
`;
