import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {CssTransitionBox} from '@yourcoach/shared/uikit/CssTransitionBox';

import TopMenuLayout from '@src/layouts/TopMenuWidgetLayout/TopMenuWidgetLayout';
import {PATH_TYPE} from '@src/routes/utils';

import Widget from 'web/widget/src/Widget';

import {useIsAuth} from '../common/useUserMethods';
import styles from '../styles/App.module.css';
import {PageLoading} from '../v2/components/PageLoading';
import {PathBuilderService} from '../v2/services/PathBuilderService';

import PrivateRoute from './LazyPrivateRoute';

export const UnsubscribePage = React.lazy(() =>
  import('@src/pages/Unsubscribe' /* webpackChunkName: "Unsubscribe" */).then(
    module => ({default: module.UnsubscribePage}),
  ),
);

export const ToDosPage = React.lazy(() =>
  import('@src/v2/pages/ToDosOld' /* webpackChunkName: "todos" */).then(
    module => ({default: module.ToDosPageOld}),
  ),
);

export const TasksRedesign = React.lazy(
  () => import('@src/pages/NewLayout/components/Tasks'),
);

export const SingleEventPage = React.lazy(() =>
  import(
    '@src/v2/pages/SingleEventOld' /* webpackChunkName: "single_event" */
  ).then(module => ({default: module.SingleEventPage})),
);

export const ChatsPage = React.lazy(
  () => import('@src/pages/NewLayout' /* webpackChunkName: "chats" */),
);

export const CoachesPage = React.lazy(
  () => import('@src/pages/Coaches' /* webpackChunkName: "coaches" */),
);

export const ConferencePage = React.lazy(
  () => import('@src/pages/Conference' /* webpackChunkName: "conference" */),
);

export const ConnectStripePage = React.lazy(
  () =>
    import('@src/pages/ConnectStripe' /* webpackChunkName: "connect_stripe" */),
);

export const CoursePage = React.lazy(
  () => import('@src/pages/Course' /* webpackChunkName: "course" */),
);

export const CRUProgramPage = React.lazy(
  () => import('@src/pages/CRUProgram' /* webpackChunkName: "cru_program" */),
);

export const DashboardPage = React.lazy(
  () => import('@src/pages/Dashboard' /* webpackChunkName: "dashboard" */),
);

export const GoalsPage = React.lazy(
  () => import('@src/pages/Goals' /* webpackChunkName: "goals" */),
);

export const LoginPage = React.lazy(
  () => import('@src/pages/Login' /* webpackChunkName: "login" */),
);

export const MailchimpUnsubscribePage = React.lazy(
  () =>
    import(
      '@src/pages/MailchimpUnsubscribe' /* webpackChunkName: "mailchimp_unsubscribe" */
    ),
);

export const OnboardingPage = React.lazy(
  () => import('@src/pages/Onboarding' /* webpackChunkName: "onboarding" */),
);

export const PracticeMainPage = React.lazy(
  () =>
    import('@src/pages/PracticeMain' /* webpackChunkName: "practice_mail" */),
);

export const ProfilePage = React.lazy(
  () => import('@src/pages/Profile' /* webpackChunkName: "profile" */),
);

export const ProgramMainPage = React.lazy(
  () => import('@src/pages/ProgramMain' /* webpackChunkName: "program_main" */),
);

export const QuestionnairePage = React.lazy(
  () =>
    import('@src/pages/Questionnaire' /* webpackChunkName: "questionnaire" */),
);

export const QuestionnaireTemplatesPage = React.lazy(
  () =>
    import(
      '@src/pages/QuestionnaireTemplatesPage' /* webpackChunkName: "questionnaire_templates" */
    ),
);

export const UnknownPage = React.lazy(
  () => import('@src/pages/Unknown' /* webpackChunkName: "unknown" */),
);

export const UserQuestionnairePage = React.lazy(
  () =>
    import(
      '@src/pages/UserQuestionnaire' /* webpackChunkName: "user_questionnaire" */
    ),
);

export const MyCalendarPage = React.lazy(() =>
  import('@src/v2/pages/MyCalendar' /* webpackChunkName: "my_calendar" */).then(
    module => ({default: module.MyCalendarPage}),
  ),
);

export const FirstUserRegister = React.lazy(() =>
  import('@src/modules' /* webpackChunkName: "first_user_register" */).then(
    module => ({default: module.FirstUserRegister}),
  ),
);

export const WidgetPage = React.lazy(() =>
  import('@src/v2/pages/Widget' /* webpackChunkName: "widget" */).then(
    module => ({default: module.WidgetPage}),
  ),
);

type RouteConfig = {
  path: string | undefined;
  component: React.LazyExoticComponent<any>;
  forCoach?: boolean;
  exact?: boolean;
};

export const pathify = (...args) => {
  return `/${args.join('/')}`;
};

export const privateRoutes: RouteConfig[] = [
  {
    path: pathify(PATH_TYPE.yourSpace),
    component: DashboardPage,
  },
  {
    path: pathify(PATH_TYPE.userProfile),
    component: FirstUserRegister,
    exact: true,
  },
  {path: pathify(PATH_TYPE.profile), component: ProfilePage},
  {path: pathify(PATH_TYPE.community), component: CoachesPage, exact: true},
  {path: pathify(PATH_TYPE.myCoaches), component: CoachesPage, exact: true},
  {path: pathify(PATH_TYPE.chats), component: ChatsPage},
  {path: pathify(PATH_TYPE.toDos), component: ToDosPage},
  {path: pathify(PATH_TYPE.goals), component: GoalsPage},
  {path: pathify(PATH_TYPE.onboarding), component: OnboardingPage},
  {
    path: pathify(
      PATH_TYPE.conference,
      ':conferenceId(conference:[0-9a-f]{24})',
    ),
    component: ConferencePage,
    exact: true,
  },
  {
    path: pathify(PATH_TYPE.connectStripe),
    component: ConnectStripePage,
    exact: true,
  },
  {
    path: PathBuilderService.PATH_TEMPLATE_TO_COURSE,
    component: CoursePage,
    exact: true,
  },
  {
    path: pathify(PATH_TYPE.createQuestionnaire),
    component: QuestionnaireTemplatesPage,
    forCoach: true,
    exact: true,
  },
  {
    path: pathify(PATH_TYPE.questionnaire),
    component: QuestionnairePage,
    forCoach: true,
  },

  {
    path: pathify(PATH_TYPE.program),
    component: CRUProgramPage,
    forCoach: true,
    exact: true,
  },
  {
    path: pathify(PATH_TYPE.program, ':programId(program:[0-9a-f]{24})'),
    component: CRUProgramPage,
    forCoach: true,
    exact: true,
  },
  {
    path: pathify(
      PATH_TYPE.program,
      'clone',
      ':clonedProgramId(program:[0-9a-f]{24})',
    ),
    component: CRUProgramPage,
    forCoach: true,
    exact: true,
  },
  {path: pathify(PATH_TYPE.singleEventPage, ':id'), component: SingleEventPage},
  {path: pathify(PATH_TYPE.myCalendar), component: MyCalendarPage},
  process.env.APP_ENV !== 'prod'
    ? {path: pathify(PATH_TYPE.widget), component: WidgetPage}
    : null,
].filter(Boolean);

const publicRoutes: RouteConfig[] = [
  {path: pathify(PATH_TYPE.login), component: LoginPage, exact: true},
  {path: pathify(PATH_TYPE.actionAuth), component: LoginPage, exact: true},
  {path: pathify(PATH_TYPE.tasks), component: TasksRedesign, exact: true},
  {
    path: pathify(PATH_TYPE.coaches, ':slug'),
    component: PracticeMainPage,
    exact: true,
  },
  {
    path: PathBuilderService.PATH_TEMPLATE_TO_PROGRAM,
    component: ProgramMainPage,
    exact: true,
  },
  {
    path: pathify(PATH_TYPE.userQuestionnaire),
    component: UserQuestionnairePage,
  },
  {path: pathify(PATH_TYPE.unsubscribeMail), component: UnsubscribePage},
  {path: undefined, component: UnknownPage},
];

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);

  const setLocalStoreAppIsAuth = useIsAuth(
    () => {
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);
    },
  );

  setLocalStoreAppIsAuth();

  return (
    <React.Fragment>
      <CssTransitionBox
        height={'100vh'}
        width={'100vw'}
        isImmediately
        isShow={isLoading && !__SSR__}
        style={{zIndex: 10}}
      >
        <PageLoading />
      </CssTransitionBox>
      <Switch>
        {privateRoutes.map(({path, exact, component, forCoach}, index) => {
          const Component = component;

          return (
            <PrivateRoute
              key={index}
              path={path}
              exact={exact}
              forCoach={forCoach}
              render={p => (
                <React.Suspense fallback={<PageLoading />}>
                  <Component {...p} />
                </React.Suspense>
              )}
            />
          );
        })}

        {process.env.APP_ENV !== 'prod' ? (
          <PrivateRoute
            path={pathify(PATH_TYPE.widget)}
            exact
            render={() => (
              <React.Suspense fallback={<PageLoading />}>
                <TopMenuLayout />
                <main className={styles.Content}>
                  <Widget viewportHeight={980} viewportWidth={660} />
                </main>
              </React.Suspense>
            )}
          />
        ) : null}
        <PrivateRoute exact path={'/'}>
          <Redirect to={`/${PATH_TYPE.yourSpace}`} />
        </PrivateRoute>
        <Redirect
          from={PathBuilderService.PATH_TEMPLATE_TO_PROGRAM_OLD}
          to={PathBuilderService.PATH_TEMPLATE_TO_PROGRAM}
        />
        <Redirect
          from={PathBuilderService.PATH_TEMPLATE_TO_COURSE_OLD}
          to={PathBuilderService.PATH_TEMPLATE_TO_COURSE}
        />

        {publicRoutes.map(({path, exact, component}, index) => {
          const Component = component;

          return (
            <Route
              path={path}
              key={index}
              exact={exact}
              render={p => (
                <React.Suspense fallback={<PageLoading />}>
                  <Component {...p} />
                </React.Suspense>
              )}
            />
          );
        })}
      </Switch>
    </React.Fragment>
  );
};

export default React.memo(Routes);
