import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';

import classNames from 'classnames';

import TextField from '@src/components/TextField';
import {t} from '@src/i18n';

import styles from './styles.module.css';

interface Props {
  text: string;
  time: number;
  editable?: boolean;
  isSelected?: boolean;
  onChange?: (arg0: {title: string; time: number}) => void;
  onBlur?: React.FocusEventHandler;
}

const I18N_SCOPE = 'Onboarding.tasks';

const TaskEditor: FC<Props> = ({
  text,
  time,
  onChange,
  onBlur,
  editable = false,
  isSelected = false,
}) => {
  const hhmm2seconds = (hhmm: string) => {
    const [hours, minutes] = hhmm.split(':');

    return (
      3600 * Number.parseInt(hours, 10) + 60 * Number.parseInt(minutes, 10)
    );
  };

  const seconds2hhmm = (seconds: number) =>
    Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0') +
    ':' +
    (Math.floor(seconds / 60) % 60).toString().padStart(2, '0');

  const onTextChange = useCallback(
    event => {
      onChange && onChange({title: event.currentTarget.value, time: time});
    },
    [onChange, time],
  );

  const timeStr = useMemo(() => seconds2hhmm(time), [time]);

  const onTimeChange = useCallback(
    event => {
      onChange &&
        onChange({title: text, time: hhmm2seconds(event.currentTarget.value)});
    },
    [onChange, text],
  );

  const onKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        onBlur &&
          onBlur({currentTarget: event.target as Element} as React.FocusEvent);
      }
    },
    [onBlur],
  );

  return (
    <div
      className={classNames(styles.TaskEditor, {
        [styles.editable]: editable,
        [styles.selected]: isSelected,
      })}
    >
      {editable ? (
        <TextField
          placeholder={t([I18N_SCOPE, 'add_own_placeholder'])}
          value={text}
          onChange={onTextChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      ) : (
        <div>{text}</div>
      )}
      <input
        className={styles.timeButton}
        type="time"
        value={timeStr}
        onChange={onTimeChange}
      />
    </div>
  );
};

export default memo(TaskEditor);
