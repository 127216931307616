import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import {Observer} from 'mobx-react';

import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {ExpandedPost} from '@yourcoach/shared/api/channel/post';
import {postExpand} from '@yourcoach/shared/api/channel/post';

import ScrollToElement from '../../../../components/ScrollToElement/ScrollToElement';
import AppContext from '../../../../context/App';
import styles from '../../styles/styles.module.css';
import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';
import PostsListItem from '../PostsListItem/PostsListItem';

const LIMIT = 20;

const CURSOR_DIRECTION = -1;

type Post = ExpandedPost;

interface ILocalStore {
  pinnedPostsStore: CollectionStore<Post> | null;
  _isPaginating: boolean;
  postsReverse: Post[];
  isFirstVisible: boolean;
  setIsFirstVisible(isFirstVisible: boolean): void;
  endReachedTimerId: number;
  setEndReachedTimerId(endReachedTimerId: number): void;
}

interface Props {}

const ChannelPinnedPosts: FC<Props> = () => {
  const {
    stores: {postStore},
  } = useContext(AppContext);
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);
  const localStore = useRef(
    observable<ILocalStore>(
      {
        endReachedTimerId: 0,
        setEndReachedTimerId(endReachedTimerId: number) {
          this.endReachedTimerId = endReachedTimerId;
        },
        pinnedPostsStore: null,
        _isPaginating: false,
        isFirstVisible: true,
        setIsFirstVisible(isFirstVisible: boolean) {
          this.isFirstVisible = isFirstVisible;
        },
        get postsReverse() {
          if (this.pinnedPostsStore) {
            const clonePosts = [
              ...(this.pinnedPostsStore.items as Post[]).slice(),
            ];

            return clonePosts.reverse();
          } else {
            return [];
          }
        },
      },
      {
        endReachedTimerId: observable,
        setEndReachedTimerId: action,
        pinnedPostsStore: observable,
        _isPaginating: observable,
        postsReverse: computed,
        isFirstVisible: observable,
        setIsFirstVisible: action,
      },
    ),
  ).current;

  useEffect(() => {
    let timerId = 0;

    const disposeCannelUpdate = reaction(
      () => localStore.postsReverse,
      () => {
        localStore.setIsFirstVisible(true);

        if (localStore.postsReverse.length > 0) {
          clearTimeout(timerId);
          // @ts-ignore
          timerId = setTimeout(() => {
            mainChatsLocalStore?.setSeeCurrentPostId(
              localStore.postsReverse[localStore.postsReverse.length - 1]._id,
            );
          }, 200);
        }
      },
    );

    if (localStore.postsReverse.length > 0) {
      clearTimeout(timerId);
      // @ts-ignore
      timerId = setTimeout(() => {
        mainChatsLocalStore?.setSeeCurrentPostId(
          localStore.postsReverse[0]._id,
        );
      }, 200);
    }

    return () => {
      disposeCannelUpdate();
      clearTimeout(timerId);
      clearTimeout(localStore.endReachedTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const channel = mainChatsLocalStore!.channel!;

    localStore.pinnedPostsStore = createCollectionStore({
      method: 'client.channels.posts.list',
      params: {
        limit: LIMIT,
        cursor: [CURSOR_DIRECTION, 0, null],
        channel_id: channel._id,
        expand: postExpand,
        query: [['is_pinned', '==', true]],
      },
    });

    const disposePostUpdate = reaction(
      () => postStore.updating,
      updating => {
        if (updating.success && updating.entity!.channel_id === channel._id) {
          localStore.pinnedPostsStore!.updateItem(
            updating.entity as Post,
            updating.entity,
          );

          if (!updating.entity!.is_pinned) {
            localStore.pinnedPostsStore!.removeItem(updating.entity as Post);
          }
        }
      },
    );
    const disposePostDelete = reaction(
      () => postStore.deleting,
      deleting => {
        if (deleting.entity && deleting.entity.channel_id !== channel._id) {
          return;
        }

        if (deleting.inProgress) {
          localStore.pinnedPostsStore!.updateItem(deleting.entity as Post, {
            inProgress: true,
          });
        } else if (deleting.success) {
          localStore.pinnedPostsStore!.removeItem(deleting.entity as Post);
        } else {
          localStore.pinnedPostsStore!.updateItem(deleting.entity as Post, {
            inProgress: false,
            error: deleting.error,
          });
        }
      },
    );

    _onRefresh();

    return () => {
      disposePostUpdate();
      disposePostDelete();
      localStore.pinnedPostsStore!.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onPostPress = (post: Post) => {
    mainChatsLocalStore!.setSelectedPinnedPostId(post._id);
    mainChatsLocalStore?.setMainTabSelect('main');
  };

  const _onRefresh = (silent = false) => {
    localStore.pinnedPostsStore!.fetch(
      {
        limit: Math.max(localStore.pinnedPostsStore!.items.length, LIMIT),
      },
      {
        silent,
      },
    );
  };

  const handleOnPostPress = (post: Post) => {
    return () => _onPostPress(post);
  };

  const _onEndReached = async () => {
    if (localStore._isPaginating) {
      return;
    }

    localStore._isPaginating = true;

    if (localStore.pinnedPostsStore!.cursor.next) {
      await localStore.pinnedPostsStore!.fetch(
        {
          cursor: localStore.pinnedPostsStore!.cursor.next,
        },
        {
          appendTo: 'end',
          silent: true,
        },
      );
    }

    localStore._isPaginating = false;
  };

  const handleOnChangeInView = async (inView: boolean) => {
    if (inView && !localStore.isFirstVisible) {
      const lastPostId = mainChatsLocalStore!.MinPostId;

      _onEndReached();

      mainChatsLocalStore?.setSeeCurrentPostId(lastPostId);
    }

    if (inView && localStore.isFirstVisible) {
      localStore.setIsFirstVisible(false);
      clearTimeout(localStore.endReachedTimerId);
      localStore.setEndReachedTimerId(
        // @ts-ignore
        setTimeout(() => {
          _onEndReached();
        }, 2000),
      );
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`ChannelPinnedPosts percent100H  ${styles.ChatMain}`}>
          <div className={styles.ChatMainOverFlowContainer}>
            <InView as="div" onChange={handleOnChangeInView}>
              <div />
            </InView>
            {localStore.pinnedPostsStore &&
              localStore.postsReverse.map(post => {
                return (
                  <div
                    key={post._id}
                    className={styles.ChatMainPostsContainer}
                    onClick={handleOnPostPress(post)}
                  >
                    <PostsListItem
                      post={post}
                      showUserName
                      hasCourse={
                        !!(
                          mainChatsLocalStore!.channel!.type === 'course' &&
                          mainChatsLocalStore!.channel!.resource_id &&
                          mainChatsLocalStore!.channel!.resource
                        )
                      }
                    />
                    {mainChatsLocalStore?.seeCurrentPostId === post._id ? (
                      <InView as="div">
                        <ScrollToElement />
                      </InView>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChannelPinnedPosts);
