import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

import TrashIcon from '@yourcoach/shared/assets/icons/trash.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {t} from '@src/i18n';
import type {Goal as PreviousGoal} from '@src/modules/legacy/Goals/SelectGoal/SelectGoal';
import SubgoalsListItem from '@src/modules/legacy/Goals/SubgoalsListItem/SubgoalsListItem';

import styles from './../../styles.module.scss';

interface Props<T extends PreviousGoal = PreviousGoal> {
  goal: T;
  index: number;
  onPress?: (
    goal: T,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onDeleteButtonPress?: (goal: T) => void;
  onSubgoalPress?: (subgoal: T['subgoals'][0]) => void;
  onDeleteSubgoalButtonPress?: (subgoal: T['subgoals'][0]) => void;
}

const PreviousGoalsListItem: FC<Props> = ({
  goal,
  index,
  onPress,
  onDeleteButtonPress,
  onSubgoalPress,
  onDeleteSubgoalButtonPress,
}) => {
  const onPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onPress && onPress(goal, event);
    },
    [goal, onPress],
  );

  const onDeleteButtonPressCb = useCallback(() => {
    onDeleteButtonPress && onDeleteButtonPress(goal);
  }, [goal, onDeleteButtonPress]);

  return (
    <Observer>
      {() => (
        <div
          className={`PreviousGoalsListItem ${styles.PreviousGoalsListItem}`}
        >
          <div className={styles.previousGoalContentWrapper}>
            <div className={styles.previousGoalContentContainer}>
              <div className={styles.flex1}>
                <div className={styles.goalIndex}>
                  {t('label.goal_index', {index})}
                </div>
                <div className={styles.goalTitle}>{goal.title}</div>
              </div>
            </div>
            <div className={styles.menu}>
              <div
                className={styles.deleteGoalIconContainer}
                onClick={onDeleteButtonPressCb}
              >
                <TrashIcon fill={themes.light.color.icon9} />
              </div>
              <div className={styles.moreBut} onClick={onPressCb}>
                ⋮
              </div>
            </div>
          </div>
          <div className={styles.description}>{goal.description}</div>
          <div className={styles.shortTermGoals}>Short-term goals </div>
          {goal.subgoals.map(subgoal => (
            <SubgoalsListItem
              key={subgoal._id}
              subgoal={subgoal}
              isRemovable
              onPress={onSubgoalPress}
              onDeleteButtonPress={onDeleteSubgoalButtonPress}
            />
          ))}
        </div>
      )}
    </Observer>
  );
};

export default memo(PreviousGoalsListItem);
