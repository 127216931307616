import type {SyntheticEvent} from 'react';
import React, {useCallback, useState} from 'react';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import Plant1SVG from '../images/plant1.svg';
import Plant2SVG from '../images/plant2.svg';
import Plant3SVG from '../images/plant3.svg';
import Plant4SVG from '../images/plant4.svg';

import styles from './roadmap.module.css';

const RoadmapItem = ({title, description}) => (
  <li className={styles.item}>
    <div className={styles.itemLeft}>
      <div className={styles.itemBullet} />
    </div>
    <div className={styles.itemContent}>
      <div className={styles.itemTitle}>{title}</div>
      {description && !description.includes(I18N_SCOPE) ? (
        <div className={styles.itemDescription}>{description}</div>
      ) : null}
    </div>
  </li>
);

const NUM_SECTIONS = 4;

const NUM_ITEMS = 14;

const SECTION_INDICES = [5, 9, 11, 1000];

const I18N_SCOPE = 'Onboarding.roadmap';

const RoadmapStep = ({onContinue}) => {
  const [section, setSection] = useState(0);

  const onListScroll = useCallback((event: SyntheticEvent<HTMLElement>) => {
    const container = event.currentTarget;
    const list = event.currentTarget.children[0] as HTMLUListElement;

    const firstVisibleChildIndex = Array(list.children.length)
      .fill(0)
      .map(
        (_, i) =>
          (list.children[i] as HTMLLIElement).offsetTop -
          list.offsetTop -
          container.scrollTop,
      )
      .findIndex(x => x >= container.clientHeight / 2);

    setSection(SECTION_INDICES.findIndex(i => i > firstVisibleChildIndex));
  }, []);

  return (
    <div className={styles.roadmap}>
      <div className={styles.leftPane}>
        <div className={styles.title}>{t([I18N_SCOPE, 'title'])}</div>
        <ul className={styles.navBox}>
          {Array(NUM_SECTIONS)
            .fill(0)
            .map((_, i) => (
              <li
                key={`item-${i}`}
                className={i === section ? styles.active : ''}
              >
                {t([I18N_SCOPE, `nav${i + 1}`])}
              </li>
            ))}
        </ul>
      </div>
      <div className={styles.rightPane}>
        <div className={styles.rightTitle}>
          {t([I18N_SCOPE, `nav${section + 1}`])}
        </div>
        <div className={styles.listAndPlant}>
          <div className={styles.listContainer} onScroll={onListScroll}>
            <ul className={styles.itemsList}>
              <div className={styles.dashedLine} />
              {Array(NUM_ITEMS)
                .fill(0)
                .map((_, i) => (
                  <RoadmapItem
                    key={`item-${i}`}
                    data-index={i}
                    title={t([I18N_SCOPE, `item${i + 1}.title`])}
                    description={t([I18N_SCOPE, `item${i + 1}.description`])}
                  />
                ))}
            </ul>
          </div>
          {[Plant1SVG, Plant2SVG, Plant3SVG, Plant4SVG][section]({})}
          {section === 3 ? (
            <Button className={styles.continueButton} onClick={onContinue}>
              {t([I18N_SCOPE, 'submit_button'])}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RoadmapStep;
