import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import classNames from 'classnames';

import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {
  parseDuration,
  parsePaymentPlan,
} from '@yourcoach/shared/api/program';
import {
  FULL_PORTION,
  getAverageMonthlyPayment,
  getMonthlyPaymentPerUnitString,
  ProgramPaymentTypeEnum,
} from '@yourcoach/shared/api/program';
import InfoIcon from '@yourcoach/shared/assets/icons/info.svg';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';
import {extractCommission, getPriceString} from '@yourcoach/shared/utils/money';

import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Button from '@src/components/Button';
import Image from '@src/components/Image';
import TextField from '@src/components/TextField';
import {t} from '@src/i18n';

import type {Coach} from './CocoachingManager';
import {I18N_SCOPE} from './CocoachingManager';
import styles from './CocoachingManagerListItem.module.css';

type Props = {
  coach: Coach;
  role: 'you' | 'owner' | 'cocoach';
  status?: 'approved' | 'rejected' | 'pending';
  cantAcceptPayments: boolean;
  parsedPaymentPlan: ReturnType<typeof parsePaymentPlan>;
  parsedDuration: ReturnType<typeof parseDuration>;
  priceIsEditable: boolean;
  className?: string;
  onPriceChange?: (price: string, coach: Coach) => void;
  showMoreButton?: boolean;
  onMoreButtonClick?: (
    item: Coach,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
};

const CocoachingManagerListItem: React.FC<Props> = ({
  coach,
  role,
  status,
  cantAcceptPayments,
  parsedPaymentPlan,
  parsedDuration,
  priceIsEditable,
  className,
  onPriceChange,
  showMoreButton,
  onMoreButtonClick,
}) => {
  const onPriceChangeCb: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      e => {
        onPriceChange && onPriceChange(e.currentTarget.value, coach);
      },
      [coach, onPriceChange],
    );

  const onMoreButtonClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(coach, e);
    },
    [coach, onMoreButtonClick],
  );

  const {total, parsedCurrency, paymentType, processing} = parsedPaymentPlan;

  const coachPrice = Math.trunc(total * (coach.portion / FULL_PORTION));

  const coachCommission = Math.trunc(
    extractCommission(total) * (coach.portion / FULL_PORTION),
  );

  const monthlyPayment = getAverageMonthlyPayment(
    coachPrice,
    parsedDuration.num,
  );

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.coachContainer}>
        <div className={styles.avatarContainer}>
          <Image
            src={getFileSrc(coach.avatar, 250).url || ''}
            placeholder={<AvatarPlaceholder name={coach.name || ''} />}
          />
        </div>
        <div className={styles.nameContainer}>
          <Link
            className={styles.name}
            to={`/coaches/${coach.slug || coach._id}`}
            target="_blank"
          >
            {coach.name}
          </Link>
          <div className={styles.roleContainer}>
            <p className={styles.role}>{t([I18N_SCOPE, 'role', role])}</p>
            {status ? (
              <p className={classNames(styles.status, styles[status])}>
                {t([I18N_SCOPE, 'status', status])}
              </p>
            ) : null}
          </div>
          {cantAcceptPayments ? (
            <p className={styles.alert}>
              <InfoIcon />
              {t([I18N_SCOPE, 'coach_isnt_provider_label'])}
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={classNames(
          styles.rightPart,
          showMoreButton && styles.withMoreButton,
        )}
      >
        {onPriceChange ? (
          <div className={styles.portionContainer}>
            <TextField
              value={coach.price || ''}
              className={styles.portionInput}
              disabled={!priceIsEditable}
              onChange={onPriceChangeCb}
            />
            {coach.portion && processing === 'internal' ? (
              <>
                <p className={styles.price}>
                  {t([I18N_SCOPE, 'commission_label'], {
                    commission:
                      paymentType.id === ProgramPaymentTypeEnum.MONTHLY.id
                        ? `${getPriceString({
                            price: extractCommission(monthlyPayment),
                            currency: parsedCurrency,
                          })}${getMonthlyPaymentPerUnitString().toLowerCase()}`
                        : getPriceString({
                            price: coachCommission,
                          }),
                  })}
                </p>
                <p className={styles.price}>
                  {t([I18N_SCOPE, 'total_label'], {
                    price:
                      paymentType.id === ProgramPaymentTypeEnum.MONTHLY.id
                        ? `${getPriceString({
                            price:
                              monthlyPayment -
                              extractCommission(monthlyPayment),
                            currency: parsedCurrency,
                          })}${getMonthlyPaymentPerUnitString().toLowerCase()}`
                        : getPriceString({
                            price: coachPrice - coachCommission,
                            currency: parsedCurrency,
                          }),
                  })}
                </p>
              </>
            ) : null}
            {coach.portion &&
            processing === 'external' &&
            paymentType.id === ProgramPaymentTypeEnum.MONTHLY.id ? (
              <p className={styles.price}>
                {`${getPriceString({
                  price: monthlyPayment,
                  currency: parsedCurrency,
                })}${getMonthlyPaymentPerUnitString().toLowerCase()}`}
              </p>
            ) : null}
          </div>
        ) : null}
        {showMoreButton ? (
          onMoreButtonClick ? (
            <Button onClick={onMoreButtonClickCb} className={styles.moreButton}>
              <MoreIcon />
            </Button>
          ) : (
            <div className={classNames(styles.moreButton, styles.stub)}>
              <MoreIcon />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(CocoachingManagerListItem);
