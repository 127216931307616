import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Task} from '@yourcoach/shared/api/task';
import {taskIsDone, taskIsOverdue} from '@yourcoach/shared/api/task';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {Expanded as TaskExpanded} from '@src/models/tasks';
import {getTaskIcon} from '@src/models/tasks';

import styles from '../styles.module.css';

const I18N_SCOPE = 'TasksListItem';
const I18N_SCOPE_WORKSPACE = 'WorkspaceJoinedCoursesTabListItem';

type TaskT = Task & TaskExpanded;

interface Props<T extends TaskT = TaskT> {
  task: TaskT;
  onPress?: (task: T) => void;
  onMoreButtonPress?: (
    task: T,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  showCourseName?: boolean;
  showDescription?: boolean;
  className?: string;
  isSelect?: boolean;
}

const TasksListItem: FC<Props> = ({
  task,
  onPress,
  onMoreButtonPress,
  showCourseName,
  showDescription,
  className,
  isSelect = false,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const onPressCb = useCallback(() => {
    onPress && onPress(task);
  }, [task, onPress]);

  const onMorePressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onMoreButtonPress && onMoreButtonPress(task, event);
    },
    [onMoreButtonPress, task],
  );

  let time = useMemo(
    () => dayjs(datetimeObjToISOString(task?.start_date)).local().format('LT'),
    [task?.start_date],
  );

  const isDone = useMemo(() => taskIsDone(task), [task]);
  const isOverdue = useMemo(() => taskIsOverdue(task), [task]);

  if (isDone) {
    time = t([I18N_SCOPE, 'done_label']);
  } else if (isOverdue) {
    time = t([I18N_SCOPE, 'overdue_label']);
  }

  const isForCoachTask = useMemo(
    () => task?.coach_ids.includes(currentUserStore.user!._id),
    [currentUserStore, task?.coach_ids],
  );

  const isDoneStyle = useMemo(
    () => (isDone && !isForCoachTask ? styles.isDone : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDone, isForCoachTask, styles.isDone],
  );

  const colorsStyle: string = useMemo(() => {
    if (isForCoachTask) {
      return styles.isForCoachTask;
    } else if (isDone) {
      return styles.isDone;
    } else if (isOverdue) {
      return styles.isOverdue;
    }

    return '';
  }, [isDone, isOverdue, isForCoachTask]);

  const Icon = useMemo(() => getTaskIcon(task), [task]);

  return (
    <Observer>
      {() => (
        <div
          className={`TasksListItem ${styles.TasksListItem} ${
            isSelect ? styles.isSelect : ''
          } ${className}`}
          onClick={onPress ? onPressCb : undefined}
        >
          <div className={styles.contentWrapper}>
            <div className={`${styles.iconContainer} ${colorsStyle}`}>
              <Icon width={22} height={22} />
            </div>

            <div className={`${styles.contentContainer} ${isDoneStyle}`}>
              <div className={styles.flex1}>
                <div className={styles.title}>{task?.title}</div>
                {showCourseName && task?.course ? (
                  <div className={styles.text}>
                    {task?.course.program
                      ? task?.course.program.title
                      : t([I18N_SCOPE_WORKSPACE, 'not_found_label'])}
                  </div>
                ) : null}
                <div className={styles.courseDuration}>
                  {getCourseDurationString(task?.course)}
                </div>
              </div>
              <div className={`${styles.statusContainer} ${colorsStyle}`}>
                {!isForCoachTask && !(isDone || isOverdue) ? (
                  <div className={styles.time}>
                    {t([I18N_SCOPE, 'start_time_label'], {time})}
                  </div>
                ) : null}
                {!isForCoachTask && (isDone || isOverdue) ? (
                  <div>{time}</div>
                ) : null}
              </div>
            </div>

            {onMoreButtonPress ? (
              <div className={styles.moreButt} onClick={onMorePressCb}>
                ⋮
              </div>
            ) : null}
          </div>
          <div className={styles.subContentContainer}>
            {showDescription && task.description ? task.description : null}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(TasksListItem);
