import React, {memo} from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';

const Progress = ({progress, total}) => {
  return (
    <div className={styles.progress}>
      {Object.keys(Array(total).fill(0)).map((k, i) => (
        <div
          key={'item-' + i}
          className={classNames(styles.bar, {[styles.active]: i <= progress})}
        >
          &nbsp;
        </div>
      ))}
    </div>
  );
};

export default memo(Progress);
