import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {observable} from 'mobx';

interface ILocalStore {
  focus: React.MutableRefObject<HTMLDivElement | null>;
}

interface Props {
  isSmooth?: boolean;
  height?: number;
}

const ScrollToElement: FC<Props> = ({isSmooth = false, height = 0}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        focus: React.useRef(null),
      },
      {
        focus: observable.shallow,
      },
    ),
  ).current;

  useEffect(() => {
    if (
      localStore.focus &&
      localStore.focus.current &&
      localStore.focus.current!.scrollIntoView
    ) {
      localStore.focus.current!.scrollIntoView &&
        localStore.focus.current!.scrollIntoView({
          behavior: isSmooth ? 'smooth' : 'auto',
          block: 'center',
        });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div
          className="ScrollToElement"
          ref={localStore.focus}
          style={{height: `${height}px`}}
        />
      )}
    </Observer>
  );
};

export default memo(ScrollToElement);
