import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {createPortal} from 'react-dom';
import {useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {t} from 'i18n-js';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {ApiRpcQuery, CollectionStore, Expand} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Course as ICourse} from '@yourcoach/shared/api/course';
import type {Subgoal} from '@yourcoach/shared/api/goal';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelClear} from '@src/common/i18n/i18nCommon';
import {labelGoals} from '@src/common/i18n/i18nMainMenu';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import type {IOption} from '@src/components/CustomForm/CustomSelect/CustomSelect';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import AppContext from '@src/context/App';
import useIsDOMRendered from '@src/hooks/useIsDOMRendered';
import type {Expanded as CourseExpanded} from '@src/models/courses';
import type {
  ISelectRightGoal,
  ListItemT,
} from '@src/modules/SeeProgram/Squad/Course/CourseGoalsTab/CourseGoalsTab';
import SelectCourseContainer from '@src/modules/ToDos/SelectCourseContainer/SelectCourseContainer';

import type {GoalT} from '../Goal/Goal';
import Goal from '../Goal/Goal';
import type {Goal as TGoal} from '../GoalsTab/GoalsTab';
import GoalsTab from '../GoalsTab/GoalsTab';
import RateGoal from '../RateGoal/RateGoal';
import RateGoals from '../RateGoals/RateGoals';

import styles from './../styles.module.scss';

export type Course = ICourse & CourseExpanded;

const LIMIT = 100;

interface ILocalStore {
  squadListOptions: IOption[];
  setSquadListOptions(squadListOptions: IOption[]): void;
  userIsCoach: boolean;
  course: Course | null;
  selectRightGoal: ISelectRightGoal | null;
  setSelectRightGoal(obj: ISelectRightGoal | null): void;
  userIsCoachSelectRightGoal: boolean;
  setUserIsCoachSelectRightGoal(userIsCoachSelectRightGoal: boolean): void;
  isOpenRateModule: boolean;
  setIsOpenRateModule(isOpenRateModule: boolean): void;
  isOpenGoalProgressModule: boolean;
  setIsOpenGoalProgressModule(isOpenGoalProgressModule: boolean): void;
  goalsStore: CollectionStore<TGoal> | null;
  setGoalsStore(goalsStore: CollectionStore<TGoal>): void;
  shouldRenderStub: boolean;
}

const expand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
          ['edition_id', null, {edition: ['contract_id']}],
        ],
      },
    ],
  ],
};

interface Props {}

const GoalsSelectCourseTab: FC<Props> = () => {
  const ref = useRef<CollectionStore<TGoal> | null>(null);
  const {
    stores: {currentUserStore, goalStore},
  } = useContext(AppContext);
  const location = useLocation();

  const {isDOMRendered} = useIsDOMRendered();

  const localStore: ILocalStore = useRef(
    observable(
      {
        isOpenRateModule: false,
        setIsOpenRateModule(isOpenRateModule: boolean) {
          this.isOpenRateModule = isOpenRateModule;
        },
        isOpenGoalProgressModule: false,
        setIsOpenGoalProgressModule(isOpenGoalProgressModule: boolean) {
          this.isOpenGoalProgressModule = isOpenGoalProgressModule;
        },
        squadListOptions: [{id: '1', val: t(['Common', 'all_programs'])}],
        setSquadListOptions(squadListOptions: IOption[]) {
          this.squadListOptions = squadListOptions;
        },
        course: null,
        get userIsCoach() {
          return Boolean(currentUserStore.user?.roles.includes('coach'));
        },
        userIsCoachSelectRightGoal: false,
        setUserIsCoachSelectRightGoal(userIsCoachSelectRightGoal: boolean) {
          this.userIsCoachSelectRightGoal = userIsCoachSelectRightGoal;
        },
        selectRightGoal: null,
        setSelectRightGoal(obj: ISelectRightGoal | null) {
          this.selectRightGoal = obj;
        },
        goalsStore: null,
        setGoalsStore(goalsStore: CollectionStore<TGoal>) {
          this.goalsStore = goalsStore;
        },
        get shouldRenderStub() {
          if (this.goalsStore) {
            return this.goalsStore.isLoaded && !this.goalsStore.hasItems;
          } else {
            return false;
          }
        },
      },
      {
        squadListOptions: observable.shallow,
        setSquadListOptions: action,
        course: observable,
        userIsCoach: computed,
        selectRightGoal: observable.shallow,
        setSelectRightGoal: action,
        userIsCoachSelectRightGoal: observable,
        setUserIsCoachSelectRightGoal: action,
        isOpenRateModule: observable,
        setIsOpenRateModule: action,
        isOpenGoalProgressModule: observable,
        setIsOpenGoalProgressModule: action,
        goalsStore: observable.shallow,
        setGoalsStore: action,
        shouldRenderStub: computed,
      },
    ),
  ).current;

  const _fetchGoals = useCallback(
    async (silent = false) => {
      ref.current!.setMethod(
        localStore.userIsCoach
          ? 'coach.goals.unbound.list'
          : 'client.goals.list',
      );

      await ref
        .current!.fetch(
          {
            query: (
              [
                localStore.course
                  ? ['course_id', '==', localStore.course._id]
                  : ['course_id', '!=', null],
                localStore.userIsCoach
                  ? ['status', 'in', ['in_progress', 'in_review']]
                  : ['status', '==', 'in_progress'],
              ] as ApiRpcQuery[]
            ).filter(Boolean),
            limit: Math.max(ref.current!.items.length, LIMIT),
          },
          {silent},
        )
        .catch(() => {});
    },
    [localStore.course, localStore.userIsCoach],
  );

  useEffect(() => {
    // @ts-ignore
    if (location.state && location.state.RateGoals) {
      localStore.setIsOpenRateModule(true);
    }

    const disposeGoalStoreSubClientCreate = reaction(
      () => goalStore.sub.client.own.creating,
      () => {
        localStore.setSelectRightGoal(null);
      },
    );

    const disposeGoalStoreSubClientDeleting = reaction(
      () => goalStore.sub.client.own.deleting,
      () => {
        localStore.setSelectRightGoal(null);
      },
    );

    ref.current = createCollectionStore({
      params: {
        sort: [
          ['sort_order', 1],
          ['created', -1],
        ],
        limit: LIMIT,
        expand,
      },
    });

    localStore.setGoalsStore(ref.current);

    _fetchGoals();

    return () => {
      disposeGoalStoreSubClientCreate();
      disposeGoalStoreSubClientDeleting();

      if (ref.current) {
        ref.current.clear();
      }

      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }
    };
  }, [
    _fetchGoals,
    goalStore.sub.client.own.creating,
    goalStore.sub.client.own.deleting,
    localStore,
    location.state,
  ]);

  const _onCourseSelectorPress = (courses: Course[]) => {
    localStore.setSelectRightGoal(null);

    runInAction(() => {
      if (courses && courses.length) {
        localStore.course = courses[0];

        localStore.setSquadListOptions([
          {
            id: courses[0]._id,
            val: `${(courses[0].program || {}).title}`,
          },
        ]);

        _fetchGoals();
      }
    });
  };

  const handleOnSelectSubgoal = (
    subgoal: Subgoal,
    goal: GoalT,
    userId?: string,
  ) => {
    const isEditableEl = subgoal.user_id === currentUserStore.user?._id;

    localStore.setSelectRightGoal({
      selectGoal: goal,
      selectSubgoalId: subgoal._id,
      isEditable: isEditableEl,
      selectUserId: userId,
    });

    localStore.setUserIsCoachSelectRightGoal(
      !!(
        currentUserStore.user &&
        goal.coach_ids.includes(currentUserStore.user._id)
      ),
    );

    localStore.setIsOpenGoalProgressModule(true);
  };

  const handleOnSelectClientGoals = (goal: GoalT) => {
    localStore.setSelectRightGoal({
      selectGoal: goal,
      selectSubgoalId: null,
      isEditable: false,
      showClientsSubgoals: true,
    });

    localStore.setUserIsCoachSelectRightGoal(
      !!(
        currentUserStore.user &&
        goal.coach_ids.includes(currentUserStore.user._id)
      ),
    );

    localStore.setIsOpenGoalProgressModule(true);
  };

  const handleCloseGoalContainer = () => {
    localStore.setSelectRightGoal(null);
  };

  const handleCloseSelect = () => {
    localStore.course = null;
    localStore.setSquadListOptions([
      {id: '1', val: t(['Common', 'all_programs'])},
    ]);

    _fetchGoals();
  };

  const urlCourseId =
    // @ts-ignore
    location.state && location.state.courseId ? location.state.courseId : null;

  const handleCloseRate = () => {
    localStore.setIsOpenRateModule(false);
  };

  const handleCloseProgress = () => {
    localStore.setIsOpenGoalProgressModule(false);
  };

  const menuId = 'GoalsSelectCourseTab';

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const handleOnDeleteListItemButtonPress = args => {
    const item = args.props.item as ListItemT;

    contextMenu.hideAll();

    _onDeleteListItemButtonPress(item);
  };

  const _onDeleteListItemButtonPress = async (item: ListItemT) => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const isSubgoal = item._id.split(':')[0] === 'subgoal';

            if (isSubgoal) {
              await _deleteSubGoal(item as Subgoal);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteSubGoal = async (item: Subgoal) => {
    try {
      if (item.is_client_managed) {
        await goalStore.sub.client.own.delete(item);
      }
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const MyMenu = ({isInsideModal, portalId}) => {
    return createPortal(
      <Observer>
        {() => (
          <div onClick={handleClickMenu}>
            <Menu
              id={menuId}
              animation={animation.fade}
              className="contextMenu"
              style={{position: 'fixed', zIndex: 2000}}
            >
              <Item
                onClick={handleOnDeleteListItemButtonPress}
                className="contextMenuItem"
              >
                Delete
              </Item>
            </Menu>
          </div>
        )}
      </Observer>,
      isInsideModal && isDOMRendered
        ? document.querySelector(portalId)
        : document.body,
    );
  };

  const _onListItemMoreButtonPress = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ListItemT,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    contextMenu.show({
      id: menuId,
      event: event,
      props: {
        item,
      },
    });
  };

  const handleOnClickClear = () => {
    runInAction(() => {
      handleCloseSelect();
    });
  };

  return (
    <Observer>
      {() => (
        <div
          className={`GoalsSelectCourseTab percent100H ${styles.GoalsSelectCourseTab}`}
        >
          <div className={styles.header}>
            <h1 className={styles.titleHeader}>{labelGoals()}</h1>
            <div className="columnsContainer">
              <div
                className={`firstColumnContainer ${
                  localStore.shouldRenderStub ? 'allWidth' : ''
                }`}
              >
                <div className={styles.tabButMenu}>
                  <div className={styles.selectCourseContainer}>
                    {localStore.userIsCoach &&
                    (!localStore.shouldRenderStub ||
                      (localStore.shouldRenderStub && localStore.course)) ? (
                      <div className={styles.goalsFilterContainer}>
                        <SelectCourseContainer
                          onSelectCourse={_onCourseSelectorPress}
                          onCloseSelect={handleCloseSelect}
                          options={localStore.squadListOptions}
                          defaultId={
                            localStore.squadListOptions.slice()[0]
                              ? localStore.squadListOptions.slice()[0].id
                              : ''
                          }
                          className={styles.goalsFilter}
                        />
                        {localStore.course ? (
                          <div
                            className={styles.goalsFilterContainerClear}
                            onClick={handleOnClickClear}
                          >
                            {labelClear()}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <div className={styles.addMainButContainer} />
                  </div>
                </div>
                <GoalsTab
                  course={localStore.course}
                  onSelectSubgoal={handleOnSelectSubgoal}
                  onSelectClientGoals={handleOnSelectClientGoals}
                  selectGoalId={
                    localStore.selectRightGoal
                      ? localStore.selectRightGoal.selectSubgoalId
                      : null
                  }
                />
              </div>
              {localStore.selectRightGoal ? (
                <ModalAnimateWin
                  showModal={localStore.isOpenGoalProgressModule}
                  closeModalHandler={handleCloseProgress}
                  className="greyHeaderContainer halfContainer"
                  isBody
                  classNameBody="whiteBody noP30"
                  header={'Goal progress'}
                  classNameHeader="greyHeader"
                  classNameCloseBut="greyHeaderBut"
                >
                  <div className={styles.selectRightGoalContainer}>
                    <Goal
                      goalId={localStore.selectRightGoal.selectGoal._id}
                      goal={localStore.selectRightGoal.selectGoal}
                      subgoalId={
                        localStore.selectRightGoal.selectSubgoalId
                          ? localStore.selectRightGoal.selectSubgoalId
                          : undefined
                      }
                      showClientsSubgoals={
                        localStore.selectRightGoal.showClientsSubgoals
                          ? localStore.selectRightGoal.showClientsSubgoals
                          : undefined
                      }
                      selectUserId={localStore.selectRightGoal.selectUserId}
                      closeContainer={handleCloseGoalContainer}
                      onMorePress={
                        localStore.selectRightGoal.isEditable
                          ? _onListItemMoreButtonPress
                          : undefined
                      }
                      forCoach={localStore.userIsCoachSelectRightGoal}
                    />
                    <div className={styles.selectRightGoalContainerRateGoal}>
                      <RateGoal
                        goalId={localStore.selectRightGoal.selectGoal._id}
                        goal={localStore.selectRightGoal.selectGoal}
                      />
                    </div>
                    <MyMenu isInsideModal={true} portalId={'#custom-modal'} />
                  </div>
                </ModalAnimateWin>
              ) : null}
            </div>
          </div>
          <ModalAnimateWin
            showModal={localStore.isOpenRateModule}
            closeModalHandler={handleCloseRate}
            className="greyHeaderContainer orWhite littleContainer w440 List"
            classNameBody="whiteBody noP30"
            header={''}
            classNameHeader="greyHeader w440"
            classNameCloseBut="greyHeaderBut"
          >
            <div className="whiteBodyContent">
              <RateGoals courseId={urlCourseId} />
            </div>
          </ModalAnimateWin>
          <MyMenu isInsideModal={false} portalId={''} />
        </div>
      )}
    </Observer>
  );
};

export default memo(GoalsSelectCourseTab);
