import type {FC} from 'react';
import React, {memo} from 'react';

// import {useParams} from 'react-router-dom';
// import {apiRequest, isEntityId} from '@yourcoach/shared/api';
// import {setPathBreadcrumbs} from '@src/common/localStorageApp';
import {Practice} from '@src/modules';
// import {slugify} from '@src/routes/utils';

interface Props {}

const PracticePage: FC<Props> = () => {
  // const {slug}: {slug: string} = useParams();

  // useEffect(() => {
  //   const fetch = async () => {
  //     const isUserId = isEntityId(slug, 'user');
  //
  //     let userId = '';
  //
  //     try {
  //       if (!isUserId) {
  //         const {user} = await apiRequest({
  //           method: 'public.profile.read',
  //           params: {
  //             slug: slug,
  //           },
  //         });
  //
  //         userId = user._id;
  //       } else {
  //         userId = slug;
  //       }
  //
  {
    /*      const {user} = await apiRequest({*/
  }

  {
    /*        method: 'client.users.read',*/
  }

  {
    /*        params: {*/
  }

  {
    /*          _id: userId,*/
  }

  {
    /*        },*/
  }

  {
    /*      });*/
  }

  {
    /*      const label = slugify(user.coach_title) as string;*/
  }

  //       const pathname: string = window.location.pathname;
  //
  {
    /*      setPathBreadcrumbs({label, pathname, search: ''});*/
  }
  //     } catch (error) {
  //       // TODO: log error
  //     }
  //   };
  //
  //   fetch();
  //
  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="PracticePage">
      <Practice />
    </div>
  );
};

export default memo(PracticePage);
