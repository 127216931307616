import type {FC} from 'react';
import React, {memo} from 'react';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

interface Props {
  question: QuestionnaireQuestion;
  answer?: string;
  classAnswer?: string;
}

const SingleChoice: FC<Props> = ({question, answer, classAnswer = ''}) => {
  return (
    <div className="SingleChoiceTemplateContainer">
      <div className="name">{question.question}</div>

      {answer ? (
        <ul className={`answer ${classAnswer}`}>
          <li>
            <span>{answer}</span>
          </li>
        </ul>
      ) : (
        <ul>
          {question.options?.map((answerLabel, index) => (
            <li key={index}>
              <span>{answerLabel}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(SingleChoice);
