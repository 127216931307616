import type {FC} from 'react';
import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';

// import {PATH_TYPE} from '@src/routes/utils';
import {PathBuilderService} from '../../../v2/services/PathBuilderService';
import CourseList from '../CourseList/CourseList';
import ProgramImage from '../ProgramImage/ProgramImage';
import type {Section as ISection} from '../useMySquads';

import styles from './../styles.module.css';

interface Props {
  section: ISection;
}

const Program: FC<Props> = ({section}) => {
  const history = useHistory();
  const program = section.program;

  const handleOnProgramClick = () => {
    // history.push({
    //   pathname: `/${PATH_TYPE.coaches}/${
    //     program.expanded_coaches[0].slug
    //       ? program.expanded_coaches[0].slug
    //       : program.expanded_coaches[0]._id
    //       ? program.expanded_coaches[0]._id
    //       : ''
    //     }/programs/${program.slug ? program.slug : program._id}`,
    // });

    const practiceSlug = program.expanded_coaches[0].slug;
    const userId = program.expanded_coaches[0]._id ?? '';
    const programSlug = program.slug;
    const programId = program._id;

    history.push(
      PathBuilderService.toProgram(
        {slug: practiceSlug, id: userId},
        {slug: programSlug, id: programId as string},
      ),
    );
  };

  return (
    <div className={`Program ${styles.Program}`}>
      <div className={styles.imageContainer} onClick={handleOnProgramClick}>
        <ProgramImage program={program} />
      </div>
      <div className={styles.coursesContainer}>
        <CourseList section={section} />
      </div>
    </div>
  );
};

export default memo(Program);
