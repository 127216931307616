/**
 * TaskStatus component controller.
 */
//import React from 'react'
//import {createEventNormalizer} from '@yourcoach/shared/utils/events';

import {useTheme} from 'styled-components';

import TickSquareSvg from '@yourcoach/shared/assets/icons/primary/Tick Square.svg';
import TimeCircleSvg from '@yourcoach/shared/assets/icons/primary/Time Circle.svg';
import type {Text} from '@yourcoach/shared/uikit/Text';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import {scheme} from './styles';
import type {Props, StyledProps} from './types';

export const useController = ({isOverdue, status, sizeVariant}: Props) => {
  const theme = useTheme();

  let schemeStatus: StyledProps['scheme'] = status;
  let label = 'In progress';
  let Icon = TickSquareSvg;
  let textSizeVariant: GetComponentProps<typeof Text>['variant'] =
    sizeVariant === 'default' ? 'regular' : 'small';

  switch (status) {
    case 'pending': {
      if (isOverdue) {
        schemeStatus = 'overdue';
        label = 'Overdue';
        Icon = TimeCircleSvg;
      }
      break;
    }
    case 'frozen': {
      label = 'Frozen';
      break;
    }
    case 'done': {
      label = 'Done';
      break;
    }
  }

  const iconFill = scheme.getColor('primary')({
    theme,
    scheme: schemeStatus,
    sizeVariant,
  });

  return {schemeStatus, label, Icon, iconFill, textSizeVariant};
};
