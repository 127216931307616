import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {labelProgress} from '@src/common/i18n/i18nQuestionnaire';
import {CustomButton} from '@src/components/CustomForm';
import DropdownMenu from '@src/components/DropdownMenu/DropdownMenu';
import {IconList} from '@src/components/icons';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import UserFlowContext from '../../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../../context/useUserFlowLocalStore';

import styles from './../styles.module.css';

interface Props {}

const QUserFlowHeader: FC<Props> = () => {
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);

  let openDropDown: () => void;
  let sectionName = '';

  //dragContainerEl.current!.addEventListener("click", highlightThis, true)

  const handleGetOpenFunc = (fn: () => void) => {
    openDropDown = fn;
  };

  const handleClickProgress = () => {
    openDropDown();
  };

  const handleClickStep = (numStep: number) => {
    return () => {
      userFlowStory?.setCurrentNumStep(numStep);
    };
  };

  return (
    <Observer>
      {() => (
        <div className="QUserFlowHeader">
          <div className={styles.QUserFlowHeader}>
            <div className={styles.tittleContainer}>
              <div className={styles.title}>
                {userFlowStory?.currentUserQ?.title}
              </div>
              <div className={styles.user}>
                {userFlowStory?.coaches[0].name}
              </div>
            </div>
            <div className={styles.childrenContainer}>
              {!userFlowStory!.isSendAnswers && (
                <>
                  <CustomButton
                    type="button"
                    classButton={`standardButton borderRadius10 ${styles.butProgress}`}
                    onClick={handleClickProgress}
                  >
                    <div className={styles.textButContainer}>
                      <IconList customClass={styles.iconList} />
                      {labelProgress()}
                    </div>
                  </CustomButton>
                  <DropdownMenu
                    getOpenFunc={handleGetOpenFunc}
                    className={styles.dropdownMenu}
                  >
                    <div className={styles.allSectionContainer}>
                      {userFlowStory?.stepsQuestion.map((stepQ, numStep) => {
                        let displaySectionName = '';

                        if (sectionName !== stepQ.sectionName) {
                          sectionName = stepQ.sectionName;
                          displaySectionName = stepQ.sectionName;
                        } else {
                          displaySectionName = '';
                        }

                        return (
                          <div className={styles.stepSection} key={numStep}>
                            {displaySectionName !== '' ? (
                              <div className={styles.sectionName}>
                                {displaySectionName}
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className={styles.qContainer}
                              onClick={handleClickStep(numStep)}
                            >
                              <div
                                className={`${styles.qName} ${
                                  stepQ.textError !== '' ? styles.error : ''
                                }`}
                              >
                                {stepQ.question.question}
                              </div>
                              <div
                                className={`${styles.answers}
                              ${
                                stepQ.question.type ===
                                  ALIAS_TYPES.multipleChoice ||
                                stepQ.question.type ===
                                  ALIAS_TYPES.multipleChoiceOpen
                                  ? styles.multiC
                                  : ''
                              } ${
                                  stepQ.question.type ===
                                    ALIAS_TYPES.singleChoice ||
                                  stepQ.question.type ===
                                    ALIAS_TYPES.singleChoiceOpen
                                    ? styles.singleC
                                    : ''
                                }`}
                              >
                                <ul>
                                  {stepQ.answerArr.map((answer, numAnswer) => (
                                    <li key={numAnswer}>{answer}</li>
                                  ))}
                                  {stepQ.userAnswer !== '' ? (
                                    <li>{stepQ.userAnswer}</li>
                                  ) : (
                                    ''
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(QUserFlowHeader);
