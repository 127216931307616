import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, autorun, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';

import {labelNoFilesLibrary} from '@src/common/i18n/i18nPrograms';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import type {
  ILink,
  TFileWithLink,
  TListDataItem,
  TSystemFolder,
} from '@src/models/library';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';
import {getMediaLinksListObj} from '../ListFolders/ListFolderLibrary';

import UserFile from './UserFile/UserFile';
import UserFolder from './UserFolder/UserFolder';

interface ILocalStore {
  listSelect: TListDataItem[];
  folderItem: TListDataItem[];
  addItem: (select: TListDataItem) => void;
  delItem: (select: TListDataItem) => void;
  setFolderItem: (select: TListDataItem) => void;
}

interface Props {
  listSelect: TListDataItem[];
  updateListSelect: (listSelect: TListDataItem[]) => void;
  loadFiles?: IFile[];
}

const ListFoldersSelect: FC<Props> = ({listSelect, updateListSelect}) => {
  const libraryStore: ILibraryLocalStore | null = useContext(LibraryContext);
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const localStorage: ILocalStore = useMemo(
    () =>
      observable(
        {
          listSelect: listSelect || [],
          folderItem: [],
          addItem(select: TListDataItem) {
            this.listSelect.push(select);
            updateListSelect(this.listSelect);
          },
          delItem(select: TListDataItem) {
            const index = this.listSelect.findIndex(
              item => item._id === select._id,
            );

            if (index >= 0) {
              this.listSelect.splice(index, 1);

              updateListSelect(this.listSelect);
            } else {
              this.addItem(select);
            }
          },
          setFolderItem(folderItem: TListDataItem) {
            this.folderItem = folderItem;
          },
        },
        {
          listSelect: observable,
          folderItem: observable,
          addItem: action,
          delItem: action,
          setFolderItem: action,
        },
      ),
    [listSelect, updateListSelect],
  );

  useEffect(() => {
    autorun(() => {
      // @ts-ignore
      const folderId = localStorage!.folderItem._id;

      const links: CollectionStore<ILink> = createCollectionStore(
        getMediaLinksListObj(folderId),
      );

      const linksFolder: CollectionStore<ILink> = createCollectionStore(
        getMediaLinksListObj(null),
      );

      if (currentUserStore.user) {
        libraryStore!.setIsCouch(currentUserStore.user.roles.includes('coach'));
      }

      libraryStore!.getFoldersLinks(links);
      libraryStore!.getFoldersMainLinks(linksFolder);
      libraryStore!.updateFolderLinks(0);

      return () => {
        links.clear();
        linksFolder.clear();
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.folderItem]);

  const handleItemClick = (item: TListDataItem) => {
    return () => {
      item.type === 'folder'
        ? localStorage.setFolderItem(item)
        : localStorage.delItem(item);
    };
  };

  return (
    <Observer>
      {() => (
        <div className="listFolderSelect">
          {(libraryStore!.data.length === 0 ||
            libraryStore!.data.every(
              (item: TListDataItem) =>
                typeof (item as TSystemFolder).type === 'undefined',
            )) && <NoResults text={labelNoFilesLibrary()} />}
          {libraryStore!.data.map((item: TListDataItem) => {
            const index = localStorage.listSelect.findIndex(
              itemStore => item._id === itemStore._id,
            );

            const isSelect = index >= 0;

            if (typeof (item as TSystemFolder).type === 'undefined') {
              return null;
            } else if ((item as ILink).type === 'folder') {
              return (
                <UserFolder
                  key={item._id}
                  data={item as ILink}
                  isSelect={isSelect}
                  className="UserFolder"
                  onClick={handleItemClick(item)}
                />
              );
            } else if ((item as ILink).resource!.type === 'url') {
              return (
                <UserFile
                  key={item._id}
                  className="UserFile"
                  isSelect={isSelect}
                  onClick={handleItemClick(item)}
                  file={
                    {
                      ...(item as ILink).resource,
                      link: {
                        ...item,
                        resource: null,
                      },
                    } as unknown as TFileWithLink
                  }
                />
              );
            } else {
              return (
                <UserFile
                  key={item._id}
                  className="UserFile"
                  isSelect={isSelect}
                  onClick={handleItemClick(item)}
                  file={
                    {
                      ...(item as ILink).resource,
                      link: {
                        ...item,
                        resource: null,
                      },
                    } as unknown as TFileWithLink
                  }
                />
              );
            }
          })}
        </div>
      )}
    </Observer>
  );
};

export default memo(ListFoldersSelect);
