/**
 * Styles for the Removable component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

//import {getColor, makeColorScheme, switchProp} from '@yourcoach/shared/styles/utils';
import type {StyledProps} from './types';

const container = css`
  position: relative;
`;

export const containerWeb = css`
  box-sizing: border-box;
  ${container};
`;

const buttonContainer = css<StyledProps>`
  position: absolute;
  z-index: 2;
  height: 16px;
  width: 16px;
  top: -8px;
  right: -8px;
`;

export const buttonContainerWeb = css<StyledProps>`
  box-sizing: border-box;
  ${buttonContainer}
`;

export const native = css<StyledProps>`
  ${buttonContainer}
`;
