import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {ScrollbarProps} from 'react-scrollbars-custom';

import classNames from 'classnames';

import type {ApiRpcQuery} from '@yourcoach/shared/api';
import {apiRequest, expandObj} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Program} from '@yourcoach/shared/api/program';
import {programIsGroup} from '@yourcoach/shared/api/program';
import ArrowIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import ChatIcon from '@yourcoach/shared/assets/icons/chat.svg';
import {waitFor} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import Collapsible from '@src/components/Collapsible';
import {
  confirm,
  getCustomConfirmAlert,
} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import Scrollbar from '@src/components/Scrollbar';
import Selectable from '@src/components/Selectable';
import useIsVisible from '@src/hooks/useIsVisible';
import useSelection from '@src/hooks/useSelection';
import {t} from '@src/i18n';
import type {Expanded as CourseExpanded} from '@src/modules/courses/utils';
import {expand as courseExpand} from '@src/modules/courses/utils';
import type {Expanded as ProgramExpanded} from '@src/modules/programs/utils';
import {expand as programExpand} from '@src/modules/programs/utils';

import CreateCourse from '../../SeeProgram/CreateCourse/CreateCourse';

import CoursesListItem from './CoursesListItem';
import CreateCourseRow from './CreateCourseRow';
import ProgramsListItem from './ProgramsListItem';
import styles from './SelectCourse.module.css';

export type CourseT = Course & CourseExpanded;

export type ProgramT = Program & ProgramExpanded;

type ProgramWithCourses = ProgramT & {courses: CourseT[]};

export const I18N_SCOPE = 'SelectCourse';

const LIMIT = 999;

interface IdFilter {
  include: string[];
  exclude: string[];
}

interface Section {
  program: ProgramT;
  courses: CourseT[];
}

const maybeClause = (field: string, op: string, items?: Array<unknown>) => {
  if (items && items.length) {
    return [field, op, items];
  }

  return null;
};

export interface Props {
  initialSelection?: CourseT[];
  onSelect?: (courses: CourseT[]) => void;
  multiselection?: boolean;
  programIds?: IdFilter;
  courseIds?: IdFilter;
  showPlanned?: boolean;
  showGroupOnly?: boolean;
  showIndividual?: boolean;
  showAddRow?: boolean;
  excludeIds?: string[];
  confirmBeforeSelect?: boolean;
  useScrollbar?: boolean;
  scrollProps?: ScrollbarProps;
}

const SelectCourse: FC<Props> = ({
  programIds,
  courseIds,
  showPlanned,
  showGroupOnly = false,
  showIndividual = true,
  showAddRow = false,
  multiselection,
  confirmBeforeSelect,
  initialSelection,
  onSelect,
  useScrollbar,
  scrollProps,
}) => {
  // Selection will be set after course load
  const {
    selection,
    setSelection,
    onSelect: selectCourse,
  } = useSelection<CourseT>({
    selection: [],
    multiselection,
  });

  const {
    selection: openedPrograms,
    setSelection: setOpenedPrograms,
    onSelect: toggleProgram,
  } = useSelection<ProgramT>({selection: [], multiselection: true});

  const [
    createCourseModalIsOpen,
    showCreateCourseModal,
    hideCreateCourseModal,
  ] = useIsVisible();

  const createCourseModalIsOpenRef = useRef(false);

  const createdCourse = useRef<CourseT>();

  const coursesStatus: Course['status'][] = useMemo(
    () => (showPlanned ? ['ongoing', 'planned'] : ['ongoing']),
    [showPlanned],
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const [sections, setSections] = useState<Section[]>([]);

  /**
   * Create a list of sections (one per program)
   * Sections for empty programs are created for
   * AddNewGroup row if showAddRow is true.
   */
  const buildSections = useCallback(
    (courses: CourseT[], programs: ProgramT[]) => {
      // Populate programs directly to include empty programs
      const programsDict = programs.reduce<{[key: string]: ProgramWithCourses}>(
        (acc, program) => {
          acc[program._id] = {...program, courses: [] as CourseT[]};

          return acc;
        },
        {},
      );

      // Add each course to the courses field of it's program
      courses.forEach(course => {
        if (course.program_id && programsDict[course.program_id]) {
          programsDict[course.program_id] = programsDict[course.program_id] || {
            ...course.program,
            courses: [],
          };
          programsDict[course.program_id].courses.push(course);
        }
      });

      return Object.values(programsDict)
        .filter(program => {
          if (!showAddRow) {
            return !!program.courses.length;
          }

          // TODO: Remove offer_id check when course will get offer_id field
          if (program.offer_id) {
            return false;
          }

          return programIsGroup(program) || !showGroupOnly || !showIndividual;
        })
        .map(program => ({
          program,
          courses:
            programIsGroup(program) || showIndividual ? program.courses : [],
        }))
        .filter(program => program.courses.length > 0 || showAddRow);
    },
    [showAddRow, showGroupOnly, showIndividual],
  );

  const fetchCourses = useCallback(async () => {
    try {
      const [
        {_items: coursesItems, _expanded: coursesExp},
        {_items: programsItems, _expanded: programsExp},
      ]: [
        {_items: CourseT[]; _expanded: {[key: string]: any}},
        {_items: ProgramT[]; _expanded: {[key: string]: any}},
      ] = await apiRequest({
        batch: [
          {
            method: 'coach.courses.list',
            params: {
              sort: [['created', -1]],
              query: (
                [
                  ['status', 'in', coursesStatus],
                  maybeClause('_id', 'in', courseIds?.include),
                  maybeClause('_id', '!in', courseIds?.exclude),
                  maybeClause('program_id', 'in', programIds?.include),
                  maybeClause('program_id', '!in', programIds?.exclude),
                  showGroupOnly ? ['client_id', '==', null] : null,
                ] as ApiRpcQuery[]
              ).filter(Boolean),
              limit: LIMIT,
              expand: courseExpand,
            },
          },
          {
            method: 'coach.programs.list',
            params: {
              sort: [['created', -1]],
              query: (
                [
                  ['is_onboarding', '!=', true],
                  ['status', '!=', 'archived'],
                  maybeClause('_id', 'in', programIds?.include),
                  maybeClause('_id', '!in', programIds?.exclude),
                ] as ApiRpcQuery[]
              ).filter(Boolean),
              limit: LIMIT,
              expand: programExpand,
            },
          },
        ],
      });

      const courses = coursesItems.map(course =>
        expandObj<Course, CourseExpanded>(course, {
          expand: courseExpand,
          expanded: coursesExp,
        }),
      );

      const programs = programsItems.map(program =>
        expandObj<Program, ProgramExpanded>(program, {
          expand: programExpand,
          expanded: programsExp,
        }),
      );

      const newSections = buildSections(courses, programs);

      setSections(newSections);

      // Open all programs
      setOpenedPrograms(newSections.map(({program}) => program));

      const selectedCourseIds = initialSelection?.map(course => course._id);

      // Select courses based on IDs
      setSelection(
        courses.filter(course => selectedCourseIds?.includes(course._id)),
      );

      setIsLoaded(true);
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  }, [
    buildSections,
    courseIds?.exclude,
    courseIds?.include,
    coursesStatus,
    initialSelection,
    programIds?.exclude,
    programIds?.include,
    setOpenedPrograms,
    setSelection,
    showGroupOnly,
  ]);

  useEffect(() => {
    fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Course creation block
  useEffect(() => {
    createCourseModalIsOpenRef.current = createCourseModalIsOpen;
  }, [createCourseModalIsOpen]);

  const onCourseCreated = useCallback(
    (course: CourseT) => {
      createdCourse.current = course;

      hideCreateCourseModal();
    },
    [hideCreateCourseModal],
  );

  const createCourse = useCallback(
    () =>
      new Promise<CourseT | void>(resolve => {
        showCreateCourseModal();

        setTimeout(() => {
          waitFor(
            () => createCourseModalIsOpenRef.current === false,
            () => {
              resolve(createdCourse.current);
            },
          );
        }, 300);
      }),
    [showCreateCourseModal],
  );
  // End of course creation block

  // Pass selected/created course to caller
  const onConfirmButtonClick = useCallback(async () => {
    if (onSelect) {
      var courses: CourseT[] = selection;

      if (selection.length === 1 && selection[0]._id.startsWith('program')) {
        const newCourse = await createCourse();

        courses = newCourse ? [newCourse] : [];
      }

      if (courses.length === 0) {
        return;
      }

      if (confirmBeforeSelect && !(await confirm())) {
        return;
      }

      await onSelect(courses);
    }
  }, [confirmBeforeSelect, createCourse, onSelect, selection]);

  // 'Add new course' click handler:
  // put a fake course with ID equal to program ID to the selection
  const onAddToCourseClick = useCallback(
    (program: CourseT['program']) => {
      selectCourse({
        _id: program._id,
        program,
      } as unknown as CourseT);
    },
    [selectCourse],
  );

  // Scroll to pre-selected item if provided
  useLayoutEffect(() => {
    if ((initialSelection || []).length > 0 && selection.length > 0) {
      const elements = window.document.getElementsByClassName(
        'Selectable-selected',
      );

      if (elements.length > 0) {
        elements[0].scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }, [initialSelection, selection.length]);

  const ProgramArrow: React.FC<{checked: boolean}> = useCallback(
    ({checked}) => (
      <ArrowIcon
        className={classNames(styles.arrow, {[styles.pointDown]: checked})}
      />
    ),
    [],
  );

  const createCourseHeader = useMemo(() => {
    if (selection.length === 0) {
      return '';
    }

    const program = selection[0].program;

    if (programIsGroup(program)) {
      return t([I18N_SCOPE, 'create_course_label.group']);
    }

    return t([I18N_SCOPE, 'create_course_label.individual']);
  }, [selection]);

  return (
    <div className={`SelectCourse ${styles.Component}`}>
      {!isLoaded ? (
        <Loader />
      ) : !sections.length ? (
        <div className={styles.noResultsContainer}>
          <NoResultsHeader
            icon={ChatIcon}
            text={t([I18N_SCOPE, 'no_results_label'])}
          />
        </div>
      ) : (
        <>
          <div className={styles.scrollContainer}>
            <Scrollbar {...scrollProps} native={!useScrollbar}>
              <ul>
                {sections.map((section, index) => {
                  const {program, courses} = section;

                  const programIsOpened = openedPrograms.includes(program);

                  return (
                    <li key={program._id}>
                      {index === 0 ? null : (
                        <div
                          key={`sep-${index}`}
                          className={styles.separator}
                        />
                      )}
                      <Collapsible
                        key={program._id}
                        isOpened={programIsOpened}
                        onClick={toggleProgram}
                        data={program}
                        header={
                          <Selectable
                            indicator={ProgramArrow}
                            align="right"
                            isSelected={programIsOpened}
                          >
                            <ProgramsListItem program={program} />
                          </Selectable>
                        }
                      >
                        {courses.map(course => (
                          <Selectable
                            key={course._id}
                            className={styles.courseItem}
                            isSelected={selection.includes(course)}
                            onClick={selectCourse}
                            data={course}
                          >
                            <CoursesListItem course={course} />
                          </Selectable>
                        ))}
                        {showAddRow && program._id ? (
                          <Selectable
                            className={styles.courseItem}
                            isSelected={
                              !!selection.find(
                                _item => _item._id === program._id,
                              )
                            }
                            onClick={onAddToCourseClick}
                            data={program}
                          >
                            <CreateCourseRow program={program} />
                          </Selectable>
                        ) : undefined}
                      </Collapsible>
                    </li>
                  );
                })}
              </ul>
            </Scrollbar>
          </div>
          <Button
            type="button"
            disabled={!selection.length}
            onClick={onConfirmButtonClick}
          >
            {t([I18N_SCOPE, 'confirm_button'])}
          </Button>
        </>
      )}
      <ModalAnimateWin
        showModal={createCourseModalIsOpen}
        closeModalHandler={hideCreateCourseModal}
        className="greyHeaderContainer littleContainer w610 List"
        isBody
        classNameBody={`whiteBody maxContent noPadding ${styles.inviteContainer}`}
        // TODO: Use title from create course i18n_scope
        header={createCourseHeader}
        classNameHeader="greyHeader w610"
        classNameCloseBut="greyHeaderBut"
      >
        <CreateCourse
          // @ts-ignore
          program={selection.length > 0 ? selection[0].program : null}
          onCreate={onCourseCreated}
        />
      </ModalAnimateWin>
    </div>
  );
};

export default memo(SelectCourse);
