import type {FC, ReactNode} from 'react';
import React, {useReducer} from 'react';

import type {ProgramExpanded as IProgramExpanded} from '@src/models/app';

import SeeProgramContext from './SeeProgramContext';
import SeeProgramReducer from './SeeProgramReducer';
import {SET_PROGRAM} from './types';

interface Props {
  children: ReactNode[] | ReactNode;
}

export interface IState {
  program: IProgramExpanded | null;
}

interface ISetUserAction {
  type: 'SET_PROGRAM';
  payload: IProgramExpanded | null;
}

export type TActionTypes = ISetUserAction;

export interface IContextState extends IState {
  setProgram: (program: IProgramExpanded | null) => void;
}

const SeeProgramState: FC<Props> = ({children}) => {
  const initialState: IState = {
    program: null,
  };

  const [state, dispatch] = useReducer(SeeProgramReducer, initialState);

  const setProgram = (program: IProgramExpanded | null) =>
    dispatch({type: SET_PROGRAM, payload: program});

  return (
    <SeeProgramContext.Provider
      value={{
        program: state.program,
        setProgram,
      }}
    >
      {children}
    </SeeProgramContext.Provider>
  );
};

export default SeeProgramState;
