import {useContext, useRef} from 'react';

import dayjs from 'dayjs';
import {action, computed, observable, runInAction} from 'mobx';
import type {Node} from 'slate';

import type {
  ApiRpcCursor,
  ApiRpcQuery,
  CollectionStore,
  Expand,
} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  datetimeObjToISOString,
  expandObj,
} from '@yourcoach/shared/api';
import type {Channel as IChannel} from '@yourcoach/shared/api/channel';
import type {
  ExpandedPost,
  PostAttachment as PostAttachmentT,
} from '@yourcoach/shared/api/channel/post';
import {
  isMaterialAttachment,
  postExpand,
} from '@yourcoach/shared/api/channel/post';
import type {Conference} from '@yourcoach/shared/api/conference';
import {courseIsEnded} from '@yourcoach/shared/api/course';
import type {Event as IEvent} from '@yourcoach/shared/api/event';
import type {Material} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Task} from '@yourcoach/shared/api/task';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {Expanded as ChannelExpanded} from '@src/models/channels';
import {expand as channelExpand} from '@src/models/channels';

import ChatsContext from '../../context/ChatsContext';
import type {IChatsLocalStore} from '../../context/useChatsLocalStore';
import {SUPPORTED_EVENTS} from '../../context/useChatsLocalStore';

export type Post = ExpandedPost;

export type ChannelT = IChannel & ChannelExpanded;

export type PostWithGroup = Post & {
  userGroup: boolean;
  firstItemInUserGroup: boolean;
  lastItemInUserGroup: boolean;
  inProgress?: boolean;
  highlighted?: boolean;
  uploadingAttachments?: boolean;
  error?: any;
};

export type Section = {
  title: string;
  date: string;
  data: PostWithGroup[];
};

export interface IFetchPostArg {
  direction?: 'prev' | 'next';
  limit?: number;
  silent?: boolean;
  refresh?: boolean;
  postId?: string;
}

export type TMainTabSelect = 'main' | 'pinned' | 'members' | 'library';

export interface IMainChatsLocalStore {
  _isRefreshing: boolean;
  _isPaginating: boolean;
  mainTabSelect: TMainTabSelect;
  setMainTabSelect(mainTabSelect: TMainTabSelect): void;
  channel: ChannelT | null;
  setChannel(channel: ChannelT | null): void;
  isLoaded: boolean;
  setIsLoaded(isLoaded: boolean): void;
  isFetching: boolean;
  setIsFetching(isFetching: boolean): void;
  posts: PostWithGroup[];
  nextPostCursor: ApiRpcCursor | null;
  prevPostCursor: ApiRpcCursor | null;
  events: Event[];
  nextEventCursor: ApiRpcCursor | null;
  prevEventCursor: ApiRpcCursor | null;
  text: string;
  setText(text: string): void;
  attachments: PostAttachmentT[];
  editablePost: Post | null;
  typingTag: string;
  setTypingTag(typingTag: string): void;
  shouldRenderStub: boolean;
  userIsAdmin: boolean;
  isIndividual: boolean;
  userIsOwner: boolean | null;
  userIsFrozen: boolean;
  userCanWrite: boolean;
  isOneToOne: boolean;
  hasCourse: boolean;
  isStarted: boolean;
  isEnded: boolean;
  isArchived: boolean;
  showMoreBtn: boolean;
  MaxPostId: string;
  MinPostId: string;
  seeCurrentPostId: string;
  setSeeCurrentPostId(seeCurrentPostId: string): void;
  fileAttachment:
    | IFile
    | (Material & {
        files: IFile[];
      })
    | Conference
    | Task
    | undefined;
  eventsToRender: Event[];
  sections: Section[];
  sectionReverse: Section[];
  textNodeFormate: Node[];
  setTextNodeFormate(textNodeFormate: Node[]): void;
  pinnedPostsStore: CollectionStore<Post>;
  clear: () => void;
  _renderedEvents: {[id: string]: boolean};
  _fetchPosts(obj?: IFetchPostArg): Promise<void>;
  _onRefresh(silent?: boolean): Promise<void>;
  _clearPosts(): void;
  _onTextInputTextChange(text: string): void;
  _onTextInputFocus(): void;
  _onTypingTag(tag: string): void;
  _onImageChange(image: any): void;
  _removeFileAttachment(attachment: PostAttachmentT): void;
  _removeFileAttachments(): void;
  _finishEditingPost(): void;
  _onEditPostBtnPress(post: Post): void;
  _onEndReached(): Promise<void>;
  _fetchChannel(): Promise<void>;
  _fetchNewPosts(): Promise<void>;
  _showRecentPosts(): Promise<void>;
  isOpenModalGetUserFile: boolean;
  setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean): void;
  _prevText: string;
  _prevAttachments: PostAttachmentT[];
  _complainPost(post: Post, reason: string): Promise<void>;
  _accessProblemsShouldBeResolved: boolean;
  setAccessProblemsShouldBeResolved(
    _accessProblemsShouldBeResolved: boolean,
  ): void;
  selectedPinnedPostId: string | null;
  setSelectedPinnedPostId(selectedPinnedPostId: string | null): void;
  isInsertText: boolean;
  setIsInsertText(isInsertText: boolean): void;
  isFirstPostVisible: boolean;
  setIsFirstPostVisible(value: boolean): void;
}

export const EVENT_EXPAND: Expand = {
  event: [
    ['actor_id', {name: 'User not found'}, userExpand],
    ['user_id', {name: 'User not found'}, userExpand],
    'context_ids',
  ],
};

export const LIMIT = 20;

export const CURSOR_DIRECTION = -1;

export type EventContext = Task | Conference | Material | null;

export type Event = IEvent & {
  actor: (User | (Partial<User> & Required<Pick<User, 'name'>>)) & UserExpanded;
  user: (User | (Partial<User> & Required<Pick<User, 'name'>>)) & UserExpanded;
  contexts: EventContext[];
};

export const isFileAttachment = (item: PostAttachmentT) =>
  !!(item as IFile).categories || isMaterialAttachment(item);

const useMainChatsLocalStore = () => {
  const {
    stores: {
      currentUserStore,
      membershipStore,
      postStore,
      eventStore,
      channelStore,
    },
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  const store: IMainChatsLocalStore = useRef(
    observable<IMainChatsLocalStore>(
      {
        isInsertText: false,
        setIsInsertText(isInsertText: boolean) {
          this.isInsertText = isInsertText;
        },
        isFirstPostVisible: false,
        setIsFirstPostVisible(value: boolean) {
          this.isFirstPostVisible = value;
        },
        selectedPinnedPostId: null,
        setSelectedPinnedPostId(selectedPinnedPostId: string | null) {
          this.selectedPinnedPostId = selectedPinnedPostId;
        },
        mainTabSelect: 'main',
        setMainTabSelect(mainTabSelect: TMainTabSelect) {
          this.mainTabSelect = mainTabSelect;
        },
        _accessProblemsShouldBeResolved: false,
        setAccessProblemsShouldBeResolved(
          _accessProblemsShouldBeResolved: boolean,
        ) {
          this._accessProblemsShouldBeResolved =
            _accessProblemsShouldBeResolved;
        },
        seeCurrentPostId: '',
        setSeeCurrentPostId(seeCurrentPostId: string) {
          this.seeCurrentPostId = seeCurrentPostId;
        },
        _prevText: '',
        _prevAttachments: [],
        _isRefreshing: false,
        _isPaginating: false,
        _renderedEvents: {},
        clear() {
          if (this.pinnedPostsStore) {
            this.pinnedPostsStore.clear();
          }
        },
        pinnedPostsStore: createCollectionStore({
          method: 'client.channels.posts.list',
          params: {
            cursor: [CURSOR_DIRECTION, 0, null],
            expand: postExpand,
            query: [['is_pinned', '==', true]],
          },
        }) as CollectionStore<Post>,
        isOpenModalGetUserFile: false,
        setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean) {
          this.isOpenModalGetUserFile = isOpenModalGetUserFile;
        },
        channel: null,
        setChannel(channel: ChannelT | null) {
          this.setText('');
          this.setMainTabSelect('main');

          this.channel = channel;
        },
        isLoaded: false,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        isFetching: false,
        setIsFetching(isFetching: boolean) {
          this.isFetching = isFetching;
        },
        posts: [],
        nextPostCursor: null,
        prevPostCursor: null,
        events: [],
        nextEventCursor: null,
        prevEventCursor: null,
        text: '',
        textNodeFormate: [],
        setTextNodeFormate(textNodeFormate: Node[]) {
          this.textNodeFormate = textNodeFormate;
        },
        setText(text: string) {
          this.text = text;
        },
        attachments: [],
        editablePost: null,
        typingTag: '',
        setTypingTag(typingTag: string) {
          this.typingTag = typingTag;
        },
        get shouldRenderStub() {
          return this.isLoaded && !this.sections.length;
        },
        get userIsAdmin() {
          return user && this.hasCourse
            ? this.channel!.resource!.coach_ids.includes(user._id)
            : false;
        },
        get isIndividual() {
          return !!(
            this.hasCourse &&
            this.channel!.resource!.edition &&
            this.channel!.resource!.edition.group_size === 1
          );
        },
        get userIsOwner() {
          return this.channel && user && this.channel.user_id === user._id;
        },
        get userIsFrozen() {
          if (this.hasCourse) {
            if (this.userIsAdmin) {
              return false;
            }

            const membership = membershipStore.membershipLookup.get(
              this.channel!.resource_id!,
            );

            return membership ? membership.status === 'frozen' : false;
          } else {
            return false;
          }
        },
        get userCanWrite() {
          if (chatsLocalStore!.withUser) {
            return true;
          }

          return !this.userIsFrozen && !this.isArchived;
        },
        get isOneToOne() {
          if (this.channel && this.channel.user_ids) {
            return this.hasCourse
              ? this.channel!.resource!.counters.memberships.accepted === 1
              : this.channel.user_ids.length === 2;
          }

          return false;
        },
        get hasCourse() {
          return Boolean(
            this.channel &&
              this.channel.type === 'course' &&
              this.channel.resource_id &&
              this.channel.resource,
          );
        },
        get isStarted() {
          return this.hasCourse && this.channel!.resource!.status === 'ongoing';
        },
        get isEnded() {
          return this.hasCourse && courseIsEnded(this.channel!.resource!);
        },
        get isArchived() {
          return (
            this.hasCourse && this.channel!.resource!.status === 'archived'
          );
        },
        get showMoreBtn() {
          // TODO: Add ability to leave/delete channel
          return false && !this.hasCourse;
        },
        get fileAttachment() {
          return (this.attachments as unknown[] as PostAttachmentT[]).find(
            isFileAttachment,
          );
        },
        get eventsToRender() {
          let events: Event[] = [];

          if (!this.hasCourse) {
            return events;
          }

          if (this.posts.length < 2) {
            events = this.events;

            return events;
          }

          const firstPost = this.posts[0];
          const lastPost = this.posts[this.posts.length - 1];

          const eventsLength: number = this.events.length;

          for (let eventI: number = 0; eventI < eventsLength; eventI += 1) {
            const event = this.events[eventI];

            if (this._renderedEvents[event._id]) {
              events.push(event);

              continue;
            }

            const eventCreated = dayjs(datetimeObjToISOString(event.created));

            let shouldRenderEvent = false;

            if (
              this.prevPostCursor === null &&
              eventCreated >= dayjs(datetimeObjToISOString(firstPost.created))
            ) {
              shouldRenderEvent = true;
            }

            if (
              this.nextPostCursor === null &&
              eventCreated <= dayjs(datetimeObjToISOString(lastPost.created))
            ) {
              shouldRenderEvent = true;
            }

            if (
              eventCreated <=
                dayjs(datetimeObjToISOString(firstPost.created)) &&
              eventCreated >= dayjs(datetimeObjToISOString(lastPost.created))
            ) {
              shouldRenderEvent = true;
            }

            if (shouldRenderEvent) {
              this._renderedEvents[event._id] = true;

              events.push(event);
            }
          }

          return events;
        },
        get sectionReverse() {
          const cloneSections = [...(this.sections as Section[])];

          cloneSections.forEach(section => {
            section.data.reverse();
          });

          return cloneSections.reverse();
        },
        get sections() {
          const posts = this.posts
            .slice()
            .concat(
              (
                this.eventsToRender.slice().map(event => {
                  const attachmentIds: string[] = [];
                  const attachments: PostAttachmentT[] = [];

                  switch (event.type) {
                    case 'conference_stopped':
                    case 'conference_started': {
                      const conference = event.contexts.find(
                        context =>
                          context && context._id.split(':')[0] === 'conference',
                      );

                      if (conference) {
                        attachmentIds.push(conference._id);
                        attachments.push(conference as Conference);
                      } else {
                        return null;
                      }
                      break;
                    }
                    case 'task_done': {
                      const task = event.contexts.find(
                        context =>
                          context && context._id.split(':')[0] === 'task',
                      );

                      if (task) {
                        attachmentIds.push(task._id);
                        attachments.push(task as Task);
                      } else {
                        return null;
                      }
                      break;
                    }
                    default:
                      break;
                  }

                  return {
                    _id: event._id,
                    channel_id: this.channel!._id,
                    is_pinned: false,
                    body: event.type,
                    attachment_ids: attachmentIds,
                    expandedAttachments: attachments,
                    user_id: event.actor_id || event.user_id,
                    user: event.actor || event.user,
                    created: event.created,
                    updated: event.updated,
                  };
                }) as PostWithGroup[]
              ).filter(Boolean),
            )
            .sort(
              (a, b) =>
                dayjs(datetimeObjToISOString(b.created)).unix() -
                dayjs(datetimeObjToISOString(a.created)).unix(),
            );

          const sections: Section[] = [];
          const groups: {[key: string]: PostWithGroup[]} = {};

          let prevGroupId = '';

          // TODO: this need for preventing post duplicates
          // need to investigate why duplicates happen,
          // possibly there are some race conditions
          const postIdMap: {[postId: string]: boolean} = {};

          const groupByDays = (post: Post, index: number, itself: Post[]) => {
            if (postIdMap[post._id]) {
              return;
            }

            postIdMap[post._id] = true;

            const groupId = formatDate(datetimeObjToISOString(post.created), {
              defaultFormat: true,
            });

            groups[groupId] = groups[groupId] || [];

            const lastItemInGroup = groups[groupId][groups[groupId].length - 1];

            let userGroup = false;
            let firstItemInUserGroup = true;
            let lastItemInUserGroup = false;

            if (lastItemInGroup && lastItemInGroup.user_id === post.user_id) {
              userGroup = true;
              firstItemInUserGroup = false;
            } else if (prevGroupId === groupId) {
              if (lastItemInGroup) {
                lastItemInGroup.lastItemInUserGroup = true;
              }
            } else if (prevGroupId) {
              groups[prevGroupId][
                groups[prevGroupId].length - 1
              ].lastItemInUserGroup = true;
            }

            if (itself.length - 1 === index) {
              lastItemInUserGroup = true;
            }

            groups[groupId].push({
              userGroup,
              firstItemInUserGroup,
              lastItemInUserGroup,
              ...post,
            });
            prevGroupId = groupId;
          };

          posts.forEach(groupByDays);

          Object.keys(groups)
            .sort((a, b) => dayjs(b).unix() - dayjs(a).unix())
            .forEach(key => {
              sections.push({
                title: formatDate(key, {withYTT: true}),
                date: key,
                data: groups[key],
              });
            });

          return sections;
        },

        get MaxPostId() {
          const numLast =
            this.sections.length > 0 ? this.sections[0].data.length - 1 : 0;

          return this.sections.length > 0
            ? this.sections[0].data[numLast]._id
            : 'no';
        },
        get MinPostId() {
          return this.sections.length > 0
            ? this.sections[this.sections.length - 1].data[0]._id
            : 'no';
        },
        async _fetchNewPosts() {
          try {
            if (!this.prevPostCursor && !this.prevEventCursor) {
              return;
            }

            await this._fetchPosts({
              direction: 'prev',
            });
          } catch (error) {
            getCustomConfirmAlert({
              title: labelErrorOccurred(),
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });

            setError(error);
          }
        },
        async _fetchPosts({
          direction,
          limit,
          silent,
          refresh,
          postId,
        }: IFetchPostArg = {}) {
          const channelId = (this.channel as ChannelT)._id || null;

          try {
            if (!silent) {
              this.setIsFetching(true);
            }

            const posts: PostWithGroup[] = [];
            const events: Event[] = [];

            let postCursor: ApiRpcCursor | null = [
              CURSOR_DIRECTION,
              0,
              postId || null,
            ];

            if (direction === 'next') {
              postCursor = this.nextPostCursor;
            } else if (direction === 'prev') {
              postCursor = this.prevPostCursor;
            }

            let postsResponse;
            let eventsResponse;

            if (postCursor) {
              postsResponse = await apiRequest({
                method: 'client.channels.posts.list',
                params: {
                  channel_id: channelId,
                  limit: refresh
                    ? Math.max(this.posts.length, LIMIT)
                    : limit || LIMIT,
                  cursor: postCursor,
                  expand: postExpand,
                },
              });
            }

            if (this.hasCourse) {
              let eventCursor: ApiRpcCursor | null = [
                CURSOR_DIRECTION,
                0,
                null,
              ];

              if (postId) {
                const passedPost = postsResponse._items.find(
                  (post: Post) => post._id === postId,
                );

                if (passedPost) {
                  eventCursor = [CURSOR_DIRECTION, 0, passedPost.created];
                }
              }

              if (direction === 'next') {
                eventCursor = this.nextEventCursor;
              } else if (direction === 'prev') {
                eventCursor = this.prevEventCursor;
              }

              if (eventCursor) {
                eventsResponse = await apiRequest({
                  method: 'user.events.list',
                  params: {
                    limit: refresh
                      ? Math.max(this.posts.length, LIMIT)
                      : limit || LIMIT,
                    cursor: eventCursor,
                    query: (
                      [
                        ['type', 'in', SUPPORTED_EVENTS],
                        ['context_ids', 'in', [this.channel!.resource_id]],
                      ] as ApiRpcQuery[]
                    ).filter(Boolean),
                    expand: EVENT_EXPAND,
                  },
                });
              }
            }

            if (postsResponse) {
              const postsLength: number = postsResponse._items.length;

              for (let postI: number = 0; postI < postsLength; postI += 1) {
                posts[postI] = expandObj(postsResponse._items[postI], {
                  expand: postExpand,
                  expanded: postsResponse._expanded,
                });
              }
            }

            if (eventsResponse) {
              const eventsLength: number = eventsResponse._items.length;

              for (let eventI: number = 0; eventI < eventsLength; eventI += 1) {
                events[eventI] = expandObj(eventsResponse._items[eventI], {
                  expand: EVENT_EXPAND,
                  expanded: eventsResponse._expanded,
                });
              }
            }

            runInAction(() => {
              this.isLoaded = true;
              this.isFetching = false;

              if (postsResponse) {
                if (direction === 'next') {
                  this.nextPostCursor = postsResponse._next;

                  this.posts.push(...posts);
                } else if (direction === 'prev') {
                  this.prevPostCursor = postsResponse._prev;
                  this.posts.unshift(...posts);
                } else {
                  this.nextPostCursor = postsResponse._next;
                  this.prevPostCursor = postsResponse._prev;

                  this.posts = [];
                  this.posts.push(...posts);
                }
              }

              if (eventsResponse) {
                if (direction === 'next') {
                  this.nextEventCursor = eventsResponse._next;

                  this.events.push(...events);
                } else if (direction === 'prev') {
                  this.prevEventCursor = eventsResponse._prev;

                  this.events.unshift(...events);
                } else {
                  this.nextEventCursor = eventsResponse._next;
                  this.prevEventCursor = eventsResponse._prev;

                  this.events = [];
                  this.events.push(...events);
                }
              }
            });
          } catch (error) {
            this.isFetching = false;
          }
        },
        async _onRefresh(silent = false) {
          const channelId = (this.channel as ChannelT)._id || null;

          if (this._isRefreshing) {
            return;
          }

          this._isRefreshing = true;

          try {
            this._fetchChannel();

            this.pinnedPostsStore.fetch({
              channel_id: channelId,
            });

            await this._fetchPosts({refresh: true, silent});

            eventStore.markReadAll({
              query: [
                ['type', '==', 'post_created'],
                ['context_ids', 'in', [this.channel!._id]],
              ],
              limit: 500,
            });
          } catch (error) {
            setError(error);

            this._isRefreshing = false;
          }

          this._isRefreshing = false;
        },
        async _onEndReached() {
          if (this._isPaginating) {
            return;
          }

          this._isPaginating = true;

          if (this.nextPostCursor || this.nextEventCursor) {
            await this._fetchPosts({
              direction: 'next',
            });
          }

          this._isPaginating = false;
        },
        async _fetchChannel() {
          const channelId = (this.channel as ChannelT)._id || null;

          try {
            const channel = (await channelStore.fetch({
              _id: channelId as string,
              expand: channelExpand,
            })) as ChannelT;

            runInAction(() => {
              this.channel = channel;
            });
          } catch (error) {
            if (error.canceled) {
              return;
            }

            getCustomConfirmAlert({
              title: t('shared.message.error_fix'),
              message: error.message,
              buttons: [
                {
                  label: t('shared.button.ok'),
                  onClick: () => {},
                },
              ],
            });

            setError(error);
          }
        },
        _clearPosts() {
          this.isFetching = false;
          this.isLoaded = false;

          this.posts = [];
          this.nextPostCursor = null;
          this.prevPostCursor = null;

          this.events = [];
          this._renderedEvents = {};
          this.nextEventCursor = null;
          this.prevEventCursor = null;
        },
        _onTextInputTextChange(text: string) {
          if (postStore.creating.inProgress) {
            return;
          }

          this.text = text;
        },
        _onTextInputFocus() {},
        _onTypingTag(tag: string) {
          this.typingTag = tag;
        },

        _onImageChange(image) {
          if (!Array.isArray(image) && image) {
            this._removeFileAttachments();

            this.attachments.push({
              categories: ['image'],
              src: {
                original: {
                  filename: image.filename || 'Image',
                  url: image.path,
                  size: image.size,
                },
              },
            } as unknown as IFile);
          }
        },
        async _complainPost(post: Post, reason: string) {
          try {
            await postStore.complain(post, {
              reason,
              message: '',
            });
            getCustomConfirmAlert({
              title: t('msg.abuse_report_sent'),
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });
          } catch (error) {
            getCustomConfirmAlert({
              title: labelErrorOccurred(),
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });

            setError(error);
          }
        },
        _removeFileAttachment(attachment) {
          const newArr = this.attachments.filter(
            attach => attach._id !== attachment._id,
          );

          this.attachments = newArr;
        },
        _removeFileAttachments() {
          this.attachments = [];
        },
        _finishEditingPost() {
          this.text = this._prevText;
          this.attachments = this._prevAttachments;
          this._prevText = '';
          this._prevAttachments = [];
          this.editablePost = null;
        },
        _onEditPostBtnPress(post: Post) {
          this._prevText = this.text;
          this._prevAttachments = this.attachments.slice();
          this.editablePost = post;
          this.text = this.editablePost.body;
          this.attachments = this.editablePost.attachments.slice();
        },
        async _showRecentPosts() {
          this._clearPosts();

          await this._fetchPosts({
            silent: true,
          });
        },
      },
      {
        selectedPinnedPostId: observable,
        setSelectedPinnedPostId: action,
        seeCurrentPostId: observable,
        _prevText: observable,
        _prevAttachments: observable,
        setSeeCurrentPostId: action,
        MaxPostId: computed,
        MinPostId: computed,
        _isPaginating: observable,
        _isRefreshing: observable,
        _renderedEvents: observable,
        pinnedPostsStore: observable,
        channel: observable,
        setChannel: action,
        isLoaded: observable,
        setIsLoaded: action,
        isFetching: observable,
        setIsFetching: action,
        posts: observable,
        nextPostCursor: observable,
        prevPostCursor: observable,
        events: observable,
        nextEventCursor: observable,
        prevEventCursor: observable,
        text: observable,
        setText: action,
        attachments: observable,
        editablePost: observable.ref,
        typingTag: observable,
        setTypingTag: action,
        shouldRenderStub: computed,
        userIsAdmin: computed,
        isIndividual: computed,
        userIsOwner: computed,
        userIsFrozen: computed,
        userCanWrite: computed,
        isOneToOne: computed,
        hasCourse: computed,
        isStarted: computed,
        isEnded: computed,
        isArchived: computed,
        showMoreBtn: computed,
        fileAttachment: computed,
        eventsToRender: computed,
        sections: computed,
        sectionReverse: computed,
        _fetchPosts: action,
        _onRefresh: action,
        _clearPosts: action,
        _onTextInputTextChange: action,
        _onTextInputFocus: action,
        textNodeFormate: observable,
        setTextNodeFormate: action,
        _onTypingTag: action,
        _onImageChange: action,
        _removeFileAttachment: action,
        _removeFileAttachments: action,
        _onEditPostBtnPress: action,
        _finishEditingPost: action,
        _onEndReached: action,
        _fetchChannel: action,
        _fetchNewPosts: action,
        _showRecentPosts: action,
        isOpenModalGetUserFile: observable,
        setIsOpenModalGetUserFile: action,
        _complainPost: action,
        _accessProblemsShouldBeResolved: observable,
        setAccessProblemsShouldBeResolved: action,
        mainTabSelect: observable,
        setMainTabSelect: action,
        isInsertText: observable,
        setIsInsertText: action,
        clear: action,
      },
    ),
  ).current;

  return store;
};

export default useMainChatsLocalStore;
