import type {FC} from 'react';
import React, {memo} from 'react';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

interface Props {
  question: QuestionnaireQuestion;
  answers?: string[];
  classAnswer?: string;
}

const MultipleChoice: FC<Props> = ({question, answers, classAnswer = ''}) => {
  return (
    <div className="MultipleChoiceTemplateContainer">
      <div className="name">{question.question}</div>
      {answers ? (
        <ul className={`answer ${classAnswer}`}>
          {answers.map((answer, index) => (
            <li key={index}>
              <span>{answer}</span>
            </li>
          ))}
        </ul>
      ) : (
        <ul>
          {question.options?.map((answerLabel, index) => (
            <li key={index}>
              <span>{answerLabel}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(MultipleChoice);
