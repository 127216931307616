import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {autorun} from 'mobx';

import localAppStore from '@src/context/appStore';
import {
  MainContainerPublicPage,
  MainContainerWithMenuColor,
} from '@src/layouts';

import ProgramPage from '../Program';
import ProgramPublicPage from '../ProgramPublic';

interface Props {}

const ProgramMainPage: FC<Props> = () => {
  const [isPublicPage, setIsPublicPage] = useState<boolean | null>(null);

  useEffect(() => {
    const dispose = autorun(() => {
      const isAuth = localAppStore?.isAuth || false;

      setIsPublicPage(!isAuth);
    });

    return dispose;
  }, []);

  return (
    <div className="ProgramMainPage">
      {isPublicPage !== null ? (
        <>
          {isPublicPage ? (
            <MainContainerPublicPage isThreeColumn>
              <ProgramPublicPage />
            </MainContainerPublicPage>
          ) : (
            <MainContainerWithMenuColor isWithFrom isWithNewCrumbs isWithCrumbs>
              <ProgramPage />
            </MainContainerWithMenuColor>
          )}
        </>
      ) : null}
    </div>
  );
};

export default memo(ProgramMainPage);
