import React from 'react';

import type {ModalRef, Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as SelectCourseProps} from '.';
import styles from './SelectCourse.module.css';
import SelectCourse, {I18N_SCOPE} from '.';

type Props = Partial<ModalProps> & SelectCourseProps;

const SelectCourseModal = React.forwardRef<ModalRef, Props>(
  (
    {
      programIds,
      courseIds,
      showPlanned,
      showGroupOnly,
      showIndividual,
      showAddRow,
      multiselection,
      confirmBeforeSelect,
      initialSelection,
      onSelect,
      children,
      ...modalProps
    },
    ref,
  ) => (
    <Modal
      title={t([I18N_SCOPE, 'title'])}
      bodyClassName={styles.modalBody}
      ref={ref}
      {...modalProps}
    >
      <SelectCourse
        programIds={programIds}
        courseIds={courseIds}
        showPlanned={showPlanned}
        showGroupOnly={showGroupOnly}
        showIndividual={showIndividual}
        showAddRow={showAddRow}
        multiselection={multiselection}
        confirmBeforeSelect={confirmBeforeSelect}
        initialSelection={initialSelection}
        onSelect={onSelect}
        useScrollbar
      />
      {children}
    </Modal>
  ),
);

export default React.memo(SelectCourseModal);
