import React, {useCallback, useMemo} from 'react';

import i18n from '@yourcoach/shared/locales/en-US.json';

import SizeFitText from '@src/components/SizeFitText';
import {t} from '@src/i18n';

import CircleSVG from '../images/fact-circle.svg';
import LampSVG from '../images/lamp2.svg';

import styles from './styles.module.css';
import VirtualHomeStep from './VirtualHomeStep';

const I18N_SCOPE = 'Onboarding.fact';
const FACTS_LENGTH = Object.keys(i18n.onboarding_facts).length;

const FactStep = props => {
  const getRandomIntInclusive = useCallback((min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }, []);

  const fact = useMemo(() => {
    const randomIndex = getRandomIntInclusive(1, FACTS_LENGTH);

    return t([`shared.onboarding_facts.fact_${randomIndex}`]);
  }, [getRandomIntInclusive]);

  return (
    <VirtualHomeStep
      title={t([I18N_SCOPE, 'title'])}
      leftPaneContent={<LampSVG className={styles.centeredImage} />}
      {...props}
    >
      <div className={styles.factContent}>
        <CircleSVG />
        <SizeFitText
          className={styles.factText}
          value={fact}
          steps={{
            80: styles.mediumText,
            160: styles.smallText,
          }}
        />
      </div>
    </VirtualHomeStep>
  );
};

export default FactStep;
