import './style.css';

import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import {action, observable} from 'mobx';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getChannelInfo} from '@yourcoach/shared/api/channel';
import {isImageAttachment} from '@yourcoach/shared/api/channel/post';
import type {Conference} from '@yourcoach/shared/api/conference';
import type {IFile} from '@yourcoach/shared/api/media/file';

import OtherUserProfileImg from '../../../../components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '../../../../context/App';
import type {ChannelT, ICoach} from '../../context/useChatsLocalStore';
import {SUPPORTED_EVENTS} from '../../context/useChatsLocalStore';
import styles from '../../styles/styles.module.css';

import {getConferencesAPI, postTimezone} from './api';
import {ScheduleCallButton} from './ScheduleCallButton';
import {useBookSessions} from './useBookSession';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(AdvancedFormat);

interface ILocalStore {
  isFirstLoading: boolean;
  setIsFirstLoading(isFirstLoading: boolean): void;
  timerId: number;
  setTimerId(timerId: number): void;
}

interface Props {
  channel: ChannelT;
  showUpdatedDate?: boolean;
  showLastPost?: boolean;
  showCoaches?: boolean;
  badge?: number;
  dontHighlightOnPress?: boolean;
}

const ChatsListItem: FC<Props> = ({channel, showCoaches = false}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [conference_list, setConference_List] = useState<Conference[]>([]);
  const [scheduledSession, setScheduledSession] = useState<Conference | null>(
    null,
  );

  const {
    stores: {currentUserStore, membershipStore},
  } = useContext(AppContext);

  const localStore: ILocalStore = useRef(
    observable(
      {
        timerId: 0,
        setTimerId(timerId: number) {
          this.timerId = timerId;
        },
        isFirstLoading: true,
        setIsFirstLoading(isFirstLoading: boolean) {
          this.isFirstLoading = isFirstLoading;
        },
      },
      {
        isFirstLoading: observable,
        setIsFirstLoading: action,
        timerId: observable,
        setTimerId: action,
      },
    ),
  ).current;

  const hasCourse = !!(
    channel &&
    channel.type === 'course' &&
    channel.resource &&
    channel.resource._id
  );

  const getConferences = useCallback(async () => {
    getConferencesAPI(
      channel.resource?.start_date,
      channel.resource?.end_date,
    ).then(response => {
      setConference_List(response._items);
    });
  }, [channel.resource?.end_date, channel.resource?.start_date]);

  useEffect(() => {
    getConferences();
  }, [getConferences]);

  // @ts-ignore
  const {avatar} = getChannelInfo(channel);

  const {bookingJSX, setIsOpenSchedulingModal} = useBookSessions({
    onBookSession: getConferences,
  });

  useEffect(() => {
    return () => {
      clearTimeout(localStore.timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const lastConference = conference_list[conference_list.length - 1];

      if (!lastConference) {
        setIsButtonDisabled(false);
      } else if (
        dayjs().isBefore(
          dayjs(datetimeObjToISOString(lastConference.start_date)),
        )
      ) {
        setScheduledSession(lastConference);
        setIsButtonDisabled(true);
      } else if (
        dayjs().isAfter(
          dayjs(
            datetimeObjToISOString(
              conference_list[conference_list.length - 1].start_date,
            ),
          ),
        )
      ) {
        setScheduledSession(null);
        setIsButtonDisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [conference_list]);

  useEffect(() => {
    postTimezone(dayjs.tz.guess());
  }, []);

  let coaches: ICoach[] = [];
  let currentUserIsCoach: boolean = false;

  if (
    showCoaches &&
    channel &&
    channel.type === 'course' &&
    channel.resource_id &&
    channel.resource
  ) {
    let coachIds: string[] = hasCourse
      ? channel.resource.coach_ids.slice()
      : [];

    currentUserIsCoach =
      !!currentUserStore.user && coachIds.includes(currentUserStore.user._id);

    coachIds.unshift(channel.user_id);

    if (currentUserIsCoach && currentUserStore.user) {
      coachIds.unshift(currentUserStore.user._id);
    }

    coachIds = Array.from(new Set(coachIds));

    coaches = (
      coachIds.map(id =>
        channel.members.find(item => {
          return item._id === id;
        }),
      ) as ICoach[]
    ).filter(Boolean);
  }

  const isIndividual = useMemo(
    () =>
      channel &&
      channel.resource &&
      channel.resource.edition &&
      channel.resource.edition.group_size === 1,
    [channel],
  );

  let isFrozen = false;

  let text = 'No messages yet';

  if (hasCourse) {
    const membership = membershipStore.membershipLookup.get(
      channel.resource_id || '',
    );

    if (membership && membership.status === 'frozen') {
      isFrozen = true;

      // userName = '';
      text = isIndividual
        ? 'Your access to this program is frozen'
        : 'Your access to this group is frozen';
    }
  }

  if (!isFrozen && channel) {
    const hasLastPost = channel.last_post && channel.last_post._id;
    const hasLastEvent =
      hasCourse &&
      channel.resource!.last_event &&
      channel.resource!.last_event._id;

    if (hasLastPost || hasLastEvent) {
      if (
        hasLastPost &&
        (!hasLastEvent ||
          (hasLastEvent &&
            dayjs(datetimeObjToISOString(channel.last_post!.created)) >
              dayjs(
                datetimeObjToISOString(channel.resource!.last_event!.created),
              )))
      ) {
        text = channel.last_post!.body;

        if (!text) {
          if (channel.last_post!.attachments.filter(Boolean).length) {
            const [attachment] = channel.last_post!.attachments.filter(Boolean);
            const attachmentId =
              (attachment as typeof attachment & {_id?: string})._id ||
              attachment.attachment_id;
            const attachmentType = attachmentId.split(':')[0];

            if (attachmentType === 'file') {
              if (isImageAttachment(attachment as unknown as IFile)) {
                text = 'Image';
              } else if (
                (attachment as unknown as IFile).categories.includes('document')
              ) {
                text = 'Document';
              } else if (
                (attachment as unknown as IFile).categories.includes('website')
              ) {
                text = 'Url';
              }
            } else if (attachmentType === 'material') {
              text = 'Material';
            }
          }
        }
      } else if (
        hasLastEvent &&
        SUPPORTED_EVENTS.includes(channel.resource!.last_event!.type) &&
        (!hasLastPost ||
          (hasLastPost &&
            dayjs(
              datetimeObjToISOString(channel.resource!.last_event!.created),
            ) > dayjs(datetimeObjToISOString(channel.last_post!.created))))
      ) {
        text =
          channel.resource!.last_event!.type === 'conference_stopped'
            ? 'Live session ended'
            : channel.resource!.last_event!.type === 'task_done'
            ? `${
                (
                  channel.resource!.last_event!.actor ||
                  channel.resource!.last_event!.user || {name: 'User not found'}
                ).name
              } done task`
            : channel.resource!.last_event!.type === 'course_started'
            ? isIndividual
              ? 'Program started'
              : 'Group started'
            : channel.resource!.last_event!.type === 'membership_created'
            ? isIndividual
              ? `${
                  (
                    channel.resource!.last_event!.actor ||
                    channel.resource!.last_event!.user || {
                      name: 'User not found',
                    }
                  ).name
                } joined the program`
              : `${
                  (
                    channel.resource!.last_event!.actor ||
                    channel.resource!.last_event!.user || {
                      name: 'User not found',
                    }
                  ).name
                } joined the group`
            : '';
      }
    }
  }

  return (
    <Observer>
      {() => (
        <div className={`ChatsListItem ${styles.ChatsListItem}`}>
          <div className={styles.channelContainer}>
            <div className={styles.image_and_name_Container}>
              <div className={styles.channelAvatar}>
                {
                  <OtherUserProfileImg
                    avatar={coaches[0].avatar}
                    childrenContainerClassName={`${styles.allUsersContainer} ${
                      currentUserIsCoach ? '' : styles.coachUsersContainer
                    }`}
                    profileClassName={styles.usersAvatar}
                    title={coaches[0].name}
                    isBGGradient
                    isOverlay={!avatar}
                  />
                }
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.infoTopContainer}>
                  <div className={styles.infoTitleContainer}>
                    <div className={styles.title}>{coaches[0].name}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.Button_And_More}>
              <ScheduleCallButton
                isDisabled={isButtonDisabled}
                scheduledSession={scheduledSession}
                onClick={() => {
                  setIsOpenSchedulingModal(true);
                }}
              />
            </div>
          </div>
          {bookingJSX}
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatsListItem);
