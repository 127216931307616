import Geolocation from '@react-native-community/geolocation';

import type {GeocoderResultT} from '@src/utils/geo';
import {geocode, getLocationString} from '@src/utils/geo';

export const getGeoCurrentPosition = () => {
  return new Promise<GeocoderResultT>((resolve, reject) => {
    Geolocation.getCurrentPosition(
      async position => {
        try {
          const retObj: GeocoderResultT = {} as GeocoderResultT;
          const [result] = await geocode({
            coords: [position.coords.longitude, position.coords.latitude],
          });
          const location = getLocationString(result);

          retObj.formatted_address = location;
          retObj.geometry = {
            // @ts-ignore
            location: [position.coords.longitude, position.coords.latitude],
          };

          resolve(retObj);
        } catch (e) {
          reject(e);
        }
      },
      e => {
        const isPermissionDeniedError = e.code === e.PERMISSION_DENIED;

        if (isPermissionDeniedError) {
          //const err = labelReceiveLocationErrorMsg();
          //alert(err);
        }
      },
      {timeout: 5000},
    );
  });
};
