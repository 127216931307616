import type {FC} from 'react';
import React, {memo} from 'react';
import {Route, Switch} from 'react-router-dom';

import LogoOnlyLayout from '@src/layouts/LogoOnly';
import {
  QUserAbout,
  QUserFlowStart,
  UserFlowState,
} from '@src/modules/Questionnaire/QUserFlow';
import {PATH_TYPE} from '@src/routes/utils';

interface Props {}

const UserQuestionnairePage: FC<Props> = () => {
  return (
    <main className="UserQuestionnairePage">
      <Switch>
        <Route path={`/${PATH_TYPE.userQuestionnaire}/about`}>
          <LogoOnlyLayout>
            <QUserAbout />
          </LogoOnlyLayout>
        </Route>
        <Route path={`/${PATH_TYPE.userQuestionnaire}/progress`}>
          <LogoOnlyLayout>
            <UserFlowState>
              <QUserFlowStart />
            </UserFlowState>
          </LogoOnlyLayout>
        </Route>
      </Switch>
    </main>
  );
};

export default memo(UserQuestionnairePage);
