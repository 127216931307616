import type {FC} from 'react';
import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {PATH_TYPE} from '../../routes/utils';
import EditProfile from '../EditProfile/EditProfile';
import styles from '../EditProfile/styles.module.css';

interface Props {}

const UserProfile: FC<Props> = () => {
  const history = useHistory();

  const handleOnClickSave = () => {
    history.push({
      // pathname: '/dashboard/my-squads',
      pathname: `/${PATH_TYPE.yourSpace}/my-squads`,
    });
  };

  return (
    <Observer>
      {() => (
        <div className="UserProfile">
          <EditProfile
            isModal={false}
            className={`UserProfile ${styles.UserProfile}`}
            endEditCallBack={handleOnClickSave}
          />
        </div>
      )}
    </Observer>
  );
};

export default memo(UserProfile);
