import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {useIsAuth} from '@src/common/useUserMethods';
import Loader from '@src/components/Loader/Loader';
import {FirstUserRegister} from '@src/modules';
import CoachesPage from '@src/pages/Coaches';
import ConferencePage from '@src/pages/Conference';
import ConnectStripePage from '@src/pages/ConnectStripe';
import CoursePage from '@src/pages/Course';
import CRUProgramPage from '@src/pages/CRUProgram';
import DashboardPage from '@src/pages/Dashboard';
import GoalsPage from '@src/pages/Goals';
import LoginPage from '@src/pages/Login';
import MailchimpUnsubscribePage from '@src/pages/MailchimpUnsubscribe';
import ChatsPage from '@src/pages/NewLayout';
import OnboardingPage from '@src/pages/Onboarding';
import PracticeMainPage from '@src/pages/PracticeMain';
import ProfilePage from '@src/pages/Profile';
import ProgramMainPage from '@src/pages/ProgramMain';
import QuestionnairePage from '@src/pages/Questionnaire';
import QuestionnaireTemplatesPage from '@src/pages/QuestionnaireTemplatesPage';
// import ToDosPage from '@src/pages/ToDos';
import UnknownPage from '@src/pages/Unknown';
import UserQuestionnairePage from '@src/pages/UserQuestionnaire';
import {ToDosPage} from '@src/v2/pages/ToDos';

import {MyCalendarPage} from '../v2/pages/MyCalendar';
import {SingleEventPage} from '../v2/pages/SingleEventOld';
import {PathBuilderService} from '../v2/services/PathBuilderService';

import PrivateRoute from './PrivateRoute';
import {PATH_TYPE} from './utils';

export const USER_REGISTER_EMAIL_PATH = `/${PATH_TYPE.userProfile}`;

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);

  const setLocalStoreAppIsAuth = useIsAuth(
    () => {
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);
    },
  );

  setLocalStoreAppIsAuth();

  return (
    <>
      {isLoading && !__SSR__ ? (
        <Loader />
      ) : (
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/action/auth" component={LoginPage} />
          <PrivateRoute
            exact
            path={USER_REGISTER_EMAIL_PATH}
            component={FirstUserRegister}
          />
          <PrivateRoute
            path={`/${PATH_TYPE.yourSpace}`}
            component={DashboardPage}
          />
          <PrivateRoute exact path={'/'}>
            <Redirect to={`/${PATH_TYPE.yourSpace}`} />
          </PrivateRoute>
          <PrivateRoute
            path={`/${PATH_TYPE.profile}`}
            component={ProfilePage}
          />
          <PrivateRoute
            exact
            path={`/${PATH_TYPE.community}`}
            component={CoachesPage}
          />
          <PrivateRoute
            exact
            path={`/${PATH_TYPE.myCoaches}`}
            component={CoachesPage}
          />
          <Route
            exact
            path={`/${PATH_TYPE.coaches}/:slug`}
            component={PracticeMainPage}
          />
          <Route
            exact
            path={PathBuilderService.PATH_TEMPLATE_TO_PROGRAM}
            component={ProgramMainPage}
          />
          {/**
           * Compatibility with old program links
           * TODO: Remove this after June 2022
           */}
          <Redirect
            from={PathBuilderService.PATH_TEMPLATE_TO_PROGRAM_OLD}
            to={PathBuilderService.PATH_TEMPLATE_TO_PROGRAM}
          />
          <PrivateRoute
            path={`/${PATH_TYPE.program}`}
            forCoach
            component={CRUProgramPage}
            exact
          />
          <PrivateRoute
            path={`/${PATH_TYPE.program}/:programId(program:[0-9a-f]{24})`}
            forCoach
            component={CRUProgramPage}
            exact
          />
          <PrivateRoute
            path={`/${PATH_TYPE.program}/clone/:clonedProgramId(program:[0-9a-f]{24})`}
            forCoach
            component={CRUProgramPage}
            exact
          />
          <PrivateRoute
            exact
            path={PathBuilderService.PATH_TEMPLATE_TO_COURSE}
            component={CoursePage}
          />
          {/**
           * Compatibility with old course links
           * TODO: Remove this after June 2022
           */}
          <Redirect
            from={PathBuilderService.PATH_TEMPLATE_TO_COURSE_OLD}
            to={PathBuilderService.PATH_TEMPLATE_TO_COURSE}
          />
          <PrivateRoute path={`/${PATH_TYPE.chats}`} component={ChatsPage} />
          <PrivateRoute path={`/${PATH_TYPE.toDos}`} component={ToDosPage} />
          <PrivateRoute path={`/${PATH_TYPE.goals}`} component={GoalsPage} />
          <PrivateRoute
            path={`/${PATH_TYPE.onboarding}`}
            component={OnboardingPage}
          />
          <PrivateRoute
            exact
            path={`/${PATH_TYPE.conference}/:conferenceId(conference:[0-9a-f]{24})`}
            component={ConferencePage}
          />

          <Route
            path={`/${PATH_TYPE.userQuestionnaire}`}
            component={UserQuestionnairePage}
          />
          <PrivateRoute
            exact
            path={`/${PATH_TYPE.createQuestionnaire}`}
            forCoach
            component={QuestionnaireTemplatesPage}
          />
          <PrivateRoute
            path={`/${PATH_TYPE.questionnaire}`}
            forCoach
            component={QuestionnairePage}
          />

          <PrivateRoute
            exact
            path={`/${PATH_TYPE.connectStripe}`}
            component={ConnectStripePage}
          />
          <Route
            path="/unsubscribe-mess"
            component={MailchimpUnsubscribePage}
          />
          {/*v2 start*/}
          <Route path={'/' + PATH_TYPE.myCalendar} component={MyCalendarPage} />
          <Route
            path={'/' + PATH_TYPE.singleEventPage + '/:id'}
            component={SingleEventPage}
          />
          {/*<Route path={'/my-todos'} component={NewTodosPage} />*/}
          {/*v2 end*/}
          <Route path={undefined} component={UnknownPage} />
        </Switch>
      )}
    </>
  );
};

export default React.memo(Routes);
