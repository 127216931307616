import type {FC} from 'react';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Account} from '@yourcoach/shared/api/account';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {fileStore, getFileSrc} from '@yourcoach/shared/api/media/file';

import {labelEditPhoto, labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelAvatar,
  labelCreateAPractice,
  labelEditPractice,
  labelEditProfile,
  labelImACoach,
  labelNewsletter,
  labelUserGender,
  labelUserLocation,
  labelUserName,
  labelUserYearBirthday,
  labelYourOneStopPracticeManagement,
  labelYouWillReceiveCurrentNewsAboutTheProject,
} from '@src/common/i18n/i18nProfile';
import {useAppRedirect} from '@src/common/useAppRedirect';
import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {
  CustomButton,
  CustomCheckbox,
  CustomInput,
  CustomSelect,
} from '@src/components/CustomForm';
import type {IRadioItem} from '@src/components/CustomForm/CustomRadioButton/CustomRadioButton';
import type {IOption} from '@src/components/CustomForm/CustomSelect/CustomSelect';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Image from '@src/components/Image';
import ImageEditor from '@src/components/ImageEditor';
import ImageSelector from '@src/components/ImageSelector';
import Loader from '@src/components/Loader/Loader';
import Modal from '@src/components/ModalNew';
import UserProfileImgDecor from '@src/components/UserProfileImgDecor/UserProfileImgDecor';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import LoginFormState from '@src/pages/Login/context/LoginFormState';
import type {GeocoderResultT} from '@src/utils/geo';

import EditCoachProfile from '../EditCoachProfile/EditCoachProfile';
import {Accounts} from '..';

import AddLocation from './AddLocation/AddLocation';
import {getGeoCurrentPosition} from './getGeoCurrentPosition';
import getRadioBut from './getRadioItemsObject';
import validObjFn from './getValidObject';
import styles from './styles.module.css';

interface Props {
  isFirstEdit?: boolean;
  endEditCallBack?: (isCoach?: boolean) => void;
  isModal?: boolean;
  className?: string;
}

export interface ILocalStore {
  accountsStore: CollectionStore<Account>;
  avatarId: string;
  updateAvatarId(newVal: string): void;
  userName: string;
  userNameErr: string;
  phone: string;
  phoneErr: string;
  radioItems: IRadioItem[];
  userEmail: string;
  addEmail(email: string): void;
  showModalEmail: boolean;
  closeModalEmail(): void;
  openModalEmail(): void;
  showModalLocation: boolean;
  closeModalLocation(): void;
  openModalLocation(): void;
  showModalCreatePractice: boolean;
  setModalCreatePractice(newShowModalCreatePractice: boolean): void;
  userEmailErr: string;
  gender: string;
  updateGender(newVal: string): void;
  userYear: string;
  updateYear(newVal: string): void;
  isDisable: boolean;
  setDisable(disVal: boolean): void;
  errorText: string;
  isValid: boolean;
  setIsValid(nameField: any): void;
  setAllValid(): void;
  isCoach: boolean;
  location: string;
  setLocation(location: string): void;
  isSendNewsletter: boolean;
  unsubscribe: boolean;
  updateSendNewsletter(newVal: boolean): void;
  updateUnsubscribe(newVal: boolean): void;
  coords: number[] | null;
  setCoords(newCoords: any): void;
  updateInputVal(name: string, newVal: string): void;
  accountsList: Account[];
  accountsListEmail: Account[];
  accountsListPhone: Account[];
  showModalUpdateFile: boolean;
  setShowModalUpdateFile(showModalUpdateFile: boolean): void;
}

export const INITIAL_YOB = 2000;

export const MINIMUM_YOB = 1900;

export const MAXIMUM_YOB = new Date().getFullYear() - 3;

export const getYearsRange = () => {
  const YEARS_RANGE: IOption[] = [];

  for (let i = MINIMUM_YOB + 1; i <= MAXIMUM_YOB; i += 1) {
    YEARS_RANGE.push({id: `${i}`, val: `${i}`});
  }

  return YEARS_RANGE;
};

export const getGenderRange = () => {
  const objGenders = t('shared.gender');

  const genderData: IOption[] = Object.keys(objGenders).map(id => ({
    id,
    val: objGenders[id],
  }));

  return genderData;
};

const I18N_SCOPE = 'Profile';

const EditProfile: FC<Props> = ({
  isFirstEdit = false,
  endEditCallBack = () => {},
  isModal = true,
  className = '',
}) => {
  const validObj = validObjFn();

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const redirect = useAppRedirect('/');
  const user = currentUserStore.user;
  const yearsRange = getYearsRange();
  const genderData = getGenderRange();
  const [avatar, setAvatar] = useState<IFile | null | undefined>(
    currentUserStore.user!.avatar,
  );

  const [editAvatarModalIsVisible, showEditAvatarModal, hideEditAvatarModal] =
    useIsVisible();

  const localStore = useRef(
    observable<ILocalStore>(
      {
        showModalUpdateFile: false,
        setShowModalUpdateFile(showModalUpdateFile: boolean) {
          this.showModalUpdateFile = showModalUpdateFile;
        },
        accountsStore: createCollectionStore({
          method: 'user.accounts.list',
        }) as CollectionStore<Account>,
        avatarId: user?.avatar_id || '',
        userName: user?.name || '',
        userNameErr: '',
        phone: user?.private_profile?.phone || '',
        phoneErr: '',
        radioItems: getRadioBut(),
        userEmail: '',
        showModalEmail: false,
        showModalLocation: false,
        showModalCreatePractice: false,
        setModalCreatePractice(newShowModalCreatePractice: boolean) {
          this.showModalCreatePractice = newShowModalCreatePractice;
        },
        userEmailErr: '',
        gender: user?.gender || genderData[2].id,
        userYear: `${user?.year_of_birth}` || `${INITIAL_YOB}`,
        isDisable: false,
        errorText: '',
        isValid: false,
        isCoach:
          user?.roles?.includes('coach') ||
          user?.metadata?.onboarding?.account_type === 'coach',
        location: user?.location || '',

        isSendNewsletter: true,
        unsubscribe: user!.unsubscribe,
        coords: user?.coords ? user?.coords.coordinates : null,
        setDisable(disVal: boolean) {
          this.isDisable = disVal;
        },
        setLocation(location: string) {
          this.location = location;
        },
        setCoords(newCoords) {
          this.coords = newCoords;
        },
        closeModalEmail() {
          this.showModalEmail = false;
        },
        openModalEmail() {
          this.showModalEmail = true;
        },
        closeModalLocation() {
          this.showModalLocation = false;
        },
        openModalLocation() {
          this.showModalLocation = true;
        },
        updateSendNewsletter(newVal: boolean) {
          this.isSendNewsletter = newVal;
        },
        updateUnsubscribe(newVal: boolean) {
          this.unsubscribe = newVal;
        },
        updateAvatarId(newVal: string) {
          this.avatarId = newVal;
        },
        updateGender(newVal: string) {
          this.gender = newVal;
        },
        updateYear(newVal: string) {
          this.userYear = newVal;
        },
        addEmail(email: string) {
          this.userEmail = email;
        },
        updateInputVal(name: string, newVal: string) {
          if (!this.showModalEmail) {
            this.isValid = true;

            if (name === 'name') {
              this.userName = newVal;
              this.setIsValid('name');
            } else if (name === 'email') {
              this.userEmail = newVal;
              this.setIsValid('email');
            } else if (name === 'phone') {
              if (/^\+\d+$/.test(newVal) || newVal === '' || newVal === '+') {
                this.phone = newVal;
              }
            }
          }
        },
        setIsValid(nameField) {
          let isValid = true;

          const currentValidObj = validObj[nameField];
          const nameInLocalStore = currentValidObj.name;

          if (this[nameInLocalStore].length) {
            isValid = currentValidObj.validTest.test(
              this[nameInLocalStore].trim(),
            );
          }

          if (isValid) {
            this[`${nameInLocalStore}Err`] = '';
          } else {
            this[`${nameInLocalStore}Err`] = currentValidObj.err;
          }
        },
        setAllValid() {
          let allValid = true;

          for (const key in validObj) {
            const nameField = validObj[key].name;

            if (this[`${nameField}Err`]) {
              this.errorText = this[`${nameField}Err`];
              allValid = false;
            } else if (validObj[key].required && !this[nameField].length) {
              this.errorText = validObj[key].requiredErr;
              allValid = false;
            }

            if (!allValid) {
              break;
            }
          }

          this.isValid = allValid;
        },
        get accountsList() {
          return (this.accountsStore as CollectionStore<Account>).items.slice();
        },
        get accountsListEmail() {
          return (this.accountsStore as CollectionStore<Account>).items
            .slice()
            .filter(acc => acc.type === 'email');
        },
        get accountsListPhone() {
          return (this.accountsStore as CollectionStore<Account>).items
            .slice()
            .filter(acc => acc.type === 'phone');
        },
      },
      {
        showModalUpdateFile: observable,
        setShowModalUpdateFile: action,
        accountsStore: observable,
        avatarId: observable,
        location: observable,
        userName: observable,
        userEmail: observable,
        showModalEmail: observable,
        showModalLocation: observable,
        showModalCreatePractice: observable,
        gender: observable,
        userNameErr: observable,
        userEmailErr: observable,
        userYear: observable,
        isDisable: observable,
        errorText: observable,
        isValid: observable,
        isCoach: observable,
        isSendNewsletter: observable,
        unsubscribe: observable,
        setLocation: action,
        setCoords: action,
        updateAvatarId: action,
        updateSendNewsletter: action,
        updateUnsubscribe: action,
        closeModalEmail: action,
        openModalEmail: action,
        setModalCreatePractice: action,
        closeModalLocation: action,
        openModalLocation: action,
        updateGender: action,
        setDisable: action,
        updateYear: action,
        updateInputVal: action,
        setIsValid: action,
        setAllValid: action,
        accountsList: computed,
        accountsListEmail: computed,
        accountsListPhone: computed,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.accountsStore.fetch();

    const disposeReaction = reaction(
      () => localStore.accountsListEmail,
      accountsListEmail => {
        if (accountsListEmail.length) {
          localStore.addEmail(
            accountsListEmail[accountsListEmail.length - 1].value,
          );
        }
      },
    );

    const geoData = async () => {
      try {
        const location: GeocoderResultT = await getGeoCurrentPosition();

        if (location) {
          localStore.setLocation(location.formatted_address);
          localStore.setCoords(location.geometry.location);
        }
      } catch (error) {
        // TODO: log error
      }
    };

    geoData();

    return () => {
      disposeReaction();
      localStore.accountsStore.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAvatarId = (file: IFile) => {
    localStore.updateAvatarId(file._id);
    setAvatar(file);
  };
  const handleOnSelectGender = (id: string) => {
    localStore.updateGender(id);
  };
  const handleOnSelectYear = (id: string) => {
    localStore.updateYear(id);
  };
  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.updateInputVal(event.target.name, event.target.value);
  };

  const handleClickLocation = () => {
    localStore.openModalLocation();
  };
  const addLocation = (location: GeocoderResultT) => {
    localStore.setLocation(location.formatted_address);
    localStore.setCoords([
      location.geometry.location.lng,
      location.geometry.location.lat,
    ]);
    localStore.closeModalLocation();
  };
  const handleCloseModalLocation = () => {
    localStore.closeModalLocation();
  };
  const handleOpenModalCreatePractice = () => {
    localStore.setModalCreatePractice(true);
  };
  const handleCloseModalCreatePractice = () => {
    localStore.setModalCreatePractice(false);
  };
  const endEditPractice = () => {
    localStore.setModalCreatePractice(false);
  };
  const handleSendNewsletter = () => {
    localStore.updateSendNewsletter(!localStore.isSendNewsletter);
  };
  const handleUnsubscribe = () => {
    localStore.updateUnsubscribe(!localStore.unsubscribe);
  };
  const submitNewsletter = async () => {
    try {
      const {success} = await currentUserStore.subscribeNewsletter(
        localStore.isCoach,
      );

      if (!success) {
        // TODO: log error
      }
    } catch (error) {
      // TODO: log error
    }
  };
  const handleOnSave = async () => {
    localStore.setAllValid();

    if (localStore.isValid) {
      localStore.setDisable(true);

      try {
        showOverlay();

        await currentUserStore.update({
          avatar_id: localStore.avatarId || null,
          name: localStore.userName.trim() || undefined,
          gender: (localStore.gender as 'male' | 'female' | 'na') || null,
          coords: localStore.coords
            ? {
                type: 'Point',
                coordinates: [localStore.coords[0], localStore.coords[1]],
              }
            : null,
          location: localStore.location || null,
          year_of_birth: localStore.userYear ? +localStore.userYear : null,
          private_profile: {
            phone: localStore.phone === '' ? null : localStore.phone,
          },
          unsubscribe: localStore.unsubscribe,
        });

        if (localStore.isSendNewsletter) {
          submitNewsletter();
        }

        hideOverlay();

        if (localStore.isCoach) {
          if (isFirstEdit && !user?.coach_title) {
            endEditCallBack(true);
          } else {
            endEditCallBack();
          }
        } else {
          if (isFirstEdit) {
            const path = localAppStore?.returnPath
              ? localAppStore?.returnPath
              : '/';

            redirect(path);
          } else {
            endEditCallBack();
          }
        }
      } catch (error) {
        hideOverlay();

        localStore.setDisable(true);

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [{label: t(['shared.button.ok'])}],
        });
      }
    }
  };

  const handleOnClickUpdateFile = () => {
    showEditAvatarModal();
  };

  const handleOnClickDeleteFile = () => {
    setAvatar(undefined);

    // @ts-ignore
    localStore.avatarId = null;
  };

  const handleGetUpdateFile = async (newFile: File) => {
    localStore.setShowModalUpdateFile(false);
    hideEditAvatarModal();

    const file = await fileStore.upload(newFile);

    getAvatarId(file);
    localStore.updateAvatarId(file._id);
  };

  const avatarSrc = useMemo(() => getFileSrc(avatar).url || '', [avatar]);

  return (
    <Observer>
      {() => (
        <LoginFormState>
          <div
            className={`EditProfile ${
              isModal ? styles.EditProfileModal : styles.EditProfile
            } ${className}`}
          >
            {isModal ? <h3>{labelEditProfile()}</h3> : null}
            <div className={styles.profileContainer}>
              <div className={styles.fields}>
                <div className={styles.error}>
                  {localStore.isValid ? '' : localStore.errorText}
                </div>
                <br />
                <div className={!isFirstEdit ? styles.inputFields : ''}>
                  <CustomInput
                    type="input"
                    label={labelUserName() + '*'}
                    customClassInput={
                      isModal
                        ? styles.nameInput
                        : `QStandardInput ${styles.nameInput}`
                    }
                    customClassLabel={`${styles.nameLabel} ${
                      localStore.userName.length ? styles.activeLink : ''
                    }`}
                    customClassCont={isModal ? undefined : 'QStandardContainer'}
                    onChange={handleOnChangeInput}
                    value={localStore.userName}
                    id="name"
                    name="name"
                    error={localStore.userNameErr}
                  />
                  <CustomSelect
                    options={genderData}
                    onSelect={handleOnSelectGender}
                    defaultId={localStore.gender}
                    label={labelUserGender()}
                    classNameMain={
                      isModal
                        ? 'CustomSelect'
                        : `CustomSelectV2 ${styles.nameInput}`
                    }
                  />
                  <CustomSelect
                    options={yearsRange}
                    onSelect={handleOnSelectYear}
                    defaultId={localStore.userYear as string}
                    label={labelUserYearBirthday()}
                    classNameMain={
                      isModal
                        ? 'CustomSelect'
                        : `CustomSelectV2 ${styles.nameInput}`
                    }
                  />
                  <CustomInput
                    type="tel"
                    label={'Phone'}
                    customClassInput={
                      isModal
                        ? styles.nameInput
                        : `QStandardInput ${styles.nameInput}`
                    }
                    customClassLabel={`${styles.nameLabel} ${
                      localStore.phone.length ? styles.activeLink : ''
                    }`}
                    customClassCont={isModal ? undefined : 'QStandardContainer'}
                    onChange={handleOnChangeInput}
                    value={localStore.phone}
                    id="phone"
                    name="phone"
                    error={localStore.phoneErr}
                    placeholder={'+380980000000'}
                  />
                  {localStore.phone.length ? (
                    <div className={styles.ConfirmationAndCheckbox}>
                      <div className={styles.ConfirmationWarning}>
                        By entering your phone number you opt in to be notified
                        of operational updates for YourCoach
                      </div>
                      <CustomCheckbox
                        id="sms-checkbox"
                        label="I agree to receive SMS notifications"
                        checked={!localStore.unsubscribe}
                        onChange={handleUnsubscribe}
                      />
                    </div>
                  ) : null}
                  <CustomInput
                    type="input"
                    disabled
                    label={labelUserLocation()}
                    onClick={handleClickLocation}
                    customClassInput={
                      isModal
                        ? styles.nameInput
                        : `QStandardInput ${styles.nameInput}`
                    }
                    customClassCont={isModal ? undefined : 'QStandardContainer'}
                    customClassLabel={`${styles.nameLabel} ${
                      localStore.location.length ? styles.activeLink : ''
                    }`}
                    value={localStore.location}
                  />
                  <ModalAnimateWin
                    showModal={localStore.showModalLocation}
                    closeModalHandler={handleCloseModalLocation}
                  >
                    <AddLocation returnLocation={addLocation} />
                  </ModalAnimateWin>
                  {!isFirstEdit && (
                    <div className={styles.accountsContainer}>
                      <Accounts isModal={isModal} />
                    </div>
                  )}
                </div>
                {isFirstEdit && (
                  <div>
                    <br />
                    <div
                      className={`customGeneratorCheckBox ${styles.customGeneratorCheckBox}`}
                    >
                      <div className={styles.header}>
                        <div>
                          <h4>{labelNewsletter()}</h4>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="switch"
                            onClick={handleSendNewsletter}
                            checked={localStore.isSendNewsletter}
                          />
                        </div>
                      </div>
                      <div className={styles.text}>
                        {labelYouWillReceiveCurrentNewsAboutTheProject()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${styles.photo} ${
                  isFirstEdit ? '' : styles.photoWhite
                }`}
              >
                <div className={styles.saveButContainer}>
                  <CustomButton
                    type="button"
                    disabled={localStore.isDisable}
                    classButton={`standardButton ${styles.saveBut}`}
                    onClick={handleOnSave}
                  >
                    {t('shared.button.save')}
                  </CustomButton>
                </div>
                {isModal ? null : (
                  <div className={styles.title}>{labelAvatar()}</div>
                )}
                <div className={isModal ? '' : styles.avatarContainer}>
                  <div className={styles.decorPhotoContainer}>
                    {isModal ? (
                      <UserProfileImgDecor />
                    ) : (
                      <ImageSelector onUpload={getAvatarId}>
                        <div className={styles.userProfileContainer}>
                          <Image
                            src={avatarSrc}
                            placeholder={
                              <AvatarPlaceholder
                                name={currentUserStore.user!.name}
                              />
                            }
                          />
                        </div>
                      </ImageSelector>
                    )}
                  </div>
                  {avatar ? (
                    <>
                      <button
                        className={`ovalButton ${styles.avatarActionButton}`}
                        type="button"
                        onClick={handleOnClickUpdateFile}
                      >
                        {t([I18N_SCOPE, 'edit_avatar_button'])}
                      </button>
                      <button
                        className={`ovalButton ${styles.avatarActionButton} ${styles.danger}`}
                        type="button"
                        onClick={handleOnClickDeleteFile}
                      >
                        {t([I18N_SCOPE, 'remove_avatar_button'])}
                      </button>
                    </>
                  ) : null}
                </div>
                {avatar ? (
                  <Modal
                    isOpen={editAvatarModalIsVisible}
                    onAfterClose={hideEditAvatarModal}
                    title={labelEditPhoto()}
                  >
                    <ImageEditor
                      image={avatar}
                      onSuccess={handleGetUpdateFile}
                    />
                  </Modal>
                ) : null}
                {!isFirstEdit && (
                  <div
                    className={`${styles.useCoach} ${
                      isModal ? '' : styles.noModal
                    }`}
                  >
                    <div>
                      <h3>{labelImACoach()}</h3>
                      <span>{labelYourOneStopPracticeManagement()}</span>
                    </div>
                    <div className={isModal ? '' : styles.butContainer}>
                      <button
                        className={`ovalButton ${styles.greenButton}`}
                        type="button"
                        onClick={handleOpenModalCreatePractice}
                      >
                        {user?.roles.includes('coach')
                          ? labelEditPractice()
                          : labelCreateAPractice()}
                      </button>
                    </div>
                    <Modal
                      isOpen={localStore.showModalCreatePractice}
                      onAfterClose={handleCloseModalCreatePractice}
                      title={
                        user?.roles.includes('coach')
                          ? labelEditPractice()
                          : labelCreateAPractice()
                      }
                    >
                      <EditCoachProfile
                        isFirstEdit={isFirstEdit}
                        endEditCallBack={endEditPractice}
                      />
                    </Modal>
                  </div>
                )}
              </div>
            </div>
            {overlayIsVisible ? (
              <div className="overlay">
                <Loader />
              </div>
            ) : null}
          </div>
        </LoginFormState>
      )}
    </Observer>
  );
};

export default memo(EditProfile);
