import type {FC} from 'react';
import React, {memo, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {
  labelAddFolder,
  labelEditFolder,
  labelFolderName,
  labelNotAValidField,
} from '@src/common/i18n/i18nLibrary';
import {CustomButton, CustomInput} from '@src/components/CustomForm';

import styles from './styles.module.css';

interface Props {
  onUpload: (nameFolder: string) => void;
  edit?: boolean;
  nameFolder?: string;
}

interface ILocalStore {
  nameFolder: string;
  isDisable: boolean;
  errorText: string;
  isValid: boolean;
  updateVal(newVal: string): void;
  setIsValid(): void;
}

const AddFolder: FC<Props> = ({onUpload, edit = false, nameFolder = ''}) => {
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          nameFolder: nameFolder,
          isDisable: true,
          errorText: '',
          isValid: false,
          updateVal(newVal: string) {
            this.nameFolder = newVal;

            this.setIsValid();
          },
          setIsValid() {
            this.isValid = /^[a-zA-Z\d\-.]+$/.test(this.nameFolder);

            if (this.isValid && this.nameFolder.length) {
              this.errorText = '';
              this.isDisable = false;
            } else {
              if (this.nameFolder.length) {
                this.errorText = labelNotAValidField();
              } else {
                this.errorText = '';
              }

              this.isDisable = true;
            }
          },
        },
        {
          nameFolder: observable,
          isDisable: observable,
          errorText: observable,
          isValid: observable,
          updateVal: action,
          setIsValid: action,
        },
      ),
    [nameFolder],
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStore.updateVal(e.currentTarget.value);
  };

  const handleOnClick = () => {
    onUpload(localStore.nameFolder);
  };

  return (
    <Observer>
      {() => (
        <div className={`AddFolder ${styles.AddFolder}`}>
          <h3>
            {edit ? `${labelEditFolder()} '${nameFolder}'` : labelAddFolder()}
          </h3>
          <div className={styles.container}>
            <CustomInput
              showErrorImmediately={true}
              type="input"
              label={labelFolderName()}
              customClassLabel="standardLabel"
              customClassInput="standardInput border"
              id="searchFile"
              value={localStore.nameFolder}
              error={localStore.errorText}
              onChange={handleOnChange}
            />
          </div>
          <CustomButton
            type="button"
            disabled={localStore.isDisable}
            onClick={handleOnClick}
          >
            {edit ? labelEditFolder() : labelAddFolder()}
          </CustomButton>
        </div>
      )}
    </Observer>
  );
};

export default memo(AddFolder);
