export const SET_USER = 'SET_USER';

export const SET_AVATAR = 'SET_AVATAR';

export const SET_COACH_LOGO = 'SET_COACH_LOGO';

export const SET_COACH_TITLE = 'SET_COACH_TITLE';

export const SET_COACH_DESCRIPTION = 'SET_COACH_DESCRIPTION';

export const SET_NAME = 'SET_NAME';

export const SET_CLIENTS_HELPED = 'SET_CLIENTS_HELPED';
