import React from 'react';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';

import MealSvg from '@yourcoach/shared/assets/icons/meal.svg';
import TickSquareSvg from '@yourcoach/shared/assets/icons/primary/Tick Square.svg';
import CameraSvg from '@yourcoach/shared/assets/icons/secondary/Camera.svg';
import {useMobxStore} from '@yourcoach/shared/hooks';
import type {AttachmentFile} from '@yourcoach/shared/stores/single-event';
import {TaskAttachmentFormStore} from '@yourcoach/shared/stores/single-event';
import {TextInput} from '@yourcoach/shared/uikit/TextInput';
import {View} from '@yourcoach/shared/uikit/View';
import {
  ConditionalFlagManager,
  payloadActionCreator,
} from '@yourcoach/shared/utils';

import Modal from '@src/components/ModalNew';
import AddFileModal from '@src/modules/library/AddFileModal';
import MealInput from '@src/modules/ToDos/MealInput/MealInput';
import {IconButton} from '@src/v2/components/IconButton';

import {AttachedPhotoIcon} from '../../components/AttachedPhotoIcon';
import {AttachmentsBox} from '../../components/AttachmentsBox';
import {MealIcon} from '../../components/MealIcon';
import {Subtitle} from '../../components/Subtitle';
import {I18N_CLIENT_TASK, useOnAttachmentClick, useOpenMeal} from '../../utils';

import {Removable} from './components/Removable';
import {FormSkeleton} from './components/skeleton';

const AttachmentsForm: React.FC<{
  taskId: string;
}> = observer(({taskId}) => {
  const store = useMobxStore(() => new TaskAttachmentFormStore(taskId));

  const isLoading = useMobxStore(() => {
    const manager = new ConditionalFlagManager(true);

    manager.trueWhen(() => store.initialLoad.isLoading);

    return manager;
  });

  const {MealModal, onMealClick} = useOpenMeal();

  const onAttachmentClickHandler = useOnAttachmentClick();

  const onAddPhoto = React.useCallback(
    (files: AttachmentFile[]) => {
      store.onAddPhoto(payloadActionCreator(files));
    },
    [store],
  );

  const onAddMeal = React.useCallback(
    meal => {
      store.onAddMeal(payloadActionCreator(meal));
    },
    [store],
  );

  const onDoneClick = async () => {
    await store.completeTask();
  };

  return isLoading.value ? (
    <FormSkeleton />
  ) : (
    <View style={{flexDirection: 'column', gap: 20}}>
      <TextInput
        value={store.editor.task.comment}
        placeholder={t([I18N_CLIENT_TASK, 'placeholder', 'comment_input'])}
        onChange={store.onChangeComment}
        disabled={store.isActionsDisabled}
      />
      <Subtitle>
        {t([I18N_CLIENT_TASK, 'section_title', 'attachments'])}
      </Subtitle>

      <AttachmentsBox>
        {store.editor.task.attachments.map(attachment => (
          <Removable
            onClick={() =>
              store.onRemovePhoto(payloadActionCreator(attachment))
            }
            disabled={store.isActionsDisabled}
            key={attachment._id}
          >
            <AttachedPhotoIcon
              alt={attachment.alt}
              onClick={() => onAttachmentClickHandler(attachment)}
              thumbSrc={attachment.imageSrc}
            />
          </Removable>
        ))}
        {store.editor.task.meals.map(meal => (
          <Removable
            onClick={() => store.onRemoveMeal(payloadActionCreator(meal))}
            disabled={store.isActionsDisabled}
            key={meal.query}
          >
            <MealIcon
              onClick={() => onMealClick(meal)}
              time={meal.renderTime}
            />
          </Removable>
        ))}
      </AttachmentsBox>
      <View style={{justifyContent: 'space-between'}}>
        <View style={{gap: 12}}>
          <IconButton
            iconFillColor={'icon3'}
            icon={<CameraSvg />}
            scheme={'gray'}
            onClick={store.addFileModalFlag.toggle}
            disabled={store.isActionsDisabled}
          >
            {t([I18N_CLIENT_TASK, 'button', 'add_photo'])}
          </IconButton>
          <IconButton
            iconFillColor={'icon3'}
            icon={<MealSvg />}
            scheme={'gray'}
            onClick={store.addMealModalFlag.toggle}
            disabled={store.isActionsDisabled}
          >
            {t([I18N_CLIENT_TASK, 'button', 'add_meal'])}
          </IconButton>
        </View>
        <IconButton
          icon={<TickSquareSvg />}
          scheme={'secondary'}
          style={{width: 200}}
          disabled={store.isDoneDisabled}
          onClick={onDoneClick}
        >
          {store.doneButtonLabel}
        </IconButton>
      </View>
      <AddFileModal
        isOpen={store.addFileModalFlag.isTrue}
        onRequestClose={store.addFileModalFlag.toggle}
        mode={'upload'}
        onUpload={onAddPhoto}
        fileType={'image'}
      />

      <Modal
        title={t([I18N_CLIENT_TASK, 'meal_popup', 'title', 'add'])}
        isOpen={store.addMealModalFlag.isTrue}
        onRequestClose={() => store.addMealModalFlag.setValue(false)}
      >
        <MealInput getMealData={onAddMeal} />
      </Modal>
      <MealModal />
    </View>
  );
});

export {AttachmentsForm};
