import type {FC} from 'react';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import PauseIcon from '@yourcoach/shared/assets/icons/pause.svg';
import PlayIcon from '@yourcoach/shared/assets/icons/play.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {convertCurrentTime} from '@src/utils';

import styles from './styles.module.scss';

interface Props {
  attachment: IFile;
  tintColor?: string;
}

const VoiceMessage: FC<Props> = ({attachment}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const [isReady, setIsReady] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const durationString = useMemo(() => {
    return convertCurrentTime(duration);
  }, [duration]);

  const currentTimeString = useMemo(() => {
    return convertCurrentTime(currentTime);
  }, [currentTime]);

  const player = useRef(new Audio(getFileSrc(attachment).url || '')).current;

  const isMy = useMemo(
    () =>
      currentUserStore.user && attachment.user_id === currentUserStore.user._id,
    [attachment.user_id, currentUserStore],
  );

  useEffect(() => {
    const playing = () => {
      setIsPlay(true);
    };
    const ended = () => {
      setIsPlay(false);
      setCurrentProgress(0);
      setCurrentTime(0);
    };
    const pause = () => {
      setIsPlay(false);
    };
    const timeupdate = () => {
      const durationIn = (player && player.duration) || 0;

      setCurrentTime(player.currentTime);
      setDuration(durationIn);
      setCurrentProgress((player.currentTime / durationIn) * 100);
    };
    const loadedmetadata = () => {
      const durationIn = (player && player.duration) || 0;

      setCurrentTime(player.currentTime);
      setDuration(durationIn);

      setIsReady(true);
    };

    player.volume = 1;
    player.addEventListener('playing', playing, false);
    player.addEventListener('ended', ended, false);
    player.addEventListener('pause', pause, false);
    player.addEventListener('timeupdate', timeupdate);
    player.addEventListener('loadedmetadata', loadedmetadata);

    return () => {
      player.removeEventListener('playing', playing, false);
      player.removeEventListener('ended', ended, false);
      player.removeEventListener('pause', pause, false);
      player.removeEventListener('timeupdate', timeupdate);
      player.removeEventListener('loadedmetadata', loadedmetadata);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePlay = () => {
    if (!isPlay) {
      player.play();
    } else {
      player.pause();
    }
  };

  return (
    <div
      className={`VoiceMessage ${styles.VoiceMessage} ${
        isMy ? styles.isMy : ''
      }`}
    >
      <div className={styles.progress} style={{width: `${currentProgress}%`}} />
      {isReady ? (
        <div className={styles.contentContainer}>
          <div onClick={togglePlay} className={styles.buttonContainer}>
            {!isPlay ? (
              <div className={styles.playIconContainer}>
                <PlayIcon
                  width={'100%'}
                  height={'100%'}
                  fill={themes.light.color.icon2}
                  className={styles.playIcon}
                />
              </div>
            ) : (
              <div className={styles.pauseIconContainer}>
                <PauseIcon
                  width={'100%'}
                  height={'100%'}
                  className={styles.pauseIcon}
                />
              </div>
            )}
          </div>
          <div className={styles.time}>
            {currentTimeString}/{durationString}
          </div>
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default memo(VoiceMessage);
