import type {Course} from '@yourcoach/shared/api/course';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

import type {Expanded as CourseExpanded} from '../courses/utils';
import {expand as courseExpand} from '../courses/utils';

export const expand = {
  conference: [
    ['course_id', null, courseExpand],
    ['client_id', {name: 'Client not found'}, userExpand],
    ['coach_ids->coaches', {name: 'User not found'}, userExpand],
  ],
};

type ExpandedUser = (User | (Partial<User> & Required<Pick<User, 'name'>>)) &
  UserExpanded;

export interface Expanded {
  course?: Course & CourseExpanded;
  client: ExpandedUser;
  coaches: ExpandedUser[];
}
