import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {autorun} from 'mobx';

import localAppStore from '@src/context/appStore';
import {MainContainerPublicPage, MainContainerWithMenu} from '@src/layouts';

import PracticePage from '../Practice';
import PracticePublicPage from '../PracticePublic';

interface Props {}

const PracticeMainPage: FC<Props> = () => {
  const [isPublicPage, setIsPublicPage] = useState(true);

  useEffect(() => {
    const dispose = autorun(() => {
      const isAuth = localAppStore?.isAuth || false;

      setIsPublicPage(!isAuth);
    });

    return dispose;
  }, []);

  return (
    <div className="PracticePageMain">
      {isPublicPage ? (
        <MainContainerPublicPage allHeight>
          <PracticePublicPage />
        </MainContainerPublicPage>
      ) : (
        <MainContainerWithMenu
          allHeight
          isWithFrom
          isWithCrumbs
          isWithNewCrumbs
        >
          <PracticePage />
        </MainContainerWithMenu>
      )}
    </div>
  );
};

export default memo(PracticeMainPage);
