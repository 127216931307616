import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';

import {setError} from '@src/common/setError';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import type {TSystemFolder} from '@src/models/library';

import LibraryContext from '../../context/LibraryContext';
import type {ILibraryLocalStore} from '../../context/useLibraryLocalStore';
import ListFolderContainer from '../../ListFolderContainer/ListFolderContainer';
import styles from '../../ListFolders/styles.module.scss';
import SystemFolder from '../../ListFolders/SystemFolder/SystemFolder';

interface Props {}

export interface ILocalStore {
  programsStore: CollectionStore<Program> | null;
  setProgramsStore: (newProgramsStore: CollectionStore<Program>) => void;
  updateProgramsStore: () => void;
  data: TSystemFolder[];
}

const ListPrograms: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const initProgramsStore: CollectionStore<Program> | null = null;
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);

  const localStore = useMemo(
    () =>
      observable<ILocalStore>(
        {
          programsStore: initProgramsStore,
          setProgramsStore(newProgramsStore: CollectionStore<Program>) {
            this.programsStore = newProgramsStore;
          },
          updateProgramsStore() {
            const LIMIT = 300;
            const silent = false;

            libraryStory!.setLoading(true);

            this.programsStore!.fetch(
              {
                limit: Math.max(this.programsStore.items.length, LIMIT),
              },
              {silent},
            )
              .then(() => {
                libraryStory!.setLoading(false);
              })
              .catch(error => {
                setError(error);
                libraryStory!.setLoading(false);
              });
          },
          get data() {
            let retArr = [];

            if (this.programsStore) {
              const programsStoreArr = this.programsStore.items.slice();

              retArr = programsStoreArr.map(
                (item: {title: string; _id: string}) => {
                  return {
                    _id: 'my_programs',
                    name: item.title,
                    program_id: item._id,
                    isSystem: true,
                    isStarred: true,
                    isProgram: true,
                  };
                },
              );
            }

            return retArr;
          },
        },
        {
          programsStore: observable,
          setProgramsStore: action,
          updateProgramsStore: action,
          data: computed,
        },
      ),
    [libraryStory],
  );

  useEffect(() => {
    const user = currentUserStore.user;
    const LIMIT = 300;

    const programsStore: CollectionStore<Program> = createCollectionStore({
      method: 'coach.programs.list',
      params: {
        sort: [['created', -1]],
        query: [['user_id', '==', user!._id]],
        limit: LIMIT,
      },
    });

    localStore.setProgramsStore(programsStore);
    localStore.updateProgramsStore();

    return () => {
      localStore.programsStore!.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div className="ListPrograms">
          {localStore!.data.length === 0 && !libraryStory?.loading && (
            <NoResults />
          )}
          <ListFolderContainer className={styles.topOffset}>
            {localStore!.data.map((item: TSystemFolder) => {
              return (
                <SystemFolder
                  key={`${item.program_id!}_${item._id}`}
                  data={item}
                  className={styles.SystemFolder}
                />
              );
            })}
          </ListFolderContainer>
        </div>
      )}
    </Observer>
  );
};

export default memo(ListPrograms);
