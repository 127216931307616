import './Scrollbar.css';

import type {FC} from 'react';
import React, {memo, useMemo} from 'react';
import type {ScrollbarProps} from 'react-scrollbars-custom';
import CustomScrollbar from 'react-scrollbars-custom';

const Scrollbar: FC<ScrollbarProps> = ({children, ...props}) => {
  const scrollProps: ScrollbarProps = useMemo(
    () => ({
      wrapperProps: {className: 'Scrollbar-wrapper'},
      trackYProps: {className: 'Scrollbar-trackY'},
      thumbYProps: {className: 'Scrollbar-thumbY'},
      trackXProps: {className: 'Scrollbar-trackX'},
      thumbXProps: {className: 'Scrollbar-thumbX'},
      className: 'Scrollbar',
    }),
    [],
  );

  return (
    // @ts-ignore
    <CustomScrollbar {...scrollProps} {...props}>
      {children}
    </CustomScrollbar>
  );
};

export default memo(Scrollbar);
