import React, {useCallback} from 'react';

import * as S from './styles';
import type {Props} from './types';

export const TabBarItem: React.FC<Props> = ({
  title,
  active,
  index,
  onClick,
}) => {
  const onClickCb = useCallback(() => {
    onClick?.(index);
  }, [index, onClick]);

  return (
    <S.Container onClick={onClickCb} active={active}>
      <S.Text active={active}>{title}</S.Text>
    </S.Container>
  );
};
