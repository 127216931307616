import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {Conference} from '@yourcoach/shared/api/conference';
import {
  conferenceIsConflicting,
  conferenceIsEnded,
  conferenceIsExpired,
  conferenceIsStarted,
  getConferenceAbleToStartDate,
} from '@yourcoach/shared/api/conference';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import {isTimeWithPeriod} from '@yourcoach/shared/utils/datetime';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {Expanded as ConferenceExpanded} from '@src/modules/conferences/utils';

import styles from '../styles.module.css';

const I18N_SCOPE = 'ConferencesListItem';
const I18N_SCOPE_WORKSPACE = 'WorkspaceJoinedCoursesTabListItem';

export type ConferenceT = Conference & ConferenceExpanded;

interface Props<T extends ConferenceT = ConferenceT> {
  conference: T;
  onPress?: (conference: T) => void;
  onMoreButtonPress?: (
    conference: T,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  variant?: 'conflict';
  showCourseName?: boolean;
  showDescription?: boolean;
  className?: string;
  isSelect?: boolean;
  onStartConferenceButtonPress?: (conference: T) => void;
}

const ConferencesListItem: FC<Props> = ({
  conference,
  onPress,
  onMoreButtonPress,
  showCourseName,
  variant,
  showDescription,
  className,
  isSelect = false,
  onStartConferenceButtonPress,
}) => {
  const history = useHistory();
  const {
    stores: {currentUserStore, membershipStore},
  } = useContext(AppContext);

  const onPressCb = useCallback(() => {
    onPress && onPress(conference);
  }, [onPress, conference]);

  const onMorePressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onMoreButtonPress && onMoreButtonPress(conference, event);
    },
    [onMoreButtonPress, conference],
  );

  const onStartConferenceButtonPressCb = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      onStartConferenceButtonPress && onStartConferenceButtonPress(conference);
    },
    [onStartConferenceButtonPress, conference],
  );

  const onJoinConferenceButtonPress = useCallback(async () => {
    const membership = membershipStore.membershipLookup.get(
      conference.course_id as string,
    );

    const userIsFrozen = membership && membership.status === 'frozen';

    if (userIsFrozen) {
      // TODO: navigate to course
    } else {
      history.push({
        pathname: `/conferences/${conference._id}`,
      });
    }
  }, [conference, history, membershipStore]);

  const onChangeDateButtonPress = useCallback(() => {
    // TODO: Open edit conference modal here
  }, []);

  const dateFormat = useMemo(
    () => (isTimeWithPeriod() ? 'hh:mm' : 'HH:mm'),
    [],
  );

  const startDate = useMemo(
    () =>
      dayjs(datetimeObjToISOString(conference.start_date)).format(
        `${dateFormat}${isTimeWithPeriod() ? ' A' : ''}`,
      ),
    [conference.start_date, dateFormat],
  );

  const endDate = useMemo(
    () =>
      dayjs(datetimeObjToISOString(conference.end_date)).format(
        `${dateFormat}${isTimeWithPeriod() ? ' A' : ''}`,
      ),
    [conference.end_date, dateFormat],
  );

  let status = '';

  const isStarted = conferenceIsStarted(conference);
  const isEnded = conferenceIsEnded(conference);
  const isExpired = conferenceIsExpired(conference);
  const isConflicting = conferenceIsConflicting(conference);
  const isCoach =
    conference.coach_ids &&
    conference.coach_ids.includes(currentUserStore.user!._id);

  let conferenceActionButton: React.ReactElement | null = null;

  const [, updateState] = useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const ableToStartDate = useMemo(
    () =>
      dayjs(datetimeObjToISOString(getConferenceAbleToStartDate(conference))),
    [conference],
  );

  const isClientConference = useMemo(
    () => conference.created_by === 'client',
    [conference.created_by],
  );

  const participantName = useMemo(() => {
    const {coaches, coach_ids, client, course} = conference;

    if (isClientConference && coaches.length && client) {
      if (coach_ids.includes(currentUserStore.user!._id)) {
        return client.name;
      }

      return coaches[0].name;
    } else if (
      course &&
      course.client_id &&
      conference.coach_ids.includes(currentUserStore.user!._id)
    ) {
      return course.client.name;
    }

    return '';
  }, [conference, currentUserStore.user, isClientConference]);

  const conferenceTitle = useMemo(
    () =>
      isClientConference
        ? conference.course_id
          ? t('label.live_session')
          : participantName
        : conference.title,
    [
      conference.course_id,
      conference.title,
      isClientConference,
      participantName,
    ],
  );

  useEffect(() => {
    const needRerender = !isExpired && !isEnded && dayjs() >= ableToStartDate;

    let timer: ReturnType<typeof setInterval>;

    if (needRerender) {
      timer = setInterval(() => {
        if (!isExpired && !isEnded && dayjs() >= ableToStartDate) {
          forceUpdate();
        } else {
          clearInterval(timer);
        }
      }, 15 * 1000);
    }

    return () => clearInterval(timer);
  }, [isEnded, isExpired, ableToStartDate, forceUpdate]);

  if (variant !== 'conflict') {
    if (isExpired) {
      status = t([I18N_SCOPE, 'expired_label']);
    } else if (isEnded) {
      status = t([I18N_SCOPE, 'ended_label']);
    } else if (isCoach && isConflicting) {
      conferenceActionButton = (
        <div className={styles.button} onClick={onChangeDateButtonPress}>
          {t([I18N_SCOPE, 'change_date_button'])}
        </div>
      );
    } else if (isStarted) {
      conferenceActionButton = (
        <div className={styles.button} onClick={onJoinConferenceButtonPress}>
          {t([I18N_SCOPE, 'join_conference_button'])}
        </div>
      );
    } else if (isCoach && dayjs() >= ableToStartDate) {
      conferenceActionButton = (
        <div className={styles.button} onClick={onStartConferenceButtonPressCb}>
          {t([I18N_SCOPE, 'start_conference_button'])}
        </div>
      );
    }
  }

  return (
    <Observer>
      {() => (
        <div
          className={`ConferencesListItem ${styles.ConferencesListItem} ${
            isSelect ? styles.isSelect : ''
          } ${className}`}
          onClick={onPress ? onPressCb : undefined}
        >
          <div className={styles.contentWrapper}>
            <div
              className={`${styles.iconContainer} ${styles.iconContainerConference}`}
            >
              <CameraIcon width={22} height={22} />

              {isStarted ? (
                <div className={styles.liveLabelContainer}>
                  {t([I18N_SCOPE, 'live_label'])}
                </div>
              ) : null}
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.contentWrapper}>
                <div className={styles.flex1}>
                  <div className={styles.title}>{conferenceTitle}</div>
                  {showCourseName && conference.course ? (
                    <div className={styles.text}>
                      {conference.course && conference.course.program
                        ? conference.course.program.title
                        : t([I18N_SCOPE_WORKSPACE, 'not_found_label'])}
                      {participantName ? ` (${participantName})` : ''}
                    </div>
                  ) : showCourseName && isClientConference ? (
                    <div className={styles.text}>{t('label.intro_call')}</div>
                  ) : null}
                  {conference.course ? (
                    <div className={styles.courseDuration}>
                      {getCourseDurationString(conference.course)}
                    </div>
                  ) : null}
                </div>
                <div className={styles.statusContainer}>
                  {!isStarted && !isExpired && !isEnded ? (
                    <div className={styles.time}>
                      {startDate}
                      <br />
                      {'-'}
                      <br />
                      {endDate}
                    </div>
                  ) : null}
                  {isExpired || isEnded ? (
                    <div className={styles.statusStyle}>{status}</div>
                  ) : null}
                </div>
                {onMoreButtonPress ? (
                  <div className={styles.moreButt} onClick={onMorePressCb}>
                    ⋮
                  </div>
                ) : null}
              </div>
              {conferenceActionButton}
            </div>
          </div>
          {showDescription && conference.description ? (
            <div className={styles.subContentContainer}>
              {conference.description}
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(ConferencesListItem);
