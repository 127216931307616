import type {FC} from 'react';
import React from 'react';

import ProgramSVG from '@yourcoach/shared/assets/icons/program.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
  viewBox?: string;
}

const IconProgram: FC<Props> = ({
  height = '100%',
  width = '100%',
  viewBox = '5 5 18 22',
  customClass = '',
}) => {
  return (
    <ProgramSVG
      className={`${customClass}`}
      width={height}
      height={width}
      fill="#819AA9"
      viewBox={viewBox}
    />
  );
};

export default IconProgram;
