import React, {useCallback, useMemo} from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {t} from 'i18n-js';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getConferenceAbleToStartDate} from '@yourcoach/shared/api/conference';
import type {Course} from '@yourcoach/shared/api/course';
import type {Task} from '@yourcoach/shared/api/task';

import LeftArrow from '@src/assets/img/left-arrow.svg';
import LiveSessionIcon from '@src/assets/img/LiveSessionIcon.svg';
import RightArrow from '@src/assets/img/right-arrow.svg';
import TaskIcon from '@src/assets/img/tasks-redesigned-icon.svg';
import VideoXSIcon from '@src/assets/img/VideoXSIcon.svg';
import Button from '@src/components/Button';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import TasksMenu from '@src/pages/NewLayout/components/TasksMenu';

import useTasksTab from '../hooks/useTasksTab';
import styles from '../styles/stylesTasks.module.scss';

import SingleDatePicker from './SingleDatePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

type ControlsWrapper = {
  course: Course;
  selectedDate: Date;
  setSelectedDate: (arg0: Date) => void;
  setSelectedDateChanged: () => void;
  showCalendarCallback: () => void;
};

const StartConferenceButton = ({
  onStartConferenceCallback,
  disabledToStart,
}) => {
  const I18N_SCOPE = 'ConferencesListItem';

  return (
    <Button
      className={styles.StartConferenceButton}
      onClick={onStartConferenceCallback}
      disabled={disabledToStart}
    >
      <VideoXSIcon className={styles.PlusIcon} />
      {t([I18N_SCOPE, 'start_conference_button'])}
    </Button>
  );
};

const ControlsWrapper: React.FC<ControlsWrapper> = ({
  course,
  selectedDate,
  setSelectedDate,
  setSelectedDateChanged,
  showCalendarCallback,
}) => {
  return (
    <div className={styles.ControlsWrapper}>
      <div className={styles.DateSwitcherWrapper}>
        <LeftArrow
          onClick={() => {
            setSelectedDate(
              dayjs(selectedDate.toISOString()).add(-1, 'd').toDate(),
            );
            setSelectedDateChanged();
          }}
          className={styles.LeftArrow}
        />
        <RightArrow
          onClick={() => {
            setSelectedDate(dayjs(selectedDate).add(1, 'd').toDate());
            setSelectedDateChanged();
          }}
          className={styles.RightArrow}
        />
        <div className={styles.DateWrapper}>
          <div onClick={() => showCalendarCallback()} className={styles.Date}>
            {dayjs(selectedDate).format('MMM D, ddd')}
          </div>
          <div className={styles.Timezone}>{dayjs().format('z')}</div>
        </div>
      </div>
      <div>
        {/* @ts-ignore */}
        <TasksMenu course={course} selectedDate={selectedDate.toISOString()} />
      </div>
    </div>
  );
};

type TasksList = {
  tasks: Array<Task>;
  onStartConference: (conferenceId: string) => void;
};

const TaskItem = ({id, title, start_date, end_date, onStartConference}) => {
  const ableToStartDate = useMemo(
    () =>
      dayjs(datetimeObjToISOString(getConferenceAbleToStartDate(start_date))),
    [start_date],
  );

  const conference = id.split(':')[0] === 'conference';
  const onStartConferenceCallback = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      onStartConference?.(id);
    },
    [onStartConference, id],
  );

  return (
    <div className={styles.Task}>
      <div className={styles.TaskIconAndDataContainer}>
        {conference ? (
          <LiveSessionIcon className={styles.TaskIcon} />
        ) : (
          <TaskIcon className={styles.TaskIcon} />
        )}
        <div className={styles.TitleAndTimeTaskContainer}>
          {conference
            ? `${dayjs(datetimeObjToISOString(start_date)).format(
                'H:mm',
              )} - ${dayjs(datetimeObjToISOString(start_date))
                .add(15, 'm')
                .format('H:mm A')}`
            : `${dayjs(datetimeObjToISOString(start_date)).format(
                'H:mm',
              )} - ${dayjs(datetimeObjToISOString(end_date)).format('H:mm A')}`}
          <div className={styles.TaskTitle}>{title}</div>
        </div>
      </div>
      {conference && (
        <StartConferenceButton
          onStartConferenceCallback={onStartConferenceCallback}
          disabledToStart={!(dayjs() >= ableToStartDate)}
        />
      )}
    </div>
  );
};

const TasksItemsList: React.FC<TasksList> = ({tasks, onStartConference}) => {
  return (
    <>
      {tasks?.length ? (
        tasks.map(task => (
          <TaskItem
            key={task.uuid}
            id={task._id}
            title={task.title}
            start_date={task.start_date}
            end_date={task.end_date}
            onStartConference={onStartConference}
          />
        ))
      ) : (
        <NoResults text="No tasks yet" />
      )}
    </>
  );
};

const TasksRedesign = ({course}) => {
  const {
    isShowCalendar,
    selectedDate,
    tasksLoaded,
    userTasks,
    handleOnStartConferenceButtonPress,
    setIsSelectedDateChanged,
    setIsShowCalendar,
    setSelectedDate,
  } = useTasksTab(course);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.TasksWrapper}>
        <ControlsWrapper
          course={course}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setSelectedDateChanged={() => setIsSelectedDateChanged(true)}
          showCalendarCallback={() => setIsShowCalendar(!isShowCalendar)}
        />
        {isShowCalendar && (
          <SingleDatePicker
            height={'364'}
            width={'364'}
            marginRight={'384'}
            marginBottom={'148'}
            selectedDate={selectedDate}
            onChange={(date: Date) => {
              setSelectedDate(date);
              setIsSelectedDateChanged(true);
              setIsShowCalendar(false);
            }}
          />
        )}
        <div className={styles.ScrollableArea}>
          <div className={styles.TasksContainer}>
            {tasksLoaded ? (
              <TasksItemsList
                tasks={userTasks}
                onStartConference={handleOnStartConferenceButtonPress}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksRedesign;
