import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import * as cardValidator from 'card-validator';
import classNames from 'classnames';
import {action, reaction} from 'mobx';

import {getCardBrandImage} from '@yourcoach/shared/api/payment/card';
import Lines from '@yourcoach/shared/assets/icons/lines-card.svg';
import {applyMask} from '@yourcoach/shared/utils/format';
import {logger} from '@yourcoach/shared/utils/logger';
import {validateFields} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import styles from './AddCard.module.css';

const DEFAULT_CARD = {
  number: {
    mask: '.... .... .... ....',
  },
  cvv: {
    placeholder: 'CVV',
    mask: '...',
  },
};

export const I18N_SCOPE = 'AddCard';

export interface Props {}

const AddCard: React.FC<Props> = () => {
  const {
    stores: {cardStore},
  } = useContext(AppContext);

  const expDateRef = useRef('');
  const [expDateError, setExpDateError] = useState('');
  const [brand, setBrand] = useState('');

  const brandImageSrc = useMemo(() => getCardBrandImage(brand, true), [brand]);

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const fields = useRef({
    number: createHtmlInputField('number', {
      extraFields: {
        mask: '.... .... .... ....',
      },
      customValidate: action(() => {
        const field = fields.number;

        field.value = applyMask(
          (field.value.match(/\d/g) || []).join(''),
          field.extraFields!.mask,
        );

        const validation = cardValidator.number(field.value);

        if (validation.card) {
          setBrand(validation.card.type);

          const mask = Array(Math.max(...validation.card.lengths)).fill('.');

          validation.card.gaps.forEach((gapIndex, i) => {
            mask.splice(gapIndex + i, 0, ' ');
          });

          field.extraFields!.mask = mask.join('');

          fields.cvv.extraFields!.placeholder = validation.card.code.name;
          fields.cvv.extraFields!.mask = Array(validation.card.code.size)
            .fill('.')
            .join('');
        } else {
          setBrand('');

          field.extraFields!.mask = DEFAULT_CARD.number.mask;

          fields.cvv.extraFields!.placeholder = DEFAULT_CARD.cvv.placeholder;
          fields.cvv.extraFields!.mask = DEFAULT_CARD.cvv.mask;
        }

        if (!field.value) {
          field.error = t([I18N_SCOPE, 'card_number_required_error_message']);
          field.isValid = false;

          return false;
        }

        const isValid = validation.isPotentiallyValid || validation.isValid;

        field.error = !isValid
          ? t([I18N_SCOPE, 'card_number_not_valid_error_message'])
          : '';
        field.isValid = isValid;

        return isValid;
      }),
    }),
    expMonth: createHtmlInputField('expMonth', {
      extraFields: {
        mask: '..',
      },
      customValidate: action(() => {
        const field = fields.expMonth;

        field.value = applyMask(
          (field.value.match(/\d/g) || []).join(''),
          field.extraFields!.mask,
        );

        return true;
      }),
    }),
    expYear: createHtmlInputField('expYear', {
      extraFields: {
        mask: '..',
      },
      customValidate: action(() => {
        const field = fields.expYear;

        field.value = applyMask(
          (field.value.match(/\d/g) || []).join(''),
          field.extraFields!.mask,
        );

        return true;
      }),
    }),
    cvv: createHtmlInputField('cvv', {
      extraFields: {
        mask: '...',
        placeholder: 'CVV',
      },
      customValidate: action(() => {
        const field = fields.cvv;

        field.value = applyMask(
          (field.value.match(/\d/g) || []).join(''),
          field.extraFields!.mask,
        );

        const validation = cardValidator.cvv(
          field.value,
          field.extraFields!.mask.length,
        );

        if (!field.value) {
          field.error = t([I18N_SCOPE, 'security_code_required_error_message']);
          field.isValid = false;

          return false;
        }

        const isValid = validation.isPotentiallyValid || validation.isValid;

        field.error = !isValid
          ? t([I18N_SCOPE, 'security_code_not_valid_error_message'])
          : '';

        return isValid;
      }),
    }),
  }).current;

  useEffect(() => {
    if (fields.number.ref && fields.number.ref.current) {
      fields.number.ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateExpDate = useCallback((expDate: string) => {
    const validation = cardValidator.expirationDate(expDate);

    if (!expDate || expDate === '/') {
      setExpDateError(t([I18N_SCOPE, 'exp_date_required_error_message']));

      return false;
    }

    const isValid = validation.isPotentiallyValid || validation.isValid;

    setExpDateError(
      !isValid ? t([I18N_SCOPE, 'exp_date_not_valid_error_message']) : '',
    );

    return isValid;
  }, []);

  useEffect(() => {
    const dispose = reaction(
      () => `${fields.expMonth.value}/${fields.expYear.value}`,
      expDate => {
        expDateRef.current = expDate;

        validateExpDate(expDate);
      },
    );

    return () => {
      dispose();
    };
  }, [
    fields.expMonth.value,
    fields.expYear.value,
    fields.number,
    validateExpDate,
  ]);

  const validateCard = useCallback(() => {
    const fieldsAreValid = validateFields(fields);
    const expDateIsValid = validateExpDate(expDateRef.current);

    return fieldsAreValid && expDateIsValid;
  }, [fields, validateExpDate]);

  const createCard = useCallback(async () => {
    const cardData = {
      number: fields.number.value,
      exp_month: fields.expMonth.value,
      exp_year: fields.expYear.value,
      cvc: fields.cvv.value,
    };

    try {
      showOverlay();

      await cardStore.addCard({
        data: cardData,
        stripeKey: process.env.STRIPE_KEY!,
        returnUrl: window.location.href,
        handle3dSecure: (url: string) =>
          new Promise(resolve => {
            const stripeWindow: Window | null = window.open(url, '_blank');

            stripeWindow?.focus();

            const timerId = setInterval(() => {
              try {
                if (
                  stripeWindow?.location.href.startsWith(
                    process.env.URL as string,
                  )
                ) {
                  clearInterval(timerId);
                  stripeWindow?.close();
                  resolve();
                }
              } catch (error) {
                // do nothing
              }
            }, 250);
          }),
      });

      logger.event('card_added');

      hideOverlay();
    } catch (error) {
      hideOverlay();

      logger.error(error);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  }, [
    cardStore,
    fields.cvv.value,
    fields.expMonth.value,
    fields.expYear.value,
    fields.number.value,
    hideOverlay,
    showOverlay,
  ]);

  const onAddCardButtonClick = useCallback(() => {
    if (validateCard()) {
      createCard();
    }
  }, [createCard, validateCard]);

  return (
    <div className={classNames('AddCard', styles.Component)}>
      <div className={styles.cardsContainersWrapper}>
        <div className={styles.cardsContainers}>
          <div className={styles.cardFront}>
            <div className={styles.lines}>
              <Lines />
            </div>
            <div className={styles.bankLogoContainer} />
            <Observer>
              {() => (
                <div className={styles.inputContainer}>
                  {fields.number.error ? (
                    <div className={styles.errorInputBorder} />
                  ) : null}
                  <TextField
                    ref={fields.number.ref}
                    value={fields.number.value}
                    onChange={fields.number.onChange}
                    placeholder={t([I18N_SCOPE, 'card_number_label'])}
                    maxLength={
                      fields.number.extraFields!.mask.length || undefined
                    }
                  />
                  {fields.number.error ? (
                    <div className={styles.numberErrorContainer}>
                      <span className={styles.errorText}>
                        {fields.number.error}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
            </Observer>
            <div className={styles.expirationDateLabel}>
              {t([I18N_SCOPE, 'exp_date_label'])}
            </div>
            <div className={styles.expirationDateContainer}>
              <div className={styles.inputContainer}>
                {expDateError ? (
                  <div className={styles.errorInputBorder} />
                ) : null}
                <Observer>
                  {() => (
                    <TextField
                      className={styles.expirationDateInput}
                      ref={fields.expMonth.ref}
                      value={fields.expMonth.value}
                      placeholder="MM"
                      onChange={fields.expMonth.onChange}
                      maxLength={2}
                    />
                  )}
                </Observer>
              </div>
              <div className={styles.expirationDateSeparator} />
              <div className={styles.inputContainer}>
                {expDateError ? (
                  <div className={styles.errorInputBorder} />
                ) : null}
                <Observer>
                  {() => (
                    <TextField
                      className={styles.expirationDateInput}
                      ref={fields.expYear.ref}
                      value={fields.expYear.value}
                      placeholder="YY"
                      onChange={fields.expYear.onChange}
                      maxLength={2}
                    />
                  )}
                </Observer>
              </div>
              {brandImageSrc ? (
                <Image
                  src={brandImageSrc}
                  className={styles.cardBrand}
                  resizeMode="contain"
                />
              ) : null}
              {expDateError ? (
                <div className={styles.expDateErrorContainer}>
                  <span className={styles.errorText}>{expDateError}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.cardBack}>
            <div className={styles.line} />
            <div className={styles.securityCodeWrapper}>
              <div className={styles.securityCodeContainer}>
                <Observer>
                  {() => (
                    <>
                      <div className={styles.inputContainer}>
                        {fields.cvv.error ? (
                          <div className={styles.errorInputBorder} />
                        ) : null}
                        <TextField
                          ref={fields.cvv.ref}
                          value={fields.cvv.value}
                          placeholder={fields.cvv.extraFields!.placeholder}
                          onChange={fields.cvv.onChange}
                          maxLength={fields.cvv.extraFields!.mask.length}
                          type="password"
                        />
                      </div>
                      <div
                        className={classNames(
                          styles.securityCodeLabel,
                          fields.cvv.error && styles.cvvError,
                        )}
                      >
                        {fields.cvv.error
                          ? fields.cvv.error
                          : t([I18N_SCOPE, 'security_code_label'])}
                      </div>
                    </>
                  )}
                </Observer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionButtonsContainer}>
        <Button onClick={onAddCardButtonClick}>
          {t([I18N_SCOPE, 'add_button'])}
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(AddCard);
