import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Button} from '@yourcoach/shared/uikit/Button';
import {Image} from '@yourcoach/shared/uikit/Image';
import {Text} from '@yourcoach/shared/uikit/Text';

import ModalNew from '@src/components/ModalNew';

import ColorPicker from '../ColorPicker';

export const Modal = styled(ModalNew)`
  border-radius: 20px !important;
  width: 50vw !important;
  min-width: 400px !important;

  .body {
    padding: 0px;
    height: 50vh !important;
    min-height: 400px !important;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${getColor('background2')};
  padding: 0 20px 20px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled(Image)`
  width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 12px;
`;

export const UserName = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
`;

export const ProgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProgramTitle = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.41px;
  color: ${getColor('text3')};
`;

export const CourseDates = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.41px;
  color: ${getColor('text3')};
`;

export const Content = styled.div`
  margin: 20px 20px 0;
  flex: 1;
  position: relative;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  border: 1px solid ${getColor('border2')};
  border-radius: 20px;
  padding: 10px 10px 40px;
  outline: none;
`;

export const Error = styled(Text)`
  font-size: 14px;
  line-height: 19px;
  color: ${getColor('error')};
`;

const SColorPicker = styled(ColorPicker)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export {SColorPicker as ColorPicker};

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

export const SaveButton = styled(Button).attrs({scheme: 'primary'})`
  height: 48px;
  font-size: 15px;
  line-height: 20px;
  padding: 14px 33px;
`;
