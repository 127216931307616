import {t} from '@src/i18n';

const I18N_SCOPE = 'Common';

export const labelDelete = () => t([I18N_SCOPE, 'delete']);

export const labelDeleteAll = () => t([I18N_SCOPE, 'deleteAll']);

export const labelShare = () => t([I18N_SCOPE, 'Share']);

export const labelCopyLink = () => t([I18N_SCOPE, 'copyLink']);

export const labelLinkCopied = () => t([I18N_SCOPE, 'linkCopied']);

export const labelShareThisWith = () => t([I18N_SCOPE, 'Share_this_with']);

export const labelLinkCopiedMessage = () =>
  t([I18N_SCOPE, 'linkCopiedMessage']);

export const labelClear = () => t([I18N_SCOPE, 'clear']);

export const labelChange = () => t([I18N_SCOPE, 'change']);

export const labelEdit = () => t([I18N_SCOPE, 'edit']);

export const labelSave = () => t(['shared', 'button', 'save']);

export const labelCreate = () => t([I18N_SCOPE, 'Create']);

export const labelUpdate = () => t([I18N_SCOPE, 'Update']);

export const labelMove = () => t([I18N_SCOPE, 'move']);

export const labelBTNTakePhoto = () => t([I18N_SCOPE, 'btn', 'take_photo']);

export const labelBTNChoosePhoto = () => t([I18N_SCOPE, 'btn', 'choose_photo']);

export const labelOpen = () => t([I18N_SCOPE, 'Open']);

export const labelAttachDocument = () => t([I18N_SCOPE, 'AttachDocument']);

export const labelAttachDocumentLibrary = () =>
  t([I18N_SCOPE, 'AttachDocumentLibrary']);

export const labelNoResults = () => t([I18N_SCOPE, 'NoResults']);

export const labelDuplicate = () => t([I18N_SCOPE, 'duplicate']);

export const labelSetImage = () => t([I18N_SCOPE, 'SetImage']);

export const labelSend = () => t([I18N_SCOPE, 'send']);

export const labelBack = () => t([I18N_SCOPE, 'Back']);

export const labelContinue = () => t([I18N_SCOPE, 'Continue']);

export const labelDone = () => t([I18N_SCOPE, 'Done']);

export const labelUse = () => t([I18N_SCOPE, 'Use']);

export const labelDetails = () => t([I18N_SCOPE, 'Details']);

export const labelAddAll = () => t([I18N_SCOPE, 'AddAll']);

export const labelGender = () => t([I18N_SCOPE, 'Gender']);

export const labelAdd = () => t([I18N_SCOPE, 'Add']);

export const labelAddEmail = () => t([I18N_SCOPE, 'AddEmail']);

export const labelAddEmails = () => t([I18N_SCOPE, 'AddEmails']);

export const labelYouEnteredAnIncorrectEmail = () =>
  t([I18N_SCOPE, 'YouEnteredAnIncorrectEmail']);

export const labelYouDontHaveAnyClients = () =>
  t([I18N_SCOPE, 'YouDontHaveAnyClients']);

export const labelLibraryPathName = () => t([I18N_SCOPE, 'libraryPathName']);

export const labelSessionNotesPathName = () =>
  t([I18N_SCOPE, 'sessionNotesPathName']);

export const labelLibraryTitle = () => t([I18N_SCOPE, 'libraryTitle']);

export const labelMySquadsPathName = () =>
  t([I18N_SCOPE, 'myProgramsPathName']);

export const labelMySquadsTitle = () => t([I18N_SCOPE, 'myProgramsTitle']);

export const labelErrorOccurred = () => 'An error has occurred!';

export const labelEditPhoto = () => t([I18N_SCOPE, 'edit_photo']);

export const labelChoose = () => t([I18N_SCOPE, 'Choose']);

export const labelSelectCourse = () => t([I18N_SCOPE, 'SelectGroup']);

export const labelToolboxPathName = () => t([I18N_SCOPE, 'toolboxPathName']);

export const labelUserProfileTabPathName = () =>
  t([I18N_SCOPE, 'userProfileTabPathName']);

export const labelToolboxTitle = () => t([I18N_SCOPE, 'toolboxTitle']);

export const labelSessionNotesTitle = () =>
  t([I18N_SCOPE, 'sessionNotesTitle']);

export const labelSharedCourseStatus = status =>
  t(['shared', 'course', 'status', status]);

export const labelSharedYou = () => t(['shared', 'label', 'you']);

export const labelSharedYour = () => t(['shared', 'label', 'your']);

export const labelJoinedProgramsPathName = () =>
  t([I18N_SCOPE, 'joinedProgramsPathName']);

export const labelJoinedProgramsTitle = () =>
  t([I18N_SCOPE, 'joinedProgramsTitle']);

export const labelPurchaseHistoryPathName = () =>
  t([I18N_SCOPE, 'purchaseHistoryPathName']);

export const labelPurchaseHistoryTitle = () =>
  t([I18N_SCOPE, 'purchaseHistoryTitle']);
