import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import type {ApiRpcQuery, CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';

import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import type {ILink, TFileWithLink, TListDataItem} from '@src/models/library';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';
import ListFolderContainer from '../ListFolderContainer/ListFolderContainer';
import {getMediaLinksListObj} from '../ListFolders/ListFolderLibrary';
import styles from '../ListFolders/styles.module.scss';
import UserFile from '../ListFolders/UserFile/UserFile';

interface Props {}

const ListFoldersUploads: FC<Props> = () => {
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  useEffect(() => {
    const LIMIT = 300;
    const categories = ['image', 'document', 'url'];
    const links: CollectionStore<ILink> = createCollectionStore({
      method: 'media.files.list',
      params: {
        limit: LIMIT,
        sort: [['created', -1]],
        query: (
          [
            ['status', '==', 'ok'],
            ['categories', 'in', categories],
          ] as ApiRpcQuery[]
        ).filter(Boolean),
      },
    });
    const linksFolder: CollectionStore<ILink> = createCollectionStore(
      getMediaLinksListObj(null),
    );

    if (currentUserStore.user) {
      libraryStory!.setIsCouch(currentUserStore.user.roles.includes('coach'));
    }

    libraryStory!.getFoldersLinks(links);
    libraryStory!.getFoldersMainLinks(linksFolder);
    libraryStory!.updateFolderLinks(0);

    return () => {
      libraryStory?.foldersLinks?.clear();
      libraryStory?.foldersMainLinks?.clear();
      links.clear();
      linksFolder.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryStory!.folderID]);

  const handleOpen = (item: any) => {
    return () => {
      if (item.src.original.url) {
        const win = window.open(item.src.original.url, '_blank');

        win!.focus();
      }
    };
  };

  return (
    <Observer>
      {() => (
        <>
          {libraryStory!.data.length === 0 && !libraryStory?.loading && (
            <NoResults />
          )}
          <ListFolderContainer className={styles.topOffset}>
            {libraryStory!.data.map((item: TListDataItem) => {
              if ((item as IFile).type === 'url') {
                return (
                  <a
                    href={(item as IFile).repr}
                    key={item._id}
                    target="_blank"
                    rel="nofollow noopener"
                    className={styles.UserFile}
                  >
                    <UserFile
                      file={
                        {
                          ...(item as IFile),
                          link: {
                            ...item,
                            resource: null,
                          },
                        } as unknown as TFileWithLink
                      }
                    />
                  </a>
                );
              } else {
                return (
                  <a
                    key={item._id}
                    onClick={handleOpen(item)}
                    target="_blank"
                    rel="nofollow noopener"
                    className={styles.UserFile}
                  >
                    <UserFile
                      file={
                        {
                          ...(item as IFile),
                          link: {
                            ...item,
                            resource: null,
                          },
                        } as unknown as TFileWithLink
                      }
                    />
                  </a>
                );
              }
            })}
          </ListFolderContainer>
        </>
      )}
    </Observer>
  );
};

export default memo(ListFoldersUploads);
