import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as CRUGoalProps} from './CRUGoal';
import CRUGoal, {I18N_SCOPE} from './CRUGoal';

type Props = Partial<ModalProps> & CRUGoalProps;

const AddFileModal: React.FC<Props> = ({
  programId,
  courseId,
  goal,
  onSuccess,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, goal ? 'edit_title' : 'title'])}>
    <CRUGoal
      programId={programId}
      courseId={courseId}
      goal={goal}
      onSuccess={onSuccess}
    />
    {children}
  </Modal>
);

export default React.memo(AddFileModal);
