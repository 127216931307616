import type {FC} from 'react';
import React, {memo} from 'react';

import {MainContainerWithMenuColor} from '@src/layouts';
import {QTemplates} from '@src/modules/Questionnaire';

interface Props {}

const QuestionnaireTemplatesPage: FC<Props> = () => {
  return (
    <MainContainerWithMenuColor>
      <div className="QuestionnaireTemplatesPage">
        <QTemplates />
      </div>
    </MainContainerWithMenuColor>
  );
};

export default memo(QuestionnaireTemplatesPage);
