import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {t} from '@src/i18n';

import MyJoinedProgramsList from './MyJoinedProgramsList/MyJoinedProgramsList';

const I18N_SCOPE = 'WorkspaceJoinedCoursesTab';

import styles from './styles.module.css';

const MyJoinedPrograms: FC = () => {
  return (
    <>
      <Observer>
        {() => (
          <div className={`MyJoinedPrograms ${styles.MyJoinedPrograms}`}>
            <div className={styles.title}>
              {t([I18N_SCOPE, 'active_tab_label'])}
            </div>
            <MyJoinedProgramsList tabKey="active" />
          </div>
        )}
      </Observer>
      <Observer>
        {() => (
          <div className={`MyJoinedPrograms ${styles.MyJoinedPrograms}`}>
            <div className={styles.title}>
              {t([I18N_SCOPE, 'archived_tab_label'])}
            </div>
            <MyJoinedProgramsList tabKey="archived" />
          </div>
        )}
      </Observer>
    </>
  );
};

export default memo(MyJoinedPrograms);
