import type {FC} from 'react';
import React, {memo} from 'react';

import styles from './../styles.module.css';

interface Props {
  description: string | undefined;
}

const About: FC<Props> = ({description}) => {
  return (
    <div className={`About ${styles.About}`}>
      <div className={styles.description}>{description && description}</div>
    </div>
  );
};

export default memo(About);
