import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {
  labelByEmail,
  labelByName,
  labelSendQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';

import QSendUsersContext from '../context/QSendUsersContext';
import type {
  ILocalStoreQSendUsers,
  TItemMenu,
} from '../context/QSendUsersLocalStore';

import styles from './../styles.module.css';

interface Props {}

const HeaderQSendUsers: FC<Props> = () => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);
  const itemMenuNames: {type: TItemMenu; name: string}[] = [
    {type: 'byName', name: labelByName()},
    {type: 'byEmail', name: labelByEmail()},
  ];

  const handleOnClick = (type: TItemMenu) => {
    return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      storeQSendUsers.setCurrentItemMenu(type);
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`HeaderQSendUsers ${styles.HeaderQSendUsers}`}>
          <h2>{labelSendQuestionnaire()}</h2>
          <div className={styles.menuContainer}>
            <div className={styles.menu}>
              {itemMenuNames.map(({type, name}) => (
                <a
                  className={`customTopMenu ${styles.menuItem} ${
                    storeQSendUsers.currentItemMenu === type
                      ? `selected ${styles.selected}`
                      : ''
                  }`}
                  key={type}
                  onClick={handleOnClick(type)}
                >
                  {name}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(HeaderQSendUsers);
