import React from 'react';

import classNames from 'classnames';

import SearchIcon from '@yourcoach/shared/assets/icons/search.svg';

import styles from './TextField.module.css';

interface Props extends React.HTMLProps<HTMLInputElement> {
  id?: string;
  type?: string;
  label?: string;
  error?: string | null;
  variant?: 'search';
}

const TextField = React.forwardRef<HTMLInputElement, Props>(
  ({id, label, error, disabled, className, variant, ...rest}, ref) => {
    id = id || Math.random().toString(36).substring(2);

    return (
      <div
        className={classNames(
          'TextField',
          styles.TextField,
          disabled && styles.disabled,
          variant && styles[variant],
          className,
        )}
      >
        {variant === 'search' ? (
          <div className={styles.inputContainer}>
            <input {...rest} id={id} ref={ref} disabled={disabled} />
            <SearchIcon />
          </div>
        ) : (
          <>
            <input {...rest} id={id} ref={ref} disabled={disabled} />
            {label ? <label htmlFor={id}>{label}</label> : null}
            <div className={styles.error}>{error}</div>
          </>
        )}
      </div>
    );
  },
);

export default React.memo(TextField);
