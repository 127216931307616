export const ALIAS_TYPES: {
  text: 'long_text';
  singleChoice: 'single_choice';
  singleChoiceOpen: 'single_choice_open';
  multipleChoice: 'multiple_choice';
  multipleChoiceOpen: 'multiple_choice_open';
} = {
  text: 'long_text',
  singleChoice: 'single_choice',
  singleChoiceOpen: 'single_choice_open',
  multipleChoice: 'multiple_choice',
  multipleChoiceOpen: 'multiple_choice_open',
};
