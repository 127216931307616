import React, {useState} from 'react';

import {t} from '@src/i18n';

import {ProgramsList} from './ProgramsList';
import * as S from './styles';

const I18N_SCOPE = 'AddSessionNote.by_program_tab';

export const ByProgramTab: React.FC = () => {
  const [tab, setTab] = useState<'active' | 'archived'>('active');

  return (
    <S.Container>
      <S.ListHeader>{t([I18N_SCOPE, 'programs_label'])}</S.ListHeader>
      <S.Tabs>
        <S.TabButton
          active={tab === 'active'}
          onClick={() => {
            setTab('active');
          }}
        >
          {t([I18N_SCOPE, 'active_tab'])}
        </S.TabButton>
        <S.TabButton
          active={tab === 'archived'}
          onClick={() => {
            setTab('archived');
          }}
        >
          {t([I18N_SCOPE, 'archived_tab'])}
        </S.TabButton>
      </S.Tabs>
      <ProgramsList type={'active'} active={tab === 'active'} />
      <ProgramsList type={'archived'} active={tab === 'archived'} />
    </S.Container>
  );
};
