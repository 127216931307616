import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Img as Image} from 'react-image';
import {Link, useHistory} from 'react-router-dom';

import {getFileSrc} from '@yourcoach/shared/api/media/file';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import {IconSettings} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';

import type {Program} from '../useMySquads';

import styles from './../styles.module.css';

interface Props {
  program: Program;
}

const ProgramImage: FC<Props> = ({program}) => {
  const history = useHistory();

  const onSettingsIconClick: GetComponentProps<typeof Clickable>['onClick'] =
    useCallback(
      e => {
        e.event.DOMEvent?.stopPropagation();

        history.push({
          pathname: `/program/${program._id}`,
        });
      },
      [history, program._id],
    );

  return (
    <div className={`ProgramImage ${styles.ProgramImage}`}>
      <Link to="#">
        <Image
          src={getFileSrc(program.avatar, 250).url || ''}
          loader={<Loader height="55%" width="55%" />}
          unloader={
            <AvatarLoader
              title={program.title}
              isBGGradient
              directionGradient="180deg"
            />
          }
        />
        <OverlayImage />
      </Link>
      <Clickable onClick={onSettingsIconClick}>
        <IconSettings className={styles.IconSettings} />
      </Clickable>
      <div className={styles.titleProgram}>
        <Link to="#">{program.title}</Link>
      </div>
    </div>
  );
};

export default memo(ProgramImage);
