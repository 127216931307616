import type {FC, MouseEvent} from 'react';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {DropTarget} from 'react-drag-drop-container';
import {Link} from 'react-router-dom';

import {useLongPress} from 'use-long-press';

import SecondaryFolderIcon from '@yourcoach/shared/assets/icons/folder-secondary.svg';

import {labelDelete, labelEdit} from '@src/common/i18n/i18nCommon';
import DropdownMenu from '@src/components/DropdownMenu/DropdownMenu';
import {IconEdit, IconTrash} from '@src/components/icons';
import type {ILink} from '@src/models/library';
import {PATH_TYPE, slugify} from '@src/routes/utils';

import styles from '../styles.module.scss';

interface Props {
  data: ILink;
  className?: string;
  folderDelete?: () => void;
  folderEdit?: () => void;
  onDropFile?: (_id: string | null) => void;
}

const UserFolder: FC<Props> = ({
  className,
  data,
  folderDelete = () => {},
  folderEdit = () => {},
  onDropFile = (_id = '') => {},
}) => {
  const {_id, name} = data;
  const [timerId, setTimerId] = useState(0);

  let openDropDown: () => void;

  const handleGetOpenFunc = (fn: () => void) => {
    openDropDown = fn;
  };

  const handleOnRClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();
    callback();
  };

  const callback = useCallback(() => {
    openDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bindLongPress = useLongPress(callback);

  const handleDelete = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();

    folderDelete();
  };

  const handleEdit = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();

    folderEdit();
  };

  const handleOnClickLink = () => {};

  const handleDrop = e => {
    clearTimeout(timerId);
    setTimerId(
      // @ts-ignore
      setTimeout(() => {
        onDropFile(_id);
        e.containerElem.style.visibility = 'hidden';
      }, 100),
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`UserFolder ${styles.containerUserFolder}`}>
      <DropTarget
        targetKey="userFolder"
        dropData={{fId: _id}}
        onHit={handleDrop}
      >
        <div
          className={className}
          onContextMenu={handleOnRClick}
          {...bindLongPress}
        >
          <Link
            to={`/${PATH_TYPE.yourSpace}/${PATH_TYPE.myLibrary}/${slugify(
              name,
            )}?id=${_id}`}
            onClick={handleOnClickLink}
          >
            <div className={styles.link}>
              <div className={styles.iconContainer}>
                <SecondaryFolderIcon />
              </div>
              <div className={styles.folderName}>
                <span>{name}</span>
              </div>
            </div>
          </Link>
        </div>
      </DropTarget>
      <DropdownMenu
        getOpenFunc={handleGetOpenFunc}
        className={styles.DropdownMenu}
      >
        <div className={styles.linkDropdownMenu} onClick={handleDelete}>
          <div>
            <span>{labelDelete()}</span>
          </div>
          <IconTrash customClass={styles.iconDropMenu} />
        </div>
        <div className={styles.linkDropdownMenu} onClick={handleEdit}>
          <div>
            <span>{labelEdit()}</span>
          </div>
          <IconEdit customClass={styles.iconDropMenu} />
        </div>
      </DropdownMenu>
    </div>
  );
};

export default memo(UserFolder);
