import type {FC} from 'react';
import React, {memo, useEffect, useMemo} from 'react';
import {contextMenu} from 'react-contexify';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore, DateTimeObj} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import {courseIsEnded} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Membership} from '@yourcoach/shared/api/membership';
import {sessionNoteStore} from '@yourcoach/shared/api/sessionNote';
import type {User} from '@yourcoach/shared/api/user';
import {currentUserStore} from '@yourcoach/shared/api/user';
import NoteIcon from '@yourcoach/shared/assets/icons/secondary/Edit.svg';
import {Button} from '@yourcoach/shared/uikit/Button';
import {logger} from '@yourcoach/shared/utils/logger';

import {
  labelClientListFrozenMsg,
  labelCourseClientsTabNoResults,
} from '@src/common/i18n/i18nCourse';
import {IconFreeze, IconProfile} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import {t} from '@src/i18n';
import {CRUSessionNoteModal} from '@src/modules/MyNotes/CRUSessionNoteModal';
import {NoteButton} from '@src/modules/MyNotes/SessionNotesListItem/styles';
import {SessionNotesModal} from '@src/modules/MyNotes/SessionNotesModal';

import {emitter} from '../../../../../../widget/src/utils';
import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';

import styles from './../../styles.module.scss';

export type MembershipT = Membership & {
  course: CourseT;
  user: Partial<User> &
    Required<Pick<User, 'name'>> & {
      avatar?: IFile | null;
    };
};

interface ILocalStore {
  course?: CourseT;
  membershipsStore?: CollectionStore<MembershipT> | null;
  shouldRenderStub?: boolean;
  isFetching?: boolean;
  currentMembership?: MembershipT | null;
  data?: MembershipT[];
  fetchData(silent?: boolean): Promise<void>;
  isLoading?: boolean;
  showNotesDialog?: boolean;
  setIsLoading(isLoading: boolean): void;
  setCourse(courseVal: CourseT): void;
  setMembershipsStore(
    membershipsStore: CollectionStore<MembershipT> | null,
  ): void;
  setCurrentMembership(currentMembership: MembershipT | null): void;
  setShowNotesDialog(value: boolean): void;
}

interface Props {
  course: CourseT;
  handleShowUserModal: () => void;
  onRefetchCourse: () => Promise<void>;
}

const CourseClientsTab: FC<Props> = ({
  course,
  handleShowUserModal,
  onRefetchCourse,
}) => {
  const LIMIT = 999;
  const menuId = 'CourseClientsTab';
  /* @ts-ignore */
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          course,
          setCourse(courseVal: CourseT) {
            this.course = courseVal;
          },
          membershipsStore: null,
          setMembershipsStore(
            membershipsStore: CollectionStore<MembershipT> | null,
          ) {
            this.membershipsStore = membershipsStore;
          },
          currentMembership: null,
          setCurrentMembership(currentMembership: MembershipT | null) {
            this.currentMembership = currentMembership;
          },
          isLoading: false,
          setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
          },
          async fetchData(silent = false) {
            try {
              if (!silent) {
                this.setIsLoading(false);
              }

              await this.membershipsStore.fetch(
                {
                  limit: Math.max(this.membershipsStore.items.length, LIMIT),
                },
                {silent},
              );
              this.setIsLoading(true);
            } catch (error) {
              return error.message;
            }
          },
          get shouldRenderStub() {
            if (this.membershipsStore) {
              return (
                (this.membershipsStore as CollectionStore<MembershipT>)
                  .isLoaded &&
                !(this.membershipsStore as CollectionStore<MembershipT>)
                  .hasItems
              );
            } else {
              return true;
            }
          },
          get isFetching() {
            if (this.membershipsStore) {
              return (
                !(this.membershipsStore as CollectionStore<MembershipT>)
                  .isLoaded ||
                (this.membershipsStore as CollectionStore<MembershipT>)
                  .isFetching
              );
            } else {
              return true;
            }
          },
          get data() {
            if (this.membershipsStore) {
              return (
                this.membershipsStore as CollectionStore<MembershipT>
              ).items
                .slice()
                .map(membership => ({
                  ...membership,
                  course: course,
                }));
            } else {
              return [];
            }
          },
        },
        {
          course: observable,
          isLoading: observable,
          membershipsStore: observable,
          currentMembership: observable,
          fetchData: action,
          setCourse: action,
          setIsLoading: action,
          setCurrentMembership: action,
          setMembershipsStore: action,
          shouldRenderStub: computed,
          isFetching: computed,
          data: computed,
        } as any,
      ),
    [course],
  );

  useEffect(() => {
    localStore.setMembershipsStore(
      createCollectionStore({
        method: 'coach.memberships.list',
        params: {
          limit: LIMIT,
          sort: [['created', -1]],
          query: [
            ['course_id', '==', course._id],
            ['user_id', '!in', course.coach_ids],
            [
              'status',
              '!in',
              ['requested', 'proposed'] as MembershipT['status'][],
            ],
          ],
          expand: {
            membership: [
              [
                'user_id',
                {name: 'User not found'},
                {
                  user: ['avatar_id'],
                },
              ],
            ],
          },
        },
        withCount: true,
      }),
    );

    localStore.fetchData();

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      localStore.membershipsStore!.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    const disposers = [
      reaction(
        () => sessionNoteStore.creating,
        creating => {
          if (creating.success && creating.entity) {
            const membership = localStore
              .membershipsStore!.items.slice()
              .find(
                item =>
                  item.course_id === creating.entity!.course_id &&
                  item.user_id === creating.entity!.client_id,
              );

            if (membership) {
              const counters = membership.counters;

              if (!counters.session_notes[currentUserStore.user!._id]) {
                counters.session_notes[currentUserStore.user!._id] = 1;
              } else {
                counters.session_notes[currentUserStore.user!._id]++;
              }

              localStore.membershipsStore!.updateItem(membership, {
                counters,
              });
            }
          }
        },
      ),
      reaction(
        () => sessionNoteStore.deleting,
        deleting => {
          if (deleting.success && deleting.entity) {
            const membership = localStore
              .membershipsStore!.items.slice()
              .find(
                item =>
                  item.course_id === deleting.entity!.course_id &&
                  item.user_id === deleting.entity!.client_id,
              );

            if (membership) {
              const counters = membership.counters;

              counters.session_notes[currentUserStore.user!._id]--;

              localStore.membershipsStore!.updateItem(membership, {
                counters,
              });
            }
          }
        },
      ),
    ];

    return () => {
      disposers.forEach(dispose => dispose());
    };
  }, [localStore]);

  const handleOnClickFreeze = (membership: MembershipT) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      localStore.setCurrentMembership(membership);
      event.stopPropagation();
      event.preventDefault();

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          membership,
        },
      });
    };
  };

  const _handleWsMessage = msg => {
    if (
      msg.event.type.includes('membership') &&
      msg.membership.course_id === course._id
    ) {
      localStore.fetchData(true);
    }
  };

  const handleClickMembership = (membership: MembershipT) => {
    return () => {
      _onMembershipPress(membership);
    };
  };

  const _onMembershipPress = (membership: MembershipT) => {
    if (membership.user._id) {
      // TODO: navigate to user profile
    }
  };

  const endIn5days = (courseEndDate: DateTimeObj) => {
    return dayjs().diff(datetimeObjToISOString(courseEndDate), 'day');
  };

  const daysRemainingToCourseEnd = endIn5days(course.end_date!);

  const handleClickProlong = async (courseValue: CourseT) => {
    try {
      await apiRequest({
        // @ts-ignore will be fixed in 1.5
        method: 'coach.courses.extend',
        params: {
          _id: courseValue._id,
        },
      });

      await onRefetchCourse();

      await localStore.fetchData();
    } catch (error) {
      logger.error(error);
    }
  };

  const findNotesCount = (coach_ids: string[], membership: Membership) => {
    return coach_ids
      .map(user => membership.counters.session_notes[user])
      .reduce((a, b) => a + b, 0);
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.shouldRenderStub ? (
            <NoResults text={labelCourseClientsTabNoResults()}>
              <div className={styles.iconNoResultContainer}>
                <IconProfile
                  className={styles.iconNoResult}
                  viewBox="0 0 24 24"
                />
              </div>
            </NoResults>
          ) : (
            <>
              {!localStore.isLoading ? (
                <Loader />
              ) : (
                <div
                  className={`CourseClientsTab
                  ${styles.CourseClientsTab}`}
                >
                  {localStore.data!.map(membership => {
                    return (
                      <div
                        className={styles.userContainerLine}
                        key={membership._id}
                        onClick={handleClickMembership(membership)}
                      >
                        {membership.status === 'frozen' ? (
                          <div className={styles.userContainer}>
                            <div className={styles.userAvatar}>
                              {
                                <OtherUserProfileImg
                                  avatar={membership.user.avatar}
                                  profileClassName={styles.userAvatarFreeze}
                                  title={membership.user_name}
                                >
                                  <div
                                    className={styles.freezeUserIconContainer}
                                  >
                                    <div className={styles.freezeUserIcon}>
                                      <IconFreeze />
                                    </div>
                                  </div>
                                </OtherUserProfileImg>
                              }
                            </div>
                            <div
                              className={`${styles.userName} ${styles.userNameFreeze}`}
                            >
                              {membership.user_name}
                            </div>
                            <div className={styles.frozenBy}>
                              {labelClientListFrozenMsg(membership.frozen_by!)}
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={handleShowUserModal}
                            className={styles.userContainer}
                          >
                            <div className={styles.userAvatar}>
                              {
                                <OtherUserProfileImg
                                  avatar={membership.user.avatar}
                                  title={membership.user_name}
                                />
                              }
                            </div>
                            <div className={styles.userName}>
                              {membership.user_name}
                            </div>
                          </div>
                        )}
                        {(() => {
                          const count = findNotesCount(
                            membership.counters.session_notes.coach_ids,
                            membership,
                          );

                          return (
                            <NoteButton
                              onClick={() => {
                                const props = {
                                  userId: membership.user_id,
                                  courseId: membership.course_id,
                                  user: membership.user,
                                  course: membership.course,
                                  program: membership.course.program,
                                };

                                NiceModal.show(
                                  count
                                    ? SessionNotesModal
                                    : CRUSessionNoteModal,
                                  props,
                                );
                              }}
                            >
                              {count ? <NoteIcon /> : null}
                              {count
                                ? t(['shared.session_notes.see_notes_label'], {
                                    count,
                                  })
                                : t('shared.session_notes.add_note_button')}
                            </NoteButton>
                          );
                        })()}
                        {membership.course.status === 'ongoing' &&
                        membership.frozen_by !== 'delete_profile' &&
                        membership.frozen_by !== 'admin' ? (
                          <div className={styles.actionButtonsWrapper}>
                            <div
                              className={styles.dotMenu}
                              onClick={handleOnClickFreeze(membership)}
                            >
                              ⋮
                            </div>
                          </div>
                        ) : null}

                        {(daysRemainingToCourseEnd >= -5 ||
                          membership.course.status === 'stopping' ||
                          courseIsEnded(course)) &&
                        membership.frozen_by !== 'delete_profile' &&
                        membership.frozen_by !== 'admin' ? (
                          <div className={styles.actionButtonsWrapper}>
                            <Button
                              /* @ts-ignore */
                              className={styles.prolongButton}
                              onClick={() =>
                                handleClickProlong(membership.course)
                              }
                            >
                              Extend
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(CourseClientsTab);
