import styled from 'styled-components';

import {DEFAULT_NOTE_COLOR} from '@yourcoach/shared/api/sessionNote';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {Text} from '@yourcoach/shared/uikit/Text';

export const Container = styled.div<{color: string}>`
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: ${p =>
    p.theme.color.v2.note[p.color || DEFAULT_NOTE_COLOR] ||
    p.theme.color.v2.note[DEFAULT_NOTE_COLOR]};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Date = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.41px;
  color: ${getColor('text3')};
`;

export const MoreButton = styled(Clickable)`
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    fill: ${getColor('icon3')};
  }
`;

export const Body = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.41px;
`;
