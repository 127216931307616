import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {Observer} from 'mobx-react';

import classNames from 'classnames';

import type {Course as ICourse} from '@yourcoach/shared/api/course';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Membership as IMembership} from '@yourcoach/shared/api/membership';
import type {Card} from '@yourcoach/shared/api/payment/card';
import {getCardBrandImage} from '@yourcoach/shared/api/payment/card';
import type {
  Edition,
  Edition as IEdition,
  Program as IProgram,
} from '@yourcoach/shared/api/program';
import {
  parsePaymentPlan,
  ProgramPaymentTypeEnum,
} from '@yourcoach/shared/api/program';
import joinProgram from '@yourcoach/shared/api/program/utils/joinProgram';
import ArrowNextIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';
import CloseIcon from '@yourcoach/shared/assets/icons/close.svg';
import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import {findLastIndex, waitForAsync} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import {MODAL_STICKY_FOOTER_CLASS_NAME} from '@src/components/ModalNew';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import DocumentViewerModal from '@src/modules/library/DocumentViewerModal';
import PaymentMethodsModal from '@src/modules/payments/PaymentMethodsModal';
import UserPrivateProfileModal from '@src/modules/users/UserPrivateProfileModal';

import useAvailableCoursesToJoin from './hooks/useAvailableCoursesToJoin';
import useProposedMembership from './hooks/useProposedMembership';
import styles from './JoinProgram.module.css';
import SignContractModal from './SignContractModal';

const SING_CANCELED = 'sign_canceled';

export const I18N_SCOPE = 'JoinProgram';

type Program = IProgram & {
  editions: (IEdition & {
    contract?: IFile | null;
  })[];
};

export interface Props {
  program: Program;
  onSuccess?: (membership: IMembership & {course: ICourse | null}) => void;
}

const getLastEdition = (program: Program) => {
  const index = findLastIndex(
    program.editions,
    edition => edition!.status === 'active',
  );

  return program.editions[index]!;
};

const JoinProgram: React.FC<Props> = ({program, onSuccess}) => {
  const {
    stores: {cardStore, currentUserStore},
  } = useContext(AppContext);

  const [isJoining, setIsJoining] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<Card>();
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const [contractModalIsOpen, showContractModal, hideContractModal] =
    useIsVisible();
  const [
    signContractModalIsOpen,
    showSignContractModal,
    hideSignContractModal,
  ] = useIsVisible();
  const [
    paymentMethodsModalIsOpen,
    showPaymentMethodsModal,
    hidePaymentMethodsModal,
  ] = useIsVisible();
  const [
    userPrivateProfileModalIsOpen,
    showUserPrivateProfileModal,
    hideUserPrivateProfileModal,
  ] = useIsVisible();

  const signatureRef = useRef('');

  const {proposedMembership, isFetching: proposedMembershipIsFetching} =
    useProposedMembership(program);

  const {
    availableCourses,
    availableCoursesToJoin,
    isFetching: availableCoursesIsFetching,
  } = useAvailableCoursesToJoin(program);

  useEffect(() => {
    setIsFetching(proposedMembershipIsFetching || availableCoursesIsFetching);
  }, [availableCoursesIsFetching, proposedMembershipIsFetching]);

  useEffect(() => {
    if (proposedMembership && proposedMembership.card_id) {
      setPaymentMethod(cardStore.cards[proposedMembership.card_id]);
      setPaymentMethodError('');
    }
  }, [cardStore.cards, proposedMembership]);

  const lastEdition = useMemo(() => getLastEdition(program), [program]);

  const paymentPlan = useMemo(
    () => parsePaymentPlan(lastEdition.payment_plan),
    [lastEdition.payment_plan],
  );

  useEffect(() => {
    if (
      lastEdition.payment_plan &&
      lastEdition.payment_plan.processing !== 'external'
    ) {
      const cardsIds = Object.keys(cardStore.cards);

      if (cardsIds.length === 1) {
        setPaymentMethod(cardStore.cards[cardsIds[0]]);
        setPaymentMethodError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaymentMethodButtonClick = useCallback(() => {
    showPaymentMethodsModal();
  }, [showPaymentMethodsModal]);

  const onPaymentMethodSelect = useCallback(
    (chosenPaymentMethod: Card) => {
      hidePaymentMethodsModal();
      setPaymentMethod(chosenPaymentMethod);
      setPaymentMethodError('');
    },
    [hidePaymentMethodsModal],
  );

  const onContractButtonClick = useCallback(() => {
    showContractModal();
  }, [showContractModal]);

  const onHideContractModal = useCallback(() => {
    setIsJoining(false);
    hideContractModal();

    if (!signatureRef.current) {
      signatureRef.current = SING_CANCELED;
    }
  }, [hideContractModal]);

  const onSignContractButtonClick = useCallback(() => {
    showSignContractModal();
  }, [showSignContractModal]);

  const onSignContractModalClose = useCallback(() => {
    hideSignContractModal();
    hideContractModal();

    if (!signatureRef.current) {
      signatureRef.current = SING_CANCELED;
    }
  }, [hideContractModal, hideSignContractModal]);

  const onContractSigned = useCallback(
    (signature: string) => {
      hideSignContractModal();
      hideContractModal();

      signatureRef.current = signature;
    },
    [hideContractModal, hideSignContractModal],
  );

  const onPersonalInfoButtonClick = useCallback(() => {
    showUserPrivateProfileModal();
  }, [showUserPrivateProfileModal]);

  const onPersonalInfoFilled = useCallback(() => {
    hideUserPrivateProfileModal();
  }, [hideUserPrivateProfileModal]);

  const join = useCallback(async () => {
    const course = proposedMembership
      ? proposedMembership.course
      : availableCourses.length === 1 && availableCoursesToJoin.length
      ? availableCoursesToJoin[0]
      : undefined;

    let edition:
      | (Edition & {
          contract?: IFile | null;
        })
      | undefined;

    if (course) {
      edition = course.edition;
    } else {
      edition = lastEdition;
    }

    if (!edition) {
      return;
    }

    try {
      setIsJoining(true);

      let signature = proposedMembership ? proposedMembership.sign : '';

      if (!signature && edition.contract) {
        signatureRef.current = '';
        showContractModal();
        await waitForAsync(() => !!signatureRef.current);

        if (signatureRef.current === SING_CANCELED) {
          setIsJoining(false);

          return;
        }
      }

      signature = signatureRef.current || currentUserStore.user!.name;

      const signedMembership = await joinProgram({
        edition,
        course,
        signature,
        proposedMembership,
        paymentMethod,
      });

      setIsJoining(false);

      onSuccess && onSuccess(signedMembership);

      const joinEventParams = {
        is_free: edition.payment_plan === null ? 'yes' : 'no',
        coaches: edition.coach_ids.length,
        with_cocoaches: edition.coach_ids.length > 1 ? 'yes' : 'no',
        is_individual: edition.group_size === 1 ? 'yes' : 'no',
      };

      if (!signedMembership.course) {
        logger.event('join_program_request_sent', joinEventParams);
      } else {
        logger.event('program_joined', joinEventParams);
      }
    } catch (error) {
      setIsJoining(false);

      logger.error(error);

      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'join_error_message']),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  }, [
    showContractModal,
    availableCourses,
    availableCoursesToJoin,
    currentUserStore.user,
    lastEdition,
    onSuccess,
    paymentMethod,
    proposedMembership,
  ]);

  const onJoinProgramButtonClick = useCallback(async () => {
    if (
      lastEdition.payment_plan &&
      lastEdition.payment_plan.processing !== 'external' &&
      !paymentMethod
    ) {
      setPaymentMethodError(t([I18N_SCOPE, 'payment_method_error']));

      return;
    }

    join();
  }, [join, lastEdition.payment_plan, paymentMethod]);

  const renderProposedCourse = useCallback(() => {
    if (!proposedMembership) {
      return null;
    }

    const {course} = proposedMembership;

    let status: string;

    if (course.status === 'ongoing') {
      status = t(['shared', 'course', 'status', course.status]);
    } else {
      status = t([I18N_SCOPE, 'join_now_label']);
    }

    const shouldShowOldConditions =
      course.programLastEdition &&
      course.edition._id !== course.programLastEdition._id &&
      !isEqual(
        course.edition.payment_plan,
        course.programLastEdition.payment_plan,
      );

    let oldPrice: string | undefined;

    if (shouldShowOldConditions) {
      oldPrice = parsePaymentPlan(course.edition.payment_plan).toString();
    }

    return (
      <div className={classNames(styles.row, styles.noBorder)}>
        <div className={styles.title}>
          {t([I18N_SCOPE, 'proposed_course_label'])}
        </div>
        <div className={styles.courseWrapper}>
          <div className={styles.course}>
            <div className={styles.courseContent}>
              <div className={styles.courseDate}>
                {getCourseDurationString(course)}
              </div>
              <div className={styles.courseSecondRow}>
                {status ? (
                  <div className={styles.courseText}>{status}</div>
                ) : null}
              </div>
            </div>
            <div className={styles.courseText}>
              {`${course.counters.memberships.accepted}/${course.edition.group_size}`}
            </div>
          </div>
          {shouldShowOldConditions ? (
            <div className={styles.messageContainer}>
              <div className={styles.messageLine} />
              <div className={styles.message}>
                {t([I18N_SCOPE, 'old_conditions_label'], {
                  oldPrice: oldPrice!.toLowerCase(),
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }, [proposedMembership]);

  const renderHeader = useCallback(
    () => (
      <>
        {isFetching ? (
          <div className={styles.loadingSpinnerContainer}>
            <Loader size={50} />
            {t([I18N_SCOPE, 'checking_invites_label'])}
          </div>
        ) : null}
        {!isFetching && proposedMembership ? renderProposedCourse() : null}
      </>
    ),
    [isFetching, proposedMembership, renderProposedCourse],
  );

  const renderProgramInfo = useCallback(
    () => (
      <>
        <div className={styles.row}>
          <div className={styles.title}>{t([I18N_SCOPE, 'payment_label'])}</div>
          <div className={styles.text}>{paymentPlan.toString()}</div>
        </div>
        {paymentPlan.processing !== 'external' && paymentPlan.total ? (
          <div
            onClick={onPaymentMethodButtonClick}
            className={classNames(styles.row, styles.clickable)}
          >
            <div>
              <div
                className={classNames(
                  styles.title,
                  paymentMethodError && styles.errorTitle,
                )}
              >
                {t([I18N_SCOPE, 'payment_method_label'])}
              </div>
              <div className={styles.text}>
                {paymentMethod ? (
                  <div className={styles.cardContainer}>
                    <Image
                      src={getCardBrandImage(paymentMethod.card!.brand)}
                      className={styles.cardBrand}
                    />
                    <span>{`**** ${paymentMethod.card!.last4}`}</span>
                  </div>
                ) : (
                  <span className={styles.subText}>
                    {t([I18N_SCOPE, 'choose_payment_method_label'])}
                  </span>
                )}
              </div>
            </div>
            <ArrowNextIcon width={24} height={24} />
          </div>
        ) : null}
        {lastEdition.contract ? (
          <div
            onClick={onContractButtonClick}
            className={classNames(styles.row, styles.clickable)}
          >
            <div>
              <div className={styles.title}>
                {t([I18N_SCOPE, 'contract_label'])}
              </div>
              <div className={styles.text}>
                {t([
                  I18N_SCOPE,
                  'contract_agree_label',
                  paymentPlan.total ? 'purchasing' : 'joining',
                ])}
              </div>
            </div>
            <ArrowNextIcon width={24} height={24} />
          </div>
        ) : null}
        {paymentPlan.paymentType.id === ProgramPaymentTypeEnum.MONTHLY.id ? (
          <div className={styles.cancellationText}>
            {t([I18N_SCOPE, 'monthly_payments_description_label'])}
          </div>
        ) : null}
      </>
    ),
    [
      lastEdition.contract,
      onContractButtonClick,
      onPaymentMethodButtonClick,
      paymentMethod,
      paymentMethodError,
      paymentPlan,
    ],
  );

  const renderPersonalInfo = useCallback(() => {
    if (!lastEdition.private_data_request.length) {
      return null;
    }

    return (
      <>
        <div className={classNames(styles.row, styles.noBorder)}>
          <div className={styles.title}>
            {t([I18N_SCOPE, 'personal_info_label'])}
          </div>
        </div>
        <Observer>
          {() => (
            <div className={styles.personalInfoContainer}>
              {lastEdition.private_data_request.map(key => {
                const isFilled = currentUserStore.user!.private_profile[key];
                const Icon = isFilled ? CheckIcon : CloseIcon;

                return (
                  <div
                    key={key}
                    className={classNames(
                      styles.personalInfoRow,
                      isFilled && styles.filled,
                    )}
                  >
                    <div className={styles.personalInfoRowTitle}>
                      {t(`shared.private_profile.${key}`)}
                    </div>
                    <div className={styles.personalInfoRowLine}>
                      {'.'.repeat(1000)}
                    </div>
                    <Icon
                      width={24}
                      height={24}
                      className={styles.personalInfoRowIcon}
                    />
                  </div>
                );
              })}
              {lastEdition.private_data_request.some(
                key => !currentUserStore.user!.private_profile[key],
              ) ? (
                <div className={styles.personalInfoActionsContainer}>
                  <Button onClick={onPersonalInfoButtonClick}>
                    {t([I18N_SCOPE, 'fill_personal_info_button'])}
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </Observer>
      </>
    );
  }, [
    currentUserStore,
    lastEdition.private_data_request,
    onPersonalInfoButtonClick,
  ]);

  const renderFooter = useCallback(() => {
    let buttonText = t([I18N_SCOPE, 'join_button']);

    if (paymentPlan.total && paymentPlan.processing === 'internal') {
      buttonText = t([I18N_SCOPE, 'purchase_button']);
    }

    if (lastEdition.contract_id) {
      buttonText = t([I18N_SCOPE, 'sign_and_button'], {buttonText});
    }

    return (
      <div
        className={classNames(styles.footer, MODAL_STICKY_FOOTER_CLASS_NAME)}
      >
        <Button
          disabled={
            // isJoining ||
            (lastEdition.group_size > 1 && isFetching) ||
            (!!lastEdition.private_data_request.length &&
              lastEdition.private_data_request.some(
                key => !currentUserStore.user!.private_profile[key],
              ))
          }
          onClick={onJoinProgramButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    );
  }, [
    currentUserStore,
    isFetching,
    lastEdition.contract_id,
    lastEdition.group_size,
    lastEdition.private_data_request,
    onJoinProgramButtonClick,
    paymentPlan.processing,
    paymentPlan.total,
  ]);

  return (
    <div className={styles.JoinProgram}>
      {renderHeader()}
      {renderProgramInfo()}
      {renderPersonalInfo()}
      {renderFooter()}
      {lastEdition.contract ? (
        <>
          <DocumentViewerModal
            isOpen={contractModalIsOpen}
            onAfterClose={onHideContractModal}
            title={t([I18N_SCOPE, 'contract_viewer_title'])}
            document={lastEdition.contract}
          >
            {isJoining ? (
              <div className={styles.signContractModalFooter}>
                <Button onClick={onSignContractButtonClick}>
                  {t([I18N_SCOPE, 'sign_contract_button'])}
                </Button>
              </div>
            ) : null}
          </DocumentViewerModal>
          <SignContractModal
            isOpen={signContractModalIsOpen}
            onAfterClose={onSignContractModalClose}
            onSign={onContractSigned}
            title={t([I18N_SCOPE, 'sign_contract_title'])}
          />
        </>
      ) : null}
      <PaymentMethodsModal
        isOpen={paymentMethodsModalIsOpen}
        onAfterClose={hidePaymentMethodsModal}
        onSelect={onPaymentMethodSelect}
      />
      <UserPrivateProfileModal
        isOpen={userPrivateProfileModalIsOpen}
        onAfterClose={hideUserPrivateProfileModal}
        requiredFields={lastEdition.private_data_request}
        onSuccess={onPersonalInfoFilled}
      />
      {isJoining ? (
        <div className="overlay">
          <LoadingSpinner size={50} />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(JoinProgram);
