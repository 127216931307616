import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import BodyQSendUsers from './BodyQSendUsers/BodyQSendUsers';
import QSendUsersContext from './context/QSendUsersContext';
import type {ILocalStoreQSendUsers} from './context/QSendUsersLocalStore';
import QSendUsersState from './context/QSendUsersState';
import HeaderQSendUsers from './HeaderQSendUsers/HeaderQSendUsers';
import styles from './styles.module.css';

interface Props {
  idQ: string;
  closeModal: () => void;
}

const QSendUsers: FC<Props> = ({idQ, closeModal}) => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);

  useEffect(() => {
    storeQSendUsers.setCloseWindow(closeModal);
    storeQSendUsers.allNull();

    return () => {
      storeQSendUsers.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <QSendUsersState QId={idQ}>
          <div className={`QSendUsers ${styles.QSendUsers}`}>
            <HeaderQSendUsers />
            <BodyQSendUsers />
          </div>
        </QSendUsersState>
      )}
    </Observer>
  );
};

export default memo(QSendUsers);
