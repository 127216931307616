import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';

import classNames from 'classnames';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import ImageAddIcon from '@yourcoach/shared/assets/icons/img-add.svg';

import useIsVisible from '@src/hooks/useIsVisible';
import AddFileModal from '@src/modules/library/AddFileModal';

import Image from './Image';
import styles from './ImageSelector.module.css';

interface Props {
  file?: IFile | null;
  onUpload?: (file: IFile) => void;
  isDisabled?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

const ImageSelector: FC<Props> = ({
  file,
  onUpload,
  isDisabled,
  className,
  children,
}) => {
  const [isModalOpen, showModal, hideModal] = useIsVisible();

  const imageSrc = useMemo(() => getFileSrc(file, 250).url || '', [file]);

  const onUploadCb = useCallback(
    (files: IFile[]) => {
      hideModal();
      onUpload && onUpload(files[0]);
    },
    [hideModal, onUpload],
  );

  return (
    <>
      {children ? (
        React.Children.map(children, c =>
          React.cloneElement(c as React.ReactElement, {
            onClick: isDisabled ? undefined : showModal,
          }),
        )
      ) : (
        <div
          onClick={isDisabled ? undefined : showModal}
          className={classNames(
            styles.ImageSelector,
            isDisabled && styles.disabled,
            className,
          )}
        >
          <Image
            src={imageSrc}
            placeholder={
              <div className={styles.placeholder}>
                <ImageAddIcon />
              </div>
            }
          />
        </div>
      )}
      <AddFileModal
        isOpen={isModalOpen}
        onAfterClose={hideModal}
        onUpload={onUploadCb}
        fileType="image"
        mode="upload"
        multiple={false}
      />
    </>
  );
};

export default memo(ImageSelector);
