import {t} from '@src/i18n';

const I18N_SCOPE = 'UpdateProgramSchedulePopup';

export const labelUpdateProgramScheduleCreateEvent = () =>
  t([I18N_SCOPE, 'create_event']);

export const labelUpdateProgramSchedulePopupHeader = (targetType, actionType) =>
  t([I18N_SCOPE, 'header', targetType, actionType]);

export const labelUpdateProgramSchedulePopupDescription = (
  targetType,
  actionType,
  programTitle,
  groupType: 'individual' | 'group',
) =>
  t([I18N_SCOPE, 'description', targetType, actionType, groupType], {
    programTitle,
  });
