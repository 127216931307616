import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import {reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Certificate} from '@yourcoach/shared/api/certificate';

import {
  labelCertificatesAdd,
  labelCertificatesEdit,
  labelCertificatesNoItems,
} from '@src/common/i18n/i18nProfile';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Modal from '@src/components/ModalNew';
import AppContext from '@src/context/App';
import AddCertificate from '@src/modules/Certificates/AddCertificate';
import CertificatesList from '@src/modules/Certificates/CertificatesList';
import {certificateExpand} from '@src/modules/Certificates/ExpandedCertificate';
import styles from '@src/modules/EditCoachProfile/styles.module.css';

interface Props {
  userId?: string | null;
  isVertical?: boolean;
  children?: React.ReactNode;
  store?: CollectionStore<Certificate>;
}

const CertificatesListManager: FC<Props> = ({
  userId = null,
  isVertical = true,
  children,
  store: storeProp,
}) => {
  const activeCertificate = useRef<Certificate | null>(null);

  const {
    stores: {certificateStore, currentUserStore},
  } = useContext(AppContext);

  const certificatesStore = useRef(
    createCollectionStore<Certificate>({
      method:
        userId || !currentUserStore.user?.roles?.includes('coach')
          ? 'client.certificates.list'
          : 'coach.certificates.list',
      params: {
        query: [['user_id', '==', userId || currentUserStore.user?._id]],
        expand: certificateExpand,
      },
    }),
  ).current;

  const store = storeProp || certificatesStore;

  const [isShowModalAddCertificate, setIsShowModalAddCertificate] =
    useState(false);

  const showAddCertificate = useCallback(() => {
    setIsShowModalAddCertificate(true);
  }, []);

  const hideAddCertificate = useCallback(() => {
    setIsShowModalAddCertificate(false);
  }, []);

  const onStoreChange = useCallback(
    op => {
      if (op?.success) {
        hideAddCertificate();

        if (!storeProp) {
          store.fetch();
        }
      }
    },
    [hideAddCertificate, storeProp, store],
  );

  useEffect(() => {
    const disposers = [
      reaction(() => certificateStore.creating, onStoreChange),
      reaction(() => certificateStore.updating, onStoreChange),
      reaction(() => certificateStore.deleting, onStoreChange),
    ];

    return () => {
      disposers.forEach(disposer => disposer());
    };
  }, [certificateStore, onStoreChange]);

  useEffect(() => {
    const fetch = async () => {
      if (!storeProp) {
        await store.fetch();
      }
    };

    fetch();
  }, [store, storeProp]);

  useEffect(() => {
    return () => {
      store.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddClick = useCallback(() => {
    activeCertificate.current = null;
    showAddCertificate();
  }, [showAddCertificate]);

  const onEditClick = useCallback(
    (certificate: Certificate) => {
      activeCertificate.current = certificate;
      showAddCertificate();
    },
    [showAddCertificate],
  );

  const onDeleteClick = useCallback(
    async (certificate: Certificate) => {
      await getCustomConfirmAlert({
        message: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              if (storeProp) {
                storeProp.removeItem(certificate);
              } else {
                await certificateStore.delete(certificate!);
              }
            },
            type: 'confirm',
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    },
    [certificateStore, storeProp],
  );

  const onCertificateSuccess = (certificate: Certificate) => {
    if (store.findItem(certificate)) {
      store.updateItem(certificate, certificate);
    } else {
      store.addItem(certificate);
    }

    hideAddCertificate();
  };

  return (
    <>
      <Observer>
        {() => (
          <>
            {store.items.length > 0 ? null : (
              <div className={styles.NoItemsExplanation}>
                {labelCertificatesNoItems()}
              </div>
            )}
          </>
        )}
      </Observer>
      <CertificatesList
        items={store.items}
        isVertical={isVertical}
        isReadOnly={!!userId}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
      {React.Children.map(children, (child, index) =>
        index === 0
          ? React.cloneElement(child as React.ReactElement, {
              onClick: onAddClick,
            })
          : null,
      )}
      <Modal
        className={styles.modal}
        isOpen={isShowModalAddCertificate}
        onAfterClose={hideAddCertificate}
        title={
          activeCertificate.current
            ? labelCertificatesEdit()
            : labelCertificatesAdd()
        }
      >
        <AddCertificate
          certificate={activeCertificate.current}
          onSuccess={storeProp ? onCertificateSuccess : undefined}
        />
      </Modal>
    </>
  );
};

export default memo(CertificatesListManager);
