import type {ChangeEvent, FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Questionnaire as IQuestionnaire} from '@yourcoach/shared/api/questionnaire';

import {labelDetails, labelUse} from '@src/common/i18n/i18nCommon';
import {
  labelChooseTemplate,
  labelSearchForQuestionnaires,
  labelYouCanUseCompletedQuestionnaires,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton, CustomSearch} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import {PATH_TYPE} from '@src/routes/utils';

import questionnaireAppStore from '../context/qAppStore';

import styles from './styles.module.css';

interface Props {}

interface ILocalStore {
  loader: boolean;
  setLoader(val: boolean): void;
  query: string;
  setQuery: (newQuery: string) => void;
  questionnairesStore: CollectionStore<IQuestionnaire> | null;
  searchStore: CollectionStore<IQuestionnaire> | null;
  store: CollectionStore<IQuestionnaire> | null;
  storeParams: ApiRpcRequestParams;
  questionnaireList: IQuestionnaire[];
  getQuestFetch: () => void;
  searchTimeout?: number;
}

const QTemplateList: FC<Props> = () => {
  const LIMIT = 100;
  const redirect = useAppRedirect();
  const localStore = useRef(
    observable<ILocalStore>(
      {
        loader: false,
        setLoader(val: boolean) {
          this.loader = val;
        },
        searchTimeout: undefined,
        query: '',
        storeParams: {},
        store: null,
        setQuery(newQuery: string) {
          this.query = newQuery;
          this.storeParams = {query: [['is_public', '==', true]]};
          this.store = this.questionnairesStore;

          if (this.query.length >= 1) {
            this.storeParams.query.push([
              'sections.title:2,sections.description',
              'fulltext',
              `${this.query}*`,
            ]);
            this.store = this.searchStore;
          }

          this.getQuestFetch();
        },
        questionnairesStore: null,
        searchStore: null,
        questionnaireList: [],
        async getQuestFetch() {
          this.setLoader(true);

          try {
            await this.store.fetch(this.storeParams, {
              override: true,
            });

            this.questionnaireList = this.store.items.slice();
          } catch (error) {
            if (error.canceled) {
              return;
            }

            setError(error);
          }

          this.setLoader(false);
        },
      },
      {
        loader: observable,
        query: observable,
        searchTimeout: observable,
        storeParams: observable,
        questionnaireList: observable,
        questionnairesStore: observable,
        searchStore: observable,
        getQuestFetch: action,
        setQuery: action,
        setLoader: action,
        store: observable,
      },
    ),
  ).current;

  useEffect(() => {
    questionnaireAppStore?.delStandardTemplateQuest();
    localStore.questionnairesStore = createCollectionStore({
      method: 'coach.questionnaires.examples.list',
      params: {
        limit: LIMIT,
        sort: [['created', -1]],
      },
    });

    localStore.searchStore = createCollectionStore({
      method: 'coach.questionnaires.examples.search',
      params: {
        limit: LIMIT,
      },
    });

    localStore.setQuery('');

    return () => {
      if (localStore.questionnairesStore) {
        localStore.questionnairesStore.clear();
      }

      if (localStore.searchStore) {
        localStore.searchStore.clear();
      }

      if (localStore.store) {
        localStore.store.clear();
      }

      if (localStore.searchTimeout) {
        clearTimeout(localStore.searchTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickUse = (useQuest: IQuestionnaire) => {
    return () => {
      questionnaireAppStore?.setStandardTemplateQuest(useQuest);
      redirect(`/${PATH_TYPE.questionnaire}/create`);
    };
  };

  const handleOnClickDetails = (useQuest: IQuestionnaire) => {
    return () => {
      questionnaireAppStore?.setStandardTemplateQuest(useQuest);
      redirect(
        `/${PATH_TYPE.questionnaire}/${PATH_TYPE.standardTemplateDetail}/${PATH_TYPE.questions}`,
      );
    };
  };

  const handleOnSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;

    if (localStore.searchTimeout) {
      clearTimeout(localStore.searchTimeout);
    }

    runInAction(() => {
      localStore.searchTimeout = +setTimeout(() => {
        localStore.setQuery(val);
      }, 1000);
    });
  };

  return (
    <Observer>
      {() => (
        <div className={`QTemplateList ${styles.QTemplateList}`}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{labelChooseTemplate()}</div>
              <div className={styles.description}>
                {labelYouCanUseCompletedQuestionnaires()}
              </div>
            </div>
            <CustomSearch
              label={labelSearchForQuestionnaires()}
              id="searchFile"
              onChange={handleOnSearchChange}
              classContainer="searchContainer"
              classInput="input"
              className="search"
              classIcon="icon"
              classIconContainer="iconContainer"
            />
          </div>
          <div>
            {localStore.loader ? (
              <Loader />
            ) : (
              <div className={styles.listContainer}>
                {localStore.questionnaireList.length > 0 &&
                  localStore.questionnaireList.map(standardQuest => {
                    const {_id, title, sections} = standardQuest;

                    return (
                      <div key={_id} className={styles.listItem}>
                        <h3>{title}</h3>
                        <ul>
                          {sections.map(({title: sectionTitle}, index) => (
                            <li key={index}>
                              <span>{sectionTitle}</span>
                            </li>
                          ))}
                        </ul>
                        <div className={styles.buttContainer}>
                          <CustomButton
                            type="button"
                            classButton="blueButt"
                            onClick={handleOnClickUse(standardQuest)}
                          >
                            {labelUse()}
                          </CustomButton>
                          <CustomButton
                            type="button"
                            classButton="greyButt"
                            onClick={handleOnClickDetails(standardQuest)}
                          >
                            {labelDetails()}
                          </CustomButton>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(QTemplateList);
