import React, {useCallback, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import TextField from '@src/components/TextField';
import {t} from '@src/i18n';

import styles from './SignContract.module.css';

const I18N_SCOPE = 'SignContract';

const SIGN_MAX_LENGTH = 255;

export interface Props {
  onSign: (signature: string) => void;
}

const SignContract: React.FC<Props> = ({onSign}) => {
  const [signature, setSignature] = useState('');
  const [date, setDate] = useState<Date | null>(null);

  const fields = useRef({
    signature: createHtmlInputField('signature', {
      validationRule: {
        max: SIGN_MAX_LENGTH,
        type: 'string',
        messages: {
          required: t([I18N_SCOPE, 'sign_required']),
          stringMax: t([I18N_SCOPE, 'sign_max_length'], {
            maxLength: SIGN_MAX_LENGTH,
          }),
        },
      },
      defaultValue: '',
    }),
  }).current;

  const onSignButtonClick = useCallback(() => {
    if (signature) {
      onSign(signature);
    } else if (fields.signature.validate()) {
      setSignature(fields.signature.value);
      setDate(new Date());
    }
  }, [fields.signature, onSign, signature]);

  const onResignButtonClick = useCallback(() => {
    fields.signature.setValue('');
    fields.signature.error = null;
    setSignature('');
    setDate(null);
  }, [fields.signature]);

  return (
    <div className={classNames('SignContract', styles.Component)}>
      <div className={styles.container}>
        <div className={styles.header}>
          {t([I18N_SCOPE, signature ? 'signature_label' : 'name_label'])}
        </div>
        {signature ? (
          <div className={styles.signatureContainer}>
            <div className={styles.signatureHolder}>{signature}</div>
            <div className={styles.signatureName}>{signature}</div>
            <div className={styles.signatureDate}>
              {t([I18N_SCOPE, 'signature_date_label'], {
                date: dayjs(date).format('lll'),
              })}
            </div>
          </div>
        ) : (
          <Observer>
            {() => (
              <TextField
                placeholder={t([I18N_SCOPE, 'text_input_placeholder'])}
                value={fields.signature.value}
                onChange={fields.signature.onChange}
                error={fields.signature.error || ''}
              />
            )}
          </Observer>
        )}
      </div>
      <div className={styles.footer}>
        {signature ? (
          <Button onClick={onResignButtonClick}>
            {t([I18N_SCOPE, 'resign_button'])}
          </Button>
        ) : null}
        <Button onClick={onSignButtonClick}>
          {t([I18N_SCOPE, 'sign_button'])}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SignContract);
