import {useContext, useEffect, useRef} from 'react';

import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';

import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import type {ICourse} from '@src/models/app';
import type {Expanded as CourseExpanded} from '@src/models/courses';
import {expand} from '@src/models/courses';

import {emitter} from '../../../widget/src/utils';

const COURSES_TO_SHOW = 2;

export type Course = ICourse & CourseExpanded;

export type Program = Course['program'];

export interface Section {
  program: Program;
  hasMore: boolean;
  data: Course[];
}

interface IProps {
  limit: number;
}

export interface IMySquadsStore {
  coursesStore: CollectionStore<Course> | null;
  setCoursesStore(coursesStore: CollectionStore<Course>): void;
  isPaginating: boolean;
  setIsPaginating(isPaginating: boolean): void;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
  sections: Section[];
  shouldRenderStub: boolean;
  _onEndReached(): Promise<void>;
}

const useMySquads = ({limit}: IProps) => {
  const {
    stores: {programStore, editionStore, courseStore},
  } = useContext(AppContext);

  const store: IMySquadsStore = useRef(
    observable<IMySquadsStore>(
      {
        coursesStore: null,
        setCoursesStore(coursesStore: CollectionStore<Course>) {
          this.coursesStore = coursesStore;
        },
        isPaginating: false,
        setIsPaginating(isPaginating: boolean) {
          this.isPaginating = isPaginating;
        },
        isLoading: true,
        setIsLoading(isLoading: boolean) {
          this.isLoading = isLoading;
        },
        get sections() {
          const sections: Section[] = [];

          if (this.coursesStore) {
            const courses = this.coursesStore.items.slice();
            const groups: {[key: string]: Course[]} = {};

            const groupByProgram = (course: Course) => {
              const groupId = course.program_id;

              if (course.program) {
                groups[groupId] = groups[groupId] || [];

                groups[groupId].push(course);
              }
            };

            courses.forEach(groupByProgram);

            Object.keys(groups).forEach(key => {
              const data = groups[key].filter(item => item.edition);

              sections.push({
                program: groups[key][0].program,
                data: data.slice(0, COURSES_TO_SHOW),
                hasMore: data.length > COURSES_TO_SHOW,
              });
            });
          }

          return sections;
        },
        get shouldRenderStub() {
          return this.coursesStore.isLoaded && !this.sections.length;
        },
        async _onEndReached() {
          if (this.isPaginating) {
            return;
          }

          this.setIsPaginating(true);

          if (this.coursesStore.hasMore) {
            try {
              await this.coursesStore.fetch(
                {
                  offset: this.coursesStore.items.length,
                },
                {
                  silent: true,
                },
              );
            } catch (error) {
              // TODO: log error
            }
          }

          this.setIsPaginating(false);
        },
      },
      {
        coursesStore: observable,
        setCoursesStore: action,
        isPaginating: observable,
        setIsPaginating: action,
        isLoading: observable,
        setIsLoading: action,
        sections: computed,
        shouldRenderStub: computed,
        _onEndReached: action,
      },
    ),
  ).current;

  useEffect(() => {
    store.setCoursesStore(
      createCollectionStore({
        method: 'coach.courses.list',
        params: {
          sort: [['created', -1]],
          query: [['status', 'in', ['ongoing']]],
          limit: limit,
          expand,
        },
      }),
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    const disposeProgramStoreUpdating = reaction(
      () => programStore.updating,
      updating => {
        if (updating.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeEditionStoreCreating = reaction(
      () => editionStore.creating,
      creating => {
        if (creating.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeEditionStoreUpdating = reaction(
      () => editionStore.updating,
      updating => {
        if (updating.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeEditionStoreDeleting = reaction(
      () => editionStore.deleting,
      deleting => {
        if (deleting.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeCourseStoreCreating = reaction(
      () => courseStore.creating,
      creating => {
        if (creating.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeCourseStoreUpdating = reaction(
      () => courseStore.updating,
      updating => {
        if (updating.success) {
          _onRefresh(true);
        }
      },
    );
    const disposeCourseStoreDeleting = reaction(
      () => courseStore.deleting,
      deleting => {
        if (deleting.success) {
          _onRefresh(true);
        }
      },
    );

    const timerId = setTimeout(() => {
      _onRefresh();
    }, 500);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      disposeProgramStoreUpdating();
      disposeEditionStoreCreating();
      disposeEditionStoreUpdating();
      disposeEditionStoreDeleting();
      disposeCourseStoreCreating();
      disposeCourseStoreUpdating();
      disposeCourseStoreDeleting();
      clearTimeout(timerId);

      if (store.coursesStore) {
        store.coursesStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onRefresh = async (silent = false) => {
    if (store.coursesStore) {
      store.setIsLoading(true);

      await store.coursesStore
        .fetch(
          {
            limit: Math.max(store.coursesStore.items.length, limit),
          },
          {silent},
        )
        .catch(() => {
          store.setIsLoading(false);
        });

      store.setIsLoading(false);
    }
  };

  const _handleWsMessage = message => {
    if (
      [
        'course_started',
        'course_created',
        'course_pending',
        'edition_created',
        'edition_signed',
        'membership_created',
        'membership_accepted',
      ].includes(message.event.type)
    ) {
      _onRefresh(true);
    }
  };

  return store;
};

export default useMySquads;
