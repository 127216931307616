import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useInView} from 'react-intersection-observer';

import classNames from 'classnames';

import styles from './Image.module.css';

type Props = {
  src: string;
  alt?: string;
  resizeMode?: 'contain' | 'fill' | 'cover' | 'none' | 'scale-down';
  placeholder?: React.ReactElement;
  className?: string;
};

const Image: React.FC<Props> = ({
  src,
  alt,
  resizeMode,
  placeholder,
  className,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(!!src);
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView();

  const prevSrc = useRef<typeof src>('');

  useEffect(() => {
    if (src !== prevSrc.current) {
      setIsValidSrc(!!src);
      setIsLoaded(false);
      setIsVisible(inView);
    }

    prevSrc.current = src;
  }, [inView, src]);

  useEffect(() => {
    if (!isVisible && inView) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  const onLoadCb = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const onErrorCb = useCallback(() => {
    setIsValidSrc(false);
  }, []);

  const imgStyle = useMemo(
    () => ({objectFit: resizeMode || 'cover'}),
    [resizeMode],
  );

  return (
    <div className={classNames('Image', styles.Component, className)} ref={ref}>
      {placeholder && (
        <div
          className={classNames(styles.placeholder, isLoaded && styles.loaded)}
        >
          {placeholder}
        </div>
      )}
      {isValidSrc && (
        <img
          className={styles[`img-${isLoaded ? 'visible' : 'hidden'}`]}
          style={imgStyle}
          src={isVisible ? src : undefined}
          alt={alt}
          onLoad={onLoadCb}
          onError={onErrorCb}
        />
      )}
    </div>
  );
};

export default React.memo(Image);
