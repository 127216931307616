import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {Category} from '@yourcoach/shared/api/category';

import {
  labelChooseCategory,
  labelChooseCategoryExplanation,
} from '@src/common/i18n/i18nProfile';
import AppContext from '@src/context/App';
import type {ICategory} from '@src/models/app';

import styles from './../styles.module.css';

interface ILocalStore {
  userCategory: ICategory[];
  loaclAddCategory: (ccategoryId: string) => void;
  localDelCategory: (index: number) => void;
  getCategoriesWithWeight: () => ICategory[];
}

interface Props {
  addCategory: (userCategory: ICategory[]) => void;
}

const ChoiceCategory: FC<Props> = ({addCategory}) => {
  const MAX_SELECTION = 5;
  const {
    stores: {currentUserStore, categoryStore},
  } = useContext(AppContext);
  const user = currentUserStore.user || null;
  const localStore: ILocalStore = useRef(
    observable(
      {
        userCategory: user?.coach_categories.slice() || [],
        loaclAddCategory(categoryId: string) {
          this.userCategory.push({category_id: categoryId});
          addCategory(this.getCategoriesWithWeight());
        },
        localDelCategory(index: number) {
          this.userCategory.splice(index, 1);
          addCategory(this.getCategoriesWithWeight());
        },
        getCategoriesWithWeight() {
          return this.userCategory.map((category, categoryIndex) => {
            const k = categoryIndex / MAX_SELECTION;

            const weight = parseFloat((1 - k).toFixed(1));

            return {
              ...category,
              weight,
            };
          });
        },
      },
      {
        userCategory: observable,
        getCategoriesWithWeight: action,
        localDelCategory: action,
        loaclAddCategory: action,
      },
    ),
  ).current;

  const handleOnClickCategory = (category: Category, index: number) => {
    return () => {
      const isDel = index !== -1;

      if (isDel) {
        localStore.localDelCategory(index);
      } else if (localStore.userCategory.length <= MAX_SELECTION - 1) {
        localStore.loaclAddCategory(category._id);
      }
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`ChoiceCategory ${styles.ChoiceCategory}`}>
          <h4>{labelChooseCategory()}</h4>
          <div className={styles.ChoiceCategoryExplanation}>
            {labelChooseCategoryExplanation()}
          </div>
          <div className={styles.categoriesContainer}>
            {categoryStore.categories.slice().map(category => {
              const index = localStore.userCategory.findIndex(
                loacalCategory => loacalCategory.category_id === category._id,
              );

              const isSelect = index > -1;

              return (
                <div
                  onClick={handleOnClickCategory(category, index)}
                  className={`${styles.category} ${
                    isSelect ? styles.selected : ''
                  }`}
                  key={category._id}
                >
                  {isSelect && (
                    <div className={styles.categoriesNum}>{index + 1}</div>
                  )}
                  <span>{category.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChoiceCategory);
