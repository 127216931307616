import './Modal.styles.css';

import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactModal from 'react-modal';

import classNames from 'classnames';

import {IconClose} from './icons';
import styles from './Modal.module.css';

const presentedModals: {[key: string]: boolean} = {};

export const MODAL_STICKY_FOOTER_CLASS_NAME = styles['Modal-stickyFooter'];

export interface Props extends Omit<ReactModal.Props, 'isOpen'> {
  title?: string | (() => React.ReactElement);
  isOpen?: boolean;
  isDisabled?: boolean;
  bodyClassName?: string;
  headerClassName?: string;
  modalClassName?: string;
  withStickyFooter?: boolean;
  children: React.ReactNode;
  WithIcon?: React.ReactNode;
  completedTask: boolean;
}

ReactModal.setAppElement('#root');

export type ModalRef = {
  show: () => void;
  hide: () => void;
};

const contentClassName = 'modal-content';
const overlayClassName = 'modal-overlay';

const Modal = React.forwardRef<ModalRef, Props>(
  (
    {
      title,
      children,
      isOpen: isOpenProp,
      isDisabled,
      bodyClassName,
      headerClassName,
      modalClassName,
      withStickyFooter: stickyFooter,
      WithIcon,
      completedTask,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(isOpenProp);

    const id = useRef(Date.now().toString());

    const refIsUsed = useRef(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        refIsUsed.current = true;

        show();
      },
      hide: () => {
        refIsUsed.current = false;

        hide();
      },
    }));

    const show = useCallback(() => {
      setIsOpen(true);

      presentedModals[id.current] = true;
    }, []);

    const hide = useCallback(() => {
      setIsOpen(false);

      delete presentedModals[id.current];
    }, []);

    const onRequestCloseCb = useCallback(
      e => {
        hide();

        if (rest.onRequestClose) {
          rest.onRequestClose(e);
        }
      },
      [hide, rest],
    );

    useEffect(() => {
      if (!refIsUsed.current) {
        if (isOpenProp !== isOpen) {
          isOpenProp ? show() : hide();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenProp]);

    useEffect(() => {
      isOpenProp ? show() : hide();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const disableScrollClass = 'no-scroll';
      const backgroundEffectClass = 'blur';

      const body = document.body;
      const bg = document.getElementById('allContent')!;

      if (isOpen) {
        body.classList.add(disableScrollClass);
        bg.classList.add(backgroundEffectClass);
      }

      return () => {
        if (Object.keys(presentedModals).length) {
          return;
        }

        body.classList.remove(disableScrollClass);
        bg.classList.remove(backgroundEffectClass);
      };
    }, [isOpen]);

    return (
      <ReactModal
        closeTimeoutMS={200}
        {...rest}
        // @ts-ignore
        isOpen={isOpen}
        onRequestClose={onRequestCloseCb}
        className={classNames(
          contentClassName,
          isDisabled ? `${contentClassName}-disabled` : undefined,
          rest.className,
        )}
        overlayClassName={overlayClassName}
      >
        <div className={classNames('Modal', styles.wrapper, modalClassName)}>
          {title && (
            <div className={classNames(styles.header, headerClassName)}>
              {WithIcon ? (
                <div
                  className={`${styles.titleContainer} ${
                    completedTask && styles.completedTitle
                  }`}
                >
                  <div>
                    <WithIcon />
                  </div>
                  {typeof title === 'function' ? title() : title}
                </div>
              ) : typeof title === 'function' ? (
                title()
              ) : (
                title
              )}
              <button className={styles.closeButton} onClick={onRequestCloseCb}>
                <IconClose />
              </button>
            </div>
          )}
          <div
            className={classNames(
              styles.body,
              stickyFooter && styles.stickyFooter,
              bodyClassName,
            )}
          >
            {children}
          </div>
        </div>
      </ReactModal>
    );
  },
);

export default React.memo(Modal);
