import React, {memo} from 'react';

import BooksLeft from './images/books-left.svg';
import Carpet from './images/carpet.svg';
import Chess from './images/chess.svg';
import Lamp from './images/lamp.svg';
import Laptop from './images/laptop.svg';
import Plant from './images/plant.svg';
import Shelf from './images/shelf.svg';
import Sofa from './images/sofa.svg';
import Stand from './images/stand.svg';
import TopLeft from './images/top-left.svg';

const parts = [
  {image: TopLeft, left: -100, top: 0},
  {image: Carpet, left: 0, top: 559},
  {image: Sofa, left: 158, top: 428},
  {image: Chess, left: 132, top: 160},
  {image: Plant, left: 99, top: 347},
  {image: Stand, left: -27, top: 472},
  {image: BooksLeft, left: 0, top: 222},
  {image: Shelf, left: 287, top: 224, zIndex: -1},
  {image: Lamp, left: 116, top: 0},
  {image: Laptop, left: 2, top: 423},
];

const ImagePane = ({step, children}) => {
  return (
    <>
      {parts.slice(0, step + 2).map((item, index) =>
        item.image(
          {
            key: 'item-' + index,
            style: {
              position: 'absolute',
              left: item.left + 'px',
              top: item.top + 'px',
              zIndex: item.zIndex,
            },
          },
          null,
        ),
      )}
      {children}
    </>
  );
};

export default memo(ImagePane);
