import React, {useMemo} from 'react';

import styles from './NoResultsHeader.module.css';

export interface Props {
  text: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  description?: string;
  iconSize?: number;
}

const NoResultsHeader: React.FC<Props> = ({
  text,
  description,
  icon,
  iconSize,
}) => {
  const Icon = icon;

  const iconStyle = useMemo(
    () => ({
      width: iconSize,
      height: iconSize,
    }),
    [iconSize],
  );

  return (
    <div className={`NoResultsHeader ${styles.NoResultsHeader}`}>
      {Icon ? (
        <div className={styles.iconContainer}>
          <Icon style={iconStyle} />
        </div>
      ) : null}
      <p className={styles.text}>{text}</p>
      {description ? <p className={styles.description}>{description}</p> : null}
    </div>
  );
};

export default React.memo(NoResultsHeader) as typeof NoResultsHeader;
