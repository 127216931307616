import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {
  labelConfirm,
  labelEnterTheCode,
  labelSentTo,
} from '@src/common/i18n/i18nProfile';
import {CustomButton, CustomInput} from '@src/components/CustomForm';
import AppContext from '@src/context/App';

import styles from './../styles.module.css';

interface ILocalStore {
  code: string;
  codeError: string;
  setCode: (newCode: string) => void;
  setCoreError: (newError: string) => void;
}

interface Props {
  newAccType: string;
  newAccVal: string;
  endCode: () => void;
}

const GetCode: FC<Props> = ({newAccType, newAccVal, endCode}) => {
  const {
    stores: {accountStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        code: '',
        codeError: '',
        setCoreError(newError: string) {
          this.codeError = newError;
        },
        setCode(newCode: string) {
          this.setCoreError('');

          if (/^\d{1,6}$/.test(newCode)) {
            this.code = newCode;
          }
        },
      },
      {
        code: observable,
        codeError: observable,
        setCoreError: action,
        setCode: action,
      },
    ),
  ).current;

  const handleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    localStore.setCode(event?.currentTarget.value as string);
  };

  const handleClickContinue = async () => {
    if (localStore.code.length === 6) {
      let data;

      if (newAccType === 'email') {
        data = {email: newAccVal};
      } else if (newAccType === 'phone') {
        data = {phone: newAccVal};
      }

      try {
        await accountStore.create({
          ...data,
          code: localStore.code,
        });

        endCode();
      } catch (error) {
        localStore.setCoreError(error.message);
      }
    }
  };

  return (
    <Observer>
      {() => (
        <div className="GetCode">
          <div className={styles.enterCodeText}>{labelEnterTheCode()}</div>
          <div className={styles.sendToContainer}>
            <span className={styles.sendTo}>{labelSentTo()}</span>
            <span className={styles.sendToVal}>{newAccVal}</span>
            <CustomInput
              type="input"
              id="enterCode"
              customClassLabel="QStandardLabel"
              customClassCont="QStandardContainer"
              customClassInput="QStandardInput CodeInput"
              value={localStore.code}
              onChange={handleOnChange}
              error={localStore.codeError}
            />
            <div className={styles.buttonSendCodeContainer}>
              <CustomButton
                disabled={localStore.code.length !== 6}
                type="button"
                classButton="QuestionnaireContinueBut allWidth"
                onClick={handleClickContinue}
              >
                <span>{labelConfirm()}</span>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(GetCode);
