import {GOOGLE_KEY} from '@src/config';

import {proxifyUrl} from '.';

export type TGoogleResultPlaces =
  google.maps.places.QueryAutocompletePrediction;

export type GeocoderResultT = google.maps.GeocoderResult & {
  parts: {[key: string]: string};
  geometry: Omit<google.maps.GeocoderGeometry, 'location'> & {
    location: google.maps.LatLngLiteral;
  };
};

export const geocode = async ({
  address,
  coords,
}: {
  address?: string;
  coords?: number[];
}) => {
  const geocodeApiEndpoint = [
    'https://maps.googleapis.com/maps/api/geocode/json',
    `?key=${GOOGLE_KEY}&`,
    'language=en&',
    'result_type=locality&',
    address ? `address=${address}` : null,
    coords ? `latlng=${coords[1]},${coords[0]}` : null,
  ]
    .filter(Boolean)
    .join('');

  const response = await fetch(geocodeApiEndpoint);
  const json = (await response.json()) as {
    status: google.maps.GeocoderStatus;
    results: google.maps.GeocoderResult[];
    error_message?: string;
  };

  if (json.status === 'OK') {
    return json.results.map(result => {
      const parts = {};

      result.address_components.forEach(element => {
        parts[element.types[0]] = element.long_name;
      });

      return {
        ...result,
        parts,
      } as GeocoderResultT;
    });
  }

  throw {
    code: json.status,
    message: json.error_message,
  };
};

export const searchLocations = async (text: string) => {
  try {
    const geocodeApiEndpoint = [
      'https://maps.googleapis.com/maps/api/place/autocomplete/json',
      `?key=${GOOGLE_KEY}&`,
      'language=en&',
      'sensor=false&',
      'types=(regions)&',
      `input=${text}`,
    ].join('');

    const response = await fetch(proxifyUrl(geocodeApiEndpoint));

    const json = (await response.json()) as {
      status: google.maps.places.PlacesServiceStatus;
      predictions: TGoogleResultPlaces[];
      error_message?: string;
    };

    if (json.status === 'OK') {
      return json.predictions;
    }

    throw {
      code: json.status,
      message: json.error_message,
    };
  } catch (error) {
    throw error;
  }
};

export const getLocationString = (gResult: GeocoderResultT) =>
  `${
    gResult.parts.administrative_area_level_1 ||
    gResult.parts.colloquial_area ||
    gResult.parts.locality
  }, ${gResult.parts.country}`;
