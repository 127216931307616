/**
 * Styles for the MealIcon component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {getColor, getThemeSize} from '@yourcoach/shared/styles/utils';

import type {StyledProps} from './types';

const base = css<StyledProps>`
  background-color: ${getColor('global1')};
  border-radius: ${getThemeSize('borderRadius.m')}px;
  height: ${getThemeSize('icon.l')}px;
  width: ${getThemeSize('icon.l')}px;
  padding-top: 12px;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

export const web = css<StyledProps>`
  box-sizing: border-box;
  ${base}
`;

export const native = css<StyledProps>`
  ${base}
`;
