import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {IconBell} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import {t} from '@src/i18n';

import useIntersectionObserver from '../../common/useIntersectionObserver';

import ProgramList from './ProgramList/ProgramList';
import styles from './styles.module.css';
import type {IMySquadsStore} from './useMySquads';
import useMySquads from './useMySquads';

interface Props {}

const MySquads: FC<Props> = () => {
  const mySquadsStore: IMySquadsStore = useMySquads({limit: 100});
  // scroll and load elements if refObserve is visible
  const refObserve = useIntersectionObserver(() =>
    mySquadsStore._onEndReached(),
  );

  return (
    <Observer>
      {() => (
        <div className={`PracticeTab ${styles.MySquads}`}>
          {mySquadsStore.sections.length > 0 ? (
            <ProgramList mySquadsStore={mySquadsStore} />
          ) : !mySquadsStore.isLoading ? (
            <NoResults text={t(['Programs', 'no_active_programs'])}>
              <IconBell />
            </NoResults>
          ) : null}
          {mySquadsStore.isLoading ? <Loader /> : ''}
          <div ref={refObserve} />
        </div>
      )}
    </Observer>
  );
};

export default memo(MySquads);
