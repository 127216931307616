import {apiRequest} from '@yourcoach/shared/api';
import {userExpand} from '@yourcoach/shared/api/user';

import {SUPPORTED_EVENTS} from '../../context/useChatsLocalStore';

const updateUserResponse = async (coach_history_id, status) => {
  return await apiRequest({
    method: 'client.coach_history.update',
    params: {
      status,
      _id: coach_history_id,
    },
  });
};

const getInitialCoachHistory = async () => {
  return apiRequest({
    method: 'client.coach_history.list',
    params: {
      query: [
        ['type', '==', 'coach_coverage'],
        ['status', '==', 'message_sent'],
      ],
      limit: 51,
      sort: [['created', -1]],
      expand: {
        coach_history: [
          'old_coach_id',
          'new_coach_id',
          'client_id',
          'course_id',
        ],
      },
    },
  });
};

const getPastCoachHistory = async () => {
  return apiRequest({
    method: 'client.coach_history.list',
    params: {
      query: [
        ['type', '==', 'coach_coverage'],
        ['status', '==', 'past'],
        ['new_coach_id', '==', null],
      ],
      limit: 51,
      sort: [['created', -1]],
      expand: {
        coach_history: [
          'old_coach_id',
          'new_coach_id',
          'client_id',
          'course_id',
        ],
      },
    },
  });
};

const getOngoingCoachHistory = async () => {
  return apiRequest({
    method: 'client.coach_history.list',
    params: {
      query: [
        ['type', '==', 'coach_coverage'],
        ['status', '==', 'ongoing'],
        ['new_coach_id', '==', null],
      ],
      limit: 51,
      sort: [['created', -1]],
      expand: {
        coach_history: [
          'old_coach_id',
          'new_coach_id',
          'client_id',
          'course_id',
        ],
      },
    },
  });
};

const getEventsListAPI = async () => {
  return apiRequest({
    method: 'user.events.list',
    params: {
      cursor: [-1, 0, null],
      expand: {
        event: ['context_ids'],
      },
      limit: 500,
      query: [['read', '==', null]],
    },
  });
};

export {
  updateUserResponse,
  getInitialCoachHistory,
  getOngoingCoachHistory,
  getPastCoachHistory,
  getEventsListAPI,
};
