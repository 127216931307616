import {addDays} from 'date-fns';
import dayjs from 'dayjs';

import {
  apiRequest,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api/index';

const SCHEDULE_DEPTH = 90;

const getEventsListAPI = async course_id => {
  return await apiRequest({
    method: 'user.events.list',
    params: {
      cursor: [-1, 0, null],
      expand: {
        event: ['actor_id', 'user_id', 'context_ids'],
        user: ['avatar_id', 'coach_logo_id'],
      },
      limit: 20,
      query: [['context_ids', 'in', [course_id]]],
    },
  });
};

const getMembershipAPI = async () => {
  return await apiRequest({
    method: 'client.memberships.list',
    params: {
      query: [['status', 'in', ['accepted', 'active']]],
      sort: [['created', -1]],
      expand: {membership: ['course_id', 'edition_id']},
    },
  });
};

const getAvailableDatesAPI = async (coach_id, end_date, isBiggerEndDate) => {
  return await apiRequest({
    method: 'client.conferences.available_slots',
    params: {
      coach_ids: [coach_id],
      start_date: {
        _type: 'datetime',
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        hour: new Date().getUTCHours(),
        minute: new Date().getUTCMinutes(),
        second: 0,
        utcoffset: 0,
      },
      end_date: !isBiggerEndDate
        ? end_date
        : ISOStringToDatetimeObj(
            addDays(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getUTCHours(),
                new Date().getUTCMinutes(),
              ),
              SCHEDULE_DEPTH,
            ).toISOString(),
          ),
    },
  });
};

const getAvailableOldCoachDatesAPI = async (
  coach_id,
  end_date,
  isBiggerEndDate,
  start_date,
) => {
  return await apiRequest({
    method: 'client.conferences.available_slots',
    params: {
      coach_ids: [coach_id],
      start_date,
      end_date: !isBiggerEndDate
        ? end_date
        : ISOStringToDatetimeObj(
            addDays(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getUTCHours(),
                new Date().getUTCMinutes(),
              ),
              SCHEDULE_DEPTH,
            ).toISOString(),
          ),
    },
  });
};

const getAvailableSlotsForOneDay = async (coach_id, start_date, end_date) => {
  return apiRequest({
    method: 'client.conferences.available_slots',
    params: {
      coach_ids: [coach_id],
      start_date: start_date,
      end_date: end_date,
    },
  });
};

const getConferencesAPI = async (start_date, end_date) => {
  return await apiRequest({
    method: 'client.conferences.list',
    params: {
      query: [
        [
          'start_date',
          '>=',
          {
            year: start_date.year,
            day: start_date.day,
            month: start_date.month,
            hour: start_date.hour,
            second: start_date.second,
            minute: start_date.minute,
            _type: 'datetime',
            utcoffset: 0,
          },
        ],
        ['start_date', '<=', end_date],
      ],
    },
  });
};

const createConferenceAPI = async (
  coach_id,
  course_id,
  selectedTimeslot,
  session_duration,
  isReschedule = false,
) => {
  return await apiRequest({
    method: 'client.conferences.create',
    params: {
      coach_ids: [coach_id],
      course_id: course_id,
      start_date: selectedTimeslot?.start_date,
      end_date: ISOStringToDatetimeObj(
        dayjs(datetimeObjToISOString(selectedTimeslot?.start_date))
          .add(session_duration, 'minutes')
          .toISOString(),
      ),
      reschedule: isReschedule,
    },
  }).catch(err => {
    throw err;
  });
};

const postTimezone = async timezone => {
  return await apiRequest({
    method: 'user.profile.update',
    params: {
      timezone,
    },
  });
};

const getCoachCoverageItem = async course_id => {
  return apiRequest({
    // @ts-ignore
    method: 'client.coach_history.list',
    params: {
      query: [
        ['type', '==', 'coach_coverage'],
        ['status', '!=', 'past'],
        ['course_id', '==', course_id],
      ],
      limit: 51,
      expand: {
        coach_history: [
          'old_coach_id',
          'new_coach_id',
          'client_id',
          'course_id',
        ],
      },
      sort: [['created', -1]],
    },
  });
};

const getAvailableTemporaryCoachDatesAPI = async (
  coach_id,
  start_date,
  end_date,
) => {
  return await apiRequest({
    method: 'client.conferences.available_slots',
    params: {
      coach_ids: [coach_id],
      start_date,
      end_date,
    },
  });
};

export {
  getEventsListAPI,
  getMembershipAPI,
  getConferencesAPI,
  getAvailableDatesAPI,
  getAvailableSlotsForOneDay,
  createConferenceAPI,
  postTimezone,
  getCoachCoverageItem,
  getAvailableTemporaryCoachDatesAPI,
  getAvailableOldCoachDatesAPI,
};
