import type {FC} from 'react';
import React, {memo, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {
  labelIsThisFieldRequired,
  labelNewQuestion,
  labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters,
  labelTypeQuestion,
} from '@src/common/i18n/i18nQuestionnaire';
import {SwitchEditInput} from '@src/components/CustomForm';
import {IconTrash} from '@src/components/icons';

import questionnaireAppStore from '../../../../context/qAppStore';
import type {ITypeInput} from '..';
import TypeSelect from '../TypeSelect/TypeSelect';

import styles from './../../styles.module.css';

interface Props extends ITypeInput {
  isPart?: boolean;
}

interface ILocalTextType {
  text: string;
  setText: (newT: string) => void;
  onChangeText: string;
  setTextNoSave(newT: string): void;
  currentType: QuestionnaireQuestion['type'];
  setCurrentType: (newType: QuestionnaireQuestion['type']) => void;
  isOptional: boolean;
  setOptional: (newOptional: boolean) => void;
  saveData: () => void;
}

const TextType: FC<Props> = ({
  question: {question, type, is_optional},
  delInput,
  saveInput,
  setOldQuestion,
  indexQ,
  isPart = false,
}) => {
  let openInput: (() => void) | null = null;

  const localStore = useMemo(
    () =>
      observable<ILocalTextType>(
        {
          text: question || '',
          setText(newT: string) {
            this.text = newT;
            this.saveData();
          },
          onChangeText: question || '',
          setTextNoSave(newT: string) {
            this.onChangeText = newT;
          },
          currentType: type,
          setCurrentType(newType: QuestionnaireQuestion['type']) {
            this.currentType = newType;
            this.text = this.onChangeText;
            this.saveData();
          },
          isOptional: is_optional,
          setOptional(newOptional: boolean) {
            this.isOptional = newOptional;
            this.saveData();
          },
          saveData() {
            saveInput({
              question: this.text,
              type: this.currentType,
              is_optional: this.isOptional,
            });
          },
        },
        {
          text: observable,
          onChangeText: observable,
          setTextNoSave: action,
          currentType: observable,
          isOptional: observable,
          setText: action,
          setCurrentType: action,
          setOptional: action,
          saveData: action,
        },
      ),
    [is_optional, question, saveInput, type],
  );

  const handleGetOpenFn = (fn: () => void) => {
    openInput = fn;
  };

  const handleUpdate = (newVal: string) => {
    localStore.setText(newVal);
  };

  const handleOnChange = (newVal: string) => {
    localStore.setTextNoSave(newVal);
  };

  const handleUpdateType = (newType: QuestionnaireQuestion['type']) => {
    localStore.setCurrentType(newType);
  };

  const handleDelQuestion = () => {
    delInput();
  };

  const handleClickRequired = () => {
    localStore.setOptional(!localStore.isOptional);
  };

  useEffect(() => {
    if (indexQ || indexQ === 0) {
      if (questionnaireAppStore?.newQId) {
        const arr = questionnaireAppStore?.newQId.split('=');

        if (
          arr[0] === questionnaireAppStore.activeSectionId &&
          arr[1] === indexQ.toString()
        ) {
          if (openInput) {
            openInput();
          }
        }
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div className={`TextType ${styles.TextType}`}>
          <div className={isPart ? '' : styles.textContainerQ}>
            <SwitchEditInput
              inputVal={localStore.text}
              label={labelTypeQuestion()}
              setOldQuestion={setOldQuestion}
              isClearDefault
              defaultLabel={labelNewQuestion()}
              customClassLabel="QStandardLabel"
              customClassCont="QStandardContainer"
              customClassInput="QStandardInput noPurple"
              test={/^.{1,255}$/}
              testError={labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters()}
              getOpenFn={handleGetOpenFn}
              onChange={handleOnChange}
              onUpdateVal={handleUpdate}
            >
              <div className={styles.textInputContainer}>
                {localStore.text ? (
                  localStore.text
                ) : (
                  <div>{labelTypeQuestion()}</div>
                )}
              </div>
            </SwitchEditInput>
          </div>
          <div className={styles.selectTypeContainer}>
            <TypeSelect
              type={localStore.currentType}
              updateType={handleUpdateType}
            />
            <div className={styles.isRequired}>
              <div className={styles.fieldRequired}>
                {labelIsThisFieldRequired()}
              </div>
              <div className="customGeneratorCheckBox">
                <input
                  type="checkbox"
                  className="switch"
                  defaultChecked={!localStore.isOptional}
                  onClick={handleClickRequired}
                />
              </div>
            </div>
          </div>
          <div className={styles.trash} onClick={handleDelQuestion}>
            <IconTrash />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(TextType);
