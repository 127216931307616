import AvatarStep from './steps/AvatarStep';
import BirthdayStep from './steps/BirthdayStep';
import ExtraCategoriesStep from './steps/ExtraCategoriesStep';
import FactStep from './steps/FactStep';
import GreetingStep from './steps/GreetingStep';
import LocationStep from './steps/LocationStep';
import MainCategoryStep from './steps/MainCategoryStep';
import NameStep from './steps/NameStep';
import ProgramStep from './steps/ProgramStep';
import ReadyStep from './steps/ReadyStep';
import RoadmapStep from './steps/RoadmapStep';
import TasksStep from './steps/TasksStep';
import TrackingStep from './steps/TrackingStep';
import VideoStep from './steps/VideoStep';

export const BasicFlow = [
  NameStep,
  AvatarStep,
  LocationStep,
  BirthdayStep,
  GreetingStep,
  MainCategoryStep,
  ExtraCategoriesStep,
  FactStep,
  TrackingStep,
];

export const FinalFlow = [ProgramStep, TasksStep, ReadyStep];

export const InviteFlow = BasicFlow.concat(ReadyStep);

export const EmployeeFlow = BasicFlow.concat(
  VideoStep,
  RoadmapStep,
  ...FinalFlow,
);

export const ClientFlow = BasicFlow.concat(VideoStep, ...FinalFlow);
