import {makeAutoObservable} from 'mobx';

import {
  CalendarDataStore,
  TodosCalendarDataFetcher,
} from '@yourcoach/shared/stores/calendar-data';
import type {Destructible} from '@yourcoach/shared/utils';

export class TodosStore implements Destructible {
  calendar = new CalendarDataStore(new TodosCalendarDataFetcher());

  constructor() {
    makeAutoObservable(this);

    this.calendar.fetch();
  }

  destruct() {
    this.calendar.destruct();
  }

  get filteredData() {
    return this.calendar.events.entries;
  }
}
