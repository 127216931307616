import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import type {Course} from '@yourcoach/shared/api/course';
import type {Program} from '@yourcoach/shared/api/program';

import InvitesState from './context/InvitesState';
import InvitesBody from './InvitesBody/InvitesBody';
import InvitesHeader from './InvitesHeader/InvitesHeader';
import styles from './styles.module.css';

interface Props {
  target: Program | Course;
  onSend?: () => void;
}

const Invites: FC<Props> = ({target, onSend}) => {
  return (
    <Observer>
      {() => (
        <InvitesState target={target} onSend={onSend}>
          <div className={`Invites ${styles.Invites}`}>
            <InvitesHeader />
            <InvitesBody />
          </div>
        </InvitesState>
      )}
    </Observer>
  );
};

export default memo(Invites);
