import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {t} from 'i18n-js';
import {action, autorun, observable, runInAction} from 'mobx';

import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Certificate} from '@yourcoach/shared/api/certificate';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import CalendarIcon from '@yourcoach/shared/assets/icons/primary/Calendar.svg';
import CertificateIcon from '@yourcoach/shared/assets/icons/primary/Certificate.svg';
import SendIcon from '@yourcoach/shared/assets/icons/primary/Send.svg';
import {OnOffController} from '@yourcoach/shared/controllers/OnOffController';
import {EditWeekScheduleContainer} from '@yourcoach/shared/modules/edit-week-schedule';
import delay from '@yourcoach/shared/utils/delay';

import {
  labelCertificates,
  labelCertificatesAdd,
} from '@src/common/i18n/i18nProfile';
import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Button from '@src/components/Button';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import type {ModalRef} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import {syncIleStorage} from '@src/hooks/useIsLimitedEdition';
import CertificatesListManager from '@src/modules/Certificates/CertificatesListManager';
import {certificateExpand} from '@src/modules/Certificates/ExpandedCertificate';
import SendMessageModal from '@src/modules/users/SendMessageModal';
import {slugify} from '@src/routes/utils';
import {getPagePath} from '@src/utils';

// import {IProgramLocalStore} from '../../MyLibrary/ListFoldersPrograms/ListProgram/ListProgramLocalStore';
import PracticeContext from '../context/PracticeContext';
import type {IPracticeLocalStore} from '../context/usePracticeLocalStore';

import HeaderImg from './HeaderImg/HeaderImg';
import styles from './styles.module.css';

const I18N_SCOPE = 'Coach';

type ICoach = User & UserExpanded;

interface ILocalStore {
  followersCount: number;
  followersCountIsFetched: boolean;
  coach: ICoach | null;
  isEditWeekScheduleModalOpen: OnOffController;
  _fetchFollowersCount(): Promise<void>;
  _fetchCertificates(): Promise<void>;
}

interface Props {}

const HeaderPractice: React.FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const programState: IPracticeLocalStore | null = useContext(PracticeContext);

  const history = useHistory();

  const certificatesStore = useRef(
    createCollectionStore<Certificate>({
      method: 'client.certificates.list',
      params: {
        expand: certificateExpand,
      },
    }),
  ).current;

  const certificatesModal = useRef<ModalRef | null>(null);
  const sendMessageModal = useRef<ModalRef | null>(null);

  const localStore: ILocalStore = useRef(
    observable(
      {
        followersCount: 0,
        followersCountIsFetched: false,
        coach: null,
        isEditWeekScheduleModalOpen: new OnOffController(false),
        async _fetchFollowersCount() {
          if (!programState!.practice._id) {
            await delay(2000);

            return this._fetchFollowersCount();
          }

          if (programState!.isMe) {
            try {
              const result = await apiRequest({
                method: 'coach.followers.count',
                params: {
                  query: [
                    ['coach_id', '==', programState!.practice._id],
                    ['status', '==', 'joined'],
                    ['client_deleted', '==', null],
                    ['hidden_for_coach', '==', false],
                  ],
                },
              });

              runInAction(() => {
                localStore.followersCount = result._count;

                localStore.followersCountIsFetched = true;
              });

              return result._count;
            } catch (error) {
              await delay(2000);

              return this._fetchFollowersCount();
            }
          }
        },

        async _fetchCertificates() {
          if (!programState!.practice._id) {
            await delay(2000);

            return this._fetchCertificates();
          }

          certificatesStore.setMethod(
            currentUserStore.user?.roles?.includes('coach')
              ? 'coach.certificates.list'
              : 'client.certificates.list',
          );

          try {
            await certificatesStore.fetch({
              query: programState!.isMe
                ? []
                : [['user_id', '==', programState!.practice._id]],
            });
          } catch (e) {
            // TODO: log error
          }
        },
      },
      {
        followersCount: observable,
        followersCountIsFetched: observable,
        coach: observable,
        isEditWeekScheduleModalOpen: observable,
        _fetchFollowersCount: action,
        _fetchCertificates: action,
      },
    ),
  ).current;

  autorun(() => {
    if (programState!.practice.coach_title) {
      const name = slugify(programState!.practice.coach_title)!;
      const path = getPagePath();

      localAppStore?.addLinksBreadcrumbs({
        name,
        path,
      });
    }

    if (
      currentUserStore.user &&
      !currentUserStore.user.roles.includes('coach')
    ) {
      syncIleStorage(
        programState!.practice._id,
        programState!.isLimitedEdition,
      );
    }
  });

  useEffect(() => {
    localStore._fetchFollowersCount();
    localStore._fetchCertificates();

    return () => {
      certificatesStore.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coachContainer = programState!.isMe
    ? `${styles.coachContainer}`
    : `${styles.coachContainer} ${styles.user}`;

  const onCertificatesButtonClick = useCallback(() => {
    if (certificatesModal.current) {
      certificatesModal.current.show();
    }
  }, []);

  const onSendMessageButtonClick = useCallback(() => {
    if (sendMessageModal.current) {
      sendMessageModal.current.show();
    }
  }, []);

  const onAvailabilityClickHandler = useCallback(() => {
    const isOpenCalendar =
      (programState as unknown as IPracticeLocalStore)?.isMe &&
      currentUserStore.user?.coach_schedule;
    const isOpenEditWeekSchedule =
      (programState as unknown as IPracticeLocalStore)?.isMe &&
      currentUserStore.user?.coach_schedule === null;

    if (isOpenCalendar) {
      history.push('/my-calendar');
    } else if (isOpenEditWeekSchedule) {
      localStore.isEditWeekScheduleModalOpen.setValue(true);
    }
  }, [
    history,
    localStore.isEditWeekScheduleModalOpen,
    currentUserStore.user?.coach_schedule,
    programState,
  ]);

  return (
    <Observer>
      {() => (
        <>
          {programState &&
          ((programState as IPracticeLocalStore)!.isLoadingPractice ||
            !(programState as IPracticeLocalStore)!.practice) ? (
            <Loader />
          ) : (
            <div className={`HeaderPractice ${styles.HeaderPractice}`}>
              <Helmet
                title={
                  programState!.isLimitedEdition
                    ? programState!.practice.name || ''
                    : programState!.practice.coach_title || ''
                }
              />
              <div className={styles.imgContainer}>
                <HeaderImg />
              </div>
              <div className={styles.textContainer}>
                {!programState!.isLimitedEdition ? (
                  <h2 className={styles.title}>
                    {programState!.practice.coach_title}
                  </h2>
                ) : null}
                <div>
                  {programState!.categories.map(category => (
                    <span
                      key={category._id}
                      className={styles.category}
                    >{`#${category.title}`}</span>
                  ))}
                </div>
                <div className={styles.itemsPanel}>
                  <div className={coachContainer}>
                    <div className={styles.coachAvatar}>
                      <Image
                        src={
                          getFileSrc(programState!.practice.avatar, 250).url ||
                          ''
                        }
                        placeholder={
                          <AvatarPlaceholder
                            name={programState!.practice.name}
                          />
                        }
                      />
                    </div>
                    <div className={styles.nameContainer}>
                      <div className={styles.coachName}>
                        {programState!.practice.name}
                      </div>
                      {programState!.isMe ? (
                        <div className={styles.coachFollowingCount}>
                          <div className={styles.coachFollowingNumber}>
                            {localStore.followersCountIsFetched
                              ? t([I18N_SCOPE, 'clients_label'], {
                                  count: localStore.followersCount || 0,
                                })
                              : '...'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={styles.actionsContainer}>
                  {!programState!.isMe && !programState!.isLimitedEdition && (
                    <>
                      <button
                        className={styles.actionButton}
                        onClick={onSendMessageButtonClick}
                      >
                        <div className={styles.iconContainer}>
                          <SendIcon />
                        </div>
                        {t([I18N_SCOPE, 'message_button'])}
                      </button>
                      {programState!.practice._id && (
                        <SendMessageModal
                          ref={sendMessageModal}
                          userId={programState!.practice._id}
                        />
                      )}
                    </>
                  )}
                  {programState && (programState as IPracticeLocalStore)!.isMe && (
                    <button
                      className={styles.actionButton}
                      onClick={onAvailabilityClickHandler}
                    >
                      <div className={styles.iconContainer}>
                        <CalendarIcon />
                      </div>
                      {t([I18N_SCOPE, 'availability_button'])}
                    </button>
                  )}
                  {certificatesStore.hasItems &&
                    !programState!.isLimitedEdition && (
                      <>
                        <button
                          className={styles.actionButton}
                          onClick={onCertificatesButtonClick}
                        >
                          <div className={styles.iconContainer}>
                            <CertificateIcon />
                          </div>
                          {t([I18N_SCOPE, 'certificates_button'])}
                        </button>
                        <Modal
                          ref={certificatesModal}
                          title={labelCertificates()}
                        >
                          <CertificatesListManager
                            userId={
                              programState!.isMe
                                ? null
                                : programState!.practice._id
                            }
                            isVertical={false}
                          >
                            {programState!.isMe ? (
                              <div className={styles.certificatesModalFooter}>
                                <Button type="button">
                                  {labelCertificatesAdd()}
                                </Button>
                              </div>
                            ) : null}
                          </CertificatesListManager>
                        </Modal>
                      </>
                    )}
                </div>
                <div className={styles.description}>
                  {programState!.practice.coach_description}
                </div>
                <Modal
                  title={t('v2.my_calendar.buttons.edit_week_schedule')}
                  isOpen={localStore.isEditWeekScheduleModalOpen.value}
                  onRequestClose={() =>
                    localStore.isEditWeekScheduleModalOpen.setValue(false)
                  }
                >
                  <EditWeekScheduleContainer
                    onSaveCallback={() =>
                      localStore.isEditWeekScheduleModalOpen.setValue(false)
                    }
                  />
                </Modal>
              </div>
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default React.memo(HeaderPractice);
