import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {
  labelChooseQuestionType,
  labelMultipleChoice,
  labelSingleChoice,
  labelTextField,
} from '@src/common/i18n/i18nQuestionnaire';
import FadeComponent from '@src/components/FadeComponent/FadeComponent';
import {IconCheck} from '@src/components/icons';
import IconCheckBox from '@src/components/icons/IconCheckBox-1/IconCheckBox-1';
import IconRadio from '@src/components/icons/IconRadio-1/IconRadio-1';
import IconTexField from '@src/components/icons/IconTexField/IconTexField';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import styles from './../../styles.module.css';

interface Props {
  type?: QuestionnaireQuestion['type'];
  updateType: (newType: QuestionnaireQuestion['type']) => void;
}

const TypeSelect: FC<Props> = ({type, updateType}) => {
  const [visible, setVisible] = useState(false);
  const [allies, setAllies] = useState('');
  const [currentType, setCurrentType] = useState<{
    id?: QuestionnaireQuestion['type'];
    icon?: any;
    text?: string;
  }>({});

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleClick = (typSelect: QuestionnaireQuestion['type']) => {
    return () => {
      if (typSelect !== type) {
        updateType(typSelect);
      }
    };
  };

  const buttons = [
    {id: ALIAS_TYPES.text, icon: IconTexField, text: labelTextField()},
    {id: ALIAS_TYPES.singleChoice, icon: IconRadio, text: labelSingleChoice()},
    {
      id: ALIAS_TYPES.singleChoiceOpen,
      icon: IconRadio,
      text: labelSingleChoice(),
    },
    {
      id: ALIAS_TYPES.multipleChoice,
      icon: IconCheckBox,
      text: labelMultipleChoice(),
    },
    {
      id: ALIAS_TYPES.multipleChoiceOpen,
      icon: IconCheckBox,
      text: labelMultipleChoice(),
    },
  ];

  const allButtRend: {
    id?: QuestionnaireQuestion['type'];
    icon?: any;
    text?: string;
  }[] = buttons.filter(
    ({id}) =>
      id !== ALIAS_TYPES.singleChoiceOpen &&
      id !== ALIAS_TYPES.multipleChoiceOpen,
  );

  useEffect(() => {
    if (type) {
      const currBut = buttons.find(({id}) => id === type);

      if (type === ALIAS_TYPES.singleChoiceOpen) {
        setAllies(ALIAS_TYPES.singleChoice);
      } else if (type === ALIAS_TYPES.multipleChoiceOpen) {
        setAllies(ALIAS_TYPES.multipleChoice);
      } else {
        setAllies(type);
      }

      if (currBut) {
        setCurrentType(currBut);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div
      className={`TypeSelect ${styles.TypeSelect} ${
        visible ? styles.TypeSelectActive : ''
      }`}
      onMouseEnter={handleOpen}
    >
      {!visible &&
        (type ? (
          <div className={styles.label}>
            <div className={styles[type]}>
              {currentType.icon && <currentType.icon />}
            </div>
            <div className={styles.text}>
              {currentType.text && currentType.text}
            </div>
          </div>
        ) : (
          <div>+ {labelChooseQuestionType()}</div>
        ))}
      {visible && (
        <FadeComponent show={visible}>
          <ul onMouseLeave={handleClose}>
            {allButtRend.map(but => (
              <li
                key={but.id}
                className={allies === but.id ? styles.active : ''}
                onClick={handleClick(but.id!)}
              >
                <div className={styles[but.id!]}>
                  <but.icon />
                </div>
                <div className={styles.text}>{but.text && but.text}</div>
                {allies === but.id && (
                  <div className={styles.check}>
                    <IconCheck />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </FadeComponent>
      )}
    </div>
  );
};

export default memo(TypeSelect);
