import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import type {Course} from '@yourcoach/shared/api/course';
import type {Program} from '@yourcoach/shared/api/program';

import InvitesContext from './InvitesContext';
import useLocalStoreInvite from './InvitesLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
  target: Program | Course;
  onSend?: () => void;
}

const InvitesState: FC<Props> = ({children, target, onSend}) => {
  const invitesLocalStore = useLocalStoreInvite();

  invitesLocalStore!.setCurrentTarget(target);
  invitesLocalStore!.setCurrentOnSend(onSend);

  return (
    <InvitesContext.Provider value={invitesLocalStore}>
      {children}
    </InvitesContext.Provider>
  );
};

export default memo(InvitesState);
