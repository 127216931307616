import React from 'react';
import {observer} from 'mobx-react';

import styled from 'styled-components';

import TimeIcon from '@yourcoach/shared/assets/icons/secondary/Time Circle.svg';
import {useMobxStore} from '@yourcoach/shared/hooks';
import {CourseInfoStore} from '@yourcoach/shared/stores/single-event';
import {Text} from '@yourcoach/shared/uikit/Text';
import type {Nullable} from '@yourcoach/shared/utils';
import {ConditionalFlagManager, isDefined} from '@yourcoach/shared/utils';

import {Subtitle} from '../../components/Subtitle';

import {InfoBadge} from './components/InfoBadge';
import {Layout} from './components/Layout';
import {DescriptionSkeleton, InfoBadgeSkeleton} from './components/skeleton';

const StyledText = styled(Text)`
  font-size: 16px;
`;

export const CourseInfoContainer: React.FC<{
  courseId?: Nullable<string>;
  eventId?: string;
}> = observer(({courseId, eventId}) => {
  const store = useMobxStore(() => new CourseInfoStore({courseId, eventId}));

  const isLoading = useMobxStore(() => {
    const manager = new ConditionalFlagManager(true);

    manager.trueWhen(
      () => store.initialLoad.isLoading || store.busy.getReason('fetch'),
    );

    return manager;
  });

  React.useEffect(() => {
    store.setIds({eventId, courseId});
  }, [store, courseId, eventId]);

  const renderHeader = () => (
    <Layout.Header.Container>
      <Layout.Header.TitleBox>
        <Subtitle>Information</Subtitle>
      </Layout.Header.TitleBox>

      {isLoading.value ? (
        <DescriptionSkeleton />
      ) : (
        <StyledText>{store.event.description}</StyledText>
      )}
    </Layout.Header.Container>
  );

  const renderDueDate = () => {
    if (isDefined(store.event.dueDate)) {
      return (
        <InfoBadge
          icon={<TimeIcon />}
          label="Due date"
          value={store.event.dueDate}
        />
      );
    }
  };

  return (
    <Layout.Container>
      {renderHeader()}
      <Layout.Body>
        {isLoading.value ? (
          <React.Fragment>
            <InfoBadgeSkeleton />
          </React.Fragment>
        ) : (
          <React.Fragment>{renderDueDate()}</React.Fragment>
        )}
      </Layout.Body>
    </Layout.Container>
  );
});
