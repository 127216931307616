import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import {CustomButton} from '@src/components/CustomForm';
import {t} from '@src/i18n';
import type ExpandedCertificate from '@src/modules/Certificates/ExpandedCertificate';

import styles from './CertificatesList.module.css';

const I18N_SCOPE_COMMON = 'Common';

interface Props {
  certificate: ExpandedCertificate;
  isReadOnly: boolean;
  onEditClick: (certificate: ExpandedCertificate) => void;
  onDeleteClick: (certificate: ExpandedCertificate) => void;
}

const CertificatesListItem: FC<Props> = ({
  certificate,
  isReadOnly = true,
  onEditClick = () => {},
  onDeleteClick = () => {},
}) => {
  const photoUrl = useMemo(
    () => getFileSrc(certificate.file as IFile, 250).url || undefined,
    [certificate],
  );

  const photoUrlOriginal = useMemo(
    () => getFileSrc(certificate.file as IFile).url || undefined,
    [certificate],
  );

  const onDeleteClickCb = useCallback(() => {
    onDeleteClick(certificate);
  }, [certificate, onDeleteClick]);

  const onEditClickCb = useCallback(() => {
    onEditClick(certificate);
  }, [certificate, onEditClick]);

  const onClickCertificateCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      window.open(photoUrlOriginal, '_blank');
    },
    [photoUrlOriginal],
  );

  return (
    <div className={`CertificatesListItem ${styles.CertificatesListItem}`}>
      <div className={styles.imageContainer}>
        <div
          onClick={onClickCertificateCb}
          className={styles.imageDiv}
          style={{
            backgroundImage: `url(${photoUrl})`,
          }}
        />
      </div>
      <div className={styles.content}>
        <h4>{certificate.title}</h4>
        <div className={styles.school}>{certificate.school}</div>
        <div className={styles.spacer} />
        {isReadOnly ? null : (
          <div className={styles.buttons}>
            <CustomButton
              type="button"
              classButton={`${styles.button} ${styles.editButton}`}
              classContainer={styles.buttonContainer}
              onClick={onEditClickCb}
            >
              <span>{t([I18N_SCOPE_COMMON, 'edit'])}</span>
            </CustomButton>
            <div className={styles.buttonSpacer} />
            <CustomButton
              type="button"
              classButton={styles.button}
              classContainer={styles.buttonContainer}
              onClick={onDeleteClickCb}
            >
              <span>{t([I18N_SCOPE_COMMON, 'delete'])}</span>
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CertificatesListItem);
