import type {FC, HTMLProps} from 'react';
import React, {memo, useCallback} from 'react';

import classNames from 'classnames';

import styles from './Switch.module.css';

interface Props
  extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'checked'> {
  id?: string;
  label?: string;
  isOn?: boolean;
  onChange?: (isOn: boolean) => void;
}

const Switch: FC<Props> = ({id, label, isOn, onChange, disabled, ...rest}) => {
  id = id || Math.random().toString(36).substring(2);

  const onChangeCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.currentTarget.checked);
    },
    [onChange],
  );

  return (
    <div
      className={classNames(
        'Switch',
        styles.Switch,
        disabled && styles.disabled,
      )}
    >
      {label ? <span className={styles.label}>{label}</span> : null}
      <div className={styles.container}>
        <input
          {...rest}
          id={id}
          type="checkbox"
          checked={isOn}
          onChange={onChangeCb}
          disabled={disabled}
        />
        <label htmlFor={id} />
      </div>
    </div>
  );
};

export default memo(Switch);
