import type {FC} from 'react';
import React from 'react';

import IconFreezeSVG from '@src/assets/img/freeze.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconFreeze: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = '',
  viewBox = '0 0 14 14',
}) => {
  return (
    <IconFreezeSVG
      width={height}
      height={width}
      className={className}
      fill="#ffffff"
      viewBox={viewBox}
    />
  );
};

export default IconFreeze;
