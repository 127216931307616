import type {FC} from 'react';
import React from 'react';

import Chat from '@yourcoach/shared/assets/icons/chat.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconChat: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'icon',
  viewBox = '0 0 24 24',
}) => {
  return (
    <div className={`IconChat fill ${className}`}>
      <Chat fill="#819AA9" height={height} width={width} viewBox={viewBox} />
    </div>
  );
};

export default IconChat;
