import type {FC} from 'react';
import React from 'react';

import Coach from '@yourcoach/shared/assets/icons/coach.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
}

const IconCoach: FC<Props> = ({
  height = '100%',
  width = '100%',
  customClass = 'icon',
}) => {
  return (
    <div className={`fill ${customClass}`}>
      <Coach fill="#819AA9" height={height} width={width} />
    </div>
  );
};

export default IconCoach;
