import React, {useCallback, useEffect, useMemo, useState} from 'react';

import classNames from 'classnames';

import type {
  PaymentPlan,
  ProgramPaymentType,
} from '@yourcoach/shared/api/program';
import {
  createPaymentPlan,
  parsePaymentPlan,
  ProgramPaymentTypeEnum,
} from '@yourcoach/shared/api/program';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import styles from './PaymentPlan.module.css';

export const I18N_SCOPE = 'shared.CRUProgramPaymentPlan';

export type Props = {
  paymentPlan: PaymentPlan;
  duration: number;
  onSet: (paymentPlan: PaymentPlan) => void;
};

const CRUProgramPaymentPlan: React.FC<Props> = ({
  duration,
  paymentPlan: paymentPlanProp,
  onSet,
}) => {
  const [paymentType, setPaymentType] = useState(
    ProgramPaymentTypeEnum.ONE_TIME,
  );

  const parsedPaymentPlan = useMemo(
    () => parsePaymentPlan(paymentPlanProp),
    [paymentPlanProp],
  );

  useEffect(() => {
    setPaymentType(parsedPaymentPlan.paymentType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaymentTypeChange = useCallback((type: ProgramPaymentType) => {
    setPaymentType(type);
  }, []);

  const onSetButtonClick = useCallback(() => {
    onSet(
      createPaymentPlan({
        price: parsedPaymentPlan.total,
        duration,
        paymentType,
        currency: parsedPaymentPlan.parsedCurrency,
        processing: paymentPlanProp.processing,
      }),
    );
  }, [
    duration,
    onSet,
    parsedPaymentPlan.parsedCurrency,
    parsedPaymentPlan.total,
    paymentPlanProp.processing,
    paymentType,
  ]);

  return (
    <div>
      <div className={styles.paymentTypesContainer}>
        {[ProgramPaymentTypeEnum.ONE_TIME, ProgramPaymentTypeEnum.MONTHLY].map(
          type => {
            const isSelected = type.id === paymentType.id;

            const paymentPlan = parsePaymentPlan(
              createPaymentPlan({
                price: parsedPaymentPlan.total,
                duration,
                paymentType: type,
                currency: parsedPaymentPlan.parsedCurrency,
              }),
            );

            return (
              <div
                key={type.id}
                className={classNames(
                  styles.paymentType,
                  isSelected && styles.selected,
                )}
                onClick={() => onPaymentTypeChange(type)}
              >
                <p className={styles.paymentTypeText}>{type.toString()}</p>
                <p className={styles.paymentTypePriceText}>
                  {paymentPlan.toString()}
                </p>
              </div>
            );
          },
        )}
      </div>
      <div className={styles.footer}>
        <Button onClick={onSetButtonClick} disabled={!duration}>
          {t([I18N_SCOPE, 'set_button'])}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(CRUProgramPaymentPlan);
