import React from 'react';

export const markLinks = text => {
  const RE_URL = /\w+:\/\/\S+/g;

  let match;

  const results: React.ReactElement[] = [];

  let lastIndex = 0;

  while ((match = RE_URL.exec(text))) {
    const link = match[0];

    if (lastIndex !== match.index) {
      const word = text.substring(lastIndex, match.index);

      results.push(<span key={results.length}>{word}</span>);
    }

    results.push(
      <a key={results.length} href={link} target="_blank">
        {link}
      </a>,
    );
    lastIndex = match.index + link.length;
  }

  if (results.length === 0) {
    return text;
  }

  if (lastIndex !== text.length) {
    results.push(<span key={results.length}>{text.substring(lastIndex)}</span>);
  }

  return results;
};
