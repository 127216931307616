import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

export const expand = {
  course: [
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: [
          'avatar_id',
          'background_id',
          [
            'coach_ids->expanded_coaches',
            {name: 'Coach not found', coach_title: 'Coach not found'},
            userExpand,
          ],
          'edition_ids',
        ],
      },
    ],
    ['edition_id', null, {edition: ['contract_id']}],
    ['client_id', {name: 'Client not found'}, userExpand],
  ],
};

export type ExpandedCoach = (
  | User
  | (Partial<User> & Required<Pick<User, 'name' | 'coach_title'>>)
) &
  UserExpanded;

export type ExpandedClient = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) &
  UserExpanded;

export interface Expanded {
  program: (Program | (Partial<Program> & Required<Pick<Program, 'title'>>)) & {
    avatar?: IFile | null;
    background?: IFile | null;
    expanded_coaches: ExpandedCoach[];
    editions: Edition[];
  };
  edition?:
    | (Edition & {
        contract?: IFile | null;
      })
    | null;
  client: ExpandedClient;
}
