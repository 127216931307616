import type {FC} from 'react';
import React, {memo, useCallback, useMemo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {getSubgoalColor} from '@yourcoach/shared/api/goal/utils';
import CommentIcon from '@yourcoach/shared/assets/icons/comment.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {IconCheck} from '@src/components/icons';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';

import type {BoundSubgoal} from '../Goal/Goal';

import styles from './../styles.module.scss';

interface Props<T extends BoundSubgoal = BoundSubgoal> {
  subgoal: T;
  onPress?: (subgoal: T, boundElement: DOMRect) => void;
  className?: string;
  isSelect?: boolean;
}

const BoundSubgoalsListItem: FC<Props> = ({
  subgoal,
  onPress,
  className = '',
  isSelect = false,
}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress(subgoal, thisRef.current!.getBoundingClientRect());
  }, [subgoal, onPress]);

  const thisRef = useRef<HTMLDivElement | null>(null);

  const color = useMemo(
    () => getSubgoalColor(subgoal.color, themes.light),
    [subgoal.color],
  );

  const isReached = useMemo(() => {
    if (subgoal.checkpoints.length) {
      return subgoal.checkpoints.every(item => !!item.reached);
    }

    return subgoal.target_value === subgoal.current_value;
  }, [subgoal.checkpoints, subgoal.current_value, subgoal.target_value]);

  const reachedCheckpointsCount = useMemo(
    () => subgoal.checkpoints.filter(item => !!item.reached).length,
    [subgoal.checkpoints],
  );

  return (
    <Observer>
      {() => (
        <div
          ref={thisRef}
          className={`BoundSubgoalsListItem ${styles.BoundSubgoalsListItem} ${
            onPress ? styles.onClickable : ''
          } ${isSelect ? styles.active : ''} ${className}`}
          style={{backgroundColor: isSelect ? color : undefined}}
          onClick={onPressCb}
        >
          <div className={styles.userContainer}>
            <div className={styles.userAvatar}>
              <OtherUserProfileImg
                avatar={subgoal.client!.avatar}
                title={subgoal.client!.name}
                isOverlay={!subgoal.client!.avatar}
              />
            </div>
            <div className={styles.userName}>{subgoal.client.name}</div>
          </div>
          <div className={styles.progressContainer}>
            {subgoal.client_comment ? (
              <div className={styles.commentIconContainer}>
                <CommentIcon
                  fill={isSelect ? '#ffffff' : '#819AA9'}
                  width={16}
                  height={16}
                />
              </div>
            ) : null}
            {isReached ? (
              <div className={styles.checkIconContainer}>
                <IconCheck customClass={styles.checkIcon} />
              </div>
            ) : null}
            <div
              className={`${styles.progress} ${
                isReached ? styles.isReached : ''
              }`}
            >
              <div className={styles.checkpoints}>
                {subgoal.checkpoints.length
                  ? `${reachedCheckpointsCount}`
                  : `${subgoal.current_value}`}
              </div>
              {subgoal.checkpoints.length
                ? ` /${subgoal.checkpoints.length}`
                : ` /${subgoal.target_value}${
                    subgoal.unit ? ` ${subgoal.unit}` : ''
                  }`}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(BoundSubgoalsListItem);
