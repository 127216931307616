/**
 * Styles for the TaskStatus component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {
  getThemeSize,
  makeColorScheme,
  switchProp,
} from '@yourcoach/shared/styles/utils';

import type {StyledProps, Variant} from './types';

export const scheme = makeColorScheme<
  Required<StyledProps>,
  'primary' | 'bg' | 'border'
>({
  done: {
    primary: 'text2',
    bg: 'button.fill1',
    border: 'button.fill1',
  },
  pending: {
    primary: 'text3',
    bg: 'button.fill3',
    border: 'button.fill3',
  },
  overdue: {
    primary: 'error',
    bg: 'transparent',
    border: 'error',
  },
  frozen: {
    primary: 'text3',
    bg: 'button.fill3',
    border: 'button.fill3',
  },
});

// export const text = css`
//   color: ${scheme.getColor('primary')};
//   ${switchProp<Variant, StyledProps>('sizeVariant', {
//     default: css<StyledProps>`
//       font-size: 14px;
//     `,
//     tag: css<StyledProps>`
//       font-size: 12px;
//     `,
//   })};
// `;

const container = css<StyledProps>`
  justify-content: center;
  align-items: center;
  background-color: ${scheme.getColor('bg')};
  border-width: 1px;
  border-style: solid;
  border-color: ${scheme.getColor('border')};
  color: ${scheme.getColor('primary')};
  ${switchProp<Variant, StyledProps>('sizeVariant', {
    default: css<StyledProps>`
      gap: 5px;
      height: 32px;
      width: 128px;
      border-radius: ${getThemeSize('borderRadius.m')}px;
    `,
    tag: css<StyledProps>`
      height: 20px;
      border-radius: 4px;
      padding: 0 5px;
    `,
  })};
`;

export const webContainer = css<StyledProps>`
  box-sizing: border-box;
  ${container}
`;

export const nativeContainer = css<StyledProps>`
  ${container}
`;
