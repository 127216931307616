import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Observer} from 'mobx-react';

import CustomConfetti from '../CustomConfetti/CustomConfetti';

import styles from './styles.module.css';

interface ICustomBut {
  label: string;
  onClick?: (resolve?: any) => Promise<any> | void;
  className?: string;
  type?: 'standard' | 'attention' | 'danger' | 'confirm';
}

interface Props {
  onClose: () => void;
  buttons: ICustomBut[];
  childrenElement?: ReactNode;
  title?: string;
  isCongrats?: boolean;
  message?: ReactNode;
  className?: string;
  classNameTitle?: string;
  classNameMessage?: string;
  classNameButtContainer?: string;
  classNameElement?: string;
  resolve: (value?: any) => void;
}

const CustomConfirmAlert: FC<Props> = ({
  onClose,
  buttons,
  title,
  message,
  childrenElement,
  isCongrats = false,
  className = '',
  classNameTitle = '',
  classNameMessage = '',
  classNameButtContainer = '',
  classNameElement = '',
  resolve,
}) => {
  return (
    <Observer>
      {() => (
        <>
          {isCongrats ? <CustomConfetti /> : null}
          <div
            className={`CustomConfirmAlert ${styles.CustomConfirmAlert} ${className}`}
          >
            {childrenElement ? (
              <>
                <div
                  className={`${styles.elementContainer} ${classNameElement}`}
                >
                  {childrenElement}
                </div>
                <br />
              </>
            ) : null}
            {title ? (
              <h1 className={`${styles.titleContainer} ${classNameTitle}`}>
                {title}
              </h1>
            ) : null}

            {message ? (
              <div className={`${styles.messageContainer} ${classNameMessage}`}>
                {message}
              </div>
            ) : null}
            <div
              className={`${styles.buttonsContainer} ${classNameButtContainer}`}
            >
              {buttons.map(button => {
                return (
                  <div
                    key={button.label}
                    className={`${styles.buttContainer} ${
                      button.className ? button.className : ''
                    } ${
                      button.type
                        ? button.type === 'standard'
                          ? styles.standard
                          : button.type === 'confirm'
                          ? styles.confirm
                          : button.type === 'attention'
                          ? styles.attention
                          : button.type === 'danger'
                          ? styles.danger
                          : ''
                        : ''
                    }`}
                    onClick={() => {
                      if (button.onClick) {
                        button.onClick(resolve);
                      }

                      resolve();
                      onClose();
                    }}
                  >
                    {button.label}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Observer>
  );
};

export default memo(CustomConfirmAlert);

export const getCustomConfirmAlert = (options: {
  buttons: ICustomBut[];
  title?: string;
  isCongrats?: boolean;
  childrenElement?: ReactNode;
  message?: ReactNode;
  className?: string;
  classNameTitle?: string;
  classNameMessage?: string;
  classNameButtContainer?: string;
  classNameElement?: string;
}) => {
  return new Promise(resolve => {
    confirmAlert({
      overlayClassName: styles.overlayCustomClassName,
      customUI: ({onClose}) => {
        return (
          <CustomConfirmAlert
            onClose={onClose}
            resolve={resolve}
            buttons={options.buttons}
            isCongrats={options.isCongrats}
            childrenElement={options.childrenElement}
            title={options.title}
            message={options.message}
            className={options.className}
            classNameTitle={options.classNameTitle}
            classNameMessage={options.classNameMessage}
            classNameButtContainer={options.classNameButtContainer}
            classNameElement={options.classNameElement}
          />
        );
      },
    });
  });
};

export const confirm = (
  {
    title,
    message,
    buttons,
  }: {title?: string; message?: string; buttons?: string[]} = {
    title: '',
    message: '',
    buttons: [],
  },
) =>
  new Promise<boolean>(resolve => {
    confirmAlert({
      overlayClassName: styles.overlayCustomClassName,
      customUI: ({onClose}) => {
        return (
          <CustomConfirmAlert
            onClose={onClose}
            resolve={() => {}}
            buttons={
              buttons && buttons.length === 2
                ? [
                    {
                      label: buttons[0],
                      onClick: () => resolve(false),
                    },
                    {
                      label: buttons[1],
                      type: 'danger',
                      onClick: () => resolve(true),
                    },
                  ]
                : [
                    {
                      label: 'No',
                      onClick: () => resolve(false),
                    },
                    {
                      label: 'Yes',
                      type: 'danger',
                      onClick: () => resolve(true),
                    },
                  ]
            }
            title={title || 'Are you sure?'}
            message={message}
          />
        );
      },
    });
  });
