import type {FC, ReactElement} from 'react';
import React, {memo, useCallback, useRef, useState} from 'react';

interface FlowItemProps {
  path: string;
  children: ReactElement | ReactElement[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _FlowItem: FC<FlowItemProps> = ({path, children}) => <>{children}</>;

export const FlowItem = memo(_FlowItem);

interface Props {
  transitionFn: (path: string, action: string) => string | null;
  children: ReactElement | ReactElement[];
}

const ScreenFlow: FC<Props> = ({transitionFn, children}) => {
  const [path, setPath] = useState('/');
  const history = useRef<{path: string; value: any}[]>([
    {path: '/', value: null},
  ]).current;
  const data = useRef({}).current;

  const onClick = useCallback(
    e => {
      const [action, kind] = (
        e.target.attributes['data-action'] || {value: ''}
      ).value.split(' ');

      if (!action) {
        history.pop();
        setPath(history[history.length - 1].path);
      } else {
        const newPath = transitionFn(path, action);

        if (newPath) {
          if (kind === 'replace') {
            history[history.length - 1] = {path: newPath, value: null};
          } else {
            history.push({path: newPath, value: null});
          }

          setPath(newPath);
        }
      }
    },
    [history, path, transitionFn],
  );

  const onChange = useCallback(
    (value, fieldName = '') => {
      history[history.length - 1].value = value;

      if (fieldName) {
        data[fieldName] = value;
      }
    },
    [data, history],
  );

  const onData = useCallback(
    (value, fieldName) => {
      data[fieldName] = value;
    },
    [data],
  );

  return React.Children.map<ReactElement | null, ReactElement>(children, c => {
    if (c.props.path === path) {
      return React.Children.map(c.props.children, cc =>
        React.cloneElement(cc, {
          onClick: onClick,
          onChange: onChange,
          onData: onData,
          value: history[history.length - 1]?.value,
          data: data,
        }),
      );
    }

    return null;
  })[0];
};

export default memo(ScreenFlow);
