import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as JoinProgramProps} from './JoinProgram';
import JoinProgram, {I18N_SCOPE} from './JoinProgram';
import styles from './JoinProgram.module.css';

type Props = Partial<ModalProps> & JoinProgramProps;

const JoinProgramModal: React.FC<Props> = ({
  program,
  onSuccess,
  children,
  ...modalProps
}) => (
  <Modal
    {...modalProps}
    title={t([I18N_SCOPE, 'title'])}
    withStickyFooter
    className={styles.modal}
    bodyClassName={styles.modalBody}
  >
    <JoinProgram program={program} onSuccess={onSuccess} />
    {children}
  </Modal>
);

export default React.memo(JoinProgramModal);
