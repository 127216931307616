import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import InView from 'react-intersection-observer';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {ApiRpcQuery, CollectionStore, Expand} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  datetimeObjToISOString,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';
import {logger} from '@yourcoach/shared/utils/logger';

import ModalAnimateWin from '../../../components/GoalsModal/GoalsModal';
import {IconCup} from '../../../components/icons';
import NoResults from '../../../components/NoResults/NoResults';
import ScrollToElement from '../../../components/ScrollToElement/ScrollToElement';
import {MAX_MOBILE_BREAKPOINT, MIN_MOBILE_BREAKPOINT} from '../../../const';
import AppContext from '../../../context/App';
import localAppStore from '../../../context/appStore';
import useURLQueryParam from '../../../hooks/useURLQueryParam';
import {useWindowDimensions} from '../../../hooks/useWindowDimensions';
import ClientSubgoalsButton, {
  CLIENT_SUBGOALS_BUTTON_ID,
} from '../ClientSubgoalsButton/ClientSubgoalsButton';
import CRUSubgoal from '../CRUSubgoal/CRUSubgoal';
import type {GoalT} from '../Goal/Goal';
import type {Course as CourseProps} from '../GoalsSelectCourseTab/GoalsSelectCourseTab';
import SubgoalsListItem from '../SubgoalsListItem/SubgoalsListItem';

import styles from './../styles.module.css';

const LIMIT = 100;

const expand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
          ['edition_id', null, {edition: ['contract_id']}],
        ],
      },
    ],
  ],
};

export type ExpandedClient =
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>);

type TCourse = Course & {
  client: ExpandedClient;
  edition?:
    | (Edition & {
        contract?: IFile | null;
      })
    | null;
};

export type Goal = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: TCourse;
};

type GoalListItemT = Omit<Goal, 'subgoals'> & {
  subgoals: SubgoalListItemT[];
  index: number;
  isLast?: boolean;
};

type SubgoalListItemT = Subgoal & {
  isLast?: boolean;
};

type ListItemT = GoalListItemT | SubgoalListItemT;

interface Section {
  title?: string;
  program: Goal['program'];
  course?: Goal['course'];
  dataGoal: ListItemT[][];
}

interface ILocalStore {
  _onListItemPress: (item: ListItemT, goal: GoalT, userId?: string) => void;
  goalsStore: CollectionStore<Goal> | null;
  setGoalsStore(goalsStore: CollectionStore<Goal>): void;
  userIsCoach: boolean;
  shouldRenderStub: boolean;
  sections: Section[];
  selectGoalId: string | null;
  setSelectGoalId(selectGoalId: string | null): void;
  selectUrlGoalId: string | null;
  setSelectUrlGoalId(selectUrlGoalId: string | null): void;
  selectUrlSubgoalId: string | null;
  setSelectUrlSubgoalId(selectUrlSubgoalId: string | null): void;
  selectGoal: Goal | null;
  setSelectGoal(selectGoal: Goal | null): void;
  selectCourse: TCourse | null;
  setSelectCourse(selectCourse: TCourse | null): void;
  selectSubgoal: Subgoal | null;
  setSelectSubgoal(selectSubgoal: Subgoal | null): void;
  isOpenCRUSubgoalModule: boolean;
  setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean): void;
}

interface Props {
  course: CourseProps | null;
  selectGoalId?: string | null;
  onSelectSubgoal?: (subgoal: Subgoal, goal: IGoal, userId?: string) => void;
  onSelectClientGoals?: (goal: IGoal) => void;
}

const GoalsTab: FC<Props> = ({
  course,
  selectGoalId,
  onSelectSubgoal,
  onSelectClientGoals,
}) => {
  const goalUrlId = useURLQueryParam('goalId');
  const {width} = useWindowDimensions();

  const {
    stores: {currentUserStore, goalStore},
  } = useContext(AppContext);
  const ref = useRef<CollectionStore<Goal> | null>(null);

  const localStore: ILocalStore = useMemo(
    () => {
      return observable(
        {
          _onListItemPress: (item: ListItemT, goal: GoalT, userId?: string) => {
            const isSubgoal = item._id.split(':')[0] === 'subgoal';

            localStore.setSelectUrlSubgoalId(null);

            if (isSubgoal && onSelectSubgoal) {
              localStore.setSelectGoalId(item._id);
              onSelectSubgoal(item as Subgoal, goal, userId);
            }
          },
          selectSubgoal: null,
          setSelectSubgoal(selectSubgoal: Subgoal | null) {
            this.selectSubgoal = selectSubgoal;
          },
          isOpenCRUSubgoalModule: false,
          setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean) {
            this.isOpenCRUSubgoalModule = isOpenCRUSubgoalModule;
          },
          selectUrlGoalId: null,
          setSelectUrlGoalId(selectUrlGoalId: string | null) {
            this.selectUrlGoalId = selectUrlGoalId;
          },
          selectUrlSubgoalId: null,
          setSelectUrlSubgoalId(selectUrlSubgoalId: string | null) {
            this.selectUrlSubgoalId = selectUrlSubgoalId;
          },
          selectGoal: null,
          setSelectGoal(selectGoal: Goal | null) {
            this.selectGoal = selectGoal;
          },
          selectCourse: null,
          setSelectCourse(selectCourse: TCourse | null) {
            this.selectCourse = selectCourse;
          },
          selectGoalId: null,
          setSelectGoalId(newSelectGoalId: string | null) {
            this.selectGoalId = newSelectGoalId;
          },

          goalsStore: null,
          setGoalsStore(goalsStore: CollectionStore<Goal>) {
            this.goalsStore = goalsStore;
          },
          get userIsCoach() {
            return (
              currentUserStore.user &&
              currentUserStore.user.roles.includes('coach')
            );
          },
          get shouldRenderStub() {
            if (this.goalsStore) {
              return this.goalsStore.isLoaded && !this.goalsStore.hasItems;
            } else {
              return false;
            }
          },
          get sections() {
            const sections: Section[] = [];

            if (this.goalsStore) {
              let goals = this.goalsStore.items.slice();

              const groups: {[key: string]: Goal[]} = {};

              const groupBy = (goal: Goal) => {
                const groupId = goal.course_id || goal.program_id;

                groups[groupId] = groups[groupId] || [];

                groups[groupId].push(goal);
              };

              goals.forEach(groupBy);

              Object.keys(groups).forEach(key => {
                const dataGoal: ListItemT[][] = [];

                groups[key]
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .forEach((goal, gIndex) => {
                    const data: ListItemT[] = [];

                    data.push({
                      ...goal,
                      index: gIndex,
                      subgoals: goal.subgoals.sort(
                        (a, b) => a.sort_order - b.sort_order,
                      ),
                    });

                    const userIsCoach = !!(
                      currentUserStore.user &&
                      goal.coach_ids.includes(currentUserStore.user._id)
                    );

                    if (goal.subgoals.length) {
                      let subgoals = goal.subgoals.slice();

                      if (!userIsCoach) {
                        subgoals = subgoals.sort((a, b) =>
                          b.is_client_managed === a.is_client_managed
                            ? 0
                            : b.is_client_managed
                            ? -1
                            : 1,
                        );
                      } else {
                        subgoals = subgoals.sort(
                          (a, b) => a.sort_order - b.sort_order,
                        );
                      }

                      data.push(...subgoals);

                      subgoals.forEach(item => {
                        if (localStore.selectUrlSubgoalId === item._id) {
                          localStore._onListItemPress(
                            item,
                            goal,
                            // @ts-ignore
                            // location.state.clientId as string,
                          );
                        }
                      });

                      if (
                        !(
                          userIsCoach &&
                          (goal.is_client_managed ||
                            goal.counters.client_subgoals)
                        )
                      ) {
                        data[data.length - 1].isLast = true;
                      }
                    }

                    if (
                      userIsCoach &&
                      (goal.is_client_managed || goal.counters.client_subgoals)
                    ) {
                      data.push({
                        ...goal,
                        index: gIndex,
                        _id: `${CLIENT_SUBGOALS_BUTTON_ID}|${goal._id}`,
                      });
                    }

                    dataGoal.push(data);
                  });

                sections.push({
                  program: groups[key][0].program,
                  course: groups[key][0].course,
                  dataGoal,
                });
              });
            }

            return sections;
          },
        },
        {
          goalsStore: observable.shallow,
          setGoalsStore: action,
          userIsCoach: computed,
          shouldRenderStub: computed,
          sections: computed,
          selectGoalId: observable,
          setSelectGoalId: action,
          selectUrlGoalId: observable,
          setSelectUrlGoalId: action,
          selectUrlSubgoalId: observable,
          setSelectUrlSubgoalId: action,
          selectGoal: observable,
          setSelectGoal: action,
          selectCourse: observable,
          setSelectCourse: action,
          selectSubgoal: observable,
          setSelectSubgoal: action,
          isOpenCRUSubgoalModule: observable,
          setIsOpenCRUSubgoalModule: action,
        },
      );
    },
    // @ts-ignore
    [onSelectSubgoal, currentUserStore.user],
  );

  useEffect(() => {
    if (typeof selectGoalId !== 'undefined') {
      localStore.setSelectGoalId(selectGoalId);
    }

    if (ref.current) {
      localStore.setGoalsStore(ref.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectGoalId]);

  useEffect(() => {
    const disposeGoalStoreSubClientUpdate = reaction(
      () => goalStore.sub.client.updating,
      updating => {
        if (updating.success && updating.entity && localStore.goalsStore) {
          const goalIndex = localStore.goalsStore.items.findIndex(
            item => item._id === updating.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore.items[goalIndex];

            const subGoalIndex = goalItem.subgoal_ids.findIndex(
              item => item === updating.entity!._id,
            );

            if (subGoalIndex >= 0) {
              const subGoalItem = goalItem.subgoals[subGoalIndex];

              goalItem.subgoals[subGoalIndex] = {
                ...subGoalItem,
                ...updating.entity,
              };

              localStore.goalsStore.updateItem(goalItem, goalItem);
            }
          }
        }
      },
    );

    const disposeGoalStoreSubClientCreate = reaction(
      () => goalStore.sub.client.own.creating,
      () => {
        _fetchGoals();
      },
    );

    const disposeGoalStoreSubClientDeleting = reaction(
      () => goalStore.sub.client.own.deleting,
      () => {
        _fetchGoals();
      },
    );

    if (goalUrlId) {
      localAppStore?.setIsTopNotificationsOpen(false);
      localStore.setSelectUrlGoalId(goalUrlId);

      // @ts-ignore
      // if (location.state && location.state.subgoalId) {
      // @ts-ignore
      //  localStore.setSelectUrlSubgoalId(location.state.subgoalId as string);
      // }
    }

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }

      if (ref.current) {
        ref.current.clear();
      }

      disposeGoalStoreSubClientUpdate();
      disposeGoalStoreSubClientCreate();
      disposeGoalStoreSubClientDeleting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref.current = createCollectionStore({
      params: {
        sort: [
          ['sort_order', 1],
          ['created', -1],
        ],
        limit: LIMIT,
        expand,
      },
    });

    localStore.setGoalsStore(ref.current);

    _fetchGoals();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const _fetchGoals = async (silent = false) => {
    ref.current!.setMethod(
      localStore.userIsCoach ? 'coach.goals.unbound.list' : 'client.goals.list',
    );

    await ref
      .current!.fetch(
        {
          query: (
            [
              course
                ? ['course_id', '==', course._id]
                : ['course_id', '!=', null],
              localStore.userIsCoach
                ? ['status', 'in', ['in_progress', 'in_review']]
                : ['status', '==', 'in_progress'],
            ] as ApiRpcQuery[]
          ).filter(Boolean),
          limit: Math.max(ref.current!.items.length, LIMIT),
        },
        {silent},
      )
      .catch(() => {});
  };

  const _onClientSubgoalsButtonPress = (item: ListItemT, goal: GoalT) => {
    localStore.setSelectUrlSubgoalId(null);

    if (onSelectClientGoals) {
      onSelectClientGoals(goal);
    }
  };

  const RenderStub = () => {
    return (
      <Observer>
        {() => (
          <>
            {localStore.shouldRenderStub ? (
              currentUserStore.user?.roles?.includes('coach') ? (
                <NoResults
                  text="No goals here just yet"
                  className={styles.NoResults}
                  classNameTextContainer={styles.textContainer}
                >
                  <div className={styles.iconNoResultContainer}>
                    <IconCup
                      className={styles.iconNoResult}
                      viewBox="4 4 30 30"
                    />
                  </div>
                </NoResults>
              ) : (
                <div className={styles.CenteredNoGoals}>
                  <div>
                    {width >= MIN_MOBILE_BREAKPOINT &&
                    width <= MAX_MOBILE_BREAKPOINT ? (
                      <img
                        className={styles.IconNoGoals}
                        src="https://static.hcod.yourcoach.health/NoGoalsIcon.png"
                        alt="no-goals"
                      />
                    ) : (
                      <img
                        className={styles.IconNoGoals}
                        src="https://static.hcod.yourcoach.health/NoGoalsSmallIcon.png"
                        alt="no-goals"
                      />
                    )}
                  </div>
                  <div className={styles.CenteredTextNoGoals}>
                    <p className={styles.ParagraphNoGoals}>
                      You currently have no goals set with your coach
                    </p>
                    <div>
                      This section will be used to track any goals you would
                      like to accomplish as part of your journey with your
                      coach. After your first coaching interaction you and your
                      coach will setup your goal(s) and you will be able to
                      visit this page to add additional short-term goals and
                      update progress while working with your coach.
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </>
        )}
      </Observer>
    );
  };

  const _onAddSubGoalButtonPress = (_course: TCourse) => (item: ListItemT) => {
    logger.event('add_goal_tap');
    localStore.setSelectGoal(item as Goal);
    localStore.setIsOpenCRUSubgoalModule(true);
    localStore.setSelectCourse(_course);
  };

  const handleCloseModalCRUSubgoal = () => {
    localStore.setIsOpenCRUSubgoalModule(false);
    localStore.setSelectGoal(null);
    localStore.setSelectSubgoal(null);
    localStore.setSelectCourse(null);
  };

  const RenderItem = memo(
    ({
      item,
      goal,
      _course,
      _selectGoalId,
    }: {
      item: ListItemT;
      goal: ListItemT;
      _course?: Goal['course'];
      _selectGoalId?: string | null;
    }) => {
      return (
        <Observer>
          {() => (
            <>
              <ListItem
                item={item}
                goal={goal}
                selectGoalId={selectGoalId}
                onPress={localStore._onListItemPress}
                onAddSubGoalButtonPress={
                  item.is_client_managed &&
                  _course &&
                  (_course.status === 'planned' ||
                    _course.status === 'ongoing') &&
                  dayjs() < dayjs(datetimeObjToISOString(_course.end_date))
                    ? _onAddSubGoalButtonPress(_course)
                    : undefined
                }
                onClientSubgoalsButtonPress={_onClientSubgoalsButtonPress}
                isModalOpen={localStore.isOpenCRUSubgoalModule}
                handleCloseModal={() => handleCloseModalCRUSubgoal()}
                course={_course}
              />
              <ModalAnimateWin
                showModal={localStore.isOpenCRUSubgoalModule}
                closeModalHandler={handleCloseModalCRUSubgoal}
                className="greyHeaderContainer littleContainer w500 List"
                isBody
                classNameBody="whiteBody maxContent noP30"
                header="Add Short-term goal"
                classNameHeader="greyHeader w500"
                classNameCloseBut="greyHeaderBut"
              >
                {localStore.selectGoal ? (
                  <CRUSubgoal
                    onCloseModal={handleCloseModalCRUSubgoal}
                    goalId={localStore.selectGoal!._id}
                    forClient={true}
                    minDate={dayjs(
                      localStore.selectCourse!.start_date
                        ? datetimeObjToISOString(
                            localStore.selectCourse!.start_date,
                          )
                        : undefined,
                    ).toDate()}
                    maxDate={dayjs().add(90, 'day').toDate()}
                  />
                ) : localStore.selectSubgoal ? (
                  <CRUSubgoal
                    onCloseModal={handleCloseModalCRUSubgoal}
                    goalId={localStore.selectSubgoal.goal_id}
                    forClient={true}
                    subgoal={localStore.selectSubgoal}
                    minDate={dayjs(
                      localStore.selectCourse!.start_date
                        ? datetimeObjToISOString(
                            localStore.selectCourse!.start_date,
                          )
                        : undefined,
                    ).toDate()}
                    maxDate={dayjs().add(90, 'day').toDate()}
                  />
                ) : null}
              </ModalAnimateWin>
            </>
          )}
        </Observer>
      );
    },
  );

  const handleOnChangeInView = inView => {
    if (inView) {
      localStore.setSelectUrlGoalId(null);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`GoalsTab ${styles.GoalsTab}`}>
          {localStore.sections.length <= 0 ? <RenderStub /> : null}
          {localStore.sections.map((section, index) => {
            return (
              <div
                className={styles.sectionContainer}
                key={`${section.program._id}-${
                  section.course ? section.course._id : index
                }`}
              >
                {section.dataGoal.map(goalSection => {
                  return (
                    <div
                      className={styles.goalSection}
                      key={goalSection[0]._id}
                    >
                      {goalSection.map(item => {
                        return (
                          <div className={styles.itemContainer} key={item._id}>
                            <RenderItem
                              _course={section.course}
                              item={item}
                              goal={goalSection[0]}
                              _selectGoalId={localStore.selectGoalId}
                            />
                          </div>
                        );
                      })}
                      {localStore.selectUrlGoalId === goalSection[0]._id ? (
                        <InView as="div" onChange={handleOnChangeInView}>
                          <ScrollToElement isSmooth />
                        </InView>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </Observer>
  );
};

export default memo(GoalsTab);

interface ListItemProps<T extends ListItemT = ListItemT> {
  item: T;
  goal: T;
  selectGoalId?: string | null;
  onPress?: (item: T, goal: GoalT) => void;
  onClientSubgoalsButtonPress?: (item: T, goal: GoalT) => void;
  onAddSubGoalButtonPress?: (item: ListItemT) => undefined;
  isOpenModal: boolean;
  handleCloseModal: () => void;
  course: Goal['course'];
}

const ListItem: FC<ListItemProps> = ({
  item,
  goal,
  selectGoalId,
  onPress,
  onClientSubgoalsButtonPress,
  onAddSubGoalButtonPress,
  isOpenModal,
  handleCloseModal,
  course,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const onPressCb = useCallback(() => {
    onPress && onPress(item, goal as GoalT);
    logger.event('open_goal_tap');
  }, [item, goal, onPress]);

  const onClientSubgoalsButtonPressCb = useCallback(() => {
    onClientSubgoalsButtonPress &&
      onClientSubgoalsButtonPress(item, goal as GoalT);
  }, [onClientSubgoalsButtonPress, item, goal]);

  const isGoal = useMemo(() => item._id.split(':')[0] === 'goal', [item._id]);

  const isSubgoal = useMemo(
    () => item._id.split(':')[0] === 'subgoal',
    [item._id],
  );

  const isGoalHasSubgoals = useMemo(
    () => !!((item as Goal).subgoals && (item as Goal).subgoals.length),
    [item],
  );

  const isClientManagedGoal = useMemo(
    () => isGoal && (item as Goal).is_client_managed,
    [isGoal, item],
  );

  const isCoach = useMemo(
    () => user && item.coach_ids.includes(user._id),
    [item.coach_ids, user],
  );

  const AddShortTermGoal = () => {
    return (
      <div
        className={styles.AddShortTermGoals}
        onClick={isGoal ? () => onAddSubGoalButtonPress(item) : undefined}
      >
        + Add Short-term goals
      </div>
    );
  };

  return (
    <Observer>
      {() => (
        <div>
          <div className={styles.listItemContainer}>
            {isGoal ? (
              <>
                <div className={styles.listItemContentContainer}>
                  <div className={styles.goalIndex}>
                    Main goal #{(item as GoalListItemT).index + 1}
                  </div>
                  <div className={styles.goalTitle}>{item.title}</div>
                </div>

                <div className={styles.subgoalsHeaderContainerStyle}>
                  {isCoach ||
                  (!isCoach && (isGoalHasSubgoals || isClientManagedGoal)) ? (
                    <div className={styles.subgoalsHeaderLabel}>
                      {(item as GoalListItemT).subgoals.label}
                    </div>
                  ) : null}
                </div>
              </>
            ) : isSubgoal ? (
              <>
                <SubgoalsListItem
                  subgoal={item as SubgoalListItemT}
                  onPress={onPressCb}
                  selectGoalId={selectGoalId}
                  showNextIcon
                />
              </>
            ) : item._id.includes(CLIENT_SUBGOALS_BUTTON_ID) ? (
              <ClientSubgoalsButton onPress={onClientSubgoalsButtonPressCb} />
            ) : null}
            {isGoal && isClientManagedGoal ? (
              <>
                <AddShortTermGoal />
                <ModalAnimateWin
                  showModal={isOpenModal}
                  closeModalHandler={handleCloseModal}
                  className="greyHeaderContainer littleContainer w500 List h100"
                  isBody
                  classNameBody="whiteBody maxContent noP30"
                  header="Add Short-term goal"
                  classNameHeader="greyHeader w500"
                  classNameCloseBut="greyHeaderBut"
                >
                  {selectGoalId ? (
                    <CRUSubgoal
                      onCloseModal={handleCloseModal}
                      goalId={selectGoalId}
                      forClient={true}
                      minDate={dayjs(
                        course?.start_date
                          ? datetimeObjToISOString(course.start_date)
                          : undefined,
                      ).toDate()}
                      maxDate={dayjs().add(90, 'day').toDate()}
                    />
                  ) : null}
                </ModalAnimateWin>
              </>
            ) : null}
          </div>
        </div>
      )}
    </Observer>
  );
};
