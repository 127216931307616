import type {FC} from 'react';
import React, {memo} from 'react';

import TopMenuPublic from '@src/modules/TopMenuPublic/TopMenuPublic';

interface Props {}

const TopMenuLayoutPublic: FC<Props> = () => {
  return (
    <div className="TopMenuLayoutPublic">
      <TopMenuPublic />
    </div>
  );
};

export default memo(TopMenuLayoutPublic);
