import type {FC} from 'react';
import React, {memo} from 'react';

import LoaderSVG from '@src/assets/img/loader.svg';

interface Props {
  size?: number;
  width?: string;
  height?: string;
  customClass?: string;
}

const Loader: FC<Props> = ({
  size,
  height = '100',
  width = '100',
  customClass = '',
}) => {
  if (size !== undefined) {
    width = `${size}`;
    height = `${size}`;
  }

  return (
    <div className={`Loader ${customClass}`}>
      <LoaderSVG height={height} width={width} />
    </div>
  );
};

export default memo(Loader);
