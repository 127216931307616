import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import Loader from '@src/components/Loader/Loader';
import {t} from '@src/i18n';

import styles from './DocumentViewer.module.css';

const I18N_SCOPE = 'DocumentViewer';

export interface Props {
  document: IFile;
}

const DocumentViewer: React.FC<Props> = ({document}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const src = useMemo(() => {
    const fileSrc = getFileSrc(document);

    return `https://docs.google.com/gview?embedded=true&url=${fileSrc.url}`;
  }, [document]);

  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let iframe = iframeRef.current;

    if (iframe) {
      iframe.addEventListener('load', onLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onLoad);
      }
    };
  }, [onLoad]);

  return (
    <div className={classNames('DocumentViewer', styles.Component)}>
      <iframe ref={iframeRef} src={src}>
        {t([I18N_SCOPE, 'not_supported_message'])}
      </iframe>
      {isLoading ? (
        <div className={styles.loadingSpinnerContainer}>
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default memo(DocumentViewer);
