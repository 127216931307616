import {useCallback, useRef, useState} from 'react';

export const useSessionType = () => {
  const startTime = useRef(Date.now());
  const [disabledVideo, setDisabledVideo] = useState({
    duration: 0,
    period: [] as number[],
  });

  const handleSessionType = useCallback(
    (videoIsDisabled: boolean) => {
      if (videoIsDisabled) {
        setDisabledVideo({
          period: [Date.now()],
          duration: disabledVideo.duration,
        });
      } else {
        const duration =
          Date.now() - disabledVideo.period?.[0] + disabledVideo.duration;

        setDisabledVideo({
          duration,
          period: [],
        });
      }
    },
    [disabledVideo],
  );

  const getSessionType = useCallback(() => {
    const sessionDuration = Date.now() - startTime.current;

    if (disabledVideo.period?.[0]) {
      const duration =
        Date.now() - disabledVideo.period?.[0] + disabledVideo.duration;

      return sessionDuration - duration > sessionDuration / 2
        ? 'video'
        : 'audio';
    } else {
      return sessionDuration - disabledVideo.duration > sessionDuration / 2
        ? 'video'
        : 'audio';
    }
  }, [disabledVideo]);

  return {
    disabledVideo,
    onChangeSessionType: handleSessionType,
    getSessionType,
  };
};
