/**
 * Styles for the IconButton component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

//import {getColor, makeColorScheme, switchProp} from '@yourcoach/shared/styles/utils';
import type {StyledProps} from './types';

const iconBox = css<StyledProps>`
  margin-right: 10px;
`;

const button = css<StyledProps>`
  min-width: ${p => (p.buttonVariant === 'default' ? '150px' : 'initial')};
  box-shadow: 0 4px 20px rgba(66, 56, 181, 0.1);
  ${p =>
    p.buttonVariant === 'rounded' &&
    css<StyledProps>`
      border-radius: 40px;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      height: max-content;
    `};
`;

export const iconBoxWeb = css<StyledProps>`
  ${iconBox};
`;

export const buttonWeb = css<StyledProps>`
  box-sizing: border-box;
  ${button}
`;
