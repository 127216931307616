import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import noEmailImg from '@src/assets/img/questionnaireNoEmail.png';
import {
  labelAddEmail,
  labelAddEmails,
  labelYouEnteredAnIncorrectEmail,
} from '@src/common/i18n/i18nCommon';
import {labelSendQuestionnaire} from '@src/common/i18n/i18nQuestionnaire';
import {CustomButton, CustomInput} from '@src/components/CustomForm';
import {IconUserPlus} from '@src/components/icons';

import QSendUsersContext from '../../context/QSendUsersContext';
import type {ILocalStoreQSendUsers} from '../../context/QSendUsersLocalStore';
import SendsElements from '../../SendsElements/SendsElements';

import styles from './../../styles.module.css';

interface Props {}

interface ILocalStore {
  email: string;
  setEmail(newVal: string): void;
  errorText: string;
  setErrorText(newError: any): void;
}

const ByEmail: FC<Props> = () => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        email: '',
        setEmail(newVal: string) {
          this.email = newVal;
        },
        errorText: '',
        setErrorText(newError) {
          this.errorText = newError;
        },
      },
      {
        email: observable,
        errorText: observable,
        setEmail: action,
        setErrorText: action,
      },
    ),
  ).current;

  const handleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    localStore.setErrorText('');
    localStore.setEmail(event?.currentTarget.value!);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addEmail();
    }
  };

  const handleClickSendQ = () => {
    if (storeQSendUsers.countSelectUser > 0) {
      storeQSendUsers.sentQEmail();
    }
  };

  const handleClickAddEmail = () => {
    addEmail();
  };

  const addEmail = () => {
    const reg = /^[\w.%+-]+@[A-Z\d-].{2,}[A-Z]{2}$/i;

    if (reg.test(localStore.email)) {
      storeQSendUsers.addEmail(localStore.email);
      localStore.setEmail('');
    } else {
      localStore.setErrorText(labelYouEnteredAnIncorrectEmail());
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`ByEmail ${styles.mainContainer}`}>
          <div className={styles.inputContainer}>
            <CustomInput
              type="input"
              label={labelAddEmail()}
              customClassLabel="standardLabel"
              customClassInput={'standardInput border'}
              id="addEmail"
              value={localStore.email}
              onChange={handleOnChange}
              onKeyPress={handleKeyPress}
              showErrorImmediately
              error={localStore.errorText}
            />
            <div className={styles.addBut} onClick={handleClickAddEmail}>
              <IconUserPlus customClass={styles.icoUserPlus} />
            </div>
          </div>
          <div className={styles.resultContainer}>
            <Scrollbar
              className={`scrollbar ${styles.mainSectionContainer}`}
              noScrollX
              wrapperProps={{className: 'wrapper'}}
              trackYProps={{className: 'trackY'}}
              thumbYProps={{className: 'thumbY'}}
              trackXProps={{className: 'trackX'}}
              thumbXProps={{className: 'thumbY'}}
            >
              {storeQSendUsers.usersEmailList.length > 0 ? (
                <SendsElements />
              ) : (
                <div className={styles.noUserContainer}>
                  <img src={noEmailImg} />
                  <div className={styles.textNoContent}>{labelAddEmails()}</div>
                </div>
              )}
            </Scrollbar>
          </div>

          <div className={styles.footerButContainer}>
            <div className={styles.footerAddAllBut} />
            <div className={styles.SendQBut}>
              <CustomButton
                type="button"
                classButton="blueButt"
                disabled={storeQSendUsers.countSelectUser <= 0}
                onClick={handleClickSendQ}
              >
                <span>{labelSendQuestionnaire()}</span>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ByEmail);
