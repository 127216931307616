import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {DayPicker} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, toJS} from 'mobx';
import moment from 'moment';

import type {
  ApiRpcQuery,
  CollectionStore,
  DateTimeObj,
} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  datetimeObjToISOString,
  expandObj,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Course as ICourse} from '@yourcoach/shared/api/course';
import {courseIsEditable} from '@yourcoach/shared/api/course';
import type {Material as IMaterial} from '@yourcoach/shared/api/material';

import IconArrowLeft from '../../../../assets/arrow-left.svg';
import {setError} from '../../../../common/setError';
import {getCustomConfirmAlert} from '../../../../components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton} from '../../../../components/CustomForm';
import ModalAnimateWin from '../../../../components/GoalsModal/GoalsModal';
import {IconNewFolder, IconNext, IconPrev} from '../../../../components/icons';
import NoResults from '../../../../components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '../../../../components/WS/WS';
import AppContext from '../../../../context/App';
import type {Expanded as CourseExpanded} from '../../../../models/courses';
import {expand as courseExpand} from '../../../../models/courses';
import {getFilePressHandler} from '../../../../models/library';
import type {Expanded as MaterialExpanded} from '../../../../models/materials';
import {
  expand as materialExpand,
  getMaterialFile,
  materialIsFolder,
} from '../../../../models/materials';
import {
  shouldUpdateProgramSchedule,
  updateMaterialsSchedule,
} from '../../../../models/program';
import {emitter} from '../../../../utils';
import Material from '../Material/Material';
import MaterialsListItem from '../MaterialsListItem/MaterialsListItem';

import styles from './styles.module.css';

const LIMIT = 300;

const CURSOR_DIRECTION = -1;

export type CourseT = ICourse & CourseExpanded;

type Material = IMaterial & MaterialExpanded;

interface ILocalStore {
  isOpenAddFileModule: boolean;
  setIsOpenAddFileModule(isOpenAddFileModule: boolean): void;
  isPaginating: boolean;
  setIsPaginating(isPaginating: boolean): void;
  loading: boolean;
  setLoading(loading: boolean): void;
  course: CourseT | null;
  setCourse(newCourse: CourseT | null): void;
  tabIndex: number;
  setTabIndex(tabIndex: number): void;
  selection: Material[];
  setSections(selection: Material[]): void;
  materialsStore: CollectionStore<Material> | null;
  shouldRenderStub: boolean;
  courseIsPlanned: boolean;
  editIsAllowed: boolean;
  listData: Material[][];
  _onMaterialCheckboxPress(material: Material): void;
  currentMaterial: Material | null;
  setCurrentMaterial(currentMaterial: Material | null): void;
  selectDate: Date;
  setSelectDate(selectDate: Date): void;
  showModalGetDate: boolean;
  setShowModalGetDate(showModalGetDate: boolean): void;
  isOpenModalMaterial: boolean;
  setIsOpenModalMaterial(isOpenModalMaterial: boolean): void;
  selectFolderMaterial: Material | null;
  setSelectFolderMaterial(selectFolderMaterial: Material | null): void;
}

interface Props {
  courseId: string;
  course?: CourseT;
  forCoach?: boolean;
  selection?: Material[];
  multiSelection?: boolean;
  onChoose?: (selection: Material[]) => void;
  onClickBackButton?: () => void;
  isMedium?: boolean;
}

const CourseMaterials: FC<Props> = ({
  courseId,
  course,
  forCoach,
  selection,
  multiSelection,
  onChoose,
  onClickBackButton,
  isMedium = false,
}) => {
  const {
    stores: {currentUserStore, materialStore, courseStore, membershipStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  let materialUnlockDate: Material | null = null;

  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          selectFolderMaterial: null,
          setSelectFolderMaterial(selectFolderMaterial: Material | null) {
            this.selectFolderMaterial = selectFolderMaterial;
          },
          isOpenModalMaterial: false,
          setIsOpenModalMaterial(isOpenModalMaterial: boolean) {
            this.isOpenModalMaterial = isOpenModalMaterial;
          },
          showModalGetDate: false,
          setShowModalGetDate(showModalGetDate: boolean) {
            this.showModalGetDate = showModalGetDate;
          },
          selectDate: dayjs().add(1, 'day').toDate(),
          setSelectDate(selectDate: Date) {
            this.selectDate = selectDate;
          },
          currentMaterial: null,
          setCurrentMaterial(currentMaterial: Material | null) {
            this.currentMaterial = currentMaterial;
          },
          isOpenAddFileModule: false,
          setIsOpenAddFileModule(isOpenAddFileModule: boolean) {
            this.isOpenAddFileModule = isOpenAddFileModule;
          },
          isPaginating: false,
          setIsPaginating(isPaginating: boolean) {
            this.isPaginating = isPaginating;
          },
          loading: true,
          setLoading(loading: boolean) {
            this.loading = loading;
          },
          course: null,
          setCourse(newCourse: CourseT | null) {
            this.course = newCourse;
          },
          tabIndex: 2,
          setTabIndex(tabIndex: number) {
            this.tabIndex = tabIndex;
          },
          selection: [],
          setSections(newSelection: Material[]) {
            this.selection = newSelection;
          },
          materialsStore: null,
          get shouldRenderStub() {
            return (
              (this.materialsStore &&
                this.materialsStore.isLoaded &&
                !this.listData.length) ||
              (this.course && this.course.status === 'planned')
            );
          },
          get courseIsPlanned() {
            return this.course ? this.course.status === 'planned' : true;
          },
          get editIsAllowed() {
            return !!(
              this.course &&
              user &&
              this.course.coach_ids.includes(user._id) &&
              courseIsEditable(this.course)
            );
          },
          get listData() {
            let data: Material[] = [];

            const materials = this.materialsStore
              ? this.materialsStore.items.slice()
              : [];

            if (materials.length) {
              let folders: Material[] = [];
              let files: Material[] = [];

              materials.forEach(material => {
                if (materialIsFolder(material)) {
                  folders.push(material);
                } else {
                  files.push(material);
                }
              });

              const sortFn = (a: Material, b: Material) =>
                Date.parse(datetimeObjToISOString(a.start_date)) -
                Date.parse(datetimeObjToISOString(b.start_date));

              data = folders.concat(files).sort(sortFn);
            }

            const newData: Material[][] = [];

            let chunk: Material[];

            while (data.length > 0) {
              chunk = data.splice(0, 2);

              newData.push(chunk);
            }

            return newData;
          },
          _onMaterialCheckboxPress(material: Material) {
            const materialSearch = (item: Material) =>
              item._id === material._id;

            if (this.selection.find(materialSearch)) {
              const index = this.selection.findIndex(materialSearch);

              if (index >= 0) {
                this.selection.splice(index, 1);
              }
            } else {
              if (!multiSelection) {
                this.selection = [];
              }

              this.selection.push(material);
            }
          },
        },
        {
          selectFolderMaterial: observable,
          setSelectFolderMaterial: action,
          isOpenModalMaterial: observable,
          setIsOpenModalMaterial: action,
          showModalGetDate: observable,
          setShowModalGetDate: action,
          currentMaterial: observable,
          setCurrentMaterial: action,
          isOpenAddFileModule: observable,
          setIsOpenAddFileModule: action,
          isPaginating: observable,
          setIsPaginating: action,
          loading: observable,
          setLoading: action,
          course: observable,
          setCourse: action,
          tabIndex: observable,
          setTabIndex: action,
          selection: observable.shallow,
          setSections: action,
          _onMaterialCheckboxPress: action,
          shouldRenderStub: computed,
          courseIsPlanned: computed,
          editIsAllowed: computed,
          listData: computed,
          setSelectDate: action,
          selectDate: observable,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiSelection],
  );

  useEffect(() => {
    localStore.materialsStore = createCollectionStore({
      params: {
        limit: LIMIT,
        query: (
          [
            ['course_id', '==', courseId],
            onChoose ? ['status', '==', 'open'] : null,
          ] as ApiRpcQuery[]
        ).filter(Boolean),
        cursor: [CURSOR_DIRECTION, 0, null],
        expand: materialExpand,
      },
    });

    localStore.setSections(selection || []);

    localStore.materialsStore.setMethod(
      `${forCoach ? 'coach' : 'client'}.materials.list`,
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    if (course) {
      localStore.setCourse(course);
    } else {
      _fetchCourse();
    }

    const disposeMaterialStoreCreating = reaction(
      () => materialStore.creating,
      creating => {
        if (creating.success && creating.entity!.course_id === courseId) {
          localStore.materialsStore!.addItem(creating.entity! as Material);
        }
      },
    );
    const disposeMaterialStoreUpdating = reaction(
      () => materialStore.updating,
      updating => {
        if (updating.success && updating.entity!.course_id === courseId) {
          localStore.materialsStore!.updateItem(
            updating.entity! as Material,
            updating.entity,
          );
        }
      },
    );
    const disposeMaterialStoreDeleting = reaction(
      () => materialStore.deleting,
      deleting => {
        if (deleting.success && deleting.entity!.course_id === courseId) {
          localStore.materialsStore!.removeItem(deleting.entity as Material);
        }
      },
    );

    const disposeCourseUpdate = reaction(
      () => localStore.course && localStore.course._id,
      () => {
        if (localStore.course) {
          if (
            !localStore.materialsStore!.isLoaded &&
            localStore.course.status !== 'planned'
          ) {
            _onRefresh();
          }
        }
      },
    );

    return () => {
      disposeMaterialStoreCreating();
      disposeMaterialStoreUpdating();
      disposeMaterialStoreDeleting();
      disposeCourseUpdate();
      localStore.materialsStore?.clear();
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (msg.event.type === 'materials_disclosed') {
      localStore.materialsStore &&
        localStore.materialsStore.updateItem(msg.material, {
          status: 'open',
        });

      _onRefresh();
    }
  };

  const _onRefresh = async (silent = false) => {
    if (localStore.courseIsPlanned) {
      return;
    }

    localStore.setLoading(true);

    await localStore.materialsStore!.fetch(
      {
        limit: Math.max(localStore.materialsStore!.items.length, LIMIT),
      },
      {
        silent,
      },
    );

    localStore.setLoading(false);
  };

  const _fetchCourse = async () => {
    localStore.setLoading(true);

    try {
      let newCourse: CourseT;

      if (forCoach) {
        newCourse = (await courseStore.fetch({
          _id: courseId,
          expand: courseExpand,
        })) as CourseT;
      } else {
        const membership = membershipStore.membershipLookup.get(courseId);

        const error = {
          code: 'membership_not_found',
          message: 'You are not a member of the group',
        };

        if (membership) {
          const expand = {membership: [['course_id', null, courseExpand]]};

          const result = await apiRequest({
            method: 'client.memberships.read',
            params: {
              _id: membership._id,
              expand,
            },
          });

          const expandedMembership = expandObj(result.membership, {
            expand,
            expanded: result._expanded,
          });

          if (!expandedMembership.course) {
            throw error;
          } else {
            newCourse = expandedMembership.course;
          }
        } else {
          throw error;
        }
      }

      localStore.setCourse(newCourse);
      localStore.setLoading(false);
    } catch (error) {
      if (error.canceled) {
        return;
      }

      localStore.setLoading(false);

      getCustomConfirmAlert({
        title: 'An error has occurred!',
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onPlusBtnPress = () => {
    localStore.setIsOpenAddFileModule(true);
  };

  const _onDeleteMaterialButtonPress = async args => {
    const material = args.props.material as Material;

    contextMenu.hideAll();

    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => _deleteMaterial(material),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteMaterial = async (material: Material) => {
    try {
      localStore.setLoading(true);

      await materialStore.delete(material);

      localStore.setLoading(false);

      let programTitle = 'Program not found';

      if (material.course && material.course.program) {
        programTitle = material.course.program.title;
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle,
          actionType: 'delete',
          targetType: 'material',
          isIndividual: !!(material.course && material.course.client_id),
        })
      ) {
        updateMaterialsSchedule({
          programId: material.program_id,
          materials: [
            {
              title: '',
              description: '',
              day: 0,
              time: 0,
              coach_id: '',
              file_ids: [],
              uuid: material.uuid,
            },
          ],
        });
      }
    } catch (error) {
      localStore.setLoading(false);

      getCustomConfirmAlert({
        title: 'An error has occurred!',
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onUnlockMaterialButtonPress = async args => {
    const material = args.props.material as Material;

    contextMenu.hideAll();

    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => _unlockMaterial(material),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _unlockMaterial = async (material: Material) => {
    try {
      localStore.setLoading(true);

      await materialStore!.update(material, {
        status: 'open',
        start_date: ISOStringToDatetimeObj(dayjs().toISOString()),
      });

      localStore.setLoading(false);
    } catch (error) {
      localStore.setLoading(false);

      getCustomConfirmAlert({
        title: 'An error has occurred!',
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onMaterialUnlockDatePress = (material: Material) => {
    contextMenu.hideAll();

    materialUnlockDate = material;

    localStore.setShowModalGetDate(true);
  };

  const _updateMaterial = async (
    material: Material,
    data: {[key: string]: any},
  ) => {
    try {
      localStore.setLoading(true);

      const updatedMaterial = await materialStore.update(material, data);

      localStore.setLoading(false);

      let programTitle = 'Program not found';

      if (material.course && material.course.program) {
        programTitle = material.course.program.title;
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle,
          actionType: 'update',
          targetType: 'material',
        })
      ) {
        const startDate = dayjs(
          datetimeObjToISOString(updatedMaterial.start_date),
        );

        let day = startDate.diff(
          material.course
            ? dayjs(datetimeObjToISOString(material.course.start_date))
            : dayjs(),
          'day',
        );

        if (
          localStore.course!.edition &&
          day > localStore.course!.edition.duration - 1
        ) {
          day = localStore.course!.edition.duration - 1;
        }

        updateMaterialsSchedule({
          programId: material.program_id,
          materials: [
            {
              title: updatedMaterial.title,
              description: updatedMaterial.description,
              day,
              time: startDate.diff(startDate.startOf('day'), 'second'),
              uuid: updatedMaterial.uuid,
              file_ids: updatedMaterial.file_ids,
              coach_id: updatedMaterial.coach_id,
            },
          ],
        });
      }
    } catch (error) {
      localStore.setLoading(true);
      setError(error);

      // TODO: log error
    }
  };

  const onChooseButtonPress = () => {
    if (onChoose) {
      onChoose(toJS(localStore.selection));
    }
  };

  const _onMaterialPress = (material: Material) => {
    if (!material.coach_ids.includes(user!._id) && material.status !== 'open') {
      return;
    }

    if (materialIsFolder(material)) {
      localStore.setSelectFolderMaterial(material);
      localStore.setIsOpenModalMaterial(true);
    } else {
      const file = getMaterialFile(material);

      getFilePressHandler(file)();
    }
  };

  const handleCloseModalMaterial = () => {
    localStore.setIsOpenModalMaterial(false);
    localStore.setSelectFolderMaterial(null);
  };

  const _onProgramPress = () => {
    // TODO: navigate to program
  };

  const _renderStub = () => {
    const isIndividual = !!(localStore.course && localStore.course.client_id);

    const title = localStore.courseIsPlanned
      ? `This ${
          isIndividual ? 'program' : 'group'
        } program has not yet started. You can modify its contents in the program it belongs to`
      : 'Nothing here yet...';

    const text = title
      .split(/<%(.*?)%>/g)
      .filter(Boolean)
      .map((part, i) => {
        const key = `${part}-${i}`;

        if (part.includes('highlight->')) {
          return (
            <div key={key} onClick={_onProgramPress}>
              {part.replace('highlight->', '')}
            </div>
          );
        }

        return part;
      });

    return (
      <Observer>
        {() => (
          <>
            {localStore.shouldRenderStub ? (
              <NoResults
                text={''}
                className={styles.NoResults}
                classNameChildrenContainer={styles.NoResultsMainContainer}
                classNameImgContainer={styles.NoResultsImgContainer}
              >
                <div className={styles.iconNoResultMainContainer}>
                  <div className={styles.iconNoResultContainer}>
                    <IconNewFolder className={styles.iconNoResult} />
                  </div>
                  <div className={styles.iconNoResultText}>{text}</div>
                </div>
              </NoResults>
            ) : null}
          </>
        )}
      </Observer>
    );
  };

  const onMaterialMoreButtonPress = (material: Material) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      localStore.setCurrentMaterial(material);

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          material,
        },
      });
    };
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const menuId = 'menuIdCourseMaterials';

  const MyMenu = () => (
    <Observer>
      {() => (
        <div onClick={handleClickMenu}>
          <Menu
            id={menuId}
            animation={animation.fade}
            className="contextMenu"
            style={{zIndex: 1000}}
          >
            <Item
              onClick={_onDeleteMaterialButtonPress}
              className="contextMenuItem"
            >
              Delete
            </Item>
            <Item
              onClick={_onUnlockMaterialButtonPress}
              className="contextMenuItem"
            >
              Unlock
            </Item>
            <Item
              onClick={_onMaterialUnlockDatePress as any}
              className="contextMenuItem"
            >
              Change unlock date
            </Item>
          </Menu>
        </div>
      )}
    </Observer>
  );

  const handleOnMaterialCheckboxPress = (material: Material) => {
    return () => {
      localStore._onMaterialCheckboxPress(material);
    };
  };

  const handleOnMaterialPress = (material: Material) => {
    return () => {
      _onMaterialPress(material);
    };
  };

  const handleCloseModalGetDate = () => {
    localStore.setShowModalGetDate(false);
  };

  const handleGetDate = async () => {
    const startDate = dayjs.max([
      dayjs(localStore.selectDate),
      materialUnlockDate!.course
        ? dayjs(datetimeObjToISOString(materialUnlockDate!.course.start_date))
        : dayjs(),
    ]);
    const data: {
      start_date: DateTimeObj;
      status?: Material['status'];
    } = {
      start_date: ISOStringToDatetimeObj(
        startDate.add(1, 'second').toISOString(),
      ),
    };

    if (localStore.selectDate <= new Date()) {
      data.status = 'open';
    }

    await _updateMaterial(materialUnlockDate!, data);

    localStore.setShowModalGetDate(false);
  };

  const handleOnDayClick = day => {
    return () => {
      localStore.setSelectDate(dayjs(day.format('YYYY-MM-DD')).toDate());
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`CourseMaterials ${styles.CourseMaterials}`}>
          <div className={styles.headerWrapper}>
            <IconArrowLeft
              className={styles.iconBackButton}
              onClick={onClickBackButton}
            />
            <span className={styles.title}>
              {localStore.course?.program.title}
            </span>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.listContainer}>
              {localStore.listData.length > 0 ? (
                <div
                  className={`${styles.list} ${
                    isMedium ? styles.threeColumn : ''
                  }`}
                >
                  {localStore.listData.map(data => {
                    return data.map(material => {
                      const isChoosable = material.status === 'open';

                      return (
                        <MaterialsListItem
                          key={material._id}
                          material={material}
                          isMedium={isMedium}
                          onPress={
                            onChoose
                              ? isChoosable
                                ? handleOnMaterialCheckboxPress(material)
                                : undefined
                              : handleOnMaterialPress(material)
                          }
                          showUnlockBtn={
                            localStore.editIsAllowed &&
                            material.status !== 'open'
                          }
                          onMorePress={onMaterialMoreButtonPress(material)}
                          isSelectionMode={onChoose ? true : false}
                          isChoosable={isChoosable}
                          isSelected={
                            onChoose
                              ? !!localStore.selection.find(
                                  _item => _item._id === material._id,
                                )
                              : undefined
                          }
                        />
                      );
                    });
                  })}
                  <MyMenu />
                </div>
              ) : (
                _renderStub()
              )}
            </div>
          </div>
          <div className={styles.butMenuContainer}>
            {localStore.editIsAllowed ? (
              <CustomButton
                type="button"
                classButton={`QuestionnaireContinueBut ${styles.addFile}`}
                onClick={_onPlusBtnPress}
              >
                <div className={styles.plusIcon}>+</div>
                <div>Add file</div>
              </CustomButton>
            ) : (
              <div />
            )}
            {onChoose ? (
              <CustomButton
                type="button"
                classButton={`blueButt ${styles.choose}`}
                disabled={!localStore.selection.length}
                onClick={onChooseButtonPress}
              >
                <span>Choose</span>
              </CustomButton>
            ) : null}
          </div>
          <ModalAnimateWin
            showModal={localStore.isOpenModalMaterial}
            closeModalHandler={handleCloseModalMaterial}
            className="greyHeaderContainer List"
            isBody
            classNameBody="whiteBody maxContent"
            header={
              localStore.selectFolderMaterial
                ? (
                    localStore.selectFolderMaterial as Material &
                      MaterialExpanded
                  ).title
                : ''
            }
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <Material
              material={
                localStore.selectFolderMaterial
                  ? (localStore.selectFolderMaterial! as Material &
                      MaterialExpanded)
                  : undefined
              }
              materialId={
                localStore.selectFolderMaterial
                  ? (
                      localStore.selectFolderMaterial! as Material &
                        MaterialExpanded
                    )._id || undefined
                  : undefined
              }
            />
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.showModalGetDate}
            closeModalHandler={handleCloseModalGetDate}
            className="greyHeaderContainer littleContainer Calendar"
            isBody
            classNameBody="whiteBody"
            header="Select date"
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <div className="modalCalendar">
              <DayPicker
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                noBorder
                navPrev={<IconPrev height="14" />}
                navNext={<IconNext height="14" />}
                renderWeekHeaderElement={dayName => {
                  return <>{dayName}</>;
                }}
                firstDayOfWeek={1}
                renderDayContents={day => {
                  return (
                    <Observer>
                      {() => {
                        const min = moment();
                        const max = localStore.currentMaterial!.course
                          ? dayjs(
                              datetimeObjToISOString(
                                localStore.currentMaterial!.course.end_date,
                              ),
                            ).toDate()
                          : dayjs().toDate();

                        const selectDay = moment(localStore.selectDate);

                        const isSelectDay =
                          selectDay.format('YYYY-MM-DD') ===
                          day.format('YYYY-MM-DD');

                        const dayWeek = day.day() % 7;
                        const isWeekend = dayWeek === 6 || dayWeek === 0;

                        const dif =
                          Math.sign(
                            dayjs(day.format('YYYY-MM-DD')).diff(min.toDate()),
                          ) > 0 &&
                          Math.sign(dayjs(day.format('YYYY-MM-DD')).diff(max)) <
                            0;

                        return (
                          <div
                            className={`particularDay ${
                              isSelectDay ? 'today' : 'otherDay'
                            } ${isWeekend ? 'weekend' : ''} particularDay ${
                              dif ? '' : 'blocked'
                            }`}
                            onClick={dif ? handleOnDayClick(day) : undefined}
                          >
                            {day.format('D')}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />
              <CustomButton
                type="button"
                classButton={`QuestionnaireContinueBut allWidth ${styles.greenBack}`}
                onClick={handleGetDate}
              >
                <span>Select a date.</span>
              </CustomButton>
            </div>
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseMaterials);
