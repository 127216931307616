import {useEffect, useState} from 'react';

import {
  getActiveClientMembership,
  getArchivedClientMembership,
} from '../ChatsListContainer/ChatsListItem/api';
import type {ChannelT} from '../context/useChatsLocalStore';

const useFetchClientMembership = (channel: ChannelT, isUserCoach?: boolean) => {
  const [isLoadedActiveClientMembership, setIsLoadedActiveClientMembership] =
    useState<boolean>(true);
  const [isLoadedArchivedClientMembership, setIsLoadedArchivedlientMembership] =
    useState<boolean>(true);

  const [tenantTitle, setTenantTitle] = useState<string>('');
  const [subtenantTitle, setSubtenantTitle] = useState<string>('');

  useEffect(() => {
    setIsLoadedActiveClientMembership(false);

    if (
      isUserCoach &&
      channel.status === 'active' &&
      !isLoadedActiveClientMembership
    ) {
      getActiveClientMembership().then(resp => {
        if (
          channel.resource &&
          channel.resource.client_id &&
          resp?._expanded[channel.resource.client_id]
        ) {
          const subtenant_id =
            resp?._expanded[channel.resource.client_id]?.subtenant_id;
          const tenant_id = resp?._expanded[subtenant_id]?.tenant_id;

          setTenantTitle(resp?._expanded[tenant_id]?.title);
          setSubtenantTitle(resp?._expanded[subtenant_id]?.title);
        }
      });
    }

    return () => setIsLoadedActiveClientMembership(true);
  }, [
    channel.resource,
    channel.status,
    isUserCoach,
    isLoadedActiveClientMembership,
  ]);

  useEffect(() => {
    setIsLoadedArchivedlientMembership(false);

    if (
      isUserCoach &&
      channel.status === 'archived' &&
      !isLoadedArchivedClientMembership
    ) {
      getArchivedClientMembership().then(resp => {
        if (
          channel.resource &&
          channel.resource.client_id &&
          resp?._expanded[channel.resource.client_id]
        ) {
          const subtenant_id =
            resp?._expanded[channel.resource.client_id]?.subtenant_id;
          const tenant_id = resp?._expanded[subtenant_id]?.tenant_id;

          setTenantTitle(resp?._expanded[tenant_id]?.title);
          setSubtenantTitle(resp?._expanded[subtenant_id]?.title);
        }
      });
    }

    return () => setIsLoadedArchivedlientMembership(true);
  }, [
    channel.resource,
    channel.status,
    isUserCoach,
    isLoadedArchivedClientMembership,
  ]);

  return {tenantTitle, subtenantTitle, setTenantTitle, setSubtenantTitle};
};

export default useFetchClientMembership;
