import React, {
  memo,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import type {Category} from '@yourcoach/shared/api/category';
import {getCategoryIcon} from '@yourcoach/shared/api/category';

import List from '@src/components/List';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import {getListScrollProps} from '../../getListScrollProps';
import ListItem, {
  ListItemCheckbox,
  ListItemIcon,
  ListItemRadio,
  ListItemTitle,
} from '../../ListItem';
import {bindIndex, useSelection} from '../../useSelection';

import styles from './styles.module.css';

interface Data {
  client_categories: {category_id: string; weight: number}[];
}

export interface Editor {
  validate: () => boolean;
}

interface Props {
  categories: Category[];
  isSecondary?: boolean;
  value?: Data;
  onChange?: (arg0: Data) => void;
}

const MAX_SELECTION = 4;

const I18N_SCOPE = 'Onboarding.main_category';

const CategoriesListEditor = React.forwardRef<Editor, Props>(
  ({onChange, categories, isSecondary = false}, ref) => {
    const {
      stores: {currentUserStore},
    } = useContext(AppContext);

    // Pass selected categories to caller
    const onSelectionChange = useCallback(
      newSelection => {
        setError('');

        const head = isSecondary
          ? [
              {
                category_id:
                  currentUserStore.user!.client_categories[0].category_id,
                weight: 1,
              },
            ]
          : [];

        const selectedCats = newSelection.map((i, index) => ({
          category_id: categories[i]._id,
          weight: parseFloat(
            (1 - (index + (isSecondary ? 1 : 0)) / MAX_SELECTION).toFixed(1),
          ),
        }));

        onChange &&
          onChange({
            client_categories: head.concat(...selectedCats),
          });
      },
      [categories, currentUserStore.user, isSecondary, onChange],
    );

    const {selection, onItemClick} = useSelection(
      [...(currentUserStore.user!.client_categories || [])]
        .slice(undefined, isSecondary ? categories.length : 1)
        .map(cat => categories.findIndex(c2 => c2._id === cat.category_id))
        .filter(i => i >= 0),
      !!isSecondary,
      MAX_SELECTION,
      onSelectionChange,
    );
    const [error, setError] = useState('');

    // Provide validate() function to caller
    useImperativeHandle(
      ref,
      () => ({
        validate: () => {
          if (isSecondary || selection.length > 0) {
            return true;
          }

          setError(t([I18N_SCOPE, 'category_required_error']));

          return false;
        },
      }),
      [isSecondary, selection.length],
    );

    return (
      <div className={styles.CategoriesListEditor}>
        <div className={styles.error}>{error}</div>
        <List customScrollbar {...useMemo(getListScrollProps, [])}>
          {categories.map((item, index) => (
            <ListItem
              key={'item-' + index}
              isSelected={selection.includes(index)}
              onClick={bindIndex(index, onItemClick)}
            >
              <ListItemIcon>
                {getCategoryIcon(item.icon)({className: styles.categoryIcon})}
              </ListItemIcon>
              <ListItemTitle>{item.title}</ListItemTitle>
              {isSecondary ? (
                <ListItemCheckbox
                  isSelected={selection.includes(index)}
                  onClick={bindIndex(index, onItemClick)}
                />
              ) : (
                <ListItemRadio
                  isSelected={selection.includes(index)}
                  onClick={bindIndex(index, onItemClick)}
                />
              )}
            </ListItem>
          ))}
        </List>
      </div>
    );
  },
);

export default memo(CategoriesListEditor);
