import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {Material} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {IconLock} from '../../../../components/icons';
import type {
  Expanded as MaterialExpanded,
  ScheduleMaterial,
} from '../../../../models/materials';
import {getMaterialFile, materialIsFolder} from '../../../../models/materials';
import FileCard from '../../../../modules/MyLibraryWidget/FileCard/FileCard';
import FolderCard from '../../../../modules/MyLibraryWidget/FolderCard/FolderCard';

import styles from './styles.module.css';

type ExpandedMaterial = Material & MaterialExpanded;

export type MaterialT = ExpandedMaterial | ScheduleMaterial;

interface Props<T extends MaterialT = MaterialT> {
  material: T;
  onPress?: (material: T) => void;
  onMorePress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  showUnlockBtn?: boolean;
  isSelected?: boolean;
  isSelectionMode?: boolean;
  isChoosable?: boolean;
  className?: string;
  containerStyle?: string;
  isMedium?: boolean;
}

const MaterialsListItem: FC<Props> = ({
  material,
  onPress,
  showUnlockBtn,
  onMorePress,
  isSelected,
  isSelectionMode,
  isChoosable,
  className = '',
  containerStyle = '',
  isMedium = false,
}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress(material);
  }, [onPress, material]);

  const onMorePressCb = useCallback(
    e => {
      onMorePress && onMorePress(e);
    },
    [onMorePress],
  );

  const isLocked =
    (material as ExpandedMaterial).status !== undefined &&
    (material as ExpandedMaterial).status !== 'open';

  const file =
    getMaterialFile(material) ||
    ({
      src: {
        original: {
          url: '',
          size: 0,
          filename: isLocked ? 'Material is locked' : 'Material not found',
        },
      },
      categories: [],
    } as unknown as IFile);

  const isFolder = materialIsFolder(material);

  let listItem: React.ReactElement | null = null;

  if (isFolder) {
    listItem = (
      <FolderCard
        // @ts-ignore
        folder={{
          name: material.title,
          ...material,
        }}
        onPress={onPress ? onPressCb : undefined}
        isMedium={isMedium}
        isSelectionMode={isSelectionMode && isChoosable}
        isSelected={isSelected}
      />
    );
  } else {
    listItem = (
      <FileCard
        file={file}
        isSelectionMode={isSelectionMode && isChoosable}
        isSelected={isSelected}
        isMedium={isMedium}
        onPress={onPress ? onPressCb : undefined}
      />
    );
  }

  return (
    <Observer>
      {() => (
        <div
          className={`MaterialsListItem ${styles.MaterialsListItem} ${className}`}
          onClick={onPress && !showUnlockBtn ? onPressCb : undefined}
        >
          <div className={`${styles.container} ${containerStyle}`}>
            <>
              {listItem}
              {isLocked ? (
                <div className={styles.lockedOverlay}>
                  <div className={styles.lockedContent}>
                    <div className={styles.lockedImageContainer}>
                      <IconLock viewBox="3 1 35 35" />
                    </div>
                    <div className={styles.date}>
                      {formatDate(
                        datetimeObjToISOString(
                          (material as ExpandedMaterial).start_date,
                        ),
                        {
                          appendYTT: true,
                          customFormat: 'lll',
                        },
                      )}
                    </div>
                  </div>
                  {showUnlockBtn ? (
                    <div
                      className={styles.moreButtonContainer}
                      onClick={onMorePressCb}
                    >
                      ⋮
                    </div>
                  ) : null}
                </div>
              ) : null}
            </>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(MaterialsListItem);
