import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Observer} from 'mobx-react';

import {action, observable, reaction} from 'mobx';

import IconPrev from '@yourcoach/shared/assets/icons/secondary/Arrow - Left 2.svg';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {labelBack, labelContinue} from '@src/common/i18n/i18nCommon';
import {
  labelAddSection,
  labelSection,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import IconNext from '@src/components/icons/IconNext/IconNext';
// import {ColorWrapper} from '@src/layouts';
import type {IQSectionWithFields} from '@src/modules/Questionnaire/context/qAppStore';

import {IconButton} from '../../../../../v2/components/IconButton';
import questionnaireAppStore from '../../../context/qAppStore';
import styles from '../../styles.module.css';

import AddSectionQ from './AddSectionQ/AddSectionQ';
import AddTemplateSectionQ from './AddTemplateSectionQ/AddTemplateSectionQ';
import UpdateSectionQ from './UpdateSectionQ/UpdateSectionQ';

interface Props {
  clickContinue: () => void;
  clickBack: () => void;
}

export interface IQCreateUserLocaleStore {
  showModalSection: boolean;
  sections: IQSectionWithFields[];
  setSections: (newSec: IQSectionWithFields[]) => void;
  setShowModalSection: (newVal: boolean) => void;
}

const Step3: FC<Props> = ({clickContinue, clickBack}) => {
  const localStore: IQCreateUserLocaleStore = useRef(
    observable<IQCreateUserLocaleStore>(
      {
        showModalSection: false,
        sections: [],
        setSections(newSec: IQSectionWithFields[]) {
          this.sections = newSec;
        },
        setShowModalSection(newVal: boolean) {
          this.showModalSection = newVal;
        },
      },
      {
        showModalSection: observable,
        sections: observable,
        setShowModalSection: action,
        setSections: action,
      },
    ),
  ).current;

  useEffect(() => {
    const dispose = reaction(
      () => questionnaireAppStore?.userTemplateQuest?.sections,
      sections => {
        if (sections) {
          localStore.setSections(sections);
        }
      },
    );

    if (questionnaireAppStore?.userTemplateQuest) {
      localStore.setSections(
        questionnaireAppStore?.userTemplateQuest?.sections,
      );
    }

    questionnaireAppStore?.delActiveSectionId();

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAddSection = () => {
    localStore.setShowModalSection(true);
  };
  const closeModalSection = () => {
    localStore.setShowModalSection(false);
  };
  const handleSetSection = (section: IQSectionWithFields) => {
    questionnaireAppStore?.addSection(section);
    localStore.setShowModalSection(false);
  };
  const handleOnClickSelectSection = (idSection: string) => {
    return () => {
      questionnaireAppStore?.setActiveSectionId(idSection);
    };
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    questionnaireAppStore?.reorderSection(
      result.source.index,
      result.destination.index,
    );
  };

  const nDragStart = () => {
    questionnaireAppStore?.delActiveSectionId();
  };

  const handleClickContinue = () => {
    if (questionnaireAppStore?.userTemplateQuest?.title === '') {
      setError('The questionnaire should have a title');
    } else if (
      questionnaireAppStore!.userTemplateQuest!.sections.slice().length <= 0
    ) {
      setError('The questionnaire should have a sections');
    } else {
      clickContinue();
    }
  };

  const handleClickBack = () => {
    clickBack();
  };

  return (
    <Observer>
      {() => (
        <div className={`Step3 ${styles.step} ${styles.Step3}`}>
          <div className={styles.sectionsQuestionContainer}>
            <div className={styles.sectionsContainer}>
              {localStore.sections.length > 0 && (
                <div className={styles.sectionList}>
                  <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={nDragStart}
                  >
                    <Droppable droppableId="list">
                      {provided => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {localStore.sections.map((oneSection, index) => (
                            <Draggable
                              draggableId={oneSection.id}
                              index={index}
                              key={oneSection.id}
                            >
                              {providedEl => (
                                <Observer>
                                  {() => (
                                    <li
                                      onClick={handleOnClickSelectSection(
                                        oneSection.id,
                                      )}
                                      ref={providedEl.innerRef}
                                      {...providedEl.draggableProps}
                                      {...providedEl.dragHandleProps}
                                      className={
                                        questionnaireAppStore?.activeSectionId ===
                                        oneSection.id
                                          ? styles.sectionActive
                                          : ''
                                      }
                                    >
                                      <div className={styles.sectionNum}>
                                        {`${labelSection()} ${index + 1}`}
                                      </div>
                                      <div className={styles.sectionTitle}>
                                        {oneSection.title}
                                      </div>
                                    </li>
                                  )}
                                </Observer>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
              <CustomButton
                type="button"
                classButton="blueButt"
                onClick={handleClickAddSection}
              >
                <span>{labelAddSection()}</span>
              </CustomButton>
              <ModalAnimateWin
                showModal={localStore.showModalSection}
                closeModalHandler={closeModalSection}
                className={styles.modalSection}
              >
                <>
                  <AddSectionQ setSection={handleSetSection} />
                  <AddTemplateSectionQ setSection={handleSetSection} />
                </>
              </ModalAnimateWin>
            </div>

            <div className={styles.questionsContainer}>
              <UpdateSectionQ />
            </div>
          </div>
          <div className={styles.footer}>
            {/*<ColorWrapper containerClass={styles.right}>*/}
            {/*  <CustomButton*/}
            {/*    type="button"*/}
            {/*    classButton="QuestionnaireContinueBut"*/}
            {/*    onClick={handleClickContinue}*/}
            {/*  >*/}
            {/*    <span>{labelContinue()}</span>*/}
            {/*    <IconNext customClass="IconNext" />*/}
            {/*  </CustomButton>*/}
            {/*</ColorWrapper>*/}
            <View
              style={{
                width: '100%',
                padding: 40,
                // display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                iconFillColor={'icon3'}
                size={'xl'}
                scheme={'gray'}
                onClick={handleClickBack}
                icon={<IconPrev />}
              >
                <Text variant={'medium'} color={'inherit'}>
                  {labelBack()}
                </Text>
              </IconButton>
              <IconButton
                onClick={handleClickContinue}
                size={'xl'}
                icon={<IconNext />}
              >
                <Text variant={'medium'} color={'inherit'}>
                  {labelContinue()}
                </Text>
              </IconButton>
            </View>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Step3);
