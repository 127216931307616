import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import {labelNoResults} from '@src/common/i18n/i18nCommon';

import styles from './styles.module.css';

interface Props {
  children?: ReactNode;
  text?: string;
  className?: string;
  classNameChildrenContainer?: string;
  classNameImgContainer?: string;
  classNameTextContainer?: string;
}

const NoResults: FC<Props> = ({
  children,
  text = labelNoResults(),
  className = '',
  classNameChildrenContainer = '',
  classNameImgContainer = '',
  classNameTextContainer = '',
}) => {
  return (
    <div className={`NoResults ${styles.NoResults} ${className}`}>
      <div className={classNameChildrenContainer}>
        {children && (
          <div className={`${styles.imgContainer} ${classNameImgContainer}`}>
            {children}
          </div>
        )}
        <div className={`${styles.textContainer} ${classNameTextContainer}`}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default memo(NoResults);
