import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable, runInAction} from 'mobx';

import {ISOStringToDatetimeObj} from '@yourcoach/shared/api';
import type {Subgoal} from '@yourcoach/shared/api/goal';
import {getGoalAttitudeIcon} from '@yourcoach/shared/api/goal';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import BACKGROUND_IMG from '@src/modules/legacy/Goals/assets/background.png';
import BACKGROUND_IMG_X2 from '@src/modules/legacy/Goals/assets/background@2x.png';
import BACKGROUND_IMG_X3 from '@src/modules/legacy/Goals/assets/background@3x.png';
import FLOWERS_IMG from '@src/modules/legacy/Goals/assets/flowers.png';
import FLOWERS_IMG_X2 from '@src/modules/legacy/Goals/assets/flowers@2x.png';
import FLOWERS_IMG_X3 from '@src/modules/legacy/Goals/assets/flowers@3x.png';
import LEFT_FLAGS_IMG from '@src/modules/legacy/Goals/assets/leftFlags.png';
import LEFT_FLAGS_IMG_X2 from '@src/modules/legacy/Goals/assets/leftFlags@2x.png';
import LEFT_FLAGS_IMG_X3 from '@src/modules/legacy/Goals/assets/leftFlags@3x.png';
import RIGHT_FLAGS_IMG from '@src/modules/legacy/Goals/assets/rightFlags.png';
import RIGHT_FLAGS_IMG_X2 from '@src/modules/legacy/Goals/assets/rightFlags@2x.png';
import RIGHT_FLAGS_IMG_X3 from '@src/modules/legacy/Goals/assets/rightFlags@3x.png';
import WOMAN_IMG from '@src/modules/legacy/Goals/assets/woman.png';
import WOMAN_IMG_X2 from '@src/modules/legacy/Goals/assets/woman@2x.png';
import WOMAN_IMG_X3 from '@src/modules/legacy/Goals/assets/woman@3x.png';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'RateSubgoal';

interface ILocalStore {
  attitudeValue: number;
  isSaving: boolean;
  _onValueChange(value: number): void;
}

interface Props {
  subgoal: Subgoal;
  closeModal: () => void;
}

const RateSubgoal: FC<Props> = ({subgoal, closeModal}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        attitudeValue: 100,
        isSaving: false,
        _onValueChange(value: number) {
          this.attitudeValue = value;
        },
      },
      {
        attitudeValue: observable,
        isSaving: observable,
        _onValueChange: action,
      },
    ),
  ).current;

  const handleOnChangeRate = value => {
    const rate = parseInt(value, 10);

    localStore._onValueChange(rate);
  };

  const _onConfirmButtonPress = async () => {
    try {
      runInAction(() => (localStore.isSaving = true));

      await goalStore.sub.client.markReached(subgoal, {
        client_attitude: localStore.attitudeValue / 100,
      });

      await goalStore.sub.client.localUpdate(subgoal, {
        reached: ISOStringToDatetimeObj(dayjs().toISOString()),
      });

      runInAction(() => {
        localStore.isSaving = false;
      });

      closeModal();
    } catch (error) {
      runInAction(() => (localStore.isSaving = false));

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`RateSubgoal ${styles.RateSubgoal}`}>
          <div className={styles.headerPic}>
            <div className={styles.picContainer}>
              <div
                className={`${styles.background}  animate__animated animate__fadeIn`}
              >
                <img
                  src={BACKGROUND_IMG}
                  srcSet={`${BACKGROUND_IMG_X2} 2x, ${BACKGROUND_IMG_X3} 3x`}
                  alt="background image"
                />
              </div>
              <div
                className={`${styles.flowers} animate__animated animate__bounceInUp`}
              >
                <img
                  src={FLOWERS_IMG}
                  srcSet={`${FLOWERS_IMG_X2} 2x, ${FLOWERS_IMG_X3} 3x`}
                  alt="background image"
                />
              </div>
              <div
                className={`${styles.leftFlags} animate__animated animate__fadeInTopLeft`}
              >
                <img
                  src={LEFT_FLAGS_IMG}
                  srcSet={`${LEFT_FLAGS_IMG_X2} 2x, ${LEFT_FLAGS_IMG_X3} 3x`}
                  alt="background image"
                />
              </div>
              <div
                className={`${styles.rightFlags} animate__animated animate__fadeInTopRight`}
              >
                <img
                  src={RIGHT_FLAGS_IMG}
                  srcSet={`${RIGHT_FLAGS_IMG_X2} 2x, ${RIGHT_FLAGS_IMG_X3} 3x`}
                  alt="background image"
                />
              </div>
              <div
                className={`${styles.woman} animate__animated animate__fadeIn`}
              >
                <img
                  src={WOMAN_IMG}
                  srcSet={`${WOMAN_IMG_X2} 2x, ${WOMAN_IMG_X3} 3x`}
                  alt="background image"
                />
              </div>
            </div>
          </div>
          <div className={styles.firstPanelContainer}>
            <div className={styles.firstPanelContentContainer}>
              <div className={styles.title}>{t([I18N_SCOPE, 'title'])}</div>
              <div className={styles.description}>
                {t([I18N_SCOPE, 'description'])}
              </div>
            </div>
            <div className={styles.secondPanelContainer}>
              <div className={styles.attitudeTitle}>
                {t([I18N_SCOPE, 'attitude_title'])}
              </div>
              <div className={styles.sliderContainer}>
                <div className={`customSlider ${styles.settingItem}`}>
                  <Slider
                    min={0}
                    max={100}
                    tooltip={false}
                    value={localStore.attitudeValue}
                    orientation="horizontal"
                    onChange={handleOnChangeRate}
                  />
                </div>
                <div className={styles.sliderContentContainer}>
                  <div className={styles.swipeItLabel}>
                    {t([I18N_SCOPE, 'swipe_it_label'])}
                  </div>
                  <div className={styles.sliderValueContainer}>
                    <div className={styles.attitudeIcon}>
                      {getGoalAttitudeIcon(localStore.attitudeValue)}
                    </div>
                    <div
                      className={styles.sliderValue}
                    >{`${localStore.attitudeValue} %`}</div>
                  </div>
                </div>
              </div>
              <div className={styles.confirmButContainer}>
                <CustomButton
                  type="button"
                  classButton={`blueButt ${styles.saveBut}`}
                  onClick={_onConfirmButtonPress}
                >
                  {localStore.isSaving ? (
                    <Loader />
                  ) : (
                    <span>{t([I18N_SCOPE, 'footer_button'])}</span>
                  )}
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(RateSubgoal);
