import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import Button from '@src/components/Button';
import Image from '@src/components/Image';
import ModalNew from '@src/components/ModalNew';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import AddFileModal from '@src/modules/library/AddFileModal';

import AvatarSVG from '../../images/avatar.svg';
import HintIcon from '../../images/photo-hint.svg';

import styles from './styles.module.css';

interface Props {
  value?: IFile;
  onChange?: (file: IFile) => void;
  onSkipClick?: () => void;
}

export interface Editor {
  validate: () => boolean;
}

const I18N_SCOPE = 'Onboarding.avatar';

const AvatarEditor = React.forwardRef<Editor, Props>(
  ({onChange, onSkipClick}, ref) => {
    const {
      stores: {currentUserStore},
    } = useContext(AppContext);

    const [isDialogVisible, showDialog, hideDialog] = useIsVisible(false);
    const [isHintVisible, showHint, hideHint] = useIsVisible(false);
    const [file, setFile] = useState<IFile | undefined | null>(
      currentUserStore.user!.avatar,
    );
    const skipAfterHint = useRef(false);

    const src = useMemo(() => getFileSrc(file, 250).url || '', [file]);

    const hideImageDialog = useCallback(() => {
      hideDialog();
      hideHint();
    }, [hideDialog, hideHint]);

    const onUpload = useCallback(
      async (files: IFile[]) => {
        const newFile = files[0];

        setFile(newFile);
        hideImageDialog();

        if (onChange) {
          onChange(newFile);
        }
      },
      [hideImageDialog, onChange],
    );

    useImperativeHandle(
      ref,
      () => ({
        validate: () => {
          if (src) {
            return true;
          }

          showHint();

          return false;
        },
      }),
      [showHint, src],
    );

    const onAddClick = useCallback(() => {
      hideHint();

      showDialog();
    }, [hideHint, showDialog]);

    const callOnSkipClick = useCallback(() => {
      hideHint();
      skipAfterHint.current = true;
    }, [hideHint]);

    useEffect(() => {
      if (!isHintVisible && skipAfterHint.current && onSkipClick) {
        onSkipClick();
      }
    }, [isHintVisible, onSkipClick]);

    return (
      <div className={styles.container}>
        <Image src={src} placeholder={<AvatarSVG />} className={styles.image} />
        <Button onClick={showDialog}>{t([I18N_SCOPE, 'upload_label'])}</Button>
        <AddFileModal
          isOpen={isDialogVisible}
          onAfterClose={hideImageDialog}
          onUpload={onUpload}
          fileType="image"
          mode="upload"
          multiple={false}
        />
        <ModalNew
          isOpen={isHintVisible}
          onAfterClose={hideHint}
          className={styles.modal}
          bodyClassName={styles.modalBody}
        >
          <div className={styles.mozillaClipPathWorkaround}>
            <HintIcon />
          </div>
          <div>{t([I18N_SCOPE, 'upload_photo_hint'])}</div>
          <Button onClick={onAddClick}>{t([I18N_SCOPE, 'add_button'])}</Button>
          <Button className={styles.skip} onClick={callOnSkipClick}>
            {t([I18N_SCOPE, 'skip_button'])}
          </Button>
        </ModalNew>
      </div>
    );
  },
);

export default memo(AvatarEditor);
