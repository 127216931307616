import React, {useCallback, useMemo} from 'react';

import classNames from 'classnames';
import Color from 'color';
import randomcolor from 'randomcolor';

import type {Card} from '@yourcoach/shared/api/payment/card';
import {getCardBrandImage} from '@yourcoach/shared/api/payment/card';

import Image from '@src/components/Image';

import styles from './PaymentCard.module.css';

interface Props {
  card: Card;
  className?: string;
  onClick?: (card: Card) => void;
}

const PaymentCard: React.FC<Props> = ({card, className, onClick}) => {
  const brandStyle = useMemo(
    () => card.card!.brand.replace(/\s/g, ''),
    [card.card],
  );

  const colors = useMemo(() => {
    const seed =
      card.card!.last4 +
      card.card!.exp_year.toString() +
      card.card!.exp_month.toString();

    const firstColor = Color(
      randomcolor({
        seed,
      }),
    )
      .rotate(-20)
      .darken(0.4);

    const secondColor = Color(firstColor)
      .rotate(-20)
      .darken(0.2)
      .rgb()
      .string();

    return [firstColor, secondColor];
  }, [card.card]);

  const cardBrandImage = useMemo(
    () => getCardBrandImage(card.card!.brand, true),
    [card.card],
  );

  const onClickCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onClick && onClick(card);
    },
    [onClick, card],
  );

  return (
    <div className={classNames('PaymentCard', styles.Component, className)}>
      <div
        className={classNames(
          styles.card,
          styles[brandStyle],
          onClick && styles.clickable,
        )}
        style={useMemo(
          () => ({
            background: `linear-gradient(45deg, ${colors[0]}, ${colors[1]})`,
          }),
          [colors],
        )}
        onClick={onClick ? onClickCb : undefined}
      >
        <Image src={cardBrandImage} className={styles.brandImage} />
        <div>
          <div className={styles.cardNumber}>
            ●●●● ●●●● ●●●● {card.card!.last4}
          </div>
          <div className={styles.cardDate}>
            {card.card!.exp_month > 9
              ? card.card!.exp_month
              : `0${card.card!.exp_month}`}
            {' / '}
            {card.card!.exp_year.toString().slice(-2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaymentCard);
