import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {getCourseDurationString} from '@yourcoach/shared/api/course';

import {
  labelProgramClientCoursesListItemStatusFrozen,
  labelProgramClientCoursesListItemStatusJoined,
  labelProgramClientCoursesListItemStatusRequested,
  labelProgramCourseNoStartDateClient,
} from '@src/common/i18n/i18nPrograms';
import {IconCalendar, IconNext} from '@src/components/icons';

import type {MembershipT} from '../SquadsTabClient';

import styles from './../../styles.module.scss';

interface Props {
  membership: MembershipT;
  onPress: (membership: MembershipT) => void;
}

const ProgramClientCoursesListItem: FC<Props> = ({membership, onPress}) => {
  const {course, edition} = membership;
  const handleOnClick = useCallback(() => {
    if (membership.status !== 'requested') {
      onPress && onPress(membership);
    }
  }, [membership, onPress]);

  let status = '';

  if (membership.status === 'active' || membership.status === 'accepted') {
    status = labelProgramClientCoursesListItemStatusJoined();
  } else if (membership.status === 'requested') {
    status = labelProgramClientCoursesListItemStatusRequested();
  } else if (membership.status === 'frozen') {
    status = labelProgramClientCoursesListItemStatusFrozen();
  }

  const courseDurationString = useMemo(
    () =>
      getCourseDurationString(course) || labelProgramCourseNoStartDateClient(),
    [course],
  );

  return (
    <Observer>
      {() => (
        <div
          className={`ProgramClientCoursesListItem ${styles.ProgramClientCoursesListItem}`}
          onClick={handleOnClick}
        >
          <div
            key={membership._id}
            className={styles.ProgramClientCoursesListItemContainer}
          >
            <div className={styles.iconCalendarContainer}>
              <IconCalendar className={styles.iconCalendar} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.content}>
                <div className={styles.date}>{courseDurationString}</div>
                <div className={styles.secondRow}>
                  {edition && edition.group_size > 1 && course ? (
                    <div className={styles.courseSizeStyle}>
                      {`${course.counters.memberships.accepted}/${edition.group_size}`}
                    </div>
                  ) : null}
                  {status ? (
                    <div className={styles.courseStatusStyle}>{status}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.iconCalendarContainer}>
            {membership.status !== 'requested' ? <IconNext /> : null}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ProgramClientCoursesListItem);
