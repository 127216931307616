import React, {useEffect, useRef, useState} from 'react';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';

import {t} from '@src/i18n';

import ProgramsList from './Program/ProgramsList';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.program';

const ProgramStep = props => {
  const [programs, setPrograms] = useState<Program[]>([]);

  const programStore = useRef<CollectionStore<Program>>(
    createCollectionStore({
      method: 'client.programs.list',
      params: {
        sort: [['created', 1]],
        query: [['is_onboarding', '==', true]],
        expand: {program: ['edition_ids']},
      },
    }),
  ).current;

  useEffect(() => {
    const fetchPrograms = async () => {
      setPrograms(await programStore.fetch());
    };

    fetchPrograms();
  }, [programStore]);

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={9}
      buttons="skip"
      autoNext
      clearNext
      fieldName="_program"
      {...props}
    >
      <ProgramsList items={programs} />
    </EditorStep>
  );
};

export default ProgramStep;
