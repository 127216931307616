import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {}

const CircleGreen: FC<Props> = () => {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="CircleGreen1" fill="white">
        <path d="M50 1.42181C50 0.636566 50.6369 -0.00211006 51.4218 0.0202093C61.9257 0.318885 72.0824 3.92077 80.4381 10.3323C88.7938 16.7439 94.9017 25.6223 97.9088 35.6909C98.1335 36.4433 97.6814 37.2238 96.9229 37.4271C96.1644 37.6303 95.3869 37.1792 95.1609 36.4272C92.3167 26.9632 86.5661 18.6188 78.707 12.5883C70.8479 6.55785 61.2995 3.16287 51.4218 2.86505C50.6369 2.84138 50 2.20705 50 1.42181Z" />
      </mask>
      <path
        d="M50 1.42181C50 0.636566 50.6369 -0.00211006 51.4218 0.0202093C61.9257 0.318885 72.0824 3.92077 80.4381 10.3323C88.7938 16.7439 94.9017 25.6223 97.9088 35.6909C98.1335 36.4433 97.6814 37.2238 96.9229 37.4271C96.1644 37.6303 95.3869 37.1792 95.1609 36.4272C92.3167 26.9632 86.5661 18.6188 78.707 12.5883C70.8479 6.55785 61.2995 3.16287 51.4218 2.86505C50.6369 2.84138 50 2.20705 50 1.42181Z"
        stroke="#25d2a2"
        strokeWidth="20"
        mask="url(#CircleGreen1)"
      />
      <mask id="CircleGreen2" fill="white">
        <path d="M7.90135 74.2393C7.22084 74.6311 6.34956 74.3979 5.97724 73.7065C0.994929 64.4547 -0.951563 53.8555 0.435514 43.415C1.82259 32.9746 6.46906 23.2513 13.6942 15.6213C14.2341 15.0512 15.1361 15.0535 15.6907 15.6094C16.2453 16.1653 16.2424 17.0642 15.7035 17.6353C8.92095 24.8225 4.559 33.9697 3.25437 43.7895C1.94973 53.6094 3.772 63.5782 8.44264 72.287C8.81377 72.979 8.58185 73.8475 7.90135 74.2393Z" />
      </mask>
      <path
        d="M7.90135 74.2393C7.22084 74.6311 6.34956 74.3979 5.97724 73.7065C0.994929 64.4547 -0.951563 53.8555 0.435514 43.415C1.82259 32.9746 6.46906 23.2513 13.6942 15.6213C14.2341 15.0512 15.1361 15.0535 15.6907 15.6094C16.2453 16.1653 16.2424 17.0642 15.7035 17.6353C8.92095 24.8225 4.559 33.9697 3.25437 43.7895C1.94973 53.6094 3.772 63.5782 8.44264 72.287C8.81377 72.979 8.58185 73.8475 7.90135 74.2393Z"
        stroke="#25d2a2"
        strokeWidth="20"
        mask="url(#CircleGreen2)"
      />
    </svg>
  );
};

export default memo(CircleGreen);
