import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Observer} from 'mobx-react';

import {action, observable, reaction} from 'mobx';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {labelAddQuestion} from '@src/common/i18n/i18nQuestionnaire';
import {CustomButton} from '@src/components/CustomForm';

import questionnaireAppStore from '../../../../context/qAppStore';
import FactoryInput from '../../../FactoryInput/FactoryInput';
import styles from '../../../styles.module.css';

interface Props {}

interface IQAddQuestionLocaleStore {
  questions: QuestionnaireQuestion[];
  setQuestions: (newQuestions: QuestionnaireQuestion[]) => void;
}

const AddQuestion: FC<Props> = () => {
  const localStore = useRef(
    observable<IQAddQuestionLocaleStore>(
      {
        questions: [],
        setQuestions(newQuestions: QuestionnaireQuestion[]) {
          this.questions = newQuestions;
        },
      },
      {
        questions: observable,
        setQuestions: action,
      },
    ),
  ).current;

  useEffect(() => {
    const dispose = reaction(
      () => questionnaireAppStore?.currentSection?.questions,
      questions => {
        if (questions) {
          localStore.setQuestions(questions);
        }
      },
    );

    if (questionnaireAppStore?.currentSection) {
      localStore.setQuestions(questionnaireAppStore?.currentSection?.questions);
    }

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSaveInput = (indexQuestion: number) => {
    return (updateInput: QuestionnaireQuestion) => {
      questionnaireAppStore?.updateCurrentQuestion(updateInput, indexQuestion);
    };
  };

  const handlerDelInput = (indexQuestion: number) => {
    return () => {
      questionnaireAppStore?.delCurrentQuestion(indexQuestion);
    };
  };

  const handleOldQuestion = (indexQuestion: number) => {
    return () => {
      questionnaireAppStore?.setOldQuestion(indexQuestion);
    };
  };

  const handleUpdateTypeOpen = (
    newType: QuestionnaireQuestion['type'],
    numQuestion: number,
  ) => {
    questionnaireAppStore?.updateCurrentQuestionType(newType, numQuestion);
  };

  const handleOnClickAdd = () => {
    questionnaireAppStore?.addNewQuestion();
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    questionnaireAppStore?.reorderQuestion(
      result.source.index,
      result.destination.index,
    );
  };

  const nDragStart = () => {};

  return (
    <Observer>
      {() => (
        <div className={`AddQuestion ${styles.AddQuestion}`}>
          {/* FIXME: Should it be removed??? */}
          <div className={styles.countQuestions} style={{display: 'none'}}>
            {localStore.questions.slice().length}
          </div>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={nDragStart}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {localStore.questions.slice().map((question, index) => (
                    <Draggable
                      draggableId={
                        (questionnaireAppStore?.activeSectionId
                          ? questionnaireAppStore?.activeSectionId
                          : 'no') + index
                      }
                      index={index}
                      key={
                        (questionnaireAppStore?.activeSectionId
                          ? questionnaireAppStore?.activeSectionId
                          : 'no') + index
                      }
                    >
                      {providedEl => (
                        <Observer>
                          {() => (
                            <>
                              <div
                                ref={providedEl.innerRef}
                                {...providedEl.draggableProps}
                                {...providedEl.dragHandleProps}
                                className={
                                  localStore.questions.slice().length > 1
                                    ? styles.movingField
                                    : ''
                                }
                              >
                                {localStore.questions.slice().length > 1 ? (
                                  <>
                                    <div className={styles.movingIcon}>⋮⋮</div>
                                    <div className={styles.movingText}>{`#${
                                      index + 1
                                    } (You
                                can change the order by dragging this line)`}</div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                              <FactoryInput
                                question={question}
                                numQuestion={index}
                                sectionId={
                                  questionnaireAppStore?.activeSectionId
                                }
                                saveInput={handlerSaveInput(index)}
                                updateTypeOpen={handleUpdateTypeOpen}
                                setOldQuestion={handleOldQuestion(index)}
                                delInput={handlerDelInput(index)}
                                indexQ={index}
                              />
                            </>
                          )}
                        </Observer>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <CustomButton
              type="button"
              classButton="standardButton borderRadius10 mt30"
              onClick={handleOnClickAdd}
            >
              {labelAddQuestion()}
            </CustomButton>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(AddQuestion);
