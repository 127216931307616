import ee from 'event-emitter';

export const emitter = ee();

export const getPagePath = () => {
  return `${document.location.pathname}${document.location.search}`;
};

export const getFirstSymbols = (str: string) => {
  const arrStr = str
    .replace('[CLONE]', '')
    .replace(/[^a-zA-Z\d\s!?]+/g, '')
    .trim()
    .split(' ')
    .slice(0, 2)
    .map(subStr => subStr.charAt(0));

  return arrStr.join('').toUpperCase();
};

export const getColorByString = (str: string) => {
  var hash = 0;

  for (var i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var colour = '#';

  for (var i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    var value = (hash >> (i * 8)) & 0xff;

    colour += ('00' + value.toString(16)).substr(-2);
  }

  return colour;
};

export const lightenDarkenColor = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^\da-f]/gi, '');

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;

  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export const convertCurrentTime = (number: number) => {
  const mins = Math.floor(number / 60);
  const secs = +(number % 60).toFixed();

  return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

export const proxifyUrl = (url: string) =>
  `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`;
