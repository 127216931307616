import type {FC} from 'react';
import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api/index';

import AppContext from '@src/context/App';

import type {ChannelT} from '../../context/useChatsLocalStore';
import ChatsListItem from '../ChatsListItem/ChatsListItem';

import styles from './../../styles.module.scss';

interface Props {
  channels?: ChannelT[];
  expandedMode?: boolean;
}

const ChatsList: FC<Props> = ({channels, expandedMode}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  return (
    <div className={`ChatsList percent100H ${styles.ChatsList}`}>
      {channels &&
        channels
          .slice()
          .sort((a, b) =>
            dayjs(
              datetimeObjToISOString(a.coach_unresponded_post_date),
            ).isAfter(
              dayjs(datetimeObjToISOString(b.coach_unresponded_post_date)),
            )
              ? 1
              : -1,
          )
          .map(channel => {
            if (channel.coach_unresponded_post_date !== null) {
              return (
                <ChatsListItem
                  key={channel._id}
                  channel={channel}
                  showUpdatedDate
                  showLastPost
                  showCoaches
                  isUserCoach={
                    !!currentUserStore?.user?.roles.includes('coach')
                  }
                  badge={channel.updatesCount}
                  coachUnrespondedDate={channel.coach_unresponded_post_date!}
                  expandedMode={expandedMode}
                />
              );
            }
          })}
      {channels && channels.length
        ? channels
            .slice()
            .sort((a, b) =>
              dayjs(datetimeObjToISOString(a.last_post?.created)).isBefore(
                dayjs(datetimeObjToISOString(b.last_post?.created)),
              )
                ? 1
                : -1,
            )
            .map(channel => {
              if (channel.coach_unresponded_post_date === null) {
                return (
                  <ChatsListItem
                    key={channel._id}
                    channel={channel}
                    showUpdatedDate
                    showLastPost
                    showCoaches
                    isUserCoach={
                      !!currentUserStore?.user?.roles.includes('coach')
                    }
                    badge={channel.updatesCount}
                    coachUnrespondedDate={channel.coach_unresponded_post_date}
                    expandedMode={expandedMode}
                  />
                );
              }
            })
        : null}
    </div>
  );
};

export default observer(ChatsList);
