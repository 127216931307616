import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as AddCardProps} from './AddCard';
import AddCard, {I18N_SCOPE} from './AddCard';

type Props = Partial<ModalProps> & AddCardProps;

const AddCardModal: React.FC<Props> = ({children, ...modalProps}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])}>
    <AddCard />
    {children}
  </Modal>
);

export default React.memo(AddCardModal);
