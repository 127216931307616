import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
  // RpcRequest,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Goal, Subgoal} from '@yourcoach/shared/api/goal';
import type {Edition, Program} from '@yourcoach/shared/api/program';

import {labelMainGoal} from '@src/common/i18n/i18nPrograms';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton, CustomSelect} from '@src/components/CustomForm';
import type {IOption} from '@src/components/CustomForm/CustomSelect/CustomSelect';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {IconCup} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {Expanded as CourseExpanded} from '@src/models/courses';
import {expand as courseExpand} from '@src/models/courses';
// import {expand as goalExpand} from '@src/models/goals';
import CRUGoal from '@src/modules/legacy/Goals/CRUGoal/CRUGoal';
import CRUSubgoal from '@src/modules/legacy/Goals/CRUSubgoal/CRUSubgoal';
import GoalsListItem from '@src/modules/legacy/Goals/GoalsListItem/GoalsListItem';
import type {Goal as PreviousGoal} from '@src/modules/legacy/Goals/SelectGoal/SelectGoal';
import SelectGoal from '@src/modules/legacy/Goals/SelectGoal/SelectGoal';

import {GoalsDataService} from '../../../v2/services/GoalsDataService';
import styles from '../styles.module.scss';

import PreviousGoalsListItem from './PreviousGoalsListItem/PreviousGoalsListItem';
import RangeDate from './RangeDate/RangeDate';

export const I18N_SCOPE = 'CreateCourse';

const I18N_SCOPE_CRU_SUBGOAL = 'CRUSubgoal';
const GOALS_SOURCE = ['program', 'previous'] as const;

interface ILocalStore {
  startDate: Date | null;
  goalsSource: typeof GOALS_SOURCE[number];
  skipGoals: boolean;
  subgoalObj: {
    goalId: string;
    goalIndex: number;
    subgoalIndex: number;
    subgoal: Subgoal;
    minDate?: Date;
    maxDate?: Date;
  } | null;
  programGoalsSelection: Goal[];
  previousGoals: PreviousGoal[];
  havePreviousSubgoals: boolean;
  minStartDate: Date;
  programGoalsSourceIsNewGoals: boolean;
  dateRangeError: string | null;
  setDateRangeError(dateRangeError: string | null): void;
  _setGoalsSource(source: typeof GOALS_SOURCE[number]): void;
  _setSkipGoals(): void;
  _fetchProgramGoals(silent?: boolean): void;
  _onProgramGoalSelectPress(goal: Goal): void;
  _onDeletePreviousGoalPress(goal: PreviousGoal): void;
  _onDeletePreviousSubgoalPress(subgoal: PreviousGoal['subgoals'][0]): void;
  _changePreviousSubgoalDates(): void;
  rangeDataListOptions: IOption[];
  setRangeDataListOptions(rangeDataListOptions: IOption[]): void;
  isShowModalRangeDate: boolean;
  setIsShowModalRangeDate(isShowModalRangeDate: boolean): void;
  isOpenEditGoalModule: boolean;
  setIsOpenEditGoalModule(isOpenEditGoalModule: boolean): void;
  isCurrentPreviousGoal: boolean;
  setIsCurrentPreviousGoal(isCurrentPreviousGoal: boolean): void;
  selectGoal: Goal | null;
  setSelectGoal(selectGoal: Goal | null): void;
  isOpenChooseGoalModule: boolean;
  setIsOpenChooseGoalModule(isOpenChooseGoalModule: boolean): void;
  editSubgoal: Subgoal | null;
  setEditSubgoal(editSubgoal: Subgoal | null): void;
  isOpenCRUSubgoalModule: boolean;
  setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean): void;
}

interface Props {
  program: Program & {
    editions: (Edition | undefined)[];
  };
  onCreate?: (course: Course & CourseExpanded) => void;
}

const CreateCourse: FC<Props> = ({program, onCreate}) => {
  const programGoalsStore = useRef<CollectionStore<Goal> | null>(null);
  const {
    stores: {
      goalStore,
      programStore,
      editionStore,
      courseStore,
      currentUserStore,
    },
  } = useContext(AppContext);
  const dataService = React.useRef(new GoalsDataService(goalStore));
  const localStore: ILocalStore = useMemo(
    () =>
      observable<ILocalStore>(
        {
          startDate: null,
          goalsSource: 'program',
          skipGoals: false,
          programGoalsSelection: [],
          previousGoals: [],
          subgoalObj: null,
          rangeDataListOptions: [],
          setRangeDataListOptions(rangeDataListOptions: IOption[]) {
            this.rangeDataListOptions = rangeDataListOptions;
          },
          dateRangeError: null,
          setDateRangeError(dateRangeError: string | null) {
            this.dateRangeError = dateRangeError;
          },
          isShowModalRangeDate: false,
          setIsShowModalRangeDate(isShowModalRangeDate: boolean) {
            this.isShowModalRangeDate = isShowModalRangeDate;
          },
          isOpenEditGoalModule: false,
          setIsOpenEditGoalModule(isOpenEditGoalModule: boolean) {
            this.isOpenEditGoalModule = isOpenEditGoalModule;
          },
          get havePreviousSubgoals() {
            return (
              this.previousGoals.reduce(
                (sum, goal) => sum + goal.subgoals.length,
                0,
              ) > 0
            );
          },
          minStartDate: dayjs().add(1, 'day').toDate(),
          programGoalsSourceIsNewGoals: false,
          _setGoalsSource(source: typeof GOALS_SOURCE[number]) {
            this.goalsSource = source;

            if (this.goalsSource === 'program') {
              this._fetchProgramGoals(true);
            }
          },
          _setSkipGoals() {
            this.skipGoals = !this.skipGoals;
          },
          async _fetchProgramGoals(silent = false) {
            if (programGoalsStore.current) {
              const storeWasLoaded = programGoalsStore.current.isLoaded;

              const goals = await programGoalsStore.current.fetch({}, {silent});

              if (!storeWasLoaded) {
                this.programGoalsSelection = goals;
              }
            }
          },
          _onProgramGoalSelectPress(goal: Goal) {
            const index = this.programGoalsSelection.findIndex(
              item => item._id === goal._id,
            );

            if (index >= 0) {
              this.programGoalsSelection.splice(index, 1);
            } else {
              this.programGoalsSelection.push(goal);
            }
          },
          _onDeletePreviousGoalPress(goal: PreviousGoal) {
            const index = this.previousGoals.findIndex(
              item => goal._id === item._id,
            );

            this.previousGoals.splice(index, 1);
          },
          _onDeletePreviousSubgoalPress(subgoal: PreviousGoal['subgoals'][0]) {
            const goalIndex = this.previousGoals.findIndex(
              item => subgoal.goal_id === item._id,
            );

            const subgoalIndex = this.previousGoals[
              goalIndex
            ].subgoal_ids.findIndex(id => subgoal._id === id);

            const subgoals = this.previousGoals[goalIndex].subgoals;

            subgoals.splice(subgoalIndex, 1);

            this.previousGoals[goalIndex] = {
              ...this.previousGoals[goalIndex],
              subgoals,
            };
          },
          _changePreviousSubgoalDates() {
            const lastEdition = program.editions[program.editions.length - 1];

            const minDate = dayjs(this.startDate!);
            const maxDate = dayjs(this.startDate!).add(
              lastEdition!.duration - 1,
              'day',
            );

            this.previousGoals = this.previousGoals.map(goal => ({
              ...goal,
              subgoals: goal.subgoals.map(subgoal => {
                if (goal.course) {
                  const startDateDaysDelta = minDate
                    .startOf('day')
                    .diff(
                      dayjs(
                        datetimeObjToISOString(goal.course.start_date),
                      ).startOf('day'),
                      'day',
                    );

                  let sgStartDate = dayjs(
                    datetimeObjToISOString(
                      // @ts-ignore
                      subgoal._initialStartDate || subgoal.start_date,
                    ),
                  ).add(startDateDaysDelta, 'day');

                  sgStartDate =
                    sgStartDate < minDate || sgStartDate > maxDate
                      ? minDate
                      : sgStartDate;

                  let sgEndDate = dayjs(
                    // @ts-ignore
                    datetimeObjToISOString(
                      subgoal._initialEndDate || subgoal.end_date,
                    ),
                  ).add(startDateDaysDelta, 'day');

                  sgEndDate = sgEndDate > maxDate ? maxDate : sgEndDate;

                  // one day program case
                  if (sgStartDate.isSame(sgEndDate, 'date')) {
                    sgEndDate = sgEndDate.add(1, 'day');
                  }

                  return {
                    ...subgoal,
                    // Need to cache initial start and end dates
                    // to prevent wrong calculations when the startDate is changing
                    // @ts-ignore
                    _initialStartDate:
                      subgoal._initialStartDate || subgoal.start_date,
                    // @ts-ignore
                    _initialEndDate:
                      subgoal._initialEndDate || subgoal.end_date,
                    start_date: ISOStringToDatetimeObj(
                      sgStartDate.toISOString(),
                    ),
                    end_date: ISOStringToDatetimeObj(sgEndDate.toISOString()),
                  };
                } else {
                  return subgoal;
                }
              }),
            }));
          },
          isCurrentPreviousGoal: false,
          setIsCurrentPreviousGoal(isCurrentPreviousGoal: boolean) {
            this.isCurrentPreviousGoal = isCurrentPreviousGoal;
          },
          selectGoal: null,
          setSelectGoal(selectGoal: Goal | null) {
            this.selectGoal = selectGoal;
          },
          isOpenChooseGoalModule: false,
          setIsOpenChooseGoalModule(isOpenChooseGoalModule: boolean) {
            this.isOpenChooseGoalModule = isOpenChooseGoalModule;
          },
          editSubgoal: null,
          setEditSubgoal(editSubgoal: Subgoal | null) {
            this.editSubgoal = editSubgoal;
          },
          isOpenCRUSubgoalModule: false,
          setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean) {
            this.isOpenCRUSubgoalModule = isOpenCRUSubgoalModule;
          },
        },
        {
          startDate: observable.ref,
          goalsSource: observable,
          skipGoals: observable,
          programGoalsSelection: observable.shallow,
          previousGoals: observable.shallow,
          havePreviousSubgoals: computed,
          minStartDate: observable.ref,
          programGoalsSourceIsNewGoals: observable,
          _setGoalsSource: action,
          _setSkipGoals: action,
          _fetchProgramGoals: action,
          _onProgramGoalSelectPress: action,
          _onDeletePreviousGoalPress: action,
          _onDeletePreviousSubgoalPress: action,
          _changePreviousSubgoalDates: action,
          rangeDataListOptions: observable,
          setRangeDataListOptions: action,
          dateRangeError: observable,
          setDateRangeError: action,
          setIsShowModalRangeDate: action,
          isOpenEditGoalModule: observable,
          setIsOpenEditGoalModule: action,
          isCurrentPreviousGoal: observable,
          setIsCurrentPreviousGoal: action,
          selectGoal: observable,
          setSelectGoal: action,
          isOpenChooseGoalModule: observable,
          setIsOpenChooseGoalModule: action,
          editSubgoal: observable,
          setEditSubgoal: action,
          isOpenCRUSubgoalModule: observable,
          setIsOpenCRUSubgoalModule: action,
          subgoalObj: observable,
        },
      ),
    [program.editions],
  );

  useEffect(() => {
    programGoalsStore.current = createCollectionStore({
      method: 'coach.goals.unbound.list',
      params: {
        query: [
          ['program_id', '==', program._id],
          ['course_id', '==', null],
        ],
        sort: [['sort_order', 1]],
      },
    });

    const disposeGoalStoreCreating = reaction(
      () => goalStore.creating,
      creating => {
        if (
          creating.success &&
          creating.entity &&
          creating.entity.program_id === program._id
        ) {
          programGoalsStore.current!.addItem(creating.entity);

          localStore.programGoalsSelection.push(creating.entity);
        }
      },
    );

    const disposeProgramGoalStoreCurrentIsLoaded = reaction(
      () => programGoalsStore.current!.isLoaded,
      isLoaded => {
        if (isLoaded) {
          localStore.programGoalsSourceIsNewGoals =
            !programGoalsStore.current!.hasItems;
        }
      },
    );

    if (localStore.goalsSource === 'program') {
      localStore._fetchProgramGoals();
    }

    return () => {
      disposeGoalStoreCreating();
      disposeProgramGoalStoreCurrentIsLoaded();

      if (programGoalsStore.current) {
        programGoalsStore.current.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _createCourse = async () => {
    const lastEdition = program.editions[program.editions.length - 1]!;

    try {
      if (lastEdition.status === 'draft') {
        await editionStore.sign(lastEdition, {
          sign: currentUserStore.user!.name,
        });

        lastEdition.status = 'active';

        programStore.localUpdate(program, {});
      }

      const startDate = ISOStringToDatetimeObj(
        dayjs(localStore.startDate!).toISOString(),
      );

      const course = (await courseStore.create({
        edition_id: lastEdition._id,
        start_date: startDate,
        expand: courseExpand,
      })) as Course & CourseExpanded;

      await _createGoalsIfNeeded(course);

      if (lastEdition.group_size > 1) {
        const courseIds = lastEdition.course_ids.slice();

        const newEdition = await editionStore.update(lastEdition, {
          revision: lastEdition.revision,
          course_ids: [...courseIds, course._id],
        });

        // @ts-ignore
        program.editions[program.editions.length - 1].revision =
          newEdition.revision;
      }

      programStore.localUpdate(program, {});

      if (onCreate) {
        await onCreate(course);
      }
    } catch (error) {
      let message = error.message;

      if (Array.isArray(error)) {
        message = error.find(item =>
          apiRequest.isRpcRequestError(item),
        ).message;
      }

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _createGoalsIfNeeded = async (course: Course & CourseExpanded) => {
    // TODO: look AddGoals:355 extract to one

    if (localStore.skipGoals) {
      return;
    }

    if (localStore.goalsSource === 'program') {
      if (localStore.programGoalsSelection.length) {
        // const batch: RpcRequest[] = [];
        //
        // localStore.programGoalsSelection.forEach((goal, i) => {
        //   batch.push({
        //     method: 'coach.goals.unbound.create',
        //     params: {
        //       parent_goal_id: goal._id.includes('goal') ? goal._id : null,
        //       program_id: course.program_id,
        //       course_id: course._id,
        //       title: goal.title,
        //       description: goal.description,
        //       is_client_managed: goal.is_client_managed,
        //       sort_order: i * 10,
        //       expand: goalExpand,
        //     },
        //   });
        // });
        //
        // await goalStore.createBatch(batch.map(item => item.params!));
        await dataService.current.createGoalsInCourse(
          localStore.programGoalsSelection,
          course,
        );
      }
    } else if (localStore.goalsSource === 'previous') {
      if (localStore.previousGoals.length) {
        // const goalsBatch: RpcRequest[] = [];
        // const subgoalsBatch: RpcRequest[] = [];
        //
        // localStore.previousGoals.forEach((goal, gIndex) => {
        //   goalsBatch.push({
        //     method: 'coach.goals.unbound.create',
        //     params: {
        //       parent_goal_id: goal._id,
        //       program_id: course.program_id,
        //       course_id: course._id,
        //       title: goal.title,
        //       description: goal.description,
        //       is_client_managed: goal.is_client_managed,
        //       sort_order: gIndex * 10,
        //       expand: goalExpand,
        //     },
        //   });
        //
        //   goal.subgoals.forEach((subgoal, sgIndex) => {
        //     subgoalsBatch.push({
        //       method: 'coach.subgoals.unbound.create',
        //       params: {
        //         goal_id: goal._id,
        //         title: subgoal.title,
        //         description: subgoal.description,
        //         icon: subgoal.icon,
        //         color: subgoal.color,
        //         start_date: subgoal.start_date,
        //         end_date: subgoal.end_date,
        //         unit: subgoal.unit,
        //         initial_value: subgoal.initial_value,
        //         target_value: subgoal.target_value,
        //         checkpoints: subgoal.checkpoints.map(
        //           checkpoint => checkpoint.title,
        //         ),
        //         sort_order: sgIndex * 10,
        //       },
        //     });
        //   });
        // });
        //
        // const goals = await goalStore.createBatch(
        //   goalsBatch.map(item => item.params!),
        // );
        //
        // await goalStore.sub.createBatch(
        //   subgoalsBatch.map(item => {
        //     const mainGoal = goals.find(
        //       goal => goal.parent_goal_id === item.params!.goal_id,
        //     );
        //
        //     return {
        //       ...item.params,
        //       goal_id: mainGoal ? mainGoal._id : null,
        //     };
        //   }),
        // );
        await dataService.current.createGoalsInCourse(
          localStore.previousGoals,
          course,
        );
      }
    }
  };

  const _onChoosePreviousGoalsButtonPress = () => {
    localStore.setIsOpenChooseGoalModule(true);
  };
  const handleCloseModalChooseGoal = () => {
    localStore.setIsOpenChooseGoalModule(false);
  };

  const handleOnSelectChooseGoal = (goals: PreviousGoal[]) => {
    localStore.setIsOpenChooseGoalModule(false);

    localStore.previousGoals = goals;

    localStore._changePreviousSubgoalDates();
  };

  const _onPreviousSubgoalPress = (subgoal: PreviousGoal['subgoals'][0]) => {
    const lastEdition = program.editions[program.editions.length - 1];

    let minDate: Date | undefined;
    let maxDate: Date | undefined;

    if (localStore.startDate) {
      minDate = localStore.startDate;
      maxDate = dayjs(localStore.startDate)
        .add(lastEdition!.duration, 'day')
        .toDate();
    }

    const goalIndex = localStore.previousGoals.findIndex(
      item => subgoal.goal_id === item._id,
    );

    const subgoalIndex = localStore.previousGoals[
      goalIndex
    ].subgoal_ids.findIndex(id => subgoal._id === id);

    localStore.subgoalObj = {
      goalId: subgoal.goal_id,
      goalIndex,
      subgoalIndex,
      subgoal,
      minDate,
      maxDate,
    };

    localStore.setIsOpenCRUSubgoalModule(true);
  };

  const onSuccessSubgoalEdit = (updatedSubgoal: Subgoal) => {
    localStore.setIsOpenCRUSubgoalModule(false);

    if (localStore.subgoalObj) {
      const subgoals =
        localStore.previousGoals[localStore.subgoalObj.goalIndex].subgoals;

      subgoals.splice(localStore.subgoalObj.subgoalIndex, 1, {
        ...updatedSubgoal,
        // @ts-ignore
        _initialStartDate: null,
        // @ts-ignore
        _initialEndDate: null,
      });

      localStore.previousGoals[localStore.subgoalObj.goalIndex] = {
        ...localStore.previousGoals[localStore.subgoalObj.goalIndex],
        subgoals,
      };

      localStore.subgoalObj = null;
    }
  };

  const handleCloseModalCRUSubgoal = () => {
    localStore.setIsOpenCRUSubgoalModule(false);
    localStore.subgoalObj = null;
  };

  const _onSetButtonPress = async () => {
    getCustomConfirmAlert({
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => _createCourse(),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _renderFooter = () => {
    return (
      <Observer>
        {() => (
          <div className={styles.actionButtonsContainer}>
            {localStore.startDate ? (
              <div className={styles.butBottContainer}>
                <div>
                  {!localStore.skipGoals &&
                  programGoalsStore.current &&
                  programGoalsStore.current.isLoaded &&
                  localStore.goalsSource === 'program' ? (
                    <CustomButton
                      type="button"
                      classButton={`blueButt ${styles.subActionButton}`}
                      onClick={_onAddGoalButtonPress}
                    >
                      +<span>{t([I18N_SCOPE, 'add_goal_button'])}</span>
                    </CustomButton>
                  ) : null}
                  {!localStore.skipGoals &&
                  programGoalsStore.current &&
                  programGoalsStore.current.isLoaded &&
                  localStore.goalsSource === 'previous' ? (
                    <CustomButton
                      type="button"
                      classButton={`blueButt ${styles.subActionButton}`}
                      onClick={_onChoosePreviousGoalsButtonPress}
                    >
                      <span>{t([I18N_SCOPE, 'choose_goals_button'])}</span>
                    </CustomButton>
                  ) : null}
                </div>
                <div>
                  <CustomButton
                    type="button"
                    classButton={`blueButt ${styles.saveBut}`}
                    onClick={_onSetButtonPress}
                  >
                    <div className={styles.actionButtonText}>
                      {t([I18N_SCOPE, 'set_button'])}
                    </div>
                    {localStore.skipGoals ? (
                      <div className={styles.actionButtonSubText}>
                        {t([I18N_SCOPE, 'set_without_goals_label'])}
                      </div>
                    ) : null}
                  </CustomButton>
                </div>
              </div>
            ) : (
              <div className={styles.nextButContainer}>
                <CustomButton
                  type="button"
                  classButton={`blueButt ${styles.saveButNext}`}
                  onClick={_onStartDateButtonPress}
                >
                  <span>Next</span>
                </CustomButton>
              </div>
            )}
          </div>
        )}
      </Observer>
    );
  };

  const handleOnProgramGoalSelectPress = (goal: Goal) => {
    localStore._onProgramGoalSelectPress(goal);
  };

  const _renderProgramGoal = (goal: Goal, index: number) => {
    const isSelected =
      localStore.programGoalsSelection.findIndex(
        item => item._id === goal._id,
      ) >= 0;

    return (
      <GoalsListItem
        key={goal._id}
        goal={goal}
        index={index + 1}
        label={`Main goal #${index + 1}`}
        isSelectable={!localStore.programGoalsSourceIsNewGoals}
        isSelected={isSelected}
        onSelectPress={handleOnProgramGoalSelectPress}
        onGetContextMenu={handleGetContextMenu(false)}
      />
    );
  };

  const menuProgramMainGoalId = 'menuProgramMainGoalId';
  const handleGetContextMenu =
    (isPreviousGoal: boolean = false) =>
    (goal: Goal, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      contextMenu.show({
        id: menuProgramMainGoalId,
        event: event,
        props: {
          isPreviousGoal,
          goal,
        },
      });
    };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const MyMenuProgramMainGoal = () => (
    <div onClick={handleClickMenu}>
      <Menu
        id={menuProgramMainGoalId}
        animation={animation.fade}
        className="contextMenu"
        style={{zIndex: 1000}}
      >
        <Item onClick={handleSeeGoal} className="contextMenuItem">
          edit goal
        </Item>
      </Menu>
    </div>
  );

  const handleSeeGoal = args => {
    const currentGoal = args.props.goal as Goal;
    const isPreviousGoal = args.props.isPreviousGoal as boolean;

    localStore.setIsCurrentPreviousGoal(isPreviousGoal);
    _onGoalPress(currentGoal);
  };

  const _onGoalPress = (goal: Goal) => {
    contextMenu.hideAll();
    localStore.setSelectGoal(goal);
    localStore.setIsOpenEditGoalModule(true);
  };

  const handleOnDeletePreviousGoalPress = (goal: PreviousGoal) => {
    localStore._onDeletePreviousGoalPress(goal);
  };

  const handleOnDeletePreviousSubgoalPress = (
    subgoal: PreviousGoal['subgoals'][0],
  ) => {
    localStore._onDeletePreviousSubgoalPress(subgoal);
  };

  const _renderPreviousGoal = (goal: PreviousGoal, index: number) => (
    <PreviousGoalsListItem
      key={goal._id}
      goal={goal}
      index={index + 1}
      onPress={handleGetContextMenu(true)}
      onDeleteButtonPress={handleOnDeletePreviousGoalPress}
      onSubgoalPress={_onPreviousSubgoalPress}
      onDeleteSubgoalButtonPress={handleOnDeletePreviousSubgoalPress}
    />
  );

  const lastEdition = program.editions[program.editions.length - 1];

  const _onStartDateButtonPress = () => {
    localStore.setIsShowModalRangeDate(true);
  };

  const handleCloseModalRangeDate = () => {
    localStore.setIsShowModalRangeDate(false);
  };

  const handleOnDateSelect = (date: Date) => {
    localStore.setIsShowModalRangeDate(false);

    if (
      !localStore.skipGoals &&
      localStore.goalsSource === 'previous' &&
      localStore.havePreviousSubgoals
    ) {
      getCustomConfirmAlert({
        title: t([
          I18N_SCOPE,
          'change_start_date_confirm',
          'subgoals',
          'title',
        ]),
        message: t([
          I18N_SCOPE,
          'change_start_date_confirm',
          'subgoals',
          'message',
        ]),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              return;
            },
          },
        ],
      });
    }

    runInAction(() => {
      localStore.startDate = date;

      let dateStr = '';

      if (localStore.startDate) {
        const endDate = dayjs(localStore.startDate)
          .add(lastEdition!.duration, 'day')
          .toDate();

        dateStr = getCourseDurationString({
          start_date: ISOStringToDatetimeObj(
            localStore.startDate.toISOString(),
          ),
          end_date: ISOStringToDatetimeObj(endDate.toISOString()),
        });
      }

      localStore.setRangeDataListOptions([
        {
          id: dateStr,
          val: dateStr,
        },
      ]);

      localStore._changePreviousSubgoalDates();
    });
  };

  const handleSetSkipGoals = () => {
    localStore._setSkipGoals();
  };

  const _onAddGoalButtonPress = () => {
    localStore.setSelectGoal(null);
    localStore.setIsOpenEditGoalModule(true);
  };

  const handleOnEditMainGoal = (createdGoal: Goal) => {
    localStore.setIsOpenEditGoalModule(false);

    if (localStore.isCurrentPreviousGoal) {
      const index = localStore.previousGoals.findIndex(
        item => createdGoal._id === item._id,
      );

      localStore.previousGoals[index] = {
        ...localStore.previousGoals[index],
        ...createdGoal,
      };
    } else if (localStore.selectGoal) {
      programGoalsStore.current!.updateItem(localStore.selectGoal, createdGoal);

      const index = localStore.programGoalsSelection.findIndex(
        item => item._id === localStore.selectGoal!._id,
      );

      if (index >= 0) {
        localStore.programGoalsSelection.splice(index, 1, createdGoal);
      }
    }
  };

  const handleCloseModalEditGoal = () => {
    localStore.setIsOpenEditGoalModule(false);
  };

  return (
    <Observer>
      {() => {
        return (
          <div className={`CreateCourse ${styles.CreateCourse}`}>
            <div className={styles.sectionContent}>
              <div className={styles.name}>
                {t([I18N_SCOPE, 'start_date_label'])}
                {':'}
              </div>
              <CustomSelect
                options={localStore.rangeDataListOptions}
                onSelect={() => {}}
                customClassError="noPadding"
                onClick={_onStartDateButtonPress}
                isCalendar
                error={
                  localStore.dateRangeError ? localStore.dateRangeError : ''
                }
                label={
                  localStore.rangeDataListOptions.slice()[0]
                    ? ''
                    : t([I18N_SCOPE, 'choose_label'])
                }
                defaultId={
                  localStore.rangeDataListOptions.slice()[0]
                    ? localStore.rangeDataListOptions.slice()[0].id
                    : ''
                }
                className={'qListStyle'}
                disabled={true}
              />
              {localStore.startDate ? (
                <div>
                  <div className={styles.container}>
                    <div className={styles.name}>
                      {t([I18N_SCOPE, 'goals_label'])}
                      {':'}
                    </div>
                    <div
                      onClick={handleSetSkipGoals}
                      className={styles.skipGoalsContainer}
                    >
                      <div
                        className={`${styles.checkbox} ${
                          localStore.skipGoals ? styles.isChecked : ''
                        }`}
                      />
                      <div className={styles.skipGoalsText}>
                        {t([I18N_SCOPE, 'skip_label'])}
                      </div>
                    </div>
                  </div>
                  {localStore.skipGoals ? (
                    <NoResults
                      text={t([I18N_SCOPE, 'skip_goals_title'])}
                      classNameImgContainer={styles.iconNoNameImgContainer}
                      className={styles.noResult}
                    >
                      <div className={styles.iconNoResultContainer}>
                        <div
                          className={styles.iconNoResultContainerDescription}
                        >
                          {t([I18N_SCOPE, 'skip_goals_description'])}
                        </div>
                        <IconCup
                          className={styles.iconNoResult}
                          viewBox="5 4 30 30"
                        />
                      </div>
                    </NoResults>
                  ) : (
                    <div>
                      {programGoalsStore.current ? (
                        <>
                          <div className={styles.goalsTypeContainer}>
                            {programGoalsStore.current.isLoaded ? (
                              <div className={styles.goalsTypeLabelContainer}>
                                {GOALS_SOURCE.map(source => {
                                  const isSelected =
                                    source === localStore.goalsSource;

                                  return (
                                    <div
                                      key={source}
                                      onClick={() =>
                                        localStore._setGoalsSource(source)
                                      }
                                      className={`${styles.goalsSource} ${
                                        isSelected ? styles.isSelected : ''
                                      }`}
                                    >
                                      <div className={styles.goalsSourceText}>
                                        {t([
                                          I18N_SCOPE,
                                          `${
                                            source === 'program' &&
                                            localStore.programGoalsSourceIsNewGoals
                                              ? 'new'
                                              : source
                                          }_goals_label`,
                                        ])}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div
                                className={styles.activityIndicatorContainer}
                              >
                                <Loader />
                              </div>
                            )}
                          </div>
                          {programGoalsStore.current.isLoaded &&
                          localStore.goalsSource === 'program' ? (
                            programGoalsStore.current.isFetching ? (
                              <Loader />
                            ) : (
                              <>
                                {programGoalsStore.current.items
                                  .slice()
                                  .map(_renderProgramGoal)}
                              </>
                            )
                          ) : null}
                          {programGoalsStore.current.isLoaded &&
                          localStore.goalsSource === 'previous'
                            ? localStore.previousGoals
                                .slice()
                                .map(_renderPreviousGoal)
                            : null}
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {_renderFooter()}
            <ModalAnimateWin
              showModal={localStore.isShowModalRangeDate}
              closeModalHandler={handleCloseModalRangeDate}
              className="greyHeaderContainer littleContainer Calendar"
              isBody
              classNameBody="whiteBody"
              classNameHeader="greyHeader"
              classNameCloseBut="greyHeaderBut"
              header={`${t([I18N_SCOPE, 'select_start_date_label'])}:`}
            >
              <RangeDate
                date={localStore.startDate || localStore.minStartDate}
                minDate={localStore.minStartDate}
                onDateSelect={handleOnDateSelect}
              />
            </ModalAnimateWin>
            <ModalAnimateWin
              showModal={localStore.isOpenChooseGoalModule}
              closeModalHandler={handleCloseModalChooseGoal}
              className="greyHeaderContainer halfContainer"
              isBody
              classNameBody="whiteBody noP30"
              header={t(['AddGoals', 'choose_goals_btn'])}
              classNameHeader="greyHeader"
              classNameCloseBut="greyHeaderBut"
            >
              <SelectGoal
                firstProgramId={program._id}
                multiSelection
                onSelect={handleOnSelectChooseGoal}
              />
            </ModalAnimateWin>
            <MyMenuProgramMainGoal />
            <ModalAnimateWin
              showModal={localStore.isOpenCRUSubgoalModule}
              closeModalHandler={handleCloseModalCRUSubgoal}
              className="greyHeaderContainer littleContainer w500 List"
              isBody
              classNameBody="whiteBody maxContent noP30"
              header={t([I18N_SCOPE_CRU_SUBGOAL, 'nav_edit_title'])}
              classNameHeader="greyHeader w500"
              classNameCloseBut="greyHeaderBut"
            >
              {localStore.subgoalObj ? (
                <CRUSubgoal
                  goalId={localStore.subgoalObj?.goalId}
                  subgoal={localStore.subgoalObj?.subgoal}
                  minDate={localStore.subgoalObj?.minDate}
                  maxDate={localStore.subgoalObj?.maxDate}
                  onSuccess={onSuccessSubgoalEdit}
                />
              ) : null}
            </ModalAnimateWin>
            <ModalAnimateWin
              showModal={localStore.isOpenEditGoalModule}
              closeModalHandler={handleCloseModalEditGoal}
              className="greyHeaderContainer"
              isBody
              classNameBody="whiteBody noP30"
              header={`${labelMainGoal()}`}
              classNameHeader="greyHeader"
              classNameCloseBut="greyHeaderBut"
            >
              {localStore.selectGoal ? (
                <CRUGoal
                  goal={localStore.selectGoal}
                  onSuccess={handleOnEditMainGoal}
                />
              ) : (
                <CRUGoal
                  programId={program._id}
                  closeModal={handleOnEditMainGoal}
                />
              )}
            </ModalAnimateWin>
          </div>
        );
      }}
    </Observer>
  );
};

export default memo(CreateCourse);
