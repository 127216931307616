import type {FC} from 'react';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';

import {labelAddTask, labelMyTasks} from '@src/common/i18n/i18nTasks';

import styles from './styles.module.css';

interface Props {}

const MyTasks: FC<Props> = () => {
  return (
    <div className={`MyTasks ${styles.MyTasks}`}>
      <h3>{labelMyTasks()}</h3>
      <Link
        to="/to-dos"
        className={`squareGreenButton gradientGreenBut ${styles.addTaskBut}`}
      >
        {labelAddTask()}
      </Link>
    </div>
  );
};

export default memo(MyTasks);
