import {useContext, useEffect, useRef, useState} from 'react';

import {action, observable} from 'mobx';

import type {DateTimeObj} from '@yourcoach/shared/api';
import type {Channel} from '@yourcoach/shared/api/channel';

import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import ChatsContext from '@src/modules/Chats/context/ChatsContext';
import type {
  ChannelT,
  IChatsLocalStore,
} from '@src/modules/Chats/context/useChatsLocalStore';

import {getBadgeColors} from 'web/widget/src/utils/getBadgeColors';

interface ILocalStore {
  isFirstLoading: boolean;
  setIsFirstLoading(isFirstLoading: boolean): void;
  timerId: number;
  setTimerId(timerId: number): void;
}

const useChatsListItem = (coachUnrespondedDate: DateTimeObj) => {
  const [badgeColor, setBadgeColor] = useState<string>('');

  const localStore: ILocalStore = useRef(
    observable(
      {
        timerId: 0,
        setTimerId(timerId: number) {
          this.timerId = timerId;
        },
        isFirstLoading: true,
        setIsFirstLoading(isFirstLoading: boolean) {
          this.isFirstLoading = isFirstLoading;
        },
      },
      {
        isFirstLoading: observable,
        setIsFirstLoading: action,
        timerId: observable,
        setTimerId: action,
      },
    ),
  ).current;

  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  const {
    stores: {chatsColumnsStore, eventStore},
  } = useContext(AppContext);

  const handleOnClickElement = (cselectChannel: ChannelT) => {
    return async () => {
      if (chatsLocalStore) {
        localStore.setIsFirstLoading(false);
        chatsLocalStore!.setSelectChannel(cselectChannel);
        chatsColumnsStore.setSelectedValue('channel', cselectChannel);
        clearTimeout(localStore.timerId);
        localStore.setTimerId(
          // @ts-ignore
          setTimeout(async () => {
            await eventStore!.fetchUnread({
              limit: 500,
            });

            let numNotif = 0;

            eventStore.unreadEvents.forEach(event => {
              if (event.type === 'post_created') {
                const channel = (event as any).contexts.find(
                  (item: any) => item && item._id.split(':')[0] === 'channel',
                ) as Channel | undefined;

                if (channel) {
                  numNotif += 1;
                }
              }
            });

            localAppStore?.setPostCreatedNotification(numNotif);
          }, 2000),
        );
      }
    };
  };

  useEffect(() => {
    return () => {
      clearTimeout(localStore.timerId);
    };
  }, [localStore.timerId]);

  useEffect(() => {
    const timer = setTimeout(
      () => getBadgeColors(coachUnrespondedDate, setBadgeColor),
      1000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [coachUnrespondedDate, setBadgeColor]);

  return {localStore, handleOnClickElement, badgeColor, setBadgeColor};
};

export default useChatsListItem;
