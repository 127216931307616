import React, {memo, useCallback, useContext, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';

import {logger} from '@yourcoach/shared/utils/logger';
import storage from '@yourcoach/shared/utils/storage';

import ScreenFlow, {FlowItem} from '@src/components/ScreenFlow';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';

import AccountTypeStep from './steps/AccountTypeStep';
import InviteCodeStep from './steps/InviteCodeStep';
import KeyExplainedStep from './steps/KeyExplainedStep';
import SignInStep from './steps/SignInStep';
import SignUpStep from './steps/SignUpStep';
import VerificationStep from './steps/VerificationStep';
import WelcomeStep from './steps/WelcomeStep';

const LoginPage = () => {
  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();

  const code = useURLQueryParam('code');
  const isreg = useURLQueryParam('isreg');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const sessionIsActive =
          (await authStore.sessionIsActive()) &&
          (await currentUserStore.userExists());

        if (sessionIsActive) {
          history.replace('/');
        } else {
          storage.clear();
        }
      } catch (error) {
        logger.error(error);
      }
    };

    checkAuth();
  }, [authStore, currentUserStore, history]);

  const table = useMemo(
    () => [
      // [current path, action, next path]

      // Choose sign-in or sign-up
      ['/', 'signIn', '/signIn'],
      ['/', 'signUp', '/signUp'],

      // Sign-Up flow (removed InviteCodeStep)
      ['/signUp', 'next', '/signUpVerification'],
      ['/signUpVerification', 'next', '/accountType'],

      // Verification after sign-in
      ['/signIn', 'next', '/emailVerification'],
      ['/signInPhone', 'next', '/phoneVerification'],

      // Switch between email/phone
      ['/signIn', 'usePhone', '/signInPhone'],
      ['/signInPhone', 'useEmail', '/signIn'],

      // Jump to sign-up
      ['/signIn', 'signUp', '/signUp'],
      ['/signInPhone', 'signUp', '/signUp'],
    ],
    [],
  );

  const transFn = useCallback(
    (path, action) => {
      const row = table.find(x => x[0] === path && x[1] === action);

      return row ? row[2] : null;
    },
    [table],
  );

  const initialStep = useMemo(() => {
    let step = <WelcomeStep />;

    if (code) {
      step = <VerificationStep login={isreg !== 'true'} code={code} />;
    }

    return step;
  }, [code, isreg]);

  return (
    <>
      <Helmet title={'Login'} />
      <ScreenFlow transitionFn={transFn}>
        <FlowItem path="/">{initialStep}</FlowItem>
        <FlowItem path="/signUp">
          <SignUpStep />
        </FlowItem>
        <FlowItem path="/signIn">
          <SignInStep />
        </FlowItem>
        <FlowItem path="/signInPhone">
          <SignInStep usePhone />
        </FlowItem>
        <FlowItem path="/emailVerification">
          <VerificationStep login />
        </FlowItem>
        <FlowItem path="/phoneVerification">
          <VerificationStep login usePhoneIcon />
        </FlowItem>
        <FlowItem path="/signUpVerification">
          <VerificationStep />
        </FlowItem>
        {/* <FlowItem path="/key">
          <InviteCodeStep />
        </FlowItem>
        <FlowItem path="/keyExplained">
          <KeyExplainedStep />
        </FlowItem> */}
        <FlowItem path="/accountType">
          <AccountTypeStep />
        </FlowItem>
      </ScreenFlow>
    </>
  );
};

export default memo(LoginPage);
