import {getCustomConfirmAlert} from '../components/CustomConfirmAlert/CustomConfirmAlert';

export interface IError {
  code?: string;
  isError?: boolean;
  message: string;
  data?: IDataError;
}

export interface IDataError {
  method: string;
  reason: {name: string};
  version: number[];
}

export const setError: (
  error: IError[] | string,
  tryAgainFunc?: () => void,
) => void = (error, tryAgainFunc = () => {}) => {
  if (
    error === 'Your access is frozen. Please manage your account on the web'
  ) {
    getCustomConfirmAlert({
      message: 'Your access is frozen. Please manage your account on the web',
      buttons: [
        {
          label: 'OK',
          onClick: () => {
            window.location.replace('/profile/user-payments');
          },
          type: 'confirm',
        },
      ],
    });
  }

  tryAgainFunc();
};
