import type {FC} from 'react';
import React from 'react';

import ChatsPage from '../../modules/Chats';
import GoalsPage from '../../modules/Goals';
import MyLibraryWidget from '../../modules/MyLibraryWidget/MyLibrary';
import {ToDosPage} from '../../modules/ToDosWidget/ToDos';
import styles from '../../styles/Widget.module.css';

interface Props {
  activeTab: string;
}

export const WidgetInnerContainer: FC<Props> = ({activeTab}) => {
  const renderWidgetContainerComponent = (currentActiveTab: string) => {
    switch (currentActiveTab) {
      case 'chat':
        return (
          <div className={styles.Widget_Component}>
            <ChatsPage />
          </div>
        );
      case 'to-dos':
        return (
          <div className={styles.Widget_Component}>
            <ToDosPage />
          </div>
        );
      case 'goals':
        return (
          <div className={styles.Widget_Component}>
            <GoalsPage />
          </div>
        );
      case 'library':
        return (
          <div className={styles.Widget_Component}>
            <MyLibraryWidget />
          </div>
        );
      default:
        return <div className={styles.Widget_Component} />;
    }
  };

  return (
    <div className={styles.Widget_Component_Container}>
      {renderWidgetContainerComponent(activeTab)}
    </div>
  );
};
