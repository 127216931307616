import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import NotificationTopContainer from '../NotificationTopContainer/NotificationTopContainer';
import {MainMenuLayout, TopMenuLayout} from '../';

interface Props {
  customMainClass?: string;
  allHeight?: boolean;
  offsetMenuContainerClass?: string;
  children: ReactNode;
  isWithCrumbs?: boolean;
  isWithNewCrumbs?: boolean;
  isWithFrom?: boolean;
}

const MainContainerWithMenu: FC<Props> = ({
  customMainClass = '',
  allHeight = false,
  children,
  offsetMenuContainerClass = '',
  isWithFrom,
  isWithCrumbs,
  isWithNewCrumbs,
}) => {
  return (
    <main className={`MainContainerWithMenuLayout ${customMainClass}`}>
      <NotificationTopContainer />

      <div className="container">
        <MainMenuLayout />
        <div
          className={`offsetMenuContainer ${
            allHeight ? 'allHeight' : ''
          } ${offsetMenuContainerClass}`}
        >
          <TopMenuLayout
            isWithFrom={isWithFrom}
            isWithCrumbs={isWithCrumbs}
            isWithNewCrumbs={isWithNewCrumbs}
          />
          {children}
        </div>
      </div>
    </main>
  );
};

export default memo(MainContainerWithMenu);
