import type {ReactElement} from 'react';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';

import {autorun} from 'mobx';

import {validateFields} from '@yourcoach/shared/utils/validation';
import type {HtmlTextInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {t} from '@src/i18n';

import LeftPaneStep from './LeftPaneStep';

export interface Form {
  submit: () => void;
}

interface Props {
  icon?: React.ReactElement;
  onClick?: (event) => void;
  onChange?: (value, fieldName) => void;
  onResponse?: (response) => void;
  field: HtmlTextInputField<HTMLInputElement>;
  fieldName?: string;
  onSubmit: (value) => Promise<any>;
  children?: ReactElement | ReactElement[];
}

const FormStep = React.forwardRef<Form, Props>(
  (
    {icon, onChange, onClick, onResponse, field, fieldName, onSubmit, children},
    ref,
  ) => {
    useEffect(
      () =>
        autorun(() => {
          onChange && onChange(field.value, fieldName);
        }),
      [field.value, fieldName, onChange],
    );

    const onNextClick = useCallback(
      async event => {
        if (validateFields({email: field})) {
          onSubmit(field.value)
            .then(response => {
              onResponse && onResponse(response);
              onClick && onClick(event);
            })
            .catch(err => {
              let {message} = err;

              if (message !== '_skip') {
                if (err.code === 'error.resource.user.blocked_account') {
                  message = 'This account is blocked';
                }

                getCustomConfirmAlert({
                  title: t('Common.ErrorOccurred'),
                  message,
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => {},
                    },
                  ],
                });
              }
            });
        }
      },
      [field, onClick, onResponse, onSubmit],
    );

    const nextButtonRef = useRef<HTMLButtonElement>();

    useLayoutEffect(() => {
      const buttons = document.getElementsByTagName('button');

      Array(buttons.length)
        .fill(0)
        .map((_, i) => buttons[i])
        .filter(
          b =>
            b.attributes['data-action'] &&
            b.attributes['data-action'].value === 'next',
        )
        .forEach(b => {
          b.onclick = onNextClick;
          nextButtonRef.current = b;
        });
    });

    useImperativeHandle(
      ref,
      () => ({
        submit: () => {
          onNextClick({
            currentTarget: nextButtonRef.current,
            target: nextButtonRef.current,
          });
        },
      }),
      [onNextClick, nextButtonRef],
    );

    return <LeftPaneStep icon={icon}>{children}</LeftPaneStep>;
  },
);

export default FormStep;
