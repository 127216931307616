import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Edition, ParsedPaymentPlan} from '@yourcoach/shared/api/program';
import {parsePaymentPlan} from '@yourcoach/shared/api/program';

import {labelSharedCourseStatus} from '@src/common/i18n/i18nCommon';
import {IconCalendar, IconGroupSmall} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import useIsLimitedEdition from '@src/hooks/useIsLimitedEdition';
import {t} from '@src/i18n';
import type {ICourse} from '@src/models/app';

import SeeProgramContext from '../context/SeeProgramContext';

import styles from './../styles.module.css';

const I18N_SCOPE = 'SeeProgramPublic';

interface Props {}

const MainColumn: FC<Props> = () => {
  const seeProgramContext = useContext(SeeProgramContext);
  const history = useHistory();
  const [paymentPlan, setPaymentPlan] = useState<ParsedPaymentPlan | null>(
    null,
  );
  const [courses, setCourses] = useState<ICourse[] | null>([]);
  const [lastEdition, setLastEdition] = useState<Edition | null>(null);

  const {isLimitedEdition} = useIsLimitedEdition(
    seeProgramContext?.program?._id,
  );

  useEffect(() => {
    if (seeProgramContext && seeProgramContext?.program) {
      const countEditions = seeProgramContext?.program.editions.length - 1;
      const lastEditionLocal =
        seeProgramContext?.program.editions[countEditions];

      // @ts-ignore
      setCourses(lastEditionLocal.courses as ICourse[]);
      setLastEdition(lastEditionLocal);
      setPaymentPlan(parsePaymentPlan(lastEditionLocal.payment_plan));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeProgramContext?.program]);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      history.push({
        pathname: '/login',
      });
    },
    [history],
  );

  return (
    <>
      {seeProgramContext && seeProgramContext.program ? (
        <div className={`MainColumn ${styles.MainColumn}`}>
          <Helmet title={seeProgramContext.program.title} />
          <div className={styles.headerProgram}>
            <div className={styles.headerProgramLeft}>
              <h1>{seeProgramContext.program.title}</h1>
              {isLimitedEdition !== null && !isLimitedEdition ? (
                <div className={styles.paymentPlan}>
                  {paymentPlan ? paymentPlan!.toString() : ''}
                </div>
              ) : null}
              <div className={styles.programDescription}>
                {seeProgramContext.program.description}
              </div>
            </div>
            <div />
          </div>
          {courses?.length ? (
            <div className={styles.coursesContainer}>
              <div className={styles.tabsContainer}>
                <div className={styles.tabTitle}>
                  {t([I18N_SCOPE, 'groups'])}
                </div>
              </div>
              {courses?.map(course => {
                let status = '';

                if (course.status === 'ongoing') {
                  status = labelSharedCourseStatus(course.status);
                }

                return (
                  <div key={course._id} className={styles.course}>
                    <div className={styles.programCoursesContainer}>
                      <div className={styles.contentContainerLeft}>
                        <div className={styles.iconCalendarContainer}>
                          <IconCalendar className={styles.iconCalendar} />
                        </div>
                        <div className={styles.durationContainer}>
                          {getCourseDurationString(course)}
                        </div>
                      </div>
                      <div className={styles.contentContainerRight}>
                        <div className={styles.status}>
                          <div className={styles.courseStatus}>{status}</div>
                          {lastEdition && lastEdition.group_size > 1 ? (
                            <div
                              className={`${styles.groupContainer} ${
                                course.counters.memberships.accepted ===
                                lastEdition.group_size
                                  ? styles.grey
                                  : styles.green
                              }`}
                            >
                              <IconGroupSmall
                                className={styles.iconGroupSmall}
                              />
                              <div className={styles.courseSize}>
                                {`${course.counters.memberships.accepted}/${lastEdition.group_size}`}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.buttonsContainer}>
                          {status === '' ? (
                            <div
                              className={`${styles.button} ${styles.buttonGreen}`}
                              onClick={handleOnClick}
                            >
                              <span>{t([I18N_SCOPE, 'join_program'])}</span>
                            </div>
                          ) : (
                            <div
                              className={`${styles.button} ${styles.buttonGray}`}
                              onClick={handleOnClick}
                            >
                              <span>{t([I18N_SCOPE, 'send_request'])}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default memo(MainColumn);
