import {useEffect, useState} from 'react';

const useIsDOMRendered = () => {
  const [isDOMRendered, setIsDOMRendered] = useState<boolean>(false);

  useEffect(() => {
    return () => setIsDOMRendered(true);
  }, []);

  return {
    isDOMRendered,
  };
};

export default useIsDOMRendered;
