import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import type {Questionnaire} from '@yourcoach/shared/api/questionnaire';

import {
  labelBackToFormsAndQrs,
  labelSendQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {IconPrev, IconSettings} from '@src/components/icons';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {ColorWrapper} from '@src/layouts';
import {PATH_TYPE} from '@src/routes/utils';

import QDetail from '../QDetail/QDetail';
import QSendUsers from '../QSendUsers/QSendUsers';

interface Props {}

const QStandardDetail: FC<Props> = () => {
  const [userQ, setUserQ] = useState<Questionnaire | null>(null);
  const [showModal, setShowModal] = useState(false);
  const redirect = useAppRedirect();
  const QUserId = useURLQueryParam('id');

  const handleClickBack = () => {
    redirect(`/${PATH_TYPE.yourSpace}/${PATH_TYPE.myToolbox}`);
  };

  const handleClickUseTemplateEditBut = () => {
    redirect(`/${PATH_TYPE.questionnaire}/edit?id=${QUserId}`);
  };

  const handleClickUseTemplateSendBut = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const getUserQ = async () => {
      try {
        let questionnaireId = QUserId;

        // means that we receive user_questionnaire here
        if (questionnaireId?.includes('user_')) {
          const {user_questionnaire} = await apiRequest({
            method: 'coach.questionnaires.user.read',
            params: {
              _id: questionnaireId,
            },
          });

          questionnaireId = user_questionnaire.questionnaire_id;
        }

        const {questionnaire} = await apiRequest({
          method: 'coach.questionnaires.own.read',
          params: {
            _id: questionnaireId,
          },
        });

        setUserQ(questionnaire);
      } catch (error) {
        setError(error.message);
      }
    };

    getUserQ();

    return () => {};
  }, [QUserId]);

  return (
    <div className="QStandardDetail">
      <ColorWrapper containerClass="pl50">
        <CustomButton
          type="button"
          classButton="QuestionnaireHeaderBut"
          onClick={handleClickBack}
        >
          <IconPrev customClass="IconPrev" />
          <span>{labelBackToFormsAndQrs()}</span>
        </CustomButton>
      </ColorWrapper>
      {userQ && (
        <QDetail quest={userQ} questId={QUserId}>
          <div className="flex">
            <CustomButton
              type="button"
              classButton="greyBorderButt mr30"
              onClick={handleClickUseTemplateEditBut}
            >
              <IconSettings />
            </CustomButton>
            <CustomButton
              type="button"
              classButton="standardButton borderRadius10"
              onClick={handleClickUseTemplateSendBut}
            >
              {labelSendQuestionnaire()}
            </CustomButton>
          </div>
        </QDetail>
      )}
      <ModalAnimateWin
        showModal={showModal}
        closeModalHandler={closeModal}
        className="greenModal"
        classNameCloseBut="modalCloseBut"
      >
        <QSendUsers idQ={QUserId!} closeModal={closeModal} />
      </ModalAnimateWin>
    </div>
  );
};

export default memo(QStandardDetail);
