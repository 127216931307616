import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import NotificationTopContainer from '../NotificationTopContainer/NotificationTopContainer';
import {MainMenuLayout, TopMenuLayout} from '../';

interface Props {
  customMainClass?: string;
  children: ReactNode;
  withTopMenu?: boolean;
  isWithFrom?: boolean;
  isWithCrumbs?: boolean;
  isWithNewCrumbs?: boolean;
}

const MainContainerWithMenuColor: FC<Props> = ({
  customMainClass = '',
  children,
  withTopMenu = true,
  isWithFrom,
  isWithNewCrumbs,
  isWithCrumbs,
}) => {
  return (
    <main className={`MainContainerWithMenuLayout ${customMainClass}`}>
      <NotificationTopContainer />

      <div className="container">
        <MainMenuLayout />
        {withTopMenu ? (
          <div className="offsetMenuContainer">
            <TopMenuLayout
              isWithFrom={isWithFrom}
              isWithCrumbs={isWithCrumbs}
              isWithNewCrumbs={isWithNewCrumbs}
            />
          </div>
        ) : null}
      </div>
      {children}
    </main>
  );
};

export default memo(MainContainerWithMenuColor);
