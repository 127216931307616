import React, {useCallback, useState} from 'react';

import {TabBarItem} from './Item';
import * as S from './styles';
import type {Props} from './types';

export const TabBar: React.FC<Props> = ({
  tabs,
  index: indexProp,
  onIndexChange,
  ...rest
}) => {
  const [index, setIndex] = useState(indexProp || 0);

  const onTabClick = useCallback(
    (tabIndex: number) => {
      const result = onIndexChange?.(tabIndex);

      if (!result) {
        setIndex(tabIndex);
      }
    },
    [onIndexChange],
  );

  return (
    <S.Container {...rest}>
      {tabs.map((tab, i) => (
        <TabBarItem
          key={tab.key}
          title={tab.title}
          active={i === index}
          index={i}
          onClick={onTabClick}
        />
      ))}
    </S.Container>
  );
};
