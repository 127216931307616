import type {FC} from 'react';
import React from 'react';

import cn from 'classnames';

import Modal from '../Modal/Modal';

import styles from './index.module.css';

interface Props {
  onClose: () => void;
  onDecline?: () => void;
}

export const ConfirmationModal: FC<Props> = ({onClose, onDecline}) => {
  return (
    <Modal classNameContent={styles.modalContentWrapper}>
      <div className={cn(styles.wrapper, styles.confirmationWrapper)}>
        <p className={cn(styles.title, styles.confirmationTitle)}>
          In order to participate in coaching you will need to agree to the
          Terms and Conditions of the program.
        </p>
        <div className={cn(styles.buttonsWrapper, styles.confirmationButtons)}>
          <button className={styles.button} onClick={onClose}>
            Ok, let's review it
          </button>
          <button className={styles.button} onClick={onDecline}>
            No Thanks
          </button>
        </div>
      </div>
    </Modal>
  );
};
