import type {FC} from 'react';
import React, {memo, useMemo} from 'react';
import {Img as Image} from 'react-image';

import {
  getFileExtension,
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';

import {IconCheck} from '@src/components/icons';
import i18n from '@src/i18n';
import type {TFileWithLink} from '@src/models/library';

import {getFileIconByExt} from '../../libraryFunctions';

interface Props {
  file: TFileWithLink;
  className?: string;
  onClick: () => void;
  isSelect: boolean;
}

const UserFile: FC<Props> = ({className, file, isSelect, onClick}) => {
  const url = useMemo(() => getFileSrc(file).url, [file]);
  const {size, urlrepr} = getFileSrc(file);
  const fileName = getFileName(file);
  const ext = getFileExtension(fileName).toLowerCase();
  const Icon = getFileIcon(file);
  const IconByExt = getFileIconByExt(ext);

  const isImage = file.categories.includes('image') ? true : false;
  const preview = useMemo(() => {
    if (file.categories.includes('website')) {
      return getFileSrc(file, 'image').url;
    } else if (file.categories.includes('image')) {
      return url;
    } else {
      return null;
    }
  }, [file, url]);

  return (
    <div className={`UserFile ${className}`} onClick={onClick}>
      <div className="UserFileContainer">
        <div className={`checkItem ${isSelect ? 'active' : ''}`}>
          {isSelect && (
            <div className="checkedContainer">
              <IconCheck />
            </div>
          )}
        </div>
        {isImage && preview && (
          <div className="imagePreviewContainer">
            <Image
              src={getFileSrc(file, 250).url || ''}
              className="imagePreview"
              loader={<Icon />}
              unloader={<Icon />}
            />
          </div>
        )}
        {!isImage && !urlrepr && (
          <div className="documentIcon">
            <IconByExt />
          </div>
        )}
        {!isImage && urlrepr && (
          <div className="documentIconUrl">
            <Icon />
          </div>
        )}
        <div className="documentName">
          <span>{fileName}</span>
        </div>
        {size && file.type !== 'url' ? (
          <div className="documentSize">{i18n.toHumanSize(size)}</div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(UserFile);
