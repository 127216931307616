import React, {useCallback} from 'react';

import classNames from 'classnames';

import type {Card} from '@yourcoach/shared/api/payment/card';
import TrashIcon from '@yourcoach/shared/assets/icons/trash.svg';

import PaymentCard from '@src/modules/payments/PaymentCard';

import styles from './PaymentMethods-CardsListItem.module.css';

export type Props = {
  card: Card;
  onClick?: (card: Card) => void;
  onDeleteButtonClick?: (card: Card) => void;
};

const CardsListItem: React.FC<Props> = ({
  card,
  onClick,
  onDeleteButtonClick,
}) => {
  const onClickCb = useCallback(() => {
    onClick && onClick(card);
  }, [card, onClick]);

  const onDeleteButtonClickCb = useCallback(() => {
    onDeleteButtonClick && onDeleteButtonClick(card);
  }, [card, onDeleteButtonClick]);

  return (
    <div className={classNames('CardsListItem', styles.CardsListItem)}>
      <PaymentCard card={card} onClick={onClick ? onClickCb : undefined} />
      {onDeleteButtonClick ? (
        <div className={styles.actionsContainer}>
          <span>{`**** ${card.card!.last4}`}</span>
          <div
            className={styles.actionIconContainer}
            onClick={onDeleteButtonClickCb}
          >
            <TrashIcon />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CardsListItem);
