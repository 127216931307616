import React from 'react';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';

import SettingsIcon from '@yourcoach/shared/assets/icons/primary/Setting.svg';
import TickSquareIcon from '@yourcoach/shared/assets/icons/primary/Tick Square.svg';
import InfoCircleIcon from '@yourcoach/shared/assets/icons/secondary/Info Circle.svg';
import type {
  ConflictConferencesStore,
  ScheduleStore,
} from '@yourcoach/shared/stores/calendar-data';
import {Button} from '@yourcoach/shared/uikit/Button';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {IconButton} from '../../../../components/IconButton';
import {I18N_MY_CALENDAR} from '../../utils';

export const ControlsContainer: React.FC<{
  schedule: ScheduleStore;
  conflicts: ConflictConferencesStore;
}> = observer(({schedule, conflicts}) => {
  const onEditSelectedHandler = React.useCallback(() => {
    schedule.isSelectMode.setValue(false);
    schedule.isEditSelectedModalOpen.setValue(true);
  }, [schedule]);

  const onCancelSelectedHandler = React.useCallback(() => {
    schedule.isSelectMode.setValue(false);
    schedule.selected.clear();
  }, [schedule]);

  return (
    <View style={{gap: 12, alignItems: 'center'}}>
      {schedule.isSelectMode.value ? (
        <React.Fragment>
          <Button
            style={{minWidth: 120}}
            onClick={onEditSelectedHandler}
            disabled={schedule.selected.isEmpty}
          >
            <Text color={'inherit'}>{t(['shared.button', 'edit'])}</Text>
          </Button>
          <Button
            style={{minWidth: 120}}
            onClick={onCancelSelectedHandler}
            scheme={'gray'}
          >
            <Text color={'inherit'}>{t(['shared.button', 'cancel'])}</Text>
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {conflicts.list.isNotEmpty && (
            <IconButton
              onClick={conflicts.isModalOpen.toggle}
              scheme={'error'}
              icon={<InfoCircleIcon />}
            >
              <Text color={'text2'}>
                {conflicts.list.length +
                  ' ' +
                  t([I18N_MY_CALENDAR, 'buttons', 'session_errors'])}
              </Text>
            </IconButton>
          )}
          {schedule.isShowEditSelectedButton.value && (
            <IconButton
              onClick={schedule.isSelectMode.toggle}
              scheme={'option'}
              iconFillColor={'icon3'}
              icon={<TickSquareIcon />}
            >
              <Text color={'text3'}>
                {t([I18N_MY_CALENDAR, 'buttons', 'edit_spec_days'])}
              </Text>
            </IconButton>
          )}
          <IconButton
            onClick={schedule.isEditWeeklyModalOpen.toggle}
            icon={<SettingsIcon />}
            iconFillColor={'icon3'}
            scheme={'option'}
          >
            <Text color={'text3'}>
              {t([I18N_MY_CALENDAR, 'buttons', 'edit_week_schedule'])}
            </Text>
          </IconButton>
        </React.Fragment>
      )}
    </View>
  );
});
