import type {FC, ReactNode} from 'react';
import React, {memo, useContext} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';

import ModalAnimateWin from '../../components/GoalsModal/GoalsModal';
import AppContext from '../../context/App';
import AddFile from '../../modules/AddFile/AddFile';

interface Props {
  getFile: (files: IFile[]) => void;
  children?: ReactNode;
  closeModal: () => void;
  showModal: boolean;
  isOnlyImage?: boolean;
  multiple?: boolean;
  maxSize?: number;
  isOnlyPdf?: boolean;
  isOnlyDock?: boolean;
}

const GetUserFile: FC<Props> = ({
  getFile,
  children,
  closeModal,
  showModal,
  isOnlyImage = false,
  isOnlyDock = false,
  isOnlyPdf = false,
  maxSize = 300000,
  multiple = true,
}) => {
  const {
    stores: {fileStore},
  } = useContext(AppContext);

  const handleOnUpLoad = filesArr => {
    return new Promise(async (resolve: (files: IFile[]) => void) => {
      const files: IFile[] = await Promise.all(
        filesArr
          .map(file => {
            return () =>
              fileStore.upload(file, {
                name: file.name,
              });
          })
          .map(fn => fn()),
      );

      if (files.length) {
        getFile(files);
      }

      closeModal();

      resolve(files);
    });
  };

  return (
    <>
      {children}
      <ModalAnimateWin showModal={showModal} closeModalHandler={closeModal}>
        <AddFile
          isOnlyDock={isOnlyDock}
          onUpload={handleOnUpLoad}
          isOnlyImage={isOnlyImage}
          isOnlyPdf={isOnlyPdf}
          maxSize={maxSize}
          multiple={multiple}
        />
      </ModalAnimateWin>
    </>
  );
};

export default memo(GetUserFile);
