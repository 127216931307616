import type {FC} from 'react';
import React, {memo} from 'react';

import styled from 'styled-components';

import type {Course as ICourse} from '@yourcoach/shared/api/course';
import {getColor} from '@yourcoach/shared/styles/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import type {TSystemFolder} from '../../../../models/library';
import IconFolder from '../../assets/icon-folder.svg';
import styles from '../styles.module.css';

interface Props {
  data: TSystemFolder & {
    course_id?: string;
    channel_ids?: string[];
    course?: ICourse;
  };
  onSelectCourse: (arg: string) => void;
  className: string;
  link?: string;
  dataLink?: string;
  forUser?: boolean;
}

const SIconFolder = styled(IconFolder)`
  fill: ${getColor('global1')};
`;

const SystemFolder: FC<Props> = ({
  className,
  data,
  onSelectCourse,
  forUser = false,
}) => {
  const {name, isCourses} = data;

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    logger.event('library_folder_open_tap');

    if (isCourses || forUser) {
      onSelectCourse(data.course?._id ? data.course?._id : '');
    } else {
    }
  };

  return (
    <div
      className={`SystemFolder ${className}`}
      style={{cursor: 'pointer'}}
      onClick={handleOnClick}
    >
      <div className={`${styles.iconContainer} ${styles.link}`}>
        <SIconFolder />
      </div>
      <div className={styles.folderName}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default memo(SystemFolder);
