import React from 'react';

import styled from 'styled-components';

import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {useController} from './controller';
import {webContainer} from './styles';
import type {Props, StyledProps} from './types';

const Container = styled(View)<StyledProps>`
  ${webContainer}
`;

// const StyledText = styled(Text)<StyledProps>`
//   ${text};
// `;

const TaskStatus: React.FC<WithDomStyleProp<Props>> = ({
  isOverdue,
  status,
  sizeVariant,
  ...rest
}) => {
  const {iconFill, Icon, schemeStatus, label, textSizeVariant} = useController({
    isOverdue,
    status,
    sizeVariant,
  });

  return (
    <Container {...rest} sizeVariant={sizeVariant} scheme={schemeStatus}>
      {sizeVariant === 'default' && (
        <IconBox bg={'transparent'} fill={iconFill} customSize={13} padding={0}>
          <Icon />
        </IconBox>
      )}
      <Text variant={textSizeVariant} color={'inherit'}>
        {label}
      </Text>
    </Container>
  );
};

export {TaskStatus};
