import type {ChangeEvent, FC, ReactNode, RefObject} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {CustomInput} from '@src/components/CustomForm';

interface ILocalStore {
  isEditElement: boolean;
  setEditTitle(val: boolean): void;
  inputCurrentVal: string;
  setinputCurrentVal(newVal: string): void;
  inputCurrentValError: string;
  setinputCurrentValError(newVal: string): void;
}

interface Props {
  inputVal: string | undefined;
  children: ReactNode;
  label: string;
  test?: RegExp;
  testError?: string;
  customClassLabel: string;
  customClassCont: string;
  customClassInput: string;
  defaultLabel?: string;
  isClearDefault?: boolean;
  isOpenDefault?: boolean;
  setOldQuestion?: () => void;
  isRequired?: boolean;
  getOpenFn?: (fn: () => void) => void;
  onUpdateVal: (newVal: string) => void;
  onChange?: (newVal: string) => void;
}

const SwitchEditInput: FC<Props> = ({
  inputVal,
  children,
  label,
  customClassLabel,
  customClassCont,
  customClassInput,
  getOpenFn = () => {},
  setOldQuestion = () => {},
  onChange = () => {},
  test,
  testError = 'This field is not valid',
  isRequired = false,
  defaultLabel,
  isClearDefault = false,
  isOpenDefault = false,
  onUpdateVal,
}) => {
  const inputRef = useRef(null);
  const untouched = useRef(true);

  const localStore: ILocalStore = useRef(
    observable<ILocalStore>(
      {
        isEditElement: false,
        setEditTitle(val: boolean) {
          this.isEditElement = val;
        },
        inputCurrentVal: '',
        setinputCurrentVal(newVal: string) {
          this.inputCurrentVal = newVal;
        },
        inputCurrentValError: '',
        setinputCurrentValError(newVal: string) {
          this.inputCurrentValError = newVal;
        },
      },
      {
        isEditElement: observable,
        inputCurrentValError: observable,
        inputCurrentVal: observable,
        setEditTitle: action,
        setinputCurrentVal: action,
        setinputCurrentValError: action,
      },
    ),
  ).current;

  useEffect(() => {
    getOpenFn(() => {
      localStore.setEditTitle(true);
    });
  }, [getOpenFn, localStore]);

  useEffect(() => {
    if (inputVal) {
      localStore.setinputCurrentVal(inputVal);
    } else {
      localStore.setinputCurrentVal('');
    }

    if (
      untouched.current &&
      isOpenDefault &&
      localStore.inputCurrentVal === defaultLabel
    ) {
      localStore.setEditTitle(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal, localStore]);

  const handleOnClickUpdateTitle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    localStore.setinputCurrentValError('');
    localStore.setEditTitle(true);
  };
  const handleOnCreateTitle = () => {
    if (inputRef.current) {
      (inputRef as RefObject<HTMLInputElement>).current!.focus();
      (inputRef as RefObject<HTMLInputElement>).current!.select();
    }
  };
  const handleOnCangeinputCurrentVal = (e: ChangeEvent<HTMLInputElement>) => {
    localStore.setinputCurrentVal(e.currentTarget.value);

    if (isRequired && localStore.inputCurrentVal.length <= 0) {
      localStore.setinputCurrentValError('This field is required.');
    } else if (test) {
      if (test.test(localStore.inputCurrentVal)) {
        localStore.setinputCurrentValError('');
        onChange(e.currentTarget.value);
      } else {
        localStore.setinputCurrentValError(testError);
      }
    } else {
      onChange(e.currentTarget.value);
      localStore.setinputCurrentValError('');
    }
  };
  const handleOnBlurTitle = () => {
    if (localStore.inputCurrentValError === '') {
      onUpdateVal(localStore.inputCurrentVal);
    } else {
      localStore.inputCurrentVal = inputVal ? inputVal : '';
    }

    setOldQuestion();
    untouched.current = false;

    localStore.setEditTitle(false);
  };

  return (
    <Observer>
      {() => (
        <div>
          {localStore.isEditElement ? (
            <CustomInput
              onCreate={handleOnCreateTitle}
              type="input"
              ref={inputRef}
              label={label}
              id={label}
              onBlur={handleOnBlurTitle}
              customClassLabel={customClassLabel}
              customClassCont={customClassCont}
              customClassInput={customClassInput}
              value={
                isClearDefault && localStore.inputCurrentVal === defaultLabel
                  ? ''
                  : localStore.inputCurrentVal
              }
              error={localStore.inputCurrentValError}
              showErrorImmediately
              onChange={handleOnCangeinputCurrentVal}
            />
          ) : (
            <div onClick={handleOnClickUpdateTitle}>{children}</div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(SwitchEditInput);
