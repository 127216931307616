import {useContext, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import dayjs from 'dayjs';
import minMaxDayjs from 'dayjs/plugin/minMax';
import {action, computed, observable, runInAction} from 'mobx';

import type {
  ApiRpcQuery,
  ApiRpcRequestParams,
  CancellablePromise,
  RpcRequest,
} from '@yourcoach/shared/api';
import {
  apiRequest,
  datetimeObjToISOString,
  expandObj,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Conference as IConference} from '@yourcoach/shared/api/conference';
import type {Task as ITask} from '@yourcoach/shared/api/task';

import AppContext from '@src/context/App';
import {getCurrentDateLocale} from '@src/i18n';
import type {Expanded as TaskExpanded} from '@src/models/tasks';
import {expand as taskExpand} from '@src/models/tasks';
import type {Expanded as ConferenceExpanded} from '@src/modules/conferences/utils';
import {expand as conferenceExpand} from '@src/modules/conferences/utils';
import type {CourseT} from '@src/modules/courses/SelectCourse';

export const DATE_FORMAT = 'YYYY-MM-DD';

const DATE_LOCALE = getCurrentDateLocale();
const FIRST_DAY_OF_WEEK_INDEX = DATE_LOCALE ? DATE_LOCALE.weekStart : 1;
const DAY_NAMES = DATE_LOCALE
  ? DATE_LOCALE.weekdaysShort!.map(day => day.toUpperCase())
  : // TODO: add default weekdays
    [];

if (FIRST_DAY_OF_WEEK_INDEX === 1) {
  DAY_NAMES.push(DAY_NAMES.shift()!);
}

dayjs.extend(minMaxDayjs);

export const I18N_SCOPE = 'TasksTab';

export const MIN_DATE = dayjs().subtract(6, 'month').startOf('month');

export const MAX_DATE = dayjs().add(12, 'month').endOf('month');

export type Task = ITask & TaskExpanded;

export type Conference = IConference & ConferenceExpanded;

export type IEvent = Task | Conference;

export interface IToDosLocalStore {
  selectedDateWhenScroll: string;
  setSelectedDateWhenScroll(newDate: Date): void;
  selectedDate: string;
  setSelectedDate(newDate: Date): void;
  addDay(): void;
  delDay(): void;
  currentMonth: string;
  isLoaded: boolean;
  setIsLoaded(isLoaded: boolean): void;
  tasks: Task[];
  conferences: Conference[];
  events: IEvent[];
  markedDates: {};
  userIsCoach: boolean | null;
  _fetchEvents(): void;
  updateDate(): void;
  _fetchRequest: CancellablePromise<any> | null;
  isFetching: boolean;
  setIsFetching(isFetching: boolean): void;
  clickableEvent: boolean;
  setClickableEvent(): void;
  clickableScrollTimerId: number;
  _fetchEventsOnCurrentMonthChangeTimeout: number;
  course: CourseT | null;
  setCourse(newCourse: CourseT | null): void;
  _getCourseEndDate(): void;
  setGetNearestDate(newDate: Date): string;
}

const useToDosLocalStore = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const location = useLocation();
  // @ts-ignore
  const course = location.state ? location.state.course || null : null;

  const store: IToDosLocalStore | null = useRef(
    observable<IToDosLocalStore>(
      {
        clickableScrollTimerId: 0,
        _fetchEventsOnCurrentMonthChangeTimeout: 0,
        clickableEvent: false,
        setClickableEvent() {
          this.clickableEvent = false;
          this.clickableEvent = true;

          clearTimeout(this.clickableScrollTimerId);

          this.clickableScrollTimerId = setTimeout(() => {
            runInAction(() => {
              this.clickableEvent = false;
            });
          }, 2000);
        },
        selectedDate: dayjs().format(DATE_FORMAT),
        setSelectedDate(newDate: Date) {
          this.selectedDate = dayjs(newDate).format(DATE_FORMAT);

          this.updateDate();
        },
        selectedDateWhenScroll: '',
        setSelectedDateWhenScroll(newDate: Date) {
          this.selectedDateWhenScroll = newDate;
        },
        addDay() {
          const arrDates = Object.getOwnPropertyNames(this.markedDates);
          const index = arrDates.findIndex(strDate => {
            return strDate === this.selectedDate;
          });

          if (index >= 0 && index < arrDates.length - 1) {
            const nameDate = arrDates[index + 1];

            this.selectedDate = nameDate;
          }

          this.updateDate();
        },
        setGetNearestDate(newDate: Date) {
          return getNearestDate(
            this.events,
            dayjs(newDate).format(DATE_FORMAT),
          );
        },
        delDay() {
          const arrDates = Object.getOwnPropertyNames(this.markedDates).sort();
          const index = arrDates.findIndex(strDate => {
            return strDate === this.selectedDate;
          });

          if (index > 0 && index < arrDates.length) {
            const nameDate = arrDates[index - 1];

            this.selectedDate = nameDate;
          }

          this.updateDate();
        },
        updateDate() {
          const mon = dayjs(this.selectedDate)
            .startOf('month')
            .format(DATE_FORMAT);

          if (this.currentMonth !== mon) {
            this.currentMonth = mon;
            this._fetchEvents();
          }
        },
        currentMonth: dayjs().startOf('month').format(DATE_FORMAT),
        isLoaded: false,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        isFetching: false,
        setIsFetching(isFetching: boolean) {
          this.isFetching = isFetching;
        },
        tasks: [],
        conferences: [],
        _fetchRequest: null,
        _getCourseEndDate() {
          if (!this.course) {
            return dayjs();
          }

          return this.course.status === 'ongoing'
            ? dayjs.max(
                dayjs(),
                dayjs(datetimeObjToISOString(this.course.end_date)).subtract(
                  1,
                  'day',
                ),
              )
            : dayjs(datetimeObjToISOString(this.course.end_date)).subtract(
                1,
                'day',
              );
        },
        get events() {
          return [
            ...(this.tasks as Task[]),
            ...(this.conferences as Conference[]),
          ];
        },
        get markedDates() {
          const eventsMap = {};

          this.events.forEach(event => {
            const key = dayjs(datetimeObjToISOString(event.start_date)).format(
              DATE_FORMAT,
            );

            eventsMap[key] = {
              ...eventsMap[key],
              marked: true,
              events: [event, ...((eventsMap[key] || {}).events || [])].sort(
                (a, b) =>
                  Date.parse(datetimeObjToISOString(a.start_date)) -
                  Date.parse(datetimeObjToISOString(b.start_date)),
              ),
            };
          });

          const dates = {
            ...eventsMap,
            [this.selectedDate]: {
              ...eventsMap[this.selectedDate],
              selected: true,
            },
          };

          return dates;
        },
        get userIsCoach() {
          return (
            currentUserStore.user &&
            currentUserStore.user.roles.includes('coach')
          );
        },
        async _fetchEvents() {
          if (this.isFetching && this._fetchRequest) {
            this._fetchRequest.cancel();
          }

          const startDate = dayjs.max(
            MIN_DATE,
            dayjs(this.currentMonth).subtract(1, 'month'),
          );
          const endDate = dayjs.min(
            MAX_DATE,
            dayjs(this.currentMonth).add(1, 'month').endOf('month'),
          );

          try {
            const params: ApiRpcRequestParams = {
              query: (
                [
                  [
                    'start_date',
                    '>=',
                    ISOStringToDatetimeObj(dayjs(startDate).toISOString()),
                  ],
                  [
                    'start_date',
                    '<=',
                    ISOStringToDatetimeObj(dayjs(endDate).toISOString()),
                  ],
                  this.course ? ['course_id', '==', this.course._id] : null,
                ] as ApiRpcQuery[]
              ).filter(Boolean),
              limit: 1000,
            };

            runInAction(() => {
              this.isFetching = true;
            });

            this._fetchRequest = apiRequest({
              batch: (
                [
                  {
                    method: 'client.tasks.list',
                    params: {
                      ...params,
                      expand: taskExpand,
                    },
                  },
                  {
                    method: 'client.conferences.list',
                    params: {
                      ...params,
                      expand: conferenceExpand,
                    },
                  },
                  this.userIsCoach
                    ? {
                        method: 'coach.tasks.coach.list',
                        params: {
                          ...params,
                          expand: taskExpand,
                        },
                      }
                    : null,
                  this.userIsCoach
                    ? {
                        method: 'coach.conferences.list',
                        params: {
                          ...params,
                          expand: conferenceExpand,
                        },
                      }
                    : null,
                ] as RpcRequest[]
              ).filter(Boolean),
            });

            const [
              tasksResult,
              conferencesResult,
              coachTasksResults,
              coachConferencesResult,
            ] = await this._fetchRequest;

            const tasks: Task[] = [];
            const conferences: Conference[] = [];

            const clientTasks: Task[] = [];
            const clientTasksLength = tasksResult._items.length;

            const processTasks = async () => {
              for (let taskI = 0; taskI < clientTasksLength; taskI += 1) {
                clientTasks[taskI] = expandObj(tasksResult._items[taskI], {
                  expand: taskExpand,
                  expanded: tasksResult._expanded,
                });
              }

              tasks.push(...clientTasks);
            };

            const clientConferences: Conference[] = [];
            const clientConferencesLength = conferencesResult._items.length;

            const processConferences = async () => {
              for (
                let conferencesI = 0;
                conferencesI < clientConferencesLength;
                conferencesI += 1
              ) {
                clientConferences[conferencesI] = expandObj(
                  conferencesResult._items[conferencesI],
                  {
                    expand: conferenceExpand,
                    expanded: conferencesResult._expanded,
                  },
                );
              }

              conferences.push(...clientConferences);
            };

            let coachTasks: Task[] = [];
            let coachConferences: Conference[] = [];

            const processCoachTasks = async () => {
              if (this.userIsCoach) {
                const coachTaskGroups = {};

                const groupByUuid = (task: Task) => {
                  const groupId = `${dayjs(
                    datetimeObjToISOString(task.start_date),
                  ).format(DATE_FORMAT)}-${task.uuid}`;

                  coachTaskGroups[groupId] = coachTaskGroups[groupId] || [];
                  coachTaskGroups[groupId].push({
                    ...task,
                    is_coach_task: true,
                  });
                };

                coachTasksResults._items.forEach(groupByUuid);

                Object.keys(coachTaskGroups).forEach(key => {
                  coachTasks.push(coachTaskGroups[key][0]);
                });

                const coachTasksLength = coachTasks.length;

                for (
                  let coachTaskI = 0;
                  coachTaskI < coachTasksLength;
                  coachTaskI += 1
                ) {
                  coachTasks[coachTaskI] = expandObj(coachTasks[coachTaskI], {
                    expand: taskExpand,
                    expanded: coachTasksResults._expanded,
                  });
                }

                tasks.push(...coachTasks);
              }
            };

            const processCoachConferences = async () => {
              if (this.userIsCoach) {
                const coachConferencesLength =
                  coachConferencesResult._items.length;

                for (
                  let conferencesI = 0;
                  conferencesI < coachConferencesLength;
                  conferencesI += 1
                ) {
                  coachConferences[conferencesI] = expandObj(
                    coachConferencesResult._items[conferencesI],
                    {
                      expand: conferenceExpand,
                      expanded: coachConferencesResult._expanded,
                    },
                  );
                }

                conferences.push(...coachConferences);
              }
            };

            await Promise.all(
              [
                processTasks,
                processConferences,
                processCoachTasks,
                processCoachConferences,
              ].map(fn => fn()),
            );

            runInAction(() => {
              this.tasks = tasks;
              this.conferences = conferences;

              this.isLoaded = true;
              this.isFetching = false;
            });
          } catch (error) {
            runInAction(() => {
              this.isLoaded = true;
              this.isFetching = false;
            });

            if (!apiRequest.isCanceledError(error)) {
              // TODO: log error
            }
          }
        },
        course: course,
        setCourse(newCourse: CourseT | null) {
          this.course = newCourse;
        },
      },
      {
        selectedDateWhenScroll: observable,
        setSelectedDateWhenScroll: action,
        selectedDate: observable,
        setSelectedDate: action,
        currentMonth: observable,
        tasks: observable.shallow,
        conferences: observable.shallow,
        isLoaded: observable,
        isFetching: observable,
        setIsLoaded: action,
        updateDate: action,
        addDay: action,
        delDay: action,
        _fetchEvents: action,
        setIsFetching: action,
        events: computed,
        markedDates: computed,
        userIsCoach: computed,
        clickableEvent: observable,
        setClickableEvent: action,
        clickableScrollTimerId: observable,
        _fetchEventsOnCurrentMonthChangeTimeout: observable,
        course: observable,
        setCourse: action,
        _getCourseEndDate: action,
        setGetNearestDate: action,
      },
    ),
  ).current;

  return store;
};

export default useToDosLocalStore;

const getNearestDate = (events: IEvent[], selectedDate: string) => {
  let retDate = selectedDate;
  let difDay = 999;

  for (let event of events) {
    const date = dayjs(datetimeObjToISOString(event.start_date));

    const thisDif = Math.abs(date.diff(selectedDate, 'day'));

    if (difDay > thisDif || thisDif === 0) {
      difDay = thisDif;
      retDate = date.format(DATE_FORMAT);
    }
  }

  return retDate;
};
