import {t} from '@src/i18n';

const I18N_SCOPE = 'Course';

export const labelClientsTab = () => t([I18N_SCOPE, 'clients_tab']);

export const labelClientTab = () => t([I18N_SCOPE, 'client_tab']);

export const labelCoachesTab = () => t([I18N_SCOPE, 'coaches_tab']);

export const labelGoalsTab = () => t([I18N_SCOPE, 'goals_tab']);

export const labelDeleteButton = () => t([I18N_SCOPE, 'delete_button']);

export const labelInvitesTab = () => t([I18N_SCOPE, 'invites_tab']);

export const labelMembersTab = () => t([I18N_SCOPE, 'members_tab']);

export const labelPaymentsTab = () => t([I18N_SCOPE, 'payments_tab']);

export const labelStartButton = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'start_button', groupType]);

export const labelStopButton = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'stop_button', groupType]);

export const labelAreYouSure = () => t([I18N_SCOPE, 'AreYouSure']);

export const labelTitle = () => t([I18N_SCOPE, 'title']);

export const labelCourseClientsTabFreezeClientConfirmMsg = () =>
  t([I18N_SCOPE, 'CourseClientsTab', 'freeze_client_confirm_message']);

export const labelCourseClientsTabNoResults = () =>
  t([I18N_SCOPE, 'CourseClientsTab', 'no_results_lbl']);

export const labelCourseClientsTabInviteButton = () =>
  t([I18N_SCOPE, 'CourseClientsTab', 'invite_button']);

export const labelCourseMembersTabNoResults = () =>
  t([I18N_SCOPE, 'CourseMembersTab', 'no_results_lbl']);

export const labelCourseMembersTabJoined = (date: string) =>
  t([I18N_SCOPE, 'CourseMembersTab', 'joined_lbl'], {date});

export const labelCourseInvitesTabNoResults = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'no_results_lbl']);

export const labelCourseInvitesTabProgramInvites = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'program_invites_lbl']);

export const labelCourseInvitesTabInviteButton = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'invite_button']);

export const labelCourseInvitesTabInviteOnlyButton = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'invite_only_button']);

export const labelCoursePaymentsTabNoResults = () =>
  t([I18N_SCOPE, 'CoursePaymentsTab', 'no_results_lbl']);

export const labelCoursePaymentsListItemConfirmButton = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'confirm_button']);

export const labelCoursePaymentsListItemConfirmDescription = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'confirm_description']);

export const labelCoursePaymentsListItemFailedDescription = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'failed_description']);

export const labelCoursePaymentsListItemMarkPaidButton = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'mark_paid_button']);

export const labelCoursePaymentsListItemMarkPaidDescription = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'mark_paid_description']);

export const labelCoursePaymentsListItemRetryButton = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'retry_button']);

export const labelCoursePaymentsListItemPayment = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'payment_lbl']);

export const labelCoursePaymentsListItemCancelButton = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'Cancel_button']);

export const labelCoursePaymentsListItemUnjoinedCourse = () =>
  t([I18N_SCOPE, 'CoursePaymentsListItem', 'unjoined_course']);

export const labelCourseInvitesTabInviteDeleteInvite = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'swipe_delete_invite_btn']);

export const labelCourseInvitesTabInviteResendInvite = () =>
  t([I18N_SCOPE, 'CourseInvitesTab', 'swipe_resend_invite_btn']);

export const labelFreeze = () =>
  t([I18N_SCOPE, 'CourseClientsListItem', 'freeze_button']);

export const labelUnfreeze = () =>
  t([I18N_SCOPE, 'CourseClientsListItem', 'unfreeze_button']);

export const labelStatusLbl = (status: 'archived' | 'canceled' | 'ongoing') =>
  t([I18N_SCOPE, 'status_lbl', status]);

export const labelClientListFrozenMsg = (
  status: 'admin' | 'client' | 'coach' | 'delete_profile' | 'failed_payment',
) => t([I18N_SCOPE, 'CourseClientsListItem', 'frozen_message', status]);

export const labelFrozenMsg = (
  status: 'client' | 'coach' | 'failed_payment' | 'delete_profile' | 'admin',
  type: 'group' | 'individual',
) => t([I18N_SCOPE, 'frozen_message', status, type]);

export const labelClientProfileButton = () =>
  t([I18N_SCOPE, 'client_profile_button']);

export const labelChargeError = () => t([I18N_SCOPE, 'charge_error']);

export const labelChangePaymentMethodButton = () =>
  t([I18N_SCOPE, 'change_payment_method_button']);
