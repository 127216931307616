import type {FC} from 'react';
import React, {memo} from 'react';

import TopMenu from './../../modules/TopMenu/TopMenu';

interface Props {
  isWithNewCrumbs?: boolean;
  isWithCrumbs?: boolean;
  isWithFrom?: boolean;
}

const TopMenuLayout: FC<Props> = ({
  isWithNewCrumbs,
  isWithCrumbs,
  isWithFrom,
}) => {
  return (
    <div className="TopMenuLayout">
      <TopMenu
        isWithFrom={isWithFrom}
        isWithNewCrumbs={isWithNewCrumbs}
        isWithCrumbs={isWithCrumbs}
      />
    </div>
  );
};

export default memo(TopMenuLayout);
