import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import FolderIcon from '@src/assets/img/FolderIcon.svg';
import {labelAddFolder} from '@src/common/i18n/i18nLibrary';
import {setError} from '@src/common/setError';
import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import AppContext from '@src/context/App';
import AddFolder from '@src/modules/AddFolder/AddFolder';

import LibraryContext from '../../context/LibraryContext';
import type {ILibraryLocalStore} from '../../context/useLibraryLocalStore';
import styles from '../styles.module.scss';

interface Props {
  className?: string;
}

interface ILocalStore {
  showModal: boolean;
  toggleVisModal(isShow: boolean): void;
}

const AddFolderButt: FC<Props> = () => {
  const {
    stores: {linkStore},
  } = useContext(AppContext);
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        showModal: false,
        toggleVisModal(isShow: boolean) {
          this.showModal = isShow;
        },
      },
      {
        showModal: observable,
        toggleVisModal: action,
      },
    ),
  ).current;

  const handleOnClick = () => {
    localStore.toggleVisModal(true);
  };

  const closeModal = () => {
    localStore.toggleVisModal(false);
  };

  const handleOnUpload = async (nameFile: string) => {
    libraryStory!.setLoading(true);

    try {
      await linkStore.create({
        name: nameFile,
        folder_id: libraryStory!.folderID || null,
        resource_id: null,
      });

      closeModal();

      libraryStory!.updateFolderLinks(1);
    } catch (error) {
      const tryAgainFn = () => {};

      setError(error, tryAgainFn);
    }

    libraryStory!.setLoading(false);
  };

  return (
    <Observer>
      {() => (
        <>
          <CustomButton
            type="button"
            classButton={`${styles.buttonItem}`}
            onClick={handleOnClick}
          >
            <div className={styles.buttonLabelContainer}>
              {labelAddFolder()}
              <FolderIcon className={styles.addItemButtonIcon} />
            </div>
          </CustomButton>
          <ModalAnimateWin
            showModal={localStore.showModal}
            className="modalAddFolder"
            closeModalHandler={closeModal}
          >
            <AddFolder onUpload={handleOnUpload} />
          </ModalAnimateWin>
        </>
      )}
    </Observer>
  );
};

export default memo(AddFolderButt);
