import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

// import {GoalT} from '@src/modules/Program/context/useProgramLocalStore';
import type {Goal as GoalT} from '@yourcoach/shared/api/goal';

import {IconCheck} from '@src/components/icons';

import styles from './../styles.module.scss';

interface Props {
  goal: GoalT;
  index: number;
  label?: string;
  containerStyle?: string;
  onGetContextMenu?: (
    goal: GoalT,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelectPress?: (goal: GoalT) => void;
}

const GoalsListItem: FC<Props> = ({
  goal,
  label = '',
  containerStyle = '',
  onGetContextMenu,
  isSelectable,
  isSelected,
  onSelectPress,
}) => {
  const onGetContextMenuCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onGetContextMenu && onGetContextMenu(goal, event);
    },
    [goal, onGetContextMenu],
  );

  const onSelectPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onSelectPress && onSelectPress(goal);
    },
    [goal, onSelectPress],
  );

  return (
    <Observer>
      {() => (
        <div
          className={`GoalsListItem ${containerStyle} ${styles.GoalsListItem} ${
            isSelected ? styles.active : ''
          }`}
          onClick={isSelectable ? onSelectPressCb : () => {}}
        >
          <div className={styles.checkBoxContainer}>
            <div
              className={`${styles.checkBox} ${
                isSelected ? styles.active : ''
              }`}
            >
              {isSelected ? <IconCheck /> : null}
            </div>
          </div>
          <div className={styles.goalContainer}>
            <div className={styles.goalLabe}>{label}</div>
            <div className={styles.goalTitle}>{goal.title}</div>
            <div className={styles.goalDescription}>{goal.description}</div>
          </div>
          <div className={styles.moreButContainer}>
            {onGetContextMenu ? (
              <div className={styles.moreBut} onClick={onGetContextMenuCb}>
                ⋮
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(GoalsListItem);
