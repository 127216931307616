import React, {memo} from 'react';

import WhitePlusIcon from '@src/assets/img/WhitePlusIcon.svg';

import {
  AddItemButtonContainer,
  AddItemButtonLabel,
  AddItemButtonLabelIcon,
  AddItemButtonLabelText,
  IconWrapper,
} from '../styles/stylesAddItemButton';

interface Props {
  onClickHandler?: () => void;
  buttonLabel: string;
}

const AddItemButton = ({onClickHandler, buttonLabel}: Props) => {
  return (
    <AddItemButtonContainer onClick={onClickHandler}>
      <AddItemButtonLabel>
        <AddItemButtonLabelIcon>
          <IconWrapper>
            <WhitePlusIcon />
          </IconWrapper>
        </AddItemButtonLabelIcon>
        <AddItemButtonLabelText>{buttonLabel}</AddItemButtonLabelText>
      </AddItemButtonLabel>
    </AddItemButtonContainer>
  );
};

export default memo(AddItemButton);
