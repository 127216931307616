import {useEffect, useState} from 'react';

import type {DateTimeObj} from '@yourcoach/shared/api';

import {getCoachHistoryList} from '../ChatsListContainer/ChatsListItem/api';

const useFetchHistoryList = channel => {
  const [coachChangeDate, setCoachChangeDate] = useState<DateTimeObj | null>(
    null,
  );

  const [isLoadedCoachHistoryList, setIsLoadedCoachHistoryList] =
    useState<boolean>(true);

  useEffect(() => {
    setIsLoadedCoachHistoryList(false);
    !isLoadedCoachHistoryList &&
      getCoachHistoryList(channel).then(response => {
        setCoachChangeDate(
          response._items[response._items.length - 1]?.start_date,
        );
      });

    return () => setIsLoadedCoachHistoryList(true);
  }, [
    channel,
    channel.resource_id,
    isLoadedCoachHistoryList,
    setCoachChangeDate,
  ]);

  return {coachChangeDate};
};

export default useFetchHistoryList;
