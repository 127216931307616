import {t} from '@src/i18n';

const I18N_SCOPE = 'Invite';

export const labelInviteTitle = () => t([I18N_SCOPE, 'title']);

export const labelInviteButton = () => t([I18N_SCOPE, 'invite_button']);

export const labelInviteByNameTabSearch = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'search_input_placeholder']);

export const labelInviteByNameTabDefaultMessage = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'default_message']);

export const labelInviteByNameTabAddMessageBtn = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'add_message_btn']);

export const labelInviteByNameTabAddAllBtn = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'add_all_btn']);

export const labelInviteByNameTabSaveBtn = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'save_btn']);

export const labelInviteByNameTabEditMessageBtn = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'edit_message_btn']);

export const labelInviteByNameTabNoResultsLbl = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'no_results_lbl']);

export const labelInviteByNameTabMessageTextInputPlaceholder = () =>
  t([I18N_SCOPE, 'InviteByNameTab', 'message_text_input_placeholder']);

export const labelInviteByEmailTab = () =>
  t([I18N_SCOPE, 'invite_by_email_tab']);

export const labelInviteByNameTab = () => t([I18N_SCOPE, 'invite_by_name_tab']);

export const labelInviteSuccessMsg = (count: number) =>
  t([I18N_SCOPE, 'success_msg'], {count});

export const labelInviteSuccessMsgWithExistedMemberships = (count: number) =>
  t([I18N_SCOPE, 'success_msg_with_existed_memberships'], {count});

export const labelInviteExistedMembershipsError = (
  count: number,
  userNames: string,
) => t([I18N_SCOPE, 'existed_memberships_error'], {count, userNames});
