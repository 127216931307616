import type {ChangeEvent} from 'react';

import {action, computed, observable} from 'mobx';

export interface ILoadFilesStore {
  fileList: ILoadFile[];
  hoverState: string | null;
  isUploadFile: boolean;
  getIsUploadFile: (newIsUploadFile: boolean) => void;
  getHoverState(
    e: React.DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  ): void;
  getFileList(newFileList: ILoadFile[]): void;
  removeItem(index: number): void;
  clearFileList(): void;
  fileExt: string;
  extTail: string | null;
  fileNames: string;
}

export interface ILoadFile extends File {
  lastModified: number;
  loading: boolean;
}

export const addFileLocalStore: ILoadFilesStore = observable(
  {
    fileList: [],
    isUploadFile: false,
    hoverState: null,
    getHoverState(
      e: React.DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
    ) {
      if (e.type === 'dragover') {
        this.hoverState = 'styles.hover';
      } else {
        this.hoverState = null;
      }
    },
    getIsUploadFile(newIsUploadFile) {
      this.isUploadFile = newIsUploadFile;
    },
    getFileList(newFileList) {
      if (newFileList.length > 0 && newFileList[0]) {
        this.fileList = newFileList;
      }
    },
    clearFileList() {
      this.fileList = [];
    },
    removeItem(index) {
      this.fileList.splice(index, 1);
    },
    get fileExt() {
      let retType: string = '';

      if (this.fileList.length === 1) {
        if (this.fileList[0].type) {
          retType = `.${getExtFromType(this.fileList[0].type)}`;
        } else {
          retType = `.${getExtFromName(this.fileList[0].name)}`;
        }
      }

      retType = retType === '.jpeg' ? '.jpg' : retType;

      return retType;
    },
    get extTail() {
      let retEl: string | null = null;

      if (this.fileExt) {
        retEl = this.fileExt;
      }

      return retEl;
    },
    get fileNames() {
      let retStr = 'No file chosen';

      if (this.fileList.length > 1) {
        retStr = `${this.fileList.length} 'Files'`;
      } else if (this.fileList.length === 1) {
        retStr = this.fileList[0].name.replace(this.fileExt, '');
      }

      return retStr;
    },
  },
  {
    fileList: observable,
    isUploadFile: observable,
    hoverState: observable,
    getHoverState: action,
    getIsUploadFile: action,
    getFileList: action,
    removeItem: action,
    clearFileList: action,
    fileExt: computed,
    extTail: computed,
    fileNames: computed,
  },
);

const getExtFromType = type => {
  const parts = type.split('/');

  return parts[parts.length - 1];
};

const getExtFromName = name => {
  const parts = name.split('.');

  return parts[parts.length - 1];
};
