import React, {memo} from 'react';

import classNames from 'classnames';

import styles from './Button.module.css';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<Props> = props => (
  <button
    {...props}
    className={classNames(
      'Button',
      styles.Button,
      props.className,
      props.disabled && styles.disabled,
    )}
  />
);

export default memo(Button);
