import React, {useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {Conference} from '@yourcoach/shared/api/conference';
import type {Task} from '@yourcoach/shared/api/task';

import type {ModalRef} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import type {Expanded as TaskExpanded} from '@src/models/tasks';
import CRUCalendarEvent from '@src/modules/calendar/CRUCalendarEvent';
import type {Expanded as ConferenceExpanded} from '@src/modules/conferences/utils';
import ToDosContext from '@src/modules/ToDos/context/ToDosContext';
import type {IToDosLocalStore} from '@src/modules/ToDos/context/useToDosLocalStore';

import styles from '../styles/stylesTasks.module.scss';

import AddItemButton from './AddItemButton';

interface ILocalStore {
  isShowCreateEventMod: boolean;
  setIsShowCreateEventMod(isShowCreateEventMod: boolean): void;
  date: Date;
  setDate(date: Date): void;
}

type Event = (Conference & ConferenceExpanded) | (Task & TaskExpanded);

type Course = Event['course'] | null;

interface Props {
  course: Course;
  selectedDate: string;
}

const TasksMenu: React.FC<Props> = ({course, selectedDate}) => {
  const toDosLocalStore: IToDosLocalStore | null = useContext(ToDosContext);

  const cruEventModalRef = useRef<ModalRef>(null);

  const localStore: ILocalStore = useRef(
    observable(
      {
        isShowCreateEventMod: false,
        setIsShowCreateEventMod(isShowCreateEventMod: boolean) {
          this.isShowCreateEventMod = isShowCreateEventMod;
        },
        date: new Date(),
        setDate(date) {
          this.date = date;
        },
      },
      {
        isShowCreateEventMod: observable,
        setIsShowCreateEventMod: action,
        date: observable,
        setDate: action,
      },
    ),
  ).current;

  const handleOnClickButCreateEvent = () => {
    const date = new Date(selectedDate);
    const now = new Date();

    date.setHours(now.getHours(), now.getMinutes());

    localStore.setDate(date);

    if (cruEventModalRef.current) {
      cruEventModalRef.current.show();
    }
  };

  const handleOnCreateEvent = () => {
    toDosLocalStore?._fetchEvents();

    if (cruEventModalRef.current) {
      cruEventModalRef.current.hide();
    }
  };

  return (
    <Observer>
      {() => (
        <>
          <div className={styles.TasksMenu}>
            <AddItemButton
              onClickHandler={handleOnClickButCreateEvent}
              buttonLabel={'Add task'}
            />
          </div>
          <Modal title={'Add task'} ref={cruEventModalRef}>
            <CRUCalendarEvent
              date={localStore.date}
              course={course}
              onCreate={handleOnCreateEvent}
            />
          </Modal>
        </>
      )}
    </Observer>
  );
};

export default React.memo(TasksMenu);
