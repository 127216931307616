import {useMemo} from 'react';

import {action, computed, observable} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import type {
  QuestionnaireQuestion,
  UserQuestionnaire,
} from '@yourcoach/shared/api/questionnaire';

import {
  labelThisQuestionIsRequired,
  labelThisQuestionIsRequiredAndMustContain,
} from '@src/common/i18n/i18nQuestionnaire';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import type {ExpandedCoach} from '@src/models/questionnaire';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

export interface IUserFlowLocalStore {
  currentQId: string | null;
  currentUserQ: UserQuestionnaire | null;
  setCurrentUserQ: (newCurrentUserQ: UserQuestionnaire) => void;
  coachAvatar?: IFile | null;
  setCoachAvatar: (newCoachAvatar: IFile | null) => void;
  coaches: ExpandedCoach[];
  setCoaches: (newCoaches: ExpandedCoach[]) => void;
  currentNumStep: number;
  setCurrentNumStep: (newCurrentNumStep: number) => void;
  maxStep: number;
  stepsQuestion: IStepQuestion[];
  setStepsQuestion: (newStepsQuestion: IStepQuestion[]) => void;
  currentStepQuestion: IStepQuestion | null;
  setCurrentStepQuestion: () => void;
  setNextStep: () => void;
  setCurrentStepQuestionError: (newTextError: string) => void;
  setCurrentStepQuestionUserAnswer: (newAnswer: string) => void;
  setDelCurrentStepQuestionAnswer: (delAnswer: string) => void;
  setToggleCurrentStepQuestionAnswer: (Answer: string) => void;
  isSelectCurrentStepQuestionAnswer: (Answer: string) => boolean;
  setCurrentStepQuestionAnswer: (newAnswer: string) => void;
  setCurrentStepQuestionAnswers: (newAnswers: string[]) => void;
  isValidQ: (question: IStepQuestion) => string;
  firstCurrentAnswer: string;
  numAnswered: number;
  getNumAnswer: () => void;
  isSendAnswers: boolean;
  setIsSendAnswer: (newVal: boolean) => void;
}

export interface IStepQuestion {
  question: QuestionnaireQuestion;
  sectionName: string;
  sectionDescription: string;
  numSection: number;
  numQuestion: number;
  answerArr: string[];
  userAnswer: string;
  textError: string;
}

const useUserFlowLocalStore = () => {
  const QUserId = useURLQueryParam('id');

  const store: IUserFlowLocalStore | null = useMemo(
    () =>
      observable<IUserFlowLocalStore>(
        {
          currentQId: QUserId,
          currentUserQ: null,
          setCurrentUserQ(newCurrentUserQ: UserQuestionnaire) {
            const newStepsQuestion: IStepQuestion[] = [];

            let maxStep = 0;

            this.currentUserQ = newCurrentUserQ;

            newCurrentUserQ.sections.forEach((section, numSection) => {
              section.questions.forEach((question, numQuestion) => {
                maxStep++;

                newStepsQuestion.push({
                  answerArr: [],
                  userAnswer: '',
                  sectionName: section.title,
                  sectionDescription: section.description,
                  question,
                  numQuestion,
                  numSection,
                  textError: '',
                });
              });
            });

            this.maxStep = maxStep;
            this.setStepsQuestion(newStepsQuestion);
            this.setCurrentStepQuestion();
          },
          coachAvatar: null,
          setCoachAvatar(newCoachAvatar: IFile | null) {
            this.coachAvatar = newCoachAvatar;
          },
          coaches: [],
          setCoaches(newCoaches: ExpandedCoach[]) {
            this.coaches = newCoaches;
          },
          stepsQuestion: [],
          setStepsQuestion(newStepsQuestion: IStepQuestion[]) {
            this.stepsQuestion = newStepsQuestion;
          },
          currentNumStep: 0,
          setCurrentNumStep(newCurrentNumStep: number) {
            if (newCurrentNumStep < 0) {
              newCurrentNumStep = 0;
            }

            if (newCurrentNumStep > this.maxStep) {
              newCurrentNumStep = this.maxStep + 1;
            }

            this.currentNumStep = newCurrentNumStep;

            this.setCurrentStepQuestion();
          },
          maxStep: 0,
          numAnswered: 0,
          currentStepQuestion: null,
          setCurrentStepQuestion() {
            this.currentStepQuestion = this.stepsQuestion[this.currentNumStep];
          },
          setNextStep() {
            const err = this.isValidQ(this.currentStepQuestion);

            if (err === '') {
              this.getNumAnswer();
              this.setCurrentNumStep(this.currentNumStep + 1);
            } else {
              this.setCurrentStepQuestionError(err);
            }
          },
          getNumAnswer() {
            let retNum = 0;

            (this.stepsQuestion as IStepQuestion[]).forEach(stepQ => {
              if (
                (stepQ.answerArr && stepQ.answerArr.length > 0) ||
                stepQ.userAnswer !== ''
              ) {
                retNum++;
              }
            });

            this.numAnswered = retNum;
          },
          isValidQ(question: IStepQuestion) {
            let retVal = '';

            if (question.question.is_optional) {
              retVal = '';
            } else {
              if (question.question.type === ALIAS_TYPES.text) {
                if (question.userAnswer.length > 1) {
                  retVal = '';
                } else {
                  retVal = labelThisQuestionIsRequiredAndMustContain();
                }
              } else if (
                question.question.type === ALIAS_TYPES.singleChoice ||
                question.question.type === ALIAS_TYPES.multipleChoice
              ) {
                if (
                  question.answerArr &&
                  question.answerArr[0] &&
                  question.answerArr[0].length > 1
                ) {
                  retVal = '';
                } else {
                  retVal = labelThisQuestionIsRequired();
                }
              } else if (
                question.question.type === ALIAS_TYPES.singleChoiceOpen ||
                question.question.type === ALIAS_TYPES.multipleChoiceOpen
              ) {
                if (
                  (question.answerArr &&
                    question.answerArr[0] &&
                    question.answerArr[0].length > 1) ||
                  question.userAnswer.length > 1
                ) {
                  retVal = '';
                } else {
                  retVal = labelThisQuestionIsRequired();
                }
              }
            }

            return retVal;
          },
          setCurrentStepQuestionError(newTextError: string) {
            this.currentStepQuestion.textError = newTextError;
          },
          setCurrentStepQuestionAnswer(newAnswer: string) {
            this.currentStepQuestion.answerArr[0] = newAnswer;
          },
          setDelCurrentStepQuestionAnswer(delAnswer: string) {
            this.currentStepQuestion.answerArr =
              this.currentStepQuestion.answerArr
                .slice()
                .filter(answer => answer !== delAnswer);
          },
          setToggleCurrentStepQuestionAnswer(answer: string) {
            if (this.isSelectCurrentStepQuestionAnswer(answer)) {
              this.setDelCurrentStepQuestionAnswer(answer);
            } else {
              this.currentStepQuestion.answerArr.push(answer);
            }
          },
          isSelectCurrentStepQuestionAnswer(answer: string) {
            return this.currentStepQuestion.answerArr.some(
              itemAnswer => itemAnswer === answer,
            );
          },
          setCurrentStepQuestionUserAnswer(newAnswer: string) {
            this.currentStepQuestion.userAnswer = newAnswer;
          },
          setCurrentStepQuestionAnswers(newAnswers: string[]) {
            this.currentStepQuestion.answerArr = newAnswers;
          },
          isSendAnswers: false,
          setIsSendAnswer(newVal: boolean) {
            this.isSendAnswers = newVal;
          },
          get firstCurrentAnswer() {
            return this.currentStepQuestion.answerArr.slice()[0];
          },
        },
        {
          currentQId: observable,
          currentUserQ: observable,
          coachAvatar: observable,
          coaches: observable,
          numAnswered: observable,
          stepsQuestion: observable,
          maxStep: observable,
          isSendAnswers: observable,
          currentNumStep: observable,
          currentStepQuestion: observable,
          setCurrentUserQ: action,
          setCoachAvatar: action,
          setCoaches: action,
          setNextStep: action,
          getNumAnswer: action,
          setStepsQuestion: action,
          setIsSendAnswer: action,
          setCurrentNumStep: action,
          setCurrentStepQuestion: action,
          setCurrentStepQuestionError: action,
          setCurrentStepQuestionUserAnswer: action,
          setCurrentStepQuestionAnswer: action,
          setDelCurrentStepQuestionAnswer: action,
          setToggleCurrentStepQuestionAnswer: action,
          setCurrentStepQuestionAnswers: action,
          isValidQ: action,
          firstCurrentAnswer: computed,
        },
      ),
    [QUserId],
  );

  return store;
};

export default useUserFlowLocalStore;
