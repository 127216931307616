import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {formatDate} from '@yourcoach/shared/utils/datetime';

import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {t} from '@src/i18n';
import type {Meal} from '@src/models/tasks';
import {WaterGlassVolume} from '@src/models/tasks';

import styles from '../styles.module.css';

const MEAL_POPUP_I18N_SCOPE = 'MealPopup';

interface Props {
  isOpen: boolean;
  meal: Meal;
  allowChangeDate?: boolean;
  handleClose: () => void;
  onAttachButtonPress?: (meal: Meal) => void;
}

const ShowMealPopup: FC<Props> = ({
  isOpen,
  handleClose,
  meal,
  allowChangeDate = false,
  onAttachButtonPress,
}) => {
  if (!Array.isArray(meal.nutrients)) {
    meal.nutrients = [];
  }

  const handleOnAttachButtonPress = () => {
    if (onAttachButtonPress) {
      onAttachButtonPress(meal);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`ShowMealPopup ${styles.ShowMealPopup}`}>
          <ModalAnimateWin
            showModal={isOpen}
            closeModalHandler={handleClose}
            className="greyHeaderContainer littleContainer w610 List"
            isBody
            classNameBody="whiteBody"
            header={t([MEAL_POPUP_I18N_SCOPE, 'attach_button'])}
            classNameHeader="greyHeader w610"
            classNameCloseBut="greyHeaderBut"
          >
            <div>
              <div>
                {meal.query ? (
                  <div className={styles.mealDescriptionRow}>
                    <div className={styles.mealDescriptionLabel}>
                      {`${t([MEAL_POPUP_I18N_SCOPE, 'meal_label'])}: `}
                    </div>
                    <div className={styles.mealDescription}>{meal.query}</div>
                  </div>
                ) : null}
                {(meal.ingredients || []).length ? (
                  <div className={styles.mealDescriptionRow}>
                    <div className={styles.mealDescriptionLabel}>
                      {`${t([MEAL_POPUP_I18N_SCOPE, 'ingredients_label'])}: `}
                    </div>
                    <div className={styles.mealDescription}>
                      {meal.ingredients
                        .map(ingredient => {
                          const {name, qty, unit} = ingredient;

                          return `${name} - ${qty} ${unit}`;
                        })
                        .join(', ')}
                    </div>
                  </div>
                ) : null}
                {meal.nutrients.map((nutrient, i, itself) => {
                  const {name, qty, unit} = nutrient;

                  const i18nName = t(['shared', 'nutrient', name]);
                  const i18nUnit = t(['shared', 'nutrient_unit', unit]);

                  let qtyString = `${qty || '0.0'} ${
                    i18nUnit.includes('NT') ? unit || '' : i18nUnit
                  }`;

                  if (name === 'wate') {
                    qtyString = [
                      `${qty} ${t([
                        'shared',
                        'nutrient_unit',
                        WaterGlassVolume.OZ.unit,
                      ])}`,
                      ' / ',
                      `${(
                        (qty / WaterGlassVolume.OZ.amount) *
                        WaterGlassVolume.L.amount
                      ).toFixed(1)} ${t([
                        'shared',
                        'nutrient_unit',
                        WaterGlassVolume.L.unit,
                      ])}`,
                    ].join('');
                  }

                  return (
                    <React.Fragment key={`${name}-${qty}-${unit}`}>
                      <div className={styles.mealRow}>
                        <div className={styles.mealTimeText}>
                          {i18nName.includes('NT') ? name : i18nName}
                        </div>
                        <div className={styles.mealQty}>{qtyString}</div>
                      </div>
                      {i !== itself.length - 1 ? <div /> : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div>
              <div className={styles.mealTimeRowContainer}>
                <div className={styles.mealTimeRow}>
                  <div className={styles.mealTimeRowButton}>
                    <div className={styles.mealText}>
                      {allowChangeDate
                        ? t([MEAL_POPUP_I18N_SCOPE, 'set_time_label'])
                        : t([MEAL_POPUP_I18N_SCOPE, 'time_label'])}
                    </div>
                    <div className={styles.mealText}>
                      {formatDate(meal.date, {
                        useUTC: !allowChangeDate,
                        customFormat: 'LT',
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {onAttachButtonPress ? (
                <CustomButton
                  type="button"
                  classButton={`blueButt ${styles.addBut}`}
                  onClick={handleOnAttachButtonPress}
                >
                  <span>{t([MEAL_POPUP_I18N_SCOPE, 'attach_button'])}</span>
                </CustomButton>
              ) : null}
            </div>
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(ShowMealPopup);
