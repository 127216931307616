import type {FC} from 'react';
import React, {memo, useContext, useState} from 'react';

import Breadcrumbs from '@src/components/Breadcrumbs/Breadcrumbs';
import AddItemButton from '@src/pages/NewLayout/components/AddItemButton';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';

import AddFileButt from './AddFileButt/AddFileButt';
import AddFolderButt from './AddFolderButt/AddFolderButt';
import AddLinkButt from './AddLinkButt/AddLinkButt';
import styles from './styles.module.scss';

interface Props {}

const HeaderLibrary: FC<Props> = () => {
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const folderId = libraryStory!.folderID;

  const [displayButtons, setDisplayButtons] = useState<boolean>(false);

  return (
    <div className={`HeaderLibrary ${styles.HeaderLibrary}`}>
      {folderId && (
        <Breadcrumbs
          noFirst
          className={styles.breadcrumbs}
          classNameLink={styles.link}
          classNameSeparator={styles.separator}
          classNameTales={styles.tales}
        />
      )}
      <div />
      <div
        className={styles.buttonsContainer}
        onMouseEnter={() => setDisplayButtons(true)}
        onMouseLeave={() => setDisplayButtons(false)}
      >
        <AddItemButton buttonLabel={'Add'} />
        {displayButtons && (
          <div className={styles.buttons}>
            <AddFileButt />
            <div className={styles.buttonDivider} />
            {folderId ? '' : <AddFolderButt />}
            <div className={styles.buttonDivider} />
            <AddLinkButt />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(HeaderLibrary);
