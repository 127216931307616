import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';
import {
  animation,
  contextMenu,
  Item,
  Menu,
  Separator,
  Submenu,
} from 'react-contexify';
import InView from 'react-intersection-observer';
import {Observer} from 'mobx-react';

import {action, observable, reaction, runInAction} from 'mobx';

import {
  labelChatsAbuseAlertTitle,
  labelChatsCopyPostText,
  labelChatsDeletePost,
  labelChatsEditPost,
  labelChatsPinPost,
  labelChatsReportAbusePost,
  labelChatsUnpinPost,
} from '@src/common/i18n/i18nChannel';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ScrollToElement from '@src/components/ScrollToElement/ScrollToElement';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';
import type {PostT} from '../PostsListItem/PostsListItem';
import PostsListItem from '../PostsListItem/PostsListItem';

import styles from './../../styles.module.scss';

interface ILocalStore {
  selectPost: PostT | null;
  setSelectPost(selectPost: PostT): void;
}

interface Props {}

const ChatMain: FC<Props> = () => {
  const {
    stores: {postStore, currentUserStore},
  } = useContext(AppContext);
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);

  const menuId = 'menuIdPostsListItem';
  const localStore: ILocalStore = useRef(
    observable(
      {
        selectPost: null,
        setSelectPost(selectPost: PostT) {
          this.selectPost = selectPost;
        },
      },
      {
        selectPost: observable,
        setSelectPost: action,
      },
    ),
  ).current;

  useEffect(() => {
    let pinnedPostTimerId = 0;

    const disposeScrollToMaxPost = reaction(
      () => mainChatsLocalStore?.sections.length,
      (length, prevLength) => {
        if (!prevLength) {
          // It's a fu..ng miracle!!!
          // This reaction is for scroll to last post on initial load
          mainChatsLocalStore?.setSeeCurrentPostId(
            mainChatsLocalStore.MaxPostId,
          );
        }
      },
    );

    pinnedPostTimerId = +setTimeout(() => {
      if (mainChatsLocalStore?.selectedPinnedPostId) {
        mainChatsLocalStore?.setSeeCurrentPostId(
          mainChatsLocalStore?.selectedPinnedPostId,
        );
        mainChatsLocalStore.setSelectedPinnedPostId(null);
      } else {
        mainChatsLocalStore?.setSeeCurrentPostId('no');
      }
    }, 200);

    return () => {
      disposeScrollToMaxPost();
      clearTimeout(pinnedPostTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const handleGetContextMenu = (selectPost: PostT) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      localStore.setSelectPost(selectPost);

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          selectPost,
        },
      });
    };
  };

  const MyMenu = () => (
    <Observer>
      {() => (
        <div onClick={handleClickMenu}>
          <Menu
            id={menuId}
            animation={animation.fade}
            className="contextMenu"
            style={{zIndex: 1000}}
          >
            <Submenu label={labelChatsReportAbusePost()}>
              {[
                'hate_speech',
                'violence',
                'spam',
                'terrorism',
                'other_reason',
              ].map(key => (
                <Item
                  className="contextMenuItem"
                  key={key}
                  onClick={onClickAbusePost(key)}
                >
                  {t(['shared', 'post', 'complaint', 'reason', key])}
                </Item>
              ))}
            </Submenu>
            <Separator />
            <Item onClick={_onPinPostBtnPress} className="contextMenuItem">
              {localStore.selectPost && localStore.selectPost!.is_pinned
                ? labelChatsUnpinPost(
                    mainChatsLocalStore!.isIndividual ? 'individual' : 'group',
                  )
                : labelChatsPinPost(
                    mainChatsLocalStore!.isIndividual ? 'individual' : 'group',
                  )}
            </Item>
            {localStore.selectPost?.body ? (
              <Item
                onClick={_onCopyPostTextBtnPress}
                className="contextMenuItem"
              >
                {labelChatsCopyPostText()}
              </Item>
            ) : null}
            {localStore.selectPost?.user_id === currentUserStore.user?._id ? (
              <Item onClick={_onEditPostBtnPress} className="contextMenuItem">
                {labelChatsEditPost()}
              </Item>
            ) : null}
            {localStore.selectPost?.user_id === currentUserStore.user?._id ? (
              <Item onClick={_onDeletePostBtnPress} className="contextMenuItem">
                {labelChatsDeletePost()}
              </Item>
            ) : null}
          </Menu>
        </div>
      )}
    </Observer>
  );

  const onClickAbusePost = (key: string) => {
    return args => {
      const post = args.props.selectPost as PostT;

      contextMenu.hideAll();

      getCustomConfirmAlert({
        title: `${labelChatsAbuseAlertTitle()}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => mainChatsLocalStore!._complainPost(post, key),
            type: 'confirm',
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    };
  };

  const _onPinPostBtnPress = async args => {
    const post = args.props.selectPost as PostT;

    const postIndex = mainChatsLocalStore!.posts.findIndex(
      item => item._id === post._id,
    );

    try {
      runInAction(() => {
        mainChatsLocalStore!.posts[postIndex] = {
          ...mainChatsLocalStore!.posts[postIndex],
          inProgress: true,
        };
      });
      await postStore.pin(post);
      await postStore.localUpdate(post, {
        is_pinned: !post.is_pinned,
      });
      runInAction(() => {
        mainChatsLocalStore!.posts[postIndex] = {
          ...mainChatsLocalStore!.posts[postIndex],
          inProgress: false,
        };
      });

      if (!post.is_pinned) {
        mainChatsLocalStore!.pinnedPostsStore.addItem(post);
      } else {
        mainChatsLocalStore!.pinnedPostsStore.removeItem(post);
      }

      contextMenu.hideAll();
    } catch (error) {
      runInAction(() => {
        mainChatsLocalStore!.posts[postIndex] = {
          ...mainChatsLocalStore!.posts[postIndex],
          inProgress: false,
          error,
        };
      });
    }
  };

  const _onCopyPostTextBtnPress = args => {
    const post = args.props.selectPost as PostT;

    let text = post.body;

    if (window.getSelection()?.toString()) {
      text = window.getSelection()?.toString()!;
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        contextMenu.hideAll();
      })
      .catch(() => {
        contextMenu.hideAll();
      });
  };

  const _onEditPostBtnPress = args => {
    const post = args.props.selectPost as PostT;

    mainChatsLocalStore!.setIsInsertText(true);
    mainChatsLocalStore!._onEditPostBtnPress(post);
    contextMenu.hideAll();
  };

  const _onDeletePostBtnPress = async args => {
    const post = args.props.selectPost as PostT;

    contextMenu.hideAll();

    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            postStore.delete(post, {
              channel_id: post.channel_id,
            });
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleOnChangeInView = async (inView: boolean) => {
    if (inView) {
      const lastPostId = mainChatsLocalStore!.MinPostId;

      await mainChatsLocalStore?._onEndReached();

      mainChatsLocalStore?.setSeeCurrentPostId(lastPostId);
    }
  };

  const onFirstPostVisibilityChange = useCallback(
    (inView: boolean) => {
      if (
        mainChatsLocalStore?.isLoaded &&
        !inView &&
        mainChatsLocalStore?.isFirstPostVisible
      ) {
        mainChatsLocalStore?.setSeeCurrentPostId(
          mainChatsLocalStore!.MaxPostId,
        );
      }

      mainChatsLocalStore?.setIsFirstPostVisible(inView);
    },
    [mainChatsLocalStore],
  );

  return (
    <Observer>
      {() => (
        <div className={`ChatMain ${styles.ChatMain}`}>
          <div className={styles.ChatMainOverFlowContainer}>
            <div className={styles.ChatPlacePostsAtTheBottom}>
              {/* @ts-ignore */}
              <InView as="div" onChange={handleOnChangeInView}>
                <div />
              </InView>
              {!mainChatsLocalStore?.userIsFrozen ? (
                <>
                  {mainChatsLocalStore?.sectionReverse.map(section => {
                    const allCoachesInChat = section.data
                      .filter(
                        post =>
                          post?.user?.roles?.includes('coach') && post.user_id,
                      )
                      .map(post => post.user_id);

                    return (
                      <div
                        key={section.date}
                        className={styles.ChatMainDaySectionContainer}
                      >
                        <div className={styles.ChatMainDaySection}>
                          <div className={styles.ChatMainDayLine} />
                          <div className={styles.ChatMainDayTitle}>
                            {section.title}
                          </div>
                          <div className={styles.ChatMainDayLine} />
                        </div>
                        <div className={styles.ChatMainPostsContainer}>
                          {section.data.map((post, i) => {
                            let isAdminPost = false;

                            const isLast = section.data.length - 1 === i;

                            if (mainChatsLocalStore!.hasCourse) {
                              isAdminPost =
                                /* !mainChatsLocalStore.isOneToOne && */
                                mainChatsLocalStore.channel!.resource!.coach_ids.includes(
                                  post.user_id,
                                );
                            }

                            return (
                              <div
                                key={post._id}
                                className={
                                  mainChatsLocalStore?.seeCurrentPostId ===
                                  post._id
                                    ? `maxPost_${post._id}`
                                    : ''
                                }
                              >
                                <PostsListItem
                                  post={post}
                                  isLast={isLast}
                                  groupWithNext={post.userGroup}
                                  showUserAvatar={
                                    !mainChatsLocalStore.isOneToOne &&
                                    post.firstItemInUserGroup
                                  }
                                  showUserName={
                                    !mainChatsLocalStore.isOneToOne &&
                                    post.lastItemInUserGroup
                                  }
                                  showUserGroups={
                                    !mainChatsLocalStore.isOneToOne
                                  }
                                  allCoachesInChat={allCoachesInChat}
                                  isAdminPost={isAdminPost}
                                  hasCourse={mainChatsLocalStore.hasCourse}
                                  onClickContextMenu={handleGetContextMenu}
                                />
                                {mainChatsLocalStore?.seeCurrentPostId ===
                                post._id ? (
                                  <ScrollToElement height={10} />
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              <MyMenu />
            </div>
          </div>
          <InView as="div" onChange={onFirstPostVisibilityChange}>
            <div />
          </InView>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatMain);
