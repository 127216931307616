import React, {memo} from 'react';

import WavesSVG from '../../images/waves.svg';

import styles from './styles.module.css';

const ProgramsListItem = ({title, description, color, ...props}) => {
  return (
    <div
      className={styles.programsListItem}
      style={{background: color}}
      {...props}
    >
      <WavesSVG />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default memo(ProgramsListItem);
