import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import type {Expand} from '@yourcoach/shared/api';
import {apiRequest, expandObj, isEntityId} from '@yourcoach/shared/api';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';

import PracticeContext from './context/PracticeContext';
import HeaderPractice from './HeaderPractice/HeaderPractice';
import Programs from './Programs/Programs';

interface Props {}

export const fetchData = async (userId: string) => {
  const isUserId = isEntityId(userId, 'user');
  const params = isUserId
    ? {
        _id: userId,
      }
    : {
        slug: userId,
      };

  const expand: Expand = {
    user: ['avatar_id', 'coach_logo_id'],
  };

  try {
    const result = await apiRequest({
      method: 'public.profile.read',
      params: {
        ...params,
        expand,
      },
      options: {
        withAuth: false,
      },
    });

    return expandObj<User, UserExpanded>(result.user, {
      expand,
      expanded: result._expanded,
    });
  } catch (error) {
    throw error;
  }
};

const PracticePublic: FC<Props> = () => {
  const practiceContext = useContext(PracticeContext);
  const {slug}: {slug: string} = useParams();

  useEffect(() => {
    let isActive = true;

    const fetch = async () => {
      try {
        const user = await fetchData(slug);

        if (isActive) {
          practiceContext?.setUserId(user._id);
          practiceContext?.setAvatar(user.avatar || null);
          practiceContext?.setCoachLogo(user.coach_logo || null);
          practiceContext?.setCoachTitle(user.coach_title || '');
          practiceContext?.setCoachDescription(user.coach_description || '');
          practiceContext?.setName(user.name);
          practiceContext?.setClientsHelpedId(user.counters.followers.joined);
        }
      } catch (error) {
        // TODO: log error
      }
    };

    fetch();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PracticePublic">
      <HeaderPractice />
      <Programs />
    </div>
  );
};

export default memo(PracticePublic);
