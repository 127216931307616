import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';

import {autorun} from 'mobx';

import AppContext from '@src/context/App';

import {QuestionnaireToolbox} from '../Questionnaire';

interface Props {}

const MyToolbox: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [isUserCoach, setIsUserCoach] = useState(false);

  useEffect(() => {
    const dispose = autorun(() => {
      setIsUserCoach(!!currentUserStore.user?.roles?.includes('coach'));
    });

    return dispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="MyToolbox">
      {isUserCoach ? <QuestionnaireToolbox /> : null}
    </div>
  );
};

export default memo(MyToolbox);
