import type {FC} from 'react';
import React, {memo} from 'react';

import ChatsState from './context/ChatsState';
import styles from './styles/ChatsPage.module.css';
import ChatsWidget from './Chats';

interface Props {}

const ChatsPage: FC<Props> = () => {
  return (
    <div className={'ChatsPage' + ' ' + styles.ChatsPage}>
      <ChatsState>
        <ChatsWidget />
      </ChatsState>
    </div>
  );
};

export default memo(ChatsPage);
