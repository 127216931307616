import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import UserFlowContext from './UserFlowContext';
import useUserFlowLocalStore from './useUserFlowLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const UserFlowState: FC<Props> = ({children}) => {
  const localStore = useUserFlowLocalStore();

  return (
    <UserFlowContext.Provider value={localStore}>
      {children}
    </UserFlowContext.Provider>
  );
};

export default memo(UserFlowState);
