import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';

import {programIsGroup} from '@yourcoach/shared/api/program';
import UserIcon from '@yourcoach/shared/assets/icons/user.svg';
import GroupIcon from '@yourcoach/shared/assets/icons/user-group.svg';

import {t} from '@src/i18n';

import type {ProgramT} from '.';
import styles from './CoursesListItem.module.css';
import {I18N_SCOPE} from '.';

interface Props {
  program: ProgramT;
  onClick?: (program: ProgramT) => void;
}

const CreateCourseRow: FC<Props> = ({program, onClick}) => {
  const isGroup = useMemo(() => programIsGroup(program), [program]);

  const onClickCb = useCallback(() => {
    onClick && onClick(program);
  }, [onClick, program]);

  return (
    <div className={styles.Component} onClick={onClickCb}>
      <div className={styles.row}>
        {isGroup ? (
          <>
            <GroupIcon className={styles.icon} />
            <span className={styles.ellipsis}>
              {t([I18N_SCOPE, 'add_to_course_label.group'])}
            </span>
          </>
        ) : (
          <>
            <UserIcon className={styles.icon} />
            <span className={styles.ellipsis}>
              {t([I18N_SCOPE, 'add_to_course_label.individual'])}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(CreateCourseRow);
