import type {FC} from 'react';
import React, {memo} from 'react';

import ThreeColumns from '@src/layouts/ThreeColumns/ThreeColumns';
import SeeProgramState from '@src/modules/SeeProgramPublic/context/SeeProgramState';
import SeeProgramPublic from '@src/modules/SeeProgramPublic/SeeProgramPublic';

interface Props {}

const ProgramPublicPage: FC<Props> = () => {
  return (
    <div className="ProgramPublicPage">
      <ThreeColumns thirdColClassName="greyColumn">
        <SeeProgramState>
          <SeeProgramPublic />
        </SeeProgramState>
      </ThreeColumns>
    </div>
  );
};

export default memo(ProgramPublicPage);
