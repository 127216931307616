import type {FC} from 'react';
import React from 'react';

import IconGoalsSVG from '@yourcoach/shared/assets/icons/cup.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconGoals: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'icon',
  viewBox = '2 2 36 36',
}) => {
  return (
    <div className={`fill ${className}`}>
      <IconGoalsSVG
        fill="#819AA9"
        height={height}
        width={width}
        viewBox={viewBox}
      />
    </div>
  );
};

export default IconGoals;
