import type {FC} from 'react';
import React, {memo} from 'react';

import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import type {ITypeInput} from './typesInput';
import {MultipleChoiceType, SingleChoiceType, TextType} from './typesInput';

interface Props extends ITypeInput {}

const FactoryInput: FC<Props> = ({
  question,
  saveInput,
  delInput,
  updateTypeOpen,
  sectionId,
  indexQ,
  numQuestion,
  setOldQuestion,
}) => {
  switch (question.type) {
    case ALIAS_TYPES.text:
      return (
        <TextType
          question={question}
          numQuestion={numQuestion}
          saveInput={saveInput}
          delInput={delInput}
          indexQ={indexQ}
          setOldQuestion={setOldQuestion}
          sectionId={sectionId}
        />
      );
    case ALIAS_TYPES.singleChoice:
      return (
        <SingleChoiceType
          question={question}
          numQuestion={numQuestion}
          saveInput={saveInput}
          indexQ={indexQ}
          delInput={delInput}
          updateTypeOpen={updateTypeOpen}
          setOldQuestion={setOldQuestion}
          sectionId={sectionId}
        />
      );
    case ALIAS_TYPES.singleChoiceOpen:
      return (
        <SingleChoiceType
          question={question}
          numQuestion={numQuestion}
          saveInput={saveInput}
          indexQ={indexQ}
          delInput={delInput}
          updateTypeOpen={updateTypeOpen}
          setOldQuestion={setOldQuestion}
          sectionId={sectionId}
          isOpen
        />
      );
    case ALIAS_TYPES.multipleChoice:
      return (
        <MultipleChoiceType
          question={question}
          numQuestion={numQuestion}
          saveInput={saveInput}
          indexQ={indexQ}
          delInput={delInput}
          updateTypeOpen={updateTypeOpen}
          setOldQuestion={setOldQuestion}
          sectionId={sectionId}
        />
      );
    case ALIAS_TYPES.multipleChoiceOpen:
      return (
        <MultipleChoiceType
          question={question}
          numQuestion={numQuestion}
          saveInput={saveInput}
          indexQ={indexQ}
          delInput={delInput}
          updateTypeOpen={updateTypeOpen}
          setOldQuestion={setOldQuestion}
          sectionId={sectionId}
          isOpen
        />
      );
  }

  return (
    <TextType
      question={question}
      saveInput={saveInput}
      delInput={delInput}
      numQuestion={0}
    />
  );
};

export default memo(FactoryInput);
