import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as PrivateDataRequestProps} from './PrivateDataRequest';
import PrivateDataRequest, {I18N_SCOPE} from './PrivateDataRequest';

type Props = Partial<ModalProps> & PrivateDataRequestProps;

const PrivateDataRequestModal: React.FC<Props> = ({
  privateDataRequest,
  onSet,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])} withStickyFooter>
    <PrivateDataRequest privateDataRequest={privateDataRequest} onSet={onSet} />
    {children}
  </Modal>
);

export default React.memo(PrivateDataRequestModal);
