import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, computed, observable, reaction, runInAction, toJS} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api';
import {courseIsEnded} from '@yourcoach/shared/api/course';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {Payment} from '@yourcoach/shared/api/payment';
import type {Card} from '@yourcoach/shared/api/payment/card';
import FolderIcon from '@yourcoach/shared/assets/icons/primary/Folder.svg';
import CalendarIcon from '@yourcoach/shared/assets/icons/secondary/Calendar.svg';
import ChatIcon from '@yourcoach/shared/assets/icons/secondary/Chat.svg';
// import {PATH_TYPE} from '@src/routes/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelAreYouSure,
  labelChangePaymentMethodButton,
  labelChargeError,
  labelDeleteButton,
  labelFrozenMsg,
  labelStartButton,
  labelStopButton,
} from '@src/common/i18n/i18nCourse';
import {labelInviteTitle} from '@src/common/i18n/i18nInvites';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import AppContext from '@src/context/App';
import {expand as courseExpand} from '@src/models/courses';
import Invites from '@src/modules/Invites/Invites';
import SeeProgramContext from '@src/modules/SeeProgram/context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '@src/modules/SeeProgram/context/useSeeProgramLocalStore';

import {PathBuilderService} from '../../../../../v2/services/PathBuilderService';
import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';

import styles from './../../styles.module.scss';

interface ILocalStore {
  course: CourseT | null;
  showModalGetInvite: boolean;
  userIsCourseCoach: boolean;
  shouldShowStartButton: boolean;
  shouldShowStopButton: boolean;
  shouldShowDeleteButton: boolean;
  courseWasStarted: boolean;
  userIsMainCoach: boolean;
  userMembership:
    | Pick<
        Membership,
        | '_id'
        | 'program_id'
        | 'edition_id'
        | 'course_id'
        | 'card_id'
        | 'status'
        | 'frozen_by'
      >
    | undefined;
  userPaymentMethod: Card | null;
  setCourse(courseVal: CourseT | null): void;
  setShowModalGetInvite(showModalGetInvite: boolean): void;
}

interface Props {
  course: CourseT | null;
  fetchCourse: () => void;
  hideChannelButton?: boolean;
}

const ButtonsHeader: FC<Props> = ({
  course,
  fetchCourse,
  hideChannelButton = false,
}) => {
  const {
    stores: {
      currentUserStore,
      membershipStore,
      courseStore,
      cardStore,
      paymentStore,
    },
  } = useContext(AppContext);
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);

  const redirect = useAppRedirect('/');
  const history = useHistory();
  const user = currentUserStore.user;
  const cards = cardStore.cards;
  const localStore: ILocalStore = useRef(
    observable(
      {
        course: null,
        setCourse(courseVal: CourseT | null) {
          this.course = courseVal;
        },
        showModalGetInvite: false,
        setShowModalGetInvite(showModalGetInvite: boolean) {
          this.showModalGetInvite = showModalGetInvite;
        },
        get userIsCourseCoach() {
          return !!(
            this.course &&
            user &&
            this.course.coach_ids.includes(user._id)
          );
        },
        get userIsMainCoach() {
          return !!(this.course && user && this.course.user_id === user._id);
        },
        get courseWasStarted() {
          return (
            this.course &&
            (this.course.status === 'ongoing' ||
              this.course.status === 'archived')
          );
        },
        get userMembership() {
          return (
            this.course && membershipStore.membershipLookup.get(this.course._id)
          );
        },
        get shouldShowStartButton() {
          return (
            this.userIsCourseCoach &&
            this.course!.counters.memberships.accepted > 0 &&
            this.course!.status === 'planned'
          );
        },
        get shouldShowStopButton() {
          return (
            this.userIsCourseCoach &&
            this.course!.status === 'ongoing' &&
            courseIsEnded(this.course!)
          );
        },
        get shouldShowDeleteButton() {
          if (seeProgramLocalStore?.isCoach) {
            return !Object.values(this.course!.counters.memberships).some(
              (count: number) => +count > 0,
            );
          }

          return false;
        },
        get userPaymentMethod() {
          if (
            this.userMembership &&
            this.userMembership.card_id &&
            cards[this.userMembership.card_id]
          ) {
            return cards[this.userMembership.card_id];
          }

          return null;
        },
      },
      {
        course: observable,
        showModalGetInvite: observable,
        setCourse: action,
        setShowModalGetInvite: action,
        userIsCourseCoach: computed,
        shouldShowStartButton: computed,
        shouldShowStopButton: computed,
        courseWasStarted: computed,
        shouldShowDeleteButton: computed,
        userIsMainCoach: computed,
        userMembership: computed,
        userPaymentMethod: computed,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setCourse(course);

    const disposerCourseStoreUpdating = reaction(
      () => courseStore.updating,
      updating => {
        if (
          localStore.course &&
          updating.success &&
          updating.entity &&
          updating.entity._id === localStore.course._id
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore.course![key] = updating.entity![key];
          });

          //_fetchCourse();
        }
      },
    );

    return () => {
      disposerCourseStoreUpdating();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const _onDefaultChannelButtonPress = () => {
    history.push({
      pathname: '/chats',
      search: `?cid=${localStore.course!.channel_ids[0]!}`,
    });
  };

  const _onCourseCalendarButtonPress = () => {
    history.push({
      pathname: '/to-dos',
      search: localStore.course?._id,
      state: {
        course: toJS(localStore.course),
        forCoach: localStore.userIsCourseCoach,
      },
    });
  };
  const _onCourseLibraryButtonPress = () => {
    history.push({
      pathname: '/chats',
      search: `?cid=${localStore.course!.channel_ids[0]!}`,
      state: {tab: 'library'},
    });
  };

  const _onStartCourseButtonPress = async () => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const startedCourse = await courseStore.start(
                localStore.course!,
                {
                  expand: courseExpand,
                },
              );

              courseStore.localUpdate(localStore.course!, startedCourse);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onStopCourseButtonPress = async () => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const archivedCourse = await courseStore.stop(localStore.course!);

              courseStore.localUpdate(localStore.course!, archivedCourse);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onDeleteCourseButtonPress = async () => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await courseStore.delete(localStore.course!);

              const program = seeProgramLocalStore!.program!;
              // const practiceName = user?.slug ? user.slug : user?._id;
              // const programName = program.slug ? program.slug : program._id;
              // const retPath = `/${PATH_TYPE.coaches}/${practiceName}/programs/${programName}`;

              const practiceSlug = user?.slug;
              const userId = user?._id;
              const programSlug = program.slug;
              const programId = program._id;

              const retPath = PathBuilderService.toProgram(
                {slug: practiceSlug, id: userId as string},
                {slug: programSlug, id: programId as string},
              ).pathname;

              redirect(retPath);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onChangePaymentMethodButtonPress = () => {};

  const handleCloseModalGetInvite = () => {
    localStore.setShowModalGetInvite(false);
  };

  const onSendInvite = () => {
    fetchCourse();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChoose = async (paymentMethod: Card) => {
    if (
      !localStore.userPaymentMethod ||
      localStore.userPaymentMethod._id !== paymentMethod._id
    ) {
      try {
        const paymentsResult = await apiRequest({
          method: 'client.payments.list',
          params: {
            cursor: [1, 0, null],
            query: [['course_id', '==', localStore.course!._id]],
          },
        });

        if (!paymentsResult._items.length) {
          //loading complete

          return;
        }

        await paymentStore.updateBatch(
          paymentsResult._items.map((item: Payment) => ({
            entity: item,
            params: {
              _id: item._id,
              card_id: paymentMethod._id,
            },
          })),
        );

        const batchResult = await paymentStore.executeBatch(
          paymentsResult._items.filter((payment: Payment) =>
            (['failed', 'requires_action'] as Payment['status'][]).includes(
              payment.status,
            ),
          ),
        );

        const toConfirmItems = batchResult.filter(
          ({payment, clientSecret}) =>
            payment.status === 'requires_action' && clientSecret,
        );

        const toConfirmItemsLength = toConfirmItems.length;

        for (let i: number = 0; i < toConfirmItemsLength; i += 1) {
          const toConfirmItem = toConfirmItems[i];

          if (!toConfirmItem.payment.card_id) {
            logger.error({
              name: 'Payment method is not found!',
              message: JSON.stringify(toConfirmItem.payment, null, 2),
            });

            throw new Error('Payment method is not found!');
          }

          const card = cardStore.cards[toConfirmItem.payment.card_id];

          const stripeConfirmIntentResponse = await fetch(
            `https://api.stripe.com/v1/payment_intents/${toConfirmItem.payment.payment_id}/confirm`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${process.env.STRIPE_KEY}`,
              },
              method: 'POST',
              body: [
                `payment_method=${card.pm_id}`,
                `client_secret=${toConfirmItem.clientSecret}`,
                `return_url=${window.location.href}`,
              ].join('&'),
            },
          );

          const stripeConfirmIntentResponseJson =
            await stripeConfirmIntentResponse.json();

          if (stripeConfirmIntentResponseJson.error) {
            logger.error({
              name: 'StripeConfirmSetupIntentError',
              message: JSON.stringify(
                stripeConfirmIntentResponseJson.error,
                null,
                2,
              ),
            });

            throw stripeConfirmIntentResponseJson.error;
          }

          let stripeWindow: Window | null = null;

          if (stripeConfirmIntentResponseJson.status === 'requires_action') {
            stripeWindow = window.open(
              stripeConfirmIntentResponseJson.next_action.redirect_to_url.url,
              '_blank',
            );

            stripeWindow?.focus();

            await new Promise<void>(resolve => {
              const timerId = setInterval(() => {
                try {
                  if (
                    stripeWindow &&
                    stripeWindow.location.href.startsWith(
                      process.env.URL as string,
                    )
                  ) {
                    clearInterval(timerId);
                    stripeWindow?.close();
                    stripeWindow = null;
                    resolve();
                  }
                } catch (error) {
                  // do nothing
                }
              }, 250);
            });
          }

          const result = await paymentStore.execute(toConfirmItem.payment);

          if (['requires_action', 'failed'].includes(result.payment.status)) {
            logger.error({
              name: 'Payment execute failed!',
              message: JSON.stringify(toConfirmItem.payment, null, 2),
            });

            throw new Error(labelChargeError());
          }
        }

        runInAction(() => {
          membershipStore.membershipLookup.set(localStore.course!._id, {
            ...localStore.userMembership!,
            status: 'active',
            frozen_by: null,
          });
        });

        fetchCourse();
      } catch (error) {
        fetchCourse();

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
            },
          ],
        });

        setError(error);
      }
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.course ? (
            <>
              <div className={`ButtonsHeader ${styles.buttonsContainer}`}>
                <div className={styles.topButtonsContainer}>
                  {localStore.userIsMainCoach &&
                  (localStore.shouldShowStartButton ||
                    localStore.shouldShowStopButton ||
                    localStore.shouldShowDeleteButton) ? (
                    <div
                      className={`${styles.button} ${styles.courseActionButtonsContainer}`}
                    >
                      {localStore.shouldShowStartButton ? (
                        <div
                          className={styles.startBut}
                          onClick={_onStartCourseButtonPress}
                        >
                          {labelStartButton(
                            seeProgramLocalStore!.isIndividual
                              ? 'individual'
                              : 'group',
                          )}
                        </div>
                      ) : null}
                      {/* {localStore.shouldShowStopButton ? (
                        <div
                          className={styles.stopBut}
                          onClick={_onStopCourseButtonPress}
                        >
                          {labelStopButton(
                            seeProgramLocalStore!.isIndividual
                              ? 'individual'
                              : 'group',
                          )}
                        </div>
                      ) : null} */}
                      {localStore.shouldShowDeleteButton ? (
                        <div
                          className={styles.deleteBut}
                          onClick={_onDeleteCourseButtonPress}
                        >
                          {labelDeleteButton()}
                        </div>
                      ) : null}

                      {localStore.userIsCourseCoach &&
                      localStore.userMembership &&
                      localStore.userMembership.status === 'frozen' ? (
                        <div className={styles.courseMessageContainer}>
                          <div className={styles.courseMessage}>
                            {labelFrozenMsg(
                              localStore.userMembership.frozen_by!,
                              seeProgramLocalStore!.isIndividual
                                ? 'individual'
                                : 'group',
                            )}
                          </div>
                          {localStore.userMembership.frozen_by ===
                          'failed_payment' ? (
                            <div
                              className={styles.courseMessageActionButton}
                              onClick={_onChangePaymentMethodButtonPress}
                            >
                              {labelChangePaymentMethodButton()}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className={styles.bottomButtonsContainer}>
                  {localStore.course.channel_ids.length &&
                  !hideChannelButton ? (
                    <div
                      onClick={_onDefaultChannelButtonPress}
                      className={`${styles.but} ${styles.channelButton}`}
                    >
                      <ChatIcon className={styles.channelButt} />
                    </div>
                  ) : null}
                  {localStore.courseWasStarted ? (
                    <div
                      onClick={_onCourseCalendarButtonPress}
                      className={`${styles.but} ${styles.calendarButton}`}
                    >
                      <CalendarIcon className={styles.calendarButt} />
                    </div>
                  ) : null}
                  {localStore.courseWasStarted ? (
                    <div
                      onClick={_onCourseLibraryButtonPress}
                      className={`${styles.but} ${styles.libraryButton}`}
                    >
                      <FolderIcon className={styles.libraryButt} />
                    </div>
                  ) : null}
                </div>
              </div>
              <ModalAnimateWin
                showModal={localStore.showModalGetInvite}
                closeModalHandler={handleCloseModalGetInvite}
                className="greyHeaderContainer maxHeight80vh"
                isBody
                classNameBody={`whiteBody noPadding ${styles.inviteContainer}`}
                header={labelInviteTitle()}
                classNameHeader="greyHeader"
                classNameCloseBut="greyHeaderBut"
              >
                <Invites target={localStore.course} onSend={onSendInvite} />
              </ModalAnimateWin>
            </>
          ) : null}
        </>
      )}
    </Observer>
  );
};

export default memo(ButtonsHeader);
