import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import PracticeContext from './PracticeContext';
import usePracticeLocalStore from './usePracticeLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const PracticeState: FC<Props> = ({children}) => {
  const localStore = usePracticeLocalStore(20);

  return (
    // @ts-ignore
    <PracticeContext.Provider value={localStore}>
      {children}
    </PracticeContext.Provider>
  );
};

export default memo(PracticeState);
