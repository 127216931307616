import type {FC} from 'react';
import React, {useCallback, useContext, useState} from 'react';

import type {DateObj} from '@yourcoach/shared/api';
import {dateObjToDateString} from '@yourcoach/shared/api';

import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import EditorStep from './EditorStep';

interface Data {
  year_of_birth: number;
  private_profile: {date_of_birth: DateObj};
}

interface Props {
  value?: Data;
  onChange?: (arg0: Data) => void;
}

const I18N_SCOPE = 'Onboarding.birthday';

const BirthdayEditor: FC<Props> = ({onChange}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const [date, setDate] = useState(
    (currentUserStore.user!.private_profile.date_of_birth &&
      dateObjToDateString(
        currentUserStore.user!.private_profile.date_of_birth,
      )) ||
      '',
  );

  const onInputChange = useCallback(
    event => {
      setDate(event.target.value);

      const newDate = new Date(Date.parse(event.target.value));

      onChange &&
        onChange({
          year_of_birth: newDate.getFullYear(),
          private_profile: {
            ...currentUserStore.user?.private_profile,
            date_of_birth: {
              _type: 'date',
              year: newDate.getFullYear(),
              month: newDate.getMonth() + 1,
              day: newDate.getDate(),
            },
          },
        });
    },
    [currentUserStore.user?.private_profile, onChange],
  );

  return (
    <TextField
      placeholder={t([I18N_SCOPE, 'placeholder'])}
      type="date"
      value={date}
      onChange={onInputChange}
    />
  );
};

const BirthdayStep = props => {
  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={3}
      fieldName="birthday"
      {...props}
    >
      <BirthdayEditor />
    </EditorStep>
  );
};

export default BirthdayStep;
