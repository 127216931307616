import React, {useCallback, useMemo} from 'react';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';

import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import i18n from '@src/i18n';

import styles from './FileCard.module.css';

export interface Props<T> {
  file: T;
  className?: string;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onClick?: (file: T) => void;
  onMoreButtonClick?: (
    file: T,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
}

function FileCard<T extends IFile = IFile>(
  props: Props<T>,
): React.ReactElement {
  const {
    file,
    className,
    isSelectionMode,
    isSelected,
    onClick,
    onMoreButtonClick,
  } = props;

  const onClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onClick && onClick(file);
    },
    [file, onClick],
  );

  const onMoreButtonClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(file, e);
    },
    [file, onMoreButtonClick],
  );

  const url = useMemo(() => getFileSrc(file).url, [file]);

  const preview = useMemo(() => {
    if (file.categories.includes('website')) {
      return getFileSrc(file, 'image').url;
    } else if (file.categories.includes('image')) {
      return url;
    } else {
      return null;
    }
  }, [file, url]);

  // @ts-ignore
  const {size, urlrepr} = getFileSrc(file);
  const fileName = getFileName(file);

  const Icon = getFileIcon(file);

  const isImageFile = !!file.categories.includes('image');

  return (
    <div
      className={classNames(
        styles.container,
        isImageFile && styles.image,
        className,
      )}
      onClick={onClickCb}
    >
      <div className={styles.content}>
        <div className={styles.previewContainer}>
          <Image
            src={preview || ''}
            placeholder={
              <div className={styles.previewPlaceholder}>
                {isImageFile ? <Loader size={75} /> : <Icon />}
              </div>
            }
          />
        </div>
        {isImageFile ? <div className={styles.gradient} /> : null}
        <div className={styles.dataContainer}>
          <p className={styles.name}>{fileName}</p>
          {size && file.type !== 'url' && !isImageFile ? (
            <p className={styles.text}>{i18n.toHumanSize(size)}</p>
          ) : null}
          {urlrepr ? (
            <p className={styles.text}>
              {urlrepr.replace(/^((ht|f)tp(s?):\/\/)/, '')}
            </p>
          ) : null}
        </div>
      </div>
      {isSelectionMode && !onMoreButtonClick ? (
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={isSelected}
            // TODO: Add round checkbox varian
            // variant="round"
          />
        </div>
      ) : null}
      {onMoreButtonClick && !isSelectionMode ? (
        <Button onClick={onMoreButtonClickCb} className={styles.moreButton}>
          <MoreIcon />
        </Button>
      ) : null}
    </div>
  );
}

export default React.memo(FileCard, isEqual) as typeof FileCard;
