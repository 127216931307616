import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import styled from 'styled-components';

import {ThemeContext} from '@yourcoach/shared/styles/ThemeProvider';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Button} from '@yourcoach/shared/uikit/Button';
import {Logo} from '@yourcoach/shared/uikit/Logo';

// TODO: use this from updated shared package
import {getRandomColor} from '../../../widget/ti/getRandomColor';

import styles from './styles.module.css';

const ThemeButton = styled(Button)`
  background-color: ${getColor('global1')};

  &:hover {
    background-color: ${getColor('global1')};
  }
`;

const TopMenu = () => {
  const history = useHistory();

  const {setAccentColor} = useContext(ThemeContext);

  return (
    <header className={styles.header}>
      <div className={styles.your_coach_logo}>
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            history.replace('/');
          }}
        >
          <Logo size={40} variant="pwr" />
        </div>
      </div>
      <ThemeButton
        onClick={() => {
          const color = getRandomColor();

          setAccentColor({
            light: color,
            dark: color,
          });

          window.widget.api.setAccentColor(color);
        }}
      >
        Test theming
      </ThemeButton>
    </header>
  );
};

export default TopMenu;
