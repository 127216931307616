import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable, reaction} from 'mobx';

import type {DateTimeObj} from '@yourcoach/shared/api';
import {apiRequest, datetimeObjToISOString} from '@yourcoach/shared/api';
import type {ChannelT} from '@yourcoach/shared/api/channel';
import {getChannelInfo} from '@yourcoach/shared/api/channel';
import {
  courseIsEnded,
  getCourseDurationString,
} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {isMeCoachForEntity} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import {
  labelChatsAccessProblems,
  labelChatsArchivedCourseCoach,
  labelChatsEndedCourseClient,
  labelChatsResolveAccessProblems,
  labelChatsViewPinnedMessages,
} from '@src/common/i18n/i18nChannel';
import {labelInviteTitle} from '@src/common/i18n/i18nInvites';
import Button from '@src/components/Button';
import {IconNewArrowPrev} from '@src/components/icons';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import {ongoingCoachHistoryRequest} from '@src/modules/Chats/api';
import {
  getActiveClientMembership,
  getArchivedClientMembership,
} from '@src/modules/Chats/ChatMainContent/ChatMainTopMenu/api';
import Invites from '@src/modules/Invites/Invites';

import ChatsContext from '../../context/ChatsContext';
import type {IChatsLocalStore} from '../../context/useChatsLocalStore';
import {idTypeList} from '../../context/useChatsLocalStore';
import useFetchClientMembership from '../../hooks/useFetchClientMembership';
import useFetchOngoingHistoryList from '../../hooks/useFetchOngoingHistoryList';
import MainChatsContext from '../context/MainChatsContext';
import type {
  IMainChatsLocalStore,
  TMainTabSelect,
} from '../context/useMainChatsLocalStore';

import styles from './../../styles.module.scss';
import ChannelPinnedMessage from './ChannelPinnedMessage/ChannelPinnedMessage';

interface ILocalStore {
  avatar: IFile | null;
  setAvatar(avatar: IFile | null): void;
  title: string;
  setTitle(title: string): void;
  description: string;
  setDescription(description: string): void;
  showModalGetInvite: boolean;
  setShowModalGetInvite(showModalGetInvite: boolean): void;
}

interface Props {}

const ChatMainTopMenu: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);

  const {tenantTitle, subtenantTitle, setTenantTitle, setSubtenantTitle} =
    useFetchClientMembership(chatsLocalStore!.selectChannel!);

  const {coachCoverageEndDate, setCoachCoverageEndDate} =
    useFetchOngoingHistoryList(chatsLocalStore!.selectChannel!);

  const [showExtendButton, setShowExtendButton] = useState<boolean>(() => {
    const course = chatsLocalStore!.selectChannel!.resource!;
    const endIn5days = (courseEndDate: DateTimeObj) => {
      return dayjs().diff(dayjs(datetimeObjToISOString(courseEndDate)), 'day');
    };

    const daysRemainingToCourseEnd = endIn5days(course.end_date!);

    return Boolean(
      daysRemainingToCourseEnd >= -5 ||
        course.status === 'stopping' ||
        courseIsEnded(course),
    );
  });

  const [channelResourceId, setChannelResourceId] = useState('');

  const handleClickProlong = async (courseId: string) => {
    try {
      await apiRequest({
        // @ts-ignore will be fixed in 1.5
        method: 'coach.courses.extend',
        params: {
          _id: courseId,
        },
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const localStore: ILocalStore = useRef(
    observable(
      {
        showModalGetInvite: false,
        setShowModalGetInvite(showModalGetInvite: boolean) {
          this.showModalGetInvite = showModalGetInvite;
        },
        avatar: null,
        setAvatar(avatar: IFile | null) {
          this.avatar = avatar;
        },
        title: '',
        setTitle(title: string) {
          this.title = title;
        },
        description: '',
        setDescription(description: string) {
          this.description = description;
        },
      },
      {
        showModalGetInvite: observable,
        setShowModalGetInvite: action,
        avatar: observable,
        setAvatar: action,
        title: observable,
        setTitle: action,
        description: observable,
        setDescription: action,
      },
    ),
  ).current;

  useEffect(() => {
    const setDataChannel = channel => {
      if (
        channel?.status === 'active' &&
        currentUserStore?.user?.roles[0] === 'coach'
      ) {
        setChannelResourceId(channel.resource._id);
        getActiveClientMembership().then(resp => {
          if (resp?._expanded[channel?.resource?.client_id]) {
            const subtenant_id =
              resp?._expanded[channel?.resource?.client_id]?.subtenant_id;
            const tenant_id = resp?._expanded[subtenant_id]?.tenant_id;

            setTenantTitle(resp?._expanded[tenant_id]?.title);
            setSubtenantTitle(resp?._expanded[subtenant_id]?.title);
          }
        });
      } else if (
        channel?.status === 'archived' &&
        currentUserStore?.user?.roles[0] === 'coach'
      ) {
        setChannelResourceId(channel.resource._id);
        getArchivedClientMembership().then(resp => {
          if (resp?._expanded[channel?.resource?.client_id]) {
            const subtenant_id =
              resp?._expanded[channel?.resource?.client_id]?.subtenant_id;
            const tenant_id = resp?._expanded[subtenant_id]?.tenant_id;

            setTenantTitle(resp?._expanded[tenant_id]?.title);
            setSubtenantTitle(resp?._expanded[subtenant_id]?.title);
          }
        });
      }

      const {title, avatar} = getChannelInfo(channel as ChannelT);

      localStore.setAvatar(avatar!);
      localStore.setTitle(title);
      localStore.setDescription(getCourseDurationString(channel.resource));
    };

    setDataChannel(chatsLocalStore!.selectChannel);

    if (chatsLocalStore?.inMainTabSelect) {
      mainChatsLocalStore!.setMainTabSelect(
        chatsLocalStore?.inMainTabSelect as TMainTabSelect,
      );
    }

    const disposeInMainTabSelect = reaction(
      () => chatsLocalStore?.inMainTabSelect,
      inMainTabSelect => {
        mainChatsLocalStore!.setMainTabSelect(
          inMainTabSelect as TMainTabSelect,
        );
      },
    );

    const disposeProgramAvatar = reaction(
      () => chatsLocalStore!.selectChannel,
      selectChannel => {
        if (selectChannel) {
          setDataChannel(selectChannel);
        }
      },
    );

    return () => {
      disposeProgramAvatar();
      disposeInMainTabSelect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickMoreContent = () => {};

  const handleCloseModalGetInvite = () => {
    localStore.setShowModalGetInvite(false);
  };

  const _onChannelPinnedPostsPress = () => {
    mainChatsLocalStore!.setMainTabSelect('pinned');
  };

  const handleOnClickBackInChat = () => {
    mainChatsLocalStore!.setMainTabSelect('main');
  };

  useEffect(() => {
    currentUserStore?.user?.roles[0] === 'coach' &&
      channelResourceId &&
      ongoingCoachHistoryRequest(channelResourceId).then(response => {
        setCoachCoverageEndDate(
          response._items[response._items.length - 1]?.end_date,
        );
      });
  }, [
    channelResourceId,
    currentUserStore?.user?.roles,
    setCoachCoverageEndDate,
  ]);

  return (
    <Observer>
      {() => (
        <div className={`ChatMainTopMenu ${styles.ChatMainTopMenu}`}>
          <div>
            <div className={`greyColumnInLayoutContent ${styles.topContent}`}>
              <div className={styles.dataAndBtnContainer}>
                <div
                  className={styles.imageContainer}
                  onClick={handleOnClickMoreContent}
                >
                  <OtherUserProfileImg
                    avatar={localStore.avatar}
                    title={localStore.title}
                    titleFontSize="14px"
                    isBGGradient
                    getTitleIconIfNoImage={
                      chatsLocalStore?.selectTypeList === idTypeList[0]
                    }
                    isOverlay={!localStore.avatar}
                  />
                </div>
                <div
                  className={styles.textContainer}
                  onClick={handleOnClickMoreContent}
                >
                  <div className={styles.title}>{`${
                    chatsLocalStore?.selectChannel?.resource?.client?.name
                  } ${
                    tenantTitle ? `(${tenantTitle}/${subtenantTitle})` : ''
                  }`}</div>
                  <div
                    className={
                      coachCoverageEndDate?.day
                        ? styles.CoverageDescription
                        : styles.description
                    }
                  >
                    {coachCoverageEndDate?.day
                      ? 'Coverage until ' +
                        dayjs(
                          datetimeObjToISOString(coachCoverageEndDate),
                        ).format('MMM D, YYYY')
                      : localStore.description}
                  </div>
                </div>
                {isMeCoachForEntity(
                  chatsLocalStore!.selectChannel!.resource!,
                ) &&
                  showExtendButton && (
                    <div className={styles.actionButtonsWrapper}>
                      <Button
                        /* @ts-ignore */
                        className={styles.prolongButton}
                        onClick={() => {
                          handleClickProlong(
                            chatsLocalStore!.selectChannel!.resource!._id,
                          );
                          setShowExtendButton(false);
                        }}
                      >
                        Extend
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {mainChatsLocalStore?.mainTabSelect === 'main' ? (
            <div className={styles.buttContentLine}>
              <div
                className={`greyColumnInLayoutContent ${styles.buttContent}`}
              >
                <div className={styles.pinnedContainer}>
                  <div className={styles.pinnedContainerFirstLine} />
                  <div>
                    {mainChatsLocalStore!.channel &&
                    mainChatsLocalStore!.pinnedPostsStore.hasItems ? (
                      <ChannelPinnedMessage
                        text={labelChatsViewPinnedMessages(
                          mainChatsLocalStore!.pinnedPostsStore.items.length,
                        )}
                        singleButtonUI
                        onPress={_onChannelPinnedPostsPress}
                      />
                    ) : null}
                    {mainChatsLocalStore!.hasCourse &&
                    mainChatsLocalStore!.userIsAdmin &&
                    mainChatsLocalStore!.isArchived ? (
                      <ChannelPinnedMessage
                        text={labelChatsArchivedCourseCoach(
                          mainChatsLocalStore!.isIndividual
                            ? 'individual'
                            : 'group',
                        )}
                      />
                    ) : null}
                    {mainChatsLocalStore!.hasCourse &&
                    !mainChatsLocalStore!.userIsAdmin &&
                    mainChatsLocalStore!.isArchived ? (
                      <ChannelPinnedMessage
                        text={labelChatsEndedCourseClient(
                          mainChatsLocalStore!.isIndividual
                            ? 'individual'
                            : 'group',
                        )}
                      />
                    ) : null}
                    {mainChatsLocalStore!.userIsFrozen ? (
                      <ChannelPinnedMessage
                        text={labelChatsAccessProblems(
                          mainChatsLocalStore!.isIndividual
                            ? 'individual'
                            : 'group',
                        )}
                        button={{
                          text: labelChatsResolveAccessProblems(),
                          onPress: () => {
                            mainChatsLocalStore!.setAccessProblemsShouldBeResolved(
                              true,
                            );

                            // TODO: navigate to course
                          },
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {mainChatsLocalStore?.mainTabSelect !== 'main' ? (
            <div className={styles.buttContentLine}>
              <div
                className={`greyColumnInLayoutContent ${styles.buttContent}`}
              >
                <div
                  className={styles.pinnedTopPageTitle}
                  onClick={handleOnClickBackInChat}
                >
                  <div className={styles.backInChat}>
                    <IconNewArrowPrev />
                  </div>
                  <div className={styles.pinnedTitle}>
                    {mainChatsLocalStore?.mainTabSelect === 'pinned'
                      ? labelChatsViewPinnedMessages(
                          mainChatsLocalStore!.pinnedPostsStore.items.length,
                        )
                      : mainChatsLocalStore?.mainTabSelect === 'library'
                      ? 'Library'
                      : mainChatsLocalStore?.mainTabSelect === 'members'
                      ? 'Members'
                      : 'Back'}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <ModalAnimateWin
            showModal={localStore.showModalGetInvite}
            closeModalHandler={handleCloseModalGetInvite}
            className="greyHeaderContainer maxHeight80vh"
            isBody
            classNameBody={`whiteBody noPadding ${styles.inviteContainer}`}
            header={labelInviteTitle()}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <Invites target={chatsLocalStore!.selectChannel!.resource!} />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatMainTopMenu);
