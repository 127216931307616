import type {FC} from 'react';
import React, {memo} from 'react';

import type {ITypeInput} from '..';
import {SingleChoiceType} from '..';

import styles from './../../styles.module.css';

interface Props extends ITypeInput {
  isOpen?: boolean;
}

const MultipleChoiceType: FC<Props> = ({
  isOpen = false,
  question,
  delInput,
  saveInput,
  updateTypeOpen,
  setOldQuestion,
  sectionId,
  indexQ,
  numQuestion,
}) => {
  return (
    <div className={`MultipleChoiceType ${styles.MultipleChoiceType}`}>
      <SingleChoiceType
        isOpen={isOpen}
        question={question}
        delInput={delInput}
        indexQ={indexQ}
        updateTypeOpen={updateTypeOpen}
        saveInput={saveInput}
        setOldQuestion={setOldQuestion}
        sectionId={sectionId}
        numQuestion={numQuestion}
        isRadio={false}
      />
    </div>
  );
};

export default memo(MultipleChoiceType);
