import {datetimeObjToISOString} from '@yourcoach/shared/api/index';
import dayjs from 'dayjs';

const getBadgeColors = (postDate, handleStateChangeCallback) => {
  if (postDate === null || postDate === undefined) {
    return;
  }

  if (dayjs().isBefore(dayjs(datetimeObjToISOString(postDate)).add(6, 'h'))) {
    handleStateChangeCallback('green');
  }

  if (
    dayjs().isBefore(dayjs(datetimeObjToISOString(postDate)).add(10, 'h')) &&
    dayjs().isAfter(dayjs(datetimeObjToISOString(postDate)).add(6, 'h'))
  ) {
    handleStateChangeCallback('yellow');
  }

  if (
    dayjs().isBefore(dayjs(datetimeObjToISOString(postDate)).add(15, 'h')) &&
    dayjs().isAfter(dayjs(datetimeObjToISOString(postDate)).add(10, 'h'))
  ) {
    handleStateChangeCallback('orange');
  }

  if (dayjs().isAfter(dayjs(datetimeObjToISOString(postDate)).add(15, 'h'))) {
    handleStateChangeCallback('red');
  }
};

export {getBadgeColors};
