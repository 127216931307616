import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {Material as IMaterial} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileName, NOT_FOUND_FILE} from '@yourcoach/shared/api/media/file';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {labelCourseMatsMaterialNoResults} from '@src/common/i18n/i18nCourseMaterials';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconNewFolder} from '@src/components/icons';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import {getFilePressHandler} from '@src/models/library';
import type {Expanded as MaterialExpanded} from '@src/models/materials';
import {expand} from '@src/models/materials';
import FileCard from '@src/modules/MyLibrary/FileCard/FileCard';

import styles from './styles.module.css';

export type MaterialT = IMaterial & MaterialExpanded;

interface ILocalStore {
  material: MaterialT | null;
  setMaterial(material: MaterialT | null): void;
  isFetching: boolean;
  setIsFetching(isFetching: boolean): void;
  listData: IFile[][];
}

interface Props {
  materialId?: string;
  title?: string;
  material?: MaterialT;
}

const Material: FC<Props> = ({materialId, material}) => {
  const {
    stores: {materialStore, currentUserStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        material: null,
        setMaterial(_material: MaterialT | null) {
          this.material = _material;
        },
        isFetching: false,
        setIsFetching(isFetching: boolean) {
          this.isFetching = isFetching;
        },
        get listData() {
          if (this.material) {
            const items: IFile[] = (
              this.material.files.filter(Boolean) as IFile[]
            ).sort((a, b) =>
              getFileName(a as IFile)
                .toLowerCase()
                .localeCompare(
                  getFileName(b as IFile).toLowerCase(),
                  undefined,
                  {
                    numeric: true,
                  },
                ),
            );

            const newData: IFile[][] = [];

            let chunk: IFile[];

            while (items.length > 0) {
              chunk = items.splice(0, 2);

              newData.push(chunk);
            }

            return newData;
          }

          return [];
        },
      },
      {
        material: observable,
        setMaterial: action,
        isFetching: observable,
        setIsFetching: action,
        listData: computed,
      },
    ),
  ).current;

  useEffect(() => {
    if (material && !materialId) {
      localStore.setMaterial({
        ...material,
        files:
          material.files ||
          material.file_ids.map(id => ({
            ...NOT_FOUND_FILE,
            _id: id,
          })),
      });
    }

    _fetchMaterial();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchMaterial = async () => {
    if (!materialId) {
      return;
    }

    try {
      localStore.setIsFetching(true);

      const fetchedMaterial = (await (material &&
        currentUserStore.user &&
        material.coach_ids.includes(currentUserStore.user._id)
        ? materialStore.coach.fetch
        : materialStore.client.fetch)({
        _id: materialId,
        expand,
      })) as MaterialT;

      localStore.setMaterial(fetchedMaterial);
      localStore.setIsFetching(false);
    } catch (error) {
      localStore.setIsFetching(false);

      if (error.canceled) {
        return;
      }

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onFilePress = (file: IFile) => {
    return getFilePressHandler(file);
  };

  const RenderStub: FC = () => (
    <Observer>
      {() => (
        <>
          {localStore.material && !localStore.material.files ? (
            <NoResults
              text={labelCourseMatsMaterialNoResults()}
              className={styles.NoResults}
              classNameChildrenContainer={styles.NoResultsMainContainer}
              classNameImgContainer={styles.NoResultsImgContainer}
            >
              <div className={styles.iconNoResultContainer}>
                <IconNewFolder className={styles.iconNoResult} />
              </div>
            </NoResults>
          ) : null}
        </>
      )}
    </Observer>
  );

  const RenderItem: FC<{item: IFile[]}> = ({item}) => (
    <>
      {item.map(file => (
        <div key={file._id} className={styles.fileContainer}>
          <FileCard file={file} onPress={_onFilePress(file)} />
        </div>
      ))}
    </>
  );

  return (
    <Observer>
      {() => (
        <div className={`Material ${styles.Material}`}>
          <div className={styles.filesContainer}>
            {localStore.listData.length > 0 ? (
              localStore.listData.map(sectionFiles => (
                <RenderItem
                  key={sectionFiles.map(x => x._id).join('_')}
                  item={sectionFiles}
                />
              ))
            ) : (
              <RenderStub />
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Material);
