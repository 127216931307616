import React, {useCallback} from 'react';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';

import type {Goal} from '@yourcoach/shared/api/goal';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';
import CupIcon from '@yourcoach/shared/assets/icons/primary/Trophy.svg';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import styles from './GoalsListItem.module.css';

export interface Props<T extends Goal = Goal> {
  goal: T;
  index: number;
  className?: string;
  onClick?: (goal: T) => void;
  onMoreButtonClick?: (
    item: T,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
}

const GoalsListItem: React.FC<Props<Goal>> = ({
  goal,
  index,
  className,
  onClick,
  onMoreButtonClick,
}) => {
  const onClickCb = useCallback(() => {
    onClick && onClick(goal);
  }, [onClick, goal]);

  const onMoreButtonClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(goal, e);
    },
    [goal, onMoreButtonClick],
  );

  return (
    <div
      className={classNames(styles.container, className)}
      onClick={onClickCb}
    >
      <div className={styles.contentContainer}>
        <div className={styles.labelContainer}>
          <CupIcon />
          <p className={styles.label}>
            {t('label.goal_index', {index: index + 1})}
          </p>
        </div>
        <p className={styles.title}>{goal.title}</p>
        {goal.description ? (
          <p className={styles.description}>{goal.description}</p>
        ) : null}
      </div>
      {onMoreButtonClick ? (
        <Button onClick={onMoreButtonClickCb} className={styles.moreButton}>
          <MoreIcon />
        </Button>
      ) : null}
    </div>
  );
};

export default React.memo(GoalsListItem, isEqual) as typeof GoalsListItem;
