import type {FC} from 'react';
import React, {useContext, useEffect, useState} from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {observer} from 'mobx-react-lite';

import {apiRequest} from '@yourcoach/shared/api/index';

import ChatsContext from '../../context/ChatsContext';
import type {
  ChannelT,
  IChatsLocalStore,
} from '../../context/useChatsLocalStore';
import styles from '../../styles/styles.module.css';
import ChatsListItem from '../ChatsListItem/ChatsListItem';

interface Props {}

const ChatsList: FC<Props> = () => {
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);
  const [isWithConcierge, setIsWithConcierge] = useState(false);

  const handleSetArchivedChannelsIsVisible = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    chatsLocalStore!.setArchivedChannelsIsVisible(
      !chatsLocalStore?.archivedChannelsIsVisible,
    );
  };

  useEffect(() => {
    const userProfile = async () => {
      const response = await apiRequest({
        method: 'user.profile.read',
        params: {
          expand: {
            user: ['avatar_id', 'coach_logo_id'],
          },
        },
      });

      setIsWithConcierge(Boolean(response.user.concierge_ids.length));
    };

    userProfile();

    const createChannel = async () => {
      return await apiRequest({
        method: 'client.channels.list',
        params: {
          sort: [['updated', -1]],
          query: [
            ['type', '==', 'concierge'],
            ['status', '!=', 'archived'],
          ],
          expand: {
            channel: ['resource_id', 'user_ids', 'last_post_id'],
            user: ['avatar_id', 'coach_logo_id'],
            program: ['avatar_id', 'background_id', 'coach_ids'],
            edition: ['contract_id'],
            course: ['program_id', 'edition_id', 'client_id'],
            material: ['file_ids'],
            questionnaire: ['avatar_id'],
            post: ['user_id', 'attachment_ids'],
          },
          limit: 301,
        },
      });
    };

    isWithConcierge &&
      createChannel().then(response => {
        chatsLocalStore?.setSelectChannel(response._items[0]);
      });

    !isWithConcierge &&
      chatsLocalStore?.setSelectChannel(chatsLocalStore?.sections[0]?.data[0]);
  }, [chatsLocalStore, isWithConcierge]);

  return (
    <NiceModal.Provider>
      <div className={`ChatsList percent100H ${styles.ChatsList}`}>
        {chatsLocalStore!.sections[0] &&
          chatsLocalStore!.sections[0].data.map((channel: ChannelT) => {
            return (
              <ChatsListItem
                key={channel._id}
                channel={channel}
                showUpdatedDate
                showLastPost
                showCoaches
                badge={channel.updatesCount}
              />
            );
          })}
        {chatsLocalStore!.sections[1] &&
        chatsLocalStore!.sections[1].data.length > 0 ? (
          <>
            {chatsLocalStore?.archivedChannelsIsVisible ? (
              <div className={styles.archiveListContainer}>
                {chatsLocalStore!.sections[1].data.map((channel: ChannelT) => {
                  return (
                    <ChatsListItem
                      key={channel._id}
                      channel={channel}
                      showUpdatedDate
                      showLastPost
                      showCoaches
                      badge={channel.updatesCount}
                    />
                  );
                })}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </NiceModal.Provider>
  );
};

export default observer(ChatsList);
