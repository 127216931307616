import {useCallback, useEffect, useMemo, useState} from 'react';
import {DayPicker} from 'react-day-picker';

import classNames from 'classnames';
import dayjs from 'dayjs';

import {
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Conference} from '@yourcoach/shared/api/conference';
import {conferenceStore} from '@yourcoach/shared/api/conference';
import {logger} from '@yourcoach/shared/utils/logger';

import TimeSquare from '../../../../assets/TimeSquare.svg';
import ModalAnimateWin from '../../../../components/ModalAnimateWin/ModalAnimateWin';
import styles from '../../styles/styles.module.css';

import {
  createConferenceAPI,
  getAvailableDatesAPI,
  getAvailableOldCoachDatesAPI,
  getAvailableSlotsForOneDay,
  getAvailableTemporaryCoachDatesAPI,
  getCoachCoverageItem,
  getConferencesAPI,
  getMembershipAPI,
} from './api';

export const useBookSessions = ({
  onBookSession,
}: {
  onBookSession?: () => void;
} = {}) => {
  const [coach_id, setCoach_Id] = useState<string>('');
  const [start_date_membership, setStartDateMembership] = useState<string>('');
  const [course_id, setCourse_Id] = useState<string>('');

  const [end_date, setEnd_Date] = useState<string>('');
  const [session_duration, setSession_Duration] = useState<number>(0);
  const [conference_list, setConference_List] = useState<Conference[]>([]);

  const [isOpenSchedulingModal, setIsOpenSchedulingModal] =
    useState<boolean>(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedTimeslot, setselectedTimeslot] = useState<Conference>();

  const [available_dates, setAvailable_Dates] = useState<Conference[]>([]);
  const [availableDatesInSelectedDay, setAvailableDatesInSelectedDay] =
    useState<Conference[]>([]);
  const [availableOldCoachPickerDays, setAvailableOldCoachPickerDays] =
    useState<Date[]>([]);
  const [
    availableTemporaryCoachPickerDays,
    setAvailableTemporaryCoachPickerDays,
  ] = useState<Date[]>([]);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false);
  const [coachCoverageItem, setCoachCoverageItem] = useState([]);
  const [availableTemporaryCoachDays, setAvailableTemporaryCoachDays] =
    useState([]);
  const [sessionToRebook, setSessionToRebook] = useState<Conference | null>(
    null,
  );

  useEffect(() => {
    const getMembership = async () => {
      getMembershipAPI().then(response => {
        setCoach_Id(response._items[0]?.coach_ids[0]);
        setCourse_Id(response._items[0]?.course_id);
        setStartDateMembership(response._items[0]?.start_date);
        setEnd_Date(
          response._expanded[response._items[0]?.course_id]?.end_date,
        );
        setSession_Duration(
          response._expanded[response._items[0]?.edition_id]?.client_conferences
            .duration,
        );
        setCourse_Id(response._items[0]?.course_id);
      });
    };

    if (isOpenSchedulingModal) {
      getMembership();
    }
  }, [isOpenSchedulingModal]);

  useEffect(() => {
    const getConferences = async () => {
      getConferencesAPI(start_date_membership, end_date).then(response => {
        setConference_List(response._items);
      });
    };

    end_date && getConferences();
  }, [end_date, start_date_membership]);

  useEffect(() => {
    if (end_date && coach_id && !coachCoverageItem.length) {
      const display90DaysPeriod = dayjs().add(90, 'date');

      getAvailableDatesAPI(coach_id, end_date, display90DaysPeriod).then(
        response => {
          setAvailable_Dates(response._items);
        },
      );
    } else if (
      coachCoverageItem?.length &&
      availableTemporaryCoachDays?.length
    ) {
      const display90DaysPeriod = dayjs().add(90, 'date');

      const start_date = ISOStringToDatetimeObj(
        dayjs(
          datetimeObjToISOString(
            availableTemporaryCoachDays[availableTemporaryCoachDays.length - 1]
              .start_date,
          ),
        )
          .add(15, 'm')
          .toISOString(),
      );

      getAvailableOldCoachDatesAPI(
        coachCoverageItem[0]?.old_coach_id,
        end_date,
        display90DaysPeriod,
        start_date,
      ).then(resp => {
        setAvailable_Dates(resp._items);
      });
    }
  }, [coach_id, end_date, coachCoverageItem, availableTemporaryCoachDays]);

  useEffect(() => {
    if (available_dates.length && !availableTemporaryCoachDays.length) {
      let availableDatesFiltered = available_dates.slice();

      // first we need to filter out dates that are not available for the coach
      // as we have a coach coverage item
      if (coachCoverageItem.length) {
        const ccStartDate = dayjs(
          datetimeObjToISOString(coachCoverageItem[0]?.start_date),
        );
        const ccEndDate = dayjs(
          datetimeObjToISOString(coachCoverageItem[0]?.end_date),
        );

        availableDatesFiltered = availableDatesFiltered.filter(c => {
          const startDate = dayjs(datetimeObjToISOString(c.start_date));

          return (
            dayjs(startDate).isAfter(ccEndDate) ||
            dayjs(startDate).isBefore(ccStartDate)
          );
        });
      }

      const datesWithoutTime = availableDatesFiltered.map(date =>
        dayjs(datetimeObjToISOString(date.start_date)).format('M/D/YYYY'),
      );

      let uniqueDatesWithoutTime = [...new Set(datesWithoutTime)];

      const availableDays = uniqueDatesWithoutTime
        .filter(dateString => dayjs(dateString).isAfter(dayjs().endOf('day')))
        .map(date => {
          return dayjs(date).toDate();
        });

      /* @ts-ignore */

      setAvailableOldCoachPickerDays(availableDays);
    } else if (available_dates.length && availableTemporaryCoachDays.length) {
      const datesWithoutTimeOldCoach = available_dates.map(date =>
        dayjs(datetimeObjToISOString(date.start_date)).format('M/D/YYYY'),
      );

      const datesWithoutTimeNewCoach = availableTemporaryCoachDays.map(date =>
        dayjs(datetimeObjToISOString(date.start_date)).format('M/D/YYYY'),
      );

      const uniqueDatesWithoutTimeOldCoach = [
        ...new Set(datesWithoutTimeOldCoach),
      ];

      const uniqueDatesWithoutTimeTemporaryCoach = [
        ...new Set(datesWithoutTimeNewCoach),
      ];

      const availableDaysOldCoach = uniqueDatesWithoutTimeOldCoach
        .filter(dateString => dayjs(dateString).isAfter(dayjs().endOf('day')))
        .map(date => {
          return dayjs(date).toDate();
        });

      const availableDaysTemporaryCoach = uniqueDatesWithoutTimeTemporaryCoach
        .filter(dateString => dayjs(dateString).isAfter(dayjs().endOf('day')))
        .map(date => {
          return dayjs(date).toDate();
        });

      setAvailableOldCoachPickerDays(availableDaysOldCoach);

      /* @ts-ignore */
      setAvailableTemporaryCoachPickerDays(availableDaysTemporaryCoach);
    }
  }, [available_dates, availableTemporaryCoachDays, coachCoverageItem]);

  useEffect(() => {
    !coachCoverageItem.length &&
      course_id &&
      getCoachCoverageItem(course_id).then(resp => {
        if (resp?._items?.length) {
          setCoachCoverageItem(resp._items);
        }
      });

    if (coachCoverageItem.length && coachCoverageItem[0].new_coach_id) {
      const ccStartDate = dayjs(
        datetimeObjToISOString(coachCoverageItem[0]?.start_date),
      );
      const ccEndDate = dayjs(
        datetimeObjToISOString(coachCoverageItem[0]?.end_date),
      );

      const startDate = dayjs.max(ccStartDate, dayjs());
      const endDate = ccEndDate;

      if (startDate.isAfter(endDate)) {
        return;
      }

      getAvailableTemporaryCoachDatesAPI(
        coachCoverageItem[0].new_coach_id,
        ISOStringToDatetimeObj(startDate.toISOString()),
        ISOStringToDatetimeObj(endDate.toISOString()),
      ).then(resp => {
        if (resp._items.length) {
          setAvailableTemporaryCoachDays(resp._items);
        }
      });
    }
  }, [course_id, coachCoverageItem]);

  const isAvailableSlotsExistInSelectedDayForOldCoach = useMemo(() => {
    // prevent rendering timeslots for today
    if (dayjs(selectedDay).format('l') === dayjs(new Date()).format('l')) {
      return false;
    }

    return !!(
      available_dates.length &&
      available_dates.filter(day => {
        return (
          dayjs(datetimeObjToISOString(day.start_date)).get('date') ===
            selectedDay?.getDate() &&
          dayjs(datetimeObjToISOString(day.start_date)).get('M') ===
            selectedDay?.getMonth()
        );
      }).length
    );
  }, [available_dates, selectedDay]);

  const isAvailableSlotsExistInSelectedDayForTemporaryCoach = useMemo(() => {
    return !!(
      availableTemporaryCoachDays.length &&
      availableTemporaryCoachDays.filter(day => {
        return (
          dayjs(datetimeObjToISOString(day.start_date)).get('date') ===
            selectedDay?.getDate() &&
          dayjs(datetimeObjToISOString(day.start_date)).get('M') ===
            selectedDay?.getMonth()
        );
      }).length
    );
  }, [availableTemporaryCoachDays, selectedDay]);

  const AvailableSectionsDivs = (isForTemporaryCoach: boolean) => {
    if (!selectedDay && availableDatesInSelectedDay.length) {
      return;
    }

    let filteredAvailableDates = availableDatesInSelectedDay.filter(
      elem =>
        dayjs(datetimeObjToISOString(elem.start_date)).get('date') ===
          selectedDay.getDate() &&
        dayjs(datetimeObjToISOString(elem.start_date)).get('M') ===
          selectedDay.getMonth(),
    );

    filteredAvailableDates = filteredAvailableDates.filter((elem, i, array) => {
      const conferences = conference_list.filter(
        item =>
          dayjs(datetimeObjToISOString(item.start_date)).get('date') ===
            selectedDay.getDate() &&
          dayjs(datetimeObjToISOString(item.start_date)).get('M') ===
            selectedDay.getMonth(),
      );

      for (let l = 0; l < conferences.length; l++) {
        if (
          dayjs(datetimeObjToISOString(conferences[l].start_date))
            .add(-15, 'm')
            .isSame(dayjs(datetimeObjToISOString(elem.start_date)))
        ) {
          return false;
        } else if (
          dayjs(datetimeObjToISOString(conferences[l].start_date))
            .add(-30, 'm')
            .isSame(dayjs(datetimeObjToISOString(elem.start_date)))
        ) {
          return false;
        } else if (
          i === 0 &&
          dayjs(datetimeObjToISOString(array[i].start_date)).isSame(
            dayjs(datetimeObjToISOString(conferences[l].start_date)).add(
              -30,
              'm',
            ),
          )
        ) {
          return false;
        }
      }

      return true;
    });

    let filteredAvailableDatesWithoutSlot: Conference[] = [];

    for (let i = 0; i < filteredAvailableDates.length - 1; ) {
      let day = dayjs(
        datetimeObjToISOString(filteredAvailableDates[i].start_date),
      ).get('date');

      if (
        dayjs(datetimeObjToISOString(filteredAvailableDates[i].start_date)).get(
          'date',
        ) === day
      ) {
        filteredAvailableDatesWithoutSlot.push(filteredAvailableDates[i]);
        i++;
      }

      if (
        dayjs(datetimeObjToISOString(filteredAvailableDates[i].start_date)).get(
          'date',
        ) !== day
      ) {
        filteredAvailableDatesWithoutSlot.pop();
        day = dayjs(
          datetimeObjToISOString(filteredAvailableDates[i].start_date),
        ).get('date');
        filteredAvailableDatesWithoutSlot.push(filteredAvailableDates[i]);
        i++;
      }
    }

    let filteredAvailableDatesInclToday = [
      ...filteredAvailableDatesWithoutSlot,
    ];

    if (
      new Date().getDate() === selectedDay.getDate() &&
      new Date().getMonth() === selectedDay.getMonth()
    ) {
      filteredAvailableDatesInclToday = filteredAvailableDatesInclToday.filter(
        elem => {
          return dayjs().isBefore(
            dayjs(datetimeObjToISOString(elem.start_date)),
          );
        },
      );
    }

    return filteredAvailableDatesInclToday.map((timeSection, i) => {
      const handleClick = () => {
        setselectedTimeslot(timeSection);
        setSelectedTime(
          `${timeSection.start_date.hour}:${timeSection.start_date.minute}`,
        );
        logger.event('slot_select_tap');
      };

      const formattedTime = dayjs(
        new Date(datetimeObjToISOString(timeSection.start_date)),
      ).format('h:mm A');

      return (
        <div
          onClick={handleClick}
          className={classNames({
            [styles.TimeSection]: true,
            [styles.SelectedTimeSection]:
              `${timeSection.start_date.hour}:${timeSection.start_date.minute}` ===
              selectedTime,
            [styles.TimeSection__tempCoach]: isForTemporaryCoach,
            [styles.SelectedTimeSection__tempCoach]:
              isForTemporaryCoach &&
              `${timeSection.start_date.hour}:${timeSection.start_date.minute}` ===
                selectedTime,
          })}
          key={i}
        >
          {formattedTime}
        </div>
      );
    });
  };

  const onSelectDay = useCallback(
    day => {
      const startOfDay = dayjs.utc(dayjs(day).startOf('day'));
      const endOfDay = dayjs.utc(dayjs(day).endOf('day'));

      let currentCoachId = '';

      const isOldCoachChosenDay =
        availableOldCoachPickerDays.length &&
        availableOldCoachPickerDays.find(item => {
          return (
            dayjs(item).get('D') === dayjs(day).get('D') &&
            dayjs(item).get('M') === dayjs(day).get('M')
          );
        });
      const isTemporaryCoachChosenDay =
        availableTemporaryCoachPickerDays.length &&
        availableTemporaryCoachPickerDays.find(item => {
          return (
            dayjs(item).get('D') === dayjs(day).get('D') &&
            dayjs(item).get('M') === dayjs(day).get('M')
          );
        });

      if (isOldCoachChosenDay && !coachCoverageItem.length) {
        currentCoachId = coach_id;
      } else if (isTemporaryCoachChosenDay) {
        currentCoachId = coachCoverageItem[0].new_coach_id;
      } else if (isOldCoachChosenDay && coachCoverageItem.length) {
        currentCoachId = coachCoverageItem[0].old_coach_id;
      }

      if (currentCoachId === '') {
        return;
      }

      getAvailableSlotsForOneDay(currentCoachId, startOfDay, endOfDay).then(
        response => {
          setAvailableDatesInSelectedDay(response._items);
        },
      );

      setSelectedDay(day);
    },
    [
      availableOldCoachPickerDays,
      availableTemporaryCoachPickerDays,
      coachCoverageItem,
      coach_id,
    ],
  );

  const handleScheduleCallClick = async () => {
    if (!selectedDay && !selectedTime) {
      return;
    }

    logger.event(
      sessionToRebook ? 'reschedule_session_tap' : 'schedule_session_tap',
    );

    let currentCoachId = '';

    const isOldCoachChosenDay =
      availableOldCoachPickerDays.length &&
      availableOldCoachPickerDays.find(item => {
        return (
          dayjs(item).get('D') === dayjs(selectedDay).get('D') &&
          dayjs(item).get('M') === dayjs(selectedDay).get('M')
        );
      });
    const isTemporaryCoachChosenDay =
      availableTemporaryCoachPickerDays.length &&
      availableTemporaryCoachPickerDays.find(item => {
        return (
          dayjs(item).get('D') === dayjs(selectedDay).get('D') &&
          dayjs(item).get('M') === dayjs(selectedDay).get('M')
        );
      });

    if (isOldCoachChosenDay && !coachCoverageItem.length) {
      currentCoachId = coach_id;
    } else if (isTemporaryCoachChosenDay) {
      currentCoachId = coachCoverageItem[0].new_coach_id;
    } else if (isOldCoachChosenDay && coachCoverageItem.length) {
      currentCoachId = coachCoverageItem[0].old_coach_id;
    }

    const createConference = async (coachId: string) => {
      try {
        if (sessionToRebook) {
          await conferenceStore.client.delete(sessionToRebook, {
            reschedule: true,
          });
        }

        return await createConferenceAPI(
          coachId,
          course_id,
          selectedTimeslot,
          session_duration,
          !!sessionToRebook,
        );
      } catch (error) {
        throw error;
      }
    };

    createConference(currentCoachId)
      .then(() => setIsOpenConfirmationModal(true))
      .then(async () => {
        onBookSession?.();

        const startOfDay = dayjs.utc(dayjs(selectedDay).startOf('day'));
        const endOfDay = dayjs.utc(dayjs(selectedDay).endOf('day'));

        getAvailableSlotsForOneDay(currentCoachId, startOfDay, endOfDay).then(
          response => {
            setAvailableDatesInSelectedDay(response._items);
          },
        );
      })
      .catch(err => {
        if (err) {
          setIsOpenErrorModal(true);
        }
      });
    setIsOpenSchedulingModal(false);
  };

  const DayPickerFooter = () => (
    <div className={styles.DayPickerFooter}>
      <div className={styles.DayPickerFooterRow}>
        <div className={styles.AvailableDaysLabel} />{' '}
        {availableTemporaryCoachPickerDays.length ? (
          <div>- Old Coach</div>
        ) : (
          <div>- Available days</div>
        )}
      </div>
      {availableTemporaryCoachPickerDays.length ? (
        <div className={styles.DayPickerFooterRow}>
          <div className={styles.TemporaryCoachLabel} />
          <div>- Temporary coach</div>
        </div>
      ) : null}
    </div>
  );

  const title = sessionToRebook
    ? 'Reschedule live session'
    : 'Schedule live session';

  const firstAvailableDay = new Date(
    Math.min.apply(
      null,
      availableOldCoachPickerDays.concat(availableTemporaryCoachPickerDays),
    ),
  );

  const JSX = (
    <>
      <ModalAnimateWin
        showModal={isOpenSchedulingModal}
        closeModalHandler={() => setIsOpenSchedulingModal(false)}
        isBody
        header={title}
        type="schedule"
        classNameHeader="schedule-header"
      >
        <div className={styles.DayAndTimeContainer}>
          <div className={styles.RowContainer}>
            <div className={styles.DayContainer}>
              <div className={styles.ChooseDayText}>Choose day</div>
              <DayPicker
                mode="single"
                modifiers={{
                  available: availableOldCoachPickerDays,
                  temporary: availableTemporaryCoachPickerDays,
                }}
                modifiersClassNames={{
                  available: 'available-modifier',
                  temporary: 'temporary-coach',
                }}
                disabled={[
                  {
                    from: new Date(1970, 1, 1),
                    to: firstAvailableDay
                      ? new Date(
                          firstAvailableDay.getFullYear(),
                          firstAvailableDay.getMonth(),
                          firstAvailableDay.getDate() - 1,
                        )
                      : new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDate() - 1,
                        ),
                  },
                ]}
                onSelect={onSelectDay}
                selected={selectedDay}
                onDayClick={() => setSelectedTime('')}
              />
              <DayPickerFooter />
            </div>
            <div className={styles.TimeContainer}>
              <div className={styles.TimeHeaderContainer}>
                <div className={styles.ChooseTimeText}>Choose Time</div>
                <div className={styles.TimezoneContainerText}>
                  Time Zone: {dayjs().format('z')}
                </div>
              </div>
              <div className={styles.NoTimeSlots_flex}>
                <div>
                  {(isAvailableSlotsExistInSelectedDayForOldCoach ||
                    isAvailableSlotsExistInSelectedDayForTemporaryCoach) &&
                  AvailableSectionsDivs(
                    isAvailableSlotsExistInSelectedDayForTemporaryCoach,
                  )?.length ? (
                    AvailableSectionsDivs(
                      isAvailableSlotsExistInSelectedDayForTemporaryCoach,
                    )?.map((section, i) => {
                      if (i % 4 === 0) {
                        return (
                          <div
                            key={i}
                            className={styles.TimeSectionsRowContainer}
                          >
                            {AvailableSectionsDivs(
                              isAvailableSlotsExistInSelectedDayForTemporaryCoach,
                            )?.map((subdiv, k, array) => {
                              if (i === 0 && k < 4) {
                                return array[k];
                              }

                              if (i + k >= i + 4) {
                                return;
                              }

                              return array[(i / 4) * 4 + k];
                            })}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className={styles.NoTimeSlots}>
                      <TimeSquare />
                      No time Slots Available
                    </div>
                  )}
                </div>
              </div>
              {(isAvailableSlotsExistInSelectedDayForOldCoach ||
                isAvailableSlotsExistInSelectedDayForTemporaryCoach) && (
                <div
                  className={
                    selectedTime && selectedDay
                      ? styles.ScheduleLiveSessionButton__active
                      : styles.ScheduleLiveSessionButton__disabled
                  }
                  onClick={
                    selectedTime && selectedDay
                      ? () => handleScheduleCallClick()
                      : () => {}
                  }
                >
                  {title}
                  {selectedTimeslot ? (
                    <p className={styles.ScheduleTimeAndHours}>
                      {dayjs(
                        new Date(
                          datetimeObjToISOString(selectedTimeslot.start_date),
                        ),
                      ).format('lll') +
                        ' - ' +
                        dayjs(
                          datetimeObjToISOString(selectedTimeslot?.start_date),
                        )
                          .add(session_duration, 'minutes')
                          .format('h:mm A')}
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalAnimateWin>
      {selectedDay && selectedTime && (
        <ModalAnimateWin
          showModal={isOpenConfirmationModal}
          closeModalHandler={() => setIsOpenConfirmationModal(false)}
          classNameHeader="schedule-header"
          isBody
          header="Live Session Scheduled!"
          type="confirmation-schedule"
        >
          <div className={styles.ConfirmationImageContainer}>
            <img
              src={
                'https://static.hcod.yourcoach.health/confirmation-schedule.png'
              }
              alt="confirmation"
            />
          </div>
          <div className={styles.ConfirmationTextContainer}>
            Scheduled Live Session
            <br />
            {dayjs(
              new Date(datetimeObjToISOString(selectedTimeslot?.start_date)),
            ).format('MMM D, YYYY h:mm A')}{' '}
            -{' '}
            {dayjs(
              new Date(datetimeObjToISOString(selectedTimeslot?.start_date)),
            )
              .add(30, 'minutes')
              .format('h:mm A')}
          </div>
          <div
            onClick={() => setIsOpenConfirmationModal(false)}
            className={styles.ConfirmationButton}
          >
            Ok
          </div>
        </ModalAnimateWin>
      )}
      <ModalAnimateWin
        showModal={isOpenErrorModal}
        closeModalHandler={() => setIsOpenErrorModal(false)}
        type="error"
      >
        <>
          <div className={styles.ErrorModalContainer}>
            <div className={styles.MainTextError}>
              The time you chose is no longer available
            </div>
            <div className={styles.DescriptionTextError}>
              Please choose an alternate time for your live session.
            </div>
          </div>
          <div
            onClick={() => setIsOpenErrorModal(false)}
            className={styles.ConfirmationButton}
          >
            Ok
          </div>
        </>
      </ModalAnimateWin>
    </>
  );

  return {bookingJSX: JSX, setIsOpenSchedulingModal, setSessionToRebook};
};
