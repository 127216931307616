import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, computed, observable, runInAction} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';

import {labelErrorOccurred, labelSend} from '@src/common/i18n/i18nCommon';
import {
  labelInviteByNameTabAddMessageBtn,
  labelInviteByNameTabDefaultMessage,
  labelInviteByNameTabEditMessageBtn,
  labelInviteByNameTabMessageTextInputPlaceholder,
  labelInviteByNameTabNoResultsLbl,
  labelInviteByNameTabSaveBtn,
  labelInviteByNameTabSearch,
} from '@src/common/i18n/i18nInvites';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomInput, CustomSearch} from '@src/components/CustomForm';
import {IconProfile, IconUserPlus} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';

import InvitesContext from '../context/InvitesContext';
import type {Follower, ILocalStoreInvite} from '../context/InvitesLocalStore';

import styles from './../styles.module.css';

interface ILocalStore {
  _searchTimeout: number;
  _searchUsers(userName: string): void;
  data: Follower[];
  setSearchTimeout(_searchTimeout: number): void;
}

interface Props {}

const InviteByName: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const storeInvites: ILocalStoreInvite = useContext(InvitesContext)!;
  const localStore: ILocalStore = useRef(
    observable(
      {
        _searchTimeout: 0,
        setSearchTimeout(_searchTimeout: number) {
          this._searchTimeout = _searchTimeout;
        },
        _searchUsers(userName: string) {
          const target = storeInvites.target || {coach_ids: []};
          const excludeIds = target!.coach_ids.slice();

          if (!excludeIds.includes(user!._id)) {
            excludeIds.push(user!._id);
          }

          storeInvites.toItems.forEach(item => {
            if (item.client._id) {
              excludeIds.push(item.client._id);
            }
          });

          clearTimeout(this._searchTimeout);

          if (storeInvites.usersStore) {
            storeInvites.usersStore.isFetching = true;

            try {
              this.setSearchTimeout(
                setTimeout(() => {
                  storeInvites
                    .usersStore!.fetch({
                      query: [
                        ['client_id', '!in', excludeIds],
                        ['client_name', 'fulltext', `${userName}*`],
                      ],
                    })
                    .catch(error => {
                      if (apiRequest.isCanceledError(error)) {
                        return;
                      }

                      getCustomConfirmAlert({
                        title: labelErrorOccurred(),
                        message: error.message,
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {},
                          },
                        ],
                      });

                      setError(error);
                    });
                }, 1000),
              );
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          }
        },
        _onQueryChange(query: string) {
          storeInvites.setQuery(query);

          storeInvites.setSelectedItem(null);

          clearTimeout(this._searchTimeout);

          storeInvites.usersStore!.clear();

          if (
            storeInvites.query &&
            storeInvites.query.length >= 2 &&
            !storeInvites.query.includes('@')
          ) {
            this._searchUsers(storeInvites.query);
          }
        },
        get data() {
          if (storeInvites.query.length >= 2) {
            return storeInvites.usersStore
              ? storeInvites.usersStore.items
                  .slice()
                  .filter(item => !item.is_matched)
              : [];
          } else {
            return storeInvites.initialUsersStore
              ? storeInvites.initialUsersStore.items
                  .slice()
                  .filter(item => !item.is_matched)
              : [];
          }
        },
      },
      {
        _searchTimeout: observable,
        _searchUsers: action,
        setSearchTimeout: action,
        data: computed,
      },
    ),
  ).current;

  useEffect(() => {
    return () => {
      clearTimeout(localStore._searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coachName = user!.name;
  const programTitle = (storeInvites.target as Program).title || '';
  const defaultMessage = (
    <div>
      <span className={styles.boldGreen}>{coachName}</span>
      {labelInviteByNameTabDefaultMessage()}
      <span className={styles.boldGreen}>{programTitle}</span>
    </div>
  );

  const handleOnSearchChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    storeInvites.setQuery(event?.currentTarget.value!);

    storeInvites.setSelectedItem(null);

    clearTimeout(localStore._searchTimeout);

    storeInvites.usersStore!.clear();

    if (
      storeInvites.query &&
      storeInvites.query.length >= 2 &&
      !storeInvites.query.includes('@')
    ) {
      localStore._searchUsers(storeInvites.query);
    }
  };

  const _onEditMessagePress = () => {
    storeInvites.setIsEditingMessage(!storeInvites.isEditingMessage);
  };

  const handleOnChangeMessage = (
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event?.currentTarget.value.length! < 10000) {
      storeInvites.setMessage(event?.currentTarget.value!);
    }
  };

  const handleAddUser = (follower: Follower) => {
    return () => {
      if (
        !storeInvites.toItems.find(item => item.client._id === follower._id)
      ) {
        runInAction(() => {
          storeInvites.toItems.push({
            ...follower,
            fromSearch: !!(
              storeInvites.query && storeInvites.query.length >= 2
            ),
          });
        });

        storeInvites.usersStore!.removeItem(follower);

        storeInvites.initialUsersStore!.removeItem(follower);

        if (!storeInvites.usersStore!.hasItems) {
          storeInvites.usersStore!.clear();
          storeInvites.setQuery('');
        }

        if (!storeInvites.initialUsersStore!.hasItems) {
          storeInvites.initialUsersStore!.clear();
          storeInvites.setQuery('');
        }
      }
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`InviteByName ${styles.InviteByName}`}>
          <Scrollbar
            className={`scrollbar ${styles.InviteByName}`}
            noScrollX
            wrapperProps={{className: 'wrapper'}}
            trackYProps={{className: 'trackY'}}
            thumbYProps={{className: 'thumbY'}}
            trackXProps={{className: 'trackX'}}
            thumbXProps={{className: 'thumbY'}}
          >
            <CustomSearch
              label={labelInviteByNameTabSearch()}
              id="searchQuestionnaires"
              onChange={handleOnSearchChange}
              classContainer={`searchContainer ${styles.searchContainer}`}
              classInput={styles.searchContainerInput}
              className="search"
              classIcon="icon"
              classIconContainer="iconContainer"
            />
            {!storeInvites.isLoaded ? (
              <Loader />
            ) : (
              <div className={styles.invitesContainer}>
                <div className={styles.inviteView}>Invite view</div>
                <div className={styles.invitePreViewContainer}>
                  {defaultMessage}
                  {!storeInvites.isEditingMessage && storeInvites.message ? (
                    <div className={styles.userMessage}>
                      {storeInvites.message}
                    </div>
                  ) : null}
                  {storeInvites.isEditingMessage ? (
                    <CustomInput
                      type="input"
                      label={labelInviteByNameTabMessageTextInputPlaceholder()}
                      customClassLabel="standardLabel"
                      customClassInput={`standardInput border ${styles.containerInput}`}
                      id="addEmail"
                      value={storeInvites.message}
                      onChange={handleOnChangeMessage}
                      showErrorImmediately
                      error={storeInvites.error}
                    />
                  ) : null}
                  {storeInvites.isEditingMessage ? (
                    <div
                      className={styles.greenBut}
                      onClick={_onEditMessagePress}
                    >
                      <div className={styles.greenButTitle}>
                        {labelInviteByNameTabSaveBtn()}
                      </div>
                    </div>
                  ) : storeInvites.message ? (
                    <div
                      className={styles.greenBut}
                      onClick={_onEditMessagePress}
                    >
                      <div className={styles.greenButTitle}>
                        {labelInviteByNameTabEditMessageBtn()}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.greenBut}
                      onClick={_onEditMessagePress}
                    >
                      <div className={styles.createSquadIcon}>+</div>
                      <div className={styles.greenButTitle}>
                        {labelInviteByNameTabAddMessageBtn()}
                      </div>
                    </div>
                  )}
                </div>
                {localStore.data.length > 0 ? (
                  <div className={styles.usersContainer}>
                    {localStore.data.map(item => {
                      return (
                        <div className={styles.userRow} key={item._id}>
                          <div className={styles.userContainer}>
                            <div className={styles.userAvatar}>
                              <OtherUserProfileImg
                                avatar={item.client.avatar}
                                title={item.client.name}
                              />
                            </div>
                            <div className={styles.userName}>
                              {item.client.name}
                            </div>
                          </div>
                          <div
                            className={styles.addBut}
                            onClick={handleAddUser(item)}
                          >
                            <IconUserPlus customClass={styles.icoUserPlus} />
                            <span>{labelSend()}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <NoResults
                    text={labelInviteByNameTabNoResultsLbl()}
                    className={styles.NoResults}
                  >
                    <div className={styles.iconNoResultContainer}>
                      <IconProfile
                        className={styles.iconNoResult}
                        viewBox="0 0 24 24"
                      />
                    </div>
                  </NoResults>
                )}
              </div>
            )}
          </Scrollbar>
        </div>
      )}
    </Observer>
  );
};

export default memo(InviteByName);
