import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import QSendUsersContext from './QSendUsersContext';
import QSendUsersLocalStore from './QSendUsersLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
  QId: string;
}

const QSendUsersState: FC<Props> = ({children, QId}) => {
  QSendUsersLocalStore.setCurrentQId(QId);

  return (
    <QSendUsersContext.Provider value={QSendUsersLocalStore}>
      {children}
    </QSendUsersContext.Provider>
  );
};

export default memo(QSendUsersState);
