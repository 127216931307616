import type {FC} from 'react';
import React from 'react';

import Folder from '@yourcoach/shared/assets/icons/folder.svg';

interface Props {
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
}

const IconFolder: FC<Props> = ({
  height = '100%',
  width = '100%',
  viewBox = '5 5 18 22',
  className = '',
}) => {
  return (
    <Folder
      width={height}
      height={width}
      fill="#819AA9"
      viewBox={viewBox}
      className={className}
    />
  );
};

export default IconFolder;
