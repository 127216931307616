import type {FC} from 'react';
import React, {memo} from 'react';

import {labelDashboard} from '@src/common/i18n/i18nMainMenu';

import NavTabs from './NavTabs';
import styles from './styles.module.css';

interface Props {}

const Dashboard: FC<Props> = () => {
  return (
    <div className={`Dashboard ${styles.Dashboard}`}>
      <h1>{labelDashboard()}</h1>
      <NavTabs />
    </div>
  );
};

export default memo(Dashboard);
