export const API_VERSION = '9.0';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const ASSETS_URL = 'https://assets.yourcoach.health';

export const TOS_URL = `${ASSETS_URL}/tos.pdf`;

export const PRIVACY_POLICY_URL = `${ASSETS_URL}/privacy-policy.pdf`;

export const BUGSNAG_KEY = 'd17c80a658eaf51a600473bde5c568f9';

export const SPOONACULAR_KEY = '***';

export const GOOGLE_KEY = 'AIzaSyAWoXCIRokxR0SdPSHVA7yopcS8TZ-DP8g';

export const SUPPORT_EMAIL = 'support@yourcoach.health';

export const DEFAULT_USER_NAME = 'User Name';

export const APPSTORE_ID = '1359191114';

export const APPSTORE_URL = {
  ios: `https://apps.apple.com/app/yourcoach/id${APPSTORE_ID}`,
  android: `https://play.google.com/store/apps/details?id=${process.env.MOBILE_APP_BUNDLE_ID}`,
};

export const GET_WS_URL = () =>
  `${process.env.API_ENDPOINT!.replace(
    /https?/,
    process.env.APP_ENV === 'dev' ? 'wss' : 'wss',
  )}/events/listen`;

export const ACCOUNT_STORAGE_KEY = 'account';
