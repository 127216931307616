import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {Observer} from 'mobx-react';

import type {PostAttachment as PostAttachmentT} from '@yourcoach/shared/api/channel/post';
import {logger} from '@yourcoach/shared/utils/logger';

import {
  IconTextAttachments,
  IconTextSendButt,
} from '../../../../components/icons';
import AttachmentsForm from '../AttachmentsForm/AttachmentsForm';
import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';

import styles from './styles.module.css';

interface Props {
  initialText: string;
  text: string;
  isInsertText: boolean;
  setIsInsertText: (isInsertText: boolean) => void;
  changeText: (text: string) => void;
  getUserFile: () => void;
  sendDate: () => void;
}

const MIN_ROWS = 1;
const MAX_ROWS = 10;
const LINE_HEIGHT = 18;

const ChatMainTextEditor: FC<Props> = ({
  initialText,
  text,
  changeText,
  getUserFile,
  sendDate,
  setIsInsertText,
  isInsertText,
}) => {
  const [value, setValue] = useState<string>(initialText);
  const [rows, setRows] = useState<number>(1);
  const [disableSendBut, setDisableSendBut] = useState<boolean>(true);
  const menuId = 'menuIdAttachment';
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);

  useEffect(() => {
    changeText(value);

    setDisableSendBut(!value?.trim());
  }, [changeText, value]);

  useEffect(() => {
    if (isInsertText) {
      setValue(text.trim());

      setIsInsertText(false);
    }
  }, [text, isInsertText, setIsInsertText]);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const onClickAttachment = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();

    logger.event('attach_file_tap');

    contextMenu.show({
      id: menuId,
      event: event,
    });
  };

  const MyMenu = () => (
    <Observer>
      {() => (
        <div onClick={handleClickMenu}>
          <Menu
            id={menuId}
            animation={animation.fade}
            className="contextMenu"
            style={{zIndex: 1000}}
          >
            {mainChatsLocalStore!.fileAttachment ? (
              <Item
                onClick={handleOnClickRemoveAttachment}
                className="contextMenuItem"
              >
                Remove Attachment
              </Item>
            ) : null}
            <Item
              onClick={handleOnClickChoosePhoto}
              className="contextMenuItem"
            >
              Upload Document or Photo
            </Item>
          </Menu>
        </div>
      )}
    </Observer>
  );

  const handleOnClickRemoveAttachment = () => {
    mainChatsLocalStore!._removeFileAttachments();
  };

  const handleOnClickChoosePhoto = () => {
    getUserFile();
  };

  const handleOnClickSend = useCallback(() => {
    if (!disableSendBut || mainChatsLocalStore!.fileAttachment) {
      setValue('');
      setRows(MIN_ROWS);
      sendDate();
    }
  }, [disableSendBut, mainChatsLocalStore, sendDate]);

  const handleDeleteAttachment = (attachment: PostAttachmentT) => {
    mainChatsLocalStore!._removeFileAttachment(attachment);
  };

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const previousRows = e.target.rows;

      e.target.rows = MIN_ROWS; // reset number of rows in textarea

      // eslint-disable-next-line no-bitwise
      const currentRows = ~~(e.target.scrollHeight / LINE_HEIGHT);

      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }

      if (currentRows >= MAX_ROWS) {
        e.target.rows = MAX_ROWS;
        e.target.scrollTop = e.target.scrollHeight;
      }

      setRows(currentRows < MAX_ROWS ? currentRows : MAX_ROWS);
      setValue(e.currentTarget.value);
    },
    [],
  );

  return (
    <Observer>
      {() => (
        <div className={`ChatMainTextEditor ${styles.ChatMainTextEditor}`}>
          <AttachmentsForm
            attachments={mainChatsLocalStore!.attachments.slice()}
            deleteAttachment={handleDeleteAttachment}
          />
          <div className={styles.textInputContainer}>
            <textarea
              rows={rows}
              className={styles.textInput}
              placeholder="Type your message"
              spellCheck
              onChange={onInputChange}
              value={value}
            />
            <div className={styles.buttMenu}>
              <div
                className={`${styles.buttMenuItem} ${styles.attachmentsBut} ${
                  mainChatsLocalStore!.fileAttachment ? styles.butActive : ''
                }`}
                onClick={onClickAttachment}
              >
                <IconTextAttachments />
              </div>
              <div
                className={`${styles.buttMenuItem} ${styles.sendBut} ${
                  disableSendBut && !mainChatsLocalStore!.fileAttachment
                    ? styles.disabled
                    : ''
                }`}
                onClick={handleOnClickSend}
              >
                <IconTextSendButt />
              </div>
            </div>
          </div>
          <MyMenu />
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatMainTextEditor);
