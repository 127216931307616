import {useMemo} from 'react';

import {action, computed, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';

import {setError} from '../../../common/setError';
import useURLQueryParam from '../../../hooks/useURLQueryParam';
import type {
  ILink,
  TListDataItem,
  TSystemFolder,
} from '../../../models/library';

export interface ILibraryLocalStore {
  folderID: string | null;
  isCouch: boolean;
  isError: boolean;
  loading: boolean;
  clear: () => void;
  foldersLinks: CollectionStore<ILink> | null;
  foldersMainLinks: CollectionStore<ILink> | null;
  setLoading: (newLoading: boolean) => void;
  setIsCouch: (newIsCouch: boolean) => void;
  getFoldersLinks: (newFoldersLinks: CollectionStore<ILink>) => void;
  getFoldersMainLinks: (newFoldersLinks: CollectionStore<ILink>) => void;
  updateFolderLinks: (numUpdate: number) => void;
  links: ILink[];
  mainLinks: ILink[];
  data: TListDataItem[];
  mainFolderFolders: TListDataItem[];
  systemFolders: TSystemFolder[];
}

export const linkExpand = {
  link: ['resource_id'],
};

const useLibraryLocalStore = () => {
  const folderId = useURLQueryParam('id');

  const store: ILibraryLocalStore | null = useMemo(
    () =>
      observable<ILibraryLocalStore>(
        {
          foldersLinks: null,
          foldersMainLinks: null,
          clear() {
            if (this.foldersLinks) {
              this.foldersLinks.clear();
            }

            if (this.foldersMainLinks) {
              this.foldersMainLinks.clear();
            }
          },
          isCouch: false,
          isError: false,
          loading: false,

          folderID: folderId,
          getFoldersLinks(newFoldersLinks: CollectionStore<ILink>) {
            this.isError = false;
            this.foldersLinks = newFoldersLinks;
          },
          getFoldersMainLinks(newFoldersLinks: CollectionStore<ILink>) {
            this.foldersMainLinks = newFoldersLinks;
          },
          setIsCouch(newIsCouch: boolean) {
            this.isCouch = newIsCouch;
          },
          setLoading(newLoading: boolean) {
            this.loading = newLoading;
          },
          updateFolderLinks(numUpdate: number) {
            const LIMIT = 300;
            const silent = false;

            this!.setLoading(true);

            this!
              .foldersLinks!.fetch(
                {
                  limit: Math.max(
                    this!.foldersLinks!.items.length + numUpdate,
                    LIMIT,
                  ),
                },
                {silent},
              )
              .then(() => {
                this!.setLoading(false);
              })
              .catch(error => {
                setError(error);
                this.isError = true;
                this!.setLoading(false);
              });

            this!
              .foldersMainLinks!.fetch(
                {
                  limit: Math.max(this!.foldersMainLinks!.items.length, LIMIT),
                },
                {silent},
              )
              .catch(error => {
                setError(error);
                this!.setLoading(false);
              });
          },
          get links() {
            let retArr: ILink[] = [];

            if (this.foldersLinks) {
              retArr = this.foldersLinks.items.slice();
            }

            return retArr;
          },
          get mainLinks() {
            let retArr: ILink[] = [];

            if (this.foldersMainLinks) {
              retArr = this.foldersMainLinks.items.slice();
            }

            return retArr;
          },
          get data() {
            let retArr: TListDataItem[] = [];

            if (this.links.length) {
              let folders: ILink[] = [];
              let files: ILink[] = [];
              let systemFiles: ILink[] = [];

              this.links.forEach(link => {
                if (link.type === 'folder') {
                  folders.push(link);
                } else if (link.type === 'file') {
                  systemFiles.push(link);
                } else {
                  files.push(link);
                }
              });

              const sortFn = (a: ILink, b: ILink) =>
                a.name
                  .toLowerCase()
                  .localeCompare(b.name.toLowerCase(), undefined, {
                    numeric: true,
                  });

              retArr = folders
                .sort(sortFn)
                .concat(files.sort(sortFn))
                .concat(systemFiles);
            }

            if (!this.folderID) {
              retArr = this.systemFolders.slice().concat(retArr);
            }

            return retArr;
          },
          get mainFolderFolders() {
            let retArr: TListDataItem[] = [];

            if (this.mainLinks.length) {
              let folders: ILink[] = [];
              let files: ILink[] = [];

              this.mainLinks.forEach(link => {
                if (link.type === 'folder') {
                  folders.push(link);
                } else {
                  files.push(link);
                }
              });

              const sortFn = (a: ILink, b: ILink) =>
                a.name
                  .toLowerCase()
                  .localeCompare(b.name.toLowerCase(), undefined, {
                    numeric: true,
                  });

              retArr = folders.sort(sortFn).concat(files.sort(sortFn));
            }

            retArr = this.systemFolders.slice().concat(retArr);

            return retArr;
          },
          get systemFolders() {
            const retSystemFolders = (
              [
                {
                  _id: 'my_materials',
                  name: 'Shared with me',
                  isSystem: true,
                  isStarred: true,
                  onPress: this._onMaterialsPress,
                },
              ] as TSystemFolder[]
            ).filter(Boolean);

            return retSystemFolders;
          },
        },
        {
          foldersLinks: observable,
          foldersMainLinks: observable,
          folderID: observable,
          isCouch: observable,
          isError: observable,
          loading: observable,
          getFoldersLinks: action,
          getFoldersMainLinks: action,
          setIsCouch: action,
          setLoading: action,
          updateFolderLinks: action,
          links: computed,
          mainLinks: computed,
          data: computed,
          mainFolderFolders: computed,
          systemFolders: computed,
          clear: action,
        },
      ),
    [folderId],
  );

  return store;
};

export default useLibraryLocalStore;
