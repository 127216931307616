import type {FC} from 'react';
import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';

import type {Course as ICourse} from '@yourcoach/shared/api/course';

import type {TSystemFolder} from '@src/models/library';
import {PATH_TYPE} from '@src/routes/utils';

import IconFolder from '../../assets/icon-folder.svg';
import styles from '../styles.module.css';

interface Props {
  data: TSystemFolder & {
    course_id?: string;
    channel_ids?: string[];
    course?: ICourse;
  };
  onSelectCourse: (arg: string) => void;
  className: string;
  link?: string;
  dataLink?: string;
  forUser?: boolean;
}

const SystemFolder: FC<Props> = ({
  className,
  data,
  onSelectCourse,
  link = `/${PATH_TYPE.yourSpace}/${PATH_TYPE.myLibrary}`,
  dataLink,
  forUser = false,
}) => {
  const history = useHistory();
  const {_id, name, program_id, isProgram, isCourses} = data;

  let thisId = '';

  if (dataLink) {
    thisId = dataLink;
  } else if (isCourses) {
    thisId = `sid=${_id}`;
  } else if (isProgram) {
    thisId = `id=${_id}&pid=${program_id}`;
  } else if (forUser) {
    thisId = `sid=${data.course_id}`;
  } else {
    thisId = `id=${_id}`;
  }

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isCourses || forUser) {
      onSelectCourse(data.course?._id ? data.course?._id : '');
    } else {
      history.push({
        pathname: link,
        search: thisId,
      });
    }
  };

  return (
    <div
      className={`SystemFolder ${className}`}
      style={{cursor: 'pointer'}}
      onClick={handleOnClick}
    >
      <div className={`${styles.iconContainer} ${styles.link}`}>
        <IconFolder />
      </div>
      <div className={styles.folderName}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default memo(SystemFolder);
