import type {ChangeEvent, FC} from 'react';
import React, {memo, useState} from 'react';

import {v4 as uuidv4} from 'uuid';

import {labelAdd} from '@src/common/i18n/i18nCommon';
import {
  labelAddSection,
  labelDescriptionName,
  labelNewQuestion,
  labelSectionName,
  labelThisFieldIsMandatoryAndMustContainAtLeast3Characters,
} from '@src/common/i18n/i18nQuestionnaire';
import {CustomButton, CustomInput} from '@src/components/CustomForm';
import type {IQSectionWithFields} from '@src/modules/Questionnaire/context/qAppStore';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import styles from './styles.module.css';

interface Props {
  setSection: (section: IQSectionWithFields) => void;
}

const AddSectionQ: FC<Props> = ({setSection}) => {
  const [sectionName, setSectionName] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [sectionNameError, setSectionNameError] = useState('');

  const handleOnChangeSectionName = (event: ChangeEvent<HTMLInputElement>) => {
    setSectionNameError('');
    setSectionName(event!.currentTarget.value);
  };

  const handleOnChangeDescriptionName = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setSectionDescription(event!.currentTarget.value);
  };

  const handleOnClickAdd = () => {
    if (sectionName.length <= 2) {
      setSectionNameError(
        labelThisFieldIsMandatoryAndMustContainAtLeast3Characters(),
      );
    } else {
      const userSection: IQSectionWithFields = {
        id: uuidv4(),
        title: sectionName,
        description: sectionDescription,
        questions: [
          {
            question: labelNewQuestion(),
            is_optional: false,
            type: ALIAS_TYPES.text,
          },
        ],
      };

      setSection(userSection);
    }
  };

  return (
    <div className={`AddSectionQ ${styles.AddSectionQ}`}>
      <h2>{labelAddSection()}</h2>
      <div className={styles.inputsContainer}>
        <div className={styles.inputs}>
          <CustomInput
            type="input"
            label={labelSectionName()}
            id="sectionName"
            customClassLabel="QStandardLabel"
            customClassCont={`QStandardContainer ${styles.mb8}`}
            customClassInput="QStandardInput"
            value={sectionName}
            error={sectionNameError}
            showErrorImmediately
            onChange={handleOnChangeSectionName}
          />
          <CustomInput
            type="input"
            label={labelDescriptionName()}
            id="descriptionName"
            customClassLabel="QStandardLabel"
            customClassCont="QStandardContainer"
            customClassInput="QStandardInput"
            value={sectionDescription}
            onChange={handleOnChangeDescriptionName}
          />
        </div>
        <div>
          <CustomButton
            type="button"
            classButton="blueButt"
            onClick={handleOnClickAdd}
          >
            {labelAdd()}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default memo(AddSectionQ);
