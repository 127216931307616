import React from 'react';

import styled from 'styled-components';

import {Avatar} from '@yourcoach/shared/uikit/Avatar';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {containerWeb} from './styles';
import type {Props, StyledProps} from './types';

const StyledInfoBadge = styled(View)<StyledProps>`
  ${containerWeb}
`;

const InfoBadge: React.FC<WithDomStyleProp<Props>> = ({
  avatarUrl,
  label,
  value,
  icon,
  withAvatar,
  ...rest
}) => {
  // const controller = useController(rest);

  return (
    <StyledInfoBadge {...rest}>
      {withAvatar ? (
        <Avatar size={'icon.s'} name={value} avatarUrl={avatarUrl} />
      ) : (
        <IconBox
          padding={7}
          size={'icon.s'}
          variant={'round'}
          bg={'background2'}
          fill={'icon3'}
        >
          {icon}
        </IconBox>
      )}
      <View style={{flexDirection: 'column'}}>
        <Text variant={'small'} color={'text3'}>
          {label}
        </Text>
        <Text variant={'small'} weight="bold">
          {value}
        </Text>
      </View>
    </StyledInfoBadge>
  );
};

export {InfoBadge};
