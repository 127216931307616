import React, {useContext, useEffect, useMemo, useState} from 'react';

import type {Category} from '@yourcoach/shared/api/category';

import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import CategoriesListEditor from './Categories/CategoriesListEditor';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.extra_categories';

const ExtraCategoriesStep = props => {
  const [categories, setCategories] = useState<Category[]>([]);
  const {
    stores: {categoryStore, currentUserStore},
  } = useContext(AppContext);

  const mainCategory = useMemo(
    () => currentUserStore.user!.client_categories[0],
    [currentUserStore.user],
  );

  useEffect(() => {
    const fetchCats = async () => {
      await categoryStore.fetchCategories();
    };

    fetchCats().then(() => {
      setCategories(categoryStore.categories || []);
    });
  }, [categoryStore]);

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={5}
      fieldName="extra_goals"
      {...props}
    >
      {categories.length > 0 ? (
        <CategoriesListEditor
          categories={categories.filter(
            c => c._id !== mainCategory.category_id,
          )}
          isSecondary={true}
        />
      ) : (
        <Loader />
      )}
    </EditorStep>
  );
};

export default ExtraCategoriesStep;
