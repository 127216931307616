import React, {useContext, useEffect, useState} from 'react';

import type {Category} from '@yourcoach/shared/api/category';

import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import type {Editor} from './Categories/CategoriesListEditor';
import CategoriesListEditor from './Categories/CategoriesListEditor';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.main_category';

const MainCategoryStep = props => {
  const editorRef = React.createRef<Editor>();
  const [categories, setCategories] = useState<Category[]>([]);
  const {
    stores: {categoryStore},
  } = useContext(AppContext);

  useEffect(() => {
    const fetchCats = async () => {
      await categoryStore.fetchCategories();
    };

    fetchCats().then(() => {
      setCategories(categoryStore.categories || []);
    });
  }, [categoryStore]);

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={5}
      fieldName="main_category"
      editorRef={editorRef}
      clearNext
      {...props}
    >
      {categories.length > 0 ? (
        <CategoriesListEditor ref={editorRef} categories={categories} />
      ) : (
        <Loader />
      )}
    </EditorStep>
  );
};

export default MainCategoryStep;
