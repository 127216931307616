import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Text} from '@yourcoach/shared/uikit/Text';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NoResultsContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: sticky;
  top: 0;
  background-color: ${getColor('background1')};
  z-index: 2;
`;

export const SectionTitle = styled(Text).attrs({as: 'p'})`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${getColor('text3')};
  margin-right: 10px;
`;

export const SectionBadge = styled.div`
  background: ${getColor('background2')};
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 12px;
  color: ${getColor('text4')};
`;

export const SectionContent = styled.div`
  padding-bottom: 25px;
`;
