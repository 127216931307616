import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {
  Questionnaire as IQuestionnaire,
  QuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';

import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import {
  LongText,
  MultipleChoice,
  MultipleChoiceOpen,
  SingleChoice,
  SingleChoiceOpen,
} from '../TypesInputTemplates';

import styles from './styles.module.css';

interface Props {
  quest: IQuestionnaire | null | undefined;
}

const Questions: FC<Props> = ({quest}) => {
  const sections = quest?.sections.slice();
  const localStore = useRef(
    observable(
      {
        activeSection: 0,
        setActiveSection(sectionId: number) {
          this.activeSection = sectionId;
        },
        get activeSectionObj() {
          return sections ? sections[localStore.activeSection] : [];
        },
      },
      {
        activeSection: observable,
        setActiveSection: action,
        activeSectionObj: computed,
      },
    ),
  ).current;

  const handleSidebarMenuClick = (idSection: number) => {
    return () => {
      localStore.setActiveSection(idSection);
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`Questions ${styles.Questions}`}>
          <div className={styles.questionsContainer}>
            <div className={styles.sidebar}>
              <ul>
                {sections?.map(({title}, index) => (
                  <li
                    key={index}
                    className={
                      localStore.activeSection === index
                        ? styles.activeMenu
                        : ''
                    }
                    onClick={handleSidebarMenuClick(index)}
                  >
                    <div className={styles.menuItemContainer}>
                      <div className={styles.indexSection}>{`Section ${
                        index + 1
                      }`}</div>
                      <div className={styles.nameSection}>{title}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.body}>
              <div className={styles.title}>
                {localStore.activeSectionObj.title}
              </div>
              <div className={styles.description}>
                {localStore.activeSectionObj.description}
              </div>
              <div className={styles.questions}>
                {localStore.activeSectionObj.questions &&
                  (
                    localStore.activeSectionObj as QuestionnaireSection
                  ).questions.map((questionItem, index) => {
                    switch (questionItem.type) {
                      case ALIAS_TYPES.text:
                        return (
                          <div key={index}>
                            <LongText question={questionItem} />
                          </div>
                        );
                      case ALIAS_TYPES.singleChoice:
                        return (
                          <div key={index}>
                            <SingleChoice question={questionItem} />
                          </div>
                        );
                      case ALIAS_TYPES.singleChoiceOpen:
                        return (
                          <div key={index}>
                            <SingleChoiceOpen question={questionItem} />
                          </div>
                        );
                      case ALIAS_TYPES.multipleChoice:
                        return (
                          <div key={index}>
                            <MultipleChoice question={questionItem} />
                          </div>
                        );
                      case ALIAS_TYPES.multipleChoiceOpen:
                        return (
                          <div key={index}>
                            <MultipleChoiceOpen question={questionItem} />
                          </div>
                        );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Questions);
