import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, observable, reaction, toJS} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';

import {labelMyProfile} from '@src/common/i18n/i18nProfile';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import AppContext from '@src/context/App';

import MyProfileImg from '../../components/UserProfileImgDecor/UserProfileImgDecor';
import UserName from '../UserName/UserName';
import {EditProfile} from '..';

import styles from './styles.module.css';

interface Props {}

interface ILoacalStore {
  showModal: boolean;
  toggleVisModal(isShow: boolean): void;
  originalAvatar: IFile | null | undefined;
  setOriginalAvatar(originalAvatar: IFile | null | undefined): void;
  isFirstLoadAvatar: boolean;
  setIsFirstLoadAvatar(isFirstLoadAvatar: boolean): void;
}

const MyProfile: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();
  const localStore = useRef(
    observable<ILoacalStore>(
      {
        showModal: false,
        toggleVisModal(isShow: boolean) {
          this.showModal = isShow;
        },
        originalAvatar: null,
        setOriginalAvatar(originalAvatar: IFile | null | undefined) {
          this.originalAvatar = originalAvatar;
        },
        isFirstLoadAvatar: true,
        setIsFirstLoadAvatar(isFirstLoadAvatar: boolean) {
          this.isFirstLoadAvatar = isFirstLoadAvatar;
        },
      },
      {
        showModal: observable,
        toggleVisModal: action,
        originalAvatar: observable,
        setOriginalAvatar: action,
        isFirstLoadAvatar: observable,
        setIsFirstLoadAvatar: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setOriginalAvatar(toJS(currentUserStore.user?.avatar));

    const disposeUserAvatar = reaction(
      () => currentUserStore.user?.avatar,
      avatar => {
        if (localStore.isFirstLoadAvatar) {
          localStore.setOriginalAvatar(toJS(avatar));
          localStore.setIsFirstLoadAvatar(false);
        }
      },
    );

    return () => {
      disposeUserAvatar();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    history.push({
      pathname: '/profile/user-profile-tab',
    });
  };

  const closeModal = async () => {
    await currentUserStore.localUpdate({
      avatar: localStore.originalAvatar,
    });
    localStore.toggleVisModal(false);
  };

  const closeModalToSave = () => {
    localStore.setOriginalAvatar(toJS(currentUserStore.user?.avatar));
    localStore.toggleVisModal(false);
  };

  return (
    <Observer>
      {() => (
        <div className={`MyProfile ${styles.MyProfile}`}>
          <h3>{labelMyProfile()}</h3>
          <div className={styles.ProfileContainer}>
            <div>
              <MyProfileImg />
            </div>
            <div>
              <div className={styles.UserNameContainer}>
                <UserName className={styles.UserName} />
                <button
                  onClick={handleOnClick}
                  className={`roundGreyButton ${styles.ButRedactProfile}`}
                >
                  {labelMyProfile()}
                </button>
              </div>
            </div>
          </div>
          <ModalAnimateWin
            showModal={localStore.showModal}
            className="h90vh"
            closeModalHandler={closeModal}
          >
            <EditProfile endEditCallBack={closeModalToSave} />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(MyProfile);
