import React, {useCallback} from 'react';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';

import {t} from '@src/i18n';

import * as S from './styles';
import type {Props} from './types';

export const ListItem: React.FC<Props> = ({item, onClick, ...rest}) => {
  const onClickCb = useCallback(() => {
    onClick?.(item);
  }, [item, onClick]);

  return (
    <S.Container {...rest} onClick={onClickCb}>
      <S.ProgramContainer>
        <S.ProgramAvatar
          src={getFileSrc(item.program.avatar, 250).url || ''}
          resizeMode="cover"
          placeholder={<AvatarPlaceholder text={item.program.title} />}
        />
        <div>
          <S.Title>{item.program.title}</S.Title>
          {item.client_id ? (
            <S.ClientName>{item.client?.name}</S.ClientName>
          ) : null}
        </div>
      </S.ProgramContainer>
      <S.MiddleContainer>
        <S.CourseDates>
          {getCourseDurationString(item) ||
            t(['label', 'course_no_start_date', 'coach'])}
        </S.CourseDates>
      </S.MiddleContainer>
      <S.RightContainer>
        <S.Counters>
          {`${item.counters.memberships.accepted} / ${
            item.edition?.group_size || 0
          }`}
        </S.Counters>
        <S.NextArrow />
      </S.RightContainer>
    </S.Container>
  );
};
