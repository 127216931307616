import type {FC} from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import type {DateTimeObj} from '@yourcoach/shared/api';
import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {ApplyCodeStore} from '@yourcoach/shared/stores/auth/ApplyCode';
import {CodeInput} from '@yourcoach/shared/uikit/CodeInput';
import batchedUpdates from '@yourcoach/shared/utils/batchedUpdates';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';
import {logger} from '@yourcoach/shared/utils/logger';
import storage from '@yourcoach/shared/utils/storage';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {browser} from '@src/common/browserType';
import {useIsAuth} from '@src/common/useUserMethods';
import Button from '@src/components/Button';
import SpinnerOverlay from '@src/components/SpinnerOverlay';
import {ACCOUNT_STORAGE_KEY, DEFAULT_USER_NAME} from '@src/config';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import MailSVG from '../images/mail.svg';
import PhoneSVG from '../images/phone.svg';

import type {Form} from './FormStep';
import FormStep from './FormStep';
import {ButtonsContainer, Description, Title} from './LeftPaneStep';
import styles from './styles.module.css';

interface Props {
  onClick?: (event) => void;
  onChange?: (value) => void;
  onData?: (value, fieldName) => void;
  value?: string;
  usePhoneIcon?: boolean;
  login?: boolean;
  data?: {
    phone?: string;
    email?: string;
    codeExpires?: DateTimeObj;
  };
  code?: string;
}

const I18N_SCOPE = 'Login.verification';

const VerificationStep: FC<Props> = ({
  onClick,
  onChange,
  onData,
  usePhoneIcon,
  login,
  data,
  code,
}) => {
  const history = useHistory();
  const location = useLocation<any>();

  const setLocalStoreAppIsAuth = useIsAuth();
  const field = useRef(
    createHtmlInputField('code', {
      defaultValue: code || '',
      validationRule: {
        type: 'string',
        messages: {
          required: t('shared.validation.code_required'),
        },
      },
    }),
  ).current;

  const formRef = useRef<Form>(null);

  const secsUntil = useCallback((date: DateTimeObj) => {
    return dayjs(datetimeObjToISOString(date)).diff(dayjs(new Date()), 's');
  }, []);

  useEffect(() => {
    const readAccount = async () => {
      try {
        const accountStr = await storage.getItem(ACCOUNT_STORAGE_KEY);

        if (code && !accountStr) {
          history.replace('login');

          return;
        }

        if (accountStr) {
          const account = JSON.parse(accountStr);

          onData?.(account.value, account.type);
          onData?.(secsUntil(account.codeExpires), 'codeExpires');

          batchedUpdates(() => {
            setCodeExpires(account.codeExpires);
            setRemained(secsUntil(account.codeExpires));
          });

          if (code && code.length === ApplyCodeStore.CODE_SIZE) {
            formRef.current?.submit();
          }
        }
      } catch (error) {
        // do nothing
      }
    };

    readAccount();
  }, [code, history, onData, secsUntil]);

  useEffect(() => {
    const onStorageChange = (e: StorageEvent) => {
      /**
       * enter to the app on other windows(tabs) which wait while user enter the code
       */
      if (e.isTrusted && e.key === 'session' && !e.oldValue && e.newValue) {
        window.location.reload();
      }
    };

    window.addEventListener('storage', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);
  const [isRequest, setIsRequest] = useState(false);

  const onSubmit = useCallback(
    fieldValue => {
      const params = {
        phone: data?.phone || undefined,
        email: data?.email || undefined,
        agent: browser(),
        code: fieldValue,
        name: DEFAULT_USER_NAME,
      };

      setIsRequest(true);

      return (login ? authStore.login : authStore.signup)(params).finally(
        () => {
          setIsRequest(false);

          storage.removeItem(ACCOUNT_STORAGE_KEY);
        },
      );
    },
    [authStore, data?.email, data?.phone, login],
  );

  const onApiResponse = useCallback(
    response => {
      if (!login) {
        currentUserStore.update({
          metadata: {
            ...currentUserStore.user!.metadata,
            onboarding: {
              account_type: null,
              is_finished: false,
              reserved_coupon: null,
              invites_count: response.invites_count,
            },
          },
        });

        const invitesCount =
          currentUserStore.user?.metadata?.onboarding?.invites_count;

        onData &&
          onData(invitesCount || response.invites_count, 'invitesCount');
      }
    },
    [currentUserStore, login, onData],
  );

  const onNextClick = useCallback(
    event => {
      if (login) {
        setLocalStoreAppIsAuth();
        history.replace(location.state?.from.pathname || '/');
      } else {
        onClick && onClick(event);
      }
    },
    [history, location, login, onClick, setLocalStoreAppIsAuth],
  );

  const [codeExpires, setCodeExpires] = useState(data!.codeExpires!);
  const [remained, setRemained] = useState(secsUntil(codeExpires));

  useEffect(() => {
    const timerId = setInterval(() => {
      const secsRemained = secsUntil(codeExpires);

      setRemained(secsRemained);

      if (secsRemained <= 0) {
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [codeExpires, remained, secsUntil]);

  const onResendClick = useCallback(async () => {
    try {
      const sendCodeResult = await authStore.sendCode({
        phone: data?.phone || undefined,
        email: data?.email || undefined,
        toWebsite: true,
      });

      setCodeExpires(sendCodeResult.expires);
    } catch (error) {
      logger.error(error);
    }
  }, [authStore, data?.email, data?.phone]);

  const onCodeInputChange: GetComponentProps<typeof CodeInput>['onChange'] =
    useCallback(
      e => {
        const newCode = e.payload!.code;

        field.setValue(newCode);

        if (newCode.length === ApplyCodeStore.CODE_SIZE) {
          formRef.current?.submit();
        }
      },
      [field, formRef],
    );

  return (
    <FormStep
      ref={formRef}
      icon={usePhoneIcon ? <PhoneSVG /> : <MailSVG />}
      field={field}
      onSubmit={onSubmit}
      onChange={onChange}
      onResponse={onApiResponse}
      onClick={onNextClick}
    >
      <Title>{t([I18N_SCOPE, 'title_code'])}</Title>
      <Description>
        {data?.email
          ? t([I18N_SCOPE, 'code_from_email_label'], {email: data?.email})
          : t([I18N_SCOPE, 'code_from_phone_label'], {phone: data?.phone})}
      </Description>
      <Observer>
        {() => (
          <div className={styles.codeInputContainer}>
            <CodeInput
              size={64}
              value={field.value}
              spacing={10}
              onChange={onCodeInputChange}
              length={ApplyCodeStore.CODE_SIZE}
              disabled={isRequest}
            />
          </div>
        )}
      </Observer>
      {remained ? (
        <div className={styles.sendDelay}>
          {t([I18N_SCOPE, 'resend_label'], {seconds: remained})}
        </div>
      ) : (
        <a className={styles.sendDelay} href="#" onClick={onResendClick}>
          {t([I18N_SCOPE, 'resend_button'])}
        </a>
      )}
      <ButtonsContainer>
        <Button className={styles.inverted} onClick={onClick}>
          {t('Common.Back')}
        </Button>
        <SpinnerOverlay isActive={isRequest}>
          <Button data-action="next" className={styles.middle}>
            {isRequest ? <>&nbsp;</> : t('Common.Submit')}
          </Button>
        </SpinnerOverlay>
      </ButtonsContainer>
    </FormStep>
  );
};

export default VerificationStep;
