import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Observer} from 'mobx-react';

import styles from './styles.module.css';

interface Props {
  children: ReactNode;
  numMess?: number;
  className?: string;
  badgeClassName?: string;
  isVisible?: boolean;
}

const BadgeContainer: FC<Props> = ({
  children,
  numMess = 0,
  className = '',
  badgeClassName = '',
  isVisible = true,
}) => {
  return (
    <Observer>
      {() => (
        <div className={`BadgeContainer ${styles.BadgeContainer} ${className}`}>
          {children}
          <CSSTransition
            in={numMess > 0 && isVisible}
            unmountOnExit
            timeout={300}
            classNames={{
              appear: '',
              appearActive: '',
              appearDone: '',
              enter: 'animate__bounceIn',
              enterActive: 'animate__bounceIn',
              enterDone: 'animate__bounceIn',
              exit: 'animate__bounceOut',
              exitActive: 'animate__bounceOut',
              exitDone: 'animate__bounceOut',
            }}
          >
            <div className={`${styles.numNotif} ${badgeClassName}`}>
              {numMess < 99 ? numMess : '99+'}
            </div>
          </CSSTransition>
        </div>
      )}
    </Observer>
  );
};

export default memo(BadgeContainer);
