import type {ChangeEvent, FC} from 'react';
import React, {memo, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {CustomInput} from '@src/components/CustomForm';
import IconSearch from '@src/components/icons/IconSearch/IconSearch';

interface Props {
  className?: string;
  classContainer?: string;
  classIcon?: string;
  classIconContainer?: string;
  classInput?: string;
  label?: string;
  id?: string;
  onSearch?: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

interface ILocalStore {
  valSearch: string;
  updateValSearch(newVal: string): Promise<void>;
}

const CustomSearch: FC<Props> = ({
  className = '',
  classContainer = '',
  classIcon = '',
  classIconContainer = '',
  classInput = '',
  label = '',
  id = 'search',
  onChange,
  onSearch,
  value = '',
}) => {
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          valSearch: value,
          async updateValSearch(newVal: string) {
            this.valSearch = newVal;
          },
        },
        {
          valSearch: observable,
          updateValSearch: action,
        },
      ),
    [value],
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    localStore.updateValSearch(e.currentTarget.value);
    onChange(e);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (onSearch) {
        onSearch();
      }
    }
  };

  const handleClickIconContainer = () => {
    if (onSearch) {
      onSearch();
    }
  };

  return (
    <Observer>
      {() => (
        <div className={classContainer}>
          <div className={`SearchFile ${className}`}>
            <CustomInput
              type="input"
              label={label}
              customClassLabel="standardLabel"
              customClassInput={`standardInput border ${classInput}`}
              onKeyPress={handleKeyPress}
              id={id}
              value={localStore.valSearch}
              onChange={handleOnChange}
            />
            <div
              className={classIconContainer}
              onClick={handleClickIconContainer}
            >
              <IconSearch customClass={classIcon} />
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(CustomSearch);
