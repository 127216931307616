import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';
import {Observer} from 'mobx-react';

import type {Category} from '@yourcoach/shared/api/category';
import type {
  PostAttachment as PostAttachmentT,
  UserAttachment,
} from '@yourcoach/shared/api/channel/post';
import {
  isAudioAttachment,
  isFileAttachment,
  isImageAttachment,
  isQuestionnaireAttachment,
  isUserAttachment,
} from '@yourcoach/shared/api/channel/post';
import type {Conference} from '@yourcoach/shared/api/conference';
import type {Material} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import Checkbox1Icon from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import DocIcon from '@yourcoach/shared/assets/icons/doc.svg';

import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Image from '@src/components/Image';
import AppContext from '@src/context/App';
import i18n from '@src/i18n';
import type {Expanded as ExpandedMaterial} from '@src/models/materials';
import {
  getMaterialFile,
  getMaterialIcon,
  materialIsFolder,
} from '@src/models/materials';

import QuestionnairePostAttachment from '../QuestionnairePostAttachment/QuestionnairePostAttachment';
import VoiceMessage from '../VoiceMessage/VoiceMessage';

import styles from './../../styles.module.scss';

interface Props {
  attachment: PostAttachmentT;
  showUserName?: boolean;
  isMy: boolean | null;
  postId: string;
  isAdminPost?: boolean;
  onPress: (attachment: PostAttachmentT) => void;
  onLongPress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostAttachment: FC<Props> = ({
  onPress,
  postId,
  attachment,
  onLongPress,
  isAdminPost,
  isMy,
}) => {
  const {
    stores: {categoryStore},
  } = useContext(AppContext);

  const {
    title,
    description,
    Icon,
    preview,
    buttonText,
    linkUrl,
    isAudio,
    callToAction,
  } = useMemo(() => {
    let attachmentType = (attachment._id || '').split(':')[0];

    if (!attachmentType && isFileAttachment(attachment)) {
      attachmentType = 'file';
    }

    const result: {
      title: string;
      description: string;
      Icon: JSX.Element;
      preview: string | null | undefined;
      buttonText: string | undefined;
      isAudio: boolean;
      linkUrl: string | undefined;
      callToAction?: string;
    } = {
      title: (attachment as Material).title,
      description: (attachment as Material).description,
      Icon: <DocIcon />,
      preview: '',
      buttonText: '',
      isAudio: !!isAudioAttachment(attachment),
      linkUrl: undefined,
    };

    if (materialIsFolder(attachment as Material)) {
      const IconMaterial = getMaterialIcon(
        attachment as Material & ExpandedMaterial,
      );

      result.Icon = <IconMaterial />;
    } else {
      let _attachment = attachment;

      if (attachmentType === 'material') {
        _attachment = getMaterialFile(
          attachment as Material & ExpandedMaterial,
        )!;

        if (_attachment) {
          [attachmentType] = _attachment._id.split(':');
        }
      }

      if (attachmentType === 'file') {
        const IconFle = getFileIcon(_attachment as IFile);

        result.Icon = <IconFle />;
        result.title = getFileName(_attachment as IFile);

        // @ts-ignore
        const {url, size, urlrepr} = getFileSrc(_attachment);

        result.description = i18n.toHumanSize(size);

        if (isImageAttachment(_attachment)) {
          result.preview = url;
          result.linkUrl = url || undefined;
        } else if ((_attachment as IFile).type === 'url') {
          result.preview = getFileSrc(_attachment as IFile, 'image').url;
          result.description = urlrepr.replace(/^((ht|f)tp(s?):\/\/)/, '');
          result.linkUrl = urlrepr;
        }
      }
    }

    if (isUserAttachment(attachment)) {
      if ((attachment as UserAttachment).coach_categories) {
        result.description = (attachment as UserAttachment)
          .coach_categories!.map(
            item =>
              categoryStore.categoryLookup.get(item.category_id) as Category,
          )
          .filter(Boolean)
          .sort((a, b) => b!.weight - a!.weight)
          .slice(0, 2)
          .map(item => item.title)
          .join(', ');
      }

      result.title = (attachment as UserAttachment).name;
      result.preview = getFileSrc(
        (attachment as UserAttachment).avatar,
        250,
      ).url;
    }

    if (attachmentType === 'conference') {
      result.Icon = <CameraIcon />;

      if ((attachment as Conference).status === 'ongoing') {
        result.callToAction = 'Join';
      }
    } else if (attachmentType === 'task') {
      result.Icon = <Checkbox1Icon />;
    }

    return result;
  }, [attachment, categoryStore.categoryLookup]);

  const onPressCb = useCallback(() => {
    if (isAudio) {
      return;
    }

    onPress && onPress(attachment);
  }, [attachment, isAudio, onPress]);

  if (isQuestionnaireAttachment(attachment)) {
    return (
      <QuestionnairePostAttachment
        /* @ts-ignore */
        attachment={attachment as PostAttachmentT}
        postId={postId}
        onPress={onPressCb}
        forCoach={!!(isAdminPost && isMy)}
      />
    );
  }

  return (
    <Observer>
      {() => (
        <div
          className={`PostAttachment ${styles.PostAttachment}`}
          onClick={onPressCb}
          onContextMenu={onLongPress}
        >
          <div className={styles.top}>
            {!isAudio ? (
              <>
                {isUserAttachment(attachment) ? (
                  <div className={styles.userAvatar}>
                    <Image
                      src={
                        getFileSrc((attachment as UserAttachment).avatar, 250)
                          .url || ''
                      }
                      placeholder={
                        <AvatarPlaceholder
                          name={(attachment as UserAttachment).name}
                        />
                      }
                    />
                  </div>
                ) : (
                  <div className={styles.imageContainer}>
                    <div className={styles.ico}>{Icon}</div>
                    {preview ? (
                      <div className={styles.image}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={linkUrl}
                        >
                          <Image
                            src={linkUrl || ''}
                            placeholder={<AvatarLoader />}
                          />
                        </a>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            ) : null}
            {isAudio ? (
              <VoiceMessage attachment={attachment as IFile} />
            ) : (
              <>
                <div className={styles.contentContainer}>
                  <div className={styles.name}>{!linkUrl ? title : null}</div>
                  {description && !linkUrl ? (
                    <div className={styles.description}>{description}</div>
                  ) : null}
                  {callToAction ? (
                    <div className={styles.callToAction}>{callToAction}</div>
                  ) : null}
                </div>
              </>
            )}
          </div>
          {buttonText ? (
            <div className={styles.button}>
              <div className={styles.buttonText}>{buttonText}</div>
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(PostAttachment);
