import type {FC} from 'react';
import React, {memo, useMemo} from 'react';

import classNames from 'classnames';

interface Props {
  value: string;
  steps: {[key: number]: string};
  className?: string;
}

const SizeFitText: FC<Props> = ({steps, value, className = ''}) => {
  const sizeClass = useMemo(() => {
    const keys = Object.keys(steps as Object)
      .map(x => Number.parseInt(x, 10))
      .filter(x => x < value.length);

    if (keys.length > 0) {
      return steps[Math.max(...keys)];
    }

    return '';
  }, [value, steps]);

  return <p className={classNames(className, sizeClass)}>{value}</p>;
};

export default memo(SizeFitText);
