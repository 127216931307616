import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {}

const MyPurchaseHistory: FC<Props> = () => {
  return <div className="MyPurchaseHistory">MyPurchaseHistory</div>;
};

export default memo(MyPurchaseHistory);
