import React from 'react';

import styled from 'styled-components';

import type {ThemeColor} from '@yourcoach/shared/styles/theme';
import {getColor} from '@yourcoach/shared/styles/utils';
import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: ${getColor('background2')};
`;

const Title = styled.span<{color: ThemeColor}>`
  color: ${p => getColor(p.color)(p)};
  font-size: 60px;
`;

export const PageLoading: React.FC = React.memo(() => (
  <Container>
    <View style={{gap: 12}}>
      <Title color={'text3'}>Welcome to the Health Coaching</Title>
      <Title color={'global1'}>Revolution</Title>
    </View>
    <LoadingSpinner size={300} />
  </Container>
));
