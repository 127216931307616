import type {FC} from 'react';
import React, {memo} from 'react';

import type {CollectionStore} from '@yourcoach/shared/api';
import type {Certificate} from '@yourcoach/shared/api/certificate';

import {
  labelCertificates,
  labelCertificatesAddPlus,
} from '@src/common/i18n/i18nProfile';
import CertificatesListManager from '@src/modules/Certificates/CertificatesListManager';

import styles from '../styles.module.css';

interface Props {
  store?: CollectionStore<Certificate>;
}

const Certificates: FC<Props> = ({store}) => {
  return (
    <div className={`Certificates ${styles.Certificates}`}>
      <h4>{labelCertificates()}</h4>
      <br />
      <CertificatesListManager store={store}>
        <div className={styles.addItemBox}>{labelCertificatesAddPlus()}</div>
      </CertificatesListManager>
      <br />
    </div>
  );
};

export default memo(Certificates);
