import type {Nullable} from '@yourcoach/shared/utils/utility-types';

export function openImageInANewTab(url?: Nullable<string>) {
  let link: Nullable<HTMLAnchorElement>;

  link = document.createElement('a');

  if (link && url) {
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  link = null;
}
