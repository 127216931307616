import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import type {SubgoalColor} from '@yourcoach/shared/api/goal';
import {
  DEFAULT_SUBGOAL_COLOR,
  getSubgoalColor,
  SUBGOAL_COLORS,
} from '@yourcoach/shared/api/goal';
import {themes} from '@yourcoach/shared/styles/theme';

import {CustomButton} from '@src/components/CustomForm';
import {IconCheck} from '@src/components/icons';
import {t} from '@src/i18n';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'SelectGoalColor';

interface ILocalStore {
  colors: SubgoalColor[];
  color: SubgoalColor;
  _onColorPress(colorVal: SubgoalColor): void;
}

interface Props {
  color?: SubgoalColor;
  onSelect?: (color: SubgoalColor) => void;
}

const SelectGoalColor: FC<Props> = ({color, onSelect}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        colors: [],
        color: DEFAULT_SUBGOAL_COLOR,
        _onColorPress(colorVal: SubgoalColor) {
          this.color = colorVal;
        },
      },
      {
        colors: observable.shallow,
        color: observable,
        _onColorPress: action,
      },
    ),
  ).current;

  useEffect(() => {
    if (color) {
      localStore.color = color;
    }

    _prepareColors();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _prepareColors = () => {
    let data: SubgoalColor[] = SUBGOAL_COLORS.slice();

    runInAction(() => {
      localStore.colors = data;
    });
  };

  const _onSetButtonPress = () => {
    if (onSelect) {
      onSelect(localStore.color);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`SelectGoalColor ${styles.SelectGoalColor}`}>
          <div className={styles.container}>
            {localStore.colors.map(item => {
              const colorSelect = getSubgoalColor(item, themes.light);

              const isSelected = item === localStore.color;

              return (
                <div
                  key={item!}
                  onClick={() => localStore._onColorPress(item)}
                  className={`${styles.item} ${
                    isSelected ? styles.active : ''
                  }`}
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    background: colorSelect,
                    boxShadow: isSelected
                      ? `0 0 0 3px #fff, 0 0 0 4px ${colorSelect}`
                      : 'initial',
                  }}
                >
                  {isSelected ? (
                    <div className={styles.selectedItem}>
                      <IconCheck fill={themes.light.color.icon2} />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className={styles.createButContainer}>
            <CustomButton
              type="button"
              classButton={`blueButt ${styles.createBut}`}
              onClick={_onSetButtonPress}
            >
              <span>{t([I18N_SCOPE, 'set_button'])}</span>
            </CustomButton>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SelectGoalColor);
