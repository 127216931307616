import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const IconGroupFill: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'fill',
}) => {
  return (
    <div className={`IconGroupFill ${className}`}>
      <svg
        height={height}
        width={width}
        viewBox="0 0 17 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5909 4.28571C12.8736 4.28571 13.9014 3.32857 13.9014 2.14286C13.9014 0.957143 12.8736 0 11.5909 0C10.3082 0 9.27273 0.957143 9.27273 2.14286C9.27273 3.32857 10.3082 4.28571 11.5909 4.28571ZM5.40909 4.28571C6.69182 4.28571 7.71954 3.32857 7.71954 2.14286C7.71954 0.957143 6.69182 0 5.40909 0C4.12636 0 3.09091 0.957143 3.09091 2.14286C3.09091 3.32857 4.12636 4.28571 5.40909 4.28571ZM5.40909 5.71429C3.60864 5.71429 0 6.55 0 8.21429V9.28571C0 9.67857 0.347727 10 0.772727 10H10.0455C10.4705 10 10.8182 9.67857 10.8182 9.28571V8.21429C10.8182 6.55 7.20955 5.71429 5.40909 5.71429ZM11.5909 5.71429C11.3668 5.71429 11.1118 5.72857 10.8414 5.75C10.8568 5.75714 10.8645 5.77143 10.8723 5.77857C11.7532 6.37143 12.3636 7.16429 12.3636 8.21429V9.28571C12.3636 9.53571 12.3095 9.77857 12.2245 10H16.2273C16.6523 10 17 9.67857 17 9.28571V8.21429C17 6.55 13.3914 5.71429 11.5909 5.71429Z"
          fill="white"
          fillOpacity="0.7"
        />
      </svg>
    </div>
  );
};

export default memo(IconGroupFill);
