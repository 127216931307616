import {v4 as uuidv4} from 'uuid';

import type {Course} from '@yourcoach/shared/api/course';
import type {Material} from '@yourcoach/shared/api/material';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileIcon} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';
import {linkStore} from '@yourcoach/shared/api/media/link';
import type {Edition} from '@yourcoach/shared/api/program';
import {currentUserStore} from '@yourcoach/shared/api/user';
import FolderIcon from '@yourcoach/shared/assets/icons/folder.svg';

import type {Expanded as CourseExpanded} from '@src/modules/courses/utils';
import {expand as courseExpand} from '@src/modules/courses/utils';

export const expand = {
  material: [['course_id', null, courseExpand], 'file_ids'],
};

export interface Expanded {
  course?: (Course & CourseExpanded) | null;
  files: (IFile | null)[];
}

export type ScheduleMaterial = Edition['materials'][0] & {
  files: (IFile | null)[];
};

// TODO: Move to shared
export const materialIsFolder = (material: Material | ScheduleMaterial) =>
  (material.file_ids || []).length > 1;

// TODO: Move to shared
export const getMaterialFile = (
  material: (Material & Expanded) | ScheduleMaterial,
) => (material.files || [])[0];

// TODO: Move to shared
export const getMaterialIcon = (
  material: (Material & Expanded) | ScheduleMaterial,
) =>
  materialIsFolder(material)
    ? FolderIcon
    : getFileIcon(getMaterialFile(material));

// TODO: Move to shared
export const linksToMaterials = (links: (Link & {resource: IFile})[]) => {
  const promises: Promise<
    {emptyFolder: boolean} | ScheduleMaterial | (Error & {isError: boolean})
  >[] = [];

  links.forEach(link => {
    if (link.type === 'folder') {
      const unfoldPromise = linkStore
        .unfold(link, {
          query: [['type', '==', 'link']],
          expand: {
            link: ['resource_id'],
          },
        })
        .then(
          // @ts-ignore
          (result: {
            _items: (Link & {resource: IFile})[];
            _expanded: {[key: string]: IFile};
          }) => {
            if (!result._items.length) {
              return Promise.resolve({
                emptyFolder: true,
              });
            }

            return Promise.resolve({
              uuid: uuidv4().replace(/-/g, ''),
              title:
                result._items.length === 1 ? result._items[0].name : link.name,
              file_ids: result._items.map(item => item.resource_id),
              files: result._items.map(item =>
                item.resource_id ? result._expanded[item.resource_id] : null,
              ),
              day: 0,
              time: 0,
              description: '',
              coach_id: currentUserStore.user ? currentUserStore.user._id : '',
            });
          },
        )
        .catch((e: Error) => ({
          ...e,
          isError: true,
        }));

      promises.push(unfoldPromise);
    } else {
      const promise = Promise.resolve({
        uuid: uuidv4().replace(/-/g, ''),
        title: link.name,
        file_ids: link.resource_id ? [link.resource_id] : [],
        files: [link.resource],
        day: 0,
        time: 0,
        description: '',
        coach_id: currentUserStore.user ? currentUserStore.user._id : '',
      });

      promises.push(promise);
    }
  });

  return promises;
};
