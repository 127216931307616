import React, {useCallback} from 'react';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';

import type {Link} from '@yourcoach/shared/api/media/link';
import FolderPrimaryIcon from '@yourcoach/shared/assets/icons/folder-primary.svg';
import FolderSecondaryIcon from '@yourcoach/shared/assets/icons/folder-secondary.svg';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';
import StarIcon from '@yourcoach/shared/assets/icons/star.svg';

import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';

import styles from './FolderCard.module.css';

type Folder = Link;

export interface Props<T> {
  folder: T;
  isSystem?: boolean;
  isStarred?: boolean;
  isPartiallySelected?: boolean;
  className?: string;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onClick?: (folder: T) => void;
  onMoreButtonClick?: (
    folder: T,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
  onCheckboxClick?: (folder: T) => void;
}

function FolderCard<T extends Folder = Folder>(
  props: Props<T>,
): React.ReactElement {
  const {
    folder,
    isSystem,
    isStarred,
    className,
    isSelectionMode,
    isSelected,
    onClick,
    onMoreButtonClick,
    onCheckboxClick,
  } = props;

  const onClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onClick && onClick(folder);
    },
    [folder, onClick],
  );

  const onMoreButtonClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(folder, e);
    },
    [folder, onMoreButtonClick],
  );

  const onCheckboxClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onCheckboxClick && onCheckboxClick(folder);
    },
    [onCheckboxClick, folder],
  );

  return (
    <div
      className={classNames(
        styles.container,
        isSystem && styles.system,
        className,
      )}
    >
      <div className={styles.content} onClick={onClickCb}>
        <div className={styles.iconContainer}>
          {isSystem ? <FolderPrimaryIcon /> : <FolderSecondaryIcon />}
          {isStarred ? <StarIcon className={styles.starIcon} /> : null}
        </div>
        <div className={styles.dataContainer}>
          <p className={styles.name}>{folder.name}</p>
        </div>
      </div>
      {isSelectionMode && !isSystem && !onMoreButtonClick ? (
        <div className={styles.checkboxContainer} onClick={onCheckboxClickCb}>
          <Checkbox
            checked={isSelected}
            // TODO: Add round and dot checkbox varian
            // variant={isPartiallySelected ? 'dot' : 'round'}
          />
        </div>
      ) : null}
      {onMoreButtonClick && !isSelectionMode ? (
        <Button onClick={onMoreButtonClickCb} className={styles.moreButton}>
          <MoreIcon />
        </Button>
      ) : null}
    </div>
  );
}

export default React.memo(FolderCard, isEqual) as typeof FolderCard;
