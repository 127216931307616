import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {getCourseDurationString} from '@yourcoach/shared/api/course/utils';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import {currentUserStore} from '@yourcoach/shared/api/user';
import NoteIcon from '@yourcoach/shared/assets/icons/secondary/Edit.svg';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {truncateStrByWord} from '@yourcoach/shared/utils/format';

import {t} from '@src/i18n';

import {PathBuilderService} from '../../../v2/services/PathBuilderService';

import * as S from './styles';
import type {Props} from './types';

export const SessionNotesListItem: React.FC<Props> = ({
  item,
  onNoteButtonClick,
  showCourseInfo = true,
  ...rest
}) => {
  const history = useHistory();

  const onProgramSectionClick = useCallback(() => {
    const practiceSlug = currentUserStore.user?.slug;
    const userId = currentUserStore.user?._id;
    const programSlug = item.program?.slug;
    const programId = item.program_id;
    const courseId = item.course_id;

    history.push(
      PathBuilderService.toCourse(
        {slug: practiceSlug, id: userId as string},
        {slug: programSlug, id: programId},
        courseId as string,
        programId,
      ),
    );
  }, [history, item.course_id, item.program?.slug, item.program_id]);

  const onNoteButtonClickCb = useCallback(() => {
    onNoteButtonClick?.(item);
  }, [item, onNoteButtonClick]);

  const findNotesCount = (coach_ids: string[]) => {
    return coach_ids
      .map(user => item.counters.session_notes[user])
      .reduce((a, b) => a + b, 0);
  };

  const notesCount = findNotesCount(item.counters.session_notes.coach_ids);

  return (
    <S.Container {...rest}>
      <S.UserContainer>
        <S.Avatar
          src={getFileSrc(item.user.avatar, 250).url || ''}
          resizeMode="cover"
          placeholder={<AvatarPlaceholder text={item.user.name} />}
        />
        <S.Title>{truncateStrByWord(item.user.name, 40)}</S.Title>
      </S.UserContainer>
      {showCourseInfo ? (
        <S.ProgramContainer>
          <Clickable onClick={onProgramSectionClick}>
            <S.ProgramTitle>{item.program.title}</S.ProgramTitle>
          </Clickable>
          <S.CourseDates>
            {getCourseDurationString(item.course) ||
              t(['label', 'course_no_start_date', 'coach'])}
          </S.CourseDates>
        </S.ProgramContainer>
      ) : null}
      <S.NoteButtonContainer>
        <S.NoteButton onClick={onNoteButtonClickCb}>
          {notesCount > 0 ? (
            <>
              <NoteIcon />
              {t(['shared.session_notes.see_notes_label'], {
                count: notesCount,
              })}
            </>
          ) : notesCount === 0 ? (
            <>
              <NoteIcon />
              {t(['shared.session_notes.add_note_button'], {
                count: 0,
              })}
            </>
          ) : (
            t(['shared.session_notes.see_notes_label'])
          )}
        </S.NoteButton>
      </S.NoteButtonContainer>
    </S.Container>
  );
};
