import {apiRequest} from '@yourcoach/shared/api';

import type {ChannelT} from '@src/modules/Chats/context/useChatsLocalStore';

const getActiveClientMembership = async () => {
  return apiRequest({
    method: 'coach.memberships.list',
    params: {
      sort: [['created', -1]],
      query: [['status', 'in', ['active']]],
      limit: 1000,
      expand: {
        membership: ['user_id'],
        user: ['subtenant_id'],
        subtenant: ['tenant_id'],
      },
    },
  });
};

const getArchivedClientMembership = async () => {
  return apiRequest({
    method: 'coach.memberships.list',
    params: {
      sort: [['created', -1]],
      query: [['status', 'in', ['archived']]],
      limit: 1000,
      expand: {
        membership: ['user_id'],
        user: ['subtenant_id'],
        subtenant: ['tenant_id'],
      },
    },
  });
};

const getCoachHistoryList = async (channel: ChannelT) => {
  return apiRequest({
    method: 'coach.coach_history.list',
    params: {
      query: [
        ['type', 'in', ['changed_by_coach', 'changed_by_user']],
        ['course_id', '==', channel.resource_id],
        ['status', '==', 'past'],
      ],
    },
  });
};

export {
  getActiveClientMembership,
  getArchivedClientMembership,
  getCoachHistoryList,
};
