import React, {useCallback, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import {createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import EmptyProgramsIcon from '@yourcoach/shared/assets/icons/mountain.svg';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import {t} from '@src/i18n';
import {AddSessionNoteCourseClientsModal} from '@src/modules/MyNotes/AddSessionNoteCourseClientsModal';

import * as S from './styles';
import type {Item, Props} from './types';

const I18N_SCOPE = 'AddSessionNote.by_program_tab';

export const ProgramsList: React.FC<Props> = ({type, active, ...rest}) => {
  const store = useRef(
    createCollectionStore<Item>({
      method: 'coach.courses.list',
      params: {
        sort: [['created', -1]],
        limit: 50,
        expand: {
          course: [
            'client_id',
            'edition_id',
            [
              'program_id',
              {title: 'Program not found'},
              {program: ['avatar_id']},
            ],
          ],
        },
      },
    }),
  ).current;

  useEffect(() => {
    if (active) {
      store.fetch(
        {
          query: [
            [
              'status',
              '==',
              (type === 'active' ? 'ongoing' : 'archived') as Course['status'],
            ],
          ],
        },
        {
          silent: store.isLoaded,
        },
      );
    }
  }, [active, store, type]);

  const onFetchMoreInViewChange = useCallback(
    isInView => {
      if (isInView && store.hasMore) {
        store.fetch(
          {
            offset: store.items.length,
          },
          {
            silent: true,
          },
        );
      }
    },
    [store],
  );

  const onItemClick: GetComponentProps<typeof S['ListItem']>['onClick'] =
    useCallback(item => {
      NiceModal.show(AddSessionNoteCourseClientsModal, {course: item});
    }, []);

  return (
    <S.Container {...rest} active={active}>
      <Observer>{() => (!store.isLoaded ? <Loader /> : null)}</Observer>
      <Observer>
        {() =>
          store.hasItems ? (
            <S.List customScrollbar>
              {store.items.map(item => (
                <S.ListItem item={item} key={item._id} onClick={onItemClick} />
              ))}
              {/* @ts-ignore */}
              <InView as="div" threshold={0} onChange={onFetchMoreInViewChange}>
                <Observer>
                  {() => (store.hasMore ? <Loader size={50} /> : null)}
                </Observer>
              </InView>
            </S.List>
          ) : null
        }
      </Observer>
      <Observer>
        {() =>
          store.isEmpty ? (
            <S.NoResultsContainer>
              <NoResultsHeader
                text={t([I18N_SCOPE, 'no_programs_label', type])}
                icon={EmptyProgramsIcon}
              />
            </S.NoResultsContainer>
          ) : null
        }
      </Observer>
    </S.Container>
  );
};
