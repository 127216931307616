import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import AppContext from '@src/context/App';
import {MyLibrary} from '@src/modules';
import CourseGoalsTab from '@src/modules/SeeProgram/Squad/Course/CourseGoalsTab/CourseGoalsTab';
import TasksRedesign from '@src/pages/NewLayout/components/Tasks';

import styles from '../styles/stylesTabs.module.scss';

import {Tab360} from './360';
import SessionNotesContainer from './SessionNotesContainer';

type TItemMenu = 'library' | 'tasks' | 'notes' | 'goals' | '360';

interface ILocalStore {
  setCurrentItemMenu(type: TItemMenu): void;
  itemMenu: {type: TItemMenu; name: string}[];
  currentItemMenu: string;
}

const Tabs = () => {
  const {
    stores: {chatsColumnsStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        currentItemMenu: 'library',
        setCurrentItemMenu(type: TItemMenu) {
          this.currentItemMenu = type;
        },
        get itemMenu() {
          type Tab = {type: TItemMenu; name: string};

          let tabs: Tab[] = [
            {type: 'library', name: 'Library'},
            {type: 'tasks', name: 'Tasks'},
            {type: 'notes', name: 'Notes'},
            {type: 'goals', name: 'Goals'},
            {type: '360', name: '360'},
          ];

          return tabs;
        },
      },
      {
        currentItemMenu: observable,
        setCurrentItemMenu: action,
        itemMenu: computed,
      },
    ),
  ).current;

  const itemMenu: {type: TItemMenu; name: string}[] = [
    {type: 'library', name: 'Library'},
    {type: 'tasks', name: 'Tasks'},
    {type: 'notes', name: 'Notes'},
    {type: 'goals', name: 'Goals'},
    {type: '360', name: '360'},
  ];

  const handleOnClickMenuItem = (type: TItemMenu) => {
    return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      localStore.setCurrentItemMenu(type);
    };
  };

  return (
    <Observer>
      {() => (
        <>
          <div className={styles.menu}>
            {localStore.itemMenu.map(({type, name}) => (
              <a
                className={`customTopMenu ${styles.menuItem} ${
                  localStore.currentItemMenu === type
                    ? `selected ${styles.selected}`
                    : ''
                }`}
                key={type}
                onClick={handleOnClickMenuItem(type)}
              >
                <div className={styles.menuItemContent}>{name}</div>
              </a>
            ))}
          </div>
          {chatsColumnsStore.channel && chatsColumnsStore.channel?.resource && (
            <div className={styles.displayInnerContent}>
              {localStore.currentItemMenu === itemMenu[0].type &&
                chatsColumnsStore.channel?.resource_id && (
                  <MyLibrary courseId={chatsColumnsStore.channel.resource_id} />
                )}
              {localStore.currentItemMenu === itemMenu[1].type && (
                <TasksRedesign course={chatsColumnsStore.channel?.resource} />
              )}
              {localStore.currentItemMenu === itemMenu[2].type && (
                <SessionNotesContainer
                  /* @ts-ignore */
                  course={chatsColumnsStore.channel?.resource}
                />
              )}
              {localStore.currentItemMenu === itemMenu[3].type && (
                <CourseGoalsTab
                  course={chatsColumnsStore.channel?.resource}
                  variant={'chat_tools'}
                />
              )}
              {/* BLIAAAAAAAA */}
              {localStore.currentItemMenu === itemMenu[4].type && <Tab360 />}
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(Tabs);
