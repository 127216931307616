import styled from 'styled-components';

import NextArrow from '@yourcoach/shared/assets/icons/arrow-next.svg';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {Image} from '@yourcoach/shared/uikit/Image';
import {Text} from '@yourcoach/shared/uikit/Text';

export const Container = styled(Clickable)`
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
`;

export const ProgramContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ProgramAvatar = styled(Image)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

export const Title = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

export const ClientName = styled(Text).attrs({as: 'p'})`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: ${getColor('text3')};
`;

export const MiddleContainer = styled.div`
  flex: 0.9;
  padding-left: 20px;
`;

export const CourseDates = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

export const RightContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Counters = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: ${getColor('global1')};
  margin-right: 20px;
`;

const SNextArrow = styled(NextArrow)`
  width: 28px;
  height: 28px;
  fill: ${getColor('icon3')};
`;

export {SNextArrow as NextArrow};
