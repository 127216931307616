import type {FC} from 'react';
import React, {memo, useMemo} from 'react';

import YcIcon from '@yourcoach/shared/assets/gamification/yc.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import styles from './styles.module.css';

const ICON_SIZE_ASPECT = 0.7;

interface Props {
  backgroundColor?: string;
  size?: number;
}

const YcCoin: FC<Props> = ({backgroundColor, size = 14}) => {
  const iconSize = useMemo(() => size * ICON_SIZE_ASPECT, [size]);
  const backgroundColorMemo = useMemo(
    () => backgroundColor || themes.light.color.secondary1,
    [backgroundColor],
  );

  const style = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: backgroundColorMemo,
      width: size,
      height: size,
      borderRadius: size / 2,
    }),
    [backgroundColorMemo, size],
  );

  return (
    <div className={`YcCoin ${styles.YcCoin}`} style={style}>
      <YcIcon
        width={iconSize}
        height={iconSize}
        fill={themes.light.color.icon2}
      />
    </div>
  );
};

export default memo(YcCoin);
