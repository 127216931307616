import '@src/styles/customCalendar.css';

import type {FC} from 'react';
import React, {memo, useEffect} from 'react';
import {DayPicker} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {Observer} from 'mobx-react';

import moment from 'moment';

import IconNext from '@src/components/icons/IconNext/IconNext';
import IconPrev from '@src/components/icons/IconPrev/IconPrev';
import type {CourseT} from '@src/modules/courses/SelectCourse';

import styles from './styles.module.css';
import useEventCalendar from './useEventCalendar';

interface Props {
  greenEventsMonth?: number[];
  pupleEventsMonth?: number[];
  prevMonthClick?: (retDate: Date) => void;
  nextMonthClick?: (retDate: Date) => void;
  dayClick?: (retDate: Date) => void;
  startDate?: Date;
  outsideClick?: () => void;
  course?: CourseT | null;
}

const MyCalendar: FC<Props> = ({
  greenEventsMonth = [],
  pupleEventsMonth = [],
  startDate = new Date(),
  course = null,
  prevMonthClick,
  nextMonthClick,
  outsideClick,
  dayClick,
}) => {
  //const localStore: ICalendarEventStore = useEventCalendar();
  const localStore = useEventCalendar();

  useEffect(() => {
    localStore.setGreenEvents(greenEventsMonth);
    localStore.setPurpleEvents(pupleEventsMonth);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greenEventsMonth, pupleEventsMonth]);

  useEffect(() => {
    localStore.setCurrentMonth(startDate);
    localStore.getEvents();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (course && course._id) {
      localStore.setCourse(course);
    }

    localStore.getEvents();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const handleOnPrevMonthClick = params => {
    const retDate = params.toDate() as Date;

    localStore.setCurrentMonth(retDate);
    prevMonthClick ? prevMonthClick(retDate) : null;
    localStore.getEvents();
  };

  const handleOnNextMonthClick = params => {
    const retDate = params.toDate() as Date;

    localStore.setCurrentMonth(retDate);
    nextMonthClick ? nextMonthClick(retDate) : null;
    localStore.getEvents();
  };

  const handleOnDayClick = params => {
    const retDate = params.toDate() as Date;

    dayClick ? dayClick(retDate) : null;
  };

  const handleRenderWeekHeaderElement = dayName => {
    return <>{dayName}</>;
  };

  const handleInitialVisibleMonth = () => moment(startDate);

  const handleOnOutsideClick = () => {
    outsideClick ? outsideClick() : null;
  };

  return (
    <Observer>
      {() => (
        <div
          className={`MyCalendar ${styles.MyCalendar}`}
          data-observ-green={localStore.greenEvents}
          data-observ-puple={localStore.purpleEvents}
        >
          <DayPicker
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            navPrev={<IconPrev height="14" />}
            navNext={<IconNext height="14" />}
            onOutsideClick={handleOnOutsideClick}
            initialVisibleMonth={handleInitialVisibleMonth}
            renderWeekHeaderElement={handleRenderWeekHeaderElement}
            onPrevMonthClick={handleOnPrevMonthClick}
            onNextMonthClick={handleOnNextMonthClick}
            onDayClick={handleOnDayClick}
            firstDayOfWeek={1}
            noBorder
            renderDayContents={day => {
              const isToday =
                moment(startDate).format('YYYY-MM-DD') ===
                day.format('YYYY-MM-DD');
              const dayWeek = day.day() % 7;
              const isWeekend = dayWeek === 6 || dayWeek === 0;

              const isSigalsGreen = localStore.greenEvents.some(numDay => {
                return numDay === day.get('date');
              });

              const isSigalsPurple = localStore.purpleEvents.some(numDay => {
                return numDay === day.get('date');
              });

              const isSignal = isSigalsGreen || isSigalsPurple;

              return (
                <div
                  className={`particularDay ${isToday ? 'today' : 'otherDay'} ${
                    isWeekend ? 'weekend' : ''
                  } ${styles.particularDay}`}
                >
                  {day.format('D')}
                  {isSignal ? (
                    <div className={`${styles.signals}`}>
                      {isSigalsGreen ? (
                        <div className={`${styles.greenSig}`} />
                      ) : (
                        ''
                      )}
                      {isSigalsPurple ? (
                        <div className={`${styles.purpleSig}`} />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            }}
          />
        </div>
      )}
    </Observer>
  );
};

export default memo(MyCalendar);
