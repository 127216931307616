import type {FC} from 'react';
import React, {memo, useCallback, useContext, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import PlusIcon from '@yourcoach/shared/assets/icons/primary/Plus.svg';

import {labelShare, labelShareThisWith} from '@src/common/i18n/i18nCommon';
import {
  labelAddProgramOnly,
  labelPrograms,
} from '@src/common/i18n/i18nPrograms';
import {useAppRedirect} from '@src/common/useAppRedirect';
import useIntersectionObserver from '@src/common/useIntersectionObserver';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import IconIconShared from '@src/components/icons/IconShared/IconShared';
import Loader from '@src/components/Loader/Loader';
import Program from '@src/components/Program/Program';
import SharedLink from '@src/components/SharedLink/SharedLink';
import localAppStore from '@src/context/appStore';
import {PATH_TYPE} from '@src/routes/utils';
import {getPagePath} from '@src/utils';

import {PathBuilderService} from '../../../v2/services/PathBuilderService';
import PracticeContext from '../context/PracticeContext';
import type {
  IFilterProgram,
  IPracticeLocalStore,
} from '../context/usePracticeLocalStore';

import styles from './styles.module.css';

interface Props {}

const Programs: FC<Props> = () => {
  const programState: IPracticeLocalStore | null = useContext(PracticeContext);

  const redirect = useAppRedirect('/');
  const refObserve = useIntersectionObserver(() => {
    if (programState) {
      (programState as IPracticeLocalStore).getMorePrograms();
    }
  });
  const [showPopupShared, setShowPopupShared] = useState(false);

  const handleOnClickAddProgram = () => {
    localAppStore?.setReturnPath(getPagePath());
    redirect(`/${PATH_TYPE.program}`);
  };

  const handleUpdateList = () => {
    programState!.getDate();
  };
  const handleClosePopupShared = useCallback(() => {
    setShowPopupShared(false);
  }, []);
  const handleOnClickShared = useCallback(() => {
    setShowPopupShared(true);
  }, []);

  const location: {state?: {from?: string}} = useLocation();

  return (
    <Observer>
      {() => (
        <div className={`Programs ${styles.Programs}`}>
          {programState!.isMe ? (
            <div className={styles.programsHeader}>
              <h3 className={styles.programsHeaderTitle}>{labelPrograms()}</h3>
              <div className={styles.buttonContainer}>
                <div
                  className={`${styles.button} ${styles.sharedButton}`}
                  onClick={handleOnClickShared}
                >
                  <div className={`${styles.label} ${styles.black}`}>
                    {labelShare()}
                  </div>
                  <div className={`${styles.icon} ${styles.black}`}>
                    <IconIconShared />
                  </div>
                </div>
                <div
                  className={styles.button}
                  onClick={handleOnClickAddProgram}
                >
                  <div className={styles.label}>{labelAddProgramOnly()}</div>
                  <div className={styles.icon}>
                    <PlusIcon height="100%" width="100%" />
                  </div>
                </div>
              </div>
              <ModalAnimateWin
                showModal={showPopupShared}
                closeModalHandler={handleClosePopupShared}
                className="greyHeaderContainer littleContainer w610 List"
                isBody
                classNameBody={`whiteBody maxContent noPadding ${styles.inviteContainer}`}
                header={labelShareThisWith()}
                classNameHeader="greyHeader w610"
                classNameCloseBut="greyHeaderBut"
              >
                <SharedLink />
              </ModalAnimateWin>
            </div>
          ) : null}
          {programState &&
          (programState as IPracticeLocalStore).filteredPrograms.length <= 0 &&
          (programState as IPracticeLocalStore).isLoading ? (
            <Loader />
          ) : (
            <>
              {programState!.filteredPrograms.length &&
              !programState!.isLimitedEdition ? (
                <>
                  <hr className="mainSeparator" />
                  {!programState!.isMe ? (
                    <div className={styles.programsHeader}>
                      <h3 className={styles.programsHeaderTitle}>
                        {labelPrograms()}
                      </h3>
                    </div>
                  ) : null}
                </>
              ) : null}
              <div className={styles.programsContainer}>
                {programState!.filteredPrograms.map(
                  (program: IFilterProgram) => {
                    return (
                      <div key={program.id} className={styles.programContainer}>
                        <Link
                          // to={`${document.location.pathname}/programs/${
                          //   program.slug ? program.slug : program.id
                          // }`}
                          // to={PathBuilderService.toProgramFuckTheRouterIHaveDOM(
                          //   {
                          //     slug: program.slug,
                          //     id: program.id,
                          //   },
                          // )}
                          to={PathBuilderService.toProgram(
                            {
                              slug: null,
                              id: programState!.practice._id as string,
                            },
                            {slug: program.slug, id: program.id},
                            {from: location.state?.from},
                          )}
                          className={styles.programLink}
                        >
                          <Program
                            priceStr={program.priceStr}
                            title={program.title}
                            programId={program.id}
                            ownerId={program.user_id}
                            updateListProgram={handleUpdateList}
                            is_hidden={program.is_hidden}
                            is_closed={program.is_closed}
                            avatar={program.avatar}
                            isCoach={programState!.isMe}
                            coaches={program.coaches}
                            durationStr={program.durationStr}
                            isGroup={program.isGroup}
                            status={program.status}
                            offerId={program.offer_id}
                          />
                        </Link>
                      </div>
                    );
                  },
                )}
              </div>
            </>
          )}
          <div ref={refObserve}>
            <Observer>
              {() =>
                programState!.programsStore?.hasMore ? (
                  <Loader size={50} />
                ) : null
              }
            </Observer>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Programs);
