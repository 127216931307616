import type {FC} from 'react';
import React, {memo} from 'react';

import PracticeState from './context/PracticeState';
import Programs from './Programs/Programs';
import HeaderPractice from './HeaderPractice';

interface Props {}

const Practice: FC<Props> = () => {
  return (
    <PracticeState>
      <div className="Practice">
        <HeaderPractice />
        <Programs />
      </div>
    </PracticeState>
  );
};

export default memo(Practice);
