import {useContext, useRef} from 'react';

import Fuse from 'fuse.js';
import {action, computed, observable, runInAction} from 'mobx';

import type {
  ApiRpcRequestParams,
  CollectionStore,
  DateTimeObj,
} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Channel} from '@yourcoach/shared/api/channel';
import {getChannelInfo} from '@yourcoach/shared/api/channel';
import type {ExpandedPost} from '@yourcoach/shared/api/channel/post';
import {postExpand} from '@yourcoach/shared/api/channel/post';
import type {Course} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';

import {setError} from '../../../common/setError';
import {getCustomConfirmAlert} from '../../../components/CustomConfirmAlert/CustomConfirmAlert';
import AppContext from '../../../context/App';
import localAppStore from '../../../context/appStore';
import type {Expanded as ChannelExpanded} from '../../../models/channels';
import {expand as channelExpand} from '../../../models/channels';
import type {Expanded as CourseExpanded} from '../../../models/courses';

const LIMIT = 100;
const ACTIVE_CHANNELS_LIMIT = 300;

const expand = JSON.parse(JSON.stringify(channelExpand));

export const SUPPORTED_EVENTS: Event['type'][] = [
  'conference_started',
  'conference_stopped',
  'task_done',
  'membership_created',
  'course_started',
  'coach_coverage',
];

expand.channel.push(['last_post_id', {body: 'Post not found'}, postExpand]);

const findChannels = (channels: ChannelT[], query: string) => {
  const fuse = new Fuse(channels, {
    shouldSort: false,
    threshold: 0.3,
    keys: ['title', 'description', 'last_post.body', 'last_post.user.name'],
  });

  return fuse.search(query).map(item => item.item);
};

export type ChannelT = Channel &
  ChannelExpanded & {
    updatesCount?: number;
    last_post?: ExpandedPost;
    resource?:
      | (Course &
          CourseExpanded & {
            last_event?:
              | (Event & {
                  user?: User | null;
                  actor?: User | null;
                  _id?: string;
                  created?: DateTimeObj;
                })
              | null;
          })
      | null;
  };

export type ICoach = User & UserExpanded;

interface Section {
  key: 'channels' | 'archived_channels';
  title?: string;
  data: ChannelT[];
}

export type toggleTypeList = 'channels' | 'dms';

export const idTypeList: toggleTypeList[] = ['channels', 'dms'];

type Updates = {
  [channel_id: string]: {
    count: number;
    isDm: boolean;
  };
};

interface Route {
  key: toggleTypeList;
  title: string;
  badge?: string | number;
}

export interface IChatsLocalStore {
  query: string | null;
  setSearchQuery(searchQuery: string): void;
  clearSearchQuery(): void;
  selectTypeList: toggleTypeList;
  setSelectTypeList(selectTypeList: toggleTypeList): void;
  isLoaded: boolean;
  setIsLoaded(isLoaded: boolean): void;
  updates: Updates;
  tabs: Route[];
  tabBarBadge: {
    updatesCountChannels: number;
    updatesCountDms: number;
  };
  setNullChannel(): void;
  shouldRenderStub: boolean;
  channelsStore: CollectionStore<ChannelT> | null;
  setChannelsStore(channelsStore: CollectionStore<ChannelT>): void;
  archivedChannelsStore: CollectionStore<ChannelT> | null;
  setArchivedChannelsStore(
    archivedChannelsStore: CollectionStore<ChannelT>,
  ): void;
  clear(): void;
  channelsStoreParams: ApiRpcRequestParams;
  sections: Section[];
  isFetching: boolean;
  selectChannel: ChannelT | null;
  setSelectChannel(selectChannel: ChannelT): void;
  selectChannelIdUrl: string | null;
  setSelectChannelIdUrl(selectChannelIdUrl: string): void;
  setNullChannelIdUrl(): void;
  inSelectionState: boolean;
  setInSelectionState(inSelectionState: boolean): void;
  archivedChannelsIsVisible: boolean;
  setArchivedChannelsIsVisible(archivedChannelsIsVisible: boolean): void;
  createChannelsStores(): void;
  _onRefresh(silent?: boolean): Promise<void>;
  withUser:
    | (User & {
        avatar?: IFile | null;
      })
    | null;
  setWithUser(
    withUser:
      | (User & {
          avatar?: IFile | null;
        })
      | null,
  ): void;
  postId: string | null;
  setPostId(postId: string | null): void;
  inMainTabSelect: string | null;
  setInMainTabSelect(inMainTabSelect: string | null): void;
  _deleteSelectedItems(): Promise<void>;
  _deleteCourse(channel: ChannelT): Promise<void>;
}

const useChatsLocalStore = () => {
  const {
    stores: {eventStore, courseStore, channelStore},
  } = useContext(AppContext);

  const store: IChatsLocalStore | null = useRef(
    observable<IChatsLocalStore>(
      {
        query: null,
        setSearchQuery(searchQuery: string) {
          this.query = searchQuery;
        },
        clearSearchQuery() {
          this.query = null;
        },
        selectTypeList: idTypeList[0],
        setSelectTypeList(selectTypeList: toggleTypeList) {
          this.clearSearchQuery();
          this.setNullChannel();
          this.selectTypeList = selectTypeList;
          this.createChannelsStores();
          this._onRefresh();
        },
        isLoaded: false,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        get updates() {
          const updates: Updates = {};

          eventStore.unreadEvents.forEach(event => {
            if (event.type === 'post_created') {
              // @ts-ignore
              const channel = event.contexts.find(
                (item: any) => item && item._id.split(':')[0] === 'channel',
              ) as Channel | undefined;

              if (channel) {
                updates[channel._id] = updates[channel._id] || {
                  count: 0,
                  isDm: false,
                };
                updates[channel._id].count += 1;
                updates[channel._id].isDm = channel.resource_id === null;
              }
            }
          });

          return updates;
        },
        get tabs() {
          const updates = Object.keys(this.updates).map(
            key => this.updates[key],
          );

          const tabs: Route[] = [
            {
              key: 'channels',
              title: 'Channels',
              badge: updates
                .filter(item => !item.isDm)
                .reduce((badge, upd) => badge + upd.count, 0),
            },
            {
              key: 'dms',
              title: 'Direct messages',
              badge: updates
                .filter(item => item.isDm)
                .reduce((badge, upd) => badge + upd.count, 0),
            },
          ];

          return tabs;
        },
        get tabBarBadge() {
          const updates = Object.keys(this.updates as Updates).map(
            key => (this.updates as Updates)[key],
          );

          const updatesCountChannels = updates
            .filter(item => !item.isDm)
            .reduce((badge, upd) => badge + upd.count, 0);

          const updatesCountDms: number = updates
            .filter(item => item.isDm)
            .reduce((badge, upd) => badge + upd.count, 0);

          if (updatesCountChannels === 0 && updatesCountDms === 0) {
            localAppStore!.setPostCreatedNotification(0);
          }

          return {updatesCountChannels, updatesCountDms};
        },
        get shouldRenderStub() {
          return (
            this.channelsStore &&
            this.channelsStore.isLoaded &&
            !this.channelsStore.hasItems
          );
        },
        selectChannelIdUrl: null,
        setSelectChannelIdUrl(selectChannelIdUrl: string) {
          this.selectChannelIdUrl = selectChannelIdUrl;
        },
        setNullChannelIdUrl() {
          this.selectChannelIdUrl = null;
        },
        selectChannel: null,
        setSelectChannel(selectChannel: ChannelT) {
          this.selectChannel = selectChannel;
        },
        setNullChannel() {
          this.selectChannel = null;
        },
        get channelsStoreParams() {
          const params: ApiRpcRequestParams = {};

          if (this.selectTypeList === 'channels') {
            params.query = [
              ['type', '==', 'course'],
              ['status', '!=', 'archived'],
            ];
          } else {
            params.query = [
              ['type', 'in', ['private', 'concierge']],
              // [
              //   'hidden_for_ids',
              //   '!contains',
              //   currentUserStore.user ? [currentUserStore.user._id] : [],
              // ],
            ];
          }

          return params;
        },
        clear() {
          if (this.channelsStore) {
            this.channelsStore.clear();
          }

          if (this.archivedChannelsStore) {
            this.archivedChannelsStore.clear();
          }
        },
        channelsStore: null,
        setChannelsStore(channelsStore: CollectionStore<ChannelT>) {
          this.channelsStore = channelsStore;
        },
        archivedChannelsStore: null,
        setArchivedChannelsStore(
          archivedChannelsStore: CollectionStore<ChannelT>,
        ) {
          this.archivedChannelsStore = archivedChannelsStore;
        },
        get sections() {
          let channels: ChannelT[] = [];
          let archivedChannels: ChannelT[] = [];

          const sections: Section[] = [];

          if (this.channelsStore || this.archivedChannelsStore) {
            if (
              (this.selectTypeList === 'channels' &&
                (!this.channelsStore.isLoaded ||
                  !this.archivedChannelsStore.isLoaded)) ||
              (this.selectTypeList === 'dms' && !this.channelsStore.isLoaded)
            ) {
              return sections;
            }

            if (this.channelsStore && this.channelsStore.hasItems) {
              channels = this.channelsStore.items.slice();

              if (this.query) {
                channels = findChannels(
                  channels.map(item => {
                    // @ts-ignore
                    const {title} = getChannelInfo(item);

                    return {
                      ...item,
                      title,
                    };
                  }),
                  this.query,
                );
              }

              channels.forEach((channel, index) => {
                if (this.updates[channel._id]) {
                  channels[index].updatesCount =
                    this.updates[channel._id].count;
                } else {
                  channels[index].updatesCount = 0;
                }
              });
            }

            if (
              this.archivedChannelsStore &&
              this.archivedChannelsStore.hasItems
            ) {
              archivedChannels = this.archivedChannelsStore.items.slice();

              if (this.query) {
                archivedChannels = findChannels(
                  archivedChannels.map(item => {
                    // @ts-ignore
                    const {title} = getChannelInfo(item);

                    return {
                      ...item,
                      title,
                    };
                  }),
                  this.query,
                );
              }

              archivedChannels.forEach((channel, index) => {
                if (this.updates[channel._id]) {
                  archivedChannels[index].updatesCount =
                    this.updates[channel._id].count;
                } else {
                  archivedChannels[index].updatesCount = 0;
                }
              });
            }

            if (channels.length) {
              sections.push({
                key: 'channels',
                data: channels,
              });
            }

            if (archivedChannels.length) {
              sections.push({
                key: 'archived_channels',
                title: 'Archived sections',
                data: archivedChannels,
              });
            }
          }

          return sections;
        },
        get isFetching() {
          if (this.selectTypeList === 'channels') {
            return (
              (this.channelsStore || this.archivedChannelsStore) &&
              (!this.channelsStore.isLoaded ||
                !this.archivedChannelsStore.isLoaded ||
                this.channelsStore.isFetching ||
                this.archivedChannelsStore.isFetching)
            );
          }

          return !this.channelsStore.isLoaded || this.channelsStore.isFetching;
        },
        inSelectionState: false,
        setInSelectionState(inSelectionState: boolean) {
          this.inSelectionState = inSelectionState;
        },
        archivedChannelsIsVisible: false,
        setArchivedChannelsIsVisible(archivedChannelsIsVisible: boolean) {
          this.archivedChannelsIsVisible = archivedChannelsIsVisible;
        },
        createChannelsStores() {
          this.setChannelsStore(
            createCollectionStore({
              method: 'client.channels.list',
              params: {
                sort: [['updated', -1]],
                ...this.channelsStoreParams,
                expand,
              },
            }),
          );

          this.setArchivedChannelsStore(
            createCollectionStore({
              method: 'client.channels.list',
              params: {
                limit: LIMIT,
                sort: [['updated', -1]],
                query: [
                  ['type', '==', 'course'],
                  ['status', '==', 'archived'],
                ],
                expand,
              },
            }),
          );
        },
        async _onRefresh(silent = false) {
          if (this.channelsStore) {
            await this.channelsStore
              .fetch(
                {
                  limit:
                    this.selectTypeList === 'dms'
                      ? Math.max(this.channelsStore.items.length, LIMIT)
                      : ACTIVE_CHANNELS_LIMIT,
                },
                {silent},
              )
              .catch(() => {});
          }

          if (this.archivedChannelsStore) {
            if (this.selectTypeList === 'channels') {
              await this.archivedChannelsStore
                .fetch(
                  {
                    limit: Math.max(
                      this.archivedChannelsStore.items.length,
                      LIMIT,
                    ),
                  },
                  {silent},
                )
                .catch(() => {});
            }
          }

          if (!this.selectChannel && this.selectChannelIdUrl) {
            const arrChan = this.channelsStore.items.slice() as ChannelT[];
            const arrChanArch =
              this.archivedChannelsStore.items.slice() as ChannelT[];

            const filterChan = arrChan.filter(
              chan => chan._id === this.selectChannelIdUrl,
            );

            const filterChanArch = arrChanArch.filter(
              chan => chan._id === this.selectChannelIdUrl,
            );

            if (filterChan.length > 0) {
              this.setSelectChannel(filterChan[0]);
              this.setNullChannelIdUrl();
            } else if (filterChanArch.length > 0) {
              this.setSelectChannel(filterChanArch[0]);
              this.setArchivedChannelsIsVisible(true);
              this.setNullChannelIdUrl();
            }
          }
        },
        inMainTabSelect: null,
        setInMainTabSelect(inMainTabSelect) {
          this.inMainTabSelect = inMainTabSelect;
        },
        postId: null,
        setPostId(postId: string | null) {
          this.postId = postId;
        },
        withUser: null,
        setWithUser(
          withUser:
            | (User & {
                avatar?: IFile | null;
              })
            | null,
        ) {
          this.withUser = withUser;
        },
        async _deleteSelectedItems() {
          try {
            this.setIsLoaded(true);

            await channelStore.updateBatch([
              {
                entity: this.selectChannel,
                params: {
                  is_hidden: true,
                },
              },
            ]);

            this.channelsStore.removeItem(this.selectChannel);

            runInAction(() => {
              this.selection = [];
              this.inSelectionState = false;
            });

            this.setNullChannel();

            this.setIsLoaded(false);
          } catch (error) {
            this.setIsLoaded(false);

            getCustomConfirmAlert({
              title: 'Error',
              message: error.message,
              buttons: [
                {
                  label: 'Try again later',
                  onClick: () => {},
                },
                {
                  label: 'Try again',
                  onClick: () => this._deleteSelectedItems(),
                  type: 'confirm',
                },
              ],
            });
          }
        },
        async _deleteCourse(channel: ChannelT) {
          try {
            this.setIsLoaded(false);

            await courseStore.delete(channel.resource!);

            runInAction(() => {
              if (this.channels) {
                const channelIndex = this.channels.findIndex(
                  item => item._id === channel._id,
                );

                if (channelIndex >= 0) {
                  this.channels.splice(channelIndex, 1);
                }
              }

              // Case if delete called from AllChannels screen
              // @ts-ignore
              if (this.channelsStore) {
                // @ts-ignore
                this.channelsStore.removeItem(channel);
              }
            });

            this.setIsLoaded(true);
          } catch (error) {
            this.setIsLoaded(true);
            getCustomConfirmAlert({
              title: 'Error',
              message: error.message,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {},
                },
              ],
            });

            setError(error);
          }
        },
      },
      {
        query: observable,
        setSearchQuery: action,
        clearSearchQuery: action,
        selectTypeList: observable,
        setSelectTypeList: action,
        isLoaded: observable,
        setIsLoaded: action,
        updates: computed,
        tabs: computed,
        tabBarBadge: computed,
        shouldRenderStub: computed,
        channelsStore: observable,
        setChannelsStore: action,
        archivedChannelsStore: observable,
        setArchivedChannelsStore: action,
        channelsStoreParams: computed,
        sections: computed,
        isFetching: computed,
        selectChannelIdUrl: observable,
        setSelectChannelIdUrl: action,
        setNullChannelIdUrl: action,
        selectChannel: observable,
        setSelectChannel: action,
        setNullChannel: action,
        inSelectionState: observable,
        setInSelectionState: action,
        archivedChannelsIsVisible: observable,
        setArchivedChannelsIsVisible: action,
        createChannelsStores: action,
        _onRefresh: action,
        postId: observable,
        setPostId: action,
        withUser: observable,
        setWithUser: action,
        _deleteSelectedItems: action,
        _deleteCourse: action,
        clear: action,
      },
    ),
  ).current;

  return store;
};

export default useChatsLocalStore;
