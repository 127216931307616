import React, {useCallback} from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';

/* @ts-ignore */
import styles from '../../styles/styles.module.css';

import Modal from './Modal';

interface Props {
  onConfirmButtonClick: () => void;
}

export const ChatAreYouSureModal = NiceModal.create<Props>(
  ({onConfirmButtonClick}) => {
    const modal = useModal();

    const onCancelButtonClick = useCallback(() => {
      modal.resolve();

      modal.hide();
    }, [modal]);

    return (
      <Modal onAfterClose={modal.remove} isOpen={modal.visible}>
        <div className={styles.areYouSureModalBody}>
          <div className={styles.areYouSureModalHeader}>Are you sure?</div>
          <div className={styles.areYouSureModalButtonsContainer}>
            <div
              className={styles.confirmButton}
              onClick={() => {
                onConfirmButtonClick();
                onCancelButtonClick();
              }}
            >
              Yes
            </div>
            <div className={styles.cancelButton} onClick={onCancelButtonClick}>
              No
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
