import React from 'react';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import styles from './styles.module.css';

const I18N_SCOPE = 'Onboarding.video.final';

const Final = ({onShowRoadmap, onSkip}) => {
  return (
    <div className={styles.final}>
      <div className={styles.title}>
        {t([I18N_SCOPE, 'ask_see_roadmap_text'])}{' '}
      </div>
      <div className={styles.description}>
        {t([I18N_SCOPE, 'roadmap_description_text'])}
      </div>
      <div className={styles.spacer} />
      <div className={styles.buttons}>
        <Button className={styles.inverted} onClick={onSkip}>
          {t([I18N_SCOPE, 'later_button'])}
        </Button>
        <Button onClick={onShowRoadmap}>
          {t([I18N_SCOPE, 'show_roadmap_button'])}
        </Button>
      </div>
    </div>
  );
};

export default Final;
