import React, {useCallback, useContext, useEffect, useState} from 'react';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import Final from './Video/Final';
import Intro from './Video/Intro';
import Player from './Video/Player';

const VideoStep = ({onContinue, onSkip}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [index, setIndex] = useState(0);

  const onNext = useCallback(() => {
    setIndex(index + 1);
  }, [index]);

  const onGotoLast = useCallback(() => {
    setIndex(5);
  }, []);

  useEffect(() => {
    if (
      currentUserStore.user?.metadata?.onboarding?.account_type !==
        'employee' &&
      index === 5
    ) {
      onContinue();
    }
  }, [
    currentUserStore.user?.metadata?.onboarding?.account_type,
    index,
    onContinue,
  ]);

  if (index === 0) {
    return <Intro onNext={onNext} onSkip={onGotoLast} />;
  }

  if (index === 5) {
    return <Final onShowRoadmap={onContinue} onSkip={onSkip} />;
  }

  return (
    <Player
      label={t('Onboarding.video.step_label', {pos: index, total: 4})}
      url={`https://assets.yourcoach.health/video/onboarding/part${index}-w.mp4`}
      message={t(`Onboarding.video.coach.step${index}.message`, {
        defaultValue: '',
      })}
      subtitle={t(`Onboarding.video.coach.step${index}.subtitle`)}
      onNext={onNext}
    />
  );
};

export default VideoStep;
