import {apiRequest} from '@yourcoach/shared/api';

const getActiveClientMembership = async () => {
  return apiRequest({
    method: 'coach.memberships.list',
    params: {
      sort: [['created', -1]],
      query: [['status', 'in', ['active']]],
      limit: 1000,
      expand: {
        membership: ['user_id'],
        user: ['subtenant_id'],
        subtenant: ['tenant_id'],
      },
    },
  });
};

const getArchivedClientMembership = async () => {
  return apiRequest({
    method: 'coach.memberships.list',
    params: {
      sort: [['created', -1]],
      query: [['status', 'in', ['archived']]],
      limit: 1000,
      expand: {
        membership: ['user_id'],
        user: ['subtenant_id'],
        subtenant: ['tenant_id'],
      },
    },
  });
};

export {getActiveClientMembership, getArchivedClientMembership};
