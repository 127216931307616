import type {FC} from 'react';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import {Observer} from 'mobx-react';

import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';

import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';

import styles from './styles.module.css';

interface Props {
  course: CourseT;
}

const CourseCoachesTab: FC<Props> = ({course}) => {
  const coaches = course.program.expanded_coaches;

  return (
    <Observer>
      {() => (
        <div className={`CourseCoachesTab ${styles.CourseCoachesTab}`}>
          <div className={styles.avatarContainer}>
            <div className={styles.coachAvatarContainer}>
              {coaches.map(coach => {
                const practiceName = coach.slug ? coach.slug : coach._id;
                const urlString = `/coaches/${practiceName}`;

                return (
                  <Link
                    key={coach._id}
                    to={urlString}
                    className={styles.programLink}
                  >
                    <div key={coach._id} className={styles.coachString}>
                      <div className={styles.coachAvatar}>
                        <OtherUserProfileImg
                          avatar={coach.avatar}
                          title={coach.name}
                        />
                      </div>
                      <div className={styles.coachName}>{coach.name}</div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseCoachesTab);
