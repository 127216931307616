import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api';
import type {
  Questionnaire,
  QuestionnaireSection,
  UserQuestionnaire,
  UserQuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';
import type {User as IUser} from '@yourcoach/shared/api/user';

import {
  labelAnswered,
  labelCompleted,
  labelInProgress,
  labelName,
  labelSent,
  labelStatus,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import useURLQueryParam from '@src/hooks/useURLQueryParam';

import Result from './Result/Result';
import styles from './styles.module.css';

export interface IStatusQLocalStore {
  loading: boolean;
  setLoading: (newVal: boolean) => void;
  allQNum: number;
  setAllQNum: (newVal: number) => void;
  currentUserQ: UserQuestionnaire | null;
  setCurrentUserQ: (newUserQ: UserQuestionnaire) => void;
  currentUser: IUser | null;
  setCurrentUser: (newUser: IUser) => void;
  showModal: boolean;
  setShowModal: (newVal: boolean) => void;
  listUsersQuestions: UserQuestionnaire[];
  setListUsersQuestions: (newListUsersQuestions: UserQuestionnaire[]) => void;
  expanded: {} | null;
  setExpanded: (newExpanded: {}) => void;
}

interface Props {}

const Status: FC<Props> = () => {
  const QId = useURLQueryParam('id');
  const localStore = useRef(
    observable<IStatusQLocalStore>(
      {
        loading: false,
        setLoading(newVal: boolean) {
          this.loading = newVal;
        },
        allQNum: 0,
        setAllQNum(newVal: number) {
          this.allQNum = newVal;
        },
        currentUserQ: null,
        setCurrentUserQ(newUserQ: UserQuestionnaire) {
          this.currentUserQ = newUserQ;
        },
        currentUser: null,
        setCurrentUser(newUser: IUser) {
          this.currentUser = newUser;
        },
        showModal: false,
        setShowModal(newVal: boolean) {
          this.showModal = newVal;
        },
        listUsersQuestions: [],
        setListUsersQuestions(newListUsersQuestions: UserQuestionnaire[]) {
          this.listUsersQuestions = newListUsersQuestions;
        },
        expanded: {},
        setExpanded(newExpanded: {}) {
          this.expanded = newExpanded;
        },
      },
      {
        loading: observable,
        allQNum: observable,
        listUsersQuestions: observable,
        expanded: observable,
        currentUserQ: observable,
        showModal: observable,
        currentUser: observable,
        setLoading: action,
        setAllQNum: action,
        setCurrentUser: action,
        setCurrentUserQ: action,
        setListUsersQuestions: action,
        setShowModal: action,
        setExpanded: action,
      },
    ),
  ).current;

  useEffect(() => {
    const getUserQ = async () => {
      localStore.setLoading(true);

      try {
        let questionnaireId = QId;

        // means that we've received user_questionnaire here
        const isUserQuestionnaire = questionnaireId?.includes('user_');

        if (isUserQuestionnaire) {
          const {user_questionnaire} = await apiRequest({
            method: 'coach.questionnaires.user.read',
            params: {
              _id: questionnaireId,
            },
          });

          questionnaireId = user_questionnaire.questionnaire_id;
        }

        const userQuestionnaireResponse = await apiRequest({
          method: 'coach.questionnaires.user.list',
          params: {
            sort: [['created', -1]],
            query: [['questionnaire_id', '==', questionnaireId]],
            limit: 999,
            expand: {
              user_questionnaire: [
                ['user_id', {name: 'User not found'}, {user: ['avatar_id']}],
              ],
            },
          },
        });

        const {questionnaire} = await apiRequest({
          method: 'coach.questionnaires.own.read',
          params: {
            _id: questionnaireId,
          },
        });

        localStore.setExpanded(userQuestionnaireResponse._expanded);
        localStore.setAllQNum(getNumQ(questionnaire as Questionnaire));
        localStore.setListUsersQuestions(
          userQuestionnaireResponse._items as UserQuestionnaire[],
        );
        localStore.setLoading(false);

        if (isUserQuestionnaire) {
          const userQuestionnaireIndex =
            userQuestionnaireResponse._items.findIndex(
              item => item._id === QId,
            );

          if (userQuestionnaireIndex >= 0) {
            handleRowClick(
              userQuestionnaireResponse._items[userQuestionnaireIndex],
            )();
          }
        }

        // localStore.setAllQNum(getNumQ(questionnaire as Questionnaire));
      } catch (error) {
        localStore.setLoading(false);
        setError(error.message);
      }
    };

    getUserQ();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNumQ: (Q: Questionnaire) => number = (Q: Questionnaire) => {
    return Q.sections
      .slice()
      .reduce((sumQ: number, section: QuestionnaireSection) => {
        const retSum = sumQ + section.questions.length;

        return retSum;
      }, 0);
  };
  const getNumAnsweredQ: (Q: UserQuestionnaire) => number = (
    Q: UserQuestionnaire,
  ) => {
    if (Q.answered) {
      return Q.sections
        .slice()
        .reduce((sumQ: number, section: UserQuestionnaireSection) => {
          let retSum = sumQ;

          section.questions.forEach(question => {
            if (question.answer) {
              retSum = retSum + 1;
            }
          });

          return retSum;
        }, 0);
    } else {
      return 0;
    }
  };

  const handleRowClick = (userQ: UserQuestionnaire) => {
    return () => {
      const user = {
        ...((localStore.expanded![userQ.user_id!] as IUser) || {
          name: 'User not found',
        }),
      };

      user.name = userQ.client_name || user.name;

      runInAction(() => {
        // @ts-ignore
        user.avatar =
          user && user.avatar_id ? localStore.expanded![user.avatar_id] : null;
      });

      localStore.setCurrentUser(user);
      localStore.setCurrentUserQ(userQ);
      localStore.setShowModal(true);
    };
  };

  const closeModal = () => {
    localStore.setShowModal(false);
  };

  return (
    <Observer>
      {() => (
        <div className={`Status ${styles.Status}`}>
          <div className={`${styles.grid} ${styles.header}`}>
            <div>
              <span>{labelName()}</span>
            </div>

            <div>
              <span>{labelAnswered()}</span>
            </div>
            <div>
              <span>{labelStatus()}</span>
            </div>
          </div>
          {localStore.listUsersQuestions.length > 0 &&
            localStore.expanded &&
            localStore.listUsersQuestions.map((userQ: UserQuestionnaire) => {
              const user = {
                ...((localStore.expanded![userQ.user_id!] as IUser) || {
                  name: 'User not found',
                }),
              };

              const userName = userQ.client_name || user.name;

              runInAction(() => {
                // @ts-ignore
                user.avatar =
                  user && user.avatar_id
                    ? localStore.expanded![user.avatar_id]
                    : null;
              });

              return (
                <div
                  key={userQ._id}
                  className={`${styles.grid} ${styles.row} ${
                    user ? '' : styles.null
                  }`}
                  onClick={handleRowClick(userQ)}
                >
                  {user && (
                    <>
                      <div className={styles.userContainer}>
                        <div className={styles.userAvatar}>
                          <OtherUserProfileImg
                            // @ts-ignore
                            avatar={user.avatar}
                            title={userName}
                          />
                        </div>
                        <div className={styles.userName}>{userName}</div>
                      </div>

                      <div>
                        <span>
                          {getNumAnsweredQ(userQ)}/{localStore.allQNum}
                        </span>
                      </div>
                      <div>
                        {userQ.answered ? (
                          <div className={styles.qComplete}>
                            {labelCompleted()}
                          </div>
                        ) : !userQ.last_answer ? (
                          <div className={styles.qSent}>{labelSent()}</div>
                        ) : (
                          <div className={styles.qInProgress}>
                            {labelInProgress()}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          <ModalAnimateWin
            showModal={localStore.showModal}
            closeModalHandler={closeModal}
            className="greenModal"
            classNameCloseBut="modalCloseBut"
          >
            <Result
              currentAnswer={localStore.currentUserQ}
              currentUser={localStore.currentUser}
            />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(Status);
