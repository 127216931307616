import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as UserPrivateProfileProps} from './UserPrivateProfile';
import UserPrivateProfile, {I18N_SCOPE} from './UserPrivateProfile';

type Props = Partial<ModalProps> & UserPrivateProfileProps;

const UserPrivateProfileModal: React.FC<Props> = ({
  requiredFields,
  onSuccess,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])} withStickyFooter>
    <UserPrivateProfile requiredFields={requiredFields} onSuccess={onSuccess} />
    {children}
  </Modal>
);

export default React.memo(UserPrivateProfileModal);
