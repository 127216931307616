import type {FC, MouseEventHandler} from 'react';
import React, {useCallback, useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';

import classNames from 'classnames';

import {apiRequest} from '@yourcoach/shared/api';

import {useIsAuth} from '@src/common/useUserMethods';
import Button from '@src/components/Button';
import SpinnerOverlay from '@src/components/SpinnerOverlay';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import ClientSVG from '../images/client.svg';
import CoachSVG from '../images/coach.svg';
import LogoSVG from '../images/logo_left.svg';

import LeftPaneStep, {ButtonsContainer, Title} from './LeftPaneStep';
import RoleBlock from './RoleBlock';
import styles from './styles.module.css';

interface Props {
  onClick?: MouseEventHandler;
}

const I18N_SCOPE = 'Login.account_type';

const AccountTypeStep: FC<Props> = ({onClick}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const setLocalStoreAppIsAuth = useIsAuth();
  const history = useHistory();

  const [index, setIndex] = useState(-1);
  const [error, setError] = useState('');
  const [isRequest, setIsRequest] = useState(false);

  const onBoxClick = useCallback(e => {
    var element = e.target;

    while (element && !element.attributes['data-index']) {
      element = element.parentElement;
    }

    if (element) {
      setIndex(Number.parseInt(element.attributes['data-index'].value, 10));
      setError('');
    }
  }, []);

  const onNextClick = useCallback(() => {
    if (index < 0) {
      setError(t([I18N_SCOPE, 'choose_type_error']));
    } else {
      setIsRequest(true);

      const accountType = ['coach', 'client'][index];

      currentUserStore
        .update({
          metadata: {
            ...currentUserStore.user!.metadata,
            onboarding: {
              ...currentUserStore.user!.metadata.onboarding,
              account_type: accountType,
            },
          },
        })
        .then(() => {
          if (accountType === 'coach') {
            apiRequest({
              method: 'user.profile.hello_coach',
            }).catch(() => {
              // do nothing
            });
          }

          setLocalStoreAppIsAuth();
          history.replace(['/user-profile', '/'][index]);
        })
        .finally(() => {
          setIsRequest(false);
        });
    }
  }, [currentUserStore, history, index, setLocalStoreAppIsAuth]);

  return (
    <LeftPaneStep icon={<LogoSVG />}>
      <Title>{t([I18N_SCOPE, 'title'])}</Title>
      <div className={styles.error}>{error}</div>
      <RoleBlock
        className={classNames(styles.marginTop20, {
          [styles.selected]: index === 0,
        })}
        title={t([I18N_SCOPE, 'coach_title'])}
        text={t([I18N_SCOPE, 'coach_text'])}
        data-index="0"
        onClick={onBoxClick}
      >
        <CoachSVG />
      </RoleBlock>
      <RoleBlock
        title={t([I18N_SCOPE, 'client_title'])}
        text={t([I18N_SCOPE, 'client_text'])}
        className={classNames(styles.marginTop20, {
          [styles.selected]: index === 1,
        })}
        data-index="1"
        onClick={onBoxClick}
      >
        <ClientSVG />
      </RoleBlock>
      <ButtonsContainer>
        <SpinnerOverlay isActive={isRequest}>
          <Button className={styles.wide} onClick={onNextClick}>
            {isRequest ? <>&nbsp;</> : t('Common.Continue')}
          </Button>
        </SpinnerOverlay>
      </ButtonsContainer>
    </LeftPaneStep>
  );
};

export default AccountTypeStep;
