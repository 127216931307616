import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {apiRequest, expandObj, isEntityId} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';
import {userExpand} from '@yourcoach/shared/api/user';
import {logger} from '@yourcoach/shared/utils/logger';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {t} from '@src/i18n';
import type {ProgramExpanded} from '@src/models/app';

import SeeProgramContext from './context/SeeProgramContext';
import MainColumn from './MainColumn/MainColumn';
import SideColumn from './SideColumn/SideColumn';
import styles from './styles.module.css';

interface Props {}

const programExpand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      null,
      {
        edition: ['course_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

export const fetchData = async (userId: string, programId: string) => {
  const userParams = isEntityId(userId, 'user')
    ? {
        _id: userId,
      }
    : {
        slug: userId,
      };

  const programParams = isEntityId(programId, 'program')
    ? {
        _id: programId,
      }
    : {
        slug: programId,
      };

  try {
    const {user} = await apiRequest({
      method: 'public.profile.read',
      params: {
        ...userParams,
        expand: {
          user: ['avatar_id', 'coach_logo_id'],
        },
      },
      options: {
        withAuth: false,
      },
    });

    const programResponse = await apiRequest({
      method: 'public.programs.read',
      params: {
        ...programParams,
        coach_id: user._id,
        expand: programExpand,
      },
      options: {
        withAuth: false,
      },
    });

    const programExpanded = expandObj<Program, ProgramExpanded>(
      programResponse.program,
      {
        expand: programExpand,
        expanded: programResponse._expanded,
      },
    );

    return programExpanded;
  } catch (error) {
    throw error;
  }
};

const SeeProgramPublic: FC<Props> = () => {
  const seeProgramContext = useContext(SeeProgramContext);
  const {
    slugPractice,
    slugProgram,
  }: {slugPractice: string; slugProgram: string} = useParams();

  useEffect(() => {
    const fetch = async () => {
      try {
        const program = await fetchData(slugPractice, slugProgram);

        seeProgramContext?.setProgram(program);
      } catch (error) {
        logger.error(error);

        let title = t('shared.message.error_fix');
        let {message} = error;

        if (error.data?.program?.is_hidden === false) {
          title = t('shared.message.error');
          message = 'The program is hidden!';
        }

        getCustomConfirmAlert({
          title,
          message,
          buttons: [
            {
              label: t('shared.button.ok'),
              onClick: () => {
                location.replace('/');
              },
            },
          ],
        });
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`SeeProgramPublic ${styles.SeeProgramPublic}`}>
      <SideColumn />
      <div className={styles.greyColumn}>
        <MainColumn />
      </div>
    </div>
  );
};

export default memo(SeeProgramPublic);
