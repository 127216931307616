import type {FC, MouseEvent} from 'react';
import React, {memo} from 'react';

import type {ILink, TListDataItem} from '@src/models/library';

import styles from './styles.module.css';

interface Props {
  data: TListDataItem[];
  onSelectFolder: (folderId: string | null) => void;
}

const MoveFileInFolders: FC<Props> = ({data, onSelectFolder}) => {
  const handleOnClick = (folder_id: string | null) => {
    return (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
      event.preventDefault();

      onSelectFolder(folder_id);
    };
  };

  return (
    <div className={`MoveFileInFolders ${styles.MoveFileInFolders}`}>
      <h3>Move file</h3>
      <div className={styles.container}>
        <ul>
          <li onClick={handleOnClick(null)}>Main folder ...</li>
          {data &&
            data.map(item => {
              if ((item as ILink).type === 'folder') {
                return (
                  <li key={item._id} onClick={handleOnClick(item._id)}>
                    {(item as ILink).name}
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </div>
  );
};

export default memo(MoveFileInFolders);
