import type {FC} from 'react';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import storage from '@yourcoach/shared/utils/storage';
import {
  getEmailSchema,
  getPhoneSchema,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import SpinnerOverlay from '@src/components/SpinnerOverlay';
import TextField from '@src/components/TextField';
import {ACCOUNT_STORAGE_KEY} from '@src/config';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import MailSVG from '../images/mail.svg';
import PhoneSVG from '../images/phone.svg';

import FormStep from './FormStep';
import {AltNavRow, ButtonsContainer, Description, Title} from './LeftPaneStep';
import styles from './styles.module.css';

interface Props {
  onClick?: (event) => void;
  onChange?: (value) => void;
  onData?: (value, fieldName) => void;
  value?: string;
  usePhone?: boolean;
}

const I18N_SCOPE = 'Login.sign_in';

const SignInStep: FC<Props> = ({
  value,
  onChange,
  onClick,
  onData,
  usePhone,
}) => {
  const field = useRef(
    createHtmlInputField(usePhone ? 'phone' : 'email', {
      defaultValue: value || '',
      validationRule: usePhone ? getPhoneSchema() : getEmailSchema(),
    }),
  ).current;
  const {
    stores: {authStore},
  } = useContext(AppContext);
  const [isRequest, setIsRequest] = useState(false);

  const onSubmit = useCallback(
    async fieldValue => {
      setIsRequest(true);

      return authStore
        .sendCode({[usePhone ? 'phone' : 'email']: fieldValue}, true)
        .then(({user_exists}) =>
          user_exists
            ? authStore.sendCode({
                [usePhone ? 'phone' : 'email']: fieldValue,
                toWebsite: true,
              })
            : new Promise((_resolve, reject) => {
                reject(new Error(t([I18N_SCOPE, 'account_not_exist_error'])));
              }),
        )
        .finally(() => {
          setIsRequest(false);
        });
    },
    [authStore, usePhone],
  );

  const onApiResponse = useCallback(
    response => {
      if (onData) {
        onData(response.expires, 'codeExpires');
        onData(field.value, usePhone ? 'phone' : 'email');
        onData(undefined, !usePhone ? 'phone' : 'email');
      }

      storage.setItem(
        ACCOUNT_STORAGE_KEY,
        JSON.stringify({
          type: usePhone ? 'phone' : 'email',
          value: field.value,
          codeExpires: response.expires,
        }),
      );
    },
    [field.value, onData, usePhone],
  );

  return (
    <FormStep
      icon={usePhone ? <PhoneSVG /> : <MailSVG />}
      field={field}
      fieldName={usePhone ? 'phone' : 'email'}
      onSubmit={onSubmit}
      onChange={onChange}
      onResponse={onApiResponse}
      onClick={onClick}
    >
      <Title>{t([I18N_SCOPE, 'title_welcome_back'])}</Title>
      <Description>
        {t([I18N_SCOPE, `enter_${usePhone ? 'phone' : 'email'}_label`])}
      </Description>
      <Observer>
        {() => (
          <TextField
            placeholder={t([
              I18N_SCOPE,
              `${usePhone ? 'phone' : 'email'}_placeholder`,
            ])}
            value={field.value}
            onChange={field.onChange}
            error={field.error}
            autoFocus
          />
        )}
      </Observer>
      <Button
        data-action={`use${usePhone ? 'Email' : 'Phone'} replace`}
        className={styles.marginTop20 + ' ' + styles.grey}
        onClick={onClick}
      >
        {t([I18N_SCOPE, `use_${usePhone ? 'email' : 'phone'}_button`])}
      </Button>
      <ButtonsContainer>
        <Button className={styles.inverted} onClick={onClick}>
          {t('Common.Back')}
        </Button>
        <SpinnerOverlay isActive={isRequest}>
          <Button data-action="next" className={styles.middle}>
            {isRequest ? <>&nbsp;</> : t([I18N_SCOPE, 'send_code_button'])}
          </Button>
        </SpinnerOverlay>
      </ButtonsContainer>
      <AltNavRow>
        {t([I18N_SCOPE, 'sign_up1_label'])}&nbsp;
        <a href="#" onClick={onClick} data-action="signUp replace">
          {t([I18N_SCOPE, 'sign_up2_label'])}
        </a>
      </AltNavRow>
    </FormStep>
  );
};

export default SignInStep;
