import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {useAppRedirect} from '@src/common/useAppRedirect';
import Modal from '@src/components/ModalNew';
import localAppStore from '@src/context/appStore';
import {MainContainerWithMenu} from '@src/layouts';

import EditCoachProfile from '../EditCoachProfile/EditCoachProfile';
import EditProfile from '../EditProfile/EditProfile';

interface Props {}

interface ILocalStore {
  showModalEditUser: boolean;
  showModalEditPractice: boolean;
  toggleVisModalEditUser(isShow: boolean): void;
  toggleVisModalEditPractice(isShow: boolean): void;
}

const FirstUserRegister: FC<Props> = () => {
  const redirect = useAppRedirect('/');
  const localStore = useRef(
    observable<ILocalStore>(
      {
        showModalEditUser: true,
        showModalEditPractice: false,
        toggleVisModalEditUser(isShow: boolean) {
          this.showModalEditUser = isShow;
        },
        toggleVisModalEditPractice(isShow: boolean) {
          this.showModalEditPractice = isShow;
        },
      },
      {
        showModalEditUser: observable,
        showModalEditPractice: observable,
        toggleVisModalEditUser: action,
        toggleVisModalEditPractice: action,
      },
    ),
  ).current;
  const closeModalEditUser = () => {
    localStore.toggleVisModalEditUser(false);
  };

  const endEditProfile = (showPracticeEditor: boolean = false) => {
    localStore.toggleVisModalEditUser(false);

    if (showPracticeEditor) {
      localStore.toggleVisModalEditPractice(true);
    } else {
      const path = localAppStore?.returnPath ? localAppStore?.returnPath : '/';

      redirect(path);
    }
  };

  const closeModalEditPractice = () => {
    localStore.toggleVisModalEditPractice(false);
  };

  const endEditPractice = () => {
    const path = localAppStore?.returnPath ? localAppStore?.returnPath : '/';

    localStore.toggleVisModalEditPractice(false);

    redirect(path);
  };

  return (
    <Observer>
      {() => (
        <MainContainerWithMenu>
          <div className="FirstUserRegister">
            <Modal
              isOpen={localStore.showModalEditUser}
              onAfterClose={closeModalEditUser}
            >
              <EditProfile isFirstEdit endEditCallBack={endEditProfile} />
            </Modal>
            <Modal
              isOpen={localStore.showModalEditPractice}
              onAfterClose={closeModalEditPractice}
            >
              <EditCoachProfile isFirstEdit endEditCallBack={endEditPractice} />
            </Modal>
          </div>
        </MainContainerWithMenu>
      )}
    </Observer>
  );
};

export default memo(FirstUserRegister);
