import type {DateTimeObj} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {goalStore} from '@yourcoach/shared/api/goal';
import type {
  CoachGoalsUnboundCreateParams,
  CoachSubgoalsUnboundCreateParams,
} from '@yourcoach/shared/api/types/methods';
import {isDefined} from '@yourcoach/shared/utils';

import {expand as goalExpand} from '../../../models/goals';

import type {Goal, PreviousGoal} from './types';

export class GoalsDataService {
  goalsStore: typeof goalStore;

  constructor(globalGoalStore: typeof goalStore) {
    this.goalsStore = globalGoalStore;
  }

  createGoalsInCourse = async (
    goals: Goal[] | PreviousGoal[],
    course: Course,
  ) => {
    let goalBatchParams: CoachGoalsUnboundCreateParams[] = [];
    let subGoalBatchParams: CoachSubgoalsUnboundCreateParams[] = [];

    goals.forEach((goal, goalIndex) => {
      goalBatchParams.push({
        parent_goal_id: goal._id.includes('goal') ? goal._id : null,
        program_id: course.program_id,
        course_id: course._id,
        title: goal.title,
        description: goal.description,
        sort_order: goalIndex * 10,
        is_client_managed: goal.is_client_managed,
        expand: goalExpand,
      });

      if (GoalsDataService.isPreviousGoal(goal)) {
        goal.subgoals.forEach((subGoal, subGoalIndex) => {
          subGoalBatchParams.push({
            goal_id: goal._id,
            title: subGoal.title,
            description: subGoal.description,
            icon: subGoal.icon,
            color: subGoal.color,
            start_date: subGoal.start_date as DateTimeObj,
            end_date: subGoal.end_date as DateTimeObj,
            unit: subGoal.unit,
            initial_value: subGoal.initial_value,
            target_value: subGoal.target_value,
            checkpoints: subGoal.checkpoints.map(
              checkpoint => checkpoint.title,
            ),
            sort_order: subGoalIndex * 10,
          });
        });
      }
    });

    const createdGoals = await this.goalsStore.createBatch(goalBatchParams);

    if (subGoalBatchParams.length > 0) {
      subGoalBatchParams = subGoalBatchParams
        .map(paramObj => {
          const mainGoal = createdGoals.find(
            goal => goal.parent_goal_id === paramObj.goal_id,
          );

          if (mainGoal) {
            return {...paramObj, goal_id: mainGoal._id};
          }
        })
        .filter(isDefined);

      await this.goalsStore.sub.createBatch(subGoalBatchParams);

      return 0;
    }
  };

  private static isPreviousGoal(
    goal: Goal | PreviousGoal,
  ): goal is PreviousGoal {
    return Boolean((goal as PreviousGoal).subgoals);
  }
}
