import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';

const Main = styled(View)`
  flex: 1;
`;

const Aside = styled(View)`
  width: 400px;
`;

const Layout = {
  Container: View,
  Main,
  Aside,
};

export {Layout};
