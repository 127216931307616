import 'cross-fetch/polyfill';
import '@yourcoach/shared/utils/lib.es5.d';

import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import App from '@src/App';
import {Provider as AppContextProvider} from '@src/context/App';

render(
  <Router>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </Router>,
  document.getElementById('root'),
);

// TODO: Figure out why HMR reloads entire page without this
// @ts-ignore
if (process.env.APP_ENV === 'dev' && module.hot) {
  // @ts-ignore
  module.hot.accept();
}
