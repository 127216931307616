import React, {memo, useContext, useMemo} from 'react';

import LogoSVG from '@yourcoach/shared/assets/icons/logo.svg';
import LogoTextSVG from '@yourcoach/shared/assets/icons/logo-text.svg';

import AppContext from '@src/context/App';
import OnboardingSteps from '@src/pages/Onboarding/Steps';

import {ClientFlow, EmployeeFlow, InviteFlow} from './Flows';
import styles from './styles.module.css';

const Onboarding = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const accountType = currentUserStore.user?.metadata?.onboarding?.account_type;

  const flows = useMemo(
    () => ({
      ['invited-client']: InviteFlow,
      client: ClientFlow,
      employee: EmployeeFlow,
    }),
    [],
  );

  const steps = useMemo(
    () => (accountType ? flows[accountType] || ClientFlow : ClientFlow),
    [accountType, flows],
  );

  return (
    <>
      <div className={styles.background}>
        <div className={styles.leftBox}>
          <div className={styles.logo}>
            <LogoSVG id={styles.logoId} />
            <LogoTextSVG id={styles.textId} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <OnboardingSteps steps={steps} />
      </div>
    </>
  );
};

export default memo(Onboarding);
