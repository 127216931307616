import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {ModalWindowContainer} from '../../modules';
import AnimateShow from '../AnimateShow/AnimateShow';
import CustomConfetti from '../CustomConfetti/CustomConfetti';
import Modal from '../Modal/Modal';

interface Props {
  children: any;
  showModal: boolean;
  hideCloseBut?: boolean;
  className?: string;
  classNameCloseBut?: string;
  isBody?: boolean;
  classNameBody?: string;
  header?: string;
  classNameHeader?: string;
  isCongratAnim?: boolean;
  closeModalHandler: () => void;
}

const GoalsModal: FC<Props> = ({
  children,
  showModal,
  hideCloseBut,
  className = '',
  isBody = false,
  isCongratAnim = false,
  classNameBody = '',
  header = '',
  classNameHeader = '',
  classNameCloseBut = '',
  closeModalHandler,
}) => {
  const [isOpen, togOpen] = useState(showModal);
  const [isAnimate, togAnimate] = useState(false);

  const closeModal = () => {
    closeModalHandler();
  };

  const handleCallBackEndAnim = () => {
    togOpen(false);
  };

  useEffect(() => {
    if (showModal) {
      togOpen(showModal);
      togAnimate(true);
    } else {
      togAnimate(false);
    }

    return () => {};
  }, [showModal]);

  return (
    <>
      {isOpen ? (
        <Modal>
          {isCongratAnim ? <CustomConfetti /> : null}
          <AnimateShow
            isAnimateShow={isAnimate}
            callBackHide={handleCallBackEndAnim}
          >
            <ModalWindowContainer
              closeModalWindowContainer={hideCloseBut ? undefined : closeModal}
              className={className}
              classNameCloseBut={classNameCloseBut}
            >
              {isBody ? (
                <>
                  <div className={classNameHeader}>
                    <h2>{header}</h2>
                  </div>
                  <div className={classNameBody}>{children}</div>
                </>
              ) : (
                children
              )}
            </ModalWindowContainer>
          </AnimateShow>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(GoalsModal);
