import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  gap: 10px;
  align-items: center;
`;

const Cell = styled(View)`
  gap: 10px;
  &:before {
    content: '•';
    color: ${getColor('text3')};
  }
  :nth-child(1) {
    &:before {
      display: none;
    }
  }
`;

export const Layout = {
  Container,
  Cell,
};
