import type {HTMLProps, MouseEventHandler} from 'react';
import React from 'react';

import classNames from 'classnames';

import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';

import styles from './Checkbox.module.css';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
  checked?: boolean;
  label?: string | React.ReactElement;
  onClick?: MouseEventHandler;
  className?: string;
  isDisabled?: boolean;
  variant?: 'round';
}

const Checkbox: React.FC<Props> = ({
  checked,
  label,
  onClick,
  className,
  variant,
  isDisabled,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'Checkbox',
        styles.Checkbox,
        variant && styles[variant],
        isDisabled && styles.disabled,
        className,
      )}
      onClick={!isDisabled ? onClick : undefined}
      {...props}
    >
      <div className={styles.checkMark}>{checked ? <CheckIcon /> : null}</div>
      {label ? <p className={styles.label}>{label}</p> : null}
    </div>
  );
};

export default React.memo(Checkbox);
