import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';

import CourseMaterials from '../SeeProgram/Squad/CourseMaterials/CourseMaterials';

import LibraryContext from './context/LibraryContext';
import LibraryState from './context/LibraryState';
import type {ILibraryLocalStore} from './context/useLibraryLocalStore';
import ListFolderMaterials from './ListFolderMaterials/ListFolderMaterials';
import styles from './styles.module.css';

interface Props {}

const MyLibrary: FC<Props> = () => {
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const [courseId, setCourseId] = useState('');

  useEffect(() => {
    return () => {
      if (libraryStory) {
        libraryStory.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LibraryState>
      <div className={`MyLibrary ${styles.MyLibrary}`}>
        {courseId ? (
          <CourseMaterials
            isMedium
            courseId={courseId}
            forCoach={false}
            onClickBackButton={() => setCourseId('')}
          />
        ) : (
          <ListFolderMaterials onSelectCourse={setCourseId} />
        )}
      </div>
    </LibraryState>
  );
};

export default memo(MyLibrary);
