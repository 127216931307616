import {useCallback, useEffect, useRef, useState} from 'react';

const useSelection = (
  value: number[],
  isMultiSelect: boolean = false,
  maxSelection: number = 0,
  onChange?: (_: number[]) => void,
  onBeforeSelect?: (index: number, willSelect: boolean) => boolean,
) => {
  const [selection, setSelection] = useState(value);
  const clicked = useRef(false);

  const onItemClick = useCallback(
    index => {
      clicked.current = true;

      if (isMultiSelect) {
        if (selection.indexOf(index) >= 0) {
          if (!onBeforeSelect || onBeforeSelect(index, false)) {
            setSelection(selection.filter(x => x !== index));
          }
        } else {
          if (maxSelection <= 0 || selection.length < maxSelection) {
            if (!onBeforeSelect || onBeforeSelect(index, true)) {
              setSelection(selection.concat(index));
            }
          }
        }
      } else {
        setSelection([index]);
      }
    },
    [isMultiSelect, maxSelection, onBeforeSelect, selection],
  );

  const onInsert = useCallback(
    (index, include) => {
      setSelection(
        selection
          .map(i => i + (i >= index ? 1 : 0))
          .concat(...(include ? [index + 1] : [])),
      );
    },
    [selection],
  );

  const onRemove = useCallback(
    index => {
      setSelection(
        selection.filter(i => i !== index).map(i => i - (i >= index ? 1 : 0)),
      );
    },
    [selection],
  );

  useEffect(() => {
    if (clicked.current && onChange) {
      onChange(selection);
    }

    clicked.current = false;
  }, [selection, onChange]);

  return {selection, setSelection, onItemClick, onInsert, onRemove};
};

export const bindIndex = (index, callback) => {
  return event => {
    callback(index, event);
  };
};

export {useSelection};
