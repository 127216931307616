import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Helmet} from 'react-helmet';
import type {RouteComponentProps} from 'react-router-dom';
import {useHistory, useParams} from 'react-router-dom';
import {OTPublisher, OTSession, OTSubscriber} from 'opentok-react';
import type {
  Event,
  EventHandler,
  Session,
  SessionConnectEvent,
  SessionEventHandlers,
  SignalEvent,
  Stream as OtStream,
  StreamCreatedEvent,
  StreamDestroyedEvent,
  StreamPropertyChangedEvent,
  SubscriberEventHandlers,
  VideoEnabledChangedEvent,
} from 'opentok-react/types/opentok';

import approx from 'approximate-number';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {t} from 'i18n-js';
import {reaction} from 'mobx';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {Conference} from '@yourcoach/shared/api/conference';
import {
  conferenceIsEnded,
  conferenceIsExpired,
  conferenceIsStarted,
  getConferenceAbleToStartDate,
} from '@yourcoach/shared/api/conference';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import CameraOffIcon from '@yourcoach/shared/assets/icons/camera-off.svg';
import CloseIcon from '@yourcoach/shared/assets/icons/close.svg';
import MicIcon from '@yourcoach/shared/assets/icons/mic.svg';
import MicOffIcon from '@yourcoach/shared/assets/icons/mic-off.svg';
import {waitFor} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Button from '@src/components/Button';
import {
  confirm,
  getCustomConfirmAlert,
} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import useToggle from '@src/hooks/useToggle';
import LogoOnlyLayout from '@src/layouts/LogoOnly';
import type {Expanded as ConferenceExpanded} from '@src/modules/conferences/utils';
import {expand as conferenceExpand} from '@src/modules/conferences/utils';

import {useSessionType} from './hooks';
import styles from './styles.module.css';

const I18N_SCOPE = 'Conference';

const FORCE_DISCONNECT_SIGNAL_TYPE = 'forceDisconnect';

const MAX_CONNECTIONS = 2;

interface Stream extends OtStream {
  userId: string;
  avatar?: string;
  role?: 'coach' | 'co-coach' | 'client';
  isCreator?: boolean;
  isPublisher?: boolean;
}

interface NavigationParams {
  conferenceId?: string;
}

type ConferenceT = Conference & ConferenceExpanded;

type Props = RouteComponentProps<NavigationParams>;

const ConferencePage: FC<Props> = () => {
  const {
    stores: {conferenceStore, eventStore, currentUserStore},
  } = useContext(AppContext);

  const history = useHistory();
  const {conferenceId} = useParams<NavigationParams>();

  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [status, setStatus] = useState<
    'connecting' | 'joining' | 'disconnecting' | 'connected'
  >('connecting');
  const [conference, setConference] = useState<ConferenceT>();
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [streams, setStreams] = useState<Stream[]>([]);
  const [videoIsDisabled, toggleVideoIsDisabled] = useToggle(false);
  const [isMuted, toggleIsMuted] = useToggle(false);
  const {onChangeSessionType, getSessionType} = useSessionType();

  // used in memo
  const [currentUserId, setCurrentUserId] = useState(
    (currentUserStore.user && currentUserStore.user._id) || '',
  );

  const isReady = useMemo(
    () => scriptIsLoaded && sessionId && token,
    [scriptIsLoaded, sessionId, token],
  );

  const otSession = useRef<Session>();

  useEffect(() => {
    waitFor(
      // @ts-ignore
      () => global.OT,
      () => {
        setScriptIsLoaded(true);
      },
    );
  }, []);

  useEffect(() => {
    const dispose = reaction(
      () => currentUserStore.user && currentUserStore.user._id,
      id => {
        setCurrentUserId(id || '');
      },
      {fireImmediately: true},
    );

    return dispose;
  }, [currentUserStore.user]);

  const fetchConference = useCallback(async () => {
    if (conferenceId) {
      try {
        let fetchedConference: ConferenceT;

        try {
          fetchedConference = (await conferenceStore.client.fetch({
            _id: conferenceId,
            expand: conferenceExpand,
          })) as ConferenceT;
        } catch (error) {
          fetchedConference = (await conferenceStore.coach.fetch({
            _id: conferenceId,
            expand: conferenceExpand,
          })) as ConferenceT;
        }

        if (conferenceIsExpired(fetchedConference)) {
          throw {
            message: t([I18N_SCOPE, 'conference_expired_message']),
          };
        } else if (conferenceIsEnded(fetchedConference)) {
          throw {
            message: t([I18N_SCOPE, 'conference_ended_message']),
          };
        } else if (!conferenceIsStarted(fetchedConference)) {
          if (
            dayjs() <
            dayjs(
              datetimeObjToISOString(
                getConferenceAbleToStartDate(fetchedConference),
              ),
            )
          ) {
            throw {
              message: t([I18N_SCOPE, 'conference_upcoming_message'], {
                date: dayjs(
                  datetimeObjToISOString(fetchedConference.start_date),
                ).format('lll'),
              }),
            };
          } else if (
            !fetchedConference.coach_ids.includes(currentUserStore.user!._id)
          ) {
            throw {
              message: t([I18N_SCOPE, 'conference_not_started_message']),
            };
          }
        }

        eventStore.markReadAll({
          query: [['context_ids', 'in', [conferenceId]]],
          limit: 500,
        });

        setConference(fetchedConference);
      } catch (error) {
        logger.error(error);

        getCustomConfirmAlert({
          title: t('shared.message.error'),
          message: error.message,
          buttons: [
            {
              label: t('shared.button.ok'),
              onClick: () => {
                history.goBack();
              },
            },
          ],
        });
      }
    }
  }, [
    conferenceId,
    conferenceStore.client,
    conferenceStore.coach,
    currentUserStore.user,
    eventStore,
    history,
  ]);

  useEffect(() => {
    setStatus('connecting');
    setSessionId('');
    setToken('');

    fetchConference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceId]);

  const start = useCallback(async () => {
    try {
      const result = await conferenceStore.coach.start({
        _id: conferenceId,
      } as Conference);

      setSessionId(result.conference.session_id!);
      setToken(result.token);
      setStatus('connecting');
      setConference({
        ...conference,
        moderator_id: result?.conference?.moderator_id,
      } as ConferenceT);

      logger.event('conference_started');
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'start_conference_error_message']),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    }
  }, [conference, conferenceId, conferenceStore.coach, history]);

  const joinAs = useCallback(
    async (who: 'coach' | 'client') => {
      try {
        const result = await conferenceStore[who].join({
          _id: conferenceId,
        } as Conference);

        return result as {conference: ConferenceT; token: string};
      } catch (error) {
        throw error;
      }
    },
    [conferenceId, conferenceStore],
  );

  const join = useCallback(async () => {
    try {
      let result: {conference: ConferenceT; token: string};

      if (!conference || token) {
        return;
      }

      if (conference.coach_ids.includes(currentUserStore.user!._id)) {
        result = await joinAs('coach');
      } else {
        result = await joinAs('client');
      }

      setSessionId(result.conference.session_id!);
      setToken(result.token);
      setStatus('connecting');
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'join_conference_error_message']),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {
              history.goBack();
            },
          },
          {
            label: t('shared.button.try_again'),
            type: 'attention',
            onClick: join,
          },
        ],
      });
    }
  }, [conference, currentUserStore.user, history, joinAs, token]);

  const stop = useCallback(async () => {
    let prevStatus: typeof status = 'connected';

    try {
      setStatus(prev => {
        prevStatus = prev;

        return 'disconnecting';
      });

      await conferenceStore.coach.stop(conference!, {
        conference_type: getSessionType(),
      });

      if (prevStatus === 'connected' && otSession.current) {
        otSession.current.signal({
          type: FORCE_DISCONNECT_SIGNAL_TYPE,
          data: '',
        });
      } else {
        history.goBack();
      }

      logger.event('conference_stopped');
    } catch (error) {
      logger.error(error);

      setStatus(prevStatus);

      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'stop_conference_error_message']),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
          },
          {
            label: t('shared.button.try_again'),
            type: 'attention',
            onClick: stop,
          },
        ],
      });
    }
  }, [conference, conferenceStore.coach, history, getSessionType]);

  useEffect(() => {
    if (conference && !sessionId) {
      if (conferenceIsStarted(conference)) {
        join();
      } else if (conference.coach_ids.includes(currentUserStore.user!._id)) {
        start();
      }
    }
  }, [conference, currentUserStore.user, join, sessionId, start]);

  const isCoachConference = useMemo(
    () => conference && conference?.moderator_id === currentUserStore.user!._id,
    [conference, currentUserStore.user],
  );

  const onCloseButtonClick = useCallback(async () => {
    if (isCoachConference) {
      if (
        await confirm({
          title: t([I18N_SCOPE, 'stop_conference_confirm_message']),
          buttons: [
            t([I18N_SCOPE, 'stop_conference_confirm_cancel_button']),
            t([I18N_SCOPE, 'stop_conference_confirm_success_button']),
          ],
        })
      ) {
        await stop();
      }
    } else {
      history.goBack();
    }
  }, [isCoachConference, history, stop]);

  const streamsCount = useMemo(() => streams.length, [streams.length]);

  const creatorStream = useMemo(
    () => streams.find(stream => stream.isCreator),
    [streams],
  );

  const currentUserStreams = useMemo(
    () =>
      streams.filter(stream => stream.userId === currentUserStore.user!._id),
    [currentUserStore.user, streams],
  );

  useEffect(() => {
    if (currentUserStreams.length + 1 > MAX_CONNECTIONS) {
      if (otSession.current) {
        otSession.current.disconnect();
      }

      getCustomConfirmAlert({
        title: t('shared.message.error'),
        message: t([I18N_SCOPE, 'max_connections_message'], {
          devicesCount: currentUserStreams.length,
        }),
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    }
  }, [currentUserStore.user, currentUserStreams, history]);

  const coachesStreams = useMemo(
    () => streams.filter(stream => stream.role && stream.role !== 'client'),
    [streams],
  );

  const clientsStreams = useMemo(
    () => streams.filter(stream => !stream.role || stream.role === 'client'),
    [streams],
  );

  const isOneToOne = useMemo(
    () =>
      conference
        ? [
            ...conference.coach_ids,
            conference.client_id,
            conference.course?.client_id,
            ...new Array(
              conference.course
                ? conference.course.counters.memberships.accepted || 0
                : 0,
            ).fill('accepted_membership'),
          ].filter(Boolean).length === 2
        : false,

    [conference],
  );

  const isClientConference = useMemo(
    () => (conference ? conference.created_by === 'client' : false),
    [conference],
  );

  const participantName = useMemo(() => {
    if (!conference) {
      return '';
    }

    const {coaches, coach_ids, client, course} = conference;

    if (isClientConference && coaches.length && client) {
      if (coach_ids.includes(currentUserId)) {
        return client.name;
      }

      return coaches[0].name;
    } else if (
      course &&
      course.client_id &&
      conference.coach_ids.includes(currentUserId)
    ) {
      return course.client.name;
    }

    return '';
  }, [conference, currentUserId, isClientConference]);

  const conferenceTitle = useMemo(() => {
    if (conference) {
      if (conference.created_by === 'client') {
        return conference.program_id ? 'Live session' : 'Free intro call';
      }

      return conference.title || '';
    }

    return '';
  }, [conference]);

  const statusText = useMemo(() => {
    if (!conference) {
      return '';
    }

    let result = t([I18N_SCOPE, `${status}_label`]);

    if (status === 'connected') {
      if (isOneToOne) {
        if (!streamsCount) {
          result = t([I18N_SCOPE, 'waiting_label']);
        }
      } else if (conference.moderator_id === currentUserId) {
        result = streamsCount
          ? t([I18N_SCOPE, 'viewers_label'], {
              count: streamsCount,
              formattedCount: approx(streamsCount),
            })
          : t([I18N_SCOPE, 'waiting_viewers_label']);
      } else {
        result =
          streamsCount && creatorStream
            ? t([I18N_SCOPE, 'viewers_label'], {
                count: streamsCount,
                formattedCount: approx(streamsCount),
              })
            : t([I18N_SCOPE, 'waiting_coach_label']);
      }
    }

    return result;
  }, [
    conference,
    creatorStream,
    currentUserId,
    isOneToOne,
    status,
    streamsCount,
  ]);

  const onVideoButtonClick = useCallback(() => {
    onChangeSessionType(!videoIsDisabled);
    toggleVideoIsDisabled();
  }, [onChangeSessionType, toggleVideoIsDisabled, videoIsDisabled]);

  const onMuteButtonClick = useCallback(() => {
    toggleIsMuted();
  }, [toggleIsMuted]);

  const onSessionError = useCallback(
    error => {
      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    },
    [history],
  );

  const onSessionConnected: EventHandler<SessionConnectEvent> = useCallback(
    e => {
      otSession.current = e.target as Session;

      setStatus('connected');
    },
    [],
  );

  const onSessionReconnected = useCallback(() => {
    setStatus('connected');
  }, []);

  const onSessionReconnecting = useCallback(() => {
    setStatus('connecting');
  }, []);

  const onSessionDisconnected = useCallback(() => {
    setStatus('connecting');
  }, []);

  const onSignal: EventHandler<SignalEvent> = useCallback(
    e => {
      if (!conference) {
        return;
      }

      if (e.type === `signal:${FORCE_DISCONNECT_SIGNAL_TYPE}`) {
        if (!isCoachConference) {
          let moderatorName = 'Your Coach';

          if (conference.course && conference.course.program) {
            const coach = conference.course.program.expanded_coaches.find(
              item => item._id === conference.moderator_id,
            );

            if (coach) {
              moderatorName = coach.name;
            }
          }

          getCustomConfirmAlert({
            title: t([I18N_SCOPE, 'coach_stopped_conference_message'], {
              name: moderatorName,
            }),
            buttons: [
              {
                label: t('shared.button.ok'),
                onClick: () => {
                  onCloseButtonClick();
                },
              },
            ],
          });
        } else {
          history.goBack();
        }
      }
    },
    [conference, history, isCoachConference, onCloseButtonClick],
  );

  const onStreamCreated: EventHandler<StreamCreatedEvent> = useCallback(
    e => {
      if (!conference) {
        return;
      }

      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        if (index >= 0) {
          return prev;
        }

        const {stream} = e;

        const [name, id, avatar] = stream.name.split('|');

        const isCreator = conference.moderator_id === id;

        let role: Stream['role'];

        if (conference.coach_ids.includes(id)) {
          role = 'coach';

          if (conference.course?.user_id !== id) {
            role = 'co-coach';
          }
        }

        return [
          ...prev,
          {
            ...stream,
            isCreator,
            userId: id,
            name,
            avatar,
            role,
          },
        ];
      });
    },
    [conference],
  );

  const onStreamDestroyed: EventHandler<StreamDestroyedEvent> = useCallback(
    e => {
      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        if (index >= 0) {
          prev.splice(index, 1);
        }

        return [...prev];
      });
    },
    [],
  );

  const streamPropertyChanged: EventHandler<StreamPropertyChangedEvent> =
    useCallback(e => {
      setStreams(prev => {
        const index = prev.findIndex(
          stream => stream.streamId === e.stream.streamId,
        );

        e.stream[e.changedProperty] = e.newValue;

        if (index >= 0) {
          prev[index][e.changedProperty] = e.newValue;

          prev[index] = {
            ...prev[index],
            [e.changedProperty]: e.newValue,
          };

          return [...prev];
        }

        return prev;
      });
    }, []);

  const otSessionEventHandlers: SessionEventHandlers = useMemo(
    () => ({
      sessionConnected: onSessionConnected,
      sessionReconnected: onSessionReconnected,
      sessionReconnecting: onSessionReconnecting,
      sessionDisconnected: onSessionDisconnected,
      signal: onSignal,
      streamCreated: onStreamCreated,
      streamDestroyed: onStreamDestroyed,
      streamPropertyChanged: streamPropertyChanged,
    }),
    [
      onSessionConnected,
      onSessionDisconnected,
      onSessionReconnected,
      onSessionReconnecting,
      onSignal,
      streamPropertyChanged,
      onStreamCreated,
      onStreamDestroyed,
    ],
  );

  const getStreamsContainerClassName = useCallback(
    (role?: 'coach') => {
      const streamsLength =
        role === 'coach' ? coachesStreams.length : clientsStreams.length;

      return classNames(
        styles.streams,
        role === 'coach' ? styles.coachesStreams : styles.clientsStreams,
        streamsLength > 2 && styles.moreThan2,
        streamsLength > 6 && styles.moreThan6,
      );
    },
    [clientsStreams.length, coachesStreams.length],
  );

  const onSubscriberVideoEnabled: EventHandler<
    VideoEnabledChangedEvent<'videoEnabled'>
  > = useCallback(
    e => {
      // @ts-ignore
      streamPropertyChanged({
        changedProperty: 'hasVideo',
        newValue: true,
        oldValue: false,
        // @ts-ignore
        stream: e.target.stream,
      });
    },
    [streamPropertyChanged],
  );

  const onSubscriberVideoDisabled: EventHandler<
    VideoEnabledChangedEvent<'videoDisabled'>
  > = useCallback(
    e => {
      // @ts-ignore
      streamPropertyChanged({
        changedProperty: 'hasVideo',
        newValue: false,
        oldValue: true,
        // @ts-ignore
        stream: e.target.stream,
      });
    },
    [streamPropertyChanged],
  );

  const onSubscriberAudioBlocked: EventHandler<Event<'audioBlocked'>> =
    useCallback(
      e => {
        // @ts-ignore
        streamPropertyChanged({
          changedProperty: 'hasAudio',
          newValue: false,
          oldValue: true,
          // @ts-ignore
          stream: e.target.stream,
        });
      },
      [streamPropertyChanged],
    );

  const onSubscriberAudioUnblocked: EventHandler<Event<'audioUnblocked'>> =
    useCallback(
      e => {
        // @ts-ignore
        streamPropertyChanged({
          changedProperty: 'hasAudio',
          newValue: true,
          oldValue: false,
          // @ts-ignore
          stream: e.target.stream,
        });
      },
      [streamPropertyChanged],
    );

  return (
    <LogoOnlyLayout v2 contentClassName={styles.page}>
      <Helmet title={'Live session'}>
        <script src="https://static.opentok.com/v2/js/opentok.min.js" defer />
      </Helmet>
      <div className={styles.container}>
        {!isReady ? (
          <Loader />
        ) : (
          <div className={styles.conference}>
            <div className={styles.topBar}>
              <div>
                <p className={styles.status}>{conferenceTitle}</p>
                <p className={styles.status}>
                  {isClientConference
                    ? participantName
                    : conference?.course?.program.title || ''}
                </p>
              </div>
              <p className={styles.status}>{statusText}</p>
            </div>
            <div className={styles.otSessionContainer}>
              <OTSession
                apiKey={process.env.OPENTOK_KEY!}
                sessionId={sessionId}
                token={token}
                onError={onSessionError}
                eventHandlers={otSessionEventHandlers}
              >
                <div
                  className={classNames(
                    styles.streamsContainer,
                    isOneToOne && styles.oneToOne,
                  )}
                >
                  <div className={getStreamsContainerClassName('coach')}>
                    <Publisher
                      type={'coach'}
                      conference={conference}
                      isMuted={isMuted}
                      videoIsDisabled={videoIsDisabled}
                    />
                    {coachesStreams.map(stream => (
                      <Stream
                        stream={stream}
                        key={stream.streamId}
                        isOneToOne={isOneToOne}
                        videoDisabled={onSubscriberVideoDisabled}
                        videoEnabled={onSubscriberVideoEnabled}
                        audioBlocked={onSubscriberAudioBlocked}
                        audioUnblocked={onSubscriberAudioUnblocked}
                      />
                    ))}
                  </div>
                  <div className={getStreamsContainerClassName()}>
                    <Publisher
                      type={'client'}
                      conference={conference}
                      isMuted={isMuted}
                      videoIsDisabled={videoIsDisabled}
                    />
                    {clientsStreams.map(stream => (
                      <Stream
                        stream={stream}
                        key={stream.streamId}
                        isOneToOne={isOneToOne}
                        videoDisabled={onSubscriberVideoDisabled}
                        videoEnabled={onSubscriberVideoEnabled}
                        audioBlocked={onSubscriberAudioBlocked}
                        audioUnblocked={onSubscriberAudioUnblocked}
                      />
                    ))}
                  </div>
                </div>
              </OTSession>
            </div>
            <div className={styles.actionsBar}>
              <div>
                <Button
                  onClick={onVideoButtonClick}
                  className={styles.actionButton}
                >
                  {videoIsDisabled ? <CameraOffIcon /> : <CameraIcon />}
                </Button>
                <Button
                  onClick={onMuteButtonClick}
                  className={styles.actionButton}
                >
                  {isMuted ? <MicOffIcon /> : <MicIcon />}
                </Button>
              </div>
              <div>
                <Button
                  onClick={onCloseButtonClick}
                  className={classNames(styles.actionButton, styles.danger)}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </LogoOnlyLayout>
  );
};

const Stream: React.FC<{
  stream: Stream;
  isOneToOne?: boolean;
  videoEnabled?: SubscriberEventHandlers['videoEnabled'];
  videoDisabled?: SubscriberEventHandlers['videoDisabled'];
  audioBlocked?: SubscriberEventHandlers['audioBlocked'];
  audioUnblocked?: SubscriberEventHandlers['audioUnblocked'];
}> = React.memo(
  ({
    stream,
    isOneToOne,
    videoEnabled,
    videoDisabled,
    audioBlocked,
    audioUnblocked,
  }) => (
    <div
      className={classNames(styles.stream, stream.hasVideo && styles.hasVideo)}
    >
      <OTSubscriber
        stream={stream}
        onError={error => {
          logger.error(error);
        }}
        eventHandlers={{
          videoEnabled,
          videoDisabled,
          audioBlocked,
          audioUnblocked,
        }}
        properties={{
          showControls: false,
          subscribeToAudio: stream.hasAudio,
          subscribeToVideo: stream.hasVideo,
        }}
      />
      {stream.hasVideo ? (
        <>
          {!isOneToOne ? (
            <p className={styles.streamBadge}>
              {`${stream.name} ${stream.role ? ` (${stream.role})` : ''}`}
            </p>
          ) : null}
        </>
      ) : (
        <>
          <div className={styles.streamAvatarContainer}>
            <Image
              src={stream.avatar || ''}
              placeholder={<AvatarPlaceholder name={stream.name} />}
              className={styles.streamAvatar}
            />
            <p className={styles.streamName}>{stream.name}</p>
          </div>
        </>
      )}
      {!stream.hasAudio ? (
        <MicOffIcon className={styles.streamNoAudioIcon} />
      ) : null}
    </div>
  ),
);

const Publisher: React.FC<{
  conference?: ConferenceT;
  type: 'coach' | 'client';
  isMuted: boolean;
  videoIsDisabled: boolean;
}> = ({conference, type, isMuted, videoIsDisabled}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  if (!conference) {
    return null;
  }

  const onPublisherError = error => {
    let title = 'Oops, something went wrong!';
    let message = error.message;

    // for web we should use error.name but for mobile error.code
    const errorCode = error.name || error.code;

    // @see https://tokbox.com/developer/sdks/js/reference/Error.html
    switch (errorCode) {
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        message =
          'Please allow your browser to use your camera & microphone to successfully connect live sessions';
        break;
      case 'OT_TIMEOUT':
      case 'ConnectionFailed':
      case 'ConnectionDropped':
        title = 'Poor network connection';
        message =
          'Video and/or audio connection can not be established due to weak Internet connection. Please check your network';
        break;
      default:
        break;
    }

    message += ` (${errorCode || 'UNKNOWN_ERROR'})`;

    getCustomConfirmAlert({
      title,
      message,
      buttons: [
        {
          label: 'OK',
          onClick: () => {},
        },
      ],
    });

    logger.error(error);
  };

  if (
    (type === 'coach' &&
      conference.coach_ids.includes(currentUserStore.user!._id)) ||
    (type === 'client' &&
      !conference.coach_ids.includes(currentUserStore.user!._id))
  ) {
    return (
      <div
        className={classNames(
          styles.stream,
          styles.publisher,
          !videoIsDisabled && styles.hasVideo,
        )}
      >
        <OTPublisher
          properties={{
            publishVideo: !videoIsDisabled,
            publishAudio: !isMuted,
            name: `${currentUserStore.user!.name}|${
              currentUserStore.user!._id
            }|${getFileSrc(currentUserStore.user!.avatar).url || ''}`,
          }}
          onError={onPublisherError}
        />
        {videoIsDisabled ? (
          <>
            <div className={styles.streamAvatarContainer}>
              <Image
                src={getFileSrc(currentUserStore.user!.avatar).url || ''}
                placeholder={
                  <AvatarPlaceholder name={currentUserStore.user!.name} />
                }
                className={styles.streamAvatar}
              />
              <p className={styles.streamName}>{currentUserStore.user!.name}</p>
            </div>
          </>
        ) : null}
        {isMuted ? <MicOffIcon className={styles.streamNoAudioIcon} /> : null}
      </div>
    );
  }

  return null;
};

export default memo(ConferencePage);
