import React, {useCallback, useEffect, useMemo} from 'react';

import classNames from 'classnames';

import {SUPPORTED_FORMATS} from '@yourcoach/shared/api/media/file';
import TrashIcon from '@yourcoach/shared/assets/icons/secondary/Delete.svg';
import ImageIcon from '@yourcoach/shared/assets/icons/secondary/Image.svg';
import DocumentIcon from '@yourcoach/shared/assets/icons/secondary/Paper.svg';

import Image from '@src/components/Image';

import styles from './AddFile-FilesListItem.module.css';

type Props = {
  file: File;
  isUploading?: boolean;
  onDeleteButtonClick: (file: File) => void;
};

const FilesListItem: React.FC<Props> = ({
  file,
  isUploading,
  onDeleteButtonClick,
}) => {
  const onDeleteButtonClickCb = useCallback(() => {
    onDeleteButtonClick && onDeleteButtonClick(file);
  }, [file, onDeleteButtonClick]);

  const source = useMemo(() => URL.createObjectURL(file), [file]);

  const fileType = useMemo(() => {
    if (SUPPORTED_FORMATS.image.mimetypes.includes(file.type)) {
      return 'image';
    }

    return 'document';
  }, [file]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(source);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        'FilesListItem',
        styles.container,
        !isUploading && styles.uploading,
      )}
    >
      <div className={styles.preview}>
        {fileType === 'image' ? (
          <Image
            src={source}
            placeholder={
              <div className={styles.previewPlaceholder}>
                <ImageIcon />
              </div>
            }
          />
        ) : (
          <div className={styles.previewPlaceholder}>
            <DocumentIcon />
          </div>
        )}
      </div>

      <div className={styles.content}>
        <p className={styles.name}>{file.name}</p>
        <p className={styles.size}>{Math.floor(file.size / 1024)} kb</p>
      </div>
      <div className={styles.deleteButton} onClick={onDeleteButtonClickCb}>
        <TrashIcon />
      </div>
    </div>
  );
};

export default React.memo(FilesListItem);
