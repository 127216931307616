import {t} from '@src/i18n';

const I18N_SCOPE = 'Payment';

export const labelPaymentBilledTo = () => t([I18N_SCOPE, 'billed_to_lbl']);

export const labelPaymentClient = () => t([I18N_SCOPE, 'client_lbl']);

export const labelPaymentDate = () => t([I18N_SCOPE, 'date_lbl']);

export const labelPaymentNavTitle = () => t([I18N_SCOPE, 'nav_title']);

export const labelPaymentPendingOperation = () =>
  t([I18N_SCOPE, 'pending_operation_lbl']);

export const labelPaymentSubtotal = () => t([I18N_SCOPE, 'subtotal_lbl']);

export const labelPaymentTax = () => t([I18N_SCOPE, 'tax_lbl']);

export const labelPaymentTotal = () => t([I18N_SCOPE, 'total_lbl']);

export const labelPaymentUnsuccessOperation = () =>
  t([I18N_SCOPE, 'unsuccess_operation_lbl']);
