import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import InView from 'react-intersection-observer';
import {Observer} from 'mobx-react';

import {action, observable, reaction} from 'mobx';

import {getCustomConfirmAlert} from '../../../../components/CustomConfirmAlert/CustomConfirmAlert';
import ScrollToElement from '../../../../components/ScrollToElement/ScrollToElement';
import AppContext from '../../../../context/App';
import styles from '../../styles/styles.module.css';
import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';
import type {PostT} from '../PostsListItem/PostsListItem';
import PostsListItem from '../PostsListItem/PostsListItem';

interface ILocalStore {
  selectPost: PostT | null;
  setSelectPost(selectPost: PostT): void;
}

interface Props {}

const ChatMain: FC<Props> = () => {
  const {
    stores: {postStore, currentUserStore},
  } = useContext(AppContext);
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);
  const menuId = 'menuIdPostsListItem';
  const localStore: ILocalStore = useRef(
    observable(
      {
        selectPost: null,
        setSelectPost(selectPost: PostT) {
          this.selectPost = selectPost;
        },
      },
      {
        selectPost: observable,
        setSelectPost: action,
      },
    ),
  ).current;

  useEffect(() => {
    let pinnedPostTimerId = 0;

    const disposeScrollToMaxPost = reaction(
      () => mainChatsLocalStore?.sections.length,
      (length, prevLength) => {
        if (!prevLength) {
          // It's a fu..ng miracle!!!
          // This reaction is for scroll to last post on initial load
          mainChatsLocalStore?.setSeeCurrentPostId(
            mainChatsLocalStore.MaxPostId,
          );
        }
      },
    );

    pinnedPostTimerId = +setTimeout(() => {
      if (mainChatsLocalStore?.selectedPinnedPostId) {
        mainChatsLocalStore?.setSeeCurrentPostId(
          mainChatsLocalStore?.selectedPinnedPostId,
        );
        mainChatsLocalStore.setSelectedPinnedPostId(null);
      } else {
        mainChatsLocalStore?.setSeeCurrentPostId('no');
      }
    }, 200);

    return () => {
      disposeScrollToMaxPost();
      clearTimeout(pinnedPostTimerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const handleGetContextMenu = (selectPost: PostT) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      localStore.setSelectPost(selectPost);

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          selectPost,
        },
        position: {
          x: navigator.userAgent.includes('iPhone')
            ? event.currentTarget.offsetLeft
            : event.pageX,
          y: navigator.userAgent.includes('iPhone')
            ? event.currentTarget.offsetTop - 400
            : event.pageY,
        },
      });
    };
  };

  const MyMenu = () => (
    <Observer>
      {() => (
        <div onClick={handleClickMenu}>
          <Menu
            id={menuId}
            animation={animation.fade}
            className="contextMenu"
            style={{zIndex: 1000}}
          >
            {localStore.selectPost?.user_id === currentUserStore.user?._id ? (
              <Item onClick={_onEditPostBtnPress} className="contextMenuItem">
                Edit
              </Item>
            ) : null}
            {localStore.selectPost?.user_id === currentUserStore.user?._id ? (
              <Item onClick={_onDeletePostBtnPress} className="contextMenuItem">
                Delete
              </Item>
            ) : null}
          </Menu>
        </div>
      )}
    </Observer>
  );

  const _onDeletePostBtnPress = async args => {
    const post = args.props.selectPost as PostT;

    contextMenu.hideAll();

    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            postStore.delete(post, {
              channel_id: post.channel_id,
            });
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onEditPostBtnPress = args => {
    const post = args.props.selectPost as PostT;

    mainChatsLocalStore!.setIsInsertText(true);
    mainChatsLocalStore!._onEditPostBtnPress(post);
    contextMenu.hideAll();
  };

  const handleOnChangeInView = async (inView: boolean) => {
    if (inView) {
      const lastPostId = mainChatsLocalStore!.MinPostId;

      await mainChatsLocalStore?._onEndReached();

      mainChatsLocalStore?.setSeeCurrentPostId(lastPostId);
    }
  };

  const onFirstPostVisibilityChange = useCallback(
    (inView: boolean) => {
      if (
        mainChatsLocalStore?.isLoaded &&
        !inView &&
        mainChatsLocalStore?.isFirstPostVisible
      ) {
        mainChatsLocalStore?.setSeeCurrentPostId(
          mainChatsLocalStore!.MaxPostId,
        );
      }

      mainChatsLocalStore?.setIsFirstPostVisible(inView);
    },
    [mainChatsLocalStore],
  );

  return (
    <Observer>
      {() => (
        <div className={`ChatMain ${styles.ChatMain}`}>
          <div className={styles.ChatMainOverFlowContainer}>
            <div className={styles.ChatPlacePostsAtTheBottom}>
              <InView as="div" onChange={handleOnChangeInView}>
                <div />
              </InView>
              {!mainChatsLocalStore?.userIsFrozen ? (
                <>
                  {mainChatsLocalStore?.sectionReverse.map(section => {
                    return (
                      <div
                        key={section.date}
                        className={styles.ChatMainDaySectionContainer}
                      >
                        <div className={styles.ChatMainPostsContainer}>
                          {section.data.map((post, i) => {
                            let isAdminPost = false;

                            const isLast = section.data.length - 1 === i;

                            if (mainChatsLocalStore!.hasCourse) {
                              isAdminPost =
                                !mainChatsLocalStore.isOneToOne &&
                                mainChatsLocalStore.channel!.resource!.coach_ids.includes(
                                  post.user_id,
                                );
                            }

                            return (
                              <div
                                key={post._id}
                                className={
                                  mainChatsLocalStore?.seeCurrentPostId ===
                                  post._id
                                    ? `maxPost_${post._id}`
                                    : ''
                                }
                              >
                                <PostsListItem
                                  post={post}
                                  isLast={isLast}
                                  groupWithNext={post.userGroup}
                                  showUserAvatar={
                                    !mainChatsLocalStore.isOneToOne &&
                                    post.firstItemInUserGroup
                                  }
                                  showUserName={
                                    !mainChatsLocalStore.isOneToOne &&
                                    post.lastItemInUserGroup
                                  }
                                  showUserGroups={
                                    !mainChatsLocalStore.isOneToOne
                                  }
                                  isAdminPost={isAdminPost}
                                  hasCourse={mainChatsLocalStore.hasCourse}
                                  onClickContextMenu={handleGetContextMenu}
                                />
                                {mainChatsLocalStore?.seeCurrentPostId ===
                                post._id ? (
                                  <ScrollToElement height={10} />
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              <MyMenu />
            </div>
          </div>
          <InView as="div" onChange={onFirstPostVisibilityChange}>
            <div />
          </InView>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatMain);
