import type {FC} from 'react';
import React, {memo, useEffect, useRef, useState} from 'react';
import Confetti from 'react-confetti';
import {Observer} from 'mobx-react';

import styles from './styles.module.css';

interface Props {}

const CustomConfetti: FC<Props> = () => {
  const [confH, setConfH] = useState(undefined);
  const [confW, setConfW] = useState(undefined);
  const confRf = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (confRf.current) {
        // @ts-ignore
        setConfH(confRf.current!.clientHeight);
        // @ts-ignore
        setConfW(confRf.current!.clientWidth);
      }
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Observer>
      {() => (
        <div className={`CustomConfetti ${styles.CustomConfetti}`} ref={confRf}>
          <Confetti width={confW} height={confH} />
        </div>
      )}
    </Observer>
  );
};

export default memo(CustomConfetti);
