import {action, computed, observable} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import type {
  Questionnaire as IQuestionnaire,
  QuestionnaireQuestion,
  QuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';

import {labelNewQuestion} from '@src/common/i18n/i18nQuestionnaire';
import type {Expanded as QuestionnaireExpanded} from '@src/models/questionnaire';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

export type TQuestionnaire = IQuestionnaire &
  Omit<QuestionnaireExpanded, 'avatar'> & {
    avatar?: IFile | string | null;
  };

export interface IQLocalStore {
  logo: IFile | null;
  setLogo: (newLogo: IFile) => void;
  logoId: string | null;
  setLogoId: (newId: string) => void;
  originalLogo: IFile | null;
  setOriginalLogo: (newLogo: IFile) => void;
  standardTemplateQuest: TQuestionnaire | null;
  setStandardTemplateQuest: (newQ: TQuestionnaire) => void;
  delStandardTemplateQuest: () => void;
  userTemplateQuest: IUserQuestionnaire | null;
  setUserTemplateQuest: (newQ: IUserQuestionnaire) => void;
  setUserTemplateTitleAndDesc: (newT: string, newD: string) => void;
  activeSectionId: string | null;
  setActiveSectionId: (id: string) => void;
  delActiveSectionId: () => void;
  currentSection: IQSectionWithFields | null;
  setCurrentSection: (newCurrentSection: IQSectionWithFields) => void;
  setNullCurrentSection: () => void;
  updateCurrentSectionTitle: (newTitle: string) => void;
  updateCurrentQuestion: (newQ: QuestionnaireQuestion, numQ: number) => void;
  updateCurrentSectionDescription: (newDescription: string) => void;
  reorderQuestion: (startIndex: number, endIndex: number) => void;
  reorderSection: (startIndex: number, endIndex: number) => void;
  addSection: (newSection: IQSectionWithFields) => void;
  delCurrentQuestion: (numQ: number) => void;
  setOldQuestion: (numQ: number) => void;
  delCurrentSection: () => void;
  addNewQuestion: () => void;
  newQId: string | null;
  setNewQId: (newId: string | null) => void;
  getNumQuestionsToTheUserQuestionnaire: number;
  updateCurrentQuestionType: (
    newType: QuestionnaireQuestion['type'],
    numQ: number,
  ) => void;
  isEditMode: boolean;
  setIsEditMode(isEditMode: boolean): void;
}

export interface IUserQuestionnaire {
  is_public: boolean;
  avatar: string | IFile | null | undefined;
  title: string;
  description: string;
  sections: IQSectionWithFields[];
}

export interface IQSectionWithFields extends QuestionnaireSection {
  id: string;
}

const qAppStore: IQLocalStore | undefined = observable<IQLocalStore>(
  {
    logo: null,
    setLogo(newLogo: IFile) {
      this.logo = newLogo;
    },
    logoId: null,
    setLogoId(newId: string) {
      this.logoId = newId;
    },
    originalLogo: null,
    setOriginalLogo(newLogo: IFile) {
      this.originalLogo = newLogo;
    },
    isEditMode: false,
    setIsEditMode(isEditMode: boolean) {
      this.isEditMode = isEditMode;
    },
    newQId: null,
    standardTemplateQuest: null,
    setStandardTemplateQuest(newQ: TQuestionnaire) {
      this.standardTemplateQuest = newQ;
    },
    delStandardTemplateQuest() {
      this.standardTemplateQuest = null;
    },
    userTemplateQuest: null,
    setUserTemplateQuest(newQ: IUserQuestionnaire) {
      this.userTemplateQuest = newQ;
      this.logo = newQ.avatar;
      this.originalLogo = newQ.avatar;
      this.logoId = newQ.avatar ? (newQ.avatar as IFile)._id : null;
    },
    setUserTemplateTitleAndDesc(newT: string, newD: string) {
      if (this.userTemplateQuest) {
        this.userTemplateQuest.title = newT;
        this.userTemplateQuest.description = newD;
        this.userTemplateQuest.avatar_id = this.logoId;
      }
    },
    reorderQuestion(startIndex: number, endIndex: number) {
      (this.currentSection as IQSectionWithFields).questions[endIndex] = (
        this.currentSection as IQSectionWithFields
      ).questions.splice(
        startIndex,
        1,
        (this.currentSection as IQSectionWithFields).questions[endIndex],
      )[0];
    },
    reorderSection(startIndex: number, endIndex: number) {
      this.userTemplateQuest.sections[endIndex] =
        this.userTemplateQuest.sections.splice(
          startIndex,
          1,
          this.userTemplateQuest.sections[endIndex],
        )[0];
    },
    addSection(newSection: IQSectionWithFields) {
      this.userTemplateQuest.sections.push(newSection);
    },
    activeSectionId: null,
    setActiveSectionId(id: string) {
      this.activeSectionId = id;

      const currentSection = this.userTemplateQuest.sections.find(
        section => id === section.id,
      );

      if (currentSection) {
        this.setCurrentSection(currentSection);

        if (
          currentSection.questions[currentSection.questions.length - 1]
            .question === labelNewQuestion()
        ) {
          this.setNewQId(
            `${this.activeSectionId}=${
              this.currentSection.questions.length - 1
            }`,
          );
        }
      }
    },
    delActiveSectionId() {
      this.activeSectionId = null;
      this.setNullCurrentSection();
    },
    currentSection: null,
    setCurrentSection(newCurrentSection: IQSectionWithFields) {
      this.currentSection = newCurrentSection;
    },
    setNullCurrentSection() {
      this.currentSection = null;
    },
    updateCurrentSectionTitle(newTitle: string) {
      this.currentSection.title = newTitle;
    },
    updateCurrentSectionDescription(newDescription: string) {
      this.currentSection.description = newDescription;
    },
    updateCurrentQuestion(newQ: QuestionnaireQuestion, numQ: number) {
      this.currentSection.questions[numQ] = newQ;
    },
    addNewQuestion() {
      this.currentSection.questions.push({
        question: labelNewQuestion(),
        is_optional: false,
        type: ALIAS_TYPES.text,
      });

      this.setNewQId(
        `${this.activeSectionId}=${this.currentSection.questions.length - 1}`,
      );
    },
    updateCurrentQuestionType(
      newType: QuestionnaireQuestion['type'],
      numQ: number,
    ) {
      const newQ = {...this.currentSection.questions[numQ], type: newType};

      this.updateCurrentQuestion(newQ, numQ);
    },
    setNewQId(newId: string | null) {
      this.newQId = newId;
    },
    setOldQuestion(numQ: number) {
      if (numQ) {
      }

      this.setNewQId(null);
    },
    delCurrentQuestion(numQ: number) {
      this.currentSection.questions.splice(numQ, 1);

      if (this.currentSection.questions.slice().length <= 0) {
        this.delCurrentSection();
      }
    },
    delCurrentSection() {
      const indexDelSection = this.userTemplateQuest.sections.findIndex(
        section => this.activeSectionId === section.id,
      );

      this.userTemplateQuest.sections.splice(indexDelSection, 1);

      this.delActiveSectionId();
    },
    get getNumQuestionsToTheUserQuestionnaire() {
      let retCount = 0;

      this.userTemplateQuest.sections.forEach(section => {
        section.questions.forEach(() => {
          retCount++;
        });
      });

      return retCount;
    },
  },
  {
    standardTemplateQuest: observable,
    setStandardTemplateQuest: action,
    delStandardTemplateQuest: action,
    userTemplateQuest: observable,
    setUserTemplateQuest: action,
    setUserTemplateTitleAndDesc: action,
    activeSectionId: observable,
    currentSection: observable,
    setActiveSectionId: action,
    updateCurrentQuestion: action,
    updateCurrentSectionTitle: action,
    delActiveSectionId: action,
    setNullCurrentSection: action,
    setCurrentSection: action,
    updateCurrentSectionDescription: action,
    reorderQuestion: action,
    reorderSection: action,
    addSection: action,
    delCurrentQuestion: action,
    delCurrentSection: action,
    updateCurrentQuestionType: action,
    addNewQuestion: action,
    getNumQuestionsToTheUserQuestionnaire: computed,
    isEditMode: observable,
    setIsEditMode: action,
    logo: observable,
    logoId: observable,
    originalLogo: observable,
    setLogo: action,
    setOriginalLogo: action,
    setLogoId: action,
  },
);

export default qAppStore;
