import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';

import type {ProgramDurationUnit} from '@yourcoach/shared/api/program';
import {
  parseDuration,
  ProgramDurationUnitEnum,
} from '@yourcoach/shared/api/program';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import TextField from '@src/components/TextField';
import {t} from '@src/i18n';

import styles from './Duration.module.css';

export const I18N_SCOPE = 'shared.CRUProgramDuration';

export type Props = {
  duration: number;
  onSet: (duration: number) => void;
};

const CRUProgramDuration: React.FC<Props> = ({
  duration: durationProp,
  onSet,
}) => {
  const [duration, setDuration] = useState(durationProp || 0);
  const [durationUnit, setDurationUnit] = useState(ProgramDurationUnitEnum.DAY);

  const parsedDuration = useMemo(() => parseDuration(duration), [duration]);

  const fields = useRef({
    duration: createHtmlInputField('duration', {
      validationRule: {
        type: 'string',
      },
      defaultValue: `${parsedDuration.num}`,
    }),
  }).current;

  useEffect(() => {
    setDurationUnit(parsedDuration.unit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDurationInitChange = useCallback(
    (unit: ProgramDurationUnit) => {
      const durationInUnits = duration / durationUnit.multiplier;

      if (durationInUnits > unit.max) {
        setDuration(unit.max * unit.multiplier);
        fields.duration.setValue(`${unit.max}`);
        fields.duration.validate();
      } else {
        setDuration(durationInUnits * unit.multiplier);
      }

      setDurationUnit(unit);
    },
    [duration, durationUnit.multiplier, fields.duration],
  );

  const onDurationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let durationText = e.currentTarget.value.replace(/\D/g, '');

      if (durationText === '0') {
        durationText = '';
      }

      let newDuration = duration;

      let durationInUnits = durationText ? parseInt(durationText, 10) : 0;

      if (durationInUnits > durationUnit.max) {
        durationText = durationText.slice(0, -1);
      } else {
        newDuration = durationInUnits * durationUnit.multiplier;
      }

      setDuration(newDuration);

      fields.duration.setValue(durationText);
      fields.duration.validate();
    },
    [duration, durationUnit.max, durationUnit.multiplier, fields.duration],
  );

  const onSetButtonClick = useCallback(() => {
    onSet(duration);
  }, [duration, onSet]);

  return (
    <div>
      <Observer>
        {() => (
          <div className={styles.textFieldContainer}>
            <TextField
              ref={fields.duration.ref}
              value={fields.duration.value}
              onChange={onDurationChange}
            />
            <div className={styles.textFieldDurationUnit}>
              {durationUnit.toString(parsedDuration.num)}
            </div>
          </div>
        )}
      </Observer>
      <div className={styles.durationUnitsContainer}>
        {[
          ProgramDurationUnitEnum.DAY,
          ProgramDurationUnitEnum.WEEK,
          ProgramDurationUnitEnum.MONTH,
        ].map(unit => {
          const isSelected = unit.id === durationUnit.id;

          return (
            <div
              key={unit.id}
              className={classNames(
                styles.durationUnit,
                isSelected && styles.selected,
              )}
              onClick={() => onDurationInitChange(unit)}
            >
              <p className={styles.durationUnitText}>{unit.toString(2)}</p>
              <p
                className={styles.durationUnitIntervalText}
              >{`1 — ${unit.max}`}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.footer}>
        <Button onClick={onSetButtonClick} disabled={!duration}>
          {t([I18N_SCOPE, 'set_button'])}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(CRUProgramDuration);
