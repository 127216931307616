import {t} from '@src/i18n';

const I18N_SCOPE = 'Programs';

export const labelMyPractice = () => t([I18N_SCOPE, 'myPractice']);

export const labelPrograms = () => t([I18N_SCOPE, 'programs']);

export const labelProgramLow = () => t([I18N_SCOPE, 'programLow']);

export const labelAddProgram = () => t([I18N_SCOPE, 'addProgram']);

export const labelAddProgramOnly = () => t([I18N_SCOPE, 'addProgramOnly']);

export const labelDescription = () => t([I18N_SCOPE, 'Description']);

export const labelDetails = () => t([I18N_SCOPE, 'Details']);

export const labelSchedule = () => t([I18N_SCOPE, 'Schedule']);

export const labelGoals = () => t([I18N_SCOPE, 'Goals']);

export const labelMainGoal = () => t([I18N_SCOPE, 'main_goal']);

export const labelAddGoal = () => t([I18N_SCOPE, 'addGoal']);

export const labelLibrary = () => t([I18N_SCOPE, 'Library']);

export const labelProgram = () => t([I18N_SCOPE, 'Program']);

export const labelPaymentPlan = () => t([I18N_SCOPE, 'payment_plan_button']);

export const labelEditPaymentProcessingIsntProviderCoaches = () =>
  t([I18N_SCOPE, 'edit_payment_processing_isnt_provider_coaches_lbl']);

export const labelIsntProviderCoachesPopupCancelButton = () =>
  t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'cancel_btn']);

export const labelIsntProviderCoachesPopupSuccessBtn = () =>
  t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'success_btn']);

export const labelIsntProviderCoachesPopupHeader = () =>
  t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'header']);

export const labelIsntProviderCoachesPopupDescription = (coaches: string) =>
  t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'description'], {coaches});

export const labelDeleteTheProgram = () => t([I18N_SCOPE, 'DeleteTheProgram']);

export const labelPublishProgram = () => t([I18N_SCOPE, 'PublishProgram']);

export const labelSetupProgram = () => t([I18N_SCOPE, 'SetupProgram']);

export const labelEditProgram = () => t([I18N_SCOPE, 'EditProgram']);

export const labelDeleteProgram = () => t([I18N_SCOPE, 'DeleteProgram']);

export const labelHideProgram = () => t([I18N_SCOPE, 'HideProgram']);

export const labelSelectStartDate = () => t([I18N_SCOPE, 'SelectStartDate']);

export const labelCreateNewSquad = () => t([I18N_SCOPE, 'CreateNewProgram']);

export const labelRequests = () => t([I18N_SCOPE, 'Requests']);

export const labelLibraryTab = () => t([I18N_SCOPE, 'library_tab']);

export const labelTasksTab = () => t([I18N_SCOPE, 'tasks_tab']);

export const labelNotesTab = () => t([I18N_SCOPE, 'notes_tab']);

export const labelGoalsTab = () => t([I18N_SCOPE, 'goals_tab']);

export const label360Tab = () => t([I18N_SCOPE, '360_tab']);

export const labelClientCoursesTab = () =>
  t([I18N_SCOPE, 'client_courses_tab']);

export const labelCoursesTab = () => t([I18N_SCOPE, 'courses_tab']);

export const labelDescriptionTab = () => t([I18N_SCOPE, 'description_tab']);

export const labelInvitesTab = () => t([I18N_SCOPE, 'invites_tab']);

export const labelRequestsTab = () => t([I18N_SCOPE, 'requests_tab']);

export const labelSharedMsgSuccess = () => t('shared.message.success');

export const labelSharedMsgError = () => t('shared.message.error');

export const labelProgramClientCoursesListItemStatusFrozen = () =>
  t([I18N_SCOPE, 'ProgramClientCoursesListItem', 'status', 'frozen']);

export const labelProgramClientCoursesListItemStatusJoined = () =>
  t([I18N_SCOPE, 'ProgramClientCoursesListItem', 'status', 'joined']);

export const labelProgramClientCoursesListItemStatusRequested = () =>
  t([I18N_SCOPE, 'ProgramClientCoursesListItem', 'status', 'requested']);

export const labelProgramCourseNoStartDateClient = () =>
  t([I18N_SCOPE, 'course_no_start_date', 'client']);

export const labelProgramCourseNoStartDateCoach = () =>
  t([I18N_SCOPE, 'course_no_start_date', 'coach']);

export const labelProgramClientCoursesTabArchivedCourses = () =>
  t([I18N_SCOPE, 'ProgramClientCoursesTab', 'archived_courses_section_header']);

export const labelProgramClientCoursesTabNoResultsLbl = () =>
  t([I18N_SCOPE, 'ProgramClientCoursesTab', 'no_results_lbl']);

export const labelProgramRequestsTabOldConditionsInviteMessage = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'old_conditions_invite_message']);

export const labelProgramRequestsTabNoResults = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'no_results_lbl']);

export const labelProgramRequestsTabInviteToCourseButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'invite_to_course_button']);

export const labelProgramRequestsTabInviteToOtherProgramButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'invite_to_another_program_button']);

export const labelProgramRequestsTabCreateCourseButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'create_course_button']);

export const labelProgramRequestsListItemOldConditions = () =>
  t([I18N_SCOPE, 'ProgramRequestsListItem', 'old_conditions_lbl']);

export const labelProgramRequestsTabDeclineRequestsButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'decline_requests_button']);

export const labelProgramRequestsTabAcceptRequestButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'accept_request_button']);

export const labelProgramRequestsTabDeclineRequestButton = () =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'decline_request_button']);

export const labelProgramInvitesTabInvite = () =>
  t([I18N_SCOPE, 'ProgramInvitesTab', 'invite_btn']);

export const labelProgramInvitesTabNoResults = () =>
  t([I18N_SCOPE, 'ProgramInvitesTab', 'no_results_lbl']);

export const labelProgramInvitesTabDeleteInviteBtn = () =>
  t([I18N_SCOPE, 'ProgramInvitesTab', 'swipe_delete_invite_btn']);

export const labelProgramInvitesTabResendInviteBtn = () =>
  t([I18N_SCOPE, 'ProgramInvitesTab', 'swipe_resend_invite_btn']);

export const labelProgramDescriptionTabNoDescription = () =>
  t([I18N_SCOPE, 'ProgramDescriptionTab', 'no_description_lbl']);

export const labelProgramInvitesTabResendSent = (date: string) =>
  t([I18N_SCOPE, 'ProgramInvitesTab', 'sent_lbl'], {date});

export const labelProgramRequestsTabInvitedToNewCourseMembershipsMsg = (
  count: number,
  groupType: 'individual' | 'group',
) =>
  t(
    [
      I18N_SCOPE,
      'ProgramRequestsTab',
      'invited_to_new_course_memberships_message',
      groupType,
    ],
    {count},
  );

export const labelProgramRequestsTabinvitedMembershipsError = (
  count: number,
  userNames: string,
  groupType: 'individual' | 'group',
) =>
  t(
    [I18N_SCOPE, 'ProgramRequestsTab', 'invited_memberships_error', groupType],
    {
      count,
      userNames,
    },
  );

export const labelProgramRequestsTabExistedMembershipsError = (
  count: number,
  userNames: string,
  groupType: 'individual' | 'group',
) =>
  t(
    [I18N_SCOPE, 'ProgramRequestsTab', 'existed_memberships_error', groupType],
    {
      count,
      userNames,
    },
  );

export const labelProgramRequestsTabFailedMembershipsError = (
  count: number,
  userNames: string,
) =>
  t([I18N_SCOPE, 'ProgramRequestsTab', 'failed_memberships_error'], {
    count,
    userNames,
  });

export const labelReviews = () => t([I18N_SCOPE, 'Reviews']);

export const labelJoinNow = () => t([I18N_SCOPE, 'JoinNow']);

export const labelCreateProgram = () => t([I18N_SCOPE, 'CreateProgram']);

export const labelUpdateProgram = () => t([I18N_SCOPE, 'UpdateProgram']);

export const labelCreateTask = () => t([I18N_SCOPE, 'CreateTask']);

export const labelCreateLiveEvent = () => t([I18N_SCOPE, 'CreateLiveEvent']);

export const labelTask = () => t([I18N_SCOPE, 'Task']);

export const labelLiveEvent = () => t([I18N_SCOPE, 'LiveEvent']);

export const labelCreateEvent = () => t([I18N_SCOPE, 'CreateEvent']);

export const labelEvent = () => t([I18N_SCOPE, 'Event']);

export const labelDateOfPublication = () =>
  t([I18N_SCOPE, 'DateOfPublication']);

export const labelCreateGroup = () => t([I18N_SCOPE, 'CreateGroup']);

export const labelYouCanHideYourProgramFromSearch = () =>
  t([I18N_SCOPE, 'YouCanHideYourProgramFromSearch']);

export const labelYouCanCloseYourProgramFromSearch = () =>
  t([I18N_SCOPE, 'YouCanCloseYourProgramFromSearch']);

export const labelClosedToNewRequests = () =>
  t([I18N_SCOPE, 'ClosedToNewRequests']);

export const labelCover = () => t([I18N_SCOPE, 'Cover']);

export const labelName = () => t([I18N_SCOPE, 'Name']);

export const labelDaySectionHeader = day =>
  t([I18N_SCOPE, 'day_section_header'], {day});

export const labelDaySectionHeaderTask = value =>
  t([I18N_SCOPE, 'day_section_header_task'], {value});

export const labelFirstDaySectionHeader = () =>
  t([I18N_SCOPE, 'first_day_section_header']);

export const labelType = () => t([I18N_SCOPE, 'Type']);

export const labelMaxSize = () => t([I18N_SCOPE, 'MaxSize']);

export const labelDuration = () => t([I18N_SCOPE, 'Duration']);

export const labelSelect = () => t([I18N_SCOPE, 'Select']);

export const labelPaymentLbl = () => t([I18N_SCOPE, 'payment_lbl']);

export const labelContract = () => t([I18N_SCOPE, 'Contract']);

export const labelNoFilesLibrary = () => t([I18N_SCOPE, 'NoFilesLibrary']);

export const labelProgramCoursesTabArchivedCourses = () =>
  t(['ProgramCoursesTab', 'archived_courses_section_header']);

export const labelProgramCoursesListItemStartButton = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'ProgramCoursesListItem', 'start_button', groupType]);

export const labelProgramCoursesListItemStopButton = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'ProgramCoursesListItem', 'stop_button', groupType]);

export const labelProgramCoursesListItemDeleteButton = () =>
  t([I18N_SCOPE, 'ProgramCoursesListItem', 'delete_button']);

export const labelProgramCoursesListItemUpgradeButton = () =>
  t([I18N_SCOPE, 'ProgramCoursesListItem', 'upgrade_button']);

export const labelProgramCoursesListItemOldConditions = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'ProgramCoursesListItem', 'old_conditions_lbl', groupType]);

export const labelPriceDescriptionLbl = () =>
  t([I18N_SCOPE, 'price_description_lbl']);

export const labelPriceDescriptionWithPriceLbl = price =>
  t([I18N_SCOPE, 'price_description_with_price_lbl'], {price});

export const labelpriceDescriptionWithPriceAndCommissionLbl = (
  price,
  commission,
) =>
  t([I18N_SCOPE, 'price_description_with_price_and_commission_lbl'], {
    price,
    commission,
  });

export const labelPaidElsewhereDescription = () =>
  t([I18N_SCOPE, 'paid_elsewhere_description']);

export const labelPaidElsewhereTitle = () =>
  t([I18N_SCOPE, 'paid_elsewhere_title']);

export const labelPriceTextInputPlaceholder = (min_price, max_price) =>
  t([I18N_SCOPE, 'price_text_input_placeholder'], {min_price, max_price});

export const labelDescribeYourPracticeHere___ = () =>
  t([I18N_SCOPE, 'DescribeYourPracticeHere___']);

export const labelStripeCancelButton = () =>
  t([I18N_SCOPE, 'setup_stripe_popup', 'cancel_button']);

export const labelStripeCancelDescription = () =>
  t([I18N_SCOPE, 'setup_stripe_popup', 'description']);

export const labelStripeCancelHeader = () =>
  t([I18N_SCOPE, 'setup_stripe_popup', 'header']);

export const labelStripeCancelSignupButton = () =>
  t([I18N_SCOPE, 'setup_stripe_popup', 'signup_button']);

export const labelComplete = () => t([I18N_SCOPE, 'Complete']);

export const labelClientsHelped = (val: number) =>
  t([I18N_SCOPE, 'clientsHelped'], {count: val});
