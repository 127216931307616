import type {FC} from 'react';
import React, {memo} from 'react';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {labelOther} from '@src/common/i18n/i18nQuestionnaire';

interface Props {
  question: QuestionnaireQuestion;
}

const SingleChoiceOpen: FC<Props> = ({question}) => {
  return (
    <div className="SingleChoiceTemplateContainer">
      <div className="name">{question.question}</div>
      <ul>
        {question.options?.map((answerLabel, index) => (
          <li key={index}>
            <span>{answerLabel}</span>
          </li>
        ))}
        <li>
          <span>{labelOther()}</span>
        </li>
      </ul>
    </div>
  );
};

export default memo(SingleChoiceOpen);
