import type {FC} from 'react';
import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {getGoalAttitudeIcon} from '@yourcoach/shared/api/goal';

import {CustomButton} from '@src/components/CustomForm';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {t} from '@src/i18n';

import type {BoundGoal} from '../RateGoal/RateGoal';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'EditGoalAttitude';

interface ILocalStore {
  attitudeValue: number;
  _onValueChange(value: number): void;
  comment?: string;
  setComment(newValue: string): void;
}

interface Props {
  goal: BoundGoal;
  onSuccess: (attitude: number, comment?: string) => void;
}

const EditGoalAttitude: FC<Props> = ({goal, onSuccess}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        attitudeValue: 100,
        _onValueChange(newValue: number) {
          this.attitudeValue = newValue;
        },
        comment: undefined,
        setComment(newValue: string) {
          this.comment = newValue;
        },
      },
      {
        attitudeValue: observable,
        _onValueChange: action,
        comment: observable,
        setComment: action,
      },
    ),
  ).current;

  const onSuccessCb = useCallback(() => {
    onSuccess(localStore.attitudeValue / 100, localStore.comment);
  }, [localStore.attitudeValue, localStore.comment, onSuccess]);

  const attitudeString = useMemo<JSX.Element>(() => {
    let result = (
      <div className={styles.attitudeString}>
        {t([I18N_SCOPE, 'no_attitude_label'])}
      </div>
    );

    let attitude =
      goal.coach_attitude !== null
        ? goal.coach_attitude
        : goal.client_attitude !== null
        ? goal.client_attitude
        : null;

    if (attitude !== null) {
      result = (
        <div className={styles.attitudeString}>
          <div className={styles.icon}>
            {getGoalAttitudeIcon((attitude * 1000) / 10)}
          </div>
          <div className={styles.text}>{(attitude * 1000) / 10}%</div>
        </div>
      );
    }

    return result;
  }, [goal.client_attitude, goal.coach_attitude]);

  useEffect(() => {
    if (goal.coach_attitude !== null) {
      localStore._onValueChange(goal.coach_attitude * 100);
    } else {
      if (goal.client_attitude !== null) {
        localStore._onValueChange(goal.client_attitude * 100);
      } else {
        localStore._onValueChange(100);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onConfirmButtonPress = () => {
    onSuccessCb();
  };

  const handleOnChangeRate = val => {
    localStore._onValueChange(val);
  };

  const handleOnChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    localStore.setComment(e.target.value);
  };

  return (
    <Observer>
      {() => (
        <div className={`EditGoalAttitude ${styles.EditGoalAttitude}`}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>{t([I18N_SCOPE, 'title'])}</div>
            <div className={styles.clientContainer}>
              <div className={styles.clientHeader}>
                <div>{t([I18N_SCOPE, 'client_label'])}</div>
                <div>{t([I18N_SCOPE, 'client_estimate_label'])}</div>
              </div>
              <div className={styles.client}>
                <div className={styles.userAvatar}>
                  <OtherUserProfileImg
                    avatar={goal.client!.avatar}
                    title={goal.client!.name}
                    isOverlay={!goal.client!.avatar}
                  />
                </div>
                <div className={styles.content}>
                  <div className={styles.userName}>{goal.client.name}</div>
                  <div className={styles.attitude}>{attitudeString}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.sliderContainer}>
              <div className={`customSlider ${styles.settingItem}`}>
                <Slider
                  min={0}
                  max={100}
                  tooltip={false}
                  value={localStore.attitudeValue}
                  orientation="horizontal"
                  onChange={handleOnChangeRate}
                />
              </div>
              <div className={styles.sliderContentContainer}>
                <div className={styles.swipeItLabel}>
                  {t([I18N_SCOPE, 'swipe_it_label'])}
                </div>
                <div className={styles.sliderValueContainer}>
                  <div className={styles.attitudeIcon}>
                    {getGoalAttitudeIcon(localStore.attitudeValue)}
                  </div>
                  <div
                    className={styles.sliderValue}
                  >{`${localStore.attitudeValue} %`}</div>
                </div>
              </div>
            </div>
            <div className={styles.commentInputContainer}>
              <textarea
                value={localStore.comment}
                onChange={handleOnChangeComment}
                placeholder={t([I18N_SCOPE, 'comment_input_placeholder'])}
              />
            </div>
            <div className={styles.footerButtonContainer}>
              <CustomButton
                type="button"
                classButton={`blueButt ${styles.saveBut}`}
                onClick={_onConfirmButtonPress}
              >
                <span>{t([I18N_SCOPE, 'confirm_button'])}</span>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(EditGoalAttitude);
