import {t} from '@src/i18n';

const I18N_SCOPE = 'Profile';
const I18N_SCOPE_COMMON = 'Common';

export const labelMyProfile = () => t([I18N_SCOPE, 'myProfile']);

export const labelEditProfile = () => t([I18N_SCOPE, 'editProfile']);

export const labelAddAccount = () => t([I18N_SCOPE, 'AddAccount']);

export const labelAccounts = () => t([I18N_SCOPE, 'Accounts']);

export const labelPrivateInfo = () => t([I18N_SCOPE, 'PrivateInfo']);

export const labelNotCorrectPhone = () => t([I18N_SCOPE, 'NotCorrectPhone']);

export const labelPrivateInfoText = () => t([I18N_SCOPE, 'PrivateInfoText']);

export const labelAvatar = () => t([I18N_SCOPE, 'avatar']);

export const labelCreateAPractice = () => t([I18N_SCOPE, 'CreateAPractice']);

export const labelYourOneStopPracticeManagement = () =>
  t([I18N_SCOPE, 'YourOneStopPracticeManagement']);

export const labelChangePhoto = () => t([I18N_SCOPE, 'changePhoto']);

export const labelUserName = () => t([I18N_SCOPE, 'userNameLabel']);

export const labelUserEmail = () => t([I18N_SCOPE, 'userEmailLabel']);

export const labelInput = () => t([I18N_SCOPE, 'Input']);

export const labelText = () => t([I18N_SCOPE_COMMON, 'Text']);

export const labelConfirm = () => t([I18N_SCOPE, 'Confirm']);

export const labelEnterTheCode = () => t([I18N_SCOPE, 'EnterTheCode']);

export const labelSentTo = () => t([I18N_SCOPE, 'SentTo']);

export const labelCover = () => t([I18N_SCOPE, 'Cover']);

export const labelChooseCategory = () => t([I18N_SCOPE, 'ChooseCategory']);

export const labelCertificates = () =>
  t([I18N_SCOPE, 'certificates', 'certificates_label']);

export const labelCertificatesNoItems = () =>
  t([I18N_SCOPE, 'certificates', 'no_items_message']);

export const labelCertificatesAdd = () =>
  t([I18N_SCOPE, 'certificates', 'add_button']);

export const labelCertificatesAddPlus = () =>
  t([I18N_SCOPE, 'certificates', 'add_plus_button']);

export const labelCertificatesEdit = () =>
  t([I18N_SCOPE, 'certificates', 'edit_dialog_title']);

export const labelCertificatePublic = () =>
  t([I18N_SCOPE, 'certificates', 'is_public_input']);

export const labelCertificateTitle = () =>
  t([I18N_SCOPE, 'certificates', 'title_input']);

export const labelCertificateSchool = () =>
  t([I18N_SCOPE, 'certificates', 'school_input']);

export const labelCertificatePhoto = () =>
  t([I18N_SCOPE, 'certificates', 'photo_label']);

export const labelChooseCategoryExplanation = () =>
  t([I18N_SCOPE, 'ChooseCategoryExplanation']);

export const labelMentorship = () => t([I18N_SCOPE, 'Mentorship']);

export const labelImAMentor = () => t([I18N_SCOPE, 'ImAMentor']);

export const labelMentorExplanation = () =>
  t([I18N_SCOPE, 'MentorExplanation']);

export const labelUserEmailLabelLite = () =>
  t([I18N_SCOPE, 'userEmailLabelLite']);

export const labelNotCorrectEmail = () => t([I18N_SCOPE, 'NotCorrectEmail']);

export const labelLocation = () => t([I18N_SCOPE, 'Location']);

export const labelEditLocation = () => t([I18N_SCOPE, 'EditLocation']);

export const labelUserYearBirthday = () =>
  t([I18N_SCOPE, 'userYearBirthdayLabel']);

export const labelUserLocation = () => t([I18N_SCOPE, 'userLocationLabel']);

export const labelUserGender = () => t([I18N_SCOPE, 'userGenderLabel']);

export const labelAccountType = () => t([I18N_SCOPE, 'accountType']);

export const labelNewsletter = () => t([I18N_SCOPE, 'newsletter']);

export const labelCreatePractice = () => t([I18N_SCOPE, 'createPractice']);

export const labelEditPractice = () => t([I18N_SCOPE, 'editPractice']);

export const labelPracticeAvatar = () => t([I18N_SCOPE, 'practiceAvatar']);

export const labelPracticeName = () => t([I18N_SCOPE, 'PracticeName']);

export const labelImACoach = () => t([I18N_SCOPE, 'ImACoach']);

export const labelSendAgain = () => t([I18N_SCOPE, 'SendAgain']);

export const labelSendCode = () => t([I18N_SCOPE, 'SendCode']);

export const labelCode = () => t([I18N_SCOPE, 'Code']);

export const labelTheCodeMustConsistOfSixDigits = () =>
  t([I18N_SCOPE, 'TheCodeMustConsistOfSixDigits']);

export const labelImLookingForACoach = () =>
  t([I18N_SCOPE, 'ImLookingForACoach']);

export const labelPracticeDescription = () =>
  t([I18N_SCOPE, 'PracticeDescription']);

export const labelYouWillReceiveCurrentNewsAboutTheProject = () =>
  t([I18N_SCOPE, 'you_will_receive_current_news_about_the_project']);

export const labelFieldsWithAnAsteriskAreRequired = () =>
  t([I18N_SCOPE, 'fields_with_an_asterisk_are_required']);

export const labelTheNameMustConsistOf3To50Characters: (obj?: {
  minLength?: number;
  maxLength?: number;
}) => string = obj => {
  let minLength;
  let maxLength;

  if (obj) {
    minLength = obj.minLength || 3;
    maxLength = obj.maxLength || 255;
  } else {
    minLength = 3;
    maxLength = 255;
  }

  return t([I18N_SCOPE, 'the_name_must_consist_of__to__characters'], {
    minLength,
    maxLength,
  });
};

export const labelTheDescriptionMustConsistOf3To50Characters: (obj?: {
  minLength?: number;
  maxLength?: number;
}) => string = obj => {
  let minLength;
  let maxLength;

  if (obj) {
    minLength = obj.minLength || 3;
    maxLength = obj.maxLength || 255;
  } else {
    minLength = 3;
    maxLength = 255;
  }

  return t([I18N_SCOPE, 'the_description_must_consist_of__to__characters'], {
    minLength,
    maxLength,
  });
};

export const labelTheNameMustConsistOf3To50CharactersAZ: (obj?: {
  minLength?: number;
  maxLength?: number;
}) => string = obj => {
  let minLength;
  let maxLength;

  if (obj) {
    minLength = obj.minLength || 3;
    maxLength = obj.maxLength || 255;
  } else {
    minLength = 3;
    maxLength = 255;
  }

  return t([I18N_SCOPE, 'the_name_must_consist_of__to__characters_AZ'], {
    minLength,
    maxLength,
  });
};

export const labelReceiveLocationErrorMsg = () =>
  t([I18N_SCOPE, 'receive_location_error_msg']);

export const labelSetLocationManuallyLbl = () =>
  t([I18N_SCOPE, 'set_location_manually_lbl']);
