import React, {useCallback, useMemo} from 'react';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';
import dayjs from 'dayjs';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {NOT_FOUND_FILE} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';

import Button from '@src/components/Button';
import {t} from '@src/i18n';
import FileCard from '@src/modules/library/FileCard';
import FolderCard from '@src/modules/library/FolderCard';
import type {ScheduleMaterial} from '@src/modules/materials/utils';
import {getMaterialFile, materialIsFolder} from '@src/modules/materials/utils';

import styles from './LibraryListItem.module.css';

const I18N_SCOPE = 'shared.CRUProgramLibraryListItem';

export interface Props {
  material: ScheduleMaterial;
  className?: string;
  onClick?: (item: ScheduleMaterial) => void;
  onMoreButtonClick?: (
    item: ScheduleMaterial,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
  onPublicationDayButtonClick?: (item: ScheduleMaterial) => void;
}

const CRUProgramLibraryListItem: React.FC<Props> = props => {
  const {
    material,
    className,
    onClick,
    onMoreButtonClick,
    onPublicationDayButtonClick,
  } = props;

  const onClickCb = useCallback(() => {
    onClick && onClick(material);
  }, [onClick, material]);

  const onMoreButtonClickCb = useCallback(
    (item: IFile | Link, e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(material, e);
    },
    [material, onMoreButtonClick],
  );

  const onPublicationDayButtonPressCb = useCallback(() => {
    onPublicationDayButtonClick && onPublicationDayButtonClick(material);
  }, [material, onPublicationDayButtonClick]);

  const file = useMemo(
    () => getMaterialFile(material) || NOT_FOUND_FILE,
    [material],
  );

  const folder = useMemo(
    () => ({
      name: material.title,
      ...material,
    }),
    [material],
  );

  const isFolder = useMemo(() => materialIsFolder(material), [material]);

  const time = useMemo(
    () => dayjs().startOf('day').add(material.time, 'second').format('LT'),
    [material.time],
  );

  const listItem = useMemo(() => {
    if (isFolder) {
      return (
        <FolderCard
          // @ts-ignore
          folder={folder}
          onClick={onClickCb}
          className={styles.card}
          // @ts-ignore
          onMoreButtonClick={
            onMoreButtonClick ? onMoreButtonClickCb : undefined
          }
        />
      );
    } else {
      return (
        <FileCard
          file={file}
          onClick={onClickCb}
          className={styles.card}
          onMoreButtonClick={
            onMoreButtonClick ? onMoreButtonClickCb : undefined
          }
        />
      );
    }
  }, [
    file,
    folder,
    isFolder,
    onClickCb,
    onMoreButtonClick,
    onMoreButtonClickCb,
  ]);

  return (
    <div
      className={classNames(styles.container, className)}
      onClick={onClickCb}
    >
      {listItem}
      <Button
        className={styles.publicationDayButton}
        onClick={onPublicationDayButtonPressCb}
      >
        {material.day === 0
          ? t([I18N_SCOPE, 'first_day_label'])
          : t([I18N_SCOPE, 'day_label'], {
              day: material.day + 1,
            })}
        {`, ${time}`}
      </Button>
    </div>
  );
};

export default React.memo(CRUProgramLibraryListItem, isEqual);
