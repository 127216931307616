import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {View} from '@yourcoach/shared/uikit/View';

export const InfoBadgeSkeleton = () => (
  <View>
    <Skeleton circle width={32} height={32} />
    <View style={{flexDirection: 'column'}}>
      <Skeleton width={50} height={10} />
      <Skeleton width={50} height={10} />
    </View>
  </View>
);

export const DescriptionSkeleton = () => (
  <Skeleton count={2} width={'100%'} height={15} />
);
