import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';
import {Observer} from 'mobx-react';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import ClientSubgoalsButton, {
  CLIENT_SUBGOALS_BUTTON_ID,
} from '@src/modules/legacy/Goals/ClientSubgoalsButton/ClientSubgoalsButton';
import SubgoalsListItem from '@src/modules/legacy/Goals/SubgoalsListItem/SubgoalsListItem';

import type {Goal, ListItemT, Subgoal} from '../CourseGoalsTab';

import styles from './../../../styles.module.scss';

const I18N_SCOPE = 'CourseGoalsListItem';

interface Props {
  item: ListItemT;
  onMorePress?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ListItemT,
  ) => void;
  selectGoalId?: string | null;
  isSelectRateGoal?: boolean;
  onPress?: (item: ListItemT) => void;
  onAddSubGoalButtonPress?: (item: ListItemT) => void;
  onResultsButtonPress?: (item: ListItemT) => void;
  onClientSubgoalsButtonPress?: (item: ListItemT) => void;
}

const CourseGoalsListItem: FC<Props> = ({
  item,
  selectGoalId,
  isSelectRateGoal = false,
  onMorePress,
  onPress,
  onAddSubGoalButtonPress,
  onResultsButtonPress,
  onClientSubgoalsButtonPress,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const isGoal = useMemo(() => item._id.split(':')[0] === 'goal', [item._id]);

  const isSubgoal = useMemo(
    () => item._id.split(':')[0] === 'subgoal',
    [item._id],
  );

  const isCoach = useMemo(
    () => user && item.coach_ids.includes(user._id),
    [item.coach_ids, user],
  );

  const isGoalHasSubgoals = useMemo(
    () => !!((item as Goal).subgoals && (item as Goal).subgoals.length),
    [item],
  );

  const isClientManagedGoal = useMemo(
    () => isGoal && (item as Goal).is_client_managed,
    [isGoal, item],
  );

  const onMorePressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onMorePress && onMorePress(event, item);
    },
    [onMorePress, item],
  );

  const onPressCb = useCallback(() => {
    onPress && onPress(item);
  }, [onPress, item]);

  const onAddSubGoalButtonPressCb = useCallback(() => {
    onAddSubGoalButtonPress && onAddSubGoalButtonPress(item);
  }, [onAddSubGoalButtonPress, item]);

  const onResultsButtonPressCb = useCallback(() => {
    onResultsButtonPress && onResultsButtonPress(item);
  }, [onResultsButtonPress, item]);

  const onClientSubgoalsButtonPressCb = useCallback(() => {
    onClientSubgoalsButtonPress && onClientSubgoalsButtonPress(item);
  }, [onClientSubgoalsButtonPress, item]);

  return (
    <Observer>
      {() => (
        <div className={`CourseGoalsListItem ${styles.CourseGoalsListItem}`}>
          {isGoal ? (
            <>
              <div className={styles.goalTitleContainer}>
                <div className={styles.goalTitleContainerHeader}>
                  <div className={styles.titleSection}>
                    <div className={styles.goalLabel}>
                      {t('label.goal_index', {index: item.index + 1})}
                    </div>
                    <div className={styles.goalTitle}>{item.title}</div>
                  </div>
                  {onMorePress ? (
                    <div className={styles.moreBut} onClick={onMorePressCb}>
                      ⋮
                    </div>
                  ) : null}
                </div>

                {item.description ? (
                  <div className={styles.goalDescription}>
                    {item.description}
                  </div>
                ) : null}
                {onResultsButtonPress ? (
                  <div
                    onClick={onResultsButtonPressCb}
                    className={`${styles.showResultsButton}  ${
                      isSelectRateGoal ? styles.isActive : ''
                    }`}
                  >
                    {isSelectRateGoal
                      ? t([I18N_SCOPE, 'hide_results_button'])
                      : t([I18N_SCOPE, 'show_results_button'])}
                  </div>
                ) : null}
              </div>
              <div className={styles.subgoalsHeaderContainerStyle}>
                {isCoach ||
                (!isCoach && (isGoalHasSubgoals || isClientManagedGoal)) ? (
                  <div className={styles.subgoalsHeaderLabel}>
                    {t([I18N_SCOPE, 'subgoals_label'])}
                  </div>
                ) : null}
                {onAddSubGoalButtonPress &&
                ((isCoach && isGoalHasSubgoals) ||
                  (!isCoach && isClientManagedGoal && isGoalHasSubgoals)) ? (
                  <div
                    onClick={onAddSubGoalButtonPressCb}
                    className={styles.subgoalsHeaderButton}
                  >
                    <div className={styles.subgoalsHeaderButtonLabel}>
                      {t([I18N_SCOPE, 'add_goal_button'])}
                    </div>
                  </div>
                ) : null}
              </div>
              {onAddSubGoalButtonPress && !isGoalHasSubgoals ? (
                <div
                  onClick={onAddSubGoalButtonPressCb}
                  className={styles.addSubGoalButtonStyle}
                >
                  {t([I18N_SCOPE, 'add_subgoal_button'])}
                </div>
              ) : null}
            </>
          ) : isSubgoal ? (
            <SubgoalsListItem
              subgoal={item as Subgoal}
              onPress={onPressCb}
              selectGoalId={selectGoalId}
              showNextIcon
            />
          ) : item._id.includes(CLIENT_SUBGOALS_BUTTON_ID) ? (
            <ClientSubgoalsButton onPress={onClientSubgoalsButtonPressCb} />
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseGoalsListItem);
