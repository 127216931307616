import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {View} from '@yourcoach/shared/uikit/View';

export const FormSkeleton = () => (
  <View style={{flexDirection: 'column', gap: 20}}>
    <Skeleton width={'100%'} height={60} />
    <View style={{justifyContent: 'space-between'}}>
      <Skeleton width={200} height={30} />
      <Skeleton width={150} height={30} />
    </View>
  </View>
);
