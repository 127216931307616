import type {FC} from 'react';
import React, {memo, useEffect, useMemo} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import Fuse from 'fuse.js';
import {action, computed, observable, toJS} from 'mobx';

import type {ApiRpcQuery, CollectionStore, Expand} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';

import {CustomButton, CustomSearch} from '@src/components/CustomForm';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {t} from '@src/i18n';

import ListItem from '../ListItem/ListItem';

import styles from './../styles.module.scss';

const LIMIT = 100;
const I18N_SCOPE = 'SelectGoal';
const expand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
        ],
      },
    ],
  ],
};

export type Goal = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: Course & {
    client: ExpandedClient;
  };
};

export type ExpandedClient = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) & {
  avatar?: IFile | null;
};

export type GoalListItemT = Omit<Goal, 'subgoals'> & {
  subgoals: SubgoalListItemT[];
  index: number;
  isLast?: boolean;
};

export type SubgoalListItemT = Subgoal & {
  isLast?: boolean;
};

export type ListItemT = GoalListItemT | SubgoalListItemT;

interface Section {
  title?: string;
  program: Goal['program'];
  course?: Goal['course'];
  data: ListItemT[];
}

interface ILocalStore {
  selection: GoalListItemT[];
  query: string;
  setQuery(query: string): void;
  uncollapsedGoalIds: string[];
  isLoading: boolean;
  sections: Section[];
  setIsLoading(isLoading: boolean): void;
  shouldRenderStub: boolean;
  goalsStore: CollectionStore<Goal> | null;
  setGoalsStore(goalsStore: CollectionStore<Goal>): void;
  _onListItemPress(item: ListItemT): void;
  _onToggleGoalPress(item: ListItemT): void;
}

interface Props {
  onSelect?: (goals: Goal[]) => void;
  programId?: string;
  firstProgramId?: string;
  excludeProgramId?: string;
  showProgramGoals?: boolean;
  showMainGoalsOnly?: boolean;
  multiSelection?: boolean;
}

const SelectGoal: FC<Props> = ({
  onSelect,
  programId,
  firstProgramId,
  excludeProgramId,
  showProgramGoals,
  showMainGoalsOnly,
  multiSelection,
}) => {
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          selection: [],
          query: '',
          setQuery(query: string) {
            this.query = query;
          },
          goalsStore: null,
          setGoalsStore(goalsStore: CollectionStore<Goal>) {
            this.goalsStore = goalsStore;
          },
          uncollapsedGoalIds: [],
          isLoading: false,
          setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
          },
          get shouldRenderStub() {
            return this.goalsStore.isLoaded && !this.goalsStore.hasItems;
          },
          get sections() {
            let sections: Section[] = [];

            if (this.goalsStore) {
              let goals = this.goalsStore.items.slice();

              const groups: {[key: string]: Goal[]} = {};

              if (this.query) {
                const fuse = new Fuse(goals, {
                  shouldSort: false,
                  threshold: 0.3,
                  keys: ['title', 'subgoals.title', 'program.title'],
                });

                goals = fuse.search(this.query).map(item => item.item);
              }

              const groupBy = (goal: Goal) => {
                const groupId = goal.course_id || goal.program_id;

                groups[groupId] = groups[groupId] || [];

                groups[groupId].push(goal);
              };

              goals.forEach(groupBy);

              Object.keys(groups).forEach(key => {
                const data: ListItemT[] = [];

                groups[key]
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .forEach((goal, gIndex) => {
                    data.push({
                      ...goal,
                      index: gIndex,
                      subgoals: !showMainGoalsOnly
                        ? goal.subgoals.sort(
                            (a, b) => a.sort_order - b.sort_order,
                          )
                        : [],
                    });

                    if (!showMainGoalsOnly && goal.subgoals.length) {
                      data.push(
                        ...goal.subgoals.sort(
                          (a, b) => a.sort_order - b.sort_order,
                        ),
                      );

                      data[data.length - 1].isLast = true;
                    }
                  });

                sections.push({
                  program: groups[key][0].program,
                  course: groups[key][0].course,
                  data,
                });
              });

              if (firstProgramId) {
                sections = sections.sort((a, b) =>
                  a.program._id === firstProgramId
                    ? -1
                    : b.program._id === firstProgramId
                    ? 1
                    : 0,
                );
              }
            }

            return sections;
          },
          _onListItemPress(item: ListItemT) {
            const isSubgoal = item._id.split(':')[0] === 'subgoal';

            const index = this.selection.findIndex(
              sItem =>
                sItem._id ===
                (isSubgoal ? (item as SubgoalListItemT).goal_id : item._id),
            );

            const selectedGoal = index >= 0 ? this.selection[index] : undefined;

            let isSelected = false;

            if (isSubgoal) {
              if (selectedGoal) {
                isSelected = selectedGoal.subgoal_ids.includes(item._id);
              }
            } else {
              isSelected = !!selectedGoal;
            }

            if (isSelected) {
              if (!isSubgoal) {
                this.selection.splice(index, 1);
              } else {
                const subgoalIndex = this.selection[
                  index
                ].subgoal_ids.findIndex(id => id === item._id);

                if (subgoalIndex >= 0) {
                  this.selection.splice(index, 1, {
                    ...this.selection[index],
                    subgoal_ids: this.selection[index].subgoal_ids.filter(
                      id => id !== item._id,
                    ),
                    subgoals: this.selection[index].subgoals.filter(
                      subgoal => (subgoal as SubgoalListItemT)._id !== item._id,
                    ),
                  });
                }
              }
            } else {
              if (!multiSelection) {
                this.selection = [];
              }

              if (!isSubgoal) {
                this.selection.push(item as GoalListItemT);
              } else {
                const goalIndex = this.selection.findIndex(
                  sItem => sItem._id === (item as SubgoalListItemT).goal_id,
                );

                if (goalIndex >= 0) {
                  this.selection.splice(goalIndex, 1, {
                    ...this.selection[goalIndex],
                    subgoal_ids: [
                      ...this.selection[goalIndex].subgoal_ids,
                      item._id,
                    ],
                    subgoals: [
                      ...this.selection[goalIndex].subgoals,
                      item as SubgoalListItemT,
                    ],
                  });
                } else {
                  const section = this.sections.find(
                    x => x.program._id === item.program_id,
                  );

                  if (section) {
                    const goal = section.data.find(
                      x => x._id === (item as SubgoalListItemT).goal_id,
                    ) as GoalListItemT;

                    if (goal) {
                      this.selection.push({
                        ...goal,
                        subgoal_ids: [item._id],
                        subgoals: [item as SubgoalListItemT],
                      });
                    }
                  }
                }
              }
            }
          },
          _onToggleGoalPress(item: ListItemT) {
            const index = this.uncollapsedGoalIds.findIndex(
              id => id === item._id,
            );

            if (index >= 0) {
              this.uncollapsedGoalIds.splice(index, 1);
            } else {
              this.uncollapsedGoalIds.push(item._id);
            }
          },
        },
        {
          selection: observable.shallow,
          sections: computed,
          query: observable,
          setQuery: action,
          uncollapsedGoalIds: observable,
          isLoading: observable,
          setIsLoading: action,
          shouldRenderStub: computed,
          goalsStore: observable,
          setGoalsStore: action,
          _onListItemPress: action,
          _onToggleGoalPress: action,
        },
      ),
    [firstProgramId, multiSelection, showMainGoalsOnly],
  );

  useEffect(() => {
    localStore.setGoalsStore(
      createCollectionStore({
        method: 'coach.goals.unbound.list',
        params: {
          sort: [['sort_order', -1]],
          query: (
            [
              programId ? ['program_id', '==', programId] : null,
              excludeProgramId ? ['program_id', '!=', excludeProgramId] : null,
              ['course_id', showProgramGoals ? '==' : '!=', null],
            ] as ApiRpcQuery[]
          ).filter(Boolean),
          limit: LIMIT,
          expand,
        },
      }),
    );

    _onRefresh();

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onRefresh = (silent = false) => {
    localStore.goalsStore!.fetch(
      {
        limit: Math.max(localStore.goalsStore!.items.length, LIMIT),
      },
      {silent},
    );
  };

  const _onSetButtonPress = async () => {
    if (onSelect) {
      onSelect(
        toJS(
          // preserve order
          localStore.selection
            .slice()
            .sort((a, b) => a.sort_order - b.sort_order)
            .map(goal => {
              const subgoals = goal.subgoals.sort(
                (a, b) => a.sort_order - b.sort_order,
              );

              return {
                ...goal,
                subgoals,
                subgoal_ids: subgoals.map(subgoal => subgoal._id),
              };
            }),
        ),
      );
    }
  };

  const handleOnSearchGoals = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qVal = event.currentTarget.value;

    localStore.setQuery(qVal);
  };
  const handleOnListItemPress = (item: ListItemT) => {
    localStore._onListItemPress(item);
  };
  const handlaeOnToggleGoalPress = (item: ListItemT) => {
    localStore._onToggleGoalPress(item);
  };

  return (
    <Observer>
      {() => (
        <div className={`SelectGoal ${styles.SelectGoal}`}>
          <div className={styles.menuSearchContainer}>
            <CustomSearch
              label={t([I18N_SCOPE, 'search_input_placeholder'])}
              id="searchQuestionnaires"
              onChange={handleOnSearchGoals}
              classContainer={`searchContainer ${styles.searchContainer}`}
              classInput={styles.searchContainerInput}
              className="search"
              classIcon="icon"
              classIconContainer="iconContainer"
            />
          </div>
          <div className={styles.contentContainer}>
            <Scrollbar
              className={'scrollbar'}
              noScrollX
              wrapperProps={{className: 'wrapper'}}
              trackYProps={{className: 'trackY'}}
              thumbYProps={{className: 'thumbY'}}
              trackXProps={{className: 'trackX'}}
              thumbXProps={{className: 'thumbY'}}
            >
              {localStore.sections.map(section => {
                const isSelectedSection = section.data.some(goal =>
                  localStore.selection
                    .slice()
                    .some(selectGoal => selectGoal._id === goal._id),
                );

                return (
                  <div key={`${section.course?._id}${section.program._id}`}>
                    <div className={styles.headerSectionContainer}>
                      <div className={styles.sectionAvatarContainer}>
                        <OtherUserProfileImg
                          avatar={section.program.avatar}
                          getTitleIconIfNoImage
                          profileClassName={styles.sectionAvatar}
                          title={section.program.title}
                          isBGGradient
                          isOverlay={!section.program.avatar}
                        />
                      </div>
                      <div className={styles.headerTitleContainer}>
                        <div className={styles.programTitleContainer}>
                          {!section.course ? (
                            <div className={styles.programSubTitle}>
                              {t([I18N_SCOPE, 'program_label'])}
                            </div>
                          ) : null}
                          <div className={styles.programTitle}>
                            {section.program.title}
                          </div>
                        </div>
                        <div className={styles.courseTitle}>
                          {`${
                            section.course
                              ? getCourseDurationString(section.course)
                              : ''
                          } ${
                            section.course && section.course.client_id
                              ? section.course.client.name
                              : ''
                          }`}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.goalsContainer} ${
                        isSelectedSection ? styles.isSelected : ''
                      }`}
                    >
                      {section.data.map(item => {
                        const isSubgoal = item._id.split(':')[0] === 'subgoal';

                        const isCollapsed =
                          !localStore.uncollapsedGoalIds.includes(
                            isSubgoal
                              ? (item as SubgoalListItemT).goal_id
                              : item._id,
                          );

                        if (isCollapsed && isSubgoal) {
                          return null;
                        }

                        const goal = localStore.selection.find(
                          sItem =>
                            sItem._id ===
                            (isSubgoal
                              ? (item as SubgoalListItemT).goal_id
                              : item._id),
                        );

                        let isSelected = false;

                        if (isSubgoal) {
                          if (goal) {
                            isSelected = goal.subgoal_ids.includes(item._id);
                          }
                        } else {
                          isSelected = !!goal;
                        }

                        return (
                          <ListItem
                            key={item._id}
                            item={item}
                            isSelected={isSelected}
                            isLast={item.isLast}
                            onPress={handleOnListItemPress}
                            isSelectable={isSelectedSection}
                            isCollapsed={isCollapsed}
                            onToggleIconPress={handlaeOnToggleGoalPress}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Scrollbar>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.createButtContainer}>
              <CustomButton
                type="button"
                classButton={`blueButt ${styles.blueButt}`}
                disabled={!localStore.selection.length}
                onClick={_onSetButtonPress}
              >
                <span>{t([I18N_SCOPE, 'set_button'])}</span>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SelectGoal);
