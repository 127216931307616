import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {v4 as uuidv4} from 'uuid';

import {apiRequest} from '@yourcoach/shared/api';

import {labelCreate, labelEdit} from '@src/common/i18n/i18nCommon';
import {labelQuestionnaire} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
// import {useAppRedirect} from '@src/common/useAppRedirect';
// import {CustomButton} from '@src/components/CustomForm';
// import {IconPrev} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {ColorWrapper} from '@src/layouts';
import {expand as questionnaireExpand} from '@src/models/questionnaire';

// import {PATH_TYPE} from '@src/routes/utils';
import type {TQuestionnaire} from '../context/qAppStore';
import questionnaireAppStore from '../context/qAppStore';

import styles from './styles.module.css';
import useGetSteps from './useGetSteps';

interface Props {}

const QCreateCustom: FC<Props> = () => {
  const steps = useGetSteps();
  const [selectStep, setSection] = useState(0);

  const [loader, setLoader] = useState(false);
  const standardQ = questionnaireAppStore?.standardTemplateQuest;
  // const redirect = useAppRedirect();
  const QUserId = useURLQueryParam('id');

  useEffect(() => {
    if (QUserId) {
      questionnaireAppStore?.setIsEditMode(true);

      const getUserQ = async () => {
        setLoader(true);

        try {
          const {questionnaire, _expanded} = await apiRequest({
            method: 'coach.questionnaires.own.read',
            params: {
              _id: QUserId,
              expand: questionnaireExpand,
            },
          });

          questionnaireAppStore?.setUserTemplateQuest({
            title: (questionnaire as TQuestionnaire).title,
            //@ts-ignore
            avatar: _expanded[(questionnaire as TQuestionnaire).avatar_id],
            description: (questionnaire as TQuestionnaire).description,
            is_public: (questionnaire as TQuestionnaire).is_public,
            sections: (questionnaire as TQuestionnaire).sections
              .slice()
              .map(userSection => ({id: uuidv4(), ...userSection})),
          });
          setLoader(false);
        } catch (error) {
          setError(error.message);
          questionnaireAppStore?.setUserTemplateQuest({
            title: '',
            avatar: null,
            description: '',
            is_public: true,
            sections: [],
          });
          setLoader(false);
        }
      };

      getUserQ();
    } else if (standardQ) {
      questionnaireAppStore?.setUserTemplateQuest({
        title: standardQ.title,
        description: standardQ.description,
        is_public: true,
        avatar: standardQ.avatar,
        sections: standardQ.sections
          .slice()
          .map(standardSection => ({id: uuidv4(), ...standardSection})),
      });
    } else {
      questionnaireAppStore?.setUserTemplateQuest({
        title: '',
        avatar: null,
        description: '',
        is_public: true,
        sections: [],
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickBack = () => {
    if (selectStep > 0) {
      setSection(selectStep - 1);
      // } else {
      //   if (QUserId) {
      //     redirect(`/${PATH_TYPE.yourSpace}/${PATH_TYPE.myToolbox}`);
      //   } else if (standardQ) {
      //     redirect(
      //       `/${PATH_TYPE.questionnaire}/${PATH_TYPE.standardTemplateDetail}/${PATH_TYPE.questions}`,
      //     );
      //   } else {
      //     redirect(`/${PATH_TYPE.createQuestionnaire}`);
      //   }
      // }
    }
  };

  const handleClickContinue = () => {
    setSection(selectStep + 1);
  };

  const handleOnClickStep =
    (numStepSelect: number) =>
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      setSection(numStepSelect);
    };

  return (
    <div className={`QCreateCustom ${styles.QCreateCustom}`}>
      <ColorWrapper containerClass="pl50" className={styles.topHeader}>
        {/*<CustomButton*/}
        {/*  type="button"*/}
        {/*  classButton="QuestionnaireHeaderBut"*/}
        {/*  onClick={handleClickBack}*/}
        {/*>*/}
        {/*  <IconPrev customClass="IconPrev" />*/}
        {/*  <span>{labelBack()}</span>*/}
        {/*</CustomButton>*/}
      </ColorWrapper>
      <ColorWrapper containerClass="pl50" className={styles.mainHeader}>
        <h2>{`${
          QUserId ? labelEdit() : labelCreate()
        } ${labelQuestionnaire()}`}</h2>
        {steps.map(({name, num}) => (
          <div
            key={num}
            onClick={handleOnClickStep(num)}
            className={`customTopMenuUnClickable clickable ${
              selectStep === num ? 'selected' : ''
            }`}
          >
            {name}
          </div>
        ))}
      </ColorWrapper>

      {loader ? (
        <Loader />
      ) : (
        <ColorWrapper containerClass="pl50" className={styles.body}>
          {steps
            .filter(({num}) => selectStep === num)
            .map(({num, component}) => (
              <div key={num}>
                {component(handleClickContinue, handleClickBack)}
              </div>
            ))}
        </ColorWrapper>
      )}
    </div>
  );
};

export default memo(QCreateCustom);
