import type {FC} from 'react';
import React, {memo} from 'react';
import Helmet from 'react-helmet';
import {useHistory} from 'react-router-dom';

import {Logo} from '@yourcoach/shared/uikit/Logo';

import Button from '@src/components/Button';

import styles from './styles.module.css';

interface Props {}

const UnknownPage: FC<Props> = () => {
  const history = useHistory();

  return (
    <div className={styles.page}>
      <Helmet title="Unknown page" />
      <Logo size={75} variant="text" />
      <h1 className={styles.header}>Oops! That page can’t be found.</h1>
      <Button onClick={history.goBack}>Go Back</Button>
    </div>
  );
};

export default memo(UnknownPage);
