import React, {useCallback} from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';

import {Clickable} from '@yourcoach/shared/uikit/Clickable';

import localAppStore from '@src/context/appStore';
import {t} from '@src/i18n';

import {NotificationsList} from '../../containers/List';
import {I18N_SCOPE} from '../../utils';

import * as S from './styles';
import type {Props} from './types';

export const NotificationsModal = NiceModal.create<Props>(() => {
  const modal = useModal();

  const onCloseButtonClick = useCallback(() => {
    localAppStore.setIsTopNotificationsOpen(false);
  }, []);

  const onRequestClose = useCallback(() => {
    localAppStore.setIsTopNotificationsOpen(false);
  }, []);

  return (
    <S.Modal
      onAfterClose={modal.remove}
      isOpen={modal.visible}
      onRequestClose={onRequestClose}
      disableBodyScroll
    >
      <S.Container>
        <S.Header>
          <S.Title>{t([I18N_SCOPE, 'title'])}</S.Title>
          <Clickable onClick={onCloseButtonClick}>
            <S.CloseButtonContainer>
              <S.CloseIcon />
            </S.CloseButtonContainer>
          </Clickable>
        </S.Header>
        <S.Content>
          <NotificationsList />
        </S.Content>
      </S.Container>
    </S.Modal>
  );
});
