import type {FC, ReactElement} from 'react';
import React, {createElement, memo, useContext, useEffect, useRef} from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {observable, reaction} from 'mobx';

import {labelUserProfileTabPathName} from '@src/common/i18n/i18nCommon';
import isActive from '@src/common/isActivePath';
import {IconUserProfileTab} from '@src/components/icons';
import AppContext from '@src/context/App';
import {PATH_TYPE} from '@src/routes/utils';

import UserProfile from '../UserProfile/UserProfile';

interface navItem {
  onlyCoach: boolean;
  name: string;
  nameUrl: string;
  obj: () => FC;
  ico: () => ReactElement<any, any> | null;
}

const getNavArr: () => navItem[] = () => [
  {
    name: labelUserProfileTabPathName(),
    nameUrl: PATH_TYPE.userProfileTab,
    obj: () => UserProfile,
    onlyCoach: false,
    ico: () => (
      <div className="icoContainer userProfileTab">
        <IconUserProfileTab />
      </div>
    ),
  },
];

const NavTabs: FC = () => {
  const navArr = getNavArr();
  const {url} = useRouteMatch();
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const localStore = useRef(
    observable(
      {
        isCoach:
          currentUserStore.user &&
          currentUserStore.user.roles.includes('coach'),
      },
      {isCoach: observable},
    ),
  ).current;

  useEffect(() => {
    const dispose = reaction(
      () => currentUserStore.user,
      user => {
        localStore.isCoach = user && user.roles.includes('coach');
        // reaction.dispose();
      },
    );

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div className="NavTabs">
          {navArr
            .filter(({onlyCoach}) => {
              return localStore.isCoach || (!localStore.isCoach && !onlyCoach);
            })
            .map(({name, nameUrl, ico}) => {
              return (
                <div key={nameUrl} className="NavTabsItem">
                  <NavLink
                    to={`${url}/${nameUrl}`}
                    isActive={isActive.bind(this, `${url}/${nameUrl}`)}
                    activeClassName="selected"
                  >
                    <div className="linkContainer">
                      {ico()}
                      <div className="nameContainer">{name}</div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
        </div>
      )}
    </Observer>
  );
};

export default memo(NavTabs);

const RoutesEl: FC = () => {
  const navArr = getNavArr();
  const {url} = useRouteMatch();
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const localStore = useRef(
    observable(
      {
        isCoach:
          currentUserStore.user &&
          currentUserStore.user.roles.includes('coach'),
      },
      {isCoach: observable},
    ),
  ).current;

  reaction(
    () => currentUserStore.user,
    user => {
      localStore.isCoach = user && user.roles.includes('coach');
    },
  );

  return (
    <Observer>
      {() => (
        <Switch>
          {navArr
            .filter(({onlyCoach}) => {
              return localStore.isCoach || (!localStore.isCoach && !onlyCoach);
            })
            .map(({nameUrl, obj}: navItem) => {
              return (
                <Route key={nameUrl} path={`${url}/${nameUrl}`}>
                  {createElement(obj())}
                </Route>
              );
            })}
          <Route path="*">
            <Redirect
              to={{
                pathname: `${url}/${
                  navArr.filter(({onlyCoach}) => {
                    return (
                      localStore.isCoach || (!localStore.isCoach && !onlyCoach)
                    );
                  })[0].nameUrl
                }`,
              }}
            />
          </Route>
        </Switch>
      )}
    </Observer>
  );
};

export const Routes = memo(RoutesEl);
