import {
  labelImACoach,
  labelImLookingForACoach,
} from '@src/common/i18n/i18nProfile';
import type {IRadioItem} from '@src/components/CustomForm/CustomRadioButton/CustomRadioButton';

const AccountClientIcon = require('@yourcoach/shared/assets/account-client.png');
const AccountCoachIcon = require('@yourcoach/shared/assets/account-coach.png');

const getRadioBut = () => {
  const radioItems: IRadioItem[] = [
    {
      id: 'client',
      label: labelImLookingForACoach(),
      Img: AccountClientIcon,
    },
    {
      id: 'coach',
      label: labelImACoach(),
      Img: AccountCoachIcon,
    },
  ];

  return radioItems;
};

export default getRadioBut;
