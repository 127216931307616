import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import SeeProgramContext from './SeeProgramContext';
import useSeeProgramLocalStore from './useSeeProgramLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const SeeProgramState: FC<Props> = ({children}) => {
  const localStore = useSeeProgramLocalStore();

  return (
    <SeeProgramContext.Provider value={localStore}>
      {children}
    </SeeProgramContext.Provider>
  );
};

export default memo(SeeProgramState);
