import type {FC, ReactNode} from 'react';
import React, {useReducer} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';

import PracticeContext from './PracticeContext';
import PracticeReducer from './PracticeReducer';
import {
  SET_AVATAR,
  SET_CLIENTS_HELPED,
  SET_COACH_DESCRIPTION,
  SET_COACH_LOGO,
  SET_COACH_TITLE,
  SET_NAME,
  SET_USER,
} from './types';

interface Props {
  children: ReactNode[] | ReactNode;
}

export interface IState {
  userId: string;
  userAvatar: IFile | null;
  coachLogo: IFile | null;
  coachTitle: string;
  coachDescription: string;
  name: string;
  clientsHelped: number;
}

interface ISetClientsHelpedAction {
  type: 'SET_CLIENTS_HELPED';
  payload: number;
}

interface ISetUserAction {
  type: 'SET_USER';
  payload: string;
}

interface ISetCoachTitleAction {
  type: 'SET_COACH_TITLE';
  payload: string;
}

interface ISetCoachDescriptionAction {
  type: 'SET_COACH_DESCRIPTION';
  payload: string;
}

interface ISetNameAction {
  type: 'SET_NAME';
  payload: string;
}

interface ISetAvatarIdAction {
  type: 'SET_AVATAR';
  payload: IFile | null;
}

interface ISetCoachLogoIdAction {
  type: 'SET_COACH_LOGO';
  payload: IFile | null;
}

export type TActionTypes =
  | ISetUserAction
  | ISetAvatarIdAction
  | ISetCoachLogoIdAction
  | ISetCoachDescriptionAction
  | ISetNameAction
  | ISetClientsHelpedAction
  | ISetCoachTitleAction;

export interface IContextState extends IState {
  setUserId: (userId: string) => void;
  setAvatar: (userAvatar: IFile | null) => void;
  setCoachLogo: (coachLogo: IFile | null) => void;
  setCoachTitle: (coachTitle: string) => void;
  setCoachDescription: (coachDescription: string) => void;
  setName: (name: string) => void;
  setClientsHelpedId: (clientsHelped: number) => void;
}

const PracticeState: FC<Props> = ({children}) => {
  const initialState: IState = {
    userId: '',
    coachTitle: '',
    coachDescription: '',
    name: '',
    userAvatar: null,
    coachLogo: null,
    clientsHelped: 0,
  };

  const [state, dispatch] = useReducer(PracticeReducer, initialState);

  const setUserId = (userId: string) =>
    dispatch({type: SET_USER, payload: userId});

  const setClientsHelpedId = (clientsHelped: number) =>
    dispatch({type: SET_CLIENTS_HELPED, payload: clientsHelped});

  const setCoachTitle = (coachTitle: string) =>
    dispatch({type: SET_COACH_TITLE, payload: coachTitle});

  const setCoachDescription = (coachDescription: string) =>
    dispatch({type: SET_COACH_DESCRIPTION, payload: coachDescription});

  const setName = (name: string) => dispatch({type: SET_NAME, payload: name});

  const setAvatar = (userAvatar: IFile | null) =>
    dispatch({type: SET_AVATAR, payload: userAvatar});

  const setCoachLogo = (coachLogo: IFile | null) =>
    dispatch({type: SET_COACH_LOGO, payload: coachLogo});

  return (
    <PracticeContext.Provider
      value={{
        userId: state.userId,
        coachTitle: state.coachTitle,
        coachDescription: state.coachDescription,
        userAvatar: state.userAvatar,
        coachLogo: state.coachLogo,
        name: state.name,
        clientsHelped: state.clientsHelped,
        setUserId,
        setAvatar,
        setCoachLogo,
        setCoachTitle,
        setCoachDescription,
        setName,
        setClientsHelpedId,
      }}
    >
      {children}
    </PracticeContext.Provider>
  );
};

export default PracticeState;
