import {action, observable, runInAction} from 'mobx';

export interface ITimerStore {
  countTimer: number;
  timerId: null;
  isTimerStart: boolean;
  startTimer(): void;
  stopTimer(): void;
  initTimer(): void;
  updateTime(countTimer: number): void;
}

export type ILocalStoreTimer = (count?: number) => ITimerStore;

const useLocalStoreTimer: ILocalStoreTimer = (count: number = 60) => {
  const store: ITimerStore = observable<ITimerStore>(
    {
      countTimer: count,
      timerId: null,
      isTimerStart: false,
      updateTime(countTimer: number) {
        this.countTimer = countTimer;
        this.count = countTimer;
      },
      startTimer() {
        this.isTimerStart = true;

        if (this.timerId === null) {
          this.timerId = setInterval(() => {
            runInAction(() => {
              this.countTimer--;
            });

            if (this.countTimer <= 0) {
              clearInterval(this.timerId);
              this.initTimer();
            }
          }, 1000);
        }
      },

      stopTimer() {
        if (this.timerId !== null) {
          clearInterval(this.timerId);
        }
      },

      initTimer() {
        this.countTimer = count;
        this.timerId = null;
        this.isTimerStart = false;
      },
    },
    {
      countTimer: observable,
      isTimerStart: observable,
      timerId: observable,
      startTimer: action,
      initTimer: action,
      stopTimer: action,
      updateTime: action,
    },
  );

  return store;
};

export default useLocalStoreTimer;
