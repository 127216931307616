import styled from 'styled-components';

import TimeIcon from '@yourcoach/shared/assets/icons/primary/Time Circle.svg';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Image} from '@yourcoach/shared/uikit/Image';
import {View} from '@yourcoach/shared/uikit/View';

import NoResults from '@src/components/NoResults/NoResults';

const ComingSoonIcon = styled(TimeIcon)`
  width: 100px;
  height: 100px;
  fill: ${getColor('icon3')};
  margin-block: 20px;
`;

const SNoResults = styled(NoResults)`
  & > div > div {
    width: auto !important;
    height: auto !important;
  }
`;

const ImageWrapper = styled(View)`
  height: 600px;
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
  justify-content: center;
`;

const ImageContainer = styled(View)`
  overflow-y: auto;
  border-radius: 10px;
  overflow: hidden;
`;

export const Tab360 = () => {
  return process.env.APP_ENV === 'stage' ? (
    <ImageWrapper>
      <ImageContainer>
        <Image src={require('./360-oura.jpg')} />
      </ImageContainer>
    </ImageWrapper>
  ) : (
    <SNoResults text={'Coming soon'}>
      <ComingSoonIcon />
    </SNoResults>
  );
};
