import type {useHistory} from 'react-router-dom';

import {complaintStore} from '@yourcoach/shared/api/complaint';
import {membershipStore} from '@yourcoach/shared/api/membership';
import {alert} from '@yourcoach/shared/modules/alert';
import type {NotificationsListItem} from '@yourcoach/shared/modules/notifications/components/NotificationsListItem';
import type {NotificationsStore} from '@yourcoach/shared/modules/notifications/stores/Notifications';
import {isMeCoachForEntity} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';
import type {GetComponentProps} from '@yourcoach/shared/utils/utility-types';

import {t} from '@src/i18n';
import type {TTabs} from '@src/modules/SeeProgram/Squad/Course/Course';
import {PATH_TYPE} from '@src/routes/utils';
import {PathBuilderService} from '@src/v2/services/PathBuilderService';

type OnClickArgs = Parameters<
  GetComponentProps<typeof NotificationsListItem>['onClick']
>;

export const getClickHandler = ({
  id,
  event,
  contexts,
  store,
  history,
}: {
  id: OnClickArgs[0];
  event: OnClickArgs[1];
  contexts: OnClickArgs[2];
  store: InstanceType<typeof NotificationsStore>;
  history: ReturnType<typeof useHistory>;
}) => {
  const {
    task,
    course,
    program,
    membership,
    conference,
    invite,
    actor,
    user_questionnaire,
    payment,
    post,
    complaint,
    certificate,
    goal,
    subgoal,
  } = contexts;

  switch (id) {
    case 'task_pending':
    case 'task_overdue':
    case 'task_done': {
      if (task?._id) {
        return () => {
          history.push(PathBuilderService.toTask(task._id!));
        };
      }

      return undefined;
    }
    case 'materials_disclosed': {
      if (course?._id) {
        return () => {
          history.push({
            pathname: '/chats',
            search: `?cid=${course.channel_ids[0]}`,
            state: {tab: 'library'},
          });
        };
      }

      return undefined;
    }
    case 'program_shared':
    case 'program_updated':
    case 'program_signed':
    case 'program_rejected':
    case 'program_resigned':
    case 'edition_created':
    case 'edition_signed': {
      if (program?._id) {
        if (isMeCoachForEntity(program)) {
          return () => {
            history.push(
              PathBuilderService.toProgram(
                {slug: program.user?.slug, id: program.user_id!},
                {slug: program.slug, id: program._id!},
              ),
            );
          };
        }
      }

      return undefined;
    }
    case 'membership_created': {
      if (course?._id && program?._id) {
        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
            ),
          );
        };
      }

      return undefined;
    }
    case 'membership_requested': {
      if (program && program._id) {
        return () => {
          history.push(
            PathBuilderService.toProgram(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              {tab: 'requests'},
            ),
          );
        };
      }

      return undefined;
    }
    case 'membership_proposed': {
      if (program?._id) {
        return () => {
          history.push(
            PathBuilderService.toProgram(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
            ),
          );
        };
      }

      return undefined;
    }
    case 'membership_accepted': {
      if (course?._id && program?._id) {
        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
            ),
          );
        };
      }

      return undefined;
    }
    case 'membership_declined': {
      if (program?._id) {
        return () => {
          history.push(
            PathBuilderService.toProgram(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
            ),
          );
        };
      }

      return undefined;
    }
    case 'membership_frozen':
    case 'membership_unfrozen': {
      if (course?._id && program?._id) {
        let tab: TTabs = '';

        if (membership && membership.frozen_by === 'failed_payment') {
          tab = 'payments';
        }

        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
              {tab},
            ),
          );
        };
      }

      return undefined;
    }
    case 'start_conference_button': {
      if (conference?._id) {
        return async () => {
          if (await alert.confirm()) {
            history.push(PathBuilderService.toConference(conference._id!));
          }
        };
      }

      return undefined;
    }
    case 'join_conference_button': {
      if (conference?._id) {
        return async () => {
          const foundMembership = membershipStore.membershipLookup.get(
            conference.course_id || '',
          );

          const userIsFrozen = foundMembership?.status === 'frozen';

          if (userIsFrozen) {
            if (course?._id && program?._id) {
              history.push(
                PathBuilderService.toCourse(
                  {slug: program.user?.slug, id: program.user_id!},
                  {slug: program.slug, id: program._id!},
                  course._id as string,
                  program._id,
                ),
              );
            }
          } else {
            history.push(PathBuilderService.toConference(conference._id!));
          }
        };
      }

      return undefined;
    }
    case 'course_started': {
      if (course?._id && program?._id) {
        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
            ),
          );
        };
      }

      return undefined;
    }
    case 'course_created':
    case 'course_pending': {
      if (course?._id && program?._id) {
        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
            ),
          );
        };
      }

      return undefined;
    }
    case 'invite_received': {
      if (!invite) {
        return undefined;
      }

      if (program && program._id) {
        return () => {
          history.push(
            PathBuilderService.toProgram(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
            ),
          );
        };
      }

      if (invite.user_questionnaire_id && invite.user_questionnaire?._id) {
        if (
          invite.user_questionnaire.answered ||
          invite.user_questionnaire.last_answer
        ) {
          return () => {
            history.push({
              pathname: '/user-questionnaire/progress',
              search: `id=${invite.user_questionnaire_id}`,
            });
          };
        }

        return () => {
          history.push({
            pathname: '/user-questionnaire/about',
            search: `id=${invite.user_questionnaire_id}`,
          });
        };
      }

      if (actor?._id) {
        return () => {
          history.push(
            PathBuilderService.toPractice({slug: actor.slug, id: actor._id!}),
          );
        };
      }

      return undefined;
    }
    case 'questionnaire_completed': {
      if (user_questionnaire?._id) {
        return () =>
          history.push({
            pathname: '/questionnaire/view/status',
            search: `?id=${user_questionnaire._id!}`,
          });
      }

      return undefined;
    }
    case 'payment_upcoming':
    case 'payment_marked_paid':
    case 'payment_failed':
    case 'payment_requires_action': {
      if (course?._id && program?._id) {
        return () => {
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              course._id as string,
              program._id,
              {tab: 'payments'},
            ),
          );
        };
      } else if (payment?.type === 'subscription') {
        return () => {
          history.push({
            pathname: '/profile/user-payments',
          });
        };
      }

      return undefined;
    }
    case 'payment_successful':
    case 'payment_transferred': {
      if (payment?._id && payment.type === 'program') {
        return () => {
          history.push({
            pathname: `/${PATH_TYPE.yourSpace}/my-finances`,
            state: {tab: 'incoming'},
          });
        };
      }

      return undefined;
    }
    case 'complaint_received': {
      if (post?._id) {
        return () => {
          history.push({
            pathname: '/chats',
            search: `?cid=${post.channel_id}`,
            state: {postId: post._id},
          });
        };
      }

      return undefined;
    }
    case 'resolve_complaint_button': {
      if (complaint?._id) {
        return async () => {
          try {
            const complaintIndex = event.contexts.findIndex(
              context => context && context._id.split(':')[0] === 'complaint',
            );

            if (complaintIndex < 0) {
              throw new Error('Complaint not found');
            }

            const resolvedComplaint = await complaintStore.resolve(complaint);

            logger.event('complaint resolved');

            event.contexts.splice(complaintIndex, 1, resolvedComplaint);

            store.todayEventsStore.updateItem(event, event);
            store.earlierEventsStore.updateItem(event, event);
          } catch (error) {
            logger.error(error);

            alert.show({
              title: t('shared.message.error'),
              message: error.message,
            });
          }
        };
      }

      return undefined;
    }
    case 'program_shared_message_button': {
      if (actor?._id) {
        return () => {
          history.push({
            pathname: '/chats',
            state: {withUser: actor},
          });
        };
      }

      return undefined;
    }
    case 'certificate_approved':
    case 'certificate_rejected': {
      if (certificate?._id) {
        return undefined;
      }

      return undefined;
    }
    case 'goal_reached': {
      if (goal?._id) {
        return () =>
          history.push({
            pathname: '/goals',
            search: `?goalId=${goal.parent_goal_id}`,
            state: {forCoach: true},
          });
      }

      return undefined;
    }
    case 'subgoal_reached': {
      if (goal?._id && subgoal?._id) {
        return () =>
          history.push({
            pathname: '/goals',
            search: `?goalId=${goal.parent_goal_id}`,
            state: {
              ClientGoal: true,
              subgoalId: subgoal.parent_subgoal_id,
              clientId: subgoal.client_id,
            },
          });
      }

      return undefined;
    }
    case 'goal_coach_review_reminder':
    case 'goal_coach_review_expiring': {
      if (goal?._id && program?._id) {
        return () =>
          history.push(
            PathBuilderService.toCourse(
              {slug: program.user?.slug, id: program.user_id!},
              {slug: program.slug, id: program._id!},
              goal.course_id as string,
              program._id,
              {tab: 'goals'},
            ),
          );
      }

      return undefined;
    }
    case 'goal_client_review_reminder':
    case 'goal_client_review_expiring': {
      if (goal?._id) {
        return () =>
          history.push({
            pathname: '/goals',
            search: `?goalId=${goal._id}`,
            state: {
              courseId: goal.course_id,
              RateGoals: true,
            },
          });
      }

      return undefined;
    }
    case 'offered_program_created_coach': {
      if (program?._id) {
        return () => {
          return () =>
            history.push(
              PathBuilderService.toProgram(
                {slug: program.user?.slug, id: program.user_id!},
                {slug: program.slug, id: program._id!},
              ),
            );
        };
      }

      return undefined;
    }
    case 'offered_program_created_client': {
      if (membership?._id) {
        if (
          membership.status === 'accepted' &&
          membership.course?.channel_ids.length
        ) {
          return () =>
            history.push({
              pathname: '/chats',
              search: `?cid=${membership.course?.channel_ids[0]}`,
            });
        } else if (membership.status === 'proposed' && program?._id) {
          return () =>
            history.push(
              PathBuilderService.toProgram(
                {slug: program.user?.slug, id: program.user_id!},
                {slug: program.slug, id: program._id!},
              ),
            );
        }
      }

      return undefined;
    }
    default:
      return undefined;
  }
};
