import {t} from '@src/i18n';

const I18N_SCOPE = 'Library';

export const labelMyMaterialsLbl = () => t([I18N_SCOPE, 'my_materials_lbl']);

export const labelMyProgramsLbl = () => t([I18N_SCOPE, 'my_programs_lbl']);

export const labelMyUploadsLbl = () => t([I18N_SCOPE, 'my_uploads_lbl']);

export const labelAddFile = () => t([I18N_SCOPE, 'addFile']);

export const labelUploadFiles = () => t([I18N_SCOPE, 'uploadFiles']);

export const labelUploadAll = () => t([I18N_SCOPE, 'uploadAll']);

export const labelUpload = () => t([I18N_SCOPE, 'upload']);

export const labelRemove = () => t([I18N_SCOPE, 'remove']);

export const labelChooseFiles = () => t([I18N_SCOPE, 'chooseFiles']);

export const labelOrDropFilesHere = () => t([I18N_SCOPE, 'orDropFilesHere']);

export const labelNoFileChosen = () => t([I18N_SCOPE, 'noFileChosen']);

export const labelAddFolder = () => t([I18N_SCOPE, 'addFolder']);

export const labelEditFolder = () => t([I18N_SCOPE, 'editFolder']);

export const labelFolderName = () => t([I18N_SCOPE, 'folderName']);

export const labelAddLink = () => t([I18N_SCOPE, 'addLink']);

export const labelLinkUrl = () => t([I18N_SCOPE, 'linkUrl']);

export const labelIncorrectUrl = () => t([I18N_SCOPE, 'incorrectUrl']);

export const labelNotAValidField = () => t([I18N_SCOPE, 'notAValidField']);

export const labelRequiredFieldError = () =>
  t('shared.validation.required_field_error');

export const labelEditLink = () => t([I18N_SCOPE, 'editLink']);

export const labelFiles = () => t([I18N_SCOPE, 'files']);

export const labelCoursesLbl = () => t([I18N_SCOPE, 'courses_lbl']);
