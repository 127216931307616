import {action, flow, makeObservable, observable} from 'mobx';

import {AsyncFlowManager, StoreException} from '@yourcoach/shared/utils';

import type {LocationType, LocationWithFromState} from '../../types';

import {Crumb} from './Crumb.store';

export class BreadcrumbsStore {
  location: LocationType | LocationWithFromState;
  crumbs: Crumb[];
  private readonly isWithCrumbs: boolean;
  private readonly isWithFrom: boolean;
  private readonly flowManager = new AsyncFlowManager();
  constructor(config: {isWithCrumbs: boolean; isWithFrom: boolean}) {
    this.isWithCrumbs = config.isWithCrumbs;
    this.isWithFrom = config.isWithFrom;
    this.crumbs = [];

    makeObservable(this, {
      location: observable,
      crumbs: observable,
      setCrumbs: action,
      nextLocationFlow: flow.bound,
    });
  }

  nextLocation = async (location: LocationType) => {
    await this.flowManager.run(this.nextLocationFlow(location));
  };

  *nextLocationFlow(location: LocationType) {
    if (this.location?.pathname !== location.pathname) {
      this.location = location;

      const crumbs = yield this.createCrumbs();

      this.setCrumbs(crumbs);
    }
  }

  setCrumbs = (crumbs: Crumb[]) => {
    this.crumbs = crumbs;
  };

  private async createCrumbs() {
    let crumbs: Crumb[] = [];

    if (!this.isWithCrumbs) {
      return crumbs;
    }

    if (
      this.isWithFrom &&
      (this.location as LocationWithFromState).state?.from
    ) {
      const fromRoot = (this.location as LocationWithFromState).state?.from
        .split('/')
        .filter(Boolean)[0];

      if (fromRoot) {
        const crumb = await Crumb.build([fromRoot]);

        crumbs.push(crumb);
      }
    }

    if (this.location.pathname) {
      const pathChunks = this.location.pathname.split('/').filter(Boolean);

      const promises = pathChunks
        /** slice(1) to skip root chunk */
        .slice(1)
        .map((_, index) => Crumb.build(pathChunks.slice(0, index + 2)));

      try {
        const pathCrumbs = await Promise.all(promises);

        crumbs.push(...pathCrumbs);
      } catch (e) {
        throw new StoreException('BreadcrumbsStore', e);
      }
    }

    return crumbs;
  }
}
