import styled, {keyframes} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Image} from '@yourcoach/shared/uikit/Image';
import {Text} from '@yourcoach/shared/uikit/Text';

import List from '@src/components/List';
import ModalNew from '@src/components/ModalNew';

export const Modal = styled(ModalNew)`
  border-radius: 20px !important;
  width: 50vw !important;
  min-width: 400px !important;

  .Modal {
    background-color: ${getColor('background2')};
  }

  .body {
    padding: 20px;
    height: 60vh !important;
    min-height: 400px !important;
  }
`;

export const InfoRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const UserInfo = styled('div')`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled(Image)`
  width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 12px;
`;

export const UserName = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
`;

export const CoachNotesTitle = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.41px;
  margin-top: 20px;
  margin-bottom: 16px;
`;

export const OldCoachTitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  width: 740px;
`;

export const OldCoachArrow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  padding-right: 22px;
`;

const fadeIn = keyframes`
  from {
    transform: scale(0.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const OldCoachNotesWrapper = styled<any>('div')`
  display: flex;
  flex-direction: column;
  animation-name: ${fadeIn};
  animation-duration: 0.3s;
`;

export const ProgramContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProgramTitle = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.41px;
  color: ${getColor('text3')};
`;

export const CourseDates = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.41px;
  color: ${getColor('text3')};
`;

export const ScrollableArea = styled('div')`
  height: calc(68vh - 24px);
  margin: 12px 0;
  padding-right: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f5f6;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #25d2a2;
    border-radius: 2px;
  }
`;

const SList = styled(List)`
  margin: 20px 0;
  flex: 1;
`;

export {SList as List};

export const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
`;
