import type {FC} from 'react';
import React, {memo, useEffect, useMemo, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {IconCalendar} from '@src/components/icons';
import IconPrev from '@src/components/icons/IconPrev/IconPrev';

interface Props {
  options: IOption[];
  label?: string;
  defaultId?: string;
  className?: string;
  classNameMain?: string;
  customClassError?: string;
  disabled?: boolean;
  error?: string;
  seeError?: boolean;
  isCalendar?: boolean;
  onSelect: (id: string) => void;
  onClick?: () => void;
}

interface ILocalStore {
  currentOption: IOption | null;
  isOpenSelect: boolean;
  timer: NodeJS.Timeout | null;
  openSelect: () => void;
  closeSelect: () => void;
  optionSelect: (selectId: string) => void;
}

export interface IOption {
  id: string;
  val: string;
}

const CustomSelect: FC<Props> = ({
  options,
  onSelect,
  onClick,
  label = '',
  className = '',
  classNameMain = 'CustomSelect',
  customClassError = '',
  error = '',
  defaultId,
  seeError = true,
  disabled = false,
  isCalendar = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const localStore = useMemo(
    () =>
      observable<ILocalStore>(
        {
          currentOption: null,
          isOpenSelect: false,
          timer: null,
          openSelect() {
            if (!disabled) {
              this.isOpenSelect = true;
              clearTimeout(this.timer);

              this.timer = setTimeout(() => {
                if (
                  listRef &&
                  listRef.current &&
                  listRef.current.querySelectorAll
                ) {
                  if (
                    listRef.current.querySelectorAll(
                      '.activeSelectOption',
                    )[0] as HTMLLIElement
                  ) {
                    listRef.current.scrollTo(
                      0,
                      (
                        listRef.current.querySelectorAll(
                          '.activeSelectOption',
                        )[0] as HTMLLIElement
                      ).offsetTop,
                    );
                  }
                }
              }, 100);
            }
          },
          closeSelect() {
            this.isOpenSelect = false;
          },
          optionSelect(selectId: string) {
            this.currentOption = options.find(({id}) => id === selectId);
            this.closeSelect();
          },
        },
        {
          currentOption: observable,
          isOpenSelect: observable,
          timer: observable,
          openSelect: action,
          closeSelect: action,
          optionSelect: action,
        },
      ),
    [disabled, options],
  );

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current) {
        if (e.target) {
          if (!wrapperRef.current.contains(e.target as Node)) {
            if (localStore.isOpenSelect) {
              localStore.closeSelect();
            }
          }
        }
      }
    };

    if (defaultId) {
      localStore.optionSelect(defaultId);
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);

      if (localStore.timer) {
        clearTimeout(localStore.timer);
      }
    };
  }, [defaultId, localStore]);

  const handleClickOption = (id: string) => {
    return (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      localStore.optionSelect(id);
      onSelect(id);
    };
  };

  const handleOpenSelect = () => {
    localStore.openSelect();
  };

  return (
    <Observer>
      {() => (
        <>
          <div
            className={`${classNameMain} ${className} ${
              disabled ? 'CustomSelectDisabled' : ''
            } ${localStore.isOpenSelect ? 'open' : 'close'}`}
            onClick={onClick ? onClick : handleOpenSelect}
            ref={wrapperRef}
          >
            {isCalendar ? (
              <IconCalendar className={'CustomSelectCalendar'} />
            ) : (
              <IconPrev customClass="CustomSelectArrow" />
            )}
            <label className={localStore.currentOption ? 'activeLabel' : ''}>
              {label}
            </label>
            <span>{localStore.currentOption?.val}</span>
            {localStore.isOpenSelect && (
              <ul ref={listRef}>
                {options.map(({val, id}) => (
                  <li
                    key={id}
                    id={id}
                    className={
                      localStore.currentOption?.id === id
                        ? 'activeSelectOption'
                        : ''
                    }
                    onClick={handleClickOption(id)}
                  >
                    {val}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {seeError ? (
            <CSSTransition
              in={error !== ''}
              unmountOnExit
              timeout={10}
              classNames={{
                enter: 'animate__fadeInDown',
                enterActive: 'animate__fadeInDown',
                enterDone: 'animate__fadeInDown',
                exit: 'animate__fadeOutUp',
                exitActive: 'animate__fadeOutUp',
                exitDone: 'animate__fadeOutUp',
              }}
            >
              <div
                className={`animate__animated errorContainer ${customClassError}`}
              >
                <span>{error}</span>
              </div>
            </CSSTransition>
          ) : null}
        </>
      )}
    </Observer>
  );
};

export default memo(CustomSelect);
