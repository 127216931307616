import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';

import type {Props as SignContractProps} from './SignContract';
import SignContract from './SignContract';

type Props = Partial<ModalProps> & SignContractProps;

const SignContractModal: React.FC<Props> = ({
  onSign,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps}>
    <SignContract onSign={onSign} />
    {children}
  </Modal>
);

export default React.memo(SignContractModal);
