import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {computed, observable, runInAction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {sortCoursesFn} from '@yourcoach/shared/api/course';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {Edition} from '@yourcoach/shared/api/program';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelProgramClientCoursesTabArchivedCourses,
  labelProgramClientCoursesTabNoResultsLbl,
} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';

import {emitter} from '../../../../widget/src/utils';
import SeeProgramContext from '../context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '../context/useSeeProgramLocalStore';

import styles from './../styles.module.scss';
import ProgramClientCoursesListItem from './ProgramClientCoursesListItem/ProgramClientCoursesListItem';

export type MembershipT = Membership & {
  course?: Course;
  edition?: Edition;
};

interface ILocalStore {
  membershipsStore: CollectionStore<MembershipT> | null;
  shouldRenderStub: boolean;
  isFetching: boolean;
  data: MembershipT[];
  isLoaded: boolean;
}

interface Props {}

const SquadsTabClient: FC<Props> = () => {
  const LIMIT = 999;
  const history = useHistory();
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);

  const localStore: ILocalStore = useRef(
    observable(
      {
        membershipsStore: null,
        isLoaded: false,
        get shouldRenderStub() {
          return (
            this.membershipsStore &&
            this.membershipsStore.isLoaded &&
            !this.data.length
          );
        },
        get isFetching() {
          return (
            this.membershipsStore &&
            (!(this.membershipsStore as CollectionStore<MembershipT>)
              .isLoaded ||
              (this.membershipsStore as CollectionStore<MembershipT>)
                .isFetching)
          );
        },
        get data() {
          const archivedMemberships: MembershipT[] = [];
          const memberships: MembershipT[] = [];

          if (this.membershipsStore) {
            this.membershipsStore.items
              .slice()
              .filter(item => !!item.course)
              .sort((a, b) => sortCoursesFn(a.course!, b.course!))
              .forEach(membership => {
                if (membership.status === 'archived') {
                  archivedMemberships.push(membership);
                } else {
                  memberships.push(membership);
                }
              });

            if (archivedMemberships.length) {
              return (
                memberships
                  // @ts-ignore
                  .concat([{_id: 'archived_courses_section_header'}])
                  .concat(archivedMemberships)
              );
            }
          }

          return memberships;
        },
      },
      {
        membershipsStore: observable,
        isLoaded: observable,
        shouldRenderStub: computed,
        isFetching: computed,
        data: computed,
      },
    ),
  ).current;

  useEffect(() => {
    runInAction(() => {
      localStore.membershipsStore = createCollectionStore({
        method: 'client.memberships.list',
        params: {
          limit: LIMIT,
          sort: [['created', -1]],
          query: [
            ['program_id', '==', seeProgramLocalStore!.programId],
            ['status', '!=', 'proposed' as Membership['status']],
          ],
          expand: {
            membership: ['course_id', 'edition_id'],
          },
        },
      });

      emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

      const timerId = setTimeout(() => {
        fetchData();
      }, 500);

      return () => {
        emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
        clearTimeout(timerId);

        if (localStore.membershipsStore) {
          localStore.membershipsStore.clear();
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (msg.event.type.includes('membership')) {
      fetchData(true);
    }
  };

  const fetchData = async (silent = false) => {
    runInAction(() => {
      localStore.isLoaded = false;
    });

    if (localStore.membershipsStore) {
      try {
        await localStore.membershipsStore!.fetch(
          {
            limit: Math.max(localStore.membershipsStore!.items.length, LIMIT),
          },
          {silent},
        );
      } catch (error) {
        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
            },
          ],
        });

        setError(error);
      }
    }

    runInAction(() => {
      localStore.isLoaded = true;
    });
  };

  const _onCoursePress = (membership: MembershipT) => {
    const program = seeProgramLocalStore!.program;

    history.push(
      // `${window.location.pathname}/program?sqid=${membership.course_id}&pid=${
      //   program!._id
      // }`,
      `${window.location.pathname}/${membership.course_id}?sqid=${
        membership.course_id
      }&pid=${program!._id}`,
    );
  };

  return (
    <Observer>
      {() => (
        <div className={`SquadsTabClient ${styles.SquadsTabClient}`}>
          {localStore.shouldRenderStub ? (
            <NoResults text={labelProgramClientCoursesTabNoResultsLbl()} />
          ) : !localStore.isLoaded ? (
            <Loader />
          ) : (
            <div>
              {localStore.data.map((item, num) => {
                if (item._id === 'archived_courses_section_header') {
                  return (
                    <div className={styles.archivedCourses} key={num}>
                      <div className={styles.text}>
                        {labelProgramClientCoursesTabArchivedCourses()}
                      </div>
                    </div>
                  );
                }

                return (
                  <ProgramClientCoursesListItem
                    membership={item}
                    key={item._id}
                    onPress={_onCoursePress}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(SquadsTabClient);
