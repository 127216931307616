import type {FC} from 'react';
import React, {memo} from 'react';

import {MainContainerWithMenu} from '@src/layouts';
import Profile from '@src/modules/Profile/Profile';

interface Props {}

const ProfilePage: FC<Props> = () => {
  return (
    <MainContainerWithMenu offsetMenuContainerClass="minHeight" isWithNewCrumbs>
      <div className="ProfilePage minHeightGrow">
        <div className="greyArea allWidth">
          <Profile />
        </div>
      </div>
    </MainContainerWithMenu>
  );
};

export default memo(ProfilePage);
