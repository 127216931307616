import React from 'react';
import {observer} from 'mobx-react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import {datetimeObjectToTimestamp} from '@yourcoach/shared/api';
import {useMobxStore} from '@yourcoach/shared/hooks';
import {CourseInfoStore} from '@yourcoach/shared/stores/single-event';
import {Text} from '@yourcoach/shared/uikit/Text';
import type {Nullable} from '@yourcoach/shared/utils';
import {ConditionalFlagManager, isDefined} from '@yourcoach/shared/utils';

import CalendarPopupMob from '../../../../../assets/CalendarPopupMob.svg';
import TaskClockInfo from '../../../../../assets/TaskClockInfo.svg';
import TimeCirclePopupMob from '../../../../../assets/TimeCirclePopupMob.svg';
import {Subtitle} from '../../components/Subtitle';

import {InfoBadge} from './components/InfoBadge';
import {Layout} from './components/Layout';
import {DescriptionSkeleton, InfoBadgeSkeleton} from './components/skeleton';

const StyledText = styled(Text)`
  font-size: 16px;
  font-family: 'GT Walsheim Pro', sans-serif;
`;

const StyledInfoOutterWrapper = styled('div')`
  display: flex;

  @media (min-width: 320px) and (max-width: 799px) {
    flex-direction: column;
  }
`;

const StyledInfoInnerWrapper = styled('div')`
  display: flex;
  align-items: center;

  @media (min-width: 800px) {
    margin-right: 32px;
  }
`;

const StyledIconContainer = styled('div')`
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const StyledTextContainer = styled('div')`
  font-family: 'GT Walsheim Pro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.41px;
`;

export const CourseInfoContainer: React.FC<{
  courseId?: Nullable<string>;
  eventId?: string;
}> = observer(({courseId, eventId}) => {
  const store = useMobxStore(() => new CourseInfoStore({courseId, eventId}));

  const isLoading = useMobxStore(() => {
    const manager = new ConditionalFlagManager(true);

    manager.trueWhen(
      () => store.initialLoad.isLoading || store.busy.getReason('fetch'),
    );

    return manager;
  });

  React.useEffect(() => {
    store.setIds({eventId, courseId});
  }, [store, courseId, eventId]);

  const renderHeader = () => (
    <Layout.Header.Container>
      <Layout.Header.TitleBox>
        <Subtitle>Information</Subtitle>
      </Layout.Header.TitleBox>

      {isLoading.value ? (
        <DescriptionSkeleton />
      ) : (
        <StyledText>{store.event.description}</StyledText>
      )}
    </Layout.Header.Container>
  );

  const renderDueDate = () => {
    if (isDefined(store.event?.toJS)) {
      return store.event?.toJS._id.includes('conference') ? (
        <StyledInfoOutterWrapper>
          <StyledInfoInnerWrapper>
            <StyledIconContainer>
              <CalendarPopupMob />
            </StyledIconContainer>
            <StyledTextContainer>
              {dayjs(
                datetimeObjectToTimestamp(store.event?.toJS?.start_date),
              ).format('ddd D, MMM')}
            </StyledTextContainer>
          </StyledInfoInnerWrapper>
          <StyledInfoInnerWrapper>
            <StyledIconContainer>
              <TimeCirclePopupMob />
            </StyledIconContainer>
            <StyledTextContainer>{`${dayjs(
              datetimeObjectToTimestamp(store.event?.toJS?.start_date),
            ).format('hh:mm A')} - ${dayjs(
              datetimeObjectToTimestamp(store.event?.toJS?.end_date),
            ).format('hh:mm A')}`}</StyledTextContainer>
          </StyledInfoInnerWrapper>
        </StyledInfoOutterWrapper>
      ) : (
        <InfoBadge
          icon={TaskClockInfo}
          label="Due date"
          value={dayjs(
            datetimeObjectToTimestamp(store.event?.toJS?.start_date),
          ).format('ddd D, MMM hh:mm a')}
        />
      );
    }
  };

  return (
    <Layout.Container>
      {renderHeader()}
      <Layout.Body>
        {isLoading.value ? (
          <React.Fragment>
            <InfoBadgeSkeleton />
          </React.Fragment>
        ) : (
          <React.Fragment>{renderDueDate()}</React.Fragment>
        )}
      </Layout.Body>
    </Layout.Container>
  );
});
