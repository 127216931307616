import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {
  labelNoDescription,
  labelSectionDescription,
  labelSectionName,
  labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters,
} from '@src/common/i18n/i18nQuestionnaire';
import {SwitchEditInput} from '@src/components/CustomForm';

import questionnaireAppStore from '../../../../context/qAppStore';
import styles from '../../../styles.module.css';
import AddQuestion from '../AddQuestion/AddQuestion';

interface Props {}

const UpdateSectionQ: FC<Props> = () => {
  let openDescription: (() => void) | null = null;

  const handleUpdateTitle = (newVal: string) => {
    questionnaireAppStore?.updateCurrentSectionTitle(newVal);
  };

  const handleUpdateDescription = (newVal: string) => {
    questionnaireAppStore?.updateCurrentSectionDescription(newVal);
  };

  const handleGetOpenFn = (fn: () => void) => {
    openDescription = fn;
  };

  const handleClickNoDescription = () => {
    if (openDescription) {
      openDescription();
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`UpdateSectionQ ${styles.UpdateSectionQ}`}>
          {questionnaireAppStore?.activeSectionId && (
            <div className={styles.header}>
              <SwitchEditInput
                inputVal={questionnaireAppStore.currentSection?.title}
                label={labelSectionName()}
                customClassLabel="QStandardLabel"
                customClassCont={'QStandardContainer'}
                test={/^.{1,255}$/}
                testError={labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters()}
                customClassInput="QStandardInput"
                onUpdateVal={handleUpdateTitle}
              >
                <h3>{questionnaireAppStore.currentSection?.title}</h3>
              </SwitchEditInput>
              <SwitchEditInput
                getOpenFn={handleGetOpenFn}
                inputVal={questionnaireAppStore.currentSection?.description}
                label={labelSectionDescription()}
                customClassLabel="QStandardLabel"
                customClassCont={'QStandardContainer'}
                customClassInput="QStandardInput"
                onUpdateVal={handleUpdateDescription}
              >
                <div className={styles.description}>
                  {questionnaireAppStore.currentSection?.description ? (
                    questionnaireAppStore.currentSection?.description
                  ) : (
                    <div onClick={handleClickNoDescription}>
                      {labelNoDescription()}
                    </div>
                  )}
                </div>
              </SwitchEditInput>
              <AddQuestion />
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(UpdateSectionQ);
