import React from 'react';

import styled from 'styled-components';

import {Avatar} from '@yourcoach/shared/uikit/Avatar';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {containerWeb} from './styles';
import type {Props, StyledProps} from './types';

const Container = styled(View)<StyledProps>`
  ${containerWeb}
`;

const UserBox: React.FC<WithDomStyleProp<Props>> = ({
  userName,
  userAvatarUrl,
  isSelected,
  taskStatusSlot,
  ...rest
}) => {
  return (
    <Container isSelected={isSelected} {...rest}>
      <View style={{gap: 16, alignItems: 'center'}}>
        <Avatar avatarUrl={userAvatarUrl} name={userName} size={'icon.s'} />
        <Text weight="bold">{userName}</Text>
      </View>
      {taskStatusSlot}
    </Container>
  );
};

export {UserBox};
