import React, {useCallback, useContext, useState} from 'react';

import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.location';

interface LocationEditorProps {
  onChange?: (_: any) => void;
}

const LocationEditor = ({onChange}: LocationEditorProps) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const [location, setLocation] = useState(
    currentUserStore.user!.location || '',
  );

  const onInputChange = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      setLocation(event.currentTarget.value);
      onChange && onChange(event.currentTarget.value);
    },
    [onChange],
  );

  return (
    <TextField
      placeholder={t([I18N_SCOPE, 'placeholder'])}
      value={location}
      onChange={onInputChange}
    />
  );
};

const LocationStep = props => {
  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      description={t([I18N_SCOPE, 'description'])}
      step={2}
      fieldName="location"
      {...props}
    >
      <LocationEditor />
    </EditorStep>
  );
};

export default LocationStep;
