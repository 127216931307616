import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import {runInAction} from 'mobx';

import {createCollectionStore} from '@yourcoach/shared/api';
import {userExpand} from '@yourcoach/shared/api/user';
import {isEmail} from '@yourcoach/shared/utils/validation/isEmail';

import {
  labelInviteButton,
  labelInviteByNameTabAddAllBtn,
} from '@src/common/i18n/i18nInvites';
import {CustomButton} from '@src/components/CustomForm';

import InvitesContext from '../context/InvitesContext';
import type {ILocalStoreInvite} from '../context/InvitesLocalStore';
import InviteByEmail from '../InviteByEmail/InviteByEmail';
import InviteByName from '../InviteByName/InviteByName';
import SendsElements from '../SendsElements/SendsElements';

import styles from './../styles.module.css';

interface Props {}

const InvitesBody: FC<Props> = () => {
  const storeInvites: ILocalStoreInvite = useContext(InvitesContext)!;

  useEffect(() => {
    storeInvites.setIsLoaded(false);

    storeInvites.setUsersStore(
      createCollectionStore({
        method: 'coach.followers.search',
        params: {
          expand: {
            follower: [['client_id', {name: 'User not found'}, userExpand]],
          },
        },
      }),
    );

    storeInvites.setInitialUsersStore(
      createCollectionStore({
        method: 'coach.followers.list',
        params: {
          sort: [['created', -1]],
          expand: {
            follower: [['client_id', {name: 'User not found'}, userExpand]],
          },
        },
      }),
    );

    const timerId = setTimeout(() => {
      storeInvites.fetchInitialUsersStore();
    }, 500);

    return () => {
      storeInvites.clear();
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSendInvite = () => {
    let user_ids: string[] = [];
    let emails: string[] = [];

    const message: string = storeInvites.message ? storeInvites.message : '';

    if (storeInvites.toItems && storeInvites.toItems.length) {
      user_ids = storeInvites.toItems.map(item => item.client_id);
    }

    if (storeInvites.toItemEmail) {
      if (storeInvites.toItemEmail.length) {
        emails = storeInvites.toItemEmail;
      }

      if (isEmail(storeInvites.queryEmail)) {
        emails.push(storeInvites.queryEmail);
      }
    }

    if (emails.length || user_ids.length) {
      storeInvites.setInvite({emails, user_ids, message});
      storeInvites.setToItems([]);
      storeInvites.setToItemEmail([]);
    }
  };

  const handleClickAddAll = () => {
    storeInvites.initialUsersStore!.items.forEach(item => {
      runInAction(() => {
        storeInvites.toItems.push({
          ...item,
          fromSearch: false,
        });
      });
    });

    storeInvites.initialUsersStore!.clear();
    storeInvites.query = '';
  };

  return (
    <Observer>
      {() => (
        <div className={`InvitesBody ${styles.tabsContainer}`}>
          <SendsElements />
          {storeInvites.currentTab === 'byName' ? (
            <InviteByName />
          ) : (
            <InviteByEmail />
          )}
          <div className={styles.buttonContainer}>
            <div className={styles.addButContainer}>
              {storeInvites.currentTab === 'byName' &&
              storeInvites.initialUsersStore &&
              storeInvites.initialUsersStore!.items.slice().length > 0 ? (
                <CustomButton
                  type="button"
                  classButton={`QuestionnaireHeaderBut ${styles.addBut}`}
                  onClick={handleClickAddAll}
                >
                  <span>{labelInviteByNameTabAddAllBtn()}</span>
                </CustomButton>
              ) : null}
            </div>
            <CustomButton
              type="button"
              classButton={`blueButt ${styles.buttonSend}`}
              disabled={
                storeInvites.toItemEmail.length <= 0 &&
                storeInvites.toItems.length <= 0
              }
              onClick={handleClickSendInvite}
            >
              <span>{labelInviteButton()}</span>
            </CustomButton>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(InvitesBody);
