import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {runInAction} from 'mobx';

import {
  labelInviteByEmailTab,
  labelInviteByNameTab,
} from '@src/common/i18n/i18nInvites';

import InvitesContext from '../context/InvitesContext';
import type {ILocalStoreInvite, TTabs} from '../context/InvitesLocalStore';

import styles from './../styles.module.css';

interface Route {
  type: TTabs;
  name: string;
}

interface Props {}

const InvitesHeader: FC<Props> = () => {
  const storeInvites: ILocalStoreInvite = useContext(InvitesContext)!;
  const TABS: Route[] = [
    {
      type: 'byName',
      name: labelInviteByNameTab(),
    },
    {
      type: 'byEmail',
      name: labelInviteByEmailTab(),
    },
  ];

  const handleOnClickSelectTab = (selectTab: TTabs) => {
    return () => {
      runInAction(() => {
        storeInvites.currentTab = selectTab;
      });
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`InvitesHeader ${styles.tabsHeader}`}>
          {TABS.map(({type, name}) => (
            <a
              className={`customTopMenu ${styles.menuItem} ${
                storeInvites.currentTab === type
                  ? `selected ${styles.selected}`
                  : ''
              }`}
              key={type}
              onClick={handleOnClickSelectTab(type)}
            >
              {name}
            </a>
          ))}
        </div>
      )}
    </Observer>
  );
};

export default memo(InvitesHeader);
