import type {FC, FormEvent, MouseEvent} from 'react';
import React, {memo} from 'react';

interface Props {
  onSubmit?: ((event: FormEvent<HTMLButtonElement>) => void) | undefined;
  onClick?:
    | ((event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void)
    | undefined;
  type: 'button' | 'submit' | 'reset' | undefined;
  children?: any;
  disabled?: boolean;
  classContainer?: string;
  classButton?: string;
  background?: string;
}

const CustomButton: FC<Props> = (props: Props) => {
  const {
    type,
    disabled = false,
    classContainer = '',
    classButton = '',
    children = '',
    onSubmit,
    onClick,
    background,
  } = props;

  return (
    <div className={`customButtonContainer ${classContainer}`}>
      <button
        type={type}
        className={`customButton ${classButton}`}
        disabled={disabled}
        onSubmit={onSubmit}
        onClick={onClick}
        style={background ? {background: background} : undefined}
      >
        {children}
      </button>
    </div>
  );
};

export default memo(CustomButton);
