import type {FC} from 'react';
import React from 'react';

import IconIconSharedSVG from '@src/assets/img/shared.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconIconShared: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = '',
  viewBox = '0 0 20 20',
}) => {
  return (
    <IconIconSharedSVG
      width={height}
      height={width}
      className={`IconCalendarFill ${className}`}
      viewBox={viewBox}
    />
  );
};

export default IconIconShared;
