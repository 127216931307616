import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import {useHistory, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Course as ICourse} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition as IEdition} from '@yourcoach/shared/api/program';
import {userExpand} from '@yourcoach/shared/api/user';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {labelAreYouSure} from '@src/common/i18n/i18nCourse';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {ExpandedClient} from '@src/models/courses';
import {expand as courseExpand} from '@src/models/courses';

import {emitter} from '../../../../widget/src/utils';
import SeeProgramContext from '../context/SeeProgramContext';
import type {
  ISeeProgramLocalStore,
  ProgramT,
} from '../context/useSeeProgramLocalStore';

import styles from './../styles.module.scss';
import ProgramCoursesListItem from './ProgramCoursesListItem/ProgramCoursesListItem';

const I18N_SCOPE = 'ProgramCoursesTab';

const ACTIVE_COURSES_LIMIT = 200;
const LIMIT = 20;

export type Course = ICourse & {
  edition: IEdition;
  programLastEdition?: IEdition & {
    contract?: IFile | null;
  };
  program: Omit<ProgramT, 'editions'>;
  client: ExpandedClient;
};

interface ILocalStore {
  activeCoursesStore: CollectionStore<Course> | null;
  setActiveCoursesStore(activeCoursesStore: CollectionStore<Course>): void;
  archivedCoursesStore: CollectionStore<Course> | null;
  setArchivedCoursesStore(archivedCoursesStore: CollectionStore<Course>): void;
  isFetching: boolean;
  shouldRenderStub: boolean;
  data: Course[];
  fetchData(silent?: boolean): Promise<void>;
  isPaginating: boolean;
  setIsPaginating(isPaginating: boolean): void;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
}

interface Props {}

const SquadsTab: FC<Props> = () => {
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);
  const history = useHistory();
  const location: ReturnType<typeof useLocation> & {state?: {from?: string}} =
    useLocation();
  const {
    stores: {programStore, editionStore, courseStore, currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user || null;

  const localStore: ILocalStore = useRef(
    observable(
      {
        isPaginating: false,
        setIsPaginating(isPaginating: boolean) {
          this.isPaginating = isPaginating;
        },
        isLoading: false,
        setIsLoading(isLoading: boolean) {
          this.isLoading = isLoading;
        },
        async fetchData(silent = false) {
          if (!silent) {
            this.setIsLoading(false);
          }

          await this.membershipsStore.fetch(
            {
              limit: Math.max(this.membershipsStore.items.length, LIMIT),
            },
            {silent},
          );
          this.setIsLoading(true);
        },
        activeCoursesStore: null,
        setActiveCoursesStore(activeCoursesStore: CollectionStore<Course>) {
          this.activeCoursesStore = activeCoursesStore;
        },
        archivedCoursesStore: null,
        setArchivedCoursesStore(archivedCoursesStore: CollectionStore<Course>) {
          this.archivedCoursesStore = archivedCoursesStore;
        },
        get data() {
          const program = seeProgramLocalStore!.program!;

          if (
            !this.activeCoursesStore ||
            !this.archivedCoursesStore ||
            (!this.activeCoursesStore.isLoaded &&
              !this.archivedCoursesStore.isLoaded)
          ) {
            return [];
          }

          const programLastEdition = (
            program.editions && program.editions.length
              ? program.editions[program.editions.length - 1]
              : undefined
          ) as Course['programLastEdition'];

          const modifyCourse = (course: Course) => ({
            ...course,
            program: {
              ...program,
              editions: undefined,
            },
            programLastEdition,
          });

          const archivedCourses = this.archivedCoursesStore.items
            .slice()
            .map(modifyCourse);
          const activeCourses = this.activeCoursesStore.items
            .slice()
            .map(modifyCourse);

          if (archivedCourses.length) {
            return (
              activeCourses
                // @ts-ignore
                .concat([{_id: 'archived_courses_section_header'}])
                .concat(archivedCourses)
            );
          }

          return activeCourses;
        },
        get isFetching() {
          if (this.activeCoursesStore && this.archivedCoursesStore) {
            return (
              !this.activeCoursesStore.isLoaded ||
              this.activeCoursesStore.isFetching ||
              !this.archivedCoursesStore.isLoaded ||
              this.archivedCoursesStore.isFetching
            );
          } else {
            return false;
          }
        },
        get shouldRenderStub() {
          if (this.activeCoursesStore && this.archivedCoursesStore) {
            return (
              this.activeCoursesStore.isLoaded &&
              !this.activeCoursesStore.hasItems &&
              this.archivedCoursesStore.isLoaded &&
              !this.archivedCoursesStore.hasItems
            );
          } else {
            return false;
          }
        },
      },
      {
        activeCoursesStore: observable,
        setActiveCoursesStore: action,
        archivedCoursesStore: observable,
        setArchivedCoursesStore: action,
        isFetching: computed,
        shouldRenderStub: computed,
        data: computed,
        isPaginating: observable,
        setIsPaginating: action,
        isLoading: observable,
        setIsLoading: action,
        fetchData: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setIsLoading(true);

    const params: ApiRpcRequestParams = {
      limit: LIMIT,
      sort: [['start_date', -1]],
      query: [
        ['program_id', '==', seeProgramLocalStore!.program!._id],
        ['status', '==', 'archived'],
      ],
      expand: {
        course: [
          'edition_id',
          ['client_id', {name: 'Client not found'}, userExpand],
        ],
      },
    };

    localStore.setActiveCoursesStore(
      createCollectionStore({
        method: 'coach.courses.list',
        params: {
          ...params,
          limit: ACTIVE_COURSES_LIMIT,
          query: [
            ['program_id', '==', seeProgramLocalStore!.program!._id],
            ['status', '==', 'ongoing'],
          ],
        },
        withCount: true,
      }),
    );

    localStore.setArchivedCoursesStore(
      createCollectionStore({
        method: 'coach.courses.list',
        params,
      }),
    );

    const disposeProgramStoreUpdate = reaction(
      () => programStore.updating,
      updating => {
        if (updating.success) {
          fetchData(true);
        }
      },
    );
    const disposeEditionStoreCreating = reaction(
      () => editionStore.creating,
      creating => {
        if (creating.success) {
          fetchData(true);
        }
      },
    );
    const disposeEditionStoreUpdate = reaction(
      () => editionStore.updating,
      updating => {
        if (updating.success) {
          fetchData(true);
        }
      },
    );
    const disposeEditionStoreDeleting = reaction(
      () => editionStore.deleting,
      deleting => {
        if (deleting.success) {
          fetchData(true);
        }
      },
    );
    const disposeCourseStoreCreating = reaction(
      () => courseStore.creating,
      creating => {
        if (creating.success) {
          fetchData(true);
        }
      },
    );
    const disposeCourseStoreUpdating = reaction(
      () => courseStore.updating,
      updating => {
        if (updating.success) {
          fetchData(true);
        }
      },
    );
    const disposeCourseStoreDeleting = reaction(
      () => courseStore.deleting,
      deleting => {
        if (deleting.success) {
          fetchData(true);
        }
      },
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    const timerId = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      localStore.activeCoursesStore?.clear();
      localStore.archivedCoursesStore?.clear();
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      disposeProgramStoreUpdate();
      disposeEditionStoreCreating();
      disposeEditionStoreUpdate();
      disposeEditionStoreDeleting();
      disposeCourseStoreCreating();
      disposeCourseStoreUpdating();
      disposeCourseStoreDeleting();
      clearTimeout(timerId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = message => {
    if (
      [
        'course_started',
        'course_created',
        'course_pending',
        'edition_created',
        'edition_signed',
        'membership_created',
        'membership_accepted',
      ].includes(message.event.type)
    ) {
      fetchData(true);
    }
  };

  const fetchData = async (silent = false) => {
    localStore.setIsLoading(true);

    if (localStore.activeCoursesStore && localStore.archivedCoursesStore) {
      if (!silent) {
        localStore.activeCoursesStore!.isFetching = true;
        localStore.archivedCoursesStore!.isFetching = true;
      }

      await localStore.activeCoursesStore!.fetch(
        {
          limit: Math.max(
            localStore.activeCoursesStore!.items.length,
            ACTIVE_COURSES_LIMIT,
          ),
        },
        {silent},
      );

      await localStore.archivedCoursesStore!.fetch(
        {
          limit: Math.max(localStore.archivedCoursesStore!.items.length, LIMIT),
        },
        {silent},
      );
    }

    localStore.setIsLoading(false);
  };

  const _onCoursePress = (course: Course) => {
    const program = seeProgramLocalStore!.program!;

    // history.push(
    //   // `${window.location.pathname}/program?sqid=${course._id}&pid=${program._id}`,
    //   `${window.location.pathname}/${course._id}?sqid=${course._id}&pid=${program._id}`,
    // );
    history.push({
      pathname: `${location.pathname}/${course._id}`,
      search: `?sqid=${course._id}&pid=${program._id}`,
      state: {from: location.state?.from},
    });
  };

  const _onStartCourseButtonPress = async (course: Course) => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const startedCourse = await courseStore.start(course, {
                expand: courseExpand,
              });

              courseStore!.localUpdate(course, startedCourse);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onStopCourseButtonPress = async (course: Course) => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const archivedCourse = await courseStore.stop(course, {
                expand: courseExpand,
              });

              courseStore!.localUpdate(course, archivedCourse);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onDeleteCourseButtonPress = async (course: Course) => {
    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await courseStore.delete(course);
            } catch (error) {
              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: error.message,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onUpgradeCourseButtonPress = async (course: Course) => {
    try {
      const editionIds = course.program.edition_ids;

      const movedCourse = await courseStore!.move(course, {
        edition_id: editionIds[editionIds.length - 1],
        expand: courseExpand,
      });

      await courseStore!.localUpdate(course, movedCourse);
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onEndReached = async () => {
    if (localStore.isPaginating) {
      return;
    }

    localStore.setIsPaginating(true);

    if (
      localStore.archivedCoursesStore &&
      localStore.archivedCoursesStore.hasMore
    ) {
      try {
        await localStore.archivedCoursesStore.fetch(
          {
            offset: localStore.archivedCoursesStore.items.length,
          },
          {
            silent: true,
          },
        );
      } catch (error) {}
    }

    if (
      localStore.activeCoursesStore &&
      localStore.activeCoursesStore.hasMore
    ) {
      try {
        await localStore.activeCoursesStore.fetch(
          {
            offset: localStore.activeCoursesStore.items.length,
          },
          {
            silent: true,
          },
        );
      } catch (error) {}
    }

    localStore.setIsPaginating(false);
  };

  const handleOnChangeInView = async (inView: boolean) => {
    if (inView && !localStore.isLoading) {
      _onEndReached();
    }
  };

  const program = seeProgramLocalStore!.program!;
  const lastEdition = seeProgramLocalStore!.lastEdition!;
  const isMainCoach = user && program.user_id === user._id;

  if (localStore.isFetching) {
    return <Observer>{() => <Loader />}</Observer>;
  }

  const handleClickProlong = async (courseValue: Course) => {
    try {
      await apiRequest({
        // @ts-ignore will be fixed in 1.5
        method: 'coach.courses.extend',
        params: {
          _id: courseValue?._id,

        },
      });

      await localStore.fetchData();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.shouldRenderStub ? (
            <div>
              <NoResults
                text={t([
                  I18N_SCOPE,
                  'no_results_label',
                  isMainCoach
                    ? program.status
                    : program.status === 'draft'
                    ? 'active'
                    : program.status,
                  lastEdition && lastEdition.group_size === 1
                    ? 'individual'
                    : 'group',
                ])}
              />
            </div>
          ) : localStore.isLoading ? (
            <Loader />
          ) : (
            <div className={`SquadsTab ${styles.SquadsTab}`}>
              {localStore.data.map((item, num) => {
                if (item._id === 'archived_courses_section_header') {
                  return (
                    <div className={styles.archivedCourses} key={num}>
                      <div className={styles.text}>Archived</div>
                    </div>
                  );
                }

                return (
                  <ProgramCoursesListItem
                    course={item}
                    key={item._id}
                    onPress={_onCoursePress}
                    onStartButtonPress={_onStartCourseButtonPress}
                    onStopButtonPress={_onStopCourseButtonPress}
                    onDeleteButtonPress={_onDeleteCourseButtonPress}
                    onUpgradeButtonPress={_onUpgradeCourseButtonPress}
                    onClickExtend={handleClickProlong}
                  />
                );
              })}
              {/* @ts-ignore */}
              <InView as="div" onChange={handleOnChangeInView}>
                <div />
              </InView>
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(SquadsTab);
