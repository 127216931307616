import React from 'react';

import {Text} from '@yourcoach/shared/uikit/Text';

import {MainMenuLayout, TopMenuLayout} from '../../../layouts';
import NotificationTopContainer from '../../../layouts/NotificationTopContainer/NotificationTopContainer';

import {Layout} from './components/Layout';

const PageContainer: React.FC<{
  pageTitle?: string;
  titleSlot?: React.ReactElement;
  headerSlot?: React.ReactElement;
  isWithBreadcrumbs?: boolean;
  isWithFromBreadcrumb?: boolean;
}> = ({
  children,
  headerSlot,
  isWithBreadcrumbs,
  isWithFromBreadcrumb,
  pageTitle,
  titleSlot,
}) => {
  return (
    <React.Fragment>
      <NotificationTopContainer />

      <Layout.Container>
        <MainMenuLayout />
        <Layout.Body>
          <TopMenuLayout
            isWithFrom={isWithFromBreadcrumb}
            isWithCrumbs={isWithBreadcrumbs}
            isWithNewCrumbs
          />
          <Layout.Header>
            {titleSlot ? (
              titleSlot
            ) : (
              <Text variant={'heading'} as={'h1'}>
                {pageTitle}
              </Text>
            )}
            {headerSlot}
          </Layout.Header>
          <Layout.Content>{children}</Layout.Content>
        </Layout.Body>
      </Layout.Container>
    </React.Fragment>
  );
};

export {PageContainer};
