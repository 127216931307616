import type {FC, MouseEvent} from 'react';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {DragDropContainer} from 'react-drag-drop-container';
import {Img as Image} from 'react-image';

import {useLongPress} from 'use-long-press';

import {
  getFileExtension,
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';

import {labelDelete, labelMove, labelOpen} from '@src/common/i18n/i18nCommon';
import DropdownMenu from '@src/components/DropdownMenu/DropdownMenu';
import {IconLeftArrow, IconTrash} from '@src/components/icons';
import i18n from '@src/i18n';
import type {TFileWithLink} from '@src/models/library';

import {getFileIconByExt} from '../../libraryFunctions';
import styles from '../styles.module.scss';

interface Props {
  file: TFileWithLink;
  className?: string;
  dragFile?: () => void;
  fileDelete?: () => void;
  fileMove?: () => void;
}

const UserFile: FC<Props> = ({
  className,
  file,
  fileDelete,
  fileMove,
  dragFile = () => {},
}) => {
  const [isDrag, setDrag] = useState(false);
  const url = useMemo(() => getFileSrc(file).url, [file]);
  const {size, urlrepr} = getFileSrc(file);
  const fileName = getFileName(file);
  const ext = getFileExtension(fileName).toLowerCase();
  const Icon = getFileIcon(file);
  const IconByExt = getFileIconByExt(ext);

  const isImage = file.categories.includes('image') ? true : false;
  const preview = useMemo(() => {
    if (file.categories.includes('website')) {
      return getFileSrc(file, 'image').url;
    } else if (file.categories.includes('image')) {
      return url;
    } else {
      return null;
    }
  }, [file, url]);

  let openDropDown: () => void;
  let closeDropDown: () => void;

  //dragContainerEl.current!.addEventListener("click", highlightThis, true)

  const handleGetOpenFunc = (fn: () => void) => {
    openDropDown = fn;
  };

  const handleGetCloseFunc = (fn: () => void) => {
    closeDropDown = fn;
  };

  const handleOnRClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();
    callback();
  };

  const callback = useCallback(() => {
    if (fileDelete || fileMove) {
      if (!isDrag) {
        if (openDropDown) {
          openDropDown();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bindLongPress = useLongPress(callback);

  const handleDelete = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (fileDelete) {
      fileDelete();
    }
  };

  const handleMove = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (fileMove) {
      fileMove();
    }
  };

  const handleOnDrag = useCallback(() => {
    if (closeDropDown) {
      closeDropDown();
    }

    setDrag(true);
    dragFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragEnd = useCallback(() => {
    //setDrag(false);
  }, []);

  const handleClickContainer = event => {
    //e.stopImmediatePropagation();
    if (isDrag) {
      event.stopPropagation();
      setDrag(false);
    }
  };

  const handleOpen = (_url: string) => {
    return () => {
      const win = window.open(_url, '_blank');

      win!.focus();
    };
  };

  return (
    <div
      className={`UserFile ${className}`}
      onContextMenu={handleOnRClick}
      onClick={handleClickContainer}
      {...bindLongPress}
    >
      <DragDropContainer
        targetKey="userFolder"
        onDrag={handleOnDrag}
        onDragEnd={handleDragEnd}
      >
        {isImage && preview && (
          <div className={styles.imagePreviewContainer}>
            <Image
              src={getFileSrc(file, 250).url || ''}
              className={styles.imagePreview}
              loader={<Icon />}
              unloader={<Icon />}
            />
          </div>
        )}
        {!isImage && !urlrepr && (
          <div className={styles.documentIcon}>
            <IconByExt />
          </div>
        )}
        {!isImage && urlrepr && (
          <div className={styles.documentIconUrl}>
            <Icon />
          </div>
        )}
      </DragDropContainer>
      <div className={styles.documentName}>
        <span>{fileName}</span>
      </div>
      {size && file.type !== 'url' ? (
        <div className={styles.documentSize}>{i18n.toHumanSize(size)}</div>
      ) : null}

      {(fileDelete || fileMove) && (
        <DropdownMenu
          getOpenFunc={handleGetOpenFunc}
          getCloseFunc={handleGetCloseFunc}
          className={styles.DropdownMenu}
        >
          <div
            className={styles.linkDropdownMenu}
            onClick={handleOpen(
              file.type !== 'url' ? file.src.original.url! : file.repr,
            )}
          >
            <div>
              <span>{labelOpen()}</span>
            </div>
          </div>
          {fileDelete && (
            <div className={styles.linkDropdownMenu} onClick={handleDelete}>
              <div>
                <span>{labelDelete()}</span>
              </div>
              <IconTrash customClass={styles.iconDropMenu} />
            </div>
          )}
          {fileMove && (
            <div className={styles.linkDropdownMenu} onClick={handleMove}>
              <div>
                <span>{labelMove()}</span>
              </div>
              <IconLeftArrow customClass={styles.iconDropMenu} />
            </div>
          )}
        </DropdownMenu>
      )}
    </div>
  );
};

export default memo(UserFile);
