import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  flex-direction: column;
  gap: 20px;
`;
const HeaderContainer = styled(View)`
  flex-direction: column;
  gap: 16px;
`;

const HeaderTitleBox = styled(View)`
  justify-content: space-between;
  font-family: 'GT Walsheim Pro', sans-serif;
`;

const Body = styled(View)`
  justify-content: space-between;
`;

const Layout = {
  Container,
  Header: {
    Container: HeaderContainer,
    TitleBox: HeaderTitleBox,
  },
  Body,
};

export {Layout};
