import type {IRegLogFormStore, ITimerStore} from './../hooks';
import {
  useLocalStoreTimer as localStoreTimer,
  useRegLogFormStore as regLogFormStore,
} from './../hooks';

export interface ILoginFormStore {
  timerStore?: ITimerStore;
  regLogFormStore?: IRegLogFormStore;
}

const stores: ILoginFormStore = {
  timerStore: localStoreTimer(),
  regLogFormStore: regLogFormStore(),
};

export default stores;
