import {accountStore} from '@yourcoach/shared/api/account';
import {authStore} from '@yourcoach/shared/api/auth';
import {categoryStore} from '@yourcoach/shared/api/category';
import {certificateStore} from '@yourcoach/shared/api/certificate';
import {channelStore} from '@yourcoach/shared/api/channel';
import {postStore} from '@yourcoach/shared/api/channel/post';
import {complaintStore} from '@yourcoach/shared/api/complaint';
import {conferenceStore} from '@yourcoach/shared/api/conference';
import {couponStore} from '@yourcoach/shared/api/coupon';
import {courseStore} from '@yourcoach/shared/api/course';
import {eventStore} from '@yourcoach/shared/api/event';
import {followerStore} from '@yourcoach/shared/api/follower';
import {goalStore} from '@yourcoach/shared/api/goal';
import {inviteStore} from '@yourcoach/shared/api/invite';
import {materialStore} from '@yourcoach/shared/api/material';
import {fileStore} from '@yourcoach/shared/api/media/file';
import {linkStore} from '@yourcoach/shared/api/media/link';
import {membershipStore} from '@yourcoach/shared/api/membership';
import {paymentStore} from '@yourcoach/shared/api/payment';
import {cardStore} from '@yourcoach/shared/api/payment/card';
import {refundStore} from '@yourcoach/shared/api/payment/refund';
import {programStore} from '@yourcoach/shared/api/program';
import {editionStore} from '@yourcoach/shared/api/program/edition';
import {questionnaireStore} from '@yourcoach/shared/api/questionnaire';
import {sessionNoteStore} from '@yourcoach/shared/api/sessionNote';
import {taskStore} from '@yourcoach/shared/api/task';
import {currentUserStore} from '@yourcoach/shared/api/user';

const stores = {
  authStore,
  currentUserStore,
  cardStore,
  accountStore,

  programStore,
  editionStore,
  followerStore,
  courseStore,
  membershipStore,
  channelStore,
  postStore,

  sessionNoteStore,

  questionnaireStore,

  categoryStore,

  complaintStore,

  conferenceStore,
  taskStore,
  materialStore,

  fileStore,
  linkStore,

  paymentStore,
  refundStore,

  inviteStore,

  eventStore,

  certificateStore,

  goalStore,

  couponStore,
};

export default stores;
