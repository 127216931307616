import type {FC, PropsWithChildren} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Observer} from 'mobx-react';

import type {Subgoal} from '@yourcoach/shared/api/goal';
import {
  getSubgoalColor,
  getSubgoalDurationString,
  getSubgoalIcon,
} from '@yourcoach/shared/api/goal';
import FlagIcon from '@yourcoach/shared/assets/icons/flag.svg';
import TrashIcon from '@yourcoach/shared/assets/icons/trash.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {IconCheck, IconPrev} from '../../../components/icons';

import styles from './../styles.module.css';

interface Props<T extends Subgoal = Subgoal> {
  subgoal: T;
  selectGoalId?: string | null;
  onPress?: (course: T) => void;
  onMorePress?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    subgoal: T,
  ) => void;
  isRemovable?: boolean;
  onDeleteButtonPress?: (subgoal: T) => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  showNextIcon?: boolean;
  isVariantHeader?: boolean;
}

const SubgoalsListItem: FC<Props> = <T extends Subgoal = Subgoal>({
  subgoal,
  onPress,
  onMorePress,
  isRemovable,
  selectGoalId,
  onDeleteButtonPress,
  isSelectable,
  isSelected,
  showNextIcon = false,
  isVariantHeader = false,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const onPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onPress && onPress(subgoal);
    },
    [subgoal, onPress],
  );

  const onMorePressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onMorePress && onMorePress(event, subgoal);
    },
    [onMorePress, subgoal],
  );

  const onDeleteButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onDeleteButtonPress && onDeleteButtonPress(subgoal);
    },
    [subgoal, onDeleteButtonPress],
  );

  const durationString = useMemo(
    () => getSubgoalDurationString(subgoal),
    [subgoal],
  );

  const Icon = useMemo(() => getSubgoalIcon(subgoal.icon), [subgoal]);

  const color = useMemo(
    () => getSubgoalColor(subgoal.color, themes.light),
    [subgoal.color],
  );

  const isSelectSubgoal = useMemo(
    () => selectGoalId && selectGoalId === subgoal._id,
    [subgoal._id, selectGoalId],
  );

  if (isVariantHeader) {
    return (
      <Observer>
        {() => (
          <div
            className={`SubgoalsListItem ${
              styles.SubgoalsListItemVariantHeader
            } ${
              (onPress && typeof isSelectable === 'undefined') || isSelectable
                ? styles.onClickable
                : ''
            } ${isSelectSubgoal ? styles.thisSelect : ''}`}
            onClick={onPressCb}
          >
            <div className={styles.contentWrapper}>
              <div
                className={styles.iconContainer}
                style={{backgroundColor: color}}
              >
                <Icon fill={themes.light.color.icon2} />
              </div>

              <div
                className={`${styles.title} ${
                  subgoal.reached ? styles.lineThrough : ''
                }`}
              >
                <div className={styles.headerContainer}>
                  <div className={styles.durationContainer}>
                    {durationString ? (
                      <>
                        <div className={styles.flagIconContainer}>
                          <FlagIcon fill={color} />
                        </div>
                        <div
                          className={styles.duration}
                          style={{
                            color: color,
                          }}
                        >
                          {durationString}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {subgoal.title}
              </div>
            </div>
          </div>
        )}
      </Observer>
    );
  } else {
    return (
      <Observer>
        {() => (
          <div
            className={`SubgoalsListItem ${styles.SubgoalsListItem} ${
              (onPress && typeof isSelectable === 'undefined') || isSelectable
                ? styles.onClickable
                : ''
            } ${isSelectSubgoal ? styles.thisSelect : ''}`}
            style={
              isSelectSubgoal
                ? {
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${color}`,
                  }
                : {
                    backgroundColor: color,
                    border: `1px solid ${color}`,
                  }
            }
            onClick={onPressCb}
          >
            <div className={styles.contentWrapper}>
              {isSelectable ? (
                <div
                  className={`${styles.checkBox} ${
                    isSelected ? styles.selected : ''
                  }`}
                >
                  {isSelected ? <IconCheck viewBox="0 2 30 30" /> : null}
                </div>
              ) : null}
              <div className={styles.iconContainer}>
                <Icon
                  fill={isSelectSubgoal ? color : themes.light.color.icon2}
                />
              </div>

              <div className={styles.contentContainerSubgoal}>
                <div
                  className={`${styles.title} ${
                    subgoal.reached ? styles.lineThrough : ''
                  }`}
                  style={
                    isSelectSubgoal
                      ? {
                          color: color,
                        }
                      : {
                          color: '#FFFFFF',
                        }
                  }
                >
                  {subgoal.title}
                </div>
                {durationString ? (
                  <div className={styles.durationContainer}>
                    <div className={styles.flagIconContainer}>
                      <FlagIcon
                        fill={
                          isSelectSubgoal ? color : themes.light.color.icon2
                        }
                      />
                    </div>
                    <div
                      className={styles.duration}
                      style={
                        isSelectSubgoal
                          ? {
                              color: color,
                            }
                          : {
                              color: '#FFFFFF',
                            }
                      }
                    >
                      {durationString}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {isRemovable ? (
              <div
                onClick={onDeleteButtonPressCb}
                className={styles.deleteIconContainer}
              >
                <TrashIcon fill={themes.light.color.icon2} />
              </div>
            ) : null}
            {onMorePress ? (
              <div className={styles.moreBut} onClick={onMorePressCb}>
                ⋮
              </div>
            ) : null}
            {showNextIcon ? (
              <div className={styles.nextIconContainer}>
                <IconPrev
                  customClass={styles.CustomSelectArrow}
                  viewBox="5 6 20 20"
                />
              </div>
            ) : null}
          </div>
        )}
      </Observer>
    );
  }
};

export default memo(SubgoalsListItem);
