import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import isEqual from 'react-fast-compare';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import {action, runInAction} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileName, getFileSrc} from '@yourcoach/shared/api/media/file';
import type {
  Edition,
  PaymentPlan,
  ProgramPaymentOption,
  ProgramType,
} from '@yourcoach/shared/api/program';
import {
  CLIENT_CONFERENCE_DURATIONS,
  createPaymentPlan,
  DEFAULT_CLIENT_CONFERENCES_COUNT,
  DEFAULT_DURATION,
  DEFAULT_GROUP_SIZE,
  DefaultPaymentPlan,
  getMonthlyPaymentPerUnitString,
  MAX_GROUP_SIZE,
  MAX_PRICE,
  MIN_GROUP_SIZE,
  MIN_PRICE,
  monthlyPaymentIsAvailable,
  parseDuration,
  parsePaymentPlan,
  ProgramPaymentOptionEnum,
  ProgramPaymentTypeEnum,
  ProgramTypeEnum,
} from '@yourcoach/shared/api/program';
import ArrowNextIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';
import CloneProgramIcon from '@yourcoach/shared/assets/icons/clone-program.svg';
import DocumentIcon from '@yourcoach/shared/assets/icons/secondary/Paper.svg';
import delay from '@yourcoach/shared/utils/delay';
import {logger} from '@yourcoach/shared/utils/logger';
import {
  Currency,
  extractCommission,
  getPriceString,
} from '@yourcoach/shared/utils/money';
import {validateFields} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Image from '@src/components/Image';
import Switch from '@src/components/Switch';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import i18n, {t} from '@src/i18n';
import type {ILink, TListDataItem} from '@src/models/library';
import AddFileModal from '@src/modules/library/AddFileModal';
import DocumentViewerModal from '@src/modules/library/DocumentViewerModal';
import {connectStripe} from '@src/pages/ConnectStripe';

import {emitter} from '../../../widget/src/utils';

import AddFile from './legacy/AddFile';
import type {ProgramT} from '.';
import styles from './Details.module.css';
import DurationModal from './DurationModal';
import PaymentPlanModal from './PaymentPlanModal';
import PrivateDataRequestModal from './PrivateDataRequestModal';
import commonStyles from './styles.module.css';
import type {TabProps, TabRef} from './useTabs';

const I18N_SCOPE = 'shared.CRUProgramDetails';

const ProgramGroupIcon = require('@yourcoach/shared/assets/program-group@3x.png');
const ProgramIndividualIcon = require('@yourcoach/shared/assets/program-individual@3x.png');

const CONTRACT_CONTEXT_MENU_ID = 'contract-context-menu';

export const PROGRAM_CONTRACT_UPLOADING_KEY = 'program_contract';

export const PROGRAM_TYPE_CHANGE_EVENT = 'program_type_change';

const CRUProgramDetails = React.forwardRef<TabRef, TabProps>(
  (
    {program, userIsOwner, shouldCloneProgram, cloneProgram, isOfferedProgram},
    ref,
  ) => {
    const {
      stores: {currentUserStore, fileStore},
    } = useContext(AppContext);

    useImperativeHandle(ref, () => ({
      getData,
    }));

    const [type, setType] = useState(ProgramTypeEnum.GROUP);
    const [duration, setDuration] = useState(DEFAULT_DURATION);
    const [sentInvitesCount, setSentInvitesCount] = useState<number | null>(
      null,
    );
    const [currency, setCurrency] = useState<Currency>(
      Currency[DefaultPaymentPlan.currency],
    );
    const [paymentOption, setPaymentOption] = useState(
      ProgramPaymentOptionEnum.FREE,
    );
    const [paymentPlan, setPaymentPlan] = useState(DefaultPaymentPlan);
    const [paymentProcessing, setPaymentProcessing] =
      useState<PaymentPlan['processing']>('internal');
    const [contract, setContract] = useState<IFile | File>();
    const [privateDataRequest, setPrivateDataRequest] = useState<
      Edition['private_data_request']
    >([]);

    const [clientConferenceDuration, setClientConferenceDuration] = useState(
      CLIENT_CONFERENCE_DURATIONS[0],
    );

    const groupSize = useRef(DEFAULT_GROUP_SIZE);
    const price = useRef(0);
    const fetchInvitesCountDelay = useRef(delay(0));

    const parsedDuration = useMemo(() => parseDuration(duration), [duration]);
    const isDurationEditable = useMemo(() => {
      if (!program || !program._id) {
        return true;
      }

      if (
        sentInvitesCount === 0 &&
        program.counters &&
        program.counters.memberships
      ) {
        return Object.keys(program.counters.memberships).every(
          key => program.counters.memberships[key] === 0,
        );
      }

      return false;
    }, [program, sentInvitesCount]);
    const parsedPaymentPlan = useMemo(
      () => parsePaymentPlan(paymentPlan),
      [paymentPlan],
    );
    const commission = useMemo(
      () =>
        extractCommission(
          parsedPaymentPlan.monthlyPayment || parsedPaymentPlan.total,
        ),
      [parsedPaymentPlan.monthlyPayment, parsedPaymentPlan.total],
    );

    const monthlyPaymentIsAvailableMemo = useMemo(
      () => monthlyPaymentIsAvailable(parsedPaymentPlan.total, duration),
      [duration, parsedPaymentPlan.total],
    );

    const internalProcessingIsAvailable = useMemo(() => {
      if (!program) {
        return true;
      } else if (program.status === 'active') {
        return program.coach_ids.length > 1
          ? program.expanded_coaches.every(coach => coach.is_provider)
          : true;
      }

      return true;
    }, [program]);

    const [durationModalIsOpen, showDurationModal, hideDurationModal] =
      useIsVisible();

    const [paymentPlanModalIsOpen, showPaymentPlanModal, hidePaymentPlanModal] =
      useIsVisible();

    const [
      privateDataRequestModalIsOpen,
      showPrivateDataRequestModal,
      hidePrivateDataRequestModal,
    ] = useIsVisible();

    const [contractModalIsOpen, showContractModal, hideContractModal] =
      useIsVisible();

    const [addContractModalIsOpen, showAddContractModal, hideAddContractModal] =
      useIsVisible();

    const [
      addContractFromLibraryModalIsOpen,
      showAddContractFromLibraryModal,
      hideAddContractFromLibraryModal,
    ] = useIsVisible();

    const fields = useRef({
      groupSize: createHtmlInputField('group_size', {
        validationRule: {
          type: 'string',
        },
        customValidate: action(() => {
          if (fields.groupSize.validationRule!.optional) {
            fields.groupSize.error = null;

            return true;
          }

          const field = fields.groupSize;

          let isValid = true;

          field.error = null;

          if (
            (groupSize.current > 0 && groupSize.current < MIN_GROUP_SIZE) ||
            !groupSize.current
          ) {
            field.error = t([I18N_SCOPE, 'group_size_min_error_message'], {
              value: MIN_GROUP_SIZE,
            });
            isValid = false;
          } else if (groupSize.current > MAX_GROUP_SIZE) {
            field.error = t([I18N_SCOPE, 'group_size_max_error_message'], {
              value: MAX_GROUP_SIZE,
            });
            isValid = false;
          }

          field.isValid = isValid;

          return isValid;
        }),
        defaultValue: `${DEFAULT_GROUP_SIZE}`,
      }),
      price: createHtmlInputField('price', {
        validationRule: {
          type: 'string',
          optional: true,
        },
        customValidate: action(() => {
          if (fields.price.validationRule!.optional) {
            fields.price.error = null;

            return true;
          }

          const field = fields.price;

          let isValid = true;

          field.error = null;

          if (
            (price.current > 0 && price.current < MIN_PRICE) ||
            !price.current
          ) {
            field.error = t([I18N_SCOPE, 'min_price_error_message'], {
              value: getPriceString({
                price: MIN_PRICE,
                currency: currency,
              }),
            });
            isValid = false;
          } else if (price.current > MAX_PRICE) {
            field.error = t([I18N_SCOPE, 'max_price_error_message'], {
              value: getPriceString({
                price: MAX_PRICE,
                currency: currency,
              }),
            });
            isValid = false;
          }

          field.isValid = isValid;

          return isValid;
        }),
        defaultValue: '0',
      }),
      clientConferencesCount: createHtmlInputField('clientConferencesCount', {
        validationRule: {
          type: 'string',
          optional: true,
        },
        defaultValue: `${DEFAULT_CLIENT_CONFERENCES_COUNT}`,
      }),
    }).current;

    useEffect(() => {
      if (program) {
        const lastEdition = program.editions[program.editions.length - 1];

        if (lastEdition) {
          groupSize.current = lastEdition.group_size || groupSize.current;

          runInAction(() => {
            fields.groupSize.value = `${
              lastEdition.group_size || groupSize.current
            }`;
            fields.groupSize.validationRule!.optional = groupSize.current === 1;
          });

          setType(
            groupSize.current > 1
              ? ProgramTypeEnum.GROUP
              : ProgramTypeEnum.INDIVIDUAL,
          );

          setDuration(lastEdition.duration || duration);

          setPaymentOption(
            lastEdition.payment_plan && lastEdition.payment_plan.total
              ? ProgramPaymentOptionEnum.CUSTOM
              : paymentOption,
          );

          setPaymentProcessing(
            lastEdition.payment_plan
              ? lastEdition.payment_plan.processing
              : paymentProcessing,
          );

          if (!internalProcessingIsAvailable) {
            onPaymentProcessingChange(true);
          }

          setPaymentPlan(lastEdition.payment_plan || paymentPlan);

          price.current = lastEdition.payment_plan
            ? lastEdition.payment_plan.total
            : price.current;

          setCurrency(
            lastEdition.payment_plan
              ? Currency[lastEdition.payment_plan.currency]
              : currency,
          );

          const priceString = `${price.current}`;

          runInAction(() => {
            fields.price.value =
              price.current > 0
                ? `${priceString.slice(0, -2)}.${priceString.slice(-2)}`
                : '';
          });

          runInAction(() => {
            fields.clientConferencesCount.validationRule!.optional =
              groupSize.current > 1;

            fields.clientConferencesCount.value =
              lastEdition.client_conferences.count.toString();

            setClientConferenceDuration(
              lastEdition.client_conferences.duration ||
                clientConferenceDuration,
            );
          });

          if (lastEdition.contract) {
            if (
              (lastEdition.contract as IFile)._id ||
              lastEdition.contract instanceof File
            ) {
              setContract(lastEdition.contract || contract);
            }
          }

          setPrivateDataRequest(lastEdition.private_data_request);
        }
      }

      fetchInvitesCount();

      return () => {
        fetchInvitesCountDelay.current.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupStripe = useCallback(
      () =>
        new Promise<boolean>(async resolve => {
          const popupI18nScope = 'setup_stripe_popup';

          getCustomConfirmAlert({
            title: t([I18N_SCOPE, popupI18nScope, 'header']),
            message: t([I18N_SCOPE, popupI18nScope, 'description']),
            buttons: [
              {
                label: t([I18N_SCOPE, popupI18nScope, 'cancel_button']),
                onClick: () => resolve(false),
              },
              {
                label: t([I18N_SCOPE, popupI18nScope, 'signup_button']),
                type: 'confirm',
                onClick: () => {
                  resolve(connectStripe());
                },
              },
            ],
          });
        }),
      [],
    );

    const getData = useCallback(
      async (direction?: 'back' | 'next') => {
        if (validateFields(fields)) {
          if (
            direction === 'next' &&
            paymentPlan.total &&
            paymentPlan.processing === 'internal' &&
            !currentUserStore.user!.is_provider
          ) {
            const stripeIsSetup = await setupStripe();

            if (!stripeIsSetup) {
              return;
            }
          }

          let lastEdition: ProgramT['editions'][0] | null =
            program && program.editions.length
              ? program.editions[program.editions.length - 1]
              : null;

          lastEdition = {
            ...lastEdition,
            group_size:
              type.id === ProgramTypeEnum.INDIVIDUAL.id ? 1 : groupSize.current,
            duration: duration,
            payment_plan: paymentPlan.total ? paymentPlan : null,
            contract: contract || null,
            private_data_request: privateDataRequest.slice(),
            client_conferences: {
              count:
                type.id === ProgramTypeEnum.INDIVIDUAL.id
                  ? parseInt(fields.clientConferencesCount.value, 10)
                  : 0,
              duration:
                type.id === ProgramTypeEnum.INDIVIDUAL.id
                  ? clientConferenceDuration
                  : 0,
              min_pause: 0,
            },
          } as ProgramT['editions'][0];

          let editions: ProgramT['editions'];

          if (program && program.editions.length) {
            program.editions.splice(
              program.editions.length - 1,
              1,
              lastEdition,
            );

            editions = program.editions;
          } else {
            editions = [lastEdition];
          }

          return {
            editions,
          };
        }
      },
      [
        clientConferenceDuration,
        contract,
        currentUserStore.user,
        duration,
        fields,
        paymentPlan,
        privateDataRequest,
        program,
        setupStripe,
        type.id,
      ],
    );

    const onProgramTypeChange = useCallback(
      (programType: ProgramType) => {
        runInAction(() => {
          fields.groupSize.validationRule!.optional =
            programType.id === ProgramTypeEnum.INDIVIDUAL.id;
          fields.clientConferencesCount.validationRule!.optional =
            programType.id === ProgramTypeEnum.GROUP.id;
        });

        if (
          programType.id === ProgramTypeEnum.GROUP.id &&
          groupSize.current === 1
        ) {
          groupSize.current = DEFAULT_GROUP_SIZE;
          fields.groupSize.setValue(`${DEFAULT_GROUP_SIZE}`);
        }

        setType(programType);

        emitter.emit(PROGRAM_TYPE_CHANGE_EVENT, programType);
      },
      [fields.clientConferencesCount.validationRule, fields.groupSize],
    );

    const onGroupSizeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        let gropeSizeText = e.currentTarget.value.replace(/\D/g, '');

        if (gropeSizeText === '0') {
          gropeSizeText = '';
        }

        groupSize.current = gropeSizeText ? parseInt(gropeSizeText, 10) : 0;

        fields.groupSize.setValue(gropeSizeText);

        fields.groupSize.validate();
      },
      [fields.groupSize],
    );

    const onDurationButtonClick = useCallback(() => {
      showDurationModal();
    }, [showDurationModal]);

    const onDurationSet = useCallback(
      (newDuration: number) => {
        setDuration(newDuration);

        setPaymentPlan(
          createPaymentPlan({
            price: parsedPaymentPlan.total,
            duration: newDuration,
            processing: paymentProcessing,
            paymentType:
              parsedPaymentPlan.paymentType.id ===
                ProgramPaymentTypeEnum.MONTHLY.id &&
              monthlyPaymentIsAvailable(parsedPaymentPlan.total, newDuration)
                ? ProgramPaymentTypeEnum.MONTHLY
                : ProgramPaymentTypeEnum.ONE_TIME,
            currency: parsedPaymentPlan.parsedCurrency,
          }),
        );

        hideDurationModal();
      },
      [
        hideDurationModal,
        parsedPaymentPlan.parsedCurrency,
        parsedPaymentPlan.paymentType.id,
        parsedPaymentPlan.total,
        paymentProcessing,
      ],
    );

    const onPaymentOptionChange = useCallback(
      (option: ProgramPaymentOption) => {
        fields.price.validationRule!.optional =
          option.id !== ProgramPaymentOptionEnum.CUSTOM.id;

        setPaymentPlan(
          option.id !== ProgramPaymentOptionEnum.CUSTOM.id
            ? DefaultPaymentPlan
            : createPaymentPlan({
                price: price.current,
                duration,
                processing: paymentProcessing,
                paymentType:
                  parsedPaymentPlan.paymentType.id ===
                    ProgramPaymentTypeEnum.MONTHLY.id &&
                  monthlyPaymentIsAvailable(parsedPaymentPlan.total, duration)
                    ? ProgramPaymentTypeEnum.MONTHLY
                    : ProgramPaymentTypeEnum.ONE_TIME,
                currency: parsedPaymentPlan.parsedCurrency,
              }),
        );

        setPaymentOption(option);
      },
      [
        fields.price.validationRule,
        parsedPaymentPlan.total,
        parsedPaymentPlan.paymentType.id,
        parsedPaymentPlan.parsedCurrency,
        duration,
        paymentProcessing,
      ],
    );

    const onPriceTextChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        let priceText = e.currentTarget.value
          .replace(/[^\d., d]/g, '')
          .replace(currency.sign, '');

        if (priceText === '.' || priceText === ',') {
          priceText = '';
        } else if (priceText === '0' || priceText === '') {
          priceText = '';
        } else if (priceText.includes('.') || priceText.includes(',')) {
          let point = '.';

          if (priceText.includes(',')) {
            point = ',';
          }

          const tmp = priceText.split(point);

          priceText = `${tmp[0]}${point}${tmp[1].slice(0, 2)}`;
        }

        price.current = priceText
          ? (parseFloat(priceText.replace(',', '.')) * 1000) / 10
          : 0;

        setPaymentPlan(
          createPaymentPlan({
            price: price.current,
            duration,
            processing: paymentProcessing,
            paymentType: parsedPaymentPlan.paymentType,
            currency,
          }),
        );

        fields.price.setValue(priceText);

        fields.price.validate();
      },
      [
        currency,
        duration,
        fields.price,
        parsedPaymentPlan.paymentType,
        paymentProcessing,
      ],
    );

    const onClientConferencesCountTextChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const countText = e.currentTarget.value.replace(/\D/g, '');

        fields.clientConferencesCount.setValue(countText);

        fields.clientConferencesCount.validate();
      },
      [fields.clientConferencesCount],
    );

    const onClientConferenceDurationChange = useCallback((value: number) => {
      setClientConferenceDuration(value);
    }, []);

    const onPaymentPlanButtonClick = useCallback(() => {
      showPaymentPlanModal();
    }, [showPaymentPlanModal]);

    const onPaymentPlanChange = useCallback(
      (newPaymentPlan: PaymentPlan) => {
        setPaymentPlan(newPaymentPlan);

        hidePaymentPlanModal();
      },
      [hidePaymentPlanModal],
    );

    const onPaymentProcessingChange = useCallback(
      (value: boolean) => {
        setPaymentProcessing(value ? 'external' : 'internal');

        setPaymentPlan(
          createPaymentPlan({
            price: price.current,
            duration,
            processing: value ? 'external' : 'internal',
            paymentType: parsedPaymentPlan.paymentType,
            currency,
          }),
        );
      },
      [currency, duration, parsedPaymentPlan.paymentType, price],
    );

    const onContractButtonClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        contextMenu.show({
          id: CONTRACT_CONTEXT_MENU_ID,
          event: e,
        });
      },
      [],
    );

    const onViewContractButtonClick = useCallback(() => {
      showContractModal();
    }, [showContractModal]);

    const onChooseContractButtonClick = useCallback(() => {
      showAddContractModal();
    }, [showAddContractModal]);

    const onChooseContractFromLibraryButtonClick = useCallback(() => {
      showAddContractFromLibraryModal();
    }, [showAddContractFromLibraryModal]);

    const onRemoveContractButtonClick = useCallback(() => {
      setContract(undefined);
    }, []);

    const ContractContextMenu = useCallback(
      () => (
        <Menu
          id={CONTRACT_CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          {contract ? (
            <Item
              onClick={onRemoveContractButtonClick}
              className="contextMenuItem"
            >
              {t([I18N_SCOPE, 'remove_contract_button'])}
            </Item>
          ) : null}
          {contract && (contract as IFile)._id ? (
            <Item
              onClick={onViewContractButtonClick}
              className="contextMenuItem"
            >
              {t([I18N_SCOPE, 'view_contract_button'])}
            </Item>
          ) : null}
          <Item
            onClick={onChooseContractButtonClick}
            className="contextMenuItem"
          >
            {t([I18N_SCOPE, 'choose_contract_button'])}
          </Item>
          <Item
            onClick={onChooseContractFromLibraryButtonClick}
            className="contextMenuItem"
          >
            {t([I18N_SCOPE, 'choose_contract_from_yc_library_button'])}
          </Item>
        </Menu>
      ),
      [
        contract,
        onChooseContractButtonClick,
        onChooseContractFromLibraryButtonClick,
        onRemoveContractButtonClick,
        onViewContractButtonClick,
      ],
    );

    const onContractSelect = useCallback(
      (files: File[]) => {
        const [file] = files;

        hideAddContractModal();

        setContract(file);

        fileStore.upload(file, {
          uploadingKey: PROGRAM_CONTRACT_UPLOADING_KEY,
        });
      },
      [fileStore, hideAddContractModal],
    );

    // TODO: Library refactoring
    const onChooseContractFromLibrary = useCallback(
      (listSelect: TListDataItem[]) => {
        hideAddContractFromLibraryModal();

        if (
          (listSelect[0] as ILink).resource?.categories.includes('document') &&
          (listSelect[0] as ILink).resource
        ) {
          setContract((listSelect[0] as ILink).resource!);
        }
      },
      [hideAddContractFromLibraryModal],
    );

    const onPrivateDataRequestButtonClick = useCallback(() => {
      showPrivateDataRequestModal();
    }, [showPrivateDataRequestModal]);

    const onPrivateDataRequestChange = useCallback(
      (newPrivateDataRequest: Edition['private_data_request']) => {
        setPrivateDataRequest(newPrivateDataRequest);

        hidePrivateDataRequestModal();
      },
      [hidePrivateDataRequestModal],
    );

    const fetchInvitesCount = useCallback(async () => {
      if (!program || !program._id) {
        return;
      }

      try {
        const result = await apiRequest({
          method: 'coach.invites.count',
          params: {
            query: [['program_id', '==', program._id]],
          },
        });

        setSentInvitesCount(result._count);
      } catch (error) {
        logger.error(error);

        fetchInvitesCountDelay.current = delay(2000);

        await fetchInvitesCountDelay.current;

        fetchInvitesCount();
      }
    }, [program]);

    return (
      <div>
        <div className={commonStyles.dataRow}>
          <h3 className={commonStyles.header}>
            {t([I18N_SCOPE, 'type_label'])}
            {':'}
            {shouldCloneProgram || !userIsOwner ? (
              <span className={commonStyles.sub}>{type.toString()}</span>
            ) : null}
          </h3>
          {!shouldCloneProgram && userIsOwner ? (
            <div className={styles.typeOptionsContainer}>
              {[ProgramTypeEnum.GROUP, ProgramTypeEnum.INDIVIDUAL].map(
                programType => {
                  const isSelected = programType.id === type.id;

                  return (
                    <div
                      key={programType.id}
                      className={classNames(
                        styles.typeOption,
                        isSelected && styles.selected,
                      )}
                      onClick={() => onProgramTypeChange(programType)}
                    >
                      <Image
                        src={
                          programType.id === ProgramTypeEnum.GROUP.id
                            ? ProgramGroupIcon
                            : ProgramIndividualIcon
                        }
                        className={styles.typeOptionIcon}
                      />
                      <p className={styles.typeOptionText}>
                        {isSelected ? (
                          <CheckIcon className={styles.typeOptionCheckIcon} />
                        ) : null}
                        {programType.toString()}
                      </p>
                    </div>
                  );
                },
              )}
            </div>
          ) : (
            <>
              <div className={commonStyles.message}>
                {t([I18N_SCOPE, 'edit_type_label'])}
              </div>
              <Button
                className={commonStyles.cloneButton}
                onClick={cloneProgram}
              >
                <CloneProgramIcon />
                {t([I18N_SCOPE, 'clone_program_button'])}
              </Button>
            </>
          )}
        </div>
        {type.id === ProgramTypeEnum.GROUP.id ? (
          <div className={commonStyles.dataRow}>
            <h3 className={commonStyles.header}>
              {t([I18N_SCOPE, 'group_size_label'])}
              {':'}
              {!userIsOwner ? (
                <span className={commonStyles.sub}>
                  {fields.groupSize.value}
                </span>
              ) : null}
            </h3>
            {!userIsOwner ? (
              <div className={commonStyles.message}>
                {t([I18N_SCOPE, 'edit_group_size_label'])}
              </div>
            ) : (
              <Observer>
                {() => (
                  <TextField
                    ref={fields.groupSize.ref}
                    disabled={!userIsOwner}
                    value={fields.groupSize.value}
                    placeholder={t(
                      [I18N_SCOPE, 'group_size_text_input_placeholder'],
                      {
                        min_group_size: MIN_GROUP_SIZE,
                        max_group_size: MAX_GROUP_SIZE,
                      },
                    )}
                    onChange={onGroupSizeChange}
                    error={fields.groupSize.error || ''}
                  />
                )}
              </Observer>
            )}
          </div>
        ) : null}
        <div className={commonStyles.dataRow}>
          <h3 className={commonStyles.header}>
            {t([I18N_SCOPE, 'duration_label'])}
            {':'}
            {!isDurationEditable || !userIsOwner ? (
              <span className={commonStyles.sub}>
                {parsedDuration.toString()}
              </span>
            ) : null}
          </h3>
          {isDurationEditable && userIsOwner ? (
            <>
              <Button
                className={styles.dataRowButton}
                onClick={onDurationButtonClick}
              >
                {parsedDuration.toString()}
                <ArrowNextIcon />
              </Button>
              <DurationModal
                isOpen={durationModalIsOpen}
                onAfterClose={hideDurationModal}
                duration={duration}
                onSet={onDurationSet}
              />
            </>
          ) : (
            <>
              <div className={commonStyles.message}>
                {t([I18N_SCOPE, 'edit_duration_label'])}
              </div>
              <Button
                className={commonStyles.cloneButton}
                onClick={cloneProgram}
              >
                <CloneProgramIcon />
                {t([I18N_SCOPE, 'clone_program_button'])}
              </Button>
            </>
          )}
        </div>
        {!isOfferedProgram ? (
          <>
            <div
              className={classNames(
                commonStyles.dataRow,
                !userIsOwner && styles.customDataRow,
              )}
            >
              <h3 className={commonStyles.header}>
                {t([I18N_SCOPE, 'payment_label'])}
                {':'}
                {!userIsOwner ? (
                  <span className={commonStyles.sub}>
                    {parsedPaymentPlan.toString()}
                  </span>
                ) : null}
              </h3>
              {!userIsOwner ? (
                <div className={commonStyles.message}>
                  {t([I18N_SCOPE, 'edit_payment_label'])}
                </div>
              ) : (
                <div className={styles.paymentOptionsContainer}>
                  {[
                    ProgramPaymentOptionEnum.FREE,
                    ProgramPaymentOptionEnum.CUSTOM,
                  ].map(option => {
                    const isSelected = option.id === paymentOption.id;

                    return (
                      <div
                        key={option.id}
                        className={classNames(
                          styles.paymentOption,
                          isSelected && styles.selected,
                        )}
                        onClick={() => onPaymentOptionChange(option)}
                      >
                        <p className={styles.paymentOptionText}>
                          {option.toString()}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {paymentOption.id === ProgramPaymentOptionEnum.CUSTOM.id ? (
              <>
                {/* TODO: Enable currency picker when we'll have the support of currencies */}
                {/* <div className={commonStyles.dataRow}>
              <h3 className={commonStyles.header}>
                {t([I18N_SCOPE, 'currency_label'])}
                {':'}
                {!userIsOwner ? (
                  <span className={commonStyles.sub}>
                    {currency.toString()}
                  </span>
                ) : null}
              </h3>
              {userIsOwner ? (
                <>
                  <Button
                    className={styles.dataRowButton}
                    onClick={onCurrencyButtonClick}>
                    {currency.toString()}
                    <ArrowNextIcon />
                  </Button>
                </>
              ) : (
                <>
                  <div className={commonStyles.message}>
                    {t([I18N_SCOPE, 'edit_currency_label'])}
                  </div>
                </>
              )}
            </div> */}
                <div className={commonStyles.dataRow}>
                  {userIsOwner ? (
                    <>
                      <div className={styles.priceTextFieldContainer}>
                        <Observer>
                          {() => (
                            <TextField
                              ref={fields.groupSize.ref}
                              disabled={!userIsOwner}
                              value={
                                !fields.price.value ||
                                fields.price.value === '0'
                                  ? ''
                                  : `${currency.sign}${fields.price.value}`
                              }
                              placeholder={t(
                                [I18N_SCOPE, 'price_text_input_placeholder'],
                                {
                                  min_price: getPriceString({
                                    price: MIN_PRICE,
                                    currency,
                                  }),
                                  max_price: getPriceString({
                                    price: MAX_PRICE,
                                    currency,
                                  }),
                                },
                              )}
                              onChange={onPriceTextChange}
                              error={fields.price.error || ''}
                            />
                          )}
                        </Observer>
                        {price.current > 0 &&
                        !fields.price.error &&
                        monthlyPaymentIsAvailableMemo ? (
                          <>
                            <Button
                              onClick={onPaymentPlanButtonClick}
                              className={styles.paymentPlanButton}
                            >
                              {t([I18N_SCOPE, 'payment_plan_button'])}
                            </Button>
                            <PaymentPlanModal
                              isOpen={paymentPlanModalIsOpen}
                              onAfterClose={hidePaymentPlanModal}
                              duration={duration}
                              paymentPlan={paymentPlan}
                              onSet={onPaymentPlanChange}
                            />
                          </>
                        ) : null}
                      </div>
                      <div className={commonStyles.message}>
                        {price.current > 0 && !fields.price.error
                          ? paymentProcessing === 'internal'
                            ? t(
                                [
                                  I18N_SCOPE,
                                  'price_description_with_price_and_commission_label',
                                ],
                                {
                                  price: parsedPaymentPlan.toString(),
                                  commission: `${getPriceString({
                                    price: commission,
                                  })}${
                                    parsedPaymentPlan.paymentType.id ===
                                    ProgramPaymentTypeEnum.MONTHLY.id
                                      ? getMonthlyPaymentPerUnitString().toLowerCase()
                                      : ''
                                  }`,
                                },
                              )
                            : t(
                                [
                                  I18N_SCOPE,
                                  'price_description_with_price_label',
                                ],
                                {
                                  price: parsedPaymentPlan.toString(),
                                },
                              )
                          : t([I18N_SCOPE, 'price_description_label'])}
                      </div>
                    </>
                  ) : null}
                  <div className={styles.paymentProcessingContainer}>
                    <div className={styles.paymentProcessingContent}>
                      <p className={styles.paymentProcessingTitle}>
                        {t([I18N_SCOPE, 'paid_elsewhere', 'title'])}
                      </p>
                      <p className={styles.paymentProcessingDescription}>
                        {t([I18N_SCOPE, 'paid_elsewhere', 'description'])}
                      </p>
                    </div>
                    <Switch
                      isOn={paymentProcessing === 'external'}
                      onChange={onPaymentProcessingChange}
                      disabled={!userIsOwner || !internalProcessingIsAvailable}
                    />
                  </div>
                  {!internalProcessingIsAvailable ? (
                    <div className={commonStyles.message}>
                      {t([
                        I18N_SCOPE,
                        'edit_payment_processing_isnt_provider_coaches_label',
                      ])}
                    </div>
                  ) : null}
                  {!userIsOwner ? (
                    <>
                      <div className={commonStyles.message}>
                        {t([I18N_SCOPE, 'edit_payment_processing_label'])}
                      </div>
                      <Button
                        className={commonStyles.cloneButton}
                        onClick={cloneProgram}
                      >
                        <CloneProgramIcon />
                        {t([I18N_SCOPE, 'clone_program_button'])}
                      </Button>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {type.id === ProgramTypeEnum.INDIVIDUAL.id ? (
          <>
            <h3 className={commonStyles.header}>
              {t([I18N_SCOPE, 'client_conferences_count_label'])}
              {':'}
              {!userIsOwner || isOfferedProgram ? (
                <span className={commonStyles.sub}>
                  {fields.clientConferencesCount.value}
                </span>
              ) : null}
            </h3>
            {userIsOwner && !isOfferedProgram ? (
              <div className={commonStyles.dataRow}>
                <Observer>
                  {() => (
                    <TextField
                      ref={fields.clientConferencesCount.ref}
                      value={fields.clientConferencesCount.value}
                      maxLength={3}
                      placeholder={t([
                        I18N_SCOPE,
                        'client_conferences_count_text_input_placeholder',
                      ])}
                      onChange={onClientConferencesCountTextChange}
                      error={fields.clientConferencesCount.error || ''}
                    />
                  )}
                </Observer>
              </div>
            ) : !isOfferedProgram ? (
              <div className={commonStyles.message}>
                {t([I18N_SCOPE, 'edit_client_conferences_count_label'])}
              </div>
            ) : null}
            <div className={commonStyles.dataRow}>
              <h3 className={commonStyles.header}>
                {t([I18N_SCOPE, 'client_conference_duration_label'])}
                {':'}
                {!userIsOwner || isOfferedProgram ? (
                  <span className={commonStyles.sub}>
                    {t([I18N_SCOPE, 'client_conference_duration'], {
                      duration: clientConferenceDuration,
                    })}
                  </span>
                ) : null}
              </h3>
              {userIsOwner && !isOfferedProgram ? (
                <div className={styles.clientConferenceDurationContainer}>
                  {CLIENT_CONFERENCE_DURATIONS.map(item => {
                    const isSelected = item === clientConferenceDuration;

                    return (
                      <div
                        key={item}
                        className={classNames(
                          styles.clientConferenceDuration,
                          isSelected && styles.selected,
                        )}
                        onClick={() => onClientConferenceDurationChange(item)}
                      >
                        <p className={styles.clientConferenceDurationText}>
                          {t([I18N_SCOPE, 'client_conference_duration'], {
                            duration: item,
                          })}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : !isOfferedProgram ? (
                <div className={commonStyles.message}>
                  {t([I18N_SCOPE, 'edit_client_conferences_duration_label'])}
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {!isOfferedProgram ? (
          <>
            <div className={commonStyles.dataRow}>
              <h3 className={commonStyles.header}>
                {t([I18N_SCOPE, 'contract_label'])}
                {':'}
              </h3>
              <div
                onClick={
                  userIsOwner
                    ? onContractButtonClick
                    : contract
                    ? onViewContractButtonClick
                    : undefined
                }
              >
                {(!userIsOwner && contract) || userIsOwner ? (
                  <div className={styles.contractContainer}>
                    <div
                      className={classNames(
                        styles.contractPreview,
                        contract && styles.attached,
                      )}
                    >
                      <DocumentIcon />
                    </div>
                    {contract ? (
                      <div>
                        <p className={styles.contractName}>
                          {contract instanceof File
                            ? contract.name
                            : getFileName(contract)}
                        </p>
                        <p className={styles.contractSize}>
                          {i18n.toHumanSize(
                            contract instanceof File
                              ? contract.size
                              : getFileSrc(contract).size,
                          )}
                        </p>
                      </div>
                    ) : (
                      <p className={styles.attachContractText}>
                        {t([I18N_SCOPE, 'attach_contract_button'])}
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
              <ContractContextMenu />
              <AddFileModal
                isOpen={addContractModalIsOpen}
                onAfterClose={hideAddContractModal}
                mode="select"
                fileType="document"
                onSelect={onContractSelect}
              />
              <ModalAnimateWin
                showModal={addContractFromLibraryModalIsOpen}
                closeModalHandler={hideAddContractFromLibraryModal}
                className="greyHeaderContainer List"
                isBody
                classNameBody="whiteBody maxContent"
                header={'Select document'}
                classNameHeader="greyHeader"
                classNameCloseBut="greyHeaderBut"
              >
                <AddFile getListItems={onChooseContractFromLibrary} />
              </ModalAnimateWin>
              {!userIsOwner ? (
                <div className={commonStyles.message}>
                  {t([I18N_SCOPE, 'edit_contract_label'])}
                </div>
              ) : null}
              {contract && (contract as IFile)._id ? (
                <DocumentViewerModal
                  isOpen={contractModalIsOpen}
                  onAfterClose={hideContractModal}
                  title={t([I18N_SCOPE, 'contract_viewer_title'])}
                  document={contract as IFile}
                />
              ) : null}
            </div>
            <div className={commonStyles.dataRow}>
              <h3 className={commonStyles.header}>
                {t([I18N_SCOPE, 'personal_info_label'])}
                {':'}
              </h3>
              <div
                onClick={
                  userIsOwner ? onPrivateDataRequestButtonClick : undefined
                }
                className={classNames(
                  styles.personalInfoContainer,
                  !userIsOwner && styles.disabled,
                )}
              >
                <div className={styles.personalInfoListContainer}>
                  {privateDataRequest.length ? (
                    <div className={styles.personalInfoList}>
                      {privateDataRequest.map(key => (
                        <div key={key} className={styles.personalInfoItem}>
                          {t(`shared.private_profile.${key}`)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.selectPersonalInfo}>
                      {t([I18N_SCOPE, 'personal_info_none_label'])}
                    </div>
                  )}
                </div>
                {userIsOwner ? <ArrowNextIcon /> : null}
              </div>
              <PrivateDataRequestModal
                isOpen={privateDataRequestModalIsOpen}
                onAfterClose={hidePrivateDataRequestModal}
                privateDataRequest={privateDataRequest}
                onSet={onPrivateDataRequestChange}
              />
              {!userIsOwner ? (
                <div className={commonStyles.message}>
                  {t([I18N_SCOPE, 'edit_personal_info_label'])}
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  },
);

export default React.memo(CRUProgramDetails, isEqual);
