import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {action, runInAction} from 'mobx';
import nextFrame from 'next-frame';
import {v4 as uuidv4} from 'uuid';

import type {Edition} from '@yourcoach/shared/api/program';
import type {IScheduleItemRepeat} from '@yourcoach/shared/utils/calendar';
import {ScheduleItemRepeat} from '@yourcoach/shared/utils/calendar';
import {getSecondsFromMidnight} from '@yourcoach/shared/utils/datetime';
import {
  getDescriptionSchema,
  getTitleSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import Loader from '@src/components/Loader/Loader';
import TextArea from '@src/components/TextArea';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import type {ProgramT} from '.';
import styles from './ScheduleEvent.module.css';

type ScheduledConference = Edition['conferences'][0];

type ScheduledTask = Edition['tasks'][0];

type ScheduleItem = ScheduledConference | ScheduledTask;

export const I18N_SCOPE = 'shared.CRUProgramScheduleEvent';

type EventType = 'task' | 'conference';

export interface Props {
  program: ProgramT;
  type: EventType;
  event?: ScheduleItem;
  onSuccess: (events: ScheduleItem[], type: EventType) => void;
}

const CRUProgramScheduleEvent: React.FC<Props> = ({
  program,
  type,
  event,
  onSuccess,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const [repeat, setRepeat] = useState(ScheduleItemRepeat.ONE_TIME);
  const [date, setDate] = useState(new Date());

  const lastEdition = useMemo(
    () => program.editions[program.editions.length - 1],
    [program.editions],
  );

  const duration = useRef(type === 'task' ? 2 * 60 * 60 : 1 * 60 * 60);
  const day = useRef(0);
  const uuid = useRef(uuidv4().replace(/-/g, ''));

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const fields = useRef({
    title: createHtmlInputField('title', {
      validationRule: getTitleSchema(),
    }),
    description: createHtmlInputField<HTMLTextAreaElement>('description', {
      validationRule: getDescriptionSchema(),
    }),
    day: createHtmlInputField('day', {
      validationRule: {
        type: 'string',
      },
      customValidate: action(() => {
        const field = fields.day;

        let isValid = true;

        field.error = null;

        if (!field.value) {
          field.error = t([I18N_SCOPE, 'day_required_error_message']);
          isValid = false;
        } else if (day.current > lastEdition!.duration - 1) {
          field.error = t([I18N_SCOPE, 'day_max_error_message'], {
            value: lastEdition!.duration,
          });
          isValid = false;
        }

        field.isValid = isValid;

        return isValid;
      }),
    }),
  }).current;

  useEffect(() => {
    if (event) {
      runInAction(() => {
        fields.title.value = event.title;
        fields.description.value = event.description;
      });

      day.current = event.day || day.current;
      duration.current = event.duration || duration.current;
      uuid.current = event.uuid || uuid.current;

      setDate(dayjs(date).startOf('day').add(event.time, 'second').toDate());
    }

    runInAction(() => {
      fields.day.value = `${day.current + 1}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRepeatChange = useCallback((newRepeat: IScheduleItemRepeat) => {
    setRepeat(newRepeat);
  }, []);

  const onDayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let dayText = e.currentTarget.value.replace(/\D/g, '');

      if (dayText === '0') {
        dayText = '';
      }

      day.current = dayText ? parseInt(dayText, 10) : 0;

      if (day.current > 0) {
        day.current = day.current - 1;
      }

      fields.day.setValue(dayText);
      fields.day.validate();
    },
    [fields.day],
  );

  const onTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = e.currentTarget.value;

    if (timeString) {
      const [hours, mins] = timeString.split(':');

      setDate(prev =>
        dayjs(prev)
          .startOf('day')
          .add(parseInt(hours, 10) || 0, 'hours')
          .add(parseInt(mins, 10) || 0, 'minutes')
          .toDate(),
      );
    }
  }, []);

  const onSetButtonClick = useCallback(async () => {
    if (validateFields(fields)) {
      showOverlay();

      const {interval} = repeat;

      const events: ScheduleItem[] = [];

      const data: Omit<ScheduleItem, 'speaker_ids'> & {
        speaker_ids?: ScheduledConference['speaker_ids'];
      } = {
        title: fields.title.value,
        description: fields.description.value,
        day: day.current,
        duration: duration.current,
        time: getSecondsFromMidnight(date),
        uuid: uuid.current,
      };

      if (type === 'conference') {
        data.speaker_ids = [currentUserStore.user!._id];
      }

      events.push(data);

      if (interval) {
        let {day: eventDay} = data;

        while (eventDay + interval < lastEdition!.duration) {
          await nextFrame();

          eventDay += interval;

          events.push({
            ...data,
            uuid: uuidv4().replace(/-/g, ''),
            day: eventDay,
          });
        }
      }

      onSuccess(events, type);

      hideOverlay();
    }
  }, [
    currentUserStore.user,
    date,
    fields,
    hideOverlay,
    lastEdition,
    onSuccess,
    repeat,
    showOverlay,
    type,
  ]);

  return (
    <>
      <div
        className={classNames(
          'CRUProgramScheduleEvent',
          styles.Component,
          event && styles.editMode,
        )}
      >
        <div className={classNames(styles.container, styles.titleContainer)}>
          <h3>{`${t([I18N_SCOPE, 'title_label'])}:`}</h3>
          <Observer>
            {() => (
              <TextField
                value={fields.title.value}
                onChange={fields.title.onChange}
                error={fields.title.error || ''}
                placeholder={t([I18N_SCOPE, 'title_text_input_placeholder'])}
              />
            )}
          </Observer>
        </div>
        <div
          className={classNames(
            styles.container,
            styles.repeatOptionsContainer,
          )}
        >
          <h3>{`${t([I18N_SCOPE, 'repeat_label'])}:`}</h3>
          <div className={styles.repeatOptions}>
            {[
              ScheduleItemRepeat.ONE_TIME,
              ScheduleItemRepeat.DAILY,
              ScheduleItemRepeat.WEEKLY,
              ScheduleItemRepeat.MONTHLY,
            ]
              .filter(item => lastEdition!.duration > item.interval)
              .map(item => {
                const isSelected = item.id === repeat.id;

                return (
                  <div
                    onClick={() => onRepeatChange(item)}
                    key={item.id}
                    className={classNames({
                      [styles.repeatOption]: true,
                      [styles.selected]: isSelected,
                    })}
                  >
                    {item.toString()}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'day_label'])}:`}</h3>
          <Observer>
            {() => (
              <TextField
                value={fields.day.value}
                onChange={onDayChange}
                error={fields.day.error || ''}
                placeholder={t([I18N_SCOPE, 'day_text_input_placeholder'], {
                  min_day: 1,
                  max_day: lastEdition!.duration,
                })}
              />
            )}
          </Observer>
        </div>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'time_label'])}:`}</h3>
          <TextField
            type="time"
            onChange={onTimeChange}
            value={dayjs(date).format('HH:mm')}
          />
        </div>
        <div
          className={classNames(styles.container, styles.descriptionContainer)}
        >
          <h3>
            {`${t([I18N_SCOPE, 'description_label'])}:`}
            <span className={styles.sub}>
              {t([I18N_SCOPE, 'optional_label'])}
            </span>
          </h3>
          <Observer>
            {() => (
              <TextArea
                value={fields.description.value}
                onChange={fields.description.onChange}
                error={fields.description.error || ''}
                placeholder={t([
                  I18N_SCOPE,
                  'description_text_input_placeholder',
                ])}
              />
            )}
          </Observer>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          onClick={onSetButtonClick}
          className={classNames({
            [styles.actionButton]: true,
          })}
        >
          {t([I18N_SCOPE, 'footer_button', type, event ? 'update' : 'create'])}
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(CRUProgramScheduleEvent);
