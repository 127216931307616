import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {CollectionStore, Entity} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';

import {setError} from '@src/common/setError';
import NoResults from '@src/components/NoResults/NoResults';
import type {TSystemFolder} from '@src/models/library';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';
import ListFolderContainer from '../ListFolderContainer/ListFolderContainer';
import styles from '../ListFolders/styles.module.css';
import SystemFolder from '../ListFolders/SystemFolder/SystemFolder';

interface Props {
  onSelectCourse: (arg: string) => void;
}

interface ILocalStore {
  membershipsStore: CollectionStore<Entity> | null;
  setMembershipsStore: (newMembershipsStore: CollectionStore<Entity>) => void;
  updateMembershipsStore: () => void;
  listData: TSystemFolder[];
}

const ListFolderMaterials: FC<Props> = ({onSelectCourse}) => {
  const LIMIT = 300;
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        membershipsStore: null,
        setMembershipsStore(newMembershipsStore: CollectionStore<Entity>) {
          this.membershipsStore = newMembershipsStore;
        },
        updateMembershipsStore() {
          libraryStory!.setLoading(true);
          this.membershipsStore
            .fetch({
              limit: Math.max(this.membershipsStore.items.length, LIMIT),
            })
            .then(() => {
              libraryStory!.setLoading(false);
            })
            .catch(error => {
              setError(error);
              libraryStory!.setLoading(false);
            });
        },
        get listData() {
          const retArr: TSystemFolder[] = [];

          if (this.membershipsStore) {
            const data = this.membershipsStore.items.slice();

            data.forEach(item => {
              const name = item.program
                ? item.program.title
                : 'Session not found';

              retArr.push({
                ...item,
                isSystem: true,
                isStarred: true,
                name,
              });
            });
          }

          return retArr;
        },
      },
      {
        membershipsStore: observable,
        setMembershipsStore: action,
        updateMembershipsStore: action,
        listData: computed,
      },
    ),
  ).current;

  useEffect(() => {
    const membershipsStore = createCollectionStore({
      method: 'client.memberships.list',
      params: {
        sort: [['created', -1]],
        query: [
          ['status', 'in', ['active', 'archived']],
          ['course_id', '!=', null],
        ],
        limit: LIMIT,
        expand: {
          membership: ['program_id', 'course_id'],
        },
      },
    });

    localStore.setMembershipsStore(membershipsStore);
    localStore.updateMembershipsStore();

    return () => {
      if (localStore.membershipsStore) {
        localStore.membershipsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <div className="ListFolderMaterials">
          {localStore!.listData.length === 0 && !libraryStory?.loading && (
            <NoResults />
          )}
          <ListFolderContainer>
            {localStore!.listData.map((item: TSystemFolder) => {
              return (
                <SystemFolder
                  key={`${item.program_id!}_${item._id}`}
                  data={item as TSystemFolder}
                  forUser
                  className={styles.SystemFolder}
                  onSelectCourse={onSelectCourse}
                />
              );
            })}
          </ListFolderContainer>
        </div>
      )}
    </Observer>
  );
};

export default memo(ListFolderMaterials);
