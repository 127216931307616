import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {Questionnaire as IQuestionnaire} from '@yourcoach/shared/api/questionnaire';

import {
  labelCreateQuestionnaire,
  labelQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import type {Expanded as QuestionnaireExpanded} from '@src/models/questionnaire';
import {expand as questionnaireExpand} from '@src/models/questionnaire';
import {PATH_TYPE} from '@src/routes/utils';

import QSendUsers from '../QSendUsers/QSendUsers';

import MoreBut from './MoreBut/MoreBut';
import styles from './styles.module.css';

interface ILocalStore {
  loader: boolean;
  setLoader(val: boolean): void;
  questionnairesStore: CollectionStore<
    IQuestionnaire & QuestionnaireExpanded
  > | null;
  storeParams: ApiRpcRequestParams;
  questionnaireList: (IQuestionnaire & QuestionnaireExpanded)[];
  getQuestFetch: () => void;
  showSendModal: boolean;
  setShowSendModal: (newVal: boolean) => void;
  modalQCurrentId: string;
  setModalQCurrentId: (newId: string) => void;
}

interface Props {}

const QuestionnaireToolbox: FC<Props> = () => {
  const LIMIT = 50;
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const localStore = useRef(
    observable<ILocalStore>(
      {
        loader: false,
        setLoader(val: boolean) {
          this.loader = val;
        },
        questionnairesStore: null,
        storeParams: {},
        questionnaireList: [],
        async getQuestFetch() {
          this.setLoader(true);

          try {
            await this.questionnairesStore.fetch(this.storeParams, {
              override: true,
            });

            this.questionnaireList = this.questionnairesStore.items.slice();
          } catch (error) {
            if (error.canceled) {
              return;
            }

            setError(error);
          }

          this.setLoader(false);
        },
        showSendModal: false,
        setShowSendModal(newVal: boolean) {
          this.showSendModal = newVal;
        },
        modalQCurrentId: '',
        setModalQCurrentId(newId: string) {
          this.modalQCurrentId = newId;
        },
      },
      {
        loader: observable,
        storeParams: observable,
        questionnaireList: observable,
        questionnairesStore: observable,
        showSendModal: observable,
        modalQCurrentId: observable,
        getQuestFetch: action,
        setLoader: action,
        setShowSendModal: action,
        setModalQCurrentId: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.questionnairesStore = createCollectionStore<
      IQuestionnaire & QuestionnaireExpanded
    >({
      method: 'coach.questionnaires.own.list',
      params: {
        limit: LIMIT,
        sort: [['created', -1]],
        query: [['user_id', '==', user!._id]],
        expand: questionnaireExpand,
      },
    });

    localStore.getQuestFetch();

    return () => {
      localStore.questionnairesStore?.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = () => {
    localStore.getQuestFetch();
  };

  const handleOpenSendModal = (idQ: string) => {
    return () => {
      localStore.setModalQCurrentId(idQ);
      localStore.setShowSendModal(true);
    };
  };

  const closeSendModal = () => {
    localStore.setShowSendModal(false);
  };

  return (
    <Observer>
      {() => (
        <div className={`QuestionnaireToolbox ${styles.Questionnaire}`}>
          <h3>{labelQuestionnaire()}</h3>
          {localStore.loader ? (
            <Loader />
          ) : (
            <div className={styles.linksContainer}>
              {localStore.questionnaireList.length > 0 &&
                localStore.questionnaireList.map(userQuest => {
                  const photoUrl =
                    getFileSrc(userQuest.avatar as IFile, 250).url || undefined;

                  return (
                    <div
                      className={styles.buttUserQ}
                      key={userQuest._id}
                      style={{
                        backgroundImage: `url(${photoUrl})`,
                      }}
                    >
                      <Link
                        to={`/${PATH_TYPE.questionnaire}/view/${PATH_TYPE.questions}?id=${userQuest._id}`}
                        className={styles.link}
                      >
                        <div className={styles.overlay} />
                      </Link>
                      <div className={styles.textContainer}>
                        <div className={styles.text}>
                          <Link
                            to={`/${PATH_TYPE.questionnaire}/view/${PATH_TYPE.questions}?id=${userQuest._id}`}
                          >
                            <span>{userQuest.title}</span>
                          </Link>
                        </div>
                        <MoreBut
                          id={userQuest._id}
                          update={handleUpdate}
                          openSendModal={handleOpenSendModal(userQuest._id)}
                        />
                      </div>
                    </div>
                  );
                })}

              <Link
                to={`/${PATH_TYPE.createQuestionnaire}`}
                className={styles.butt}
              >
                <p className={styles.pluse}>+</p>
                <p className={styles.textButt}>{labelCreateQuestionnaire()}</p>
              </Link>
            </div>
          )}
          <ModalAnimateWin
            showModal={localStore.showSendModal}
            closeModalHandler={closeSendModal}
            className="greenModal"
            classNameCloseBut="modalCloseBut"
          >
            <QSendUsers
              idQ={localStore.modalQCurrentId}
              closeModal={closeSendModal}
            />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(QuestionnaireToolbox);
