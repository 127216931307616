import type {MutableRefObject} from 'react';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import type {ModalRef} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import TextArea from '@src/components/TextArea';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import styles from './SendMessageModal.module.css';

const I18N_SCOPE = 'SendMessageToUserModal';

type Props = {
  userId: string;
  title?: string;
};

const SendMessageModal = React.forwardRef<ModalRef, Props>(
  ({userId, title}, ref) => {
    const {
      stores: {currentUserStore, channelStore, postStore},
    } = useContext(AppContext);

    const history = useHistory();

    const [isSending, setIsSending] = useState(false);

    const fields = useRef({
      message: createHtmlInputField<HTMLTextAreaElement>('message', {
        validationRule: {
          type: 'string',
          max: 5000,
          messages: {
            required: t([I18N_SCOPE, 'message_required_error']),
            stringMax: t([I18N_SCOPE, 'message_max_length_error'], {
              maxLength: 5000,
            }),
          },
        },
      }),
    }).current;

    const sendMessage = useCallback(async () => {
      if (!fields.message.validate()) {
        return;
      }

      try {
        setIsSending(true);

        const channel = await channelStore.create({
          user_ids: [currentUserStore.user!._id, userId],
        });

        await postStore.create({
          body: fields.message.value,
          channel_id: channel._id,
        });

        if ((ref as MutableRefObject<ModalRef>).current) {
          (ref as MutableRefObject<ModalRef>).current.hide();
        }

        history.push({
          pathname: '/chats',
          search: `?cid=${channel._id}`,
          state: {
            isDM: true,
          },
        });
      } catch (error) {
        setIsSending(false);

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      }
    }, [
      channelStore,
      userId,
      currentUserStore.user,
      fields.message,
      history,
      postStore,
      ref,
    ]);

    return (
      <Modal
        ref={ref}
        title={title || t([I18N_SCOPE, 'title'])}
        isDisabled={isSending}
      >
        <div className={styles.textAreaContainer}>
          <Observer>
            {() => (
              <TextArea
                placeholder={t([I18N_SCOPE, 'text_input_placeholder'])}
                className={styles.textArea}
                error={fields.message.error || ''}
                onChange={fields.message.onChange}
              />
            )}
          </Observer>
        </div>
        <div className={styles.footer}>
          <Button onClick={sendMessage}>
            {!isSending
              ? t([I18N_SCOPE, 'send_button'])
              : t([I18N_SCOPE, 'sending_button'])}
          </Button>
        </div>
      </Modal>
    );
  },
);

export default React.memo(SendMessageModal);
