import React, {useContext, useEffect, useMemo, useState} from 'react';
import type {RouteProps} from 'react-router-dom';
import {Redirect, Route, useHistory} from 'react-router-dom';

import {autorun} from 'mobx';

import {useAppRedirect} from '@src/common/useAppRedirect';
import AppContext from '@src/context/App';

type Props = RouteProps & {
  forCoach?: boolean;
};

const PrivateRoute: React.FC<Props> = ({
  children,
  render,
  forCoach,
  ...rest
}) => {
  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);

  const history = useHistory();
  const redirect = useAppRedirect();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const sessionIsActive =
        (await authStore.sessionIsActive()) &&
        (await currentUserStore.userExists());

      setIsAuthenticated(sessionIsActive);
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dispose = autorun(() => {
      if (isAuthenticated && !currentUserStore.user) {
        setIsAuthenticated(false);
      }
    });

    return dispose;
  }, [currentUserStore.user, history, isAuthenticated]);

  useEffect(() => {
    const dispose = autorun(() => {
      if (
        isAuthenticated &&
        forCoach &&
        currentUserStore.user &&
        !currentUserStore.user.roles.includes('coach')
      ) {
        redirect('/');
      }
    });

    return dispose;
  }, [currentUserStore.user, forCoach, history, isAuthenticated, redirect]);

  const needShowOnboarding = useMemo(() => {
    const onboardingData = currentUserStore.user?.metadata?.onboarding || {};

    return (
      ['invited-client', 'client', 'employee'].includes(
        onboardingData.account_type || '',
      ) && !onboardingData.is_finished
    );
  }, [currentUserStore.user?.metadata?.onboarding]);

  return (
    <Route
      {...rest}
      render={p => {
        if (isAuthenticated === null) {
          return null;
        } else if (isAuthenticated) {
          if (
            needShowOnboarding &&
            !p.location.pathname.startsWith('/onboarding')
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/onboarding',
                  state: {from: p.location},
                }}
              />
            );
          }

          return render ? render(p) : children;
        } else {
          if (p.location.pathname !== 'login') {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {from: p.location},
                }}
              />
            );
          }
        }

        return null;
      }}
    />
  );
};

export default React.memo(PrivateRoute);
