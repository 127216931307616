import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';

import useURLQueryParam from '@src/hooks/useURLQueryParam';

import CourseMaterials from '../SeeProgram/Squad/CourseMaterials/CourseMaterials';

import LibraryContext from './context/LibraryContext';
import LibraryState from './context/LibraryState';
import type {ILibraryLocalStore} from './context/useLibraryLocalStore';
import ListFolderMaterials from './ListFolderMaterials/ListFolderMaterials';
import ListFolders from './ListFolders/ListFolders';
import ListFoldersPrograms from './ListFoldersPrograms/ListFoldersPrograms';
import ListFoldersUploads from './ListFoldersUploads/ListFoldersUploads';
import styles from './styles.module.scss';

interface Props {
  courseId?: string | undefined;
}

const MyLibrary: FC<Props> = ({courseId}) => {
  const folderId = useURLQueryParam('id');
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);

  const [currentId, setCurrentId] = useState<string | undefined>(courseId);

  useEffect(() => {
    setCurrentId(courseId);

    return () => {
      if (libraryStory) {
        libraryStory.clear();
      }

      () => {};
    };
  }, [libraryStory, courseId, currentId]);

  const renderComponent = () => {
    if (folderId === 'my_materials') {
      return <ListFolderMaterials />;
    }

    if (folderId === 'my_programs' || folderId === 'courses') {
      return <ListFoldersPrograms />;
    }

    if (folderId === 'my_uploads') {
      return <ListFoldersUploads />;
    }

    if (courseId && currentId === courseId) {
      return <CourseMaterials courseId={currentId} forCoach={true} />;
    }

    return <ListFolders />;
  };

  return (
    <LibraryState>
      <div className={`MyLibrary ${styles.MyLibrary}`}>{renderComponent()}</div>
    </LibraryState>
  );
};

export default memo(MyLibrary);
