import type {FC} from 'react';
import React from 'react';
import {Img as Image} from 'react-image';
import {observer} from 'mobx-react-lite';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import Loader from '@src/components/Loader/Loader';

import type {PostT} from '../PostsListItem';

import styles from './../../../styles.module.scss';

interface Props {
  imageAttachment?: IFile;
  showUserName?: boolean;
  userName?: string;
  pinnedUI?: boolean;
  isMy?: boolean | null;
  post?: PostT;
}

const ImageContainer: FC<Props> = ({
  imageAttachment,
  showUserName = false,
  userName,
  pinnedUI = false,
  isMy = false,
  post,
}) => {
  return (
    <div className={`ImageContainer ${styles.imageAttachmentsContainer}`}>
      <div className={styles.imgContainer}>
        <div className={styles.image}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={getFileSrc(imageAttachment).url || undefined}
          >
            <Image
              src={getFileSrc(imageAttachment, 250).url || ''}
              loader={<Loader height="95%" width="95%" />}
              unloader={<AvatarLoader />}
            />
          </a>
        </div>
      </div>
      {showUserName && !isMy ? (
        <div className={styles.userNameOnImageAttachmentGradient}>
          <div className={styles.userName}>
            {userName}
            {pinnedUI && post ? (
              <div>
                {` (${formatDate(dayjs(datetimeObjToISOString(post.created)), {
                  withYTT: true,
                })})`}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default observer(ImageContainer);
