import type {FC} from 'react';
import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {Logo} from '@yourcoach/shared/uikit/Logo';

import BurgerButton from '@src/components/BurgerButton/BurgerButton';
import {MainMenu} from '@src/modules';

interface Props {}

interface ILocalStore {
  isMenuOpen: boolean;
  setToggleMenu(val: boolean): void;
}

const MainMenuLayout: FC<Props> = () => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        isMenuOpen: false,
        setToggleMenu(val: boolean) {
          this.isMenuOpen = val;
        },
      },
      {
        isMenuOpen: observable,
        setToggleMenu: action,
      },
    ),
  ).current;

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    localStore.setToggleMenu(!localStore.isMenuOpen);
  };

  return (
    <Observer>
      {() => (
        <>
          <div
            className={`navbarVerticalLeftBack ${
              localStore.isMenuOpen ? 'show' : ''
            }`}
          />
          <nav
            className={`navbar navbarVerticalLeft ${
              localStore.isMenuOpen ? '' : 'hide'
            }`}
          >
            <Link className="logoContainer" to={'/'}>
              <Logo size={40} />
            </Link>
            <div className="BurgerButContainer">
              <BurgerButton
                onClick={handleOnClick}
                isOpen={localStore.isMenuOpen}
              />
            </div>
            <MainMenu />
            <div />
          </nav>
        </>
      )}
    </Observer>
  );
};

export default MainMenuLayout;
