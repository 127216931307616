import {useContext, useEffect, useState} from 'react';

import type {DateTimeObj} from '@yourcoach/shared/api';

import AppContext from '@src/context/App';

import {ongoingCoachHistoryRequest} from '../api';
import type {ChannelT} from '../context/useChatsLocalStore';

const useFetchOngoingHistoryList = (
  channel: ChannelT,
  isUserCoach?: boolean,
) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [isLoadedOngoingCoachHistoryList, setIsLoadedOngoingCoachHistoryList] =
    useState<boolean>(true);

  const [coachCoverageEndDate, setCoachCoverageEndDate] =
    useState<DateTimeObj | null>(null);

  useEffect(() => {
    setIsLoadedOngoingCoachHistoryList(false);
    !isLoadedOngoingCoachHistoryList &&
      isUserCoach &&
      currentUserStore?.user?.roles[0] === 'coach' &&
      channel?.resource?._id &&
      ongoingCoachHistoryRequest(channel.resource_id!).then(response => {
        setCoachCoverageEndDate(
          response._items[response._items.length - 1]?.end_date,
        );
      });

    return () => setIsLoadedOngoingCoachHistoryList(true);
  }, [
    channel.resource_id,
    isUserCoach,
    isLoadedOngoingCoachHistoryList,
    setCoachCoverageEndDate,
    channel?.resource?._id,
    currentUserStore?.user?.roles,
  ]);

  return {coachCoverageEndDate, setCoachCoverageEndDate};
};

export default useFetchOngoingHistoryList;
