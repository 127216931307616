import React, {useMemo} from 'react';

import classNames from 'classnames';

import {
  getProgramType,
  parseDuration,
  parsePaymentPlan,
  ProgramPaymentTypeEnum,
  ProgramTypeEnum,
} from '@yourcoach/shared/api/program';
import CalendarIcon from '@yourcoach/shared/assets/icons/calendar.svg';
import DollarSignIcon from '@yourcoach/shared/assets/icons/dollar-sign.svg';
import UserIcon from '@yourcoach/shared/assets/icons/user.svg';
import UserGroupIcon from '@yourcoach/shared/assets/icons/user-group.svg';
import {getPriceString} from '@yourcoach/shared/utils/money';

import type {ProgramT} from '.';
import styles from './Summary.module.css';

type Props = {
  program: ProgramT;
  className?: string;
};

const CRUProgramSummary: React.FC<Props> = ({program, className}) => {
  const lastEdition = useMemo(
    () => program.editions[program.editions.length - 1],
    [program],
  );

  const paymentPlan = useMemo(
    () =>
      lastEdition ? parsePaymentPlan(lastEdition.payment_plan) : undefined,
    [lastEdition],
  );

  const duration = useMemo(
    () => (lastEdition ? parseDuration(lastEdition.duration) : undefined),
    [lastEdition],
  );

  const programType = useMemo(
    () => (lastEdition ? getProgramType(lastEdition) : undefined),
    [lastEdition],
  );

  const ProgramTypeIcon = useMemo(
    () =>
      programType
        ? programType.id === ProgramTypeEnum.INDIVIDUAL.id
          ? UserIcon
          : UserGroupIcon
        : UserIcon,
    [programType],
  );

  return (
    <div className={classNames(styles.container, className)}>
      <h3 className={styles.title}>{program.title}</h3>
      {program.description ? (
        <p className={styles.description}>{program.description}</p>
      ) : null}
      {paymentPlan && !program.offer_id ? (
        <div className={styles.row}>
          <DollarSignIcon />
          <p className={styles.text}>
            {`${getPriceString({
              price: paymentPlan.total,
              currency: paymentPlan.parsedCurrency,
            })}`}
          </p>
          {paymentPlan.paymentType.id === ProgramPaymentTypeEnum.MONTHLY.id ? (
            <p className={styles.sub}>{paymentPlan.toString()}</p>
          ) : null}
        </div>
      ) : null}
      {duration ? (
        <div className={styles.row}>
          <CalendarIcon />
          <p className={styles.text}>{duration.toString()}</p>
        </div>
      ) : null}
      {programType ? (
        <div className={styles.row}>
          <ProgramTypeIcon />
          <p className={styles.text}>{programType.toString()}</p>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CRUProgramSummary);
