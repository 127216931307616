import React, {memo, useCallback, useEffect, useState} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {
  Questionnaire,
  UserQuestionnaire,
} from '@yourcoach/shared/api/questionnaire';
import {
  getQuestionnaireCurrentQuestionsIndex,
  getQuestionnaireQuestionsCount,
} from '@yourcoach/shared/api/questionnaire';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';
import {Image} from '@yourcoach/shared/uikit/Image';

import type {Expanded as QuestionnaireExpanded} from '../../../../models/questionnaire';

import styles from './styles.module.css';

type QuestionnaireT = Questionnaire & QuestionnaireExpanded;

interface Props {
  attachment: QuestionnaireT;
  onPress?: (attachment: QuestionnaireT) => void;
  forCoach: boolean;
  postId: string;
}

type QuestionnaireDataT = {
  title: string;
  description: string;
  avatar: string | undefined;
  status: string | null;
  statusColor: string;
  descriptionColor: string;
};

const QuestionnairePostAttachment: React.FC<Props> = ({
  attachment,
  onPress,
  forCoach,
  postId,
}) => {
  const [questionnaireData, setQuestionnaireData] =
    useState<QuestionnaireDataT>({
      title: attachment.title,
      description: 'Retrieving data...',
      avatar: undefined,
      status: null,
      statusColor: '#819AA9',
      descriptionColor: '#000000',
    });

  const getQuestionnaireData = useCallback(async () => {
    const data: QuestionnaireDataT = {...questionnaireData};

    const preview = getFileSrc(attachment.avatar, 250).url;

    if (preview) {
      data.avatar = preview;
    }

    const questionsCount = getQuestionnaireQuestionsCount(attachment);

    if (!forCoach) {
      try {
        const result: {
          _items: UserQuestionnaire[];
        } = await apiRequest({
          method: 'client.questionnaires.list',
          params: {
            query: [
              ['questionnaire_id', '==', attachment._id],
              ['resource_id', '==', postId],
            ],
          },
        });

        data.description =
          questionsCount === 1
            ? `${questionsCount} question`
            : `${questionsCount} questions`;
        data.status = 'not started';
        data.statusColor = '#819AA9';
        data.descriptionColor = '#819AA9';

        if (result._items.length) {
          const [userQuestionnaire] = result._items;

          const currentQuestionsIndex =
            getQuestionnaireCurrentQuestionsIndex(userQuestionnaire);

          if (userQuestionnaire.answered) {
            data.description = `${currentQuestionsIndex}/${questionsCount}`;
            data.status = 'completed';
            data.statusColor = '#25D2A2';
          } else if (userQuestionnaire.last_answer) {
            data.description = `${currentQuestionsIndex}/${questionsCount}`;
            data.status = 'in progress';
            data.statusColor = '#819AA9';
          }
        }
      } catch (error) {}
    } else {
      data.description =
        questionsCount === 1
          ? `${questionsCount} question`
          : `${questionsCount} questions`;
    }

    setQuestionnaireData(data);
  }, [attachment, forCoach, postId, questionnaireData]);

  useEffect(() => {
    getQuestionnaireData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPressCb = useCallback(
    () => onPress && onPress(attachment),
    [attachment, onPress],
  );

  return (
    <div className={styles.QuestionnairePostAttachment} onClick={onPressCb}>
      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <Image
            src={questionnaireData.avatar || ''}
            resizeMode="cover"
            placeholder={<AvatarPlaceholder text={questionnaireData.title} />}
          />
        </div>
        <div className={styles.contentContainer}>
          <span className={styles.name}>{questionnaireData.title}</span>
          {questionnaireData.description ? (
            <div className={styles.descriptionContainer}>
              <span
                className={styles.description}
                style={{
                  color: questionnaireData.descriptionColor,
                }}
              >
                {questionnaireData.description}
              </span>
              {questionnaireData.status ? (
                <span
                  className={styles.status}
                  style={{
                    color: questionnaireData.statusColor,
                  }}
                >
                  {questionnaireData.status}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(QuestionnairePostAttachment);
