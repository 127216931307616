import type React from 'react';
import {useEffect} from 'react';

import {useWS} from '@yourcoach/shared/hooks/useWS';

import {GET_WS_URL} from '../../config';
import {emitter} from '../../utils';

export const WS_RECEIVE_MESSAGE_EVENT = 'wsReceiveMessage';

const WS: React.FC = () => {
  const {lastWsMessage} = useWS(GET_WS_URL());

  useEffect(() => {
    if (lastWsMessage) {
      emitter.emit(WS_RECEIVE_MESSAGE_EVENT, lastWsMessage);
    }
  }, [lastWsMessage]);

  return null;
};

export default WS;
