import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import InView from 'react-intersection-observer';
import {Link} from 'react-router-dom';

import type {
  ApiRpcRequestParams,
  CollectionStore,
  Entity,
} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {
  ParsedPaymentPlan,
  PaymentPlan,
} from '@yourcoach/shared/api/program';
import {
  DefaultPaymentPlan,
  parseDuration,
  parsePaymentPlan,
} from '@yourcoach/shared/api/program';
import {userExpand} from '@yourcoach/shared/api/user';
import {Currency, getPriceString} from '@yourcoach/shared/utils/money';

import {labelPrograms} from '@src/common/i18n/i18nPrograms';
import Loader from '@src/components/Loader/Loader';
import Program from '@src/components/Program/Program';
import useIsLimitedEdition from '@src/hooks/useIsLimitedEdition';
import type {ProgramExpanded as IProgramExpanded} from '@src/models/app';

import {PathBuilderService} from '../../../v2/services/PathBuilderService';
import PracticeContext from '../context/PracticeContext';

import styles from './styles.module.css';

const limit = 20;

export interface ISendRpcParams {
  method?: string | undefined;
  params?: ApiRpcRequestParams | undefined;
  withAuth?: boolean | undefined;
  withCount?: boolean | undefined;
  observableFields?: string[] | undefined;
}

interface IProgramFiltered extends IProgramExpanded {
  paymentPlan: (PaymentPlan & ParsedPaymentPlan) | null;
  price: string;
  durationStr: string;
  isGroup: boolean;
  duration: number;
  coachesAvatar: ISoCouch[];
}

interface ISoCouch {
  avatar: IFile | null;
}

interface Props {}

const Programs: FC<Props> = () => {
  const practiceContext = useContext(PracticeContext);

  const {isLimitedEdition} = useIsLimitedEdition(practiceContext?.userId);

  const [programs, setPrograms] = useState<IProgramFiltered[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const programsStore = useRef<CollectionStore<Entity> | null>(null);
  const programExpand = {
    program: [
      'avatar_id',
      'background_id',
      ['edition_ids'],
      [
        'coach_ids->expanded_coaches',
        {coach_title: 'Coach not found'},
        userExpand,
      ],
    ],
  };

  const sendRpsParams: ISendRpcParams = {
    params: {
      sort: [['created', -1]],
      coach_id: practiceContext?.userId,
      limit: limit,
      expand: programExpand,
    },
  };

  const filteredPrograms = useCallback(
    (beforeFilteredPrograms: IProgramExpanded[]) => {
      const ret: IProgramFiltered[] = beforeFilteredPrograms.map(
        beforeFilteredProgram => {
          const countEditions = beforeFilteredProgram.editions.length - 1;
          const paymentPlan = beforeFilteredProgram.editions[countEditions]
            ? parsePaymentPlan(
                beforeFilteredProgram.editions[countEditions].payment_plan,
              )
            : null;

          const price = getPriceString({
            price: paymentPlan ? paymentPlan.total : 0,
            currency: paymentPlan
              ? paymentPlan.parsedCurrency
              : (Currency[DefaultPaymentPlan.currency] as Currency),
          });
          const duration = parseDuration(
            beforeFilteredProgram.editions[countEditions]
              ? beforeFilteredProgram.editions[countEditions].duration
              : 1,
          );

          return {
            ...beforeFilteredProgram,
            paymentPlan,
            price,
            durationStr: duration.toString(),
            isGroup: beforeFilteredProgram.editions[countEditions]
              ? beforeFilteredProgram.editions[countEditions].group_size > 1
              : false,
            duration: beforeFilteredProgram.editions[countEditions]
              ? beforeFilteredProgram.editions[countEditions].duration
              : 1,
            coachesAvatar: beforeFilteredProgram.expanded_coaches.map(couch => {
              return {
                name: couch.name,
                avatar: couch.avatar,
              };
            }) as ISoCouch[],
          } as IProgramFiltered;
        },
      );

      return ret;
    },
    [],
  );

  useEffect(() => {
    const fetch = async () => {
      programsStore.current = createCollectionStore(sendRpsParams);
      programsStore.current.setMethod('public.programs.list');
      programsStore.current.setWithAuth(false);
      setIsLoading(true);

      try {
        await programsStore.current.fetch();
      } catch (error) {
        // TODO: log error
      }

      setIsLoading(false);

      if (programsStore.current.hasItems) {
        const programFiltered = filteredPrograms(
          programsStore.current.items.slice() as IProgramExpanded[],
        );

        setPrograms(programFiltered);
      }
    };

    if (
      practiceContext?.userId !== '' &&
      isLimitedEdition !== null &&
      !isLimitedEdition
    ) {
      fetch();
    }

    return () => {
      programsStore.current?.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceContext?.userId]);

  const getMorePrograms = useCallback(async () => {
    if (programsStore.current && !programsStore.current.isFetching) {
      if (programsStore.current.hasMore) {
        setIsLoading(true);

        try {
          await programsStore.current.fetch({
            offset: programsStore.current.items.length,
          });
        } catch (error) {
          // TODO: log error
        }

        setIsLoading(false);

        if (programsStore.current.hasItems) {
          const programFiltered = filteredPrograms(
            programsStore.current.items.slice() as IProgramExpanded[],
          );

          setPrograms(programFiltered);
        }
      }
    }
  }, [filteredPrograms]);

  const handleOnChangeInView = useCallback(
    (inView: boolean) => {
      if (inView) {
        getMorePrograms();
      }
    },
    [getMorePrograms],
  );

  return isLimitedEdition ? null : (
    <div className={`Programs ${styles.Programs}`}>
      {!isLimitedEdition && programs.length ? (
        <>
          <hr className="mainSeparator" />
          <div className={styles.programsHeader}>
            <h3 className={styles.programsHeaderTitle}>{labelPrograms()}</h3>
          </div>
        </>
      ) : null}
      <div className={styles.programsContainer}>
        {programs.map(program => {
          return (
            <div key={program._id} className={styles.programContainer}>
              <Link
                // to={`${document.location.pathname}/programs/${
                //   program.slug ? program.slug : program._id
                // }${window.location.search}`}
                to={PathBuilderService.toProgramFuckTheRouterIHaveDOMWithSearch(
                  {slug: program.slug, id: program._id},
                )}
                className={styles.programLink}
              >
                <Program
                  priceStr={program.price}
                  title={program.title}
                  programId={program._id}
                  is_hidden={program.is_hidden}
                  is_closed={program.is_closed}
                  avatar={program.avatar}
                  coaches={program.coachesAvatar}
                  durationStr={program.durationStr}
                  isGroup={program.isGroup}
                  status={program.status}
                />
              </Link>
            </div>
          );
        })}
      </div>
      <InView as="div" onChange={handleOnChangeInView}>
        <div />
      </InView>
      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default memo(Programs);
