import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';

import {setError} from '@src/common/setError';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import MoveFileInFolders from '@src/components/MoveFileInFolders/MoveFileInFolders';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import type {
  ILink,
  TFileWithLink,
  TListDataItem,
  TSystemFolder,
} from '@src/models/library';
import AddFolder from '@src/modules/AddFolder/AddFolder';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';
import HeaderLibrary from '../HeaderLibrary/HeaderLibrary';
import {openFile, openLink} from '../libraryFunctions';
import ListFolderContainer from '../ListFolderContainer/ListFolderContainer';

import SystemFolder from './SystemFolder/SystemFolder';
import UserFile from './UserFile/UserFile';
import UserFolder from './UserFolder/UserFolder';
import {
  getMediaLinksDelete,
  getMediaLinksListObj,
  getMediaLinksUpdate,
} from './ListFolderLibrary';
import type {IListFolderLocalStore} from './ListFolderLocalStore';
import {listFolderLocalStore} from './ListFolderLocalStore';
import styles from './styles.module.scss';

interface Props {}

const ListFolders: FC<Props> = () => {
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const {
    stores: {currentUserStore, linkStore},
  } = useContext(AppContext);
  const localStore: IListFolderLocalStore = listFolderLocalStore;

  useEffect(() => {
    const folderId = libraryStory!.folderID;

    const links: CollectionStore<ILink> = createCollectionStore(
      getMediaLinksListObj(folderId),
    );

    const linksFolder: CollectionStore<ILink> = createCollectionStore(
      getMediaLinksListObj(null),
    );

    if (currentUserStore.user) {
      libraryStory!.setIsCouch(currentUserStore.user.roles.includes('coach'));
    }

    libraryStory!.getFoldersLinks(links);
    libraryStory!.getFoldersMainLinks(linksFolder);
    libraryStory!.updateFolderLinks(0);

    return () => {
      links.clear();
      linksFolder.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryStory!.folderID]);

  const handleFileDelete = (fileID: string) => {
    return async () => {
      try {
        await getMediaLinksDelete(fileID);
        libraryStory!.updateFolderLinks(-1);
      } catch (error) {
        const tryAgainFn = () => {};

        setError(error, tryAgainFn);
      }
    };
  };

  const handleFolderEdit = (item: ILink) => {
    return () => {
      localStore.toggleMoveFileOrEditFolder(false);
      localStore.setCurrentFolder(item);
      localStore.toggleVisModal(true);
    };
  };

  const handleFileMove = (item: ILink) => {
    return () => {
      localStore.toggleMoveFileOrEditFolder(true);
      localStore.setCurrentFolder(item);
      localStore.toggleVisModal(true);
    };
  };

  const handleFileDrag = (item: ILink) => {
    return () => {
      localStore.setCurrentFolder(item);
    };
  };

  const closeModal = () => {
    localStore.toggleVisModal(false);
    localStore.setCurrentFolder(null);
  };

  const handleOnUpload = async (newNameFile: string) => {
    libraryStory!.setLoading(true);

    if (localStore.currentFolder) {
      try {
        await linkStore.update(localStore.currentFolder, {
          name: newNameFile,
          folder_id: libraryStory!.folderID || null,
        });

        closeModal();

        libraryStory!.updateFolderLinks(0);
      } catch (error) {
        const tryAgainFn = () => {};

        setError(error, tryAgainFn);
      }

      libraryStory!.setLoading(false);
    }

    closeModal();
    localStore.setCurrentFolder(null);
  };

  const handleSelectFolderToMoveFile = async (folderId: string | null) => {
    const currentFile = localStore.currentFolder;

    closeModal();

    if (currentFile) {
      try {
        await getMediaLinksUpdate(currentFile._id, folderId, currentFile.name);
        libraryStory!.updateFolderLinks(0);
      } catch (error) {
        const tryAgainFn = () => {};

        setError(error, tryAgainFn);
      }
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {libraryStory!.loading
            ? ''
            : !libraryStory!.isError && <HeaderLibrary />}
          {libraryStory!.data.length === 0 && !libraryStory?.loading && (
            <NoResults />
          )}
          <ListFolderContainer>
            {libraryStory!.data.map((item: TListDataItem) => {
              if (typeof (item as TSystemFolder).type === 'undefined') {
                return (
                  <SystemFolder
                    key={item._id}
                    data={item as TSystemFolder}
                    className={styles.SystemFolder}
                  />
                );
              } else if ((item as ILink).type === 'folder') {
                return (
                  <UserFolder
                    key={item._id}
                    data={item as ILink}
                    className={styles.UserFolder}
                    folderDelete={handleFileDelete(item._id)}
                    folderEdit={handleFolderEdit(item as ILink)}
                    onDropFile={handleSelectFolderToMoveFile}
                  />
                );
              } else if ((item as ILink).resource!.type === 'url') {
                return (
                  <a
                    key={item._id}
                    onClick={openLink((item as ILink).resource!.repr)}
                    target="_blank"
                    rel="nofollow noopener"
                    className={styles.UserFile}
                  >
                    <UserFile
                      fileDelete={handleFileDelete(item._id)}
                      fileMove={handleFileMove(item as ILink)}
                      dragFile={handleFileDrag(item as ILink)}
                      file={
                        {
                          ...(item as ILink).resource,
                          link: {
                            ...item,
                            resource: null,
                          },
                        } as unknown as TFileWithLink
                      }
                    />
                  </a>
                );
              } else {
                return (
                  <a
                    key={item._id}
                    onClick={openFile(
                      (item as ILink).resource!.src.original.url,
                    )}
                    target="_blank"
                    rel="nofollow noopener"
                    className={styles.UserFile}
                  >
                    <UserFile
                      fileDelete={handleFileDelete(item._id)}
                      fileMove={handleFileMove(item as ILink)}
                      dragFile={handleFileDrag(item as ILink)}
                      file={
                        {
                          ...(item as ILink).resource,
                          link: {
                            ...item,
                            resource: null,
                          },
                        } as unknown as TFileWithLink
                      }
                    />
                  </a>
                );
              }
            })}
          </ListFolderContainer>
          <ModalAnimateWin
            showModal={localStore.showModal}
            className="modalAddFolder"
            closeModalHandler={closeModal}
          >
            {localStore.isMoveFileOrEditFolder ? (
              <MoveFileInFolders
                data={libraryStory!.mainFolderFolders}
                onSelectFolder={handleSelectFolderToMoveFile}
              />
            ) : (
              <AddFolder
                onUpload={handleOnUpload}
                edit
                nameFolder={localStore.currentFolder?.name}
              />
            )}
          </ModalAnimateWin>
        </>
      )}
    </Observer>
  );
};

export default memo(ListFolders);
