import React from 'react';
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react';

import {useMobxStore} from '@yourcoach/shared/hooks';
import {eventStoreFactory} from '@yourcoach/shared/stores/single-event';
import {ConditionalFlagManager} from '@yourcoach/shared/utils';

import Modal from '@src/components/ModalNew';
import CRUCalendarEvent from '@src/modules/calendar/CRUCalendarEvent';
import {Card} from '@src/v2/components/Card';
import {PageContainer} from '@src/v2/containers/Page';

import {CourseInfoContainer} from '../SingleEvent/containers/CourseInfo_updated';

import {Layout} from './components/Layout';
import {AttachmentsForm} from './containers/AttachmentsForm';
import {ClientCard} from './containers/ClientCard';
import {ClientsList} from './containers/ClientsList';
import {ControlsContainer} from './containers/Controls';
import {PageTitle} from './containers/PageTitle';

export const SingleEventPage: React.FC = observer(() => {
  const {id}: {id: string} = useParams();

  const store = useMobxStore(() => eventStoreFactory(id));

  const isLoading = useMobxStore(() => {
    const manager = new ConditionalFlagManager(true);

    manager.trueWhen(
      () => store.initialLoad.isLoading || store.busy.getReason('fetch'),
    );

    return manager;
  });

  React.useEffect(() => {
    store.fetch();
  }, [store]);

  const onUpdateEditedEventCallback = React.useCallback(() => {
    store.isEditOpenModal.setFalse();
    store.fetch();
  }, [store]);

  return (
    <PageContainer
      titleSlot={
        <PageTitle
          title={store.event?.title}
          isConference={store.event?.isConference}
          isTask={store.event?.isTask}
          isLoading={isLoading.value}
        />
      }
      headerSlot={<ControlsContainer store={store} />}
      isWithBreadcrumbs
      isWithFromBreadcrumb
    >
      <Layout.Container>
        <Layout.Main>
          <Card>
            <CourseInfoContainer
              courseId={store.event?.value?.course_id}
              eventId={store.event?.id}
            />

            {store.initialLoad.isReady && store.event.isMeCoach && (
              <ClientsList
                isLoading={isLoading.value}
                selectedUserId={store.eventBySelectedClient.user.id}
                users={store.events.entries}
                onUserSelect={store.setSelectedClientId}
              />
            )}
            {store.event?.isTask && store.event?.isMeClient && (
              <AttachmentsForm taskId={store.event.id} />
            )}
          </Card>
        </Layout.Main>
        <Layout.Aside>
          {store.initialLoad.isReady && store.event.isMeCoach ? (
            <ClientCard
              isShowTaskInfo={store.event.isTask && store.event.isMeCoach}
              clientId={store.eventBySelectedClient.user.id}
              idOrUuid={store.eventBySelectedClient.eventFetchId}
            />
          ) : null}
        </Layout.Aside>
      </Layout.Container>

      {store.initialLoad.isReady && (
        <React.Fragment>
          <Modal
            isOpen={store.isEditOpenModal.value}
            title={store.editPopupTitle}
            onRequestClose={store.isEditOpenModal.toggle}
          >
            <CRUCalendarEvent
              course={store.event?.value.course}
              event={store.event.value}
              onUpdate={onUpdateEditedEventCallback}
            />
          </Modal>
        </React.Fragment>
      )}
    </PageContainer>
  );
});
