import React from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';

import {t} from 'i18n-js';

// import ProfileIcon from '@yourcoach/shared/assets/icons/primary/Profile.svg';
import SendIcon from '@yourcoach/shared/assets/icons/primary/Send.svg';
import ChatIcon from '@yourcoach/shared/assets/icons/secondary/Chat.svg';
import MessageSentIcon from '@yourcoach/shared/assets/icons/secondary/Message sent.svg';
import AttachmentIcon from '@yourcoach/shared/assets/icons/secondary/Send.svg';
import {useMobxStore} from '@yourcoach/shared/hooks';
import type {TaskModel} from '@yourcoach/shared/stores/single-event';
import {ClientCardStore} from '@yourcoach/shared/stores/single-event';
import {Avatar} from '@yourcoach/shared/uikit/Avatar';
import {Text} from '@yourcoach/shared/uikit/Text';
import {ConditionalFlagManager} from '@yourcoach/shared/utils';

import {Card} from '@src/v2/components/Card';
import {IconButton} from '@src/v2/components/IconButton';

import {PathBuilderService} from '../../../../services/PathBuilderService';
import {AttachedPhotoIcon} from '../../components/AttachedPhotoIcon';
import {AttachmentsBox} from '../../components/AttachmentsBox';
import {MealIcon} from '../../components/MealIcon';
import {Subtitle} from '../../components/Subtitle';
import {I18N_CLIENT_TASK, useOnAttachmentClick, useOpenMeal} from '../../utils';

import {InfoItem} from './components/InfoItem';
import {Layout as L} from './components/Layout';
import {TaskInfoSkeleton, UserInfoSkeleton} from './components/skeleton';
import type {Props} from './types';

export const ClientCard: React.FC<Props> = observer(
  ({isShowTaskInfo, clientId, idOrUuid}) => {
    const store = useMobxStore(() => new ClientCardStore(idOrUuid, clientId));
    const isLoading = useMobxStore(() => {
      const manager = new ConditionalFlagManager(true);

      manager.trueWhen(
        () => store.initialLoad.isLoading || store.busy.getReason('fetch'),
      );

      return manager;
    });

    const history = useHistory();

    const {MealModal, onMealClick} = useOpenMeal();

    const onAttachmentClickHandler = useOnAttachmentClick();

    React.useEffect(() => {
      store.setUserId(clientId);
    }, [store, clientId]);

    // const onProfileClickHandler = React.useCallback(() => {}, []);

    const onSendMessageClickHandler = React.useCallback(() => {
      const channels = store.chanelIds;

      if (channels.individual) {
        history.push(
          PathBuilderService.toChat(null, {
            withUser: {_id: channels.individual},
          }),
        );
      } else {
        history.push(PathBuilderService.toChat(channels.group));
      }
    }, [store, history]);

    const renderUserInfo = () => {
      return (
        <>
          <Avatar
            name={store.client.name}
            avatarUrl={store.client.avatarUrl}
            size={'icon.xxl'}
          />
          <L.User.Body>
            <L.User.Header>
              <Subtitle>{store.client.name}</Subtitle>
              {store.client.isOnline && <Text color={'global1'}>online</Text>}
            </L.User.Header>
            <L.User.Buttons>
              <IconButton
                onClick={onSendMessageClickHandler}
                icon={<SendIcon />}
                iconSize={'icon.m'}
                buttonVariant={'rounded'}
                iconVariant={'round'}
                iconBgColor={'global1'}
                iconPadding={10}
              >
                <Text>{t([I18N_CLIENT_TASK, 'button', 'send_message'])}</Text>
              </IconButton>
              {/*  TODO: uncomment when profile page*/}
              {/*<IconButton*/}
              {/*  onClick={onProfileClickHandler}*/}
              {/*  icon={<ProfileIcon />}*/}
              {/*  iconSize={'icon.m'}*/}
              {/*  buttonVariant={'rounded'}*/}
              {/*  iconBgColor={'global1'}*/}
              {/*  iconVariant={'round'}*/}
              {/*  iconPadding={10}*/}
              {/*>*/}
              {/*  <Text>{t([I18N_SINGLE_EVENT, 'buttons', 'profile'])}</Text>*/}
              {/*</IconButton>*/}
            </L.User.Buttons>
          </L.User.Body>
        </>
      );
    };

    const renderTaskInfo = () => {
      return (
        <>
          <InfoItem
            label={t([I18N_CLIENT_TASK, 'label', 'status'])}
            icon={<MessageSentIcon />}
          >
            <Text variant={'small'} color={'global1'}>
              {store.event.status}
            </Text>
          </InfoItem>

          <InfoItem
            label={t([I18N_CLIENT_TASK, 'label', 'comment'])}
            icon={<ChatIcon />}
          >
            <Text variant={'small'}>{(store.event as TaskModel).comment}</Text>
          </InfoItem>

          <InfoItem
            label={t([I18N_CLIENT_TASK, 'label', 'attachments'])}
            icon={<AttachmentIcon />}
          >
            <AttachmentsBox>
              {(store.event as TaskModel).attachments.map(attachment => (
                <AttachedPhotoIcon
                  alt={attachment.alt}
                  key={attachment._id}
                  onClick={() => onAttachmentClickHandler(attachment)}
                  thumbSrc={attachment.imageSrc}
                />
              ))}
              {(store.event as TaskModel).meals.map(meal => (
                <MealIcon
                  onClick={() => onMealClick(meal)}
                  key={meal.query}
                  time={meal.renderTime}
                />
              ))}
              <MealModal />
            </AttachmentsBox>
          </InfoItem>
        </>
      );
    };

    return (
      <Card>
        <L.Container>
          <L.Header />
          <L.User.Container>
            {isLoading.value ? <UserInfoSkeleton /> : renderUserInfo()}
          </L.User.Container>
          {isShowTaskInfo && (
            <L.Info.Container>
              {isLoading.value ? <TaskInfoSkeleton /> : renderTaskInfo()}
            </L.Info.Container>
          )}
        </L.Container>
      </Card>
    );
  },
);
