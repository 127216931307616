import type {FC} from 'react';
import React, {memo} from 'react';

import IconClose from '@src/components/icons/IconClose/IconClose';

interface Props {
  children: any;
  className?: string;
  classNameCloseBut?: string;
  closeModalWindowContainer?: () => void;
  styles?: any;
}

const ModalWindowContainer: FC<Props> = ({
  children,
  className = '',
  classNameCloseBut = '',
  closeModalWindowContainer,
  styles,
}) => {
  return (
    <div className={`ModalWindowContainer ${className}`} style={styles}>
      {closeModalWindowContainer ? (
        <button
          onClick={closeModalWindowContainer}
          className={`closeBut ${classNameCloseBut}`}
        >
          <IconClose />
        </button>
      ) : (
        ''
      )}
      {children}
    </div>
  );
};

export default memo(ModalWindowContainer);
