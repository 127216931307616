import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  flex-direction: column;
  gap: 16px;
  min-height: 320px;
  position: relative;
`;
const Header = styled(View)``;

const UserContainer = styled(View)`
  align-items: flex-start;
  position: relative;
  gap: 20px;
`;
const UserBody = styled(View)`
  flex-direction: column;
  gap: 12px;
`;
const UserHeader = styled(View)`
  flex-direction: column;
  gap: 4px;
`;
const UserButtons = styled(View)`
  gap: 8px;
`;
const InfoContainer = styled(View)`
  flex-direction: column;
  gap: 16px;
`;
const ItemContainer = styled(View)`
  gap: 12px;
`;
const ItemIcon = styled(View)`
  width: 32px;
  height: 32px;
`;
const ItemContent = styled(View)`
  flex-direction: column;
`;

const Layout = {
  Container,
  Header,
  User: {
    Container: UserContainer,
    Body: UserBody,
    Header: UserHeader,
    Buttons: UserButtons,
  },
  Info: {
    Container: InfoContainer,
    Item: {
      Container: ItemContainer,
      Icon: ItemIcon,
      Content: ItemContent,
    },
  },
};

export {Layout};
