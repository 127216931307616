import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {useToasts} from 'react-toast-notifications';
import {Observer} from 'mobx-react';

import {t} from '@src/i18n';
import YcCoin from '@src/modules/UserCertification/YcCoin/YcCoin';

import styles from './styles.module.css';

const I18N_SCOPE = 'RewardNotification';

interface Props {
  title?: string;
  qty?: number;
  text?: string;
  description?: string;
  className?: string;
  toastId?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const YCToast: FC<Props> = ({
  text = '',
  description = '',
  title,
  qty,
  toastId,
  onClick,
}) => {
  const {removeToast} = useToasts();
  const onClickCb = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);

        if (toastId) {
          removeToast(toastId);
        }
      }
    },
    [onClick, removeToast, toastId],
  );

  return (
    <Observer>
      {() => (
        <div className={`YCToast ${styles.YCToast}`} onClick={onClickCb}>
          <div className={styles.YcCoinContainer}>
            <YcCoin size={34} />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.text}>
              {text}
              {qty ? `${t([I18N_SCOPE, 'title'])}  +${qty}` : ''}
            </div>
            {description && (
              <div className={styles.description}>{`${description} ${
                title ? title : ''
              }`}</div>
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(YCToast);
