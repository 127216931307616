import React from 'react';

import {t} from 'i18n-js';

import type {Meal as MealType} from '@yourcoach/shared/stores/single-event';
import type {Nullable} from '@yourcoach/shared/utils';

import Modal from '@src/components/ModalNew';

import {Meal} from '../components/Meal';

import {I18N_MEAL_POPUP} from './index';

export function useOpenMeal() {
  const [selectedMeal, setSelectedMeal] =
    React.useState<Nullable<MealType>>(null);

  const onMealClick = (meal: MealType) => {
    setSelectedMeal(meal);
  };

  const MealModal = () => (
    <Modal
      title={t([I18N_MEAL_POPUP, 'title', 'show'])}
      isOpen={Boolean(selectedMeal)}
      onAfterClose={() => setSelectedMeal(null)}
    >
      {selectedMeal ? <Meal meal={selectedMeal} /> : null}
    </Modal>
  );

  return {onMealClick, MealModal};
}
