import React, {useCallback, useMemo, useRef, useState} from 'react';
import AvatarEditor from 'react-avatar-editor';

import classNames from 'classnames';
import dayjs from 'dayjs';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import Button from '@src/components/Button';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import {proxifyUrl} from '@src/utils';

import {getCustomConfirmAlert} from './CustomConfirmAlert/CustomConfirmAlert';
import Loader from './Loader/Loader';
import styles from './ImageEditor.module.css';

const I18N_SCOPE = 'ImageEditor';

interface Props {
  image: File | IFile;
  onSuccess?: (file: File) => void;
  width?: number;
  height?: number;
  className?: string;
}

const ImageEditor: React.FC<Props> = ({
  image,
  onSuccess,
  width = 200,
  height = 200,
  className,
}) => {
  const [error, setError] = useState('');
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [borderRadius, setBorderRadius] = useState(0);
  const [useProxy, setUseProxy] = useState(false);

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible(true);

  const editorRef = useRef<AvatarEditor | null>(null);

  const imageSource = useMemo(() => {
    if (image instanceof File) {
      return image;
    }

    return getFileSrc(image).url || '';
  }, [image]);

  const imageName = useMemo(() => {
    if (image instanceof File) {
      return image;
    }

    return getFileSrc(image).filename || '';
  }, [image]);

  const onLoad = useCallback(() => {
    hideOverlay();
  }, [hideOverlay]);

  const onReady = useCallback(() => {
    hideOverlay();
  }, [hideOverlay]);

  const onError = useCallback(() => {
    setError(t([I18N_SCOPE, 'load_error_message']));

    hideOverlay();
  }, [hideOverlay]);

  const onScaleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setScale(parseFloat(e.target.value));
    },
    [],
  );

  const rotateLeft = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      setRotate(prev => prev - 90);
    },
    [],
  );

  const rotateRight = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      setRotate(prev => prev + 90);
    },
    [],
  );

  const onBorderRadiusChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBorderRadius(parseInt(event.target.value, 10));
    },
    [],
  );

  const onSaveButtonClick = useCallback(() => {
    showOverlay();

    try {
      editorRef.current.getImage().toBlob((blob: Blob) => {
        const file = new File(
          [blob],
          `${imageName}-edited-${dayjs().format('YYYYMMDDHHmmss')}`,
          {type: blob.type},
        );

        hideOverlay();

        onSuccess && onSuccess(file);
      });
    } catch (getImageError) {
      hideOverlay();

      getCustomConfirmAlert({
        message: t([I18N_SCOPE, 'get_image_error_message']),
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              setUseProxy(true);
            },
            type: 'confirm',
          },
        ],
      });
    }
  }, [hideOverlay, imageName, onSuccess, showOverlay]);

  return (
    <div className={classNames('ImageEditor', styles.Component, className)}>
      {error ? (
        <div>{error}</div>
      ) : (
        <>
          <div className={styles.container}>
            <AvatarEditor
              className={styles.avatarEditor}
              image={
                image instanceof File
                  ? image
                  : useProxy
                  ? proxifyUrl(imageSource as string)
                  : `${imageSource}?v=${Math.random()}`
              }
              border={30}
              width={width}
              height={height}
              borderRadius={borderRadius}
              crossOrigin="anonymous"
              onLoadFailure={onError}
              onImageReady={onReady}
              onLoadSuccess={onLoad}
              scale={scale}
              rotate={rotate}
              ref={editorRef}
            />
            <div className={styles.settingsContainer}>
              <div className={styles.setting}>
                <div className={styles.settingName}>
                  {t([I18N_SCOPE, 'rotate_label'])}
                  {':'}
                </div>
                <div className={styles.settingContent}>
                  <Button onClick={rotateLeft} className={styles.rotateButton}>
                    {t([I18N_SCOPE, 'left_rotate_button'])}
                  </Button>
                  <Button onClick={rotateRight} className={styles.rotateButton}>
                    {t([I18N_SCOPE, 'right_rotate_button'])}
                  </Button>
                </div>
              </div>
              <div className={styles.setting}>
                <div className={styles.settingName}>
                  {t([I18N_SCOPE, 'scale_label'])}
                  {':'}
                </div>
                <div className={styles.settingContent}>
                  <input
                    type="range"
                    onChange={onScaleChange}
                    min={0.1}
                    max={2}
                    step={0.01}
                    defaultValue={1}
                  />
                </div>
              </div>
              <div className={styles.setting}>
                <div className={styles.settingName}>
                  {t([I18N_SCOPE, 'border_radius_label'])}
                  {':'}
                </div>
                <div className={styles.settingContent}>
                  <input
                    type="range"
                    onChange={onBorderRadiusChange}
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button onClick={onSaveButtonClick}>
              {t([I18N_SCOPE, 'save_button'])}
            </Button>
          </div>
        </>
      )}
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ImageEditor);
