import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {Observer} from 'mobx-react';

import {action, computed, observable, runInAction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {User} from '@yourcoach/shared/api/user';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelAreYouSure,
  labelCourseInvitesTabInviteDeleteInvite,
  labelCourseInvitesTabInviteResendInvite,
  labelCourseInvitesTabNoResults,
  labelCourseInvitesTabProgramInvites,
} from '@src/common/i18n/i18nCourse';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconProfile} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import {expandEvent} from '@src/models/courses';

import {emitter} from '../../../../../../widget/src/utils';
import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';

import styles from './../../styles.module.scss';

interface ILocalStore {
  course: CourseT | null;
  membershipsStore: CollectionStore<MembershipT> | null;
  programInvitesCount: number;
  shouldRenderStub: boolean;
  isFetching: boolean;
  currentMembership: MembershipT | null;
  data: MembershipT[] | null;
  setCourse(courseVal: CourseT | null): void;
  setMembershipsStore(
    membershipsStore: CollectionStore<MembershipT> | null,
  ): void;
  setCurrentMembership(currentMembership: MembershipT | null): void;
  setProgramInvitesCount(programInvitesCount: number): void;
}

export const PROGRAM_INVITES_MESSAGE_ID = 'programInvitesMessage';

interface Props {
  course: CourseT;
}

export type MembershipT = Membership & {
  course: CourseT;
  user: Partial<User> &
    Required<Pick<User, 'name'>> & {
      avatar?: IFile;
    };
};

const CourseInvitesTab: FC<Props> = ({course}) => {
  const LIMIT = 999;
  const menuId = 'CourseInvitesTab';
  const {
    stores: {membershipStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          course: null,
          setCourse(courseVal: CourseT | null) {
            this.course = courseVal;
          },
          currentMembership: null,
          setCurrentMembership(currentMembership: MembershipT | null) {
            this.currentMembership = currentMembership;
          },
          membershipsStore: null,
          setMembershipsStore(
            membershipsStore: CollectionStore<MembershipT> | null,
          ) {
            this.membershipsStore = membershipsStore;
          },
          programInvitesCount: 0,
          setProgramInvitesCount(programInvitesCount: number) {
            this.programInvitesCount = programInvitesCount;
          },
          get shouldRenderStub() {
            return (
              this.membershipsStore &&
              this.membershipsStore.isLoaded &&
              !this.membershipsStore.hasItems
            );
          },
          get isFetching() {
            return (
              (this.membershipsStore as CollectionStore<MembershipT>) &&
              (!(this.membershipsStore as CollectionStore<MembershipT>)
                .isLoaded ||
                (this.membershipsStore as CollectionStore<MembershipT>)
                  .isFetching)
            );
          },
          get data() {
            if (this.membershipsStore) {
              const data = this.membershipsStore.items
                .slice()
                .map(membership => ({
                  ...membership,
                  course: course as CourseT,
                }));

              if (this.programInvitesCount as number) {
                // @ts-ignore
                data.unshift({_id: PROGRAM_INVITES_MESSAGE_ID});
              }

              return data;
            } else {
              return [];
            }
          },
        },
        {
          course: observable,
          currentMembership: observable,
          membershipsStore: observable,
          programInvitesCount: observable,
          setCourse: action,
          setCurrentMembership: action,
          setProgramInvitesCount: action,
          setMembershipsStore: action,
          shouldRenderStub: computed,
          isFetching: computed,
          data: computed,
        },
      ),
    [course],
  );

  useEffect(() => {
    localStore.setCourse(course);
    localStore.setMembershipsStore(
      createCollectionStore({
        method: 'coach.memberships.list',
        params: {
          limit: LIMIT,
          sort: [['created', -1]],
          query: [
            ['course_id', '==', course._id],
            ['user_id', '!in', course.coach_ids],
            ['status', 'in', ['proposed'] as MembershipT['status'][]],
          ],
          expand: expandEvent,
        },
        withCount: true,
      }),
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    const timerId = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      clearTimeout(timerId);
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

      if (localStore.membershipsStore) {
        localStore.membershipsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const _handleWsMessage = msg => {
    if (
      msg.event.type.includes('membership') &&
      msg.membership.course_id === course._id
    ) {
      fetchData(true);
    }
  };

  const fetchData = async (silent = false) => {
    await localStore.membershipsStore!.fetch(
      {
        limit: Math.max(localStore.membershipsStore!.items.length, LIMIT),
      },
      {silent},
    );

    const {_count} = await apiRequest({
      method: 'coach.invites.count',
      params: {
        query: [['program_id', '==', course.program_id]],
      },
    });

    runInAction(() => {
      localStore.setProgramInvitesCount(_count as number);
    });
  };

  const handleClickMembership = (membership: MembershipT) => {
    return () => {
      _onMembershipPress(membership);
    };
  };

  const _onMembershipPress = (membership: MembershipT) => {
    if (membership.user._id) {
      // TODO: navigate to user profile
    }
  };

  const handleDeleteInvite = arrProps => {
    const localMembership = arrProps.props.membership as MembershipT;

    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => _deleteInvite(localMembership),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteInvite = async (membership: MembershipT) => {
    try {
      await membershipStore.decline(membership);

      localStore.membershipsStore!.removeItem(membership);

      fetchData(true);
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const handleResendInvite = arrProps => {
    const localMembership = arrProps.props.membership as MembershipT;

    getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => _resendInvite(localMembership),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _resendInvite = async (membership: MembershipT) => {
    try {
      await membershipStore.decline(membership);

      localStore.membershipsStore!.removeItem(membership);

      const createdMembership = (await membershipStore.coach.create({
        course_id: membership.course_id as string,
        user_id: membership.user_id,
        expand: expandEvent,
      })) as MembershipT;

      localStore.membershipsStore!.addItem(createdMembership);

      fetchData(true);
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const handleOnClickOptions = (membership: MembershipT) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      localStore.setCurrentMembership(membership);
      event.stopPropagation();
      event.preventDefault();

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          membership,
        },
      });
    };
  };

  const MyMenu = () => (
    <Observer>
      {() => (
        <Menu id={menuId} animation={animation.fade} className="contextMenu">
          <Item onClick={handleDeleteInvite} className="contextMenuItem">
            {labelCourseInvitesTabInviteDeleteInvite()}
          </Item>
          <Item onClick={handleResendInvite} className="contextMenuItem">
            {labelCourseInvitesTabInviteResendInvite()}
          </Item>
        </Menu>
      )}
    </Observer>
  );

  return (
    <Observer>
      {() => (
        <>
          {localStore.shouldRenderStub ? (
            <NoResults text={labelCourseInvitesTabNoResults()}>
              <div className={styles.iconNoResultContainer}>
                <IconProfile
                  className={styles.iconNoResult}
                  viewBox="0 0 24 24"
                />
              </div>
            </NoResults>
          ) : localStore.isFetching ? (
            <Loader />
          ) : (
            <div className={`CourseInvitesTab ${styles.CourseInvitesTab}`}>
              {localStore.data?.map((membership, index) => {
                if (membership._id === PROGRAM_INVITES_MESSAGE_ID) {
                  return (
                    <div
                      key={index}
                      className={`${styles.userContainerLine} ${styles.noClickable}`}
                    >
                      <div className={styles.frozenBy}>
                        {labelCourseInvitesTabProgramInvites()}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={membership._id}
                      className={styles.userContainerLine}
                      onClick={handleClickMembership(membership)}
                    >
                      <div className={styles.userContainer}>
                        <div className={styles.userAvatar}>
                          {
                            <OtherUserProfileImg
                              avatar={membership.user.avatar}
                              title={membership.user.name}
                            />
                          }
                        </div>
                        <div className={styles.userName}>
                          {membership.user_name}
                        </div>
                      </div>
                      <div
                        className={styles.dotMenu}
                        onClick={handleOnClickOptions(membership)}
                      >
                        ⋮
                      </div>
                    </div>
                  );
                }
              })}
              <MyMenu />
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(CourseInvitesTab);
