import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {getFileSrc, getYcLogoFile} from '@yourcoach/shared/api/media/file';
import {Image} from '@yourcoach/shared/uikit/Image';

import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';

import PracticeContext from '../../context/PracticeContext';
import type {IPracticeLocalStore} from '../../context/usePracticeLocalStore';

import styles from './../styles.module.css';

interface Props {}

const HeaderImg: FC<Props> = () => {
  const programState: IPracticeLocalStore | null = useContext(PracticeContext);

  return (
    <Observer>
      {() => (
        <div className={`HeaderImg ${styles.HeaderImg}`}>
          <Image
            src={
              programState!.isLimitedEdition
                ? getFileSrc(getYcLogoFile(340, true)).url || ''
                : getFileSrc(programState!.practice.coach_logo, 1000).url || ''
            }
            placeholder={<AvatarLoader />}
            blurredCover
            resizeMode="contain"
          />
          <OverlayImage className={styles.OverlayImage} />
        </div>
      )}
    </Observer>
  );
};

export default memo(HeaderImg);
