import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {labelSection} from '@src/common/i18n/i18nQuestionnaire';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import UserFlowContext from '../../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../../context/useUserFlowLocalStore';
import {
  MultipleChoiceOpenType,
  MultipleChoiceType,
  SingleChoiceOpenType,
  SingleChoiceType,
  TextType,
} from '../TypesTemplates';

import styles from './../styles.module.css';

interface Props {}

const QUserFlowQuestionFactory: FC<Props> = () => {
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);
  const getStep = () => {
    switch (userFlowStory?.currentStepQuestion?.question.type) {
      case ALIAS_TYPES.text:
        return <TextType />;
      case ALIAS_TYPES.singleChoice:
        return <SingleChoiceType />;
      case ALIAS_TYPES.singleChoiceOpen:
        return <SingleChoiceOpenType />;
      case ALIAS_TYPES.multipleChoice:
        return <MultipleChoiceType />;
      case ALIAS_TYPES.multipleChoiceOpen:
        return <MultipleChoiceOpenType />;
      default:
        return <TextType />;
    }
  };

  return (
    <Observer>
      {() => (
        <div
          className={`QUserFlowQuestionFactory ${styles.QUserFlowQuestionFactory}`}
        >
          <div className={styles.header}>
            <div className={styles.sectionName}>
              {labelSection()}{' '}
              {userFlowStory?.currentStepQuestion?.numSection! + 1}{' '}
              {userFlowStory?.currentStepQuestion?.sectionName}
            </div>
            <div
              className={`${styles.questionTitle} ${
                userFlowStory!.currentStepQuestion!.question!.question!.length >
                150
                  ? styles.questionTitleBig
                  : ''
              }`}
            >
              {userFlowStory?.currentStepQuestion?.question.question}
            </div>
          </div>
          {getStep()}
        </div>
      )}
    </Observer>
  );
};

export default memo(QUserFlowQuestionFactory);
