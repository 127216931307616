import type {FC} from 'react';
import React, {memo} from 'react';

import {labelChatsChannelPinnedMessageHeader} from '@src/common/i18n/i18nChannel';

import styles from './../../../styles.module.scss';

interface Props {
  text: string;
  showHeader?: boolean;
  singleButtonUI?: boolean;
  onPress?: () => void;
  button?: {
    text: string;
    onPress: () => void;
  };
}

const ChannelPinnedMessage: FC<Props> = ({
  onPress,
  singleButtonUI,
  showHeader,
  text,
  button,
}) => {
  const handleOnClick = () => {
    if (onPress) {
      onPress();
    }
  };

  return (
    <div
      className={`ChannelPinnedMessage ${styles.ChannelPinnedMessage} ${styles.ChannelPinnedMessageClickable}`}
      onClick={handleOnClick}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {!singleButtonUI ? <div className={styles.line} /> : null}
          <div className={styles.contentContainer}>
            {showHeader ? (
              <div className={styles.headerText}>
                {labelChatsChannelPinnedMessageHeader()}
              </div>
            ) : null}
            {text ? (
              <div
                className={`${styles.text} ${
                  singleButtonUI ? styles.headerText : ''
                }`}
              >
                {text}
              </div>
            ) : null}
          </div>
        </div>
        {button ? (
          <div className={styles.button} onClick={button.onPress}>
            {button.text}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(ChannelPinnedMessage);
