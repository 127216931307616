import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Link} from 'react-router-dom';

import classNames from 'classnames';
import {t} from 'i18n-js';
import {autorun} from 'mobx';

import {createCollectionStore} from '@yourcoach/shared/api';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';
import CloseIcon from '@yourcoach/shared/assets/icons/close.svg';
import SearchIcon from '@yourcoach/shared/assets/icons/search.svg';
import UserPlusIcon from '@yourcoach/shared/assets/icons/user-plus.svg';

import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Button from '@src/components/Button';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader/Loader';
import {MODAL_STICKY_FOOTER_CLASS_NAME} from '@src/components/ModalNew';
import NoResultsHeader from '@src/components/NoResultsHeader';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';

import styles from './SelectCoach.module.css';

export const I18N_SCOPE = 'SelectCoach';

type Coach = User & UserExpanded;

export type Props = {
  selection?: Coach[];
  canAcceptPayments?: boolean;
  onSelect?: (coaches: Coach[]) => void;
};

const SelectCoach: React.FC<Props> = ({
  selection: selectionProp,
  canAcceptPayments,
  onSelect,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const [query, setQuery] = useState('');
  const [selection, setSelection] = useState<Coach[]>(selectionProp || []);
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const shouldSearch = useMemo(() => query && query.length >= 2, [query]);

  const searchTimeout = useRef(setTimeout(() => {}, 0));
  const coachesStore = useRef(
    createCollectionStore<Coach>({
      method: 'client.users.search',
      params: {
        query: [['roles', 'in', ['coach'] as User['roles']]],
        expand: userExpand,
      },
    }),
  ).current;

  useEffect(() => {
    const dispose = autorun(() => {
      setCoaches(coachesStore.items.slice());
      setIsFetching(coachesStore.isFetching);
    });

    return () => {
      dispose();
      coachesStore.clear();

      clearTimeout(searchTimeout.current);
    };
  }, [coachesStore]);

  const onFooterButtonPress = useCallback(() => {
    onSelect && onSelect(selection);
  }, [onSelect, selection]);

  const onQueryChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setQuery(e.currentTarget.value);
    },
    [],
  );

  const searchCoaches = useCallback(
    (userName: string) => {
      clearTimeout(searchTimeout.current);
      setIsFetching(true);

      searchTimeout.current = setTimeout(() => {
        coachesStore
          .fetch({
            query: [
              [
                '_id',
                '!in',
                selection.concat(currentUserStore.user!).map(x => x._id),
              ],
              ['coach_title:2,name', 'fulltext', `${userName}*`],
            ],
          })
          .catch(() => {});
      }, 1000);
    },
    [coachesStore, currentUserStore.user, selection],
  );

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    coachesStore.clear();

    if (shouldSearch) {
      searchCoaches(query);
    } else {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachesStore, query, shouldSearch]);

  const onAddCoachButtonClick = useCallback(
    (coach: Coach) => {
      if (!selection.find(item => coach._id === item._id)) {
        setSelection(prev => [...prev, coach]);

        coachesStore.removeItem(coach);

        if (!coachesStore.hasItems) {
          coachesStore.clear();
          setQuery('');
        }
      }
    },
    [coachesStore, selection],
  );

  const onDeleteCoachButtonClick = useCallback(
    (coach: Coach) => {
      const index = selection.findIndex(item => item._id === coach._id);

      if (index >= 0) {
        setSelection(prev => {
          prev.splice(index, 1);

          coachesStore.addItem(coach, 0);

          return [...prev];
        });
      }
    },
    [coachesStore, selection],
  );

  return (
    <div className={classNames('SelectCoach', styles.Component)}>
      <TextField
        variant="search"
        onChange={onQueryChange}
        placeholder={t([I18N_SCOPE, 'text_input_placeholder'])}
      />
      {selection.length ? (
        <div className={styles.selection}>
          {selection.map(coach => (
            <div key={coach._id} className={styles.selectedCoach}>
              <div className={styles.avatar}>
                <Image
                  src={getFileSrc(coach.avatar).url || ''}
                  placeholder={<AvatarPlaceholder name={coach.name || ''} />}
                />
              </div>
              <p className={styles.name}>{coach.name}</p>
              <Button
                className={styles.deleteButton}
                onClick={() => onDeleteCoachButtonClick(coach)}
              >
                <CloseIcon />
              </Button>
            </div>
          ))}
        </div>
      ) : null}

      <div className={styles.resultsContainer}>
        {isFetching ? (
          <div>
            <Loader />
          </div>
        ) : coaches.length ? (
          coaches.map(coach => (
            <div
              key={coach._id}
              className={classNames(
                styles.foundCoach,
                canAcceptPayments && !coach.is_provider && styles.isntProvider,
              )}
            >
              <div className={styles.leftPart}>
                <div className={styles.avatar}>
                  <Image
                    src={getFileSrc(coach.avatar).url || ''}
                    placeholder={<AvatarPlaceholder name={coach.name || ''} />}
                  />
                </div>
                <div className={styles.nameContainer}>
                  <Link
                    className={styles.name}
                    to={`/coaches/${coach.slug || coach._id}`}
                    target="_blank"
                  >
                    {coach.name}
                  </Link>
                  {canAcceptPayments && !coach.is_provider ? (
                    <p className={styles.message}>
                      {t([I18N_SCOPE, 'isnt_provider_label'])}
                    </p>
                  ) : null}
                </div>
              </div>
              <Button
                className={styles.addButton}
                onClick={() => onAddCoachButtonClick(coach)}
                disabled={canAcceptPayments && !coach.is_provider}
              >
                <UserPlusIcon className={styles.addIcon} />
                {t([I18N_SCOPE, 'add_button'])}
              </Button>
            </div>
          ))
        ) : shouldSearch ? (
          <div className={styles.noResultsContainer}>
            <NoResultsHeader
              text={t([I18N_SCOPE, 'no_results_label'])}
              icon={SearchIcon}
            />
          </div>
        ) : null}
      </div>
      <div
        className={classNames(styles.footer, MODAL_STICKY_FOOTER_CLASS_NAME)}
      >
        <Button disabled={!selection.length} onClick={onFooterButtonPress}>
          {t([I18N_SCOPE, 'footer_button'])}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SelectCoach);
