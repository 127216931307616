import type {ChangeEvent, FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {
  Questionnaire as IQuestionnaire,
  QuestionnaireSection,
} from '@yourcoach/shared/api/questionnaire';

import {
  labelSearchForQuestionnaires,
  labelSectionTemplates,
  labelSeeTemplates,
  labelYouCanUseCompletedSections,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {CustomButton, CustomSearch} from '@src/components/CustomForm';
import FadeComponent from '@src/components/FadeComponent/FadeComponent';
import type {IQSectionWithFields} from '@src/modules/Questionnaire/context/qAppStore';
import QSectionTemplateList from '@src/modules/Questionnaire/QSectionTemplateList/QSectionTemplateList';

import styles from './styles.module.css';

interface Props {
  setSection: (section: IQSectionWithFields) => void;
}

interface ILocalStoreSectionTemplates {
  showFade: boolean;
  setShowFade: (newVal: boolean) => void;
  loader: boolean;
  setLoader(loader: boolean): void;
  query: string;
  setQuery: (newQuery: string) => void;
  questionnairesStore: CollectionStore<IQuestionnaire> | null;
  searchStore: CollectionStore<IQuestionnaire> | null;
  store: CollectionStore<IQuestionnaire> | null;
  storeParams: ApiRpcRequestParams;
  sectionList: QuestionnaireSection[];
  setSectionList(sectionList: QuestionnaireSection[]): void;
  getQuestFetch: () => void;
  searchTimeout?: number;
  setSearchTimeout(searchTimeout: number | undefined): void;
  isContainsQuery: (text: string) => boolean;
}

const AddTemplateSectionQ: FC<Props> = ({setSection}) => {
  const LIMIT = 50;
  const localStore: ILocalStoreSectionTemplates = useRef(
    observable<ILocalStoreSectionTemplates>(
      {
        showFade: false,
        setShowFade(newVal: boolean) {
          this.showFade = newVal;
        },
        searchTimeout: undefined,
        setSearchTimeout(searchTimeout: number | undefined) {
          this.searchTimeout = searchTimeout;
        },
        loader: false,
        setLoader(loader: boolean) {
          this.loader = loader;
        },
        store: null,
        query: '',
        setQuery(newQuery: string) {
          this.query = newQuery;
          this.storeParams = {query: [['is_public', '==', true]]};
          this.store = this.questionnairesStore;

          if (this.query.length >= 1) {
            this.storeParams.query.push([
              'sections.title:2,sections.description',
              'fulltext',
              `${this.query}*`,
            ]);
            this.store = this.searchStore;
          }

          this.getQuestFetch();
        },
        questionnairesStore: null,
        searchStore: null,
        storeParams: {},
        sectionList: [],
        setSectionList(sectionList: QuestionnaireSection[]) {
          this.sectionList = sectionList;
        },
        isContainsQuery(text: string) {
          return (
            text.toUpperCase().indexOf(localStore.query.toUpperCase()) !== -1
          );
        },
        async getQuestFetch() {
          this.setLoader(true);

          try {
            await this.store.fetch(this.storeParams, {
              override: true,
            });

            const questionnairesList: IQuestionnaire[] =
              this.store.items.slice();

            this.setSectionList([]);

            questionnairesList.forEach(questionnaire => {
              const sections = questionnaire.sections.slice();

              if (localStore.query.length >= 2) {
                sections.forEach(section => {
                  const addSections: QuestionnaireSection[] = [];

                  if (this.isContainsQuery(section.title)) {
                    addSections.push(section);
                  } else if (this.isContainsQuery(section.description)) {
                    addSections.push(section);
                  } else if (
                    section.questions.some(item =>
                      this.isContainsQuery(item.question),
                    )
                  ) {
                    addSections.push(section);
                  }

                  this.setSectionList(this.sectionList.concat(addSections));
                });
              } else {
                this.setSectionList(this.sectionList.concat(sections));
              }
            });
          } catch (error) {
            if (error.canceled) {
              return;
            }

            setError(error);
          }

          this.setLoader(false);
        },
      },
      {
        showFade: observable,
        setShowFade: action,
        query: observable,
        setQuery: action,
        searchTimeout: observable,
        setSearchTimeout: action,
        loader: observable,
        setLoader: action,
        storeParams: observable,
        sectionList: observable,
        setSectionList: action,
        questionnairesStore: observable,
        searchStore: observable,
        store: observable,
        getQuestFetch: action,
        isContainsQuery: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.questionnairesStore = createCollectionStore({
      method: 'coach.questionnaires.examples.list',
      params: {
        limit: LIMIT,
        sort: [['created', -1]],
      },
    });

    localStore.searchStore = createCollectionStore({
      method: 'coach.questionnaires.examples.search',
      params: {
        limit: LIMIT,
      },
    });

    localStore.setQuery('');

    return () => {
      if (localStore.store) {
        localStore.store.clear();
      }

      if (localStore.searchStore) {
        localStore.searchStore.clear();
      }

      if (localStore.questionnairesStore) {
        localStore.questionnairesStore.clear();
      }

      clearTimeout(localStore.searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFadeOpen = () => {
    localStore.setShowFade(true);
  };

  const handleOnSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;

    if (localStore.searchTimeout) {
      clearTimeout(localStore.searchTimeout);
    }

    localStore.setSearchTimeout(
      +setTimeout(() => {
        localStore.setQuery(val);
      }, 1000),
    );
  };

  return (
    <Observer>
      {() => (
        <div className={`AddTemplateSectionQ ${styles.AddTemplateSectionQ}`}>
          <div className={styles.header}>
            <div>
              <h3>{labelSectionTemplates()}</h3>
              <div className={styles.description}>
                {labelYouCanUseCompletedSections()}
              </div>
            </div>
            <div className={styles.seeTmplButtContainer}>
              {localStore.showFade ? (
                <CustomSearch
                  label={labelSearchForQuestionnaires()}
                  id="searchFile"
                  onChange={handleOnSearchChange}
                  classContainer="searchContainer"
                  classInput="input"
                  className="search"
                  classIcon="icon"
                  classIconContainer="iconContainer"
                />
              ) : (
                <CustomButton
                  type="button"
                  classButton={`greenBorderButt ${styles.butSeeTmpl}`}
                  onClick={handleFadeOpen}
                >
                  {labelSeeTemplates()}
                </CustomButton>
              )}
            </div>
          </div>
          <div className={styles.body}>
            <FadeComponent show={localStore.showFade}>
              <QSectionTemplateList
                isInModal
                setSection={setSection}
                className={
                  localStore.showFade ? 'showFadeTrue' : 'showFadeFalse'
                }
                sectionList={localStore.sectionList.slice()}
              />
            </FadeComponent>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(AddTemplateSectionQ);
