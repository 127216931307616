import type {FC} from 'react';
import React, {memo, useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import {apiRequest} from '@yourcoach/shared/api';
import type {UserQuestionnaire} from '@yourcoach/shared/api/questionnaire';

import {labelBack} from '@src/common/i18n/i18nCommon';
import {labelClientsHelped} from '@src/common/i18n/i18nPrograms';
import {
  labelStart,
  labelUser,
  labelYourcoach,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton} from '@src/components/CustomForm';
import {IconPrev} from '@src/components/icons';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {ColorWrapper} from '@src/layouts';
import type {UserQuestionnaireExpanded} from '@src/models/questionnaire';
import {userQuestionnaireExpand} from '@src/models/questionnaire';
import styles from '@src/modules/Questionnaire/QDetail/styles.module.css';
import {PATH_TYPE} from '@src/routes/utils';

import stylesQUserAbout from './styles.module.css';

interface Props {
  history: any;
}

const QUserAbout: FC<Props> = ({history}) => {
  const nameGetParam = 'id';
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const [userQ, setUserQ] = useState<UserQuestionnaire | null>(null);
  const [coachExpandedQ, setCoachExpandedQ] =
    useState<UserQuestionnaireExpanded | null>(null);
  const redirect = useAppRedirect();
  const QUserId = useURLQueryParam(nameGetParam);

  useEffect(() => {
    const getUserQ = async () => {
      try {
        const {user_questionnaire, _expanded} = await apiRequest({
          method: 'client.questionnaires.read',

          params: {
            _id: QUserId,
            expand: userQuestionnaireExpand,
          },
        });

        setUserQ(user_questionnaire);

        const retExpanded: UserQuestionnaireExpanded = {
          expanded_coaches: [],
        };

        for (let el in _expanded) {
          if (el.indexOf('user') !== -1) {
            retExpanded.expanded_coaches.push(_expanded[el]);
          }

          if (el.indexOf('file') !== -1) {
            retExpanded.avatar = _expanded[el];
          }
        }

        setCoachExpandedQ(retExpanded);
      } catch (error) {
        setError(error.message);
      }
    };

    getUserQ();

    return () => {};
  }, [QUserId]);

  const handleClickBack = () => {
    history.goBack();
  };

  const handleClickStartQ = () => {
    redirect(
      `/${PATH_TYPE.userQuestionnaire}/progress?${nameGetParam}=${QUserId}`,
    );
  };

  return (
    <div className="QUserAbout">
      <ColorWrapper containerClass="pl50">
        {currentUserStore.user && (
          <CustomButton
            type="button"
            classButton="QuestionnaireHeaderBut"
            onClick={handleClickBack}
          >
            <IconPrev customClass="IconPrev" />
            <span>{labelBack()}</span>
          </CustomButton>
        )}
      </ColorWrapper>
      {userQ && (
        <ColorWrapper containerClass="pl50">
          <div className={`${styles.header} ${stylesQUserAbout.header}`}>
            <div className={styles.tittleContainer}>
              <div className={styles.title}>{userQ?.title}</div>
              <div className={styles.user}>
                {userQ?.user_id ? labelUser() : labelYourcoach()}
              </div>
            </div>
            <div className={styles.childrenContainer}>
              {!userQ.answered && (
                <CustomButton
                  type="button"
                  classButton="standardButton borderRadius10"
                  onClick={handleClickStartQ}
                >
                  {labelStart()}
                </CustomButton>
              )}
            </div>
          </div>
          <div className={stylesQUserAbout.body}>
            <div className={styles.description}>{userQ.description}</div>
            {coachExpandedQ && (
              <div>
                <div className={stylesQUserAbout.coachContainer}>
                  <div className={stylesQUserAbout.coachAvatar}>
                    <OtherUserProfileImg avatar={coachExpandedQ.avatar} />
                  </div>
                  <div className={stylesQUserAbout.nameContainer}>
                    <div className={stylesQUserAbout.coachName}>
                      {coachExpandedQ.expanded_coaches[0].name}
                    </div>
                    <div className={stylesQUserAbout.coachFollowingCount}>
                      {
                        coachExpandedQ.expanded_coaches[0].counters?.following
                          .joined
                      }{' '}
                      {coachExpandedQ.expanded_coaches[0].counters?.following
                        .joined! >= 0 &&
                        labelClientsHelped(
                          coachExpandedQ.expanded_coaches[0].counters?.following
                            .joined!,
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ColorWrapper>
      )}
    </div>
  );
};

export default memo(withRouter(QUserAbout));
