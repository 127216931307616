import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo, useRef} from 'react';
import InView from 'react-intersection-observer';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable, runInAction} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import type {Expand} from '@yourcoach/shared/api';
import {
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {
  Subgoal,
  SubgoalColor,
  SubgoalIcon,
  SubgoalType,
} from '@yourcoach/shared/api/goal';
import {
  getSubgoalColor,
  getSubgoalIcon,
  getSubgoalUnitTypes,
  SubgoalTypeEnum,
} from '@yourcoach/shared/api/goal';
import {themes} from '@yourcoach/shared/styles/theme';
import {logger} from '@yourcoach/shared/utils/logger';
import type {Field} from '@yourcoach/shared/utils/validation';
import {
  createField,
  getTitleSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';

import {getCustomConfirmAlert} from '../../../components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomInput, CustomSelect} from '../../../components/CustomForm';
import type {IOption} from '../../../components/CustomForm/CustomSelect/CustomSelect';
import DayPickerRangeControllerWrapper from '../../../components/DayPickerRangeControllerWrapper/DayPickerRangeControllerWrapper';
import ModalAnimateWin from '../../../components/GoalsModal/GoalsModal';
import {IconTrash} from '../../../components/icons';
import ScrollToElement from '../../../components/ScrollToElement/ScrollToElement';
import AppContext from '../../../context/App';
import SelectGoalColor from '../SelectGoalColor/SelectGoalColor';
import SelectGoalIcon from '../SelectGoalIcon/SelectGoalIcon';

import styles from './../styles.module.css';

const minMax = require('dayjs/plugin/minMax');

dayjs.extend(minMax);

const MAX_STEPS = 30;

const expand: Expand = {
  subgoal: ['course_id'],
};

type SubgoalExpanded = Subgoal & {
  course?: Course | null;
};

type Step = {
  id: string;
  fields: {
    title: IField;
  };
  remove: () => void;
};

interface IField extends Field {
  value: string;
  ref?: React.RefObject<HTMLInputElement>;
  onChangeText?: (text: string) => void;
}

interface Props {
  goalId: string;
  forClient?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isClientSubgoal?: boolean;
  subgoal?: Subgoal | null;
  onSuccess?: (subgoal: Subgoal) => void;
  onCloseModal?: () => void;
}

const CRUSubgoal: FC<Props> = ({
  goalId,
  forClient,
  isClientSubgoal,
  minDate,
  subgoal,
  maxDate,
  onSuccess,
  onCloseModal,
}) => {
  const smallDevice = window.matchMedia('(max-width: 799px)').matches;
  const orientation = smallDevice ? 'vertical' : 'horizontal';
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const textFields = createField('title', {
    validationRule: getTitleSchema(),
  }) as IField;

  textFields.ref = useRef(null);

  const unitFields = createField('unit', {
    validationRule: {
      type: 'string',
      max: 255,
      messages: {
        required: 'Unit is required',
        stringMax: 'Unit may not be longer than 255 characters',
      },
    },
  }) as IField;

  unitFields.ref = useRef(null);
  unitFields.onChangeText = (text: string) => {
    runInAction(() => {
      localStore.fields.unit.value = text;
      localStore.fields.unit.validate();
    });
  };

  const targetValueFields = createField('targetValue', {
    validationRule: {
      type: 'string',
    },
    customValidate: action(() => {
      if (localStore.fields.targetValue.validationRule!.optional) {
        localStore.fields.targetValue.error = null;

        return true;
      }

      const field = localStore.fields.targetValue;

      let isValid = true;

      field.error = null;

      if (!localStore.fields.targetValue.value) {
        field.error = 'Unit is required';
        isValid = false;
      } else if (Number(localStore.fields.targetValue.value) <= 0) {
        field.error = 'Amount must be greater than zero';
        isValid = false;
      }

      field.isValid = isValid;

      return isValid;
    }),
  }) as IField;

  targetValueFields.ref = useRef(null);
  targetValueFields.onChangeText = (text: string) => {
    runInAction(() => {
      localStore.fields.targetValue.value = text;
      localStore.fields.targetValue.validate();
    });
  };

  const localStore: any = useMemo(
    () =>
      observable<any>(
        {
          icon: null,
          color: null,
          type: SubgoalTypeEnum.CHECKLIST,
          steps: [],
          dateRange: [minDate ? minDate : dayjs().toDate()],
          dateRangeError: null,
          dateRangeIsDisabled: false,
          isOneDayDateRange: false,
          isCreatingMode: true,
          isShowModalSelectGoalIcon: false,
          setIsShowModalSelectGoalIcon(isShowModalSelectGoalIcon: boolean) {
            this.isShowModalSelectGoalIcon = isShowModalSelectGoalIcon;
          },
          isShowModalSelectGoalColor: false,
          setIsShowModalSelectGoalColor(isShowModalSelectGoalColor: boolean) {
            this.isShowModalSelectGoalColor = isShowModalSelectGoalColor;
          },
          rangeDataListOptions: [],
          setRangeDataListOptions(rangeDataListOptions: IOption[]) {
            this.rangeDataListOptions = rangeDataListOptions;
          },
          isShowModalRangeDate: false,
          setIsShowModalRangeDate(isShowModalRangeDate: boolean) {
            this.isShowModalRangeDate = isShowModalRangeDate;
          },
          fields: {
            title: textFields,
            unit: unitFields,
            targetValue: targetValueFields,
          },
          _setType(type: SubgoalType) {
            this.type = type;
          },
          async _addStep(title?: string, id?: string) {
            const uuid = id || uuidv4().replace(/-/g, '');

            if (this.steps.length >= MAX_STEPS) {
              return;
            }

            const stepField: IField = createField('title', {
              defaultValue: title || '',
              validationRule: {
                max: 255,
                type: 'string',
                messages: {
                  required: 'Step is required',
                  stringMax: 'Step may not be longer than 255 characters',
                },
              },
            });

            stepField.onChangeText = function (text: string) {
              runInAction(() => {
                (this as IField).value = text;
              });

              (this as IField).validate();
            };
            stepField.ref = React.createRef();

            this.steps.push({
              id: uuid,
              fields: {
                title: stepField,
              },
              remove: action(() => {
                const removeIndex = this.steps.findIndex(
                  step => step.id === uuid,
                );

                if (removeIndex >= 0) {
                  this.steps.splice(removeIndex, 1);
                }

                if (!this.steps.length) {
                  this._addStep('');
                }
              }),
            });

            return Promise.resolve();
          },
          _setColor(color: SubgoalColor) {
            this.color = color;
          },

          _setIcon(icon: SubgoalIcon) {
            this.icon = icon;
          },
          _getSubgoalData(): () => any {
            const data: any = {
              title: this.fields.title.value,
              description: '',
              icon: this.icon,
              color: this.color,
              start_date: ISOStringToDatetimeObj(
                this.dateRange[0].toISOString(),
              ),
              end_date: ISOStringToDatetimeObj(
                this.dateRange[1]!.toISOString(),
              ),
            };

            if (this.isCreatingMode) {
              data.goal_id = goalId;
            }

            if (this.type.id === SubgoalTypeEnum.METRIC.id) {
              data.unit = this.fields.unit.value;
              data.initial_value = 0;
              data.target_value = Number(this.fields.targetValue.value);
            }

            if (this.type.id === SubgoalTypeEnum.CHECKLIST.id) {
              if (this.isCreatingMode) {
                data.checkpoints = this.steps.map(
                  step => step.fields.title.value,
                );
              } else {
                data.checkpoints = this.steps.map(step => ({
                  uuid: step.id,
                  title: step.fields.title.value,
                }));
              }
            }

            return data;
          },
          async _createSubgoal() {
            try {
              const data = this._getSubgoalData();

              if (forClient) {
                await goalStore.sub.client.own.create({
                  ...data,
                  expand,
                });
              } else {
                const createdSubgoal = (await goalStore.sub.create({
                  ...data,
                  expand,
                })) as Subgoal & SubgoalExpanded;

                if (
                  createdSubgoal.course &&
                  createdSubgoal.course.status === 'ongoing'
                ) {
                  await goalStore.coach.bound.synchronize({
                    course_id: createdSubgoal.course_id,
                  });
                }
              }
            } catch (error) {
              // TODO: log error
            }
          },
          async _updateSubgoal() {
            try {
              const data = this._getSubgoalData();

              if (forClient) {
                await goalStore.sub.client.own.update(subgoal!, {
                  ...data,
                  expand,
                });
              } else if (isClientSubgoal) {
                await goalStore.sub.coach.bound.update(subgoal!, {
                  ...data,
                  expand,
                });
              } else {
                const updatedSubgoal = (await goalStore.sub.update(subgoal!, {
                  ...data,
                  expand,
                })) as Subgoal & SubgoalExpanded;

                if (
                  updatedSubgoal.course &&
                  updatedSubgoal.course.status === 'ongoing'
                ) {
                  await goalStore.coach.bound.synchronize({
                    course_id: updatedSubgoal.course_id,
                  });
                }
              }
            } catch (error) {
              getCustomConfirmAlert({
                message: error.message,
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {},
                  },
                ],
              });
            }
          },
          async _onFooterButtonPress() {
            this.fields.unit.validationRule!.optional =
              this.type.id === SubgoalTypeEnum.CHECKLIST.id;
            this.fields.targetValue.validationRule!.optional =
              this.type.id === SubgoalTypeEnum.CHECKLIST.id;

            const fieldsAreValid = validateFields(this.fields);

            let stepsAreValid = true;

            if (this.type.id === SubgoalTypeEnum.CHECKLIST.id) {
              this.steps.forEach(step => {
                if (!validateFields(step.fields)) {
                  stepsAreValid = false;
                }
              });
            }

            const dateRangeIsValid = this.dateRange.length === 2;

            if (!dateRangeIsValid) {
              this.dateRangeError = 'Date range is required';

              return;
            }

            if (fieldsAreValid && stepsAreValid && dateRangeIsValid) {
              if (onSuccess) {
                const uuid = uuidv4().replace(/-/g, '');

                onSuccess({
                  ...subgoal,
                  ...this._getSubgoalData(),
                  _id: subgoal && subgoal._id ? subgoal._id : uuid,
                } as Subgoal);

                return;
              }

              if (this.isCreatingMode) {
                this._createSubgoal();
              } else {
                this._updateSubgoal();
              }

              if (onCloseModal) {
                onCloseModal();
              }
            } else {
              const allFields = [this.fields];

              if (this.type.id === SubgoalTypeEnum.CHECKLIST.id) {
                // @ts-ignore
                this.steps.forEach(step => allFields.push(step.fields));
              }

              allFields.some(item =>
                Object.keys(localStore.fields)
                  .map(key => item[key])
                  .some(field => {
                    if (!field.validate()) {
                      return true;
                    }

                    return false;
                  }),
              );
            }
          },
          _onUnitPress(unit: string) {
            if (this.fields.unit) {
              this!.fields!.unit!.setValue(unit);
            }
          },
        },
        {
          icon: observable,
          color: observable,
          steps: observable,
          type: observable,
          dateRangeError: observable,
          dateRange: observable,
          isOneDayDateRange: observable,
          dateRangeIsDisabled: observable,
          isCreatingMode: observable,
          fields: observable.shallow,
          _setType: action,
          _setColor: action,
          _setIcon: action,
          _addStep: action,
          _onFooterButtonPress: action,
          _onUnitPress: action,
          rangeDataListOptions: observable,
          setRangeDataListOptions: action,
          isShowModalRangeDate: observable,
          setIsShowModalRangeDate: action,
          isShowModalSelectGoalIcon: observable,
          setIsShowModalSelectGoalIcon: action,
          isShowModalSelectGoalColor: observable,
          setIsShowModalSelectGoalColor: action,
        },
      ),
    [
      forClient,
      goalId,
      goalStore.coach.bound,
      goalStore.sub,
      isClientSubgoal,
      minDate,
      onCloseModal,
      onSuccess,
      subgoal,
      targetValueFields,
      textFields,
      unitFields,
    ],
  );

  useEffect(() => {
    runInAction(() => {
      localStore.fields.title.value = '';
      localStore.fields.unit.value = '';
      localStore.fields.targetValue.value = '';
    });

    if (subgoal && subgoal._id) {
      runInAction(() => {
        localStore.fields.title.value = subgoal.title || '';
        localStore.fields.unit.value = subgoal.unit || '';
        localStore.fields.targetValue.value = `${subgoal.target_value || ''}`;

        localStore.icon = subgoal.icon as SubgoalIcon;
        localStore.color = subgoal.color as SubgoalColor;

        localStore.type = subgoal.checkpoints.length
          ? SubgoalTypeEnum.CHECKLIST
          : SubgoalTypeEnum.METRIC;

        subgoal.checkpoints.forEach(checkpoint => {
          localStore._addStep(checkpoint.title, checkpoint.uuid);
        });

        localStore.dateRange = [
          dayjs(datetimeObjToISOString(subgoal.start_date)).toDate(),
          dayjs(datetimeObjToISOString(subgoal.end_date)).toDate(),
        ];

        localStore.isCreatingMode = false;
      });

      if (minDate && maxDate) {
        localStore.setRangeDataListOptions([
          {
            id: `${minDate}-${maxDate}`,
            val: `${dayjs(minDate).format('ll')}-${dayjs(maxDate).format(
              'll',
            )}`,
          },
        ]);
      }
    }

    if (
      minDate &&
      maxDate &&
      dayjs(minDate).isSame(dayjs(maxDate).subtract(1, 'day'), 'day')
    ) {
      runInAction(() => {
        localStore.dateRange = [minDate, maxDate];

        localStore.isOneDayDateRange = true;
        localStore.dateRangeIsDisabled = true;
      });
    }

    if (
      localStore.dateRange.length === 2 &&
      dayjs(localStore.dateRange[0]).isSame(
        dayjs(localStore.dateRange[1]).subtract(1, 'day'),
        'day',
      )
    ) {
      runInAction(() => {
        localStore.isOneDayDateRange = true;
      });
    }

    if (!localStore.steps.length) {
      localStore._addStep('');
    }

    localStore.fields.title.ref?.current?.focus();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTitleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const text = event?.currentTarget.value as string;

    runInAction(() => {
      localStore.fields.title.value = text;
    });

    localStore.fields.title.validate();
  };

  const handleOnClickSelectData = () => {
    localStore.setIsShowModalRangeDate(true);
  };

  const handleCloseModalRangeDate = () => {
    localStore.setIsShowModalRangeDate(false);
  };

  const handleChangeDate = (startDate: Date, endDate: Date) => {
    localStore.setIsShowModalRangeDate(false);

    runInAction(() => {
      let dateRange1 = dayjs(startDate);
      let dateRange2 = dayjs(endDate);

      const dMinDate = dayjs(minDate);
      const dMaxDate = dayjs(maxDate);

      dateRange1 =
        dateRange1 < dMinDate || dateRange1 > dMaxDate ? dMinDate : dateRange1;
      dateRange2 = dateRange2 > dMaxDate ? dMaxDate : dateRange2;

      if (dateRange1 > dateRange2) {
        dateRange2 = dateRange1;
      }

      if (dateRange2.isSame(dateRange1, 'date')) {
        dateRange2 = dateRange2.endOf('day');
      }

      localStore.dateRange = [dateRange1.toDate(), dateRange2.toDate()];
      localStore.isOneDayDateRange = false;
      localStore.dateRangeError = null;
    });

    localStore.setRangeDataListOptions([
      {
        id: `${startDate}-${endDate}`,
        val: `${dayjs(startDate).format('ll')}-${dayjs(endDate).format('ll')}`,
      },
    ]);
  };

  const handleOnClickType = type => () => localStore._setType(type);

  const handleTitleStepOnChange = (step: Step) => {
    return (event?: React.ChangeEvent<HTMLInputElement>) => {
      const text = event?.currentTarget.value as string;

      if (step.fields.title.onChangeText) {
        step.fields.title.onChangeText(text);
      }
    };
  };

  const handleOnAddStep = async () => {
    await localStore._addStep('');

    localStore.steps[
      localStore.steps.length - 1
    ].fields.title.ref?.current?.focus();
  };

  const handleUnitOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const text = event?.currentTarget.value as string;

    // @ts-ignore
    localStore.fields.unit.onChangeText(text);
  };

  const handleOnClickCategory = (unit: string) => {
    return () => {
      localStore._onUnitPress(unit);
    };
  };

  const handleTargetValueOnChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const text = event?.currentTarget.value as string;

    // @ts-ignore
    localStore.fields.targetValue.onChangeText(text);
  };

  const handleCloseModalSelectGoalIcon = () => {
    localStore.setIsShowModalSelectGoalIcon(false);
  };

  const _onChooseIconButtonPress = () => {
    localStore.setIsShowModalSelectGoalIcon(true);
  };

  const handleOnSelect = (icon: SubgoalIcon) => {
    localStore.setIsShowModalSelectGoalIcon(false);
    localStore._setIcon(icon);
  };

  const _onChooseColorButtonPress = () => {
    localStore.setIsShowModalSelectGoalColor(true);
  };

  const handleCloseModalSelectGoalColor = () => {
    localStore.setIsShowModalSelectGoalColor(false);
  };

  const handleOnSelectColor = (color: SubgoalColor) => {
    localStore.setIsShowModalSelectGoalColor(false);
    localStore._setColor(color);
  };

  const handleOnClickFooterBut = () => {
    if (localStore.type.id === 'checklist') {
      logger.event('finish_goal_creation_tap', {type: 'checklist'});
    }

    if (localStore.type.id === 'metric') {
      logger.event('finish_goal_creation_tap', {type: 'measurement'});
    }

    localStore._onFooterButtonPress();
  };

  return (
    <Observer>
      {() => {
        const Icon = getSubgoalIcon(localStore.icon);
        const bgColor = getSubgoalColor(localStore.color, themes.light);

        return (
          <div className={`CRUSubgoal ${styles.CRUSubgoal}`}>
            <div className={styles.chooseContainer}>
              <div className={styles.iconContainer}>
                <div className={styles.label}>Choose icon</div>
                <div
                  className={styles.chooseIconContainer}
                  onClick={_onChooseIconButtonPress}
                >
                  <Icon fill={'#819AA9'} />
                </div>
              </div>
              <div className={styles.colorContainer}>
                <div className={styles.label}>Choose color</div>
                <div
                  className={styles.chooseColorContainer}
                  onClick={_onChooseColorButtonPress}
                  style={{background: bgColor}}
                />
              </div>
            </div>
            <div>
              <div className={styles.label}>Short-term goal name</div>
              <CustomInput
                type="input"
                label="Name"
                id="name"
                hideLabel
                customClassError="noPadding"
                customClassLabel="QStandardLabel"
                customClassCont="QStandardContainer"
                customClassInput="QStandardInput"
                ref={localStore.fields.title.ref}
                value={localStore.fields.title.value}
                onChange={handleTitleOnChange}
                error={localStore.fields.title.error}
                showErrorImmediately
              />
            </div>
            <div className={styles.dateRangeContainer}>
              <div className={styles.label}>Date range</div>
              <CustomSelect
                options={localStore.rangeDataListOptions}
                onSelect={() => {}}
                customClassError="noPadding"
                onClick={handleOnClickSelectData}
                isCalendar
                error={
                  localStore.dateRangeError ? localStore.dateRangeError : ''
                }
                label={
                  localStore.rangeDataListOptions.slice()[0] ? '' : 'Select'
                }
                defaultId={
                  localStore.rangeDataListOptions.slice()[0]
                    ? localStore.rangeDataListOptions.slice()[0].id
                    : ''
                }
                className={'qListStyle'}
                disabled={true}
              />
            </div>
            <div className={styles.typeContentContainer}>
              <div className={styles.label}>Type</div>
              {!localStore.isCreatingMode ? (
                <div className={styles.label}>{localStore.type.toString()}</div>
              ) : null}
              {localStore.isCreatingMode ? (
                <div className={styles.typesContainer}>
                  {Object.keys(SubgoalTypeEnum).map(key => {
                    const type = SubgoalTypeEnum[key];

                    const isSelected = type.id === localStore.type.id;

                    return (
                      <div key={type.id} onClick={handleOnClickType(type)}>
                        <div
                          className={`${styles.typeContainer} ${
                            isSelected ? styles.active : ''
                          }`}
                        >
                          {type.toString()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            {localStore.type.id === SubgoalTypeEnum.CHECKLIST.id ? (
              <div className={styles.section}>
                <Scrollbar
                  className="scrollbar"
                  noScrollX
                  wrapperProps={{className: 'wrapper'}}
                  trackYProps={{className: 'trackY'}}
                  thumbYProps={{className: 'thumbY'}}
                  trackXProps={{className: 'trackX'}}
                  thumbXProps={{className: 'thumbY'}}
                >
                  {localStore.steps.slice().map((step, index) => {
                    return (
                      <div key={step.id} className={styles.stepContainer}>
                        <div className={styles.radioIconContainer}>
                          <div className={styles.radioIcon} />
                        </div>
                        <div className={styles.inputContainer}>
                          <CustomInput
                            type="input"
                            label={`Step option number: ${index + 1}`}
                            id={step.id}
                            ref={step.fields.title.ref}
                            hideLabel
                            customClassError="noPadding"
                            customClassLabel="QStandardLabel"
                            customClassCont="QStandardContainer"
                            customClassInput={`QStandardInput ${styles.input}`}
                            value={step.fields.title.value}
                            onChange={handleTitleStepOnChange(step)}
                            error={
                              step.fields.title.error
                                ? step.fields.title.error
                                : ''
                            }
                            showErrorImmediately
                          />
                        </div>

                        {localStore.steps.length > 1 ? (
                          <div
                            onClick={step.remove}
                            className={styles.trashIconContainer}
                          >
                            <IconTrash />
                          </div>
                        ) : null}
                        {index === localStore.steps.length - 1 ? (
                          <InView as="div">
                            <ScrollToElement />
                          </InView>
                        ) : null}
                      </div>
                    );
                  })}
                  {localStore.steps.length < MAX_STEPS ? (
                    <div
                      className={styles.AddStepBtn}
                      onClick={handleOnAddStep}
                    >
                      <span>Add Step</span>
                    </div>
                  ) : null}
                </Scrollbar>
              </div>
            ) : null}

            {localStore.type.id === SubgoalTypeEnum.METRIC.id ? (
              <>
                <div className={styles.sectionMetric}>
                  <div className={styles.label}>Measured in</div>
                  <CustomInput
                    type="input"
                    label="Unit"
                    id="sectionMetric"
                    hideLabel
                    customClassError="noPadding"
                    customClassLabel="QStandardLabel"
                    customClassCont="QStandardContainer"
                    customClassInput="QStandardInput"
                    ref={localStore.fields.unit.ref}
                    value={localStore.fields.unit.value}
                    onChange={handleUnitOnChange}
                    error={
                      localStore.fields.unit.error
                        ? localStore.fields.unit.error
                        : ''
                    }
                    showErrorImmediately
                  />
                </div>
                <div className={styles.sectionSubgoalCategory}>
                  <div className={styles.categoriesContainer}>
                    {getSubgoalUnitTypes().map(unit => {
                      const isSelected =
                        localStore.fields.unit.value.toLowerCase() ===
                        unit.toLowerCase();

                      return (
                        <div
                          onClick={handleOnClickCategory(unit)}
                          className={`${styles.category} ${
                            isSelected ? styles.selected : ''
                          }`}
                          key={unit}
                        >
                          <span>{unit}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.sectionMetricsInput}>
                  <div className={styles.label}>Amount</div>
                  <CustomInput
                    type="input"
                    label="Amount"
                    id="sectionMetric"
                    hideLabel
                    customClassError="noPadding"
                    customClassLabel="QStandardLabel"
                    customClassCont="QStandardContainer"
                    customClassInput="QStandardInput"
                    ref={localStore.fields.targetValue.ref}
                    value={localStore.fields.targetValue.value}
                    onChange={handleTargetValueOnChange}
                    error={
                      localStore.fields.targetValue.error
                        ? localStore.fields.targetValue.error
                        : ''
                    }
                    showErrorImmediately
                  />
                </div>
              </>
            ) : null}

            <div className={styles.createButContainer}>
              <div
                className={styles.CreateBtn}
                onClick={handleOnClickFooterBut}
              >
                <span>{localStore.isCreatingMode ? 'Create' : 'Save'}</span>
              </div>
            </div>
            <ModalAnimateWin
              showModal={localStore.isShowModalRangeDate}
              closeModalHandler={handleCloseModalRangeDate}
              className="greyHeaderContainer List"
              isBody
              classNameBody="whiteBody maxContent"
              header="Choose period"
              classNameHeader="greyHeader"
              classNameCloseBut="greyHeaderBut"
            >
              <DayPickerRangeControllerWrapper
                changeDate={handleChangeDate}
                minDate={dayjs
                  .min(
                    dayjs(localStore.dateRange[0]),
                    minDate ? dayjs(minDate) : dayjs(),
                  )
                  .toDate()}
                maxDate={maxDate}
                orientation={orientation}
                withPortal={smallDevice}
              />
            </ModalAnimateWin>
            <ModalAnimateWin
              showModal={localStore.isShowModalSelectGoalIcon}
              closeModalHandler={handleCloseModalSelectGoalIcon}
              className="greyHeaderContainer littleContainer w610 List"
              isBody
              classNameBody="whiteBody maxContent noP30"
              header="Choose icon"
              classNameHeader="greyHeader w610"
              classNameCloseBut="greyHeaderBut"
            >
              <SelectGoalIcon
                onSelect={handleOnSelect}
                icon={localStore.icon}
                color={localStore.color}
              />
            </ModalAnimateWin>
            <ModalAnimateWin
              showModal={localStore.isShowModalSelectGoalColor}
              closeModalHandler={handleCloseModalSelectGoalColor}
              className="greyHeaderContainer littleContainer w440 List"
              isBody
              classNameBody="whiteBody maxContent noP30"
              header="Choose color"
              classNameHeader="greyHeader w440"
              classNameCloseBut="greyHeaderBut"
            >
              <SelectGoalColor
                onSelect={handleOnSelectColor}
                color={localStore.color}
              />
            </ModalAnimateWin>
          </div>
        );
      }}
    </Observer>
  );
};

export default memo(CRUSubgoal);
