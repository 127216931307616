import {
  labelChats,
  labelCoaches,
  labelCommunity,
  labelConference,
  labelDashboard,
  labelGoals,
  labelMyCoaches,
  labelProfile,
  labelToDos,
} from '@src/common/i18n/i18nMainMenu';
import {
  labelCreateQuestionnaire,
  labelQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';

import {
  labelJoinedProgramsPathName,
  labelLibraryPathName,
  labelMySquadsPathName,
  labelSessionNotesPathName,
  labelToolboxPathName,
  labelUserProfileTabPathName,
} from '../common/i18n/i18nCommon';

export const PATH_TYPE = {
  about: 'about',
  actionAuth: 'action/auth',
  tasks: 'tasks',
  chats: 'chats',
  coaches: 'coaches',
  community: 'community',
  conference: 'conferences',
  connectStripe: 'connect-stripe',
  createQuestionnaire: 'create-questionnaire',
  goals: 'goals',
  login: 'login',
  myCalendar: 'my-calendar', // v2
  myCoaches: 'my-coaches',
  myJoinedPrograms: 'my-joined-programs',
  myLibrary: 'my-library',
  mySessionNotes: 'my-notes',
  myToolbox: 'my-toolbox',
  notifications: 'notifications',
  onboarding: 'onboarding',
  practice: 'practice',
  profile: 'profile',
  program: 'program',
  questionnaire: 'questionnaire',
  questions: 'questions',
  recaptchaTest: 'recaptcha-test',
  singleEventPage: 'event-page', // v2
  standardTemplateDetail: 'standard-template-detail',
  status: 'status',
  toDos: 'to-dos', // v2
  unsubscribe: 'unsubscribe-mess',
  unsubscribeMail: 'unsubscribe',
  userProfile: 'user-profile',
  userProfileTab: 'user-profile-tab',
  userQuestionnaire: 'user-questionnaire',
  yourSpace: 'your-space',
  widget: 'widget',
};

export const PATH_TITLE = {
  [PATH_TYPE.practice]: labelMySquadsPathName,
  [PATH_TYPE.userProfileTab]: labelUserProfileTabPathName,
  [PATH_TYPE.myLibrary]: labelLibraryPathName,
  [PATH_TYPE.mySessionNotes]: labelSessionNotesPathName,
  [PATH_TYPE.myJoinedPrograms]: labelJoinedProgramsPathName,
  [PATH_TYPE.myToolbox]: labelToolboxPathName,
  [PATH_TYPE.coaches]: labelCoaches,
  [PATH_TYPE.community]: labelCommunity,
  [PATH_TYPE.myCoaches]: labelMyCoaches,
  [PATH_TYPE.yourSpace]: labelDashboard,
  [PATH_TYPE.conference]: labelConference,
  [PATH_TYPE.toDos]: labelToDos,
  [PATH_TYPE.goals]: labelGoals,
  [PATH_TYPE.chats]: labelChats,
  [PATH_TYPE.questionnaire]: labelQuestionnaire,
  [PATH_TYPE.profile]: labelProfile,
  [PATH_TYPE.createQuestionnaire]: labelCreateQuestionnaire,
};

export const getPathTitle = (path: string) => {
  const pathTitle = PATH_TITLE[path];
  const title = pathTitle ? pathTitle() : unSlugify(path);

  return title;
};

export const slugify = (name: string) => {
  const nameStr = name.replace(/#\S*/g, '');

  return nameStr.split(' ').join('-').toLowerCase();
};

export const unSlugify = (name: string) => {
  name = name ? name[0].toUpperCase() + name.slice(1) : '';

  return name.split('-').join(' ');
};
