import type {FC} from 'react';
import React, {memo, useContext, useMemo, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import {isEmail} from '@yourcoach/shared/utils/validation/isEmail';

import {labelAddEmail} from '@src/common/i18n/i18nCommon';
import {
  labelInviteByNameTabAddMessageBtn,
  labelInviteByNameTabEditMessageBtn,
  labelInviteByNameTabMessageTextInputPlaceholder,
  labelInviteByNameTabSaveBtn,
} from '@src/common/i18n/i18nInvites';
import {CustomInput} from '@src/components/CustomForm';
import {IconUserPlus} from '@src/components/icons';
import AppContext from '@src/context/App';

import InvitesContext from '../context/InvitesContext';
import type {ILocalStoreInvite} from '../context/InvitesLocalStore';

import styles from './../styles.module.css';

interface ILocalStore {
  emailError: string;
  setEmailError(emailError: string): void;
  _onQueryChange(query: string): void;
}

interface Props {}

const InviteByEmail: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const storeInvites: ILocalStoreInvite = useContext(InvitesContext)!;
  const localStore: ILocalStore = useRef(
    observable(
      {
        emailError: '',
        setEmailError(emailError: string) {
          this.emailError = emailError;
        },
        _onQueryChange(query: string) {
          if (
            (query.includes(',') || query.trim().includes(' ')) &&
            !queryIsEmail(storeInvites.queryEmail)
          ) {
            return;
          }

          storeInvites.setQueryEmail(query);

          storeInvites.setSelectedItem(null);

          if (
            storeInvites.queryEmail.length &&
            queryIsEmail(storeInvites.queryEmail) &&
            (storeInvites.queryEmail.includes(',') ||
              storeInvites.queryEmail.includes(' '))
          ) {
            const [email, rest] = storeInvites.queryEmail.split(/\s|,/);

            runInAction(() => {
              storeInvites.toItemEmail.push(email.trim());
            });

            storeInvites.setQueryEmail(rest || '');

            this._onQueryChange(storeInvites.queryEmail);

            return;
          }
        },
      },
      {
        emailError: observable,
        setEmailError: action,
        _onQueryChange: observable,
      },
    ),
  ).current;

  const queryIsEmail = query => {
    return isEmail(query);
  };

  const handleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const query = event?.currentTarget.value!;

    localStore.setEmailError('');
    localStore._onQueryChange(query);
  };

  const handleClickAddEmail = () => {
    if (queryIsEmail(storeInvites.queryEmail)) {
      if (/^[\w\\.]+@[\w\\.]+$/.test(storeInvites.queryEmail)) {
        if (
          !storeInvites.toItemEmail.find(
            item => item === storeInvites.queryEmail,
          )
        ) {
          runInAction(() => {
            storeInvites.toItemEmail.push(storeInvites.queryEmail.trim());
          });
        }

        storeInvites.setQueryEmail('');
      } else {
        localStore.setEmailError('The email is incorrect');
      }
    }
  };

  const _onEditMessagePress = () => {
    storeInvites.setIsEditingMessage(!storeInvites.isEditingMessage);
  };

  const handleOnChangeMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (event?.currentTarget.value.length < 10000) {
      storeInvites.setMessage(event?.currentTarget.value!);
    }
  };

  const programTitle = useMemo(
    () =>
      storeInvites.target!._id.split(':')[0] === 'course'
        ? // @ts-ignore
          storeInvites.target!.program
          ? // @ts-ignore
            storeInvites.target!.program?.title
          : ''
        : // @ts-ignore
          storeInvites.target!.title,
    [storeInvites.target],
  );

  const inviteMessage = (
    <Observer>
      {() => (
        <div>
          Hi there,
          <br />
          <br />
          {user!.name} has invited you to join their program on the YourCoach
          platform
          <br />
          <br />
          Here, you can connect with your coach to achieve your unique health
          and wellness goals, stay in touch via video and chat and keep all the
          relevant information all in one place.
          <br />
          <br />
          Program details:
          <br />
          {programTitle}
          <br />
          <br />
          Use this Unique Key when you create your account on the platform:
          <br />
          XXXX-XXXX
          <br />
          <br />
          {storeInvites.message ? (
            <>
              Here’s a personal message from {user!.name} to get you started
              with the app right away:
              <br />
              <br />
              <i>
                {storeInvites.message
                  .split(/\r\n|\r|\n/g)
                  .map((item, index) => (
                    <div key={index}>{item ? item : <br />}</div>
                  ))}
              </i>
              <br />
            </>
          ) : null}
          Congrats on taking the first step in your Health and Wellness journey!
          <br />
          <br />
          Download now, we can't wait to welcome you to your new virtual home!
          <br />
          <br />
          App Store Google Play
          <br />
          Learn more on our website
          <br />
          <br />
          What is YourCoach?
          <br />
          YourCoach is your virtual home for all things health and wellness
          coaching. It's a one-stop-solution for cultivating coach and client
          relationships and achieving real and lasting health outcomes.
          <br />
          <br />
          If you have any questions, please email us at help@yourcoach.health or
          visit our FAQs
          <br />
        </div>
      )}
    </Observer>
  );

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      handleClickAddEmail();
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`InviteByEmail ${styles.InviteByEmail}`}>
          <Scrollbar
            className={`scrollbar ${styles.InviteByEmail}`}
            noScrollX
            wrapperProps={{className: 'wrapper'}}
            trackYProps={{className: 'trackY'}}
            thumbYProps={{className: 'thumbY'}}
            trackXProps={{className: 'trackX'}}
            thumbXProps={{className: 'thumbY'}}
          >
            <div className={styles.inputContainer}>
              <CustomInput
                type="input"
                label={labelAddEmail()}
                customClassLabel="standardLabel"
                customClassInput={'standardInput border'}
                id="addEmail"
                value={storeInvites.queryEmail}
                onChange={handleOnChange}
                showErrorImmediately
                error={localStore.emailError}
                onKeyPress={handleOnKeyPress}
              />
              <div className={styles.addBut} onClick={handleClickAddEmail}>
                <IconUserPlus customClass={styles.icoUserPlus} />
              </div>
            </div>
            <div className={styles.invitesContainer}>
              <div className={styles.inviteView}>Invite view</div>
              <div className={styles.invitePreViewContainer}>
                {inviteMessage}
                {storeInvites.isEditingMessage ? (
                  <>
                    <textarea
                      className={styles.textArea}
                      value={storeInvites.message}
                      onChange={handleOnChangeMessage}
                      placeholder={labelInviteByNameTabMessageTextInputPlaceholder()}
                    />
                    {storeInvites.error ? storeInvites.error : null}
                  </>
                ) : null}
                {storeInvites.isEditingMessage ? (
                  <div
                    className={styles.greenBut}
                    onClick={_onEditMessagePress}
                  >
                    <div className={styles.greenButTitle}>
                      {labelInviteByNameTabSaveBtn()}
                    </div>
                  </div>
                ) : storeInvites.message ? (
                  <div
                    className={styles.greenBut}
                    onClick={_onEditMessagePress}
                  >
                    <div className={styles.greenButTitle}>
                      {labelInviteByNameTabEditMessageBtn()}
                    </div>
                  </div>
                ) : (
                  <div
                    className={styles.greenBut}
                    onClick={_onEditMessagePress}
                  >
                    <div className={styles.createSquadIcon}>+</div>
                    <div className={styles.greenButTitle}>
                      {labelInviteByNameTabAddMessageBtn()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Scrollbar>
        </div>
      )}
    </Observer>
  );
};

export default memo(InviteByEmail);
