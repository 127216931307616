export const I18N_SINGLE_EVENT = 'v2.single_event';

export const I18N_MEAL_POPUP = 'v2.client_task.meal_popup';

export const I18N_CLIENT_TASK = 'v2.client_task';

export const I18N_COURSE_INFO = 'v2.course_info';

export {useOpenMeal} from './useOpenMeal';

export {useOnAttachmentClick} from './useOnAttachmentClick';
