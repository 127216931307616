export const API_VERSION = '9.0';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const MIXPANEL_TOKEN = '65d63b14576340802056eb7627900732';

export const BUGSNAG_KEY = 'a1700533b8f33cb9a06cc65f21bed51a';

export const IS_WIDGET_PACKAGE = process.env.IS_WIDGET_PACKAGE === 'true';

// as we can load widget as part of the web app
// we need to check if we are in the "packaged" code
export const IS_ANALYTICS_ENABLED =
  IS_WIDGET_PACKAGE && process.env.ANALYTICS_ENABLED === 'true';

export const GET_WS_URL = () =>
  `${process.env.API_ENDPOINT!.replace(
    /https?/,
    process.env.APP_ENV === 'dev' ? 'wss' : 'wss',
  )}/events/listen`;
