import React, {useCallback, useRef, useState} from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';

import type {Tab} from '@src/components/TabBar/types';
import {t} from '@src/i18n';

import {ByNameTab} from './ByNameTab';
import {ByProgramTab} from './ByProgramTab';
import * as S from './styles';

const I18N_SCOPE = 'AddSessionNote';

export const AddSessionNoteModal = NiceModal.create(() => {
  const modal = useModal();

  const TABS: Tab[] = useRef([
    {key: 'byName', title: t([I18N_SCOPE, 'by_name_tab', 'title'])},
    {key: 'byProgram', title: t([I18N_SCOPE, 'by_program_tab', 'title'])},
  ]).current;

  const [index, setIndex] = useState(0);

  const renderTab = useCallback(
    tabIndex => {
      const currentTab = TABS[tabIndex];

      switch (currentTab.key) {
        case 'byName':
          return <ByNameTab />;
        case 'byProgram':
          return <ByProgramTab />;
        default:
          return null;
      }
    },
    [TABS],
  );

  return (
    <S.Modal
      isOpen={modal.visible}
      onRequestClose={modal.hide}
      onAfterClose={modal.remove}
      bodyClassName="body"
      title={t(['shared.session_notes.add_note_button'])}
    >
      <S.TabBar tabs={TABS} index={index} onIndexChange={setIndex} />
      {renderTab(index)}
    </S.Modal>
  );
});
