import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';
import {Img as Image} from 'react-image';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import {
  getColorByString,
  getFirstSymbols,
  lightenDarkenColor,
} from '../../utils';
import Loader from '../Loader/Loader';
import OverlayImage from '../OverlayImage/OverlayImage';

import styles from './styles.module.css';

interface Props {
  avatar?: IFile | null | undefined;
  className?: string;
  profileClassName?: string;
  childrenContainerClassName?: string;
  children?: ReactNode;
  isBGGradient?: boolean;
  getTitleIconIfNoImage?: boolean;
  title?: string;
  titleFontSize?: string;
  directionGradient?: string;
  differenceGradientColor?: number;
  isOverlay?: boolean;
}

const OtherUserProfile: FC<Props> = ({
  avatar = null,
  className = '',
  children,
  profileClassName = '',
  childrenContainerClassName = '',
  isBGGradient = false,
  directionGradient = 'to left',
  differenceGradientColor = -0.2,
  title = '',
  titleFontSize,
  isOverlay = false,
}) => {
  const getBackGround = () => {
    if (title) {
      const firstColor = getColorByString(title);

      if (isBGGradient) {
        const secondColor = lightenDarkenColor(
          firstColor,
          differenceGradientColor,
        );

        return {
          background: `linear-gradient(${directionGradient}, ${firstColor}, ${secondColor})`,
        };
      } else {
        return {
          backgroundColor: `${firstColor}`,
        };
      }
    } else {
      return undefined;
    }
  };
  const getTitle = () => {
    if (title) {
      return getFirstSymbols(title);
    } else {
      return '';
    }
  };

  return (
    <div className={`${styles.OtherUserProfileContainer}  ${className}`}>
      <div
        className={`OtherUserProfile ${styles.OtherUserProfile} ${profileClassName}`}
      >
        <Image
          src={getFileSrc(avatar, 250).url || ''}
          loader={<Loader height="55%" width="55%" />}
          unloader={
            <div className={styles.UserProfileImg} style={getBackGround()}>
              <div
                className={styles.AvatarTitle}
                style={{fontSize: titleFontSize}}
              >
                {getTitle()}
              </div>
            </div>
          }
        />
        {isOverlay ? <OverlayImage /> : null}
      </div>
      {children && (
        <div
          className={`${styles.childrenContainer} ${childrenContainerClassName}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default memo(OtherUserProfile);
