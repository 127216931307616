import startCase from 'lodash/startCase';
import {action, computed, makeObservable, observable} from 'mobx';

import {apiRequest, isEntityId} from '@yourcoach/shared/api';
import {
  conferenceFetch,
  courseFetch,
  programFetch,
  taskFetch,
} from '@yourcoach/shared/services/entity-action';
import {
  getCourseDurationString,
  getEntityTypeById,
} from '@yourcoach/shared/utils';

import type {
  ConferenceBase,
  CourseBase,
  ProgramBase,
  SupportedEntity,
  TaskBase,
  UserBase,
} from '../../types';

type EmptyCourse = {title: string};

export class Crumb {
  _path: string[];
  _label: string;
  constructor(path: string[], slug: string) {
    this.setPath(path);
    this.setLabel(slug);
    makeObservable(this, {
      _path: observable,
      _label: observable,
      label: computed,
      path: computed,
      setPath: action,
      setLabel: action,
    });
  }

  setPath(path: string[]) {
    this._path = path;
  }

  setLabel(label: string) {
    if (label) {
      this._label = label;
    }
  }

  get label() {
    return startCase(this._label);
  }

  get path() {
    return '/' + this._path.join('/');
  }

  static async build(path: string[]) {
    const value = path.slice(-1)[0];

    let slug = value;

    if (isEntityId(value)) {
      try {
        const entity = await Crumb.getEntityData(value, path);

        if (entity) {
          const entityType = getEntityTypeById(value) ?? 'unknown';

          slug = Crumb.getSlugFromEntity(entity, entityType);
        }
      } catch (error) {
        throw error;
      }
    }

    return new Crumb(path, slug);
  }

  private static async getEntityData(
    entityId: string,
    path: string[],
  ): Promise<SupportedEntity | EmptyCourse> {
    const entityType = getEntityTypeById(entityId);
    const params = {_id: entityId};

    switch (entityType) {
      case 'user': {
        const request = apiRequest({
          method: 'public.profile.read',
          params,
        });
        const result: {user: UserBase} = await request;

        return result.user;
      }
      case 'program': {
        const hasToBeCoachId = path.slice(-2)[0];

        return await programFetch({
          params: {...params, coach_id: hasToBeCoachId},
        });
      }
      case 'course': {
        const course = await courseFetch({params});

        return course ?? {title: 'course not found'};
      }
      case 'conference': {
        return await conferenceFetch({params});
      }
      case 'task': {
        return await taskFetch({params});
      }
      default: {
        throw 'Crumbs.store: unsupported entity type: ' + entityType;
      }
    }
  }

  private static getSlugFromEntity(
    entity: SupportedEntity | EmptyCourse,
    entityType: string,
  ): string {
    switch (entityType) {
      case 'user': {
        return (entity as UserBase).slug ?? (entity as UserBase).name;
      }
      case 'program': {
        return (entity as ProgramBase).slug ?? (entity as ProgramBase).title;
      }
      case 'course': {
        const course = {...entity} as CourseBase;

        if (!course.start_date) {
          return (course as unknown as EmptyCourse).title;
        }

        return getCourseDurationString(course);
      }
      case 'conference': {
        return (entity as ConferenceBase).title;
      }
      case 'task': {
        return (entity as TaskBase).title;
      }
      default: {
        throw 'Crumbs store: unsupported entity type: ' + entityType;
      }
    }
  }
}
