import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {observable} from 'mobx';

import {
  labelChatsCreateChat,
  labelChatsSearchChat,
} from '@src/common/i18n/i18nChannel';
import {CustomSearch} from '@src/components/CustomForm';
import {IconAddChat} from '@src/components/icons';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import AppContext from '@src/context/App';

import ChatsContext from '../../context/ChatsContext';
import type {IChatsLocalStore} from '../../context/useChatsLocalStore';
import CreateChannel from '../../CreateChannel/CreateChannel';

import styles from './../../styles.module.scss';

interface ILocalStore {
  showModalUserList: boolean;
  setShowModalUserList(showModalUserList: boolean): void;
}

interface Props {}

const ChatsListContainerMenu: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);
  const localStore = useRef(
    observable<ILocalStore>({
      showModalUserList: false,
      setShowModalUserList(showModalUserList: boolean) {
        this.showModalUserList = showModalUserList;
      },
    }),
  ).current;

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;

    if (text === '') {
      chatsLocalStore?.clearSearchQuery();
    } else {
      chatsLocalStore?.setSearchQuery(e.currentTarget.value);
    }
  };
  const handleClickAddChat = () => {
    localStore.setShowModalUserList(true);
  };
  const handleCloseModalUserList = () => {
    localStore.setShowModalUserList(false);
  };

  const handleCloseModal = () => {
    localStore.setShowModalUserList(false);
  };

  return (
    <Observer>
      {() => (
        <>
          <div
            className={`ChatsListContainerMenu ${styles.ChatsListContainerMenu}`}
          >
            <div className={styles.menuSearchContainer}>
              <CustomSearch
                label={labelChatsSearchChat()}
                id="searchQuestionnaires"
                onChange={handleOnSearchChange}
                classContainer={`searchContainer ${styles.searchContainer}`}
                classInput={styles.searchContainerInput}
                className="search"
                classIcon="icon"
                classIconContainer="iconContainer"
              />
            </div>
            {user?.roles?.includes('coach') ? (
              <div className={styles.menuButContainer}>
                <div className={styles.addChatBut} onClick={handleClickAddChat}>
                  <div className={styles.IconAddChatContainer}>
                    <IconAddChat />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <ModalAnimateWin
            showModal={localStore.showModalUserList}
            closeModalHandler={handleCloseModalUserList}
            className="greyHeaderContainer littleContainer List"
            isBody
            classNameBody="whiteBody maxContent"
            header={labelChatsCreateChat()}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <CreateChannel closeModal={handleCloseModal} />
          </ModalAnimateWin>
        </>
      )}
    </Observer>
  );
};

export default memo(ChatsListContainerMenu);
