import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import UserFlowContext from '../../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../../context/useUserFlowLocalStore';

import styles from './../styles.module.css';

interface Props {}

const QUserProgressBar: FC<Props> = () => {
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);

  const getPercent: () => number = () => {
    if (userFlowStory) {
      if (userFlowStory.maxStep > 0 && userFlowStory.numAnswered > 0) {
        return Math.round(
          (userFlowStory.numAnswered * 100) / userFlowStory.maxStep,
        );
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`QUserProgressBar ${styles.QUserProgressBar}`}>
          <div className={styles.mainLine} />
          <div className={styles.progress} style={{width: `${getPercent()}%`}}>
            <div
              className={`${styles.labelProgress} ${
                getPercent() === 0 ? styles.left0 : ''
              }`}
            >
              {getPercent()} %
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(QUserProgressBar);
