import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {
  getEmailSchema,
  getPhoneSchema,
} from '@yourcoach/shared/utils/validation';

import {
  labelInput,
  labelNotCorrectEmail,
  labelNotCorrectPhone,
  labelSendCode,
  labelUserEmailLabelLite,
} from '@src/common/i18n/i18nProfile';
import {CustomButton, CustomInput} from '@src/components/CustomForm';
import type {IValidSchema} from '@src/models/app';

import styles from './../styles.module.css';

interface ILocalStore {
  isEmail: boolean;
  validSEmail: IValidSchema;
  validSPhone: IValidSchema;
  setIsEmail: (newVal: boolean) => void;
  textEmail: string;
  textEmailError: string;
  setTextEmail: (newText: string) => void;
  textPhone: string;
  textPhoneError: string;
  setTextPhone: (newText: string) => void;
}

interface Props {
  addAccount: (sendObj: {type: 'email' | 'phone'; val: string}) => void;
}

const GetAccount: FC<Props> = ({addAccount}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        validSEmail: getEmailSchema(),
        validSPhone: getPhoneSchema(),
        textEmailError: '',
        textEmail: '',
        setTextEmail(newText: string) {
          const re = /^[\w.%+-]+@[A-Z\d-].{2,}[A-Z]{2}$/i;

          this.textEmailError = '';

          if (!re.test(newText)) {
            this.textEmailError = labelNotCorrectEmail();
          }

          this.textEmail = newText;
        },
        textPhoneError: '',
        textPhone: '',
        setTextPhone(newText: string) {
          const re = this.validSPhone.pattern;

          this.textPhoneError = '';

          if (!re.test(newText)) {
            this.textPhoneError = labelNotCorrectPhone();
          }

          this.textPhone = newText;
        },
        isEmail: true,
        setIsEmail(newVal: boolean) {
          this.isEmail = newVal;
          this.textEmailError = '';
          this.textEmail = '';
          this.textPhoneError = '';
          this.textPhone = '';
        },
      },
      {
        textEmail: observable,
        isEmail: observable,
        textEmailError: observable,
        textPhoneError: observable,
        textPhone: observable,
        setIsEmail: action,
        setTextPhone: action,
      },
    ),
  ).current;

  const handleOnClickIsEmail = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    localStore.setIsEmail(true);
  };

  const handleOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (localStore.isEmail) {
      localStore.setTextEmail(event?.currentTarget.value as string);
    } else {
      localStore.setTextPhone(event?.currentTarget.value as string);
    }
  };

  const handleClickContinue = () => {
    if (localStore.textEmailError === '' && localStore.textPhoneError === '') {
      const sendObj = {
        type: (localStore.isEmail ? 'email' : 'phone') as 'email' | 'phone',
        val: localStore.isEmail ? localStore.textEmail : localStore.textPhone,
      };

      addAccount(sendObj);
    }
  };

  return (
    <Observer>
      {() => (
        <div className="GetAccount">
          <div className="topMenuContainer">
            <a
              className={`customTopMenu ${
                localStore.isEmail ? 'selected' : ''
              }`}
              onClick={handleOnClickIsEmail}
            >
              {labelUserEmailLabelLite()}
            </a>
          </div>
          <hr className={styles.hrClass} />
          <CustomInput
            type="input"
            label={labelInput()}
            id="questionnaireName"
            customClassLabel="QStandardLabel"
            customClassCont="QStandardContainer"
            customClassInput="QStandardInput"
            value={
              localStore.isEmail ? localStore.textEmail : localStore.textPhone
            }
            onChange={handleOnChange}
            error={
              localStore.isEmail
                ? localStore.textEmailError
                : localStore.textPhoneError
            }
          />
          <div className={styles.buttonSendCodeContainer}>
            <CustomButton
              disabled={
                localStore.textEmail === '' && localStore.textPhone === ''
              }
              type="button"
              classButton="QuestionnaireContinueBut allWidth"
              onClick={handleClickContinue}
            >
              <span>{labelSendCode()}</span>
            </CustomButton>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(GetAccount);
