import {action, makeObservable, observable} from 'mobx';

import {EntityStore} from '@yourcoach/shared/api';
import type {ChannelT} from '@yourcoach/shared/api/channel';
import type {User} from '@yourcoach/shared/api/user';

export const STORAGE_KEY = 'chatsColumnsStore';

export class ChatsColumnsStore<T extends User> extends EntityStore<T> {
  channel: ChannelT | null = null;
  channelId: string;

  constructor(...args: ConstructorParameters<typeof EntityStore>) {
    super(...args);

    makeObservable(this, {
      channel: observable,
      channelId: observable,
      setSelectedValue: action,
    });
  }

  setSelectedValue(key: string, selectedValue: unknown | null) {
    this[key] = selectedValue;
  }
}

export default new ChatsColumnsStore('chats_columns_store');
