import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import ReactPlayer from 'react-player';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import styles from './styles.module.css';

interface Props {
  url?: string;
  label?: string;
  subtitle?: string;
  message?: string;
  onNext: () => void;
}

const Player: FC<Props> = ({url, label, subtitle, message, onNext}) => {
  const [messageClass, setMessageClass] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setMessageClass(styles.animated);
  }, [url]);

  const onPlayerReady = useCallback(() => {
    if (!message) {
      setIsPlaying(true);
    } else {
      setTimeout(() => {
        setIsPlaying(true);
      }, 1500);
    }
  }, [message]);

  const onPlayerEnded = useCallback(() => {
    setIsPlaying(false);
    onNext();
  }, [onNext]);

  const endAnimation = useCallback(() => {
    setMessageClass('');
  }, []);

  return (
    <div className={styles.player}>
      <ReactPlayer
        className={styles.videoPlayer}
        url={url}
        width="100%"
        height="auto"
        controls={true}
        playing={isPlaying}
        muted={false}
        onReady={onPlayerReady}
        onEnded={onPlayerEnded}
      />
      <div className={styles.label}>{label}</div>
      <div className={styles.spacer} />
      <div className={styles.subtitle}>{subtitle}</div>
      <Button className={styles.nextButton} onClick={onNext}>
        {t('Common.next')}
      </Button>
      {message ? (
        <div
          className={styles.message + ' ' + messageClass}
          onAnimationEnd={endAnimation}
        >
          {message}
        </div>
      ) : null}
    </div>
  );
};

export default Player;
