import styled, {css} from 'styled-components';

import {DEFAULT_NOTE_COLOR} from '@yourcoach/shared/api/sessionNote';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';

export const Container = styled.div`
  display: flex;
`;

export const Item = styled(Clickable)<{color: string; active: boolean}>`
  width: 26px;
  height: 26px;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p =>
    p.active &&
    css`
      background-color: ${getColor('border1')};
    `}

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${p =>
      p.theme.color.v2.note[p.color || DEFAULT_NOTE_COLOR]};
  }
`;
