import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  datetimeObjToISOString,
} from '@yourcoach/shared/api';
import type {Invite} from '@yourcoach/shared/api/invite';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {User} from '@yourcoach/shared/api/user';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {labelAreYouSure} from '@src/common/i18n/i18nCourse';
import {
  labelProgramInvitesTabDeleteInviteBtn,
  labelProgramInvitesTabNoResults,
  labelProgramInvitesTabResendInviteBtn,
  labelProgramInvitesTabResendSent,
} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconProfile} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';

import {emitter} from '../../../../widget/src/utils';
import IconCalendarFill from '../../../components/icons/IconCalendarFill/IconCalendarFill';
import SeeProgramContext from '../context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '../context/useSeeProgramLocalStore';

import styles from './../styles.module.scss';

export type InviteT = Invite & {
  user: Partial<User> &
    Required<Pick<User, 'name'>> & {
      avatar?: IFile | null;
    };
};

interface ILocalStore {
  invitesStore: CollectionStore<InviteT> | null;
  shouldRenderStub: boolean;
  isFetching: boolean;
  data: InviteT[];
  currentInvite: InviteT | null;
  isLoading: boolean;
  setIsLoading(val: boolean): void;
  setInvitesStore(invitesStore: CollectionStore<InviteT>): void;
}

interface Props {}

const InvitesTab: FC<Props> = () => {
  const CURSOR_DIRECTION = -1;
  const LIMIT = 999;
  const menuId = 'InvitesTab';
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);
  const {
    stores: {inviteStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        invitesStore: null,
        setInvitesStore(invitesStore: CollectionStore<InviteT>) {
          this.invitesStore = invitesStore;
        },
        isLoading: false,
        setIsLoading(val: boolean) {
          this.isLoading = val;
        },
        currentInvite: null,
        get shouldRenderStub() {
          return (
            this.invitesStore &&
            this.invitesStore.isLoaded &&
            !this.invitesStore.hasItems
          );
        },
        get isFetching() {
          return (
            this.invitesStore &&
            (!this.invitesStore.isLoaded || this.invitesStore.isFetching)
          );
        },
        get data() {
          return (this.invitesStore as CollectionStore<InviteT>).items.slice();
        },
      },
      {
        invitesStore: observable,
        isLoading: observable,
        currentInvite: observable,
        setInvitesStore: action,
        setIsLoading: action,
        shouldRenderStub: computed,
        isFetching: computed,
        data: computed,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setInvitesStore(
      createCollectionStore({
        method: 'coach.invites.list',
        params: {
          cursor: [CURSOR_DIRECTION, 0, null],
          limit: LIMIT,
          query: [['program_id', '==', seeProgramLocalStore!.program!._id]],
          expand: {
            invite: [
              [
                'user_id',
                {name: 'User not found'},
                {
                  user: ['avatar_id'],
                },
              ],
            ],
          },
        },
        withCount: true,
      }),
    );

    const disposeCount = reaction(
      () => localStore.invitesStore?.count,
      count => {
        seeProgramLocalStore!.invitesCount = count as number;
      },
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    const timerId = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      disposeCount();
      localStore.invitesStore?.clear();
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (
      msg.event.type === 'membership_created' &&
      msg.program._id === seeProgramLocalStore!.program!._id
    ) {
      fetchData(true);
    }
  };

  const handleResendInvite = async () => {
    await getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: () => _resendInvite(localStore.currentInvite!),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _resendInvite = async (invite: InviteT) => {
    try {
      localStore.setIsLoading(false);

      const params: {
        emails?: string[];
        user_ids?: string[];
      } = {};

      if (invite.email) {
        params.emails = [invite.email];
      } else {
        params.user_ids = [invite.user_id!];
      }

      await inviteStore.send({
        program_id: invite.program_id,
        message: invite.message,
        ...params,
      });

      await inviteStore.delete(invite);

      localStore.invitesStore!.removeItem(invite);

      fetchData(true);

      localStore.setIsLoading(true);
    } catch (error) {
      localStore.setIsLoading(true);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const fetchData = async (silent = false) => {
    localStore.setIsLoading(false);

    try {
      localStore.invitesStore!.fetch(
        {
          limit: Math.max(localStore.invitesStore!.items.length, LIMIT),
        },
        {silent},
      );

      localStore.setIsLoading(true);
    } catch (error) {
      localStore.setIsLoading(true);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const handleDeleteInvite = async () => {
    await getCustomConfirmAlert({
      title: labelAreYouSure(),
      buttons: [
        {
          label: 'Yes',
          onClick: () => _deleteInvite(localStore.currentInvite!),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteInvite = async (invite: InviteT) => {
    try {
      localStore.setIsLoading(false);

      await inviteStore.delete(invite);

      localStore.invitesStore!.removeItem(invite);

      fetchData(true);

      localStore.setIsLoading(true);
    } catch (error) {
      localStore.setIsLoading(true);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onInvitePress = () => {
    // TODO: Navigate to user profile
  };

  const handleOnClickOptions = (invite: InviteT) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      localStore.currentInvite = invite;
      event.stopPropagation();
      event.preventDefault();

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          invite,
        },
      });
    };
  };

  const onStoreChange = useCallback(
    op => {
      if (op?.success) {
        localStore?.invitesStore?.fetch();
      }
    },
    [localStore],
  );

  useEffect(() => {
    const disposer = reaction(() => inviteStore.sending, onStoreChange);

    return () => {
      disposer();
    };
  }, [inviteStore, onStoreChange]);

  const MyMenu = () => (
    <Observer>
      {() => (
        <Menu id={menuId} animation={animation.fade} className="contextMenu">
          <Item onClick={handleDeleteInvite} className="contextMenuItem">
            {labelProgramInvitesTabDeleteInviteBtn()}
          </Item>
          <Item onClick={handleResendInvite} className="contextMenuItem">
            {labelProgramInvitesTabResendInviteBtn()}
          </Item>
        </Menu>
      )}
    </Observer>
  );

  return (
    <Observer>
      {() => (
        <div className={`InvitesTab ${styles.InvitesTab}`}>
          {localStore.shouldRenderStub ? (
            <NoResults text={labelProgramInvitesTabNoResults()}>
              <div className={styles.iconNoResultContainer}>
                <IconProfile
                  className={styles.iconNoResult}
                  viewBox="0 0 24 24"
                />
              </div>
            </NoResults>
          ) : !localStore.isLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.invitesContainer}>
                {localStore.data.map(invite => {
                  return (
                    <div
                      onClick={_onInvitePress}
                      key={invite._id}
                      className={styles.inviteContainer}
                    >
                      <div className={styles.contentContainer}>
                        <div className={styles.userAvatar}>
                          <OtherUserProfileImg
                            avatar={invite.user.avatar}
                            title={invite.user.name}
                          />
                        </div>
                        <div className={styles.content}>
                          <div className={styles.userName}>
                            {invite.email || invite.user.name}
                          </div>
                          <div className={styles.date}>
                            <div className={styles.calendarContainerIcon}>
                              <IconCalendarFill
                                className={styles.calendarIcon}
                                viewBox="2 1 20 20"
                              />
                            </div>
                            <div>
                              {labelProgramInvitesTabResendSent(
                                dayjs(
                                  datetimeObjToISOString(
                                    invite.sent || invite.created,
                                  ),
                                ).format('lll'),
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles.moreButt}
                          onClick={handleOnClickOptions(invite)}
                        >
                          ⋮
                        </div>
                      </div>
                      {invite.message ? (
                        <div className={styles.messageContainer}>
                          <div className={styles.message}>{invite.message}</div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <MyMenu />
            </>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(InvitesTab);
