import {action, observable} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api';
import delay from '@yourcoach/shared/utils/delay';

// @ts-ignore
import mp3 from './../../click.mp3';
import icon from './../../favicon.png';
import iconDot from './../../favicon-dot.png';
import iconDotGreen from './../../favicon-dot-green.png';

export interface IAppStore {
  isAuth: boolean;
  breadcrumbs: IBreadcrumb[];
  linksBreadcrumbs: ILinksBreadcrumbs;
  addLinksBreadcrumbs(obj: IBreadcrumb): void;
  clearLinksBreadcrumbs(name: string): void;
  clearAllLinksBreadcrumbs(): void;
  isLinksBreadcrumbsHaveProp(name: string): boolean;
  returnPath: string;
  setIsAuth(val: boolean): void;
  setReturnPath(path: string): void;
  addBreadcrumb(breadcrumb: IBreadcrumb): void;
  addBreadcrumbs(breadcrumbs: IBreadcrumb[]): void;
  clearBreadcrumbs(): void;
  isAppLoading: boolean;
  setIsAppLoading(isAppLoading: boolean): void;
  topNotification: number;
  setTopNotification(topNotification: number): void;
  _fetchUnreadEventsCount(): Promise<number>;
  postCreatedNotification: number;
  setPostCreatedNotification(postCreatedNotification: number): void;
  isTopNotificationsOpen: boolean;
  setIsTopNotificationsOpen(isTopNotificationsOpen: boolean): void;
  setFavIcon(): void;
  audio: HTMLAudioElement | null;
  isAudioPlay: boolean;
}

export interface ILinksBreadcrumbs {
  [name: string]: string;
}

export interface IBreadcrumb {
  name: string;
  path: string;
}

export interface IMetaEl {
  name: string;
  val: string;
}

export interface IMeta {
  title: string;
}

export const EXCLUDED_EVENTS: Event['type'][] = [
  'post_created',
  'post_deleted',
  'post_updated',
  'program_deleted',
  'points_added',
  'tier_upgraded',
  'tier_downgraded',
  'coach_blocked',
  'coach_unblocked',
  'payment_expiring',
  'subscription_info',
];

export const POST_EVENTS: Event['type'][] = [
  'course_started',
  'membership_frozen',
  'membership_unfrozen',
  'post_created',
];

const appStore: IAppStore = observable<IAppStore>(
  {
    postCreatedNotification: 0,
    setPostCreatedNotification(postCreatedNotification: number) {
      this.postCreatedNotification = postCreatedNotification;
      this.setFavIcon();
    },
    topNotification: 0,
    setTopNotification(topNotification: number) {
      this.topNotification = topNotification;
      this.setFavIcon();
    },
    audio: typeof Audio !== 'undefined' ? new Audio() : null,
    isAudioPlay: false,
    setFavIcon() {
      if (this.postCreatedNotification > 0) {
        // @ts-ignore
        document.getElementById('favicon').href = iconDot;

        if (!this.isAudioPlay) {
          this.audio.src = mp3;
          this.audio.autoplay = true;
        }

        this.isAudioPlay = true;
      } else if (this.topNotification > 0) {
        // @ts-ignore
        document.getElementById('favicon').href = iconDotGreen;

        if (!this.isAudioPlay) {
          this.audio.src = mp3;
          this.audio.autoplay = true;
        }

        this.isAudioPlay = true;
      } else {
        // @ts-ignore
        document.getElementById('favicon').href = icon;
        this.isAudioPlay = false;
      }
    },
    isTopNotificationsOpen: false,
    setIsTopNotificationsOpen(isTopNotificationsOpen: boolean) {
      this.isTopNotificationsOpen = isTopNotificationsOpen;
    },
    async _fetchUnreadEventsCount() {
      try {
        const result = await apiRequest({
          method: 'user.events.count',
          params: {
            query: [
              ['type', '!in', EXCLUDED_EVENTS],
              ['read', '==', null],
            ],
          },
        });

        this.setTopNotification(result._count);

        return result._count;
      } catch (error) {
        await delay(2000);

        return this._fetchUnreadEventsCount();
      }
    },
    isAuth: false,
    isAppLoading: true,
    setIsAppLoading(isAppLoading: boolean) {
      this.isAppLoading = isAppLoading;
    },
    breadcrumbs: [],
    linksBreadcrumbs: {},
    addLinksBreadcrumbs(obj: IBreadcrumb) {
      this.linksBreadcrumbs[obj.name] = obj.path;
    },
    clearLinksBreadcrumbs(name: string) {
      delete this.linksBreadcrumbs[name];
    },
    clearAllLinksBreadcrumbs() {
      this.linksBreadcrumbs = {};
    },
    isLinksBreadcrumbsHaveProp(name: string) {
      return typeof this.linksBreadcrumbs[name] !== 'undefined';
    },
    returnPath: '/',
    addBreadcrumb(breadcrumb: IBreadcrumb) {
      this.breadcrumbs.push(breadcrumb);
    },
    addBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
    clearBreadcrumbs() {
      this.breadcrumbs = [];
    },
    setIsAuth(val: boolean) {
      this.isAuth = val;
    },
    setReturnPath(path: string) {
      this.returnPath = path;
    },
  },
  {
    postCreatedNotification: observable,
    setPostCreatedNotification: action,
    isTopNotificationsOpen: observable,
    setIsTopNotificationsOpen: action,
    topNotification: observable,
    setTopNotification: action,
    _fetchUnreadEventsCount: action,
    isAuth: observable,
    breadcrumbs: observable,
    linksBreadcrumbs: observable,
    returnPath: observable,
    isAppLoading: observable,
    setIsAuth: action,
    setReturnPath: action,
    addBreadcrumb: action,
    setIsAppLoading: action,
    addBreadcrumbs: action,
    clearBreadcrumbs: action,
    addLinksBreadcrumbs: action,
    clearLinksBreadcrumbs: action,
    clearAllLinksBreadcrumbs: action,
    isLinksBreadcrumbsHaveProp: action,
    setFavIcon: action,
    audio: observable,
    isAudioPlay: observable,
  },
);

export default appStore;
