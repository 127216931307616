import React, {useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import classNames from 'classnames';

import ArrowBackIcon from '@yourcoach/shared/assets/icons/arrow-back.svg';
import {Logo} from '@yourcoach/shared/uikit/Logo';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import styles from './LogoOnly.module.css';

interface Props {
  v2?: boolean;
  withBackButton?: boolean;
  backButtonText?: string;
  onBackButtonClick?: () => void;
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
}

const LogoOnlyLayout: React.FC<Props> = ({
  v2,
  withBackButton,
  backButtonText,
  onBackButtonClick,
  className,
  contentClassName,
  children,
}) => {
  const history = useHistory();

  const onBackButtonClickCb = useCallback(() => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      history.goBack();
    }
  }, [history, onBackButtonClick]);

  return (
    <section
      className={classNames(
        'LogoOnlyLayout',
        `${styles.LogoOnlyLayout}`,
        v2 && styles.v2,
        className,
      )}
    >
      <div className={styles.header}>
        <Link className={styles.logoContainer} to={'/'}>
          <Logo size={40} />
        </Link>
        <div className={styles.headerRightPart}>
          {withBackButton ? (
            <Button onClick={onBackButtonClickCb} className={styles.backButton}>
              <ArrowBackIcon />
              <span>{backButtonText || t('label.back')}</span>
            </Button>
          ) : null}
        </div>
      </div>
      <section className={classNames(styles.content, contentClassName)}>
        {children}
      </section>
    </section>
  );
};

export default React.memo(LogoOnlyLayout);
