/* eslint-disable no-bitwise */
// java String#hashCode
function hashCode(str: string) {
  let hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

export const getRandomColor = (seed?: string) => {
  if (seed) {
    const hash = hashCode(seed);

    let c = (hash & 0x00ffffff).toString(16).toUpperCase();
    let result = '00000'.substring(0, 6 - c.length) + c;

    return `#${result}`;
  }

  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padEnd(6, '0')}`;
};
