import type {FC} from 'react';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Observer} from 'mobx-react-lite';

import {reaction} from 'mobx';

import SecondaryArrowLeft from '@src/assets/img/SecondaryArrowLeft.svg';
import SecondaryArrowRight from '@src/assets/img/SecondaryArrowRight.svg';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import ChatMainContent from '@src/modules/Chats/ChatMainContent/ChatMainContent';
import MainChatsState from '@src/modules/Chats/ChatMainContent/context/MainChatsState';
import ChatsListContainer from '@src/modules/Chats/ChatsListContainer/ChatsListContainer';
import ChatsContext from '@src/modules/Chats/context/ChatsContext';
import type {IChatsLocalStore} from '@src/modules/Chats/context/useChatsLocalStore';
import {SUPPORTED_EVENTS} from '@src/modules/Chats/context/useChatsLocalStore';
import Tabs from '@src/pages/NewLayout/components/Tabs';

import {emitter} from 'web/widget/src/utils';

import styles from '../styles/styles.module.scss';

interface Props {}

const ChatsNewLayout: FC<Props> = () => {
  const [expanded, setExpanded] = useState(false);

  const {
    stores: {
      channelStore,
      chatsColumnsStore,
      courseStore,
      eventStore,
      postStore,
    },
  } = useContext(AppContext);
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  const _fetchUnreadEvents = useCallback(() => {
    eventStore.fetchUnread({
      limit: 500,
    });
  }, [eventStore]);

  const _handleWsMessage = useCallback(
    (msg: any) => {
      if (
        [
          'course_started',
          'membership_frozen',
          'membership_unfrozen',
          'post_created',
        ]
          .concat(SUPPORTED_EVENTS)
          .includes(msg.event.type)
      ) {
        _fetchUnreadEvents();
      }
    },
    [_fetchUnreadEvents],
  );

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    chatsLocalStore!.createChannelsStores();

    const disposeCourseUpdate = reaction(
      () => courseStore.updating,
      updating => {
        if (updating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );

    const disposeCourseDel = reaction(
      () => courseStore.deleting,
      updating => {
        if (updating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeChannelCreate = reaction(
      () => channelStore.creating,
      creating => {
        if (creating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeCannelUpdate = reaction(
      () => channelStore.updating,
      updating => {
        if (updating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposeCannelDelete = reaction(
      () => channelStore.deleting,
      deleting => {
        if (deleting.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostCreating = reaction(
      () => postStore.creating,
      creating => {
        if (creating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostUpdate = reaction(
      () => postStore.updating,
      updating => {
        if (updating.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );
    const disposePostDelete = reaction(
      () => postStore.deleting,
      deleting => {
        if (deleting.success) {
          chatsLocalStore!._onRefresh(true);
        }
      },
    );

    chatsLocalStore!._onRefresh();

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      disposeCourseUpdate();
      disposeCourseDel();
      disposeChannelCreate();
      disposeCannelUpdate();
      disposeCannelDelete();
      disposePostCreating();
      disposePostUpdate();
      disposePostDelete();

      if (chatsLocalStore) {
        chatsLocalStore.clear();
      }
    };
  }, [
    _handleWsMessage,
    channelStore.creating,
    channelStore.deleting,
    channelStore.updating,
    chatsLocalStore,
    courseStore.deleting,
    courseStore.updating,
    postStore.creating,
    postStore.deleting,
    postStore.updating,
  ]);

  return (
    <Observer>
      {() => (
        <div className={`${styles.mainContainer} ${styles.columnsLayout}`}>
          <div
            className={`${styles.colLeft} ${
              expanded ? styles.colLeftNarrow : ''
            }`}
          >
            <div
              className={`${styles.header} ${
                expanded ? styles.headerS : styles.headerL
              }`}
            />
            <div className={styles.chatsColumn}>
              <ChatsListContainer expandedMode={expanded} />
            </div>
          </div>
          <div className={styles.colCenter}>
            <div
              className={`${styles.colCenterInner} ${
                expanded ? styles.colCenterInnerExpanded : ''
              }`}
            >
              <MainChatsState>
                <ChatMainContent />
              </MainChatsState>
              {chatsColumnsStore.channel ? (
                <div
                  className={styles.expandButton}
                  onClick={() => setExpanded(!expanded)}
                >
                  <div className={styles.arrowContainer}>
                    {expanded ? (
                      <SecondaryArrowRight className={styles.arrowIcon} />
                    ) : (
                      <SecondaryArrowLeft className={styles.arrowIcon} />
                    )}
                  </div>
                  {`${expanded ? ' Hide tools' : ' Show tools'}`}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={`${styles.colRight} ${
              expanded ? styles.colRightWide : ''
            }`}
          >
            <div className={styles.headerS}>Tools</div>
            <Tabs />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default ChatsNewLayout;
