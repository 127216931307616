import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import MyCoachesIcon from '@yourcoach/shared/assets/icons/secondary/3 User.svg';
import UserProfileIcon from '@yourcoach/shared/assets/icons/secondary/Your space.svg';

import {
  labelChats,
  labelDashboard,
  labelGoals,
  labelMyCoaches,
  labelProfile,
  labelToDos,
} from '@src/common/i18n/i18nMainMenu';
import isActive from '@src/common/isActivePath';
import BadgeContainer from '@src/components/BadgeContainer/BadgeContainer';
import {
  IconActivity,
  IconCalendar,
  IconChat,
  IconContainer,
} from '@src/components/icons';
import IconGoals from '@src/components/icons/IconGoals/IconGoals';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import {PATH_TYPE} from '@src/routes/utils';

interface Props {}

const MainMenu: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const location = useLocation();

  const handleIsVisibleBadge = () => {
    return location.pathname !== `/${PATH_TYPE.chats}`;
  };

  return (
    <Observer>
      {() => (
        <ul className="navbarNav">
          <li>
            <NavLink
              to={`/${PATH_TYPE.yourSpace}`}
              activeClassName="selected"
              className="Dashboard"
              isActive={isActive.bind(this, `/${PATH_TYPE.yourSpace}`)}
            >
              <IconContainer title={labelDashboard()}>
                <IconActivity />
              </IconContainer>
            </NavLink>
          </li>
          {currentUserStore.user?.roles?.includes('coach') ? null : (
            <li>
              <NavLink
                to={`/${PATH_TYPE.myCoaches}`}
                activeClassName="selected"
                className="Coaches"
                isActive={isActive.bind(this, `/${PATH_TYPE.myCoaches}`)}
              >
                <IconContainer title={labelMyCoaches()}>
                  <div className="fill icon">
                    <MyCoachesIcon />
                  </div>
                </IconContainer>
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to={`/${PATH_TYPE.toDos}`}
              activeClassName="selected"
              className="To-dos"
              isActive={isActive.bind(this, `/${PATH_TYPE.toDos}`)}
            >
              <IconContainer title={labelToDos()}>
                <IconCalendar viewBox="2 2 20 20" />
              </IconContainer>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${PATH_TYPE.goals}`}
              activeClassName="selected"
              className="Goals"
              isActive={isActive.bind(this, `/${PATH_TYPE.goals}`)}
            >
              <IconContainer title={labelGoals()}>
                <IconGoals />
              </IconContainer>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${PATH_TYPE.chats}`}
              activeClassName="selected"
              className="Chats"
              isActive={isActive.bind(this, `/${PATH_TYPE.chats}`)}
            >
              <IconContainer title={labelChats()}>
                <BadgeContainer
                  isVisible={handleIsVisibleBadge()}
                  numMess={localAppStore?.postCreatedNotification}
                  badgeClassName={'badge'}
                >
                  <IconChat viewBox="2 2 20 20" />
                </BadgeContainer>
              </IconContainer>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${PATH_TYPE.profile}`}
              activeClassName="selected"
              className="Profile"
              isActive={isActive.bind(this, `/${PATH_TYPE.profile}`)}
            >
              <IconContainer title={labelProfile()}>
                <div className="fill icon">
                  <UserProfileIcon />
                </div>
              </IconContainer>
            </NavLink>
          </li>
          {currentUserStore.user?.roles?.includes('coach') ? null : (
            <li>
              <NavLink
                to={`/${PATH_TYPE.widget}`}
                activeClassName="selected"
                className="Profile"
                isActive={isActive.bind(this, `/${PATH_TYPE.widget}`)}
              >
                <IconContainer title="Widget">
                  <div className="fill icon">
                    <UserProfileIcon />
                  </div>
                </IconContainer>
              </NavLink>
            </li>
          )}
        </ul>
      )}
    </Observer>
  );
};

export default memo(MainMenu);
