import {makeAutoObservable} from 'mobx';

import {
  AvailabilityCalendarDataFetcher,
  CalendarDataStore,
  ScheduleStore,
} from '@yourcoach/shared/stores/calendar-data';
import type {Destructible} from '@yourcoach/shared/utils';

export class MyCalendarStore implements Destructible {
  schedule = new ScheduleStore();
  calendar = new CalendarDataStore(new AvailabilityCalendarDataFetcher());

  constructor() {
    makeAutoObservable(this);

    this.calendar.fetch();
  }

  destruct = () => {
    this.calendar.destruct();
  };

  get filteredData() {
    return this.calendar.events.entries;
  }
}
