import {useEffect, useState} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import type {Program} from '@yourcoach/shared/api/program';
import type {ProposedMembership} from '@yourcoach/shared/api/program/utils/getProposedMembership';
import getProposedMembership from '@yourcoach/shared/api/program/utils/getProposedMembership';
import {logger} from '@yourcoach/shared/utils/logger';

import useIsMounted from '@src/hooks/useIsMounted';

const useProposedMembership = (program: Program) => {
  const [proposedMembership, setProposedMembership] =
    useState<ProposedMembership>();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<unknown>();

  const isMountedRef = useIsMounted();

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!isMountedRef.current) {
          setIsFetching(true);
        }

        const _proposedMembership = await getProposedMembership(program);

        setProposedMembership(_proposedMembership);

        setIsFetching(false);
      } catch (fetchError) {
        logger.error(fetchError);

        if (apiRequest.isCanceledError(fetchError)) {
          return;
        }

        if (!isMountedRef.current) {
          return;
        }

        setIsFetching(false);
        setError(fetchError);
      }
    };

    fetch();
  }, [isMountedRef, program, program._id]);

  return {isFetching, error, proposedMembership};
};

export default useProposedMembership;
