import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {apiRequest} from '@yourcoach/shared/api';
import questionnaireDone from '@yourcoach/shared/assets/congratulations.png';
import questionnaireDone2x from '@yourcoach/shared/assets/congratulations@2x.png';
import questionnaireDone3x from '@yourcoach/shared/assets/congratulations@3x.png';

import {labelBack, labelContinue, labelDone} from '@src/common/i18n/i18nCommon';
import {
  labelCongratulationBegin,
  labelCongratulationsEnd,
  labelYouAreAwesome,
  labelYouHaveAlreadyTakenThisQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton} from '@src/components/CustomForm';
import {IconNext, IconPrev} from '@src/components/icons';
import {ColorWrapper} from '@src/layouts';
import type {UserQuestionnaireExpanded} from '@src/models/questionnaire';
import {userQuestionnaireExpand} from '@src/models/questionnaire';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';
import {PATH_TYPE} from '@src/routes/utils';

import UserFlowContext from '../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../context/useUserFlowLocalStore';

import QUserFlowHeader from './QUserFlowHeader/QUserFlowHeader';
import QUserFlowQuestionFactory from './QUserFlowQuestionFactory/QUserFlowQuestionFactory';
import QUserProgressBar from './QUserProgressBar/QUserProgressBar';
import styles from './styles.module.css';

interface Props {}

const QUserFlowStart: FC<Props> = () => {
  const redirect = useAppRedirect();
  const history = useHistory();
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);
  const QUserId = userFlowStory?.currentQId;

  useEffect(() => {
    const getUserQ = async () => {
      try {
        const {user_questionnaire, _expanded} = await apiRequest({
          method: 'client.questionnaires.read',
          params: {
            _id: QUserId,
            expand: userQuestionnaireExpand,
          },
        });

        const retExpanded: UserQuestionnaireExpanded = {
          expanded_coaches: [],
        };

        for (let el in _expanded) {
          if (el.indexOf('user') !== -1) {
            retExpanded.expanded_coaches.push(_expanded[el]);
          }

          if (el.indexOf('file') !== -1) {
            retExpanded.avatar = _expanded[el];
          }
        }

        if (retExpanded.avatar) {
          userFlowStory?.setCoachAvatar({...retExpanded.avatar});
        }

        userFlowStory?.setCoaches([...retExpanded.expanded_coaches]);
        userFlowStory?.setCurrentUserQ(user_questionnaire);
      } catch (error) {
        setError(error.message);
      }
    };

    getUserQ();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QUserId]);

  const sendQ = () => {
    const qId = userFlowStory?.currentQId;

    userFlowStory?.setIsSendAnswer(true);

    const dataAnswers = userFlowStory?.stepsQuestion.map(stepQ => {
      let answer;

      if (stepQ.question.type === ALIAS_TYPES.multipleChoice) {
        answer = stepQ.answerArr.slice();

        if ((answer as string[]).length === 0) {
          answer = null;
        }
      } else if (stepQ.question.type === ALIAS_TYPES.multipleChoiceOpen) {
        answer = stepQ.answerArr.slice();

        if (stepQ.userAnswer !== '') {
          answer.push(stepQ.userAnswer);
        }

        if ((answer as string[]).length === 0) {
          answer = null;
        }
      } else {
        answer = '';

        if (stepQ.answerArr.slice()[0]) {
          answer = answer + stepQ.answerArr.slice()[0];
        }

        answer = answer + stepQ.userAnswer;
      }

      if (answer === '') {
        answer = null;
      }

      return [stepQ.numSection, stepQ.numQuestion, answer];
    });

    const send = async () => {
      try {
        await apiRequest({
          method: 'client.questionnaires.answer',
          params: {
            _id: qId,
            answers: dataAnswers,
            is_finished: true,
          },
        });

        history.goBack();
      } catch (error) {
        if (error.canceled) {
          return;
        }

        setError(error.message);
      }
    };

    send();
  };

  const handleClickBack = () => {
    if (userFlowStory?.currentNumStep === 0) {
      redirect(`/${PATH_TYPE.userQuestionnaire}/about?id=${QUserId}`);
    } else {
      userFlowStory?.setCurrentNumStep(userFlowStory?.currentNumStep - 1);
    }
  };

  const handleClickContinue = () => {
    userFlowStory?.setNextStep();
  };

  const handleClickDone = () => {
    const invalidSteps: number[] = [];

    userFlowStory?.stepsQuestion.forEach((stepQ, numStep) => {
      const err = userFlowStory.isValidQ(stepQ);

      if (err !== '') {
        invalidSteps.push(numStep);
        stepQ.textError = err;
      }
    });

    if (invalidSteps.length > 0) {
      userFlowStory?.setCurrentNumStep(invalidSteps[0]);
    } else {
      if (!userFlowStory?.isSendAnswers) {
        sendQ();
      }
    }
  };

  return (
    <Observer>
      {() => (
        <div className="QUserFlowStart">
          <ColorWrapper
            containerClass="pl50"
            colorClass="mintGreen height100Percent "
          >
            {userFlowStory && userFlowStory.currentUserQ && (
              <div className={styles.QUserFlowStart}>
                {userFlowStory.currentUserQ.answered ? (
                  <div>{labelYouHaveAlreadyTakenThisQuestionnaire()}</div>
                ) : (
                  <div>
                    <QUserFlowHeader />
                    <QUserProgressBar />
                    <div>
                      {userFlowStory.currentNumStep < userFlowStory.maxStep ? (
                        <div>
                          <QUserFlowQuestionFactory />
                          <div className={styles.butRow}>
                            <CustomButton
                              type="button"
                              classButton={`QuestionnaireUserAnswerBackBut ${styles.backBut}`}
                              onClick={handleClickBack}
                            >
                              <IconPrev customClass="IconPrev" />
                              <span>{labelBack()}</span>
                            </CustomButton>
                            <br />
                            <CustomButton
                              type="button"
                              classButton="QuestionnaireContinueBut"
                              onClick={handleClickContinue}
                            >
                              <span>{labelContinue()}</span>
                              <IconNext customClass="IconNext" />
                            </CustomButton>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.doneContainer}>
                          <div className={styles.doneImgSection}>
                            <img
                              src={questionnaireDone}
                              srcSet={`${questionnaireDone2x} 2x, ${questionnaireDone3x} 3x`}
                              alt="this is questionnaire done image"
                            />
                          </div>
                          <div className={styles.doneTextSection}>
                            <div className={styles.doneText}>
                              <div className={styles.text}>
                                <h2>{labelYouAreAwesome()}</h2>
                                <span>
                                  {labelCongratulationBegin()} <br />
                                  {labelCongratulationsEnd()}
                                </span>
                              </div>
                              <div className={styles.but}>
                                <CustomButton
                                  type="button"
                                  classButton="QuestionnaireContinueBut"
                                  onClick={handleClickDone}
                                >
                                  <span>{labelDone()}</span>
                                </CustomButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </ColorWrapper>
        </div>
      )}
    </Observer>
  );
};

export default memo(QUserFlowStart);
