import React, {useEffect, useRef, useState} from 'react';

import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Edition} from '@yourcoach/shared/api/program';

import Loader from '@src/components/Loader/Loader';
import {t} from '@src/i18n';

import TasksListEditor from './Tasks/TasksListEditor';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.tasks';

const TasksStep = props => {
  const [tasks, setTasks] = useState<any[]>([]);
  const lastEditionId = useRef('');

  const editionsStore = useRef(
    createCollectionStore<Edition>({
      method: 'client.onboarding.editions.read',
      params: {
        _id: props.data._program.editions[0]._id,
      },
    }),
  ).current;

  useEffect(() => {
    const fetchEdition = async () => {
      const editionResult = (await apiRequest({
        method: 'client.onboarding.editions.read',
        params: {
          _id: props.data._program.editions[0]._id,
        },
      })) as {onboarding_edition: Edition};

      setTasks(editionResult.onboarding_edition.tasks);
      lastEditionId.current = editionResult.onboarding_edition._id;
    };

    fetchEdition();
  }, [editionsStore, props.data._program.editions]);

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'])}
      step={9}
      fieldName="_tasks"
      {...props}
    >
      {tasks.length > 0 ? <TasksListEditor initialTasks={tasks} /> : <Loader />}
    </EditorStep>
  );
};

export default TasksStep;
