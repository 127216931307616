import type {RouteComponentProps} from 'react-router-dom';

import type {CollectionStore, Expand} from '@yourcoach/shared/api';
import type {Course as IMainCourse} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {
  Edition,
  Edition as IMainEdition,
  Program as IMainProgram,
  Program,
} from '@yourcoach/shared/api/program';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

import type {ExpandedClient} from '@src/common/getExpandedClient';
import type {Expanded as ExpandedChannel} from '@src/models/channels';
import type {TSystemFolder} from '@src/models/library';

export type ICategory = {
  category_id: string;
  weight?: number;
};

export interface IValidSchema {
  type: string;
  match?: RegExp;
  minLength?: number;
  maxLength?: number;
  error: {
    type?: string;
    match?: string;
    required?: string;
    minLength?: string;
    maxLength?: string;
  };
}

export interface ICurrentUserStore {
  userExists: PromiseFunction<boolean>;
  user: User & {
    coach_logo?: IFile | null;
  } & {
    avatar?: IFile | null;
  };
  setUser: Function;
  clear: Function;
  fetch: Function;
  localUpdate: Function;
  update: Function;
  getProviderUrl: Function;
  becomeCoach: Function;
}

export type LoginFunction = ({}: {
  email?: string;
  phone?: string;
  code: string;
  agent: string;
  pushToken?: string | null;
  needAdmin?: boolean;
}) => Promise<void>;

export type SignupFunction = ({}: {
  name: string;
  email?: string;
  phone?: string;
  code: string;
  agent: string;
  pushToken?: string | null;
}) => Promise<void>;

export type PromiseFunction<T> = () => Promise<T>;

export type SetPushTokenFunction = (token: string) => Promise<any>;

export interface ILocationStateRRD {
  from: {hash: string; key: string; pathname: string; search: string};
}

export const expand: Expand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      undefined,
      {
        edition: ['contract_id', 'materials.file_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {name: 'Coach not found'},
      {user: ['avatar_id']},
    ],
  ],
};

export const programExpand: Expand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      null,
      {
        edition: ['contract_id', 'materials.file_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

type ExpandedCoach = Partial<User> &
  Required<Pick<User, 'name'>> & {
    avatar: IFile | null;
  };

export interface ProgramExpanded extends Program {
  avatar?: IFile | null;
  background?: IFile | null;
  editions: IMainEdition[];
  expanded_coaches: ExpandedCoach[];
}

export type ProgramT = Program & ProgramExpanded;

export type Coach = User & UserExpanded;

export interface NavigationParams {
  coachId: string;
}

export type Props = RouteComponentProps<NavigationParams>;

export interface FiltersPanelProps {
  fields: Field[];
  store?: CollectionStore<any>;
  autoApply?: boolean;
}

export interface FieldValue {
  name: string;
  label: string;
}

export interface Field {
  name: string;
  label: string;
  type: 'string' | 'boolean' | 'number' | 'date' | 'custom';
  values?: FieldValue[];
  nullable?: boolean;
}

export interface IProgram extends IMainProgram {
  avatar?: IFile | null;
  background?: IFile | null;
  editions: IEdition[];
}

export interface IEdition extends IMainEdition {
  courses: ICourse[];
}

export interface ICourse extends IMainCourse {
  edition: IEdition;
  client: ExpandedClient;
  channels?: ExpandedChannel;
}

export type TScheduleMaterial = Edition['materials'][0] & {
  files: (IFile | null)[];
  type?: string;
};

export type TListDataItem = TSystemFolder | TScheduleMaterial;
