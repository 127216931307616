import {useCallback, useEffect, useRef, useState} from 'react';

const useSelection = <T extends unknown>({
  selection: initialSelection,
  multiselection,
  maxSelection,
  onChange,
  onBeforeSelect,
}: {
  selection: T[];
  multiselection?: boolean;
  maxSelection?: number;
  onChange?: (selection: T[]) => void;
  onBeforeSelect?: (item: T, willSelect: boolean) => boolean;
}) => {
  const [selection, setSelection] = useState(initialSelection);
  const clicked = useRef(false);

  const onSelect = useCallback(
    (item: T) => {
      clicked.current = true;

      if (multiselection) {
        if (selection.indexOf(item) >= 0) {
          if (!onBeforeSelect || onBeforeSelect(item, false)) {
            setSelection(selection.filter(x => x !== item));
          }
        } else {
          if (
            (maxSelection || 0) <= 0 ||
            selection.length < (maxSelection || 0)
          ) {
            if (!onBeforeSelect || onBeforeSelect(item, true)) {
              setSelection(selection.concat(item));
            }
          }
        }
      } else {
        setSelection([item]);
      }
    },
    [maxSelection, multiselection, onBeforeSelect, selection],
  );

  useEffect(() => {
    if (clicked.current && onChange) {
      onChange(selection);
    }

    clicked.current = false;
  }, [selection, onChange]);

  return {selection, setSelection, onSelect};
};

export default useSelection;
