import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

interface Props {
  children: ReactNode;
  firstColClassName?: string;
  secondColClassName?: string;
  allHeight?: boolean;
}

const TwoColumns: FC<Props> = ({
  children,
  firstColClassName = '',
  secondColClassName = '',
  allHeight = true,
}) => {
  return (
    <div className={`TwoColumnsLayouts ${allHeight ? 'percent100H' : ''}`}>
      <div className={firstColClassName} />
      <div className={`secondCol percent100H ${secondColClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default memo(TwoColumns);
