import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useHistory} from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {toJS} from 'mobx';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {
  ExpandedPost,
  PostAttachment as PostAttachmentType,
  UserAttachment,
} from '@yourcoach/shared/api/channel/post';
import {
  isImageAttachment,
  isMaterialAttachment,
  isUserAttachment,
} from '@yourcoach/shared/api/channel/post';
import type {Event} from '@yourcoach/shared/api/event';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Task} from '@yourcoach/shared/api/task';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {markLinks} from '../../../../common/markLinks';
import Loader from '../../../../components/Loader/Loader';
import OtherUserProfileImg from '../../../../components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '../../../../context/App';
import {useForceUpdate} from '../../../../hooks/useForceUpdate';
import Conference from '../../../Conference';
import {ChatAreYouSureModal} from '../../ChatsListContainer/ChatAreYouSureModal/ChatAreYouSureModal';
import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';
import PostAttachment from '../PostAttachment/PostAttachment';

import styles from './../../styles.module.css';
import ImageContainer from './ImageContainer/ImageContainer';
import {updateUserResponse} from './api';

export type PostT = ExpandedPost & {
  inProgress?: boolean;
  highlighted?: boolean;
  uploadingAttachments?: boolean;
};

const SYSTEM_POST_TYPE: Event['type'][] = [
  'membership_created',
  'course_started',
];

interface Props {
  post: PostT;
  isAdminPost?: boolean;
  groupWithNext?: boolean;
  showUserGroups?: boolean;
  showUserAvatar?: boolean;
  showUserName?: boolean;
  hasCourse?: boolean;
  pinnedUI?: boolean;
  isLast?: boolean;
  onClickContextMenu?: (
    selectPost: PostT,
  ) => (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => void;
}

const PostsListItem: FC<Props> = ({
  post,
  isAdminPost,
  groupWithNext,
  showUserGroups,
  showUserAvatar,
  showUserName,
  hasCourse,
  pinnedUI,
  onClickContextMenu = () => {
    return () => {};
  },
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();
  const [conferenceId, setConferenceId] = useState<string | null>(null);
  const [isOpenConference, setIsOpenConference] = useState<boolean>(false);
  const localUser = currentUserStore.user;
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);
  const refreshCcStatusTimer = useRef(0);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const refreshTimer = refreshCcStatusTimer.current;

    return () => {
      clearTimeout(refreshTimer);
    };
  }, []);

  const handleCloseConference = React.useCallback(() => {
    setConferenceId(null);
    setIsOpenConference(false);
  }, []);

  const onUserAvatarPressCb = () => {
    if (onUserAvatarPress) {
      onUserAvatarPress(post.user);
    }
  };

  const onUserAvatarPress = (userPost: PostT['user']) => {
    if (!userPost._id) {
      return;
    }

    if (
      mainChatsLocalStore!.hasCourse &&
      !mainChatsLocalStore!.isOneToOne &&
      mainChatsLocalStore!.channel!.resource!.coach_ids.includes(userPost._id)
    ) {
      // TODO: navigate to practice
    } else {
      // TODO: navigate to user profile
    }
  };

  const onAttachmentPress = (attachment: PostT['attachments'][number]) => {
    if (isImageAttachment(attachment as unknown as PostAttachmentType)) {
    } else if ((attachment as unknown as IFile).categories) {
    } else if (
      isMaterialAttachment(attachment as unknown as PostAttachmentType)
    ) {
    } else if (
      (attachment as unknown as PostAttachmentType)._id.split(':')[0] === 'task'
    ) {
      const task = attachment as unknown as Task;

      if (task.coach_ids.includes(localUser!._id)) {
        history.push({
          pathname: '/to-dos',
          search: `?cid=${mainChatsLocalStore!.channel!.resource_id!}`,
          state: {task: toJS(task)},
        });
      }
    } else if (isUserAttachment(attachment as unknown as PostAttachmentType)) {
      const user = attachment as unknown as UserAttachment;

      if (user.roles.includes('coach')) {
        window.open(`/coaches/${user.slug || user._id}`, '_blank');
      }
    }
  };

  const onAttachmentPressCb = useCallback(
    (attachment: PostT['attachments'][number]) => {
      onAttachmentPress(attachment);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isEventPost = post._id.split(':')[0] === 'event';
  const isMy = !pinnedUI && localUser && post.user_id === localUser._id;
  const user = (isMy ? localUser : post.user) as PostT['user'];

  const imageAttachment = useMemo(
    () =>
      (post.expandedAttachments as unknown[] as PostAttachmentType[]).find(
        isImageAttachment,
      ),
    [post.expandedAttachments],
  ) as IFile | undefined;

  const nonImageAttachments = useMemo(
    () =>
      post.expandedAttachments.filter(
        item =>
          item && !isImageAttachment(item as unknown as PostAttachmentType),
      ),
    [post.expandedAttachments],
  );

  const hasImageAttachment = !!imageAttachment;
  const attachmentGroup = hasImageAttachment && !!post.body;
  const singleAttachment = hasImageAttachment && !post.body;

  const userName =
    isAdminPost && hasCourse ? user.coach_title || '' : user.name;

  const userAvatar = isAdminPost && hasCourse ? user.coach_logo : user.avatar;

  const coachCoverageAttachment = post.expandedAttachments.find(
    a => a.type === 'coach_coverage',
  );

  const postBody = () => {
    if (isEventPost) {
      if (post.body === 'course_started') {
        if (mainChatsLocalStore!.isIndividual) {
          return 'Program started';
        } else {
          return 'Group started';
        }
      } else if (post.body === 'membership_created') {
        if (mainChatsLocalStore!.isIndividual) {
          return `${userName} joined the program`;
        } else {
          return `${userName} joined the group`;
        }
      } else if (post.body === 'task_done') {
        /* @ts-ignore */
        return nonImageAttachments[0].title;
      } else if (post.body === 'conference_started') {
        return 'Live session started';
      } else if (post.body === 'conference_stopped') {
        return 'Live session ended';
      } else if (post.body === 'coach_coverage') {
        return coachCoverageAttachment?.additional_data[0]?.message;
      }
    }

    return post.body;
  };

  const openAreYouSureModal = async (status, coach_history_id) => {
    const result = NiceModal.show(ChatAreYouSureModal, {
      onConfirmButtonClick: () => {
        updateUserResponse(coach_history_id, status).then(() =>
          mainChatsLocalStore?._onRefresh(true),
        );
      },
    });

    if (!result) {
      return;
    }
  };

  const triggeredContextMenu =
    !post.inProgress && !isEventPost ? onClickContextMenu(post) : undefined;

  const renderCCStatus = () => {
    const postAdditionalDataStatuses = coachCoverageAttachment
      ? coachCoverageAttachment.additional_data[0].status
      : [];

    const includesReadyToChange = postAdditionalDataStatuses
      ? postAdditionalDataStatuses.includes('ready_to_change')
      : '';

    const includesDisagreed = postAdditionalDataStatuses
      ? postAdditionalDataStatuses.includes('disagreed')
      : '';

    const includesMessageSent = postAdditionalDataStatuses
      ? postAdditionalDataStatuses.includes('message_sent')
      : '';

    if (!coachCoverageAttachment) {
      return null;
    }

    const ccStatus = coachCoverageAttachment.status;

    const ccStartDate = dayjs(
      datetimeObjToISOString(coachCoverageAttachment.start_date),
    );

    const MINS_BEFORE_CC_START = 5;

    // we allow to response only if before CC start
    // remains more than MINS_BEFORE_CC_START mins
    const shouldAllowToResponse = dayjs().isBefore(
      ccStartDate.subtract(MINS_BEFORE_CC_START, 'minutes'),
    );

    const regularCoachName = coachCoverageAttachment.old_coach?.name;

    clearTimeout(refreshCcStatusTimer.current);

    if (ccStatus === 'message_sent' && shouldAllowToResponse) {
      // force update every second to hide the buttons and show another message
      // TODO: Calculate delay based on the time left to the start of the CC
      refreshCcStatusTimer.current = +setTimeout(() => {
        forceUpdate();
      }, 1000);

      return (
        <div className={styles.OptionButtonsContainer}>
          <div
            onClick={() =>
              openAreYouSureModal(
                'ready_to_change',
                coachCoverageAttachment._id,
              )
            }
            className={styles.YesButton}
          >
            Yes
          </div>
          <div
            onClick={() =>
              openAreYouSureModal('disagreed', coachCoverageAttachment._id)
            }
            className={styles.NoButton}
          >
            No
          </div>
        </div>
      );
    }

    if (ccStatus === 'ready_to_change' || includesReadyToChange) {
      return (
        <div className={styles.ChosenOptionContainer}>
          Thanks for your response! You've chosen to work with another coach
          while {regularCoachName} is away. If you change your mind, you can
          email coaching@yourcoach.health and we'll be able to pause your
          program until your coach returns.
        </div>
      );
    }

    if (ccStatus === 'disagreed' || includesDisagreed) {
      return (
        <div className={styles.ChosenOptionContainer}>
          Thanks for your response! You've chosen to pause your program until{' '}
          {regularCoachName} returns. If you change your mind, you can email
          coaching@yourcoach.health and another coach right away!
        </div>
      );
    }

    if (
      (ccStatus === 'message_sent' || includesMessageSent) &&
      !shouldAllowToResponse &&
      !includesReadyToChange &&
      !includesDisagreed
    ) {
      return (
        <div className={styles.ChosenOptionContainer}>
          We didn't get an answer from you and your program has automatically
          been paused until {regularCoachName} returns. If you change your mind,
          you can email coaching@yourcoach.health and we'll match you to another
          coach right away!
        </div>
      );
    }

    return null;
  };

  return (
    <Observer>
      {() => (
        <div className={`PostsListItem ${styles.PostsListItem}`}>
          {isEventPost &&
          SYSTEM_POST_TYPE.includes(post.body as Event['type']) ? (
            <div className={styles.systemPost}>{postBody()}</div>
          ) : (
            <div className={styles.mainContainer}>
              <div
                className={`${styles.container} ${
                  isMy && !isEventPost ? styles.myContainer : ''
                } ${
                  groupWithNext || attachmentGroup ? styles.groupContainer : ''
                }
            `}
              >
                {!isMy &&
                !showUserAvatar &&
                showUserGroups &&
                (groupWithNext || attachmentGroup) ? (
                  <div className={styles.groupAvatarStub} />
                ) : null}
                {showUserAvatar && !isMy ? (
                  <div onClick={onUserAvatarPressCb}>
                    <div className={styles.avatarContainer}>
                      <OtherUserProfileImg
                        avatar={userAvatar}
                        title={userName}
                      />
                    </div>
                  </div>
                ) : null}
                <div
                  className={`${
                    isMy && !isEventPost
                      ? styles.rightPosition
                      : styles.leftPosition
                  } ${post.body === 'task_done' && styles.taskColor}`}
                  onContextMenu={
                    !post.inProgress &&
                    !isEventPost &&
                    'onclick' in document.documentElement
                      ? onClickContextMenu(post)
                      : undefined
                  }
                  onTouchStart={
                    !post.inProgress &&
                    !isEventPost &&
                    'ontouchstart' in document.documentElement
                      ? onClickContextMenu(post)
                      : undefined
                  }
                >
                  <div className={styles.innerPostContainer}>
                    <div
                      className={`${
                        post.inProgress ? styles.postInProgress : ''
                      } ${post.highlighted ? styles.postHighlighted : ''} ${
                        styles.postTouchable
                      } ${isMy ? styles.myPostTouchable : ''} ${
                        pinnedUI ? styles.pinnedUiPostTouchable : ''
                      }
                  `}
                    >
                      <div
                        className={`${styles.post} ${
                          isAdminPost ? styles.adminBackground : ''
                        } ${isMy ? styles.myPost_flex : ''} ${
                          pinnedUI ? styles.pinnedUiPost : ''
                        } ${
                          singleAttachment && !post.uploadingAttachments
                            ? styles.postWithSingleImageContainer
                            : ''
                        }${
                          (post.body === 'conference_started' ||
                            post.body === 'conference_stopped') &&
                          styles.Live_flex
                        }
                        ${post.body === 'task_done' ? styles.task : ''}`}
                      >
                        {showUserName && !isMy && !singleAttachment ? (
                          <div
                            className={`${styles.userName} ${
                              isMy || isAdminPost
                                ? styles.myOrAdminTextColor
                                : ''
                            }
                        `}
                          >
                            {userName}
                            {pinnedUI ? (
                              <div>
                                {`(${formatDate(
                                  dayjs(datetimeObjToISOString(post.created)),
                                  {withYTT: true},
                                )})`}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {post.uploadingAttachments ? <Loader /> : null}
                        <div className={styles.bodyPost}>
                          {postBody() ? markLinks(postBody()) : null}
                        </div>
                        {singleAttachment ? (
                          <div
                            onClick={() =>
                              onAttachmentPressCb(
                                imageAttachment! as unknown as PostT['attachments'][number],
                              )
                            }
                            onContextMenu={
                              !post.inProgress && !isEventPost
                                ? onClickContextMenu(post)
                                : undefined
                            }
                          >
                            <ImageContainer
                              imageAttachment={imageAttachment}
                              isMy={isMy}
                              pinnedUI={pinnedUI}
                              showUserName={showUserName}
                              userName={userName}
                              post={post}
                            />
                          </div>
                        ) : null}
                        {nonImageAttachments.map(item => (
                          <PostAttachment
                            key={item._id}
                            postId={post._id}
                            attachment={item as unknown as PostAttachmentType}
                            showUserName={showUserName}
                            isMy={isMy}
                            isAdminPost={isAdminPost}
                            onPress={onAttachmentPressCb as any}
                            onLongPress={
                              !post.inProgress && !isEventPost
                                ? onClickContextMenu(post)
                                : undefined
                            }
                          />
                        ))}
                      </div>
                      {nonImageAttachments.map(item => {
                        let attachmentType = (item._id || '').split(':')[0];

                        setConferenceId(item._id);

                        if (attachmentType === 'conference') {
                          if (item.status === 'ongoing') {
                            return (
                              <div key={item._id} className={styles.joinButton}>
                                <div
                                  onClick={() => setIsOpenConference(true)}
                                  className={styles.joinButtonContent}
                                >
                                  Join
                                </div>
                                {isOpenConference ? (
                                  <Conference
                                    conferenceId={conferenceId}
                                    onCloseConference={handleCloseConference}
                                  />
                                ) : null}
                              </div>
                            );
                          }
                        }
                      })}
                      {renderCCStatus()}
                      <div className={styles.timeStamp}>
                        {dayjs(datetimeObjToISOString(post.created)).format(
                          'MM/DD/YY - LT',
                        )}
                      </div>
                    </div>
                  </div>
                  {attachmentGroup ? (
                    // render here image attachment of the same post as separate bubble
                    <div
                      className={`${styles.container} ${
                        isMy ? styles.myContainer : null
                      } ${groupWithNext ? styles.groupContainer : null}
                  `}
                    >
                      <div className={styles.innerPostContainer}>
                        {!isMy && showUserAvatar ? (
                          <div onClick={onUserAvatarPressCb}>
                            <div className={styles.avatarContainer} />
                          </div>
                        ) : null}
                        <div
                          className={`${styles.post} ${
                            isMy ? styles.myPost : null
                          } ${pinnedUI ? styles.pinnedUiPost : null} ${
                            styles.postWithSingleImageContainer
                          }
                    `}
                        >
                          <div
                            onClick={() =>
                              onAttachmentPressCb(
                                imageAttachment! as unknown as PostT['attachments'][number],
                              )
                            }
                            onContextMenu={onClickContextMenu(post)}
                          >
                            <ImageContainer
                              imageAttachment={imageAttachment}
                              isMy={isMy}
                              pinnedUI={pinnedUI}
                              showUserName={showUserName}
                              userName={userName}
                              post={post}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(PostsListItem);
