import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import {TopMenuLayoutPublic} from '..';

interface Props {
  className?: string;
  allHeight?: boolean;
  offsetMenuContainerClass?: string;
  children: ReactNode;
  isThreeColumn?: boolean;
}

const MainContainerPublicPage: FC<Props> = ({
  className = '',
  allHeight = false,
  children,
  offsetMenuContainerClass = '',
  isThreeColumn = false,
}) => {
  return (
    <main className={`MainContainerPublicPage ${className}`}>
      <div className="container">
        <div
          className={`offsetMenuContainer ${
            allHeight ? 'allHeight' : ''
          } ${offsetMenuContainerClass}`}
        >
          <TopMenuLayoutPublic />
          {!isThreeColumn ? children : null}
        </div>
      </div>
      {isThreeColumn ? children : null}
    </main>
  );
};

export default memo(MainContainerPublicPage);
