import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import IconCalendar from '@yourcoach/shared/assets/icons/calendar.svg';
import UserIcon from '@yourcoach/shared/assets/icons/user.svg';
import IconGroupSmall from '@yourcoach/shared/assets/icons/user-group.svg';

import Image from '@src/components/Image';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import type {CourseT} from '.';
import styles from './CoursesListItem.module.css';

interface Props {
  course: CourseT;
  onClick?: (course: CourseT) => void;
}

const CoursesListItem: FC<Props> = ({course, onClick}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const onClickCb = useCallback(() => {
    onClick && onClick(course);
  }, [onClick, course]);

  const status =
    course.status === 'ongoing'
      ? t(['shared', 'course', 'status', course.status])
      : '';

  const avatar = useMemo(
    () => getFileSrc(course.client.avatar, 250).url || '',
    [course],
  );

  return (
    <div className={styles.Component} onClick={onClickCb}>
      <div className={styles.row}>
        <IconCalendar className={styles.icon} />
        <span className={styles.ellipsis}>
          {getCourseDurationString(course) ||
            t([
              'label',
              'course_no_start_date',
              currentUserStore.user?.roles?.includes('coach')
                ? 'coach'
                : 'client',
            ])}
        </span>
      </div>
      {course.edition ? (
        <div className={styles.row}>
          {course.edition.group_size === 1 && course.client_id ? (
            <>
              <Image
                className={styles.icon}
                src={avatar}
                placeholder={<UserIcon />}
              />
              <div className={styles.ellipsis}>{course.client.name}</div>
            </>
          ) : (
            <>
              <IconGroupSmall className={styles.icon} />
              <div className={styles.courseSize}>
                {`${course.counters.memberships.accepted}/${course.edition.group_size}`}
              </div>
            </>
          )}
          <div className={styles.courseStatus}>{status || <>&nbsp;</>}</div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(CoursesListItem);
