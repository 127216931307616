import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {observable} from 'mobx';

import type {ApiRpcRequestParams, CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {
  Membership as IMembership,
  Membership,
} from '@yourcoach/shared/api/membership';
import EmptyProgramsIcon from '@yourcoach/shared/assets/icons/mountain.svg';

import {setError} from '@src/common/setError';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import Program from '@src/components/Program/Program';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {TSystemFolder} from '@src/models/library';

import type {Expanded as CourseExpanded} from '../../../models/courses';
import {expand as courseExpand} from '../../../models/courses';
import {PathBuilderService} from '../../../v2/services/PathBuilderService';

import styles from './styles.module.css';

const I18N_SCOPE = 'WorkspaceJoinedCoursesSubTab';

interface Props {
  tabKey: 'active' | 'archived';
}

const ACTIVE_COURSES_LIMIT = 200;
const LIMIT = 20;

type MembershipT = IMembership & {
  course?: Course & CourseExpanded;
};

interface ILocalStore {
  memberships: [];
  loading: boolean;
  setMemberships: (newMembershipsStore) => void;
  setLoading: (loading: boolean) => void;
  updateMembershipsStore: () => void;
  listData: TSystemFolder[];
}

const MyJoinedProgramsList: FC<Props> = ({tabKey}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const localStore: ILocalStore = useRef(
    observable({
      memberships: [],
      loading: true,
      setLoading(loading) {
        this.loading = loading;
      },
      setMemberships(newMembershipsStore) {
        this.memberships = newMembershipsStore;
      },
      updateMembershipsStore() {
        this.setLoading(true);
        membershipsStore.current
          .fetch()
          .then(() => {
            this.setLoading(false);
          })
          .catch(error => {
            setError(error);
            this.setLoading(false);
          });
      },
      get listData() {
        const result: MembershipT[] = [];

        if (this.memberships.current) {
          const data: MembershipT[] = this.memberships.current.items.slice();

          result.push(...data);
        }

        return result;
      },
    }),
  ).current;

  const storeParams = useMemo(() => {
    const params: ApiRpcRequestParams = {
      sort: [['created', -1]],
      limit: LIMIT,
      expand: {
        membership: [['course_id', null, courseExpand]],
      },
    };

    if (tabKey === 'active') {
      params.limit = ACTIVE_COURSES_LIMIT;
      params.query = [
        [
          'status',
          'in',
          ['accepted', 'active', 'frozen'] as Membership['status'][],
        ],
        ['course_id', '!=', null],
      ];
    } else {
      params.query = [
        ['status', 'in', ['archived'] as Membership['status'][]],
        ['course_id', '!=', null],
      ];
    }

    return params;
  }, [tabKey]);

  const membershipsStore = useRef<CollectionStore<MembershipT>>(
    createCollectionStore({
      method: 'client.memberships.list',
      params: storeParams,
    }),
  );

  useEffect(() => {
    localStore.setMemberships(membershipsStore);
    localStore.updateMembershipsStore();

    return () => {
      if (localStore.memberships) {
        localStore.setMemberships([]);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <Observer>
      {() => (
        <div className={styles.programsContainer}>
          {localStore!.listData.map(item => {
            const {
              course_id,
              program_id,
              course: {edition, program, handleUpdateList},
            } = item as any;

            const {course} = item as any;

            // const isGroup = edition.group_size === 1 ? false : true;
            const isGroup = edition.group_size !== 1;

            // const practiceName = program.expanded_coaches[0].slug
            //   ? program.expanded_coaches[0].slug
            //   : program.expanded_coaches[0]._id;
            //
            // const programName = program.slug ? program.slug : program_id;

            // const urlString = `/coaches/${practiceName}/programs/${programName}/program?sqid=${course_id}&pid=${program_id}`;

            const practiceSlug = program.expanded_coaches[0].slug;
            const practiceId = program.expanded_coaches[0]._id;
            const programSlug = program.slug;
            const programId = program_id;

            const {pathname, search} = PathBuilderService.toCourse(
              {slug: practiceSlug, id: practiceId},
              {slug: programSlug, id: programId},
              course_id,
              programId,
            );

            const urlString = pathname + search;

            const durationString = getCourseDurationString(course);

            return (
              <div
                key={`${item.program_id!}_${item._id}`}
                className={styles.programContainer}
              >
                <NavLink to={urlString} className={styles.programLink}>
                  <Program
                    priceStr=""
                    hidePrice
                    title={program.title}
                    programId={program._id}
                    updateListProgram={handleUpdateList}
                    is_hidden={program.is_hidden}
                    is_closed={program.is_closed}
                    avatar={program.avatar}
                    isCoach={false}
                    coaches={program.expanded_coaches}
                    durationStr={durationString.toString()}
                    isGroup={isGroup}
                  />
                </NavLink>
              </div>
            );
          })}
        </div>
      )}
    </Observer>
  );

  const joinedPrograms = (
    <Observer>
      {() => (
        <>
          {localStore!.listData.length ? (
            content
          ) : (
            <div className={styles.noResultsContainer}>
              <NoResultsHeader
                icon={EmptyProgramsIcon}
                text={t([
                  I18N_SCOPE,
                  'no_results_label',
                  tabKey,
                  currentUserStore.user &&
                  currentUserStore.user.roles.includes('coach')
                    ? 'coach'
                    : 'client',
                ])}
              />
            </div>
          )}
        </>
      )}
    </Observer>
  );

  return (
    <Observer>
      {() => (
        <div className={`MyJoinedProgramsList ${styles.MyJoinedPrograms}`}>
          {localStore.loading ? <Loader /> : joinedPrograms}
        </div>
      )}
    </Observer>
  );
};

export default memo(MyJoinedProgramsList);
