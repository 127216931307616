import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import ReactDOM from 'react-dom';
import InView from 'react-intersection-observer';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import classNames from 'classnames';

import type {Course} from '@yourcoach/shared/api/course';
import type {Program} from '@yourcoach/shared/api/program';
import type {SessionNote} from '@yourcoach/shared/api/sessionNote';
import {sessionNoteStore} from '@yourcoach/shared/api/sessionNote';
import type {User} from '@yourcoach/shared/api/user';
import {ClientSessionNotesStore} from '@yourcoach/shared/stores/sessionNotes/ClientSessionNotes';
import {logger} from '@yourcoach/shared/utils/logger';

import ArrowClosed from '@src/assets/img/ArrowClosed.svg';
import ArrowOpened from '@src/assets/img/ArrowOpened.svg';
import {
  confirm,
  getCustomConfirmAlert,
} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import {CRUSessionNoteModal} from '@src/modules/MyNotes/CRUSessionNoteModal';
import {SessionNoteCard} from '@src/modules/MyNotes/SessionNoteCard';

import * as S from '../styles/stylesNotes';

import AddItemButton from './AddItemButton';

const CONTEXT_MENU_ID = 'session_note_context_menu';

interface Props {
  course: Course & {
    program: Program;
    client: User | (Partial<User> & Required<Pick<User, 'name'>>);
    client_id: string;
  };
}

function Portal({children}) {
  return ReactDOM.createPortal(children, document.body);
}

const SessionNotesContainer = ({course}: Props) => {
  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible(false);

  const [oldCoachNotesActive, setOldCoachNotesActive] =
    useState<boolean>(false);

  const clientSessionNotesStore = useMemo(
    () =>
      new ClientSessionNotesStore({
        clientId: course?.client_id,
        courseId: course?._id || '',
        /* @ts-ignore */
        user: course?.client,
        course,
        program: course?.program,
      }),
    [course],
  );

  useEffect(() => {
    clientSessionNotesStore.fetch();

    return () => {
      clientSessionNotesStore.dispose();
    };
  }, [clientSessionNotesStore]);

  const onAddNoteButtonClick = useCallback(() => {
    NiceModal.show(CRUSessionNoteModal, {
      userId: clientSessionNotesStore.clientId,
      courseId: clientSessionNotesStore.courseId,
      user: clientSessionNotesStore.user,
      course: clientSessionNotesStore.course,
      program: clientSessionNotesStore.program,
    });
  }, [
    clientSessionNotesStore.clientId,
    clientSessionNotesStore.course,
    clientSessionNotesStore.courseId,
    clientSessionNotesStore.program,
    clientSessionNotesStore.user,
  ]);

  const onUpdateButtonClick = useCallback(
    ({props}: ItemParams<{note: SessionNote}>) => {
      NiceModal.show(CRUSessionNoteModal, {
        userId: clientSessionNotesStore.clientId,
        courseId: clientSessionNotesStore.courseId,
        user: clientSessionNotesStore.user,
        course: clientSessionNotesStore.course,
        program: clientSessionNotesStore.program,
        note: props?.note,
      });
    },
    [
      clientSessionNotesStore.clientId,
      clientSessionNotesStore.course,
      clientSessionNotesStore.courseId,
      clientSessionNotesStore.program,
      clientSessionNotesStore.user,
    ],
  );

  const onDeleteButtonClick = useCallback(
    async ({props}: ItemParams<{note: SessionNote}>) => {
      if (props) {
        const {note} = props;

        if (!(await confirm())) {
          return;
        }

        try {
          showOverlay();

          await sessionNoteStore.delete(note);

          logger.event('session_note_deleted');

          hideOverlay();
        } catch (error) {
          hideOverlay();

          logger.error(error);

          getCustomConfirmAlert({
            title: t('shared.message.error_fix'),
            message: error.message,
            buttons: [
              {
                label: t(['shared.button.ok']),
              },
            ],
          });
        }
      }
    },
    [hideOverlay, showOverlay],
  );

  const ContextMenu = useCallback(
    () => (
      <Menu
        id={CONTEXT_MENU_ID}
        animation={animation.fade}
        className="contextMenu"
      >
        <Item onClick={onUpdateButtonClick} className="contextMenuItem">
          {t(['shared.session_notes.actions.edit'])}
        </Item>
        <Item
          onClick={onDeleteButtonClick}
          className={classNames('contextMenuItem', 'danger')}
        >
          {t(['shared.session_notes.actions.delete'])}
        </Item>
      </Menu>
    ),
    [onDeleteButtonClick, onUpdateButtonClick],
  );

  const onMoreButtonClick = useCallback(
    (note: SessionNote, event: React.MouseEvent<Element, MouseEvent>) => {
      contextMenu.show({
        id: CONTEXT_MENU_ID,
        event,
        props: {
          note,
        },
      });
    },
    [],
  );

  const onFetchMoreInViewChange = useCallback(
    isInView => {
      if (isInView) {
        clientSessionNotesStore.fetchMore();
      }
    },
    [clientSessionNotesStore],
  );

  const inEqualIds = (note: SessionNote) =>
    note.coach_id !==
    clientSessionNotesStore.course?.coach_ids[
      clientSessionNotesStore.course?.coach_ids.length - 1
    ];

  const equalIds = (note: SessionNote) =>
    note.coach_id ===
    clientSessionNotesStore.course?.coach_ids[
      clientSessionNotesStore.course?.coach_ids.length - 1
    ];

  const findOldCoachNotes = (arr: SessionNote[], equalityCheck: Function) => {
    return arr.filter(note => equalityCheck(note)).length;
  };

  return (
    <>
      <S.Footer>
        <AddItemButton
          onClickHandler={onAddNoteButtonClick}
          buttonLabel={t('shared.session_notes.add_note_button')}
        />
      </S.Footer>
      <Observer>
        {() =>
          !clientSessionNotesStore.listStore.isLoaded ? <Loader /> : null
        }
      </Observer>
      <Observer>
        {() => (
          <S.ScrollableArea>
            <S.List>
              {findOldCoachNotes(
                clientSessionNotesStore.listStore.items,
                inEqualIds,
              ) > 0 && (
                <>
                  <S.OldCoachTitleWrapper
                    onClick={() => setOldCoachNotesActive(!oldCoachNotesActive)}
                  >
                    <>
                      <S.CoachNotesTitle>
                        {'Previous coach notes'}
                      </S.CoachNotesTitle>
                      <S.OldCoachArrow>
                        {oldCoachNotesActive ? (
                          <ArrowOpened />
                        ) : (
                          <ArrowClosed />
                        )}
                      </S.OldCoachArrow>
                    </>
                  </S.OldCoachTitleWrapper>
                  {oldCoachNotesActive && (
                    <S.OldCoachNotesWrapper>
                      {clientSessionNotesStore.listStore.items
                        .filter(
                          note =>
                            note.coach_id !==
                            clientSessionNotesStore.course?.coach_ids[
                              clientSessionNotesStore.course?.coach_ids.length -
                                1
                            ],
                        )
                        .map(note => (
                          <SessionNoteCard
                            note={note}
                            editable={false}
                            key={note._id}
                            onMoreButtonClick={onMoreButtonClick}
                          />
                        ))}
                    </S.OldCoachNotesWrapper>
                  )}
                </>
              )}
              {findOldCoachNotes(
                clientSessionNotesStore.listStore.items,
                equalIds,
              ) > 0 && (
                <>
                  <S.CoachNotesTitle>{'My notes'}</S.CoachNotesTitle>
                  {clientSessionNotesStore.listStore.items
                    .filter(
                      note =>
                        note.coach_id ===
                        clientSessionNotesStore.course?.coach_ids[
                          clientSessionNotesStore.course?.coach_ids.length - 1
                        ],
                    )
                    .map(note => (
                      <SessionNoteCard
                        note={note}
                        editable={true}
                        key={note._id}
                        onMoreButtonClick={onMoreButtonClick}
                      />
                    ))}
                </>
              )}
              {/* @ts-ignore */}
              <InView as="div" threshold={0} onChange={onFetchMoreInViewChange}>
                <Observer>
                  {() =>
                    clientSessionNotesStore.listStore.cursor.next ? (
                      <Loader size={50} />
                    ) : null
                  }
                </Observer>
              </InView>
            </S.List>
          </S.ScrollableArea>
        )}
      </Observer>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
      <Portal>
        <ContextMenu />
      </Portal>
    </>
  );
};

export default SessionNotesContainer;
