import styled from 'styled-components';

import {Text} from '@yourcoach/shared/uikit/Text';

const Subtitle = styled(Text)`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
`;

export {Subtitle};
