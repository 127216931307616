import type {FC} from 'react';
import React, {memo} from 'react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc, getYcLogoFile} from '@yourcoach/shared/api/media/file';
import {Image} from '@yourcoach/shared/uikit/Image';

import AvatarLoader from '../AvatarLoader/AvatarLoader';

import styles from './styles.module.css';

interface Props {
  title?: string | null | undefined;
  coachName: string;
  practiceImage: IFile | null | undefined;
  isLimitedEdition?: boolean;
}

const Practice: FC<Props> = ({
  practiceImage,
  title = '',
  coachName,
  isLimitedEdition,
}) => {
  const srcUrl = isLimitedEdition
    ? getFileSrc(getYcLogoFile(340)).url || ''
    : getFileSrc(practiceImage, 500).url || '';

  return (
    <div className={`Practice ${styles.Practice}`}>
      <div className={`${styles.imageContainer}`}>
        <div className={`${styles.image}`}>
          <Image
            src={srcUrl}
            blurredCover
            resizeMode="contain"
            placeholder={
              <AvatarLoader
                title={title}
                isBGGradient
                directionGradient="180deg"
              />
            }
          />
        </div>
      </div>
      <div className={`${styles.practiceTitle}`}>
        {isLimitedEdition ? coachName : title}
      </div>
      {!isLimitedEdition ? (
        <div className={`${styles.coachName}`}>{coachName}</div>
      ) : null}
    </div>
  );
};

export default memo(Practice);
