import type {FC} from 'react';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';

import {getCourseDurationString} from '@yourcoach/shared/api/course';

import {IconArrow, IconCalendar, IconGroupSmall} from '@src/components/icons';

import type {Course as ICourse} from '../useMySquads';

import styles from './../styles.module.css';

interface Props {
  course: ICourse;
}

const Course: FC<Props> = ({course}) => {
  let status = '';

  switch (course.status) {
    case 'planned':
      status = '';
      break;
    case 'ongoing':
      status = 'In progress';
      break;
    case 'archived':
      status = 'archived';
      break;
    case 'canceled':
      status = 'canceled';
      break;
  }

  return (
    <Link
      to={`/chats?cid=${course.channel_ids![0]}`}
      className={styles.courseLink}
    >
      <div className={`Course ${styles.Course}`}>
        <div className={`${styles.Items} ${styles.IconCalendar}`}>
          <IconCalendar
            height="20px"
            width="20px"
            className={`${styles.icon}`}
          />
        </div>
        <div className={`${styles.Items} ${styles.date}`}>
          {getCourseDurationString(course)}
        </div>
        <div className={`${styles.Items} ${styles.status}`}>{status}</div>
        <div
          className={`${styles.Items} ${styles.size} ${
            !status ? styles.sizeGreen : ''
          }`}
        >
          <IconGroupSmall
            height="20px"
            width="20px"
            className={`${styles.icon}`}
          />
          <div className={`${styles.sizeText}`}>
            {course.counters.memberships.accepted}/{course.edition.group_size}
          </div>
        </div>
        <div className={`${styles.Items} ${styles.IconArrow}`}>
          <IconArrow className={`${styles.icon}`} />
        </div>
      </div>
    </Link>
  );
};

export default memo(Course);
