import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {reaction, runInAction} from 'mobx';

import type {PostAttachment as PostAttachmentT} from '@yourcoach/shared/api/channel/post';
import {postExpand} from '@yourcoach/shared/api/channel/post';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import {
  labelNoResultsDescriptionClient,
  labelNoResultsDescriptionCoach,
} from '@src/common/i18n/i18nChannel';
import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import GetUserFile from '@src/components/GetUserFile/GetUserFile';
import NoResults from '@src/components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import {expand as channelExpand} from '@src/models/channels';
import CourseMaterials from '@src/modules/SeeProgram/Squad/CourseMaterials/CourseMaterials';

import {emitter} from '../../../../widget/src/utils';
import ChatsContext from '../context/ChatsContext';
import type {IChatsLocalStore} from '../context/useChatsLocalStore';
import {SUPPORTED_EVENTS} from '../context/useChatsLocalStore';

import styles from './../styles.module.scss';
import ChannelPinnedPosts from './ChannelPinnedPosts/ChannelPinnedPosts';
import ChatMain from './ChatMain/ChatMain';
import ChatMainTextEditor from './ChatMainTextEditor/ChatMainTextEditor';
import ChatMainTopMenu from './ChatMainTopMenu/ChatMainTopMenu';
import MainChatsContext from './context/MainChatsContext';
import type {
  ChannelT,
  IMainChatsLocalStore,
  Post,
  PostWithGroup,
} from './context/useMainChatsLocalStore';
import {isFileAttachment} from './context/useMainChatsLocalStore';

interface Props {}

const ChatMainContent: FC<Props> = () => {
  const history = useHistory();
  const {
    stores: {
      currentUserStore,
      courseStore,
      postStore,
      channelStore,
      eventStore,
      fileStore,
    },
  } = useContext(AppContext);
  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);
  const user = currentUserStore.user;
  const localStore: IMainChatsLocalStore | null = useContext(MainChatsContext);

  const noResultText = user?.roles?.includes('coach')
    ? labelNoResultsDescriptionCoach()
    : labelNoResultsDescriptionClient();

  useEffect(() => {
    let timerId = 0;

    const disposeCourseUpdate = reaction(
      () => courseStore.updating,
      updating => {
        if (
          localStore!.hasCourse &&
          updating.success &&
          updating.entity &&
          updating.entity._id === localStore!.channel!.resource_id
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore!.channel!.resource![key] = updating.entity![key];
          });
        }
      },
    );
    const disposeCourseDeleting = reaction(
      () => courseStore.deleting,
      deleting => {
        if (
          localStore!.hasCourse &&
          deleting.success &&
          deleting.entity &&
          deleting.entity._id === localStore!.channel!.resource_id
        ) {
          clearTimeout(timerId);
          // @ts-ignore
          timerId = setTimeout(() => {
            history.goBack();
          }, 300);
        }
      },
    );
    const disposePostCreate = reaction(
      () => postStore.creating,
      creating => {
        // FYI: getting params directly from props to prevent caching (closure)
        const channelId = localStore!.channel?._id || '-1';

        if (creating.success && creating.entity!.channel_id === channelId) {
          localStore!.posts.unshift(creating.entity as PostWithGroup);
        }
      },
    );
    const disposePostUpdate = reaction(
      () => postStore.updating,
      updating => {
        const channelId = localStore!.channel?._id || '-1';

        if (updating.success && updating.entity!.channel_id === channelId) {
          const updatedPostIndex = localStore!.posts.findIndex(
            item => item._id === (updating.entity as Post)._id,
          );

          if (updatedPostIndex >= 0) {
            const updatedPost = localStore!.posts[updatedPostIndex];

            if (updatedPost.is_pinned && !updating.entity!.is_pinned) {
              localStore!.pinnedPostsStore.removeItem(updating.entity as Post);
            }

            localStore!.posts[updatedPostIndex] = {
              ...localStore!.posts[updatedPostIndex],
              ...(updating.entity as PostWithGroup),
            };
          }
        }
      },
    );
    const disposePostDeleting = reaction(
      () => postStore.deleting,
      deleting => {
        const channelId = localStore!.channel?._id || '-1';

        if (deleting.entity && deleting.entity.channel_id !== channelId) {
          return;
        }

        const postIndex = localStore!.posts.findIndex(
          item => item._id === (deleting.entity as Post)._id,
        );

        if (postIndex >= 0) {
          if (deleting.inProgress) {
            localStore!.posts[postIndex] = {
              ...localStore!.posts[postIndex],
              inProgress: true,
            };
          } else if (deleting.success) {
            localStore!.posts.splice(postIndex, 1);

            if (deleting.entity!.is_pinned) {
              localStore!.pinnedPostsStore.removeItem(deleting.entity as Post);
            }
          } else {
            localStore!.posts[postIndex] = {
              ...localStore!.posts[postIndex],
              inProgress: true,
              error: deleting.error,
            };
          }
        }
      },
    );
    const disposeChannelUpdate = reaction(
      () => channelStore.updating,
      async updating => {
        const channelId = localStore!.channel?._id || '-1';

        if (
          localStore!.channel &&
          updating.success &&
          updating.entity!._id === channelId
        ) {
          Object.keys(updating.entity!).forEach(key => {
            localStore!.channel![key] = updating.entity![key];
          });

          if (
            localStore!._accessProblemsShouldBeResolved &&
            !localStore!.userIsFrozen
          ) {
            localStore!.setAccessProblemsShouldBeResolved(false);

            await localStore!._onRefresh();
          }
        }
      },
    );
    const disposeReaction = reaction(
      () =>
        localStore!.channel &&
        localStore!.channel._id &&
        localStore!.showMoreBtn &&
        localStore!.userIsFrozen,
      async () => {
        if (localStore!.channel) {
          if (localStore!.userIsFrozen) {
            return;
          }

          if (!localStore!.isLoaded) {
            localStore!.pinnedPostsStore
              .fetch({
                channel_id: localStore!.channel._id,
              })
              .catch(() => {});

            await localStore!._fetchPosts();

            await eventStore!.markReadAll({
              query: [
                ['type', '==', 'post_created'],
                ['context_ids', 'in', [localStore!.channel?._id]],
              ],
              limit: 500,
            });
          }
        }
      },
    );
    const disposeSelectChannel = reaction(
      () => chatsLocalStore && chatsLocalStore.selectChannel,
      async selectChannel => {
        localStore?._clearPosts();
        localStore?.setChannel(selectChannel);

        if (selectChannel) {
          if (localStore!.userIsFrozen) {
            return;
          }

          localStore!.pinnedPostsStore
            .fetch({
              channel_id: selectChannel._id,
            })
            .catch(() => {});

          await localStore!._fetchPosts();

          await eventStore!.markReadAll({
            query: [
              ['type', '==', 'post_created'],
              ['context_ids', 'in', [selectChannel._id]],
            ],
            limit: 500,
          });
        }

        if (chatsLocalStore!.withUser) {
          _checkChannelWithUserExists();
        }
      },
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    return () => {
      disposeCourseUpdate();
      disposeCourseDeleting();
      disposePostCreate();
      disposePostUpdate();
      disposePostDeleting();
      disposeChannelUpdate();
      disposeReaction();
      disposeSelectChannel();
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      localStore!._clearPosts();
      localStore!.clear();
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = async msg => {
    if (localStore!.channel) {
      if (
        msg.event.type === 'post_created' &&
        msg.channel._id === localStore!.channel._id
      ) {
        await localStore!._onRefresh(true);
      } else if (
        SUPPORTED_EVENTS.includes(msg.event.type) &&
        localStore!.channel.resource_id === msg.course._id
      ) {
        await localStore!._onRefresh(true);
      }
    }
  };

  const _checkChannelWithUserExists = async () => {
    const withUser = chatsLocalStore!.withUser;

    try {
      const channel = (await channelStore!.create({
        user_ids: [...withUser!._id, user!._id],
        dry_run: true,
        expand: channelExpand,
      })) as ChannelT;

      if (channel) {
        chatsLocalStore!.setSelectChannelIdUrl(channel._id);
      } else {
        localStore!.setIsLoaded(true);
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _onDeleteChannelBtnPress = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => _deleteChannel(),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteChannel = async () => {
    try {
      localStore!.setIsLoaded(false);

      await channelStore.delete(localStore!.channel!);

      localStore!.setIsLoaded(true);
    } catch (error) {
      localStore!.setIsLoaded(true);

      getCustomConfirmAlert({
        title: t('shared.message.deleting_error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              _deleteChannel();
            },
            type: 'confirm',
          },
        ],
      });

      setError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _onLeaveChannelBtnPress = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => _leaveChannel(),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _leaveChannel = async () => {
    try {
      localStore!.setIsLoaded(false);

      // TODO: Add ability to unjoin channel/session
      // @ts-ignore
      await channelStore.unjoin(localStore!.channel, user);

      localStore!.setIsLoaded(true);
    } catch (error) {
      localStore!.setIsLoaded(true);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              _leaveChannel();
            },
            type: 'confirm',
          },
        ],
      });

      setError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _showRecentPosts = async () => {
    localStore!._clearPosts();

    await localStore!._fetchPosts({
      silent: true,
    });
  };

  const _onSendBtnPress = () => {
    if (localStore!.editablePost) {
      _editPost(_getPostData());
    } else {
      _createPost(_getPostData());
    }
  };

  const _getPostData = () => ({
    attachments: localStore!.attachments.slice(),
    body: localStore!.text.trim(),
  });

  const _uploadAttachmentsIfNeeded = async (
    post: Post,
    attachments: PostAttachmentT[],
  ) => {
    try {
      const fileAttachment = attachments.find(isFileAttachment) as
        | IFile
        | undefined;

      let wasUploads = false;

      if (fileAttachment && !fileAttachment._id) {
        postStore.localUpdate(post, {
          uploadingAttachments: true,
        });

        const file = await fileStore!.upload(getFileSrc(fileAttachment).url!);

        wasUploads = true;

        const fileAttachmentIndex = attachments.findIndex(isFileAttachment);

        if (fileAttachmentIndex >= 0) {
          attachments[fileAttachmentIndex] = file;
        }

        postStore.localUpdate(post, {
          uploadingAttachments: false,
        });
      }

      return {attachments, wasUploads};
    } catch (error) {
      postStore.localUpdate(post, {
        uploadingAttachments: false,
      });

      throw error;
    }
  };

  const _createPost = async (data: {
    body: string;
    attachments: PostAttachmentT[];
  }) => {
    const withUser = chatsLocalStore?.withUser;

    try {
      if (!localStore!.channel && withUser) {
        runInAction(() => {
          postStore.creating.inProgress = true;
        });

        const channel = (await channelStore.create({
          user_ids: [withUser._id, user!._id],
          expand: channelExpand,
        })) as ChannelT;

        chatsLocalStore?.setSelectChannelIdUrl(channel._id);
      }

      const {body, attachments} = data;

      const attachmentsIds = attachments
        .filter(item => item._id)
        .map(item => item._id);

      const post = (await postStore.create({
        channel_id: localStore!.channel!._id,
        body,
        attachment_ids: attachmentsIds,
        expand: postExpand,
      })) as Post;

      runInAction(() => {
        localStore!.text = '';

        localStore!.attachments = [];
      });

      try {
        const uploadResult = await _uploadAttachmentsIfNeeded(
          post,
          attachments,
        );

        if (uploadResult.wasUploads) {
          await postStore.update(post, {
            // channel_id: post.channel_id, // TODO:? how to check
            body: post.body,
            attachment_ids: uploadResult.attachments.map(item => item._id),
            expand: postExpand,
          });
        }
      } catch (error) {
        await postStore.delete(post, {
          channel_id: post.channel_id,
        });

        throw error;
      }

      localStore?.setSeeCurrentPostId(post._id);
    } catch (error) {
      runInAction(() => {
        postStore.creating.inProgress = false;
      });

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _editPost = async (data: {
    body: string;
    attachments: PostAttachmentT[];
  }) => {
    try {
      const {body, attachments} = data;

      const attachmentsIds = attachments
        .filter(item => item._id)
        .map(item => item._id);

      const post = (await postStore.update(localStore!.editablePost!, {
        // channel_id: localStore!.channel!._id, // TODO:? how to check
        body,
        attachment_ids: attachmentsIds,
        expand: postExpand,
      })) as Post;

      localStore!._finishEditingPost();

      const uploadResult = await _uploadAttachmentsIfNeeded(post, attachments);

      if (uploadResult.wasUploads) {
        await postStore.update(post, {
          // channel_id: post.channel_id, // TODO:? how to check?
          body: post.body,
          attachment_ids: uploadResult.attachments.map(item => item._id),
          expand: postExpand,
        });
      }
    } catch (error) {
      setError(error);

      getCustomConfirmAlert({
        title: t('shared.message.updating_error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              _editPost(data);
            },
            type: 'confirm',
          },
        ],
      });

      setError(error);
    }
  };

  const changeText = (text: string) => {
    localStore!.setText(text);
  };
  const GetUserFileClose = () => {
    localStore!.setIsOpenModalGetUserFile(false);
  };
  const getUserUploadFile = (files: IFile[]) => {
    if (Array.isArray(files) && files) {
      localStore!._removeFileAttachments();

      localStore!.attachments.push(files[0]);
    }
  };
  const openModalGetUserFile = () => {
    localStore!.setIsOpenModalGetUserFile(true);
  };

  const handleSetIsInsertText = (isInsertText: boolean) => {
    localStore!.setIsInsertText(isInsertText);
  };

  return (
    <Observer>
      {() => (
        <div
          className={`ChatMainContent percent100H ${styles.ChatMainContent}`}
        >
          {chatsLocalStore?.selectChannel ? (
            <div className={styles.ChatMainContentContainer}>
              <ChatMainTopMenu />
              {localStore!.mainTabSelect === 'main' ? (
                <div
                  className={`greyColumnInLayoutContent ${styles.ChatMainTextContainer}`}
                >
                  <ChatMain />
                  <ChatMainTextEditor
                    initialText={localStore?.text || ''}
                    text={localStore!.text}
                    isInsertText={localStore ? localStore.isInsertText : false}
                    setIsInsertText={handleSetIsInsertText}
                    changeText={changeText}
                    getUserFile={openModalGetUserFile}
                    sendDate={_onSendBtnPress}
                  />
                </div>
              ) : localStore!.mainTabSelect === 'pinned' ? (
                <div
                  className={`greyColumnInLayoutContent ${styles.ChatMainTextContainer}`}
                >
                  <ChannelPinnedPosts />
                </div>
              ) : localStore!.mainTabSelect === 'members' ? (
                <div>members</div>
              ) : localStore!.mainTabSelect === 'library' ? (
                <div
                  className={`greyColumnInLayoutContent ${styles.ChatMainTextContainer} ${styles.library}`}
                >
                  <CourseMaterials
                    isMedium
                    courseId={localStore!.channel!.resource_id!}
                    forCoach={localStore!.userIsAdmin}
                  />
                </div>
              ) : null}

              <GetUserFile
                showModal={localStore!.isOpenModalGetUserFile}
                closeModal={GetUserFileClose}
                getFile={getUserUploadFile}
                isOnlyImage
                multiple={false}
              />
            </div>
          ) : (
            <div className="greyColumnInLayoutContent">
              <NoResults text={noResultText} />
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatMainContent);
