import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {
  Currency,
  extractCommission,
  getPriceString,
} from '@yourcoach/shared/utils/money';

import {labelCoursePaymentsListItemUnjoinedCourse} from '@src/common/i18n/i18nCourse';
import {
  labelPaymentBilledTo,
  labelPaymentClient,
  labelPaymentDate,
  labelPaymentPendingOperation,
  labelPaymentSubtotal,
  labelPaymentTax,
  labelPaymentTotal,
  labelPaymentUnsuccessOperation,
} from '@src/common/i18n/i18nPayment';
import AppContext from '@src/context/App';

import type {PaymentT} from '../MyFinances/context/usePaymentLocalStore';

import styles from './styles.module.css';

interface Props {
  payment: PaymentT;
}

const Payment: FC<Props> = ({payment}) => {
  const {
    stores: {currentUserStore, cardStore},
  } = useContext(AppContext);

  if (payment) {
    const paymentMethod = payment.card_id
      ? cardStore.cards[payment.card_id]
      : null;

    const programTitle = payment.program
      ? payment.program.title
      : labelCoursePaymentsListItemUnjoinedCourse();

    const commission = extractCommission(payment.amount);

    const transferToMe =
      currentUserStore.user && payment.transfers[currentUserStore.user._id];

    let date = payment.start_date;

    if (transferToMe && transferToMe.transferred) {
      date = transferToMe.transferred;
    } else if (payment.paid) {
      date = payment.paid;
    } else if (payment.status === 'failed' || payment.status === 'frozen') {
      date = payment.end_date;
    }

    return (
      <Observer>
        {() => (
          <div className={`Payment ${styles.Payment}`}>
            <ul className={styles.cashVoucher}>
              {payment.course ? (
                <li className={styles.noFlex}>
                  <div className={styles.name}>{programTitle}</div>
                  <div className={styles.value}>
                    {getCourseDurationString(payment.course)}
                  </div>
                </li>
              ) : null}
              {transferToMe && payment.user ? (
                <li>
                  <div className={styles.name}>{labelPaymentClient()}</div>
                  <div className={styles.value}>{payment.user.name}</div>
                </li>
              ) : null}
              <li>
                <div className={styles.name}>
                  {labelPaymentTotal()}
                  {':'}
                </div>
                <div className={styles.value}>
                  <div className={styles.amount}>
                    <div>
                      {getPriceString({
                        price: payment.amount,
                        currency: Currency[payment.currency],
                      })}
                    </div>
                    {payment.status === 'failed' ||
                    payment.status === 'frozen' ? (
                      <div className={styles.alarm}>!!!</div>
                    ) : null}
                  </div>
                  {payment.status === 'failed' ||
                  payment.status === 'frozen' ? (
                    <div className={styles.statusText}>
                      {labelPaymentUnsuccessOperation()}
                    </div>
                  ) : null}
                  {payment.status === 'pending' ||
                  payment.status === 'requires_action' ||
                  payment.status === 'processing' ? (
                    <div className={styles.statusText}>
                      {labelPaymentPendingOperation()}
                    </div>
                  ) : null}
                </div>
              </li>
              {commission && transferToMe ? (
                <>
                  <li>
                    <div className={styles.name}>
                      {labelPaymentTax()}
                      {':'}
                    </div>
                    <div className={styles.value}>
                      {getPriceString({
                        price: commission,
                        currency: Currency[payment.currency],
                        intZeroPrice: true,
                      })}
                    </div>
                  </li>

                  <li>
                    <div className={styles.name}>
                      {labelPaymentSubtotal()}
                      {':'}
                    </div>
                    <div className={styles.value}>
                      {getPriceString({
                        price: transferToMe.amount,
                        currency: Currency[payment.currency],
                      })}
                    </div>
                  </li>
                </>
              ) : null}
              {paymentMethod ? (
                <li>
                  <div className={styles.name}>{labelPaymentBilledTo()}</div>
                  <div className={styles.value}>
                    <div>
                      <div className={styles.cardNumber}>
                        {`**** ${paymentMethod.card!.last4}`}
                      </div>
                    </div>
                  </div>
                </li>
              ) : null}
              <li>
                <div className={styles.name}>{labelPaymentDate()}</div>
                <div className={styles.value}>
                  {dayjs(datetimeObjToISOString(date)).format('lll')}
                </div>
              </li>
            </ul>
          </div>
        )}
      </Observer>
    );
  } else {
    return <></>;
  }
};

export default memo(Payment);
