import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as CRUProgramPaymentPlanProps} from './PaymentPlan';
import CRUProgramPaymentPlan, {I18N_SCOPE} from './PaymentPlan';

type Props = Partial<ModalProps> & CRUProgramPaymentPlanProps;

const CRUProgramPaymentPlanModal: React.FC<Props> = ({
  duration,
  paymentPlan,
  onSet,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])}>
    <CRUProgramPaymentPlan
      duration={duration}
      paymentPlan={paymentPlan}
      onSet={onSet}
    />
    {children}
  </Modal>
);

export default React.memo(CRUProgramPaymentPlanModal);
