import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InView from 'react-intersection-observer';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {autorun, reaction} from 'mobx';

import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {courseStore} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {Program} from '@yourcoach/shared/api/program';
import {programIsGroup} from '@yourcoach/shared/api/program';
import handleProgramRequests from '@yourcoach/shared/api/program/utils/handleRequests';
import type {User} from '@yourcoach/shared/api/user';
import ProfileIcon from '@yourcoach/shared/assets/icons/profile.svg';
import {waitFor} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import {
  confirm,
  getCustomConfirmAlert,
} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import {emitter} from '../../../widget/src/utils';
import {PathBuilderService} from '../../v2/services/PathBuilderService';
import type {CourseT} from '../courses/SelectCourse';
import {I18N_SCOPE as SELECT_COURSE_I18N_SCOPE} from '../courses/SelectCourse';
import SelectCourseModal from '../courses/SelectCourse/Modal';

import SeeProgramContext from './context/SeeProgramContext';
import CreateCourse, {
  I18N_SCOPE as CREATE_COURSE_I18N_SCOPE,
} from './CreateCourse/CreateCourse';
import ProgramRequestsListItem from './ProgramRequestsListItem';
import styles from './ProgramRequestsTab.module.css';

const I18N_SCOPE = 'shared.ProgramRequests';
const LIMIT = 20;

export type MembershipT = Membership & {
  user: Partial<User> &
    Required<Pick<User, 'name'>> & {
      avatar?: IFile | null;
    };
  course?: Course | null;
  program?: Program | null;
};

interface Props {}

const ProgramRequestsTab: React.FC<Props> = ({}) => {
  const history = useHistory();

  const {
    stores: {programStore, editionStore, currentUserStore},
  } = useContext(AppContext);

  const seeProgramLocalStore = useContext(SeeProgramContext);

  const lastEdition = useMemo(
    () =>
      seeProgramLocalStore!.program!.editions[
        seeProgramLocalStore!.program!.editions.length - 1
      ],
    [seeProgramLocalStore],
  );

  const lastEditionIsSigned = useMemo(
    () =>
      lastEdition
        ? lastEdition.coach_ids.every(coachId => {
            return lastEdition!.coaches[coachId].signed;
          })
        : true,
    [lastEdition],
  );

  const isMainCoach = useMemo(
    () =>
      currentUserStore.user &&
      seeProgramLocalStore!.program!.user_id === currentUserStore.user._id,
    [currentUserStore.user, seeProgramLocalStore],
  );

  const isIndividual = useMemo(
    () => (lastEdition ? lastEdition.group_size === 1 : false),
    [lastEdition],
  );

  const [selection, setSelection] = useState<MembershipT[]>([]);
  const [memberships, setMemberships] = useState<MembershipT[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [shouldRenderStub, setShouldRenderStub] = useState(false);
  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();
  const [
    createCourseModalIsOpen,
    showCreateCourseModal,
    hideCreateCourseModal,
  ] = useIsVisible();
  const [
    selectCourseModalIsOpen,
    showSelectCourseModal,
    hideSelectCourseModal,
  ] = useIsVisible();

  const createCourseModalIsOpenRef = useRef(false);
  const createdCourse = useRef<CourseT>();
  const selectCourseModalIsOpenRef = useRef(false);
  const selectCourseForCurrentProgram = useRef(true);
  const selectedCourse = useRef<CourseT>();

  const membershipsStore = useRef(
    createCollectionStore<MembershipT>({
      method: 'coach.memberships.list',
      params: {
        limit: LIMIT,
        sort: [['created', -1]],
        query: [
          ['program_id', '==', seeProgramLocalStore!.program!._id],
          ['status', 'in', ['requested'] as MembershipT['status'][]],
        ],
        expand: {
          membership: [
            [
              'user_id',
              {name: 'User not found'},
              {
                user: ['avatar_id'],
              },
            ],
            'program_id',
            'course_id',
          ],
        },
      },
      withCount: true,
    }),
  ).current;

  useEffect(() => {
    createCourseModalIsOpenRef.current = createCourseModalIsOpen;
  }, [createCourseModalIsOpen]);

  useEffect(() => {
    selectCourseModalIsOpenRef.current = selectCourseModalIsOpen;
  }, [selectCourseModalIsOpen]);

  const fetchData = useCallback(
    async (silent = false) => {
      await membershipsStore.fetch(
        {
          limit: Math.max(membershipsStore.items.length, LIMIT),
        },
        {silent},
      );
    },
    [membershipsStore],
  );

  const handleWsMessage = useCallback(
    msg => {
      const program = seeProgramLocalStore!.program!;

      if (
        msg.event.type.includes('membership') &&
        msg.membership.program_id === program._id
      ) {
        fetchData(true);
      }
    },
    [fetchData, seeProgramLocalStore],
  );

  useEffect(() => {
    const disposers = [
      autorun(() => {
        setMemberships(membershipsStore.items.slice());
        setIsFetching(
          !membershipsStore.isLoaded || membershipsStore.isFetching,
        );
        setShouldRenderStub(
          membershipsStore.isLoaded && !membershipsStore.hasItems,
        );
      }),
      reaction(
        () => programStore.updating,
        updating => {
          if (updating.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => editionStore.creating,
        creating => {
          if (creating.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => editionStore.updating,
        updating => {
          if (updating.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => editionStore.deleting,
        deleting => {
          if (deleting.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => courseStore.creating,
        creating => {
          if (creating.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => courseStore.updating,
        updating => {
          if (updating.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => courseStore.deleting,
        deleting => {
          if (deleting.success) {
            fetchData(true);
          }
        },
      ),
      reaction(
        () => membershipsStore.count,
        count => {
          seeProgramLocalStore!.requestsCount = count as number;
        },
      ),
    ];

    const timeout = setTimeout(() => {
      fetchData();
    }, 500);

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);

    return () => {
      disposers.forEach(dispose => dispose());

      clearTimeout(timeout);

      membershipsStore.clear();

      emitter.off(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMembershipSelect = useCallback(
    (membership: MembershipT) => {
      setSelection(prev => {
        const index = prev.findIndex(
          (item: MembershipT) => item._id === membership._id,
        );

        if (index >= 0) {
          prev.splice(index, 1);
        } else if (isIndividual) {
          prev = [membership];
        } else {
          prev.push(membership);
        }

        return [...prev];
      });
    },
    [isIndividual],
  );

  const fetchMoreMemberships = useCallback(async () => {
    if (membershipsStore.hasMore) {
      try {
        await membershipsStore.fetch(
          {
            offset: membershipsStore.items.length,
          },
          {
            silent: true,
          },
        );
      } catch (error) {
        logger.error(error);
      }
    }
  }, [membershipsStore]);

  const onMoreMembershipsInViewChange = useCallback(
    isInView => {
      if (isInView) {
        fetchMoreMemberships();
      }
    },
    [fetchMoreMemberships],
  );

  const onCourseCreated = useCallback(
    (course: CourseT) => {
      createdCourse.current = course;

      hideCreateCourseModal();

      showOverlay();
    },
    [hideCreateCourseModal, showOverlay],
  );

  const createCourse = useCallback(
    () =>
      new Promise<Course | void>(resolve => {
        showCreateCourseModal();

        setTimeout(() => {
          waitFor(
            () => createCourseModalIsOpenRef.current === false,
            () => {
              resolve(createdCourse.current);
            },
          );
        }, 300);
      }),
    [showCreateCourseModal],
  );

  const onCourseSelect = useCallback(
    (courses: CourseT[]) => {
      const [course] = courses;

      selectedCourse.current = course;

      hideSelectCourseModal();

      showOverlay();
    },
    [hideSelectCourseModal, showOverlay],
  );

  const getCourse = useCallback(
    () =>
      new Promise<Course | void>(resolve => {
        showSelectCourseModal();

        setTimeout(() => {
          waitFor(
            () => selectCourseModalIsOpenRef.current === false,
            () => {
              resolve(selectedCourse.current);
            },
          );
        }, 300);
      }),
    [showSelectCourseModal],
  );

  const handleRequestsResult = useCallback(
    (
      result: any,
      action: Parameters<typeof handleProgramRequests>['0']['action'],
      target?: Parameters<typeof handleProgramRequests>['0']['target'],
    ) => {
      if (!result) {
        return;
      }

      if (result.message) {
        getCustomConfirmAlert({
          title: t('shared.message.error'),
          message: result.message,
          buttons: [
            {
              label: t('shared.button.ok'),
              onClick: () => {},
            },
          ],
        });

        return;
      }

      const succeededMemberships: MembershipT[] = [];
      const existedMemberships: MembershipT[] = [];
      const failedMemberships: MembershipT[] = [];

      result.forEach((item, index: number) => {
        if (apiRequest.isRpcRequestError(item)) {
          if (item.data.reason === 'Membership already exists') {
            existedMemberships.push(selection[index]);
          } else {
            failedMemberships.push(selection[index]);
          }
        } else {
          succeededMemberships.push(selection[index]);

          membershipsStore.removeItem(selection[index]);
        }
      });

      setSelection([]);

      const _isIndividual = selectCourseForCurrentProgram.current
        ? isIndividual
        : !programIsGroup(
            (selectedCourse.current! || createdCourse.current!).program,
          );

      if (
        succeededMemberships.length ||
        existedMemberships.length ||
        failedMemberships.length
      ) {
        let title = '';
        let message = '';

        if (action === 'decline') {
          return;
        }

        if (succeededMemberships.length) {
          if (target === 'new_course') {
            title = t('shared.label.great_job');
            message = t(
              [
                I18N_SCOPE,
                'invited_to_new_course_memberships_message',
                _isIndividual ? 'individual' : 'group',
              ],
              {
                count: succeededMemberships.length,
              },
            );
          } else {
            title = selectCourseForCurrentProgram.current
              ? t('shared.label.get_coaching')
              : t('shared.label.all_set');

            message = t(
              [
                I18N_SCOPE,
                selectCourseForCurrentProgram.current
                  ? 'accepted_memberships_message'
                  : 'invited_memberships_message',
                _isIndividual ? 'individual' : 'group',
              ],
              {
                count: succeededMemberships.length,
                userNames: succeededMemberships
                  .map(item => item.user_name)
                  .join(', '),
              },
            );
          }
        }

        if (existedMemberships.length) {
          title = t('shared.message.error');

          message = message
            ? `${message}${message[message.length - 1] === '.' ? ' ' : '. '}`
            : '';

          message = t(
            [
              I18N_SCOPE,
              'existed_memberships_error',
              _isIndividual ? 'individual' : 'group',
            ],
            {
              count: existedMemberships.length,
              userNames: existedMemberships
                .map(item => item.user_name)
                .join(', '),
            },
          );
        }

        if (failedMemberships.length) {
          title = t('shared.message.error');

          message = message
            ? `${message}${message[message.length - 1] === '.' ? ' ' : '. '}`
            : '';

          message = `${message}${t([I18N_SCOPE, 'failed_memberships_error'], {
            count: failedMemberships.length,
            userNames: failedMemberships.map(item => item.user_name).join(', '),
          })}`;
        }

        if (!title && !message) {
          return;
        }

        getCustomConfirmAlert({
          title,
          message,
          buttons: [
            {
              label: t('shared.button.ok'),
              onClick: () => {
                if (succeededMemberships.length) {
                  const course =
                    createdCourse.current || selectedCourse.current;

                  if (course) {
                    // history.push(
                    //   `/coaches/${
                    //     currentUserStore.user!.slug ||
                    //     currentUserStore.user!._id
                    //   }/programs/${
                    //     // TODO: Add program to Course type
                    //     // @ts-ignore
                    //     course.program ? course.program.slug : course.program_id
                    //   }/program?sqid=${course._id}&pid=${course.program_id}`,
                    // );
                    history.push(
                      PathBuilderService.toCourse(
                        {
                          slug: currentUserStore.user!.slug,
                          id: currentUserStore.user!._id,
                        },
                        {slug: course.program.slug, id: course.program_id},
                        course._id,
                        course.program_id,
                      ),
                    );
                  }
                }
              },
            },
          ],
        });
      }
    },
    [currentUserStore.user, history, isIndividual, membershipsStore, selection],
  );

  const handleRequests = useCallback(
    async (
      action: Parameters<typeof handleProgramRequests>['0']['action'],
      target?: Parameters<typeof handleProgramRequests>['0']['target'],
    ) => {
      try {
        createdCourse.current = undefined;
        selectedCourse.current = undefined;

        if (action === 'decline') {
          showOverlay();
        }

        const result = await handleProgramRequests({
          edition: lastEdition!,
          requests: selection,
          action,
          target,
          createCourse,
          getCourse,
        });

        if (result) {
          handleRequestsResult(result, action, target);

          if (action === 'accept') {
            logger.event('join_requests_accepted', {
              action: target || action,
            });
          } else if (action === 'decline') {
            logger.event('join_requests_declined');
          }

          fetchData(true);
        }

        hideOverlay();
      } catch (error) {
        hideOverlay();

        handleRequestsResult(error, action, target);

        fetchData(true);

        setError(error);
      }
    },
    [
      createCourse,
      fetchData,
      getCourse,
      handleRequestsResult,
      hideOverlay,
      lastEdition,
      selection,
      showOverlay,
    ],
  );

  const onAcceptButtonClick = useCallback(() => {
    handleRequests('accept');
  }, [handleRequests]);

  const onAddToCourseButtonClick = useCallback(() => {
    selectCourseForCurrentProgram.current = true;

    handleRequests('accept');
  }, [handleRequests]);

  const onCreateCourseButtonClick = useCallback(() => {
    handleRequests('accept', 'new_course');
  }, [handleRequests]);

  const onInviteToAnotherProgramButtonClick = useCallback(() => {
    selectCourseForCurrentProgram.current = false;

    handleRequests('accept', 'other_program');
  }, [handleRequests]);

  const onDeclineRequestsButtonClick = useCallback(async () => {
    if (!(await confirm())) {
      return;
    }

    handleRequests('decline');
  }, [handleRequests]);

  const courseIds = useMemo(
    () => ({
      include: [],
      exclude: selection.map(item => item.course_id!).filter(Boolean),
    }),
    [selection],
  );

  return (
    <div className={`RequestsTab ${styles.Component}`}>
      {isFetching ? (
        <Loader />
      ) : shouldRenderStub ? (
        <div className={styles.noResultsContainer}>
          <NoResultsHeader
            icon={ProfileIcon}
            text={t([I18N_SCOPE, 'no_results_label'])}
          />
        </div>
      ) : (
        <>
          <div className={styles.actionsContainer}>
            {isIndividual ? (
              <>
                <Button
                  disabled={!selection.length || !lastEditionIsSigned}
                  onClick={onAcceptButtonClick}
                >
                  {t([I18N_SCOPE, 'accept_button'])}
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={!selection.length || !lastEditionIsSigned}
                  onClick={onAddToCourseButtonClick}
                >
                  {t([I18N_SCOPE, 'add_to_course_button'])}
                </Button>
                {isMainCoach ? (
                  <>
                    <Button
                      disabled={!selection.length || !lastEditionIsSigned}
                      onClick={onCreateCourseButtonClick}
                    >
                      {t([I18N_SCOPE, 'create_course_button'])}
                    </Button>
                    <Button
                      disabled={!selection.length || !lastEditionIsSigned}
                      onClick={onInviteToAnotherProgramButtonClick}
                    >
                      {t([I18N_SCOPE, 'invite_to_another_program_button'])}
                    </Button>
                  </>
                ) : null}
              </>
            )}
            <Button
              disabled={!selection.length || !lastEditionIsSigned}
              onClick={onDeclineRequestsButtonClick}
            >
              {t([I18N_SCOPE, 'decline_button'])}
            </Button>
          </div>
          <div className={styles.list}>
            {memberships.map(membership => (
              <ProgramRequestsListItem
                key={membership._id}
                membership={membership}
                isSelected={
                  !!selection.find(item => item._id === membership._id)
                }
                onSelect={onMembershipSelect}
              />
            ))}
          </div>
          <InView
            as="div"
            threshold={0}
            onChange={onMoreMembershipsInViewChange}
          >
            <Observer>
              {() => (membershipsStore.hasMore ? <Loader size={50} /> : null)}
            </Observer>
          </InView>
        </>
      )}

      <ModalAnimateWin
        showModal={createCourseModalIsOpen}
        closeModalHandler={hideCreateCourseModal}
        className="greyHeaderContainer littleContainer w610 List"
        isBody
        classNameBody={`whiteBody maxContent noPadding ${styles.inviteContainer}`}
        header={t([CREATE_COURSE_I18N_SCOPE, 'title'])}
        classNameHeader="greyHeader w610"
        classNameCloseBut="greyHeaderBut"
      >
        <CreateCourse
          program={seeProgramLocalStore!.program!}
          onCreate={onCourseCreated}
        />
      </ModalAnimateWin>
      <SelectCourseModal
        {...(!selectCourseForCurrentProgram.current
          ? {
              title: t([SELECT_COURSE_I18N_SCOPE, 'select_program_label']),
            }
          : null)}
        isOpen={selectCourseModalIsOpen}
        onAfterClose={hideSelectCourseModal}
        programIds={{
          include: selectCourseForCurrentProgram.current
            ? [seeProgramLocalStore!.program?._id!]
            : [],
          exclude: selectCourseForCurrentProgram.current
            ? []
            : [seeProgramLocalStore!.program?._id!],
        }}
        courseIds={courseIds}
        showPlanned
        confirmBeforeSelect
        showAddRow
        showIndividual={false}
        onSelect={onCourseSelect}
      />
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ProgramRequestsTab);
