import type {FC} from 'react';
import React, {memo} from 'react';

import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {t} from '@src/i18n';

import styles from './styles.module.css';

const I18N_SCOPE = 'MailchimpUnsubscribe';

import Image from '@src/components/Image';

import BENTLEY from './assets/bentley.gif';

interface Props {}

const MailchimpUnsubscribe: FC<Props> = () => {
  const md_email = useURLQueryParam('md_email');
  const mess = t([I18N_SCOPE, 'message_unsubscribe'], {md_email});
  const updateMess = mess
    .split(/<%(.*?)%>/g)
    .filter(Boolean)
    .map((part, i) => {
      const key = `${part}-${i}`;

      if (part.includes('highlight->')) {
        return (
          <span key={key} className={styles.highlight}>
            {part.replace('highlight->', '')}
          </span>
        );
      }

      return part;
    });

  return (
    <div className={`MailchimpUnsubscribe ${styles.MailchimpUnsubscribe}`}>
      <div className={styles.unsubscribeContainer}>
        <div className={styles.title}>{t([I18N_SCOPE, 'title'])}</div>
        <div className={styles.message}>{updateMess}</div>
        <br />
        <div className={styles.messageSad}>{t([I18N_SCOPE, 'bentley'])}</div>
        <div className={styles.bentleyContainer}>
          <Image src={BENTLEY} alt="Bentley" />
        </div>
      </div>
    </div>
  );
};

export default memo(MailchimpUnsubscribe);
