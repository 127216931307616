import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const IconUserFill: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'fill',
}) => {
  return (
    <div className={`IconUserFill ${className}`}>
      <svg
        height={height}
        width={width}
        viewBox="4 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.864 11.136a2.568 2.568 0 100-5.137 2.568 2.568 0 000 5.137zm-2.856.624a4.296 4.296 0 115.717-.004l-.005.004c2.384 1.11 4.007 3.485 4.008 6.24A.864.864 0 1117 18a5.136 5.136 0 00-10.272 0A.864.864 0 115 18a6.864 6.864 0 013.966-6.222l.042-.018z"
          fill="white"
          fillOpacity="0.7"
        />
      </svg>
    </div>
  );
};

export default memo(IconUserFill);
