import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from 'i18n-js';

import {isDateInUsFormat} from '@yourcoach/shared/utils/datetime/isDateInUsFormat';

dayjs.extend(LocalizedFormat);

const DEFAULT_LOCALE = 'en-US';

const locales = {
  'en-US': 'en-US',
};

const _isDateInUsFormat = isDateInUsFormat();

const dateLocaleUS = 'en-ca';
const dateLocaleNonUS = 'en-au';

const dayjsLocales = {
  'en-US': _isDateInUsFormat ? dateLocaleUS : dateLocaleNonUS,
};

export let userLocale =
  typeof navigator !== 'undefined'
    ? navigator.language ||
      // @ts-ignore
      navigator.browserLanguage ||
      // @ts-ignore
      navigator.userLanguage
    : '';

if (userLocale.includes('_')) {
  userLocale = userLocale.replace('_', '-');
}

i18n.defaultLocale = DEFAULT_LOCALE;
i18n.fallbacks = true;

i18n.locale = userLocale;

i18n.missingTranslation = scope =>
  // @ts-ignore
  `NT: ${i18n.currentLocale()}.${i18n.getFullScope(scope)}`;

export const init = async () => {
  try {
    const defaultLocaleDict = await import(
      /* webpackChunkName: "locale-[request]" */
      `@src/locales/${locales[DEFAULT_LOCALE]}`
    );

    const defaultSharedLocaleDict = await import(
      /* webpackChunkName: "shared-locale-[request]" */
      `@yourcoach/shared/locales/${locales[DEFAULT_LOCALE]}`
    );

    i18n.translations = {
      [DEFAULT_LOCALE]: {
        shared: defaultSharedLocaleDict.default,
        number: defaultSharedLocaleDict.default.number || {},
        ...defaultLocaleDict.default,
      },
    };

    if (userLocale !== DEFAULT_LOCALE && locales[userLocale]) {
      try {
        const dict = await import(
          /* webpackChunkName: "locale-[request]" */
          `@src/locales/${locales[userLocale]}`
        );

        const sharedDict = await import(
          /* webpackChunkName: "shared-locale-[request]" */
          `@yourcoach/shared/locales/${locales[userLocale]}`
        );

        i18n.translations = {
          [userLocale]: {
            shared: sharedDict.default,
            number: sharedDict.default.number || {},
            ...dict.default,
          },
        };
      } catch (error) {
        // fallback to default locale
      }
    }

    const defaultDayjsLocaleDict = await import(
      /* webpackChunkName: "dayjs-locale-[request]" */
      /* webpackExclude: /d.ts$/ */
      `dayjs/locale/${dayjsLocales[DEFAULT_LOCALE]}`
    );

    if (userLocale !== DEFAULT_LOCALE && dayjsLocales[userLocale]) {
      try {
        const dayjsDict = await import(
          /* webpackChunkName: "dayjs-locale-[request]" */
          /* webpackExclude: /d.ts$/ */
          `dayjs/locale/${dayjsLocales[userLocale]}`
        );

        dayjs.locale(userLocale, {
          ...defaultDayjsLocaleDict.default,
          ...dayjsDict.default,
        });
      } catch {
        // fallback to default locale
      }
    } else {
      dayjs.locale(userLocale, {
        ...defaultDayjsLocaleDict.default,
      });
    }

    return true;
  } catch (e) {
    // TODO: Log
    // fallback to en

    return true;
  }
};

const dayjsLocalesCommon = {
  'en-US': require('dayjs/locale/en-ca'),
};

dayjs.locale(userLocale, {
  ...dayjsLocalesCommon['en-US'],
  ...dayjsLocalesCommon[userLocale],
});

export const getCurrentDateLocale = () => ({
  weekStart: _isDateInUsFormat ? 0 : 1,
  ...dayjs.Ls[dayjsLocales['en-US']],
  ...dayjs.Ls[userLocale],
});

export const t: (scope: i18n.Scope, options?: i18n.TranslateOptions) => any =
  i18n.t.bind(i18n);

export default i18n;
