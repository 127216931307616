import type {FC} from 'react';
import React, {memo} from 'react';

import SingleChoiceType from '../SingleChoiceType/SingleChoiceType';
import TextType from '../TextType/TextType';

interface Props {}

const SingleChoiceOpenType: FC<Props> = () => {
  return (
    <div className="SingleChoiceOpenType">
      <SingleChoiceType />
      <TextType noError singleOnly />
    </div>
  );
};

export default memo(SingleChoiceOpenType);
