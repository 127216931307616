import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {Observer} from 'mobx-react';

import type {IFile} from '@yourcoach/shared/api/media/file';

import {setError} from '@src/common/setError';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import type {ProgramT, TListDataItem, TScheduleMaterial} from '@src/models/app';
import {programExpand} from '@src/models/app';
import type {TFileWithLink, TSystemFolder} from '@src/models/library';

import LibraryContext from '../../context/LibraryContext';
import type {ILibraryLocalStore} from '../../context/useLibraryLocalStore';
import ListFolderContainer from '../../ListFolderContainer/ListFolderContainer';
import styles from '../../ListFolders/styles.module.scss';
import SystemFolder from '../../ListFolders/SystemFolder/SystemFolder';
import UserFile from '../../ListFolders/UserFile/UserFile';

import ProgramCoursesFolder from './ProgramCoursesFolder/ProgramCoursesFolder';
import {useProgramLocalStore} from './ListProgramLocalStore';

interface Props {}

const ListProgram: FC<Props> = () => {
  const {
    stores: {programStore},
  } = useContext(AppContext);
  const materialId = useURLQueryParam('mid');
  const folderlId = useURLQueryParam('id');
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const programId = useURLQueryParam('pid');
  const localStore = useProgramLocalStore();

  useEffect(() => {
    async function fetchProgram() {
      libraryStory!.setLoading(true);

      if (programId) {
        try {
          const program = (await programStore.coach.fetch({
            _id: programId,
            expand: programExpand,
          })) as ProgramT;

          localStore.setProgram(program);

          const lastEdition = program.editions[program.editions.length - 1];

          if (lastEdition) {
            const materials = lastEdition.materials || [];

            localStore.setMaterial(materials as TScheduleMaterial[]);
          }

          libraryStory!.setLoading(false);
        } catch (error) {
          const tryAgainFn = () => {};

          libraryStory!.setLoading(false);

          setError(error, tryAgainFn);
        }
      }
    }

    fetchProgram();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderlId, materialId]);

  const render = () => {
    if (materialId) {
      const data = localStore!.materials.find(
        (itemProgram: TScheduleMaterial) => itemProgram.uuid === materialId,
      );

      return (
        <ListFolderContainer className={styles.topOffset}>
          {data &&
            (data as TScheduleMaterial).files.map(item => {
              return (
                <UserFile
                  key={(item as IFile)._id}
                  className={styles.UserFile}
                  file={
                    {
                      ...(item as IFile),
                      link: {
                        ...data,
                        resource: null,
                      },
                    } as unknown as TFileWithLink
                  }
                />
              );
            })}
        </ListFolderContainer>
      );
    } else if (folderlId === 'courses') {
      if (programId) {
        return <ProgramCoursesFolder pid={programId} />;
      } else {
        <div />;
      }
    } else {
      return (
        <ListFolderContainer className={styles.topOffset}>
          {localStore!.listData.map((item: TListDataItem) => {
            if (item.type === 'folder') {
              return (
                <SystemFolder
                  key={(item as TSystemFolder)._id!}
                  data={item as TSystemFolder}
                  className={styles.SystemFolder}
                />
              );
            } else {
              return (
                <UserFile
                  key={(item as TScheduleMaterial).files[0]?._id}
                  className={styles.UserFile}
                  file={
                    {
                      ...(item as TScheduleMaterial).files[0],
                      link: {
                        ...item,
                        resource: null,
                      },
                    } as unknown as TFileWithLink
                  }
                />
              );
            }
          })}
        </ListFolderContainer>
      );
    }
  };

  return (
    <Observer>{() => <div className="ListProgram">{render()}</div>}</Observer>
  );
};

export default memo(ListProgram);
