import React from 'react';

import styled from 'styled-components';

import CloseIcon from '@yourcoach/shared/assets/icons/close.svg';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

// import {useController} from './controller';
import {buttonContainerWeb, containerWeb} from './styles';
import type {Props} from './types';

const Container = styled(View)`
  ${containerWeb};
`;

const ButtonContainer = styled(View)`
  ${buttonContainerWeb}
`;

const Removable: React.FC<WithDomStyleProp<Props>> = ({
  children,
  onClick,
  disabled,
  ...rest
}) => {
  // const controller = useController(rest);

  return (
    <Container {...rest}>
      {!disabled && (
        <ButtonContainer>
          <Clickable onClick={onClick}>
            <IconBox bg={'black'} customSize={16} fill={'icon2'} padding={0}>
              <CloseIcon />
            </IconBox>
          </Clickable>
        </ButtonContainer>
      )}
      {children}
    </Container>
  );
};

export {Removable};
