import isEqual from 'react-fast-compare';

import type {Expand} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {fileStore} from '@yourcoach/shared/api/media/file';
import type {Edition} from '@yourcoach/shared/api/program';
import {
  editionStore,
  getUniqueProgramSlug,
  programStore,
} from '@yourcoach/shared/api/program';
import {currentUserStore} from '@yourcoach/shared/api/user';
import {waitForAsync} from '@yourcoach/shared/utils';

import {expand as programExpand} from '@src/modules/programs/utils';

import type {ProgramT} from '..';
import {PROGRAM_AVATAR_UPLOADING_KEY} from '../Description';
import {PROGRAM_CONTRACT_UPLOADING_KEY} from '../Details';

const updateProgram = async (originalProgram: ProgramT, program: ProgramT) => {
  try {
    const originalLastEdition =
      originalProgram.editions[originalProgram.editions.length - 1];

    let lastEdition = (program.editions.slice()[
      program.editions.length - 1
    ] as ProgramT['editions'][0])!;

    type ScheduleItem =
      | typeof lastEdition.conferences[0]
      | typeof lastEdition.tasks[0]
      | typeof lastEdition.materials[0];

    if (
      program.status === 'draft' &&
      lastEdition.payment_plan &&
      lastEdition.payment_plan.processing === 'internal' &&
      lastEdition.payment_plan.total !== 0 &&
      program.expanded_coaches.some(coach => !coach.is_provider)
    ) {
      throw {
        code: 'isnt_provider_coaches',
      };
    }

    const shouldUpdateProgram = () => {
      const keys = ['title', 'description', 'coaches', 'avatar'];

      return keys.some(key => {
        let originalValue = originalProgram[key];
        let lastValue = program[key];

        if (key === 'coaches') {
          originalValue = Object.keys(originalValue).map(userId => ({
            _id: userId,
            role: originalValue[userId].role.toLowerCase(),
            portion: originalValue[userId].portion,
          }));

          lastValue = Object.keys(lastValue).map(userId => ({
            _id: userId,
            role: lastValue[userId].role.toLowerCase(),
            portion: lastValue[userId].portion,
          }));
        }

        return !isEqual(originalValue, lastValue);
      });
    };

    const shouldUpdateEdition = () => {
      const keys = [
        'group_size',
        'conferences',
        'tasks',
        'materials',
        'channels',
        'client_conferences',
      ];

      if (!originalLastEdition) {
        return false;
      }

      return keys.some(
        key => !isEqual((originalLastEdition || {})[key], lastEdition[key]),
      );
    };

    const shouldCreateNewEdition = () => {
      const keys = [
        'duration',
        'payment_plan',
        'contract',
        'private_data_request',
      ];

      if (!originalLastEdition) {
        return true;
      }

      const lastEditionPaymentPlan =
        lastEdition.payment_plan && lastEdition.payment_plan.total
          ? lastEdition.payment_plan
          : null;

      return keys.some(key => {
        const originalValue = (originalLastEdition || {})[key];

        let lastValue = lastEdition[key];

        if (key === 'payment_plan') {
          lastValue = lastEditionPaymentPlan;
        }

        return !isEqual(originalValue, lastValue);
      });
    };

    let updatedProgram: ProgramT | undefined;

    if (shouldUpdateProgram()) {
      const programData: Pick<ProgramT, 'title' | 'description' | 'coaches'> & {
        slug?: ProgramT['slug'];
        avatar_id?: ProgramT['avatar_id'];
        expand?: Expand;
      } = {
        title: program.title,
        description: program.description,
        coaches: program.coaches,
        expand: programExpand,
      };

      let avatarId: string | null = null;

      if (program.status === 'draft') {
        const slug = await getUniqueProgramSlug(
          program.title,
          currentUserStore.user!._id,
        );

        programData.slug = slug;
      }

      if (program.avatar) {
        if ((program.avatar as IFile)._id) {
          avatarId = (program.avatar as IFile)._id;
        } else {
          const uploading = fileStore.uploading.get(
            PROGRAM_AVATAR_UPLOADING_KEY,
          );

          if (uploading) {
            if (uploading.inProgress) {
              await waitForAsync(() => uploading.inProgress === false);
            }

            if (uploading.success && uploading.file) {
              avatarId = uploading.file._id;
            } else if (uploading.error) {
              const file = await fileStore.upload(program.avatar as File);

              avatarId = file._id;
            }
          }
        }
      }

      programData.avatar_id = avatarId;

      updatedProgram = (await programStore.update(
        program,
        programData,
      )) as ProgramT;
    }

    const filterSchedule = (item: ScheduleItem) =>
      item.day <= lastEdition.duration - 1;

    if (shouldCreateNewEdition()) {
      const newEditionData: Pick<
        Edition,
        | 'program_id'
        | 'title'
        | 'group_size'
        | 'duration'
        | 'payment_plan'
        | 'conferences'
        | 'tasks'
        | 'channels'
        | 'private_data_request'
        | 'client_conferences'
      > & {
        expand?: Expand;
        contract_id?: Edition['contract_id'];
        materials: Edition['materials'];
      } = {
        title: `v${program.editions.length + 1}`,
        program_id: program._id,
        group_size: lastEdition.group_size,
        duration: lastEdition.duration,
        payment_plan:
          lastEdition.payment_plan && lastEdition.payment_plan.schedule.length
            ? lastEdition.payment_plan
            : null,
        conferences: lastEdition.conferences.filter(filterSchedule),
        tasks: lastEdition.tasks.filter(filterSchedule),
        channels: [],
        materials: lastEdition.materials.filter(filterSchedule).map(item => {
          const material = {...item};

          // @ts-ignore
          delete material.files;

          return material;
        }),
        private_data_request: lastEdition.private_data_request,
        client_conferences: lastEdition.client_conferences,
        expand: {
          edition: ['contract_id', 'materials.file_ids'],
        },
      };

      let contractId: string | null = null;

      if (lastEdition.contract) {
        if ((lastEdition.contract as IFile)._id) {
          contractId = (lastEdition.contract as IFile)._id;
        } else {
          const uploading = fileStore.uploading.get(
            PROGRAM_CONTRACT_UPLOADING_KEY,
          );

          if (uploading) {
            if (uploading.inProgress) {
              await waitForAsync(() => uploading.inProgress === false);
            }

            if (uploading.success && uploading.file) {
              contractId = uploading.file._id;
            } else if (uploading.error) {
              const file = await fileStore.upload(lastEdition.contract as File);

              contractId = file._id;
            }
          }
        }
      }

      newEditionData.contract_id = contractId;

      let edition = ((await editionStore.create(
        newEditionData,
      )) as ProgramT['editions'][0])!;

      if (program.status === 'active' && edition.coach_ids.length === 1) {
        edition = ((await editionStore.sign(edition, {
          sign: currentUserStore.user!.name,
        })) as ProgramT['editions'][0])!;
      }

      await programStore.update(program, {
        edition_ids: [...program.edition_ids, edition._id],
        expand: programExpand,
      });

      lastEdition = {
        ...lastEdition,
        ...edition,
      };
    } else if (shouldUpdateEdition()) {
      const editionData: Pick<
        Edition,
        '_id' | 'revision' | 'conferences' | 'tasks'
      > & {
        expand?: Expand;
        contract_id?: Edition['contract_id'];
        group_size?: Edition['group_size'];
        materials: Edition['materials'];
        channels?: Edition['channels'];
        client_conferences?: Edition['client_conferences'];
      } = {
        _id: lastEdition._id,
        revision: lastEdition.revision,
        group_size: lastEdition.group_size,
        conferences: lastEdition.conferences.filter(filterSchedule),
        tasks: lastEdition.tasks.filter(filterSchedule),
        channels: lastEdition.channels,
        client_conferences: lastEdition.client_conferences,
        materials: lastEdition.materials.filter(filterSchedule).map(item => {
          const material = {...item};

          // @ts-ignore
          delete material.files;

          return material;
        }),
        expand: {
          edition: ['contract_id', 'materials.file_ids'],
        },
      };

      // editing by co-coach use case
      if (program.user_id !== currentUserStore.user!._id) {
        delete editionData.group_size;
        delete editionData.channels;
      }

      // use the latest revision
      if (program.coach_ids.length > 1) {
        const fetchedLastEdition = await editionStore.fetch({
          _id: lastEdition._id,
        });

        if (fetchedLastEdition!.revision !== lastEdition.revision) {
          editionData.revision = fetchedLastEdition!.revision;
        }
      }

      (await editionStore.update(
        lastEdition,
        editionData,
      )) as ProgramT['editions'][0];
    }

    return updatedProgram || program;
  } catch (error) {
    throw error;
  }
};

export default updateProgram;
