import type {FC, ReactNode} from 'react';
import React, {memo, useEffect} from 'react';
import {NavLink, Route, Switch, useRouteMatch} from 'react-router-dom';

import type {Questionnaire as IQuestionnaire} from '@yourcoach/shared/api/questionnaire';

import {
  labelAbout,
  labelQuestions,
  labelStatus,
} from '@src/common/i18n/i18nQuestionnaire';
import isActive from '@src/common/isActivePath';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {ColorWrapper} from '@src/layouts';
import {PATH_TYPE} from '@src/routes/utils';

import About from './About/About';
import Questions from './Questions/Questions';
import Status from './Status/Status';
import styles from './styles.module.css';

interface Props {
  quest: IQuestionnaire | null | undefined;
  questId?: string | null;
  children?: ReactNode;
}

const QDetail: FC<Props> = ({quest, children, questId}) => {
  const redirect = useAppRedirect();
  const {path, url} = useRouteMatch();

  useEffect(() => {
    if (!quest) {
      redirect(`/${PATH_TYPE.createQuestionnaire}`);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`QDetail ${styles.QDetail}`}>
      <ColorWrapper containerClass="pl50">
        <div className={styles.header}>
          <div className={styles.tittleContainer}>
            <div className={styles.title}>{quest?.title}</div>
          </div>
          <div className={styles.childrenContainer}>
            {children ? children : ''}
          </div>
        </div>
        <div className={styles.topMenu}>
          <NavLink
            className="customTopMenu"
            isActive={isActive.bind(this, `${url}/${PATH_TYPE.questions}`)}
            activeClassName="selected"
            to={`${url}/${PATH_TYPE.questions}${
              questId ? `?id=${questId}` : ''
            }`}
          >
            {labelQuestions()}
          </NavLink>

          <NavLink
            className="customTopMenu"
            isActive={isActive.bind(this, `${url}/${PATH_TYPE.about}`)}
            activeClassName="selected"
            to={`${url}/${PATH_TYPE.about}${questId ? `?id=${questId}` : ''}`}
          >
            {labelAbout()}
          </NavLink>

          {questId && (
            <NavLink
              className="customTopMenu"
              isActive={isActive.bind(this, `${url}/${PATH_TYPE.status}`)}
              activeClassName="selected"
              to={`${url}/${PATH_TYPE.status}?id=${questId}`}
            >
              {labelStatus()}
            </NavLink>
          )}
        </div>
      </ColorWrapper>
      <hr />
      <ColorWrapper containerClass="pl50" className={styles.questionsBody}>
        <Switch>
          <Route path={`${path}/${PATH_TYPE.questions}`}>
            <Questions quest={quest} />
          </Route>
          <Route path={`${path}/${PATH_TYPE.about}`}>
            <About description={quest?.description} />
          </Route>
          <Route path={`${path}/${PATH_TYPE.status}`}>
            <Status />
          </Route>
        </Switch>
      </ColorWrapper>
    </div>
  );
};

export default memo(QDetail);
