import type {FC, MouseEventHandler} from 'react';
import React, {memo, useCallback} from 'react';

import classNames from 'classnames';

import RadioButton from './RadioButton';
import styles from './Selectable.module.css';

interface Props {
  isSelected: boolean;
  className?: string;
  indicator?: React.FC<{checked: boolean}>;
  align?: 'left' | 'right';
  children: React.ReactNode | React.ReactNodeArray;
  onClick?: (data?: any) => void;
  data?: any;
}

const Selectable: FC<Props> = ({
  isSelected,
  className,
  indicator,
  align = 'left',
  children,
  onClick,
  data,
}) => {
  const Indicator = useCallback(
    ({checked, ...props}) =>
      indicator ? (
        indicator({checked, ...props})
      ) : (
        <RadioButton checked={checked} {...props} />
      ),
    [indicator],
  );

  // throwback passed data
  const onClickCb: MouseEventHandler = useCallback(() => {
    if (onClick) {
      onClick(data);
    }
  }, [data, onClick]);

  return (
    <div
      className={classNames(
        'Selectable',
        styles.Component,
        className,
        styles[`align-${align}`],
        isSelected && 'Selectable-selected',
      )}
      onClick={onClickCb}
    >
      <Indicator checked={isSelected} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default memo(Selectable);
