import {action, observable} from 'mobx';

import type {ILink} from '@src/models/library';

export interface IListFolderLocalStore {
  showModal: boolean;
  isMoveFileOrEditFolder: boolean;
  currentFolder: ILink | null;
  toggleVisModal: (isShow: boolean) => void;
  toggleMoveFileOrEditFolder: (val: boolean) => void;
  setCurrentFolder: (item: ILink | null) => void;
}

export const listFolderLocalStore: IListFolderLocalStore = observable(
  {
    showModal: false,
    isMoveFileOrEditFolder: false,
    currentFolder: null,
    toggleVisModal(isShow: boolean) {
      this.showModal = isShow;
    },
    toggleMoveFileOrEditFolder(val: boolean) {
      this.isMoveFileOrEditFolder = val;
    },
    setCurrentFolder(item: ILink | null) {
      this.currentFolder = item;
    },
  },
  {
    showModal: observable,
    isMoveFileOrEditFolder: observable,
    currentFolder: observable,
    toggleVisModal: action,
    toggleMoveFileOrEditFolder: action,
    setCurrentFolder: action,
  },
);
