import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import {action, autorun, computed, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import {userExpand} from '@yourcoach/shared/api/user';

import {setError} from '@src/common/setError';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import {expand as programExpand} from '@src/models/program';
import {readMembership} from '@src/modules/SeeProgram/Squad/api';

import {expand as courseExpand} from '../../../models/courses';
import SeeProgramContext from '../context/SeeProgramContext';
import type {
  ISeeProgramLocalStore,
  ProgramT,
} from '../context/useSeeProgramLocalStore';
import type {Course} from '../SquadsTab/SquadsTab';

import CourseEl from './Course/Course';
import styles from './styles.module.scss';

const ACTIVE_COURSES_LIMIT = 200;

interface ILocalStore {
  courses: Course[];
  isLoaded: boolean;
  currentCourse: Course | null | undefined;
  isFetching: boolean;
  shouldRenderStub: boolean;
  data: Course[];
  timerId: number;
  currentSquadId: string | null;
  fetchCourse(silent?: boolean): void;
  setIsLoaded(isLoaded: boolean): void;
  setCurrentSquadId(currentSquadIdVal: string | null): void;
  setCurrentCourse(currentCourse: Course | null | undefined): void;
  setCourse(courses: Course[]): void;
  activeCoursesStore: CollectionStore<Course> | null;
  setActiveCoursesStore(activeCoursesStore: CollectionStore<Course>): void;
  isPaginating: boolean;
  setIsPaginating(isPaginating: boolean): void;
}

interface Props {
  currentSquadId: string | null;
  tab?: string | null;
}

const Squad: FC<Props> = ({currentSquadId, tab}) => {
  const [userInfo, setUserInfo] = useState(undefined);
  const programExpandWithCourses = JSON.parse(JSON.stringify(programExpand));
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);

  const {
    stores: {programStore},
  } = useContext(AppContext);
  /* @ts-ignore */
  const localStore: ILocalStore = useRef(
    observable(
      {
        isPaginating: false,
        setIsPaginating(isPaginating: boolean) {
          this.isPaginating = isPaginating;
        },
        activeCoursesStore: null,
        setActiveCoursesStore(activeCoursesStore: CollectionStore<Course>) {
          this.activeCoursesStore = activeCoursesStore;
        },
        courses: [],
        setCourse(courses: Course[]) {
          this.courses = courses;
        },
        isLoaded: false,
        setIsLoaded(isLoaded: boolean) {
          this.isLoaded = isLoaded;
        },
        currentSquadId,
        setCurrentSquadId(currentSquadIdVal: string | null) {
          this.currentSquadId = currentSquadIdVal;
        },
        currentCourse: null,
        setCurrentCourse(currentCourse: Course | null | undefined) {
          this.currentCourse = currentCourse;
        },
        timerId: setTimeout(() => {}),
        async fetchCourse(silent = false) {
          if (!silent) {
            this.activeCoursesStore.isFetching = true;
          }

          this.activeCoursesStore.fetch(
            {
              limit: Math.max(
                this.activeCoursesStore.items.length,
                ACTIVE_COURSES_LIMIT,
              ),
            },
            {silent},
          );
        },
        get data() {
          const program = seeProgramLocalStore!.program!;

          seeProgramLocalStore?.getProgram(program);

          if (!this.activeCoursesStore) {
            return [];
          }

          if (!this.activeCoursesStore.isLoaded) {
            return [];
          }

          const programLastEdition = (
            program.editions && program.editions.length
              ? program.editions[program.editions.length - 1]
              : undefined
          ) as Course['programLastEdition'];

          const modifyCourse = (course: Course) => ({
            ...course,
            program: {
              ...program,
              editions: undefined,
            },
            programLastEdition,
          });
          const activeCourses = this.activeCoursesStore.items
            .slice()
            .map(modifyCourse);

          if (!activeCourses.length) {
            localStore.setActiveCoursesStore(
              createCollectionStore({
                method: 'coach.courses.list',
                params: {
                  limit: ACTIVE_COURSES_LIMIT,
                  sort: [['start_date', -1]],
                  query: [
                    ['program_id', '==', seeProgramLocalStore!.programId],
                  ],
                  expand: {
                    course: [
                      'edition_id',
                      ['client_id', {name: 'Client not found'}, userExpand],
                    ],
                  },
                },
                withCount: true,
              }),
            );

            this.fetchCourse();
          }

          return activeCourses;
        },
        get isFetching() {
          if (this.activeCoursesStore) {
            return (
              !this.activeCoursesStore.isLoaded ||
              this.activeCoursesStore.isFetching
            );
          } else {
            return false;
          }
        },
        get shouldRenderStub() {
          if (this.activeCoursesStore) {
            return (
              this.activeCoursesStore.isLoaded &&
              !this.activeCoursesStore.hasItems
            );
          } else {
            return false;
          }
        },
      },
      {
        courses: observable,
        isLoaded: observable,
        isFetching: computed,
        currentCourse: observable,
        currentSquadId: observable,
        timerId: observable,
        fetchCourse: action,
        setIsLoaded: action,
        setCurrentSquadId: action,
        setCurrentCourse: action,
        setCourse: action,
        shouldRenderStub: computed,
        data: computed,
        isPaginating: observable,
        setIsPaginating: action,
        activeCoursesStore: observable,
        setActiveCoursesStore: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setActiveCoursesStore(
      createCollectionStore({
        method: 'client.memberships.list',
        params: {
          sort: [['created', -1]],
          limit: 1,
          expand: {
            membership: [['course_id', null, courseExpand]],
          },
          query: [
            ['course_id', '==', currentSquadId],
            ['program_id', '==', seeProgramLocalStore!.programId],
          ],
        },
      }),
    );

    return () => {
      if (localStore.activeCoursesStore) {
        (
          localStore.activeCoursesStore as unknown as CollectionStore<Course>
        ).clear();
      }

      clearTimeout(localStore.timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dispose = autorun(() => {
      if (seeProgramLocalStore?.isCoach && seeProgramLocalStore.coachIsSigned) {
        localStore.setCurrentCourse(
          localStore.data.find(
            items => localStore.currentSquadId === items._id,
          ),
        );
      } else {
        localStore.data[0] &&
          //@ts-ignore
          localStore.setCurrentCourse(localStore.data[0].course);
      }
    });

    fetchProgram();

    readMembership()
      .then(response => {
        const membership = response?._items?.length
          ? response?._items.find(item => item.course_id === currentSquadId)
              ?._id
          : undefined;

        return (
          membership &&
          apiRequest({
            method: 'coach.memberships.read',
            params: {
              _id: membership,
            },
          })
        );
      })
      .then(response => {
        setUserInfo(response?.membership?.user);
      });

    return () => dispose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSquadId]);

  const fetchProgram = async () => {
    let fetchMethod;

    fetchMethod = programStore.client.fetch;

    try {
      const program: ProgramT = (await fetchMethod({
        _id: seeProgramLocalStore!.programId,
        expand: programExpandWithCourses,
      })) as ProgramT;

      seeProgramLocalStore?.getProgram(program);

      clearTimeout(localStore.timerId);
      localStore.timerId = +setTimeout(() => {
        localStore.fetchCourse();
      }, 500);
      localStore.setIsLoaded(true);
    } catch (error) {
      localStore.setIsLoaded(true);
      setError(error);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`Squad percent100H ${styles.Squad}`}>
          {!localStore.isLoaded ? (
            <Loader />
          ) : (
            <div className={styles.greyColumn}>
              {localStore.currentCourse && (
                <CourseEl
                  userInfo={userInfo}
                  course={localStore.currentCourse}
                  tab={tab}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(Squad);
