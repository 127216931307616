import '@src/styles/index.css';

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {ToastProvider as OriginalToastProvider} from 'react-toast-notifications';
import 'react-contexify/dist/ReactContexify.css';
import NiceModal from '@ebay/nice-modal-react';

import Bugsnag from '@bugsnag/js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import md5 from 'js-md5';
import {reaction} from 'mobx';

import type {ApiRpcRequest} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import {AlertContainer} from '@yourcoach/shared/modules/alert';
import {syncTimeZoneService} from '@yourcoach/shared/services/sync-time-zone';
import {ThemeProvider} from '@yourcoach/shared/styles/ThemeProvider';
import {redactStr} from '@yourcoach/shared/utils/format';
// import Routes from '@src/routes/index';
import {logger} from '@yourcoach/shared/utils/logger';

import {useSetReturnPath} from '@src/common/useUserMethods';
// import Loader from '@src/components/Loader/Loader';
import Toast from '@src/components/Toast/Toast';
import WS, {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import {API_VERSION} from '@src/config';
import AppContext from '@src/context/App';
import {init as i18nInit, t} from '@src/i18n';
import Routes from '@src/routes/lazy';
import {Logger} from '@src/v2/utils/logger';

import {emitter} from '../widget/src/utils';

import {getCustomConfirmAlert} from './components/CustomConfirmAlert/CustomConfirmAlert';
import styles from './styles/App.module.css';
import {PageLoading} from './v2/components/PageLoading';

dayjs.extend(utc);

logger.__setPlatformLogger(new Logger());

apiRequest.configure({
  apiEndpoint: process.env.API_ENDPOINT!,
  apiVersion: API_VERSION,
  onRequestDidEnd: (payload: ApiRpcRequest | ApiRpcRequest[]) => {
    logger.logApiRequest(payload);
  },
  onRpcRequestError: error => {
    // cleanup params
    if (error.sentParams) {
      [
        'access_token',
        'session_token',
        'client_id',
        'client_secret',
        'external_id',
        'token',
        'phone',
        'email',
      ].forEach(key => {
        if (error.sentParams[key]) {
          error.sentParams[key] = redactStr(error.sentParams[key]);
        }
      });
    }

    const hash = md5(JSON.stringify(error));

    logger.error({
      name: `RpcRequestError - ${hash}`,
      message: JSON.stringify(error, null, 2),
    });
  },
});

const ErrorBoundary =
  __SSR__ || process.env.NODE_ENV === 'development'
    ? ({children}) => children
    : Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const ToastProvider = __SSR__
  ? ({children}) => children
  : OriginalToastProvider;

const TITLE = 'YourCoach Health';
const ENV =
  process.env.APP_ENV !== 'prod' ? process.env.APP_ENV!.toUpperCase() : '';

export const DEFAULT_TITLE = [TITLE, ENV].filter(Boolean).join(' | ');

const Alert = () => {
  return createPortal(<AlertContainer />, document.body);
};

const App: React.FC = () => {
  const {
    stores: {categoryStore, currentUserStore, membershipStore, authStore},
  } = useContext(AppContext);

  const setRetPath = useSetReturnPath();

  const history = useHistory();

  const [i18nIsReady, setI18nIsReady] = useState(__SSR__);

  useEffect(() => {
    setRetPath();

    i18nInit().finally(() => {
      setI18nIsReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWindowFocus = useCallback(() => {
    if (currentUserStore.user) {
      currentUserStore.fetch();
      membershipStore.fetchMemberships();

      syncTimeZoneService({
        onEditScheduleClick: () => {
          const pathname = '/my-calendar';
          const state = {isScheduleOpen: true};

          if (history.location.pathname === pathname) {
            history.replace({state});
          } else {
            history.push(pathname, state);
          }
        },
      });
    }

    categoryStore.fetchCategories();
  }, [categoryStore, currentUserStore, history, membershipStore]);

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
    };
  }, [onWindowFocus]);

  useEffect(() => {
    const disposers = [
      reaction(
        () => currentUserStore.user && currentUserStore.user._id,
        () => {
          if (currentUserStore.user) {
            logger.setUser(currentUserStore.user);

            membershipStore.fetchMemberships();
          }
        },
      ),
      reaction(
        () => currentUserStore.user && currentUserStore.user.is_blocked,
        () => {
          if (currentUserStore.user && currentUserStore.user.is_blocked) {
            getCustomConfirmAlert({
              title: 'Your account has been blocked!',
              buttons: [{label: t('shared.button.ok')}],
            });

            currentUserStore.clear();
            authStore.logout().catch(() => {});
            authStore._deleteSession();
          }
        },
      ),
    ];

    categoryStore.fetchCategories();

    return () => {
      disposers.forEach(dispose => dispose());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWsMessage = useCallback(
    message => {
      if (
        [
          'membership_accepted',
          'membership_frozen',
          'membership_unfrozen',
        ].includes(message.event.type)
      ) {
        membershipStore.addMembershipIfNeeded(message.membership);
      }

      membershipStore.fetchMemberships();
    },
    [membershipStore],
  );

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);
    };
  }, [handleWsMessage]);

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${DEFAULT_TITLE}`}
        defaultTitle={DEFAULT_TITLE}
      />
      {/* TODO: Add FallbackComponent */}
      <ThemeProvider>
        <NiceModal.Provider>
          <ErrorBoundary>
            <ToastProvider components={{Toast}} autoDismissTimeout={10000}>
              <div className={styles.App} id="allContent">
                {i18nIsReady ? <Routes /> : <PageLoading />}
              </div>
              <div id="modal-root" />
              <WS />
            </ToastProvider>
          </ErrorBoundary>
          <Alert />
        </NiceModal.Provider>
      </ThemeProvider>
    </>
  );
};

export default React.memo(App);
