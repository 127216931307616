import type {FC, MouseEventHandler} from 'react';
import React, {memo} from 'react';

import styles from './styles.module.css';

interface Props {
  title?: string;
  text?: string;
  className?: string;
  onClick?: MouseEventHandler;
  children?: React.ReactNode | React.ReactNodeArray;
}

const RoleBlock: FC<Props> = ({
  title,
  text,
  className = '',
  onClick,
  children,
  ...props
}) => (
  <div
    className={styles.roleBox + ' ' + className}
    onClick={onClick}
    {...props}
  >
    <div className={styles.roleTitle}>{title}</div>
    <div>{text}</div>
    {children}
    <div className={styles.border} />
  </div>
);

export default memo(RoleBlock);
