import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';

import {slugify} from '@yourcoach/shared/utils/format';

import List from '@src/components/List';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import {getListScrollProps} from '../../getListScrollProps';
import ListItem, {ListItemCheckbox, ListItemTitle} from '../../ListItem';
import {bindIndex} from '../../useSelection';
import {useListEditor} from '../useListEditor';

interface TrackingItem {
  id: string;
  title: string;
}

const TRACKING_ITEMS: TrackingItem[] = [
  {
    id: 'food_intake',
    title: 'Food Intake',
  },
  {
    id: 'steps',
    title: 'Steps',
  },
  {
    id: 'weight',
    title: 'Weight',
  },
  {
    id: 'sleep',
    title: 'Sleep',
  },
  {
    id: 'mood',
    title: 'Mood',
  },
  {
    id: 'water_intake',
    title: 'Water Intake',
  },
];

const I18N_SCOPE = 'Onboarding.tracking';

interface Data {
  metadata: {tracking: string[]};
}

interface Props {
  value?: Data;
  onChange?: (value: Data) => void;
}

const TrackEditor: FC<Props> = ({value, onChange}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const deslugify = (s?: string) =>
    (s && s[0].toUpperCase() + s.slice(1).split('-').join(' ')) || '';

  const {
    selection,
    items,
    makeOnItemChange,
    makeOnBlur,
    makeOnKeyPress,
    onItemClick,
  } = useListEditor<TrackingItem>(
    (value || currentUserStore.user)?.metadata.tracking?.map(slug => ({
      id: '',
      title: deslugify(slug),
    })) || [],
    TRACKING_ITEMS,
    {id: '', title: ''},
    (x, y) => slugify(x.title) === slugify(y.title),
    (track, newTitle) => ({...track, title: newTitle as string}),
  );

  // Pass selected tracks to caller
  useEffect(() => {
    onChange &&
      onChange({
        metadata: {
          ...currentUserStore.user?.metadata,
          tracking: selection.sort().map(i => slugify(items[i].title)),
        },
      });
  }, [currentUserStore.user?.metadata, items, onChange, selection]);

  return (
    <List customScrollbar {...useMemo(getListScrollProps, [])}>
      {items.map((item, index) => {
        const isSelected = selection.includes(index);
        const isFixed = index >= items.length - TRACKING_ITEMS.length;

        return (
          <ListItem
            key={`item-${index}`}
            isSelected={isSelected && isFixed}
            isEditable={!isFixed}
            size="small"
            onClick={isFixed ? bindIndex(index, onItemClick) : undefined}
          >
            <ListItemTitle>
              {!isFixed ? (
                <TextField
                  value={item.title}
                  placeholder={t([I18N_SCOPE, 'add_own_placeholder'])}
                  onChange={makeOnItemChange(index)}
                  onBlur={makeOnBlur(index)}
                  onKeyPress={makeOnKeyPress(index)}
                />
              ) : (
                item.title
              )}
            </ListItemTitle>
            <ListItemCheckbox
              isSelected={isSelected}
              onClick={bindIndex(index, onItemClick)}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default memo(TrackEditor);
