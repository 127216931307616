import type {FC} from 'react';
import React, {memo, useCallback, useContext} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {useHistory} from 'react-router-dom';

import classNames from 'classnames';

import MoreVerticalSVG from '@src/assets/img/more-vertical.svg';
import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import styles from './styles.module.css';

const CONTEXT_MENU_ID = 'context_menu';

interface Props {}

const MoreVertical: FC<Props> = () => {
  const {
    stores: {authStore},
  } = useContext(AppContext);

  const history = useHistory();

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const handleOnClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    contextMenu.show({
      id: CONTEXT_MENU_ID,
      event,
    });
  };

  const onLogoutButtonClick = useCallback(async () => {
    try {
      showOverlay();

      await authStore.logout();

      history.push('/login');

      localAppStore.setIsAuth(false);
    } catch (error) {
      setError(error);

      hideOverlay();

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [{label: t('shared.button.ok'), onClick: () => {}}],
      });
    }
  }, [authStore, hideOverlay, history, showOverlay]);

  const ContextMenu = useCallback(
    () => (
      <Menu
        id={CONTEXT_MENU_ID}
        animation={animation.fade}
        className="contextMenu"
      >
        <Item
          onClick={onLogoutButtonClick}
          className={classNames('contextMenuItem', 'danger')}
        >
          {'Logout'}
        </Item>
      </Menu>
    ),
    [onLogoutButtonClick],
  );

  return (
    <div className={`MoreVertical ${styles.MoreVertical}`}>
      <MoreVerticalSVG onClick={handleOnClick} />
      <ContextMenu />
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default memo(MoreVertical);
