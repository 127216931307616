import {useEffect, useState} from 'react';

import storageWeb from '@yourcoach/shared/utils/storage';

import getQueryParams from '@src/common/getURLParams';

export const ILE_STORAGE_KEY = 'ile';

const useIsLimitedEdition = (id?: string) => {
  const searchParams = getQueryParams(window.location.search) as {
    [ILE_STORAGE_KEY]?: string;
  };

  const [isLimitedEdition, setIsLimitedEdition] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    const isItLimitedEdition = async () => {
      let storageValue = (await storageWeb.getItem(ILE_STORAGE_KEY)) as
        | string
        | undefined;
      let ileMap = {};
      let result = false;

      if (storageValue) {
        try {
          ileMap = JSON.parse(storageValue) as {[key: string]: number};
        } catch (error) {
          // do nothing
        }
      }

      if (id) {
        result = !!(ileMap[id] || searchParams.ile);
      } else {
        result = !!searchParams.ile;
      }

      setIsLimitedEdition(result);
    };

    isItLimitedEdition();
  }, [id, searchParams.ile]);

  return {isLimitedEdition};
};

export default useIsLimitedEdition;

export const syncIleStorage = async (id: string | undefined, value: any) => {
  window.history.pushState(
    null,
    '',
    value
      ? `${window.location.pathname}?${ILE_STORAGE_KEY}=1`
      : window.location.pathname,
  );

  if (!id) {
    return;
  }

  let storageValue = (await storageWeb.getItem(ILE_STORAGE_KEY)) as
    | string
    | undefined;
  let ileMap = {};

  const key = `${id}`;

  if (storageValue) {
    try {
      ileMap = JSON.parse(storageValue) as {[key: string]: number};
    } catch (error) {
      // do nothing
    }
  }

  delete ileMap[key];

  if (value) {
    ileMap[key] = value;
  }

  await storageWeb.setItem(ILE_STORAGE_KEY, JSON.stringify(ileMap));

  return ileMap;
};
