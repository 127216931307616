import type {FC} from 'react';
import React from 'react';

import IconAddChatSVG from '@src/assets/img/add_chat.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconAddChat: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'ico',
  viewBox = '0 0 24 24',
}) => {
  return (
    <IconAddChatSVG
      width={height}
      height={width}
      className={className}
      viewBox={viewBox}
    />
  );
};

export default IconAddChat;
