import type {Certificate} from '@yourcoach/shared/api/certificate';
import type {IFile} from '@yourcoach/shared/api/media/file';

export default interface ExpandedCertificate extends Certificate {
  file?: IFile | null;
}

export const certificateExpand = {
  certificate: ['file_id'],
};
