import React, {useCallback} from 'react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import CalendarIcon from '@yourcoach/shared/assets/icons/calendar.svg';

import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Checkbox from '@src/components/Checkbox';
import Image from '@src/components/Image';
import {t} from '@src/i18n';

import styles from './ProgramRequestsListItem.module.css';
import type {MembershipT} from './ProgramRequestsTab';

const I18N_SCOPE = 'shared.ProgramRequestsListItem';

export interface Props<T extends MembershipT = MembershipT> {
  membership: T;
  isSelected: boolean;
  onSelect?: (membership: T) => void;
  onClick?: (membership: T) => void;
}

const ProgramRequestsListItem: React.FC<Props<MembershipT>> = ({
  membership,
  isSelected,
  onSelect,
  onClick,
}) => {
  const onClickCb = useCallback(() => {
    onClick && onClick(membership);
  }, [membership, onClick]);

  const onSelectCb = useCallback(() => {
    onSelect && onSelect(membership);
  }, [membership, onSelect]);

  return (
    <div
      className={`ProgramRequestsListItem ${styles.Component}`}
      onClick={onClickCb}
    >
      <div className={styles.wrapper}>
        <div className={styles.leftPart}>
          <Checkbox
            checked={isSelected}
            onClick={onSelectCb}
            variant="round"
            className={styles.checkbox}
          />
          <div className={styles.userContainer}>
            <Image
              className={styles.avatar}
              src={getFileSrc(membership.user.avatar).url || ''}
              placeholder={<AvatarPlaceholder name={membership.user.name} />}
            />
            <p className={styles.userName}>{membership.user.name}</p>
          </div>
        </div>
        {membership.course ? (
          <div className={styles.courseDatesContainer}>
            <CalendarIcon />
            <p className={styles.courseDates}>
              {getCourseDurationString(membership.course)}
            </p>
          </div>
        ) : null}
        <div className={styles.rightPart}>
          <div className={styles.date}>
            {dayjs(datetimeObjToISOString(membership.created)).format('ll')}
          </div>
        </div>
      </div>
      {membership.program &&
      membership.edition_id !==
        membership.program.edition_ids[
          membership.program.edition_ids.length - 1
        ] ? (
        <div className={styles.message}>
          {t([I18N_SCOPE, 'old_conditions_label'])}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ProgramRequestsListItem);
