import type {FC} from 'react';
import React, {memo, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {labelCourseMembersTabNoResults} from '@src/common/i18n/i18nCourse';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconProfile} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';

import {emitter} from '../../../../../../widget/src/utils';
import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';
import type {MembershipT} from '../CourseClientsTab/CourseClientsTab';

import styles from './../../styles.module.scss';
import CourseMembersListItem from './CourseMembersListItem/CourseMembersListItem';

interface ILocalStore {
  course: CourseT | null;
  isLoaded: boolean;
  membershipsStore: CollectionStore<MembershipT> | null;
  shouldRenderStub: boolean;
  data: MembershipT[];
  setCourse(courseVal: CourseT | null): void;
  setMembershipsStore(
    membershipsStore: CollectionStore<MembershipT> | null,
  ): void;
  setIsLoaded(isLoaded: boolean): void;
}

interface Props {
  course: CourseT;
}

const CourseMembersTab: FC<Props> = ({course}) => {
  const LIMIT = 999;
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          course: null,
          setCourse(courseVal: CourseT | null) {
            this.course = courseVal;
          },
          isLoaded: false,
          setIsLoaded(isLoaded: boolean) {
            this.isLoaded = isLoaded;
          },
          membershipsStore: null,
          setMembershipsStore(
            membershipsStore: CollectionStore<MembershipT> | null,
          ) {
            this.membershipsStore = membershipsStore;
          },

          get shouldRenderStub() {
            return (
              this.membershipsStore &&
              this.membershipsStore.isLoaded &&
              !this.membershipsStore.hasItems
            );
          },
          get data() {
            if (this.membershipsStore) {
              return (this
                .membershipsStore as CollectionStore<MembershipT>)!.items
                .slice()
                .map(membership => ({
                  ...membership,
                  course: course,
                }));
            } else {
              return [];
            }
          },
        },
        {
          course: observable,
          isLoaded: observable,
          membershipsStore: observable,
          setCourse: action,
          setMembershipsStore: action,
          setIsLoaded: action,
          shouldRenderStub: computed,
          data: computed,
        },
      ),
    [course],
  );

  useEffect(() => {
    localStore.setCourse(course);

    localStore.setMembershipsStore(
      createCollectionStore({
        method: 'client.memberships.list',
        params: {
          limit: LIMIT,
          sort: [['created', -1]],
          course_id: course._id,
          query: [
            [
              'status',
              '!in',
              ['requested', 'proposed'] as MembershipT['status'][],
            ],
          ],
          expand: {
            membership: [
              [
                'user_id',
                {name: 'User not found'},
                {
                  user: ['avatar_id'],
                },
              ],
            ],
          },
        },
        withCount: true,
      }),
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    fetchData();

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

      if (localStore.membershipsStore) {
        localStore.membershipsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, localStore]);

  const _handleWsMessage = msg => {
    if (
      msg.event.type.includes('membership') &&
      msg.membership.course_id === course._id
    ) {
      fetchData(true);
    }
  };

  const fetchData = async (silent = false) => {
    localStore.setIsLoaded(false);

    try {
      await localStore.membershipsStore!.fetch(
        {
          limit: LIMIT,
        },
        {silent},
      );
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }

    localStore.setIsLoaded(true);
  };

  const _onMembershipPress = () => {
    // TODO: navigate to user profile
  };

  return (
    <Observer>
      {() => (
        <div className={`CourseMembersTab ${styles.CourseMembersTab}`}>
          {localStore.shouldRenderStub ? (
            <NoResults
              text={labelCourseMembersTabNoResults()}
              className={styles.NoResults}
            >
              <div className={styles.iconNoResultContainer}>
                <IconProfile
                  className={styles.iconNoResult}
                  viewBox="0 0 24 24"
                />
              </div>
            </NoResults>
          ) : !localStore.isLoaded ? (
            <Loader />
          ) : (
            <div>
              {localStore.data.map(item => {
                return (
                  <CourseMembersListItem
                    membership={item}
                    key={item._id}
                    onPress={_onMembershipPress}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseMembersTab);
