import {useContext, useEffect, useRef} from 'react';

import {action, computed, observable, runInAction} from 'mobx';

import type {CollectionStore, Entity} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  EntityStore,
  isEntityId,
} from '@yourcoach/shared/api';
import type {Category} from '@yourcoach/shared/api/category';
import type {Follower} from '@yourcoach/shared/api/follower';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {membershipStore} from '@yourcoach/shared/api/membership';
import type {PaymentPlan} from '@yourcoach/shared/api/program';
import {parseDuration, parsePaymentPlan} from '@yourcoach/shared/api/program';
import {userExpand} from '@yourcoach/shared/api/user';
import {getPriceString} from '@yourcoach/shared/utils/money';

import {useUserId} from '@src/common';
import {setError} from '@src/common/setError';
import AppContext from '@src/context/App';
import type {Coach as TCoach, ProgramExpanded} from '@src/models/app';

import getSendMyProgramRpsParams from './getSendMyProgramRpsParams';

export interface IPractice {
  coach_title: string;
  avatar: IFile | null;
  counters: {
    following: {
      matched: number;
      invited: number;
      requested: number;
      joined: number;
    };
  };
  name: string;
  coach_logo: IFile | null;
  coach_description: string;
  coach_categories: [];
  _id: string;
}

export interface IFilterProgram {
  id: string;
  title: string;
  slug: string;
  avatar: IFile | null | undefined;
  background: IFile | null | undefined;
  status: 'draft' | 'active' | 'archived';
  statusType: 'danger' | 'success' | '';
  isGroup: boolean;
  duration: number;
  is_closed: boolean;
  is_hidden: boolean;
  durationStr: string;
  payment_plan: PaymentPlan | null;
  priceStr: string;
  coaches: ISoCouch[];
  user_id: string;
  offer_id: string;
}

export interface ISoCouch {
  name: string;
  avatar: IFile | null;
}

export interface IPracticeLocalStore {
  isLoading: boolean;
  isMe: boolean;
  setIsMe(isMeVal: boolean): void;
  isLimitedEdition: boolean;
  categories: Category[];
  isLoadingPractice: boolean;
  isFirstLoading: boolean;
  isTimerOn: boolean;
  practice: Partial<IPractice> | TCoach;
  programs: ProgramExpanded[];
  clear: () => void;
  programsStore: null | CollectionStore<Entity>;
  filteredPrograms: IFilterProgram[];
  getDate: () => void;
  setLoading(val: boolean): void;
  getMorePrograms: () => void;
  setPrograms(programs: ProgramExpanded[]): void;
}

const usePracticeLocalStore = (limit: number) => {
  const slug = useRef(useUserId());
  const userId = useRef('');
  const isMe = useRef(false);

  const {
    stores: {currentUserStore, categoryStore},
  } = useContext(AppContext);

  let timerId;

  useEffect(() => {
    const effect = async () => {
      const isUserId = isEntityId(slug.current, 'user');

      try {
        if (!isUserId) {
          const {user} = await apiRequest({
            method: 'public.profile.read',
            params: {
              slug: slug.current,
            },
          });

          userId.current = user._id;
        } else {
          userId.current = slug.current;
        }

        const user = currentUserStore.user;

        isMe.current = !!(user && user._id === userId.current);

        store.setIsMe(isMe.current);

        store.getDate();
      } catch (error) {
        // TODO: log error
      }
    };

    effect();

    return () => {
      clearInterval(timerId);
      store.clear();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store: IPracticeLocalStore = useRef(
    observable(
      {
        isLoading: false,
        setLoading(val: boolean) {
          this.isLoading = val;
        },
        isMe: isMe.current,
        setIsMe(isMeVal: boolean) {
          this.isMe = isMeVal;
        },
        isLimitedEdition: false,
        get categories() {
          let categories: Category[] = [];

          categories = (
            (this.practice.coach_categories || []).map(item =>
              categoryStore.categoryLookup.get(item.category_id),
            ) as Category[]
          )
            .filter(Boolean)
            .sort((a, b) => b!.weight - a!.weight);

          return categories;
        },
        isLoadingPractice: false,
        isFirstLoading: true,
        isTimerOn: false,
        practice: {},
        clear() {
          if (this.programsStore) {
            this.programsStore.clear();
          }
        },
        programsStore: null,
        programs: [],

        setPrograms(programs: ProgramExpanded[]) {
          this.programs = programs;
        },

        async getDate() {
          if (isMe.current) {
            const coach: TCoach = currentUserStore.user!;

            this.practice = getPractice(coach);
          } else {
            const userStore = getCouchUser();

            this.setLoading(true);

            try {
              const userCouch: TCoach = await userStore.fetch({
                _id: userId.current,
                expand: userExpand,
              });

              if (!currentUserStore.user!.roles.includes('coach')) {
                const followersResult = (await apiRequest({
                  method: 'client.followers.list',
                  params: {
                    query: [['coach_id', '==', userId.current]],
                  },
                })) as {_items: Follower[]};

                const [follower] = followersResult._items;

                runInAction(() => {
                  this.isLimitedEdition = follower && follower.is_matched;
                });
              }

              this.practice = getPractice(userCouch);
            } catch (error) {
              setError(error);
            }

            this.isLoadingPractice = false;
          }

          this.programsStore = getProgramStore();
          this.setLoading(true);

          if (!this.isLimitedEdition) {
            try {
              await this.programsStore.fetch();
            } catch (error) {
              setError(error);
            }
          }

          this.setLoading(false);
          this.isFirstLoading = false;

          this.setPrograms(this.programsStore.items.slice());
        },

        async getMorePrograms() {
          if (this.isFirstLoading === false) {
            if (this.programsStore.isFetching) {
              if (this.isTimerOn === false) {
                this.isTimerOn = true;
                timerId = setInterval(() => {
                  if (this.programsStore.isFetching === false) {
                    clearInterval(timerId);
                    this.isTimerOn = false;
                    this.getMorePrograms();
                  }
                }, 500);
              }
            } else {
              if (this.programsStore.hasMore) {
                this.setLoading(true);

                try {
                  await this.programsStore.fetch(
                    {
                      offset: this.programsStore.items.length,
                    },
                    {silent: true},
                  );
                } catch (error) {
                  setError(error);
                }

                this.setLoading(false);

                if (this.programsStore.hasItems) {
                  this.setPrograms(this.programsStore.items.slice());
                }
              }
            }
          }
        },
        get filteredPrograms(): IFilterProgram[] {
          const ret: IFilterProgram[] = this.programs.map(
            (program: ProgramExpanded) => {
              const countEditions = program.editions.length - 1;
              const [status, statusType] = getProgramStatus(
                program,
                countEditions,
              );

              const paymentPlan = program.editions[countEditions]
                ? parsePaymentPlan(program.editions[countEditions].payment_plan)
                : null;

              const isOfferedProgram = !!(program && program.offer_id);

              const userIsProgramCoach = !!(
                currentUserStore.user &&
                program.coach_ids.includes(currentUserStore.user._id)
              );

              const getProgramPrice = () => {
                if (!paymentPlan) {
                  return;
                }

                if (
                  (!userIsProgramCoach &&
                    ((isOfferedProgram && paymentPlan.total) ||
                      !isOfferedProgram)) ||
                  (userIsProgramCoach && !isOfferedProgram)
                ) {
                  return getPriceString({
                    price: paymentPlan.total,
                    currency: paymentPlan.parsedCurrency,
                  });
                }
              };

              const priceStr = getProgramPrice() || '';
              const duration = parseDuration(
                program.editions[countEditions]
                  ? program.editions[countEditions].duration
                  : 1,
              );

              return {
                id: program._id,
                slug: program.slug,
                title: program.title,
                avatar: program.avatar,
                background: program.background,
                status: status,
                is_hidden: program.is_hidden,
                is_closed: program.is_closed,
                statusType: statusType,
                isGroup: program.editions[countEditions]
                  ? program.editions[countEditions].group_size > 1
                  : false,
                duration: program.editions[countEditions]
                  ? program.editions[countEditions].duration
                  : 1,
                durationStr: duration.toString(),
                payment_plan: program.editions[countEditions]
                  ? program.editions[countEditions].payment_plan
                  : null,
                priceStr: priceStr,
                coaches: program.expanded_coaches.map(couch => {
                  return {
                    avatar: couch.avatar,
                    name: couch.name,
                  };
                }),
                user_id: program.user_id,
                offer_id: program.offer_id,
              } as IFilterProgram;
            },
          );

          return ret;
        },
      },
      {
        isLoading: observable,
        isMe: observable,
        setIsMe: action,
        isLimitedEdition: observable,
        categories: computed,
        isLoadingPractice: observable,
        isFirstLoading: observable,
        isTimerOn: observable,
        practice: observable,
        programs: observable,
        programsStore: observable,
        clear: action,
        getDate: action,
        getMorePrograms: action,
        setPrograms: action,
        setLoading: action,
        filteredPrograms: computed,
      },
    ),
  ).current;

  //------------functions-------------------------
  const getPractice = (coach: TCoach): IPractice | TCoach => {
    const nullObj: IPractice = {
      avatar: null,
      name: '',
      counters: {
        following: {
          matched: 0,
          invited: 0,
          requested: 0,
          joined: 0,
        },
      },
      coach_logo: null,
      coach_description: '',
      coach_title: '',
      coach_categories: [],
      _id: '',
    };

    if (coach.roles.includes('coach')) {
      return coach;
    } else {
      return nullObj;
    }
  };

  const getProgramStore = () => {
    const sendRpsParams = getSendMyProgramRpsParams(
      limit,
      isMe.current,
      userId.current,
    );

    const retProgramStore = createCollectionStore(sendRpsParams);

    retProgramStore.setMethod(
      isMe.current ? 'coach.programs.list' : 'client.programs.list',
    );

    return retProgramStore;
  };

  const getCouchUser = () => {
    return new EntityStore<TCoach>('user', {
      fetch: 'client.users.read',
    });
  };

  const getProgramStatus = (program, countEditions) => {
    let retStatus = '';
    let retStatusType = '';

    const lastEdition = program.editions[countEditions];

    if (isMe.current) {
      [retStatus, retStatusType] = getCouchStatus(program, lastEdition);
    } else if (program.status === 'active') {
      [retStatus, retStatusType] = getUserStatus(program);
    } else if (program.status === 'archived') {
      retStatus = program.status;
    }

    return [retStatus, retStatusType];
  };

  const getCouchStatus = (program, lastEdition) => {
    let retStatus = '';
    let retStatusType = '';

    if (!lastEdition) {
      retStatus = 'need_setup';
      retStatusType = 'danger';
    } else {
      retStatus = program.status;

      if (program.is_closed) {
        retStatus = 'closed';
      }

      if (program.status === 'active') {
        retStatusType = 'success';
      }

      if (program.offer_id) {
        retStatus = 'restricted';
      }
    }

    return [retStatus, retStatusType];
  };

  const getUserStatus = program => {
    let retStatus = '';
    let retStatusType = '';

    const memberships = Array.from(
      membershipStore.membershipLookup.values(),
    ).filter(item => item.program_id === program._id);

    if (
      memberships.some(
        item =>
          item.status === 'accepted' ||
          item.status === 'active' ||
          item.status === 'frozen',
      )
    ) {
      retStatus = 'joined';
      retStatusType = 'success';
    } else if (memberships.some(item => item.status === 'requested')) {
      retStatus = 'requested';
    } else if (program.is_closed) {
      retStatus = 'closed';
    } else if (program.counters.courses.ongoing) {
      retStatus = 'in_progress';
    } else {
      retStatus = 'join_now';
      retStatusType = 'success';
    }

    return [retStatus, retStatusType];
  };

  //-------------------return store-------------------
  return store;
};

export default usePracticeLocalStore;
