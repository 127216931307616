import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

interface Props {
  show: boolean;
  children: ReactNode;
}

const FadeComponent: FC<Props> = ({show, children}) => {
  return <div className={`fadeBox ${show ? '' : 'hide '}`}>{children}</div>;
};

export default memo(FadeComponent);
