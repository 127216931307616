import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {IconCheck, IconPrev} from '@src/components/icons';
import {t} from '@src/i18n';

import type {
  GoalListItemT,
  ListItemT,
  SubgoalListItemT,
} from '../SelectGoal/SelectGoal';
import SubgoalsListItem from '../SubgoalsListItem/SubgoalsListItem';

import styles from './../styles.module.scss';

interface Props {
  item: ListItemT;
  isSelected: boolean;
  isLast?: boolean;
  isSelectable?: boolean;
  isCollapsed?: boolean;
  onPress?: (item: ListItemT) => void;
  onToggleIconPress?: (item: ListItemT) => void;
}

const ListItem: FC<Props> = ({
  item,
  isSelected,
  isCollapsed,
  isSelectable,
  onPress,
  onToggleIconPress,
}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress(item);
  }, [item, onPress]);

  const onToggleIconPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onToggleIconPress && onToggleIconPress(item);
    },
    [onToggleIconPress, item],
  );

  const isSubgoal = useMemo(
    () => item._id.split(':')[0] === 'subgoal',
    [item._id],
  );

  return (
    <Observer>
      {() => (
        <div className={`ListItem ${styles.ListItem}`} onClick={onPressCb}>
          {!isSubgoal ? (
            <div className={styles.mainGoalContainer}>
              <div
                className={`${styles.checkBox} ${
                  isSelected ? styles.selected : ''
                }`}
              >
                {isSelected ? <IconCheck viewBox="0 2 30 30" /> : null}
              </div>
              <div className={styles.mainGoalContentContainer}>
                <div className={styles.indexGoal}>
                  {t('label.goal_index', {
                    index: (item as GoalListItemT).index + 1,
                  })}
                </div>
                <div className={styles.title}>{item.title}</div>
              </div>
              {(item as GoalListItemT).subgoals.length >= 1 ? (
                <div
                  className={`${styles.toggleArrowContainer} ${
                    isCollapsed ? '' : styles.open
                  }`}
                  onClick={onToggleIconPressCb}
                >
                  <IconPrev
                    customClass="CustomSelectArrow"
                    viewBox="5 6 20 20"
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div className={styles.listSubgoals}>
              <SubgoalsListItem
                subgoal={item as SubgoalListItemT}
                isSelectable={isSelectable}
                isSelected={isSelected}
                onPress={onPressCb}
              />
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(ListItem);
