import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Img as Image} from 'react-image';
import {Observer} from 'mobx-react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {
  getFileIcon,
  getFileName,
  getFileSrc,
} from '@yourcoach/shared/api/media/file';

import {IconCheck} from '@src/components/icons';
import i18n from '@src/i18n';

import styles from './../styles.module.scss';

interface Props<T extends IFile = IFile> {
  file: T;
  className?: string;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  withMoreButton?: boolean;
  onPress?: (vent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMorePress?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isMedium?: boolean;
}

const FileCard: FC<Props> = ({
  file,
  className = '',
  isSelectionMode,
  isSelected,
  withMoreButton,
  onPress,
  onMorePress,
  isMedium = false,
}) => {
  const onPressCb = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();

      onPress && onPress(e);
    },
    [onPress],
  );

  const onMorePressCb = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onMorePress && onMorePress(e);
    },
    [onMorePress],
  );

  // @ts-ignore
  const {size, urlrepr} = getFileSrc(file);
  const fileName = getFileName(file);

  const Icon = getFileIcon(file);

  const isFileImage = !!file.categories.includes('image');

  return (
    <Observer>
      {() => (
        <div
          className={`FileCard ${styles.FileCard} ${styles.container} ${className}`}
          onClick={onPress ? onPressCb : undefined}
        >
          <div
            className={`${styles.content} ${
              isMedium ? styles.mediumContainer : ''
            }`}
          >
            <div
              className={
                isMedium ? styles.iconMediumContainer : styles.iconContainer
              }
            >
              {isFileImage ? (
                <Image
                  src={getFileSrc(file, 250).url || ''}
                  className={styles.imagePreview}
                  loader={<Icon />}
                  unloader={<Icon />}
                />
              ) : (
                <Icon />
              )}
            </div>
            <div
              className={
                isFileImage
                  ? styles.imageContentContainer
                  : styles.fileContentContainer
              }
            >
              <div className={styles.name}>{fileName}</div>
              {size && file.type !== 'url' ? (
                <div className={styles.size}>{i18n.toHumanSize(size)}</div>
              ) : null}
              {urlrepr ? (
                <div className={styles.url}>
                  {urlrepr.replace(/^((ht|f)tp(s?):\/\/)/, '')}
                </div>
              ) : null}
            </div>
          </div>

          {isSelectionMode && !withMoreButton ? (
            <div
              className={`${styles.checkboxContainer} ${
                isSelected ? styles.selected : ''
              }`}
            >
              {isSelected ? <IconCheck /> : null}
            </div>
          ) : null}
          {withMoreButton && !isSelectionMode ? (
            <div className={styles.moreButtonContainer} onClick={onMorePressCb}>
              ⋮
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(FileCard);
