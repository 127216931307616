import type {FC} from 'react';
import React from 'react';

import IconFlagSVG from '@src/assets/img/flag.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconFlag: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'ico',
  viewBox = '0 1 22 22',
}) => {
  return (
    <IconFlagSVG
      width={height}
      height={width}
      className={className}
      fill="#819AA9"
      viewBox={viewBox}
    />
  );
};

export default IconFlag;
