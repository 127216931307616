import type {Nullable} from '@yourcoach/shared/utils/utility-types';

type SlugAndId = {slug: Nullable<string> | undefined; id: string};

type LocationState = any;

export class PathBuilderService {
  public static toChat(chanelId: Nullable<string>, state?: LocationState) {
    return {
      pathname: '/chats',
      search: chanelId ? `?cid=${chanelId}` : '',
      state,
    };
  }
  public static toConference(conferenceId: string, state?: LocationState) {
    return {
      pathname: `/conferences/${conferenceId}`,
      state,
    };
  }
  public static toSession(sessionId: string, state?: LocationState) {
    return {
      pathname: `/event-page/${sessionId}`,
      state,
    };
  }

  public static toTask(taskId: string, state?: LocationState) {
    return {
      pathname: `/event-page/${taskId}`,
      state,
    };
  }

  public static toPractice(practice: SlugAndId, state?: LocationState) {
    return {
      pathname: `/coaches/${this.getSlugOrId(practice)}`,
      state,
    };
  }

  public static toProgram(
    practice: SlugAndId,
    program: SlugAndId,
    state?: LocationState,
  ) {
    return {
      pathname: `${this.toPractice(practice).pathname}/${this.getSlugOrId(
        program,
      )}`,
      state,
      // pathname: `/coaches/${this.getSlugOrId(
      //   practice,
      // )}/programs/${this.getSlugOrId(program)}`,
    };
  }

  public static toCourse(
    practice: SlugAndId,
    program: SlugAndId,
    courseId: string,
    programId?: string,
    state?: LocationState,
  ) {
    return {
      // pathname: `${this.toProgram(practice, program).pathname}/program`,
      pathname: `${this.toProgram(practice, program).pathname}/${courseId}`,
      search: `?sqid=${courseId}&pid=${programId}`,
      state,
    };
  }

  public static toProgramFuckTheRouterIHaveDOM(program: SlugAndId): string {
    return `${document.location.pathname}/${this.getSlugOrId(program)}`;
    // return `${document.location.pathname}/programs/${this.getSlugOrId(
    //   program,
    // )}`;
  }

  public static toProgramFuckTheRouterIHaveDOMWithSearch(
    program: SlugAndId,
  ): string {
    return `${this.toProgramFuckTheRouterIHaveDOM(program)}${
      window.location.search
    }`;
  }

  /**
   * Compatibility with old program links
   * TODO: Remove this after June 2022
   */
  public static get PATH_TEMPLATE_TO_PROGRAM_OLD() {
    return '/coaches/:slugPractice/programs/:slugProgram';
  }

  public static get PATH_TEMPLATE_TO_PROGRAM() {
    return '/coaches/:slugPractice/:slugProgram';
  }

  /**
   * Compatibility with old program links
   * TODO: Remove this after June 2022
   */
  public static get PATH_TEMPLATE_TO_COURSE_OLD() {
    return '/coaches/:slugPractice/programs/:slugProgram/program';
  }

  public static get PATH_TEMPLATE_TO_COURSE() {
    return '/coaches/:slugPractice/:slugProgram/:slugCourse';
  }

  private static getSlugOrId(slugAndId: SlugAndId) {
    return slugAndId.slug ?? slugAndId.id;
  }
}
