import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction, toJS} from 'mobx';

import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';
import DropIcon from '@yourcoach/shared/assets/icons/drop.svg';
import GlassIcon from '@yourcoach/shared/assets/icons/glass.svg';
import GlassBg from '@yourcoach/shared/assets/icons/glass-bg.svg';
import GlassFill from '@yourcoach/shared/assets/icons/glass-fill.svg';
import PlusIcon from '@yourcoach/shared/assets/icons/plus.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {CustomButton} from '@src/components/CustomForm';
import {SPOONACULAR_KEY} from '@src/config';
import {t} from '@src/i18n';
import type {MealData, MealIngredient, MealNutrient} from '@src/models/tasks';
import {WaterGlassVolume} from '@src/models/tasks';

import styles from '../styles.module.css';

const I18N_SCOPE = 'MealInput';
const GLASSES_COUNT_IN_ROW = 10;
const MAX_GLASSES = 20;
const GLASSES_GAP = 2;
const GLASS_STROKE = 2;

interface ILocalStore {
  query: string;
  isFetching: boolean;
  ingredients: MealIngredient[];
  glassesCount: number;
  nutrients: MealNutrient[];
  _timer: number;
  _onTextChange(text?: string): void;
  _onGlassPress(index: number): void;
}

interface Props {
  getMealData: (mealData: MealData) => void;
}

const MealInput: FC<Props> = ({getMealData}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        query: '',
        isFetching: false,
        ingredients: [],
        glassesCount: 0,
        nutrients: [],
        _timer: 0,
        _onTextChange(text = '') {
          clearTimeout(this._timer);

          if (this.isFetching) {
            _abortController.abort();
          }

          const trimmedText = text.trim();

          if (trimmedText && trimmedText !== this.query) {
            this._timer = setTimeout(() => {
              _parseText(text);
            }, 1000);
          }

          this.query = trimmedText;

          if (!trimmedText) {
            this.ingredients = [];
          }
        },
        _onGlassPress(index: number) {
          if (this.glassesCount === index + 1) {
            this.glassesCount = index;
          } else {
            this.glassesCount = index + 1;
          }
        },
      },
      {
        query: observable,
        isFetching: observable,
        ingredients: observable,
        glassesCount: observable,
        nutrients: observable,
        _timer: observable,
        _onTextChange: action,
        _onGlassPress: action,
      },
    ),
  ).current;

  let _abortController = new AbortController();

  useEffect(() => {
    return () => {
      clearTimeout(localStore._timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _parseText = async (text: string) => {
    try {
      const keywords = [
        'and',
        'or',
        'with',
        'by',
        'over',
        'along',
        'from',
        'aside',
        'on a side',
        'side',
      ];

      const params = {
        ingredientList: text
          .replace(new RegExp(keywords.join('|'), 'g'), ',')
          .split(',')
          .filter(Boolean)
          .map(x => x.trim())
          .join('\n'),
        servings: 1,
        includeNutrition: true,
      };

      runInAction(() => {
        localStore.isFetching = true;
      });

      const body = Object.keys(params)
        .map(
          key =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
        )
        .join('&');

      const endpoint = `https://api.spoonacular.com/recipes/parseIngredients?apiKey=${SPOONACULAR_KEY}`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
        signal: _abortController.signal,
      });

      if (!response.ok) {
        // TODO: log error
        return;
      }

      interface ResponseItem {
        id: string;
        amount: number;
        unit: string;
        name: string;
        nutrition: {
          weightPerServing: {
            amount: number;
            unit: string;
          };
          nutrients: {
            amount: number;
            unit: string;
            /**
             * Looks line spoonacular api response interface changed:
             *
             * Deprecation Notice (by December 31st, 2021): Nutrition.nutrients.title, Nutrition.flavanoids.title and Nutrition.properties.title => will be renamed to "name". Every field called "metaInformation" in the response (not in requests) will be called "meta". Every field "originalString" will be removed.
             *
             * @see https://spoonacular.com/food-api/updates - June, 2021
             *  */
            // title: string;
            name: string;
          }[];
        };
      }

      const json: ResponseItem[] = await response.json();

      const ingredients: MealIngredient[] = [];

      let nutrientsFromResponse: MealNutrient[][] = [];
      let nutrients: MealNutrient[] = [];

      const supportedNutrients = [
        'calories',
        'fat',
        'protein',
        'carbohydrates',
        'cholesterol',
      ];

      let waterIngredient: ResponseItem | undefined;

      json
        .filter(item => item.id)
        .forEach(item => {
          const {amount, unit, name, nutrition} = item;

          if (name.toLowerCase() === 'water') {
            waterIngredient = item;

            return;
          }

          const {weightPerServing} = nutrition;

          const weightStr = `${weightPerServing.amount}${weightPerServing.unit}`;

          ingredients.push({
            qty: amount,
            unit: unit === 'serving' ? `${unit} (${weightStr})` : unit,
            name,
          });

          nutrientsFromResponse.push(
            nutrition.nutrients
              .filter(nutrient =>
                supportedNutrients.includes(nutrient.name.toLowerCase()),
              )
              .map(nutrient => ({
                qty: nutrient.amount,
                unit: nutrient.unit,
                name: `${nutrient.name.slice(0, 4)}${
                  nutrient.name.split(/\s/)[1] || ''
                }`.toLowerCase(),
              })),
          );
        });

      if (nutrientsFromResponse.length) {
        const nutrientsQty: {[key: string]: number} = {};

        nutrientsFromResponse.forEach(item => {
          item.forEach(({name, qty}) => {
            if (nutrientsQty[name]) {
              nutrientsQty[name] += qty;
            } else {
              nutrientsQty[name] = qty;
            }
          });
        });

        nutrients = nutrientsFromResponse[0].map(item => {
          item.qty = parseFloat(nutrientsQty[item.name].toFixed(2));

          return item;
        });
      }

      runInAction(() => {
        if (waterIngredient) {
          const {
            nutrition: {weightPerServing},
          } = waterIngredient;

          const glassesCount = Math.min(
            Math.ceil(weightPerServing.amount / 226.8),
            MAX_GLASSES,
          );

          localStore.glassesCount = glassesCount;
        }

        localStore.ingredients = ingredients;

        localStore.nutrients = nutrients;
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        // console.log('error: ', error);
        // TODO: log error
      }
    } finally {
      _abortController = new AbortController();

      runInAction(() => {
        localStore.isFetching = false;
      });
    }
  };

  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    localStore._onTextChange(e.target.value);
  };

  const handleOnGlassPress =
    (i: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      localStore._onGlassPress(i);
    };

  const _onSetButtonPress = () => {
    const nutrients = localStore.nutrients.slice();
    const hasNutrients = nutrients.length;

    if (localStore.glassesCount) {
      nutrients.push({
        qty: localStore.glassesCount * WaterGlassVolume.OZ.amount,
        unit: WaterGlassVolume.OZ.unit,
        name: 'wate',
      });
    }

    getMealData({
      query: hasNutrients ? localStore.query : '',
      ingredients: toJS(localStore.ingredients),
      nutrients,
    });
  };

  return (
    <Observer>
      {() => {
        const glassesCountToRender =
          localStore.glassesCount >= GLASSES_COUNT_IN_ROW
            ? Math.min(localStore.glassesCount + 1, MAX_GLASSES)
            : GLASSES_COUNT_IN_ROW;

        const glassWidth =
          (400 - (GLASSES_COUNT_IN_ROW - 1) * GLASSES_GAP) /
          GLASSES_COUNT_IN_ROW;

        const glassHeightAspect = 1.35;
        const glassHeight = glassWidth * glassHeightAspect;

        return (
          <div className={`MealInput ${styles.MealInput}`}>
            <div className={styles.inputContainer}>
              <textarea
                onChange={handleOnChangeText}
                placeholder={t([I18N_SCOPE, 'placeholder'])}
              />
              {localStore.isFetching ? (
                <div className={styles.mealProcessingLabel}>
                  {t([I18N_SCOPE, 'processing_label'])}
                </div>
              ) : null}
            </div>
            <div className={styles.ingredientsContainer}>
              {localStore.ingredients.map((ingredient, i) => {
                const {name, qty, unit} = ingredient;

                return (
                  <div
                    key={`${name}-${qty}-${unit}-${i}`}
                    className={styles.ingredientRow}
                  >
                    <CheckIcon
                      width={20}
                      height={20}
                      fill={themes.light.color.secondary1}
                    />
                    <div className={styles.ingredientRowText}>
                      {`${name} - ${qty} ${unit}`}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.waterSectionHeader}>
              <div className={styles.waterHeaderTitle}>
                {t([I18N_SCOPE, 'water_section_header'])}
              </div>
              <div className={styles.waterHeaderSum}>
                {localStore.glassesCount
                  ? [
                      `${
                        localStore.glassesCount * WaterGlassVolume.OZ.amount
                      } ${t([
                        'shared',
                        'nutrient_unit',
                        WaterGlassVolume.OZ.unit,
                      ])}`,
                      ' / ',
                      `${(
                        localStore.glassesCount * WaterGlassVolume.L.amount
                      ).toFixed(1)} ${t([
                        'shared',
                        'nutrient_unit',
                        WaterGlassVolume.L.unit,
                      ])}`,
                    ].join('')
                  : ''}
              </div>
            </div>
            <div className={styles.glassesContainer}>
              {new Array(glassesCountToRender).fill('').map((_, i) => {
                const plusSize = 12 * (glassWidth * 0.07);
                const dropSize = 6 * (glassWidth * 0.055);

                return (
                  <div key={i} onClick={handleOnGlassPress(i)}>
                    <div className={styles.glass}>
                      <GlassBg
                        width={glassWidth}
                        height={glassHeight}
                        fill={themes.light.color.background4}
                        className={styles.glassBg}
                      />
                      {i < localStore.glassesCount ? (
                        <div className={styles.glassFillContainer}>
                          <GlassFill
                            width={glassWidth - GLASS_STROKE * 2}
                            height={glassHeight - GLASS_STROKE * 2}
                            fill={themes.light.color.icon4}
                            className={styles.glassFill}
                          />
                          <DropIcon
                            width={dropSize}
                            height={dropSize}
                            fill={themes.light.color.icon2}
                            className={styles.dropIcon}
                          />
                        </div>
                      ) : null}
                      <GlassIcon
                        width={glassWidth}
                        height={glassHeight}
                        fill={themes.light.color.icon4}
                        className={styles.glassIcon}
                      />
                      {i === localStore.glassesCount ? (
                        <PlusIcon
                          width={plusSize}
                          height={plusSize}
                          fill={themes.light.color.icon5}
                          className={styles.plusIcon}
                        />
                      ) : null}
                      {localStore.glassesCount >= GLASSES_COUNT_IN_ROW &&
                      i + 1 === GLASSES_COUNT_IN_ROW ? (
                        <div className={styles.glassCheckContainer}>
                          <CheckIcon
                            width={16}
                            height={16}
                            fill={themes.light.color.icon2}
                            className={styles.glassCheckIcon}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              {localStore.glassesCount > GLASSES_COUNT_IN_ROW
                ? new Array(
                    Math.max(MAX_GLASSES - localStore.glassesCount - 1, 0),
                  )
                    .fill('')
                    .map((_, i) => <div key={i} className={styles.glass} />)
                : null}
            </div>
            <div className={styles.buttonContainer}>
              <CustomButton
                type="button"
                disabled={
                  localStore.nutrients.slice().length === 0 &&
                  localStore.glassesCount === 0
                }
                classButton={`blueButt ${styles.addBut}`}
                onClick={_onSetButtonPress}
              >
                <span>{t([I18N_SCOPE, 'set_button'])}</span>
              </CustomButton>
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default memo(MealInput);
