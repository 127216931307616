import type {FC} from 'react';
import React from 'react';

import IconCupSVG from '@yourcoach/shared/assets/icons/cup.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
  fill?: string;
}

const IconCup: FC<Props> = ({
  height = '100%',
  width = '100%',
  viewBox = '0 0 20 20',
  className = '',
  fill,
}) => {
  return (
    <IconCupSVG
      className={`${className}`}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill ? fill : undefined}
    />
  );
};

export default IconCup;
