import type {FC} from 'react';
import React, {memo} from 'react';

import {MainContainerWithMenu} from '@src/layouts';
import GoalsSelectCourseTab from '@src/modules/legacy/Goals/GoalsSelectCourseTab/GoalsSelectCourseTab';

interface Props {}

const GoalsPage: FC<Props> = () => {
  return (
    <MainContainerWithMenu isWithNewCrumbs>
      <div className="SquadPage percent100H">
        <div className="greyArea percent100H">
          <GoalsSelectCourseTab />
        </div>
      </div>
    </MainContainerWithMenu>
  );
};

export default memo(GoalsPage);
