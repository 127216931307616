import React from 'react';

import classNames from 'classnames';

import styles from './TextArea.module.scss';

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  id?: string;
  label?: string;
  error?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({id, label, error, disabled, ...rest}, ref) => {
    id = id || Math.random().toString(36).substring(2);

    return (
      <div
        className={classNames(
          'TextArea',
          styles.TextArea,
          disabled && styles.disabled,
        )}
      >
        {/* @ts-ignore */}
        <textarea {...rest} ref={ref} id={id} disabled={disabled} />
        {label ? <label htmlFor={id}>{label}</label> : null}
        <span className={styles.error}>{error}</span>
      </div>
    );
  },
);

export default TextArea;
