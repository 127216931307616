import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import ChatsContext from './ChatsContext';
import useChatsLocalStore from './useChatsLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const ChatsState: FC<Props> = ({children}) => {
  const localStore = useChatsLocalStore();

  return (
    <ChatsContext.Provider value={localStore}>{children}</ChatsContext.Provider>
  );
};

export default memo(ChatsState);
