import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import {runInAction} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import ImageAddIcon from '@yourcoach/shared/assets/icons/img-add.svg';
import {
  getDescriptionSchema,
  getTitleSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import Image from '@src/components/Image';
import ImageEditor from '@src/components/ImageEditor';
import Modal from '@src/components/ModalNew';
import TextArea from '@src/components/TextArea';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import AddFileModal from '@src/modules/library/AddFileModal';

import styles from './Description.module.css';
import commonStyles from './styles.module.css';
import type {TabProps, TabRef} from './useTabs';

const I18N_SCOPE = 'shared.CRUProgramDescription';

export const PROGRAM_AVATAR_UPLOADING_KEY = 'program_avatar';

const CRUProgramDescription = React.forwardRef<TabRef, TabProps>(
  ({program, userIsOwner}, ref) => {
    const {
      stores: {fileStore},
    } = useContext(AppContext);

    useImperativeHandle(ref, () => ({
      getData,
    }));

    const [avatar, setAvatar] = useState<IFile | File>();

    const avatarSource = useMemo(
      () =>
        avatar
          ? (avatar as IFile)._id
            ? getFileSrc(avatar as IFile, 250).url || ''
            : URL.createObjectURL(avatar)
          : '',
      [avatar],
    );

    useEffect(() => {
      return () => {
        if (avatar instanceof File) {
          URL.revokeObjectURL(avatarSource);
        }
      };
    }, [avatar, avatarSource]);

    const [addAvatarModalIsOpen, showAddAvatarModal, hideAddAvatarModal] =
      useIsVisible();

    const [editAvatarModalIsVisible, showEditAvatarModal, hideEditAvatarModal] =
      useIsVisible();

    const fields = useRef({
      title: createHtmlInputField('title', {
        validationRule: getTitleSchema(),
        defaultValue: '',
      }),
      description: createHtmlInputField<HTMLTextAreaElement>('description', {
        validationRule: getDescriptionSchema(),
        defaultValue: '',
      }),
    }).current;

    const getData = useCallback(() => {
      if (validateFields(fields)) {
        return {
          title: fields.title.value,
          description: fields.description.value,
          avatar: avatar || null,
          editions: program && program.editions ? program.editions : [],
        };
      }
    }, [avatar, fields, program]);

    useEffect(() => {
      if (program) {
        runInAction(() => {
          fields.title.value = program.title || fields.title.value;
          fields.description.value =
            program.description || fields.description.value;
        });

        if (program.avatar) {
          setAvatar(program.avatar);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAvatarClick = useCallback(() => {
      showAddAvatarModal();
    }, [showAddAvatarModal]);

    const onAvatarSelect = useCallback(
      (files: File[]) => {
        hideAddAvatarModal();

        const [file] = files;

        if (file) {
          fileStore.upload(file, {
            name: file.name,
            uploadingKey: PROGRAM_AVATAR_UPLOADING_KEY,
          });
        }

        setAvatar(files[0]);
      },
      [fileStore, hideAddAvatarModal],
    );

    const onEditAvatarButtonClick = useCallback(() => {
      showEditAvatarModal();
    }, [showEditAvatarModal]);

    const onAvatarEdit = useCallback(
      (file: File) => {
        hideEditAvatarModal();
        onAvatarSelect([file]);
      },
      [hideEditAvatarModal, onAvatarSelect],
    );

    const onRemoveAvatarButtonClick = useCallback(() => {
      setAvatar(undefined);
    }, []);

    return (
      <div>
        <div className={commonStyles.dataRow}>
          {!userIsOwner ? (
            <div className={commonStyles.message}>
              {t([I18N_SCOPE, 'edit_description_label'])}
            </div>
          ) : null}
        </div>
        <div className={commonStyles.dataRow}>
          <h3 className={commonStyles.header}>
            {t([I18N_SCOPE, 'avatar_label'])}
            {':'}
          </h3>
          <AddFileModal
            isOpen={addAvatarModalIsOpen}
            onAfterClose={hideAddAvatarModal}
            mode="select"
            fileType="image"
            onSelect={onAvatarSelect}
          />
          <div className={styles.avatarContainer}>
            <div
              className={classNames(
                styles.avatar,
                !userIsOwner && styles.disabled,
              )}
              onClick={userIsOwner ? onAvatarClick : undefined}
            >
              <Image
                src={avatarSource}
                placeholder={
                  <div className={styles.avatarPlaceholder}>
                    <ImageAddIcon />
                  </div>
                }
              />
            </div>
            {avatar && userIsOwner ? (
              <>
                <Button
                  className={styles.avatarActionButton}
                  onClick={onEditAvatarButtonClick}
                >
                  {t([I18N_SCOPE, 'edit_avatar_button'])}
                </Button>
                <Button
                  className={classNames(
                    styles.avatarActionButton,
                    styles.danger,
                  )}
                  onClick={onRemoveAvatarButtonClick}
                >
                  {t([I18N_SCOPE, 'remove_avatar_button'])}
                </Button>
              </>
            ) : null}
          </div>
          {avatar ? (
            <Modal
              isOpen={editAvatarModalIsVisible}
              onAfterClose={hideEditAvatarModal}
              title={t([I18N_SCOPE, 'edit_avatar_modal_title'])}
            >
              <ImageEditor image={avatar} onSuccess={onAvatarEdit} />
            </Modal>
          ) : null}
        </div>
        <div className={commonStyles.dataRow}>
          <h3 className={commonStyles.header}>
            {t([I18N_SCOPE, 'title_label'])}
            {':'}
          </h3>
          <Observer>
            {() => (
              <TextField
                ref={fields.title.ref}
                disabled={!userIsOwner}
                value={fields.title.value}
                placeholder={t([I18N_SCOPE, 'title_text_input_placeholder'])}
                onChange={fields.title.onChange}
                error={fields.title.error || ''}
              />
            )}
          </Observer>
        </div>
        <div className={commonStyles.dataRow}>
          <h3 className={commonStyles.header}>
            {t([I18N_SCOPE, 'description_label'])}
            {':'}
          </h3>
          <Observer>
            {() => (
              <TextArea
                ref={fields.description.ref}
                className={styles.descriptionTextArea}
                disabled={!userIsOwner}
                value={fields.description.value}
                placeholder={t([
                  I18N_SCOPE,
                  'description_text_input_placeholder',
                ])}
                onChange={fields.description.onChange}
                error={fields.description.error || ''}
              />
            )}
          </Observer>
        </div>
      </div>
    );
  },
);

export default React.memo(CRUProgramDescription, isEqual);
