import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {}

const CircleGrey: FC<Props> = () => {
  return (
    <svg viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="CircleGrey1" fill="white">
        <path d="M2.74957 32.2149C2.04022 32.0249 1.61724 31.295 1.82915 30.5919C3.5629 24.8392 6.48464 19.5086 10.4108 14.9475C14.6263 10.0502 19.8914 6.16594 25.8147 3.58345C31.7379 1.00095 38.1671 -0.213393 44.6242 0.030687C50.6381 0.25801 56.5322 1.74493 61.9272 4.38961C62.5866 4.71285 62.8335 5.51947 62.49 6.16855C62.1465 6.81764 61.3428 7.06327 60.6827 6.74137C55.6415 4.28284 50.1383 2.90038 44.5238 2.68816C38.466 2.45917 32.4345 3.59841 26.8775 6.02119C21.3206 8.44397 16.3811 12.088 12.4263 16.6824C8.76088 20.9406 6.0286 25.9137 4.39921 31.2806C4.18586 31.9832 3.45891 32.405 2.74957 32.2149Z" />
      </mask>
      <path
        d="M2.74957 32.2149C2.04022 32.0249 1.61724 31.295 1.82915 30.5919C3.5629 24.8392 6.48464 19.5086 10.4108 14.9475C14.6263 10.0502 19.8914 6.16594 25.8147 3.58345C31.7379 1.00095 38.1671 -0.213393 44.6242 0.030687C50.6381 0.25801 56.5322 1.74493 61.9272 4.38961C62.5866 4.71285 62.8335 5.51947 62.49 6.16855C62.1465 6.81764 61.3428 7.06327 60.6827 6.74137C55.6415 4.28284 50.1383 2.90038 44.5238 2.68816C38.466 2.45917 32.4345 3.59841 26.8775 6.02119C21.3206 8.44397 16.3811 12.088 12.4263 16.6824C8.76088 20.9406 6.0286 25.9137 4.39921 31.2806C4.18586 31.9832 3.45891 32.405 2.74957 32.2149Z"
        stroke="#f1f5f6"
        strokeWidth="20"
        mask="url(#CircleGrey1)"
      />
      <mask id="CircleGrey2" fill="white">
        <path d="M80.1935 61.79C80.8489 62.1211 81.1136 62.9222 80.7624 63.5671C76.2744 71.8076 69.2062 78.3658 60.6251 82.2219C52.044 86.078 42.4469 87.0086 33.3052 84.8929C32.5897 84.7273 32.1664 83.9975 32.354 83.2875C32.5417 82.5775 33.2691 82.1563 33.9849 82.3204C42.5372 84.2814 51.5097 83.4025 59.535 79.7962C67.5604 76.1899 74.1752 70.0643 78.3875 62.3673C78.7401 61.7231 79.538 61.4588 80.1935 61.79Z" />
      </mask>
      <path
        d="M80.1935 61.79C80.8489 62.1211 81.1136 62.9222 80.7624 63.5671C76.2744 71.8076 69.2062 78.3658 60.6251 82.2219C52.044 86.078 42.4469 87.0086 33.3052 84.8929C32.5897 84.7273 32.1664 83.9975 32.354 83.2875C32.5417 82.5775 33.2691 82.1563 33.9849 82.3204C42.5372 84.2814 51.5097 83.4025 59.535 79.7962C67.5604 76.1899 74.1752 70.0643 78.3875 62.3673C78.7401 61.7231 79.538 61.4588 80.1935 61.79Z"
        stroke="#f1f5f6"
        strokeWidth="20"
        mask="url(#CircleGrey2)"
      />
    </svg>
  );
};

export default memo(CircleGrey);
