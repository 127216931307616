import type {FC} from 'react';
import React, {memo} from 'react';

import TexFieldSVG from '@src/assets/img/text-field.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
}

const IconTexField: FC<Props> = ({
  height = '100%',
  width = '100%',
  customClass = '',
}) => {
  return (
    <TexFieldSVG
      className={`${customClass}`}
      width={height}
      height={width}
      fill="#819AA9"
    />
  );
};

export default memo(IconTexField);
