import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import IconCalendar from '@yourcoach/shared/assets/icons/calendar.svg';
import UserIcon from '@yourcoach/shared/assets/icons/user.svg';
import IconGroupSmall from '@yourcoach/shared/assets/icons/user-group.svg';

import Image from '../../../components/Image';
import AppContext from '../../../context/App';

import type {CourseT} from '.';
import styles from './CoursesListItem.module.css';

interface Props {
  course: CourseT;
  onClick?: (course: CourseT) => void;
}

const CoursesListItem: FC<Props> = ({course, onClick}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const onClickCb = useCallback(() => {
    onClick && onClick(course);
  }, [onClick, course]);

  const avatar = useMemo(
    () => getFileSrc(course.client.avatar, 250).url || '',
    [course],
  );

  return (
    <div className={styles.Component} onClick={onClickCb}>
      <div className={styles.row}>
        <IconCalendar className={styles.icon} />
        <span className={styles.ellipsis}>
          {getCourseDurationString(course) ||
          currentUserStore.user?.roles?.includes('coach')
            ? 'Ready to begin'
            : 'Waiting for your Coach to start the program'}
        </span>
      </div>
      {course.edition ? (
        <div className={styles.row}>
          {course.edition.group_size === 1 && course.client_id ? (
            <>
              <Image
                className={styles.icon}
                src={avatar}
                placeholder={<UserIcon />}
              />
              <div className={styles.ellipsis}>{course.client.name}</div>
            </>
          ) : (
            <>
              <IconGroupSmall className={styles.icon} />
              <div className={styles.courseSize}>
                {`${course.counters.memberships.accepted}/${course.edition.group_size}`}
              </div>
            </>
          )}
          <div className={styles.courseStatus}>{<>&nbsp;</>}</div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(CoursesListItem);
