import {useEffect, useRef} from 'react';

const useIntersectionObserver = (callBack: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerInt: IntersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          callBack();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (ref.current) {
      observerInt.observe(ref.current);
    }
  }, [ref, callBack]);

  return ref;
};

export default useIntersectionObserver;
