import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {getGoalAttitudeIcon} from '@yourcoach/shared/api/goal';

import {CustomButton} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {t} from '@src/i18n';

import type {BoundGoal} from '../RateGoal/RateGoal';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'RateBoundGoalsListItem';

export interface Props<T extends BoundGoal = BoundGoal> {
  goal: T;
  onPress?: (goal: T) => void;
  onAgreeButtonPress?: (goal: T) => void;
  onDisagreeButtonPress?: (goal: T) => void;
  agreeInProgress?: boolean;
  disagreeInProgress?: boolean;
  containerStyle?: string;
}

const RateBoundGoalsListItem: FC<Props> = ({
  goal,
  onPress,
  onAgreeButtonPress,
  onDisagreeButtonPress,
  agreeInProgress,
  disagreeInProgress,
  containerStyle = '',
}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress(goal);
  }, [goal, onPress]);

  const onAgreeButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onAgreeButtonPress && onAgreeButtonPress(goal);
    },
    [goal, onAgreeButtonPress],
  );

  const onDisagreeButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onDisagreeButtonPress && onDisagreeButtonPress(goal);
    },
    [goal, onDisagreeButtonPress],
  );

  const attitudeString = useMemo<JSX.Element>(() => {
    let result = (
      <div className={styles.attitudeString}>
        {t([I18N_SCOPE, 'no_attitude_label'])}
      </div>
    );

    let attitude =
      goal.coach_attitude !== null
        ? goal.coach_attitude
        : goal.client_attitude !== null
        ? goal.client_attitude
        : null;

    if (attitude !== null) {
      result = (
        <div className={styles.attitudeString}>
          <div className={styles.icon}>
            {getGoalAttitudeIcon((attitude * 1000) / 10)}
          </div>
          <div className={styles.text}>{(attitude * 1000) / 10}%</div>
        </div>
      );
    }

    return result;
  }, [goal.client_attitude, goal.coach_attitude]);

  return (
    <Observer>
      {() => (
        <div
          className={`RateBoundGoalsListItem ${styles.RateBoundGoalsListItem} ${containerStyle}`}
          onClick={onPressCb}
        >
          <div className={styles.userAvatar}>
            <OtherUserProfileImg
              avatar={goal.client!.avatar}
              title={goal.client!.name}
              isOverlay={!goal.client!.avatar}
            />
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <div className={styles.userName}>{goal.client.name}</div>
              <div className={styles.content}>
                {goal.coach_attitude === null && goal.status !== 'archived' ? (
                  <div className={styles.buttonsContainer}>
                    <CustomButton
                      type="button"
                      classButton={`blueButt ${styles.disabledButton}`}
                      disabled={agreeInProgress || disagreeInProgress}
                      onClick={onAgreeButtonPressCb}
                    >
                      {agreeInProgress ? (
                        <Loader />
                      ) : (
                        <span>{t([I18N_SCOPE, 'agree_button'])}</span>
                      )}
                    </CustomButton>

                    <CustomButton
                      type="button"
                      classButton={`blueButt ${styles.disagreeDisabledButtonStyle}`}
                      disabled={agreeInProgress || disagreeInProgress}
                      onClick={onDisagreeButtonPressCb}
                    >
                      {disagreeInProgress ? (
                        <Loader />
                      ) : (
                        <span>{t([I18N_SCOPE, 'disagree_button'])}</span>
                      )}
                    </CustomButton>
                  </div>
                ) : null}
                <div className={styles.attitude}>{attitudeString}</div>
              </div>
            </div>
            {goal.coach_comment ? (
              <div className={styles.commentContainer}>
                <div className={styles.comment}>{goal.coach_comment}</div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(RateBoundGoalsListItem);
