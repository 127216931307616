import type {FC} from 'react';
import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';

import {t} from '@src/i18n';

// import {PATH_TYPE} from '@src/routes/utils';
import {PathBuilderService} from '../../../v2/services/PathBuilderService';
import Course from '../Course/Course';
import type {Section as ISection} from '../useMySquads';

import styles from './../styles.module.css';

const I18N_SCOPE = 'WorkspacePracticeTab';

interface Props {
  section: ISection;
}

const CourseList: FC<Props> = ({section}) => {
  const courses = section.data;
  const history = useHistory();
  const program = section.program;

  const handleOnProgramClick = () => {
    // history.push(
    //   `/${PATH_TYPE.coaches}/${
    //     program.expanded_coaches[0].slug
    //       ? program.expanded_coaches[0].slug
    //       : program.expanded_coaches[0]._id
    //       ? program.expanded_coaches[0]._id
    //       : ''
    //   }/programs/${program.slug ? program.slug : program._id}`,
    // );

    const practiceSlug = program.expanded_coaches[0].slug;
    const practiceId = program.expanded_coaches[0]._id ?? '';
    const programSlug = program.slug;
    const programId = program._id;

    history.push(
      PathBuilderService.toProgram(
        {slug: practiceSlug, id: practiceId},
        {slug: programSlug, id: programId as string},
      ),
    );
  };

  return (
    <div className={`CourseList ${styles.CourseList}`}>
      {courses.map(course => {
        return <Course key={course._id} course={course} />;
      })}
      {section.hasMore ? (
        <div className={styles.seeAllBut} onClick={handleOnProgramClick}>
          {t([I18N_SCOPE, 'see_all_button'])}
        </div>
      ) : null}
    </div>
  );
};

export default memo(CourseList);
