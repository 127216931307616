import type {FC} from 'react';
import React, {memo, useCallback, useState} from 'react';

import CheckIcon from '@yourcoach/shared/assets/icons/primary/Tick Square.svg';

import {
  labelCopyLink,
  labelLinkCopied,
  labelLinkCopiedMessage,
} from '@src/common/i18n/i18nCommon';
import {CustomInput} from '@src/components/CustomForm';
import IconIconLink from '@src/components/icons/IconLink/IconLink';

import styles from './styles.module.css';

interface Props {
  onSuccess?: () => void;
}

const SharedLink: FC<Props> = ({onSuccess = () => {}}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const copiedLink = useCallback(() => {
    const el = document.createElement('textarea');

    el.value = document.location.href;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setLinkCopied(true);

    onSuccess();
  }, [onSuccess]);

  return (
    <div className={`SharedLink ${styles.SharedLink}`}>
      <div className={styles.inputSharedContainer}>
        <div>
          <CustomInput
            type="input"
            label={''}
            customClassLabel="standardLabel"
            customClassInput={'standardInput border shared'}
            customClassCont="customInputContainerShared"
            id={'copy'}
            value={document.location.href}
          />
        </div>
        <div className={styles.inputSharedButton} onClick={copiedLink}>
          <div className={styles.inputSharedButtonIcon}>
            <IconIconLink />
          </div>
          <div className={styles.inputSharedButtonLabel}>{labelCopyLink()}</div>
        </div>
      </div>
      {linkCopied ? (
        <div className={styles.linkCopiedMessageContainer}>
          <div className={styles.linkCopiedMessageHeaderContainer}>
            <div className={styles.linkCopiedMessageCheckIcon}>
              <CheckIcon />
            </div>
            <div className={styles.linkCopiedMessageHeader}>
              {labelLinkCopied()}
            </div>
          </div>
          <div className={styles.linkCopiedMessage}>
            {labelLinkCopiedMessage()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(SharedLink);
