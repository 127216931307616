import type {Expand} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {fileStore} from '@yourcoach/shared/api/media/file';
import type {Edition} from '@yourcoach/shared/api/program';
import {
  editionStore,
  getUniqueProgramSlug,
  programStore,
} from '@yourcoach/shared/api/program';
import {currentUserStore} from '@yourcoach/shared/api/user';
import {waitForAsync} from '@yourcoach/shared/utils';

import {expand as programExpand} from '@src/modules/programs/utils';

import type {ProgramT} from '..';
import {PROGRAM_AVATAR_UPLOADING_KEY} from '../Description';
import {PROGRAM_CONTRACT_UPLOADING_KEY} from '../Details';

const createProgram = async (program: ProgramT) => {
  try {
    const slug = await getUniqueProgramSlug(
      program.title,
      currentUserStore.user!._id,
    );

    const coachIds = Object.keys(program.coaches! || {});

    const programData: Pick<
      ProgramT,
      'title' | 'slug' | 'description' | 'coaches'
    > & {
      avatar_id?: ProgramT['avatar_id'];
      expand?: Expand;
    } = {
      title: program.title,
      slug,
      description: program.description,
      coaches: program.coaches,
      expand: programExpand,
    };

    const lastEdition = program.editions[program.editions.length - 1]!;

    let avatarId: string | null = null;

    if (program.avatar) {
      if ((program.avatar as IFile)._id) {
        avatarId = (program.avatar as IFile)._id;
      } else {
        const uploading = fileStore.uploading.get(PROGRAM_AVATAR_UPLOADING_KEY);

        if (uploading) {
          if (uploading.inProgress) {
            await waitForAsync(() => uploading.inProgress === false);
          }

          if (uploading.success && uploading.file) {
            avatarId = uploading.file._id;
          } else if (uploading.error) {
            const file = await fileStore.upload(program.avatar as File);

            avatarId = file._id;
          }
        }
      }
    }

    programData.avatar_id = avatarId;

    let createdProgram = (await programStore.create(programData)) as ProgramT;

    const editionData: Pick<
      Edition,
      | 'title'
      | 'group_size'
      | 'duration'
      | 'payment_plan'
      | 'conferences'
      | 'tasks'
      | 'channels'
      | 'private_data_request'
      | 'client_conferences'
    > & {
      program_id: Edition['program_id'];
      expand?: Expand;
      contract_id?: Edition['contract_id'];
      materials: Edition['materials'];
    } = {
      program_id: createdProgram._id,
      title: `v${program.editions.length}`,
      group_size: lastEdition.group_size,
      duration: lastEdition.duration,
      payment_plan:
        lastEdition.payment_plan && lastEdition.payment_plan.total
          ? lastEdition.payment_plan
          : null,
      conferences: lastEdition.conferences,
      tasks: lastEdition.tasks,
      channels: [],
      materials: lastEdition.materials // copying program use case
        .filter(material => coachIds.includes(material.coach_id))
        .map(item => {
          const material = {...item};

          // @ts-ignore
          delete material.files;

          return material;
        }),
      private_data_request: lastEdition.private_data_request,
      client_conferences: lastEdition.client_conferences,
      expand: {
        edition: ['contract_id', 'materials.file_ids'],
      },
    };

    let contractId: string | null = null;

    if (lastEdition.contract) {
      if ((lastEdition.contract as IFile)._id) {
        contractId = (lastEdition.contract as IFile)._id;
      } else {
        const uploading = fileStore.uploading.get(
          PROGRAM_CONTRACT_UPLOADING_KEY,
        );

        if (uploading) {
          if (uploading.inProgress) {
            await waitForAsync(() => uploading.inProgress === false);
          }

          if (uploading.success && uploading.file) {
            contractId = uploading.file._id;
          } else if (uploading.error) {
            const file = await fileStore.upload(lastEdition.contract as File);

            contractId = file._id;
          }
        }
      }
    }

    editionData.contract_id = contractId;

    const edition = ((await editionStore.create(
      editionData,
    )) as ProgramT['editions'][0])!;

    createdProgram.edition_ids = [edition._id];
    createdProgram.editions = [edition];

    createdProgram = (await programStore.update(createdProgram, {
      edition_ids: [edition._id],
      expand: programExpand,
    })) as ProgramT;

    return createdProgram;
  } catch (error) {
    throw error;
  }
};

export default createProgram;
