import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';
import {Observer} from 'mobx-react';

import type {Task} from '@yourcoach/shared/api/task';
import {taskIsDone} from '@yourcoach/shared/api/task';
import AttachIcon from '@yourcoach/shared/assets/icons/attach.svg';
import ChatIcon from '@yourcoach/shared/assets/icons/chat.svg';
import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';

import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import type {Expanded as TaskExpanded} from '@src/models/tasks';

import styles from '../styles.module.css';

type TaskT = Task & TaskExpanded;

interface Props<T extends TaskT = TaskT> {
  task: TaskT;
  onPress?: (task: T) => void;
  showTitle?: boolean;
  showDate?: boolean;
  className?: string;
  isSelect?: boolean;
}

const ClientTasksListItem: FC<Props> = ({
  task,
  onPress,
  className,
  isSelect = false,
}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress(task);
  }, [task, onPress]);

  const isDone = useMemo(() => taskIsDone(task), [task]);
  const hasComment = useMemo(() => task.report, [task.report]);
  const hasAttachments = useMemo(
    () => task.attachment_ids.length,
    [task.attachment_ids.length],
  );

  return (
    <Observer>
      {() => (
        <div
          className={`ClientTasksListItem ${styles.ClientTasksListItem} ${
            isSelect ? styles.isSelect : ''
          }  ${className} `}
          onClick={onPress ? onPressCb : undefined}
        >
          <div className={styles.userContainer}>
            <div className={styles.avatarContainer}>
              <OtherUserProfileImg
                avatar={task.user.avatar}
                title={task.user.name}
                titleFontSize="14px"
                isBGGradient
                getTitleIconIfNoImage
                isOverlay={!task.user.avatar}
              />
            </div>
            <div>
              <div className={styles.userName}>{task.user.name}</div>
              {hasComment || hasAttachments ? (
                <div className={styles.infoContainer}>
                  {hasComment ? <ChatIcon width={16} height={16} /> : null}
                  {hasAttachments ? (
                    <AttachIcon width={16} height={16} />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={`${styles.checkIconContainerStyle} ${
              isDone ? styles.isDone : ''
            }`}
          >
            <CheckIcon width={28} height={28} />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ClientTasksListItem);
