import React, {useContext} from 'react';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import VirtualHomeStep from './VirtualHomeStep';

const I18N_SCOPE = 'Onboarding.greeting';

const GreetingStep = props => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  return (
    <VirtualHomeStep
      title={t([I18N_SCOPE, 'title'], {
        name: currentUserStore.user?.name,
      })}
      description={t([I18N_SCOPE, 'description'])}
      step={4}
      wideLeft
      {...props}
    />
  );
};

export default GreetingStep;
