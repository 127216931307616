import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import {Img as Image} from 'react-image';
import {useHistory, useParams} from 'react-router-dom';

import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {ParsedDuration, ProgramType} from '@yourcoach/shared/api/program';
import {
  getProgramType,
  parseDuration,
  ProgramTypeEnum,
} from '@yourcoach/shared/api/program';

import {labelMySquadsPathName} from '@src/common/i18n/i18nCommon';
import {labelJoinNow} from '@src/common/i18n/i18nPrograms';
import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import {
  IconCalendar,
  IconPrev,
  IconUser,
  IconUserGroup,
} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';
import useIsLimitedEdition from '@src/hooks/useIsLimitedEdition';

import SeeProgramContext from '../context/SeeProgramContext';

import styles from './../styles.module.css';

interface Props {}

const SideColumn: FC<Props> = () => {
  const seeProgramContext = useContext(SeeProgramContext);
  const history = useHistory();
  const {slugPractice}: {slugPractice: string; slugProgram: string} =
    useParams();

  const [programType, setProgramType] = useState<ProgramType | null>(null);
  const [duration, setDuration] = useState<ParsedDuration | null>(null);

  const {isLimitedEdition} = useIsLimitedEdition(
    seeProgramContext?.program?._id,
  );

  useEffect(() => {
    if (seeProgramContext && seeProgramContext?.program) {
      const countEditions = seeProgramContext?.program.editions.length - 1;
      const lastEdition = seeProgramContext?.program.editions[countEditions];

      setProgramType(getProgramType(lastEdition));
      setDuration(parseDuration(lastEdition.duration));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeProgramContext?.program]);

  const handleOnClickJoinNow = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      history.push({
        pathname: '/login',
      });
    },
    [history],
  );

  const handleOnBackPractice = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      history.push({
        pathname: `/coaches/${slugPractice}`,
        search: location.search,
      });
    },
    [history, slugPractice],
  );

  return (
    <>
      {seeProgramContext && seeProgramContext.program ? (
        <div className={'SideColumn'}>
          <div className={styles.ImageProgramContainer}>
            <div className={styles.ImageProgramAbsContainer}>
              <Image
                src={
                  getFileSrc(seeProgramContext.program.avatar, 500).url || ''
                }
                loader={<Loader height="55%" width="55%" />}
                unloader={
                  <AvatarLoader
                    title={seeProgramContext.program.title}
                    isBGGradient
                    directionGradient="180deg"
                  />
                }
              />
              <OverlayImage className={styles.OverlayImage} />
              {isLimitedEdition !== null && !isLimitedEdition ? (
                <div
                  className={styles.joinNowBut}
                  onClick={handleOnClickJoinNow}
                >
                  {labelJoinNow()}
                </div>
              ) : null}
            </div>
          </div>
          {isLimitedEdition !== null && !isLimitedEdition ? (
            <div
              className={styles.backToPracticeButton}
              onClick={handleOnBackPractice}
            >
              <div className={styles.iconContainer}>
                <IconPrev viewBox="5 5 20 20" />
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.label}>{labelMySquadsPathName()}</div>

                <div className={styles.practiceName}>
                  {seeProgramContext.program.expanded_coaches[0].coach_title}
                </div>
              </div>
            </div>
          ) : null}
          <div className={styles.paymentPlanOptionContainer}>
            <div className={styles.paymentPlanOption}>
              <div className={styles.coachAvatarContainer}>
                <OtherUserProfileImg
                  avatar={seeProgramContext.program.expanded_coaches[0].avatar}
                  title={seeProgramContext.program.expanded_coaches[0].name}
                />
              </div>
              <span>{seeProgramContext.program.expanded_coaches[0].name}</span>
            </div>
            <div className={styles.paymentPlanOption}>
              <div className={styles.programTypeIcon}>
                {programType &&
                programType.id === ProgramTypeEnum.INDIVIDUAL.id ? (
                  <IconUser viewBox="0 0 21 21" />
                ) : (
                  <IconUserGroup
                    className={styles.groupIcon}
                    viewBox="0 0 32 32"
                  />
                )}
              </div>
              <span>{programType ? programType.toString() : ''}</span>
            </div>
            <div className={styles.paymentPlanOption}>
              <div className={styles.paymentPlanIcon}>
                <IconCalendar
                  className={styles.calendarIcon}
                  viewBox="0 0 24 24"
                />
              </div>
              <span>{duration ? duration.toString() : ''}</span>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default memo(SideColumn);
