import type {ChangeEventHandler, FC} from 'react';
import React, {memo, useCallback, useContext, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import {v4 as uuidv4} from 'uuid';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {createField, validateFields} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelCertificatePhoto,
  labelCertificatePublic,
  labelCertificateSchool,
  labelCertificateTitle,
} from '@src/common/i18n/i18nProfile';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ErrorMessage from '@src/components/CustomForm/ErrorMessage/ErrorMessage';
import ImageSelector from '@src/components/ImageSelector';
import Loader from '@src/components/Loader/Loader';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import type ExpandedCertificate from '@src/modules/Certificates/ExpandedCertificate';

import styles from './styles.module.css';

interface Props {
  certificate?: ExpandedCertificate | null;
  onSuccess?: (certificate: ExpandedCertificate) => void;
}

const AddCertificate: FC<Props> = ({certificate = null, onSuccess}) => {
  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const fields = useRef({
    fileId: createHtmlInputField('fileId', {
      defaultValue: certificate?.file_id,
      validationRule: {
        type: 'string',
        messages: {
          required: t('Certificate.validation.photo_is_required'),
        },
      },
    }),
    title: createHtmlInputField('title', {
      defaultValue: certificate?.title,
      validationRule: {
        type: 'string',
        min: 5,
        max: 255,
        messages: {
          stringMin: t('Certificate.validation.title_min_length', {
            minLength: 5,
          }),
          stringMax: t('Certificate.validation.title_max_length', {
            maxLength: 255,
          }),
        },
      },
    }),
    school: createHtmlInputField('school', {
      defaultValue: certificate?.school,
      validationRule: {
        type: 'string',
        min: 5,
        max: 255,
        messages: {
          stringMin: t('Certificate.validation.school_min_length', {
            minLength: 5,
          }),
          stringMax: t('Certificate.validation.school_max_length', {
            maxLength: 255,
          }),
        },
      },
    }),
    isPublic: createField<boolean>('isPublic', {
      defaultValue: certificate === null ? false : certificate!.is_public,
    }),
  }).current;
  const [imageFile, setImageFile] = useState(certificate?.file);
  const [isDisable, setIsDisable] = useState(false);

  const {
    stores: {certificateStore},
  } = useContext(AppContext);

  const onFileUploaded = useCallback(
    async (file: IFile) => {
      setImageFile(file);
      fields.fileId.setValue(file._id);
    },
    [fields.fileId],
  );

  const onIsPublicChange: ChangeEventHandler<HTMLInputElement> = e => {
    fields.isPublic.setValue(e.currentTarget.checked);
  };

  const save = useCallback(async () => {
    if (validateFields(fields)) {
      try {
        setIsDisable(true);

        showOverlay();

        const data = {
          file_id: fields.fileId.value,
          title: fields.title.value,
          school: fields.school.value,
          is_public: fields.isPublic.value,
        };

        if (onSuccess) {
          onSuccess({
            ...data,
            _id: certificate ? certificate._id : uuidv4().replace(/-/g, ''),
            file: imageFile,
          } as ExpandedCertificate);
        } else {
          if (certificate !== null) {
            await certificateStore.update(certificate, data);
          } else {
            await certificateStore.create(data);
          }
        }

        hideOverlay();
      } catch (error) {
        hideOverlay();

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [{label: t(['shared.button.ok'])}],
        });
      }
    }
  }, [
    certificate,
    certificateStore,
    fields,
    hideOverlay,
    imageFile,
    onSuccess,
    showOverlay,
  ]);

  return (
    <div className={`AddCertificate ${styles.AddCertificate}`}>
      <h4>{labelCertificatePhoto()}:</h4>
      <ImageSelector
        isDisabled={!!certificate}
        file={imageFile}
        onUpload={onFileUploaded}
        className={styles.CoachLogo}
      />
      <Observer>{() => <ErrorMessage value={fields.fileId.error} />}</Observer>
      <br />
      <h4>{`${labelCertificateTitle()}:`}</h4>
      <Observer>
        {() => (
          <TextField
            ref={fields.title.ref}
            value={fields.title.value}
            onChange={fields.title.onChange}
            error={fields.title.error}
            placeholder="Enter title here..."
          />
        )}
      </Observer>
      <br />
      <h4>{`${labelCertificateSchool()}:`}</h4>
      <Observer>
        {() => (
          <TextField
            ref={fields.school.ref}
            value={fields.school.value}
            onChange={fields.school.onChange}
            error={fields.school.error}
            placeholder="Enter school name here..."
          />
        )}
      </Observer>
      <br />
      <div className={styles.switchContainer}>
        <h4>{labelCertificatePublic()}</h4>
        <div className={`customGeneratorCheckBox ${styles.mt3}`}>
          <Observer>
            {() => (
              <input
                type="checkbox"
                id="isPublic"
                name="isPublic"
                className="switch"
                checked={fields.isPublic.value}
                defaultChecked={fields.isPublic.value}
                onChange={onIsPublicChange}
              />
            )}
          </Observer>
        </div>
      </div>
      <br />
      <div className={styles.actionButtonsContainer}>
        <Button type="button" disabled={isDisable} onClick={save}>
          {t('shared.button.save')}
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default memo(AddCertificate);
