import type {FC} from 'react';
import React, {memo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {Logo} from '@yourcoach/shared/uikit/Logo';

import {t} from '@src/i18n';

import styles from './styles.module.css';

const I18N_SCOPE = 'TopMenuPublic';

interface Props {}

const TopMenuPublic: FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    history.push({
      pathname: '/login',
      state: {
        from: location,
      },
    });
  };

  return (
    <div className={`TopMenuPublic ${styles.TopMenuPublic}`}>
      <div className={styles.logoContainer}>
        <Logo variant="text" size={40} />
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={handleOnClick}>
          {t([I18N_SCOPE, 'login'])}
        </div>
      </div>
    </div>
  );
};

export default memo(TopMenuPublic);
