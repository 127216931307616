import type {FC} from 'react';
import React, {memo} from 'react';

import PracticeState from '@src/modules/PracticePublic/context/PracticeState';
import PracticePublic from '@src/modules/PracticePublic/PracticePublic';

interface Props {}

const PracticePublicPage: FC<Props> = () => {
  return (
    <div className="PracticePublicPage">
      <PracticeState>
        <PracticePublic />
      </PracticeState>
    </div>
  );
};

export default memo(PracticePublicPage);
