import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, runInAction} from 'mobx';

import type {SubgoalColor, SubgoalIcon} from '@yourcoach/shared/api/goal';
import {
  getSubgoalColor,
  getSubgoalIcon,
  SUBGOAL_ICONS,
} from '@yourcoach/shared/api/goal';
import {themes} from '@yourcoach/shared/styles/theme';

import {CustomButton} from '@src/components/CustomForm';
import {t} from '@src/i18n';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'SelectGoalIcon';

interface ILocalStore {
  icons: string[];
  icon: SubgoalIcon;
  color: SubgoalColor;
  _onIconPress(icon: SubgoalIcon): void;
}

interface Props {
  icon?: SubgoalIcon;
  color?: SubgoalColor;
  onSelect?: (icon: SubgoalIcon) => void;
}

const SelectGoalIcon: FC<Props> = ({icon, color, onSelect}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        icons: [],
        icon: null,
        color: null,
        _onIconPress(iconVal: SubgoalIcon) {
          this.icon = iconVal;
        },
      },
      {
        icons: observable.shallow,
        icon: observable,
        color: observable,
        _onIconPress: action,
      },
    ),
  ).current;

  useEffect(() => {
    if (icon) {
      localStore.icon = icon;
    }

    if (color) {
      localStore.color = color;
    }

    _prepareIcons();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _prepareIcons = () => {
    let data: string[] = SUBGOAL_ICONS.slice();

    runInAction(() => {
      localStore.icons = data;
    });
  };

  const _onSetButtonPress = () => {
    if (onSelect) {
      onSelect(localStore.icon);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`SelectGoalIcon ${styles.SelectGoalIcon}`}>
          <div className={styles.container}>
            {localStore.icons.map(item => {
              const isSelected = item === localStore.icon;

              const Icon = getSubgoalIcon(item);
              const selectColor = getSubgoalColor(
                localStore.color,
                themes.light,
              );

              return (
                <div
                  key={item}
                  onClick={() => localStore._onIconPress(item as SubgoalIcon)}
                  className={`${styles.item}`}
                  style={
                    isSelected
                      ? {
                          background: selectColor,
                        }
                      : {}
                  }
                >
                  <Icon
                    fill={
                      isSelected
                        ? themes.light.color.icon2
                        : themes.light.color.icon3
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.createButContainer}>
            <CustomButton
              type="button"
              classButton={`blueButt ${styles.createBut}`}
              onClick={_onSetButtonPress}
            >
              <span>{t([I18N_SCOPE, 'set_button'])}</span>
            </CustomButton>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SelectGoalIcon);
