import styled, {css} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {Text} from '@yourcoach/shared/uikit/Text';

import type {StyledProps} from './types';

export const Container = styled(Clickable)<StyledProps>`
  position: relative;
  overflow: hidden;
  margin: 0 20px;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    transition: width 0.4s ease-in-out;
    background-color: ${getColor('global1')};
  }

  &:hover::after,
  &:focus::after {
    width: 100%;
  }

  ${p =>
    p.active &&
    css`
      &:after {
        width: 100%;
      }
    `}
`;

const SText = styled(Text)<StyledProps>`
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.4675px;
  color: ${getColor('text3')};
  padding: 16px 0;
  display: block;

  ${p =>
    p.active &&
    css`
      color: ${getColor('text1')};
    `}
`;

export {SText as Text};
