import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {action, observable, runInAction} from 'mobx';

import {ISOStringToDatetimeObj} from '@yourcoach/shared/api';
import {ProgramTypeEnum} from '@yourcoach/shared/api/program';
import AddUserIcon from '@yourcoach/shared/assets/icons/primary/Add User.svg';
import {logger} from '@yourcoach/shared/utils/logger';

import {
  labelErrorOccurred,
  labelShare,
  labelShareThisWith,
} from '@src/common/i18n/i18nCommon';
import {labelCourseInvitesTabInviteOnlyButton} from '@src/common/i18n/i18nCourse';
import {labelInviteTitle} from '@src/common/i18n/i18nInvites';
import {
  labelClosedToNewRequests,
  labelCreateGroup,
  labelDeleteProgram,
  labelDeleteTheProgram,
  labelEditProgram,
  labelHideProgram,
  labelPublishProgram,
  labelSetupProgram,
  labelYouCanCloseYourProgramFromSearch,
  labelYouCanHideYourProgramFromSearch,
} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import {useAppRedirect} from '@src/common/useAppRedirect';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {IconFlag, IconNewSettings, IconTrash} from '@src/components/icons';
import IconIconShared from '@src/components/icons/IconShared/IconShared';
import SharedLink from '@src/components/SharedLink/SharedLink';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import {t} from '@src/i18n';
import Invites from '@src/modules/Invites/Invites';
import {PATH_TYPE} from '@src/routes/utils';
import {getPagePath} from '@src/utils';

import SeeProgramContext from '../context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '../context/useSeeProgramLocalStore';
import CreateCourse from '../CreateCourse/CreateCourse';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'Programs';

interface ILocalStore {
  _setIsClosedTimeout: number;
  showPopupShared: boolean;
  _setIsHiddenTimeout: number;
  isClosed: boolean | undefined;
  isHidden: boolean | undefined;
  showModalCreateCourse: boolean;
  setShowModalCreateCourse(showModalCreateCourse: boolean): void;
  setShowModalGetInvite(showModalGetInvite: boolean): void;
  setCreateCourse(createCourse: boolean): void;
  showModalGetInvite: boolean;
  createCourse: boolean;
  isPublishProgram: boolean;
  setIsPublishProgram(isPublishProgram: boolean): void;
}

interface Props {}

const CoachRightMenu: FC<Props> = () => {
  const {
    stores: {programStore, currentUserStore, editionStore},
  } = useContext(AppContext);
  const history = useHistory();
  const redirect = useAppRedirect('/');

  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        isPublishProgram: false,
        showPopupShared: false,
        setIsPublishProgram(isPublishProgram: boolean) {
          this.isPublishProgram = isPublishProgram;
        },
        createCourse: false,
        setCreateCourse(createCourse: boolean) {
          this.createCourse = createCourse;
        },
        _setIsClosedTimeout: 0,
        _setIsHiddenTimeout: 0,
        showModalCreateCourse: false,
        setShowModalCreateCourse(showModalCreateCourse: boolean) {
          this.showModalCreateCourse = showModalCreateCourse;
        },
        showModalGetInvite: false,
        setShowModalGetInvite(showModalGetInvite: boolean) {
          this.showModalGetInvite = showModalGetInvite;
        },
        isClosed: seeProgramLocalStore?.program?.is_closed,
        isHidden: seeProgramLocalStore?.program?.is_hidden,
      },
      {
        createCourse: observable,
        _setIsClosedTimeout: observable,
        _setIsHiddenTimeout: observable,
        showModalGetInvite: observable,
        showModalCreateCourse: observable,
        setShowModalCreateCourse: action,
        setShowModalGetInvite: action,
        setCreateCourse: action,
        isClosed: observable,
        isHidden: observable,
        showPopupShared: observable,
        isPublishProgram: observable,
        setIsPublishProgram: action,
      },
    ),
  ).current;

  const handleOnPublishProgram = () => {
    _publishProgram();
  };

  const handleCloseModalGetDate = () => {
    localStore.setShowModalCreateCourse(false);
  };

  const _publishProgram = async () => {
    const program = seeProgramLocalStore!.program;
    const user = currentUserStore.user;
    const lastEdition = seeProgramLocalStore!.lastEdition;

    try {
      await programStore.sign(program!, {
        sign: user!.name,
      });

      runInAction(() => {
        program!.coaches[user!._id].sign = user!.name;

        program!.status = 'active';

        program!.editions.forEach(item => {
          item!.status = 'active';
          item!.coaches[user!._id].sign = user!.name;
          item!.coaches[user!._id].signed = ISOStringToDatetimeObj(
            dayjs().toISOString(),
          );
        });

        programStore.localUpdate(program!, {});
      });

      if (lastEdition!.group_size > 1) {
        localStore.setIsPublishProgram(true);
        localStore.setCreateCourse(false);
        localStore.setShowModalCreateCourse(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(localStore._setIsClosedTimeout);
      clearTimeout(localStore._setIsHiddenTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnProgramInvite = () => {
    localStore.setShowModalGetInvite(true);
  };
  const handleCloseModalGetInvite = () => {
    localStore.setShowModalGetInvite(false);
  };
  const _onCreateCourseButtonPress = () => {
    //update ProgramCoursesTab.tsx 389
    localStore.setCreateCourse(true);
    localStore.setShowModalCreateCourse(true);
  };
  const handleOnProgramSettings = () => {
    localAppStore?.setReturnPath(getPagePath());
    history.push(`/program/${seeProgramLocalStore?.program?._id}`);
  };
  const handleClosedToNewRequests = () => {
    const program = seeProgramLocalStore!.program;
    const isClosed = !localStore.isClosed;

    clearTimeout(localStore._setIsClosedTimeout);

    if (localStore.isClosed !== isClosed) {
      localStore._setIsClosedTimeout = +setTimeout(async () => {
        try {
          await programStore.update(program!, {
            is_closed: isClosed,
          });

          runInAction(() => {
            program!.is_closed = isClosed;
          });

          localStore.isClosed = isClosed;
        } catch (error) {
          setError(error.message);
        }
      }, 1000);
    }
  };
  const handleHideProgram = () => {
    const program = seeProgramLocalStore!.program;
    const isHidden = !localStore.isHidden;

    clearTimeout(localStore._setIsHiddenTimeout);

    if (localStore.isHidden !== isHidden) {
      localStore._setIsHiddenTimeout = +setTimeout(async () => {
        try {
          programStore.update(program!, {
            is_hidden: isHidden,
          });

          runInAction(() => {
            program!.is_hidden = isHidden;
          });

          localStore.isHidden = isHidden;
        } catch (error) {
          setError(error.message);
        }
      }, 1000);
    }
  };
  const onSetupProgramButtonPress = () => {};

  const handleOnDeleteProgram = async () => {
    const program = seeProgramLocalStore!.program;

    getCustomConfirmAlert({
      title: labelDeleteTheProgram(),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await programStore.delete(program!);

              redirect(
                `/${PATH_TYPE.coaches}/${
                  currentUserStore.user!.slug || currentUserStore.user!._id
                }`,
              );
            } catch (error) {
              let errorMessage = '';

              switch (error.message) {
                case 'Cannot delete program with clients':
                  errorMessage = t([
                    I18N_SCOPE,
                    'cannot_delete_program_with_clients',
                  ]);
                  break;
                default:
                  errorMessage = error.message;
                  break;
              }

              getCustomConfirmAlert({
                title: labelErrorOccurred(),
                message: errorMessage,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {},
                  },
                ],
              });

              setError(error);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleOnCreate = () => {
    localStore.setShowModalCreateCourse(false);

    const lastEdition = seeProgramLocalStore!.lastEdition;

    if (lastEdition!.group_size > 1 && localStore.isPublishProgram) {
      const INVITE_POPUP_I18N_SCOPE = 'invite_popup';

      getCustomConfirmAlert({
        title: t([I18N_SCOPE, INVITE_POPUP_I18N_SCOPE, 'header']),
        buttons: [
          {
            label: t([I18N_SCOPE, INVITE_POPUP_I18N_SCOPE, 'invite_button']),
            onClick: () => {
              handleOnProgramInvite();
            },
            type: 'confirm',
          },
          {
            label: t([I18N_SCOPE, INVITE_POPUP_I18N_SCOPE, 'cancel_button']),
            onClick: () => {},
          },
        ],
      });

      localStore.setIsPublishProgram(false);
    }
  };

  const handleClosePopupShared = () => {
    runInAction(() => {
      localStore.showPopupShared = false;
    });
  };

  const handleOnClickShared = () => {
    runInAction(() => {
      localStore.showPopupShared = true;
    });
  };

  const onCoachResignProgramButtonClick = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'No',
          onClick: () => {},
        },
        {
          label: 'Yes',
          onClick: () => {
            coachResignProgram();
          },
          type: 'confirm',
        },
      ],
    });
  };

  const coachResignProgram = async () => {
    try {
      await programStore.resign(seeProgramLocalStore!.program!, {
        _id: currentUserStore.user!._id,
      });

      runInAction(() => {
        seeProgramLocalStore!.program!.coaches[
          currentUserStore.user!._id
        ].resigned = ISOStringToDatetimeObj(dayjs().toISOString());

        programStore.localUpdate(seeProgramLocalStore!.program!, {});
      });

      history.goBack();
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onCoachSignProgramButtonClick = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'No',
          onClick: () => {},
        },
        {
          label: 'Yes',
          onClick: () => {
            coachSignProgram();
          },
          type: 'confirm',
        },
      ],
    });
  };

  const coachSignProgram = async () => {
    try {
      await programStore.sign(seeProgramLocalStore!.program!, {
        // _id: currentUserStore.user!._id, // TODO:? how to check
        sign: currentUserStore.user!.name,
      });

      runInAction(() => {
        seeProgramLocalStore!.program!.coaches[
          currentUserStore.user!._id
        ].signed = ISOStringToDatetimeObj(dayjs().toISOString());

        seeProgramLocalStore!.program!.coaches[
          currentUserStore.user!._id
        ].sign = currentUserStore.user!.name;

        programStore.localUpdate(seeProgramLocalStore!.program!, {});
      });
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const onSignEditionButtonClick = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'No',
          onClick: () => {},
        },
        {
          label: 'Yes',
          onClick: () => {
            signEdition();
          },
          type: 'confirm',
        },
      ],
    });
  };

  const signEdition = async () => {
    try {
      await editionStore.sign(seeProgramLocalStore!.lastEdition!, {
        // _id: currentUserStore.user!._id,  // TODO:? how to check
        sign: currentUserStore.user!.name,
      });

      runInAction(() => {
        const edition =
          seeProgramLocalStore!.program!.editions[
            seeProgramLocalStore!.program!.editions.length - 1
          ];

        edition!.coaches[currentUserStore.user!._id].signed =
          ISOStringToDatetimeObj(dayjs().toISOString());

        edition!.coaches[currentUserStore.user!._id].sign =
          currentUserStore.user!.name;

        programStore.localUpdate(seeProgramLocalStore!.program!, {});
      });
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`CoachRightMenu ${styles.CoachRightMenu}`}>
          {seeProgramLocalStore!.lastEdition ? (
            <>
              <>
                {seeProgramLocalStore!.coaches.length > 1 &&
                !seeProgramLocalStore!.cocoachesAreSigned &&
                seeProgramLocalStore!.isMainCoach ? (
                  <div className={styles.messageContainer}>
                    <p>{t(['Program', 'cocoaching_description'])}</p>
                  </div>
                ) : null}
                {!seeProgramLocalStore!.lastEditionIsSigned ? (
                  <>
                    <div className={styles.messageContainer}>
                      <p>{t(['Program', 'sign_edition_description'])}</p>
                      {seeProgramLocalStore!.isMainCoach ? (
                        <p>
                          {t([
                            'Program',
                            'main_coach_sign_edition_description',
                          ])}
                        </p>
                      ) : null}
                    </div>
                    {!seeProgramLocalStore!.coachIsSignedLastEdition ? (
                      <Button
                        className={styles.publishProgramBut}
                        disabled={
                          seeProgramLocalStore!.isMainCoach &&
                          !seeProgramLocalStore!.cocoachesAreSignedLastEdition
                        }
                        onClick={onSignEditionButtonClick}
                      >
                        <div className={styles.publishProgramButTitle}>
                          {t(['Program', 'sign_edition_button'])}
                        </div>
                        <div className={styles.publishProgramButIco}>
                          <IconFlag />
                        </div>
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </>
              {seeProgramLocalStore!.program!.status === 'draft' ? (
                <>
                  {seeProgramLocalStore!.isMainCoach ? (
                    <Button
                      className={styles.publishProgramBut}
                      onClick={handleOnPublishProgram}
                      disabled={!seeProgramLocalStore!.cocoachesAreSigned}
                    >
                      <div className={styles.publishProgramButTitle}>
                        {labelPublishProgram()}
                      </div>
                      <div className={styles.publishProgramButIco}>
                        <IconFlag />
                      </div>
                    </Button>
                  ) : !seeProgramLocalStore!.program!.coaches[
                      currentUserStore.user?._id || ''
                    ]?.signed &&
                    !seeProgramLocalStore!.program!.coaches[
                      currentUserStore.user?._id || ''
                    ]?.resigned ? (
                    <>
                      <Button
                        className={classNames(styles.publishProgramBut)}
                        onClick={onCoachSignProgramButtonClick}
                      >
                        <div className={styles.publishProgramButTitle}>
                          {t(['Program', 'coach_sign_program_button'])}
                        </div>
                        <div className={styles.publishProgramButIco}>
                          <IconFlag />
                        </div>
                      </Button>
                      <Button
                        className={classNames(
                          styles.publishProgramBut,
                          styles.declineButton,
                        )}
                        onClick={onCoachResignProgramButtonClick}
                      >
                        <div className={styles.publishProgramButTitle}>
                          {t(['Program', 'coach_resign_program_button'])}
                        </div>
                        <div className={styles.publishProgramButIco}>
                          <IconFlag />
                        </div>
                      </Button>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {seeProgramLocalStore!.userIsProgramCoach ? (
                    <>
                      {seeProgramLocalStore!.isMainCoach &&
                      seeProgramLocalStore!.programType!.id ===
                        ProgramTypeEnum.GROUP.id ? (
                        <div
                          className={styles.publishProgramBut}
                          onClick={_onCreateCourseButtonPress}
                        >
                          <div className={styles.publishProgramButTitle}>
                            {labelCreateGroup()}
                          </div>
                          <div className={styles.createSquadIcon}>+</div>
                        </div>
                      ) : null}

                      {!seeProgramLocalStore!.isOfferedProgram ? (
                        <>
                          <div className={styles.buttonContainer}>
                            <div
                              className={styles.publishProgramBut}
                              onClick={handleOnProgramInvite}
                            >
                              <div className={styles.publishProgramButTitle}>
                                {labelCourseInvitesTabInviteOnlyButton()}
                              </div>
                              <div className={styles.editProgramButIco}>
                                <AddUserIcon />
                              </div>
                            </div>
                            {!seeProgramLocalStore?.program?.is_closed ? (
                              <div
                                className={`${styles.publishProgramBut} ${styles.sharedButton}`}
                                onClick={handleOnClickShared}
                              >
                                <div
                                  className={styles.publishProgramButTitleBlack}
                                >
                                  {labelShare()}
                                </div>
                                <div className={styles.editProgramButIcoBlack}>
                                  <IconIconShared />
                                </div>
                              </div>
                            ) : null}
                            <ModalAnimateWin
                              showModal={localStore.showPopupShared}
                              closeModalHandler={handleClosePopupShared}
                              className="greyHeaderContainer littleContainer w610 List"
                              isBody
                              classNameBody={`whiteBody maxContent noPadding ${styles.inviteContainer}`}
                              header={labelShareThisWith()}
                              classNameHeader="greyHeader w610"
                              classNameCloseBut="greyHeaderBut"
                            >
                              <SharedLink />
                            </ModalAnimateWin>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <div
              className={styles.editProgramBut}
              onClick={onSetupProgramButtonPress}
            >
              <div className={styles.editProgramButTitle}>
                {labelSetupProgram()}
              </div>
            </div>
          )}

          <div className={`${styles.mb30} ${styles.mt12}`}>
            {seeProgramLocalStore!.isMainCoach ||
            (!seeProgramLocalStore!.isMainCoach &&
              seeProgramLocalStore!.coachIsSigned) ? (
              <div
                className={styles.editProgramBut}
                onClick={handleOnProgramSettings}
              >
                <div className={styles.editProgramButTitle}>
                  {labelEditProgram()}
                </div>
                <div className={styles.editProgramButIco}>
                  <IconNewSettings />
                </div>
              </div>
            ) : null}
            {seeProgramLocalStore!.isMainCoach &&
            !Object.values(
              seeProgramLocalStore!.program!.counters.memberships,
            ).some(count => count > 0) ? (
              <div
                className={`${styles.editProgramBut} ${styles.mt12}`}
                onClick={handleOnDeleteProgram}
              >
                <div className={styles.editProgramButTitle}>
                  {labelDeleteProgram()}
                </div>
                <div className={styles.editProgramButIco}>
                  <IconTrash />
                </div>
              </div>
            ) : null}
          </div>

          {seeProgramLocalStore!.isMainCoach &&
          !seeProgramLocalStore!.isOfferedProgram ? (
            <>
              <div className={styles.switchBox}>
                <div className={styles.switchBoxText}>
                  <div className={styles.switchBoxTitle}>
                    {labelClosedToNewRequests()}
                  </div>
                  <div className={styles.switchBoxDescription}>
                    {labelYouCanCloseYourProgramFromSearch()}
                  </div>
                </div>
                <div className={`customGeneratorCheckBox ${styles.mt3}`}>
                  <input
                    type="checkbox"
                    className="switch"
                    defaultChecked={localStore.isClosed}
                    onClick={handleClosedToNewRequests}
                  />
                </div>
              </div>
              <hr className={styles.hr} />
              <div className={styles.switchBox}>
                <div className={styles.switchBoxText}>
                  <div className={styles.switchBoxTitle}>
                    {labelHideProgram()}
                  </div>
                  <div className={styles.switchBoxDescription}>
                    {labelYouCanHideYourProgramFromSearch()}
                  </div>
                </div>
                <div className={`customGeneratorCheckBox ${styles.mt3}`}>
                  <input
                    type="checkbox"
                    className="switch"
                    defaultChecked={localStore.isHidden}
                    onClick={handleHideProgram}
                  />
                </div>
              </div>
            </>
          ) : null}
          <ModalAnimateWin
            showModal={localStore.showModalCreateCourse}
            closeModalHandler={handleCloseModalGetDate}
            className="greyHeaderContainer littleContainer w610 List"
            isBody
            classNameBody={`whiteBody maxContent noPadding ${styles.inviteContainer}`}
            header={t([I18N_SCOPE, 'create_course_button'])}
            classNameHeader="greyHeader w610"
            classNameCloseBut="greyHeaderBut"
          >
            <CreateCourse
              program={seeProgramLocalStore?.program!}
              onCreate={handleOnCreate}
            />
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.showModalGetInvite}
            closeModalHandler={handleCloseModalGetInvite}
            className="greyHeaderContainer maxHeight80vh"
            isBody
            classNameBody={`whiteBody noPadding ${styles.inviteContainer}`}
            header={labelInviteTitle()}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <Invites target={seeProgramLocalStore?.program!} />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(CoachRightMenu);
