import type {FC} from 'react';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Link, Route, useHistory, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, observable, toJS} from 'mobx';

import {Clickable} from '@yourcoach/shared/uikit/Clickable';

import type {IBreadcrumbsLocalStore} from '@src/common/localStorageApp';
import {getPathBreadcrumbs} from '@src/common/localStorageApp';
import {setError} from '@src/common/setError';
import localAppStore from '@src/context/appStore';
import {getPathTitle, PATH_TYPE} from '@src/routes/utils';

import ArrowSeparator from './ArrowSeparator';
import Separator from './Separator';
import styles from './styles.module.css';

interface Props {
  noFirst?: boolean;
  className?: string;
  classNameLink?: string;
  classNameSeparator?: string;
  classNameTales?: string;
}

interface ILocalStore {
  breadcrumbs: {[key: string]: IBreadcrumbsLocalStore};
  setBreadcrumbs(breadcrumbs: {[key: string]: IBreadcrumbsLocalStore}): void;
  tales: string;
  setTales(tales: string): void;
}

const Breadcrumbs: FC<Props> = ({
  noFirst = false,
  className = '',
  classNameLink = '',
  classNameSeparator = '',
  classNameTales = '',
}) => {
  const location = useLocation();
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          breadcrumbs: {},
          setBreadcrumbs(breadcrumbs: {[key: string]: IBreadcrumbsLocalStore}) {
            this.breadcrumbs = breadcrumbs;
          },
          tales: '',
          setTales(tales: string) {
            this.tales = tales;
          },
        },
        {
          breadcrumbs: observable,
          setBreadcrumbs: action,
          tales: observable,
          setTales: action,
        },
      ),
    [],
  );
  const breadcrumbsAppStore = localAppStore!.breadcrumbs;

  const [metaTitle, setMetaTitle] = useState('');

  const history = useHistory();

  const crumb = (part, partIndex, parts) => {
    if (part === PATH_TYPE.coaches) {
      part = PATH_TYPE.yourSpace;
    }

    let path = '';

    if (localAppStore?.isLinksBreadcrumbsHaveProp(part)) {
      path = localAppStore?.linksBreadcrumbs[part];
    } else {
      path = ['', ...parts.slice(0, partIndex + 1)].join('/');

      if (part === PATH_TYPE.yourSpace) {
        path = `/${PATH_TYPE.yourSpace}`;
      }
    }

    if (part === 'programs') {
      return null;
    }

    if (noFirst && partIndex === 0) {
      return '';
    } else {
      return (
        <div key={partIndex} className={styles.LinksContainer}>
          <Observer>
            {() => {
              let partLabel = '';

              const toJsBreadcrumbs = toJS(localStore.breadcrumbs);

              for (const labelPath in toJsBreadcrumbs) {
                if (labelPath !== undefined) {
                  if (toJsBreadcrumbs[labelPath].pathname === path) {
                    partLabel = localStore.breadcrumbs[labelPath].label;
                  }
                }
              }

              return (
                <>
                  <div className={`${styles.Links} ${classNameLink}`}>
                    <Link
                      key={path}
                      to={
                        localStore.breadcrumbs[part]
                          ? `${localStore.breadcrumbs[part].pathname}${localStore.breadcrumbs[part].search}`
                          : path
                      }
                    >
                      {partLabel !== ''
                        ? getPathTitle(partLabel)
                        : getPathTitle(part)}
                    </Link>
                  </div>
                  <Separator className={classNameSeparator} />
                </>
              );
            }}
          </Observer>
        </div>
      );
    }
  };

  useEffect(() => {
    const parts = location.pathname.split('/');
    const place = getPathTitle(parts[parts.length - 1]) as string;

    setMetaTitle(place);

    const fetchBreadcrumbs = () => {
      const breadcrumbsProm = getPathBreadcrumbs() || [];

      return breadcrumbsProm
        .then((breadcrumbsLocalSt: IBreadcrumbsLocalStore[]) => {
          const pathname = location.pathname;
          const breadcrumbs = breadcrumbsLocalSt || [];
          const breadOb: {[key: string]: IBreadcrumbsLocalStore} = {};

          breadcrumbs.forEach((element: IBreadcrumbsLocalStore) => {
            breadOb[element.label] = element;

            if (element.pathname === pathname) {
              localStore.setTales(element.label);

              setMetaTitle(element.label);
            }
          });

          localStore.setBreadcrumbs(breadOb);
        })
        .catch(error => {
          setError(error);
        });
    };

    fetchBreadcrumbs();

    const timeoutId = setTimeout(() => {
      fetchBreadcrumbs();
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getBreadcrumbsAppStore = () => {
    const place = breadcrumbsAppStore[breadcrumbsAppStore.length - 1];
    const parts = breadcrumbsAppStore.slice(1, breadcrumbsAppStore.length - 1);

    return (
      <>
        {parts.map(item => {
          if (item.name === 'programs') {
            return null;
          }

          return (
            <div key={item.path} className={`${styles.Links} ${classNameLink}`}>
              <Link to={item.path}>{getPathTitle(item.name)}</Link>
              <Separator className={classNameSeparator} />
            </div>
          );
        })}
        <Observer>
          {() => (
            <div className={`${styles.Tales} ${classNameTales}`}>
              {localStore.tales !== ''
                ? getPathTitle(localStore.tales)
                : getPathTitle(place.name)}
            </div>
          )}
        </Observer>
      </>
    );
  };

  return (
    <Route
      path="*"
      render={props => {
        let parts = props.location.pathname.split('/');

        const place = getPathTitle(parts[parts.length - 1]) as string;

        parts = parts.slice(1, parts.length - 1);

        return (
          <div className={`Breadcrumbs ${styles.Breadcrumbs} ${className}`}>
            <Helmet title={metaTitle} />
            {parts.length ? (
              <>
                {!noFirst ? (
                  <Clickable onClick={history.goBack}>
                    <ArrowSeparator />
                  </Clickable>
                ) : (
                  ''
                )}
                {parts.map(crumb)}
                {breadcrumbsAppStore.length ? (
                  getBreadcrumbsAppStore()
                ) : (
                  <Observer>
                    {() => (
                      <div className={`${styles.Tales} ${classNameTales}`}>
                        {localStore.tales !== '' ? localStore.tales : place}
                      </div>
                    )}
                  </Observer>
                )}
              </>
            ) : (
              <Observer>
                {() => (
                  <div className={`${styles.Tales} ${classNameTales}`}>
                    {localStore.tales !== '' ? localStore.tales : place}
                  </div>
                )}
              </Observer>
            )}
          </div>
        );
      }}
    />
  );
};

export default memo(Breadcrumbs);
