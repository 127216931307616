import type {HTMLProps} from 'react';
import React from 'react';

import classNames from 'classnames';

import styles from './RadioButton.module.css';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
  checked?: boolean;
  label?: string | React.ReactElement;
  className?: string;
}

const RadioButton: React.FC<Props> = ({
  checked,
  label,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'RadioButton',
        styles.Component,
        {[styles.checked]: checked},
        className,
      )}
      {...props}
    >
      <div className={classNames(styles.mark, 'RadioButton-mark')} />
      {label ? <div className={styles.label}>{label}</div> : null}
    </div>
  );
};

export default React.memo(RadioButton);
