import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import {Link} from 'react-router-dom';

import type {ApiRpcRequestParams} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {Questionnaire} from '@yourcoach/shared/api/questionnaire';

import {
  labelDelete,
  labelDuplicate,
  labelEdit,
  labelSend,
} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {confirm} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import DropdownMenu from '@src/components/DropdownMenu/DropdownMenu';
import {PATH_TYPE} from '@src/routes/utils';

import styles from './../styles.module.css';

interface Props {
  id: string;
  update: () => void;
  openSendModal: () => void;
}

const MoreBut: FC<Props> = ({id, update, openSendModal}) => {
  const openDropDown = useRef(() => {});

  const handleGetOpenFunc = (fn: () => void) => {
    openDropDown.current = fn;
  };

  const handleClickMore = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    openDropDown.current();
  };

  const handleClickDuplicate = async (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.stopPropagation();

    try {
      const {questionnaire} = await apiRequest({
        method: 'coach.questionnaires.own.read',
        params: {
          _id: id,
        },
      });

      // @ts-ignore
      delete (questionnaire as Questionnaire)._id;
      // @ts-ignore
      delete (questionnaire as Questionnaire).user_id;
      // @ts-ignore
      delete (questionnaire as Questionnaire).updated;
      // @ts-ignore
      delete (questionnaire as Questionnaire).created;

      const params: ApiRpcRequestParams = {
        ...questionnaire,
        title: `[Clone] ${questionnaire.title}`,
      };

      await apiRequest({
        method: 'coach.questionnaires.own.create',
        params,
      });

      update();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  const handleClickDelete = async (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    if (!(await confirm())) {
      return;
    }

    event.stopPropagation();

    try {
      await apiRequest({
        method: 'coach.questionnaires.own.delete',
        params: {
          _id: id,
        },
      });

      update();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="MoreBut">
      <div className={styles.more} onClick={handleClickMore}>
        ⋮
      </div>
      <DropdownMenu
        getOpenFunc={handleGetOpenFunc}
        className={styles.dropdownMenu}
      >
        <a onClick={handleClickDuplicate}>{labelDuplicate()}</a>
        <Link
          onClick={handleClickEdit}
          to={`/${PATH_TYPE.questionnaire}/edit?id=${id}`}
          className={styles.link}
        >
          {labelEdit()}
        </Link>
        <a onClick={openSendModal}>{labelSend()}</a>
        <a onClick={handleClickDelete}>{labelDelete()}</a>
      </DropdownMenu>
    </div>
  );
};

export default memo(MoreBut);
