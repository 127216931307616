import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {CollectionStore, Expand} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import {getGoalAttitudeIcon, getSubgoalColor} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';
import CommentIcon from '@yourcoach/shared/assets/icons/comment.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {IconCheck} from '@src/components/icons';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'ClientGoal';

const goalExpand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: ['edition_id'],
      },
    ],
    ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
  ],
};

export type ExpandedClient = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) & {
  avatar?: IFile | null;
};

export type GoalT = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: Course & {
    edition?: Edition | null;
  };
  client?: ExpandedClient;
};

type Section = {
  id: 'goal' | 'subgoal';
  data: Subgoal[];
};

interface ILocalStore {
  goal: GoalT | null;
  subgoal: Subgoal | null;
  client: ExpandedClient | null;
  goalIndex: number | undefined;
  listProps: {
    sections: Section[];
  };
  goalsStore: CollectionStore<GoalT> | null;
  subgoalsStore: CollectionStore<Subgoal> | null;
  _fetchGoal(): Promise<void>;
  _fetchGoals(silent?: boolean): void;
  bottom: number | null | undefined;
}

interface Props {
  goalId: string;
  goal?: GoalT;
  subgoalId?: string;
  clientId: string;
  client?: ExpandedClient;
  offset?: number;
}

const ClientGoal: FC<Props> = ({
  goalId,
  goal,
  subgoalId,
  clientId,
  client,
  offset = 0,
}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const history = useHistory();
  const thisRef = useRef<HTMLDivElement | null>(null);
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          goal: null,
          bottom: null,
          subgoal: null,
          client: null,
          goalsStore: null,
          subgoalsStore: null,
          get goalIndex() {
            const {route} = this.props;

            const {goalIdValue} = route.params || {};

            let index: number | undefined;

            if (this.goal && this.goalsStore.isLoaded) {
              index = this.goalsStore.items.findIndex(
                item => item._id === goalIdValue,
              );

              if (index === -1) {
                index === undefined;
              }
            }

            return index;
          },
          get listProps() {
            const sections: Section[] = [];

            if (this.goal) {
              sections.push({
                id: 'goal',
                data: [],
              });
            }

            if (this.subgoal) {
              sections.push({
                id: 'subgoal',
                data: [this.subgoal],
              });
            }

            return {sections};
          },
          async _fetchGoal() {
            try {
              const goalVal = (await goalStore.coach.bound.fetch({
                _id: goalId,
                expand: goalExpand,
              })) as GoalT;

              runInAction(() => {
                this.goal = goalVal;

                this.client = goalVal.client!;
              });

              runInAction(() => {
                localStore.bottom =
                  offset &&
                  thisRef.current?.getBoundingClientRect().height &&
                  offset >= thisRef.current?.getBoundingClientRect().height
                    ? offset - thisRef.current?.getBoundingClientRect().height
                    : null;
              });
            } catch (error) {
              if (apiRequest.isCanceledError(error)) {
                return;
              }

              getCustomConfirmAlert({
                title: t('shared.message.error_fix'),
                message: error.message,
                buttons: [
                  {
                    label: t('shared.button.ok'),
                    onClick: () => {},
                  },
                ],
              });
            }
          },
          _fetchGoals(silent = false) {
            if (!this.goal) {
              return;
            }

            if (this.goalsStore) {
              this.goalsStore.fetch(
                {
                  query: [
                    ['course_id', '==', this.goal.course_id],
                    ['client_id', '==', clientId],
                  ],
                },
                {silent},
              );
            }
          },
        },
        {
          goal: observable,
          subgoal: observable,
          client: observable,
          goalsStore: observable,
          subgoalsStore: observable,
          goalIndex: computed,
          listProps: computed,
          _fetchGoal: action,
          _fetchGoals: action,
          bottom: observable,
        },
      ),
    [clientId, goalId, goalStore.coach.bound, offset],
  );

  useEffect(() => {
    const disposeGoalStoreUpdating = reaction(
      () => goalStore.updating,
      updating => {
        if (
          localStore.goal &&
          updating.success &&
          updating.entity &&
          updating.entity._id === goalId
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore.goal![key] = updating.entity![key];
          });

          localStore._fetchGoal();
        }
      },
    );

    runInAction(() => {
      localStore.goalsStore = createCollectionStore({
        method: 'coach.goals.bound.list',
        params: {
          sort: [['sort_order', 1]],
          expand: goalExpand,
        },
      });

      localStore.subgoalsStore = createCollectionStore({
        method: 'coach.subgoals.bound.list',
      });
    });

    return () => {
      disposeGoalStoreUpdating();

      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }

      if (localStore.subgoalsStore) {
        localStore.subgoalsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const disposeGoal = reaction(
      () => localStore.goal && localStore.goal._id,
      () => {
        if (localStore.goal && localStore.goal._id) {
          localStore._fetchGoals();

          const subgoalIndex = subgoalId
            ? localStore.goal.subgoal_ids.findIndex(id => id === subgoalId)
            : localStore.goal.subgoal_ids.length
            ? 0
            : localStore.goal.subgoal_ids.length - 1;

          if (subgoalIndex >= 0) {
            localStore.subgoal = localStore.goal.subgoals[subgoalIndex];
          }
        }
      },
    );

    if (client) {
      runInAction(() => {
        localStore.client = client;
      });
    }

    if (goal) {
      runInAction(() => {
        localStore.goal = goal;
      });
    }

    if (!goal || !client) {
      localStore._fetchGoal();
    }

    return () => {
      disposeGoal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, client]);

  const _onClientInfoButtonPress = () => {
    if (localStore.client && localStore.client._id) {
      // TODO: navigate to user profile
    }
  };

  const _onClientChatButtonPress = () => {
    if (
      localStore.goal &&
      localStore.goal.course &&
      localStore.goal.course.edition &&
      localStore.goal.course.edition.group_size === 1
    ) {
      history.push({
        pathname: '/chats',
        search: `?cid=${localStore.goal.course.channel_ids[0]}`,
      });
    } else if (localStore.client && localStore.client._id) {
      history.push({
        pathname: '/chats',
        state: {withUser: {_id: [localStore.client._id]}},
      });
    }
  };

  const RenderHeader = () => {
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.header}>
              <div className={styles.clientRow}>
                <div className={styles.clientButtonInfoContainer}>
                  {localStore.client && localStore.client._id ? (
                    <div
                      onClick={_onClientInfoButtonPress}
                      className={styles.clientButtonInfo}
                    >
                      i
                    </div>
                  ) : null}
                </div>
                <div className={styles.bigUserAvatar}>
                  <OtherUserProfileImg
                    avatar={(localStore.client || {avatar: null}).avatar}
                    title={(localStore.client || {name: '...'}).name}
                    isOverlay={!(localStore.client || {avatar: null}).avatar}
                  />
                </div>
                {/* <div className={styles.clientButtonChatContainer}>
                  {localStore.client && localStore.client._id ? (
                    <div
                      onClick={_onClientChatButtonPress}
                      className={styles.clientButtonChat}
                    >
                      <CommentIcon fill={'#ffffff'} width={22} height={18} />
                    </div>
                  ) : null}
                </div> */}
              </div>
              <div className={styles.clientName}>
                {(localStore.client || {name: '...'}).name}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  const RenderSubgoal = () => {
    const isReached = localStore.subgoal
      ? localStore.subgoal.checkpoints.every(item => !!item.reached)
      : false;

    return (
      <Observer>
        {() => (
          <div className={styles.subgoalContainer}>
            {localStore.subgoal ? (
              <div className={styles.subgoalProgressHeaderContainer}>
                <div className={styles.subgoalProgressHeader}>
                  {t([I18N_SCOPE, 'progress_label'])}
                </div>
                <div
                  className={`${styles.subgoalProgressSubHeader} ${
                    isReached && localStore.subgoal.checkpoints.length
                      ? styles.reached
                      : ''
                  }`}
                >
                  {isReached && localStore.subgoal.checkpoints.length ? (
                    <div className={styles.checkIconContainer}>
                      <IconCheck customClass={styles.checkIcon} />
                    </div>
                  ) : null}
                  <div>
                    {localStore.subgoal.checkpoints.length
                      ? `${
                          localStore.subgoal.checkpoints.filter(
                            item => !!item.reached,
                          ).length
                        }`
                      : `${localStore.subgoal.current_value}`}
                  </div>
                  {localStore.subgoal.checkpoints.length
                    ? `/${localStore.subgoal.checkpoints.length}`
                    : `/${localStore.subgoal.target_value}${
                        localStore.subgoal.unit
                          ? ` ${localStore.subgoal.unit}`
                          : ''
                      }`}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Observer>
    );
  };

  const RenderItem = ({subgoal}: {subgoal: Subgoal}) => {
    return (
      <Observer>
        {() => (
          <div className={styles.clientPartContainer}>
            {subgoal.checkpoints.length ? (
              subgoal.checkpoints.map(checkpoint => {
                const isReached = !!checkpoint.reached;

                return (
                  <div
                    className={styles.checkpointContainer}
                    key={checkpoint.uuid}
                  >
                    <div
                      className={`${styles.checkBox} ${
                        isReached ? styles.active : ''
                      }`}
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{
                        borderColor: getSubgoalColor(
                          subgoal.color,
                          themes.light,
                        ),
                        background: isReached
                          ? getSubgoalColor(subgoal.color, themes.light)
                          : 'initial',
                      }}
                    >
                      {isReached ? (
                        <IconCheck
                          customClass={styles.checkIcon}
                          fill={'#ffffff'}
                          viewBox={'2 0 32 32'}
                        />
                      ) : null}
                    </div>
                    <div
                      className={`${styles.checkpointTitle} ${
                        isReached ? styles.reached : ''
                      }`}
                      style={{
                        color: getSubgoalColor(subgoal.color, themes.light),
                      }}
                    >
                      {checkpoint.title}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div className={styles.progressBar}>
                  <div
                    className={styles.progressBarFill}
                    style={{
                      flex: subgoal.current_value / subgoal.target_value,
                      backgroundColor: getSubgoalColor(
                        subgoal.color,
                        themes.light,
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            {subgoal.client_comment ? (
              <div className={styles.commentContainer}>
                <div className={styles.commentHeader}>
                  {t([I18N_SCOPE, 'comment_label'])}
                </div>
                <div className={styles.comment}>{subgoal.client_comment}</div>
              </div>
            ) : null}
            {subgoal.client_attitude !== null ? (
              <div className={styles.attitudeContainer}>
                <div className={styles.attitudeHeader}>
                  {t([I18N_SCOPE, 'attitude_label'])}
                </div>
                <div className={styles.attitude}>
                  <div className={styles.attitudeText}>
                    {`${getGoalAttitudeIcon(
                      (subgoal.client_attitude * 1000) / 10,
                    )} ${(subgoal.client_attitude * 1000) / 10}%`}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Observer>
    );
  };

  return (
    <Observer>
      {() => (
        <div
          className={`ClientGoal ${styles.ClientGoal}`}
          ref={thisRef}
          style={
            localStore.bottom
              ? {
                  position: 'absolute',
                  left: '0px',
                  top: `${localStore.bottom}px`,
                  width: '100%',
                }
              : {}
          }
        >
          <RenderHeader />
          <RenderSubgoal />
          {localStore.subgoal ? (
            <RenderItem subgoal={localStore.subgoal} />
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(ClientGoal);
