import type {FC} from 'react';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import InView from 'react-intersection-observer';
import {useHistory, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {
  ApiRpcQuery,
  CollectionStore,
  DateTimeObj,
  Expand,
} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  datetimeObjToISOString,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';

import {IconCup} from '@src/components/icons';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import NoResults from '@src/components/NoResults/NoResults';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import ScrollToElement from '@src/components/ScrollToElement/ScrollToElement';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {t} from '@src/i18n';
import {PathBuilderService} from '@src/v2/services/PathBuilderService';

import ClientSubgoalsButton, {
  CLIENT_SUBGOALS_BUTTON_ID,
} from '../ClientSubgoalsButton/ClientSubgoalsButton';
import CRUSubgoal from '../CRUSubgoal/CRUSubgoal';
import type {GoalT} from '../Goal/Goal';
import type {Course as CourseProps} from '../GoalsSelectCourseTab/GoalsSelectCourseTab';
import SubgoalsListItem from '../SubgoalsListItem/SubgoalsListItem';

import styles from './../styles.module.scss';

const LIMIT = 100;
const I18N_SCOPE = 'GoalsTab';
const I18N_SCOPE_COURSE_GOALS_LIST_ITEM = 'CourseGoalsListItem';
const I18N_SCOPE_CRU_SUBGOAL = 'CRUSubgoal';

const expand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
          ['edition_id', null, {edition: ['contract_id']}],
        ],
      },
    ],
  ],
};

export type ExpandedClient =
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>);

type TCourse = Course & {
  client: ExpandedClient;
  edition?:
    | (Edition & {
        contract?: IFile | null;
      })
    | null;
  start_date: DateTimeObj;
  end_date: DateTimeObj;
};

export type Goal = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: TCourse;
};

type GoalListItemT = Omit<Goal, 'subgoals'> & {
  subgoals: SubgoalListItemT[];
  index: number;
  isLast?: boolean;
};

type SubgoalListItemT = Subgoal & {
  isLast?: boolean;
};

type ListItemT = GoalListItemT | SubgoalListItemT;

interface Section {
  title?: string;
  program: Goal['program'];
  course?: Goal['course'];
  dataGoal: ListItemT[][];
}

interface ILocalStore {
  _onListItemPress: (item: ListItemT, goal: GoalT, userId?: string) => void;
  goalsStore: CollectionStore<Goal> | null;
  setGoalsStore(goalsStore: CollectionStore<Goal>): void;
  userIsCoach: boolean | null;
  shouldRenderStub: boolean;
  sections: Section[];
  selectGoalId: string | null;
  setSelectGoalId(selectGoalId: string | null): void;
  selectUrlGoalId: string | null;
  setSelectUrlGoalId(selectUrlGoalId: string | null): void;
  selectUrlSubgoalId: string | null;
  setSelectUrlSubgoalId(selectUrlSubgoalId: string | null): void;
  selectGoal: Goal | null;
  setSelectGoal(selectGoal: Goal | null): void;
  selectCourse: TCourse | null;
  setSelectCourse(selectCourse: TCourse | null): void;
  selectSubgoal: Subgoal | null;
  setSelectSubgoal(selectSubgoal: Subgoal | null): void;
  isOpenCRUSubgoalModule: boolean;
  setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean): void;
}

interface Props {
  course: CourseProps | null;
  selectGoalId?: string | null;
  onSelectSubgoal?: (subgoal: Subgoal, goal: IGoal, userId?: string) => void;
  onSelectClientGoals?: (goal: IGoal) => void;
}

const GoalsTab: FC<Props> = ({
  course,
  selectGoalId,
  onSelectSubgoal,
  onSelectClientGoals,
}) => {
  const goalUrlId = useURLQueryParam('goalId');
  const history = useHistory();
  const location = useLocation();
  const {
    stores: {currentUserStore, goalStore},
  } = useContext(AppContext);
  const ref = useRef<CollectionStore<Goal> | null>(null);

  const localStore = useMemo(
    () =>
      observable<ILocalStore>(
        {
          _onListItemPress: (item: ListItemT, goal: GoalT, userId?: string) => {
            const isSubgoal = item._id.split(':')[0] === 'subgoal';

            localStore.setSelectUrlSubgoalId(null);

            if (isSubgoal && onSelectSubgoal) {
              localStore.setSelectGoalId(item._id);
              onSelectSubgoal(item as Subgoal, goal, userId);
            }
          },
          selectSubgoal: null,
          setSelectSubgoal(selectSubgoal: Subgoal | null) {
            this.selectSubgoal = selectSubgoal;
          },
          isOpenCRUSubgoalModule: false,
          setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean) {
            this.isOpenCRUSubgoalModule = isOpenCRUSubgoalModule;
          },
          selectUrlGoalId: null,
          setSelectUrlGoalId(selectUrlGoalId: string | null) {
            this.selectUrlGoalId = selectUrlGoalId;
          },
          selectUrlSubgoalId: null,
          setSelectUrlSubgoalId(selectUrlSubgoalId: string | null) {
            this.selectUrlSubgoalId = selectUrlSubgoalId;
          },
          selectGoal: null,
          setSelectGoal(selectGoal: Goal | null) {
            this.selectGoal = selectGoal;
          },
          selectCourse: null,
          setSelectCourse(selectCourse: TCourse | null) {
            this.selectCourse = selectCourse;
          },
          selectGoalId: null,
          setSelectGoalId(newSelectGoalId: string | null) {
            this.selectGoalId = newSelectGoalId;
          },

          goalsStore: null,
          setGoalsStore(goalsStore: CollectionStore<Goal>) {
            this.goalsStore = goalsStore;
          },
          get userIsCoach() {
            return (
              currentUserStore.user &&
              currentUserStore.user.roles.includes('coach')
            );
          },
          get shouldRenderStub() {
            if (this.goalsStore) {
              return this.goalsStore.isLoaded && !this.goalsStore.hasItems;
            } else {
              return false;
            }
          },
          get sections() {
            const sections: Section[] = [];

            if (this.goalsStore) {
              let goals = this.goalsStore.items.slice();

              const groups: {[key: string]: Goal[]} = {};

              const groupBy = (goal: Goal) => {
                const groupId = goal.course_id || goal.program_id;

                groups[groupId] = groups[groupId] || [];

                groups[groupId].push(goal);
              };

              goals.forEach(groupBy);

              Object.keys(groups).forEach(key => {
                const dataGoal: ListItemT[][] = [];

                groups[key]
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .forEach((goal, gIndex) => {
                    const data: ListItemT[] = [];

                    data.push({
                      ...goal,
                      index: gIndex,
                      subgoals: goal.subgoals.sort(
                        (a, b) => a.sort_order - b.sort_order,
                      ),
                    });

                    const userIsCoach = !!(
                      currentUserStore.user &&
                      goal.coach_ids.includes(currentUserStore.user._id)
                    );

                    if (goal.subgoals.length) {
                      let subgoals = goal.subgoals.slice();

                      if (!userIsCoach) {
                        subgoals = subgoals.sort((a, b) =>
                          b.is_client_managed === a.is_client_managed
                            ? 0
                            : b.is_client_managed
                            ? -1
                            : 1,
                        );
                      } else {
                        subgoals = subgoals.sort(
                          (a, b) => a.sort_order - b.sort_order,
                        );
                      }

                      data.push(...subgoals);

                      subgoals.forEach(item => {
                        if (localStore.selectUrlSubgoalId === item._id) {
                          localStore._onListItemPress(
                            item,
                            goal,
                            // @ts-ignore
                            location.state.clientId as string,
                          );
                        }
                      });

                      if (
                        !(
                          userIsCoach &&
                          (goal.is_client_managed ||
                            goal.counters.client_subgoals)
                        )
                      ) {
                        data[data.length - 1].isLast = true;
                      }
                    }

                    if (
                      userIsCoach &&
                      (goal.is_client_managed || goal.counters.client_subgoals)
                    ) {
                      data.push({
                        ...goal,
                        index: gIndex,
                        _id: `${CLIENT_SUBGOALS_BUTTON_ID}|${goal._id}`,
                      });
                    }

                    dataGoal.push(data);
                  });

                sections.push({
                  program: groups[key][0].program,
                  course: groups[key][0].course,
                  dataGoal,
                });
              });
            }

            return sections;
          },
        },
        {
          goalsStore: observable.shallow,
          setGoalsStore: action,
          userIsCoach: computed,
          shouldRenderStub: computed,
          sections: computed,
          selectGoalId: observable,
          setSelectGoalId: action,
          selectUrlGoalId: observable,
          setSelectUrlGoalId: action,
          selectUrlSubgoalId: observable,
          setSelectUrlSubgoalId: action,
          selectGoal: observable,
          setSelectGoal: action,
          selectCourse: observable,
          setSelectCourse: action,
          selectSubgoal: observable,
          setSelectSubgoal: action,
          isOpenCRUSubgoalModule: observable,
          setIsOpenCRUSubgoalModule: action,
        },
      ),
    // @ts-ignore
    [currentUserStore.user, location.state?.clientId, onSelectSubgoal],
  );

  useEffect(() => {
    if (typeof selectGoalId !== 'undefined') {
      localStore.setSelectGoalId(selectGoalId);
    }

    if (ref.current) {
      localStore.setGoalsStore(ref.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectGoalId]);

  useEffect(() => {
    const disposeGoalStoreSubClientUpdate = reaction(
      () => goalStore.sub.client.updating,
      updating => {
        if (updating.success && updating.entity && localStore.goalsStore) {
          const goalIndex = localStore.goalsStore.items.findIndex(
            item => item._id === updating.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore.items[goalIndex];

            const subGoalIndex = goalItem.subgoal_ids.findIndex(
              item => item === updating.entity!._id,
            );

            if (subGoalIndex >= 0) {
              const subGoalItem = goalItem.subgoals[subGoalIndex];

              goalItem.subgoals[subGoalIndex] = {
                ...subGoalItem,
                ...updating.entity,
              };

              localStore.goalsStore.updateItem(goalItem, goalItem);
            }
          }
        }
      },
    );

    const disposeGoalStoreSubClientCreate = reaction(
      () => goalStore.sub.client.own.creating,
      () => {
        _fetchGoals();
      },
    );

    const disposeGoalStoreSubClientDeleting = reaction(
      () => goalStore.sub.client.own.deleting,
      () => {
        _fetchGoals();
      },
    );

    if (goalUrlId) {
      localAppStore?.setIsTopNotificationsOpen(false);
      localStore.setSelectUrlGoalId(goalUrlId);

      // @ts-ignore
      if (location.state && location.state.subgoalId) {
        // @ts-ignore
        localStore.setSelectUrlSubgoalId(location.state.subgoalId as string);
      }
    }

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }

      if (ref.current) {
        ref.current.clear();
      }

      disposeGoalStoreSubClientUpdate();
      disposeGoalStoreSubClientCreate();
      disposeGoalStoreSubClientDeleting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref.current = createCollectionStore({
      params: {
        sort: [
          ['sort_order', 1],
          ['created', -1],
        ],
        limit: LIMIT,
        expand,
      },
    });

    localStore.setGoalsStore(ref.current);

    _fetchGoals();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const _fetchGoals = async (silent = false) => {
    ref.current!.setMethod(
      localStore.userIsCoach ? 'coach.goals.unbound.list' : 'client.goals.list',
    );

    await ref
      .current!.fetch(
        {
          query: (
            [
              course
                ? ['course_id', '==', course._id]
                : ['course_id', '!=', null],
              localStore.userIsCoach
                ? ['status', 'in', ['in_progress', 'in_review']]
                : ['status', '==', 'in_progress'],
            ] as ApiRpcQuery[]
          ).filter(Boolean),
          limit: Math.max(ref.current!.items.length, LIMIT),
        },
        {silent},
      )
      .catch(() => {});
  };

  const _onClientSubgoalsButtonPress = (item: ListItemT, goal: GoalT) => {
    localStore.setSelectUrlSubgoalId(null);

    if (onSelectClientGoals) {
      onSelectClientGoals(goal);
    }
  };

  const _onSectionHeaderPress = (section: Section) => {
    if (section.course) {
      if (
        currentUserStore.user &&
        section.program.coach_ids.includes(currentUserStore.user._id)
      ) {
        // const practiceName =
        //   currentUserStore.user.slug || currentUserStore.user._id;
        //
        // history.push({
        //   pathname: `/coaches/${practiceName}/programs/${
        //     section.program!.slug ? section.program!.slug : section.program!._id
        //   }/program`,
        //   search: `?sqid=${section.course._id}&pid=${section.program!._id}`,
        //   state: {tab: 'goals'},
        // });

        const practiceSlug = currentUserStore.user.slug;
        const practiceId = currentUserStore.user._id;
        const programSlug = section.program!.slug;
        const programId = section.program!._id;
        const courseId = section.course._id;

        history.push(
          PathBuilderService.toCourse(
            {slug: practiceSlug, id: practiceId},
            {slug: programSlug, id: programId},
            courseId,
            programId,
            {tab: 'goals'},
          ),
        );
      }
    }
  };

  const RenderStub = () => (
    <Observer>
      {() => (
        <>
          {localStore.shouldRenderStub ? (
            <NoResults
              text={
                currentUserStore.user?.roles?.includes('coach')
                  ? t([I18N_SCOPE, 'no_results_label'])
                  : t(['CourseGoalsTab', 'no_results_label'])
              }
              className={styles.NoResults}
              classNameTextContainer={styles.textContainer}
            >
              <div className={styles.iconNoResultContainer}>
                <IconCup className={styles.iconNoResult} viewBox="4 4 30 30" />
              </div>
            </NoResults>
          ) : null}
        </>
      )}
    </Observer>
  );

  const RenderSectionHeader = memo(({section}: {section: Section}) => {
    return (
      <div
        onClick={() => _onSectionHeaderPress(section)}
        className={styles.programContainer}
      >
        <div className={styles.programAvatarContainer}>
          <OtherUserProfileImg
            avatar={section.program.avatar}
            title={section.program.title}
            titleFontSize="14px"
            isBGGradient
            getTitleIconIfNoImage
            isOverlay={!section.program.avatar}
          />
        </div>
        <div className={styles.programInfoContainer}>
          {!section.course ? (
            <div className={styles.programSubTitle}>
              {t([I18N_SCOPE, 'program_label'])}
            </div>
          ) : null}
          <div
            className={
              !section.course ? styles.programTitle : styles.programSubTitle
            }
          >
            {section.program.title}
          </div>
          {section.course ? (
            <div className={styles.programTitle}>
              {getCourseDurationString(section.course)}
            </div>
          ) : null}
          {section.course && section.course.client_id ? (
            <div className={styles.courseClientName}>
              {section.course.client.name}
            </div>
          ) : null}
        </div>
      </div>
    );
  });

  const _onAddSubGoalButtonPress = (_course: TCourse) => (item: ListItemT) => {
    localStore.setSelectGoal(item as Goal);
    localStore.setIsOpenCRUSubgoalModule(true);
    localStore.setSelectCourse(_course);
  };

  const handleCloseModalCRUSubgoal = () => {
    localStore.setIsOpenCRUSubgoalModule(false);
    localStore.setSelectGoal(null);
    localStore.setSelectSubgoal(null);
    localStore.setSelectCourse(null);
  };

  const RenderItem = memo(
    ({
      item,
      goal,
      _course,
      _selectGoalId,
    }: {
      item: ListItemT;
      goal: ListItemT;
      _course?: Goal['course'];
      _selectGoalId?: string | null;
    }) => {
      return (
        <Observer>
          {() => (
            <>
              <ListItem
                item={item}
                goal={goal}
                selectGoalId={selectGoalId}
                onPress={localStore._onListItemPress}
                onAddSubGoalButtonPress={
                  item.is_client_managed &&
                  _course &&
                  (_course.status === 'planned' ||
                    _course.status === 'ongoing') &&
                  dayjs() < dayjs(datetimeObjToISOString(_course.end_date))
                    ? _onAddSubGoalButtonPress(_course)
                    : undefined
                }
                onClientSubgoalsButtonPress={_onClientSubgoalsButtonPress}
              />
              <ModalAnimateWin
                showModal={localStore.isOpenCRUSubgoalModule}
                closeModalHandler={handleCloseModalCRUSubgoal}
                className="greyHeaderContainer littleContainer w500 List"
                isBody
                classNameBody="whiteBody maxContent noP30"
                header={t([I18N_SCOPE_CRU_SUBGOAL, 'nav_title'])}
                classNameHeader="greyHeader w500"
                classNameCloseBut="greyHeaderBut"
              >
                {localStore.selectGoal ? (
                  <CRUSubgoal
                    onCloseModal={handleCloseModalCRUSubgoal}
                    goalId={localStore.selectGoal!._id}
                    forClient={true}
                    minDate={dayjs(
                      localStore.selectCourse!.start_date
                        ? datetimeObjToISOString(
                            localStore.selectCourse!.start_date,
                          )
                        : undefined,
                    ).toDate()}
                    maxDate={
                      localStore.selectCourse!.end_date
                        ? dayjs(
                            datetimeObjToISOString(
                              localStore.selectCourse!.end_date,
                            ),
                          ).toDate()
                        : dayjs()
                            .add(
                              (
                                localStore.selectCourse!.edition || {
                                  duration: 7,
                                }
                              ).duration,
                              'day',
                            )
                            .toDate()
                    }
                  />
                ) : localStore.selectSubgoal ? (
                  <CRUSubgoal
                    onCloseModal={handleCloseModalCRUSubgoal}
                    goalId={localStore.selectSubgoal.goal_id}
                    forClient={true}
                    subgoal={localStore.selectSubgoal}
                    minDate={dayjs(
                      localStore.selectCourse!.start_date
                        ? datetimeObjToISOString(
                            localStore.selectCourse!.start_date,
                          )
                        : undefined,
                    ).toDate()}
                    maxDate={
                      localStore.selectCourse!.end_date
                        ? dayjs(
                            datetimeObjToISOString(
                              localStore.selectCourse!.end_date,
                            ),
                          ).toDate()
                        : dayjs()
                            .add(
                              (
                                localStore.selectCourse!.edition || {
                                  duration: 7,
                                }
                              ).duration,
                              'day',
                            )
                            .toDate()
                    }
                  />
                ) : null}
              </ModalAnimateWin>
            </>
          )}
        </Observer>
      );
    },
  );

  const handleOnChangeInView = inView => {
    if (inView) {
      localStore.setSelectUrlGoalId(null);
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`GoalsTab ${styles.GoalsTab}`}>
          {localStore.sections.length <= 0 ? <RenderStub /> : null}
          {localStore.sections.map((section, index) => {
            return (
              <div
                className={styles.sectionContainer}
                key={`${section.program._id}-${
                  section.course ? section.course._id : index
                }`}
              >
                <RenderSectionHeader section={section} />
                {section.dataGoal.map(goalSection => {
                  return (
                    <div
                      className={styles.goalSection}
                      key={goalSection[0]._id}
                    >
                      {goalSection.map(item => {
                        return (
                          <div className={styles.itemContainer} key={item._id}>
                            <RenderItem
                              _course={section.course}
                              item={item}
                              goal={goalSection[0]}
                              _selectGoalId={localStore.selectGoalId}
                            />
                          </div>
                        );
                      })}
                      {localStore.selectUrlGoalId === goalSection[0]._id ? (
                        /* @ts-ignore */
                        <InView as="div" onChange={handleOnChangeInView}>
                          <ScrollToElement isSmooth />
                        </InView>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </Observer>
  );
};

export default memo(GoalsTab);

interface ListItemProps<T extends ListItemT = ListItemT> {
  item: T;
  goal: T;
  selectGoalId?: string | null;
  onPress?: (item: T, goal: GoalT) => void;
  onClientSubgoalsButtonPress?: (item: T, goal: GoalT) => void;
  onAddSubGoalButtonPress?: (item: ListItemT) => void;
}

const ListItem: FC<ListItemProps> = ({
  item,
  goal,
  selectGoalId,
  onPress,
  onClientSubgoalsButtonPress,
  onAddSubGoalButtonPress,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;

  const onPressCb = useCallback(() => {
    onPress && onPress(item, goal as GoalT);
  }, [item, goal, onPress]);

  const onClientSubgoalsButtonPressCb = useCallback(() => {
    onClientSubgoalsButtonPress &&
      onClientSubgoalsButtonPress(item, goal as GoalT);
  }, [onClientSubgoalsButtonPress, item, goal]);

  const isGoal = useMemo(() => item._id.split(':')[0] === 'goal', [item._id]);

  const isSubgoal = useMemo(
    () => item._id.split(':')[0] === 'subgoal',
    [item._id],
  );

  const isGoalHasSubgoals = useMemo(
    () => !!((item as Goal).subgoals && (item as Goal).subgoals.length),
    [item],
  );

  const isClientManagedGoal = useMemo(
    () => isGoal && (item as Goal).is_client_managed,
    [isGoal, item],
  );

  const onAddSubGoalButtonPressCb = useCallback(() => {
    onAddSubGoalButtonPress && onAddSubGoalButtonPress(item);
  }, [onAddSubGoalButtonPress, item]);

  const isCoach = useMemo(
    () => user && item.coach_ids.includes(user._id),
    [item.coach_ids, user],
  );

  return (
    <Observer>
      {() => (
        <div>
          <div className={styles.listItemContainer}>
            {isGoal ? (
              <>
                <div className={styles.listItemContentContainer}>
                  <div className={styles.goalIndex}>
                    {t('label.goal_index', {
                      index: (item as GoalListItemT).index + 1,
                    })}
                  </div>
                  <div className={styles.goalTitle}>{item.title}</div>
                  <div className={styles.goalDescription}>
                    {item.description}
                  </div>
                </div>

                <div className={styles.subgoalsHeaderContainerStyle}>
                  {isCoach ||
                  (!isCoach && (isGoalHasSubgoals || isClientManagedGoal)) ? (
                    <div className={styles.subgoalsHeaderLabel}>
                      {t([I18N_SCOPE_COURSE_GOALS_LIST_ITEM, 'subgoals_label'])}
                    </div>
                  ) : null}
                </div>
                {onAddSubGoalButtonPress && !isCoach && isClientManagedGoal ? (
                  <div
                    onClick={onAddSubGoalButtonPressCb}
                    className={styles.subgoalsHeaderButtonText}
                  >
                    {t([I18N_SCOPE, 'add_goal_button'])}
                  </div>
                ) : null}
              </>
            ) : isSubgoal ? (
              <SubgoalsListItem
                subgoal={item as SubgoalListItemT}
                onPress={onPressCb}
                selectGoalId={selectGoalId}
                showNextIcon
              />
            ) : item._id.includes(CLIENT_SUBGOALS_BUTTON_ID) ? (
              <ClientSubgoalsButton onPress={onClientSubgoalsButtonPressCb} />
            ) : null}
          </div>
        </div>
      )}
    </Observer>
  );
};
