import React from 'react';

import Modal from '../../../components/Modal/Modal';

interface IGoalModal {
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  type: string;
}

const GoalModal = ({onClose, children, className, type}: IGoalModal) => {
  return (
    <Modal classNameContent={className} closeModal={onClose} type={type}>
      {children}
    </Modal>
  );
};

export default GoalModal;
