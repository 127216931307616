import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import MainChatsContext from './MainChatsContext';
import useMainChatsLocalStore from './useMainChatsLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const MainChatsState: FC<Props> = ({children}) => {
  const localStore = useMainChatsLocalStore();

  return (
    <MainChatsContext.Provider value={localStore}>
      {children}
    </MainChatsContext.Provider>
  );
};

export default memo(MainChatsState);
