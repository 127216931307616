import React, {useCallback, useMemo} from 'react';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';
import dayjs from 'dayjs';

import {ProgramScheduleItemTypeEnum} from '@yourcoach/shared/api/program';
import CameraIcon from '@yourcoach/shared/assets/icons/camera.svg';
import CheckboxIcon from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import ClockIcon from '@yourcoach/shared/assets/icons/clock.svg';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';

import Button from '@src/components/Button';

import type {ScheduleItem} from './Schedule';
import styles from './ScheduleListItem.module.css';

export interface Props<T extends ScheduleItem = ScheduleItem> {
  item: T;
  onClick?: (item: T) => void;
  onMoreButtonClick?: (
    item: T,
    event: React.MouseEvent<Element, MouseEvent>,
  ) => void;
  className?: string;
}

const ProgramScheduleListItem: React.FC<Props<ScheduleItem>> = ({
  item,
  onClick,
  onMoreButtonClick,
  className,
}) => {
  const onClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onClick && onClick(item);
    },
    [item, onClick],
  );

  const onMoreButtonClickCb: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      onMoreButtonClick && onMoreButtonClick(item, e);
    },
    [item, onMoreButtonClick],
  );

  const time = useMemo(
    () => dayjs().startOf('day').add(item.time, 'second').format('LT'),
    [item.time],
  );

  return (
    <div
      onClick={onClick ? onClickCb : undefined}
      className={classNames(styles.container, styles[item.type.id], className)}
    >
      <div className={styles.iconContainer}>
        {item.type.id === ProgramScheduleItemTypeEnum.CONFERENCE.id ? (
          <CameraIcon />
        ) : (
          <CheckboxIcon />
        )}
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{item.title}</p>
        {item.description ? (
          <p className={styles.text}>{item.description}</p>
        ) : null}
      </div>
      <div className={styles.timeContainer}>
        <ClockIcon />
        <p className={styles.time}>{time}</p>
      </div>
      {onMoreButtonClick ? (
        <Button onClick={onMoreButtonClickCb} className={styles.moreButton}>
          <MoreIcon />
        </Button>
      ) : null}
    </div>
  );
};

export default React.memo(
  ProgramScheduleListItem,
  isEqual,
) as typeof ProgramScheduleListItem;
