import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as CRUProgramDurationProps} from './Duration';
import CRUProgramDuration, {I18N_SCOPE} from './Duration';

type Props = Partial<ModalProps> & CRUProgramDurationProps;

const CRUProgramDurationModal: React.FC<Props> = ({
  duration,
  onSet,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title={t([I18N_SCOPE, 'title'])}>
    <CRUProgramDuration duration={duration} onSet={onSet} />
    {children}
  </Modal>
);

export default React.memo(CRUProgramDurationModal);
