import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

import LibraryContext from './LibraryContext';
import useLibraryLocalStore from './useLibraryLocalStore';

interface Props {
  children: ReactNode[] | ReactNode;
}

const LibraryState: FC<Props> = ({children}) => {
  const localStore = useLibraryLocalStore();

  return (
    <LibraryContext.Provider value={localStore}>
      {children}
    </LibraryContext.Provider>
  );
};

export default memo(LibraryState);
