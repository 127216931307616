/**
 * Styles for the Card component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

import type {StyledProps} from './types';

const containerBase = css<StyledProps>`
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${getColor('border2')};
  background-color: ${getColor('background1')};

  @media (min-width: 320px) and (max-width: 950px) {
    padding: 22px 26px 16px 20px;
    border: 0;
    border-radius: 0;
  }
`;

export const containerWeb = css<StyledProps>`
  box-sizing: border-box;
  ${containerBase}
`;
