import React from 'react';
import {observer} from 'mobx-react';

import Color from 'color';
import styled from 'styled-components';

import DangerIcon from '@yourcoach/shared/assets/icons/primary/Danger.svg';
import VideoIcon from '@yourcoach/shared/assets/icons/primary/Video.svg';
import type {
  ConferenceModel,
  ConferenceStrategy,
  TaskStrategy,
} from '@yourcoach/shared/stores/single-event';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

import {IconButton} from '../../../IconButton';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 18px;
  right: 48px;
  width: 200px;
  background: ${getColor('global1')};
  &:hover {
    background: ${p => Color(getColor('global1')(p)).darken(0.2).toString()};
  }
  @media (min-width: 320px) and (max-width: 950px) {
    position: static;
    width: 100%;
  }
`;

export const ControlsContainer: React.FC<{
  store: ConferenceStrategy | TaskStrategy;
  onStartConference: (arg: string) => void;
  isRescheduleButtonDisabled: boolean;
  onRescheduleClick: () => void;
}> = observer(
  ({
    store,
    onStartConference,
    isRescheduleButtonDisabled,
    onRescheduleClick,
  }) => {
    const onEditHandler = React.useCallback(() => {
      store.isEditOpenModal.toggle();
    }, [store]);

    return store.initialLoad.isReady ? (
      <View>
        {store.event.isConference && (
          <ConferenceControlsContainer
            event={store.event as ConferenceModel}
            onEditHandler={onEditHandler}
            startButtonLabel={(store as ConferenceStrategy).startButtonLabel}
            onStartConference={onStartConference}
            isRescheduleButtonDisabled={isRescheduleButtonDisabled}
            onRescheduleClick={onRescheduleClick}
          />
        )}
      </View>
    ) : null;
  },
);

const ConferenceControlsContainer: React.FC<{
  event: ConferenceModel;
  startButtonLabel: string;
  onEditHandler: () => any;
  onStartConference: (arg: string) => void;
  onRescheduleClick: () => void;
  isRescheduleButtonDisabled: boolean;
}> = observer(
  ({
    event,
    onEditHandler,
    onStartConference,
    onRescheduleClick,
    isRescheduleButtonDisabled,
  }) => {
    const onStartHandler = React.useCallback(async () => {
      onStartConference(event.id);
    }, [onStartConference, event]);

    const onFixConflictClickHandler = React.useCallback(() => {
      onEditHandler();
    }, [onEditHandler]);

    return (
      <React.Fragment>
        {event.isConflicting ? (
          <IconButton
            icon={<DangerIcon />}
            scheme={'error'}
            onClick={onFixConflictClickHandler}
          >
            <Text color={'inherit'}>Select new date</Text>
          </IconButton>
        ) : (
          event.isShowStart && (
            <StyledIconButton
              icon={<VideoIcon />}
              scheme={'secondary'}
              onClick={
                isRescheduleButtonDisabled ? onStartHandler : onRescheduleClick
              }
              disabled={
                isRescheduleButtonDisabled
                  ? !event.isStartEnabled
                  : isRescheduleButtonDisabled
              }
            >
              <Text color={'inherit'}>
                {isRescheduleButtonDisabled ? 'Join' : 'Reschedule'}
              </Text>
            </StyledIconButton>
          )
        )}
      </React.Fragment>
    );
  },
);
