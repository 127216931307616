import React from 'react';

import styled from 'styled-components';

import {Avatar} from '@yourcoach/shared/uikit/Avatar';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

// import {useController} from './controller';
import {containerWeb} from './styles';
import type {Props} from './types';

const Container = styled(View)`
  ${containerWeb}
`;

const AttachedPhotoIcon: React.FC<WithDomStyleProp<Props>> = ({
  thumbSrc,
  alt,
  onClick,
  ...rest
}) => {
  // const controller = useController(rest);

  return (
    <Container {...rest}>
      <Clickable onClick={onClick}>
        <Avatar
          name={alt}
          size={'icon.l'}
          variant={'square'}
          avatarUrl={thumbSrc}
        />
      </Clickable>
    </Container>
  );
};

export {AttachedPhotoIcon};
