import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import noUserImg from '@src/assets/img/questionnaireNoUser.png';
import {
  labelSend,
  labelYouDontHaveAnyClients,
} from '@src/common/i18n/i18nCommon';
import {
  labelClients,
  labelSearchForClients,
} from '@src/common/i18n/i18nQuestionnaire';
import {CustomSearch} from '@src/components/CustomForm';
import {IconUserPlus} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import type {IUserFollower} from '@src/models/questionnaire';

import QSendUsersContext from '../../context/QSendUsersContext';
import type {ILocalStoreQSendUsers} from '../../context/QSendUsersLocalStore';

import styles from './../../styles.module.css';

interface ILocalStore {
  loading: boolean;
  setLoading: (newVal: boolean) => void;
  currentFilter: TBut;
  setCurrentFilter: (newFilter: TBut) => void;
  searchWord: string;
  setSearchWord: (newVal: string) => void;
}

type TBut = 'all' | 'followers' | 'inPrograms';

interface Props {}

const ByName: FC<Props> = () => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);
  const localStore = useRef(
    observable<ILocalStore>(
      {
        loading: false,
        setLoading(newVal: boolean) {
          this.loading = newVal;
        },
        currentFilter: 'all',
        setCurrentFilter(newFilter: TBut) {
          this.currentFilter = newFilter;
        },
        searchWord: '',
        setSearchWord(newVal: string) {
          this.searchWord = newVal;
        },
      },
      {
        searchWord: observable,
        loading: observable,
        currentFilter: observable,
        setLoading: action,
        setCurrentFilter: action,
        setSearchWord: action,
      },
    ),
  ).current;

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStore.setSearchWord(e.currentTarget.value);
  };

  const handleAddUser = (user: IUserFollower) => {
    return () => {
      storeQSendUsers.setAddUser(user);
      storeQSendUsers.sentQUser(user.follower.client_id);
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`ByName ${styles.mainContainer}`}>
          <CustomSearch
            label={labelSearchForClients()}
            id="searchQuestionnaires"
            onChange={handleOnSearchChange}
            classContainer={`searchContainer ${styles.searchContainer}`}
            classInput={styles.searchContainerInput}
            className="search"
            classIcon="icon"
            classIconContainer="iconContainer"
          />

          <div className={styles.resultContainer}>
            <Scrollbar
              className={`scrollbar ${styles.mainSectionContainer}`}
              noScrollX
              wrapperProps={{className: 'wrapper'}}
              trackYProps={{className: 'trackY'}}
              thumbYProps={{className: 'thumbY'}}
              trackXProps={{className: 'trackX'}}
              thumbXProps={{className: 'thumbY'}}
            >
              <h3>{labelClients()}</h3>
              {localStore.loading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.filterContainer}>
                    <div className={styles.filterButContainer} />
                  </div>
                  <div className={styles.listUserContainer}>
                    {storeQSendUsers.usersList ? (
                      storeQSendUsers.usersList
                        .filter(userFollower => {
                          if (localStore.searchWord === '') {
                            return userFollower.isAdded === false;
                          } else {
                            const reg = new RegExp(localStore.searchWord, 'i');

                            if (reg.test(userFollower.follower.client.name)) {
                              return userFollower.isAdded === false;
                            } else {
                              return false;
                            }
                          }
                        })
                        .map(userFollower => {
                          const follower = userFollower.follower;

                          return (
                            <div className={styles.userRow} key={follower._id}>
                              <div className={styles.userContainer}>
                                <div className={styles.userAvatar}>
                                  <OtherUserProfileImg
                                    avatar={follower.client.avatar}
                                    title={follower.client.name}
                                  />
                                </div>
                                <div className={styles.userName}>
                                  {follower.client.name}
                                </div>
                              </div>
                              <div
                                className={styles.addBut}
                                onClick={handleAddUser(userFollower)}
                              >
                                <IconUserPlus
                                  customClass={styles.icoUserPlus}
                                />
                                <span>{labelSend()}</span>
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div className={styles.noUserContainer}>
                        <img src={noUserImg} />
                        <div className={styles.textNoContent}>
                          {labelYouDontHaveAnyClients()}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Scrollbar>
          </div>

          <div className={styles.footerButContainer} />
        </div>
      )}
    </Observer>
  );
};

export default memo(ByName);
