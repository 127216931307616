import type {FC} from 'react';
import React, {memo} from 'react';

import Radio1SWG from '@src/assets/img/radio-1.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
}

const IconRadio1: FC<Props> = ({
  height = '100%',
  width = '100%',
  customClass = '',
}) => {
  return (
    <Radio1SWG
      className={`${customClass}`}
      width={height}
      height={width}
      fill="#819AA9"
    />
  );
};

export default memo(IconRadio1);
