import React from 'react';
import {observer} from 'mobx-react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

import {useHistory} from 'react-router-dom';

import {t} from 'i18n-js';

import {datetimeObjectToTimestamp} from '@yourcoach/shared/api';
import {useMobxStore} from '@yourcoach/shared/hooks';
import {alert} from '@yourcoach/shared/modules/alert';
import type {CalendarEvent} from '@yourcoach/shared/modules/calendar';
import {CalendarContainer, Period} from '@yourcoach/shared/modules/calendar';
import {
  EditSpecificDaysContainer,
  EditWeekScheduleContainer,
} from '@yourcoach/shared/modules/edit-week-schedule';
import {View} from '@yourcoach/shared/uikit/View';
import type {PayloadAction} from '@yourcoach/shared/utils';
import {getCourseDurationString} from '@yourcoach/shared/utils';

import Modal from '@src/components/ModalNew';
import CRUCalendarEvent from '@src/modules/calendar/CRUCalendarEvent';
import {PageContainer} from '@src/v2/containers/Page';
import {PathBuilderService} from '@src/v2/services/PathBuilderService';

import {ConflictListItem} from './components/ConflictListItem';
import {ControlsContainer} from './containers/Controls';
import {MyCalendarStore} from './stores';
import {I18N_MY_CALENDAR, mapScheme} from './utils';

export const MyCalendarPage: React.FC = observer(() => {
  const store = useMobxStore(() => new MyCalendarStore());

  const history = useHistory();

  const onPeriodChangeCallback = React.useCallback(
    (action: PayloadAction<Period>) => {
      if (action.payload) {
        if (action.payload === Period.month) {
          store.schedule.isShowEditSelectedButton.setTrue();
        } else {
          store.schedule.isSelectMode.setFalse();
          store.schedule.selected.clear();
          store.schedule.isShowEditSelectedButton.setFalse();
        }
      }
    },
    [store],
  );

  const onEventClickHandler = React.useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      if (action.payload) {
        history.push(
          PathBuilderService.toSession(action.payload.id, {
            from: '/my-calendar',
          }),
        );
      }
    },
    [history],
  );

  const onEventActionClickHandler = React.useCallback(
    async (action: PayloadAction<CalendarEvent>) => {
      if (action.payload) {
        const conferenceId = action.payload.id;

        const confirm = await alert.confirm({
          title: t(['shared.Conference', 'start_conference_question']),
        });

        if (confirm) {
          history.push(PathBuilderService.toConference(conferenceId));
        }
      }
    },
    [history],
  );

  const onDayClickCallback = React.useCallback(() => {
    store.schedule.isShowEditSelectedButton.setFalse();
  }, [store]);

  const renderEditModal = () => (
    <Modal
      isOpen={store.calendar.edit.isModalOpen.isTrue}
      title={store.calendar.edit.editPopupTitle}
      onRequestClose={store.calendar.edit.isModalOpen.setFalse}
    >
      {store.calendar.eventToEdit && (
        <CRUCalendarEvent
          course={store.calendar.eventToEdit.course as any}
          event={store.calendar.eventToEdit as any} //until have edit strategy
          onUpdate={store.calendar.onEditEventCallback}
        />
      )}
    </Modal>
  );

  const renderCreateModal = () => (
    <Modal
      isOpen={store.calendar.create.isModalOpen.isTrue}
      onRequestClose={store.calendar.create.isModalOpen.setFalse}
    >
      <CRUCalendarEvent onCreate={store.calendar.onCreateEventCallback} />
    </Modal>
  );

  const renderConflictsModal = () => (
    <Modal
      isOpen={store.calendar.conflicts.isModalOpen.isTrue}
      onRequestClose={store.calendar.conflicts.isModalOpen.setFalse}
      title={t([I18N_MY_CALENDAR, 'conflicts'])}
    >
      <View style={{flexDirection: 'column', gap: 15}}>
        {store.calendar.conflicts.list.entries.map(entry => (
          <ConflictListItem
            id={entry._id}
            key={entry._id}
            title={entry.title}
            programTitle={entry?.course?.program.title ?? ''}
            courseTitle={getCourseDurationString(entry.course)}
            start={datetimeObjectToTimestamp(entry.start_date)}
            end={datetimeObjectToTimestamp(entry.end_date)}
            onClick={store.calendar.onFixEvent}
          />
        ))}
      </View>
    </Modal>
  );

  const renderEditWeeklyScheduleModal = () => (
    <Modal
      isOpen={store.schedule.isEditWeeklyModalOpen.isTrue}
      title={t([I18N_MY_CALENDAR, 'buttons', 'edit_week_schedule'])}
      onRequestClose={store.schedule.isEditWeeklyModalOpen.setFalse}
    >
      <EditWeekScheduleContainer
        onSaveCallback={store.schedule.isEditWeeklyModalOpen.setFalse}
      />
    </Modal>
  );

  const renderEditSpecificDaysScheduleModal = () => (
    <Modal
      isOpen={store.schedule.isEditSelectedModalOpen.isTrue}
      onRequestClose={store.schedule.onCloseSpecificDaysFormCallback}
      title={t([I18N_MY_CALENDAR, 'buttons', 'edit_spec_days'])}
    >
      <EditSpecificDaysContainer
        days={store.schedule.selected.entries}
        onSaveCallback={store.schedule.onCloseSpecificDaysFormCallback}
      />
    </Modal>
  );

  return (
    <PageContainer
      pageTitle={t([I18N_MY_CALENDAR, 'page_title'])}
      headerSlot={
        <ControlsContainer
          schedule={store.schedule}
          conflicts={store.calendar.conflicts}
        />
      }
    >
      <CalendarContainer
        isSelectMode={store.schedule.isSelectMode.value}
        isLoading={store.calendar.isLoading}
        data={store.filteredData}
        getDataTrigger={store.calendar.fetch}
        schedule={store.schedule.weeklySchedule as string}
        onDayOffClickAction={store.calendar.create.isModalOpen.setTrue}
        onDaySelectClick={store.schedule.onSelectSpecificDays}
        onEditEvent={store.calendar.onEditEvent}
        onDeleteEvent={store.calendar.onDeleteEvent}
        onEventClick={onEventClickHandler}
        onEventActionClick={onEventActionClickHandler}
        mapScheme={mapScheme}
        onDayClick={onDayClickCallback}
        calendarInstanceType={'scheduling'}
        onPeriodChange={onPeriodChangeCallback}
      />

      {renderConflictsModal()}
      {renderCreateModal()}
      {renderEditModal()}
      {renderEditWeeklyScheduleModal()}
      {renderEditSpecificDaysScheduleModal()}
    </PageContainer>
  );
});
