import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Certificate} from '@yourcoach/shared/api/certificate';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelCover,
  labelCreatePractice,
  labelFieldsWithAnAsteriskAreRequired,
  labelImAMentor,
  labelMentorExplanation,
  labelMentorship,
  labelPracticeDescription,
  labelPracticeName,
  labelTheDescriptionMustConsistOf3To50Characters,
  labelTheNameMustConsistOf3To50Characters,
} from '@src/common/i18n/i18nProfile';
import {labelDescription, labelName} from '@src/common/i18n/i18nQuestionnaire';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {
  CustomButton,
  CustomInput,
  CustomTextArea,
} from '@src/components/CustomForm';
import ImageSelector from '@src/components/ImageSelector';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import type {ICategory} from '@src/models/app';

import Certificates from './Certificates/Certificates';
import ChoiceCategory from './ChoiceCategory/ChoiceCategory';
import styles from './styles.module.css';

interface ILocalStore {
  coach_logo: IFile | null | undefined;
  isDisable: boolean;
  isValid: boolean;
  errorText: string;
  userIsCoach: boolean;
  userIsMentor: boolean;
  toggleIsMentor(val: boolean): void;
  userCategory: ICategory[];
  setUserCategories(userCategory: ICategory[]): void;
  practiceName: string;
  practiceNameErr: string;
  description: string;
  descriptionErr: string;
  coach_logo_id: string | null;
  isEditPhotoModule: boolean;
  toggleOpenEditPhotoModule(val: boolean): void;
  setCoachLogo(newLogo: IFile): void;
  setCoachLogoId(newId: string): void;
  updateInputVal(name: string, value: string): void;
  setDisable(disVal: boolean): void;
  setIsValid(nameField: string): void;
  setAllValid(): void;
}

interface Props {
  isFirstEdit?: boolean;
  endEditCallBack?: () => void;
}

const EditCoachProfile: FC<Props> = ({
  isFirstEdit = false,
  endEditCallBack = () => {},
}) => {
  const {
    stores: {currentUserStore, couponStore},
  } = useContext(AppContext);

  const user = currentUserStore.user || null;

  const coach_logo = user ? user.coach_logo : null;

  const certificatesStore =
    user && user.roles.includes('coach')
      ? undefined
      : createCollectionStore<Certificate>({});

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const validObj = {
    practiceName: {
      name: 'practiceName',
      validTest: /^.{3,50}$/,
      err: labelTheNameMustConsistOf3To50Characters({maxLength: 50}),
      required: true,
      requiredErr: labelFieldsWithAnAsteriskAreRequired(),
    },
    description: {
      name: 'description',
      validTest: /.{3,250}$/,
      err: labelTheDescriptionMustConsistOf3To50Characters(),
      required: true,
      requiredErr: labelFieldsWithAnAsteriskAreRequired(),
    },
  };
  const localStore: ILocalStore = useRef(
    observable<ILocalStore>(
      {
        coach_logo,
        isDisable: false,
        isValid: true,
        errorText: '',
        userIsCoach: user?.roles?.includes('coach') || false,
        userIsMentor: user?.roles?.includes('mentor') || false,
        toggleIsMentor(val: boolean) {
          this.userIsMentor = val;
        },
        userCategory: user?.coach_categories || [],
        setUserCategories(userCategory: ICategory[]) {
          this.userCategory = userCategory;
        },
        practiceName: user?.coach_title || '',
        practiceNameErr: '',
        description: user?.coach_description || '',
        descriptionErr: '',
        coach_logo_id: user?.coach_logo_id || null,
        isEditPhotoModule: false,
        toggleOpenEditPhotoModule(val: boolean) {
          this.isEditPhotoModule = val;
        },
        setCoachLogo(newLogo: IFile) {
          this.coach_logo = newLogo;
        },
        setCoachLogoId(newId: string) {
          this.coach_logo_id = newId;
        },
        updateInputVal(name: string, value: string) {
          this[name] = value;
          this.setIsValid(name);
        },
        setDisable(disVal: boolean) {
          this.isDisable = disVal;
        },
        setIsValid(nameField: string) {
          let isValid = true;

          const currentValidObj = validObj[nameField];
          const nameInLocalStore = currentValidObj.name;

          if (this[nameInLocalStore].length) {
            isValid = currentValidObj.validTest.test(this[nameInLocalStore]);
          }

          if (isValid) {
            this[`${nameInLocalStore}Err`] = '';
          } else {
            this[`${nameInLocalStore}Err`] = currentValidObj.err;
          }
        },
        setAllValid() {
          let allValid = true;

          for (const key in validObj) {
            const nameField = validObj[key].name;

            if (this[`${nameField}Err`]) {
              this.errorText = this[`${nameField}Err`];
              allValid = false;
            } else if (validObj[key].required && !this[nameField].length) {
              this.errorText = validObj[key].requiredErr;
              allValid = false;
            }

            if (!allValid) {
              break;
            }
          }

          this.isValid = allValid;
        },
      },
      {
        coach_logo: observable,
        coach_logo_id: observable,
        userIsMentor: observable,
        isValid: observable,
        errorText: observable,
        userIsCoach: observable,
        userCategory: observable,
        isDisable: observable,
        isEditPhotoModule: observable,
        practiceName: observable,
        practiceNameErr: observable,
        description: observable,
        descriptionErr: observable,
        toggleOpenEditPhotoModule: action,
        setCoachLogo: action,
        updateInputVal: action,
        toggleIsMentor: action,
        setUserCategories: action,
        setDisable: action,
        setCoachLogoId: action,
        setIsValid: action,
        setAllValid: action,
      },
    ),
  ).current;

  const handleGetFile = async (file: IFile) => {
    localStore.setCoachLogo(file);
    localStore.setCoachLogoId(file._id);
  };

  const handleOnChangeInput = (
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | undefined,
  ) => {
    if (event) {
      localStore.updateInputVal(event.target.name, event.target.value);
    }
  };

  const handleOnSave = async () => {
    localStore.setAllValid();

    if (localStore.isValid && localStore.practiceName !== '') {
      localStore.setDisable(true);

      try {
        showOverlay();

        await currentUserStore.update({
          coach_logo_id: localStore.coach_logo_id
            ? localStore.coach_logo_id
            : null,
          coach_title: localStore.practiceName || '',
          coach_description: localStore.description || '',
          coach_categories:
            localStore.userCategory as unknown as Required<ICategory>[],
          is_mentor: localStore.userIsMentor,
        });

        if (!localStore.userIsCoach) {
          await currentUserStore.becomeCoach();
        }

        await currentUserStore.fetch();

        await currentUserStore.localUpdate({
          roles: [...currentUserStore.user!.roles, 'coach'],
          coach_logo: localStore.coach_logo,
        });

        // during onboarding, a user is not a coach yet
        // so we need to create certificates after that only
        if (certificatesStore && certificatesStore.items.length) {
          await apiRequest({
            batch: certificatesStore.items.map(certificate => ({
              method: 'coach.certificates.create',
              params: {
                file_id: certificate.file_id,
                title: certificate.title,
                school: certificate.school,
                is_public: certificate.is_public,
              },
            })),
          });
        }

        if (
          currentUserStore.user &&
          !(currentUserStore.user.metadata.onboarding || {is_finished: true})
            .is_finished
        ) {
          if (
            currentUserStore.user.metadata.onboarding &&
            currentUserStore.user.metadata.onboarding.reserved_coupon &&
            currentUserStore.user.metadata.onboarding.reserved_coupon.role ===
              'coach'
          ) {
            try {
              await couponStore.apply(
                currentUserStore.user.metadata.onboarding.reserved_coupon,
              );

              logger.event('coupon_applied');
            } catch (error) {
              // do nothing
            }
          }

          await currentUserStore.update({
            metadata: {
              ...currentUserStore.user!.metadata,
              onboarding: {
                is_finished: true,
              },
            },
          });
        }

        hideOverlay();

        endEditCallBack();
      } catch (error) {
        hideOverlay();

        localStore.setDisable(true);

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [{label: t(['shared.button.ok'])}],
        });
      }
    }
  };

  const handleClickSwitchMentor = () => {
    localStore.toggleIsMentor(!localStore.userIsMentor);
  };

  const addCategory = (userCategory: ICategory[]) => {
    localStore.setUserCategories(userCategory);
  };

  return (
    <Observer>
      {() => (
        <div className={`EditCoachProfile ${styles.EditCoachProfile}`}>
          <Scrollbar
            className="scrollbar"
            noScrollX
            wrapperProps={{className: 'wrapper'}}
            trackYProps={{className: 'trackY'}}
            thumbYProps={{className: 'thumbY'}}
            trackXProps={{className: 'trackX'}}
            thumbXProps={{className: 'thumbY'}}
          >
            {isFirstEdit && <h3>{labelCreatePractice()}</h3>}
            <br />
            <div className={styles.coachProfileContainer}>
              <h4>{labelCover()}:</h4>
              <ImageSelector
                className={styles.CoachLogo}
                file={localStore.coach_logo}
                onUpload={handleGetFile}
              />
              <br />
              <h4>{`${labelPracticeName()}:*`}</h4>
              <CustomInput
                type="input"
                label={`${labelName()}...`}
                id="practiceName"
                customClassLabel="QStandardLabel"
                customClassCont="QStandardContainer"
                customClassInput="QStandardInput"
                name="practiceName"
                value={localStore.practiceName}
                onChange={handleOnChangeInput}
                error={localStore.practiceNameErr}
              />
              <br />
              <h4>{`${labelPracticeDescription()}:*`}</h4>
              <CustomTextArea
                type="textarea"
                label={`${labelDescription()}...`}
                id="programDescription"
                hideLabel
                name="description"
                customClassLabel="QStandardLabel"
                customClassCont="QStandardContainer"
                customClassInput="QStandardTextArea"
                value={localStore.description}
                onChange={handleOnChangeInput}
                error={localStore.descriptionErr}
              />
              <br />
              <ChoiceCategory addCategory={addCategory} />
              <br />
              <Certificates store={certificatesStore} />
              <br />
              <div>{localStore.isValid ? '' : localStore.errorText}</div>
              <br />
              <div className={styles.bottomContainer}>
                <div className={styles.greyBoard}>
                  <h4>{labelMentorship()}</h4>
                  <div className={styles.switchContainer}>
                    <div className={styles.mentor}>{labelImAMentor()}</div>
                    <div className={`customGeneratorCheckBox ${styles.mt3}`}>
                      <input
                        type="checkbox"
                        className="switch"
                        defaultChecked={localStore.userIsMentor}
                        onClick={handleClickSwitchMentor}
                      />
                    </div>
                  </div>
                  <div className={styles.greyBoardText}>
                    {labelMentorExplanation()}
                  </div>
                </div>

                <div className={styles.bottomContainerSpacer}> </div>
              </div>
              <CustomButton
                disabled={localStore.isDisable}
                type="button"
                classButton="QuestionnaireContinueBut"
                onClick={handleOnSave}
              >
                <span>{t('shared.button.save')}</span>
              </CustomButton>
            </div>
          </Scrollbar>
          {overlayIsVisible ? (
            <div className="overlay">
              <Loader />
            </div>
          ) : null}
        </div>
      )}
    </Observer>
  );
};

export default memo(EditCoachProfile);
