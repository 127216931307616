import {useEffect, useState} from 'react';

type WindowDimensionsT = {
  width: number;
  height: number;
};

function getWindowDimensions(): WindowDimensionsT {
  const {innerWidth: width, innerHeight: height} = window;

  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
