import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as SelectCoachProps} from './SelectCoach';
import SelectCoach, {I18N_SCOPE} from './SelectCoach';
import styles from './SelectCoach.module.css';

type Props = Partial<ModalProps> & SelectCoachProps;

const SelectCoachModal: React.FC<Props> = ({
  selection,
  canAcceptPayments,
  onSelect,
  children,
  ...modalProps
}) => (
  <Modal
    title={t([I18N_SCOPE, 'title'])}
    withStickyFooter
    bodyClassName={styles.modalBody}
    {...modalProps}
  >
    <SelectCoach
      selection={selection}
      canAcceptPayments={canAcceptPayments}
      onSelect={onSelect}
    />
    {children}
  </Modal>
);

export default React.memo(SelectCoachModal);
