import React, {useCallback} from 'react';
import ReactModal from 'react-modal';

import styled from 'styled-components';

export interface Props extends ReactModal.Props {
  className?: string;
  disableBodyScroll?: boolean;
}

ReactModal.setAppElement('#root');

const ReactModalAdapter: React.FC<Props> = ({
  className,
  disableBodyScroll,
  ...props
}: Props) => {
  const contentClassName = 'content';
  const overlayClassName = 'overlay';

  const onAfterOpen = useCallback(
    (...args) => {
      if (disableBodyScroll) {
        document.body.style.overflow = 'hidden';
      }

      if (props.onAfterOpen) {
        props.onAfterOpen(...args);
      }
    },
    [disableBodyScroll, props],
  );

  const onAfterClose = useCallback(() => {
    if (disableBodyScroll) {
      document.body.style.overflow = 'auto';
    }

    if (props.onAfterClose) {
      props.onAfterClose();
    }
  }, [disableBodyScroll, props]);

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={200}
      {...props}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    />
  );
};

const StyledModal = styled(ReactModalAdapter)`
  & .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    margin: 20px;
    border-radius: 20px;
    outline: none;
    max-width: calc(100vw - (20px * 2));
    max-height: calc(100vh - (20px * 2));
    overflow-y: hidden;
    overflow-x: hidden;

    transform: scale(0.9);
    transition: transform 200ms ease-in-out;

    &.ReactModal__Content--after-open {
      transform: scale(1);
    }
    &.ReactModal__Content--before-close {
      transform: scale(0.9);
    }
  }
`;

export default StyledModal;

export const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${p => p.theme.color.background1};
  z-index: 10;
  padding: 20px 0 10px;
`;

export const ModalTitle = styled.h5`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 26px;
  line-height: 41px;

  @media screen and (max-width: 460px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const ModalMessage = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
`;

export const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${p => p.theme.color.background1};
  padding: 10px 0 20px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    margin: 0 10px 0 0;
  }

  & button:last-child {
    margin: 0;
  }
`;
