import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  align-items: flex-start;
  gap: 20px;
`;

const Main = styled(View)`
  flex: 1;
`;

const Aside = styled(View)`
  width: 400px;
`;

const Layout = {
  Container,
  Main,
  Aside,
};

export {Layout};
