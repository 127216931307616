import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {autorun} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import type {RpcRequest} from '@yourcoach/shared/api';
import {
  apiRequest,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {Conference} from '@yourcoach/shared/api/conference';
import {getCourseDurationString} from '@yourcoach/shared/api/course';
import type {Task} from '@yourcoach/shared/api/task';
import ArrowNextIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import CheckboxIcon from '@yourcoach/shared/assets/icons/checkbox-1.svg';
import type {IScheduleItemRepeat} from '@yourcoach/shared/utils/calendar';
import {ScheduleItemRepeat} from '@yourcoach/shared/utils/calendar';
import {getSecondsFromMidnight} from '@yourcoach/shared/utils/datetime';
import {
  createField,
  getDescriptionSchema,
  getTitleSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import TextArea from '@src/components/TextArea';
import TextField from '@src/components/TextField';
import {DATE_FORMAT} from '@src/config';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import {
  shouldUpdateProgramSchedule,
  updateConferencesSchedule,
  updateTasksSchedule,
} from '@src/models/program';
import type {Expanded as TaskExpanded} from '@src/models/tasks';
import {expand as taskExpand} from '@src/models/tasks';
import type {Expanded as ConferenceExpanded} from '@src/modules/conferences/utils';
import {expand as conferenceExpand} from '@src/modules/conferences/utils';
import SelectCourseModal from '@src/modules/courses/SelectCourse/Modal';

import styles from './CRUCalendarEvent.module.scss';

export const I18N_SCOPE = 'CRUCalendarEvent';

interface IEventType {
  id: string;
  toString: () => string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  duration: number;
}

const EventType: {[key: string]: IEventType} = {
  TASK: {
    id: 'task',
    toString: () => t([I18N_SCOPE, 'task_type_label']),
    icon: CheckboxIcon,
    duration: 2 * 60 * 60,
  },
  // CONFERENCE: {
  //   id: 'conference',
  //   toString: () => t([I18N_SCOPE, 'conference_type_label']),
  //   icon: CameraIcon,
  //   duration: 1 * 60 * 60,
  // },
};

type Event = (Conference & ConferenceExpanded) | (Task & TaskExpanded);

type Course = Event['course'] | null;

interface Props {
  event?: Event;
  course?: Course;
  date?: Date;
  onCreate?: () => void;
  onUpdate?: () => void;
}

const CRUCalendarEvent: React.FC<Props> = ({
  date: dateProp,
  course,
  event,
  onCreate,
  onUpdate,
}) => {
  const {
    stores: {conferenceStore, taskStore},
  } = useContext(AppContext);

  const [eventType, setEventType] = useState(EventType.TASK);
  const [repeat, setRepeat] = useState(ScheduleItemRepeat.ONE_TIME);
  const [date, setDate] = useState(new Date());
  const [daysDiff, setDaysDiff] = useState(0);

  const duration = useRef(0);
  const dateRef = useRef(new Date());
  const initialDate = useRef(new Date());
  const minDate = useRef(new Date());
  const maxDate = useRef(new Date());
  const uuid = useRef(uuidv4().replace(/-/g, ''));
  const [isCourseSelectorVisible, showCourseSelector, hideCourseSelector] =
    useIsVisible();

  const fields = useRef({
    title: createHtmlInputField('title', {
      validationRule: getTitleSchema(),
    }),
    description: createHtmlInputField<HTMLTextAreaElement>('description', {
      validationRule: getDescriptionSchema(),
    }),
    course: createField<Course>('course', {
      defaultValue: null,
      validationRule: {
        type: 'object',
        messages: {
          required: t([I18N_SCOPE, 'course_required_error_message']),
        },
      },
    }),
  }).current;

  useEffect(() => {
    const dispose = autorun(() => {
      if (fields.course.value) {
        maxDate.current =
          dayjs() > dayjs(datetimeObjToISOString(fields.course.value.end_date))
            ? dayjs().add(1, 'day').toDate()
            : dayjs(
                datetimeObjToISOString(fields.course.value.end_date),
              ).toDate();
      } else {
        maxDate.current = dateRef.current;
      }

      setDaysDiff(
        dayjs(date)
          .startOf('day')
          .diff(dayjs(maxDate.current).endOf('day'), 'day'),
      );
    });

    return dispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDaysDiff(
      dayjs(date)
        .startOf('day')
        .diff(dayjs(maxDate.current).endOf('day'), 'day'),
    );
  }, [date]);

  useEffect(() => {
    if (dateProp) {
      dateRef.current = dateProp;
      setDate(dateRef.current);
    }

    if (course) {
      fields.course.value = course;
    }

    if (event) {
      const typeOfEvent = event._id.split(':')[0].toUpperCase();

      if (typeOfEvent === 'COACH_TASK' || typeOfEvent === 'TASK') {
        setEventType(EventType.TASK);
      } else {
        setEventType(EventType[typeOfEvent]);
      }

      fields.course.value = event.course || course || null;
      fields.title.value = event.title;
      fields.description.value = event.description;

      const startDate = dayjs(datetimeObjToISOString(event.start_date));
      const endDate = dayjs(datetimeObjToISOString(event.end_date));

      duration.current = endDate.diff(startDate, 'second');

      dateRef.current = startDate.toDate();
      setDate(dateRef.current);

      uuid.current = event.uuid;
    } else if (dateRef.current < new Date()) {
      dateRef.current = new Date();
      setDate(dateRef.current);

      minDate.current = dateRef.current;
    }

    if (fields.course.value) {
      minDate.current = dayjs(
        datetimeObjToISOString(fields.course.value.start_date),
      ).toDate();
    } else {
      minDate.current = dateRef.current;
    }

    initialDate.current = dateRef.current;
  }, [
    course,
    dateProp,
    event,
    fields.course,
    fields.description,
    fields.title,
  ]);

  const onRepeatChange = useCallback((newRepeat: IScheduleItemRepeat) => {
    setRepeat(newRepeat);
  }, []);

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = e.currentTarget.value;

    if (dateString) {
      const secs = getSecondsFromMidnight(dateRef.current);

      let newDate = dayjs(dateString);

      const maxDayjsDate = dayjs(maxDate.current);
      const minDayjsDate = dayjs(minDate.current);

      if (newDate > maxDayjsDate) {
        newDate = maxDayjsDate;
      } else if (newDate < minDayjsDate) {
        newDate = minDayjsDate;
      }

      dateRef.current = newDate.startOf('day').add(secs, 'seconds').toDate();
      setDate(dateRef.current);
    }
  };

  const onTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = e.currentTarget.value;

    if (timeString) {
      const [hours, mins] = timeString.split(':');

      dateRef.current = dayjs(dateRef.current)
        .startOf('day')
        .add(parseInt(hours, 10) || 0, 'hours')
        .add(parseInt(mins, 10) || 0, 'minutes')
        .toDate();
      setDate(dateRef.current);
    }
  }, []);

  const onSetButtonPress = () => {
    if (validateFields(fields)) {
      if (!event) {
        if (eventType.id === 'task') {
          createTask();
        }
      }

      if (event && eventType.id === 'task') {
        updateTask();
      } else {
        updateConference();
      }
    }
  };

  const onCourseButtonClick = () => {
    showCourseSelector();
  };

  const onCourseSelect = (courses: Course[]) => {
    const [selectedCourse] = courses;

    fields.course.setValue(selectedCourse);

    minDate.current = dayjs(
      datetimeObjToISOString(fields.course.value!.start_date),
    ).toDate();

    hideCourseSelector();
  };

  const updateConference = async () => {
    const data = {
      title: fields.title.value,
      description: fields.description.value,
      start_date: ISOStringToDatetimeObj(dayjs(dateRef.current).toISOString()),
      end_date: ISOStringToDatetimeObj(
        dayjs(dateRef.current).add(eventType.duration, 'second').toISOString(),
      ),
      expand: conferenceExpand,
    };

    try {
      await conferenceStore.coach.update(event! as Conference, data);

      if (onUpdate) {
        onUpdate();
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle: (
            fields.course.value!.program || {
              title: 'Program not found',
            }
          ).title,
          actionType: 'update',
          targetType: 'conference',
          isIndividual: !!(
            fields.course.value! && fields.course.value!.client_id
          ),
        })
      ) {
        let day = dayjs(dateRef.current).diff(
          dayjs(datetimeObjToISOString(fields.course.value!.start_date)),
          'day',
        );

        if (
          fields.course.value!.edition &&
          day > fields.course.value!.edition.duration - 1
        ) {
          day = fields.course.value!.edition.duration - 1;
        }

        updateConferencesSchedule({
          programId: fields.course.value!.program_id || '',
          conferences: [
            {
              title: fields.title.value,
              description: fields.description.value,
              day,
              time: getSecondsFromMidnight(dateRef.current),
              duration: eventType.duration,
              uuid: uuid.current,
              speaker_ids: (event as Conference).speaker_ids,
            },
          ],
        });
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const createTask = async () => {
    try {
      const {interval} = repeat;

      const batch: RpcRequest[] = [];

      const dates = [dateRef.current];

      let stopDate = ISOStringToDatetimeObj(
        dayjs(dates[0]).add(eventType.duration, 'second').toISOString(),
      );

      if (interval) {
        stopDate = ISOStringToDatetimeObj(
          dayjs(datetimeObjToISOString(fields?.course?.value?.end_date)).format(
            'YYYY-MM-DDTHH:mm:ss',
          ),
        );

        let taskDate = dateRef.current;

        while (dayjs(taskDate).add(interval, 'day') <= dayjs(maxDate.current)) {
          taskDate = dayjs(taskDate).add(interval, 'day').toDate();

          dates.push(taskDate);
        }
      }

      await apiRequest({
        // @ts-ignore
        method: 'coach.tasks.base.create',
        params: {
          title: fields.title.value,
          description: fields.description.value,
          duration: eventType.duration,
          start_date: ISOStringToDatetimeObj(
            dayjs(dates[0]).format('YYYY-MM-DDTHH:mm:ss'),
          ),
          stop_date: stopDate,
          course_id: fields.course.value!._id,
          repeat: repeat.interval
            ? {
                days: repeat.interval,
                type: 'interval',
              }
            : undefined,
          expand: taskExpand,
        },
      });

      if (onCreate) {
        onCreate();
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle: (
            fields.course.value!.program || {title: 'Program not found'}
          ).title,
          actionType: 'create',
          targetType: 'task',
          isIndividual: !!(
            fields.course.value! && fields.course.value!.client_id
          ),
        })
      ) {
        updateTasksSchedule({
          programId: fields.course.value!.program_id || '',
          tasks: batch.map(item => {
            let day = dayjs(
              datetimeObjToISOString(item.params!.start_date),
            ).diff(
              dayjs(datetimeObjToISOString(fields.course.value!.start_date)),
              'day',
            );

            if (
              fields.course.value!.edition &&
              day > fields.course.value!.edition.duration - 1
            ) {
              day = fields.course.value!.edition.duration - 1;
            }

            return {
              title: item.params!.title,
              description: item.params!.description,
              day,
              time: getSecondsFromMidnight(dateRef.current),
              duration: eventType.duration,
              uuid: item.params!.uuid,
            };
          }),
        });
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const updateTask = async () => {
    const data = {
      title: fields.title.value,
      description: fields.description.value,
      start_date: ISOStringToDatetimeObj(
        dayjs(dateRef.current).format('YYYY-MM-DDTHH:mm:ss'),
      ),
      end_date: ISOStringToDatetimeObj(
        dayjs(dateRef.current)
          .add(eventType.duration, 'second')
          .format('YYYY-MM-DDTHH:mm:ss'),
      ),
      expand: taskExpand,
    };

    try {
      const tasksToUpdateResult = await apiRequest({
        method: 'coach.tasks.coach.list',
        params: {
          limit: 200,
          query: [
            ['uuid', '==', (event as Task).uuid],
            ['course_id', '==', (event as Task).course_id],
          ],
        },
      });

      await taskStore.updateBatch(
        tasksToUpdateResult._items.map((item: Task) => ({
          entity: item,
          params: data,
        })),
      );

      if (onUpdate) {
        onUpdate();
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle: (
            fields.course.value!.program || {title: 'Program not found'}
          ).title,
          actionType: 'update',
          targetType: 'task',
          isIndividual: !!(
            fields.course.value && fields.course.value.client_id
          ),
        })
      ) {
        let day = dayjs(dateRef.current).diff(
          dayjs(datetimeObjToISOString(fields.course.value!.start_date)),
          'day',
        );

        if (
          fields.course.value!.edition &&
          day > fields.course.value!.edition.duration - 1
        ) {
          day = fields.course.value!.edition.duration - 1;
        }

        updateTasksSchedule({
          programId: fields.course.value!.program_id || '',
          tasks: [
            {
              title: fields.title.value,
              description: fields.description.value,
              day,
              time: getSecondsFromMidnight(dateRef.current),
              duration: eventType.duration,
              uuid: uuid.current,
            },
          ],
        });
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  return (
    <>
      <div className={classNames('CRUCalendarEvent', styles.Component)}>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'title_label'])}:`}</h3>
          <Observer>
            {() => (
              <TextField
                value={fields.title.value}
                onChange={fields.title.onChange}
                error={fields.title.error || ''}
                placeholder={t([I18N_SCOPE, 'title_text_input_placeholder'])}
              />
            )}
          </Observer>
        </div>
        <div
          className={classNames({
            [styles.container]: true,
            [styles.removedContainer]: !!event,
          })}
        >
          <h3>{`${t([I18N_SCOPE, 'repeat_label'])}:`}</h3>
          <div className={styles.repeatOptions}>
            {[
              ScheduleItemRepeat.ONE_TIME,
              ScheduleItemRepeat.DAILY,
              ScheduleItemRepeat.WEEKLY,
              ScheduleItemRepeat.MONTHLY,
            ]
              .filter(item => (Math.abs(daysDiff) || 1) > item.interval)
              .map(item => {
                const isSelected = item.id === repeat.id;

                return (
                  <div
                    onClick={() => onRepeatChange(item)}
                    key={item.id}
                    className={classNames({
                      [styles.repeatOption]: true,
                      [styles.selected]: isSelected,
                    })}
                  >
                    {item.toString()}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.container}>
          <h3>{t([I18N_SCOPE, 'course_label'])}:</h3>
          <div onClick={!event && !course ? onCourseButtonClick : undefined}>
            <Observer>
              {() => (
                <>
                  <div
                    className={classNames({
                      [styles.courseRow]: true,
                      [styles.disabled]: !!event,
                    })}
                  >
                    {fields.course.value ? (
                      <div className={styles.courseTitle}>
                        {(fields.course.value.program || {}).title}
                        <span className={styles.courseDates}>
                          {` (${getCourseDurationString(fields.course.value)})`}
                        </span>
                      </div>
                    ) : (
                      <span className={styles.selectCourse}>
                        {t([I18N_SCOPE, 'select_course_label'])}
                      </span>
                    )}
                    {!event && !course ? <ArrowNextIcon /> : null}
                  </div>
                  {fields.course.error && (
                    <span className={styles.courseError}>
                      {fields.course.error}
                    </span>
                  )}
                </>
              )}
            </Observer>
          </div>
        </div>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'date_label'])}:`}</h3>
          <TextField
            type="date"
            onChange={onDateChange}
            value={dayjs(date).format(DATE_FORMAT)}
            min={dayjs(minDate.current).format(DATE_FORMAT)}
            max={dayjs(maxDate.current).format(DATE_FORMAT)}
          />
        </div>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'time_label'])}:`}</h3>
          <TextField
            type="time"
            onChange={onTimeChange}
            value={dayjs(date).format('HH:mm')}
          />
        </div>
        <div
          className={classNames({
            [styles.container]: true,
            [styles.descriptionContainer]: true,
          })}
        >
          <h3>{`${t([I18N_SCOPE, 'description_label'])}:`}</h3>
          <Observer>
            {() => (
              <TextArea
                value={fields.description.value}
                onChange={fields.description.onChange}
                error={fields.description.error || ''}
                placeholder={t([
                  I18N_SCOPE,
                  'description_text_input_placeholder',
                ])}
              />
            )}
          </Observer>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          onClick={onSetButtonPress}
          className={classNames({
            [styles.actionButton]: true,
          })}
        >
          {event
            ? t([I18N_SCOPE, 'set_button', 'update']).conference
            : t([I18N_SCOPE, 'set_button', 'create'])}
        </Button>
      </div>
      <SelectCourseModal
        isOpen={isCourseSelectorVisible}
        title={t([I18N_SCOPE, 'select_program_title'])}
        onSelect={onCourseSelect}
        // @ts-ignore
        initialSelection={fields.course.value ? [fields.course.value] : []}
        onRequestClose={hideCourseSelector}
      />
    </>
  );
};

export default React.memo(CRUCalendarEvent);
