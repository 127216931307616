/**
 * Meal component controller.
 */
//import React from 'react'
//import {createEventNormalizer} from '@yourcoach/shared/utils/events';

import {formatDate} from '@yourcoach/shared/utils/datetime';

import type {Props} from './types';

export const useController = (meal: Props['meal']) => {
  const dataString = meal.ingredients
    .map(i => `${i.name} - ${i.qty}${i.unit}`)
    .join(', ');

  const time = formatDate(meal.date, {
    useUTC: true,
    customFormat: 'LT',
  });

  return {dataString, time};
};
