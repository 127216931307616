import type {FC} from 'react';
import React from 'react';

import IconDollarSignSVG from '@yourcoach/shared/assets/icons/dollar-sign.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
  viewBox?: string;
}

const IconDollarSign: FC<Props> = ({
  height = '100%',
  width = '100%',
  customClass = '',
  viewBox = '6 5 22 22',
}) => {
  return (
    <IconDollarSignSVG
      className={`${customClass}`}
      width={height}
      height={width}
      fill="#819AA9"
      viewBox={viewBox}
    />
  );
};

export default IconDollarSign;
