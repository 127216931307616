import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';

import type {Props as DocumentViewerProps} from './DocumentViewer';
import DocumentViewer from './DocumentViewer';
import styles from './DocumentViewer.module.css';

type Props = Partial<ModalProps> & DocumentViewerProps;

const DocumentViewerModal: React.FC<Props> = ({
  document,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} bodyClassName={styles.modalBody}>
    <DocumentViewer document={document} />
    {children}
  </Modal>
);

export default React.memo(DocumentViewerModal);
