import React, {memo, useCallback, useEffect, useState} from 'react';

import {apiRequest} from '@yourcoach/shared/api';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {
  Questionnaire,
  UserQuestionnaire,
} from '@yourcoach/shared/api/questionnaire';
import {
  getQuestionnaireCurrentQuestionsIndex,
  getQuestionnaireQuestionsCount,
} from '@yourcoach/shared/api/questionnaire';
import {themes} from '@yourcoach/shared/styles/theme';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';
import {Image} from '@yourcoach/shared/uikit/Image';
import {logger} from '@yourcoach/shared/utils/logger';

import {t} from '@src/i18n';
import type {Expanded as QuestionnaireExpanded} from '@src/models/questionnaire';

import styles from './styles.module.scss';

const I18N_SCOPE = 'QuestionnairePostAttachment';

type QuestionnaireT = Questionnaire & QuestionnaireExpanded;

interface Props {
  attachment: QuestionnaireT;
  onPress?: (attachment: QuestionnaireT) => void;
  forCoach: boolean;
  postId: string;
}

type QuestionnaireDataT = {
  title: string;
  description: string;
  avatar: string | undefined;
  status: string | null;
  statusColor: string;
  descriptionColor: string;
};

const QuestionnairePostAttachment: React.FC<Props> = ({
  attachment,
  onPress,
  forCoach,
  postId,
}) => {
  const [questionnaireData, setQuestionnaireData] =
    useState<QuestionnaireDataT>({
      title: attachment.title,
      description: t([I18N_SCOPE, 'retrieving_data_label']),
      avatar: undefined,
      status: null,
      statusColor: themes.light.color.v2.text3,
      descriptionColor: themes.light.color.v2.text1,
    });

  const getQuestionnaireData = useCallback(async () => {
    const data: QuestionnaireDataT = {...questionnaireData};

    const preview = getFileSrc(attachment.avatar, 250).url;

    if (preview) {
      data.avatar = preview;
    }

    const questionsCount = getQuestionnaireQuestionsCount(attachment);

    if (!forCoach) {
      try {
        const result: {
          _items: UserQuestionnaire[];
        } = await apiRequest({
          method: 'client.questionnaires.list',
          params: {
            query: [
              ['questionnaire_id', '==', attachment._id],
              ['resource_id', '==', postId],
            ],
          },
        });

        data.description = t([I18N_SCOPE, 'questions_count'], {
          count: questionsCount,
        });
        data.status = t([I18N_SCOPE, 'status', 'not_started']);
        data.statusColor = themes.light.color.v2.text3;
        data.descriptionColor = themes.light.color.v2.text3;

        if (result._items.length) {
          const [userQuestionnaire] = result._items;

          const currentQuestionsIndex =
            getQuestionnaireCurrentQuestionsIndex(userQuestionnaire);

          if (userQuestionnaire.answered) {
            data.description = `${currentQuestionsIndex}/${questionsCount}`;
            data.status = t([I18N_SCOPE, 'status', 'completed']);
            data.statusColor = themes.light.color.v2.text4;
          } else if (userQuestionnaire.last_answer) {
            data.description = `${currentQuestionsIndex}/${questionsCount}`;
            data.status = t([I18N_SCOPE, 'status', 'in_progress']);
            data.statusColor = themes.light.color.v2.icon6;
          }
        }
      } catch (error) {
        logger.error(error);
      }
    } else {
      data.description = t([I18N_SCOPE, 'questions_count'], {
        count: questionsCount,
      });
    }

    setQuestionnaireData(data);
  }, [attachment, forCoach, postId, questionnaireData]);

  useEffect(() => {
    getQuestionnaireData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPressCb = useCallback(
    () => onPress && onPress(attachment),
    [attachment, onPress],
  );

  return (
    <div className={styles.QuestionnairePostAttachment} onClick={onPressCb}>
      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <Image
            src={questionnaireData.avatar || ''}
            resizeMode="cover"
            placeholder={<AvatarPlaceholder text={questionnaireData.title} />}
          />
        </div>
        <div className={styles.contentContainer}>
          <span className={styles.name}>{questionnaireData.title}</span>
          {questionnaireData.description ? (
            <div className={styles.descriptionContainer}>
              <span
                className={styles.description}
                style={{
                  color: questionnaireData.descriptionColor,
                }}
              >
                {questionnaireData.description}
              </span>
              {questionnaireData.status ? (
                <span
                  className={styles.status}
                  style={{
                    color: questionnaireData.statusColor,
                  }}
                >
                  {questionnaireData.status}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default memo(QuestionnairePostAttachment);
