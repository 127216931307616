import type {FC} from 'react';
import React, {memo, useCallback, useEffect, useMemo} from 'react';

import List from '@src/components/List';

import {getListScrollProps} from '../../getListScrollProps';
import ListItem, {ListItemCheckbox, ListItemTitle} from '../../ListItem';
import {bindIndex} from '../../useSelection';
import {useListEditor} from '../useListEditor';

import styles from './styles.module.css';
import TaskEditor from './TaskEditor';

interface TaskT {
  time: number;
  title: string;
  uuid?: string;
}

interface Props {
  initialTasks: TaskT[];
  value?: TaskT[];
  onChange?: (_: TaskT[]) => void;
}

const TasksListEditor: FC<Props> = ({initialTasks, value, onChange}) => {
  const {
    selection,
    items: tasks,
    makeOnItemChange,
    makeOnBlur,
    onItemClick,
  } = useListEditor<TaskT>(
    value || [],
    initialTasks,
    {title: '', time: 3600 * 8, uuid: ''},
    (x, y) => x.uuid === y.uuid,
    (task, {title, time}) => {
      if (!task.uuid) {
        task.title = title;
      }

      task.time = time;

      return task;
    },
  );

  // Don't pass time button clicks to item click handler
  const onItemClickCb = useCallback(
    (index, event) => {
      if (event.target.tagName !== 'INPUT') {
        onItemClick(index);
      }
    },
    [onItemClick],
  );

  // Pass selected tasks to caller
  useEffect(() => {
    onChange && onChange(selection.map(i => tasks[i]));
  }, [onChange, selection, tasks]);

  return (
    <List customScrollbar {...useMemo(getListScrollProps, [])}>
      {tasks.map((task, index) => {
        const isSelected = selection.includes(index);
        const isFixed = index >= tasks.length - initialTasks.length;

        return (
          <ListItem
            key={'item-' + index}
            isSelected={isSelected && isFixed}
            isEditable={!isFixed}
            size="small"
            className={styles.styledListItem + ' auto'}
            onClick={isFixed ? bindIndex(index, onItemClickCb) : undefined}
          >
            <ListItemTitle>
              <TaskEditor
                text={task.title}
                time={task.time}
                editable={!isFixed}
                isSelected={isSelected}
                onChange={makeOnItemChange(index)}
                onBlur={makeOnBlur(index)}
              />
            </ListItemTitle>
            <ListItemCheckbox
              isSelected={isSelected}
              onClick={bindIndex(index, onItemClick)}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default memo(TasksListEditor);
