import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  flex-direction: column;
  gap: 16px;
`;

const Header = styled(View)``;

const Body = styled(View)`
  flex-direction: column;
  gap: 12px;
`;

const Layout = {
  Container,
  Header,
  Body,
};

export {Layout};
