import type {FC} from 'react';
import React, {memo, useContext, useMemo} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import type {DateTimeObj} from '@yourcoach/shared/api';
import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getChannelInfo} from '@yourcoach/shared/api/channel';
import {isImageAttachment} from '@yourcoach/shared/api/channel/post';
import type {IFile} from '@yourcoach/shared/api/media/file';

import {
  labelChatsChannelsListDocument,
  labelChatsChannelsListEvent,
  labelChatsChannelsListItemFrozenMessage,
  labelChatsChannelsListItemImage,
  labelChatsChannelsListItemMyLastMessage,
  labelChatsChannelsListItemNoMessagesYet,
  labelChatsChannelsListMaterial,
  labelChatsChannelsListUrl,
} from '@src/common/i18n/i18nChannel';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import useChatsListItem from '@src/modules/Chats/hooks/useChatsListItem';

import ChatsContext from '../../context/ChatsContext';
import type {
  ChannelT,
  IChatsLocalStore,
  ICoach,
} from '../../context/useChatsLocalStore';
import {idTypeList, SUPPORTED_EVENTS} from '../../context/useChatsLocalStore';
import useFetchClientMembership from '../../hooks/useFetchClientMembership';
import useFetchHistoryList from '../../hooks/useFetchHistoryList';
import useFetchOngoingHistoryList from '../../hooks/useFetchOngoingHistoryList';

import styles from './../../styles.module.scss';
import ChatsListItemInfo from './ChatsListItemInfo';

interface Props {
  badge?: number;
  channel: ChannelT;
  coachUnrespondedDate: DateTimeObj;
  expandedMode?: boolean;
  isUserCoach: boolean;
  showCoaches?: boolean;
  showLastPost?: boolean;
  showUpdatedDate?: boolean;
}

const ChatsListItem: FC<Props> = ({
  badge = 0,
  channel,
  coachUnrespondedDate,
  expandedMode,
  isUserCoach,
  showCoaches = false,
  showLastPost = false,
  showUpdatedDate = false,
}) => {
  const {
    stores: {currentUserStore, membershipStore},
  } = useContext(AppContext);

  const chatsLocalStore: IChatsLocalStore | null = useContext(ChatsContext);

  const {badgeColor, handleOnClickElement} =
    useChatsListItem(coachUnrespondedDate);

  const {coachChangeDate} = useFetchHistoryList(channel);

  const {tenantTitle, subtenantTitle} = useFetchClientMembership(
    channel,
    isUserCoach,
  );

  const {coachCoverageEndDate} = useFetchOngoingHistoryList(
    channel,
    isUserCoach,
  );

  const hasCourse = !!(
    channel &&
    channel.type === 'course' &&
    channel.resource &&
    channel.resource._id
  );

  // @ts-ignore
  const {title, avatar} = getChannelInfo(channel);

  let userTitleName: string | null = null;

  if (channel.resource && channel.resource._id) {
    if (
      channel.resource.edition &&
      channel.resource.edition!.group_size === 1 &&
      currentUserStore.user &&
      channel.resource.coach_ids &&
      channel.resource.coach_ids.includes(currentUserStore.user._id) &&
      channel.resource.client
    ) {
      userTitleName = channel.resource.client!.name || 'Client not found';
    }
  }

  const ddate = dayjs(datetimeObjToISOString(channel.updated));

  let date = ddate.format('ll');

  const startOfDayDate = dayjs().startOf('day');
  const daysDiff = useMemo(
    () => startOfDayDate.diff(ddate.startOf('day'), 'day'),
    [ddate, startOfDayDate],
  );

  if (daysDiff === 0) {
    date = ddate.format('LT');
  }

  if (daysDiff === 1) {
    date = t('shared.date.yesterday');
  }

  if (daysDiff < 7) {
    date = ddate.format('ll');
  }

  let coaches: ICoach[] = [];
  let currentUserIsCoach: boolean = false;

  if (
    showCoaches &&
    channel &&
    channel.type === 'course' &&
    channel.resource_id &&
    channel.resource
  ) {
    let coachIds: string[] = hasCourse
      ? channel.resource.coach_ids.slice()
      : [];

    currentUserIsCoach =
      !!currentUserStore.user && coachIds.includes(currentUserStore.user._id);

    coachIds.unshift(channel.user_id);

    if (currentUserIsCoach && currentUserStore.user) {
      coachIds.unshift(currentUserStore.user._id);
    }

    coachIds = Array.from(new Set(coachIds));

    coaches = (
      coachIds.map(id => {
        return channel.members.find(item => {
          return item._id === id;
        });
      }) as ICoach[]
    ).filter(Boolean);
  }

  const isIndividual = useMemo(
    () =>
      channel &&
      channel.resource &&
      channel.resource.edition &&
      channel.resource.edition.group_size === 1,
    [channel],
  );

  const isLastEventCourseType = useMemo(() => {
    if (
      channel &&
      channel.resource &&
      channel.resource.last_event &&
      channel.resource.last_event.type
    ) {
      const {type} = channel.resource.last_event;

      return type === 'course_started' || type === 'membership_created';
    }

    return false;
  }, [channel]);

  let isFrozen = false;

  let text = labelChatsChannelsListItemNoMessagesYet();
  let userName = '';

  if (hasCourse) {
    const membership = membershipStore.membershipLookup.get(
      channel.resource_id || '',
    );

    if (membership && membership.status === 'frozen') {
      isFrozen = true;

      userName = '';
      text = labelChatsChannelsListItemFrozenMessage(
        isIndividual ? 'individual' : 'group',
      );
    }
  }

  if (!isFrozen && channel) {
    const hasLastPost = channel.last_post && channel.last_post._id;
    const hasLastEvent =
      hasCourse &&
      channel.resource!.last_event &&
      channel.resource!.last_event._id;

    if (hasLastPost || hasLastEvent) {
      if (
        hasLastPost &&
        (!hasLastEvent ||
          (hasLastEvent &&
            dayjs(datetimeObjToISOString(channel.last_post!.created)) >
              dayjs(
                datetimeObjToISOString(channel.resource!.last_event!.created),
              )))
      ) {
        userName = labelChatsChannelsListItemMyLastMessage();
        text = channel.last_post!.body;

        if (!text) {
          if (channel.last_post!.attachments.filter(Boolean).length) {
            const [attachment] = channel.last_post!.attachments.filter(Boolean);
            const attachmentId =
              (attachment as typeof attachment & {_id?: string})._id ||
              attachment.attachment_id;
            const attachmentType = attachmentId.split(':')[0];

            if (attachmentType === 'file') {
              if (isImageAttachment(attachment as unknown as IFile)) {
                text = labelChatsChannelsListItemImage();
              }

              if (
                (attachment as unknown as IFile).categories.includes('document')
              ) {
                text = labelChatsChannelsListDocument();
              }

              if (
                (attachment as unknown as IFile).categories.includes('website')
              ) {
                text = labelChatsChannelsListUrl();
              }
            }

            if (attachmentType === 'material') {
              text = labelChatsChannelsListMaterial();
            }
          }
        }

        const lastPostIsFromCurrentUser =
          currentUserStore.user &&
          channel.last_post!.user_id === currentUserStore.user._id;

        if (!lastPostIsFromCurrentUser) {
          userName = channel.last_post!.user.name;

          if (
            hasCourse &&
            channel.resource &&
            channel.resource.coach_ids.includes(channel.last_post!.user_id)
          ) {
            userName = channel.last_post!.user.coach_title || '';
          }
        }

        if (
          !hasCourse &&
          channel.user_ids.length === 2 &&
          !lastPostIsFromCurrentUser
        ) {
          userName = '';
        }
      }

      if (
        hasLastEvent &&
        SUPPORTED_EVENTS.includes(channel.resource!.last_event!.type) &&
        (!hasLastPost ||
          (hasLastPost &&
            dayjs(
              datetimeObjToISOString(channel.resource!.last_event!.created),
            ) > dayjs(datetimeObjToISOString(channel.last_post!.created))))
      ) {
        text = labelChatsChannelsListEvent(
          channel.resource!.last_event!.type,
          isLastEventCourseType ? (isIndividual ? 'individual' : 'group') : '',
          {
            userName: (
              channel.resource!.last_event!.actor ||
              channel.resource!.last_event!.user || {name: 'User not found'}
            ).name,
          },
        );
      }
    }
  }

  const getUserAvatars = () => {
    if (process.env.APP_ENV === 'stage') {
      return (
        <div className={styles.usersAvatarContainer}>
          <div className={styles.userAvatar}>
            <OtherUserProfileImg
              avatar={{
                _id: 'oura',
                categories: ['image'],
                src: {
                  original: {
                    filename: 'oura.jpg',
                    url: 'https://play-lh.googleusercontent.com/9Pt4GiNIygQwkcvCb3D_QcN7PprMb15_CLVrSJcj9u0YPU5nGOHIt_ldEQKOKKGW_OE',
                    size: 0,
                    mimetype: 'image/jpg',
                  },
                },
              }}
              title="Oura"
            />
          </div>
        </div>
      );
    }

    if (channel.members && channel.members.length > 0) {
      return (
        <div className={styles.usersAvatarContainer}>
          {channel.members.length > 2 ? (
            <div className={styles.userAvatarNum}>
              <div className={styles.userAvatarMore}>...</div>
            </div>
          ) : null}
          {channel.members.length > 1 ? (
            <div className={styles.userAvatar}>
              <OtherUserProfileImg
                avatar={channel.members[1].avatar}
                title={channel.members[1].name}
              />
            </div>
          ) : null}
          <div className={styles.userAvatar}>
            <OtherUserProfileImg
              avatar={channel.members[0].avatar}
              title={channel.members[0].name}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const getCoachAvatars = () => {
    return (
      <div className={styles.usersAvatarContainer}>
        {coaches.length > 2 ? (
          <div className={styles.userAvatarNum}>
            <div className={styles.userAvatarMore}>...</div>
          </div>
        ) : null}
        {coaches.length > 1 ? (
          <div className={`${styles.userAvatar} ${styles.coachAvatar}`}>
            <OtherUserProfileImg
              avatar={coaches[1].avatar}
              title={coaches[1].name}
            />
          </div>
        ) : null}
        {coaches.length > 0 ? (
          <div className={`${styles.userAvatar} ${styles.coachAvatar}`}>
            <OtherUserProfileImg
              avatar={coaches[0].avatar}
              title={coaches[0].name}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Observer>
      {() => (
        <div
          className={`ChatsListItem ${styles.ChatsListItem} ${
            chatsLocalStore?.selectChannel?._id === channel._id
              ? styles.active
              : ''
          }`}
          onClick={handleOnClickElement(channel)}
        >
          <div className={styles.channelContainer}>
            <div className={styles.imageContainer}>
              <div className={styles.channelAvatar}>
                {
                  <OtherUserProfileImg
                    avatar={avatar}
                    childrenContainerClassName={`${styles.allUsersContainer} ${
                      currentUserIsCoach ? '' : styles.coachUsersContainer
                    }`}
                    expandedMode={expandedMode}
                    getTitleIconIfNoImage={
                      chatsLocalStore?.selectTypeList === idTypeList[0]
                    }
                    isBGGradient
                    isOverlay={!avatar}
                    profileClassName={styles.usersAvatar}
                    title={title}
                  >
                    {currentUserIsCoach ? getUserAvatars() : getCoachAvatars()}
                  </OtherUserProfileImg>
                }
              </div>
            </div>
            <ChatsListItemInfo
              badge={badge}
              badgeColor={badgeColor}
              channel={channel}
              coachChangeDate={coachChangeDate}
              coachCoverageEndDate={coachCoverageEndDate}
              currentUserIsCoach={currentUserIsCoach}
              date={date}
              expandedMode={expandedMode}
              hasCourse={hasCourse}
              isFrozen={isFrozen}
              showLastPost={showLastPost}
              showUpdatedDate={showUpdatedDate}
              subtenantTitle={subtenantTitle}
              tenantTitle={tenantTitle}
              text={text}
              userName={userName}
              userTitleName={userTitleName}
            />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(ChatsListItem);
