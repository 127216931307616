import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

import ModalNew from '@src/components/ModalNew';
import {TabBar} from '@src/components/TabBar';

export const Modal = styled(ModalNew)`
  width: 75vw !important;
  min-width: 400px !important;

  .body {
    padding: 0px;
    height: 75vh !important;
    min-height: 400px !important;
  }

  .header {
    padding-left: 40px;
  }
`;

const STabBar = styled(TabBar)`
  background-color: ${getColor('background2')};
  padding-left: 20px;
`;

export {STabBar as TabBar};
