import styled from 'styled-components';

import CloseIcon from '@yourcoach/shared/assets/icons/secondary/Close.svg';
import {getColor} from '@yourcoach/shared/styles/utils';
import {Text} from '@yourcoach/shared/uikit/Text';

import {ReactModalAdapter} from '@src/v2/components/Modal';

export const Modal = styled(ReactModalAdapter)`
  & .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 3000;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    will-change: opacity;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35vw;
    min-width: 400px;
    max-width: 500px;
    height: 100%;
    height: calc(100% - 80px);
    position: relative;
    top: 40px;
    box-shadow: 0 0 15px rgba(14, 14, 19, 10%);
    border-radius: 50px 0 0;
    transition: transform 200ms ease-in-out;
    background-color: ${getColor('background1')};
    transform: translateX(600px);
    will-change: transform;
    padding: 17px 24px 0;
    outline: none;

    &.ReactModal__Content--after-open {
      transform: translateX(0);
    }
    &.ReactModal__Content--before-close {
      transform: translateX(600px);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  padding: 20px 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.12px;
`;

export const CloseButtonContainer = styled.div`
  height: 34px;
  width: 34px;
  background: ${getColor('background2')};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SCloseIcon = styled(CloseIcon)`
  width: 40px;
  height: 40px;
  fill: ${getColor('icon3')};
`;

export {SCloseIcon as CloseIcon};

export const Content = styled.div`
  flex: 1;
  position: relative;
`;
