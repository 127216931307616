import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {labelEditLocation, labelLocation} from '@src/common/i18n/i18nProfile';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomInput} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import {GOOGLE_KEY} from '@src/config';
import {proxifyUrl} from '@src/utils';
import type {GeocoderResultT, TGoogleResultPlaces} from '@src/utils/geo';
import {searchLocations} from '@src/utils/geo';

import styles from '../styles.module.css';

interface Props {
  returnLocation: (location: GeocoderResultT) => void;
}

interface ILocalStore {
  location: string;
  setLocation(location: string): void;
  locationErr: string;
  setLocationErr(locationErr: string): void;
  timer: number | null;
  setTimer(timer: number): void;
  fetching: boolean;
  setFetching(fetching: boolean): void;
  result: TGoogleResultPlaces[];
  setResult(result: TGoogleResultPlaces[]): void;
  updateInputVal: (val: string) => void;
  getGeo: (val: string) => void;
}

const AddLocation: FC<Props> = ({returnLocation}) => {
  const localStore = useRef(
    observable<ILocalStore>(
      {
        location: '',
        setLocation(location: string) {
          this.location = location;
        },
        locationErr: '',
        setLocationErr(locationErr: string) {
          this.locationErr = locationErr;
        },
        fetching: false,
        setFetching(fetching: boolean) {
          this.fetching = fetching;
        },
        timer: null,
        setTimer(timer: number) {
          this.timer = timer;
        },
        result: [],
        setResult(result: TGoogleResultPlaces[]) {
          this.result = result;
        },
        updateInputVal(val: string) {
          clearTimeout(this.timer);

          this.setLocation(val);
          this.setResult([]);

          if (val && val.length >= 2) {
            this.getGeo(val);
          } else {
            this.setFetching(false);
          }
        },
        getGeo(val: string) {
          this.setFetching(true);

          this.setLocationErr('');

          this.setTimer(
            setTimeout(async () => {
              try {
                const results: TGoogleResultPlaces[] = await searchLocations(
                  val,
                );

                this.setFetching(false);
                this.setResult(results);
              } catch (error) {
                this.setFetching(false);

                this.setLocationErr(error.message);
              }
            }, 1000),
          );
        },
      },
      {
        location: observable,
        setLocation: action,
        locationErr: observable,
        setLocationErr: action,
        fetching: observable,
        setFetching: action,
        timer: observable,
        setTimer: action,
        result: observable,
        setResult: action,
        updateInputVal: action,
        getGeo: action,
      },
    ),
  ).current;

  useEffect(() => {
    return () => {
      if (localStore.timer) {
        clearTimeout(localStore.timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.updateInputVal(event.target.value);
  };

  const handleOnChangeLocation = (item: TGoogleResultPlaces) => {
    return async () => {
      localStore.setFetching(true);

      try {
        const endpoint = [
          'https://maps.googleapis.com/maps/api/place/details/json',
          `?placeid=${item.place_id}&`,
          `key=${GOOGLE_KEY}`,
        ].join('');

        const response = await fetch(proxifyUrl(endpoint));

        const json = (await response.json()) as {
          status: google.maps.places.PlacesServiceStatus;
          result: google.maps.places.PlaceResult;
          error_message?: string;
        };

        localStore.setFetching(false);

        if (json.status === 'OK') {
          const parts = {};

          json.result.address_components!.forEach(element => {
            parts[element.types[0]] = element.long_name;
          });

          const gResult = {
            parts,
            formatted_address: item.description,
            geometry: json.result.geometry,
          } as GeocoderResultT;

          returnLocation(gResult);
        } else {
          throw {
            code: json.status,
            message: json.error_message,
          };
        }
      } catch (error) {
        localStore.setFetching(false);

        getCustomConfirmAlert({
          title: labelErrorOccurred(),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
            },
          ],
        });

        setError(error);
      }
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`AddLocation ${styles.AddLocation}`}>
          <h3>{labelEditLocation()}</h3>
          <br />
          <CustomInput
            type="input"
            label={`${labelLocation()}:`}
            customClassInput={styles.nameInput}
            customClassLabel={`${styles.nameLabel} ${
              localStore.location.length ? styles.activeLink : ''
            }`}
            onChange={handleChangeLocation}
            value={localStore.location}
            id="location"
            name="location"
            // TODO
            // error={localStore.locationErr}
          />
          {localStore.fetching ? (
            <Loader />
          ) : (
            <div className={styles.listContainer}>
              {localStore.result.length > 0 && (
                <div className={styles.listContainerTitle}>
                  <h4>Confirm your location</h4>
                </div>
              )}
              <div className={styles.list}>
                <div className={styles.scrollListContainer}>
                  <Scrollbar
                    className={`scrollbar ${styles.mainSectionContainer}`}
                    noScrollX
                    wrapperProps={{className: 'wrapper'}}
                    trackYProps={{className: 'trackY'}}
                    thumbYProps={{className: 'thumbY'}}
                    trackXProps={{className: 'trackX'}}
                    thumbXProps={{className: 'thumbY'}}
                  >
                    <TransitionGroup className="todo-list" component={null}>
                      {localStore.result.map(
                        (item: TGoogleResultPlaces, index) => {
                          const delay = 100 * index;

                          return (
                            <CSSTransition
                              appear={true}
                              key={item.place_id}
                              timeout={{
                                appear: 0,
                                enter: delay,
                                exit: 0,
                              }}
                              classNames={{
                                enterActive: 'noWisible',
                                enterDone: 'animate__fadeIn',
                              }}
                            >
                              <div
                                className={`animate__animated ${styles.listItem}`}
                                onClick={handleOnChangeLocation(item)}
                              >
                                {item.description}
                              </div>
                            </CSSTransition>
                          );
                        },
                      )}
                    </TransitionGroup>
                  </Scrollbar>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(AddLocation);
