import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  colorClass?: string;
  containerClass?: string;
}

const ColorWrapper: FC<Props> = ({
  children,
  className = '',
  colorClass = '',
  containerClass = '',
}) => {
  return (
    <div className={`ColorWrapper ${className}`}>
      <div className={`colorContainer ${colorClass}`}>
        <div className={`container ${containerClass}`}>{children}</div>
      </div>
    </div>
  );
};

export default memo(ColorWrapper);
