import React, {memo, useRef} from 'react';
import type {ScrollbarProps} from 'react-scrollbars-custom';
import Scrollbar from 'react-scrollbars-custom';

import styles from './List.module.css';

interface Props extends ScrollbarProps {
  customScrollbar?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const List: React.FC<Props> = ({customScrollbar, children, ...rest}) => {
  const scrollbarProps = useRef<ScrollbarProps>({
    wrapperProps: {
      style: {
        inset: '0 10px 0 0',
      },
    },
    trackYProps: {
      style: {
        width: 4,
      },
    },
    thumbYProps: {},
    trackXProps: {style: {height: 4}},
    thumbXProps: {},
  }).current;

  return customScrollbar ? (
    // @ts-ignore
    <Scrollbar {...scrollbarProps} {...rest}>
      <ul className={styles.List}>{children}</ul>
    </Scrollbar>
  ) : (
    <ul className={styles.List}>{children}</ul>
  );
};

export default memo(List);
