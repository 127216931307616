import React, {useEffect, useMemo, useRef, useState} from 'react';

import Color from 'color';
import randomcolor from 'randomcolor';
import {length} from 'stringz';

import styles from './AvatarPlaceholder.module.css';

export interface Props {
  name?: string;
}

const AvatarPlaceholder: React.FC<Props> = ({name}) => {
  const [size, setSize] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setSize(ref.current.getBoundingClientRect().height);
    }
  }, []);

  const letters = useMemo(
    () =>
      name
        ? name
            .replace(/[@#!$%^&*()+|~=`{}[\]:";'<>?,./\d]/g, '')
            // eslint-disable-next-line regexp/strict
            .split(/[\s-_]/)
            .filter(Boolean)
            .slice(0, 2)
            .filter(Boolean)
            .map(part => (length(part) > 1 ? part[0] : part).toUpperCase())
        : '...',
    [name],
  );

  const lettersStyle = useMemo(
    () => ({
      fontSize: `${size * 0.375}px`,
      lineHeight: `${size}px`,
    }),
    [size],
  );

  const colors = useMemo(() => {
    const firstColor = name
      ? randomcolor({
          seed: name,
        })
      : '#1FB088';

    const secondColor = Color(firstColor)
      .rotate(-20)
      .darken(0.2)
      .rgb()
      .string();

    return [firstColor, secondColor];
  }, [name]);

  return (
    <div
      ref={ref}
      style={useMemo(
        () => ({
          background: `linear-gradient(45deg, ${colors[0]}, ${colors[1]})`,
        }),
        [colors],
      )}
      className={`AvatarPlaceholder ${styles.AvatarPlaceholder}`}
    >
      {size ? (
        <span className={styles.letters} style={lettersStyle}>
          {letters}
        </span>
      ) : null}
    </div>
  );
};

export default React.memo(AvatarPlaceholder);
