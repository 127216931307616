import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {runInAction} from 'mobx';

import IconClose from '@src/components/icons/IconClose/IconClose';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';

import InvitesContext from '../context/InvitesContext';
import type {ILocalStoreInvite} from '../context/InvitesLocalStore';

import styles from './../styles.module.css';

interface Props {}

const SendsElements: FC<Props> = () => {
  const storeInvites: ILocalStoreInvite = useContext(InvitesContext)!;
  const handleDelUser = (index: number) => {
    return () => {
      _removeToItem(index);
    };
  };
  const handleDelEmail = (index: number) => {
    return () => {
      runInAction(() => {
        storeInvites.toItemEmail.splice(index, 1);
      });
    };
  };

  const _removeToItem = (index: number) => {
    const item = storeInvites.toItems[index];

    if (item.fromSearch) {
      storeInvites.usersStore!.addItem(item);
    } else {
      storeInvites.initialUsersStore!.addItem(item);
    }

    runInAction(() => {
      storeInvites.toItems.splice(index, 1);

      storeInvites.toItemsWidths.splice(index, 1);
    });
  };

  return (
    <Observer>
      {() => (
        <div className={`SendsElements ${styles.SendsElements}`}>
          <div className={styles.containerBubbles}>
            {storeInvites.toItems?.slice().map((userFollower, index) => (
              <div
                onClick={handleDelUser(index)}
                className={styles.userContainer}
                key={userFollower._id}
              >
                <div className={styles.userAvatar}>
                  <OtherUserProfileImg
                    avatar={userFollower.client.avatar}
                    title={userFollower.client.name}
                  />
                </div>
                <div className={styles.userName}>
                  {userFollower.client.name}
                </div>
                <div className={styles.closeBut}>
                  <IconClose />
                </div>
              </div>
            ))}
            {storeInvites.toItemEmail.map((email, index) => (
              <div
                key={email}
                className={styles.emailContainer}
                onClick={handleDelEmail(index)}
              >
                <div className={styles.userName}>{email}</div>
                <div className={styles.closeBut}>
                  <IconClose />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SendsElements);
