/**
 * Styles for the AttachedPhotoIcon component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {getColor, getThemeSize} from '@yourcoach/shared/styles/utils';

const container = css`
  position: relative;
  width: ${getThemeSize('icon.l')}px;
  height: ${getThemeSize('icon.l')}px;
  border-radius: ${getThemeSize('borderRadius.m')}px;
  background-color: ${getColor('global1')};
`;

export const containerWeb = css`
  box-sizing: border-box;
  ${container}
`;

export const containerNative = css`
  ${container}
`;
