import React, {useCallback} from 'react';
import ReactModal from 'react-modal';

import styled from 'styled-components';

import CloseIcon from '@yourcoach/shared/assets/icons/close.svg';
import {getColor} from '@yourcoach/shared/styles/utils';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import * as S from './styles';
import type {Props} from './types';

ReactModal.setAppElement('#root');

export const ReactModalAdapter: React.FC<Props> = ({
  disableBodyScroll,
  title,
  children,
  ...rest
}: Props) => {
  const contentClassName = 'content';
  const overlayClassName = 'overlay';

  const onAfterOpen = useCallback(
    (...args) => {
      if (disableBodyScroll) {
        document.body.style.overflow = 'hidden';
      }

      if (rest.onAfterOpen) {
        rest.onAfterOpen(...args);
      }
    },
    [disableBodyScroll, rest],
  );

  const onAfterClose = useCallback(() => {
    if (disableBodyScroll) {
      document.body.style.overflow = 'auto';
    }

    if (rest.onAfterClose) {
      rest.onAfterClose();
    }
  }, [disableBodyScroll, rest]);

  const onCloseButtonClick: GetComponentProps<
    typeof S['CloseButton']
  >['onClick'] = useCallback(
    e => {
      rest.onRequestClose?.(e.event.DOMEvent!);
    },
    [rest],
  );

  return (
    <ReactModal
      closeTimeoutMS={200}
      {...rest}
      // @ts-ignore
      portalClassName={rest.className}
      overlayClassName={overlayClassName}
      className={contentClassName}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    >
      <>
        {title && (
          <S.Header>
            {typeof title === 'function' ? title() : title}
            <S.CloseButton onClick={onCloseButtonClick}>
              <CloseIcon />
            </S.CloseButton>
          </S.Header>
        )}
        {children}
      </>
    </ReactModal>
  );
};

export const StyledModal = styled(ReactModalAdapter)`
  & .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    color: ${getColor('text1')};
    background-color: ${getColor('background1')} !important;
    padding: 0 20px;
    margin: 20px;
    border-radius: 20px;
    outline: none;
    max-width: calc(100vw - (20px * 2));
    max-height: calc(100vh - (20px * 2));
    overflow-y: scroll;
    overflow-x: hidden;

    transform: scale(0.9);
    transition: transform 200ms ease-in-out;

    &.ReactModal__Content--after-open {
      transform: scale(1);
    }
    &.ReactModal__Content--before-close {
      transform: scale(0.9);
    }
  }
`;
