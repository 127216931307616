import type {FC} from 'react';
import React, {memo} from 'react';

import classNames from 'classnames';

import CheckSVG from '@yourcoach/shared/assets/icons/check.svg';

import styles from './styles.module.css';

export const ListItemTitle = ({children, ...props}) => {
  return (
    <div className={styles.title} {...props}>
      {children}
    </div>
  );
};

export const ListItemIcon = ({children, ...props}) => {
  return (
    <div className={styles.icon} {...props}>
      {children}
    </div>
  );
};

export const ListItemCheckbox = ({isSelected, onClick}) => {
  return (
    <div onClick={onClick} className={styles.checkMark + ' mark'}>
      {isSelected ? <CheckSVG /> : null}
    </div>
  );
};

export const ListItemRadio = ({isSelected, onClick}) => (
  <div
    onClick={onClick}
    className={classNames(styles.radioMark, 'mark ', {
      [styles.selected]: isSelected,
    })}
  />
);

interface Props {
  isSelected?: boolean;
  size?: 'small';
  isEditable?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  onClick?: React.MouseEventHandler;
}

const ListItem: FC<Props> = ({
  isSelected,
  size,
  isEditable,
  className,
  onClick,
  children,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      {...props}
      className={classNames(styles.ListItem, className || '', {
        [styles.selected]: isSelected,
        [styles.editable]: isEditable,
        [styles.small]: size === 'small',
      })}
    >
      {children}
    </div>
  );
};

export default memo(ListItem);
