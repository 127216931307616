import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {Helmet} from 'react-helmet';
import type {RouteComponentProps} from 'react-router-dom';
import {useHistory, useParams} from 'react-router-dom';

import classNames from 'classnames';
import {reaction, toJS} from 'mobx';

import type {RpcRequest} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Goal} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition, Program} from '@yourcoach/shared/api/program';
import {FULL_PORTION} from '@yourcoach/shared/api/program';
import ArrowBackIcon from '@yourcoach/shared/assets/icons/arrow-back.svg';
import ArrowNextIcon from '@yourcoach/shared/assets/icons/arrow-next.svg';
import {ucfirst} from '@yourcoach/shared/utils/format';
import {logger} from '@yourcoach/shared/utils/logger';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import useLeaveBlocker from '@src/hooks/useLeaveBlocker';
import {t} from '@src/i18n';
import LogoOnlyLayout from '@src/layouts/LogoOnly';
import type {ScheduleMaterial} from '@src/modules/materials/utils';
import type {Expanded as ProgramExpanded} from '@src/modules/programs/utils';
import {expand as programExpand} from '@src/modules/programs/utils';

import {PathBuilderService} from '../../v2/services/PathBuilderService';

import createProgramFlow from './utils/createProgram';
import updateProgramFlow from './utils/updateProgram';
import styles from './styles.module.css';
import type {Tab} from './useTabs';
import useTabs from './useTabs';

export const I18N_SCOPE = 'shared.CRUProgram';

const NO_PROGRAM_ID = 'program:000000000000000000000000';

export type ProgramT = Program &
  Omit<ProgramExpanded, 'avatar' | 'background'> & {
    avatar?: IFile | File;
    editions: (
      | (Edition & {
          contract?: IFile | File;
          materials: ScheduleMaterial[];
        })
      | undefined
    )[];
  };

interface NavigationParams {
  programId?: string;
  clonedProgramId?: string;
}

type Props = RouteComponentProps<NavigationParams>;

const CRUProgramPage: React.FC<Props> = () => {
  const {
    stores: {programStore, currentUserStore},
  } = useContext(AppContext);

  const {programId, clonedProgramId} = useParams<NavigationParams>();

  const history = useHistory();

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const [isFetching, setIsFetching] = useState(
    !!(programId || clonedProgramId),
  );
  const [program, setProgram] = useState<ProgramT | null>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const tabIndexRef = useRef(tabIndex);
  const programRef = useRef<ProgramT>();
  const originalProgram = useRef<ProgramT>();

  const goalsStore = useRef(
    createCollectionStore<Goal>({
      method: 'coach.goals.unbound.list',
      params: {
        query: [
          ['course_id', '==', null],
          ['program_id', '==', clonedProgramId || programId || NO_PROGRAM_ID],
        ],
        sort: [['sort_order', 1]],
      },
    }),
  ).current;

  const originalGoals = useRef<Goal[]>([]);

  const {tabs, refs} = useTabs(program, tabIndex, goalsStore);

  const isCreatingMode = useMemo(() => !programId, [programId]);
  const programHasEditions = useMemo(
    () =>
      program &&
      program._id &&
      !!(program && program.editions && program.editions.length),
    [program],
  );

  const isCreatingUI = useMemo(
    () => isCreatingMode || !programHasEditions,
    [isCreatingMode, programHasEditions],
  );

  const fetchProgram = useCallback(async () => {
    try {
      if (!programId && !clonedProgramId) {
        return;
      }

      setIsFetching(true);

      let fetchedProgram = (await programStore.coach.fetch({
        _id: programId || clonedProgramId,
        expand: programExpand,
      })) as ProgramT;

      const coaches: ProgramT['coaches'] = {};

      /**
       * old programs were created with lower case role "coach"
       * but the backend creates coaches with "Coach" role
       * it causes that we block leave from the page because "coach" !== "Coach"
       */
      Object.keys(fetchedProgram.coaches).forEach(userId => {
        coaches[userId] = {
          ...fetchedProgram.coaches[userId],
          role: ucfirst(fetchedProgram.coaches[userId].role),
        };
      });

      fetchedProgram.coaches = coaches;

      if (clonedProgramId) {
        fetchedProgram = {
          ...fetchedProgram,
          _id: undefined,
          revision: undefined,
          title: `[CLONE] ${fetchedProgram.title}`,
          coaches: {
            [currentUserStore.user!._id]: {
              role: 'Coach',
              portion: FULL_PORTION,
              sign: null,
              signed: null,
              resigned: null,
            },
          },
          expanded_coaches: [currentUserStore.user!],
        } as unknown as ProgramT;
      }

      setTabIndex(0);
      setProgram(fetchedProgram);

      originalProgram.current = JSON.parse(
        JSON.stringify(fetchedProgram),
      ) as ProgramT;

      programRef.current = fetchedProgram;

      setIsFetching(false);
    } catch (error) {
      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clonedProgramId, history, programId, programStore.coach]);

  useEffect(() => {
    fetchProgram();
  }, [fetchProgram]);

  useEffect(() => {
    const dispose = reaction(
      () => goalsStore.isLoaded,
      isLoaded => {
        if (isLoaded) {
          originalGoals.current = JSON.parse(
            JSON.stringify(goalsStore.items.slice()),
          );

          dispose();
        }
      },
    );

    return () => {
      dispose();

      goalsStore.clear();
    };
  }, [goalsStore]);

  useEffect(() => {
    tabIndexRef.current = tabIndex;
  }, [tabIndex]);

  const getProgramDataFromCurrentTab = useCallback(
    async (direction?: 'back' | 'next') => {
      const currentTab = tabs[tabIndexRef.current];

      if (!refs[currentTab.key].current) {
        return false;
      }

      const tabProgramData = await refs[currentTab.key].current!.getData(
        direction,
      );

      if (!tabProgramData) {
        return false;
      }

      const newProgram = {
        ...programRef.current,
        ...tabProgramData,
      } as ProgramT;

      programRef.current = newProgram;

      setProgram(newProgram);

      return true;
    },
    [refs, tabs],
  );

  const hasUnsavedChanges = useCallback(async () => {
    await getProgramDataFromCurrentTab();

    return !!(
      originalProgram.current &&
      (!isEqual(programRef.current, originalProgram.current) ||
        !isEqual(goalsStore.items.slice(), originalGoals.current.slice()))
    );
  }, [getProgramDataFromCurrentTab, goalsStore.items]);

  useLeaveBlocker(
    t([I18N_SCOPE, 'has_unsaved_changes_message']),
    hasUnsavedChanges,
  );

  const jumpTo = useCallback(
    async (index: number) => {
      if (index < 0 || index > tabs.length - 1) {
        return;
      }

      setTabIndex(index);
    },
    [tabs.length],
  );

  const updateGoalsIfNeeded = useCallback(
    async (forProgramId: string) => {
      const goals = goalsStore.items.slice();

      const batch: RpcRequest[] = [];

      const logEvents: string[] = [];

      (clonedProgramId ? [] : originalGoals.current).forEach(goal => {
        const index = goals.findIndex(item => item._id === goal._id);

        if (index >= 0) {
          if (!isEqual(toJS(goal), toJS(goals[index]))) {
            const goalToUpdate = goals[index];

            if (!logEvents.includes('program_goals_updated')) {
              logEvents.push('program_goals_updated');
            }

            batch.push({
              method: 'coach.goals.unbound.update',
              params: {
                _id: goalToUpdate._id,
                title: goalToUpdate.title,
                description: goalToUpdate.description,
                is_client_managed: goalToUpdate.is_client_managed,
              },
            });
          }

          goals.splice(index, 1);
        } else {
          if (!logEvents.includes('program_goals_deleted')) {
            logEvents.push('program_goals_deleted');
          }

          batch.push({
            method: 'coach.goals.unbound.delete',
            params: {
              _id: goal._id,
            },
          });
        }
      });

      if (goals.length) {
        logEvents.push('program_goals_created');

        const maxSortOrder = Math.max.apply(
          Math,
          goalsStore.items.slice().map(goal => (goal as Goal).sort_order || 0),
        );

        goals.forEach((goal, i) => {
          const order = i * 10;
          const _order = (i + 1) * 10;

          batch.push({
            method: 'coach.goals.unbound.create',
            params: {
              parent_goal_id: goal._id.includes('goal') ? goal._id : null,
              program_id: forProgramId,
              title: goal.title,
              description: goal.description,
              is_client_managed: goal.is_client_managed,
              sort_order: maxSortOrder ? maxSortOrder + _order : order,
            },
          });
        });
      }

      if (batch.length) {
        await apiRequest({batch});

        logEvents.forEach(eventName => {
          logger.event(eventName);
        });
      }
    },
    [clonedProgramId, goalsStore.items],
  );

  const createProgram = useCallback(async () => {
    try {
      showOverlay();

      const createdProgram = await createProgramFlow(programRef.current!);

      const lastEdition =
        createdProgram.editions[createdProgram.editions.length - 1]!;

      await updateGoalsIfNeeded(createdProgram._id);

      logger.event('program_created', {
        is_free: lastEdition.payment_plan === null ? 'yes' : 'no',
        coaches: createdProgram.coach_ids.length,
        with_cocoaches: createdProgram.coach_ids.length > 1 ? 'yes' : 'no',
        is_individual: lastEdition.group_size === 1 ? 'yes' : 'no',
      });

      hideOverlay();

      history.replace(
        `/coaches/${
          currentUserStore.user!.slug || currentUserStore.user!._id
          // }/programs/${createdProgram.slug || createdProgram._id}`,
        }/${createdProgram.slug || createdProgram._id}`,
      );
    } catch (error) {
      hideOverlay();

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
          },
        ],
      });
    }
  }, [
    currentUserStore.user,
    hideOverlay,
    history,
    showOverlay,
    updateGoalsIfNeeded,
  ]);

  const updateProgram = useCallback(async () => {
    try {
      const updatedProgram = await updateProgramFlow(
        originalProgram.current!,
        programRef.current!,
      );

      await updateGoalsIfNeeded(updatedProgram._id);

      logger.event('program_updated');

      // Prevent leave blocking
      originalProgram.current = programRef.current;
      originalGoals.current = goalsStore.items.slice();

      hideOverlay();

      // history.replace(
      //   `/coaches/${
      //     currentUserStore.user!.slug || currentUserStore.user!._id
      //   }/programs/${updatedProgram!.slug || updatedProgram!._id}`,
      // );

      const practiceSlug = currentUserStore.user!.slug;
      const practiceId = currentUserStore.user!._id;
      const programSlug = updatedProgram!.slug;
      const programId = updatedProgram!._id;

      history.replace(
        PathBuilderService.toProgram(
          {slug: practiceSlug, id: practiceId},
          {slug: programSlug, id: programId},
        ),
      );
    } catch (error) {
      hideOverlay();

      if (error.code === 'isnt_provider_coaches') {
        getCustomConfirmAlert({
          title: t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'header']),
          message: t(
            [I18N_SCOPE, 'isnt_provider_coaches_popup', 'description'],
            {
              coaches: programRef
                .current!.expanded_coaches.filter(coach => !coach.is_provider)
                .map(coach => coach.name)
                .join(', '),
            },
          ),
          buttons: [
            {
              label: t([
                I18N_SCOPE,
                'isnt_provider_coaches_popup',
                'success_button',
              ]),
              type: 'confirm',
              onClick: () => {
                const completeTabIndex = tabs.findIndex(
                  tab => tab.key === 'complete',
                );

                setTimeout(() => {
                  jumpTo(completeTabIndex);
                });
              },
            },
            {
              label: t([
                I18N_SCOPE,
                'isnt_provider_coaches_popup',
                'cancel_button',
              ]),
            },
          ],
        });

        return;
      }

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
          },
        ],
      });
    }
  }, [
    currentUserStore.user,
    goalsStore.items,
    hideOverlay,
    history,
    jumpTo,
    tabs,
    updateGoalsIfNeeded,
  ]);

  const onMainNextButtonClick = useCallback(async () => {
    if (!(await getProgramDataFromCurrentTab('next'))) {
      return;
    }

    if (isCreatingUI) {
      if (tabIndex === tabs.length - 1) {
        if (!isCreatingMode) {
          updateProgram();
        } else {
          createProgram();
        }

        return;
      }
    } else {
      updateProgram();

      return;
    }

    jumpTo(tabIndex + 1);
  }, [
    createProgram,
    updateProgram,
    getProgramDataFromCurrentTab,
    isCreatingMode,
    isCreatingUI,
    jumpTo,
    tabIndex,
    tabs.length,
  ]);

  const onNextButtonClick = useCallback(async () => {
    if (!(await getProgramDataFromCurrentTab('next'))) {
      return;
    }

    jumpTo(tabIndex + 1);
  }, [getProgramDataFromCurrentTab, jumpTo, tabIndex]);

  const onPrevButtonClick = useCallback(async () => {
    if (!(await getProgramDataFromCurrentTab('back'))) {
      return;
    }

    jumpTo(tabIndex - 1);
  }, [getProgramDataFromCurrentTab, jumpTo, tabIndex]);

  const onTabClick = useCallback(
    async (tab: Tab) => {
      const clickedTabIndex = tabs.findIndex(item => item.key === tab.key);

      if (
        !(await getProgramDataFromCurrentTab(
          clickedTabIndex > tabIndex ? 'next' : 'back',
        ))
      ) {
        return;
      }

      jumpTo(clickedTabIndex);
    },
    [getProgramDataFromCurrentTab, jumpTo, tabIndex, tabs],
  );

  return (
    <LogoOnlyLayout
      v2
      withBackButton={false}
      contentClassName={styles.container}
    >
      <Helmet
        title={t([I18N_SCOPE, 'title', isCreatingMode ? 'create' : 'update'])}
      />
      <h1 className={styles.title}>
        {t([I18N_SCOPE, 'title', isCreatingMode ? 'create' : 'update'])}
      </h1>
      {isFetching ? (
        <div className={styles.loadingSpinnerContainer}>
          <Loader />
          <p className={styles.text}>{t([I18N_SCOPE, 'loading_message'])}</p>
        </div>
      ) : (
        <>
          <nav className={styles.tabs}>
            {tabs.map((tab, i, itself) => {
              const isActive = i <= tabIndex;
              const isCurrent = i === tabIndex;
              const isLast = i === itself.length - 1;

              return (
                <div
                  key={tab.key}
                  className={classNames(
                    styles.tab,
                    isActive && styles.active,
                    !isCurrent && styles.notCurrent,
                    isLast && styles.last,
                    !isCreatingUI && styles.clickable,
                  )}
                  onClick={!isCreatingUI ? () => onTabClick(tab) : undefined}
                >
                  {tab.title}
                </div>
              );
            })}
          </nav>
          <section className={styles.content}>{tabs[tabIndex].content}</section>
          <footer className={styles.footer}>
            {tabIndex > 0 ? (
              <Button
                onClick={onPrevButtonClick}
                className={classNames(styles.button)}
              >
                <ArrowBackIcon />
                {t([I18N_SCOPE, 'back_button'])}
              </Button>
            ) : null}
            <Button
              onClick={onMainNextButtonClick}
              className={classNames(styles.button, styles.main)}
            >
              {t([
                I18N_SCOPE,
                'main_next_button',
                isCreatingUI
                  ? tabIndex === tabs.length - 1 && !isCreatingMode
                    ? 'update'
                    : `create${tabIndex === tabs.length - 1 ? '_last' : ''}`
                  : 'update',
              ])}
              {tabIndex !== tabs.length - 1 && isCreatingMode ? (
                <ArrowNextIcon />
              ) : null}
            </Button>
            {!isCreatingUI && tabIndex < tabs.length - 1 ? (
              <Button
                onClick={onNextButtonClick}
                className={classNames(styles.button)}
              >
                {t([I18N_SCOPE, 'next_button'])}
                <ArrowNextIcon />
              </Button>
            ) : null}
          </footer>
          {overlayIsVisible ? (
            <div className="overlay">
              <Loader />
            </div>
          ) : null}
        </>
      )}
    </LogoOnlyLayout>
  );
};

export default React.memo(CRUProgramPage);
