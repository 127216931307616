import React, {useCallback, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import type {Membership} from '@yourcoach/shared/api/membership';
import ProfileIcon from '@yourcoach/shared/assets/icons/profile.svg';
import SearchIcon from '@yourcoach/shared/assets/icons/search.svg';
import {SessionNotesStore} from '@yourcoach/shared/stores/sessionNotes/SessionNotes';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import {t} from '@src/i18n';

import {CRUSessionNoteModal} from '../../CRUSessionNoteModal';
import {SessionNotesModal} from '../../SessionNotesModal';

import * as S from './styles';

const I18N_SCOPE = 'AddSessionNote.by_name_tab';

export const ByNameTab: React.FC = () => {
  const sessionNotesStore = useRef(new SessionNotesStore({limit: 50})).current;

  useEffect(() => {
    sessionNotesStore.fetch();

    return () => {
      sessionNotesStore.dispose();
    };
  }, [sessionNotesStore]);

  const findNotesCount = (coach_ids: string[], membership: Membership) => {
    return coach_ids
      .map(user => membership.counters.session_notes[user])
      .reduce((a, b) => a + b, 0);
  };

  const onNoteButtonClick: GetComponentProps<
    typeof S['ListItem']
  >['onNoteButtonClick'] = useCallback(membership => {
    const sessionNotesCount = findNotesCount(
      membership.counters.session_notes.coach_ids,
      membership,
    );

    const props = {
      userId: membership.user_id,
      courseId: membership.course_id,
      user: membership.user,
      program: membership.program,
      course: membership.course,
    };

    NiceModal.show(
      sessionNotesCount ? SessionNotesModal : CRUSessionNoteModal,
      props,
    );
  }, []);

  const onFetchMoreInViewChange = useCallback(
    isInView => {
      if (isInView) {
        sessionNotesStore.fetchMore();
      }
    },
    [sessionNotesStore],
  );

  const onQueryChange: GetComponentProps<
    typeof S['SearchTextInput']
  >['onChange'] = useCallback(
    e => {
      sessionNotesStore.setQuery(e.currentTarget.value);
    },
    [sessionNotesStore],
  );

  return (
    <S.Container>
      <Observer>
        {() => (!sessionNotesStore.listStore.isLoaded ? <Loader /> : null)}
      </Observer>
      <Observer>
        {() =>
          sessionNotesStore.listStore.isLoaded &&
          sessionNotesStore.listStore.items.length > 5 ? (
            <S.SearchTextInput
              placeholder={t([I18N_SCOPE, 'search_placeholder'])}
              onChange={onQueryChange}
            />
          ) : null
        }
      </Observer>
      <Observer>
        {() => (sessionNotesStore.isSearching ? <Loader size={50} /> : null)}
      </Observer>
      <Observer>
        {() =>
          sessionNotesStore.computedStore.hasItems ? (
            <>
              <S.ListHeader>{t([I18N_SCOPE, 'clients_label'])}</S.ListHeader>
              <S.List customScrollbar>
                {sessionNotesStore.computedStore.items.map(item => (
                  <S.ListItem
                    item={item}
                    key={item._id}
                    onNoteButtonClick={onNoteButtonClick}
                  />
                ))}
                {/* @ts-ignore */}
                <InView
                  as="div"
                  threshold={0}
                  onChange={onFetchMoreInViewChange}
                >
                  <Observer>
                    {() =>
                      sessionNotesStore.listStore.hasMore ? (
                        <Loader size={50} />
                      ) : null
                    }
                  </Observer>
                </InView>
              </S.List>
            </>
          ) : null
        }
      </Observer>
      <Observer>
        {() =>
          sessionNotesStore.computedStore.isEmpty ? (
            <S.NoResultsContainer>
              <NoResultsHeader
                text={
                  sessionNotesStore.query
                    ? t([I18N_SCOPE, 'no_search_results'])
                    : t([I18N_SCOPE, 'no_results'])
                }
                icon={sessionNotesStore.query ? SearchIcon : ProfileIcon}
              />
            </S.NoResultsContainer>
          ) : null
        }
      </Observer>
    </S.Container>
  );
};
