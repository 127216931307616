import type {
  ApiRpcRequestParams,
  RpcRequest as IRpcRequest,
  RpcRequest,
} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';

import {linkExpand} from '../context/useLibraryLocalStore';

export interface IMediaLinkListObj {
  method?: string;
  params?: ApiRpcRequestParams;
  withAuth?: boolean;
  withCount?: boolean;
  observableFields?: string[];
}

export const getMediaLinksListObj: (folderId: string | null) => RpcRequest = (
  folderId = null,
) => {
  const LIMIT = 300;

  return {
    method: 'media.links.list',
    params: {
      sort: [['type', 1]],
      folder_id: folderId,
      limit: LIMIT,
      expand: linkExpand,
    },
  };
};

export const getMediaLinksUpdate = async (
  currentFileId: string,
  folderId: string | null,
  currentFileName: string,
) => {
  const batch: IRpcRequest[] = [];

  batch.push({
    method: 'media.links.update',
    params: {
      _id: currentFileId,
      folder_id: folderId,
      name: currentFileName,
      expand: linkExpand,
    },
  });

  await apiRequest({batch});
};

export const getMediaLinksDelete = async (fileID: string) => {
  const batch: IRpcRequest[] = [];

  batch.push({
    method: 'media.links.delete',
    params: {
      _id: fileID,
    },
  });

  await apiRequest({batch});
};
