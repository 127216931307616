import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, computed, observable, runInAction} from 'mobx';

import type {
  ApiRpcQuery,
  ApiRpcRequestParams,
  CollectionStore,
} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import type {Channel} from '@yourcoach/shared/api/channel';
import type {Follower as IFollower} from '@yourcoach/shared/api/follower';
import type {User as IUser, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

import {
  labelChatsCreateChat,
  labelChatsSearchClient,
} from '@src/common/i18n/i18nChannel';
import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton, CustomSearch} from '@src/components/CustomForm';
import {IconCheck} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import type {Expanded as ChannelExpanded} from '@src/models/channels';
import {expand as channelExpand} from '@src/models/channels';

import styles from './../styles.module.scss';

type User = IUser & UserExpanded;

type Follower = IFollower & {
  client: User | (Partial<User> & Required<Pick<User, 'name'>>);
};

interface ILocalStore {
  query: string;
  setQuery(query: string): void;
  searching: boolean;
  setSearching(searching: boolean): void;
  members: User[];
  shouldSearch: boolean;
  usersStore: CollectionStore<User | Follower> | null;
  setUsersStore(usersStore: CollectionStore<User | Follower>): void;
  searchStore: CollectionStore<User | Follower> | null;
  setSearchStore(searchStore: CollectionStore<User | Follower>): void;
  store: CollectionStore<User | Follower> | null;
  storeParams: ApiRpcRequestParams;
  users: User[];
  _searchTimeout: number;
  _redirectTimeout: number;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
  _onQueryChange(query: string): void;
  _onRefresh(silent?: boolean): Promise<void>;
  _clearMembers(): void;
  _onUserCheckboxPress(user: User): void;
}

interface Props {
  closeModal?: () => void;
}

const CreateChannel: FC<Props> = ({closeModal}) => {
  const {
    stores: {currentUserStore, channelStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const history = useHistory();
  const menuId = 'menuIdCreateChannel';
  const localStore = useRef(
    observable<ILocalStore>(
      {
        isLoading: false,
        setIsLoading(isLoading: boolean) {
          this.isLoading = isLoading;
        },
        query: '',
        setQuery(query: string) {
          this._onQueryChange(query);
        },
        searching: false,
        setSearching(searching: boolean) {
          this.searching = searching;
        },
        members: [],
        usersStore: null,
        setUsersStore(usersStore: CollectionStore<User | Follower>) {
          this.usersStore = usersStore;
        },
        searchStore: null,
        setSearchStore(searchStore: CollectionStore<User | Follower>) {
          this.searchStore = searchStore;
        },
        _searchTimeout: 0,
        _redirectTimeout: 0,
        get shouldSearch() {
          return this.query.length >= 2;
        },
        get store() {
          return this.query.length >= 2 ? this.searchStore : this.usersStore;
        },
        get storeParams() {
          const forCoach = user?.roles?.includes('coach');
          const params: ApiRpcRequestParams = {};

          if (this.query) {
            params.query = [
              [
                forCoach ? 'client_name' : 'coach_title:2,coach_description',
                'fulltext',
                `${this.query}*`,
              ],
            ];
          }

          return params;
        },
        get users() {
          const forCoach = user?.roles?.includes('coach');

          if (this.store) {
            return this.store.items
              .slice()
              .map(item => (forCoach ? (item as Follower).client : item))
              .filter(
                item =>
                  item._id &&
                  currentUserStore.user &&
                  item._id !== currentUserStore.user._id,
              ) as User[];
          } else {
            return [];
          }
        },
        _onQueryChange(query: string) {
          this.query = query;

          clearTimeout(this._searchTimeout);

          if (this.shouldSearch) {
            this.searching = true;
            this.searchStore.clear();

            this._searchTimeout = setTimeout(async () => {
              try {
                await this._onRefresh(true);
              } finally {
                runInAction(() => {
                  this.searching = false;
                });
              }
            }, 500);
          } else if (!this.query) {
            runInAction(() => {
              this.searching = false;
              this.searchStore.clear();
            });
          }
        },
        async _onRefresh(silent = false) {
          await this.store.fetch(this.storeParams, {
            silent,
          });
        },
        _clearMembers() {
          this.members = [];
        },
        _onUserCheckboxPress(user: User) {
          const index = this.members.findIndex(item => item._id === user._id);

          if (index >= 0) {
            this.members.splice(index, 1);
          } else {
            this.members.push(user);
          }
        },
      },
      {
        query: observable,
        setQuery: action,
        searching: observable,
        setSearching: action,
        members: observable.shallow,
        searchStore: observable,
        setSearchStore: action,
        usersStore: observable,
        setUsersStore: action,
        _searchTimeout: observable,
        _redirectTimeout: observable,
        isLoading: observable,
        setIsLoading: action,
        shouldSearch: computed,
        store: computed,
        storeParams: computed,
        users: computed,
        _onQueryChange: action,
        _onRefresh: action,
        _clearMembers: action,
        _onUserCheckboxPress: action,
      },
    ),
  ).current;

  useEffect(() => {
    const forCoach = user?.roles?.includes('coach');

    const params: ApiRpcRequestParams = {
      query: (
        [
          !forCoach ? ['roles', 'in', ['coach']] : null,
          !forCoach ? ['is_hidden', '==', false] : null,
        ] as ApiRpcQuery[]
      ).filter(Boolean),
      expand: forCoach
        ? {
            follower: [['client_id', {name: 'User not found'}, userExpand]],
          }
        : userExpand,
    };

    localStore.setUsersStore(
      createCollectionStore({
        method: forCoach ? 'coach.followers.list' : 'client.users.list',
        params: {
          ...params,
          sort: [['created', -1]],
          query: (
            [
              ...params.query!,
              !forCoach ? ['deleted', '==', null] : null,
            ] as ApiRpcQuery[]
          ).filter(Boolean),
        },
      }),
    );

    localStore.setSearchStore(
      createCollectionStore({
        method: forCoach ? 'coach.followers.search' : 'client.users.search',
        params,
      }),
    );

    localStore._onRefresh();

    return () => {
      clearTimeout(localStore._searchTimeout);
      clearTimeout(localStore._redirectTimeout);

      if (localStore.usersStore) {
        localStore.usersStore.clear();
      }

      if (localStore.searchStore) {
        localStore.searchStore?.clear();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _createChannel = async () => {
    try {
      localStore.setIsLoading(true);

      (await channelStore.create({
        user_ids: [user!._id, ...localStore.members.map(x => x._id)],
        expand: channelExpand,
      })) as Channel & ChannelExpanded;

      localStore.setIsLoading(false);

      runInAction(() => {
        // @ts-ignore
        localStore._redirectTimeout = setTimeout(() => {
          history.push({
            pathname: '/chats',
            state: {withUser: {_id: [...localStore.members.map(x => x._id)]}},
          });

          if (closeModal) {
            closeModal();
          }
        });
      });
    } catch (error) {
      localStore.setIsLoading(false);

      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onUserPress = (_user: User) => {
    // TODO: navigate to user profile or coach practice
  };

  const handleOnSearchClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;

    localStore.setQuery(text);
  };

  const handleClickCreateChat = () => {
    _createChannel();
  };

  const handleGetContextMenu = (selectUserList: User) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      contextMenu.show({
        id: menuId,
        event: event,
        props: {
          selectUserList,
        },
      });
    };
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const handleSeeUser = args => {
    const selectUser = args.props.selectUserList as User;

    _onUserPress(selectUser);
  };

  const MyMenu = () => (
    <div onClick={handleClickMenu}>
      <Menu
        id={menuId}
        animation={animation.fade}
        className="contextMenu"
        style={{zIndex: 1000}}
      >
        <Item onClick={handleSeeUser} className="contextMenuItem">
          see user
        </Item>
      </Menu>
    </div>
  );

  const handleChoiceUser = (selectUser: User) => {
    return () => {
      localStore._onUserCheckboxPress(selectUser);
    };
  };

  return (
    <Observer>
      {() => (
        <>
          <div className={`CreateChannel ${styles.CreateChannel}`}>
            <div className={styles.createChannelSearchContainer}>
              <CustomSearch
                label={labelChatsSearchClient()}
                id="CreateChannel"
                onChange={handleOnSearchClient}
                classContainer={`searchContainer ${styles.searchContainer}`}
                classInput={styles.searchContainerInput}
                className="search"
                classIcon="icon"
                classIconContainer="iconContainer"
              />
            </div>
            <div className={styles.userListContainer}>
              {localStore.isLoading || localStore.searching ? (
                <Loader />
              ) : (
                <div>
                  {localStore.users.map(userInList => {
                    const isSelected = !!localStore.members.find(
                      member => member._id === userInList._id,
                    );

                    return (
                      <div
                        key={userInList._id}
                        className={styles.userListItemContainer}
                        onClick={handleChoiceUser(userInList)}
                        onContextMenu={handleGetContextMenu(userInList)}
                      >
                        <div
                          className={`${styles.userCheckboxContainer} ${
                            isSelected ? styles.selected : ''
                          }`}
                        >
                          {isSelected ? <IconCheck /> : null}
                        </div>
                        <div className={styles.userAvatarContainer}>
                          <OtherUserProfileImg
                            avatar={userInList.avatar}
                            childrenContainerClassName={
                              styles.coachUsersContainer
                            }
                            profileClassName={styles.usersAvatar}
                            title={userInList.name}
                          />
                        </div>
                        <div className={styles.userNameContainer}>
                          {userInList.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={styles.buttContainer}>
              <CustomButton
                type="button"
                classButton={`blueButt ${styles.buttonSend}`}
                disabled={
                  localStore.members.length <= 0 || localStore.isLoading
                }
                onClick={handleClickCreateChat}
              >
                <span>{labelChatsCreateChat()}</span>
              </CustomButton>
            </div>
          </div>
          <MyMenu />
        </>
      )}
    </Observer>
  );
};

export default memo(CreateChannel);
