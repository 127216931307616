import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {t} from '@src/i18n';

export interface IError {
  code?: string;
  isError?: boolean;
  message: string;
  data?: IDataError;
}

export interface IDataError {
  method: string;
  reason: {name: string};
  version: number[];
}

export const setError: (
  error: IError[] | string,
  tryAgainFunc?: () => void,
) => void = (error, tryAgainFunc = () => {}) => {
  if (error === t('shared.message.subscription_expired')) {
    getCustomConfirmAlert({
      message: t('shared.message.subscription_expired'),
      buttons: [
        {
          label: t('shared.button.ok'),
          onClick: () => {
            window.location.replace('/profile/user-payments');
          },
          type: 'confirm',
        },
      ],
    });
  }

  tryAgainFunc();
};
