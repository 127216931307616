import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  ISOStringToDatetimeObj,
} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Payment as IPayment} from '@yourcoach/shared/api/payment';
import type {User} from '@yourcoach/shared/api/user';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {
  labelCoursePaymentsListItemPayment,
  labelCoursePaymentsTabNoResults,
} from '@src/common/i18n/i18nCourse';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import {IconBell} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import NoResults from '@src/components/NoResults/NoResults';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import Payment from '@src/modules/Payment/Payment';

import {emitter} from '../../../../../../widget/src/utils';
import type {Course as CourseT} from '../../../SquadsTab/SquadsTab';

import styles from './../../styles.module.scss';
import CoursePaymentsListItem from './CoursePaymentsListItem/CoursePaymentsListItem';

interface ILocalStore {
  paymentsStore: CollectionStore<PaymentT> | null;
  showModal: boolean;
  currentPayment: PaymentT | null;
  course: CourseT | null;
  shouldRenderStub: boolean;
  isFetching: boolean;
  data: PaymentT[];
  setCourse(courseVal: CourseT | null): void;
  setShowModal(showModal: boolean): void;
  setCurrentPayment(currentPayment: PaymentT | null): void;
  setPaymentsStore(paymentsStore: CollectionStore<PaymentT> | null): void;
}

type PaymentT = IPayment & {
  user: User & {
    avatar: IFile | null;
  };
};

interface Props {
  course: CourseT;
  isExternalPayments: boolean;
}

const CoursePaymentsTab: FC<Props> = ({course, isExternalPayments}) => {
  const LIMIT = 999;
  const CURSOR_DIRECTION = -1;
  const {
    stores: {currentUserStore, cardStore, paymentStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const localStore: ILocalStore = useRef(
    observable(
      {
        course: null,
        setCourse(courseVal: CourseT | null) {
          this.course = courseVal;
        },
        paymentsStore: null,
        setPaymentsStore(paymentsStore: CollectionStore<PaymentT> | null) {
          this.paymentsStore = paymentsStore;
        },
        showModal: false,
        setShowModal(showModal: boolean) {
          this.showModal = showModal;
        },
        currentPayment: null,
        setCurrentPayment(currentPayment: PaymentT | null) {
          this.currentPayment = currentPayment;
        },
        get shouldRenderStub() {
          return (
            this.paymentsStore &&
            (this.paymentsStore as CollectionStore<PaymentT>).isLoaded &&
            !(this.paymentsStore as CollectionStore<PaymentT>).hasItems
          );
        },
        get isFetching() {
          return (
            (this.paymentsStore as CollectionStore<PaymentT>) &&
            (!(this.paymentsStore as CollectionStore<PaymentT>).isLoaded ||
              (this.paymentsStore as CollectionStore<PaymentT>).isFetching)
          );
        },
        get data() {
          if (this.paymentsStore) {
            return this.paymentsStore.items.slice();
          } else {
            return [];
          }
        },
      },
      {
        course: observable,
        showModal: observable,
        currentPayment: observable,
        paymentsStore: observable,
        setCourse: action,
        setShowModal: action,
        setCurrentPayment: action,
        setPaymentsStore: action,
        shouldRenderStub: computed,
        isFetching: computed,
        data: computed,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setPaymentsStore(
      createCollectionStore({
        method: `${
          course.coach_ids.includes(user!._id) ? 'coach' : 'client'
        }.payments.list`,
        params: {
          limit: LIMIT,
          cursor: [CURSOR_DIRECTION, 0, null],
          query: [
            ['start_date', '<=', ISOStringToDatetimeObj(dayjs().toISOString())],
            ['course_id', '==', course._id],
          ],
          expand: {
            payment: [
              [
                'user_id',
                {name: 'User not found'},
                {
                  user: ['avatar_id'],
                },
              ],
            ],
          },
        },
      }),
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    fetchData();

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
      localStore.paymentsStore!.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const _handleWsMessage = msg => {
    if (msg.event.type.includes('payment')) {
      fetchData(true);
    }
  };

  const fetchData = async (silent = false) => {
    localStore.paymentsStore!.fetch(
      {
        limit: Math.max(localStore.paymentsStore!.items.length, LIMIT),
      },
      {silent},
    );
  };

  const _onPaymentPress = (payment: PaymentT) => {
    localStore.setCurrentPayment(payment);
    localStore.setShowModal(true);
  };

  const closeModal = () => {
    localStore.setShowModal(false);
  };

  const _onMarkPaymentPaidButtonPress = async (payment: PaymentT) => {
    try {
      const paidPayment = await paymentStore!.markPaid(payment);

      localStore.paymentsStore!.updateItem(payment, {
        status: paidPayment.status,
      });

      fetchData();
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  const _onRetryPaymentButtonPress = async (payment: PaymentT) => {
    try {
      const executeResult = await paymentStore.execute(payment);

      const {clientSecret} = executeResult;

      let executedPayment = executeResult.payment;

      if (executedPayment.status === 'requires_action' && clientSecret) {
        if (!executedPayment.card_id) {
          setError('Payment method is not found!');

          await getCustomConfirmAlert({
            title: 'Payment method is not found!',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {},
              },
            ],
          });

          throw new Error('Payment method is not found!');
        }

        const card = cardStore.cards[executedPayment.card_id];

        const stripeConfirmIntentResponse = await fetch(
          `https://api.stripe.com/v1/payment_intents/${payment.payment_id}/confirm`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${process.env.STRIPE_KEY}`,
            },
            method: 'POST',
            body: [
              `payment_method=${card.pm_id}`,
              `client_secret=${clientSecret}`,
              `return_url=${window.location.href}`,
            ].join('&'),
          },
        );

        const stripeConfirmIntentResponseJson =
          await stripeConfirmIntentResponse.json();

        if (stripeConfirmIntentResponseJson.error) {
          getCustomConfirmAlert({
            title: 'Stripe error',
            message: JSON.stringify(
              stripeConfirmIntentResponseJson.error,
              null,
              2,
            ),
            buttons: [
              {
                label: 'Ok',
                onClick: () => {},
              },
            ],
          });

          throw stripeConfirmIntentResponseJson.error;
        }

        let stripeWindow: Window | null = null;

        if (stripeConfirmIntentResponseJson.status === 'requires_action') {
          stripeWindow = window.open(
            stripeConfirmIntentResponseJson.next_action.redirect_to_url.url,
            '_blank',
          );

          stripeWindow?.focus();

          await new Promise<void>(resolve => {
            const timerId = setInterval(() => {
              try {
                if (
                  stripeWindow &&
                  stripeWindow.location.href.startsWith(
                    process.env.URL as string,
                  )
                ) {
                  clearInterval(timerId);
                  stripeWindow?.close();
                  stripeWindow = null;
                  resolve();
                }
              } catch (error) {
                // do nothing
              }
            }, 250);
          });
        }

        const result = await paymentStore.execute(payment);

        executedPayment = result.payment;
      }

      localStore.paymentsStore!.updateItem(payment, {
        status: executedPayment.status,
      });

      fetchData();
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.shouldRenderStub ? (
            <NoResults text={labelCoursePaymentsTabNoResults()}>
              <div className={styles.iconNoResultContainer}>
                <IconBell className={styles.iconNoResult} viewBox="4 4 24 24" />
              </div>
            </NoResults>
          ) : localStore.isFetching ? (
            <Loader />
          ) : (
            <div className={`CoursePaymentsTab ${styles.CoursePaymentsTab}`}>
              {localStore.data?.map(item => {
                return (
                  <CoursePaymentsListItem
                    key={item._id}
                    payment={item}
                    isExternal={isExternalPayments}
                    onPress={_onPaymentPress}
                    onMarkPaidButtonPress={_onMarkPaymentPaidButtonPress}
                    onRetryButtonPress={_onRetryPaymentButtonPress}
                  />
                );
              })}
              <ModalAnimateWin
                showModal={localStore.showModal}
                closeModalHandler={closeModal}
                className="greyHeaderContainer littleContainer marginTopMinus50Percent"
                isBody
                classNameBody="whiteBody"
                header={labelCoursePaymentsListItemPayment()}
                classNameHeader="greyHeader"
                classNameCloseBut="greyHeaderBut"
              >
                <div className={'paymentContainer'}>
                  <div className={'currentPaymentContainer'}>
                    <Payment payment={localStore.currentPayment!} />
                  </div>
                </div>
              </ModalAnimateWin>
            </div>
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(CoursePaymentsTab);
