import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {t} from '@src/i18n';

import Program from '../Program/Program';
import type {IMySquadsStore, Section as ISection} from '../useMySquads';

import styles from './../styles.module.css';

const I18N_SCOPE = 'WorkspacePracticeTab';

interface Props {
  mySquadsStore: IMySquadsStore;
}

const ProgramList: FC<Props> = ({mySquadsStore}) => {
  return (
    <Observer>
      {() => (
        <div className={`ProgramList ${styles.ProgramList}`}>
          <h3>{t([I18N_SCOPE, 'active_courses_label'])}</h3>
          {mySquadsStore.sections.map((section: ISection) => (
            <div key={section.program._id}>
              <Program section={section} />
              <hr />
            </div>
          ))}
        </div>
      )}
    </Observer>
  );
};

export default memo(ProgramList);
