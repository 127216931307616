import type {FC} from 'react';
import React, {memo, useCallback, useMemo} from 'react';

import {getFileSrc} from '@yourcoach/shared/api/media/file';

import AvatarPlaceholder from '@src/components/AvatarPlaceholder';
import Image from '@src/components/Image';
import {t} from '@src/i18n';

import type {ProgramT} from '.';
import styles from './ProgramsListItem.module.css';
import {I18N_SCOPE} from '.';

interface Props {
  program: ProgramT;
  onClick?: (program: ProgramT) => void;
}

const ProgramsListItem: FC<Props> = ({program, onClick}) => {
  const onClickCb = useCallback(() => {
    onClick && onClick(program);
  }, [onClick, program]);

  const avatar = useMemo(
    () => getFileSrc(program.avatar, 250).url || '',
    [program],
  );

  return (
    <div className={styles.Component} onClick={onClickCb}>
      <Image
        src={avatar}
        resizeMode="cover"
        className={styles.image}
        placeholder={<AvatarPlaceholder name={program.title} />}
      />
      <div className={styles.content}>
        {t([I18N_SCOPE, 'program_label'])}
        <div className={styles.title}>{program.title}</div>
      </div>
    </div>
  );
};

export default memo(ProgramsListItem);
