import {themes} from '@yourcoach/shared/styles/theme';

export const getListScrollProps = () => ({
  wrapperProps: {className: 'wrapper'},
  trackYProps: {
    style: {
      width: '4px',
      background: themes.light.color.v2.background2,
      top: 0,
      height: '100%',
    },
  },
  thumbYProps: {style: {background: themes.light.color.v2.button.fill2}},
  trackXProps: {className: 'trackX'},
  thumbXProps: {className: 'thumbY'},
});
