import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {Layout as L} from './Layout';

const InfoItemSkeleton: React.FC = () => {
  return (
    <L.Info.Item.Container>
      <L.Info.Item.Icon>
        <Skeleton circle height={32} width={32} />
      </L.Info.Item.Icon>
      <L.Info.Item.Content>
        <Skeleton height={8} width={50} />
        <Skeleton height={8} width={160} />
      </L.Info.Item.Content>
    </L.Info.Item.Container>
  );
};

export const UserInfoSkeleton: React.FC = () => (
  <L.User.Container>
    <Skeleton circle={true} height={98} width={98} />
    <L.User.Body>
      <L.User.Header>
        <Skeleton height={20} width={160} />
        <Skeleton height={8} width={160} />
      </L.User.Header>
      <L.User.Buttons>
        <Skeleton height={30} width={160} />
      </L.User.Buttons>
    </L.User.Body>
  </L.User.Container>
);

export const TaskInfoSkeleton: React.FC = props => (
  <L.Info.Container {...props}>
    <InfoItemSkeleton />
    <InfoItemSkeleton />
    <InfoItemSkeleton />
  </L.Info.Container>
);
