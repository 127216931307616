import type {FC, ReactNodeArray} from 'react';
import React, {memo} from 'react';

import LeftPaneLayout from '../../../layouts/LeftPaneLayout/LeftPaneLayout';
import LogoSVG from '../images/logo_small.svg';

import styles from './styles.module.css';

export const Title = ({children}) => (
  <div className={styles.title}>{children}</div>
);

export const Description = ({children}) => (
  <div className={styles.description}>{children}</div>
);

export const ButtonsContainer = ({children}) => (
  <div className={styles.ButtonsContainer}>{children}</div>
);

export const AltNavRow = ({children}) => (
  <div className={styles.altNavRow}>{children}</div>
);

interface Props {
  icon?: React.ReactElement;
  children?: React.ReactNode | ReactNodeArray;
}

const LeftPaneStep: FC<Props> = ({icon, children}) => {
  return (
    <LeftPaneLayout className={styles.root}>
      <div className={styles.leftLogo}>{icon}</div>

      <div className={styles.form}>
        <LogoSVG className={styles.logo} />
        <div className={styles.content}>{children}</div>
      </div>
    </LeftPaneLayout>
  );
};

export default memo(LeftPaneStep);
