import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

import {IconTrash} from '@src/components/icons';
import {t} from '@src/i18n';
import type {Goal as PreviousGoal} from '@src/modules/legacy/Goals/SelectGoal/SelectGoal';

import SubgoalsListItem from '../SubgoalsListItem/SubgoalsListItem';

import styles from './../styles.module.scss';

interface Props {
  goal: PreviousGoal;
  index: number;
  onPress?: (
    goal: PreviousGoal,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onDeleteButtonPress?: (goal: PreviousGoal) => void;
  onSubgoalPress?: (subgoal: PreviousGoal['subgoals'][0]) => void;
  onDeleteSubgoalButtonPress?: (subgoal: PreviousGoal['subgoals'][0]) => void;
}

const PreviousGoalsListItem: FC<Props> = ({
  goal,
  index,
  onPress,
  onDeleteButtonPress,
  onSubgoalPress,
  onDeleteSubgoalButtonPress,
}) => {
  const onPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onPress && onPress(goal, event);
    },
    [goal, onPress],
  );

  const onDeleteButtonPressCb = useCallback(() => {
    onDeleteButtonPress && onDeleteButtonPress(goal);
  }, [goal, onDeleteButtonPress]);

  return (
    <Observer>
      {() => (
        <div
          className={`PreviousGoalsListItem ${styles.PreviousGoalsListItem}`}
        >
          <div className={styles.mainGoalContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.mainGoalLabel}>
                {t('label.goal_index', {index})}
              </div>
              <div className={styles.mainGoalTitle}>{goal.title}</div>
            </div>
            <div className={styles.lastMenuContainer}>
              <div className={styles.iconTrash} onClick={onDeleteButtonPressCb}>
                <IconTrash />
              </div>
              <div className={styles.moreMenu} onClick={onPressCb}>
                ⋮
              </div>
            </div>
          </div>
          <div className={styles.subGoalsListContainer}>
            {goal.subgoals.map(subgoal => (
              <SubgoalsListItem
                key={subgoal._id}
                subgoal={subgoal}
                isRemovable
                onPress={onSubgoalPress}
                onDeleteButtonPress={onDeleteSubgoalButtonPress}
              />
            ))}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(PreviousGoalsListItem);
