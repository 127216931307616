import type {FC} from 'react';
import React from 'react';

import IconLockSVG from '@yourcoach/shared/assets/icons/lock-2.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconLock: FC<Props> = ({
  height = '100%',
  width = '100%',
  viewBox = '2 2 22 22',
  className = '',
}) => {
  return (
    <IconLockSVG
      className={`${className}`}
      width={height}
      height={width}
      viewBox={viewBox}
    />
  );
};

export default IconLock;
