import React, {useCallback, useState} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import {useMobxStore} from '@yourcoach/shared/hooks';
import type {CalendarEvent} from '@yourcoach/shared/modules/calendar';
import {CalendarContainer, Period} from '@yourcoach/shared/modules/calendar';
import {View} from '@yourcoach/shared/uikit/View';
import type {PayloadAction} from '@yourcoach/shared/utils';
import {doNothing} from '@yourcoach/shared/utils';
import {logger} from '@yourcoach/shared/utils/logger';

import CompletedTask from '../../../assets/CompletedTask.svg';
import TaskTodos from '../../../assets/TaskTodos.svg';
import TickSquareCompletedSmallMob from '../../../assets/TickSquareCompletedSmallMob.svg';
import TickSquareSmallMob from '../../../assets/TickSquareSmallMob.svg';
import VideoBig from '../../../assets/VideoBig.svg';
import VideoSmall from '../../../assets/VideoSmall.svg';
import Modal from '../../../components/ModalNew';
import {useWindowDimensions} from '../../../hooks/useWindowDimensions';
import {useBookSessions} from '../../Chats/ChatsListContainer/ChatsListItem/useBookSession';
import Conference from '../../Conference';
import {mapScheme} from '../MyCalendar/utils';
import {SingleEvent as SingleEventModal} from '../SingleEvent';

import styles from './index.module.css';
import {TodosStore} from './stores';

dayjs.extend(weekday);

/* may be needed in the future */
// const getHiddenPeriods = (width: number) =>
//   width >= AVERAGE_TABLET_BREAKPOINT
//     ? [Period.week]
//     : [Period.week, Period.month];
// const getInitialPeriod = (width: number) =>
//   width > AVERAGE_TABLET_BREAKPOINT ? Period.month : Period.day;

export const ToDosPage: React.FC = () => {
  const {width} = useWindowDimensions();
  const [isOpenEventModal, setIsOpenEventModal] = useState<string | null>(null);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [conferenceId, setConferenceId] = useState<string | null>(null);
  const [calendatRangeStartDate, setCalendatRangeStartDate] = useState<number>(
    Date.now(),
  );

  const store = useMobxStore(() => new TodosStore());

  const onEventClickHandler = React.useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      if (action.payload) {
        if (
          action.payload.type === 'task' &&
          action.payload.eventStatus !== 'done'
        ) {
          setIsTaskCompleted(false);
        }

        if (
          action.payload.type === 'task' &&
          action.payload.eventStatus === 'done'
        ) {
          setIsTaskCompleted(true);
        }

        if (action.payload.type === 'session') {
          logger.event('session_open_tap');
        }

        if (action.payload.type === 'task') {
          logger.event('task_open_tap');
        }

        setIsOpenEventModal(action.payload.id);
      }
    },
    [],
  );

  const onEventActionClickHandler = React.useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      logger.event('session_join_tap', {type: 'available', place: 'to_dos'});

      if (action.payload) {
        setConferenceId(action.payload.id);
      }
    },
    [],
  );

  const handleStartConference = React.useCallback((eventId: string) => {
    logger.event('session_join_tap', {type: 'available', place: 'to_dos'});
    setConferenceId(eventId);
    setIsOpenEventModal(null);
  }, []);

  const handleCloseConference = React.useCallback(() => {
    setConferenceId(null);
    store.calendar.fetch();
  }, [store.calendar]);

  const renderIcon = () => {
    if (isOpenEventModal?.includes('task') && isTaskCompleted && width > 800) {
      return CompletedTask;
    }

    if (isOpenEventModal?.includes('task') && isTaskCompleted && width <= 799) {
      return TickSquareCompletedSmallMob;
    }

    if (
      isOpenEventModal?.includes('task') &&
      !isTaskCompleted &&
      width <= 799
    ) {
      return TickSquareSmallMob;
    }

    if (isOpenEventModal?.includes('task') && !isTaskCompleted && width > 800) {
      return TaskTodos;
    }

    if (isOpenEventModal?.includes('conference') && width > 800) {
      return VideoBig;
    }

    if (isOpenEventModal?.includes('conference') && width <= 799) {
      return VideoSmall;
    }
  };

  const onRangeChange = useCallback(
    res => {
      if (calendatRangeStartDate > res?.payload?.start) {
        logger.event('schedule_day_tap', {type: 'back'});
      } else {
        logger.event('schedule_day_tap', {type: 'forward'});
      }

      setCalendatRangeStartDate(res?.payload?.start);
    },
    [calendatRangeStartDate],
  );

  const renderEventModal = () => (
    <Modal
      isOpen={Boolean(isOpenEventModal)}
      title={
        isOpenEventModal?.includes('task')
          ? 'Journaling your daily exercise'
          : 'Live Session'
      }
      modalClassName={styles.modalWrapper}
      headerClassName={styles.eventInfoHeaderModal}
      bodyClassName={styles.bodyModal}
      onRequestClose={() => {
        setIsOpenEventModal(null);
        store.calendar.fetch();
      }}
      WithIcon={renderIcon()}
      completedTask={isTaskCompleted}
    >
      {isOpenEventModal && (
        <SingleEventModal
          eventId={isOpenEventModal}
          onStartConference={handleStartConference}
          handleCloseEventModal={() => setIsOpenEventModal(null)}
          storeCalendarFetch={() => store.calendar.fetch()}
        />
      )}
    </Modal>
  );

  const onDeleteEventHandler = useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      logger.event('session_delete_tap');
      store.calendar.onDeleteEvent(action);
    },
    [store.calendar],
  );

  const {bookingJSX, setIsOpenSchedulingModal, setSessionToRebook} =
    useBookSessions({
      onBookSession: () => store.calendar.fetch(),
    });

  const onRescheduleEventHandler = useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      // @ts-ignore
      setSessionToRebook(action.payload?.sourceData?.event);

      setIsOpenSchedulingModal(true);
    },
    [setIsOpenSchedulingModal, setSessionToRebook],
  );

  return (
    <div className={styles.wrapper}>
      <Observer>
        {() => (
          <View>
            <CalendarContainer
              isSelectMode={false}
              isLoading={store.calendar.isLoading}
              data={store.filteredData}
              getDataTrigger={store.calendar.fetch}
              schedule={null as unknown as string}
              onDayOffClickAction={doNothing}
              onDaySelectClick={doNothing}
              onEditEvent={doNothing}
              onDeleteEvent={onDeleteEventHandler}
              onEventClick={onEventClickHandler}
              onEventActionClick={onEventActionClickHandler}
              mapScheme={mapScheme}
              onDayClick={doNothing}
              calendarInstanceType={'todos'}
              onPeriodChange={doNothing}
              onRangeChange={onRangeChange}
              hiddenPeriods={[Period.month]}
              initialPeriod={Period.week}
              onRescheduleEvent={onRescheduleEventHandler}
            />
            {renderEventModal()}
          </View>
        )}
      </Observer>

      {conferenceId && (
        <Conference
          conferenceId={conferenceId}
          onCloseConference={handleCloseConference}
        />
      )}
      {bookingJSX}
    </div>
  );
};
