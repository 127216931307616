import type {ChangeEvent, FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable, reaction} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {fileStore} from '@yourcoach/shared/api/media/file';
import {Text} from '@yourcoach/shared/uikit/Text';

import {labelContinue, labelEditPhoto} from '@src/common/i18n/i18nCommon';
import {labelCover} from '@src/common/i18n/i18nPrograms';
import {
  labelDescribeYourQuestionnaireHere___,
  labelName___,
  labelQuestionnaireName,
  labelThisFieldIsMandatoryAndMustContainAtLeast3Characters,
} from '@src/common/i18n/i18nQuestionnaire';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {
  // CustomButton,
  CustomInput,
  CustomTextArea,
} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import IconNext from '@src/components/icons/IconNext/IconNext';
import ImageEditor from '@src/components/ImageEditor';
import ImageSelector from '@src/components/ImageSelector';
import {ColorWrapper} from '@src/layouts';
import {PATH_TYPE} from '@src/routes/utils';

import {IconButton} from '../../../../../v2/components/IconButton';
import questionnaireAppStore from '../../../context/qAppStore';
import styles from '../../styles.module.css';

interface Props {
  clickContinue: () => void;
  clickBack: () => void;
}

interface ILocalStore {
  questionnaireName: string;
  setQuestionnaireName(newVal: string): void;
  questionnaireNameError: string;
  setQuestionnaireNameError(newVal: string): void;
  questionnaireDescription: string;
  setQuestionnaireDescription(newVal: string): void;
  imageLoading: boolean;
  setImageLoading(imageLoading: boolean): void;
  isEditPhotoModule: boolean;
  toggleOpenEditPhotoModule: (val: boolean) => void;
  showModalUpdateFile: boolean;
  setShowModalUpdateFile(showModalUpdateFile: boolean): void;
}

const Step1: FC<Props> = ({clickContinue}) => {
  const redirect = useAppRedirect();

  const localStore: ILocalStore = useRef(
    observable(
      {
        imageLoading: false,
        setImageLoading(imageLoading: boolean) {
          this.imageLoading = imageLoading;
        },
        questionnaireName: '',
        setQuestionnaireName(newVal: string) {
          this.questionnaireName = newVal;
        },
        questionnaireNameError: '',
        setQuestionnaireNameError(newVal: string) {
          this.questionnaireNameError = newVal;
        },
        questionnaireDescription: '',
        setQuestionnaireDescription(newVal: string) {
          this.questionnaireDescription = newVal;
        },
        isEditPhotoModule: false,
        toggleOpenEditPhotoModule(val: boolean) {
          this.isEditPhotoModule = val;
        },
        showModalUpdateFile: false,
        setShowModalUpdateFile(showModalUpdateFile: boolean) {
          this.showModalUpdateFile = showModalUpdateFile;
        },
      },
      {
        questionnaireName: observable,
        questionnaireNameError: observable,
        questionnaireDescription: observable,
        setQuestionnaireName: action,
        setQuestionnaireNameError: action,
        setQuestionnaireDescription: action,
        imageLoading: observable,
        setImageLoading: action,
        isEditPhotoModule: observable,
        toggleOpenEditPhotoModule: action,
        showModalUpdateFile: observable,
        setShowModalUpdateFile: action,
      },
    ),
  ).current;

  const setData = () => {
    if (questionnaireAppStore?.userTemplateQuest?.title) {
      localStore.setQuestionnaireName(
        questionnaireAppStore?.userTemplateQuest?.title,
      );
      localStore.setQuestionnaireDescription(
        questionnaireAppStore?.userTemplateQuest?.description,
      );
    } else {
      localStore.setQuestionnaireName('');
      localStore.setQuestionnaireDescription('');
    }
  };

  useEffect(() => {
    const dispose = reaction(
      () => questionnaireAppStore?.userTemplateQuest?.title,
      title => {
        if (title) {
          setData();
        }
      },
    );

    setData();

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeName = (
    event?: ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    localStore.setQuestionnaireNameError('');
    localStore.setQuestionnaireName(event!.currentTarget.value);
  };

  const handleOnChangeDescription = (
    event?:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | undefined,
  ) => {
    localStore.setQuestionnaireDescription(event!.currentTarget.value);
  };

  const handleClickContinue = () => {
    if (localStore.questionnaireName === undefined) {
      redirect(`/${PATH_TYPE.createQuestionnaire}`);
    } else {
      if (localStore.questionnaireName!.length < 3) {
        localStore.setQuestionnaireNameError(
          labelThisFieldIsMandatoryAndMustContainAtLeast3Characters(),
        );
      } else {
        questionnaireAppStore!.setUserTemplateTitleAndDesc(
          localStore.questionnaireName ? localStore.questionnaireName : '',
          localStore.questionnaireDescription
            ? localStore.questionnaireDescription
            : '',
        );
        clickContinue();
      }
    }
  };

  const handleGetFileOriginal = async (file: IFile) => {
    questionnaireAppStore!.setOriginalLogo(file);
    handleGetFile(file);
  };
  const handleGetFile = async (file: IFile) => {
    questionnaireAppStore!.setLogo(file);
    questionnaireAppStore!.setLogoId(file._id);
  };
  const handleOnClickUpdateFile = () => {
    localStore.setShowModalUpdateFile(true);
  };
  const handleCloseModalUpdateFile = () => {
    localStore.setShowModalUpdateFile(false);
  };
  const handleGetUpdateFile = async (newFile: File) => {
    localStore.setShowModalUpdateFile(false);
    localStore.setImageLoading(true);

    const file = await fileStore.upload(newFile);

    localStore.setImageLoading(false);

    handleGetFile(file);
  };

  return (
    <Observer>
      {() => (
        <div className={`Step1 ${styles.step}`}>
          <div className={styles.inputContainer}>
            <h3>{labelCover()}:</h3>
            <div className={styles.editLogoContainer}>
              <ImageSelector
                className={styles.logo}
                file={questionnaireAppStore?.logo}
                onUpload={handleGetFileOriginal}
              />
              {questionnaireAppStore!.logo ? (
                <>
                  <button
                    className={`ovalButton ${styles.editPhotoButton}`}
                    type="button"
                    onClick={handleOnClickUpdateFile}
                  >
                    {labelEditPhoto()}
                  </button>
                  <ModalAnimateWin
                    showModal={localStore.showModalUpdateFile}
                    header={labelEditPhoto()}
                    isBody
                    className="maxHeight60vh"
                    classNameBody="height100Per"
                    closeModalHandler={handleCloseModalUpdateFile}
                  >
                    <ImageEditor
                      image={questionnaireAppStore!.logo}
                      onSuccess={handleGetUpdateFile}
                    />
                  </ModalAnimateWin>
                </>
              ) : null}
            </div>
            <h3>{labelQuestionnaireName()}:</h3>
            <CustomInput
              type="input"
              label={labelName___()}
              id="questionnaireName"
              hideLabel
              customClassLabel="QStandardLabel"
              customClassCont="QStandardContainer"
              customClassInput="QStandardInput"
              value={localStore.questionnaireName}
              onChange={handleOnChangeName}
              error={localStore.questionnaireNameError}
              showErrorImmediately
            />
          </div>
          <div className={styles.inputContainer}>
            <h3>Questionnaire Description:</h3>
            <CustomTextArea
              type="textarea"
              label={labelDescribeYourQuestionnaireHere___()}
              id="programDescription"
              hideLabel
              customClassLabel="QStandardLabel"
              customClassCont="QStandardContainer"
              customClassInput="QStandardTextArea"
              value={localStore.questionnaireDescription}
              onChange={handleOnChangeDescription}
            />
          </div>
          <div className={styles.footer}>
            <ColorWrapper containerClass={styles.right}>
              {/*<CustomButton*/}
              {/*  type="button"*/}
              {/*  classButton="QuestionnaireContinueBut"*/}
              {/*  onClick={handleClickContinue}*/}
              {/*>*/}
              {/*  <span>{labelContinue()}</span>*/}
              {/*  <IconNext customClass="IconNext" />*/}
              {/*</CustomButton>*/}
              <IconButton
                onClick={handleClickContinue}
                size={'xl'}
                icon={<IconNext />}
              >
                <Text variant={'medium'} color={'inherit'}>
                  {labelContinue()}
                </Text>
              </IconButton>
            </ColorWrapper>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Step1);
