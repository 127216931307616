import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {t} from '@src/i18n';

/**
 * NOTE: be sure that "when" is not depend from any state
 * because it won't change since useEffect has no dependencies
 * @param message
 * @param when
 */
const useLeaveBlocker = (
  message: string,
  when: boolean | (() => boolean) | (() => Promise<boolean>),
) => {
  const history = useHistory();

  const onBeforeUnload = useCallback(
    async (e: BeforeUnloadEvent) => {
      if (typeof when === 'boolean' ? when : await when()) {
        e.preventDefault();
        e.returnValue = '';

        return message;
      }
    },
    [message, when],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);

    let unblock = history.block((location, action) => {
      const unblockNavigation = () => {
        unblock();

        setTimeout(() => {
          switch (action) {
            case 'PUSH':
              history.push(location.pathname, location.state);
              break;
            case 'REPLACE':
              history.replace(location.pathname, location.state);
              break;
            default:
              history.goBack();
              break;
          }
        }, 16);
      };

      const showConfirmation = () => {
        getCustomConfirmAlert({
          message,
          buttons: [
            {
              label: t('shared.button.cancel'),
            },
            {
              label: t('shared.button.ok'),
              type: 'confirm',
              onClick: () => unblockNavigation(),
            },
          ],
        });
      };

      let shouldShowLeaveConfirm = false;

      if (typeof when === 'boolean') {
        shouldShowLeaveConfirm = when;
      } else {
        const whenCallResult = when();

        if (!(whenCallResult as Promise<boolean>).then) {
          shouldShowLeaveConfirm = whenCallResult as boolean;
        } else {
          (whenCallResult as Promise<boolean>).then(asyncWhenCallResult => {
            if (asyncWhenCallResult) {
              showConfirmation();
            } else {
              unblockNavigation();
            }
          });

          return false;
        }
      }

      if (shouldShowLeaveConfirm) {
        showConfirmation();
      } else {
        unblockNavigation();
      }

      return false;
    });

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);

      unblock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLeaveBlocker;
