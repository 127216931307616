import React from 'react';

import styled from 'styled-components';

import IconMeal from '@yourcoach/shared/assets/icons/meal.svg';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {web} from './styles';
import type {Props, StyledProps} from './types';

const StyledMealIcon = styled(View)<StyledProps>`
  ${web};
`;

const MealIcon: React.FC<WithDomStyleProp<Props>> = ({
  onClick,
  time,
  ...rest
}) => {
  // const controller = useController(rest);

  // return <StyledMealIcon {...rest}>{children}</StyledMealIcon>;
  return (
    <Clickable onClick={onClick}>
      <StyledMealIcon {...rest}>
        <IconBox
          size={'icon.xs'}
          padding={0}
          fill={'icon2'}
          variant={'square'}
          bg={'transparent'}
        >
          <IconMeal />
        </IconBox>
        <Text variant={'tiny'} color={'icon2'}>
          {time}
        </Text>
      </StyledMealIcon>
    </Clickable>
  );
};

export {MealIcon};
