import React, {useContext, useEffect, useRef} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import InView from 'react-intersection-observer';
import {useHistory, useLocation} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable, observe, reaction, runInAction} from 'mobx';

import {apiRequest, datetimeObjToISOString} from '@yourcoach/shared/api';
import {getConferenceActions} from '@yourcoach/shared/api/conference';
import {
  courseIsEditable,
  getCourseDurationString,
} from '@yourcoach/shared/api/course';
import type {Task as ITask} from '@yourcoach/shared/api/task';

import {labelClear} from '@src/common/i18n/i18nCommon';
import {labelDeleteTask} from '@src/common/i18n/i18nTasks';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import type {IOption} from '@src/components/CustomForm/CustomSelect/CustomSelect';
import FadeComponent from '@src/components/FadeComponent/FadeComponent';
import {
  IconCalendar,
  IconClose,
  IconNext,
  IconPrev,
} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import type {ModalRef} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import ScrollToElement from '@src/components/ScrollToElement/ScrollToElement';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import {t} from '@src/i18n';
import {
  isTaskInSchedule,
  shouldUpdateProgramSchedule,
  updateConferencesSchedule,
  updateTasksSchedule,
} from '@src/models/program';
import type {Expanded as TaskExpanded} from '@src/models/tasks';
import type {CourseT} from '@src/modules/courses/SelectCourse';

import {emitter} from '../../../widget/src/utils';
import CRUCalendarEvent, {
  I18N_SCOPE as CRU_CALENDAR_EVENT_I18N_SCOPE,
} from '../calendar/CRUCalendarEvent';
import MyCalendar from '../MyCalendar/MyCalendar';

import ClientTask from './ClientTask/ClientTask';
import type {ConferenceT} from './ConferencesListItem/ConferencesListItem';
import ConferencesListItem from './ConferencesListItem/ConferencesListItem';
import ToDosContext from './context/ToDosContext';
import type {
  Conference,
  IEvent,
  IToDosLocalStore,
  Task,
} from './context/useToDosLocalStore';
import {DATE_FORMAT} from './context/useToDosLocalStore';
import SelectCourseContainer from './SelectCourseContainer/SelectCourseContainer';
import type {TaskT} from './Task/Task';
import TaskEl from './Task/Task';
import TasksListItem from './TasksListItem/TasksListItem';
import ToDosMenu from './ToDosMenu/ToDosMenu';
import styles from './styles.module.css';

const I18N_SCOPE = 'TasksTab';

interface ILocalStore {
  isShowCalendar: boolean;
  setIsShowCalendar(isShowCalendar: boolean): void;
  fetchTimeOutId: number | null;
  setFetchTimeOutId(newId: number): void;
  selectEvent: IEvent | null;
  setSelectEvent(selectEvent: IEvent): void;
  isConference: boolean;
  setIsConference(isConference: boolean): void;
  isShowCreateEventMod: boolean;
  setIsShowCreateEventMod(isShowCreateEventMod: boolean): void;
  squadListOptions: IOption[];
  setSquadListOptions(squadListOptions: IOption[]): void;
  selectTask: Task | null;
  setSelectTask(selectTask: Task | null): void;
  selectUserTask: TaskT | null;
  setSelectUserTask(selectUserTask: TaskT | null): void;
  selectConference: Conference | null;
  setSelectConference(selectConference: Conference | null): void;
  urlClientTaskId: TaskT | null;
  setUrlClientTaskId(urlClientTaskId: TaskT | null): void;
}

interface Props {}

const ToDos: React.FC<Props> = () => {
  const location = useLocation();
  // @ts-ignore
  const course = location.state ? location.state.course || null : null;

  const cruEventModalRef = useRef<ModalRef>(null);

  const {
    stores: {conferenceStore, taskStore, currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user;
  const history = useHistory();
  const toDosLocalStore: IToDosLocalStore | null = useContext(ToDosContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        urlClientTaskId: null,
        setUrlClientTaskId(urlClientTaskId: TaskT | null) {
          this.urlClientTaskId = urlClientTaskId;
        },
        selectUserTask: null,
        setSelectUserTask(selectUserTask: TaskT | null) {
          this.selectUserTask = selectUserTask;
        },
        selectTask: null,
        setSelectTask(selectTask: Task | null) {
          this.selectTask = selectTask;
        },
        selectConference: null,
        setSelectConference(selectConference: Conference | null) {
          this.selectConference = selectConference;
        },
        isShowCalendar: false,
        setIsShowCalendar(isShowCalendar: boolean) {
          this.isShowCalendar = isShowCalendar;
        },
        isShowCreateEventMod: false,
        setIsShowCreateEventMod(isShowCreateEventMod: boolean) {
          this.isShowCreateEventMod = isShowCreateEventMod;
        },
        fetchTimeOutId: null,
        setFetchTimeOutId(newId: number) {
          this.fetchTimeOutId = newId;
        },
        selectEvent: null,
        setSelectEvent(selectEvent: IEvent) {
          this.selectEvent = selectEvent;
        },
        isConference: false,
        setIsConference(isConference: boolean) {
          this.isConference = isConference;
        },
        squadListOptions: [{id: '1', val: t(['Common', 'all_programs'])}],
        setSquadListOptions(squadListOptions: IOption[]) {
          this.squadListOptions = squadListOptions;
        },
      },
      {
        isShowCalendar: observable,
        setIsShowCalendar: action,
        fetchTimeOutId: observable,
        setFetchTimeOutId: action,
        selectEvent: observable,
        setSelectEvent: action,
        isConference: observable,
        setIsConference: action,
        isShowCreateEventMod: observable,
        setIsShowCreateEventMod: action,
        squadListOptions: observable.shallow,
        setSquadListOptions: action,
        selectTask: observable,
        setSelectTask: action,
        selectUserTask: observable,
        setSelectUserTask: action,
        selectConference: observable,
        setSelectConference: action,
        urlClientTaskId: observable,
        setUrlClientTaskId: action,
      },
    ),
  ).current;

  useEffect(() => {
    // @ts-ignore
    const disposeObserve = observe(toDosLocalStore, 'currentMonth', () => {
      clearTimeout(toDosLocalStore!._fetchEventsOnCurrentMonthChangeTimeout);

      // @ts-ignore
      toDosLocalStore!._fetchEventsOnCurrentMonthChangeTimeout = setTimeout(
        () => {
          toDosLocalStore!._fetchEvents();
        },
        1000,
      );
    });
    const disposeObserveMarkedDates = observe(
      toDosLocalStore,
      // @ts-ignore
      'markedDates',
      markedDates => {
        if (localStore.urlClientTaskId) {
          const date = dayjs(
            datetimeObjToISOString(localStore.urlClientTaskId.start_date),
          ).format('YYYY-MM-DD');

          if (markedDates.newValue[date].events) {
            markedDates.newValue[date].events.forEach(element => {
              if ((element as Task)._id === localStore.urlClientTaskId?._id) {
                localStore.setSelectTask(element as Task);
                localStore.setUrlClientTaskId(null);
              }
            });
          }
        }
      },
    );

    const disposeTaskStoreCreating = reaction(
      () => taskStore.creating,
      creating => {
        if (creating.success) {
          toDosLocalStore!._fetchEvents();
        }
      },
    );

    const disposeTaskStoreUpdating = reaction(
      () => taskStore.updating,
      updating => {
        if (updating.success) {
          const index = toDosLocalStore!.tasks.findIndex(
            task => task._id === updating.entity!._id,
          );

          if (index >= 0) {
            toDosLocalStore!.tasks[index] = updating.entity! as Task;
          }
        }
      },
    );

    const disposeConferenceStoreCreating = reaction(
      () => conferenceStore.coach.creating,
      creating => {
        if (creating.success) {
          toDosLocalStore!._fetchEvents();
        }
      },
    );

    const disposeConferenceStoreUpdating = reaction(
      () => conferenceStore.coach.updating,
      updating => {
        if (updating.success) {
          const index = toDosLocalStore!.conferences.findIndex(
            conf => conf._id === updating.entity!._id,
          );

          if (index >= 0) {
            toDosLocalStore!.conferences[index] =
              updating.entity! as IToDosLocalStore['conferences'][number];
          }
        }
      },
    );

    const disposeConferenceStoreDeleting = reaction(
      () => conferenceStore.coach.deleting,
      deleting => {
        if (deleting.success) {
          const index = toDosLocalStore!.conferences.findIndex(
            conf => conf._id === deleting.entity!._id,
          );

          if (index >= 0) {
            toDosLocalStore!.conferences.splice(index, 1);
          }
        }
      },
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    if (course) {
      toDosLocalStore!.setCourse(course);
    }

    if (location.search) {
      // @ts-ignore
      if (location.state && location!.state!.task) {
        // @ts-ignore
        const urlUserTask = location!.state!.task! as TaskT;

        localAppStore?.setIsTopNotificationsOpen(false);

        const newDate = dayjs(
          datetimeObjToISOString(urlUserTask.start_date),
        ).toDate();

        localStore.setUrlClientTaskId(urlUserTask);
        toDosLocalStore!.setClickableEvent();
        toDosLocalStore!.setSelectedDate(newDate);

        localStore.setSelectUserTask(null);
        localStore.setSelectConference(null);
      }
    } else {
      toDosLocalStore?.setClickableEvent();
    }

    toDosLocalStore!._fetchEvents();

    return () => {
      disposeObserve();
      disposeTaskStoreCreating();
      disposeTaskStoreUpdating();
      disposeConferenceStoreCreating();
      disposeConferenceStoreUpdating();
      disposeConferenceStoreDeleting();
      disposeObserveMarkedDates();

      if (localStore.fetchTimeOutId) {
        clearTimeout(localStore.fetchTimeOutId);
      }

      clearTimeout(toDosLocalStore?._fetchEventsOnCurrentMonthChangeTimeout);
      clearTimeout(toDosLocalStore?.clickableScrollTimerId);

      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (
      msg.event.type.includes('conference') ||
      msg.event.type.includes('task')
    ) {
      toDosLocalStore!._fetchEvents();
    }
  };

  const handleOnPrevDayClick = () => {
    toDosLocalStore?.setClickableEvent();
    toDosLocalStore?.delDay();
  };

  const handleOnNextDayClick = () => {
    toDosLocalStore?.setClickableEvent();
    toDosLocalStore?.addDay();
  };

  const handleOnConferenceEditPress = (conference: IEvent) => {
    _onConferenceEditPress(conference);
  };

  const _onConferenceEditPress = (conference: IEvent) => {
    contextMenu.hideAll();
    localStore.setSelectEvent(conference);
    localStore.setIsConference(true);

    if (cruEventModalRef.current) {
      cruEventModalRef.current.show();
    }
  };

  const handleOnTaskEditPress = (task: IEvent) => {
    _onTaskEditPress(task);
  };

  const _onTaskEditPress = (task: IEvent) => {
    contextMenu.hideAll();
    localStore.setSelectEvent(task);
    localStore.setIsConference(false);

    if (cruEventModalRef.current) {
      cruEventModalRef.current.show();
    }
  };

  const handleOnUpdateEvent = () => {
    toDosLocalStore?._fetchEvents();

    if (cruEventModalRef.current) {
      cruEventModalRef.current.hide();
    }
  };

  const handleOnClickCalendarBut = () => {
    if (localStore.isShowCalendar) {
      localStore.setIsShowCalendar(false);
    } else {
      localStore.setIsShowCalendar(true);
    }
  };

  const closeCalendar = () => {
    localStore.setIsShowCalendar(false);
  };

  const calendarDayClick = (newDate: Date) => {
    localStore.setIsShowCalendar(false);
    toDosLocalStore!.setClickableEvent();
    toDosLocalStore!.setSelectedDate(newDate);

    const nearDay = dayjs(toDosLocalStore!.setGetNearestDate(newDate)).toDate();

    localStore.setIsShowCalendar(false);
    toDosLocalStore!.setSelectedDate(nearDay);
  };

  const handleOnScrollDayList = () => {
    localStore.setIsShowCalendar(false);
  };

  const handleOnChangeInView = (nameDay: string) => {
    return (inView: boolean) => {
      if (!toDosLocalStore!.clickableEvent && inView) {
        const day = dayjs(nameDay).toDate();

        toDosLocalStore!.setSelectedDate(day);
      }

      if (inView) {
        const day = dayjs(nameDay).toDate();

        toDosLocalStore!.setSelectedDateWhenScroll(day);
      }
    };
  };

  const handleOnChangeInPrevMess = () => {};

  const handleOnSelectCourse = (courses: CourseT[]) => {
    const [newCourse] = courses;

    toDosLocalStore!.setCourse(newCourse as CourseT);
    localStore.setSquadListOptions([
      {
        id: newCourse._id,
        val: `${(newCourse.program || {}).title}: ${getCourseDurationString(
          newCourse,
        )}`,
      },
    ]);
    toDosLocalStore!._fetchEvents();
  };

  const _onTaskPress = (task: Task) => {
    if (user) {
      localStore.setSelectUserTask(null);
      localStore.setSelectConference(null);
      localStore.setSelectTask(task);
    }
  };

  const onUserPress = (userTask: TaskT) => {
    if (
      !localStore.selectUserTask ||
      (localStore.selectUserTask &&
        localStore.selectUserTask._id !== userTask._id)
    ) {
      localStore.setSelectUserTask(userTask);
    }
  };

  const onConferencePress = (conference: Conference) => {
    if (user) {
      localStore.setSelectUserTask(null);
      localStore.setSelectTask(null);
      localStore.setSelectConference(conference);
    }
  };

  const onDeleteConferenceButtonPress = async (conference: Conference) => {
    contextMenu.hideAll();

    getCustomConfirmAlert({
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteConference(conference),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteConference = async (conference: Conference) => {
    try {
      await conferenceStore.coach.delete(conference);

      let programTitle = 'Program not found';

      if (conference.course && conference.course.program) {
        programTitle = conference.course.program.title;
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle,
          actionType: 'delete',
          targetType: 'conference',
          isIndividual: !!(conference.course && conference.course.client_id),
        })
      ) {
        if (conference.program_id) {
          updateConferencesSchedule({
            programId: conference.program_id,
            conferences: [
              {
                title: '',
                description: '',
                day: 0,
                time: 0,
                duration: 0,
                uuid: conference.uuid,
                speaker_ids: [],
              },
            ],
          });
        }
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => deleteConference(conference),
            type: 'confirm',
          },
        ],
      });
    }

    localStore.setSelectUserTask(null);
    localStore.setSelectTask(null);
    localStore.setSelectConference(null);
    toDosLocalStore!._fetchEvents();
  };

  const onCancelConferenceButtonPress = async (conference: Conference) => {
    contextMenu.hideAll();

    getCustomConfirmAlert({
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => cancelConference(conference),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const cancelConference = async (conference: Conference) => {
    try {
      const isCoach = conference.coach_ids.includes(user!._id);

      if (isCoach) {
        await conferenceStore.coach.delete(conference);
      } else {
        await conferenceStore.client.delete(conference);
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => deleteConference(conference),
            type: 'confirm',
          },
        ],
      });
    }

    localStore.setSelectUserTask(null);
    localStore.setSelectTask(null);
    localStore.setSelectConference(null);
    toDosLocalStore!._fetchEvents();
  };

  const onDeleteTaskButtonPress = async (task: Task) => {
    contextMenu.hideAll();

    getCustomConfirmAlert({
      message: labelDeleteTask(),
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteTask(task),
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTask = async (task: Task) => {
    try {
      // TODO: Add ability to delete group of tasks coach.tasks.delete_many
      const tasksToDeleteResult = await apiRequest({
        method: 'coach.tasks.coach.list',
        params: {
          limit: 200,
          query: [
            ['uuid', '==', task.uuid],
            ['course_id', '==', task.course_id],
          ],
        },
      });

      const canDeleteTask = tasksToDeleteResult._items.every(
        (item: Task) => !item.done,
      );

      if (!canDeleteTask) {
        await getCustomConfirmAlert({
          title: t('shared.message.deleting_error'),
          message: t([I18N_SCOPE, 'cant_delete_task_error']),
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
              type: 'confirm',
            },
          ],
        });

        return;
      }

      await taskStore.deleteBatch(
        tasksToDeleteResult._items.map((item: Task) => ({
          entity: item,
        })),
      );

      runInAction(() => {
        const index = toDosLocalStore!.tasks.findIndex(
          item => item._id === task._id,
        );

        if (index >= 0) {
          toDosLocalStore!.tasks.splice(index, 1);
        }
      });

      let programTitle = 'Program not found';

      if (task.course && task.course.program) {
        programTitle = task.course.program.title;
      }

      const taskInSchedule = await isTaskInSchedule({
        programId: task.program_id,
        tasks: [
          {
            title: '',
            description: '',
            day: 0,
            time: 0,
            duration: 0,
            uuid: task.uuid,
          },
        ],
      });

      if (taskInSchedule) {
        if (
          await shouldUpdateProgramSchedule({
            programTitle,
            actionType: 'delete',
            targetType: 'task',
            isIndividual: !!(task.course && task.course.client_id),
          })
        ) {
          updateTasksSchedule({
            programId: task.program_id,
            tasks: [
              {
                title: '',
                description: '',
                day: 0,
                time: 0,
                duration: 0,
                uuid: task.uuid,
              },
            ],
          });
        }
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => deleteTask(task),
            type: 'confirm',
          },
        ],
      });
    }

    localStore.setSelectUserTask(null);
    localStore.setSelectTask(null);
    localStore.setSelectConference(null);
    toDosLocalStore!._fetchEvents();
  };

  const menuTaskId = 'menuTaskId';

  const handleGetTaskContextMenu =
    () => (task: Task, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      contextMenu.show({
        id: menuTaskId,
        event: event,
        props: {
          task,
        },
      });
    };

  const menuConferenceId = 'menuConferenceId';
  const menuClientConferenceId = 'menuClientConferenceId';

  const handleGetConferenceContextMenu =
    () =>
    (
      conference: Conference,
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      contextMenu.show({
        id:
          conference.created_by === 'client'
            ? menuClientConferenceId
            : menuConferenceId,
        event: event,
        props: {
          conference,
        },
      });
    };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const TaskContextMenu = () => (
    <div onClick={handleClickMenu}>
      <Menu
        id={menuTaskId}
        animation={animation.fade}
        className="contextMenu"
        style={{zIndex: 1000}}
      >
        <Item onClick={handleEditTask} className="contextMenuItem">
          Edit
        </Item>
        <Item onClick={handleDeleteTask} className="contextMenuItem">
          Delete
        </Item>
      </Menu>
    </div>
  );

  const ConferenceContextMenu = () => {
    return (
      <div onClick={handleClickMenu}>
        <Menu
          id={menuConferenceId}
          animation={animation.fade}
          className="contextMenu"
          style={{zIndex: 1000}}
        >
          <Item onClick={handleEditConference} className="contextMenuItem">
            Edit
          </Item>
          <Item onClick={handleDeleteConference} className="contextMenuItem">
            Delete
          </Item>
        </Menu>
      </div>
    );
  };

  const ClientConferenceContextMenu = () => {
    return (
      <div onClick={handleClickMenu}>
        <Menu
          id={menuClientConferenceId}
          animation={animation.fade}
          className="contextMenu"
          style={{zIndex: 1000}}
        >
          <Item onClick={handleCancelConference} className="contextMenuItem">
            Cancel
          </Item>
        </Menu>
      </div>
    );
  };

  const handleEditTask = args => {
    const task = args.props.task as Conference | Task;

    handleOnTaskEditPress(task as Task);
  };

  const handleDeleteTask = args => {
    const task = args.props.task as Task;

    onDeleteTaskButtonPress(task as Task);
  };

  const handleEditConference = args => {
    const conference = args.props.conference as Conference;

    handleOnConferenceEditPress(conference as Conference);
  };

  const handleDeleteConference = args => {
    const conference = args.props.conference as Conference;

    onDeleteConferenceButtonPress(conference as Conference);
  };

  const handleCancelConference = args => {
    const conference = args.props.conference as Conference;

    onCancelConferenceButtonPress(conference as Conference);
  };

  const handleOnStartConferenceButtonPress = (conference: ConferenceT) => {
    getCustomConfirmAlert({
      title: t(['Conference', 'start_conference_question']),
      buttons: [
        {
          label: 'No',
          onClick: () => {},
        },
        {
          label: 'Yes',
          onClick: () => {
            history.push({
              pathname: `/conferences/${conference._id}`,
            });
          },
          type: 'confirm',
        },
      ],
    });
  };

  const _renderDayEvent = (item: IEvent) => {
    return (
      <Observer>
        {() => {
          const isConference = item._id.split(':')[0] === 'conference';

          let onConferenceMoreButtonPress = false;

          let onTaskMoreButtonPress = false;

          if (isConference) {
            const actions = getConferenceActions(item as Conference);

            if (actions.length) {
              onConferenceMoreButtonPress = true;
            }
          } else if (
            user &&
            item.coach_ids.includes(user._id) &&
            item.course &&
            courseIsEditable(item.course!)
          ) {
            onTaskMoreButtonPress = true;
          }

          return isConference ? (
            <ConferencesListItem
              conference={item as Conference}
              showCourseName={!course}
              isSelect={localStore.selectConference?._id === item._id}
              onPress={onConferencePress}
              onMoreButtonPress={
                onConferenceMoreButtonPress
                  ? handleGetConferenceContextMenu()
                  : undefined
              }
              className={styles.conferenceContainer}
              onStartConferenceButtonPress={handleOnStartConferenceButtonPress}
            />
          ) : (
            <TasksListItem
              task={item as Task}
              onPress={_onTaskPress}
              isSelect={localStore.selectTask?._id === item._id}
              onMoreButtonPress={
                onTaskMoreButtonPress ? handleGetTaskContextMenu() : undefined
              }
              showCourseName={!course}
              className={styles.taskContainer}
            />
          );
        }}
      </Observer>
    );
  };

  const handleCloseSelect = () => {
    toDosLocalStore!.setCourse(null);
    localStore.setSquadListOptions([
      {id: '1', val: t(['Common', 'all_programs'])},
    ]);
    toDosLocalStore!._fetchEvents();
  };

  const handleOnClickClear = () => {
    runInAction(() => {
      handleCloseSelect();
    });
  };

  return (
    <Observer>
      {() => (
        <div className={`ToDos ${styles.ToDos}`}>
          <div className="columnsContainer">
            <div className="firstColumnContainer">
              <div className={styles.header}>
                <h1 className={styles.title}>To-dos</h1>
                <ToDosMenu />
              </div>
            </div>
          </div>
          <div className="columnsContainer percent100H">
            <div className={`firstColumnContainer ${styles.columnContainer}`}>
              <div className={styles.topMenu}>
                <div className={styles.topMenuFirst}>
                  <div className={styles.calendarButContainer}>
                    <div
                      className={`${styles.topMenuItem} ${styles.calendarBut} ${
                        localStore.isShowCalendar ? styles.calendarCloseBut : ''
                      }`}
                      onClick={handleOnClickCalendarBut}
                    >
                      {localStore.isShowCalendar ? (
                        <IconClose
                          customClass={`${styles.calendarIcon} ${styles.calendarIconClose}`}
                        />
                      ) : (
                        <IconCalendar className={styles.calendarIcon} />
                      )}
                    </div>
                    <FadeComponent show={localStore.isShowCalendar}>
                      <div
                        className={`${styles.calendarContainer} ${
                          localStore.isShowCalendar ? '' : styles.hide
                        }`}
                      >
                        {localStore.isShowCalendar ? (
                          <MyCalendar
                            startDate={dayjs(
                              toDosLocalStore!.selectedDate,
                            ).toDate()}
                            course={toDosLocalStore!.course}
                            outsideClick={closeCalendar}
                            dayClick={calendarDayClick}
                          />
                        ) : null}
                      </div>
                    </FadeComponent>
                  </div>

                  <div
                    className={`${styles.topMenuItem} ${styles.calendarDay}`}
                  >
                    <div
                      className={styles.prevDayContainer}
                      onClick={handleOnPrevDayClick}
                    >
                      <div className={styles.prevDay}>
                        <IconPrev viewBox="5 5 20 20" />
                      </div>
                    </div>

                    <div className={styles.day}>
                      {dayjs(toDosLocalStore!.selectedDate).format('MMMM D')}
                    </div>
                    <div
                      className={styles.nextDayContainer}
                      onClick={handleOnNextDayClick}
                    >
                      <div className={styles.nextDay}>
                        <IconNext viewBox="7 5 20 20" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.topMenuSecond}>
                  <SelectCourseContainer
                    onSelectCourse={handleOnSelectCourse}
                    options={localStore.squadListOptions}
                    defaultId={
                      localStore.squadListOptions.slice()[0]
                        ? localStore.squadListOptions.slice()[0].id
                        : ''
                    }
                    className={styles.calendarFilter}
                    onCloseSelect={handleCloseSelect}
                  />
                  {toDosLocalStore?.course ? (
                    <div
                      className={styles.courseFilterContainerClear}
                      onClick={handleOnClickClear}
                    >
                      {labelClear()}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.eventsListContainer}>
                {!toDosLocalStore?.isLoaded ? (
                  <Loader />
                ) : (
                  <div
                    className={styles.eventsList}
                    onScroll={handleOnScrollDayList}
                  >
                    <InView onChange={handleOnChangeInPrevMess}>
                      <div />
                    </InView>
                    {Object.keys(toDosLocalStore?.markedDates)
                      .sort()
                      .map(day => {
                        const now = dayjs().format(DATE_FORMAT);
                        const isToday = day === now;
                        const dayNum = dayjs(day).format('DD');
                        const dayName = dayjs(day).format('ddd');

                        if (
                          !(toDosLocalStore?.markedDates[day]
                            .events as IEvent[])
                        ) {
                          return null;
                        }

                        return (
                          <InView
                            key={day}
                            as="div"
                            onChange={handleOnChangeInView(
                              dayjs(day).format(DATE_FORMAT),
                            )}
                          >
                            <div className={styles.dayEventsContainer}>
                              {toDosLocalStore.selectedDate === day &&
                              toDosLocalStore.clickableEvent ? (
                                <ScrollToElement isSmooth />
                              ) : null}
                              <div className={styles.dayContainer}>
                                <div className={styles.day}>
                                  <div className={styles.dayName}>
                                    {dayName}
                                  </div>
                                  <div className={styles.dayNum}>{dayNum}</div>
                                  {isToday ? (
                                    <div className={styles.isToday}>Today</div>
                                  ) : null}
                                </div>
                              </div>
                              <div className={styles.eventsContainerInList}>
                                {(
                                  toDosLocalStore?.markedDates[day]
                                    .events as IEvent[]
                                ).map(theEvent => {
                                  return (
                                    <div key={theEvent._id}>
                                      {_renderDayEvent(theEvent)}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </InView>
                        );
                      })}
                  </div>
                )}
              </div>
              <Modal
                title={t(
                  [CRU_CALENDAR_EVENT_I18N_SCOPE, 'title', 'update'].concat(
                    localStore.selectEvent
                      ? [localStore.selectEvent!._id.split(':')[0]]
                      : [],
                  ),
                )}
                ref={cruEventModalRef}
              >
                <CRUCalendarEvent
                  event={localStore.selectEvent!}
                  course={toDosLocalStore!.course}
                  onUpdate={handleOnUpdateEvent}
                />
              </Modal>
            </div>
            <div className="otherColumnsContainer">
              <div className={styles.eventsListContainer}>
                {localStore.selectTask ? (
                  <div className={styles.eventsList}>
                    <TaskEl
                      taskId={localStore.selectTask._id}
                      task={localStore.selectTask}
                      forCoach={localStore.selectTask.coach_ids.includes(
                        user!._id,
                      )}
                      noUpdate
                      noDelete
                      onUserPress={onUserPress}
                    />
                  </div>
                ) : null}
              </div>
              <div className={styles.eventsListContainer}>
                {localStore.selectUserTask ? (
                  <div className={styles.eventsList}>
                    <ClientTask
                      task={localStore.selectUserTask as ITask & TaskExpanded}
                      taskId={localStore.selectUserTask._id}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <TaskContextMenu />
          <ConferenceContextMenu />
          <ClientConferenceContextMenu />
        </div>
      )}
    </Observer>
  );
};

export default React.memo(ToDos);
