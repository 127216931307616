import type {FC} from 'react';
import React, {memo} from 'react';

import {MainContainerWithMenu} from '@src/layouts';
import {ChatsNewLayout} from '@src/modules';
import ChatsState from '@src/modules/Chats/context/ChatsState';

interface Props {}

const NewLayout: FC<Props> = () => {
  return (
    <MainContainerWithMenu isWithNewCrumbs allHeight>
      <ChatsState>
        <ChatsNewLayout />
      </ChatsState>
    </MainContainerWithMenu>
  );
};

export default memo(NewLayout);
