import type {FC} from 'react';
import React, {memo} from 'react';
import {Observer} from 'mobx-react';

import {runInAction} from 'mobx';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {
  labelAddChoices,
  labelAddOther,
  labelFreeUserResponse,
  labelNewAnswer,
  labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters,
  labelTypeAnswer,
  labelTypeOption,
} from '@src/common/i18n/i18nQuestionnaire';
import {SwitchEditInput} from '@src/components/CustomForm';
import {IconTrash} from '@src/components/icons';
import {ALIAS_TYPES} from '@src/modules/Questionnaire/utils';

import styles from './../../styles.module.css';

interface Props {
  options?: string[];
  sectionId?: string | null;
  isOpen: boolean;
  numQuestion: number;
  isRadio?: boolean;
  saveOptions: (newOptions: string[]) => void;
  delOption: (numDelOption: number) => void;
  updateTypeOpen?: (
    newType: QuestionnaireQuestion['type'],
    numQuestion: number,
  ) => void;
}

const TemplateChoice: FC<Props> = ({
  options = [],
  isRadio = false,
  isOpen,
  numQuestion,
  sectionId,
  saveOptions,
  delOption,
  updateTypeOpen,
}) => {
  let openInput: (() => void) | null = null;

  const handleGetOpenFn = (fn: () => void) => {
    openInput = fn;
  };

  const handleClickTypeQuestion = () => {
    if (openInput) {
      openInput();
    }
  };

  const handleUpdate = (numOption: number) => {
    return (newOptionText: string) => {
      runInAction(() => {
        options[numOption] = newOptionText;
      });

      saveOptions(options);
    };
  };

  const handleOnClickAddChoise = () => {
    runInAction(() => {
      options.push(labelNewAnswer());
    });

    saveOptions(options);
  };

  const handleDdelOption = (numDelOption: number) => {
    return () => {
      delOption(numDelOption);
    };
  };

  const handleDdelOpenEl = () => {
    if (updateTypeOpen) {
      if (isRadio) {
        updateTypeOpen(ALIAS_TYPES.singleChoice, numQuestion);
      } else {
        updateTypeOpen(ALIAS_TYPES.multipleChoice, numQuestion);
      }
    }
  };

  const handleOnClickAddOther = () => {
    if (updateTypeOpen) {
      if (isRadio) {
        updateTypeOpen(ALIAS_TYPES.singleChoiceOpen, numQuestion);
      } else {
        updateTypeOpen(ALIAS_TYPES.multipleChoiceOpen, numQuestion);
      }
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`TemplateChoice ${styles.TemplateChoice}`}>
          <ul>
            {options.slice().map((option, index) => (
              <li key={`${sectionId}-${numQuestion}-${index}`}>
                <div>
                  {options.length > 1 && (
                    <div
                      className={styles.optionTrash}
                      onClick={handleDdelOption(index)}
                    >
                      <IconTrash />
                    </div>
                  )}
                </div>

                <div className={isRadio ? styles.radioBut : styles.checkBox} />
                <SwitchEditInput
                  inputVal={option}
                  label={labelTypeAnswer()}
                  customClassLabel="QStandardLabel"
                  customClassCont="QStandardContainer"
                  customClassInput="QStandardInput noPurple"
                  test={/^.{1,255}$/}
                  defaultLabel={labelNewAnswer()}
                  isClearDefault
                  isOpenDefault
                  testError={labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters()}
                  getOpenFn={handleGetOpenFn}
                  onUpdateVal={handleUpdate(index)}
                >
                  <div className={styles.textOptionsContainer}>
                    {option ? (
                      option
                    ) : (
                      <div onClick={handleClickTypeQuestion}>
                        {labelTypeOption()}
                      </div>
                    )}
                  </div>
                </SwitchEditInput>
              </li>
            ))}
          </ul>
          {isOpen && (
            <ul>
              <li>
                <div className={styles.optionTrash} onClick={handleDdelOpenEl}>
                  <IconTrash />
                </div>
                <div className={isRadio ? styles.radioBut : styles.checkBox} />
                <div className={styles.textOptionsContainer}>
                  <div>{labelFreeUserResponse()}</div>
                </div>
              </li>
            </ul>
          )}
          <div className={styles.footer}>
            <div className={styles.footerBut} onClick={handleOnClickAddChoise}>
              <span>+</span> {labelAddChoices()}
            </div>
            {!isOpen && (
              <div className={styles.footerBut} onClick={handleOnClickAddOther}>
                <span>+</span> {labelAddOther()}
              </div>
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(TemplateChoice);
