import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useHistory} from 'react-router-dom';

import type {CollectionStore} from '@yourcoach/shared/api';
import type {Goal} from '@yourcoach/shared/api/goal';
import type {ProgramType} from '@yourcoach/shared/api/program';
import {ProgramTypeEnum} from '@yourcoach/shared/api/program';
import {logger} from '@yourcoach/shared/utils/logger';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import {emitter} from '../../../widget/src/utils';

import type {ProgramT} from '.';
import Complete from './Complete';
import Description from './Description';
import Details, {PROGRAM_TYPE_CHANGE_EVENT} from './Details';
import Goals from './Goals';
import Library from './Library';
import Schedule from './Schedule';
import {I18N_SCOPE} from '.';

export type TabProps = {
  program: ProgramT | null;
  userIsOwner: boolean;
  isOfferedProgram: boolean;
  shouldCloneProgram: boolean;
  cloneProgram: () => void;
  isActive: boolean;
  isIndividual: boolean;
  goalsStore?: CollectionStore<Goal>;
};

export type TabRef = {
  getData: (direction?: 'back' | 'next') => any;
};

export type Tab = {
  key:
    | 'description'
    | 'details'
    | 'schedule'
    | 'library'
    | 'goals'
    | 'complete';
  title: string;
  content: React.ReactElement<TabProps>;
};

const useTabs = (
  program: ProgramT | null,
  activeTabIndex: number,
  goalsStore: CollectionStore<Goal>,
) => {
  const history = useHistory();

  const refs = useRef({
    description: React.createRef<TabRef>(),
    details: React.createRef<TabRef>(),
    schedule: React.createRef<TabRef>(),
    library: React.createRef<TabRef>(),
    goals: React.createRef<TabRef>(),
    complete: React.createRef<TabRef>(),
  }).current;

  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const userIsOwner = useMemo(
    () =>
      program && program._id
        ? currentUserStore.user!._id === program.user_id
        : true,
    [currentUserStore.user, program],
  );

  const shouldCloneProgram = useMemo(
    () =>
      !!(
        program &&
        program._id &&
        program.edition_ids.length &&
        program.status !== 'draft'
      ),
    [program],
  );

  const isOfferedProgram = useMemo(
    () => !!(program && program.offer_id),
    [program],
  );

  const [isIndividual, setIsIndividual] = useState(false);

  useEffect(() => {
    const lastEdition =
      program && program.editions[program.editions.length - 1];

    if (lastEdition) {
      setIsIndividual(lastEdition.group_size === 1);
    }
  }, [program]);

  useEffect(() => {
    const onProgramTypeChange = (type: ProgramType) => {
      setIsIndividual(type.id === ProgramTypeEnum.INDIVIDUAL.id);
    };

    emitter.on(PROGRAM_TYPE_CHANGE_EVENT, onProgramTypeChange);

    return () => {
      emitter.off(PROGRAM_TYPE_CHANGE_EVENT, onProgramTypeChange);
    };
  }, []);

  const cloneProgram = useCallback(async () => {
    if (!program) {
      return;
    }

    await getCustomConfirmAlert({
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            logger.event('program_cloned');

            history.replace(`/program/clone/${program._id}`);
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }, [history, program]);

  const tabs = useMemo(() => {
    const tabProps: TabProps = {
      program,
      userIsOwner,
      isOfferedProgram,
      shouldCloneProgram,
      cloneProgram,
      isActive: false,
      isIndividual,
    };

    return [
      {
        key: 'description',
        title: t([I18N_SCOPE, 'description_tab']),
        content: (
          <Description
            {...tabProps}
            ref={refs.description}
            isActive={activeTabIndex === 0}
          />
        ),
      },
      {
        key: 'details',
        title: t([I18N_SCOPE, 'details_tab']),
        content: (
          <Details
            {...tabProps}
            ref={refs.details}
            isActive={activeTabIndex === 1}
          />
        ),
      },
      {
        key: 'schedule',
        title: t([I18N_SCOPE, isIndividual ? 'tasks_tab' : 'schedule_tab']),
        content: (
          <Schedule
            {...tabProps}
            ref={refs.schedule}
            isActive={activeTabIndex === 2}
          />
        ),
      },
      {
        key: 'library',
        title: t([I18N_SCOPE, 'library_tab']),
        content: (
          <Library
            {...tabProps}
            ref={refs.library}
            isActive={activeTabIndex === 3}
          />
        ),
      },
      {
        key: 'goals',
        title: t([I18N_SCOPE, 'goals_tab']),
        content: (
          <Goals
            {...tabProps}
            ref={refs.goals}
            isActive={activeTabIndex === 4}
            goalsStore={goalsStore}
          />
        ),
      },
      {
        key: 'complete',
        title: t([I18N_SCOPE, 'complete_tab']),
        content: (
          <Complete
            {...tabProps}
            ref={refs.complete}
            isActive={activeTabIndex === 5}
          />
        ),
      },
    ] as Tab[];
  }, [
    program,
    userIsOwner,
    isOfferedProgram,
    shouldCloneProgram,
    cloneProgram,
    isIndividual,
    refs.description,
    refs.details,
    refs.schedule,
    refs.library,
    refs.goals,
    refs.complete,
    activeTabIndex,
    goalsStore,
  ]);

  return {tabs, refs};
};

export default useTabs;
