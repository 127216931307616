import type {FC} from 'react';
import React, {memo} from 'react';

import BadgeContainer from '../BadgeContainer/BadgeContainer';
import {IconBell} from '../icons';

import styles from './styles.module.css';

interface Props {
  numMess: number;
  onClick?: () => void;
}

const TopNotifications: FC<Props> = ({numMess, onClick}) => {
  const handleOnClick = () => {
    onClick ? onClick() : null;
  };

  return (
    <div
      className={`TopNotifications ${styles.TopNotifications}`}
      onClick={handleOnClick}
    >
      <BadgeContainer
        className={styles.BellConteiner}
        numMess={numMess}
        badgeClassName={styles.numNotif}
      >
        <IconBell />
      </BadgeContainer>
    </div>
  );
};

export default memo(TopNotifications);
