import type {FC} from 'react';
import React, {memo, useEffect, useState} from 'react';

import {MAX_TABLET_BREAKPOINT, MIN_MOBILE_BREAKPOINT} from '../../const';
import {useWindowDimensions} from '../../hooks/useWindowDimensions';
import ModalWindowContainer from '../../modules/ModalWindowContainer';
import AnimateShow from '../AnimateShow/AnimateShow';
import CustomConfetti from '../CustomConfetti/CustomConfetti';
import Modal from '../Modal/Modal';

import styles from './ModalAnimateWin.module.css';

interface Props {
  children: any;
  showModal: boolean;
  hideCloseBut?: boolean;
  className?: string;
  classNameCloseBut?: string;
  isBody?: boolean;
  classNameBody?: string;
  header?: string;
  classNameHeader?: string;
  isCongratAnim?: boolean;
  closeModalHandler: () => void;
  type?: string;
}

const MIN_HEIGHT = 500;
const MAX_MOBILE_HEIGHT = 650;

const ModalAnimateWin: FC<Props> = ({
  children,
  showModal,
  hideCloseBut,
  className = '',
  isBody = false,
  isCongratAnim = false,
  classNameBody = '',
  header = '',
  classNameHeader = '',
  classNameCloseBut = '',
  closeModalHandler,
  type = '',
}) => {
  const [isOpen, togOpen] = useState(showModal);
  const [isAnimate, togAnimate] = useState(false);
  const {width, height} = useWindowDimensions();

  const closeModal = () => {
    closeModalHandler();
  };

  const handleCallBackEndAnim = () => {
    togOpen(false);
  };

  useEffect(() => {
    if (showModal) {
      togOpen(showModal);
      togAnimate(true);
    } else {
      togAnimate(false);
    }

    return () => {};
  }, [showModal]);

  return (
    <>
      {isOpen ? (
        <Modal>
          {isCongratAnim ? <CustomConfetti /> : null}
          <AnimateShow
            isAnimateShow={isAnimate}
            callBackHide={handleCallBackEndAnim}
          >
            <ModalWindowContainer
              closeModalWindowContainer={hideCloseBut ? undefined : closeModal}
              className={className}
              classNameCloseBut={classNameCloseBut}
              styles={
                type === 'schedule' &&
                width >= MIN_MOBILE_BREAKPOINT &&
                width <= 799
                  ? {padding: '15px'}
                  : type === 'schedule' && width >= 800
                  ? {width: 'auto'}
                  : type === 'confirmation-schedule' &&
                    width >= MIN_MOBILE_BREAKPOINT &&
                    width <= 799 &&
                    height >= MIN_HEIGHT &&
                    height <= MAX_MOBILE_HEIGHT
                  ? {width: '90vw', height: '60vh', padding: '15px'}
                  : type === 'confirmation-schedule' &&
                    width >= 799 &&
                    width <= 1250 &&
                    height >= MAX_MOBILE_HEIGHT
                  ? {width: '35vw', minHeight: '300px', padding: '15px'}
                  : type === 'confirmation-schedule' &&
                    width >= MIN_MOBILE_BREAKPOINT &&
                    width <= 798 &&
                    height >= MAX_MOBILE_HEIGHT
                  ? {width: '90vw', minHeight: '300px', padding: '15px'}
                  : type === 'error'
                  ? {width: 'auto', padding: '24px', paddingTop: '60px'}
                  : {}
              }
            >
              {isBody ? (
                <>
                  <div
                    className={
                      classNameHeader === 'schedule-header'
                        ? styles.ScheduleHeader
                        : ''
                    }
                  >
                    <h2>{header}</h2>
                  </div>
                  <div className={classNameBody}>{children}</div>
                </>
              ) : (
                children
              )}
            </ModalWindowContainer>
          </AnimateShow>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(ModalAnimateWin);
