import type {FC} from 'react';
import React, {memo} from 'react';

import UserProfileImg from '@src/components/UserProfileImg/UserProfileImg';

import CircleBlue from './images/CircleBlue';
import CircleGreen from './images/CircleGreen';
import CircleGrey from './images/CircleGrey';
import styles from './styles.module.css';

interface Props {
  width?: number;
  height?: number;
}

const UserProfileImgDecor: FC<Props> = ({width = 100, height = 100}) => {
  return (
    <div
      className={`MyProfileImg ${styles.MyProfileImgContainer}`}
      style={{width: `${width}px`, height: `${height}px`}}
    >
      <div className={styles.MyProfileAnimate}>
        <div className={styles.containerCircleBlue}>
          <CircleBlue />
        </div>
        <div className={styles.containerCircleGreen}>
          <CircleGreen />
        </div>
        <div className={styles.containerCircleGrey}>
          <CircleGrey />
        </div>
      </div>
      <div className={styles.MyProfileImg}>
        <UserProfileImg titleFontSize="28px" />
      </div>
    </div>
  );
};

export default memo(UserProfileImgDecor);
