import type {FC} from 'react';
import React from 'react';

import styles from './styles.module.css';

interface Props {}

const ArrowSeparator: FC<Props> = () => {
  return (
    <div className={styles.ArrowSeparator}>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70711 12.2929C9.09763 12.6834 9.09763 13.3166 8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L0.585787 7L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683418 9.09763 1.31658 8.70711 1.70711L3.41421 7L8.70711 12.2929Z"
          fill="#819AA9"
        />
      </svg>
    </div>
  );
};

export default ArrowSeparator;
