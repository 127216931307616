import type {FC, MouseEventHandler} from 'react';
import React, {memo, useCallback} from 'react';

import classNames from 'classnames';

import styles from './Collapsible.module.css';

interface Props {
  isOpened: boolean;
  header: React.ReactElement;
  children: React.ReactNode | React.ReactNodeArray;
  onClick?: (data?: any) => void;
  data?: any;
}

const Collapsible: FC<Props> = ({
  isOpened,
  header,
  children,
  onClick,
  data,
}) => {
  // throwback passed data
  const onClickCb: MouseEventHandler = useCallback(() => {
    if (onClick) {
      onClick(data);
    }
  }, [data, onClick]);

  return (
    <div className={classNames(styles.Component, isOpened && styles.opened)}>
      <div className={styles.header} onClick={onClickCb}>
        {header}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default memo(Collapsible);
