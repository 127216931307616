import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Button} from '@yourcoach/shared/uikit/Button';

export const Container = styled.div`
  flex: 1;
  margin-top: 30px;
`;

export const AddNoteButton = styled(Button).attrs({scheme: 'primary'})`
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    fill: ${getColor('icon2')};
    margin-left: 20px;
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${AddNoteButton} {
    margin-top: 16px;
  }
`;

export const List = styled.div`
  margin-top: 20px;
`;
