import React from 'react';

import styled from 'styled-components';

import {Button} from '@yourcoach/shared/uikit/Button';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {buttonWeb, iconBoxWeb} from './styles';
import type {Props, StyledProps} from './types';

const StyledIconButton = styled(Button)<StyledProps>`
  ${buttonWeb};
`;

const StyledIconBox = styled(IconBox)`
  ${iconBoxWeb};
`;

const IconButton: React.FC<WithDomStyleProp<Props>> = ({
  children,
  icon,
  iconFillColor = 'icon2',
  iconBgColor = 'transparent',
  iconCustomSize = 17,
  iconPadding = 0,
  iconVariant = 'square',
  iconSize,
  buttonVariant = 'default',
  scheme = 'primary',
  ...rest
}) => {
  return (
    <StyledIconButton
      {...rest}
      buttonVariant={buttonVariant}
      scheme={buttonVariant === 'rounded' ? 'gray' : scheme}
    >
      <StyledIconBox
        customSize={!iconSize ? iconCustomSize : undefined}
        fill={iconFillColor}
        padding={iconPadding}
        size={iconSize}
        bg={iconBgColor}
        variant={iconVariant}
      >
        {icon}
      </StyledIconBox>
      {children}
    </StyledIconButton>
  );
};

export {IconButton};
