import type {FC} from 'react';
import React, {useCallback, useRef} from 'react';

import VirtualHomeStep from './VirtualHomeStep';

interface Props {
  initialValue: any;
  onChange: (fieldName, value: Object, clearNext: boolean) => void;
  fieldName: string;
  title: string;
  description?: string | null;
  hideButtons: boolean;
  step: number;
  onBack: () => void;
  onContinue: (fieldName?: string) => void;
  autoNext?: boolean;
  clearNext?: boolean;
  editorRef?: React.RefObject<any>;
  children: React.ReactNode | React.ReactNode[];
}

const EditorStep: FC<Props> = ({
  initialValue,
  onChange,
  fieldName,
  autoNext = false,
  clearNext = false,
  editorRef,
  children,
  ...props
}) => {
  const value = useRef(initialValue || undefined);
  const valueChanged = useRef(false);

  const onValueChange = useCallback(
    e => {
      if (e.currentTarget) {
        value.current = e.currentTarget.value;
      } else {
        value.current = e;
      }

      valueChanged.current = true;
      onChange(fieldName, value.current, clearNext);

      if (autoNext && valueChanged.current) {
        props.onContinue();
      }
    },
    [autoNext, clearNext, fieldName, onChange, props],
  );

  const validateAndContinue = useCallback(() => {
    if (!editorRef || editorRef.current?.validate()) {
      props.onContinue(fieldName);
    }
  }, [editorRef, fieldName, props]);

  return (
    <VirtualHomeStep {...props} onContinue={validateAndContinue}>
      {React.Children.map(children, (child, index) =>
        index === 0
          ? React.cloneElement(child as React.ReactElement, {
              value: value.current,
              onChange: onValueChange,
            })
          : null,
      )}
    </VirtualHomeStep>
  );
};

export default EditorStep;
