import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import Loader from '@src/components/Loader/Loader';

import LibraryContext from '../context/LibraryContext';
import type {ILibraryLocalStore} from '../context/useLibraryLocalStore';
import styles from '../ListFolders/styles.module.scss';

interface Props {
  children: any;
  className?: string;
}

const ListFolderContainer: FC<Props> = ({children, className}) => {
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);

  return (
    <Observer>
      {() => (
        <div className={`ListFolders ${styles.ListFolders} ${className}`}>
          {libraryStory!.loading ? (
            <Loader />
          ) : (
            <div className={styles.ListFolderContainer}>{children}</div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(ListFolderContainer);
