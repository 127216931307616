import type {FC} from 'react';
import React, {memo} from 'react';

import {
  labelCreateNewQuestionnaire,
  labelCreateQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton} from '@src/components/CustomForm';
import {ColorWrapper} from '@src/layouts';
import {PATH_TYPE} from '@src/routes/utils';

import questionnaireAppStore from '../context/qAppStore';
import QTemplateList from '../QTemplateList/QTemplateList';

import styles from './styles.module.css';

interface Props {}

const QTemplates: FC<Props> = () => {
  const redirect = useAppRedirect();

  const handleClickCreateNewQ = () => {
    questionnaireAppStore!.setUserTemplateTitleAndDesc('', '');

    redirect(`/${PATH_TYPE.questionnaire}/create`);
  };

  return (
    <div className={`QTemplates ${styles.QTemplates}`}>
      <ColorWrapper containerClass="pl50" className={styles.mt30}>
        <div className={styles.header}>
          <h1>{labelCreateQuestionnaire()}</h1>
          <div>
            <CustomButton
              type="button"
              classButton={styles.headerBut}
              onClick={handleClickCreateNewQ}
            >
              {labelCreateNewQuestionnaire()}
            </CustomButton>
          </div>
        </div>
      </ColorWrapper>
      <ColorWrapper
        containerClass="pl50"
        colorClass="mintGreen percent100H"
        className="colorAllHeight"
      >
        <QTemplateList />
      </ColorWrapper>
    </div>
  );
};

export default memo(QTemplates);
