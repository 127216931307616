import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import ReactDOM from 'react-dom';
import InView from 'react-intersection-observer';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react-lite';

import classNames from 'classnames';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {SessionNote} from '@yourcoach/shared/api/sessionNote';
import {sessionNoteStore} from '@yourcoach/shared/api/sessionNote';
import {ClientSessionNotesStore} from '@yourcoach/shared/stores/sessionNotes/ClientSessionNotes';
import {AvatarPlaceholder} from '@yourcoach/shared/uikit/AvatarPlaceholder';
import {logger} from '@yourcoach/shared/utils/logger';

import {
  confirm,
  getCustomConfirmAlert,
} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';

import ArrowClosed from '../../../assets/img/ArrowClosed.svg';
import ArrowOpened from '../../../assets/img/ArrowOpened.svg';
import {CRUSessionNoteModal} from '../CRUSessionNoteModal';
import {SessionNoteCard} from '../SessionNoteCard';

import * as S from './styles';
import type {Props} from './types';

const CONTEXT_MENU_ID = 'session_note_context_menu';

function Portal({children}) {
  return ReactDOM.createPortal(children, document.body);
}

export const SessionNotesModal: React.FC = NiceModal.create<Props>(
  ({userId, courseId, user, course, program}) => {
    const modal = useModal();

    const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible(false);

    const [oldCoachNotesActive, setOldCoachNotesActive] =
      useState<boolean>(false);

    const clientSessionNotesStore = useRef(
      new ClientSessionNotesStore({
        clientId: userId,
        courseId,
        user,
        course,
        program,
      }),
    ).current;

    useEffect(() => {
      clientSessionNotesStore.fetch();

      return () => {
        clientSessionNotesStore.dispose();
      };
    }, [clientSessionNotesStore]);

    const onAddNoteButtonClick = useCallback(() => {
      NiceModal.show(CRUSessionNoteModal, {
        userId,
        courseId,
        user: clientSessionNotesStore.user,
        course: clientSessionNotesStore.course,
        program: clientSessionNotesStore.program,
      });
    }, [
      clientSessionNotesStore.course,
      clientSessionNotesStore.program,
      clientSessionNotesStore.user,
      courseId,
      userId,
    ]);

    const onUpdateButtonClick = useCallback(
      ({props}: ItemParams<{note: SessionNote}>) => {
        NiceModal.show(CRUSessionNoteModal, {
          userId,
          courseId,
          user: clientSessionNotesStore.user,
          course: clientSessionNotesStore.course,
          program: clientSessionNotesStore.program,
          note: props?.note,
        });
      },
      [
        clientSessionNotesStore.course,
        clientSessionNotesStore.program,
        clientSessionNotesStore.user,
        courseId,
        userId,
      ],
    );

    const onDeleteButtonClick = useCallback(
      async ({props}: ItemParams<{note: SessionNote}>) => {
        if (props) {
          const {note} = props;

          if (!(await confirm())) {
            return;
          }

          try {
            showOverlay();

            await sessionNoteStore.delete(note);

            logger.event('session_note_deleted');

            hideOverlay();
          } catch (error) {
            hideOverlay();

            logger.error(error);

            getCustomConfirmAlert({
              title: t('shared.message.error_fix'),
              message: error.message,
              buttons: [
                {
                  label: t(['shared.button.ok']),
                },
              ],
            });
          }
        }
      },
      [hideOverlay, showOverlay],
    );

    const ContextMenu = useCallback(
      () => (
        <Menu
          id={CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          <Item onClick={onUpdateButtonClick} className="contextMenuItem">
            {t(['shared.session_notes.actions.edit'])}
          </Item>
          <Item
            onClick={onDeleteButtonClick}
            className={classNames('contextMenuItem', 'danger')}
          >
            {t(['shared.session_notes.actions.delete'])}
          </Item>
        </Menu>
      ),
      [onDeleteButtonClick, onUpdateButtonClick],
    );

    const onMoreButtonClick = useCallback(
      (note: SessionNote, event: React.MouseEvent<Element, MouseEvent>) => {
        contextMenu.show({
          id: CONTEXT_MENU_ID,
          event,
          props: {
            note,
          },
        });
      },
      [],
    );

    const onFetchMoreInViewChange = useCallback(
      isInView => {
        if (isInView) {
          clientSessionNotesStore.fetchMore();
        }
      },
      [clientSessionNotesStore],
    );

    const inEqualIds = (note: SessionNote) =>
      note.coach_id !==
      clientSessionNotesStore.course?.coach_ids[
        clientSessionNotesStore.course?.coach_ids.length - 1
      ];

    const equalIds = (note: SessionNote) =>
      note.coach_id ===
      clientSessionNotesStore.course?.coach_ids[
        clientSessionNotesStore.course?.coach_ids.length - 1
      ];

    const findOldCoachNotes = (arr: SessionNote[], equalityCheck: Function) => {
      return arr.filter(note => equalityCheck(note)).length;
    };

    return (
      <>
        <S.Modal
          isOpen={modal.visible}
          onRequestClose={modal.hide}
          onAfterClose={modal.remove}
          title={t('shared.session_notes.notes_label')}
          bodyClassName="body"
        >
          <Observer>
            {() => (
              <S.InfoRow>
                <S.UserInfo>
                  <S.UserAvatar
                    src={
                      getFileSrc(clientSessionNotesStore.user?.avatar, 250)
                        .url || ''
                    }
                    resizeMode="cover"
                    placeholder={
                      <AvatarPlaceholder
                        text={clientSessionNotesStore.user?.name}
                      />
                    }
                  />
                  <S.UserName>{clientSessionNotesStore.user?.name}</S.UserName>
                </S.UserInfo>
                <S.ProgramContainer>
                  <S.ProgramTitle>
                    {clientSessionNotesStore.program?.title}
                  </S.ProgramTitle>
                  <S.CourseDates>
                    {clientSessionNotesStore.course !== undefined
                      ? getCourseDurationString(
                          clientSessionNotesStore.course,
                        ) || t(['label', 'course_no_start_date', 'coach'])
                      : ''}
                  </S.CourseDates>
                </S.ProgramContainer>
              </S.InfoRow>
            )}
          </Observer>
          <Observer>
            {() =>
              !clientSessionNotesStore.listStore.isLoaded ? <Loader /> : null
            }
          </Observer>
          <Observer>
            {() => (
              <S.List customScrollbar>
                {findOldCoachNotes(
                  clientSessionNotesStore.listStore.items,
                  inEqualIds,
                ) > 0 && (
                  <>
                    <S.OldCoachTitleWrapper
                      onClick={() =>
                        setOldCoachNotesActive(!oldCoachNotesActive)
                      }
                    >
                      <>
                        <S.CoachNotesTitle>
                          {'Previous coach notes'}
                        </S.CoachNotesTitle>
                        <S.OldCoachArrow>
                          {oldCoachNotesActive ? (
                            <ArrowOpened />
                          ) : (
                            <ArrowClosed />
                          )}
                        </S.OldCoachArrow>
                      </>
                    </S.OldCoachTitleWrapper>
                    {oldCoachNotesActive && (
                      <S.OldCoachNotesWrapper>
                        {clientSessionNotesStore.listStore.items
                          .filter(
                            note =>
                              note.coach_id !==
                              clientSessionNotesStore.course?.coach_ids[
                                clientSessionNotesStore.course?.coach_ids
                                  .length - 1
                              ],
                          )
                          .map(note => (
                            <SessionNoteCard
                              note={note}
                              editable={false}
                              key={note._id}
                              onMoreButtonClick={onMoreButtonClick}
                            />
                          ))}
                      </S.OldCoachNotesWrapper>
                    )}
                  </>
                )}
                {findOldCoachNotes(
                  clientSessionNotesStore.listStore.items,
                  equalIds,
                ) > 0 && (
                  <>
                    <S.CoachNotesTitle>{'My notes'}</S.CoachNotesTitle>
                    {clientSessionNotesStore.listStore.items
                      .filter(
                        note =>
                          note.coach_id ===
                          clientSessionNotesStore.course?.coach_ids[
                            clientSessionNotesStore.course?.coach_ids.length - 1
                          ],
                      )
                      .map(note => (
                        <SessionNoteCard
                          note={note}
                          editable={true}
                          key={note._id}
                          onMoreButtonClick={onMoreButtonClick}
                        />
                      ))}
                  </>
                )}
                {/* @ts-ignore */}
                <InView
                  as="div"
                  threshold={0}
                  onChange={onFetchMoreInViewChange}
                >
                  <Observer>
                    {() =>
                      clientSessionNotesStore.listStore.cursor.next ? (
                        <Loader size={50} />
                      ) : null
                    }
                  </Observer>
                </InView>
              </S.List>
            )}
          </Observer>
          <S.Footer>
            <S.AddNoteButton onClick={onAddNoteButtonClick}>
              {t('shared.session_notes.add_note_button')}
            </S.AddNoteButton>
          </S.Footer>
          {overlayIsVisible ? (
            <div className="overlay">
              <Loader />
            </div>
          ) : null}
        </S.Modal>
        <Portal>
          <ContextMenu />
        </Portal>
      </>
    );
  },
);
