import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';

import type {CollectionStore, Entity} from '@yourcoach/shared/api';
import {createCollectionStore} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';

import {setError} from '@src/common/setError';
import type {TSystemFolder} from '@src/models/library';
import LibraryContext from '@src/modules/MyLibrary/context/LibraryContext';
import type {ILibraryLocalStore} from '@src/modules/MyLibrary/context/useLibraryLocalStore';
import ListFolderContainer from '@src/modules/MyLibrary/ListFolderContainer/ListFolderContainer';
import SystemFolder from '@src/modules/MyLibrary/ListFolders/SystemFolder/SystemFolder';

import styles from '../../../styles.module.scss';

interface Props {
  pid: string;
}

interface ILocalStore {
  coursesStore: CollectionStore<Entity> | null;
  setCoursesStore: (newCoursesStore: CollectionStore<Entity>) => void;
  updateCoursesStore: () => void;
  listData: TSystemFolder[];
}

const ProgramCoursesFolder: FC<Props> = ({pid}) => {
  const LIMIT = 300;
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);

  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          coursesStore: null,
          setCoursesStore(newCoursesStore: CollectionStore<Entity>) {
            this.coursesStore = newCoursesStore;
          },
          updateCoursesStore() {
            libraryStory!.setLoading(true);
            this.coursesStore
              .fetch({
                limit: Math.max(this.coursesStore.items.length, LIMIT),
              })
              .then(() => {
                libraryStory!.setLoading(false);
              })
              .catch(error => {
                setError(error);
                libraryStory!.setLoading(false);
              });
          },
          get listData() {
            const retArr: TSystemFolder[] = [];

            if (this.coursesStore) {
              const data = this.coursesStore.items.slice();

              data.forEach(item => {
                let name = getCourseDurationString(item);

                if (item.edition && item.edition.group_size === 1) {
                  if (item.client) {
                    name = item.client.name;
                  } else {
                    name = 'individual_course_lbl';
                  }
                }

                retArr.push({
                  ...item,
                  isCourses: true,
                  name,
                });
              });
            }

            return retArr;
          },
        },
        {
          coursesStore: observable,
          setCoursesStore: action,
          updateCoursesStore: action,
          listData: computed,
        },
      ),
    [libraryStory],
  );

  useEffect(() => {
    const coursesStore = createCollectionStore({
      method: 'coach.courses.list',
      params: {
        sort: [['created', -1]],
        query: [['program_id', '==', pid]],
        limit: LIMIT,
        expand: {
          course: [
            'program_id',
            [
              'edition_id',
              null,
              {
                edition: ['contract_id'],
              },
            ],
            [
              'client_id',
              {
                name: 'Client not found',
              },
            ],
          ],
        },
      },
    });

    localStore.setCoursesStore(coursesStore);
    localStore.updateCoursesStore();

    return () => {
      if (localStore.coursesStore) {
        localStore.coursesStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  //onclick -> ProgramCoursesFolder -> CourseMaterials

  return (
    <Observer>
      {() => (
        <div className="ProgramCoursesFolder">
          <ListFolderContainer className={styles.topOffset}>
            {localStore!.listData.map((item: TSystemFolder) => {
              return (
                <SystemFolder
                  key={`${item.program_id!}_${item._id}`}
                  data={item as TSystemFolder}
                  className={styles.SystemFolder}
                />
              );
            })}
          </ListFolderContainer>
        </div>
      )}
    </Observer>
  );
};

export default memo(ProgramCoursesFolder);
