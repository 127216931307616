/**
 * Styles for the UserBox component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

import type {StyledProps} from './types';

const container = css<StyledProps>`
  padding: 12px;
  //gap: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${p =>
    p.isSelected ? getColor('global1')(p) : getColor('border2')(p)};
`;

export const containerWeb = css<StyledProps>`
  box-sizing: border-box;
  ${container};
`;
