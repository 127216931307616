import type {FC} from 'react';
import React from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const IconGroupSmall: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = '',
}) => {
  return (
    <div className={`icon ${className}`}>
      <svg
        width={height}
        height={width}
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 14.5V13C13 12.2044 12.6839 11.4413 12.1213 10.8787C11.5587 10.3161 10.7956 10 10 10H4C3.20435 10 2.44129 10.3161 1.87868 10.8787C1.31607 11.4413 1 12.2044 1 13V14.5"
          stroke="#819AA9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 7C8.65685 7 10 5.65685 10 4C10 2.34315 8.65685 1 7 1C5.34315 1 4 2.34315 4 4C4 5.65685 5.34315 7 7 7Z"
          stroke="#819AA9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 14.4999V12.9999C17.4995 12.3352 17.2783 11.6895 16.871 11.1641C16.4638 10.6388 15.8936 10.2636 15.25 10.0974"
          stroke="#819AA9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 1.09753C12.8953 1.26276 13.4673 1.63806 13.8757 2.16427C14.2842 2.69047 14.5059 3.33766 14.5059 4.00378C14.5059 4.66991 14.2842 5.31709 13.8757 5.8433C13.4673 6.36951 12.8953 6.74481 12.25 6.91003"
          stroke="#819AA9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default IconGroupSmall;
