import {t} from '@src/i18n';

const I18N_SCOPE = 'Channel';

export const labelChatsTitle = () => t([I18N_SCOPE, 'Chats']);

export const labelChatsSearchChat = () => t([I18N_SCOPE, 'SearchChat']);

export const labelChatsAbuseAlertTitle = () =>
  t([I18N_SCOPE, 'abuse_alert_title']);

export const labelChatsCreateChat = () => t([I18N_SCOPE, 'CreateChat']);

export const labelChatsStartCourse = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'start_course_button', groupType]);

export const labelChatsStopCourse = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'stop_course_button', groupType]);

export const labelChatsViewPinnedMessages = count =>
  t([I18N_SCOPE, 'view_pinned_messages_msg'], {count});

export const labelChatsAccessProblems = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'access_problems_message', groupType]);

export const labelChatsResolveAccessProblems = () =>
  t([I18N_SCOPE, 'resolve_access_problems_btn']);

export const labelChatsArchivedCourseCoach = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'archived_course_msg', 'coach', groupType]);

export const labelChatsEndedCourseCoach = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'ended_course_message', 'coach', groupType]);

export const labelChatsEndedCourseClient = (
  groupType: 'individual' | 'group',
) => t([I18N_SCOPE, 'ended_course_message', 'client', groupType]);

export const labelChatsInviteCourse = () =>
  t([I18N_SCOPE, 'invite_course_btn']);

export const labelChatsSearchClient = () => t([I18N_SCOPE, 'SearchClient']);

export const labelChatsUnpinPost = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'unpin_post_button', groupType]);

export const labelChatsPinPost = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'pin_post_button', groupType]);

export const labelChatsNotStartedCourseMes = (
  accepted,
  start_date,
  userIsAdmin,
  isIndividual,
  date,
) =>
  t(
    [
      I18N_SCOPE,
      'not_started_course_message',
      accepted,
      start_date,
      userIsAdmin,
      isIndividual,
    ].filter(Boolean),
    {date},
  );

export const labelChatsChannelPinnedMessageHeader = () =>
  t([I18N_SCOPE, 'ChannelPinnedMessage', 'header']);

export const labelChatsChooseMaterial = () =>
  t([I18N_SCOPE, 'choose_material_btn']);

export const labelChatsChooseLibraryMaterial = () =>
  t([I18N_SCOPE, 'choose_library_material_btn']);

export const labelChatsRemoveAttachment = () =>
  t([I18N_SCOPE, 'remove_attachment_btn']);

export const labelChatsEditPost = () => t([I18N_SCOPE, 'edit_post_btn']);

export const labelChatsAddNote = () => t([I18N_SCOPE, 'add_note_btn']);

export const labelChatsDeletePost = () => t([I18N_SCOPE, 'delete_post_btn']);

export const labelChatsPostsListItemSystemPostType = (
  step: string,
  type: string,
  userName: string = '',
) =>
  t([I18N_SCOPE, 'PostsListItem', 'system_post', step, type], {
    userName,
  });

export const labelChatsPostsListItemSystemPost = (
  step: string,
  userName: string = '',
) =>
  t([I18N_SCOPE, 'PostsListItem', 'system_post', step], {
    userName,
  });

export const labelChatsCopyPostText = () =>
  t([I18N_SCOPE, 'copy_post_text_btn']);

export const labelChatsReportAbusePost = () =>
  t([I18N_SCOPE, 'report_abuse_post_btn']);

export const labelChatsConferenceTitle = (groupType: 'individual' | 'group') =>
  t([I18N_SCOPE, 'conference_title', groupType]);

export const labelChatsTypeTogSquads = () =>
  t([I18N_SCOPE, 'Channels', 'nav_title']);

export const labelChatsTypeTogDirectMess = () =>
  t([I18N_SCOPE, 'Channels', 'dms_section_header']);

export const labelNoResultsDescriptionClient = () =>
  t([I18N_SCOPE, 'Channels', 'no_results_description', 'client']);

export const labelNoResultsDescriptionCoach = () =>
  t([I18N_SCOPE, 'Channels', 'no_results_description', 'coach']);

export const labelChatsСhooseWhoToWrite = () =>
  t([I18N_SCOPE, 'ChooseWhoToWrite']);

export const labelChatsChannelsSquadsSectionHeader = () =>
  t([I18N_SCOPE, 'Channels', 'programs_section_header']);

export const labelChatsChannelsListItemNoMessagesYet = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'no_messages_yet_lbl']);

export const labelChatsChannelsListItemFrozenMessage = (
  type: 'individual' | 'group',
) => t([I18N_SCOPE, 'ChannelsListItem', 'frozen_message', type]);

export const labelChatsChannelsListItemMyLastMessage = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'my_last_message_lbl']);

export const labelChatsChannelsListItemImage = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'image_lbl']);

export const labelChatsChannelsListDocument = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'document_lbl']);

export const labelChatsChannelsListUrl = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'url_lbl']);

export const labelChatsChannelsListMaterial = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'material_lbl']);

export const labelChatsChannelsListAttachment = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'attachment_label']);

export const labelChatsChannelsListQuestionnaire = () =>
  t([I18N_SCOPE, 'ChannelsListItem', 'questionnaire_label']);

export const labelChatsChannelsListEvent = (
  type: string,
  groupType: 'individual' | 'group' | '',
  obj?: {},
) => {
  if (obj) {
    return t(
      [I18N_SCOPE, 'ChannelsListItem', 'event_lbl', type, groupType].filter(
        Boolean,
      ),
      obj,
    );
  } else {
    return t(
      [I18N_SCOPE, 'ChannelsListItem', 'event_lbl', type, groupType].filter(
        Boolean,
      ),
    );
  }
};
