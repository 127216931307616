import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import type {Props as ScheduleEventProps} from './ScheduleEvent';
import ScheduleEvent, {I18N_SCOPE} from './ScheduleEvent';

type Props = Partial<ModalProps> & ScheduleEventProps;

const ScheduleTaskModal: React.FC<Props> = ({
  program,
  event,
  type,
  onSuccess,
  children,
  ...modalProps
}) => (
  <Modal
    {...modalProps}
    title={t([I18N_SCOPE, 'title', type, event ? 'update' : 'create'])}
  >
    <ScheduleEvent
      program={program}
      event={event}
      type={type}
      onSuccess={onSuccess}
    />
    {children}
  </Modal>
);

export default React.memo(ScheduleTaskModal);
