const MIN_MOBILE_BREAKPOINT = 320;
const MAX_MOBILE_BREAKPOINT = 799;
const AVERAGE_TABLET_BREAKPOINT = 950;
const MAX_TABLET_BREAKPOINT = 1224;

export {
  MIN_MOBILE_BREAKPOINT,
  MAX_MOBILE_BREAKPOINT,
  AVERAGE_TABLET_BREAKPOINT,
  MAX_TABLET_BREAKPOINT,
};
