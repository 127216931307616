import type {Expand} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Edition} from '@yourcoach/shared/api/program';
import type {User, UserExpanded} from '@yourcoach/shared/api/user';
import {userExpand} from '@yourcoach/shared/api/user';

import type {ScheduleMaterial} from '../../modules/materials/utils';

export const expand: Expand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      null,
      {
        edition: ['contract_id', 'materials.file_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {name: 'Coach not found', coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

export const publicExpand: Expand = {
  program: [
    'avatar_id',
    'background_id',
    [
      'edition_ids',
      null,
      {
        edition: ['course_ids'],
      },
    ],
    [
      'coach_ids->expanded_coaches',
      {name: 'Coach not found', coach_title: 'Coach not found'},
      userExpand,
    ],
  ],
};

export type ExpandedCoach = (
  | User
  | (Partial<User> & Required<Pick<User, 'name' | 'coach_title'>>)
) &
  UserExpanded;

export interface Expanded {
  avatar?: IFile | null;
  background?: IFile | null;
  editions: (
    | (Edition & {
        contract?: IFile | null;
        materials: ScheduleMaterial[];
      })
    | undefined
  )[];
  expanded_coaches: ExpandedCoach[];
}
