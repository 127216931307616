import React from 'react';

import Button from '@src/components/Button';
import {t} from '@src/i18n';

import LogoSVG from '../../images/logo-large.svg';
import PlaySVG from '../../images/play.svg';

import styles from './styles.module.css';

const I18N_SCOPE = 'Onboarding.video.intro';

const Intro = ({onNext, onSkip}) => {
  return (
    <div className={styles.intro}>
      <LogoSVG className={styles.logo} />
      <div className={styles.title}>{t([I18N_SCOPE, 'title'])}</div>
      <div className={styles.description}>{t([I18N_SCOPE, 'description'])}</div>
      <Button className={styles.playButton} onClick={onNext}>
        <div className={styles.inner}>
          <PlaySVG />
        </div>
      </Button>
      <div className={styles.spacer} />
      <Button className={styles.skipButton + ' wide'} onClick={onSkip}>
        {t([I18N_SCOPE, 'watch_later_button'])}
      </Button>
    </div>
  );
};

export default Intro;
