import type {FC} from 'react';
import React, {memo} from 'react';

import {
  labelBackToFormsAndQrs,
  labelUseThisTemplate,
} from '@src/common/i18n/i18nQuestionnaire';
import {useAppRedirect} from '@src/common/useAppRedirect';
import {CustomButton} from '@src/components/CustomForm';
import {IconPrev} from '@src/components/icons';
import {ColorWrapper} from '@src/layouts';
import {PATH_TYPE} from '@src/routes/utils';

import questionnaireAppStore from '../context/qAppStore';
import QDetail from '../QDetail/QDetail';

interface Props {}

const QStandardDetail: FC<Props> = () => {
  const standardQ = questionnaireAppStore?.standardTemplateQuest;
  const redirect = useAppRedirect();

  const handleClickBack = () => {
    redirect(`/${PATH_TYPE.createQuestionnaire}`);
  };

  const handleClickUseTemplateBut = () => {
    redirect(`/${PATH_TYPE.questionnaire}/create`);
  };

  return (
    <div className="QStandardDetail">
      <ColorWrapper containerClass="pl50">
        <CustomButton
          type="button"
          classButton="QuestionnaireHeaderBut"
          onClick={handleClickBack}
        >
          <IconPrev customClass="IconPrev" />
          <span>{labelBackToFormsAndQrs()}</span>
        </CustomButton>
      </ColorWrapper>
      <QDetail quest={standardQ}>
        <CustomButton
          type="button"
          classButton="blueButt"
          onClick={handleClickUseTemplateBut}
        >
          {labelUseThisTemplate()}
        </CustomButton>
      </QDetail>
    </div>
  );
};

export default memo(QStandardDetail);
