import {useContext, useMemo} from 'react';

import {action, computed, observable} from 'mobx';

import type {Course} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Membership} from '@yourcoach/shared/api/membership';
import type {
  Edition,
  ParsedDuration,
  ParsedPaymentPlan,
  PaymentPlan,
  Program as IProgram,
  ProgramType,
} from '@yourcoach/shared/api/program';
import {
  getProgramType,
  parseDuration,
  parsePaymentPlan,
} from '@yourcoach/shared/api/program';
import {getPriceString} from '@yourcoach/shared/utils/money';

import type {ExpandedCoach} from '@src/common/getExpandedClient';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import type {
  Expanded as ProgramExpanded,
  ScheduleMaterial,
} from '@src/models/program';

export type ProgramT = IProgram &
  Omit<ProgramExpanded, 'editions'> & {
    editions: (
      | (Edition & {
          contract?: IFile | null;
          materials: ScheduleMaterial[];
          courses: Course[];
        })
      | undefined
    )[];
  };

export type ClientMembership = Membership & {
  course?: Course;
};

export interface ISeeProgramLocalStore {
  programId: string | null;
  isCoach: boolean;
  userIsProgramCoach: boolean;
  availableCourses: Course[];
  program: ProgramT | null;
  getProgram(newProgram: ProgramT): void;
  clientMembershipWithCourse: ClientMembership | null;
  availableCoursesIsFetching: boolean;
  lastEdition: ProgramT['editions'][0];
  lastEditionIsSigned: boolean;
  cocoachesAreSignedLastEdition: boolean;
  coachIsSignedLastEdition: boolean | null;
  showAvailableCourses: boolean;
  isIndividual: boolean;
  clientIsAbleToJoin: boolean;
  clientIsInvited: boolean;
  clientHasMatched: boolean | null;
  clientMembership:
    | Pick<
        Membership,
        | '_id'
        | 'program_id'
        | 'edition_id'
        | 'course_id'
        | 'card_id'
        | 'status'
        | 'frozen_by'
      >
    | null
    | undefined;
  clientHasArchivedMembership:
    | Pick<
        Membership,
        | '_id'
        | 'program_id'
        | 'edition_id'
        | 'course_id'
        | 'card_id'
        | 'status'
        | 'frozen_by'
      >
    | null
    | undefined;
  isMainCoach: boolean;
  coachIsSigned: boolean | null;
  cocoachesAreSigned: boolean;
  isOfferedProgram: boolean;
  programPrice: string | undefined;
  requestsCount: number;
  invitesCount: number;
  paymentPlan: (PaymentPlan & ParsedPaymentPlan) | undefined;
  duration: ParsedDuration | undefined;
  programType: ProgramType | undefined;
  coaches: ExpandedCoach[];
  setRequestsCount(requestsCount: number): void;
  setInvitesCount(invitesCount: number): void;
  setProgramId(newProgramId: string): void;
}

const useSeeProgramLocalStore = () => {
  const programId = useURLQueryParam('pid');
  const {
    stores: {currentUserStore, membershipStore},
  } = useContext(AppContext);
  const user = currentUserStore.user || null;

  const store: ISeeProgramLocalStore | null = useMemo(
    () =>
      observable<ISeeProgramLocalStore>(
        {
          programId,
          setProgramId(newProgramId: string) {
            this.programId = newProgramId;
          },
          isCoach: user?.roles?.includes('coach') || false,
          clientMembershipWithCourse: null,
          availableCourses: [],
          availableCoursesIsFetching: false,
          program: null,
          requestsCount: 0,
          setRequestsCount(requestsCount: number) {
            this.requestsCount = requestsCount;
          },
          invitesCount: 0,
          setInvitesCount(invitesCount: number) {
            this.invitesCount = invitesCount;
          },
          getProgram(newProgram: ProgramT) {
            this.program = newProgram;
          },
          get userIsProgramCoach() {
            return !!(
              this.program &&
              user &&
              this.program.coach_ids.includes(user._id)
            );
          },
          get lastEdition() {
            let lastEdition: ProgramT['editions'][0];

            if (
              this.program &&
              this.program.editions &&
              this.program.editions.length
            ) {
              lastEdition =
                this.program.editions[this.program.editions.length - 1];
            }

            return lastEdition;
          },
          get paymentPlan() {
            if (this.lastEdition) {
              return parsePaymentPlan(this.lastEdition.payment_plan);
            }
          },
          get duration() {
            if (this.lastEdition) {
              return parseDuration(this.lastEdition.duration);
            }
          },
          get programType() {
            if (this.lastEdition) {
              return getProgramType(this.lastEdition);
            }
          },
          get coaches() {
            return this.program.expanded_coaches.slice();
          },
          get lastEditionIsSigned() {
            return this.lastEdition &&
              this.program!.status !== 'draft' &&
              this.userIsProgramCoach
              ? this.lastEdition.coach_ids.every(coachId => {
                  return this.lastEdition!.coaches[coachId].signed;
                })
              : true;
          },
          get cocoachesAreSignedLastEdition() {
            return (
              this.lastEdition &&
              this.lastEdition.coach_ids.every(coachId => {
                if (coachId !== this.program!.user_id) {
                  return this.lastEdition!.coaches[coachId].signed;
                }

                return true;
              })
            );
          },
          get coachIsSignedLastEdition() {
            return (
              this.lastEdition &&
              currentUserStore.user &&
              !!this.lastEdition.coaches[currentUserStore.user._id].signed
            );
          },
          get showAvailableCourses() {
            return (
              this.lastEdition &&
              this.lastEdition.group_size > 1 &&
              !this.userIsProgramCoach
            );
          },
          get isIndividual() {
            return this.lastEdition && this.lastEdition.group_size === 1;
          },
          clientIsInvited: false,
          clientHasMatched: null,
          get clientIsAbleToJoin() {
            if (this.program && this.lastEdition) {
              if (this.clientHasMatched === null || this.clientHasMatched) {
                return false;
              }

              if (this.program.is_closed) {
                return this.clientIsInvited;
              }

              return !this.clientMembership;
            }

            return false;
          },
          get clientMembership() {
            return this.program
              ? Array.from(membershipStore.membershipLookup.values()).find(
                  item =>
                    item.program_id === this.program!._id &&
                    item.status !== 'archived',
                )
              : null;
          },
          get clientHasArchivedMembership() {
            return this.program
              ? Array.from(membershipStore.membershipLookup.values()).find(
                  item =>
                    item.program_id === this.program!._id &&
                    item.status === 'archived',
                )
              : null;
          },
          get isMainCoach() {
            return !!(
              this.program &&
              currentUserStore.user &&
              this.program.user_id === currentUserStore.user._id
            );
          },
          get coachIsSigned() {
            return (
              this.program &&
              currentUserStore.user &&
              this.program.coaches[currentUserStore.user._id] &&
              !!this.program.coaches[currentUserStore.user._id].signed
            );
          },
          get cocoachesAreSigned() {
            return (
              this.program &&
              this.program.coach_ids.every(coachId => {
                if (coachId !== this.program!.user_id) {
                  return this.program!.coaches[coachId].signed;
                }

                return true;
              })
            );
          },
          get isOfferedProgram() {
            return this.program && this.program.offer_id;
          },
          get programPrice() {
            if (this.program) {
              if (this.lastEdition) {
                const paymentPlan = parsePaymentPlan(
                  this.lastEdition.payment_plan,
                );

                let price = getPriceString({
                  price: paymentPlan.total,
                  currency: paymentPlan.parsedCurrency,
                });

                if (this.userIsProgramCoach) {
                  if (this.isOfferedProgram) {
                    return;
                  }

                  return price;
                } else {
                  if (this.clientHasMatched === null) {
                    return;
                  }

                  if (this.clientHasMatched) {
                    if (
                      this.isOfferedProgram &&
                      (this.clientIsInvited || this.clientMembership) &&
                      paymentPlan.total > 0
                    ) {
                      return price;
                    }

                    return;
                  }

                  return price;
                }
              }
            }
          },
        },
        {
          programId: observable,
          setProgramId: action,
          isCoach: observable,
          clientMembershipWithCourse: observable,
          availableCourses: observable.shallow,
          userIsProgramCoach: computed,
          program: observable,
          requestsCount: observable,
          invitesCount: observable,
          getProgram: action,
          setRequestsCount: action,
          setInvitesCount: action,
          lastEdition: computed,
          lastEditionIsSigned: computed,
          cocoachesAreSignedLastEdition: computed,
          coachIsSignedLastEdition: computed,
          showAvailableCourses: computed,
          clientIsInvited: observable,
          clientHasMatched: observable,
          clientIsAbleToJoin: computed,
          clientMembership: computed,
          clientHasArchivedMembership: computed,
          isMainCoach: computed,
          coachIsSigned: computed,
          isOfferedProgram: computed,
          programPrice: computed,
          paymentPlan: computed,
          cocoachesAreSigned: computed,
          duration: computed,
          coaches: computed,
          programType: computed,
        },
      ),
    [currentUserStore, membershipStore, programId, user],
  );

  return store;
};

export default useSeeProgramLocalStore;
