import React, {useContext} from 'react';

import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import type {Editor} from './Avatar/AvatarEditor';
import AvatarEditor from './Avatar/AvatarEditor';
import EditorStep from './EditorStep';

const I18N_SCOPE = 'Onboarding.avatar';

const AvatarStep = props => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const editorRef = React.createRef<Editor>();

  return (
    <EditorStep
      title={t([I18N_SCOPE, 'title'], {
        name: currentUserStore.user?.name!,
      })}
      step={1}
      fieldName="avatar_id"
      editorRef={editorRef}
      {...props}
    >
      <AvatarEditor ref={editorRef} onSkipClick={props.onContinue} />
    </EditorStep>
  );
};

export default AvatarStep;
