import React, {useCallback, useMemo} from 'react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {DEFAULT_NOTE_COLOR} from '@yourcoach/shared/api/sessionNote';
import MoreIcon from '@yourcoach/shared/assets/icons/more.svg';
import type {Clickable} from '@yourcoach/shared/uikit/Clickable';
import type {GetComponentProps} from '@yourcoach/shared/utils/getComponentProps';

import * as S from './styles';
import type {Props} from './types';

export const SessionNoteCard: React.FC<Props> = ({
  note,
  editable,
  onMoreButtonClick,
}) => {
  const date = useMemo(() => {
    return dayjs(datetimeObjToISOString(note?.created))
      .format('ll')
      .replace(/\s/g, '\u00A0');
  }, [note]);

  const onMoreButtonClickCb: GetComponentProps<typeof Clickable>['onClick'] =
    useCallback(
      e => {
        onMoreButtonClick?.(note, e.event.DOMEvent!);
      },
      [note, onMoreButtonClick],
    );

  return (
    <S.Container color={note.color || DEFAULT_NOTE_COLOR}>
      <S.Header>
        <S.Date>{date}</S.Date>
        {editable && (
          <S.MoreButton onClick={onMoreButtonClickCb}>
            <MoreIcon />
          </S.MoreButton>
        )}
      </S.Header>
      <S.Body>{note.body}</S.Body>
    </S.Container>
  );
};
