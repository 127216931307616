import type {FC} from 'react';
import React, {memo, useEffect} from 'react';
import {createPortal} from 'react-dom';

import styles from './styles.module.css';

interface Props {
  children: any;
  className?: string;
  classNameContent?: string;
  closeModal?: () => void;
  type?: string; // TODO change to enum
}

const Modal: FC<Props> = ({
  children,
  className = '',
  classNameContent = '',
  closeModal,
  type,
}) => {
  useEffect(() => {
    const classN = 'no-scroll';
    const classBG = 'blur';
    const body = document.body;
    const bg = document.getElementById('allContent')!;

    body.classList.add(classN);
    bg.classList.add(classBG);

    return () => {
      body.classList.remove(classN);
      bg.classList.remove(classBG);
    };
  }, []);

  return createPortal(
    <div
      className={`Modal ${styles.Modal} ${className}`}
      onClick={type === 'subgoal' ? undefined : closeModal}
    >
      <div
        className={`content ${styles.content}
        ${classNameContent}`}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default memo(Modal);
