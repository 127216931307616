import type {FC} from 'react';
import React, {memo} from 'react';

import {labelProfile} from '@src/common/i18n/i18nMainMenu';

import NavTabs, {Routes} from './NavTabs';
import styles from './styles.module.css';

interface Props {}

const Profile: FC<Props> = () => {
  return (
    <div className={`Profile ${styles.Profile}`}>
      <h1>{labelProfile()}</h1>
      <NavTabs />
      <hr className="first" />
      <Routes />
    </div>
  );
};

export default memo(Profile);
