import type {FC} from 'react';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import type {IFile} from '@yourcoach/shared/api/media/file';
import storage from '@yourcoach/shared/utils/storage';
import {getEmailSchema} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import SpinnerOverlay from '@src/components/SpinnerOverlay';
import TextField from '@src/components/TextField';
import {ACCOUNT_STORAGE_KEY, PRIVACY_POLICY_URL, TOS_URL} from '@src/config';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import DocumentViewerModal from '@src/modules/library/DocumentViewerModal';

import MailSVG from '../images/mail.svg';

import FormStep from './FormStep';
import {ButtonsContainer, Description, Title} from './LeftPaneStep';
import styles from './styles.module.css';

interface Props {
  onClick?: (event) => void;
  onChange?: (value) => void;
  onData?: (value, fieldName) => void;
  value?: string;
}

const I18N_SCOPE = 'Login.sign_up';

const SignUpStep: FC<Props> = ({onClick, onChange, onData, value}) => {
  const [accepted, setAccepted] = useState(false);
  const [error, setError] = useState('');
  const documentUrl = useRef<IFile>({} as IFile);
  const [isDocumentViewerOpen, showDocumnentViewer, hideDocumentViewer] =
    useIsVisible(false);

  const field = useRef(
    createHtmlInputField('email', {
      defaultValue: value || '',
      validationRule: getEmailSchema(),
    }),
  ).current;
  const {
    stores: {authStore},
  } = useContext(AppContext);
  const [isRequest, setIsRequest] = useState(false);

  const onSubmit = useCallback(
    async fieldValue => {
      setIsRequest(true);

      return authStore
        .sendCode({email: fieldValue, toWebsite: true})
        .finally(() => {
          setIsRequest(false);
        });
    },
    [authStore],
  );

  const onApiResponse = useCallback(
    response => {
      onData && onData(response.expires, 'codeExpires');

      storage.setItem(
        ACCOUNT_STORAGE_KEY,
        JSON.stringify({
          type: 'email',
          value: field.value,
          codeExpires: response.expires,
        }),
      );
    },
    [field.value, onData],
  );

  const onAcceptClick = useCallback(
    event => {
      if (event.target.tagName === 'A') {
        return;
      }

      setAccepted(!accepted);

      if (!accepted) {
        setError('');
      }
    },
    [accepted],
  );

  const onNextClick = useCallback(
    event => {
      if (accepted) {
        onClick && onClick(event);
      } else {
        setError(t([I18N_SCOPE, 'should_accept_terms_error']));
      }
    },
    [accepted, onClick],
  );

  const onPolicyClick = useCallback(() => {
    documentUrl.current = {
      src: {original: {url: PRIVACY_POLICY_URL, mimetype: 'application/pdf'}},
    } as IFile;
    showDocumnentViewer();
  }, [showDocumnentViewer]);

  const onTosClick = useCallback(() => {
    documentUrl.current = {
      src: {original: {url: TOS_URL, mimetype: 'application/pdf'}},
    } as IFile;
    showDocumnentViewer();
  }, [showDocumnentViewer]);

  const onHideDocument = useCallback(() => {
    hideDocumentViewer();
  }, [hideDocumentViewer]);

  return (
    <FormStep
      icon={<MailSVG />}
      field={field}
      fieldName={'email'}
      onSubmit={onSubmit}
      onChange={onChange}
      onResponse={onApiResponse}
      onClick={onNextClick}
    >
      <Title>{t([I18N_SCOPE, 'title'])}</Title>
      <Description>{t([I18N_SCOPE, 'description'])}</Description>
      <Observer>
        {() => (
          <TextField
            placeholder={t([I18N_SCOPE, 'placeholder'])}
            value={field.value}
            onChange={field.onChange}
            error={field.error}
            autoFocus
          />
        )}
      </Observer>
      <Checkbox
        className={styles.marginTop20}
        checked={accepted}
        onClick={onAcceptClick}
        label={
          <>
            {t([I18N_SCOPE, 'agree_label'])}
            <a href="#" onClick={onPolicyClick}>
              {t([I18N_SCOPE, 'policy_label'])}
            </a>
            &nbsp;{t([I18N_SCOPE, 'and_label'])}&nbsp;
            <a href="#" onClick={onTosClick}>
              {t([I18N_SCOPE, 'terms_label'])}
            </a>
          </>
        }
      />
      <div className={styles.error}>{error}</div>
      <ButtonsContainer>
        <Button className={styles.inverted} onClick={onClick}>
          {t('Common.Back')}
        </Button>
        <SpinnerOverlay isActive={isRequest}>
          <Button data-action="next" className={styles.middle}>
            {isRequest ? <>&nbsp;</> : t([I18N_SCOPE, 'send_code_button'])}
          </Button>
        </SpinnerOverlay>
      </ButtonsContainer>
      <DocumentViewerModal
        isOpen={isDocumentViewerOpen}
        onAfterClose={onHideDocument}
        document={documentUrl.current}
      />
    </FormStep>
  );
};

export default SignUpStep;
