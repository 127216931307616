import React, {useCallback, useImperativeHandle, useRef} from 'react';
import isEqual from 'react-fast-compare';

import {logger} from '@yourcoach/shared/utils/logger';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {t} from '@src/i18n';

import type {ProgramT} from '.';
import type {Ref as CocoachingManagerRef} from './CocoachingManager';
import CocoachingManager from './CocoachingManager';
import styles from './Complete.module.css';
import Summary from './Summary';
import type {TabProps, TabRef} from './useTabs';

export const I18N_SCOPE = 'shared.CRUProgramComplete';

const CRUProgramComplete = React.forwardRef<TabRef, TabProps>(
  (
    {program, userIsOwner, shouldCloneProgram, cloneProgram, isOfferedProgram},
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      getData,
    }));

    const showIsntProviderCoachesAlert = useCallback(
      (coaches: ProgramT['expanded_coaches']) =>
        new Promise<void>((resolve, reject) => {
          getCustomConfirmAlert({
            title: t([I18N_SCOPE, 'isnt_provider_coaches_popup', 'header']),
            message: t(
              [I18N_SCOPE, 'isnt_provider_coaches_popup', 'description'],
              {
                coaches: coaches.map(coach => coach.name).join(', '),
              },
            ),
            buttons: [
              {
                label: t([
                  I18N_SCOPE,
                  'isnt_provider_coaches_popup',
                  'remove_button',
                ]),
                type: 'confirm',
                onClick: () => {
                  resolve();
                },
              },
              {
                label: t([
                  I18N_SCOPE,
                  'isnt_provider_coaches_popup',
                  'cancel_button',
                ]),
                onClick: () => {
                  reject();
                },
              },
            ],
          });
        }),
      [],
    );

    const getData = useCallback(
      async (direction?: 'back' | 'next') => {
        if (!cocoachingManager.current) {
          return {};
        }

        const coachesData = cocoachingManager.current.getData();

        let {coaches} = coachesData;

        const {isntProviderCoaches, expandedCoaches} = coachesData;

        if (direction === 'next' && isntProviderCoaches.length) {
          try {
            await showIsntProviderCoachesAlert(isntProviderCoaches);

            cocoachingManager.current.removeIsntProviderCoaches();

            const updatedCoachesData = cocoachingManager.current.getData();

            coaches = updatedCoachesData.coaches;

            return;
          } catch (error) {
            logger.error(error);

            return;
          }
        }

        return {
          coaches,
          coach_ids: Object.keys(coaches),
          expanded_coaches: expandedCoaches,
        };
      },
      [showIsntProviderCoachesAlert],
    );

    const cocoachingManager = useRef<CocoachingManagerRef>(null);

    return (
      <div className={styles.container}>
        {program ? (
          <>
            <Summary program={program} />
            {!isOfferedProgram ? (
              <CocoachingManager
                ref={cocoachingManager}
                program={program}
                cloneProgram={cloneProgram}
                userIsOwner={userIsOwner}
                shouldCloneProgram={shouldCloneProgram}
                className={styles.cocoachingManager}
              />
            ) : null}
          </>
        ) : null}
      </div>
    );
  },
);

export default React.memo(CRUProgramComplete, isEqual);
