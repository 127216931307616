import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import Fade from 'react-reveal/Fade';
import {observer} from 'mobx-react';

import {action, observable} from 'mobx';

import styles from './styles.module.css';

interface ILocalStore {
  isVisible: boolean;
  isAnimate: boolean;
  idT: number;
  getVisible(vis: boolean): void;
  getAnimate(an: boolean): void;
  showDrop(): void;
  hideDrop(): void;
}

interface Props {
  children: any;
  className?: string;
  getOpenFunc?: (openFunc: () => void) => void;
  getCloseFunc?: (closeFunc: () => void) => void;
}

const DropdownMenu: FC<Props> = observer(
  ({children, className, getOpenFunc = () => {}, getCloseFunc = () => {}}) => {
    const localStore = useRef(
      observable<ILocalStore>(
        {
          isVisible: false,
          isAnimate: false,
          idT: 0,
          getVisible(vis: boolean) {
            this.isVisible = vis;
          },
          getAnimate(an: boolean) {
            this.isAnimate = an;
          },
          showDrop() {
            this.getVisible(true);
            clearTimeout(this.idT);
            this.idT = setTimeout(() => {
              this.getAnimate(true);
            }, 300);
          },
          hideDrop() {
            this.getAnimate(false);
            clearTimeout(this.idT);
            this.idT = setTimeout(() => {
              this.getVisible(false);
            }, 300);
          },
        },
        {
          isVisible: observable,
          isAnimate: observable,
          idT: observable,
          getVisible: action,
          getAnimate: action,
          showDrop: action,
        },
      ),
    ).current;

    const handleOnMouseLeave = () => {
      localStore.hideDrop();
    };

    useEffect(() => {
      getOpenFunc(() => {
        localStore.showDrop();
      });

      getCloseFunc(() => {
        localStore.hideDrop();
      });

      return () => {
        if (localStore.idT) {
          clearTimeout(localStore.idT);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {localStore.isVisible && (
          <Fade bottom duration={300} when={localStore.isAnimate}>
            <div
              onMouseLeave={handleOnMouseLeave}
              className={`DropdownMenu ${styles.DropdownMenu} ${className}`}
            >
              {children}
            </div>
          </Fade>
        )}
      </>
    );
  },
);

export default memo(DropdownMenu);
