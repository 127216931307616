import type {FC, ReactNode} from 'react';
import React from 'react';

interface Props {
  children: ReactNode;
  title?: string;
}

const IconContainer: FC<Props> = ({children, title}) => {
  return (
    <div className="iconContainer">
      {children}
      {title && (
        <div className="iconLabel">
          <span>{title}</span>
        </div>
      )}
    </div>
  );
};

export default IconContainer;
