/**
 * Styles for the BreadcrumbLink component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

export const link = css`
  text-decoration: none;
  color: ${getColor('text3')};
  &.active {
    color: ${getColor('lostColors.sashaGray')};
    pointer-events: none;
  }
  :hover {
    color: ${getColor('text4')};
  }
`;
