import React, {useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import {labelUpdateProgramScheduleCreateEvent} from '@src/common/i18n/i18nProgramSchedulePopup';
import type {ModalRef} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';
import {t} from '@src/i18n';

import CRUCalendarEvent, {
  I18N_SCOPE as CRU_CALENDAR_EVENT_I18N_SCOPE,
} from '../../calendar/CRUCalendarEvent';
import ToDosContext from '../context/ToDosContext';
import type {IToDosLocalStore} from '../context/useToDosLocalStore';

import styles from './../styles.module.css';

interface ILocalStore {
  isShowCreateEventMod: boolean;
  setIsShowCreateEventMod(isShowCreateEventMod: boolean): void;
  date: Date;
  setDate(date: Date): void;
}

interface Props {}

const ToDosMenu: React.FC<Props> = () => {
  const toDosLocalStore: IToDosLocalStore | null = useContext(ToDosContext);

  const cruEventModalRef = useRef<ModalRef>(null);

  const localStore: ILocalStore = useRef(
    observable(
      {
        isShowCreateEventMod: false,
        setIsShowCreateEventMod(isShowCreateEventMod: boolean) {
          this.isShowCreateEventMod = isShowCreateEventMod;
        },
        date: new Date(),
        setDate(date) {
          this.date = date;
        },
      },
      {
        isShowCreateEventMod: observable,
        setIsShowCreateEventMod: action,
        date: observable,
        setDate: action,
      },
    ),
  ).current;

  const handleOnClickButCreateEvent = () => {
    const date = new Date(toDosLocalStore!.selectedDate);
    const now = new Date();

    date.setHours(now.getHours(), now.getMinutes());

    localStore.setDate(date);

    if (cruEventModalRef.current) {
      cruEventModalRef.current.show();
    }
  };

  const handleOnCreateEvent = () => {
    toDosLocalStore?._fetchEvents();

    if (cruEventModalRef.current) {
      cruEventModalRef.current.hide();
    }
  };

  return (
    <Observer>
      {() => (
        <>
          <div className={`ToDosMenu ${styles.ToDosMenu}`}>
            {toDosLocalStore?.userIsCoach ? (
              <div className="createBut" onClick={handleOnClickButCreateEvent}>
                <div className="butTitle">
                  {labelUpdateProgramScheduleCreateEvent()}
                </div>
                <div className="createIcon">+</div>
              </div>
            ) : null}
          </div>
          <Modal
            title={t([CRU_CALENDAR_EVENT_I18N_SCOPE, 'title', 'create'])}
            ref={cruEventModalRef}
          >
            <CRUCalendarEvent
              date={localStore.date}
              course={toDosLocalStore!.course}
              onCreate={handleOnCreateEvent}
            />
          </Modal>
        </>
      )}
    </Observer>
  );
};

export default React.memo(ToDosMenu);
