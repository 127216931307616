import {useCallback, useState} from 'react';

const useIsVisible = (isVisible = false): [boolean, () => void, () => void] => {
  const [state, setState] = useState(isVisible);

  const show = useCallback(() => setState(true), []);

  const hide = useCallback(() => setState(false), []);

  return [state, show, hide];
};

export default useIsVisible;
