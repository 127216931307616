import React from 'react';
import {useLocation} from 'react-router-dom';
import {Observer, observer} from 'mobx-react';

import {Text} from '@yourcoach/shared/uikit/Text';

import {BreadcrumbLink} from '../../components/BreadcrumbLink';
import {Layout} from '../../components/Layout';
import {BreadcrumbsStore} from '../../stores/Breadcrumbs';
import type {LocationWithFromState} from '../../types';

type Props = {
  isWithCrumbs?: boolean;
  isWithFrom?: boolean;
};

export const BreadcrumbsContainer: React.FC<Props> = observer(
  ({isWithCrumbs, isWithFrom}) => {
    const location: LocationWithFromState = useLocation();
    const [store] = React.useState(
      () =>
        new BreadcrumbsStore({
          isWithFrom: Boolean(isWithFrom),
          isWithCrumbs: Boolean(isWithCrumbs),
        }),
    );

    React.useEffect(() => {
      store.nextLocation(location);
    }, [store, location]);

    return (
      <Layout.Container>
        {isWithCrumbs ? (
          <Observer>
            {() => (
              <React.Fragment>
                {store.crumbs.map(crumb => (
                  <Layout.Cell key={crumb.label}>
                    <BreadcrumbLink
                      activeClassName={'active'}
                      to={{
                        pathname: crumb.path,
                        state: {from: location.state?.from},
                      }}
                      exact
                    >
                      <Text style={{fontWeight: 'inherit'}} color={'inherit'}>
                        {crumb.label}
                      </Text>
                    </BreadcrumbLink>
                  </Layout.Cell>
                ))}
              </React.Fragment>
            )}
          </Observer>
        ) : null}
      </Layout.Container>
    );
  },
);
