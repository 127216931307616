import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, computed, observable} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import type {ApiRpcQuery} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal} from '@yourcoach/shared/api/goal';

import {labelText} from '@src/common/i18n/i18nProfile';
import {setError} from '@src/common/setError';
import {CustomButton, CustomInput} from '@src/components/CustomForm';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import type {Expanded as GoalExpanded} from '@src/models/goals';
import {expand as goalExpand} from '@src/models/goals';
import type {GoalT as ProgramGoalT} from '@src/pages/CRUProgram/Goals';

import styles from './../styles.module.scss';

const expand = JSON.parse(JSON.stringify(goalExpand));

expand.goal.push('course_id');

export type GoalT = Goal &
  GoalExpanded & {
    course?: Course | null;
  };

const I18N_SCOPE = 'CRUGoal';

interface ILocalStore {
  isCreatingMode: boolean;
  setIsCreatingMode(isCreatingMode: boolean): void;
  goalsAreExist: boolean;
  setGoalsAreExist(goalsAreExist: boolean): void;
  goalName: string;
  setGoalName(val: string): void;
  goalNameError: string | null;
  setGoalNameError(err: string | null): void;
  goalDescription: string;
  setGoalDescription(val: string): void;
  goalDescriptionError: string | null;
  setGoalDescriptionError(err: string | null): void;
  seeGoalsPartIsVisible: boolean;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
  isClientManaged: boolean;
  setIsClientManaged(isClientManaged: boolean): void;
}

interface Props {
  programId?: string | null;
  courseId?: string | null;
  goal?: Goal | ProgramGoalT | null;
  onSuccess?: (goal: ProgramGoalT) => void;
  closeModal?: (goal: ProgramGoalT) => void;
  // goal?: Goal | null;
  // onSuccess?: (goal: Goal) => void;
  // closeModal?: (goal: Goal) => void;
}

const CRUGoal: FC<Props> = ({
  goal,
  onSuccess,
  programId,
  courseId,
  closeModal,
}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        isLoading: false,
        setIsLoading(isLoading: boolean) {
          this.isLoading = isLoading;
        },
        isClientManaged: false,
        setIsClientManaged(isClientManaged: boolean) {
          this.isClientManaged = isClientManaged;
        },
        isCreatingMode: true,
        setIsCreatingMode(isCreatingMode: boolean) {
          this.isCreatingMode = isCreatingMode;
        },
        goalsAreExist: false,
        setGoalsAreExist(goalsAreExist: boolean) {
          this.goalsAreExist = goalsAreExist;
        },
        get seeGoalsPartIsVisible() {
          return this.isCreatingMode && this.goalsAreExist;
        },
        goalName: '',
        setGoalName(val: string) {
          this.goalName = val;
        },
        goalNameError: null,
        setGoalNameError(err: string | null) {
          this.goalNameError = err;
        },
        goalDescription: '',
        setGoalDescription(val: string) {
          this.goalDescription = val;
        },
        goalDescriptionError: null,
        setGoalDescriptionError(err: string | null) {
          this.goalDescriptionError = err;
        },
      },
      {
        isCreatingMode: observable,
        setIsCreatingMode: action,
        goalName: observable,
        setGoalName: action,
        goalNameError: observable,
        setGoalNameError: action,
        goalDescription: observable,
        setGoalDescription: action,
        goalDescriptionError: observable,
        setGoalDescriptionError: action,
        goalsAreExist: observable,
        setGoalsAreExist: action,
        seeGoalsPartIsVisible: computed,
        isLoading: observable,
        setIsLoading: action,
        isClientManaged: observable,
        setIsClientManaged: action,
      },
    ),
  ).current;

  useEffect(() => {
    if (goal) {
      localStore.setGoalName(goal.title);
      localStore.setGoalDescription(goal.description);
      localStore.setIsClientManaged((goal as Goal).is_client_managed);

      localStore.setIsCreatingMode(false);

      _fetchGoalsCount();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchGoalsCount = async () => {
    try {
      const result = await apiRequest({
        method: 'coach.goals.unbound.count',
        params: {
          query: (
            [
              programId ? ['program_id', '!=', programId] : null,
              ['course_id', '==', null],
            ] as ApiRpcQuery[]
          ).filter(Boolean),
        },
      });

      localStore.setGoalsAreExist(result._count > 0);

      return result._count;
    } catch (error) {
      // TODO: log error
    }
  };

  const handleCreateGoal = () => {
    // if (goal) {
    if (localStore.goalName.length > 2) {
      if (onSuccess) {
        onSuccess({
          ...goal,
          title: localStore.goalName,
          description: localStore.goalDescription,
          is_client_managed: localStore.isClientManaged,
          _id: goal?._id ?? uuidv4(),
        });

        localStore.setIsLoading(false);
      } else {
        if (localStore.isCreatingMode) {
          _createGoal();
        } else {
          _updateGoal();
        }
      }
    } else {
      localStore.setGoalNameError(
        'The name field must be filled in and must contain more than two characters',
      );
    }
    // }
  };

  const _createGoal = async () => {
    try {
      if (programId) {
        localStore.setIsLoading(true);

        const createdGoal = (await goalStore.create({
          program_id: programId,
          course_id: courseId || null,
          title: localStore.goalName,
          description: localStore.goalDescription,
          is_client_managed: localStore.isClientManaged,
          expand,
        })) as GoalT;

        if (
          createdGoal.course &&
          createdGoal.course.status === 'ongoing' &&
          createdGoal.course_id
        ) {
          await goalStore.coach.bound.synchronize({
            course_id: createdGoal.course_id,
          });
        }

        localStore.setIsLoading(false);

        if (closeModal) {
          closeModal(createdGoal);
        }
      }
    } catch (error) {
      localStore.setIsLoading(false);

      setError(error);
    }
  };

  const _updateGoal = async () => {
    try {
      localStore.setIsLoading(true);

      const data = {
        title: localStore.goalName,
        description: localStore.goalDescription,
        is_client_managed: localStore.isClientManaged,
        expand,
      };

      const updatedGoal = (await goalStore.update(goal as Goal, data)) as GoalT;

      if (
        updatedGoal.course &&
        updatedGoal.course.status === 'ongoing' &&
        updatedGoal.course_id
      ) {
        await goalStore.coach.bound.synchronize({
          course_id: updatedGoal.course_id,
        });
      }

      localStore.setIsLoading(false);

      if (closeModal) {
        closeModal(updatedGoal);
      }
    } catch (error) {
      localStore.setIsLoading(false);

      setError(error);
    }
  };

  const handleOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.setGoalNameError(null);
    localStore.setGoalName(event.currentTarget.value as string);
  };

  const handleOnChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    localStore.setGoalDescription(event.currentTarget.value as string);
  };

  const _onIsClientManagedChange = () => {
    localStore.setIsClientManaged(!localStore.isClientManaged);
  };

  return (
    <Observer>
      {() => (
        <div className={`windowContainerBodyHeader ${styles.CRUGoal}`}>
          <div className={'mainContainer'}>
            <Scrollbar
              className={`scrollbar ${styles.mainSectionContainer}`}
              noScrollX
              wrapperProps={{className: 'wrapper'}}
              trackYProps={{className: 'trackY'}}
              thumbYProps={{className: 'thumbY'}}
              trackXProps={{className: 'trackX'}}
              thumbXProps={{className: 'thumbY'}}
            >
              <div
                className={`CreateEvent resultContainer ${styles.CreateEvent}`}
              >
                <div>
                  <h3>Goal name:</h3>
                  <CustomInput
                    type="input"
                    label={labelText()}
                    id="name"
                    hideLabel
                    customClassLabel="QStandardLabel"
                    customClassCont="QStandardContainer"
                    customClassInput="QStandardInput"
                    value={localStore.goalName}
                    onChange={handleOnChangeName}
                    error={localStore.goalNameError}
                    showErrorImmediately
                  />
                  <br />
                  <div className={`customGeneratorCheckBox ${styles.mt3}`}>
                    <h3>{`${t([I18N_SCOPE, 'is_client_managed_label'])}:`}</h3>
                    <div className={styles.switchDescription}>
                      {t([I18N_SCOPE, 'is_client_managed_description'])}
                    </div>
                    <input
                      type="checkbox"
                      className="switch"
                      defaultChecked={localStore.isClientManaged}
                      onClick={_onIsClientManagedChange}
                    />
                  </div>
                  <br />
                  <h3>Goal description:</h3>
                  <CustomInput
                    type="input"
                    label={labelText()}
                    id="Description"
                    hideLabel
                    customClassLabel="QStandardLabel"
                    customClassCont="QStandardContainer"
                    customClassInput="QStandardInput"
                    value={localStore.goalDescription}
                    onChange={handleOnChangeDescription}
                    error={localStore.goalDescriptionError}
                    showErrorImmediately
                  />
                </div>
              </div>
            </Scrollbar>
            <div className={styles.createFooterButtContainer}>
              <div className={styles.createButtContainer}>
                <CustomButton
                  type="button"
                  classButton="blueButt"
                  onClick={handleCreateGoal}
                >
                  <span>{`${goal ? 'Edit' : 'Create'} goal`}</span>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(CRUGoal);
