import type {FC} from 'react';
import React, {memo, useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {RpcRequest} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileName} from '@yourcoach/shared/api/media/file';

import {labelErrorOccurred} from '@src/common/i18n/i18nCommon';
import {setError} from '@src/common/setError';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton} from '@src/components/CustomForm';
import GetUserFile from '@src/components/GetUserFile/GetUserFile';
import type {TListDataItem} from '@src/models/library';
import {linkExpand} from '@src/models/library';
import LibraryState from '@src/modules/MyLibrary/context/LibraryState';
import ListFoldersSelect from '@src/modules/MyLibrary/ListFoldersSelect/ListFoldersSelect';

import styles from './styles.module.css';

interface ILocalStore {
  listSelect: TListDataItem[];
  attachments: IFile[];
  folderId: string | null;
  setFileAttachment(attachments: IFile[]): void;
  _removeFileAttachment(): void;
  isOpenModalGetUserFile: boolean;
  setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean): void;
}

interface Props {
  getListItems: (listSelect: TListDataItem[]) => void;
}

const AddFile: FC<Props> = ({getListItems}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        listSelect: [],
        folderId: null,
        attachments: [],
        setFileAttachment(attachments: IFile[]) {
          attachments.forEach(file => {
            this.attachments.push(file);
          });
        },
        _removeFileAttachment() {
          this.attachments = [];
        },
        isOpenModalGetUserFile: false,
        setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean) {
          this.isOpenModalGetUserFile = isOpenModalGetUserFile;
        },
      },
      {
        listSelect: observable,
        folderId: observable,
        isOpenModalGetUserFile: observable,
        setIsOpenModalGetUserFile: action,
        attachments: observable,
        setFileAttachment: action,
        _removeFileAttachment: action,
      },
    ),
  ).current;

  const handeleAddList = () => {
    getListItems(localStore.listSelect.slice());
  };

  const updateListSelect = (listSelect: TListDataItem[]) => {
    localStore.listSelect = listSelect;
  };

  const GetUserFileClose = () => {
    localStore!.setIsOpenModalGetUserFile(false);
  };
  const getUserUploadFile = (files: IFile[]) => {
    if (Array.isArray(files) && files) {
      localStore!._removeFileAttachment();

      localStore!.setFileAttachment(files);

      _linkFiles(files);
    }
  };
  const openModalGetUserFile = () => {
    localStore!.setIsOpenModalGetUserFile(true);
  };

  const _linkFiles = async (files: IFile[]) => {
    const folderId = localStore.folderId;

    try {
      const batch: RpcRequest[] = files.map(file => ({
        method: 'media.links.create',
        params: {
          name: getFileName(file),
          folder_id: folderId || null,
          resource_id: file._id,
          expand: linkExpand,
        },
      }));

      await apiRequest({batch});
    } catch (error) {
      getCustomConfirmAlert({
        title: labelErrorOccurred(),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });

      setError(error);
    }
  };

  return (
    <Observer>
      {() => (
        <LibraryState>
          <div className="windowContainerBodyHeader">
            <div className={'mainContainer'}>
              <div className="CreateEvent resultContainer">
                <Scrollbar
                  className={`scrollbar ${styles.mainSectionContainer}`}
                  noScrollX
                  wrapperProps={{className: 'wrapper'}}
                  trackYProps={{className: 'trackY'}}
                  thumbYProps={{className: 'thumbY'}}
                  trackXProps={{className: 'trackX'}}
                  thumbXProps={{className: 'thumbY'}}
                >
                  <ListFoldersSelect
                    listSelect={localStore.listSelect}
                    updateListSelect={updateListSelect}
                    loadFiles={localStore.attachments}
                  />
                </Scrollbar>
              </div>
              <div className={styles.createFooterButtContainer}>
                <div className={styles.addFileButtContainer}>
                  <CustomButton
                    type="button"
                    classButton="blueButt"
                    onClick={openModalGetUserFile}
                  >
                    <span>Add file</span>
                  </CustomButton>
                </div>
                <div className={styles.createButtContainer}>
                  <CustomButton
                    type="button"
                    classButton="blueButt"
                    onClick={handeleAddList}
                  >
                    <span>Choose</span>
                  </CustomButton>
                </div>
              </div>
              <GetUserFile
                showModal={localStore!.isOpenModalGetUserFile}
                closeModal={GetUserFileClose}
                getFile={getUserUploadFile}
                multiple={true}
              />
            </div>
          </div>
        </LibraryState>
      )}
    </Observer>
  );
};

export default memo(AddFile);
