import type {FC} from 'react';
import React, {memo, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {labelNewAnswer} from '@src/common/i18n/i18nQuestionnaire';

import type {ITypeInput} from '..';
import TemplateChoice from '../TemplateChoice/TemplateChoice';
import {TextType} from '..';

import styles from './../../styles.module.css';

interface Props extends ITypeInput {
  isOpen?: boolean;
  isRadio?: boolean;
}

interface ILocalSingleChoiceType {
  question: QuestionnaireQuestion;
  setQuestion: (newQ: QuestionnaireQuestion) => void;
  setInitialQuestion: (newQ: QuestionnaireQuestion) => void;
}

const SingleChoiceType: FC<Props> = ({
  isOpen = false,
  isRadio = true,
  question,
  delInput,
  saveInput,
  updateTypeOpen,
  setOldQuestion,
  sectionId,
  indexQ,
  numQuestion,
}) => {
  const localStore = useMemo(
    () =>
      observable<ILocalSingleChoiceType>(
        {
          question: question,
          setInitialQuestion(newQ: QuestionnaireQuestion) {
            this.question = newQ;
          },
          setQuestion(newQ: QuestionnaireQuestion) {
            this.question = newQ;
            saveInput(newQ);
          },
        },
        {
          question: observable,
          setInitialQuestion: action,
          setQuestion: action,
        },
      ),
    [question, saveInput],
  );

  const saveInputText = (textQObj: QuestionnaireQuestion) => {
    localStore.setQuestion({...localStore.question, ...textQObj});
  };

  const saveOptions = (newOptions: string[]) => {
    localStore.setQuestion({
      ...localStore.question,
      options: [...newOptions.slice()],
    });
  };

  const delOption = (numDelOption: number) => {
    const newOptions = localStore.question.options!.slice();

    newOptions.splice(numDelOption, 1);

    localStore.setQuestion({
      ...localStore.question,
      options: [...newOptions.slice()],
    });
  };

  useEffect(() => {
    localStore.setInitialQuestion({
      is_optional: question.is_optional,
      question: question.question,
      type: question.type,
      options: question.options
        ? [...question.options!.slice()]
        : [labelNewAnswer()],
    });
  }, [question, localStore]);

  return (
    <Observer>
      {() => (
        <div className={`SingleChoiceType ${styles.SingleChoiceType}`}>
          <TextType
            sectionId={sectionId}
            question={question}
            saveInput={saveInputText}
            setOldQuestion={setOldQuestion}
            delInput={delInput}
            indexQ={indexQ}
            numQuestion={numQuestion}
            isPart
          />
          <div className={styles.bodyChoice}>
            <div
              className={`${styles.bodyChoiceContainer} ${styles.textContainerQ}`}
            >
              <TemplateChoice
                sectionId={sectionId}
                isOpen={isOpen}
                isRadio={isRadio}
                numQuestion={numQuestion}
                options={localStore.question.options}
                saveOptions={saveOptions}
                delOption={delOption}
                updateTypeOpen={updateTypeOpen}
              />
            </div>
            <div />
            <div />
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SingleChoiceType);
