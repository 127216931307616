import type {FC} from 'react';
import React, {memo, useEffect, useRef} from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {CollectionStore, RpcRequest} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Goal} from '@yourcoach/shared/api/goal';
import {getGoalAttitudeIcon} from '@yourcoach/shared/api/goal';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton} from '@src/components/CustomForm';
import Loader from '@src/components/Loader/Loader';
import {t} from '@src/i18n';
import CUP_IMG from '@src/modules/legacy/Goals/assets/cup.png';
import CUP_IMG_X2 from '@src/modules/legacy/Goals/assets/cup@2x.png';
import CUP_IMG_X3 from '@src/modules/legacy/Goals/assets/cup@3x.png';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'RateGoals';

interface ILocalStore {
  goalsStore: CollectionStore<Goal> | null;
  setGoalsStore(goalsStore: CollectionStore<Goal> | null): void;
  _fetchGoals(): Promise<void>;
}

interface Props {
  courseId: string;
  goals?: Goal[];
  closeModal?: () => void;
}

const RateGoals: FC<Props> = ({courseId, closeModal}) => {
  const localStore: ILocalStore = useRef(
    observable(
      {
        goalsStore: null,
        setGoalsStore(goalsStore: CollectionStore<Goal> | null) {
          this.goalsStore = goalsStore;
        },
        async _fetchGoals() {
          try {
            await this.goalsStore!.fetch();
          } catch (error) {
            getCustomConfirmAlert({
              title: t('shared.message.deleting_error'),
              message: error.message,
              buttons: [
                {
                  label: t('shared.button.try_later'),
                  onClick: () => {},
                },
                {
                  label: t('shared.button.try_again'),
                  onClick: () => {
                    localStore._fetchGoals();
                  },
                  type: 'confirm',
                },
              ],
            });
          }
        },
      },
      {
        goalsStore: observable,
        setGoalsStore: action,
        _fetchGoals: action,
      },
    ),
  ).current;

  useEffect(() => {
    localStore.setGoalsStore(
      createCollectionStore({
        method: 'client.goals.list',
        params: {
          query: [['course_id', '==', courseId]],
        },
      }),
    );

    localStore._fetchGoals();

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onAttitudeValueChange = (item: Goal, value: number) => {
    localStore.goalsStore!.updateItem(item, {
      client_attitude: value / 100,
    });
  };

  const _onConfirmButtonPress = async () => {
    try {
      const batchRequest = {
        batch: localStore.goalsStore!.items.map(
          (goal): RpcRequest => ({
            method: 'client.goals.mark_reached',
            params: {
              _id: goal._id,
              client_attitude:
                goal.client_attitude !== null ? goal.client_attitude : 1,
            },
          }),
        ),
      };

      await apiRequest(batchRequest);

      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`RateGoals ${styles.RateGoals}`}>
          <div className={styles.headerContainer}>
            <div className={`${styles.cup} animate__animated animate__fadeIn`}>
              <img
                src={CUP_IMG}
                srcSet={`${CUP_IMG_X2} 2x, ${CUP_IMG_X3} 3x`}
                alt="cup image"
              />
            </div>
            <div className={styles.headerTitle}>
              {t([I18N_SCOPE, 'header_title'])}
            </div>
            <div className={styles.headerDescription}>
              {t([I18N_SCOPE, 'header_description'])}
            </div>
          </div>
          <div className={styles.contentContainer}>
            {localStore.goalsStore && localStore.goalsStore.isLoaded ? (
              <div className={styles.listHeader}>
                {t([I18N_SCOPE, 'estimate_label'])}
              </div>
            ) : localStore.goalsStore && localStore.goalsStore.isFetching ? (
              <Loader />
            ) : null}
            <div className={styles.goalsListContainer}>
              {localStore.goalsStore
                ? localStore.goalsStore.items.slice().map((item, index) => {
                    const isEven = !!(index % 2);

                    return (
                      <div
                        key={item._id}
                        className={`${styles.itemContainer} ${
                          isEven ? styles.isEven : styles.isNoEven
                        }`}
                      >
                        <div className={styles.itemContentContainer}>
                          <div className={styles.goalLabel}>
                            {t('label.goal_index', {
                              index: index + 1,
                            })}
                          </div>
                          <div className={styles.goalTitleText}>
                            {item.title}
                          </div>
                          <div className={styles.goalDescription}>
                            {item.description}
                          </div>
                        </div>
                        <div
                          className={`customSlider ${styles.sliderContainer}`}
                        >
                          <Slider
                            min={0}
                            max={100}
                            tooltip={false}
                            value={
                              item.client_attitude !== null
                                ? (item.client_attitude * 1000) / 10
                                : 100
                            }
                            orientation="horizontal"
                            onChange={value =>
                              _onAttitudeValueChange(item, value)
                            }
                          />
                          <div className={styles.sliderContentContainer}>
                            <div className={styles.swipeItLabel}>
                              {t([I18N_SCOPE, 'swipe_it_label'])}
                            </div>
                            <div className={styles.sliderValueContainer}>
                              <div className={styles.attitudeIcon}>
                                {getGoalAttitudeIcon(
                                  item.client_attitude !== null
                                    ? (item.client_attitude * 1000) / 10
                                    : 100,
                                )}
                              </div>
                              <div className={styles.sliderValue}>{`${
                                item.client_attitude !== null
                                  ? (item.client_attitude * 1000) / 10
                                  : 100
                              } %`}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className={styles.footerButtonContainer}>
              {localStore.goalsStore &&
              !localStore.goalsStore.isFetching &&
              localStore.goalsStore.items.some(
                item => item.status !== 'archived',
              ) ? (
                <CustomButton
                  type="button"
                  classButton={`blueButt ${styles.saveBut}`}
                  onClick={_onConfirmButtonPress}
                >
                  <span>{t([I18N_SCOPE, 'footer_button'])}</span>
                </CustomButton>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(RateGoals);
