import type {FC} from 'react';
import React, {memo, useState} from 'react';
import Fade from 'react-reveal/Fade';

interface Props {
  id: string;
  label?: string;
  checked?: boolean;
  error?: string;
  customClassCont?: string;
  customClassInput?: string;
  customClassLabel?: string;
  customClassError?: string;
  onChange?: (boolean) => void;
}

const CustomCheckbox: FC<Props> = (props: Props) => {
  const {
    id,
    label = '',
    checked = false,
    error = '',
    onChange,
    customClassCont = '',
    customClassInput = '',
    customClassLabel = '',
    customClassError = '',
  } = props;
  const [isChecked, setChecked] = useState(checked);

  const handleOnChange = e => {
    setChecked(e.target.checked);

    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div className={`customCheckBoxContainer ${customClassCont}`}>
      <div className={`customCheckBox ${customClassInput}`}>
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          onChange={handleOnChange}
        />
        <label className={customClassLabel} htmlFor={id}>
          {label}
        </label>
      </div>
      <Fade collapse when={error !== ''}>
        <div className={`errorContainer ${customClassError}`} />
      </Fade>
    </div>
  );
};

export default memo(CustomCheckbox);
