import type {FC} from 'react';
import React, {memo, useCallback, useContext, useEffect, useMemo} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import {createPortal} from 'react-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {ApiRpcQuery, CollectionStore} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  datetimeObjToISOString,
} from '@yourcoach/shared/api';
import type {
  Goal as IGoal,
  Subgoal as ISubgoal,
} from '@yourcoach/shared/api/goal';
import {logger} from '@yourcoach/shared/utils/logger';

import {labelMainGoal} from '@src/common/i18n/i18nPrograms';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import CupIcon from '@src/components/MainBanner/assets/cup.svg';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import useIsDOMRendered from '@src/hooks/useIsDOMRendered';
import {t} from '@src/i18n';
import {expand as goalExpand} from '@src/models/goals';
import AddGoals from '@src/modules/legacy/Goals/AddGoals/AddGoals';
import {CLIENT_SUBGOALS_BUTTON_ID} from '@src/modules/legacy/Goals/ClientSubgoalsButton/ClientSubgoalsButton';
import CRUGoal from '@src/modules/legacy/Goals/CRUGoal/CRUGoal';
import CRUSubgoal from '@src/modules/legacy/Goals/CRUSubgoal/CRUSubgoal';
import type {GoalT} from '@src/modules/legacy/Goals/Goal/Goal';
import Goal from '@src/modules/legacy/Goals/Goal/Goal';
import RateGoal from '@src/modules/legacy/Goals/RateGoal/RateGoal';
import AddItemButton from '@src/pages/NewLayout/components/AddItemButton';

import styles from './../../styles.module.scss';
import CourseGoalsListItem from './CourseGoalsListItem/CourseGoalsListItem';

const LIMIT = 100;
const I18N_SCOPE = 'CourseGoalsTab';
const I18N_SCOPE_CRU_SUBGOAL = 'CRUSubgoal';

export type Goal = IGoal & {
  index: number;
  subgoals: Subgoal[];
};

export type Subgoal = ISubgoal & {
  index: number;
  goalIndex: number;
  isLast?: boolean;
};

export type ListItemT = Goal | Subgoal;

interface Section {
  data: ListItemT[];
}

interface ILocalStore {
  goalsStore: CollectionStore<Goal> | null;
  setGoalsStore(goalsStore: CollectionStore<Goal>): void;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
  userIsCourseCoach: boolean;
  shouldRenderStub: boolean;
  isFetching: boolean;
  sections: Section[];
  isOpenAddGoalModule: boolean;
  setIsOpenAddGoalModule(isOpenAddGoalModule: boolean): void;
  isOpenEditGoalModule: boolean;
  setIsOpenEditGoalModule(isOpenEditGoalModule: boolean): void;
  isOpenGoalProgressModule: boolean;
  setIsOpenGoalProgressModule(isOpenGoalProgressModule: boolean): void;
  isCreateGoal: boolean;
  isLoadListGoal: boolean;
  selectGoal: Goal | null;
  setSelectGoal(selectGoal: Goal | null): void;
  selectSubgoal: Subgoal | null;
  setSelectSubgoal(selectSubgoal: Subgoal | null): void;
  isOpenCRUSubgoalModule: boolean;
  setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean): void;
  selectRightGoal: ISelectRightGoal | null;
  setSelectRightGoal(obj: ISelectRightGoal | null): void;
  selectRateItemGoal: Goal | null;
  setSelectRateItemGoal(selectRateItemGoal: Goal | null): void;
  timerIdBanner: number;
}

export interface ISelectRightGoal {
  selectGoal: GoalT;
  selectSubgoalId: string | null;
  showClientsSubgoals?: boolean;
  isEditable: boolean;
  selectUserId?: string;
  forCoach?: boolean;
}

interface Props {
  course: any;
  isBannerAddGoalClick?: boolean;
  onRefetchCourse?: () => Promise<void>;
  variant?: string;
}

const CourseGoalsTab: FC<Props> = ({
  course,
  isBannerAddGoalClick = false,
  onRefetchCourse,
  variant,
}) => {
  const {
    stores: {currentUserStore, goalStore},
  } = useContext(AppContext);

  const {isDOMRendered} = useIsDOMRendered();
  const user = currentUserStore.user;
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          timerIdBanner: 0,
          selectRightGoal: null,
          setSelectRightGoal(obj: ISelectRightGoal | null) {
            this.selectRightGoal = obj;
          },
          selectRateItemGoal: null,
          setSelectRateItemGoal(selectRateItemGoal: Goal | null) {
            this.selectRateItemGoal = selectRateItemGoal;
          },
          goalsStore: null,
          setGoalsStore(goalsStore: CollectionStore<Goal>) {
            this.goalsStore = goalsStore;
          },
          isLoading: false,
          setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
          },
          isOpenAddGoalModule: false,
          setIsOpenAddGoalModule(isOpenAddGoalModule: boolean) {
            this.isOpenAddGoalModule = isOpenAddGoalModule;
          },
          isOpenEditGoalModule: false,
          setIsOpenEditGoalModule(isOpenEditGoalModule: boolean) {
            this.isOpenEditGoalModule = isOpenEditGoalModule;
          },
          isOpenGoalProgressModule: false,
          setIsOpenGoalProgressModule(isOpenGoalProgressModule: boolean) {
            this.isOpenGoalProgressModule = isOpenGoalProgressModule;
          },
          selectGoal: null,
          setSelectGoal(selectGoal: Goal | null) {
            this.selectGoal = selectGoal;
          },
          selectSubgoal: null,
          setSelectSubgoal(selectSubgoal: Subgoal | null) {
            this.selectSubgoal = selectSubgoal;
          },
          isOpenCRUSubgoalModule: false,
          setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean) {
            this.isOpenCRUSubgoalModule = isOpenCRUSubgoalModule;
          },
          get userIsCourseCoach() {
            return !!(user && course.coach_ids.includes(user._id));
          },
          get shouldRenderStub() {
            return (
              this.goalsStore &&
              this.goalsStore.isLoaded &&
              !this.goalsStore.hasItems
            );
          },
          get isFetching() {
            return (
              this.goalsStore &&
              (!this.goalsStore.isLoaded || this.goalsStore.isFetching)
            );
          },
          get sections() {
            let goals = this.goalsStore.items.slice();

            const sections: Section[] = [];

            if (goals.length) {
              goals.forEach((goal, index) => {
                const data: ListItemT[] = [];

                data.push({...goal, index});

                if (goal.subgoals.length) {
                  let subgoals = goal.subgoals.slice();

                  if (!this.userIsCourseCoach) {
                    subgoals = subgoals.sort((a, b) =>
                      b.is_client_managed === a.is_client_managed
                        ? 0
                        : b.is_client_managed
                        ? -1
                        : 1,
                    );
                  }

                  data.push(
                    ...subgoals.map((sgoal, sgIndex) => ({
                      ...sgoal,
                      index: sgIndex,
                      goalIndex: index,
                    })),
                  );

                  if (
                    !(
                      this.userIsCourseCoach &&
                      (goal.is_client_managed || goal.counters.client_subgoals)
                    )
                  ) {
                    (data[data.length - 1] as Subgoal).isLast = true;
                  }
                }

                if (
                  this.userIsCourseCoach &&
                  (goal.is_client_managed || goal.counters.client_subgoals)
                ) {
                  data.push({
                    ...goal,
                    index,
                    _id: `${CLIENT_SUBGOALS_BUTTON_ID}|${goal._id}`,
                  });
                }

                sections.push({
                  data,
                });
              });
            }

            return sections;
          },
          get isCreateGoal() {
            if (this.userIsCourseCoach) {
              if (
                (course.status === 'planned' || course.status === 'ongoing') &&
                this.goalsStore &&
                !this.goalsStore.items.slice().length
              ) {
                return true;
              }
            }

            return false;
          },
          get isLoadListGoal() {
            if (this.goalsStore && this.goalsStore.items.slice().length) {
              return true;
            }

            return false;
          },
        },
        {
          goalsStore: observable,
          setGoalsStore: action,
          isLoading: observable,
          setIsLoading: action,
          isOpenAddGoalModule: observable,
          setIsOpenAddGoalModule: action,
          isOpenEditGoalModule: observable,
          setIsOpenEditGoalModule: action,
          isOpenGoalProgressModule: observable,
          setIsOpenGoalProgressModule: action,
          userIsCourseCoach: computed,
          shouldRenderStub: computed,
          isFetching: computed,
          sections: computed,
          isCreateGoal: computed,
          isLoadListGoal: computed,
          selectGoal: observable,
          setSelectGoal: action,
          selectSubgoal: observable,
          setSelectSubgoal: action,
          isOpenCRUSubgoalModule: observable,
          setIsOpenCRUSubgoalModule: action,
          selectRightGoal: observable.shallow,
          setSelectRightGoal: action,
          selectRateItemGoal: observable,
          setSelectRateItemGoal: action,
          timerIdBanner: observable,
        },
      ),
    [course.coach_ids, course.status, user],
  );

  const fetchData = useCallback(
    async (silent = false) => {
      localStore.goalsStore!.setMethod(
        `${
          localStore.userIsCourseCoach
            ? 'coach.goals.unbound.list'
            : 'client.goals.list'
        }`,
      );

      localStore
        .goalsStore!.fetch(
          {
            query: (
              [course ? ['course_id', '==', course._id] : null] as ApiRpcQuery[]
            ).filter(Boolean),
            limit: Math.max(localStore.goalsStore!.items.length, LIMIT),
          },
          {silent},
        )
        .catch(() => {
          // TODO: log error
        });
    },
    [course, localStore.goalsStore, localStore.userIsCourseCoach],
  );

  const handleClickProlong = async () => {
    try {
      await onRefetchCourse?.();
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    localStore.setGoalsStore(
      createCollectionStore({
        params: {
          sort: [['sort_order', 1]],
          limit: LIMIT,
          expand: goalExpand,
        },
      }),
    );

    const disposeGoalStoreCreating = reaction(
      () => goalStore.creating,
      creating => {
        if (
          creating.success &&
          creating.entity &&
          creating.entity.course_id === course._id
        ) {
          localStore.setIsOpenAddGoalModule(false);
          localStore.goalsStore!.addItem(creating.entity as Goal);
        }
      },
    );
    const disposeGoalStoreCreatingBatch = reaction(
      () => goalStore.creatingBatch,
      creating => {
        if (creating.success) {
          const courseGoals = creating.entities.filter(
            entity => entity.course_id === course._id,
          );

          if (courseGoals.length) {
            courseGoals
              .sort((a, b) => a.sort_order - b.sort_order)
              .forEach(goal => {
                localStore.goalsStore!.addItem(goal as Goal);
              });
          }
        }
      },
    );
    const disposeGoalStoreUpdating = reaction(
      () => goalStore.updating,
      updating => {
        if (
          updating.success &&
          updating.entity &&
          updating.entity.course_id === course._id
        ) {
          localStore.goalsStore!.updateItem(
            updating.entity as Goal,
            updating.entity,
          );
        }
      },
    );
    const disposeGoalStoreDeleting = reaction(
      () => goalStore.deleting,
      deleting => {
        if (
          deleting.success &&
          deleting.entity &&
          deleting.entity.course_id === course._id
        ) {
          localStore.goalsStore!.removeItem(deleting.entity as Goal);
        }
      },
    );
    const disposeGoalStoreSubCreating = reaction(
      () => goalStore.sub.creating,
      creating => {
        if (
          creating.success &&
          creating.entity &&
          creating.entity.course_id === course._id
        ) {
          const goalIndex = localStore.goalsStore!.items.findIndex(
            item => item._id === creating.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore!.items[goalIndex];

            const subgoals = [...goalItem.subgoals, creating.entity].map(
              (sGoal, sgIndex) => ({
                ...sGoal,
                index: sgIndex,
                goalIndex: goalIndex,
                isLast: sgIndex === goalItem.subgoals.length + 2,
              }),
            );

            goalItem.subgoals = subgoals;
            goalItem.subgoal_ids.push(creating.entity._id);

            localStore.goalsStore!.updateItem(goalItem, goalItem);
          }
        }
      },
    );
    const disposeGoalStoreSubCreatingBatch = reaction(
      () => goalStore.sub.creatingBatch,
      creating => {
        if (creating.success) {
          const courseSubgoals = creating.entities.filter(
            entity => entity.course_id === course._id,
          );

          courseSubgoals.forEach(subgoal => {
            const goalIndex = localStore.goalsStore!.items.findIndex(
              item => item._id === subgoal.goal_id,
            );

            if (goalIndex >= 0) {
              const goalItem = localStore.goalsStore!.items[goalIndex];

              const subgoals = [...goalItem.subgoals, subgoal].map(
                (sGoal, sgIndex) => ({
                  ...sGoal,
                  index: sgIndex,
                  goalIndex: goalIndex,
                  isLast: sgIndex === goalItem.subgoals.length + 2,
                }),
              );

              goalItem.subgoals = subgoals;
              goalItem.subgoal_ids.push(subgoal._id);

              localStore.goalsStore!.updateItem(goalItem, goalItem);
            }
          });
        }
      },
    );
    const disposeGoalStoreSubUpdating = reaction(
      () => goalStore.sub.updating,
      updating => {
        if (
          updating.success &&
          updating.entity &&
          updating.entity.course_id === course._id
        ) {
          const goalIndex = localStore.goalsStore!.items.findIndex(
            item => item._id === updating.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore!.items[goalIndex];

            const subGoalIndex = goalItem.subgoal_ids.findIndex(
              item => item === updating.entity!._id,
            );

            if (subGoalIndex >= 0) {
              const subGoalItem = goalItem.subgoals[subGoalIndex];

              goalItem.subgoals[subGoalIndex] = {
                ...subGoalItem,
                ...updating.entity,
                index: subGoalItem.index,
                isLast: subGoalItem.isLast,
                goalIndex: goalIndex,
              };

              localStore.goalsStore!.updateItem(goalItem, goalItem);

              const isEditable = true;

              const goal = {
                ...goalItem,
                program: course.program,
                course,
              };

              localStore.setSelectRateItemGoal(null);

              localStore.setSelectRightGoal({
                selectGoal: goal,
                selectSubgoalId: subGoalItem._id,
                isEditable,
              });
            }
          }
        }
      },
    );
    const disposeGoalStoreSubClientUpdating = reaction(
      () => goalStore.sub.client.updating,
      updating => {
        if (
          updating.success &&
          updating.entity &&
          updating.entity.course_id === course._id
        ) {
          const goalIndex = localStore.goalsStore!.items.findIndex(
            item => item._id === updating.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore!.items[goalIndex];

            const subGoalIndex = goalItem.subgoal_ids.findIndex(
              item => item === updating.entity!._id,
            );

            if (subGoalIndex >= 0) {
              const subGoalItem = goalItem.subgoals[subGoalIndex];

              goalItem.subgoals[subGoalIndex] = {
                ...subGoalItem,
                ...updating.entity,
                index: subGoalItem.index,
                isLast: subGoalItem.isLast,
                goalIndex: goalIndex,
              };

              localStore.goalsStore!.updateItem(goalItem, goalItem);
            }
          }
        }
      },
    );
    const disposeGoalStoreSubDeleting = reaction(
      () => goalStore.sub.deleting,
      deleting => {
        if (
          deleting.success &&
          deleting.entity &&
          deleting.entity.course_id === course._id
        ) {
          const goalIndex = localStore.goalsStore!.items.findIndex(
            item => item._id === deleting.entity!.goal_id,
          );

          if (goalIndex >= 0) {
            const goalItem = localStore.goalsStore!.items[goalIndex];

            const subGoalIndex = goalItem.subgoal_ids.findIndex(
              item => item === deleting.entity!._id,
            );

            if (subGoalIndex >= 0) {
              goalItem.subgoal_ids.splice(subGoalIndex, 1);

              goalItem.subgoals.splice(subGoalIndex, 1);

              const subgoals = goalItem.subgoals.map((sGoal, sgIndex) => ({
                ...sGoal,
                index: sgIndex,
                goalIndex: goalIndex,
                isLast: sgIndex === goalItem.subgoals.length + 1,
              }));

              goalItem.subgoals = subgoals;

              localStore.goalsStore!.updateItem(goalItem, goalItem);
            }
          }
        }
      },
    );

    const timerId = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      disposeGoalStoreCreating();
      disposeGoalStoreCreatingBatch();
      disposeGoalStoreUpdating();
      disposeGoalStoreDeleting();
      disposeGoalStoreSubCreating();
      disposeGoalStoreSubCreatingBatch();
      disposeGoalStoreSubUpdating();
      disposeGoalStoreSubClientUpdating();
      disposeGoalStoreSubDeleting();
      clearTimeout(timerId);
      clearTimeout(localStore.timerIdBanner);

      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }
    };
  }, [
    course,
    fetchData,
    goalStore.creating,
    goalStore.creatingBatch,
    goalStore.deleting,
    goalStore.sub.client.updating,
    goalStore.sub.creating,
    goalStore.sub.creatingBatch,
    goalStore.sub.deleting,
    goalStore.sub.updating,
    goalStore.updating,
    localStore,
  ]);

  useEffect(() => {
    if (isBannerAddGoalClick) {
      clearTimeout(localStore.timerIdBanner);

      runInAction(() => {
        // @ts-ignore
        localStore.timerIdBanner = setTimeout(() => {
          localStore.setIsOpenAddGoalModule(true);
        }, 1000);
      });
    }

    return () => {};
  }, [isBannerAddGoalClick, localStore]);

  const handleCloseModalAddGoal = () => {
    localStore.setIsOpenAddGoalModule(false);
  };

  const handleOnAddMainGoal = () => {
    localStore.setIsOpenAddGoalModule(true);
  };

  const handleCloseModalGoalProgress = () => {
    localStore.setIsOpenGoalProgressModule(false);
  };

  const menuId = I18N_SCOPE;

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const MyMenu = ({isInsideModal, portalId}) => {
    return createPortal(
      <Observer>
        {() => (
          <div
            onClick={handleClickMenu}
            style={{position: 'fixed', zIndex: 2000}}
          >
            <Menu
              id={menuId}
              animation={animation.fade}
              className="contextMenu"
            >
              <Item
                onClick={handleOnEditListItemButtonPress}
                className="contextMenuItem"
              >
                {t([I18N_SCOPE, 'more_edit_button'])}
              </Item>
              <Item
                onClick={handleOnDeleteListItemButtonPress}
                className="contextMenuItem"
              >
                {t([I18N_SCOPE, 'more_delete_button'])}
              </Item>
            </Menu>
          </div>
        )}
      </Observer>,
      isInsideModal && isDOMRendered
        ? document.querySelector(portalId)
        : document.body,
    );
  };

  const handleOnEditListItemButtonPress = args => {
    const item = args.props.item as ListItemT;

    contextMenu.hideAll();

    _onEditListItemButtonPress(item);
  };

  const handleOnDeleteListItemButtonPress = args => {
    const item = args.props.item as ListItemT;

    contextMenu.hideAll();

    _onDeleteListItemButtonPress(item);
  };

  const _onEditListItemButtonPress = (item: ListItemT) => {
    const isSubgoal = item._id.split(':')[0] === 'subgoal';

    if (isSubgoal) {
      localStore.setSelectSubgoal(item as Subgoal);
      localStore.setIsOpenCRUSubgoalModule(true);
    } else {
      localStore.setSelectGoal(item as Goal);
      localStore.setIsOpenEditGoalModule(true);
    }
  };

  const _onDeleteListItemButtonPress = async (item: ListItemT) => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const isSubgoal = item._id.split(':')[0] === 'subgoal';

            if (isSubgoal) {
              await _deleteSubGoal(item as Subgoal);
            } else {
              await _deleteGoal(item as Goal);
            }
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteSubGoal = async (item: Subgoal) => {
    try {
      localStore.setIsLoading(true);

      await goalStore.sub.delete(item);
      localStore.setSelectRightGoal(null);
      localStore.setSelectRateItemGoal(null);

      if (course.status === 'ongoing') {
        await goalStore.coach.bound.synchronize({
          course_id: course._id,
        });
      }

      localStore.setIsLoading(false);
    } catch (error) {
      localStore.setIsLoading(false);

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _deleteGoal = async (item: Goal) => {
    try {
      localStore.setIsLoading(true);

      await goalStore.delete(item);
      localStore.setSelectRightGoal(null);
      localStore.setSelectRateItemGoal(null);

      if (course.status === 'ongoing') {
        await goalStore.coach.bound.synchronize({
          course_id: course._id,
        });
      }

      localStore.setIsLoading(false);
    } catch (error) {
      localStore.setIsLoading(false);

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _onListItemMoreButtonPress = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ListItemT,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    contextMenu.show({
      id: menuId,
      event: event,
      props: {
        item,
      },
    });
  };

  const _onAddSubGoalButtonPress = (item: ListItemT) => {
    localStore.setSelectGoal(item as Goal);
    localStore.setIsOpenCRUSubgoalModule(true);
  };

  const handleOnListItemPress = (isEditable: boolean) => {
    return (item: ListItemT) => {
      const isSubgoal = item._id.split(':')[0] === 'subgoal';

      if (isSubgoal) {
        const goalIndex = localStore.goalsStore!.items.findIndex(
          goal => goal._id === (item as Subgoal).goal_id,
        );

        if (goalIndex >= 0) {
          const goal = {
            ...localStore.goalsStore!.items[goalIndex],
            program: course.program,
            course,
          };

          localStore.setSelectRateItemGoal(goal as Goal);

          localStore.setSelectRightGoal({
            selectGoal: goal,
            selectSubgoalId: item._id,
            isEditable,
          });

          localStore.setIsOpenGoalProgressModule(true);
        }
      }
    };
  };

  const _onGoalResultsButtonPress = (item: ListItemT) => {
    if (localStore.selectRateItemGoal?._id === item._id) {
      localStore.setSelectRateItemGoal(null);
    } else {
      localStore.setSelectRightGoal(null);
      localStore.setSelectRateItemGoal(item as Goal);
    }
  };

  const _onClientSubgoalsButtonPress = (item: ListItemT) => {
    const [, goalId] = item._id.split('|');

    localStore.setSelectRateItemGoal(null);

    localStore.setSelectRightGoal({
      selectGoal: {
        ...item,
        _id: goalId,
        course,
        program: course.program,
      } as GoalT,
      selectSubgoalId: null,
      showClientsSubgoals: true,
      isEditable: true,
    });

    localStore.setIsOpenGoalProgressModule(true);
  };

  const handleOnEditMainGoal = () => {
    localStore.setIsOpenEditGoalModule(false);
    localStore.setSelectGoal(null);
  };

  const handleCloseModalEditGoal = () => {
    localStore.setIsOpenEditGoalModule(false);
    localStore.setSelectGoal(null);
  };

  const handleCloseModalCRUSubgoal = () => {
    localStore.setIsOpenCRUSubgoalModule(false);
    localStore.setSelectGoal(null);
    localStore.setSelectSubgoal(null);
  };

  return (
    <Observer>
      {() => (
        <div className={`CourseGoalsTab ${styles.CourseGoalsTab}`}>
          {localStore.isFetching ? (
            <Loader />
          ) : localStore.isCreateGoal ? (
            <div className={styles.noMainGoalContainer}>
              <div className={styles.imageContainer}>
                <CupIcon />
              </div>
              <div className={styles.title}>
                {t([I18N_SCOPE, 'no_main_goal'])}
              </div>
              <div className={styles.butAddGoal} onClick={handleOnAddMainGoal}>
                {t([I18N_SCOPE, 'add_goal_button'])}
              </div>
            </div>
          ) : localStore.isLoadListGoal ? (
            <div className={styles.courseGoalsListContainer}>
              <div
                className={
                  variant === 'chat_tools'
                    ? styles.firstColumnContainerInChat
                    : styles.firstColumnContainer
                }
              >
                {localStore.userIsCourseCoach && variant === 'chat_tools' ? (
                  <AddItemButton
                    onClickHandler={handleOnAddMainGoal}
                    buttonLabel={t([I18N_SCOPE, 'add_goal_button'])}
                  />
                ) : (
                  <div
                    className={styles.listGoalAddGoal}
                    onClick={handleOnAddMainGoal}
                  >
                    <div className={styles.iconPlusBut}>
                      <p>+</p>
                    </div>
                    <div className={styles.iconPlusButLabel}>
                      {t([I18N_SCOPE, 'add_goal_button'])}
                    </div>
                  </div>
                )}
                <div className={styles.courseGoalsListItemContainer}>
                  <div
                    className={
                      variant === 'chat_tools' ? styles.ScrollableArea : ''
                    }
                  >
                    {localStore.sections.slice().map(section => {
                      const keyEx = section.data.reduce(
                        (accumulator, goal) => `${accumulator}${goal._id}`,
                        '',
                      );

                      return (
                        <div
                          className={`${styles.goalSection} ${
                            localStore.selectRateItemGoal &&
                            localStore.selectRateItemGoal?._id ===
                              section.data[0]._id
                              ? styles.isActive
                              : ''
                          }`}
                          key={keyEx}
                        >
                          {section.data.slice().map(item => {
                            let isEditable = false;

                            if (localStore.userIsCourseCoach) {
                              isEditable = true;
                            } else if (
                              item.is_client_managed &&
                              user &&
                              item.user_id === user._id
                            ) {
                              isEditable = true;
                            }

                            return (
                              <CourseGoalsListItem
                                item={item}
                                key={item._id}
                                onMorePress={
                                  isEditable
                                    ? _onListItemMoreButtonPress
                                    : undefined
                                }
                                onAddSubGoalButtonPress={
                                  localStore.userIsCourseCoach ||
                                  item.is_client_managed
                                    ? _onAddSubGoalButtonPress
                                    : undefined
                                }
                                selectGoalId={
                                  localStore.selectRightGoal?.selectSubgoalId
                                }
                                isSelectRateGoal={
                                  !!localStore.selectRateItemGoal &&
                                  localStore.selectRateItemGoal?._id ===
                                    item._id
                                }
                                onPress={handleOnListItemPress(isEditable)}
                                onResultsButtonPress={
                                  localStore.userIsCourseCoach &&
                                  course.status === 'archived'
                                    ? _onGoalResultsButtonPress
                                    : undefined
                                }
                                onClientSubgoalsButtonPress={
                                  _onClientSubgoalsButtonPress
                                }
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <MyMenu isInsideModal={false} portalId={''} />
                </div>
              </div>
            </div>
          ) : (
            <NoResults text={t([I18N_SCOPE, 'no_results_label'])} />
          )}
          {localStore.selectRightGoal ? (
            <ModalAnimateWin
              showModal={localStore.isOpenGoalProgressModule}
              closeModalHandler={handleCloseModalGoalProgress}
              className="greyHeaderContainer halfContainer"
              isBody
              classNameBody="whiteBody noP30"
              header={'Goal progress'}
              classNameHeader="greyHeader"
              classNameCloseBut="greyHeaderBut"
            >
              <div className={styles.selectRightGoalContainer}>
                <Goal
                  goalId={localStore.selectRightGoal.selectGoal._id}
                  goal={localStore.selectRightGoal.selectGoal}
                  subgoalId={
                    localStore.selectRightGoal.selectSubgoalId
                      ? localStore.selectRightGoal.selectSubgoalId
                      : undefined
                  }
                  showClientsSubgoals={
                    localStore.selectRightGoal.showClientsSubgoals
                      ? localStore.selectRightGoal.showClientsSubgoals
                      : undefined
                  }
                  onMorePress={
                    localStore.selectRightGoal.isEditable
                      ? _onListItemMoreButtonPress
                      : undefined
                  }
                  forCoach={localStore.userIsCourseCoach}
                />
                {localStore.userIsCourseCoach &&
                course.status === 'archived' ? (
                  <div className={styles.selectRightGoalContainerRateGoal}>
                    <RateGoal
                      goalId={localStore.selectRightGoal.selectGoal._id}
                      goal={localStore.selectRightGoal.selectGoal}
                    />
                  </div>
                ) : null}
                <MyMenu isInsideModal={true} portalId={'#custom-modal'} />
              </div>
            </ModalAnimateWin>
          ) : null}
          <ModalAnimateWin
            showModal={localStore.isOpenAddGoalModule}
            closeModalHandler={handleCloseModalAddGoal}
            className="greyHeaderContainer halfContainer"
            isBody
            classNameBody="whiteBody noP30"
            header={t([I18N_SCOPE, 'add_goal_button'])}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <div className="whiteBodyContent">
              <AddGoals
                course={course}
                closeAddGoals={handleCloseModalAddGoal}
              />
            </div>
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.isOpenEditGoalModule}
            closeModalHandler={handleCloseModalEditGoal}
            className="greyHeaderContainer"
            isBody
            classNameBody="whiteBody noP30"
            header={`${labelMainGoal()}`}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            {localStore.selectGoal ? (
              <CRUGoal
                goal={localStore.selectGoal}
                programId={localStore.selectGoal.program_id}
                courseId={localStore.selectGoal.course_id}
                closeModal={handleOnEditMainGoal}
              />
            ) : null}
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.isOpenCRUSubgoalModule}
            closeModalHandler={handleCloseModalCRUSubgoal}
            className="greyHeaderContainer littleContainer w500 List"
            isBody
            classNameBody="whiteBody maxContent noP30"
            header={t([I18N_SCOPE_CRU_SUBGOAL, 'nav_title'])}
            classNameHeader="greyHeader w500"
            classNameCloseBut="greyHeaderBut"
          >
            {localStore.selectGoal ? ( //add subGoal
              <CRUSubgoal
                onCloseModal={handleCloseModalCRUSubgoal}
                goalId={localStore.selectGoal!._id}
                forClient={
                  localStore.selectGoal!.is_client_managed &&
                  !localStore.userIsCourseCoach
                }
                minDate={dayjs(
                  course.start_date
                    ? datetimeObjToISOString(course.start_date)
                    : undefined,
                ).toDate()}
                maxDate={dayjs().add(90, 'day').toDate()}
                onRefetchCourse={handleClickProlong}
              />
            ) : localStore.selectSubgoal ? (
              <CRUSubgoal
                onCloseModal={handleCloseModalCRUSubgoal}
                goalId={localStore.selectSubgoal.goal_id}
                forClient={
                  localStore.selectSubgoal!.is_client_managed &&
                  !localStore.userIsCourseCoach
                }
                subgoal={localStore.selectSubgoal}
                minDate={dayjs(
                  course.start_date
                    ? datetimeObjToISOString(course.start_date)
                    : undefined,
                ).toDate()}
                maxDate={dayjs().add(90, 'day').toDate()}
                onRefetchCourse={handleClickProlong}
              />
            ) : null}
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseGoalsTab);
