import type {FC} from 'react';
import React, {memo} from 'react';
import {Route, Switch} from 'react-router-dom';

import LogoOnlyLayout from '@src/layouts/LogoOnly';
import {QCreateCustom, QStandardDetail} from '@src/modules/Questionnaire';
import QUserDetail from '@src/modules/Questionnaire/QUserDetail/QUserDetail';
import {PATH_TYPE} from '@src/routes/utils';

interface Props {}

const QuestionnairePage: FC<Props> = () => {
  return (
    <main className="QuestionnairePage">
      <Switch>
        <Route
          path={`/${PATH_TYPE.questionnaire}/${PATH_TYPE.standardTemplateDetail}`}
        >
          <LogoOnlyLayout>
            <QStandardDetail />
          </LogoOnlyLayout>
        </Route>
        <Route path={`/${PATH_TYPE.questionnaire}/create`}>
          <LogoOnlyLayout>
            <QCreateCustom />
          </LogoOnlyLayout>
        </Route>
        <Route path={`/${PATH_TYPE.questionnaire}/edit`}>
          <LogoOnlyLayout>
            <QCreateCustom />
          </LogoOnlyLayout>
        </Route>
        <Route path={`/${PATH_TYPE.questionnaire}/view`}>
          <LogoOnlyLayout>
            <QUserDetail />
          </LogoOnlyLayout>
        </Route>
      </Switch>
    </main>
  );
};

export default memo(QuestionnairePage);
