import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {}

const CircleBlue: FC<Props> = () => {
  return (
    <svg viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="CircleBlue1" fill="white">
        <path d="M22.1648 79.0876C21.7977 79.7235 20.9832 79.9432 20.3589 79.5565C14.3307 75.8231 9.3131 70.6526 5.7609 64.5C2.20871 58.3474 0.239753 51.4168 0.0205541 44.3295C-0.00214781 43.5955 0.59532 43 1.32968 43C2.06405 43 2.65708 43.5955 2.68127 44.3294C2.89954 50.9498 4.7452 57.422 8.06398 63.1703C11.3828 68.9186 16.065 73.7531 21.6893 77.2523C22.3128 77.6403 22.532 78.4516 22.1648 79.0876Z" />
      </mask>
      <path
        d="M22.1648 79.0876C21.7977 79.7235 20.9832 79.9432 20.3589 79.5565C14.3307 75.8231 9.3131 70.6526 5.7609 64.5C2.20871 58.3474 0.239753 51.4168 0.0205541 44.3295C-0.00214781 43.5955 0.59532 43 1.32968 43C2.06405 43 2.65708 43.5955 2.68127 44.3294C2.89954 50.9498 4.7452 57.422 8.06398 63.1703C11.3828 68.9186 16.065 73.7531 21.6893 77.2523C22.3128 77.6403 22.532 78.4516 22.1648 79.0876Z"
        stroke="#376DAA"
        strokeWidth="20"
        mask="url(#CircleBlue1)"
      />
      <mask id="CircleBlue2" fill="white">
        <path d="M72.4654 13.5346C72.9846 13.0154 73.8282 13.014 74.3312 13.5491C79.1876 18.7155 82.6961 25.0085 84.5348 31.8708C86.3736 38.7331 86.4816 45.9372 84.8591 52.8397C84.691 53.5546 83.9598 53.9751 83.2504 53.7851C82.5411 53.595 82.1224 52.8663 82.289 52.1511C83.7916 45.6999 83.684 38.9705 81.9661 32.5591C80.2481 26.1477 76.9767 20.266 72.4497 15.4304C71.9478 14.8943 71.9461 14.0539 72.4654 13.5346Z" />
      </mask>
      <path
        d="M72.4654 13.5346C72.9846 13.0154 73.8282 13.014 74.3312 13.5491C79.1876 18.7155 82.6961 25.0085 84.5348 31.8708C86.3736 38.7331 86.4816 45.9372 84.8591 52.8397C84.691 53.5546 83.9598 53.9751 83.2504 53.7851C82.5411 53.595 82.1224 52.8663 82.289 52.1511C83.7916 45.6999 83.684 38.9705 81.9661 32.5591C80.2481 26.1477 76.9767 20.266 72.4497 15.4304C71.9478 14.8943 71.9461 14.0539 72.4654 13.5346Z"
        stroke="#376DAA"
        strokeWidth="20"
        mask="url(#CircleBlue2)"
      />
    </svg>
  );
};

export default memo(CircleBlue);
