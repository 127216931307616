import type {FC} from 'react';
import React, {memo} from 'react';

import type {QuestionnaireQuestion} from '@yourcoach/shared/api/questionnaire';

import {labelAddAnswer} from '@src/common/i18n/i18nQuestionnaire';

interface Props {
  question: QuestionnaireQuestion;
  className?: string;
  classTitle?: string;
  classAnswer?: string;
  answer?: string;
}

const LongText: FC<Props> = ({
  question,
  className = '',
  classTitle = '',
  classAnswer = '',
  answer,
}) => {
  return (
    <div className={`LongTextTemplateContainer ${className}`}>
      <div className={`name ${classTitle}`}>{question.question}</div>
      <div className={`visual ${answer ? 'answer' : ''} ${classAnswer}`}>
        {answer ? (
          <span className="labelAnswer">{answer}</span>
        ) : (
          <span className="label">{labelAddAnswer()}</span>
        )}
      </div>
    </div>
  );
};

export default memo(LongText);
