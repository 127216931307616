import type {FC} from 'react';
import React, {memo} from 'react';

interface Props {}

const BySquad: FC<Props> = () => {
  return <div className="BySquad">BySquad</div>;
};

export default memo(BySquad);
