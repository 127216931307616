import type {FC} from 'react';
import React from 'react';

import IconNewArrowPrevSVG from '@src/assets/img/new_arrow_prev.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconNewArrowPrev: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = '',
  viewBox = '0 0 28 28',
}) => {
  return (
    <IconNewArrowPrevSVG
      width={height}
      height={width}
      className={className}
      viewBox={viewBox}
    />
  );
};

export default IconNewArrowPrev;
