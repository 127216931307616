import type {FC, ReactNode} from 'react';
import React, {memo} from 'react';
import Slider from 'react-slick';
import {Observer} from 'mobx-react';

import {t} from '@src/i18n';

import CupIcon from './assets/cup.svg';
import styles from './styles.module.css';

const I18N_SCOPE_CREATE_GOAL = 'CreateGoalBanner';

const I18N_SCOPE_RATE_GOAL = 'RateGoalsBanner';

interface Props {
  children: ReactNode;
  autoplay?: boolean;
}

const MainBanner: FC<Props> = ({children, autoplay = true}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    arrows: false,
  };

  return (
    <Observer>
      {() => (
        <div className={`MainBanner ${styles.MainBanner}`}>
          <Slider {...settings}>{children}</Slider>
        </div>
      )}
    </Observer>
  );
};

export default memo(MainBanner);

interface PropsBanner {
  onClick?: () => void;
  forCoach?: boolean;
}

export const CreateGoalBanner: FC<PropsBanner> = ({onClick}) => {
  const handleClickBanner = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.banner}>
        <div className={styles.imageContainer}>
          <CupIcon />
        </div>
        <div className={styles.textBanner}>
          {t([I18N_SCOPE_CREATE_GOAL, 'title'])}
        </div>
        <div
          className={`${styles.butBanner} ${onClick ? styles.clickable : ''}`}
          onClick={handleClickBanner}
        >
          {t([I18N_SCOPE_CREATE_GOAL, 'button'])}
        </div>
      </div>
    </div>
  );
};

export const RateGoalBanner: FC<PropsBanner> = ({
  onClick,
  forCoach = false,
}) => {
  const handleClickBanner = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.banner}>
        <div className={styles.imageContainer}>
          <CupIcon />
        </div>
        <div className={styles.textBanner}>
          <div className={styles.textTitle}>
            {t([I18N_SCOPE_RATE_GOAL, 'title'])}
          </div>
          <div className={styles.textDescription}>
            {t([
              I18N_SCOPE_RATE_GOAL,
              'subtitle',
              forCoach ? 'coach' : 'client',
            ])}
          </div>
        </div>
        <div
          className={`${styles.butBanner} ${onClick ? styles.clickable : ''}`}
          onClick={handleClickBanner}
        >
          {t([I18N_SCOPE_RATE_GOAL, 'button'])}
        </div>
      </div>
    </div>
  );
};
