import type {FC} from 'react';
import React from 'react';

import IconProfileSVG from '@yourcoach/shared/assets/icons/profile.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconProfile: FC<Props> = ({
  height = '100%',
  width = '100%',
  viewBox = '2 2 22 22',
  className = '',
}) => {
  return (
    <IconProfileSVG
      className={`IconProfile ${className}`}
      width={height}
      height={width}
      viewBox={viewBox}
    />
  );
};

export default IconProfile;
