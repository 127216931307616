import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Text} from '@yourcoach/shared/uikit/Text';

import List from '@src/components/List';
import TextField from '@src/components/TextField';

import {SessionNotesListItem} from '../../SessionNotesListItem';

export const Container = styled.div`
  padding: 20px 30px 20px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ListItem = styled(SessionNotesListItem)`
  padding: 15px 0 !important;
  margin: 0;
  box-shadow: initial !important;
  border-top: 1px solid ${getColor('border2')};
  border-radius: initial !important;
`;

const SList = styled(List)`
  height: 100%;

  ${ListItem}:first-child {
    border-top: none;
  }
`;

export {SList as List};

export const ListHeader = styled(Text).attrs({as: 'p'})`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
`;

export const SearchTextInput = styled(TextField).attrs({variant: 'search'})`
  margin-bottom: 20px;
`;
