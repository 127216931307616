import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {SUPPORTED_FORMATS} from '@yourcoach/shared/api/media/file';
import {logger} from '@yourcoach/shared/utils/logger';

import Button from '@src/components/Button';
import Loader from '@src/components/Loader/Loader';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';

import AddFileIcon from './assets/add-file.svg';
import styles from './AddFile.module.css';
import FilesListItem from './AddFile-FilesListItem';

export const I18N_SCOPE = 'AddFile';

const MAX_SIZE = 1048576 * 5; // 5Mb

export interface Props {
  mode: 'upload' | 'select';
  multiple?: boolean;
  maxSize?: number;
  fileType?: 'image' | 'document';
  onUpload?: (files: IFile[], wereErrors: boolean) => void;
  onSelect?: (files: File[]) => void;
}

const AddFile: React.FC<Props> = ({
  mode = 'upload',
  multiple,
  maxSize = MAX_SIZE,
  fileType,
  onUpload,
  onSelect,
}) => {
  const {
    stores: {fileStore},
  } = useContext(AppContext);

  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  const accept = useMemo(() => {
    if (fileType) {
      return SUPPORTED_FORMATS[fileType].mimetypes.join(',');
    } else {
      return undefined;
    }
  }, [fileType]);

  const onDragStateChange = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if ('preventDefault' in e) {
        e.stopPropagation();
        e.preventDefault();
      }

      setIsDragging(e.type === 'dragover');
    },
    [],
  );

  const processFilesList = useCallback(
    (filesList: FileList) => {
      const processedFiles = Object.keys(filesList)
        .map(key => filesList[key])
        .filter((file: File) =>
          fileType
            ? SUPPORTED_FORMATS[fileType].mimetypes.includes(file.type)
            : true,
        );

      setFiles(multiple ? processedFiles : processedFiles.slice(0, 1));
    },
    [multiple, fileType],
  );

  const onDropFiles = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      onDragStateChange(e);

      processFilesList(e.dataTransfer.files);
    },
    [onDragStateChange, processFilesList],
  );

  const onFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files) {
        processFilesList(e.currentTarget.files);
      }

      // it allows to select the same file
      e.currentTarget.value = '';
    },
    [processFilesList],
  );

  const onDropContainerClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const onDeleteFileButtonClick = useCallback((file: File) => {
    setFiles(prev => prev.filter(item => item !== file));
  }, []);

  const onActionButtonClick = useCallback(async () => {
    if (mode === 'upload') {
      showOverlay();

      // TODO: use Promise.allSettled when it will be available
      const result = await Promise.all(
        files
          .map(file => {
            return async () => {
              const uploadResult = await fileStore.upload(file, {
                name: file.name,
              });

              setFiles(prev => prev.filter(item => item !== file));

              return uploadResult;
            };
          })
          .map(fn =>
            fn()
              .then(value => ({
                status: 'fulfilled',
                value,
              }))
              .catch(error => {
                logger.error(error);

                return {
                  status: 'rejected',
                  reason: error,
                };
              }),
          ),
      );

      hideOverlay();

      const successFiles = result
        .filter(item => item.status === 'fulfilled')
        // @ts-ignore
        .map(item => item.value);
      const wereErrors = !!result.filter(item => item.status === 'rejected')
        .length;

      onUpload && onUpload(successFiles, wereErrors);
    } else if (mode === 'select') {
      onSelect && onSelect(files);
    }
  }, [fileStore, files, hideOverlay, mode, onSelect, onUpload, showOverlay]);

  return (
    <div className={classNames('AddFile', styles.Component)}>
      <div
        className={classNames(
          styles.dropContainer,
          isDragging && styles.active,
        )}
        onDragOver={onDragStateChange}
        onDragLeave={onDragStateChange}
        onDrop={onDropFiles}
        onClick={onDropContainerClick}
      >
        <input
          type="file"
          tabIndex={-1}
          ref={inputRef}
          className={styles.fileInput}
          multiple={multiple}
          accept={accept}
          onChange={onFileInputChange}
        />
        <div className={styles.mozillaClipPathWorkaround}>
          <AddFileIcon />
        </div>
        <p className={styles.title}>Upload file</p>
        <p className={styles.description}>
          Drag your file here or click on this area
        </p>
      </div>
      <div className={styles.dropContainerFooter}>
        <p />
        <p className={styles.note}>Maximum size: 5Mb</p>
      </div>
      {files.length ? (
        <div className={styles.files}>
          {files.map((file, i) => (
            <FilesListItem
              file={file}
              key={i}
              onDeleteButtonClick={onDeleteFileButtonClick}
            />
          ))}
        </div>
      ) : null}
      <div className={styles.footer}>
        <Button onClick={onActionButtonClick} disabled={!files.length}>
          Upload file
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default memo(AddFile);
