import type {FC} from 'react';
import React from 'react';

import styles from './styles.module.css';

interface Props {
  className?: string;
}

const Separator: FC<Props> = ({className = ''}) => {
  return <div className={`Separator ${styles.Separator} ${className}`} />;
};

export default Separator;
