import styled from 'styled-components';

import {Button} from '@yourcoach/shared/uikit/Button';
import {Text} from '@yourcoach/shared/uikit/Text';

export const Container = styled.div`
  padding: 20px 30px 20px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ListHeader = styled(Text).attrs({as: 'p'})`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabButton = styled(Button).attrs<{active?: boolean}>(p => ({
  scheme: p.active ? 'primary' : 'gray',
}))<{active?: boolean}>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.192px;
  padding: 10px 15px;
  height: 36px;
  margin-right: 10px;
  border-radius: 36px;
`;
