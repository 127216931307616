import React from 'react';

import styled from 'styled-components';

import {View} from '@yourcoach/shared/uikit/View';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

// import {useController} from './controller';
import {containerWeb} from './styles';
import type {Props, StyledProps} from './types';

const Container = styled(View)<StyledProps>`
  ${containerWeb}
`;

const Card: React.FC<WithDomStyleProp<Props>> = ({children, ...rest}) => {
  // const controller = useController(rest);

  return <Container {...rest}>{children}</Container>;
};

export {Card};
