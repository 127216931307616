import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Observer} from 'mobx-react';

import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {CollectionStore, Expand} from '@yourcoach/shared/api';
import {apiRequest, createCollectionStore} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal, Goal as IGoal, Subgoal} from '@yourcoach/shared/api/goal';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Program} from '@yourcoach/shared/api/program';
import type {User} from '@yourcoach/shared/api/user';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import EditGoalAttitude from '../EditGoalAttitude/EditGoalAttitude';
import RateBoundGoalsListItem from '../RateBoundGoalsListItem/RateBoundGoalsListItem';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'RateGoal';

const LIMIT = 50;

const goalExpand: Expand = {
  goal: [
    'subgoal_ids',
    [
      'program_id',
      {title: 'Program not found'},
      {
        program: ['avatar_id'],
      },
    ],
    [
      'course_id',
      null,
      {
        course: [
          ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
        ],
      },
    ],
  ],
};

export type ExpandedClient = (
  | User
  | (Partial<User> & Required<Pick<User, 'name'>>)
) & {
  avatar?: IFile | null;
};

export type GoalT = IGoal & {
  subgoals: Subgoal[];
  program: Program & {
    avatar?: IFile | null;
  };
  course?: Course & {
    client: ExpandedClient;
  };
};

const boundGoalExpand: Expand = {
  goal: [
    'subgoal_ids',
    ['client_id', {name: 'Client not found'}, {user: ['avatar_id']}],
  ],
};

export type BoundGoal = Goal & {
  subgoals: Subgoal[];
  client: ExpandedClient;
  agreeInProgress?: boolean;
  disagreeInProgress?: boolean;
};

type Section = {
  id: 'goal' | 'goals';
  data: BoundGoal[];
};

interface ILocalStore {
  goal: GoalT | null;
  goalIndex?: number;
  goalsStore: CollectionStore<GoalT> | null;
  boundGoalsStore: CollectionStore<BoundGoal> | null;
  isPaginating: boolean;
  listProps: Section[];
  _fetchGoal(): Promise<void>;
  _fetchGoals(silent?: boolean | undefined): Promise<void>;
  _fetchBoundSubgoals(silent?: boolean): Promise<void>;
  isOpenEditGoalAttitudeModule: boolean;
  setIsOpenEditGoalAttitudeModule(newValue: boolean): void;
  selectEditGoalAttitude: BoundGoal | null;
  setSelectEditGoalAttitude(newValue: BoundGoal | null): void;
  timerIdCloseEditGoalAttitude: number;
}

interface Props {
  goalId: string;
  goal?: GoalT;
}

const RateGoal: FC<Props> = ({goalId, goal}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          timerIdCloseEditGoalAttitude: 0,
          goal: null,
          goalsStore: null,
          boundGoalsStore: null,
          isPaginating: false,
          isOpenEditGoalAttitudeModule: false,
          setIsOpenEditGoalAttitudeModule(newValue: boolean) {
            this.isOpenEditGoalAttitudeModule = newValue;
          },
          selectEditGoalAttitude: null,
          setSelectEditGoalAttitude(newValue: BoundGoal | null) {
            this.selectEditGoalAttitude = newValue;
          },
          get goalIndex() {
            let index: number | undefined;

            if (this.goal && this.goalsStore.isLoaded) {
              index = this.goalsStore.items.findIndex(
                item => item._id === goalId,
              );

              if (index === -1) {
                index === undefined;
              }
            }

            return index;
          },
          get listProps() {
            const sections: Section[] = [];

            if (this.goal) {
              sections.push({
                id: 'goal',
                data: [],
              });

              sections.push({
                id: 'goals',
                data: this.boundGoalsStore.items.slice(),
              });
            }

            return sections;
          },
          async _fetchGoal() {
            try {
              const _goal = (await goalStore.coach.fetch({
                _id: goalId,
                expand: goalExpand,
              })) as GoalT;

              runInAction(() => {
                this.goal = _goal;
              });
            } catch (error) {
              if (apiRequest.isCanceledError(error)) {
                return;
              }

              getCustomConfirmAlert({
                title: t('shared.message.error_fix'),
                message: error.message,
                buttons: [
                  {
                    label: t('shared.button.ok'),
                    onClick: () => {},
                  },
                ],
              });
            }
          },
          async _fetchGoals(silent = false) {
            if (!this.goal) {
              return;
            }

            await this.goalsStore.fetch(
              {
                query: [['course_id', '==', this.goal.course_id]],
              },
              {silent},
            );
          },
          async _fetchBoundSubgoals(silent = false) {
            await this.boundGoalsStore.fetch(
              {
                query: this.goal
                  ? [['parent_goal_id', '==', this.goal._id]]
                  : undefined,
                limit: Math.max(this.boundGoalsStore.items.length, LIMIT),
              },
              {silent},
            );
          },
        },
        {
          goal: observable,
          goalsStore: observable,
          boundGoalsStore: observable,
          goalIndex: computed,
          isPaginating: observable,
          listProps: computed,
          _fetchGoal: action,
          _fetchGoals: action,
          _fetchBoundSubgoals: action,
          isOpenEditGoalAttitudeModule: observable,
          setIsOpenEditGoalAttitudeModule: action,
          selectEditGoalAttitude: observable,
          setSelectEditGoalAttitude: action,
          timerIdCloseEditGoalAttitude: observable,
        },
      ),
    [goalId, goalStore.coach],
  );

  useEffect(() => {
    runInAction(() => {
      localStore.goalsStore = createCollectionStore({
        method: 'coach.goals.unbound.list',
        params: {
          sort: [['sort_order', 1]],
          expand: goalExpand,
        },
      });

      localStore.boundGoalsStore = createCollectionStore({
        method: 'coach.goals.bound.list',
        params: {
          limit: LIMIT,
          expand: boundGoalExpand,
        },
      });
    });

    const disposeGoal = reaction(
      () => localStore.goal && localStore.goal._id,
      () => {
        if (localStore.goal && localStore.goal._id) {
          localStore._fetchGoals();
          localStore._fetchBoundSubgoals();
        }
      },
    );

    const disposeGoalStoreUpdate = reaction(
      () => goalStore.updating,
      updating => {
        if (
          localStore.goal &&
          updating.success &&
          updating.entity &&
          updating.entity._id === goalId
        ) {
          Object.keys(updating.entity).forEach(key => {
            localStore.goal![key] = updating.entity![key];
          });

          localStore._fetchGoal();
        }
      },
    );

    if (goal) {
      runInAction(() => {
        localStore.goal = goal;
      });
    } else {
      localStore._fetchGoal();
    }

    return () => {
      if (localStore.goalsStore) {
        localStore.goalsStore.clear();
      }

      if (localStore.boundGoalsStore) {
        localStore.boundGoalsStore?.clear();
      }

      disposeGoal();
      disposeGoalStoreUpdate();
      clearTimeout(localStore.timerIdCloseEditGoalAttitude);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, goalId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _onBoundGoalPress = (goal: BoundGoal) => {
    // TODO: Open client goal
    //   navigation.push('ClientGoal', {
    //     goalId: goal!._id,
    //     clientId: goal.client_id!,
    //   });
  };

  const _onAgreeButtonPress = async (boundGoal: BoundGoal) => {
    if (boundGoal.client_attitude === null) {
      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'not_rated_goal_message']),
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });

      return;
    }

    try {
      if (localStore.boundGoalsStore) {
        localStore.boundGoalsStore.updateItem(boundGoal, {
          agreeInProgress: true,
        });

        await goalStore.coach.bound.commentReached(boundGoal, {
          coach_attitude: boundGoal.client_attitude,
          coach_comment: '',
        });

        localStore.boundGoalsStore.updateItem(boundGoal, {
          agreeInProgress: false,
          coach_attitude: boundGoal.client_attitude,
        });
      }
    } catch (error) {
      if (localStore.boundGoalsStore) {
        localStore.boundGoalsStore.updateItem(boundGoal, {
          agreeInProgress: false,
        });
      }
    }
  };

  const _onDisagreeButtonPress = async (boundGoal: BoundGoal) => {
    if (boundGoal.client_attitude === null) {
      getCustomConfirmAlert({
        title: t([I18N_SCOPE, 'not_rated_goal_message']),
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });

      return;
    }

    localStore.setIsOpenEditGoalAttitudeModule(true);
    localStore.setSelectEditGoalAttitude(boundGoal);
  };

  const _renderGoal = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.goal) {
            return null;
          }

          return (
            <>
              <div className={styles.goalContainer}>
                <div className={styles.goalTitleContainer}>
                  <div className={styles.goalSubtitle}>
                    {localStore.goalIndex !== undefined
                      ? t('label.goal_index', {
                          index: localStore.goalIndex + 1,
                        })
                      : '...'}
                  </div>
                  <div className={styles.goalTitle}>
                    {localStore.goal.title}
                  </div>
                </div>
              </div>
              <div className={styles.labelContainer}>
                <div>{t([I18N_SCOPE, 'client_label'])}</div>
                <div>{t([I18N_SCOPE, 'client_estimate_label'])}</div>
              </div>
              {localStore.boundGoalsStore &&
              (!localStore.boundGoalsStore.isLoaded ||
                localStore.boundGoalsStore.isFetching) ? (
                <Loader />
              ) : null}
            </>
          );
        }}
      </Observer>
    );
  };

  const handleCloseEditGoalAttitude = () => {
    localStore.setIsOpenEditGoalAttitudeModule(false);

    clearTimeout(localStore.timerIdCloseEditGoalAttitude);
    runInAction(() => {
      // @ts-ignore
      localStore.timerIdCloseEditGoalAttitude = setTimeout(() => {
        localStore.setSelectEditGoalAttitude(null);
      }, 500);
    });
  };

  const handleOnSuccess = async (attitude: number, comment?: string) => {
    if (localStore.boundGoalsStore) {
      try {
        localStore.boundGoalsStore.updateItem(
          localStore.selectEditGoalAttitude!,
          {
            disagreeInProgress: true,
          },
        );

        await goalStore.coach.bound.commentReached(
          localStore.selectEditGoalAttitude!,
          {
            coach_attitude: attitude,
            coach_comment: comment || '',
          },
        );

        localStore.boundGoalsStore!.updateItem(
          localStore.selectEditGoalAttitude!,
          {
            disagreeInProgress: false,
            coach_attitude: attitude,
            coach_comment: comment || '',
          },
        );
      } catch (error) {
        localStore.boundGoalsStore!.updateItem(
          localStore.selectEditGoalAttitude!,
          {
            disagreeInProgress: false,
          },
        );
      }
    }

    handleCloseEditGoalAttitude();
  };

  return (
    <Observer>
      {() => (
        <div className={`RateGoal ${styles.RateGoal}`}>
          <div className={styles.title}>{t([I18N_SCOPE, 'title'])}</div>
          <div>{_renderGoal()}</div>
          {localStore.boundGoalsStore
            ? localStore.boundGoalsStore.items.slice().map(item => {
                return (
                  <div key={item._id}>
                    <RateBoundGoalsListItem
                      goal={item}
                      containerStyle={styles.boundGoalContainer}
                      onPress={_onBoundGoalPress}
                      onAgreeButtonPress={_onAgreeButtonPress}
                      onDisagreeButtonPress={_onDisagreeButtonPress}
                      agreeInProgress={item.agreeInProgress}
                      disagreeInProgress={item.disagreeInProgress}
                    />
                  </div>
                );
              })
            : null}
          <ModalAnimateWin
            showModal={localStore.isOpenEditGoalAttitudeModule}
            closeModalHandler={handleCloseEditGoalAttitude}
            className="greyHeaderContainer orWhite littleContainer w440 List"
            classNameBody="whiteBody noP30"
            header={t([I18N_SCOPE, 'add_goal_button'])}
            classNameHeader="greyHeader w440"
            classNameCloseBut="greyHeaderBut"
          >
            <div className="whiteBodyContent">
              <EditGoalAttitude
                goal={localStore.selectEditGoalAttitude!}
                onSuccess={handleOnSuccess}
              />
            </div>
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(RateGoal);
