import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import {action, runInAction} from 'mobx';

import type {Edition} from '@yourcoach/shared/api/program';
import {getSecondsFromMidnight} from '@yourcoach/shared/utils/datetime';
import {validateFields} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import TextField from '@src/components/TextField';
import {t} from '@src/i18n';

import type {ProgramT} from '.';
import styles from './MaterialPublicationDay.module.css';

type ScheduledMaterial = Edition['materials'][0];

export const I18N_SCOPE = 'shared.CRUProgramMaterialPublicationDay';

export interface Props {
  program: ProgramT;
  material: ScheduledMaterial;
  onSuccess: (material: ScheduledMaterial) => void;
}

const CRUProgramMaterialPublicationDay: React.FC<Props> = ({
  program,
  material,
  onSuccess,
}) => {
  const [date, setDate] = useState(new Date());

  const lastEdition = useMemo(
    () => program.editions[program.editions.length - 1],
    [program.editions],
  );

  const day = useRef(0);

  const fields = useRef({
    day: createHtmlInputField('day', {
      validationRule: {
        type: 'string',
      },
      customValidate: action(() => {
        const field = fields.day;

        let isValid = true;

        field.error = null;

        if (!field.value) {
          field.error = t([I18N_SCOPE, 'day_required_error_message']);
          isValid = false;
        } else if (day.current > lastEdition!.duration - 1) {
          field.error = t([I18N_SCOPE, 'day_max_error_message'], {
            value: lastEdition!.duration,
          });
          isValid = false;
        }

        field.isValid = isValid;

        return isValid;
      }),
    }),
  }).current;

  useEffect(() => {
    day.current = material.day;

    setDate(dayjs(date).startOf('day').add(material.time, 'second').toDate());

    runInAction(() => {
      fields.day.value = `${day.current + 1}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let dayText = e.currentTarget.value.replace(/\D/g, '');

      if (dayText === '0') {
        dayText = '';
      }

      day.current = dayText ? parseInt(dayText, 10) : 0;

      if (day.current > 0) {
        day.current = day.current - 1;
      }

      fields.day.setValue(dayText);
      fields.day.validate();
    },
    [fields.day],
  );

  const onTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = e.currentTarget.value;

    if (timeString) {
      const [hours, mins] = timeString.split(':');

      setDate(prev =>
        dayjs(prev)
          .startOf('day')
          .add(parseInt(hours, 10) || 0, 'hours')
          .add(parseInt(mins, 10) || 0, 'minutes')
          .toDate(),
      );
    }
  }, []);

  const onSetButtonClick = useCallback(async () => {
    if (validateFields(fields)) {
      onSuccess({
        ...material,
        day: day.current,
        time: getSecondsFromMidnight(date),
      });
    }
  }, [date, fields, material, onSuccess]);

  return (
    <>
      <div
        className={classNames(
          'CRUProgramMaterialPublicationDay',
          styles.Component,
        )}
      >
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'day_label'])}:`}</h3>
          <Observer>
            {() => (
              <TextField
                value={fields.day.value}
                onChange={onDayChange}
                error={fields.day.error || ''}
                placeholder={t([I18N_SCOPE, 'day_text_input_placeholder'], {
                  min_day: 1,
                  max_day: lastEdition!.duration,
                })}
              />
            )}
          </Observer>
        </div>
        <div className={styles.container}>
          <h3>{`${t([I18N_SCOPE, 'time_label'])}:`}</h3>
          <TextField
            type="time"
            onChange={onTimeChange}
            value={dayjs(date).format('HH:mm')}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          onClick={onSetButtonClick}
          className={classNames({
            [styles.actionButton]: true,
          })}
        >
          {t([I18N_SCOPE, 'footer_button'])}
        </Button>
      </div>
    </>
  );
};

export default React.memo(CRUProgramMaterialPublicationDay);
