import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import {labelProgramDescriptionTabNoDescription} from '@src/common/i18n/i18nPrograms';
import NoResults from '@src/components/NoResults/NoResults';

import SeeProgramContext from '../context/SeeProgramContext';
import type {ISeeProgramLocalStore} from '../context/useSeeProgramLocalStore';

import styles from './../styles.module.scss';

interface Props {}

const DescriptionTab: FC<Props> = () => {
  const seeProgramLocalStore: ISeeProgramLocalStore | null =
    useContext(SeeProgramContext);

  return (
    <Observer>
      {() => (
        <div className={`DescriptionTab ${styles.DescriptionTab}`}>
          {seeProgramLocalStore!.program!.description || (
            <NoResults text={labelProgramDescriptionTabNoDescription()} />
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(DescriptionTab);
