import type {FC} from 'react';
import React, {memo} from 'react';

import UserProfileImg from '../UserProfileImg/UserProfileImg';

import styles from './styles.module.css';

interface Props {}

const TopUserProfile: FC<Props> = () => {
  return (
    <div className={`TopUserProfile ${styles.TopUserProfile}`}>
      <UserProfileImg />
    </div>
  );
};

export default memo(TopUserProfile);
