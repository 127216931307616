import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {ItemParams} from 'react-contexify';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import isEqual from 'react-fast-compare';

import classNames from 'classnames';
import {reaction} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';
import type {
  Edition,
  ProgramScheduleItemType,
} from '@yourcoach/shared/api/program';
import DocumentIcon from '@yourcoach/shared/assets/icons/primary/Paper.svg';
import {logger} from '@yourcoach/shared/utils/logger';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import NoResultsHeader from '@src/components/NoResultsHeader';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import type {ScheduleMaterial} from '@src/modules/materials/utils';
import {linksToMaterials, materialIsFolder} from '@src/modules/materials/utils';
import AddFile from '@src/pages/CRUProgram/legacy/AddFile';

import type {ProgramT} from '.';
import styles from './Library.module.css';
import LibraryListItem from './LibraryListItem';
import MaterialPublicationDayModal from './MaterialPublicationDayModal';
import type {TabProps, TabRef} from './useTabs';

const MATERIAL_CONTEXT_MENU_ID = 'material_context_menu';

export const I18N_SCOPE = 'shared.CRUProgramLibrary';

type ScheduledConference = Edition['conferences'][0];

type ScheduledTask = Edition['tasks'][0];

export type ScheduleItem = (ScheduledConference | ScheduledTask) & {
  type: ProgramScheduleItemType;
};

const CRUProgramLibrary = React.forwardRef<TabRef, TabProps>(
  ({program}, ref) => {
    const {
      stores: {editionStore},
    } = useContext(AppContext);

    useImperativeHandle(ref, () => ({
      getData,
    }));

    const [materials, setMaterials] = useState<ScheduleMaterial[]>([]);

    const isMaterialInDurationRange = useCallback(
      (material: ScheduleMaterial) => {
        if (program) {
          const lastEdition = program.editions[program.editions.length - 1];

          if (lastEdition) {
            const maxDay = lastEdition.duration - 1;

            if (material.day > maxDay) {
              return false;
            }
          }
        }

        return true;
      },
      [program],
    );

    const shouldRenderStub = useMemo(
      () => !materials.filter(isMaterialInDurationRange).length,
      [isMaterialInDurationRange, materials],
    );

    const listData = useMemo(() => {
      let result: ScheduleMaterial[] = [];

      if (materials.length) {
        let folders: ScheduleMaterial[] = [];
        let files: ScheduleMaterial[] = [];

        materials.forEach((material: ScheduleMaterial) => {
          if (materialIsFolder(material)) {
            folders.push(material);
          } else {
            files.push(material);
          }
        });

        const sortFn = (a: ScheduleMaterial, b: ScheduleMaterial) =>
          a.day - b.day;

        result = folders
          .concat(files)
          .filter(isMaterialInDurationRange)
          .sort(sortFn);
      }

      return result;
    }, [materials, isMaterialInDurationRange]);

    const [addMaterialModalIsOpen, showAddMaterialModal, hideAddMaterialModal] =
      useIsVisible();

    const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

    const [unlockDateModalIsOpen, showUnlockDateModal, hideUnlockDateModal] =
      useIsVisible();

    const materialToEdit = useRef<ScheduleMaterial>();

    const getData = useCallback(() => {
      let lastEdition: ProgramT['editions'][0] | null =
        program && program.editions.length
          ? program.editions[program.editions.length - 1]
          : null;

      lastEdition = {
        ...lastEdition,
        materials: materials.slice().filter(isMaterialInDurationRange),
      } as ProgramT['editions'][0];

      let editions: ProgramT['editions'];

      if (program && program.editions.length) {
        program.editions.splice(program.editions.length - 1, 1, lastEdition);

        editions = program.editions;
      } else {
        editions = [lastEdition];
      }

      return {
        editions,
      };
    }, [isMaterialInDurationRange, materials, program]);

    useEffect(() => {
      if (program) {
        const lastEdition = program.editions[program.editions.length - 1];

        if (lastEdition) {
          setMaterials(lastEdition.materials || materials);
        }
      }

      const dispose = reaction(
        () => editionStore.updating,
        updating => {
          if (
            program &&
            updating.success &&
            updating.entity &&
            updating.entity._id === program._id
          ) {
            const lastEdition = program.editions[program.editions.length - 1];

            if (lastEdition) {
              setMaterials(lastEdition.materials || materials);
            }
          }
        },
      );

      return () => {
        dispose();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddTaskButtonClick = useCallback(() => {
      showAddMaterialModal();
    }, [showAddMaterialModal]);

    const createMaterials = useCallback(
      async (links: (Link & {resource: IFile})[]) => {
        try {
          showOverlay();

          const createdMaterials = await Promise.all(linksToMaterials(links));

          const emptyFolders: Link[] = [];
          const failedLinks: Link[] = [];
          const materialsToAdd: ScheduleMaterial[] = [];

          createdMaterials.forEach((item, i) => {
            // @ts-ignore
            if (!item.emptyFolder && !item.isError) {
              materialsToAdd.push(item as ScheduleMaterial);
              // @ts-ignore
            } else if (item.emptyFolder) {
              emptyFolders.push(links[i]);
              // @ts-ignore
            } else if (item.emptyFolder) {
              failedLinks.push(links[i]);
            }
          });

          if (materialsToAdd.length) {
            setMaterials(prev => [...prev, ...materialsToAdd]);
          }

          if (failedLinks.length || emptyFolders.length) {
            let message = '';

            if (emptyFolders.length) {
              message = t([I18N_SCOPE, 'empty_folders_error'], {
                count: emptyFolders.length,
                names: emptyFolders.map(item => item.name).join(', '),
              });
            }

            if (failedLinks.length) {
              message = message
                ? `${message}${
                    message[message.length - 1] === '.' ? ' ' : '. '
                  }`
                : '';

              message = `${message}${t([I18N_SCOPE, 'failed_links_error'], {
                count: failedLinks.length,
                userNames: failedLinks.map(item => item.name).join(', '),
              })}`;
            }

            getCustomConfirmAlert({
              title: t('shared.message.error'),
              message,
              buttons: [{label: t('shared.button.ok'), onClick: () => {}}],
            });
          }

          hideOverlay();
        } catch (error) {
          hideOverlay();

          logger.error(error);

          getCustomConfirmAlert({
            title: t('shared.message.error'),
            message: error.message,
            buttons: [
              {
                label: t('shared.button.try_later'),
              },
              {
                label: t('shared.button.try_again'),
                onClick: () => createMaterials(links),
              },
            ],
          });
        }
      },
      [hideOverlay, showOverlay],
    );

    const onChooseMaterials = useCallback(
      chosenMaterials => {
        createMaterials(chosenMaterials);

        hideAddMaterialModal();
      },
      [createMaterials, hideAddMaterialModal],
    );

    const onClearButtonClick = useCallback(async () => {
      getCustomConfirmAlert({
        title: 'Are you sure?',
        buttons: [
          {
            label: 'No',
            onClick: () => {},
          },
          {
            label: 'Yes',
            type: 'confirm',
            onClick: () => {
              setMaterials([]);
            },
          },
        ],
      });
    }, []);

    const onMaterialMoreButtonClick = useCallback(
      (
        material: ScheduleMaterial,
        event: React.MouseEvent<Element, MouseEvent>,
      ) => {
        contextMenu.show({
          id: MATERIAL_CONTEXT_MENU_ID,
          event,
          props: {
            material,
          },
        });
      },
      [],
    );

    const onChangeUnlockDateButtonClick = useCallback(
      ({props}: ItemParams<{material: ScheduleMaterial}>) => {
        if (props) {
          const {material} = props;

          materialToEdit.current = material;

          showUnlockDateModal();
        }
      },
      [showUnlockDateModal],
    );

    const onMaterialPublicationDayButtonClick = useCallback(
      (material: ScheduleMaterial) => {
        contextMenu.hideAll();

        materialToEdit.current = material;

        showUnlockDateModal();
      },
      [showUnlockDateModal],
    );

    const onDeleteButtonClick = useCallback(
      ({props}: ItemParams<{material: ScheduleMaterial}>) => {
        if (props) {
          const {material} = props;

          setMaterials(prev => {
            const index = prev.findIndex(item => material.uuid === item.uuid);

            if (index >= 0) {
              prev.splice(index, 1);
            }

            return [...prev];
          });
        }
      },
      [],
    );

    const onMaterialUnlockDateChange = useCallback(
      (material: ScheduleMaterial) => {
        hideUnlockDateModal();

        setMaterials(prev => {
          const index = prev.findIndex(item => material.uuid === item.uuid);

          if (index >= 0) {
            prev.splice(index, 1, material);
          }

          return [...prev];
        });
      },
      [hideUnlockDateModal],
    );

    const ContextMenu = useCallback(
      () => (
        <Menu
          id={MATERIAL_CONTEXT_MENU_ID}
          animation={animation.fade}
          className="contextMenu"
        >
          <Item
            onClick={onChangeUnlockDateButtonClick}
            className="contextMenuItem"
          >
            {t([I18N_SCOPE, 'more_change_unlock_date_button'])}
          </Item>
          <Item
            onClick={onDeleteButtonClick}
            className={classNames('contextMenuItem', 'danger')}
          >
            {t([I18N_SCOPE, 'more_delete_button'])}
          </Item>
        </Menu>
      ),
      [onChangeUnlockDateButtonClick, onDeleteButtonClick],
    );

    const onUnlockDateModalClose = useCallback(() => {
      materialToEdit.current = undefined;
      hideUnlockDateModal();
    }, [hideUnlockDateModal]);

    return (
      <>
        <div className={styles.addButtonsContainer}>
          <Button onClick={onAddTaskButtonClick}>
            <DocumentIcon />
            {t([I18N_SCOPE, 'add_materials_button'])}
          </Button>
          {!shouldRenderStub ? (
            <Button onClick={onClearButtonClick} className={styles.clearButton}>
              {t([I18N_SCOPE, 'clear_button'])}
            </Button>
          ) : null}
        </div>
        {shouldRenderStub ? (
          <div className={styles.noResultsContainer}>
            <NoResultsHeader
              text={t([I18N_SCOPE, 'no_results_label'])}
              icon={DocumentIcon}
            />
          </div>
        ) : (
          <div className={styles.list}>
            {listData.map(item => (
              <LibraryListItem
                key={item.uuid}
                material={item}
                onMoreButtonClick={onMaterialMoreButtonClick}
                onPublicationDayButtonClick={
                  onMaterialPublicationDayButtonClick
                }
              />
            ))}
          </div>
        )}
        <ModalAnimateWin
          showModal={addMaterialModalIsOpen}
          closeModalHandler={hideAddMaterialModal}
          className="greyHeaderContainer"
          isBody
          classNameBody="whiteBody"
          header={t([I18N_SCOPE, 'add_materials_button'])}
          classNameHeader="greyHeader"
          classNameCloseBut="greyHeaderBut"
        >
          <AddFile getListItems={onChooseMaterials} />
        </ModalAnimateWin>
        {program && materialToEdit.current ? (
          <MaterialPublicationDayModal
            isOpen={unlockDateModalIsOpen}
            onAfterClose={onUnlockDateModalClose}
            program={program}
            material={materialToEdit.current}
            onSuccess={onMaterialUnlockDateChange}
          />
        ) : null}
        <ContextMenu />
        {overlayIsVisible ? (
          <div className="overlay">
            <Loader />
          </div>
        ) : null}
      </>
    );
  },
);

export default React.memo(CRUProgramLibrary, isEqual);
