import type {FC} from 'react';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Img as Image} from 'react-image';
import {Link} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {action, observable, reaction} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import {labelEditPractice} from '@src/common/i18n/i18nProfile';
import {labelMyPractice} from '@src/common/i18n/i18nPrograms';
import AvatarLoader from '@src/components/AvatarLoader/AvatarLoader';
import {IconSettings} from '@src/components/icons';
import Modal from '@src/components/ModalNew';
import OverlayImage from '@src/components/OverlayImage/OverlayImage';
import AppContext from '@src/context/App';
import {PATH_TYPE} from '@src/routes/utils';

import {PathBuilderService} from '../../v2/services/PathBuilderService';
import EditCoachProfile from '../EditCoachProfile/EditCoachProfile';

import styles from './styles.module.css';

interface ILocalStore {
  isCoach: boolean | null | undefined;
  userId: string;
  slug: string | null;
  coach_logo: IFile | null | undefined;
  coach_title: string | null;
  showModalCreatePractice: boolean;
  setShowModalCreatePractice(showModalCreatePractice: boolean): void;
}

interface Props {}

const MyPractice: FC<Props> = () => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable<ILocalStore>(
      {
        isCoach:
          currentUserStore.user &&
          currentUserStore.user.roles.includes('coach'),
        userId:
          currentUserStore.user && currentUserStore.user._id
            ? currentUserStore.user!._id
            : '',
        slug: currentUserStore.user && currentUserStore.user.slug,
        coach_logo: currentUserStore.user
          ? currentUserStore.user.coach_logo
          : null,
        coach_title: currentUserStore.user
          ? currentUserStore.user.coach_title
          : '',
        showModalCreatePractice: false,
        setShowModalCreatePractice(showModalCreatePractice: boolean) {
          this.showModalCreatePractice = showModalCreatePractice;
        },
      },
      {
        isCoach: observable,
        userId: observable,
        slug: observable,
        coach_logo: observable,
        coach_title: observable,
        showModalCreatePractice: observable,
        setShowModalCreatePractice: action,
      },
    ),
  ).current;

  useEffect(() => {
    const dispose = reaction(
      () => currentUserStore.user,
      user => {
        localStore.isCoach = user && user.roles.includes('coach');
        localStore.coach_logo = user && user.coach_logo;
        localStore.coach_title = user && user.coach_title;
      },
    );

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModalCreatePractice = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    localStore.setShowModalCreatePractice(true);
  };

  const handleCloseModalCreatePractice = () => {
    localStore.setShowModalCreatePractice(false);
  };

  const endEditPractice = () => {
    handleCloseModalCreatePractice();
  };

  return (
    <Observer>
      {() => (
        <>
          {localStore.isCoach ? (
            <div className={`MyPractice ${styles.MyPractice}`}>
              <h3>{labelMyPractice()}</h3>
              <div className={styles.container}>
                <div className={styles.imageContainer}>
                  <Link
                    // to={`/${PATH_TYPE.coaches}/${
                    //   localStore.slug ? localStore.slug : localStore.userId
                    // }`}
                    to={PathBuilderService.toPractice(
                      {slug: localStore.slug, id: localStore.userId},
                      {from: `/${PATH_TYPE.yourSpace}`},
                    )}
                    className={styles.link}
                  >
                    <Image
                      src={getFileSrc(localStore.coach_logo, 250).url || ''}
                      loader={<AvatarLoader className={styles.avatarLoader} />}
                      unloader={
                        <AvatarLoader className={styles.avatarLoader} />
                      }
                    />
                    <OverlayImage />
                  </Link>
                </div>
                <div className={styles.title}>{localStore.coach_title}</div>
                <div
                  className={styles.settings}
                  onClick={handleOpenModalCreatePractice}
                >
                  <IconSettings className={styles.icon} />
                </div>
                <Modal
                  isOpen={localStore.showModalCreatePractice}
                  onAfterClose={handleCloseModalCreatePractice}
                  title={labelEditPractice()}
                >
                  <EditCoachProfile
                    isFirstEdit={false}
                    endEditCallBack={endEditPractice}
                  />
                </Modal>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </Observer>
  );
};

export default memo(MyPractice);
