import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {Observer} from 'mobx-react';

import classNames from 'classnames';
import {runInAction} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import type {ApiRpcQuery} from '@yourcoach/shared/api';
import {apiRequest} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal} from '@yourcoach/shared/api/goal';
import {logger} from '@yourcoach/shared/utils/logger';
import {
  getDescriptionSchema,
  getTitleSchema,
  validateFields,
} from '@yourcoach/shared/utils/validation';
import {createHtmlInputField} from '@yourcoach/shared/utils/validation/createHtmlInputField';

import Button from '@src/components/Button';
import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '@src/components/Loader/Loader';
import Switch from '@src/components/Switch';
import TextArea from '@src/components/TextArea';
import TextField from '@src/components/TextField';
import AppContext from '@src/context/App';
import useIsVisible from '@src/hooks/useIsVisible';
import {t} from '@src/i18n';
import type {Expanded as GoalExpanded} from '@src/modules/Goals/utils';
import {expand as goalExpand} from '@src/modules/Goals/utils';
import type {GoalT as ProgramGoalT} from '@src/pages/CRUProgram/Goals';

import styles from './CRUGoal.module.css';

export const I18N_SCOPE = 'CRUGoal';

const expand = JSON.parse(JSON.stringify(goalExpand));

expand.goal.push('course_id');

export type GoalT = Goal &
  GoalExpanded & {
    course?: Course | null;
  };

export type Props = {
  programId?: string | null;
  courseId?: string | null;
  goal?: Goal | ProgramGoalT | null;
  onSuccess?: (goal: ProgramGoalT) => void;
};

const CRUGoal: React.FC<Props> = ({programId, courseId, goal, onSuccess}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);

  const [isCreatingMode, setIsCreatingMode] = useState(true);
  const [goalsAreExist, setGoalsAreExist] = useState(false);
  const [isClientManaged, setIsClientManaged] = useState(false);

  const [overlayIsVisible, showOverlay, hideOverlay] = useIsVisible();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const seeGoalsPartIsVisible = useMemo(
    () => isCreatingMode && goalsAreExist,
    [goalsAreExist, isCreatingMode],
  );

  const fields = useRef({
    title: createHtmlInputField('title', {
      validationRule: getTitleSchema(),
    }),
    description: createHtmlInputField<HTMLTextAreaElement>('description', {
      validationRule: getDescriptionSchema(),
    }),
  }).current;

  const fetchGoalsCount = useCallback(async () => {
    try {
      const result = await apiRequest({
        method: 'coach.goals.unbound.count',
        params: {
          query: (
            [
              programId ? ['program_id', '!=', programId] : null,
              ['course_id', '==', null],
            ] as ApiRpcQuery[]
          ).filter(Boolean),
        },
      });

      setGoalsAreExist(result._count > 0);

      return result._count;
    } catch (error) {
      logger.error(error);
    }
  }, [programId]);

  useEffect(() => {
    if (goal) {
      runInAction(() => {
        fields.title.value = goal.title;
        fields.description.value = goal.description;
      });

      setIsClientManaged((goal as Goal).is_client_managed);
      setIsCreatingMode(false);
    }

    fetchGoalsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIsClientManagedChange = useCallback((value: boolean) => {
    setIsClientManaged(value);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSeeGoalsButtonClick = useCallback(() => {
    // TODO: Select previously created goals
  }, []);

  const createGoal = useCallback(async () => {
    try {
      showOverlay();

      if (programId) {
        const createdGoal = (await goalStore.create({
          program_id: programId,
          course_id: courseId || null,
          title: fields.title.value,
          description: fields.description.value,
          is_client_managed: isClientManaged,
          expand,
        })) as GoalT;

        logger.event('goal_created', {target: courseId ? 'course' : 'program'});

        if (
          createdGoal.course &&
          createdGoal.course.status === 'ongoing' &&
          createdGoal.course_id
        ) {
          await goalStore.coach.bound.synchronize({
            course_id: createdGoal.course_id,
          });
        }
      }

      hideOverlay();
    } catch (error) {
      hideOverlay();

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.creating_error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
          },
          {
            label: t('shared.button.try_again'),
            onClick: createGoal,
          },
        ],
      });
    }
  }, [
    courseId,
    fields.description.value,
    fields.title.value,
    goalStore,
    hideOverlay,
    isClientManaged,
    programId,
    showOverlay,
  ]);

  const updateGoal = useCallback(async () => {
    try {
      showOverlay();

      const data = {
        title: fields.title.value,
        description: fields.description.value,
        is_client_managed: isClientManaged,
        expand,
      };

      const updatedGoal = (await goalStore.update(goal as Goal, data)) as GoalT;

      logger.event('goal_updated', {target: courseId ? 'course' : 'program'});

      if (
        updatedGoal.course &&
        updatedGoal.course.status === 'ongoing' &&
        updatedGoal.course_id
      ) {
        await goalStore.coach.bound.synchronize({
          course_id: updatedGoal.course_id,
        });
      }

      hideOverlay();
    } catch (error) {
      hideOverlay();

      logger.error(error);

      getCustomConfirmAlert({
        title: t('shared.message.creating_error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
          },
          {
            label: t('shared.button.try_again'),
            onClick: updateGoal,
          },
        ],
      });
    }
  }, [
    courseId,
    fields.description.value,
    fields.title.value,
    goal,
    goalStore,
    hideOverlay,
    isClientManaged,
    showOverlay,
  ]);

  const onFooterButtonPress = useCallback(async () => {
    const fieldsAreValid = validateFields(fields);

    if (fieldsAreValid) {
      if (onSuccess) {
        const uuid = uuidv4().replace(/-/g, '');

        onSuccess({
          ...goal,
          title: fields.title.value,
          description: fields.description.value,
          is_client_managed: isClientManaged,
          _id: goal && goal._id ? goal._id : uuid,
        });

        return;
      }

      if (isCreatingMode) {
        createGoal();
      } else {
        updateGoal();
      }
    }
  }, [
    createGoal,
    fields,
    goal,
    isClientManaged,
    isCreatingMode,
    onSuccess,
    updateGoal,
  ]);

  return (
    <div className={classNames('CRUGoal', styles.Component)}>
      <div className={styles.dataRow}>
        <h3 className={styles.header}>
          {t([I18N_SCOPE, 'title_label'])}
          {':'}
        </h3>
        <Observer>
          {() => (
            <TextField
              ref={fields.title.ref}
              value={fields.title.value}
              placeholder={t([I18N_SCOPE, 'title_text_input_placeholder'])}
              onChange={fields.title.onChange}
              error={fields.title.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={classNames(styles.dataRow, styles.switchContainer)}>
        <div className={styles.switchContent}>
          <h3 className={styles.header}>
            {t([I18N_SCOPE, 'is_client_managed_label'])}
            {':'}
          </h3>
          <p className={styles.switchDescription}>
            {t([I18N_SCOPE, 'is_client_managed_description'])}
          </p>
        </div>
        <Switch isOn={isClientManaged} onChange={onIsClientManagedChange} />
      </div>
      <div className={styles.dataRow}>
        <h3 className={styles.header}>
          {t([I18N_SCOPE, 'description_label'])}
          {':'}
        </h3>
        <Observer>
          {() => (
            <TextArea
              ref={fields.description.ref}
              className={styles.descriptionTextArea}
              value={fields.description.value}
              placeholder={t([
                I18N_SCOPE,
                'description_text_input_placeholder',
              ])}
              onChange={fields.description.onChange}
              error={fields.description.error || ''}
            />
          )}
        </Observer>
      </div>
      <div className={styles.footer}>
        <Button onClick={onFooterButtonPress}>
          {t([I18N_SCOPE, goal ? 'footer_edit_button' : 'footer_button'])}
        </Button>
      </div>
      {overlayIsVisible ? (
        <div className="overlay">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CRUGoal, isEqual);
