import type {FC} from 'react';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {Logo} from '@yourcoach/shared/uikit/Logo';

import localAppStore from '@src/context/appStore';

import {BreadcrumbsContainer} from '../../v2/modules/breadcrumbs';

import Breadcrumbs from './../../components/Breadcrumbs/Breadcrumbs';
import TopNotifications from './../../components/TopNotifications/TopNotifications';
import TopUserProfile from './../../components/TopUserProfile/TopUserProfile';
import MoreVertical from './MoreVertical/MoreVertical';
import styles from './styles.module.css';

interface Props {
  isWithNewCrumbs?: boolean;
  isWithCrumbs?: boolean;
  isWithFrom?: boolean;
}

const TopMenu: FC<Props> = ({isWithNewCrumbs, isWithCrumbs, isWithFrom}) => {
  const handleOnClickNotification = () => {
    localAppStore?.setIsTopNotificationsOpen(true);
  };

  return (
    <Observer>
      {() => (
        <div className={`TopMenu ${styles.TopMenu}`}>
          <div className={styles.LeftPartTopMenu}>
            {isWithNewCrumbs ? (
              <BreadcrumbsContainer
                isWithFrom={isWithFrom}
                isWithCrumbs={isWithCrumbs}
              />
            ) : (
              <div className={styles.Breadcrumbs}>
                <Breadcrumbs />
              </div>
            )}
            <Link to="/" className={styles.MobileLogoContainer}>
              <Logo size={40} />
            </Link>
          </div>
          <div className={styles.RightPartTopMenu}>
            <div className={styles.TopNotificationsContainer}>
              <TopNotifications
                numMess={localAppStore!.topNotification}
                onClick={handleOnClickNotification}
              />
            </div>
            <div className={styles.TopUserProfileContainer}>
              <TopUserProfile />
            </div>
            <div className={styles.MoreVertical}>
              <MoreVertical />
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(TopMenu);
