import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {toJS} from 'mobx';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import type {
  ExpandedPost,
  PostAttachment as PostAttachmentType,
  UserAttachment,
} from '@yourcoach/shared/api/channel/post';
import {
  isImageAttachment,
  isMaterialAttachment,
  isUserAttachment,
} from '@yourcoach/shared/api/channel/post';
import type {Event} from '@yourcoach/shared/api/event';
import type {IFile} from '@yourcoach/shared/api/media/file';
import type {Task} from '@yourcoach/shared/api/task';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {
  labelChatsPostsListItemSystemPost,
  labelChatsPostsListItemSystemPostType,
} from '@src/common/i18n/i18nChannel';
import {markLinks} from '@src/common/markLinks';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';

import MainChatsContext from '../context/MainChatsContext';
import type {IMainChatsLocalStore} from '../context/useMainChatsLocalStore';
import PostAttachment from '../PostAttachment/PostAttachment';

import styles from './../../styles.module.scss';
import ImageContainer from './ImageContainer/ImageContainer';

export type PostT = ExpandedPost & {
  inProgress?: boolean;
  highlighted?: boolean;
  uploadingAttachments?: boolean;
};

const SYSTEM_POST_TYPE: Event['type'][] = [
  'membership_created',
  'course_started',
];

interface Props {
  post: PostT;
  allCoachesInChat?: string[];
  isAdminPost?: boolean;
  groupWithNext?: boolean;
  showUserGroups?: boolean;
  showUserAvatar?: boolean;
  showUserName?: boolean;
  hasCourse?: boolean;
  pinnedUI?: boolean;
  isLast?: boolean;
  onClickContextMenu?: (
    selectPost: PostT,
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PostsListItem: FC<Props> = ({
  post,
  allCoachesInChat,
  isAdminPost,
  groupWithNext,
  showUserGroups,
  showUserAvatar,
  showUserName,
  hasCourse,
  pinnedUI,
  onClickContextMenu = () => {
    return () => {};
  },
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const history = useHistory();
  const localUser = currentUserStore.user;
  const mainChatsLocalStore: IMainChatsLocalStore | null =
    useContext(MainChatsContext);

  const onUserAvatarPressCb = () => {
    if (onUserAvatarPress) {
      onUserAvatarPress(post.user);
    }
  };

  const onUserAvatarPress = (userPost: PostT['user']) => {
    if (!userPost._id) {
      return;
    }

    if (
      mainChatsLocalStore!.hasCourse &&
      !mainChatsLocalStore!.isOneToOne &&
      mainChatsLocalStore!.channel!.resource!.coach_ids.includes(userPost._id)
    ) {
      // TODO: navigate to practice
    } else {
      // TODO: navigate to user profile
    }
  };

  const onAttachmentPress = (attachment: PostT['attachments'][number]) => {
    if (isImageAttachment(attachment as unknown as PostAttachmentType)) {
    } else if ((attachment as unknown as IFile).categories) {
    } else if (
      isMaterialAttachment(attachment as unknown as PostAttachmentType)
    ) {
    } else if (
      (attachment as unknown as PostAttachmentType)._id.split(':')[0] === 'task'
    ) {
      const task = attachment as unknown as Task;

      if (task.coach_ids.includes(localUser!._id)) {
        history.push({
          pathname: '/to-dos',
          search: `?cid=${mainChatsLocalStore!.channel!.resource_id!}`,
          state: {task: toJS(task)},
        });
      }
    } else if (isUserAttachment(attachment as unknown as PostAttachmentType)) {
      const user = attachment as unknown as UserAttachment;

      if (user.roles.includes('coach')) {
        window.open(`/coaches/${user.slug || user._id}`, '_blank');
      }
    }
  };

  const onAttachmentPressCb = useCallback(
    (attachment: PostT['attachments'][number]) => {
      onAttachmentPress(attachment);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isEventPost = post._id.split(':')[0] === 'event';
  const isMy = !pinnedUI && localUser && post.user_id === localUser._id;
  const user = (isMy ? localUser : post.user) as PostT['user'];

  const imageAttachment = useMemo(
    () =>
      (post.expandedAttachments as unknown[] as PostAttachmentType[]).find(
        isImageAttachment,
      ),
    [post.expandedAttachments],
  ) as IFile | undefined;

  const nonImageAttachments = useMemo(
    () =>
      post.expandedAttachments.filter(
        item =>
          item && !isImageAttachment(item as unknown as PostAttachmentType),
      ),
    [post.expandedAttachments],
  );

  const hasImageAttachment = !!imageAttachment;
  const attachmentGroup = hasImageAttachment && !!post.body;
  const singleAttachment = hasImageAttachment && !post.body;

  const userName =
    isAdminPost && hasCourse ? user.coach_title || '' : user.name;

  const userAvatar = isAdminPost && hasCourse ? user.coach_logo : user.avatar;

  const postBody = isEventPost
    ? post.body === 'course_started' || post.body === 'membership_created'
      ? labelChatsPostsListItemSystemPostType(
          post.body,
          mainChatsLocalStore!.isIndividual ? 'individual' : 'group',
          userName,
        )
      : labelChatsPostsListItemSystemPost(post.body, userName)
    : post.body;

  return (
    <Observer>
      {() => (
        <div className={`PostsListItem ${styles.PostsListItem}`}>
          {isEventPost &&
          SYSTEM_POST_TYPE.includes(post.body as Event['type']) ? (
            <div className={styles.systemPost}>{postBody}</div>
          ) : (
            <div className={styles.mainContainer}>
              <div
                className={`${styles.container} ${
                  isMy || allCoachesInChat?.includes(post.user_id)
                    ? styles.myContainer
                    : ''
                } ${
                  groupWithNext || attachmentGroup ? styles.groupContainer : ''
                }
            `}
              >
                {!isMy &&
                !showUserAvatar &&
                showUserGroups &&
                (groupWithNext || attachmentGroup) ? (
                  <div className={styles.groupAvatarStub} />
                ) : null}
                {showUserAvatar && !isMy ? (
                  <div onClick={onUserAvatarPressCb}>
                    <div className={styles.avatarContainer}>
                      <OtherUserProfileImg
                        avatar={userAvatar}
                        title={userName}
                      />
                    </div>
                  </div>
                ) : null}
                <div
                  className={
                    isMy
                      ? styles.rightPosition
                      : !isMy && post.user.roles?.includes('coach')
                      ? styles.olderCoachMessage
                      : styles.leftPosition
                  }
                >
                  <div className={styles.innerPostContainer}>
                    <div
                      onContextMenu={
                        !post.inProgress && !isEventPost
                          ? onClickContextMenu(post)
                          : undefined
                      }
                      className={`${
                        post.inProgress ? styles.postInProgress : ''
                      } ${post.highlighted ? styles.postHighlighted : ''} ${
                        styles.postTouchable
                      } ${isMy ? styles.myPostTouchable : ''} ${
                        pinnedUI ? styles.pinnedUiPostTouchable : ''
                      }
                  `}
                    >
                      <div
                        className={`${styles.post} ${
                          isAdminPost ? styles.adminBackground : ''
                        } ${isMy ? styles.myPost : ''} ${
                          pinnedUI ? styles.pinnedUiPost : ''
                        } ${
                          singleAttachment && !post.uploadingAttachments
                            ? styles.postWithSingleImageContainer
                            : ''
                        }`}
                      >
                        {showUserName && !isMy && !singleAttachment ? (
                          <div
                            className={`${styles.userName} ${
                              isMy || isAdminPost
                                ? styles.myOrAdminTextColor
                                : ''
                            }
                        `}
                          >
                            {userName}
                            {pinnedUI ? (
                              <div>
                                {`(${formatDate(
                                  dayjs(datetimeObjToISOString(post.created)),
                                  {withYTT: true},
                                )})`}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {post.uploadingAttachments ? <Loader /> : null}
                        {postBody ? markLinks(postBody) : null}
                        {(singleAttachment || nonImageAttachments.length > 0) &&
                        (postBody ||
                          (showUserName && !isMy && !singleAttachment)) ? (
                          <div className={styles.spice} />
                        ) : null}
                        {singleAttachment ? (
                          <div
                            onClick={() =>
                              onAttachmentPressCb(
                                imageAttachment! as unknown as PostT['attachments'][number],
                              )
                            }
                            onContextMenu={
                              !post.inProgress && !isEventPost
                                ? onClickContextMenu(post)
                                : undefined
                            }
                          >
                            <ImageContainer
                              imageAttachment={imageAttachment}
                              isMy={isMy}
                              pinnedUI={pinnedUI}
                              showUserName={showUserName}
                              userName={userName}
                              post={post}
                            />
                          </div>
                        ) : null}
                        {nonImageAttachments.map(item => (
                          <PostAttachment
                            key={item._id}
                            postId={post._id}
                            attachment={item as unknown as PostAttachmentType}
                            showUserName={showUserName}
                            isMy={isMy}
                            isAdminPost={isAdminPost}
                            onPress={onAttachmentPressCb as any}
                            onLongPress={
                              !post.inProgress && !isEventPost
                                ? onClickContextMenu(post)
                                : undefined
                            }
                          />
                        ))}
                      </div>
                      <div className={styles.timeStamp}>
                        {dayjs(datetimeObjToISOString(post.created)).format(
                          'LT',
                        )}
                      </div>
                    </div>
                  </div>
                  {attachmentGroup ? (
                    // render here image attachment of the same post as separate bubble
                    <div
                      className={`${styles.container} ${
                        isMy ? styles.myContainer : null
                      } ${groupWithNext ? styles.groupContainer : null}
                  `}
                    >
                      <div className={styles.innerPostContainer}>
                        {!isMy && showUserAvatar ? (
                          <div onClick={onUserAvatarPressCb}>
                            <div className={styles.avatarContainer} />
                          </div>
                        ) : null}
                        <div
                          className={`${styles.post} ${
                            isMy ? styles.myPost : null
                          } ${pinnedUI ? styles.pinnedUiPost : null} ${
                            styles.postWithSingleImageContainer
                          }
                    `}
                        >
                          <div
                            onClick={() =>
                              onAttachmentPressCb(
                                imageAttachment! as unknown as PostT['attachments'][number],
                              )
                            }
                            onContextMenu={onClickContextMenu(post)}
                          >
                            <ImageContainer
                              imageAttachment={imageAttachment}
                              isMy={isMy}
                              pinnedUI={pinnedUI}
                              showUserName={showUserName}
                              userName={userName}
                              post={post}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(PostsListItem);
