import type {IState, TActionTypes} from './SeeProgramState';
import {SET_PROGRAM} from './types';

export default (state: IState, action: TActionTypes) => {
  switch (action.type) {
    case SET_PROGRAM:
      return {
        ...state,
        program: action.payload || null,
      };
    default:
      return state;
  }
};
