import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import AppContext from '@src/context/App';

interface Props {
  className?: string;
}

const UserName: FC<Props> = ({className}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  return (
    <Observer>
      {() => (
        <div className={`UserName ${className}`}>
          {currentUserStore.user ? currentUserStore.user.name : 'No name'}
        </div>
      )}
    </Observer>
  );
};

export default memo(UserName);
