import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Image} from '@yourcoach/shared/uikit/Image';
import {Text} from '@yourcoach/shared/uikit/Text';

import List from '@src/components/List';
import ModalNew from '@src/components/ModalNew';
import TextField from '@src/components/TextField';

import {SessionNotesListItem} from '../SessionNotesListItem';

export const Modal = styled(ModalNew)`
  width: 60vw !important;
  min-width: 400px !important;

  .body {
    padding: 0px;
    height: 60vh !important;
    min-height: 400px !important;
  }

  .header {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgramAvatar = styled(Image)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

export const ProgramTitle = styled(Text).attrs({as: 'p'})`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

export const CourseDates = styled(Text).attrs({as: 'p'})`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${getColor('text3')};
`;

export const Container = styled.div`
  padding: 20px 30px 20px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ListItem = styled(SessionNotesListItem)`
  padding: 15px 0 !important;
  margin: 0;
  box-shadow: initial !important;
  border-top: 1px solid ${getColor('border2')};
  border-radius: initial !important;
`;

const SList = styled(List)`
  height: 100%;

  ${ListItem}:first-child {
    border-top: none;
  }
`;

export {SList as List};

export const ListHeader = styled(Text).attrs({as: 'p'})`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
`;

export const SearchTextInput = styled(TextField).attrs({variant: 'search'})`
  margin-bottom: 20px;
`;
