/**
 * Styles for the InfoBadge component.
 *
 * To get color value from the theme by token use {@link getColor} utility.
 *
 * To create color scheme for current component use {@link makeColorScheme} utility.
 *
 * To use different css depends of the prop (switch case), use {@link switchProp} utility.
 *
 * To use sizes from the theme use {@link getThemeSize} utility.
 */

import {css} from 'styled-components';

//import {getColor, makeColorScheme, switchProp} from '@yourcoach/shared/styles/utils';
import type {StyledProps} from './types';

const container = css<StyledProps>`
  gap: 10px;
  align-items: center;
`;

export const containerWeb = css<StyledProps>`
  box-sizing: border-box;
  ${container}
`;
