import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding-right: 140px;
  padding-left: 140px;
  overflow-y: scroll;
  background-color: ${getColor('lostColors.pageBg')};
`;
const Body = styled.main`
  //padding-right: 140px;
  max-width: 1380px;
  margin: 0 auto;
  padding-bottom: 140px;
  height: fit-content;
`;
const Header = styled.div`
  display: flex;
  padding-top: 40px;
  padding-bottom: 30px;
  justify-content: space-between;
`;

const Content = styled.div``;

export const Layout = {
  Container,
  Body,
  Header,
  Content,
};
