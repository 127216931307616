import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {Img as Image} from 'react-image';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction, runInAction} from 'mobx';

import type {ApiRpcQuery, CollectionStore} from '@yourcoach/shared/api';
import {
  apiRequest,
  createCollectionStore,
  datetimeObjToISOString,
} from '@yourcoach/shared/api';
import {
  courseIsEditable,
  getCourseDurationString,
} from '@yourcoach/shared/api/course';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';
import type {Task as ITask} from '@yourcoach/shared/api/task';
import {taskIsDone, taskIsOverdue} from '@yourcoach/shared/api/task';
import CalendarIcon from '@yourcoach/shared/assets/icons/calendar.svg';
import CheckIcon from '@yourcoach/shared/assets/icons/check.svg';
import ClockIcon from '@yourcoach/shared/assets/icons/clock.svg';
import BoldCloseIcon from '@yourcoach/shared/assets/icons/close-bold.svg';
import EditIcon from '@yourcoach/shared/assets/icons/edit.svg';
import MealIcon from '@yourcoach/shared/assets/icons/meal.svg';
import PhotoIcon from '@yourcoach/shared/assets/icons/photo.svg';
import TrashIcon from '@yourcoach/shared/assets/icons/trash.svg';
import {waitForAsync} from '@yourcoach/shared/utils';
import {formatDate} from '@yourcoach/shared/utils/datetime';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import {CustomButton} from '@src/components/CustomForm';
import GetUserFile from '@src/components/GetUserFile/GetUserFile';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import Loader from '@src/components/Loader/Loader';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
import {
  shouldUpdateProgramSchedule,
  updateTasksSchedule,
} from '@src/models/program';
import type {Expanded as TaskExpanded, Meal, MealData} from '@src/models/tasks';
import {expand, MEAL_SEPARATOR} from '@src/models/tasks';

import {emitter} from '../../../../widget/src/utils';
import ClientTasksListItem from '../ClientTasksListItem/ClientTasksListItem';
import MealInput from '../MealInput/MealInput';
import ShowMealPopup from '../ShowMealPopup/ShowMealPopup';
import styles from '../styles.module.css';

const CURSOR_DIRECTION = -1;
const LIMIT = 200;

const I18N_SCOPE = 'Task';

type Section = {
  title?: string;
  data: TaskT[];
};

export type TaskT = ITask &
  Omit<TaskExpanded, 'attachments'> & {
    attachments: (IFile | string | null)[];
  };

interface ILocalStore {
  task: TaskT | null;
  originTask: TaskT | null;
  clientTasksStore: CollectionStore<TaskT> | null;
  isChanged: boolean;
  itCanBeDone: boolean;
  isDone: boolean;
  isEditable: boolean;
  isOverdue: boolean | null | undefined;
  isPaginating: boolean;
  sections: Section[];
  comment?: string;
  setComment(newValue: string): void;
  _onRefresh(silent?: boolean | undefined): Promise<void>;
  _fetchTask(): Promise<void>;
  selectIdUserTask: string;
  setSelectIdUserTask(selectIdUserTask: string): void;
  isOpenModalGetUserFile: boolean;
  setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean): void;
  isOpenModalMeal: boolean;
  setIsOpenModalMeal(isOpenModalMeal: boolean): void;
  selectMeal: Meal | null;
  setSelectMeal(selectMeal: Meal | null): void;
  isMailPopup: boolean;
  setIsMailPopup(isMailPopup: boolean): void;
}

interface Props {
  taskId: string;
  forCoach?: boolean;
  task?: TaskT;
  onUserPress?: (task: TaskT) => void;
  noDelete?: boolean;
  noUpdate?: boolean;
}

const Task: FC<Props> = ({
  taskId,
  forCoach,
  task,
  onUserPress,
  noDelete = false,
  noUpdate = false,
}) => {
  const {
    stores: {eventStore, taskStore, fileStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          selectMeal: null,
          setSelectMeal(selectMeal: Meal | null) {
            this.selectMeal = selectMeal;
          },
          isMailPopup: false,
          setIsMailPopup(isMailPopup: boolean) {
            this.isMailPopup = isMailPopup;
          },
          isOpenModalGetUserFile: false,
          setIsOpenModalGetUserFile(isOpenModalGetUserFile: boolean) {
            this.isOpenModalGetUserFile = isOpenModalGetUserFile;
          },
          isOpenModalMeal: false,
          setIsOpenModalMeal(isOpenModalMeal: boolean) {
            this.isOpenModalMeal = isOpenModalMeal;
          },
          task: null,
          originTask: null,
          isChanged: false,
          selectIdUserTask: '',
          setSelectIdUserTask(selectIdUserTask: string) {
            this.selectIdUserTask = selectIdUserTask;
          },
          clientTasksStore: null,
          isPaginating: false,
          comment: undefined,
          setComment(newValue: string) {
            this.comment = newValue;
          },
          get itCanBeDone() {
            return (
              !!this.task &&
              this.task.status !== 'frozen' &&
              (taskIsOverdue(this.task) ||
                dayjs().isSame(
                  dayjs(datetimeObjToISOString(this.task.start_date)),
                  'day',
                ))
            );
          },
          get isDone() {
            return taskIsDone(this.task);
          },
          get isOverdue() {
            return taskIsOverdue(this.task);
          },
          get isEditable() {
            return (
              forCoach &&
              this.task &&
              this.task.course &&
              courseIsEditable(this.task.course)
            );
          },
          get sections() {
            const sections: Section[] = [];

            if (!this.task) {
              return sections;
            }

            if (forCoach) {
              sections.push({
                title: t([I18N_SCOPE, 'progress_section_header']),
                data: this.clientTasksStore
                  ? this.clientTasksStore.items.slice()
                  : [],
              });
            } else {
              // render client part as a one list item
              // @ts-ignore
              sections.push({data: ['']});
            }

            return sections;
          },
          async _onRefresh(silent = false) {
            await this.clientTasksStore.fetch(
              {
                limit: Math.max(this.clientTasksStore.items.length, LIMIT),
                query: (
                  [
                    this.task && this.task._id
                      ? ['uuid', '==', this.task.uuid]
                      : null,
                    this.task && this.task._id
                      ? ['course_id', '==', this.task.course_id]
                      : null,
                  ] as ApiRpcQuery[]
                ).filter(Boolean),
              },
              {
                silent,
              },
            );
          },
          async _fetchTask() {
            try {
              const newTask = (await taskStore[
                forCoach ? 'coach' : 'client'
              ].fetch({
                _id: taskId,
                expand,
              })) as TaskT;

              runInAction(() => {
                this.task = newTask;
                this.originTask = JSON.parse(JSON.stringify(newTask));
              });
            } catch (error) {
              // TODO: log error

              if (apiRequest.isCanceledError(error)) {
                return;
              }

              getCustomConfirmAlert({
                title: t('shared.message.error_fix'),
                message: error.message,
                buttons: [
                  {
                    label: t('shared.button.ok'),
                    onClick: () => {},
                  },
                ],
              });
            }
          },
        },
        {
          task: observable,
          originTask: observable,
          isChanged: observable,
          clientTasksStore: observable,
          isPaginating: observable,
          itCanBeDone: computed,
          isDone: computed,
          isOverdue: computed,
          isEditable: computed,
          sections: computed,
          comment: observable,
          setComment: action,
          _onRefresh: action,
          _fetchTask: action,
          selectIdUserTask: observable,
          setSelectIdUserTask: action,
          isOpenModalGetUserFile: observable,
          setIsOpenModalGetUserFile: action,
          isOpenModalMeal: observable,
          setIsOpenModalMeal: action,
          selectMeal: observable,
          setSelectMeal: action,
          isMailPopup: observable,
          setIsMailPopup: action,
        },
      ),
    [forCoach, taskId, taskStore],
  );

  useEffect(() => {
    runInAction(() => {
      localStore.clientTasksStore = createCollectionStore({
        method: 'coach.tasks.coach.list',
        params: {
          limit: LIMIT,
          cursor: [CURSOR_DIRECTION, 0, null],
          expand,
        },
      });
    });

    const disposeTack = reaction(
      () => localStore.task && localStore.task._id,
      () => {
        if (localStore.task) {
          eventStore.markReadAll({
            query: [['context_ids', 'in', [localStore.task._id]]],
            limit: 500,
          });

          if (
            forCoach &&
            localStore.clientTasksStore &&
            !localStore.clientTasksStore.isLoaded
          ) {
            localStore._onRefresh();
          }
        }
      },
    );

    const disposeTaskStoreUpdateBatch = reaction(
      () => taskStore.updatingBatch,
      updating => {
        if (localStore.task && updating.success && updating.entities) {
          const [updatedTask] = updating.entities;

          if (updatedTask && updatedTask._id === taskId) {
            localStore.task = updatedTask as TaskT;
          }
        }
      },
    );

    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    if (task) {
      runInAction(() => {
        localStore.task = task;
        localStore.originTask = JSON.parse(JSON.stringify(task));
      });
    } else {
      localStore._fetchTask();
    }

    return () => {
      disposeTack();
      disposeTaskStoreUpdateBatch();

      if (localStore.clientTasksStore) {
        localStore.clientTasksStore.clear();
      }

      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const _handleWsMessage = message => {
    if (forCoach && localStore.task && message.event.type === 'task_done') {
      localStore._onRefresh(true);
    }
  };

  const _onDoneTaskButtonPress = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            _resolveTask();
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onSaveTaskButtonPress = () => {
    _resolveTask();
  };

  const _resolveTask = async () => {
    const isJustSave = taskIsDone(localStore.task);

    try {
      const attachmentsLength = localStore.task!.attachments.length;
      const promises: Promise<string>[] = [];

      for (let i = 0; i < attachmentsLength; i += 1) {
        if (localStore.task!.attachments[i]) {
          if ((localStore.task!.attachments[i] as IFile)._id) {
            promises.push(
              Promise.resolve((localStore.task!.attachments[i] as IFile)._id),
            );
          } else {
            promises.push(
              new Promise(async (resolve, reject) => {
                try {
                  const uploading = fileStore.uploading.get(
                    localStore.task!.attachments[i] as string,
                  );

                  if (uploading) {
                    if (uploading.inProgress) {
                      await waitForAsync(() => uploading.inProgress === false);
                    }

                    if (uploading.success && uploading.file) {
                      runInAction(() => {
                        localStore.task!.attachments[i] = uploading.file;
                      });

                      resolve(uploading.file._id);
                    } else if (uploading.error) {
                      const file = await fileStore.upload(
                        localStore.task!.attachments[i] as string,
                      );

                      runInAction(() => {
                        localStore.task!.attachments[i] = file;
                      });

                      resolve(file._id);
                    }
                  }
                } catch (error) {
                  reject(error);
                }
              }),
            );
          }
        }
      }

      const attachmentIds = await Promise.all(promises);

      const resolvedTask = (await taskStore.resolve(localStore.task!, {
        report: localStore.task!.report,
        attachment_ids: attachmentIds,
        expand,
      })) as ITask;

      await taskStore!.localUpdate(resolvedTask, {});

      runInAction(() => {
        localStore.task = resolvedTask as TaskT;

        localStore.originTask = JSON.parse(
          JSON.stringify(resolvedTask as TaskT),
        );
      });

      if (isJustSave) {
        _calcTaskIsChanged();
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.updating_error'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              _resolveTask();
            },
          },
        ],
      });
    }
  };

  const _calcTaskIsChanged = () => {
    if (taskIsDone(localStore.task)) {
      const taskIsChanged =
        localStore.task!.attachments.length !==
          localStore.originTask!.attachments.length ||
        localStore.task!.report !== localStore.originTask!.report;

      runInAction(() => {
        localStore.isChanged = taskIsChanged;
      });
    }
  };

  const _onEditTaskButtonPress = () => {};

  const _onDeleteTaskButtonPress = async () => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            _deleteTask();
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _deleteTask = async () => {
    try {
      // TODO: Add ability to delete group of tasks coach.tasks.delete_many
      const tasksToDeleteResult = await apiRequest({
        method: 'coach.tasks.coach.list',
        params: {
          limit: 200,
          query: [
            ['uuid', '==', localStore.task!.uuid],
            ['course_id', '==', localStore.task!.course_id],
          ],
        },
      });

      const canDeleteTask = tasksToDeleteResult._items.every(
        (item: TaskT) => !item.done,
      );

      if (!canDeleteTask) {
        getCustomConfirmAlert({
          title: t('shared.message.deleting_error'),
          message: t([I18N_SCOPE, 'cant_delete_task_error']),
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
            },
          ],
        });

        return;
      }

      await taskStore.deleteBatch(
        tasksToDeleteResult._items.map((item: TaskT) => ({
          entity: item,
        })),
      );

      let programTitle = 'Program not found';

      if (localStore.task!.course && localStore.task!.course.program) {
        programTitle = localStore.task!.course.program.title;
      }

      if (
        await shouldUpdateProgramSchedule({
          programTitle,
          actionType: 'delete',
          targetType: 'task',
          isIndividual: !!(
            localStore.task!.course && localStore.task!.course.client_id
          ),
        })
      ) {
        updateTasksSchedule({
          programId: localStore.task!.program_id,
          tasks: [
            {
              title: '',
              description: '',
              day: 0,
              time: 0,
              duration: 0,
              uuid: localStore.task!.uuid,
            },
          ],
        });
      }
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.try_again'),
            onClick: () => {
              _deleteTask();
            },
            type: 'confirm',
          },
          {
            label: t('shared.button.try_later'),
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _onMealPress = (meal: Meal) => {
    localStore.setSelectMeal(meal);
    localStore.setIsMailPopup(true);
  };

  const handleClosePopup = () => {
    localStore.setIsMailPopup(false);
  };

  const _onRemoveMealButtonPress = async (index: number) => {
    getCustomConfirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const [comment, ...meals] =
              localStore.task!.report.split(MEAL_SEPARATOR);

            meals.splice(index, 1);

            runInAction(() => {
              localStore.task!.report = [comment, ...meals].join(
                MEAL_SEPARATOR,
              );

              _calcTaskIsChanged();
            });
          },
          type: 'confirm',
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const _onCommentChange = (comment: string) => {
    const report = localStore.task!.report.split(MEAL_SEPARATOR);

    report[0] = comment;

    localStore.task!.report = report.join(MEAL_SEPARATOR);

    _calcTaskIsChanged();
  };

  const _onRemoveImageButtonPress = async (index: number) => {
    if ((localStore.task!.attachments[index] as IFile)._id) {
      await getCustomConfirmAlert({
        title: 'Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              runInAction(() => {
                localStore.task!.attachments.splice(index, 1);

                _calcTaskIsChanged();
              });
            },
            type: 'confirm',
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _onAddImageButtonPress = () => {
    localStore.setIsOpenModalGetUserFile(true);
  };

  const GetUserFileClose = () => {
    localStore.setIsOpenModalGetUserFile(false);
  };

  const getUserUploadFile = (files: IFile[]) => {
    localStore.task!.attachments.push(...files);
  };

  const _onAddMealButtonPress = () => {
    localStore.setIsOpenModalMeal(true);
  };

  const handleCloseModalMeal = () => {
    localStore.setIsOpenModalMeal(false);
  };

  const handleGetMealData = (mealData: MealData) => {
    localStore.setIsOpenModalMeal(false);

    if (mealData.nutrients.length || mealData.ingredients.length) {
      _analyzeMeal(mealData);
    }
  };

  const _analyzeMeal = async (mealData: MealData) => {
    let isCanceled = false;

    try {
      if (isCanceled) {
        return;
      }

      const meal: Meal = {
        ...mealData,
        date: new Date(),
      };

      getCustomConfirmAlert({
        buttons: [
          {
            label: 'Attach',
            onClick: () => {
              meal.date = new Date(
                (meal.date as Date).getTime() -
                  (meal.date as Date).getTimezoneOffset() * 60000,
              ).toISOString();

              runInAction(() => {
                localStore.task!.report += `${MEAL_SEPARATOR}${JSON.stringify(
                  meal,
                )}`;
              });

              _calcTaskIsChanged();
            },
            type: 'confirm',
          },
          {
            label: 'no',
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      getCustomConfirmAlert({
        title: t('shared.message.error_fix'),
        message: error.message,
        buttons: [
          {
            label: t('shared.button.ok'),
            onClick: () => {},
          },
        ],
      });
    }
  };

  const _onUserPress = (item: TaskT) => {
    if (onUserPress) {
      localStore.setSelectIdUserTask(item._id);
      onUserPress(item);
    }
  };

  const _onCourseRowPress = () => {};

  const _renderListHeader = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.task) {
            return null;
          }

          const program = localStore.task.course
            ? localStore.task.course.program
            : {
                avatar: null,
                title: 'Program not found',
              };

          return (
            <div className={styles.subHeaderContainer}>
              {!forCoach ? (
                <div className={styles.subHeaderRow}>
                  <div className={styles.avatarContainer}>
                    <div className={styles.subHeaderRowAvatar}>
                      <ClockIcon width={20} height={20} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.subHeaderRowLabel}>
                      {t([I18N_SCOPE, 'due_date_label'])}
                    </div>
                    <div className={styles.subHeaderRowTitle}>
                      {formatDate(
                        datetimeObjToISOString(localStore.task!.end_date),
                        {
                          withYTT: true,
                          appendYTT: true,
                          customFormat: 'lll',
                        },
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={styles.subHeaderWithButtonsContainer}>
                <div
                  className={`${styles.subHeaderRow} ${styles.clickable}`}
                  onClick={_onCourseRowPress}
                >
                  <div className={styles.avatarContainer}>
                    <OtherUserProfileImg
                      avatar={program.avatar}
                      title={program.title}
                      titleFontSize="14px"
                      isBGGradient
                      getTitleIconIfNoImage
                      isOverlay={!program.avatar}
                    />
                  </div>
                  <div>
                    <div className={styles.subHeaderRowLabel}>Program</div>
                    <div className={styles.subHeaderRowTitle}>
                      {program.title}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.subHeaderRow}
                  onClick={_onCourseRowPress}
                >
                  <div className={styles.avatarContainer}>
                    <div className={styles.subHeaderRowAvatar}>
                      <CalendarIcon width={20} height={20} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.subHeaderRowLabel}>
                      {t(['ClientTask', 'program_label'])}
                    </div>
                    <div className={styles.subHeaderRowTitle}>
                      {getCourseDurationString(localStore.task!.course)}
                    </div>
                  </div>
                </div>
                {forCoach ? (
                  <div className={styles.subHeaderButtonsContainer}>
                    {!noDelete ? (
                      <div
                        onClick={_onDeleteTaskButtonPress}
                        className={styles.subHeaderButton}
                      >
                        <TrashIcon width={18} height={18} />
                      </div>
                    ) : null}
                    {!noUpdate ? (
                      <div
                        onClick={_onEditTaskButtonPress}
                        className={styles.subHeaderEditButton}
                      >
                        <EditIcon width={18} height={18} />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderHeader = () => {
    return (
      <Observer>
        {() => {
          if (!localStore.task) {
            return (
              <div className={styles.taskCardLoadingContainer}>
                <Loader />
              </div>
            );
          }

          return (
            <div className={styles.headerContainer}>
              {!forCoach && (localStore.isOverdue || localStore.isDone) ? (
                <div className={styles.taskDueDateContainer}>
                  {localStore.isOverdue ? (
                    <div
                      className={`${styles.taskTag} ${styles.overdueTaskTag}`}
                    >
                      {t([I18N_SCOPE, 'overdue_tag'])}
                    </div>
                  ) : null}
                  {localStore.isDone ? (
                    <div className={styles.taskTag}>
                      {t([I18N_SCOPE, 'done_tag'])}
                    </div>
                  ) : null}
                  <div
                    className={`${styles.taskDueDate} ${
                      localStore.isOverdue
                        ? styles.isOverdue
                        : localStore.isDone
                        ? styles.isDone
                        : ''
                    }`}
                  >
                    {formatDate(
                      datetimeObjToISOString(
                        localStore.isDone
                          ? localStore.task!.done
                          : localStore.task!.end_date,
                      ),
                      {
                        useUTC: forCoach,
                        withYTT: true,
                        appendYTT: true,
                        customFormat: 'lll',
                      },
                    )}
                  </div>
                </div>
              ) : null}
              <div className={styles.taskTitle}>{localStore.task!.title}</div>
              {localStore.task!.description ? (
                <div className={styles.taskDescriptionContainer}>
                  <div className={styles.taskDescription}>
                    {localStore.task!.description}
                  </div>
                </div>
              ) : null}
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderFooter = () => {
    return (
      <Observer>
        {() => {
          if (forCoach) {
            return localStore.clientTasksStore &&
              localStore.clientTasksStore!.cursor.next ? (
              <div className={styles.paginationFooter}>
                <Loader />
              </div>
            ) : null;
          }

          if (!localStore.task) {
            return null;
          }

          return (
            <div className={styles.footerButtonContainer}>
              <CustomButton
                type="button"
                disabled={
                  (!localStore.itCanBeDone && !localStore.isDone) ||
                  (localStore.isDone && !localStore.isChanged)
                }
                classButton={`blueButt ${styles.doneButton}`}
                onClick={
                  localStore.isDone
                    ? _onSaveTaskButtonPress
                    : _onDoneTaskButtonPress
                }
              >
                <div className={styles.doneButtonContent}>
                  {localStore.isDone && !localStore.isChanged ? (
                    <div className={styles.doneButtonCheckMark}>
                      <CheckIcon width={24} height={24} />
                    </div>
                  ) : null}
                  <div className={styles.doneButtonText}>
                    {!localStore.isDone
                      ? localStore.itCanBeDone
                        ? t([I18N_SCOPE, 'done_button'])
                        : localStore.task!.status !== 'frozen'
                        ? `Can be done on ${dayjs(
                            datetimeObjToISOString(localStore.task!.start_date),
                          ).format('ll')}`
                        : 'Your access is frozen'
                      : ''}
                    {localStore.isDone && !localStore.isChanged
                      ? 'Completed'
                      : ''}
                    {localStore.isDone && localStore.isChanged ? 'Save' : ''}
                  </div>
                </div>
              </CustomButton>
            </div>
          );
        }}
      </Observer>
    );
  };

  const _renderItem = item =>
    item ? (
      <ClientTasksListItem
        task={item}
        onPress={_onUserPress}
        isSelect={localStore.selectIdUserTask === item._id}
      />
    ) : (
      _renderClientPart()
    );

  const _renderClientPart = () => {
    if (!localStore.task) {
      return null;
    }

    return (
      <Observer>
        {() => (
          <div className={styles.clientViewContainer}>
            <div className={styles.commentInputContainer}>
              <textarea
                value={localStore.comment}
                maxLength={5000}
                disabled={!localStore.itCanBeDone && !localStore.isDone}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  _onCommentChange(e.target.value)
                }
                placeholder={t([I18N_SCOPE, 'comment_input_placeholder'])}
              />
            </div>
            <div className={styles.attachmentsContainer}>
              <div className={styles.attachmentsButContainer}>
                <CustomButton
                  type="button"
                  disabled={!localStore.isDone && !localStore.itCanBeDone}
                  classButton={`blueButt ${styles.addPhotoButton}`}
                  onClick={_onAddImageButtonPress}
                >
                  <div className={styles.attachmentIcon}>
                    <PhotoIcon width={19} height={19} />
                  </div>
                  <div className={styles.attachmentButtonText}>
                    {t([I18N_SCOPE, 'add_photo_button'])}
                  </div>
                </CustomButton>
                <CustomButton
                  type="button"
                  disabled={!localStore.isDone && !localStore.itCanBeDone}
                  classButton={`blueButt ${styles.addMealButton}`}
                  onClick={_onAddMealButtonPress}
                >
                  <div className={styles.attachmentIcon}>
                    <MealIcon width={19} height={19} />
                  </div>
                  <div className={styles.attachmentButtonText}>
                    {t([I18N_SCOPE, 'add_meal_button'])}
                  </div>
                </CustomButton>
              </div>
              <div className={styles.attachmentsImageContainer}>
                {localStore
                  .task!.attachments.filter(Boolean)
                  .map((attachment, i) => (
                    <div
                      key={(attachment as IFile)._id || `${attachment}-${i}`}
                      className={styles.container}
                    >
                      <div className={styles.content}>
                        <div className={styles.contentImg}>
                          <Image
                            src={
                              (attachment as IFile)._id
                                ? getFileSrc(attachment as IFile, 250).url || ''
                                : (attachment as string)
                            }
                            loader={<Loader height="55%" width="55%" />}
                            unloader={
                              <PhotoIcon
                                width={20}
                                height={7}
                                className={styles.attachmentIcon}
                              />
                            }
                          />
                        </div>
                        <div
                          onClick={() => _onRemoveImageButtonPress(i)}
                          className={styles.removeAttachmentButton}
                        >
                          <BoldCloseIcon width={13} height={13} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={styles.attachmentsMealContainer}>
                {localStore
                  .task!.report.split(MEAL_SEPARATOR)
                  .slice(1)
                  .map((mealStr, i) => {
                    const meal = JSON.parse(mealStr);

                    return (
                      <div key={mealStr} className={styles.containerEl}>
                        <div className={styles.attachmentIconContainer}>
                          <div
                            className={styles.attachmentIcon}
                            onClick={() => _onMealPress(meal)}
                          >
                            <MealIcon width={21} height={21} />
                            <div className={styles.attachmentText}>
                              {formatDate(meal.date, {
                                useUTC: true,
                                customFormat: 'LT',
                              })}
                            </div>
                          </div>
                          <div
                            onClick={() => _onRemoveMealButtonPress(i)}
                            className={styles.removeAttachmentButton}
                          >
                            <BoldCloseIcon width={9} height={9} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <GetUserFile
              showModal={localStore!.isOpenModalGetUserFile}
              closeModal={GetUserFileClose}
              getFile={getUserUploadFile}
              isOnlyImage
              multiple={true}
            />
            <ModalAnimateWin
              showModal={localStore.isOpenModalMeal}
              closeModalHandler={handleCloseModalMeal}
              className="greyHeaderContainer littleContainer w610 List"
              isBody
              classNameBody="whiteBody"
              header={t([I18N_SCOPE, 'meal_popup.title'])}
              classNameHeader="greyHeader w610"
              classNameCloseBut="greyHeaderBut"
            >
              <MealInput getMealData={handleGetMealData} />
            </ModalAnimateWin>
            {localStore.selectMeal ? (
              <ShowMealPopup
                isOpen={localStore.isMailPopup}
                handleClose={handleClosePopup}
                meal={localStore.selectMeal}
              />
            ) : null}
          </div>
        )}
      </Observer>
    );
  };

  const _renderSectionHeader = (section: Section) => {
    if (!forCoach) {
      return null;
    }

    return (
      <div className={styles.sectionHeaderContainer}>
        <div className={styles.sectionHeaderTitle}>{section.title}</div>
        {localStore.clientTasksStore &&
        !localStore.clientTasksStore.isLoaded ? (
          <Loader />
        ) : null}
      </div>
    );
  };

  return (
    <Observer>
      {() => (
        <div className={`Task ${styles.Task}`}>
          {_renderHeader()}
          {localStore.sections.map((section, i) => {
            return (
              <div
                className={styles.sectionContainer}
                key={`${section.title}_${i}`}
              >
                {_renderListHeader()}
                {_renderSectionHeader(section)}
                {section.data.map((item, index) => {
                  return (
                    <div key={item ? item._id : index}>{_renderItem(item)}</div>
                  );
                })}
              </div>
            );
          })}
          {_renderFooter()}
        </div>
      )}
    </Observer>
  );
};

export default memo(Task);
