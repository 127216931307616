import type {FC} from 'react';
import React, {memo} from 'react';

import SearchSVG from '@yourcoach/shared/assets/icons/search.svg';

interface Props {
  width?: string;
  height?: string;
  customClass?: string;
}

const IconSearch: FC<Props> = ({
  height = '100%',
  width = '100%',
  customClass = '',
}) => {
  return (
    <SearchSVG
      viewBox="5 5 25 25"
      className={`${customClass}`}
      width={height}
      height={width}
      fill="#819AA9"
    />
  );
};

export default memo(IconSearch);
