import type {FC} from 'react';
import React, {memo} from 'react';

import TopMenu from '../../modules/TopWidgetMenu/TopWidgetMenu';

interface Props {
  isWithNewCrumbs?: boolean;
  isWithCrumbs?: boolean;
  isWithFrom?: boolean;
}

const TopMenuLayout: FC<Props> = () => {
  return (
    <div className="TopMenuLayout">
      <TopMenu />
    </div>
  );
};

export default memo(TopMenuLayout);
