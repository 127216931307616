import type {FC} from 'react';
import React, {memo} from 'react';

import dayjs from 'dayjs';

import type {DateTimeObj} from '@yourcoach/shared/api';
import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {getCourseDurationString} from '@yourcoach/shared/api/course';

import {IconUserGroup} from '@src/components/icons';
import {t} from '@src/i18n';

import type {ChannelT} from '../../context/useChatsLocalStore';

import styles from './../../styles.module.scss';

interface Props {
  badge: number;
  badgeColor: string;
  channel: ChannelT;
  coachChangeDate: any;
  coachCoverageEndDate?: DateTimeObj | null;
  currentUserIsCoach?: boolean;
  date: string;
  expandedMode?: boolean;
  hasCourse?: boolean;
  isFrozen?: boolean;
  showLastPost?: boolean;
  showUpdatedDate?: boolean;
  subtenantTitle: string;
  tenantTitle: string;
  text?: string;
  userName?: string;
  userTitleName: string | null;
}

const ChatsListItemInfo: FC<Props> = ({
  badge,
  badgeColor,
  channel,
  coachChangeDate,
  coachCoverageEndDate,
  currentUserIsCoach,
  date,
  expandedMode,
  hasCourse,
  isFrozen,
  showLastPost,
  showUpdatedDate,
  subtenantTitle,
  tenantTitle,
  text,
  userName,
  userTitleName,
}) => {
  const getCoachStartDateDifference = (coachStartDate: DateTimeObj | null) => {
    if (coachStartDate !== null) {
      const startingPoint = dayjs(datetimeObjToISOString(coachStartDate));
      const currentPoint = dayjs();

      return Math.abs(startingPoint.diff(currentPoint, 'hour'));
    }

    return 0;
  };

  const getBadgeCssClass = (
    colorValue: string,
    isExpanded: boolean = false,
  ) => {
    switch (true) {
      case colorValue === 'green' && !isExpanded:
        return styles.infoCountContainerGreen;
      case colorValue === 'green' && isExpanded:
        return styles.infoCountContainerGreenNarrow;
      case colorValue === 'yellow' && !isExpanded:
        return styles.infoCountContainerYellow;
      case colorValue === 'yellow' && isExpanded:
        return styles.infoCountContainerYellowNarrow;
      case colorValue === 'orange' && !isExpanded:
        return styles.infoCountContainerOrange;
      case colorValue === 'orange' && isExpanded:
        return styles.infoCountContainerOrangeNarrow;
      case colorValue === 'red' && !isExpanded:
        return styles.infoCountContainerRed;
      case colorValue === 'red' && isExpanded:
        return styles.infoCountContainerRedNarrow;
      default:
        return styles.infoCountContainerGreen;
    }
  };

  const displayUserIcon = () => {
    const shouldDisplayUserIcon = Boolean(
      channel &&
        channel.resource &&
        channel.resource.edition &&
        channel.resource.edition.group_size > 1,
    );

    return shouldDisplayUserIcon ? (
      <IconUserGroup
        className={styles.iconGroupContainer}
        viewBox="5 5 20 24"
      />
    ) : null;
  };

  const shouldDisplayCCData = () => {
    const styledClassName = coachCoverageEndDate?.day
      ? styles.CoverageDescriptionListItem
      : styles.courseName;

    const ccDataNotAvailable = Boolean(
      hasCourse && channel && !coachCoverageEndDate?.day,
    );

    return (
      <div className={styledClassName}>
        {ccDataNotAvailable
          ? getCourseDurationString(channel.resource) ||
            t([
              'label',
              'course_no_start_date',
              currentUserIsCoach ? 'coach' : 'client',
            ])
          : coachCoverageEndDate?.day
          ? 'Coverage until ' +
            dayjs(datetimeObjToISOString(coachCoverageEndDate)).format(
              'MMM D, YYYY',
            )
          : null}
      </div>
    );
  };

  const shouldDisplayNewClientLabel = () => {
    const displayLabel = !!(
      channel.last_post_id !== null &&
      getCoachStartDateDifference(coachChangeDate) <= 24
    );

    return displayLabel ? (
      <span className={styles.newClient}>{'New client!'}</span>
    ) : showLastPost ? (
      `${userName ? userName + ':' : ''} ${
        isFrozen ? <span className={styles.danger}>{text}</span> : text
      }`
    ) : null;
  };

  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoTopContainer}>
        <div className={styles.infoTitleContainer}>
          {displayUserIcon()}
          <div
            className={expandedMode ? styles.infoFadedOut : styles.title}
          >{`${userTitleName} ${
            tenantTitle ? `(${tenantTitle}/${subtenantTitle})` : ''
          }`}</div>
        </div>
        {showUpdatedDate && !expandedMode ? (
          <div className={styles.infoDateContainer}>{date}</div>
        ) : null}
      </div>
      <div className={styles.infoButContainer}>
        <div
          className={
            expandedMode ? styles.infoFadedOut : styles.infoDescriptionContainer
          }
        >
          {shouldDisplayCCData()}
          <div className={styles.lastMess}>{shouldDisplayNewClientLabel()}</div>
        </div>
        {badge > 0 ? (
          <div className={`${getBadgeCssClass(badgeColor, expandedMode)}`}>
            {badge}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(ChatsListItemInfo);
