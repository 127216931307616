import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import {Observer} from 'mobx-react';

import {action, observable} from 'mobx';

import type {IFile} from '@yourcoach/shared/api/media/file';

import LinkIcon from '@src/assets/img/LinkIcon.svg';
import {labelAddLink} from '@src/common/i18n/i18nLibrary';
import {setError} from '@src/common/setError';
import {CustomButton} from '@src/components/CustomForm';
import ModalAnimateWin from '@src/components/GoalsModal/GoalsModal';
import AppContext from '@src/context/App';
import AddLink from '@src/modules/AddLink/AddLink';

import LibraryContext from '../../context/LibraryContext';
import type {ILibraryLocalStore} from '../../context/useLibraryLocalStore';
import {linkFiles} from '../../libraryFunctions';
import styles from '../styles.module.scss';

interface ILocalStore {
  showModal: boolean;
  toggleVisModal(isShow: boolean): void;
}

interface Props {}

const AddLinkButt: FC<Props> = () => {
  const {
    stores: {fileStore},
  } = useContext(AppContext);
  const libraryStory: ILibraryLocalStore | null = useContext(LibraryContext);
  const localStore = useRef(
    observable<ILocalStore>(
      {
        showModal: false,
        toggleVisModal(isShow: boolean) {
          this.showModal = isShow;
        },
      },
      {
        showModal: observable,
        toggleVisModal: action,
      },
    ),
  ).current;

  const handleOnClick = () => {
    localStore.toggleVisModal(true);
  };

  const closeModal = () => {
    localStore.toggleVisModal(false);
  };

  const handleOnUpload = async (nameLink: string, urlLink: string) => {
    libraryStory!.setLoading(true);

    closeModal();

    try {
      const file: IFile = await fileStore.addUrl(urlLink);
      const files = [file];

      await linkFiles(files, libraryStory!.folderID, () => {});

      libraryStory!.updateFolderLinks(files.length);
    } catch (error) {
      const tryAgainFn = () => {};

      setError(error, tryAgainFn);
    }

    libraryStory!.setLoading(false);
  };

  return (
    <Observer>
      {() => (
        <>
          <CustomButton
            type="button"
            classButton={`${styles.buttonItem}`}
            onClick={handleOnClick}
          >
            <div className={styles.buttonLabelContainer}>
              {labelAddLink()}
              <LinkIcon className={styles.addItemButtonIcon} />
            </div>
          </CustomButton>
          <ModalAnimateWin
            showModal={localStore.showModal}
            className="modalAddFolder"
            closeModalHandler={closeModal}
          >
            <AddLink onUpload={handleOnUpload} />
          </ModalAnimateWin>
        </>
      )}
    </Observer>
  );
};

export default memo(AddLinkButt);
