import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import UserFlowContext from '../../../context/UserFlowContext';
import type {IUserFlowLocalStore} from '../../../context/useUserFlowLocalStore';

import styles from './../../styles.module.css';

interface Props {}

const SingleChoiceType: FC<Props> = () => {
  const userFlowStory: IUserFlowLocalStore | null = useContext(UserFlowContext);
  const currentQ = userFlowStory!.currentStepQuestion!;

  const handleOnClick = (answer: string) => {
    return () => {
      userFlowStory?.setCurrentStepQuestionError('');
      userFlowStory?.setCurrentStepQuestionUserAnswer('');
      userFlowStory?.setCurrentStepQuestionAnswer(answer);
    };
  };

  return (
    <Observer>
      {() => (
        <div className="SingleChoiceType">
          <div className={styles.optionContainer}>
            {currentQ.question.options?.slice().map((option, numOption) => {
              return (
                <div
                  key={numOption}
                  className={`${styles.choiceOption} ${styles.singleChoice} ${
                    option === userFlowStory?.firstCurrentAnswer
                      ? styles.selected
                      : ''
                  }`}
                  onClick={handleOnClick(option)}
                >
                  {option}
                </div>
              );
            })}
          </div>
          {currentQ.textError === '' ? (
            ''
          ) : (
            <div className="errorContainer">{currentQ.textError}</div>
          )}
        </div>
      )}
    </Observer>
  );
};

export default memo(SingleChoiceType);
