import React, {useState} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

import {useMobxStore} from '@yourcoach/shared/hooks';
import type {CalendarEvent} from '@yourcoach/shared/modules/calendar';
import {CalendarContainer, Period} from '@yourcoach/shared/modules/calendar';
import {View} from '@yourcoach/shared/uikit/View';
import type {PayloadAction} from '@yourcoach/shared/utils';
import {doNothing} from '@yourcoach/shared/utils';

import Modal from '@src/components/ModalNew';
import {SingleEvent as SingleEventModal} from '@src/v2/pages/SingleEvent';

import {mapScheme} from '../MyCalendar/utils';

import styles from './index.module.css';
import {TodosStore} from './stores';

export const ToDosPage: React.FC<{}> = () => {
  const [isOpenEventModal, setIsOpenEventModal] = useState<string | null>(null);

  const store = useMobxStore(() => new TodosStore());

  const onEventClickHandler = React.useCallback(
    (action: PayloadAction<CalendarEvent>) => {
      if (action.payload) {
        setIsOpenEventModal(action.payload.id);
      }
    },
    [],
  );

  const renderEventModal = () => (
    <Modal
      isOpen={Boolean(isOpenEventModal)}
      title={
        isOpenEventModal?.includes('task')
          ? 'Journaling your daily exercise'
          : 'Live Session'
      }
      onRequestClose={() => {
        setIsOpenEventModal(null);
        store.calendar.fetch();
      }}
    >
      {isOpenEventModal && <SingleEventModal eventId={isOpenEventModal} />}
    </Modal>
  );

  return (
    <div className={styles.wrapper}>
      <Observer>
        {() => (
          <View>
            <CalendarContainer
              isSelectMode={false}
              isLoading={store.calendar.isLoading}
              data={store.filteredData}
              getDataTrigger={store.calendar.fetch}
              schedule={null as unknown as string}
              onDayOffClickAction={doNothing}
              onDaySelectClick={doNothing}
              onEditEvent={doNothing}
              onDeleteEvent={store.calendar.onDeleteEvent}
              onEventClick={onEventClickHandler}
              onEventActionClick={onEventClickHandler}
              mapScheme={mapScheme}
              onDayClick={doNothing}
              calendarInstanceType={'todos'}
              onPeriodChange={doNothing}
              hiddenPeriods={[Period.week]}
            />
            {renderEventModal()}
          </View>
        )}
      </Observer>
    </div>
  );
};
