import {apiRequest} from '@yourcoach/shared/api';

const ongoingCoachHistoryRequest = async (course_id: string) => {
  return await apiRequest({
    method: 'coach.coach_history.list',
    params: {
      query: [
        ['type', 'in', ['coach_coverage']],
        ['course_id', '==', course_id],
        ['status', '==', 'ongoing'],
      ],
    },
  });
};

export {ongoingCoachHistoryRequest};
