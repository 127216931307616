import React from 'react';

import styles from './Select.module.css';

interface Props extends React.HTMLProps<HTMLSelectElement> {
  id?: string;
  label?: string;
  error?: string;
}

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({id, label, error, children, ...rest}, ref) => {
    id = id || Math.random().toString(36).substring(2);

    return (
      <div className={`Select ${styles.Select}`}>
        <select {...rest} id={id} ref={ref}>
          {children}
        </select>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <span className={styles.error}>{error}</span>
      </div>
    );
  },
);

export default Select;
