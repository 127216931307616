import {apiRequest} from '@yourcoach/shared/api';

import {setError} from '@src/common/setError';

export const useIsRegisterNotBeenCompleted = () => {
  const isCheckUserEmail = async () => {
    let retVal = false;

    try {
      const result = await apiRequest({
        method: 'user.accounts.list',
        params: {
          query: [['type', '==', 'email']],
        },
      });

      const hasEmailAccount = result._items.length;

      if (hasEmailAccount) {
        retVal = true;
      } else {
        retVal = false;
      }
    } catch (error) {
      setError(error);
    }

    return retVal;
  };

  return new Promise(async resolve => {
    const isCheckUserE = await isCheckUserEmail();

    resolve(isCheckUserE);
  });
};
