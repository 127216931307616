import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import MoreIcon from '@yourcoach/shared/assets/icons/secondary/More.svg';
import VideoIcon from '@yourcoach/shared/assets/icons/secondary/Video.svg';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';
import {payloadActionCreator} from '@yourcoach/shared/utils/events';
import type {WithDomStyleProp} from '@yourcoach/shared/utils/withStyleProp';

import {web} from './styles';
import type {Props, StyledProps} from './types';

const FORMAT = 'H:mm A';

const Container = styled(View)<StyledProps>`
  ${web};
`;

const ConflictListItem: React.FC<WithDomStyleProp<Props>> = ({
  id,
  title,
  programTitle,
  courseTitle,
  start,
  end,
  onClick,
}) => {
  const onClickHandler = React.useCallback(() => {
    onClick(payloadActionCreator({id}));
  }, [id, onClick]);

  return (
    <Container>
      <IconBox customSize={60} padding={15} fill={'global2'} bg={'blue10'}>
        <VideoIcon />
      </IconBox>
      <View style={{flexDirection: 'column', width: 350}}>
        <Text weight="bold">{title}</Text>
        <Text variant={'tiny'} color={'text3'}>
          {programTitle}
        </Text>
        <Text variant={'tiny'}>{courseTitle}</Text>
      </View>
      <View style={{flexDirection: 'column', alignItems: 'center'}}>
        <Text variant={'tiny'}>{dayjs(start).format(FORMAT)}</Text>
        {end && (
          <React.Fragment>
            <Text> - </Text>
            <Text variant={'tiny'}>{dayjs(end).format(FORMAT)}</Text>
          </React.Fragment>
        )}
      </View>
      <View>
        {/*<Clickable onClick={() => onClick(payloadActionCreator({id}))}>*/}
        <Clickable onClick={onClickHandler}>
          <IconBox bg={'transparent'} fill={'icon3'}>
            <MoreIcon />
          </IconBox>
        </Clickable>
      </View>
    </Container>
  );
};

export {ConflictListItem};
