import type {FC} from 'react';
import React, {memo, useContext} from 'react';
import {Observer} from 'mobx-react';

import IconClose from '@src/components/icons/IconClose/IconClose';

import QSendUsersContext from '../context/QSendUsersContext';
import type {ILocalStoreQSendUsers} from '../context/QSendUsersLocalStore';

import styles from './../styles.module.css';

interface Props {}

const SendsElements: FC<Props> = () => {
  const storeQSendUsers: ILocalStoreQSendUsers = useContext(QSendUsersContext);

  const handleDelEmail = (email: string) => {
    return () => {
      storeQSendUsers.dellEmail(email);
    };
  };

  return (
    <Observer>
      {() => (
        <div className={`SendsElements ${styles.SendsElements}`}>
          <div className={styles.containerBubbles}>
            {/* {storeQSendUsers.usersList
              ?.slice()
              .filter((item) => item.isAdded === true)
              .map((userFollower) => (
                <div
                  onClick={handleDelUser(userFollower)}
                  className={styles.userContainer}
                  key={userFollower.follower._id}>
                  <div className={styles.userAvatar}>
                    <OtherUserProfileImg
                      avatar={userFollower.follower.client.avatar}
                      title={userFollower.follower.client.name}
                    />
                  </div>
                  <div className={styles.userName}>
                    {userFollower.follower.client.name}
                  </div>
                  <div className={styles.closeBut}>
                    <IconClose />
                  </div>
                </div>
              ))} */}
            {storeQSendUsers.usersEmailList.map(email => (
              <div
                className={styles.emailContainer}
                key={email}
                onClick={handleDelEmail(email)}
              >
                <div className={styles.userName}>{email}</div>
                <div className={styles.closeBut}>
                  <IconClose />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(SendsElements);
