import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {observer} from 'mobx-react';

import TaskIcon from '@yourcoach/shared/assets/icons/secondary/Tick Square.svg';
import ConferenceIcon from '@yourcoach/shared/assets/icons/secondary/Video.svg';
import {IconBox} from '@yourcoach/shared/uikit/IconBox';
import {Text} from '@yourcoach/shared/uikit/Text';
import {View} from '@yourcoach/shared/uikit/View';

export const PageTitle: React.FC<{
  title: string;
  isConference: boolean;
  isTask: boolean;
  isLoading?: boolean;
}> = observer(({title, isConference, isTask, isLoading}) => {
  let color = isConference ? 'global2' : 'global1';

  return (
    <View style={{gap: 10, alignItems: 'center'}}>
      {isLoading ? (
        <Skeleton width={400} height={32} />
      ) : (
        <React.Fragment>
          <IconBox bg={'transparent'} fill={color} size={'icon.m'} padding={0}>
            {isConference ? <ConferenceIcon /> : null}
            {isTask ? <TaskIcon /> : null}
          </IconBox>
          <Text variant={'heading'} as={'h1'}>
            {title}
          </Text>
        </React.Fragment>
      )}
    </View>
  );
});
