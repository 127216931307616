import type {FC} from 'react';
import React, {memo, useContext, useMemo} from 'react';

import classNames from 'classnames';

import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileSrc} from '@yourcoach/shared/api/media/file';

import Button from '@src/components/Button';
import SpinnerOverlay from '@src/components/SpinnerOverlay';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import ImagePane from '../ImagePane';
import Progress from '../Progress';

import styles from './styles.module.css';

const I18N_SCOPE = 'Onboarding';

interface OnboardingData {
  name?: string[];
  avatar?: IFile | null;
  birthday?: Date;
  location?: string;
  program?: Object;
  task?: Object;
  targets?: string[];
  goal?: string;
  extra_goals?: string[];
}

interface Props {
  title: string;
  description?: string | null;
  buttons?: 'none' | 'skip' | 'nav' | (string | JSX.Element)[];
  wideLeft?: boolean;
  step: number;
  progress?: number;
  total?: number;
  data?: OnboardingData;
  onBack?: () => void;
  onContinue?: () => void;
  onSkip?: () => void;
  leftPaneContent?: React.ReactNode | React.ReactNode[];
  isRequest?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const VirtualHomeStep: FC<Props> = ({
  title,
  description = '',
  buttons = 'nav',
  wideLeft,
  step,
  progress,
  total,
  isRequest,
  onBack,
  onContinue,
  onSkip,
  leftPaneContent,
  children,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);

  const src = useMemo(
    () => getFileSrc(currentUserStore.user?.avatar, 250).url || '',
    [currentUserStore.user?.avatar],
  );

  return (
    <>
      <div
        className={classNames(styles.leftPane, {[styles.wideLeft]: wideLeft})}
      >
        {leftPaneContent ? (
          leftPaneContent
        ) : (
          <ImagePane step={step}>
            {step >= 2 && src ? (
              <img className={styles.avatar} src={src} />
            ) : null}
          </ImagePane>
        )}
      </div>
      <div
        className={classNames(styles.rightPane, {
          [styles.forWideLeft]: wideLeft,
        })}
      >
        {wideLeft ? null : <Progress progress={progress} total={total} />}
        {typeof title === 'string' ? (
          <div className={styles.title}>{title}</div>
        ) : (
          title
        )}
        {typeof description === 'string' ? (
          <div className={styles.description}>{description}</div>
        ) : (
          description
        )}
        <div className={styles.content}>{children}</div>
        {buttons === 'none' ? null : (
          <div className={styles.buttons}>
            {Array.isArray(buttons) ? (
              buttons.map((button, index) =>
                typeof button === 'string' ? (
                  <Button
                    key={`item-${index}`}
                    onClick={onContinue}
                    className={classNames(styles.wide, {
                      [styles.inverted]: index === 0,
                    })}
                  >
                    {button}
                  </Button>
                ) : (
                  <React.Fragment key={index}>{button}</React.Fragment>
                ),
              )
            ) : buttons === 'skip' ? (
              <Button className={styles.inverted} onClick={onSkip}>
                {t('Common.Skip')}
              </Button>
            ) : (
              <>
                <Button
                  onClick={onBack}
                  className={classNames(styles.inverted, {
                    [styles.hidden]: !onBack,
                  })}
                >
                  {t('Common.Back')}
                </Button>
                <SpinnerOverlay isActive={isRequest}>
                  <Button
                    onClick={onContinue}
                    className={classNames(styles.wide, {
                      [styles.hidden]: !onContinue,
                    })}
                  >
                    {isRequest ? <>&nbsp;</> : t([I18N_SCOPE, 'submit_button'])}
                  </Button>
                </SpinnerOverlay>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(VirtualHomeStep);
