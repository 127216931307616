import type {FC} from 'react';
import React, {memo, useCallback} from 'react';

import type {Program} from '@yourcoach/shared/api/program';

import ProgramListItem from './ProgramListItem';
import styles from './styles.module.css';

interface Props {
  items?: Program[];
  onChange?: (arg0: any) => void;
}

const COLORS = ['#f0d049', '#1a2f7c', '#0b8266'];

const ProgramsList: FC<Props> = ({items, onChange}) => {
  const callOnChange = useCallback(
    item => {
      if (onChange) {
        onChange(item);
      }
    },
    [onChange],
  );

  const makeCallOnChange = (item: Program) => () => callOnChange(item);

  return (
    <div className={styles.programsList}>
      {(items || []).map((item, index) => (
        <ProgramListItem
          key={'item-' + index}
          title={item.title}
          description={item.description}
          color={COLORS[index % COLORS.length]}
          onClick={makeCallOnChange(item)}
        />
      ))}
    </div>
  );
};

export default memo(ProgramsList);
