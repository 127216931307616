import type {FC} from 'react';
import React, {memo, useCallback} from 'react';

import UserGroupIcon from '@yourcoach/shared/assets/icons/user-group.svg';
import {themes} from '@yourcoach/shared/styles/theme';

import {t} from '@src/i18n';

import styles from './styles.module.scss';

export const CLIENT_SUBGOALS_BUTTON_ID = 'client_subgoals_button';

interface Props {
  onPress: () => void;
  className?: string;
}

const ClientSubgoalsButton: FC<Props> = ({onPress, className}) => {
  const onPressCb = useCallback(() => {
    onPress && onPress();
  }, [onPress]);

  return (
    <div
      onClick={onPressCb}
      className={`ClientSubgoalsButton ${styles.ClientSubgoalsButton} ${className}`}
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <UserGroupIcon
            width={36}
            height={36}
            fill={themes.light.color.primary1}
            className={styles.icon}
          />
          <div className={styles.title}>{t('label.client_subgoals')}</div>
        </div>
        <div className={styles.button}>{t('label.view_client_subgoals')}</div>
      </div>
    </div>
  );
};

export default memo(ClientSubgoalsButton);
