import {useContext} from 'react';
import {useLocation} from 'react-router-dom';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';
import {waitForAsync} from '@yourcoach/shared/utils';
import storage from '@yourcoach/shared/utils/storage';

import {setError} from '@src/common/setError';
import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';
import LoginFormContext from '@src/pages/Login/context/LoginFormContext';
import type {ILoginFormStore} from '@src/pages/Login/context/store';
import {USER_REGISTER_EMAIL_PATH} from '@src/routes';

import {PATH_TYPE} from '../routes/utils';

import {browser} from './browserType';
import {useAppRedirect} from './useAppRedirect';
import {useIsRegisterNotBeenCompleted} from './useIsRegisterNotBeenCompleted';

export const useSetReturnPath = () => {
  let location = useLocation();

  if (
    location.pathname !== '/' &&
    location.pathname !== `/${PATH_TYPE.yourSpace}` &&
    location.pathname !== '/login'
  ) {
    storage.setItem('returnPath', `${location.pathname}${location.search}`);

    return () => {
      localAppStore?.setReturnPath(`${location.pathname}${location.search}`);
    };
  } else {
    return () => {};
  }
};

export const useIsAuth = (fnTrue = () => {}, fnFalse = () => {}) => {
  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);
  const useIsRegisterNotBeenComp = useIsRegisterNotBeenCompleted();
  const redirectEditProfile = useAppRedirect();

  const isAuth = async (fnTrueIn = () => {}, fnFalseIn = () => {}) => {
    try {
      const sessionIsActive = await authStore.sessionIsActive();
      const userExists = await currentUserStore.userExists();

      if (sessionIsActive && userExists) {
        localAppStore!.setIsAuth(true);

        const isUserHasEmail = await useIsRegisterNotBeenComp;

        if (isUserHasEmail) {
          fnTrueIn();
        } else {
          fnTrueIn();
          redirectEditProfile(USER_REGISTER_EMAIL_PATH);
        }
      } else {
        localAppStore!.setIsAuth(false);
        fnFalseIn();
      }
    } catch (error) {
      setError(error);
    }
  };

  return async () => await isAuth(fnTrue, fnFalse);
};

export interface IRegSendObject {
  agent: string;
  code: any;
  name: string;
  email?: string;
  phone?: string;
}

export const useSignUp = () => {
  const {regLogFormStore}: ILoginFormStore = useContext(LoginFormContext);
  const {
    stores: {authStore},
  } = useContext(AppContext);

  const signUp = async (
    sendObject: IRegSendObject,
    fnTrue = () => {},
    fnError = error => {
      setError(error);
    },
  ) => {
    const userAgent = browser();

    sendObject.agent = userAgent;

    if (regLogFormStore?.wasEnteredEmail) {
      sendObject.email = regLogFormStore?.wasEnteredEmail;
    }

    if (regLogFormStore?.wasEnteredPhone) {
      sendObject.phone = regLogFormStore?.wasEnteredPhone;
    }

    try {
      await authStore.signup(sendObject);
      fnTrue();
    } catch (err) {
      fnError(err);
    }
  };

  return (
    sendObject: IRegSendObject,
    fnTrue = () => {},
    fnError = error => {
      setError(error);
    },
  ) => signUp(sendObject, fnTrue, fnError);
};

export interface ILoginSendObject {
  agent: string;
  code: any;
  email?: string;
  phone?: string;
}

export const useLogIn = () => {
  const {regLogFormStore}: ILoginFormStore = useContext(LoginFormContext);
  const {
    stores: {authStore},
  } = useContext(AppContext);

  const logIn = async (
    sendObject: ILoginSendObject,
    fnTrue = () => {},
    fnError = error => {
      setError(error);
    },
  ) => {
    const userAgent = browser();

    sendObject.agent = userAgent;

    if (regLogFormStore?.wasEnteredEmail) {
      sendObject.email = regLogFormStore?.wasEnteredEmail.trim();
    }

    if (regLogFormStore?.wasEnteredPhone) {
      sendObject.phone = regLogFormStore?.wasEnteredPhone;
    }

    try {
      await authStore.login(sendObject);
      fnTrue();
    } catch (err) {
      fnError(err);
    }
  };

  return (
    sendObject: ILoginSendObject,
    fnTrue = () => {},
    fnError = error => {
      setError(error);
    },
  ) => logIn(sendObject, fnTrue, fnError);
};

export const useGetCode = () => {
  const {
    stores: {authStore},
  } = useContext(AppContext);
  const {timerStore}: ILoginFormStore = useContext(LoginFormContext);

  const getCode = async (name: 'email' | 'phone', val: string) => {
    try {
      if (authStore.inProgress) {
        await waitForAsync(() => authStore.inProgress === false);
      }

      const sendCodeResult = await authStore.sendCode({
        [name]: val,
        toWebsite: true,
      });

      const sent = dayjs(datetimeObjToISOString(sendCodeResult!.sent));
      const expires = dayjs(datetimeObjToISOString(sendCodeResult!.expires));

      const resendTime = expires.diff(sent, 'second') - 1;

      timerStore?.updateTime(resendTime);
    } catch (error) {
      setError(error);

      setTimeout(() => {
        authStore.sendCode({
          [name]: val,
          toWebsite: true,
        });
      }, 10000);
    }
  };

  return (name: 'email' | 'phone', val: string) => getCode(name, val);
};
