import React from 'react';

import type {Props as ModalProps} from '@src/components/ModalNew';
import Modal from '@src/components/ModalNew';

import type {Props as AddFileProps} from './AddFile';
import AddFile from './AddFile';
import styles from './AddFile.module.css';

type Props = Partial<ModalProps> & AddFileProps;

const AddFileModal: React.FC<Props> = ({
  mode,
  multiple,
  maxSize,
  fileType,
  onUpload,
  onSelect,
  children,
  ...modalProps
}) => (
  <Modal {...modalProps} title="Upload file" bodyClassName={styles.modalBody}>
    <AddFile
      mode={mode}
      multiple={multiple}
      maxSize={maxSize}
      fileType={fileType}
      onUpload={onUpload}
      onSelect={onSelect}
    />
    {children}
  </Modal>
);

export default React.memo(AddFileModal);
