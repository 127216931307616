import type {FC} from 'react';
import React, {memo, useContext, useRef} from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, observable, runInAction} from 'mobx';

import {ISOStringToDatetimeObj} from '@yourcoach/shared/api';
import type {Subgoal} from '@yourcoach/shared/api/goal';
import {logger} from '@yourcoach/shared/utils/logger';

import {getCustomConfirmAlert} from '../../../components/CustomConfirmAlert/CustomConfirmAlert';
import Loader from '../../../components/Loader/Loader';
import AppContext from '../../../context/App';

import styles from './../styles.module.css';

interface ILocalStore {
  attitudeValue: number;
  isSaving: boolean;
  _onValueChange(value: number): void;
}

interface Props {
  subgoal: Subgoal;
  closeModal: () => void;
}

const RateSubgoal: FC<Props> = ({subgoal, closeModal}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const localStore: ILocalStore = useRef(
    observable(
      {
        attitudeValue: 0,
        isSaving: false,
        _onValueChange(value: number) {
          this.attitudeValue = value;
        },
      },
      {
        attitudeValue: observable,
        isSaving: observable,
        _onValueChange: action,
      },
    ),
  ).current;

  const handleOnChangeRate = value => {
    const rate = parseInt(value, 10);

    localStore._onValueChange(rate);
  };

  const _onConfirmButtonPress = async () => {
    try {
      runInAction(() => (localStore.isSaving = true));

      await goalStore.sub.client.markReached(subgoal, {
        client_attitude: localStore.attitudeValue / 100,
      });

      await goalStore.sub.client.localUpdate(subgoal, {
        reached: ISOStringToDatetimeObj(dayjs().toISOString()),
      });

      logger.event('confirm_goal_congratulation_tap', {
        satisfaction_score: localStore.attitudeValue / 100,
      });

      runInAction(() => {
        localStore.isSaving = false;
      });

      closeModal();
    } catch (error) {
      runInAction(() => (localStore.isSaving = false));

      getCustomConfirmAlert({
        title: 'Oops! Something went wrong! We are working to fix it!',
        message: error.message,
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`RateSubgoal ${styles.RateSubgoal}`}>
          <div className={styles.headerPic}>
            <div className={styles.picContainer}>
              <img
                src={'https://static.hcod.yourcoach.health/confetti.png'}
                alt="confetti-icon"
              />
            </div>
          </div>
          <div className={styles.firstPanelContainer}>
            <div className={styles.firstPanelContentContainer}>
              <div className={styles.title}>Congratulations!</div>
              <div className={styles.description}>
                Your short-term goals is done
              </div>
            </div>
            <div className={styles.secondPanelContainer}>
              <div className={styles.attitudeTitle}>
                How satisfied are you with completing your goal?
              </div>
              <div className={styles.sliderContainer}>
                <div className={`customSlider ${styles.settingItem}`}>
                  <Slider
                    min={0}
                    max={100}
                    tooltip={false}
                    value={localStore.attitudeValue}
                    orientation="horizontal"
                    onChange={handleOnChangeRate}
                  />
                </div>
              </div>
              <div className={styles.confirmButContainer}>
                <button
                  type="button"
                  className={styles.confirm_subgoal_rate}
                  onClick={_onConfirmButtonPress}
                >
                  {localStore.isSaving ? <Loader /> : <span>Confirm</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(RateSubgoal);
