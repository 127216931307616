import BugsnagPluginReact from '@bugsnag/plugin-react';

import Bugsnag from '@bugsnag/js';

import {apiRequest} from '@yourcoach/shared/api';
import type {ILogger} from '@yourcoach/shared/utils/logger';

import {API_VERSION, BUGSNAG_KEY} from '@src/config';

export class Logger implements ILogger {
  constructor() {
    if (!__SSR__) {
      this.init();
    }
  }

  init: Required<ILogger>['init'] = () => {
    Bugsnag.start({
      apiKey: BUGSNAG_KEY,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: ['dev', 'stage', 'prod'],
      releaseStage:
        process.env.APP_ENV +
        // we do not want to send errors from local dev to bugsnag
        (process.env.NODE_ENV === 'development' ? '-dev' : ''),
      metadata: {
        api_version: {
          version: API_VERSION,
        },
      },
      redactedKeys: [
        'access_token',
        'session_token',
        'client_id',
        'client_secret',
        'external_id',
        'token',
        'phone',
        'email',
      ],
      onError: event => {
        if (
          event.originalError.name === 'AbortError' ||
          apiRequest.isCanceledError(event.originalError) ||
          event.originalError.message === 'The network connection was lost.'
        ) {
          return false;
        }
      },
    });
  };

  setUser: Required<ILogger>['setUser'] = user => {
    Bugsnag.setUser(
      user ? user._id : undefined,
      undefined,
      user ? user.name : undefined,
    );
  };

  setUserProperty: Required<ILogger>['setUserProperty'] = () => {};

  event: Required<ILogger>['event'] = (name, params) => {
    Bugsnag.leaveBreadcrumb(name, params, 'log');
  };

  error: Required<ILogger>['error'] = error => {
    Bugsnag.notify(error);
  };

  logApiRequest: Required<ILogger>['logApiRequest'] = payload => {
    Bugsnag.leaveBreadcrumb(
      'RPC request',
      {
        method: Array.isArray(payload)
          ? `Batch: ${payload.map(item => item.method).join(' ,')}`
          : payload.method,
      },
      'log',
    );
  };
}
