import type {FC} from 'react';
import React, {memo} from 'react';

import IconTextSendButtSVG from '../../../assets/Send.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconTextSendButt: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = '',
  viewBox = '0 0 24 24',
}) => {
  return (
    <IconTextSendButtSVG
      className={className}
      width={height}
      height={width}
      viewBox={viewBox}
    />
  );
};

export default memo(IconTextSendButt);
