import React from 'react';
import {observer} from 'mobx-react';

import {useMobxStore} from '@yourcoach/shared/hooks';
import {eventStoreFactory} from '@yourcoach/shared/stores/single-event';

import {Card} from '@src/v2/components/CardWidget';

import {Layout} from './components/Layout';
import {AttachmentsForm} from './containers/AttachmentsForm';
import {ControlsContainer} from './containers/Controls';
import {CourseInfoContainer} from './containers/CourseInfo';

interface Props {
  eventId: string;
}

export const SingleEvent: React.FC<Props> = observer(({eventId}) => {
  const store = useMobxStore(() => eventStoreFactory(eventId));

  React.useEffect(() => {
    store.fetch();
  }, [store]);

  return (
    <Layout.Container>
      <Layout.Main>
        <Card>
          <CourseInfoContainer
            courseId={store.event?.value?.course_id}
            eventId={store.event?.id}
          />
          {store.event?.isTask && store.event?.isMeClient && (
            <AttachmentsForm taskId={store.event.id} />
          )}
          <ControlsContainer store={store} />
        </Card>
      </Layout.Main>
    </Layout.Container>
  );
});
