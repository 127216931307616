import type {FC} from 'react';
import React, {memo, useContext, useEffect, useMemo} from 'react';
import {animation, contextMenu, Item, Menu} from 'react-contexify';
import Scrollbar from 'react-scrollbars-custom';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';
import {action, computed, observable, reaction} from 'mobx';

import type {CollectionStore} from '@yourcoach/shared/api';
import {
  createCollectionStore,
  // DateTimeObj,
  datetimeObjToISOString,
  ISOStringToDatetimeObj,
  // RpcRequest,
} from '@yourcoach/shared/api';
import type {Course} from '@yourcoach/shared/api/course';
import type {Goal, Subgoal} from '@yourcoach/shared/api/goal';
import type {Edition} from '@yourcoach/shared/api/program';

// import {
//   CoachGoalsUnboundCreateParams,
//   CoachSubgoalsUnboundCreateParams,
// } from '@yourcoach/shared/api/types/methods';
import {labelAdd} from '@src/common/i18n/i18nCommon';
import {labelMainGoal} from '@src/common/i18n/i18nPrograms';
import {setError} from '@src/common/setError';
import {CustomButton} from '@src/components/CustomForm';
import {IconCup} from '@src/components/icons';
import Loader from '@src/components/Loader/Loader';
import ModalAnimateWin from '@src/components/ModalAnimateWin/ModalAnimateWin';
import NoResults from '@src/components/NoResults/NoResults';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';
// import {expand as goalExpand} from '@src/models/goals';
import type {Goal as PreviousGoal} from '@src/modules/legacy/Goals/SelectGoal/SelectGoal';

import {GoalsDataService} from '../../../../v2/services/GoalsDataService';
// import {isDefined} from '../../../../v2/utils';
import type {GoalT} from '../CRUGoal/CRUGoal';
import CRUGoal from '../CRUGoal/CRUGoal';
import CRUSubgoal from '../CRUSubgoal/CRUSubgoal';
import GoalsListItem from '../GoalsListItem/GoalsListItem';
import PreviousGoalsListItem from '../PreviousGoalsListItem/PreviousGoalsListItem';
import SelectGoal from '../SelectGoal/SelectGoal';

import styles from './../styles.module.scss';

const I18N_SCOPE = 'AddGoals';
const I18N_SCOPE_SUBGOAL = 'CRUSubgoal';
const GOALS_SOURCE = ['program', 'previous'] as const;

interface ILocalStore {
  isCurrentPreviousGoal: boolean;
  setIsCurrentPreviousGoal(isCurrentPreviousGoal: boolean): void;
  goalsSource: typeof GOALS_SOURCE[number];
  setGoalsSource(goalsSource: typeof GOALS_SOURCE[number]): void;
  programGoalsSelection: Goal[];
  previousGoals: PreviousGoal[];
  setPreviousGoals(previousGoals: PreviousGoal[]): void;
  havePreviousSubgoals: boolean;
  programGoalsStore: CollectionStore<Goal> | null;
  setProgramGoalsStore(programGoalsStore: CollectionStore<Goal>): void;
  programGoalsSourceIsNewGoals: boolean;
  setProgramGoalsSourceIsNewGoals(programGoalsSourceIsNewGoals: boolean): void;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
  _setGoalsSource(source: typeof GOALS_SOURCE[number]): void;
  _fetchProgramGoals(silent?: boolean): Promise<void>;
  _onProgramGoalSelectPress(goal: Goal): void;
  _onDeletePreviousGoalPress(goal: any): void;
  _onDeletePreviousSubgoalPress(subgoal: any): void;
  _changePreviousSubgoalDates(): void;
  isOpenAddGoalModule: boolean;
  setIsOpenAddGoalModule(isOpenAddGoalModule: boolean): void;
  selectGoal: Goal | null;
  setSelectGoal(selectGoal: Goal | null): void;
  isOpenChooseGoalModule: boolean;
  setIsOpenChooseGoalModule(isOpenChooseGoalModule: boolean): void;
  editSubgoal: Subgoal | null;
  setEditSubgoal(editSubgoal: Subgoal | null): void;
  isOpenCRUSubgoalModule: boolean;
  setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean): void;
}

interface Props {
  course: Course & {
    edition?: Edition | null;
  };
  closeAddGoals?: () => void;
}

const AddGoals: FC<Props> = ({course, closeAddGoals}) => {
  const {
    stores: {goalStore},
  } = useContext(AppContext);
  const dataService = React.useRef(new GoalsDataService(goalStore));
  const localStore: ILocalStore = useMemo(
    () =>
      observable(
        {
          isOpenCRUSubgoalModule: false,
          setIsOpenCRUSubgoalModule(isOpenCRUSubgoalModule: boolean) {
            this.isOpenCRUSubgoalModule = isOpenCRUSubgoalModule;
          },
          editSubgoal: null,
          setEditSubgoal(editSubgoal: Subgoal | null) {
            this.editSubgoal = editSubgoal;
          },
          isCurrentPreviousGoal: false,
          setIsCurrentPreviousGoal(isCurrentPreviousGoal: boolean) {
            this.isCurrentPreviousGoal = isCurrentPreviousGoal;
          },
          isOpenChooseGoalModule: false,
          setIsOpenChooseGoalModule(isOpenChooseGoalModule: boolean) {
            this.isOpenChooseGoalModule = isOpenChooseGoalModule;
          },
          selectGoal: null,
          setSelectGoal(selectGoal: Goal | null) {
            this.selectGoal = selectGoal;
          },
          isOpenAddGoalModule: false,
          setIsOpenAddGoalModule(isOpenAddGoalModule: boolean) {
            this.isOpenAddGoalModule = isOpenAddGoalModule;
          },
          goalsSource: 'program',
          setGoalsSource(goalsSource: typeof GOALS_SOURCE[number]) {
            this.goalsSource = goalsSource;
          },
          programGoalsSelection: [],
          previousGoals: [],
          setPreviousGoals(previousGoals: PreviousGoal[]) {
            this.previousGoals = previousGoals;
          },
          get havePreviousSubgoals() {
            return (
              this.previousGoals.reduce(
                (sum, goal) => sum + goal.subgoals.length,
                0,
              ) > 0
            );
          },
          programGoalsStore: null,
          setProgramGoalsStore(programGoalsStore: CollectionStore<Goal>) {
            this.programGoalsStore = programGoalsStore;
          },
          programGoalsSourceIsNewGoals: false,
          setProgramGoalsSourceIsNewGoals(
            programGoalsSourceIsNewGoals: boolean,
          ) {
            this.programGoalsSourceIsNewGoals = programGoalsSourceIsNewGoals;
          },
          isLoading: false,
          setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
          },
          async _fetchProgramGoals(silent = false) {
            await localStore.programGoalsStore!.fetch({}, {silent});
          },
          _setGoalsSource(source: typeof GOALS_SOURCE[number]) {
            this.goalsSource = source;

            if (this.goalsSource === 'program') {
              this._fetchProgramGoals(true);
            }
          },
          _onProgramGoalSelectPress(goal: Goal) {
            const index = this.programGoalsSelection.findIndex(
              item => item._id === goal._id,
            );

            if (index >= 0) {
              this.programGoalsSelection.splice(index, 1);
            } else {
              this.programGoalsSelection.push(goal);
            }
          },
          _onDeletePreviousGoalPress(goal: PreviousGoal) {
            const index = this.previousGoals.findIndex(
              item => goal._id === item._id,
            );

            this.previousGoals.splice(index, 1);
          },
          _onDeletePreviousSubgoalPress(subgoal: PreviousGoal['subgoals'][0]) {
            const goalIndex = this.previousGoals.findIndex(
              item => subgoal.goal_id === item._id,
            );

            const subgoalIndex = this.previousGoals[
              goalIndex
            ].subgoal_ids.findIndex(id => subgoal._id === id);

            const subgoals = this.previousGoals[goalIndex].subgoals;

            subgoals.splice(subgoalIndex, 1);

            this.previousGoals[goalIndex] = {
              ...this.previousGoals[goalIndex],
              subgoals,
            };
          },
          _changePreviousSubgoalDates() {
            const minDate = dayjs(datetimeObjToISOString(course.start_date));
            const maxDate = dayjs(datetimeObjToISOString(course.end_date));

            this.previousGoals = this.previousGoals.map(goal => ({
              ...goal,
              subgoals: goal.subgoals.map(subgoal => {
                if (goal.course) {
                  const startDateDaysDelta = minDate
                    .startOf('day')
                    .diff(
                      dayjs(
                        datetimeObjToISOString(goal.course.start_date),
                      ).startOf('day'),
                      'day',
                    );

                  let sgStartDate = dayjs(
                    datetimeObjToISOString(
                      // @ts-ignore
                      subgoal._initialStartDate || subgoal.start_date,
                    ),
                  ).add(startDateDaysDelta, 'day');

                  sgStartDate =
                    sgStartDate < minDate || sgStartDate > maxDate
                      ? minDate
                      : sgStartDate;

                  let sgEndDate = dayjs(
                    // @ts-ignore
                    datetimeObjToISOString(
                      subgoal._initialEndDate || subgoal.end_date,
                    ),
                  ).add(startDateDaysDelta, 'day');

                  sgEndDate = sgEndDate > maxDate ? maxDate : sgEndDate;

                  // one day program case
                  if (sgStartDate.isSame(sgEndDate, 'date')) {
                    sgEndDate = sgEndDate.add(1, 'day');
                  }

                  return {
                    ...subgoal,
                    // Need to cache initial start and end dates
                    // to prevent wrong calculations when the startDate is changing
                    // @ts-ignore
                    _initialStartDate:
                      subgoal._initialStartDate || subgoal.start_date,
                    // @ts-ignore
                    _initialEndDate:
                      subgoal._initialEndDate || subgoal.end_date,
                    start_date: ISOStringToDatetimeObj(
                      sgStartDate.toISOString(),
                    ),
                    end_date: ISOStringToDatetimeObj(sgEndDate.toISOString()),
                  };
                } else {
                  return subgoal;
                }
              }),
            }));
          },
        },
        {
          goalsSource: observable,
          setGoalsSource: action,
          programGoalsSelection: observable.shallow,
          previousGoals: observable.shallow,
          setPreviousGoals: action,
          havePreviousSubgoals: computed,
          programGoalsStore: observable,
          setProgramGoalsStore: action,
          programGoalsSourceIsNewGoals: observable,
          setProgramGoalsSourceIsNewGoals: action,
          isLoading: observable,
          setIsLoading: action,
          _setGoalsSource: action,
          _fetchProgramGoals: action,
          _onProgramGoalSelectPress: action,
          _onDeletePreviousGoalPress: action,
          _onDeletePreviousSubgoalPress: action,
          _changePreviousSubgoalDates: action,
          isOpenAddGoalModule: observable,
          setIsOpenAddGoalModule: action,
          selectGoal: observable,
          setSelectGoal: action,
          isOpenChooseGoalModule: observable,
          setIsOpenChooseGoalModule: action,
          isCurrentPreviousGoal: observable,
          setIsCurrentPreviousGoal: action,
          editSubgoal: observable,
          setEditSubgoal: action,
          isOpenCRUSubgoalModule: observable,
          setIsOpenCRUSubgoalModule: action,
        },
      ),
    [course.end_date, course.start_date],
  );

  useEffect(() => {
    localStore.setProgramGoalsStore(
      createCollectionStore({
        method: 'coach.goals.unbound.list',
        params: {
          query: [
            ['program_id', '==', course.program_id],
            ['course_id', '==', null],
          ],
          sort: [['sort_order', 1]],
        },
      }),
    );

    const disposeGoalStoreCreating = reaction(
      () => goalStore.creating,
      creating => {
        if (
          creating.success &&
          creating.entity &&
          creating.entity.program_id === course.program_id
        ) {
          if (localStore.programGoalsStore) {
            localStore.programGoalsStore!.addItem(creating.entity);

            localStore.programGoalsSelection.push(creating.entity);
          }
        }
      },
    );
    const disposeProgramGoalsStoreLoading = reaction(
      () => localStore.programGoalsStore!.isLoaded,
      isLoaded => {
        if (isLoaded) {
          localStore.setProgramGoalsSourceIsNewGoals(
            !localStore.programGoalsStore!.hasItems,
          );
        }
      },
    );

    if (localStore.goalsSource === 'program') {
      localStore._fetchProgramGoals();
    }

    return () => {
      disposeGoalStoreCreating();
      disposeProgramGoalsStoreLoading();
      localStore.programGoalsStore?.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _createGoals = async () => {
    try {
      localStore.setIsLoading(true);

      if (localStore.goalsSource === 'program') {
        if (localStore.programGoalsSelection.length) {
          // const batch: RpcRequest<CoachGoalsUnboundCreateParams>[] = [];
          //
          // localStore.programGoalsSelection.forEach((goal, i) => {
          //   batch.push({
          //     method: 'coach.goals.unbound.create',
          //     params: {
          //       parent_goal_id: goal._id.includes('goal') ? goal._id : null,
          //       program_id: course.program_id,
          //       course_id: course._id,
          //       title: goal.title,
          //       description: goal.description,
          //       sort_order: i * 10,
          //       expand: goalExpand,
          //     },
          //   });
          // });
          //
          // await goalStore.createBatch(batch.map(item => item.params!));
          await dataService.current.createGoalsInCourse(
            localStore.programGoalsSelection,
            course,
          );
        }
      } else if (localStore.goalsSource === 'previous') {
        if (localStore.previousGoals.length) {
          // const goalsBatchParams: CoachGoalsUnboundCreateParams[] = [];
          //
          // let subGoalsBatchParams: CoachSubgoalsUnboundCreateParams[] = [];
          //
          // localStore.previousGoals.forEach((goal, gIndex) => {
          //   goalsBatchParams.push({
          //     // method: 'coach.goals.unbound.create',
          //     // params: {
          //     parent_goal_id: goal._id,
          //     program_id: course.program_id,
          //     course_id: course._id,
          //     title: goal.title,
          //     description: goal.description,
          //     sort_order: gIndex * 10,
          //     expand: goalExpand,
          //     // },
          //   });
          //
          //   goal.subgoals.forEach((subgoal, sgIndex) => {
          //     subGoalsBatchParams.push({
          //       // method: 'coach.subgoals.unbound.create',
          //       // params: {
          //       goal_id: goal._id,
          //       title: subgoal.title,
          //       description: subgoal.description,
          //       icon: subgoal.icon,
          //       color: subgoal.color,
          //       start_date: subgoal.start_date as DateTimeObj,
          //       end_date: subgoal.end_date as DateTimeObj,
          //       unit: subgoal.unit,
          //       initial_value: subgoal.initial_value,
          //       target_value: subgoal.target_value,
          //       checkpoints: subgoal.checkpoints.map(
          //         checkpoint => checkpoint.title,
          //       ),
          //       sort_order: sgIndex * 10,
          //       // },
          //     });
          //   });
          // });
          //
          // const goals = await goalStore.createBatch(goalsBatchParams);
          //
          // // await goalStore.sub.createBatch(
          // //   subgoalsBatch.map(item => {
          // //     const mainGoal = goals.find(
          // //       goal => goal.parent_goal_id === item.params!.goal_id,
          // //     );
          // //
          // //     return {
          // //       ...item.params,
          // //       goal_id: mainGoal ? mainGoal._id : null,
          // //     };
          // //   }),
          // // );
          //
          // subGoalsBatchParams = subGoalsBatchParams
          //   .map(paramObj => {
          //     const mainGoal = goals.find(
          //       goal => goal.parent_goal_id === paramObj.goal_id,
          //     );
          //
          //     if (mainGoal) {
          //       return {...paramObj, goal_id: mainGoal._id};
          //     }
          //   })
          //   .filter(isDefined);
          //
          // await goalStore.sub.createBatch(subGoalsBatchParams);

          await dataService.current.createGoalsInCourse(
            localStore.previousGoals,
            course,
          );
        }
      }

      if (course.status === 'ongoing') {
        await goalStore.coach.bound.synchronize({
          course_id: course._id,
        });
      }

      localStore.setIsLoading(false);

      if (closeAddGoals) {
        closeAddGoals();
      }
    } catch (error) {
      localStore.setIsLoading(false);
      setError(error);
    }
  };

  const _onAddGoalButtonPress = () => {
    localStore.setSelectGoal(null);
    localStore.setIsOpenAddGoalModule(true);
  };

  const handleCloseModalAddGoal = () => {
    localStore.setIsOpenAddGoalModule(false);
  };

  const handleCloseAddGoalRerunModal = () => {
    localStore.setIsOpenAddGoalModule(false);
  };

  const _onGoalPress = (goal: Goal) => {
    contextMenu.hideAll();
    localStore.setSelectGoal(goal);
    localStore.setIsOpenAddGoalModule(true);
  };

  const handleOnEditMainGoal = (updatedGoal: Goal) => {
    localStore.setIsOpenAddGoalModule(false);

    if (localStore.isCurrentPreviousGoal) {
      //_onPreviousGoalPress
      const index = localStore.previousGoals.findIndex(
        item => updatedGoal._id === item._id,
      );

      localStore.previousGoals[index] = {
        ...localStore.previousGoals[index],
        ...updatedGoal,
      };
    } else {
      //_onProgramGoalPress
      localStore.programGoalsStore!.updateItem(
        localStore.selectGoal!,
        updatedGoal,
      );

      const index = localStore.programGoalsSelection.findIndex(
        item => item._id === localStore.selectGoal!._id,
      );

      if (index >= 0) {
        localStore.programGoalsSelection.splice(index, 1, updatedGoal);
      }
    }
  };

  const _onChoosePreviousGoalsButtonPress = () => {
    localStore.setIsOpenChooseGoalModule(true);
  };
  const handleCloseModalChooseGoal = () => {
    localStore.setIsOpenChooseGoalModule(false);
  };

  const _onSetButtonPress = () => {
    localStore.setIsOpenAddGoalModule(false);
    // cosnole.log('sure here');
    _createGoals();
  };

  const menuProgramMainGoalId = 'menuProgramMainGoalId';
  const handleGetContextMenu =
    (isPreviousGoal: boolean = false) =>
    (goal: Goal, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      contextMenu.show({
        id: menuProgramMainGoalId,
        event: event,
        props: {
          isPreviousGoal,
          goal,
        },
      });
    };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  const MyMenuProgramMainGoal = () => (
    <div onClick={handleClickMenu}>
      <Menu
        id={menuProgramMainGoalId}
        animation={animation.fade}
        className="contextMenu"
        style={{zIndex: 1000}}
      >
        <Item onClick={handleSeeGoal} className="contextMenuItem">
          edit goal
        </Item>
      </Menu>
    </div>
  );

  const handleSeeGoal = args => {
    const currentGoal = args.props.goal as Goal;
    const isPreviousGoal = args.props.isPreviousGoal as boolean;

    localStore.setIsCurrentPreviousGoal(isPreviousGoal);
    _onGoalPress(currentGoal);
  };

  const handleOnProgramGoalSelectPress = (goal: GoalT) => {
    localStore._onProgramGoalSelectPress(goal);
  };

  const handleOnSelectChooseGoal = (goals: PreviousGoal[]) => {
    localStore.setIsOpenChooseGoalModule(false);

    localStore.setPreviousGoals(goals);

    localStore._changePreviousSubgoalDates();
  };

  const _renderProgramGoal = (goal: Goal, index: number) => {
    const isSelected =
      localStore.programGoalsSelection.findIndex(
        item => item._id === goal._id,
      ) >= 0;

    return (
      <GoalsListItem
        key={goal._id}
        goal={goal}
        index={index + 1}
        label={`Main goal #${index + 1}`}
        isSelectable={!localStore.programGoalsSourceIsNewGoals}
        isSelected={isSelected}
        onSelectPress={handleOnProgramGoalSelectPress}
        onGetContextMenu={handleGetContextMenu(false)}
      />
    );
  };

  const handelOnDeletePreviousGoalPress = (goal: PreviousGoal) => {
    localStore._onDeletePreviousGoalPress(goal);
  };
  const _onPreviousSubgoalPress = (subgoal: PreviousGoal['subgoals'][0]) => {
    localStore.setEditSubgoal(subgoal);
    localStore.setIsOpenCRUSubgoalModule(true);
  };

  const handleCloseModalCRUSubgoal = () => {
    localStore.setIsOpenCRUSubgoalModule(false);
  };

  const onSuccessSubgoalEdit = (updatedSubgoal: Subgoal) => {
    handleCloseModalCRUSubgoal();

    const goalIndex = localStore.previousGoals.findIndex(
      item => localStore.editSubgoal!.goal_id === item._id,
    );

    const subgoalIndex = localStore.previousGoals[
      goalIndex
    ].subgoal_ids.findIndex(id => localStore.editSubgoal!._id === id);

    localStore.setEditSubgoal(null);

    const subgoals = localStore.previousGoals[goalIndex].subgoals;

    subgoals.splice(subgoalIndex, 1, {
      ...updatedSubgoal,
      // @ts-ignore
      _initialStartDate: null,
      // @ts-ignore
      _initialEndDate: null,
    });

    localStore.previousGoals[goalIndex] = {
      ...localStore.previousGoals[goalIndex],
      subgoals,
    };
  };

  const _onDeletePreviousSubgoalPress = (
    subgoal: PreviousGoal['subgoals'][0],
  ) => {
    const goalIndex = localStore.previousGoals.findIndex(
      item => subgoal.goal_id === item._id,
    );

    const subgoalIndex = localStore.previousGoals[
      goalIndex
    ].subgoal_ids.findIndex(id => subgoal._id === id);

    const subgoals = localStore.previousGoals[goalIndex].subgoals;

    subgoals.splice(subgoalIndex, 1);

    localStore.previousGoals[goalIndex] = {
      ...localStore.previousGoals[goalIndex],
      subgoals,
    };
  };

  const _renderPreviousGoal = (goal: PreviousGoal, index: number) => {
    return (
      <PreviousGoalsListItem
        key={goal._id}
        goal={goal}
        index={index + 1}
        onPress={handleGetContextMenu(true)}
        onDeleteButtonPress={handelOnDeletePreviousGoalPress}
        onSubgoalPress={_onPreviousSubgoalPress}
        onDeleteSubgoalButtonPress={_onDeletePreviousSubgoalPress}
      />
    );
  };

  return (
    <Observer>
      {() => (
        <div className={`AddGoals ${styles.AddGoals}`}>
          <div className={styles.topMenuContainer}>
            {localStore.programGoalsStore &&
            localStore.programGoalsStore!.isLoaded
              ? GOALS_SOURCE.map(source => {
                  const isSelected = source === localStore.goalsSource;

                  return (
                    <div
                      key={source}
                      onClick={() => localStore._setGoalsSource(source)}
                    >
                      <div
                        className={`${styles.topButt} ${
                          isSelected ? styles.active : ''
                        }`}
                      >
                        {t([
                          I18N_SCOPE,
                          `${
                            source === 'program' &&
                            localStore.programGoalsSourceIsNewGoals
                              ? 'new'
                              : source
                          }_goals_lbl`,
                        ])}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className={styles.contentContainer}>
            <Scrollbar
              className={'scrollbar'}
              noScrollX
              wrapperProps={{className: 'wrapper'}}
              trackYProps={{className: 'trackY'}}
              thumbYProps={{className: 'thumbY'}}
              trackXProps={{className: 'trackX'}}
              thumbXProps={{className: 'thumbY'}}
            >
              {localStore.programGoalsStore &&
              localStore.programGoalsStore!.isLoaded ? (
                <>
                  {localStore.goalsSource === 'program' ? (
                    <div>
                      {localStore.programGoalsStore!.items.slice().length >
                      0 ? (
                        localStore
                          .programGoalsStore!.items.slice()
                          .map(_renderProgramGoal)
                      ) : (
                        <NoResults
                          text={t([I18N_SCOPE, 'no_new_goal'])}
                          className={styles.NoResults}
                          classNameTextContainer={styles.textContainer}
                        >
                          <div className={styles.iconNoResultContainer}>
                            <IconCup
                              className={styles.iconNoResult}
                              viewBox="4 4 30 30"
                            />
                          </div>
                        </NoResults>
                      )}
                    </div>
                  ) : localStore.goalsSource === 'previous' ? (
                    <div>
                      {localStore.previousGoals.slice().length > 0 ? (
                        localStore.previousGoals
                          .slice()
                          .map(_renderPreviousGoal)
                      ) : (
                        <NoResults
                          text={t([I18N_SCOPE, 'no_choose_goal'])}
                          className={styles.NoResults}
                          classNameTextContainer={styles.textContainer}
                        >
                          <div className={styles.iconNoResultContainer}>
                            <IconCup
                              className={styles.iconNoResult}
                              viewBox="4 4 30 30"
                            />
                          </div>
                        </NoResults>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                <Loader />
              )}
            </Scrollbar>
          </div>
          <div className={styles.butMenuContainer}>
            {localStore.programGoalsStore &&
            localStore.programGoalsStore!.isLoaded ? (
              <>
                {localStore.goalsSource === 'program' ? (
                  <div>
                    <CustomButton
                      type="button"
                      classButton={`QuestionnaireContinueBut allWidth ${styles.addGoalBut}`}
                      onClick={_onAddGoalButtonPress}
                    >
                      <span className={styles.plusIcon}>+</span>
                      <span>{t([I18N_SCOPE, 'add_goal_btn'])}</span>
                    </CustomButton>
                  </div>
                ) : (
                  <div>
                    <CustomButton
                      type="button"
                      classButton={`QuestionnaireContinueBut allWidth ${styles.addGoalBut}`}
                      onClick={_onChoosePreviousGoalsButtonPress}
                    >
                      <span>{t([I18N_SCOPE, 'choose_goals_btn'])}</span>
                    </CustomButton>
                  </div>
                )}

                <div>
                  <CustomButton
                    type="button"
                    disabled={
                      !(
                        (localStore.goalsSource === 'previous' &&
                          localStore.previousGoals.length) ||
                        (localStore.goalsSource === 'program' &&
                          localStore.programGoalsSelection.length)
                      )
                    }
                    classButton={`blueButt ${styles.chooseGoalBut}`}
                    onClick={_onSetButtonPress}
                  >
                    <span>{t([I18N_SCOPE, 'set_button'])}</span>
                  </CustomButton>
                </div>
              </>
            ) : null}
          </div>
          <MyMenuProgramMainGoal />
          <ModalAnimateWin
            showModal={localStore.isOpenAddGoalModule}
            closeModalHandler={handleCloseModalAddGoal}
            className="greyHeaderContainer"
            isBody
            classNameBody="whiteBody noP30"
            header={`${labelAdd()} ${labelMainGoal()}`}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            {localStore.selectGoal ? (
              <CRUGoal
                goal={localStore.selectGoal}
                onSuccess={handleOnEditMainGoal}
              />
            ) : (
              <CRUGoal
                programId={course.program_id}
                courseId={course._id}
                closeModal={handleCloseAddGoalRerunModal}
              />
            )}
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.isOpenCRUSubgoalModule}
            closeModalHandler={handleCloseModalCRUSubgoal}
            className="greyHeaderContainer littleContainer w500 List"
            isBody
            classNameBody="whiteBody maxContent noP30"
            header={t([I18N_SCOPE_SUBGOAL, 'nav_edit_title'])}
            classNameHeader="greyHeader w500"
            classNameCloseBut="greyHeaderBut"
          >
            {localStore.editSubgoal ? (
              <CRUSubgoal
                goalId={localStore.editSubgoal.goal_id}
                subgoal={localStore.editSubgoal}
                minDate={dayjs(
                  course.start_date
                    ? datetimeObjToISOString(course.start_date)
                    : undefined,
                ).toDate()}
                maxDate={
                  course.end_date
                    ? dayjs(datetimeObjToISOString(course.end_date)).toDate()
                    : dayjs()
                        .add((course.edition || {duration: 7}).duration, 'day')
                        .toDate()
                }
                onSuccess={onSuccessSubgoalEdit}
              />
            ) : null}
          </ModalAnimateWin>
          <ModalAnimateWin
            showModal={localStore.isOpenChooseGoalModule}
            closeModalHandler={handleCloseModalChooseGoal}
            className="greyHeaderContainer halfContainer"
            isBody
            classNameBody="whiteBody noP30"
            header={t([I18N_SCOPE, 'choose_goals_btn'])}
            classNameHeader="greyHeader"
            classNameCloseBut="greyHeaderBut"
          >
            <SelectGoal
              firstProgramId={course.program_id}
              multiSelection
              onSelect={handleOnSelectChooseGoal}
            />
          </ModalAnimateWin>
        </div>
      )}
    </Observer>
  );
};

export default memo(AddGoals);
