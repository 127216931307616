import type {FC} from 'react';
import React from 'react';

import IconNewSettingsSVG from '@src/assets/img/newSetting21092020.svg';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

const IconNewSettings: FC<Props> = ({
  height = '100%',
  width = '100%',
  className = 'ico',
  viewBox = '2 2 16 16',
}) => {
  return (
    <IconNewSettingsSVG
      width={height}
      height={width}
      className={className}
      fill="#819AA9"
      viewBox={viewBox}
    />
  );
};

export default IconNewSettings;
