import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {Clickable} from '@yourcoach/shared/uikit/Clickable';

export const Header = styled.div`
  background: ${getColor('background1')};
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const CloseButton = styled(Clickable)`
  background: ${getColor('background2')};
  border-radius: 10px;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${getColor('icon3')};
    width: 24px;
    height: 24px;
  }
`;
