import React, {useCallback, useEffect, useRef} from 'react';
import InView from 'react-intersection-observer';
import {useHistory} from 'react-router-dom';
import {Observer} from 'mobx-react';

import {apiRequest} from '@yourcoach/shared/api';
import {eventStore} from '@yourcoach/shared/api/event';
import type {UserEventsCountParams} from '@yourcoach/shared/api/types/methods';
import AlarmIcon from '@yourcoach/shared/assets/icons/alarm.svg';
import {useMobxStore} from '@yourcoach/shared/hooks';
import {NotificationsListItem} from '@yourcoach/shared/modules/notifications/components/NotificationsListItem';
import {NotificationsStore} from '@yourcoach/shared/modules/notifications/stores/Notifications';
import {EXCLUDED_EVENTS} from '@yourcoach/shared/modules/notifications/stores/Notifications/utils';
import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import delay from '@yourcoach/shared/utils/delay';
import {logger} from '@yourcoach/shared/utils/logger';
import type {GetComponentProps} from '@yourcoach/shared/utils/utility-types';

import NoResultsHeader from '@src/components/NoResultsHeader';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import localAppStore from '@src/context/appStore';
import {t} from '@src/i18n';

import {emitter} from '../../../../../../widget/src/utils';
import {I18N_SCOPE} from '../../utils';

import {getClickHandler} from './getClickHandler';
import * as S from './styles';
import type {Props} from './types';

export const NotificationsList: React.FC<Props> = () => {
  const store = useMobxStore(() => new NotificationsStore());

  const fetchUnreadEventsCountDelay = useRef(delay(0));
  const readAllEventsDelay = useRef(delay(0));
  const isPaginating = useRef(false);

  const history = useHistory();

  useEffect(() => {
    readAllEvents();
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUnreadEventsCount = useCallback(async () => {
    try {
      const result = await apiRequest<UserEventsCountParams>({
        method: 'user.events.count',
        params: {
          query: [
            ['type', '!in', EXCLUDED_EVENTS],
            ['read', '==', null],
          ],
        },
      });

      localAppStore?.setTopNotification(result._count);

      return result._count;
    } catch (error) {
      logger.error(error);

      fetchUnreadEventsCountDelay.current = delay(2000);

      await fetchUnreadEventsCountDelay.current;

      return fetchUnreadEventsCount();
    }
  }, []);

  const readAllEvents = useCallback(async () => {
    try {
      await eventStore.markReadAll({
        limit: 1000,
        query: [['type', '!in', EXCLUDED_EVENTS]],
      });

      localAppStore?.setTopNotification(0);

      return true;
    } catch (error) {
      logger.error(error);

      readAllEventsDelay.current = delay(2000);

      await readAllEventsDelay.current;

      return readAllEvents();
    }
  }, []);

  const refresh = useCallback(
    async (silent = false) => {
      store.fetch(silent);

      fetchUnreadEventsCount();
    },
    [fetchUnreadEventsCount, store],
  );

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleWsMessage = msg => {
    if (!EXCLUDED_EVENTS.includes(msg.event.type)) {
      refresh(true);
    }
  };

  const handleOnScrollLastElement = async (inView: boolean) => {
    if (inView) {
      if (isPaginating.current) {
        return;
      }

      isPaginating.current = true;

      await store.fetchMore();

      isPaginating.current = false;
    }
  };

  const onClick: GetComponentProps<typeof NotificationsListItem>['onClick'] =
    useCallback(
      (id, event, contexts) => {
        const handler = getClickHandler({id, event, contexts, store, history});

        if (handler) {
          handler();

          localAppStore.setIsTopNotificationsOpen(false);
        }
      },
      [history, store],
    );

    const handleEndProgramReminderTitleClick = (url: string) => {
      history.push(url);
    };

  return (
    <Observer>
      {() => (
        <S.Container>
          {store.isFetching ? (
            <S.LoadingSpinnerContainer>
              <LoadingSpinner size={100} />
            </S.LoadingSpinnerContainer>
          ) : store.isEmpty ? (
            <S.NoResultsContainer>
              <NoResultsHeader
                text={t([I18N_SCOPE, 'no_results_label'])}
                icon={AlarmIcon}
              />
            </S.NoResultsContainer>
          ) : (
            <div>
              {store.sections.map((section, index) => {
                return (
                  <div key={index}>
                    <S.SectionHeader>
                      <S.SectionTitle>{section.title}</S.SectionTitle>
                      {section.badge ? (
                        <S.SectionBadge>{section.badge}</S.SectionBadge>
                      ) : null}
                    </S.SectionHeader>
                    <S.SectionContent>
                      {section.data.map(event => (
                        <NotificationsListItem
                          event={event}
                          key={event._id}
                          onClick={onClick}
                          onEndProgramTitleClick={
                            handleEndProgramReminderTitleClick
                          }
                        />
                      ))}
                    </S.SectionContent>
                  </div>
                );
              })}
              <InView onChange={handleOnScrollLastElement}>
                <Observer>
                  {() => (
                    <>
                      {store.itemsCount > 0 &&
                      store.earlierEventsStore.cursor.next ? (
                        <S.LoadingSpinnerContainer>
                          <LoadingSpinner size={50} />
                        </S.LoadingSpinnerContainer>
                      ) : null}
                    </>
                  )}
                </Observer>
              </InView>
            </div>
          )}
        </S.Container>
      )}
    </Observer>
  );
};
