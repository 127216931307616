import type {FC} from 'react';
import React, {memo, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import NiceModal from '@ebay/nice-modal-react';
import {Observer} from 'mobx-react';

import {autorun} from 'mobx';
import {v4 as uuidv4} from 'uuid';

import type {Channel} from '@yourcoach/shared/api/channel';
import {getTierImage} from '@yourcoach/shared/api/gamification';
import AlarmIcon from '@yourcoach/shared/assets/icons/alarm.svg';
import HelpIcon from '@yourcoach/shared/assets/icons/help.svg';

import {getCustomConfirmAlert} from '@src/components/CustomConfirmAlert/CustomConfirmAlert';
import CustomToast from '@src/components/CustomToast/CustomToast';
import {WS_RECEIVE_MESSAGE_EVENT} from '@src/components/WS/WS';
import YCToast from '@src/components/YCToast/YCToast';
import AppContext from '@src/context/App';
import localAppStore, {
  EXCLUDED_EVENTS,
  POST_EVENTS,
} from '@src/context/appStore';
import {t} from '@src/i18n';
import {SUPPORTED_EVENTS} from '@src/modules/Chats/context/useChatsLocalStore';
import {NotificationsModal} from '@src/v2/modules/notifications/components/Modal';

import {emitter} from '../../../widget/src/utils';
import {PathBuilderService} from '../../v2/services/PathBuilderService';
import TwoColumns from '../TwoColumns/TwoColumns';

import styles from './styles.module.css';

export const I18N_SCOPE = 'inAppNotification';

interface Props {}

const NotificationTopContainer: FC<Props> = () => {
  const {
    stores: {eventStore},
  } = useContext(AppContext);
  const {addToast} = useToasts();
  const history = useHistory();

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);

    _fetchUnreadEvents();
    localAppStore?._fetchUnreadEventsCount();

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, _handleWsMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dispose = autorun(() => {
      if (localAppStore.isTopNotificationsOpen) {
        NiceModal.show(NotificationsModal);
      } else {
        NiceModal.hide(NotificationsModal);
      }
    });

    return dispose;
  }, []);

  const handleOnClickPointsAdd = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    history.push({
      pathname: '/profile/certification',
    });
  };

  const _handleWsMessage = async msg => {
    if (EXCLUDED_EVENTS.includes(msg.event.type)) {
      switch (msg.event.type) {
        case 'points_added': {
          if (msg.user_reward.total_points <= 0) {
            return;
          }

          const id = `points_added_${uuidv4()}`;

          addToast(
            <YCToast
              title={msg.user_reward.title}
              qty={msg.user_reward.total_points}
              onClick={handleOnClickPointsAdd}
              toastId={id}
            />,
            {appearance: 'info', id, autoDismiss: true},
          );
          break;
        }
        case 'membership_proposed': {
          const wasMembershipRequested = !!msg.membership.sign;

          getCustomConfirmAlert({
            title: t([I18N_SCOPE, msg.event.type, 'title'], {
              courseTitle: msg.program.title,
            }),
            message: t(
              [
                I18N_SCOPE,
                msg.event.type,
                'description',
                wasMembershipRequested ? 'request_accepted' : 'invite',
              ],
              {
                actorName: msg.actor.name,
              },
            ),
            buttons: [
              {
                label: t('shared.button.ok'),
                onClick: () => {
                  // const practiceName = msg.program.expanded_coaches[0].slug
                  //   ? msg.program.expanded_coaches[0].slug
                  //   : msg.program.expanded_coaches[0]._id;
                  //
                  // const programName = msg.program.slug
                  //   ? msg.program.slug
                  //   : msg.program._id;

                  // history.push({
                  //   pathname: `/coaches/${practiceName}/programs/${programName}`,
                  // });
                  const practiceSlug = msg.program.expanded_coaches[0].slug;
                  const practiceId = msg.program.expanded_coaches[0]._id;
                  const programSlug = msg.program.slug;
                  const programId = msg.program._id;

                  history.push(
                    PathBuilderService.toProgram(
                      {slug: practiceSlug, id: practiceId},
                      {slug: programSlug, id: programId},
                    ),
                  );
                },
                type: 'confirm',
              },
            ],
          });
          break;
        }
        case 'tier_upgraded': {
          let description: string | undefined;

          getCustomConfirmAlert({
            title: t([I18N_SCOPE, msg.event.type, 'title'], {
              tierTitle: msg.tier.title,
            }),
            isCongrats: true,
            childrenElement: (
              <div>
                <img src={getTierImage(msg.tier.level)} />
              </div>
            ),
            message: description,
            buttons: [
              {
                label: t('shared.button.ok'),
                onClick: () => {
                  history.push({
                    pathname: '/profile/certification',
                  });
                },
                type: 'confirm',
              },
            ],
          });
          break;
        }
        case 'tier_downgraded': {
          let description: string | undefined;

          getCustomConfirmAlert({
            title: t([
              I18N_SCOPE,
              msg.event.type,
              'title',
              {
                tierTitle: msg.tier.title,
              },
            ]),
            message: description,
            buttons: [
              {
                label: t('shared.button.ok'),
                onClick: () => {
                  history.push({
                    pathname: '/profile/certification',
                  });
                },
                type: 'confirm',
              },
            ],
          });
          break;
        }
        case 'questionnaire_completed': {
          addToast(
            <CustomToast
              title={t([I18N_SCOPE, msg.event.type, 'title'], {
                questionnaireTitle: msg.user_questionnaire.title,
              })}
              message={t([I18N_SCOPE, msg.event.type, 'description'], {
                actorName: msg.actor.name,
              })}
              onClick={() => {
                history.push({
                  pathname: '/questionnaire/view/status',
                  search: `?id=${msg.user_questionnaire._id}`,
                });
              }}
            >
              <HelpIcon width={'100%'} height={'100%'} />
            </CustomToast>,
            {
              appearance: 'info',
              id: msg.user_questionnaire._id,
              autoDismiss: true,
            },
          );
          break;
        }
        case 'coach_verification_required': {
          addToast(
            <CustomToast title={t([I18N_SCOPE, msg.event.type, 'title'])}>
              <AlarmIcon />
            </CustomToast>,
            {
              appearance: 'info',
              id: uuidv4(),
              autoDismiss: true,
            },
          );
          break;
        }
        case 'coach_verified': {
          addToast(
            <CustomToast title={t([I18N_SCOPE, msg.event.type, 'title'])}>
              <AlarmIcon />
            </CustomToast>,
            {
              appearance: 'info',
              id: uuidv4(),
              autoDismiss: true,
            },
          );
          break;
        }
        default:
          break;
      }
    }

    if (!EXCLUDED_EVENTS.includes(msg.event.type)) {
      localAppStore?._fetchUnreadEventsCount();
    }

    if (POST_EVENTS.concat(SUPPORTED_EVENTS).includes(msg.event.type)) {
      _fetchUnreadEvents();
    }
  };

  const _fetchUnreadEvents = async () => {
    await eventStore.fetchUnread({
      limit: 500,
    });

    let numNotify = 0;

    eventStore.unreadEvents.forEach(event => {
      if (event.type === 'post_created') {
        // @ts-ignore
        const channel = event.contexts.find(
          (item: any) => item && item._id.split(':')[0] === 'channel',
        ) as Channel | undefined;

        if (channel) {
          numNotify += 1;
        }
      }
    });

    localAppStore?.setPostCreatedNotification(numNotify);
  };

  return (
    <Observer>
      {() => (
        <>
          <TwoColumns allHeight={false}>
            <div className={styles.NotificationsTopContainer} />
          </TwoColumns>
        </>
      )}
    </Observer>
  );
};

export default memo(NotificationTopContainer);
