import React from 'react';

import styles from './ConferenceLayout.module.css';

interface Props {
  children?: React.ReactNode;
}

const ConferenceLayout: React.FC<Props> = ({children}) => {
  return (
    <section className={styles.wrapper}>
      <section className={styles.container}>{children}</section>
    </section>
  );
};

export default React.memo(ConferenceLayout);
