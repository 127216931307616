import type {FC} from 'react';
import React, {memo} from 'react';

import {v4 as uuidv4} from 'uuid';

import type {QuestionnaireSection} from '@yourcoach/shared/api/questionnaire';

import {labelAddToMyQuestionnaire} from '@src/common/i18n/i18nQuestionnaire';
import {CustomButton} from '@src/components/CustomForm';

import type {IQSectionWithFields} from '../context/qAppStore';

import styles from './styles.module.css';

interface Props {
  isInModal: boolean;
  className?: string;
  setSection: (section: IQSectionWithFields) => void;
  sectionList: QuestionnaireSection[];
}

const QSectionTemplateList: FC<Props> = ({
  sectionList,
  className = '',
  setSection,
}) => {
  const handleOnClickAdd = (section: QuestionnaireSection) => {
    return () => {
      const userSection: IQSectionWithFields = {
        id: uuidv4(),
        ...section,
      };

      setSection(userSection);
    };
  };

  return (
    <div
      className={`QSectionTemplateList ${styles.QSectionTemplateList} ${className}`}
    >
      <div className={styles.sectionsContainer}>
        {sectionList.map((section, indexSect) => (
          <div key={indexSect} className={styles.sections}>
            <h4>{section.title}</h4>
            <ul>
              {section.questions.map(({question}, indexQuest) => (
                <li key={indexQuest}>
                  <span>{question}</span>
                </li>
              ))}
            </ul>
            <div className={styles.buttContainer}>
              <CustomButton
                type="button"
                classButton={`blueButt ${styles.sendBut}`}
                onClick={handleOnClickAdd(section)}
              >
                {labelAddToMyQuestionnaire()}
              </CustomButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(QSectionTemplateList);
