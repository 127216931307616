import type {FC} from 'react';
import React, {memo, useCallback, useContext, useMemo} from 'react';

import {getCourseDurationString} from '@yourcoach/shared/api/course';
import {Button} from '@yourcoach/shared/uikit/Button';

import {labelSharedCourseStatus} from '@src/common/i18n/i18nCommon';
import {
  labelProgramCoursesListItemDeleteButton,
  labelProgramCoursesListItemOldConditions,
  labelProgramCoursesListItemStartButton,
  labelProgramCoursesListItemUpgradeButton,
} from '@src/common/i18n/i18nPrograms';
import {IconCalendar, IconGroupSmall, IconNext} from '@src/components/icons';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';
import AppContext from '@src/context/App';
import {t} from '@src/i18n';

import type {Course} from '../SquadsTab';

import styles from './../../styles.module.scss';

interface Props {
  course: Course;
  onPress: (course: Course) => void;
  onStartButtonPress: (course: Course) => Promise<void>;
  onStopButtonPress: (course: Course) => Promise<void>;
  onDeleteButtonPress: (course: Course) => Promise<void>;
  onUpgradeButtonPress: (course: Course) => Promise<void>;
  onClickExtend: (course: Course) => Promise<void>;
}

const ProgramCoursesListItem: FC<Props> = ({
  course,
  onPress,
  onStartButtonPress,
  onDeleteButtonPress,
  onUpgradeButtonPress,
  onClickExtend,
}) => {
  const {
    stores: {currentUserStore},
  } = useContext(AppContext);
  const user = currentUserStore.user || null;

  const onPressCb = useCallback(() => {
    onPress && onPress(course);
  }, [course, onPress]);

  const onStartButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      onStartButtonPress && onStartButtonPress(course);
    },
    [course, onStartButtonPress],
  );

  const onDeleteButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      onDeleteButtonPress && onDeleteButtonPress(course);
    },
    [course, onDeleteButtonPress],
  );

  const onUpgradeButtonPressCb = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      onUpgradeButtonPress && onUpgradeButtonPress(course);
    },
    [course, onUpgradeButtonPress],
  );

  const isIndividual = useMemo(
    () => course.edition.group_size === 1,
    [course.edition.group_size],
  );

  let status = '';

  if (course.status === 'ongoing') {
    status = labelSharedCourseStatus(course.status);
  }

  const lastEditionIsSigned = useMemo(
    () =>
      course.programLastEdition
        ? course.programLastEdition.coach_ids.every(coachId => {
            return course.programLastEdition!.coaches[coachId].signed;
          })
        : true,
    [course.programLastEdition],
  );

  const isMainCoach = useMemo(
    () => user && course.user_id === user._id,
    [course.user_id, user],
  );

  return (
    <div
      className={`ProgramCoursesListItem ${styles.ProgramCoursesListItem}`}
      onClick={onPressCb}
    >
      <div className={styles.programCoursesContainer}>
        <div className={styles.contentContainerLeft}>
          <div className={styles.iconCalendarContainer}>
            <IconCalendar className={styles.iconCalendar} />
          </div>
          <div className={styles.durationContainer}>
            {getCourseDurationString(course) ||
              t('label.course_no_start_date.coach')}
          </div>
          {course.edition.group_size === 1 && course!.client_id ? (
            <div className={styles.singleUserContainer}>
              <div className={styles.singleUserAvatar}>
                <OtherUserProfileImg
                  avatar={course.client.avatar}
                  title={course.client.name}
                />
              </div>
              <div className={styles.courseClient}>{course.client.name}</div>
            </div>
          ) : null}
        </div>
        <div className={styles.contentContainerRight}>
          <div className={styles.buttContainer}>
            {isMainCoach &&
            course.counters.memberships.accepted > 0 &&
            course.status === 'planned' ? (
              <div className={styles.startBut} onClick={onStartButtonPressCb}>
                {labelProgramCoursesListItemStartButton(
                  isIndividual ? 'individual' : 'group',
                )}
              </div>
            ) : null}
            {isMainCoach &&
            !Object.values(course.counters.memberships).some(
              count => count > 0,
            ) ? (
              <div className={styles.deleteBut} onClick={onDeleteButtonPressCb}>
                {labelProgramCoursesListItemDeleteButton()}
              </div>
            ) : null}
          </div>
          <div className={styles.status}>
            {status ? (
              <div className={styles.courseStatus}>{status}</div>
            ) : null}
            {course.edition.group_size > 1 ? (
              <div className={styles.groupContainer}>
                <IconGroupSmall className={styles.iconGroupSmall} />
                <div className={styles.courseSize}>
                  {`${course.counters.memberships.accepted}/${course.edition.group_size}`}
                </div>
              </div>
            ) : null}
            {/* {course.status === 'archived' && (
              <Button
                className={styles.prolongButton}
                onClick={() => onClickExtend(course)}
              >
                Extend
              </Button>
            )} */}
          </div>
          <div className={styles.iconNextContainer}>
            <IconNext />
          </div>
        </div>
      </div>
      {course.status === 'planned' &&
      course.programLastEdition &&
      course.edition._id !== course.programLastEdition._id &&
      lastEditionIsSigned ? (
        <div className={styles.oldConditionsContainer}>
          <p className={styles.oldConditionsDescription}>
            {labelProgramCoursesListItemOldConditions(
              isIndividual ? 'individual' : 'group',
            )}
          </p>
          <div className={styles.upgradeBut} onClick={onUpgradeButtonPressCb}>
            {labelProgramCoursesListItemUpgradeButton()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(ProgramCoursesListItem);
