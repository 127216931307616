import type {AttachmentFile} from '@yourcoach/shared/stores/single-event';

import {openImageInANewTab} from '../../utils';

export function useOnAttachmentClick() {
  const onAttachmentClick = (attachment: Pick<AttachmentFile, 'src'>) =>
    openImageInANewTab(attachment.src.original.url);

  return onAttachmentClick;
}
