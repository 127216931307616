import {v4 as uuidv4} from 'uuid';

import type {RpcRequest} from '@yourcoach/shared/api';
import {apiRequest, expandObj} from '@yourcoach/shared/api';
import type {IFile} from '@yourcoach/shared/api/media/file';
import {getFileName} from '@yourcoach/shared/api/media/file';
import type {Link} from '@yourcoach/shared/api/media/link';
import {ucfirst} from '@yourcoach/shared/utils/format';

import type {ILink, ILinkExpanded} from '@src/models/library';

import AnyFileIcon from './assets/any-file.svg';
import DocFileIcon from './assets/doc-file.svg';
import PdfFileIcon from './assets/pdf-file.svg';
import PptFileIcon from './assets/ppt-file.svg';
import XlsFileIcon from './assets/xls-file.svg';
import {linkExpand} from './context/useLibraryLocalStore';

export const getFileIconByExt = (ext: string) => {
  if (ext) {
    if (ext === 'doc' || ext === 'docx') {
      return DocFileIcon;
    }

    if (ext === 'pdf') {
      return PdfFileIcon;
    }

    if (ext === 'ppt' || ext === 'pptx') {
      return PptFileIcon;
    }

    if (ext === 'xls' || ext === 'xlsx') {
      return XlsFileIcon;
    }
  }

  return AnyFileIcon;
};

export const getType = file => {
  let retType = '';

  if (file.type.match('text')) {
    retType = 'document';
  } else if (file.type.match('image')) {
    retType = 'image';
  } else {
    retType = file.type;
  }

  return retType;
};

export const getSendObjFile = file => {
  const type = getType(file);
  const name = file.name.replace(/\..+$/, '');
  const uri = URL.createObjectURL(file);
  const size = file.size;

  const retObj = {
    _id: uuidv4().replace(/-/g, ''),
    categories: [type],
    src: {
      original: {
        filename: name || ucfirst(type),
        url: uri,
        size,
      },
    },
  };

  return retObj;
};

export const linkFiles = async (
  files: IFile[],
  folderId: string | null,
  alertFn: Function,
) => {
  try {
    const batch: RpcRequest[] = files.map(file => ({
      method: 'media.links.create',
      params: {
        name: getFileName(file),
        folder_id: folderId || null,
        resource_id: file._id,
        expand: linkExpand,
      },
    }));

    const batchResult = await apiRequest({batch});

    const links: ILink[] = [];

    batchResult.forEach(({link, _expanded}) => {
      const expandedLink = expandObj<Link, ILinkExpanded>(link, {
        expand: linkExpand,
        expanded: _expanded,
      });

      links.push(expandedLink);
    });

    return links;
  } catch (e) {
    alertFn(e);
  }
};

/**
 * Function to open link in new window
 * @param link
 * @returns
 */
export const openLink = (link: any) => {
  return (e: any) => {
    e.preventDefault();
    window.open(link);
  };
};

/**
 * Function to open file in new window or download some type of file(.docx)
 * @param fileUrl
 * @returns
 */
export const openFile = (fileUrl: any) => {
  return () => {
    if (fileUrl) {
      const win = window.open(fileUrl, '_blank');

      win!.focus();
    }
  };
};
