import type {FC} from 'react';
import React, {memo, useCallback} from 'react';
import {Observer} from 'mobx-react';

import dayjs from 'dayjs';

import {datetimeObjToISOString} from '@yourcoach/shared/api';

import {labelCourseMembersTabJoined} from '@src/common/i18n/i18nCourse';
import OtherUserProfileImg from '@src/components/OtherUserProfileImg/OtherUserProfileImg';

import type {MembershipT} from '../../CourseClientsTab/CourseClientsTab';

import styles from './../../../styles.module.scss';

interface Props {
  membership: MembershipT;
  onPress: (membership: MembershipT) => void;
}

const CourseMembersListItem: FC<Props> = ({membership, onPress}) => {
  const handleOnClick = useCallback(() => {
    onPress && onPress(membership);
  }, [membership, onPress]);

  return (
    <Observer>
      {() => (
        <div
          className={`CourseMembersListItem ${styles.CourseMembersListItem}`}
          onClick={handleOnClick}
        >
          <div className={styles.userContainer}>
            <div className={styles.userAvatar}>
              {
                <OtherUserProfileImg
                  avatar={membership.user.avatar}
                  title={membership.user.name}
                  profileClassName={styles.userAvatar}
                />
              }
            </div>
            <div className={styles.userName}>{membership.user_name}</div>
            <div className={styles.date}>
              {labelCourseMembersTabJoined(
                dayjs(datetimeObjToISOString(membership.created)).format('lll'),
              )}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(CourseMembersListItem);
